import React, { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "./Form.scss";
import { _Api } from "../../common/api/_call";
import { useTranslation } from 'react-i18next';
const CommonForm = (props) => {
  const { setShowseqs, setEngraving, Engraving, setshowcolor, engraving_edit, edit_cart, custom_design, editproduct, engraving_logo } = props;
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [logo, setLogo] = useState();
  const [font, setFont] = useState(Engraving ? { value: Engraving?.font ? Engraving?.font : custom_design?.font, label: Engraving?.font ? Engraving?.font : custom_design?.font } : '');
  const [engraving_design, setEngraving_design] = useState(Engraving ? Engraving : { 'engraving_text': custom_design?.engraving_text, 'engraving_position': custom_design?.engraving_position, 'engraving_logo': custom_design?.engraving_logo, 'font': custom_design?.font, 'logo_position': custom_design?.engraving_position });
  const fontarray = [
    { value: 'Serif', label: 'Serif' },
    { value: 'Sans-serif', label: 'Sans-serif' },
    { value: 'Monospace ', label: 'Monospace' },
  ];
  const onImageChange = (e) => {
    const [file] = e.target.files;
    setLogo(URL.createObjectURL(file));
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      try {
        const postdata = {
          "Data": [{ 'data_url': reader.result }],
          "name": "mix_match_logo"
        };
        let res = await _Api(postdata, `api/v1/POS/customOrder/uploadDesign`);
        if (res.code === 200) {
          setEngraving_design({ ...engraving_design, engraving_logo: res?.data[0]?.url })
        }
      }
      catch (err) {
        console.log(err, 'err');
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  }
  return (
    <>
      <div className='create-own-setting-right-sketch-area-inner-engraving mix-match-form'>
        <div className='create-own-setting-right-sketch-area-inner-engraving-form'>
          <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner'>
            <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-col'>
              <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp'>
                <label className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label'>{t("Text")}</label>
                <textarea className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-field com-input-field' placeholder='Write your text here' defaultValue={engraving_design?.engraving_text} onChange={(e) => setEngraving_design({ ...engraving_design, engraving_text: e.target.value })} row="3" />
              </div>
              <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp'>
                <label className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label'>{t("Position")}</label>
                <input className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-field com-input-field' placeholder='Which position would you like to engraving ?' defaultValue={engraving_design?.engraving_position ? engraving_design?.engraving_position : custom_design?.engraving_position} onChange={(e) => setEngraving_design({ ...engraving_design, engraving_position: e.target.value })} />
              </div>
            </div>
            <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-col'>
              <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp'>
                <label className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label'>{t("logo")}</label>
                <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop'>
                  <input className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-input' onChange={(e) => onImageChange(e)} type="file"
                    accept="image/*" />

                  {
                    logo ?
                      <img className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-img'
                        src={logo} alt="" /> :
                      engraving_logo ?
                        <img className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-img' src={engraving_logo} alt="" /> :
                        custom_design?.engraving_logo ?
                          <img className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-img'
                            src={custom_design?.engraving_logo} alt="" /> :
                          <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-inner'>
                            <img className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-inner-icon'
                              src={base_assets + "images/pos/icons/add-img-g.png"} alt="" />
                          </div>

                  }
                </div>
              </div>
            </div>
          </div>
          <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner'>
            <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-col'>
              <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp'>
                <label className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label'>{t("Font")}</label>
                <Select
                  defaultValue={font}
                  onChange={(e) => { setEngraving_design({ ...engraving_design, font: e?.value }); setFont(e) }}
                  options={fontarray}
                  classNamePrefix="common-select"
                  placeholder="Select Font"
                  isSearchable={false}
                />
                <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-design">
                  <span className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-design-text' style={{
                    'fontFamily': engraving_design?.font ? engraving_design?.font : custom_design?.font,
                    'textAlign': engraving_design?.engraving_position ? engraving_design?.engraving_position : custom_design?.engraving_position
                  }}>{engraving_design?.engraving_text ? engraving_design?.engraving_text : custom_design?.engraving_text}</span>
                </div>
              </div>
            </div>
            <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-col'>
              <div className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp'>
                <label className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label'>{t("Position")}</label>
                <input className='create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-field com-input-field' placeholder='Which position would you like to engraving ?' defaultValue={engraving_design?.logo_position ? engraving_design?.logo_position : custom_design?.logo_position} onChange={(e) => setEngraving_design({ ...engraving_design, logo_position: e.target.value })} />
              </div>
            </div>
          </div>
        </div>
        {engraving_edit ?
          <button className='create-own-setting-right-sketch-area-inner-engraving-finish-btn com-btn'
            onClick={() => {
              setShowseqs(3);
              setEngraving(engraving_design);
              setshowcolor(false);
              edit_cart(engraving_design)
            }}>
            {t("Next")}
          </button>
          :
          editproduct ?
            <button className='create-own-setting-right-sketch-area-inner-engraving-finish-btn com-btn'
              onClick={() => {
                setShowseqs(3);
                setEngraving(engraving_design);
                setshowcolor(false)
              }}>
              {t("Next")}
            </button>
            :
            <button className='create-own-setting-right-sketch-area-inner-engraving-finish-btn com-btn'
              onClick={() => {
                setShowseqs(2);
                setEngraving(engraving_design);
                setshowcolor(false)
              }}>
              {t("Next")}
            </button>}
      </div >
    </>
  )
}

export default CommonForm;