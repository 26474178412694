import React from 'react'

export default function MinusIcon(props) {
    const { width, height, color } = props
    return (
        <svg width={width ? width : "16"} height={height ? height : "16"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3144_12824)">
                <path fillRule="evenodd" clipRule="evenodd" d="M22 13C22 13.5523 21.5523 14 21 14H1C0.447715 14 0 13.5523 0 13C0 12.4477 0.447716 12 1 12H21C21.5523 12 22 12.4477 22 13Z" fill={color ? color : "#292D32"} />
                <path fillRule="evenodd" clipRule="evenodd" d="M22 13C22 13.5523 21.5523 14 21 14L0.999999 14C0.447715 14 -1.95703e-08 13.5523 -4.37114e-08 13C-6.78525e-08 12.4477 0.447714 12 0.999998 12L21 12C21.5523 12 22 12.4477 22 13Z" fill={color ? color : "#292D32"} />
            </g>
            <defs>
                <clipPath id="clip0_3144_12824">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
