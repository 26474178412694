import React from 'react'

export default function Ring({color}) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.125 12.3878C5.125 10.8844 5.59263 9.63155 6.52788 8.62926C7.46313 7.62121 8.6296 7.11719 10.0273 7.11719C11.3834 7.11719 12.5291 7.62697 13.4643 8.64654C14.4048 9.66611 14.875 10.9132 14.875 12.3878C14.875 13.8913 14.4022 15.1614 13.4565 16.1983C12.5109 17.2351 11.3548 17.7536 9.98831 17.7536C8.627 17.7536 7.47612 17.2351 6.53567 16.1983C5.59522 15.1614 5.125 13.8913 5.125 12.3878ZM6.52788 12.4051C6.52788 13.8855 6.84482 15.0837 7.47872 15.9995C8.11261 16.9097 8.94394 17.3647 9.97272 17.3647C11.0119 17.3647 11.8536 16.8981 12.4979 15.965C13.1474 15.0318 13.4721 13.8682 13.4721 12.4743C13.4721 10.9766 13.1552 9.77556 12.5213 8.87119C11.8874 7.96107 11.0664 7.50601 10.0585 7.50601C8.98811 7.50601 8.1308 7.95243 7.48651 8.84527C6.84742 9.73812 6.52788 10.9247 6.52788 12.4051Z" fill={color ? color :"#A3A3A3"}/>
        <path d="M9.93821 2.24219L10.5139 6.23082H9.3625L9.93821 2.24219Z" fill={color ? color :"#A3A3A3"}/>
        <path d="M13.7675 3.29257L12.3836 6.88459L10.9992 6.22132L13.7675 3.29257Z" fill={color ? color :"#A3A3A3"}/>
        <path d="M6.10753 3.29257L7.49139 6.88459L8.87576 6.22132L6.10753 3.29257Z" fill={color ? color :"#A3A3A3"}/>
    </svg>
  )
}
