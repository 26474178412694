import React, { useState, useEffect, useCallback } from 'react'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const PurchaseOrder = (props) => {
  const { setDashboardhideshow, setDefaultset } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const { t } = useTranslation();
  const [postatics, sePostatics] = useState()
  const [completeDataObject, setCompliteDataObject] = useState({
    timeset: "Monthly",
  })
  let time_periods = ['Daily', 'Monthly', 'Yearly', 'Weekly']
  const pochart = useCallback(async () => {
    try {
      let postdata = {
        duration: completeDataObject?.timeset
      }
      let res = await _Api(postdata, `api/v1/dashboard/poStatics`)
      if (res?.code === 200) {
        sePostatics(res?.statics)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [completeDataObject]);
  useEffect(() => {
    pochart()
  }, [completeDataObject, pochart])
  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Purchase Order")}
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t(`${completeDataObject?.timeset}`)}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              {time_periods?.length ? time_periods.map((result, key) => {
                return (
                  <li key={key}
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        timeset: result
                      }))}>
                    <span className="dropdown-item">{t(`${result}`)}</span>
                  </li>
                )
              }) : ""}
            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li
                onClick={() => {
                  setDashboardhideshow(prevState => ({
                    ...prevState,
                    PurchaseOrder: 0
                  }));
                  setDefaultset(true)
                }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area py-3">
          <div className="dashboard-area-cards-single-chart-area-progress">
            <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto text-center">
              {t("Total Order")} <br /> <span className="brown-color"> {postatics?.purcahseOrder}</span>
            </div>
            <div className="dashboard-area-cards-single-chart-area-progress-single mt-4">
              <label>{t("Order in Progress")}</label>
              <div className="d-flex align-items-center w-100">
                <div className="progress w-85">
                  <div
                    className="progress-bar yellow-bg"
                    role="progressbar"
                    style={{ width: `${postatics?.ProgessSlide}%` }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <span className="ms-auto">{postatics?.POInProgess}</span>
              </div>
            </div>
            <div className="dashboard-area-cards-single-chart-area-progress-single mt-4">
              <label>{t("Completed")}</label>
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div className="progress w-85">
                  <div
                    className="progress-bar success-bg"
                    role="progressbar"
                    style={{ width: `${postatics?.CompleteSlide}%` }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <span className="ms-auto">{postatics?.POComplete}</span>
              </div>
            </div>
            <div className="dashboard-area-cards-single-chart-area-progress-single mt-4">
              <label>{t("Rejected")}</label>
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div className="progress w-85">
                  <div
                    className="progress-bar success-bg"
                    role="progressbar"
                    style={{ width: `${postatics?.CancelSlide}%` }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <span className="ms-auto">{postatics?.POCancel}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PurchaseOrder
