import React from 'react';
import { useTranslation } from 'react-i18next';
export const Importmodal = (props) => {

    const { t } = useTranslation();
    const { setImportfile, readExcel, importfile, SampleExcel, hideSample } = props
    return (
        <div
            className="modal fade import-modal"
            id="ImportModal"
            tabIndex={-1}
            aria-labelledby="ImportModalLabel"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header"
                        onClick={() => setImportfile()}>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="import-modal-choose-file">
                            {t("choose file")}
                            <input
                                type="file"
                                onChange={(e) => {
                                    const selectedFile = e.target.files[0];
                                    const allowedExtensions = !hideSample ? ['.xls', '.xlsx'] :
                                        ['.xls', '.xlsx', '.zip'];
                                    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

                                    if (!allowedExtensions.includes('.' + fileExtension)) {
                                        alert("Please select an Excel file.");
                                        e.target.value = '';
                                    } else {
                                        setImportfile(selectedFile);
                                    }
                                }}
                                name="files"
                                accept={!hideSample ? ".xls, .xlsx" : "xls, .xlsx, .zip"}
                            />
                        </div>
                        <div className="import-modal-file  w-100">
                            {importfile?.name}
                        </div>
                    </div>
                    <div id="clickonme" className="modal-footer">
                        <button
                            type="button"
                            onClick={() => { readExcel(); setImportfile() }}
                            data-bs-dismiss="modal"
                            className="btn modal-content-yes"
                        >
                            {t("Submit")}
                        </button>
                        {!hideSample &&
                            <button
                                onClick={() => { SampleExcel(); setImportfile() }}
                                id="download"
                                type="button"
                                data-bs-dismiss="modal"
                                className="btn modal-content-yes"
                            >
                                {t("Download Sample")}
                            </button>}

                    </div>
                </div>
            </div>
        </div>
    )
};
export default Importmodal;
