import React, { useEffect, useRef, useState } from "react";

import AccordianInv from "./Accordian-inv";
import AccordianPos from "./Accordian-pos ";
import BrochureModel from "./BrochureModel";
import brochureBtn from "./assets/images/brochure-downloadbtn.png";
import brochureImg1 from "./assets/images/brochure-img1.png";
import brochureImg2 from "./assets/images/brochure-img2.png";
import brochureImg3 from "./assets/images/brochure-img3.png";
import unionfeatImg from "./assets/images/Union-feat.svg";
import unionSecond from "./assets/images/Union-second.svg";

const Tabfeature = (props) => {
  const { countries } = props
  const carouselRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentSlide = carouselRef.current.querySelector(".active");
      const nextSlide =
        currentSlide.nextElementSibling ||
        carouselRef.current.firstElementChild;

      currentSlide.classList.remove("active");
      nextSlide.classList.add("active");
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const [activeTab, setActiveTab] = useState("home");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div>
        <div className="radio-buttons feature-radio-box">
          <div>
            <input
              type="radio"
              id="pills-home"
              name="tab-selector"
              checked={activeTab === "home"}
              onChange={() => handleTabChange("home")}
              className="d-none"
            />
            <label
              htmlFor="pills-home"
              className={`tab-label feature-radio-box-single ${activeTab === "home" ? " active" : ""}`}
              style={{
                color: activeTab === "home" ? "#2B486C" : "#C0C0C0",
              }}
            >
              {activeTab === "home" && <span className="circle"></span>}
              Inventory
            </label>
          </div>

          <div>
            <input
              type="radio"
              id="pills-profile"
              name="tab-selector"
              checked={activeTab === "profile"}
              onChange={() => handleTabChange("profile")}
              className="d-none"// Hide the default radio button
            />
            <label
              htmlFor="pills-profile"
              className={`tab-label feature-radio-box-single ${activeTab === "profile" ? " active" : ""}`}
              style={{
                color: activeTab === "profile" ? "#2B486C" : "#C0C0C0",
              }}
            >
              {activeTab === "profile" && <span className="circle"></span>}
              POS
            </label>
          </div>
        </div>

        <div className="faq-flex features-main">
          <div className=" features-main-left-image-box feature-union-img">
            <div className="features-main-left-image-box-first">
              <img
                src={unionfeatImg}
                alt="First slide"
              />
            </div>
            <div className="brochure">
              <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner" ref={carouselRef}>
                  <div className="carousel-item active">
                    <img
                      src={brochureImg1}
                      alt="First slide"
                      className="brochure-img"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={brochureImg2}
                      alt="Second slide"
                      className="brochure-img"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={brochureImg3}
                      alt="Third slide"
                      className="brochure-img"
                    />
                  </div>
                </div>
              </div>

              <div className="brochure-button" >

                <button
                  className="brochure-button-inner"
                  data-bs-toggle="modal"
                  data-bs-target="#brochureDownloadModal"
                >
                  <span className="brochure-button-inner-img-box"><img src={brochureBtn} alt="" /></span>
                  Download Brochure
                </button>
              </div>
            </div>
          </div>

          <div className="faq-flex-inner-box">
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade show ${activeTab === "home" ? "active" : ""
                  }`}
                id="pills-home"
                role="tabpanel"
              >
                <AccordianInv />
              </div>

              <div
                className={`tab-pane fade show ${activeTab === "profile" ? "active" : ""
                  }`}
                id="pills-profile"
                role="tabpanel"
              >
                <AccordianPos />
              </div>
            </div>
          </div>

          <div className="last-union-image">
            <img
              src={unionSecond}
              alt="First slide"
            />
          </div>
        </div>
      </div>
      <BrochureModel countries={countries} />
    </>
  );
};

export default Tabfeature;
