import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Report.scss'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import { loading, Updateloading } from '../common/helpers/toastify'
import SocialModal from '../../components/common/modules/SocialModal/SocialModal'
import { _Api } from '../common/api/_call'
import { Reportmenupos } from './Reportmenupos'
import { Daterangepos } from '../common/Daterangepos'
import { Tablehead } from '../../components/common/modules/Tablehead'
import { poscustomorderreport_head } from '../../components/common/Tablehead'
import TableModal from '../common/Modals/tableColumnPos'
import { useTranslation } from 'react-i18next'
import { Pagination } from '../common/Pagination'
import { toFormatPrice } from '../common/helpers/function'
import * as FileSaver from 'file-saver'
import { useSelector } from 'react-redux'
import Select from "react-select";
import * as XLSX from 'xlsx'
const Customorderreport = () => {
  const { t } = useTranslation()
  const [newcolumndata, setnewcolumndata] = useState(poscustomorderreport_head)
  const [datefilter, setDatefilter] = useState([])
  const [totalresult, setTotalresult] = useState()
  const [socialurl, setSocialurl] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [, setfilterby] = useState('')
  const [reportpolist, setReportpolist] = useState([])
  const [filtername, setFiltername] = useState('')
  const [limit, setLimit] = useState(100)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [reportpocounts, setReportpocounts] = useState([])
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const [printparmission, setPrintparmission] = useState(0)
  const [editparmission, setEditparmission] = useState(0)

  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission)
  let showseqs = 2
  useEffect(() => {
    if (parmissiondatashow?.pos_reports?.View)
      setPrintparmission(parmissiondatashow?.pos_reports?.Print)
    setEditparmission(parmissiondatashow?.pos_reports?.Edit)
  }, [parmissiondatashow])
  const getsharecustomorederreport = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/CustomOrder`)

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const getprintcustomorderreport = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/CustomOrder`)

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'report_customorderreport_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const filterbyname = (search) => {
    getcustomorderreport(currentPage, limit, search)
    setstate({ ...state, currentPage: 1 })
  }
  const getlimit = (limit) => {
    setLimit(limit)
    getcustomorderreport(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })

  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    getcustomorderreport(current, '')
  }
  const getcustomorderreport = async (current, firstlimit, search) => {
    UnSetdataTable('report_customorderreport')
    try {
      const postdata = {
        search: search ? search : "",
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
      }
      let listdata = await _Api(postdata, `api/v1/POS/report/customOrderreport`)
      if (listdata.code === 200) {
        setReportpolist(listdata?.data)
        setTimeout(() => {
          SetdataTable('report_customorderreport', 6, newcolumndata, [])
        }, 50)
        setstate({
          ...state,
          totalPages: Math.ceil(listdata?.count / postdata.limit),
          currentPage: current ? current : 1,
        })
        setReportpocounts(listdata?.uppercount)
        setTotalresult(listdata?.lowercount)
      } else {
        SetdataTable('report_customorderreport', 6, newcolumndata, [])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      getcustomorderreport()
    }
    // eslint-disable-next-line
  }, [newcolumndata, datefilter])
  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []

    await Promise.all(
      poscustomorderreport_head.map(async (r, k) => {
        header_values.push(r?.lable)
      }),
    )

    if (reportpolist.length) {
      await Promise.all(
        reportpolist.map(async (result, key) => {
          bodyarray.push([
            key + 1,
            result?.date,
            result?.order_no,
            result?.SKU,
            result?.stock_id,
            result?.metal_name,
            result?.stone_name,
            result?.size_name,
            result?.qty,
            result?.final_totals,
            result?.labour,
            result?.amount,
            result?.payment,
            result?.outstanding,
            result?.delivery_date,
            result?.location_name,
            result?.customer_name,
            result?.sales_person,
            result?.status?.cart_status === 1
              ? 'Open Order'
              : result?.status?.cart_status === 2
                ? 'Production'
                : result?.status?.cart_status === 3
                  ? 'MFG Stock'
                  : result?.status?.cart_status === 4
                    ? 'transit'
                    : result?.status?.cart_status === 5
                      ? 'Stock'
                      : result?.status?.cart_status === 6
                        ? 'Sold Out'
                        : result?.status?.cart_status === 9
                          ? 'Close Order'
                          : '',
          ])
        }),
      )
    }

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'report_customorderreport')
  }

  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 w-100">
            <div className="catalog-area bg-white page-menu-block Dailysalereport">
              <div className="Dailysalereport-header">
                <div className="Dailysalereport-header-left">
                  <h3 className="common-navigation-header-left-heading Dailysalereport-head">
                    {' '}
                    <Link
                      to=""
                      className="gray-mid text-decoration-none Dailysalereport-head-report"
                    >
                      {t('REPORT')}
                    </Link>{' '}
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'}
                      alt=""
                    />
                    <span className="Dailysalereport-head-pagename">
                      {t('Custom Order Report')}
                    </span>{' '}
                  </h3>
                </div>
                <div className="Dailysalereport-header-reportdetail">
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report/stock-img.png'}
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('Total Qty')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data lightblue">
                        {reportpocounts?.totalQty
                          ? reportpocounts?.totalQty
                          : 0}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report/sku-img.png'}
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('SKU')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data yellow-text">
                        {reportpocounts?.SKU ? reportpocounts?.SKU : 0}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={
                          base_assets + 'images/pos/report/customer-green.png'
                        }
                        alt=""
                      />
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('Total Customer')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data maingreen">
                        {reportpocounts?.customer
                          ? reportpocounts?.customer
                          : '0'}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report/Outstanding.png'}
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('Remaining Balance')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-value lightgray">
                        {toFormatPrice(reportpocounts?.outstanding, {
                          addSymbol: true,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Dailysalereport-content-area">
                <div className="Dailysalereport-content-area-header">
                  <div className="Dailysalereport-content-area-header-inner">
                    <Reportmenupos
                      Reportname={"Custom Order Report"} />
                    <Select
                      className="limit-select short mx-3"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); getlimit(e.value); }}
                    />
                    <div className="date">
                      {' '}
                      <Daterangepos setDatefilter={setDatefilter} />
                    </div>

                    <ul className="Dailysalereport-content-area-header-inner-indicator">
                      <li className="Dailysalereport-content-area-header-inner-indicator-li">
                        <span className="Dailysalereport-content-area-header-inner-indicator-color bg-danger"></span>
                        {t('Create your own')}
                      </li>
                      <li className="Dailysalereport-content-area-header-inner-indicator-li">
                        <span className="Dailysalereport-content-area-header-inner-indicator-color bg-yellowcolor"></span>
                        {t('Mix & Match')}
                      </li>
                    </ul>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <input
                        type="text"
                        value={filtername}
                        placeholder={t(
                          'Search  by Ref number, voucher type, ...',
                        )}
                        onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                        onChange={(e) => setFiltername(e.target.value)}
                      />
                      {filtername ? (
                        <img
                          className="com-search-area-clear"
                          src={base_assets + 'images/icons/False.png'}
                          onClick={(e) => { setFiltername(''); filterbyname("") }}
                          alt=""
                        />
                      ) : (
                        ''
                      )}
                      <img
                        onClick={() => filterbyname(filtername)}
                        className="com-search-area-img"
                        src={
                          base_assets + 'images/pos/icons/magnifying-glass.png'
                        }
                        alt=""
                      />
                    </div>
                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                      <button
                        className="btn-style"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            base_assets + 'images/icons/ellipsis-circular.png'
                          }
                          alt=""
                        />
                      </button>
                      <ul
                        className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          className={
                            printparmission
                              ? 'dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item'
                              : 'd-none'
                          }
                          onClick={(e) => {
                            getprintcustomorderreport(e.target.value)
                          }}
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={
                                base_assets + 'images/pos/report/print-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          {t('Print')}
                        </li>
                        <li
                          className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                          onClick={(e) => {
                            downloadexclsheet(e.target.value)
                          }}
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={
                                base_assets +
                                'images/pos/report/export-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          {t('Export')}
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#socialModal"
                          className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                          onClick={() => getsharecustomorederreport()}
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={
                                base_assets + 'images/pos/report/share-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          {t('Share')}
                        </li>
                        <li
                          className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                          data-bs-toggle="modal"
                          data-bs-target="#columnModal"
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={base_assets + 'images/icons/column-icon.png'}
                              alt=""
                            />
                          </div>
                          {t('Column')}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="Dailysalereport-content-area-main">
                  <div
                    className="main-body-main-table-wrap Reservereport-table"
                    id="table-scroll"
                  >
                    <table
                      id="report_customorderreport"
                      className="common-table first-row-gray w-100 Reservereport-table-main"
                    >
                      <Tablehead
                        tablehead={poscustomorderreport_head}
                        tblname={'report_customorderreport_tbl'}
                        setfilterby={setfilterby}
                      />
                      <tbody>
                        <>
                          {reportpolist
                            ? reportpolist.map((result, key) => {
                              return (
                                <tr
                                  key={key}
                                  className={
                                    result?.color === 'RED'
                                      ? 'tr-left-red-border'
                                      : 'tr-left-yellow-border'
                                  }
                                >
                                  <td>{key + 1}</td>
                                  <td>{result?.date ? result?.date : ''}</td>
                                  <td className="link-color">
                                    {result?.order_type === 'custom_order' ? (
                                      result?.status?.cart_status === 9 ? (
                                        <>
                                          {' '}
                                          {result?.order_no
                                            ? result?.order_no
                                            : ''}
                                        </>
                                      ) : (
                                        <Link
                                          className="link-color text-decoration-none"
                                          to={
                                            editparmission
                                              ? '/app/pos/custom/createYourOwnSetting'
                                              : ''
                                          }
                                          state={{
                                            order_id: result?.order_id,
                                            order_edit: true,
                                            order_type: result?.order_type,
                                          }}
                                        >
                                          {' '}
                                          {result?.order_no
                                            ? result?.order_no
                                            : ''}
                                        </Link>
                                      )
                                    ) : result?.order_type ===
                                      'mix_and_match' ? (
                                      <Link
                                        to={
                                          editparmission
                                            ? '/app/pos/custom/mixAndMatchProduct'
                                            : ''
                                        }
                                        className="text-decoration-none"
                                        state={{
                                          order_id: result?.order_id,
                                          order_edit: true,
                                          order_type: result?.order_type,
                                          fromring: showseqs ? true : false,
                                          Engraving: "engraving_design",
                                          daimond_id:
                                            result?.editOrder?.daimond_id,
                                          product_id:
                                            result?.editOrder?.product_id,
                                          customer_id:
                                            result?.editOrder?.customer_id,
                                          type: result?.editOrder?.type,
                                        }}
                                      >
                                        {result?.order_no
                                          ? result?.order_no
                                          : ''}
                                      </Link>
                                    ) : result?.order_no ? (
                                      result?.order_no
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td className="link-color">
                                    {result?.SKU ? result?.SKU : ''}
                                  </td>
                                  <td>
                                    {result?.stock_id ? result?.stock_id : ''}
                                  </td>
                                  <td>
                                    {result?.metal_name
                                      ? result?.metal_name
                                      : ''}
                                  </td>
                                  <td>
                                    {result?.stone_name
                                      ? result?.stone_name
                                      : ''}
                                  </td>
                                  <td>
                                    {result?.size_name
                                      ? result?.size_name
                                      : ''}
                                  </td>
                                  <td>{result?.qty ? result?.qty : ''}</td>
                                  <td>
                                    {toFormatPrice(result?.base_amount, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td>
                                    {toFormatPrice(result?.labour, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td>
                                    {toFormatPrice(result?.amount, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td className="main-green-text">
                                    {toFormatPrice(result?.payment, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td className="danger-text">
                                    {toFormatPrice(result?.outstanding, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td>
                                    {result?.delivery_date
                                      ? result?.delivery_date
                                      : ''}
                                  </td>
                                  <td>
                                    {result?.location_name
                                      ? result?.location_name
                                      : ''}
                                  </td>
                                  <td>
                                    {result?.customer_name
                                      ? result?.customer_name
                                      : ''}
                                  </td>
                                  <td>
                                    {result?.sales_person
                                      ? result?.sales_person
                                      : ''}
                                  </td>
                                  <td>
                                    {result?.status?.cart_status === 1 ? (
                                      <label className="status-tab open-order-bg">
                                        {t('Open Order')}
                                      </label>
                                    ) : result?.status?.cart_status === 2 ? (
                                      <label className="status-tab production-col">
                                        {t('Production')}
                                      </label>
                                    ) : result?.status?.cart_status === 3 ? (
                                      <label className="status-tab mgf-stock-bg">
                                        {t('MFG Stock')}
                                      </label>
                                    ) : result?.status?.cart_status === 4 ? (
                                      <label className="status-tab transit-bg-col">
                                        {t('transit')}{' '}
                                      </label>
                                    ) : result?.status?.cart_status === 5 ? (
                                      <label className="status-tab stoke-bg">
                                        {t('Stock')}
                                      </label>
                                    ) : result?.status?.cart_status === 6 ? (
                                      <label className="status-tab sol-out-col">
                                        {t('Sold Out')}{' '}
                                      </label>
                                    ) : result?.status?.cart_status === 9 ? (
                                      <label className="status-tab gray-tab">
                                        {t('Close Order')}
                                      </label>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              )
                            })
                            : ''}
                        </>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {totalresult?.totalQty ? totalresult?.totalQty : ''}
                          </td>
                          <td></td>
                          <td>
                            {totalresult?.labour ? totalresult?.labour : ''}
                          </td>
                          <td>
                            {totalresult?.amount ? totalresult?.amount : ''}
                          </td>
                          <td className="main-green-text">
                            {totalresult?.payment ? totalresult?.payment : ''}{' '}
                          </td>
                          <td className="danger-text">
                            {' '}
                            {totalresult?.outstanding
                              ? totalresult?.outstanding
                              : ''}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                {reportpolist.length > 0 ? (
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePaginations(crPage)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocialModal shareUrl={socialurl} shareloader={shareloader} />
      <TableModal
        tablehead={poscustomorderreport_head}
        tblName={'report_customorderreport_tbl'}
        setnewcolumndata={setnewcolumndata}
      />
    </>
  )
}
export default Customorderreport
