import React, { useState, useEffect, useRef } from 'react';
import { _Api } from '../api/_call';
import TableColumnModal from '../common/modules/tableColumn';
import { Tablehead } from '../common/modules/Tablehead';
import {
  error,
  loading,
  Updateloading,
} from '../common/helpers/toastify';
import { Pagination } from "../common/Pagination";
import DateTime from '../common/DateTime';
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import { DateRange } from '../common/helpers/daterange';
import DatePicker from 'react-datepicker';
import SocialModal from '../common/modules/SocialModal/SocialModal';
import Select from "react-select";
import {
  MultiLocationList,
  SupplierList,
  VoucherList,
} from '../common/graphql/commonlayouts';
import { inventorylowstockplanlisthead } from '../common/Tablehead';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const LowStockPlanning = (props) => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const datePickerRef = useRef(null)
  const [limit, setLimit] = useState(100)
  const [startdate, setStartdate] = useState()
  const [enddate, setEnddate] = useState()
  const [multisetcommanSelected_loc, setMultisetcommanSelected_loc] = useState()
  const [datelable, setDatelable] = useState('Replenish')
  const [commanSelected_sup, setcommanSelected_sup] = useState([])
  const [commanSelected_vouc, setcommanSelected_vouc] = useState([])
  const [datefilter, setDatefilter] = useState([])
  const [filtername, setFiltername] = useState('')
  const [lowstocklist, setLowstocklist] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(
    inventorylowstockplanlisthead,
  )
  const [shareloader, setShareloader] = useState(false)
  const [socialurl, setSocialurl] = useState()
  const [lowstockcounts, setLowstockcounts] = useState([])
  const [, setfilterby] = useState('')
  const [SelectAllStatus, setSelectAllStatus] = useState()
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  let Permission = parmissiondatashow?.Low_Stock_Planning;
  const [selecteditem, setSelecteditem] = useState([]);
  useEffect(() => {
    callcolumns()
  }, [])
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'Low_stock_planning_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getlowstockplanning = async (firstlimit) => {
    let locationarray = []
    if (multisetcommanSelected_loc?.length) {
      multisetcommanSelected_loc.forEach((result) => {
        locationarray.push(result?.value)
      })
    }
    try {
      if (!commanSelected_vouc) {
        error(t('Please select voucher'))
        return
      }
      if (!commanSelected_sup) {
        error(t('Please select Supplier'))
        return
      }
      let GetloadingID = loading()
      const postdata = {
        location: locationarray,
        supplier: commanSelected_sup,
        skip: '0',
        limit: firstlimit ? firstlimit : limit,
        start_date: startdate ? startdate : new Date(),
        end_date: enddate ? enddate : new Date(),
        voucher_id: commanSelected_vouc,
      }
      let reslowstock = await _Api(
        postdata,
        `api/v1/inventory/my/lowstockCreate`,
      )
      if (reslowstock.code === 200) {
        setTimeout(() => {
          Updateloading(t, GetloadingID, t('Successfully Run'))
          setcommanSelected_sup([])
          setMultisetcommanSelected_loc([])
          setDatelable('Replenish')
          getlowstocklist()
        })
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const changeDates = (lable) => {
    const MainDate = new Date()
    if (lable === 'Today') {
      setStartdate(new Date())
      setEnddate(new Date())
      setDatelable('Today')
    } else if (lable === 'Yesterday') {
      const yesterdaydate = new Date(MainDate.setDate(MainDate.getDate() - 1))
      setStartdate(yesterdaydate)
      setEnddate(yesterdaydate)
      setDatelable('Yesterday')
    } else if (lable === '7days') {
      const sevendays = new Date(MainDate.setDate(MainDate.getDate() - 7))
      setStartdate(sevendays)
      setEnddate(new Date())
      setDatelable('7days')
    } else if (lable === '30days') {
      const days = new Date(MainDate.setDate(MainDate.getDate() - 30))
      setStartdate(days)
      setEnddate(new Date())
      setDatelable('30days')
    } else if (lable === 'ThisMonth') {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 1)
      const lastDay = new Date(
        MainDate.getFullYear(),
        MainDate.getMonth() + 1,
        0,
      )
      setStartdate(firstDay)
      setEnddate(lastDay)
      setDatelable('ThisMonth')
    } else if (lable === 'LastMonth') {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth() - 1, 1)
      const lastDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 0)
      setStartdate(firstDay)
      setEnddate(lastDay)
      setDatelable('LastMonth')
    }
  }
  const grtprintlowstock = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/lowstock`)
      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }

  const filterbyname = (search) => {
    getlowstocklist(currentPage, limit, search);
    setstate({ ...state, currentPage: 1 });
  };
  const getlimit = (limit) => {
    setLimit(limit);
    getlowstocklist(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getlowstocklist(current, "");

  };
  const getlowstocklist = async (current, firstlimit, search) => {
    UnSetdataTable('low_stock_table')
    try {
      const postdata = {
        search: search ? search : "",
        skip: current ? (current - 1) * limit : 0,
        limit: firstlimit ? firstlimit : limit,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/inventory/my/lowstocklist`)
      if (res?.code === 200) {
        setLowstocklist(res?.data)
        setTimeout(() => {
          SetdataTable('low_stock_table', 4, newcolumndata, [0])
          setstate({
            ...state,
            totalPages: Math.ceil(res?.total / postdata.limit),
            currentPage: current ? current : 1
          });
        }, 50)
        setLowstockcounts(res?.count)
      } else {
        SetdataTable('low_stock_table', 4, newcolumndata, [0])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const getsharelowstock = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/lowstock`)
      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      getlowstocklist()
    }
    // eslint-disable-next-line
  }, [datefilter])
  const downloadexclsheet = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        id: selecteditem?.length ? selecteditem : []
      }
      let res = await _Api(postdata, `api/v1/Inventory/my/lowstock-export`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, (res?.message), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const selectedproduct = (isChecked, id) => {
    if (isChecked) {
      setSelecteditem(prevSelected => [...prevSelected, id]);
    } else {
      setSelecteditem(prevSelected => prevSelected.filter(item => item !== id));
    }
  };
  useEffect(() => {
    if (SelectAllStatus) {
      if (lowstocklist?.length) {
        lowstocklist.forEach((result) => {
          setSelecteditem(prevSelected => [...prevSelected, result?._id]);
        })
      }
    } else {
      setSelecteditem([])
    }
    // eslint-disable-next-line
  }, [SelectAllStatus])
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area-left">
                <VoucherList
                  setcommanSelected_vouc={setcommanSelected_vouc}
                  group={'Low_Stock_Planning'}
                ></VoucherList>
              </div>
              <h1 className="main-body-heading">{t('Low Stock Planning')}</h1>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/free-stock.png'}
                        className="inventory-img"
                        alt="Stock"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Total Qty')}</p>
                      <h2 className="inventory-value stock">
                        {lowstockcounts?.totalStock
                          ? lowstockcounts?.totalStock
                          : '0'}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/sku-red.png'}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Sale Order')}</p>
                      <h2 className="inventory-value danger-text">
                        {lowstockcounts?.totalSalesOrder
                          ? lowstockcounts?.totalSalesOrder
                          : '0'}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/location.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Locations')}</p>
                      <h2 className="inventory-value location">
                        {lowstockcounts?.locationcount
                          ? lowstockcounts?.locationcount
                          : '0'}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/value-green.png'}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Reorder Quantity')}</p>
                      <h2 className="inventory-value value">
                        {lowstockcounts?.totalRQ
                          ? lowstockcounts?.totalRQ
                          : '0'}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar low-stock-bar">
                <div className="common-select left-select">
                  <SupplierList setcommanSelected_sup={setcommanSelected_sup} />
                </div>
                <div className="product-multi-select">
                  <img
                    className="multi-select-place-img"
                    src={base_url_assets + 'images/icons/location-green.png'} alt=""
                  />

                  <div className="product-multi-select-box ">
                    <MultiLocationList
                      setMultisetcommanSelected_loc={
                        setMultisetcommanSelected_loc
                      }
                    />
                  </div>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <div className="main-body-top-status-bar-note-button common-dropdown replenish-date">
                    <button
                      className="dropdown-toggle btn-style align-items-center d-flex"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        className="me-2"
                        src={base_url_assets + 'images/icons/clock-icon.png'}
                        alt=""
                      />
                      {datelable}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className={`dropdown-item ${datelable === 'Today' || datelable === 'Replenish'
                          ? 'active'
                          : ''
                          }`}
                        onClick={() => changeDates('Today')}
                      >
                        {t('Today')}
                      </li>
                      <li
                        className={`dropdown-item ${datelable === 'Yesterday' ? 'active' : ''
                          }`}
                        onClick={() => changeDates('Yesterday')}
                      >
                        {t('Yesterday')}
                      </li>
                      <li
                        className={`dropdown-item ${datelable === '7days' ? 'active' : ''
                          }`}
                        onClick={() => changeDates('7days')}
                      >
                        {t('Last 7 days')}
                      </li>
                      <li
                        className={`dropdown-item ${datelable === '30days' ? 'active' : ''
                          }`}
                        onClick={() => changeDates('30days')}
                      >
                        {t('Last 30 days')}
                      </li>
                      <li
                        className={`dropdown-item ${datelable === 'ThisMonth' ? 'active' : ''
                          }`}
                        onClick={() => changeDates('ThisMonth')}
                      >
                        {t('This Month')}
                      </li>
                      <li
                        className={`dropdown-item ${datelable === 'LastMonth' ? 'active' : ''
                          }`}
                        onClick={() => changeDates('LastMonth')}
                      >
                        {t('Last Month')}
                      </li>
                      <li
                        className={`dropdown-item ${datelable === 'Custom Range' ? 'active' : ''
                          }`}
                        onClick={() => datePickerRef.current.setOpen(true)}
                      >
                        {t('Custom Range')}
                      </li>
                    </ul>
                    <DatePicker
                      selectsRange={true}
                      monthsShown={2}
                      startDate={startdate}
                      endDate={enddate}
                      onChange={(update) => {
                        setStartdate(update[0])
                        setEnddate(update[1])
                        setDatelable('Custom Range')
                      }}
                      dateFormat="yyyy-MM-dd"
                      ref={datePickerRef}
                      autoComplete='none'
                    />
                  </div>
                  <button
                    className={Permission?.Add ? "com-btn" : "d-none"}
                    onClick={() => {
                      getlowstockplanning()
                    }}
                  >
                    {t('Run')}
                  </button>
                </div>
              </div>

              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder="TF No./StockID/SKU/Metal/Stone"
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={(e) => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className={Permission?.Print ? "dropdown-item print-dropdown-item" : "d-none"}
                        onClick={() => grtprintlowstock()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li className="dropdown-item" onClick={(e) => {
                        downloadexclsheet(e.target.value)
                      }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#socialModal"
                        onClick={() => getsharelowstock()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/share-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('share')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="main-body-main-table-wrap">
                <table
                  id="low_stock_table"
                  className="common-table first-row-gray w-100"
                >
                  <Tablehead
                    tablehead={inventorylowstockplanlisthead}
                    tblname={'Low_stock_planning_tbl'}
                    setfilterby={setfilterby}
                    setShowoption={setSelectAllStatus}
                  />
                  <tbody>
                    <>
                      {lowstocklist.length
                        ? lowstocklist?.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td className="">
                                <div className="com-check">
                                  <input
                                    id={result?._id}
                                    className="lowstockHead"
                                    checked={selecteditem.includes(result?._id)}
                                    onChange={(e) => selectedproduct(e.target.checked, result?._id)}
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor={result?._id}
                                    className="com-check-label"
                                  />
                                </div>
                              </td>
                              <td>{result?.date ? result?.date : '0'}</td>
                              <td>{result?.ls_no ? result?.ls_no : '0'}</td>
                              <td>
                                {result?.supplier_name
                                  ? result?.supplier_name
                                  : '0'}
                              </td>
                              <td className="low-stock-col">
                                {result?.stores ? result?.stores : '0'}
                              </td>
                              <td>{result?.type ? result?.type : '0'}</td>
                              <td>
                                {result?.SKUcount ? result?.SKUcount : '0'}
                              </td>
                              <td className="light-blue-text">
                                {result?.stock ? result?.stock : '0'}
                              </td>
                              <td className="danger-text">
                                {result?.sales_order
                                  ? result?.sales_order
                                  : '0'}
                              </td>
                              <td className="main-green-text">
                                {result?.available ? result?.available : '0'}
                              </td>
                              <td className="yellow-text">
                                {result?.reorderpoint
                                  ? result?.reorderpoint
                                  : '0'}
                              </td>
                              <td className="status-green-text">
                                {result?.reorderquantity
                                  ? result?.reorderquantity
                                  : '0'}
                              </td>
                            </tr>)
                        }) : ''}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            {lowstocklist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
      </div>
      <TableColumnModal
        tablehead={inventorylowstockplanlisthead}
        tblName={'Low_stock_planning_tbl'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={getlowstocklist}
      ></TableColumnModal>
    </React.Fragment>
  )
}
export default LowStockPlanning;
