import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { _Api } from '../../api/_call'
import 'datatables.net-fixedcolumns'
import { useTranslation } from 'react-i18next'
import Select from "react-select"
import FilterCustomerModal from '../../common/modules/FilterCustomerModal'
import { Pagination } from '../../common/Pagination'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import { toFormatPrice } from '../../common/helpers/function'
import RightArrow from '../../common/icons/right-arrow'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import '../customers.scss'
import TableLoader from '../../common/TableLoader'
const CustomersReport = (props) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [customercount, setCustomercount] = useState()
  const [getcustomerlist, setCustomerlist] = useState([])
  const [filtername, setFiltername] = useState('')
  const [limit, setLimit] = useState(100);
  const [Namedata, setNamedata] = useState([])
  const [countrydata, setCountrydata] = useState([])
  const [citydata, setCitydata] = useState([])
  const [genderdata, setGenderdata] = useState([])
  const [groupdata, setGroupdata] = useState([])
  const [showloader, setShowloader] = useState(false)
  const [clearall, setClearall] = useState(false)
  const [maxAge, setMaxage] = useState([0, 100])

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const filterbyname = () => {
    getcustomerreport(currentPage, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const getlimit = (limit) => {
    setLimit(limit)
    getcustomerreport(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })
  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    getcustomerreport(current, '')
  }
  const getcustomerreport = async (current, firstlimit) => {
    setShowloader(true)
    UnSetdataTable('my_customer_report')
    try {
      const postdata = {
        search: filtername,
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
        name: Namedata,
        country: countrydata,
        city: citydata,
        maxAge: maxAge[1],
        minAge: maxAge[0],
      }
      let res = await _Api(
        postdata,
        `api/v1/Customer/customerReport/reportList`,
      )
      if (res?.code === 200) {
        setCustomerlist(res?.data)
        setCustomercount(res?.count)
        setShowloader(false)
        SetdataTable('my_customer_report', 4, [])
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1,
        })
      }
    } catch (err) {
      SetdataTable('my_customer_report', 4, [])
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getcustomerreport()
    // eslint-disable-next-line 
  }, [clearall])

  const [country, setCountry] = useState([])
  const [city, setCity] = useState([])
  const getcustomerfiltter = async () => {
    try {
      const postdata = {}
      let res = await _Api(postdata, `api/v1/App/customer/customerFilter`)
      if (res.code === 200) {
        setCountry(res?.data?.country)
        setCity(res?.data?.city)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getcustomerfiltter()
  }, [])

  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    header_values.push(
      '#',
      'image',
      'Customer',
      'Country',
      'City',
      'Sales Qty',
      'Sales Amount',
      'Custom Order Qty',
      'Custom Order Amount',
      'Mix & Match Qty',
      'Mix & Match Amount',
      'Repair Order Qty',
      'Repair Order Amount',
      'Exchange Qty',
      'Exchange Amount',
      'Refund Qty',
      'Refund Amount',
      'Wishlist Qty',
      'Wishlist Amount',
    )
    if (getcustomerlist.length) {
      getcustomerlist.forEach((result, key) => {
        bodyarray.push([
          key + 1,
          result?.profile_image,
          result?.name,
          result?.county,
          result?.city,
          result?.order_count?.pos_order?.Qty,
          result?.order_count?.pos_order?.Amount,
          result?.order_count?.custom_order?.Qty,
          result?.order_count?.custom_order?.Amount,
          result?.order_count?.mixMatch_order?.Qty,
          result?.order_count?.mixMatch_order?.Amount,
          result?.order_count?.repair_order?.Qty,
          result?.order_count?.repair_order?.Amount,
          result?.order_count?.exchange_order?.Qty,
          result?.order_count?.exchange_order?.Amount,
          result?.order_count?.refund_order?.Qty,
          result?.order_count?.refund_order?.Amount,
          result?.order_count?.wishlist_order?.Qty,
          result?.order_count?.wishlist_order?.Amount,
        ])
      })
    }

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'Customer Report List')
  }

  return (
    <React.Fragment>
      <div className="main-body main-content-wrapper-body customer-report-page">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
              <div className="main-content-wrapper-body-top-bar-left">
                <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                  {t('Customer')} <RightArrow /> <span>{t('Report')}</span>
                </h1>
              </div>
            </div>
            <div className="main-body-top-inventory">
              <div className="main-body-top-inventory-status customer-report-page-top-cards">
                <div className="customer-report-page-top-cards-single border-green">
                  <label className="customer-report-page-top-cards-single-heading fw-semibold">
                    {t('Sales')}
                  </label>
                  <div className="customer-report-page-top-cards-single-bottom">
                    <div className="customer-report-page-top-cards-single-bottom-box">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Pcs')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {customercount?.pos_order_qty}
                      </span>
                    </div>
                    <div className="customer-report-page-top-cards-single-bottom-box ms-auto text-end">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Amount')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {toFormatPrice(customercount?.pos_order_amount, {
                          addSymbol: true,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="customer-report-page-top-cards-single border-pink">
                  <label className="customer-report-page-top-cards-single-heading fw-semibold">
                    {t('Custom Order')}
                  </label>
                  <div className="customer-report-page-top-cards-single-bottom">
                    <div className="customer-report-page-top-cards-single-bottom-box">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Pcs')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {customercount?.custom_order_qty}
                      </span>
                    </div>
                    <div className="customer-report-page-top-cards-single-bottom-box ms-auto text-end">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Amount')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {toFormatPrice(customercount?.custom_order_amount, {
                          addSymbol: true,
                        })}{' '}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="customer-report-page-top-cards-single border-yelllow">
                  <label className="customer-report-page-top-cards-single-heading fw-semibold">
                    {t('Mix & Match')}
                  </label>
                  <div className="customer-report-page-top-cards-single-bottom">
                    <div className="customer-report-page-top-cards-single-bottom-box">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Pcs')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {customercount?.mixMatch_order_qty}
                      </span>
                    </div>
                    <div className="customer-report-page-top-cards-single-bottom-box ms-auto text-end">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Amount')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {toFormatPrice(customercount?.custom_order_amount, {
                          addSymbol: true,
                        })}{' '}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="customer-report-page-top-cards-single border-purple">
                  <label className="customer-report-page-top-cards-single-heading fw-semibold">
                    {t('Repair Order')}
                  </label>
                  <div className="customer-report-page-top-cards-single-bottom">
                    <div className="customer-report-page-top-cards-single-bottom-box">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Pcs')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {customercount?.repair_order_qty}
                      </span>
                    </div>
                    <div className="customer-report-page-top-cards-single-bottom-box ms-auto text-end">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Amount')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {toFormatPrice(customercount?.repair_order_amount, {
                          addSymbol: true,
                        })}{' '}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="customer-report-page-top-cards-single border-blue">
                  <label className="customer-report-page-top-cards-single-heading fw-semibold">
                    {t('Exchange')}
                  </label>
                  <div className="customer-report-page-top-cards-single-bottom">
                    <div className="customer-report-page-top-cards-single-bottom-box">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Pcs')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {customercount?.exchange_order_qty}
                      </span>
                    </div>
                    <div className="customer-report-page-top-cards-single-bottom-box ms-auto text-end">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Amount')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {toFormatPrice(customercount?.exchange_order_amount, {
                          addSymbol: true,
                        })}{' '}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="customer-report-page-top-cards-single border-sky">
                  <label className="customer-report-page-top-cards-single-heading fw-semibold">
                    {t('Refund')}
                  </label>
                  <div className="customer-report-page-top-cards-single-bottom">
                    <div className="customer-report-page-top-cards-single-bottom-box">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Pcs')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {customercount?.refund_order_qty}
                      </span>
                    </div>
                    <div className="customer-report-page-top-cards-single-bottom-box ms-auto text-end">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Amount')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {toFormatPrice(customercount?.refund_order_amount, {
                          addSymbol: true,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="customer-report-page-top-cards-single border-gray">
                  <label className="customer-report-page-top-cards-single-heading fw-semibold">
                    {t('Wishlist')}
                  </label>
                  <div className="customer-report-page-top-cards-single-bottom">
                    <div className="customer-report-page-top-cards-single-bottom-box">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Pcs')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {customercount?.wishlist_order_qty}
                      </span>
                    </div>
                    <div className="customer-report-page-top-cards-single-bottom-box ms-auto text-end">
                      <label className="customer-report-page-top-cards-single-bottom-box-label">
                        {t('Amount')}
                      </label>
                      <span className="customer-report-page-top-cards-single-bottom-box-span fw-semibold">
                        {toFormatPrice(customercount?.wishlist_order_amount, {
                          addSymbol: true,
                        })}{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-body-top-status-bar mb-3 mt-4 main-content-wrapper-body-top-status-bar main-content-wrapper-body-top-bar-right">
              <Select
                className="limit-select mx-3"
                options={selectOptions}
                value={{ label: limit, value: limit }}
                onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
              />
              <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center ms-auto">
                <div className="com-search-bar position-relative large">
                  <button
                    onClick={filterbyname}
                    className="main-body-top-status-bar-filter-search pe-1 h-auto"
                  >
                    <img
                      className="com-search-bar-search-img"
                      src={base_assets + 'images/admin/icons/gray-search.png'}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    className="h-100 border-0 bg-transparent"
                    placeholder="Search Customer"
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  <div
                    className="bg-transparent border-0 p-0 cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#filterCustomerModal"
                  >
                    <img
                      className="d-block com-search-bar-filter-icon"
                      src={base_assets + 'images/admin/icons/green-filter.png'}
                      alt=""
                    />
                  </div>
                </div>
                <button className="print-export-dropdown-selected-icon d-none">
                  <img
                    src={base_assets + 'images/icons/printer-icon.png'}
                    alt=""
                  />
                </button>
                <div className="print-export-dropdown dropdown">
                  <button
                    className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={base_assets + 'images/icons/ellipsis-circular.png'}
                      alt=""
                    />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {/* <li
                      className="dropdown-item print-dropdown-item"
                    >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/printer-icon.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('print')}</span>
                    </li> */}
                    <li className="dropdown-item"
                      onClick={() => downloadexclsheet()} >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/export-x-icon.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('export')}</span>
                    </li>
                    {/* <li
                      className="dropdown-item" 
                    >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/export.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('Import')}</span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer_report"
                className="com-custom-table-tag table align-middle"
              >
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th>#</th>
                    <th className="px-2"></th>
                    <th className="user-name-width">{t('Customer')}</th>
                    <th>{t('Country')}</th>
                    <th>{t('City')}</th>
                    <th
                      className="report-green-bg text-white text-center"
                      style={{ minWidth: '220px' }}
                    >
                      {t('Sales')}
                    </th>
                    <th
                      className="report-pink-bg text-white text-center"
                      style={{ minWidth: '220px' }}
                    >
                      {t('Custom Order')}
                    </th>
                    <th
                      className="report-yelllow-bg text-white text-center"
                      style={{ minWidth: '220px' }}
                    >
                      {t('Mix & Match')}
                    </th>
                    <th
                      className="report-purple-bg text-white text-center"
                      style={{ minWidth: '220px' }}
                    >
                      {t('Repair Order')}
                    </th>
                    <th
                      className="report-blue-bg text-white text-center"
                      style={{ minWidth: '220px' }}
                    >
                      {t('Exchange')}
                    </th>
                    <th
                      className="report-sky-bg text-white text-center"
                      style={{ minWidth: '220px' }}
                    >
                      {t('Refund')}
                    </th>
                    <th
                      className="report-gray-bg text-white text-center"
                      style={{ minWidth: '220px' }}
                    >
                      {t('Wishlist')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {getcustomerlist.length
                      ? getcustomerlist.map((result, key) => {
                        return (
                          <tr className="text-nowrap" key={key}>
                            <td className="bg-white">{key + 1}</td>
                            <td className="px-2 bg-white">
                              <img
                                className="table-user-img rounded-circle"
                                src={
                                  result?.profile_image
                                    ? result?.profile_image
                                    : base_assets +
                                    'images/icons/customer-card-icon.png'
                                }
                                alt=""
                              />
                            </td>
                            <td className="bg-white">
                              {result?.name ? result?.name : '-'}
                              <Link
                                to="/app/admin/customers/customerreportdetail"
                                className="ps-1 ms-1"
                                state={{ customer_id: result?.id }}
                              >
                                <i
                                  className="fa fa-search text-black"
                                  style={{ opacity: '0.3' }}
                                  aria-hidden="true"
                                />
                              </Link>
                            </td>
                            <td>{result?.county ? result?.county : '-'}</td>
                            <td>{result?.city ? result?.city : '-'}</td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="gray-mid">
                                  {result?.order_count?.pos_order?.Qty
                                    ? result?.order_count?.pos_order?.Qty
                                    : '0'}
                                </span>
                                <span>
                                  {toFormatPrice(
                                    result?.order_count?.pos_order?.Amount,
                                    { addSymbol: true },
                                  )}{' '}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="gray-mid">
                                  {result?.order_count?.custom_order?.Qty
                                    ? result?.order_count?.custom_order?.Qty
                                    : '0'}
                                </span>
                                <span>
                                  {toFormatPrice(
                                    result?.order_count?.custom_order?.Amount,
                                    { addSymbol: true },
                                  )}{' '}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="gray-mid">
                                  {result?.order_count?.mixMatch_order?.Qty
                                    ? result?.order_count?.mixMatch_order?.Qty
                                    : '0'}
                                </span>
                                <span>
                                  {toFormatPrice(
                                    result?.order_count?.mixMatch_order
                                      ?.Amount,
                                    { addSymbol: true },
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="gray-mid">
                                  {result?.order_count?.repair_order?.Qty
                                    ? result?.order_count?.repair_order?.Qty
                                    : '0'}
                                </span>
                                <span>
                                  {toFormatPrice(
                                    result?.order_count?.repair_order?.Amount,
                                    { addSymbol: true },
                                  )}{' '}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="gray-mid">
                                  {result?.order_count?.exchange_order?.Qty
                                    ? result?.order_count?.exchange_order?.Qty
                                    : '0'}
                                </span>
                                <span>
                                  {toFormatPrice(
                                    result?.order_count?.exchange_order
                                      ?.Amount,
                                    { addSymbol: true },
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="gray-mid">
                                  {result?.order_count?.refund_order?.Qty
                                    ? result?.order_count?.refund_order?.Qty
                                    : '0'}
                                </span>
                                <span>
                                  {toFormatPrice(
                                    result?.order_count?.refund_order?.Amount,
                                    { addSymbol: true },
                                  )}{' '}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <span className="gray-mid">
                                  {result?.order_count?.wishlist_order?.Qty
                                    ? result?.order_count?.wishlist_order?.Qty
                                    : '0'}
                                </span>
                                <span>
                                  {toFormatPrice(
                                    result?.order_count?.wishlist_order
                                      ?.Amount,
                                    { addSymbol: true },
                                  )}{' '}
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                      : ''}
                  </>
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
          </div>
          {getcustomerlist.length > 0 ? (
            <Pagination
              total={totalPages}
              current={currentPage}
              pagination={(crPage) => handlePaginations(crPage)}
            />
          ) : (
            ''
          )}
        </div>


        <FilterCustomerModal
          country={country}
          city={city}
          setNamedata={setNamedata}
          Namedata={Namedata}
          setCountrydata={setCountrydata}
          countrydata={countrydata}
          setCitydata={setCitydata}
          citydata={citydata}
          setGenderdata={setGenderdata}
          genderdata={genderdata}
          setGroupdata={setGroupdata}
          groupdata={groupdata}
          setMaxage={setMaxage}
          maxAge={maxAge}
          customerlist={getcustomerreport}
          setClearall={setClearall}
        />
      </div>
    </React.Fragment>
  )
}
export default CustomersReport
