import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import DateTime from "../../common/DateTime";
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { Tablehead } from "../../common/modules/Tablehead";
import Advancedfiltermodel from "../../common/modules/advanced-filter-model";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { _Api } from "../../api/_call";
import { success, error, loading, Updateloading } from "../../common/helpers/toastify";
import { polisttablehead } from "../../common/Tablehead";
import { po_product_list } from "../../transaction/subTables/po/po_product_list";
import Select from "react-select";
import { DateRange } from '../../common/helpers/daterange'
import { toFormatPrice, toUnformatPrice, transactionTotals } from "../../common/helpers/function";
import { useSelector } from 'react-redux';
import { po_status_class, status_priority } from "../../common/helpers/status_class";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import ProductModal from "../../common/modules/ProductModal";
import ProductImageSliderModal from "../../common/modules/productImageslider";
import { Pagination } from "../../common/Pagination";
import Deallcocatmodal from "../../common/modules/Deallcocatmodal"
import $ from 'jquery';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { formatDate } from "../../common/helpers/formatDate";
const POlist = (props) => {
  const { t } = useTranslation();
  const totalcountsnumber = useSelector((state) => state.app.totalcountsnumber);
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState("");
  const [alllist, setAlllist] = useState([]);
  const [datefilter, setDatefilter] = useState([]);
  const [statement, setStatement] = useState("");
  const [po_sataus, setPo_sataus] = useState([]);
  const [po_priority, setpo_priority] = useState([]);
  const [imageid, setImageid] = useState();
  const [Productmodaldata, setProductmodaldata] = useState([]);
  const [filterchangestatus, setfilterchangestatus] = useState(2);
  const [newcolumndata, setnewcolumndata] = useState(polisttablehead);
  const [cart_id, setcart_id] = useState()
  const [prosuctid, setproductid] = useState()
  const [skip, setSkip] = useState()
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color
      };
    }
  };

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const priorityarray = [
    { label: t("Low"), value: 0, color: "#D9D9D9", class: "low-text" },
    { label: t("Medium"), value: 1, color: "#D2E4FF", class: "medium-text" },
    { label: t("High"), value: 2, color: "#FFD2D9", class: "high-text" }
  ]
  const filter_status = [
    { label: t("Pending"), labelcount: "pending", value: 2, color: "yellow" },
    { label: t("Approved"), labelcount: "approved", value: 3, color: "main-green" },
    { label: t("Canceled"), labelcount: "cancelled", value: 0, color: "danger" },
    { label: t("Completed"), labelcount: "completed", value: 1, color: "light-blue" }
  ]
  const select_po_status = (index, value, po_order_id) => {
    setStatement("Change Status");
    setPo_sataus([{ index: index, status: value, po_order_id: po_order_id }]);
    document.querySelector("#status_query_select").click();
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    Getlist(current, "", "");

  };
  const filterbyname = (search) => {
    setstate({ ...state, currentPage: 1 });
    Getlist(currentPage, limit, filterchangestatus, search);

  };
  const getlimit = (limit) => {
    setLimit(limit);
    Getlist(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const Changepriority = (index, value, po_order_id) => {
    setStatement(t("Change Priority"));
    setpo_priority([{ index: index, status: value, po_order_id: po_order_id }]);
    var mainarray = [...alllist];
    mainarray[index]['status'] = {
      ...mainarray[index]['status'],
      'priority': value?.value
    }
    setAlllist(mainarray);
    document.querySelector("#status_query_select").click();
  };

  const handler_status = async () => {
    try {
      if (po_sataus[0].po_order_id) {
        const postdata = {
          po_order_id: po_sataus[0].po_order_id,
          status: po_sataus[0].status?.value,
        };
        let statusdata = await _Api(
          postdata,
          `api/v1/Inventory/po/editStatusPO`
        );
        if (statusdata?.code === 200) {
          success(t("Successfully Status Updated!!"));
          Getlist();
        } else {
          error(t("Something Went Wrong!!"));
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const [excuteTable, setexcuteTable] = useState(false);

  const Getlist = useCallback(async (current, firstlimit, filterstatus, search) => {
    setexcuteTable(false);
    var expand_col = document.querySelector(".table_btn_expand_col");
    if (expand_col) { expand_col.classList.remove("rotate-90"); }
    try {
      setDatsLodaer(true)
      setTimeout(async function () {
        UnSetdataTable("tbl_create_po_posorder");
        const postdata = {
          "status": filterstatus === 0 ? 0 : filterstatus ? filterstatus : filterchangestatus,
          "search": search ? search : "",
          "limit": firstlimit ? firstlimit : limit,
          "skip": skip === 0 ? skip : current ? (current - 1) * limit : 0,
          "start_date": datefilter[0] ? datefilter[0] : "",
          "end_date": datefilter[1] ? datefilter[1] : "",
        };
        let listdata = await _Api(postdata, `api/v1/Inventory/po/getPOList`);
        if (listdata.code === 200) {
          transactionTotals();
          setexcuteTable(true);
          SetdataTable("tbl_create_po_posorder", "4", newcolumndata, [4, 5, 7, 8]);
          setstate({
            ...state,
            totalPages: Math.ceil(listdata?.count / postdata.limit),
            currentPage: current ? current : 1
          });
          setAlllist(listdata?.data);
          setDatsLodaer(false)
        } else {
          setDatsLodaer(false)
          SetdataTable("tbl_create_po_posorder", "4", newcolumndata, [4, 5, 7, 8]);
        }
      }, 100)

    } catch (err) {
      console.log(err, "err");
    }
  }, [newcolumndata, datefilter, limit, skip, state, filterchangestatus]);
  const setSubTable = async (id, key) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector('#common-main-table-wrap-' + id);
    var alllist_data = [...alllist];
    alllist_data.filter(item => item.id === id).map((result) => {
      return (
        result.subTable = null
      )
    });

    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...alllist];
      alllist_data.filter(item => item.id === id).map((result) => {
        return (
          result.subTable = null
        )
      });
      setAlllist(alllist_data);
    } else {
      classList_.add("rotate-90");
      let alllist_data = [...alllist];
      const postdata = {
        po_order_id: id,
        show_all: false,
        status: filterchangestatus
      };
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/po/getPODetails`
      );
      if (prioritydata?.code === 200) {
        var html_ = po_product_list(prioritydata, setImageid, setProductmodaldata, setcart_id, setproductid, t, key);
        alllist_data.filter(item => item.id === id).map((result) => {
          return (
            result.subTable = html_
          )
        })
      }

      setAlllist(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  };

  const handler_priority = async () => {
    try {
      const postdata = {
        po_order_id: po_priority[0].po_order_id,
        status: po_priority[0].status?.value,
      };
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/po/editPriorityStatusPO`
      );
      if (prioritydata?.code === 200) {
        success(t("Successfully Priority Updated!!"));
        Getlist();
      } else {
        error(t("Something Went Wrong!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const deallcocation = async (event) => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "po_product_id": prosuctid,
        "customOrderId": cart_id,
        "type": event
      }
      let res = await _Api(postdata, `api/v1/Inventory/po/deallocation`);
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t("Deallocation susses!!"));
        Getlist();
      } else {
        Updateloading(t, GetloadingID, t("somthing went wrong"), 'error');
      }
    }
    catch (err) {
      console.log(err, 'err');
    }

  }
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    polisttablehead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (alllist.length) {
      alllist.map((result, key) => {
        return (
          bodyarray.push([result?.createdAt, result?.po_no, result?.supplier_name, result?.location_name, result?.sku, result?.quantity, result?.delivery_date, result?.due_days, result?.total_price,
          result.status.po_status === 0 ? "Canceled" :
            result.status.po_status === 1 ? "Completed" :
              result.status.po_status === 2 ? "Pending" :
                result.status.po_status === 3 ? "Approved" : ""
            , result.status.priority === 0 ? "Low" :
            result.status.priority === 1 ? "Medium" : "High",
          result?.note, result?.user_actions?.created_by_name, result?.user_actions?.updated_by_name
          ])
        )
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "po_list");
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Getlist();
    }
    // eslint-disable-next-line
  }, [datefilter]);
  return (
    <React.Fragment>
      <div className="file-check"></div>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-body-current-day-time">
                <DateTime></DateTime>
              </div>
              <h1 className="main-body-heading">{t("Transaction")} </h1>
              <div className="main-body-top-tab-bar">
                <div
                  className="main-body-top-tab-bar-left-col nav nav-pills"
                  role="tablist"
                >
                  <Link
                    to="/app/inventory"
                    className="main-body-top-tab-bar-left-col-a nav-link active"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Purchase Order")}
                    </span>
                    <span className="num-tab" data-bs-toggle="collapse" data-bs-target="#purchaseOrder">{totalcountsnumber?.PO?.total ? totalcountsnumber?.PO?.total : "0"}</span>
                  </Link>
                  <Link
                    to="/app/inventory/transactionPurchase"
                    className={parmissiondatashow?.inventory_transaction_purchase?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Purchase")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.PU?.total ? totalcountsnumber?.PU?.total : "0"}</span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_stock_transfer?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/stock_transfer"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Stock Transfer")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.ST?.total ? totalcountsnumber?.ST?.total : "0"}</span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_stock_Receive?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/transactionStockReceive"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Stock Receive")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.SR ? totalcountsnumber?.SR : "0"}</span>
                  </Link>
                </div>
                <div className="main-body-top-tab-bar-right-col">
                  <Link to="#" className="main-body-top-tab-bar-right-col-a">
                    <img
                      src={base_url_assets + "images/icons/kanban-icon.svg"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("KANBAN")}
                    </span>
                  </Link>
                  <Link
                    to="/app/inventory/allocationpo"
                    className="main-body-top-tab-bar-right-col-a"
                  >
                    <img
                      src={base_url_assets + "images/icons/link-icon.svg"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("Allocation")}
                    </span>
                    <span className={totalcountsnumber?.match ? "main-body-top-tab-bar-right-col-a-num" : "gray-text"}>
                      {totalcountsnumber?.allocation ? '(' + totalcountsnumber?.allocation + ')' : ""}
                    </span>
                  </Link>
                  <Link
                    to="/app/inventory/posorderlist"
                    className="main-body-top-tab-bar-right-col-a"
                  >
                    <img
                      src={base_url_assets + "images/icons/price-list-icon.png"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("POS Order")}
                    </span>
                    <span className={totalcountsnumber?.match ? "main-body-top-tab-bar-right-col-a-num" : "gray-text"}>
                      {totalcountsnumber?.posCount ? '(' + totalcountsnumber?.posCount + ')' : ""}
                    </span>
                  </Link>
                  <span className={parmissiondatashow?.purchase_order?.Add ? "divider" : ""} />
                  <Link
                    to="/app/inventory/createpo"
                    className={parmissiondatashow?.purchase_order?.Add ? "main-body-top-tab-bar-right-col-a" : "d-none"}
                  >
                    <img
                      src={
                        base_url_assets + "images/icons/plus-circle-white.png"
                      }
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("Create")}
                    </span>
                  </Link>
                </div>
              </div>
              <div id="purchaseOrder" className="collapse show">
                <div className="main-body-top-color-bar">
                  {filter_status.map((result, key) => {
                    return (
                      <div className="main-body-top-color-bar-single" onClick={() => { Getlist(currentPage, limit, result?.value); setfilterchangestatus(result?.value); setSkip(0) }} key={key} >

                        <div className={"main-body-top-color-bar-single-color " + result?.color + "-bg"} />
                        <span className={"main-body-top-color-bar-single-color-status " + result?.color + "-text"}>
                          {result?.label}
                        </span>
                        <span>{totalcountsnumber?.PO?.[`${result?.labelcount}`] ? totalcountsnumber?.PO?.[`${result?.labelcount}`] : "0"}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("PO No./Supplier/Ship to ...")}
                    value={filtername}
                    onKeyPress={(e) => e.key === "Enter" && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'}
                    onClick={(e) => { setFiltername(""); filterbyname("") }}
                    alt="" /> : ""}

                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <ul className="main-body-top-status-bar-indicator">
                  <li>
                    <span className="main-body-top-status-bar-indicator-color bg-danger" />
                    {t("POS Order")}
                  </li>
                  <li>
                    <span className="main-body-top-status-bar-indicator-color bg-neon-green" />
                    {t("Replenish POS")}
                  </li>
                </ul>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_url_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          base_url_assets + "images/icons/ellipsis-circular.png"
                        }
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + "images/icons/export-x-icon.png"
                            }
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + "images/icons/column-icon.png"
                            }
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
                style={{ maxWidth: '100%', overflowX: 'auto' }}
              >
                <table
                  id="tbl_create_po_posorder"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >

                  <Tablehead
                    tablehead={polisttablehead}
                    tblName={"tbl_create_po_posorder"}
                    setfilterby={""}
                  />
                  <tbody>
                    {alllist
                      ? alllist.map((result, key) => {
                        let row_type;
                        let po_status_class_val;
                        if (result?.type === "Sales Order") {
                          row_type = "tr-left-red-border";
                        } else {
                          row_type = "tr-left-green-border";
                        }
                        if (filterchangestatus) {
                          po_status_class_val = po_status_class(
                            filterchangestatus
                          );
                        } else {
                          po_status_class_val = po_status_class(
                            result.status.po_status
                          );
                        }

                        var priority_class_val = status_priority(
                          result.status.priority
                        );
                        var valueofpriority = [];
                        if (result.status.priority === 0) {
                          valueofpriority = [{ label: t("Low"), value: 0, color: "#D9D9D9" }]

                        } else if (result.status.priority === 1) {
                          valueofpriority = [{ label: t("Medium"), value: 1, color: "#D2E4FF" }]
                        } else {
                          valueofpriority = [{ label: t("High"), value: 2, color: "#FFD2D9" }]
                        }
                        var colorsArray = []
                        var valueofstatus = [];
                        if (filterchangestatus) {
                          if (filterchangestatus === 3) {
                            valueofstatus = [{ label: t("Approved"), value: result.status.po_status, color: "#0ECB81" }]
                            colorsArray = [
                              { label: t("Pending"), value: 2, color: "#F9BC44" },

                            ]
                          } else if (filterchangestatus === 2) {
                            valueofstatus = [{ label: t("Pending"), value: result.status.po_status, color: "#F9BC44" }]
                            colorsArray = [
                              { label: t("Approved"), value: 3, color: "#0ECB81" },
                              { label: t("Canceled"), value: 0, color: "#FF5757" }
                            ]
                          } else if (filterchangestatus === 0) {
                            valueofstatus = [{ label: t("Canceled"), value: result.status.po_status, color: "#FF5757" }]
                          } else {
                            valueofstatus = [{ label: t("Completed"), value: result.status.po_status, color: "#68B8F2" }]
                          }
                        } else {
                          if (result.status.po_status === 3) {
                            valueofstatus = [{ label: t("Approved"), value: result.status.po_status, color: "#0ECB81" }]
                            colorsArray = [
                              { label: t("Pending"), value: 2, color: "#F9BC44" },

                            ]
                          } else if (result.status.po_status === 2) {
                            valueofstatus = [{ label: t("Pending"), value: result.status.po_status, color: "#F9BC44" }]
                            colorsArray = [
                              { label: t("Approved"), value: 3, color: "#0ECB81" },
                              { label: t("Canceled"), value: 0, color: "#FF5757" }
                            ]
                          } else if (result.status.po_status === 0) {
                            valueofstatus = [{ label: t("Canceled"), value: result.status.po_status, color: "#FF5757" }]
                          } else {
                            valueofstatus = [{ label: t("Completed"), value: result.status.po_status, color: "#68B8F2" }]
                          }
                        }
                        return (
                          <React.Fragment key={key + "po_product_editList"}>
                            <tr id={`common-main-table-wrap-${result?.id}`} key={key} className={row_type + " po_product_list_dataTr"}>
                              <td className="fixed-side ">
                                {result?.createdAt ? result?.createdAt : "-"}
                              </td>
                              <td className="po-no">
                                <Link
                                  to="/app/inventory/edit-po-pos"
                                  state={{ posid: result?.id, po_status: filterchangestatus, transitiondate: result?.transaction_date_unformated, deliverydate: result?.delivery_date_unformated, voucher_id: result?.voucher_id, printparmission: parmissiondatashow?.purchase_order?.Print, editparmission: parmissiondatashow?.purchase_order?.Edit, statusparmission: parmissiondatashow?.purchase_order?.Status }}
                                >
                                  {result?.po_no ? result?.po_no : "-"}
                                </Link>
                              </td>
                              <td>
                                {result?.supplier_name
                                  ? result?.supplier_name
                                  : "-"}
                              </td>
                              <td className="">
                                {result?.location_name
                                  ? result?.location_name
                                  : "-"}
                              </td>
                              <td
                                className="sku-col table_btn_expand_col col-highlight"
                                onClick={() => setSubTable(result.id, key)}
                                id={"table-btn_" + result.id}
                              >
                                <div className="td-icons-wrap">
                                  <div>
                                    <img
                                      className="sku-col-arrow cursor-pointer"
                                      src={
                                        base_url_assets +
                                        "images/icons/right_green_button.png"
                                      }
                                      alt=""
                                    />
                                    <span>
                                      {result?.sku ? result?.sku : "0"}</span>
                                  </div>
                                  {result.allocatable ? <div className="td-icons-wrap-link">
                                    <img
                                      className="img "
                                      src={
                                        base_assets +
                                        'images/icons/link-icon.svg'
                                      }
                                      alt=""
                                    />
                                  </div> : ""}
                                  <div className='tooltip-area-up'>
                                    {result?.pos_Qty !== 0 && result?.repair ?
                                      <div className="td-icons-wrap-user">
                                        <img
                                          className="img"
                                          src={
                                            base_assets +
                                            'images/icons/user-purple.png'
                                          }
                                          alt=""
                                        /> <span>{result?.pos_Qty}</span>
                                      </div>
                                      : result?.pos_Qty !== 0 ?
                                        <div className="td-icons-wrap-user">
                                          <img
                                            className="img"
                                            src={
                                              base_assets +
                                              'images/icons/user-red.png'
                                            }
                                            alt=""
                                          /> <span>{result?.pos_Qty}</span>
                                        </div> : ""}
                                    <span className="tooltip-area-up-text">{result?.pos?.customer_name}{result?.pos?.customer_name ? <br /> : ''}{result?.pos?.ref}<br />{formatDate(result?.pos?.delivery_date)}</span>
                                  </div>
                                </div>
                              </td>
                              <td className="col-highlight">{result?.quantity ? result?.quantity : "0"}</td>
                              <td className="col-highlight">
                                {result?.delivery_date
                                  ? result?.delivery_date
                                  : "-"}
                              </td>
                              <td className="col-highlight">
                                {result?.due_days ? result?.due_days : "0"}
                              </td>
                              <td className="col-highlight">
                                {result?.total_price
                                  ? toFormatPrice(result?.total_price, { addSymbol: true })
                                  : "-"}
                              </td>
                              <td className="col-highlight">
                                <div
                                  className={
                                    "dropdown status-dropdown status-dropdown-select " +
                                    po_status_class_val
                                  }
                                >
                                  <Select
                                    options={parmissiondatashow?.purchase_order?.Status ? colorsArray : []}
                                    value={valueofstatus}
                                    isDisabled={result.status.po_status === 1 || result.status.po_status === 0 ? true : false}
                                    onChange={(event) =>
                                      select_po_status(key, event, result.id)
                                    }
                                    classNamePrefix="status-dropdown-select"
                                    styles={colorStyles}
                                    getOptionLabel={(e) => (
                                      <div className="">
                                        {e.label}
                                      </div>
                                    )}
                                    isSearchable={false}
                                  />
                                </div>
                              </td>
                              <td className="col-highlight">
                                <div className={"dropdown status-dropdown status-dropdown-select " + priority_class_val}>
                                  <Select
                                    options={priorityarray}
                                    value={valueofpriority}
                                    onChange={(event) =>
                                      Changepriority(key, event, result.id)
                                    }
                                    classNamePrefix="status-dropdown-select"
                                    styles={colorStyles}
                                    getOptionLabel={(e) => (
                                      <div className={e.class}>
                                        {e.label}
                                      </div>
                                    )}
                                    isSearchable={false}
                                  />
                                </div>
                              </td>
                              <td className="col-highlight">{result?.note ? result?.note : "-"}</td>
                              <td className="col-highlight">
                                {result?.user_actions?.created_by_name
                                  ? result?.user_actions?.created_by_name
                                  : "-"}
                              </td>
                              <td className="col-highlight">
                                {result?.user_actions?.updated_by_name
                                  ? result?.user_actions?.updated_by_name
                                  : "-"}
                              </td>
                            </tr>
                            {
                              excuteTable ? (
                                <>
                                  {typeof result.subTable == "undefined" ? (result.subTable = "") : result.subTable}
                                </>
                              ) : null
                            }
                          </React.Fragment>
                        );
                      })
                      : ""}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td> {alllist
                        ? alllist?.reduce(
                          (interation, val) =>
                          (interation =
                            interation + parseInt(val?.quantity)),
                          0
                        )
                        : ""}</td>
                      <td />
                      <td />
                      <td> {""}{" "}
                        {toFormatPrice(
                          alllist
                            ? alllist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.total_price)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {datalodaer &&
                  <div id="table_loader" className="mt-4">
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                }
              </div>
            </div>
            {alllist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <SocialModal />
        <Deallcocatmodal deallcocation_order_close={deallcocation} deallcocation_deallocate={deallcocation}></Deallcocatmodal>
        <ProductModal data_product={Productmodaldata} setOneproduct={setProductmodaldata} editproduct_submit={''} />
        <ProductImageSliderModal
          main_id={imageid}
        ></ProductImageSliderModal>
      </div>
      <TableColumnModal
        tablehead={polisttablehead}
        tblName={"tbl_create_po_posorder"}
        setnewcolumndata={setnewcolumndata}
        calldatatable={Getlist}
      ></TableColumnModal>
      <ConfirmationModel
        statement={statement}
        handler_submit={statement === 'Change Status' ? handler_status : handler_priority}
        handler_dismiss={() => { Getlist(); setexcuteTable(false); }}
      ></ConfirmationModel>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#createPurchaseModal"
        id="status_query_select"
      ></button>
      <Advancedfiltermodel></Advancedfiltermodel>
    </React.Fragment >
  );
};

export default POlist;