import React, { useState, useEffect, useRef } from "react";
import RightArrow from "../../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import EditNameCode from '../common/EditNameCode';
import { _Api, Api_form } from "../../api/_call";
import Confirmationmodel from "../../common/modules/confirmationmodel"
import { success, error, loading, Updateloading } from "../../common/helpers/toastify";
import { Pagination } from "../../common/Pagination";
import { Importmodal } from "../../common/modules/Importmodal"
import { useSelector } from 'react-redux';
import TableLoader from "../../common/TableLoader";
import Select from "react-select";
const MetalMasterMetal = () => {
  const dropdownRefname = useRef(null);
  const dropdownRefcode = useRef(null);
  const dropdownRefcolor = useRef(null)
  const dropdownRefstatus = useRef(null);
  const metalListRef = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [filtername, setFiltername] = useState('');
  const [editItem, setEditItem] = useState(false);
  const [metaldata, setMetaldata] = useState([])
  const [dataid, setDataid] = useState("")
  const [edit, setEdit] = useState(false)
  const [updatedata, setUpdatedata] = useState(false)
  const [create, setCreate] = useState(false)
  const [searchVal, setSearchVal] = useState("");
  const [namefilter, setNamefilter] = useState([])
  const [namechecked, setNamehecked] = useState([])
  const [codefilter, setCodefilter] = useState([])
  const [codechecked, setCodechecked] = useState([]);
  const [colorfilter, setColorfilter] = useState([])
  const [colorchecked, setColorchecked] = useState([])
  const [statuschecked, setStatuschecked] = useState("")
  const [statusfilter, setStatusfilter] = useState([])
  const [showloader, setShowloader] = useState(false)
  const [shortobj, setShortobj] = useState({})
  const [importfile, setImportfile] = useState()
  const [exportid, setExportid] = useState([])
  const [limit, setLimit] = useState(100);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const filterbyname = (search) => {
    getmetallist(currentPage, limit, search);
    setstate({ ...state, currentPage: 1 });
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getmetallist(current, "");

  };
  const getmetallist = async (current, firstlimit, search) => {
    setShowloader(true);
    if (updatedata) {
      setCodechecked([]);
      setNamehecked([]);
      setColorchecked([]);
      setStatuschecked("")
    }
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "search": search ? search : "",
        type: "Metal",
        "limit": firstlimit ? firstlimit : limit,
        "skip": skipNumber,
        "filter": {
          "code": codechecked ? codechecked : [],
          "name": namechecked ? namechecked : [],
          "status": statuschecked ? statuschecked : "",
          "color": colorchecked ? colorchecked : []
        },
        "sort": { [shortobj?.key]: shortobj?.value }
      }
      let res = await _Api(postdata, `api/v1/App/master/metalMasterView`)
      if (res?.code === 200) {
        setMetaldata(res?.data)
        setShowloader(false);
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
      setUpdatedata(false)
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getlimit = (limit) => {
    setLimit(limit);
    getmetallist(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const metalfilter = async () => {
    try {
      let postdata = {
        type: "Metal",
      }
      let res = await _Api(postdata, `api/v1/App/master/metalfilter`)
      if (res?.code === 200) {
        setNamefilter(res?.data?.name);
        setCodefilter(res?.data?.code)
        setColorfilter(res?.data?.color)
        setStatusfilter(res?.data?.status)
      }
    }
    catch (err) {
      console(err, "err")
    }
  }
  useEffect(() => {
    getmetallist();
    metalfilter()
    // eslint-disable-next-line
  }, [updatedata, shortobj]);

  const deletemetal = async () => {
    let GetloadingID = loading();
    try {
      let postdata = {
        type: "Metal",
        id: dataid
      }
      let res = await _Api(postdata, `api/v1/App/master/metalMasterDelete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        getmetallist()
      } else {
        Updateloading(t, GetloadingID, res.errors, 'error');
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const hendledropdownhide = (lable) => {
    if (lable === "name") {
      dropdownRefname.current.classList.remove("show")
    } else if (lable === "code") {
      dropdownRefcode.current.classList.remove("show")
    } else if (lable === "Status") {
      dropdownRefstatus.current.classList.remove("show")
    } else if (lable === "color") {
      dropdownRefcolor.current.classList.remove("show")
    }
  }
  const handleCheckAllChange = (e, lable) => {
    if (lable === "name") {
      setNamehecked(
        e.target.checked ? namefilter.map((c) => c) : []
      );
    } else if (lable === "code") {
      setCodechecked(
        e.target.checked ? codefilter.map((c) => c) : []
      );
    } else if (lable === "color") {
      setColorchecked(
        e.target.checked ? colorfilter.map((c) => c?._id) : []
      );
    } else if (lable === "export") {
      setExportid(
        e.target.checked ? metaldata.map((c) => c?._id) : []
      )
    }

  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "name") {
      setNamehecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "code") {
      setCodechecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "color") {
      setColorchecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data?._id]
          : prevChecked.filter((item) => item !== data?._id)

      );
    } else if (lable === "export") {
      setExportid((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)
      )
    }

  };
  const selectOptions = [
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "300", value: "300" }
  ]
  const getscarchvalue = async (event, lable) => {
    if (lable === "name") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...namefilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setNamefilter(arr)
        });
      } else {
        metalfilter()
      }
    } else if (lable === "code") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...codefilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setCodefilter(arr)
        });
      } else {
        metalfilter()
      }
    } else if (lable === "color") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...colorfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setColorfilter(arr)
        });
      } else {
        metalfilter()
      }
    }
  }
  const readExcel = async () => {
    if (!importfile) {
      error(t('Choose file!'));
      return;
    }
    try {
      const formData = new FormData()
      formData.append('file', importfile)
      formData.append('type', 'Metal')
      let res = await Api_form(
        formData,
        `api/v1/App/Import/metalImport`,
      )
      if (res.code === 200) {
        success(
          t(res?.message),
        )
        getmetallist()
      } else {
        error(t(res?.message))
      }
    } catch (err) {
      console.log(err, 'err')
    }

  }
  const downloadexclsheet = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        type: "metal",
        ids: exportid
      }
      let res = await _Api(postdata, `api/v1/App/Export/metalExport`)
      if (res?.code === 200) {
        window.open(res?.filexlsx, '_blank', 'noreferrer')
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, (res?.message), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL
  const SampleExcel = () => {
    const exportLinkElement = document.createElement('a')
    exportLinkElement.hidden = true
    exportLinkElement.download = 'metalExport.xlsx'
    exportLinkElement.href = base_url + "master_xlsx/metalExport.xlsx"
    exportLinkElement.text = 'downloading...'
    document.body.appendChild(exportLinkElement)
    exportLinkElement.click()
    exportLinkElement.remove()
  }
  const handleScrollToThetop = () =>{
    setTimeout(()=>{
      if (metalListRef.current) {
        metalListRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
    },100)
  }
  return (
    <>
      <div className="main-content-wrapper-body" >
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">{t("Master")} <RightArrow /> {t("Metal Master")} <RightArrow /> <span className="cursor-pointer" onClick={() => setUpdatedata(true)}>{t("Metal")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <Select
              className="limit-select mx-3"
              options={selectOptions}
              value={{ label: limit, value: limit }}
              onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
            />
            <div className="com-search-bar position-relative">
              <img
                onClick={filterbyname} className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search Metal" value={filtername} className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                onChange={(e) => setFiltername(e.target.value)} />
              {filtername ? <img className="search-bar-clear" onClick={(e) => {
                setFiltername("");
                getmetallist(1, 100, "")
              }} src={base_assets + 'images/icons/False.png'} alt="" /> : ""}
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("print")}</span>
                </li>
                <li className="dropdown-item"
                  onClick={() => downloadexclsheet()}>
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
                <li className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#ImportModal">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export.png"}
                      alt=""
                    />
                  </div>
                  <span >{t("Import")}</span>
                </li>
              </ul>
            </div>
            <button className={parmissiondatashow?.Master?.Add ? "com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3" : "d-none"}
              onClick={() => { setEditItem(true); setCreate(true); setDataid(""); setEdit(false) }}>
              <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className={editItem ? "col-md-6" : "col-md-12"}>
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th className="pe-0">
                      <div className="com-check">
                        <input id="check1" type="checkbox"
                          onChange={(e) => handleCheckAllChange(e, "export")}
                          checked={metaldata?.length ? exportid.length === metaldata.length : false} />
                        <label htmlFor="check1" className="com-check-label"
                        />
                      </div>
                    </th>
                    <th>#</th>
                    <th className="user-name-width">{t("Name")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                          ref={dropdownRefname}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "name", value: 1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "name", value: -1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "name")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="name"
                                  onChange={(e) => handleCheckAllChange(e, "name")}
                                  checked={namechecked.length === namefilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="name">{t("Select All")}</label>
                              </div>
                              <label>{namechecked.length}/{namefilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {namefilter?.length ? namefilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key + "name"}
                                      checked={
                                        namechecked ? namechecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "name")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "name"}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                          >
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setNamehecked([]); setShortobj({}); hendledropdownhide("name") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getmetallist(); hendledropdownhide("name") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Code")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden code"
                          ref={dropdownRefcode} >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "code", value: 1 }); hendledropdownhide("code") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "code", value: -1 }); hendledropdownhide("code") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "code")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="code"
                                  onChange={(e) => handleCheckAllChange(e, "code")}
                                  checked={codechecked.length === codefilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="code">{t("Select All")}</label>
                              </div>
                              <label>{codechecked.length}/{codefilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {codefilter?.length ? codefilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"code" + key}
                                      checked={
                                        codechecked ? codechecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "code")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={"code" + key}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setCodechecked([]); setShortobj({}); hendledropdownhide("code") }}>Clear</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getmetallist(); hendledropdownhide("code") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Color")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                          ref={dropdownRefcolor}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "color", value: 1 }); hendledropdownhide("color") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "color", value: -1 }); hendledropdownhide("color") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt=""
                              />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "color")}
                                onChange={(e) => setSearchVal(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="color"
                                  onChange={(e) => handleCheckAllChange(e, "color")}
                                  checked={colorchecked.length === colorfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="color">{t("Select All")}</label>
                              </div>
                              <label>{colorchecked?.length}/{colorfilter?.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {colorfilter?.length ? colorfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox"
                                      id={result?._id}
                                      onChange={(e) => handlefilerChange(e, result, "color")}
                                      checked={
                                        colorchecked ? colorchecked.includes(result?._id) : ""
                                      } />
                                    <label className="form-check-label cursor-pointer" htmlFor={result?._id}>{result?.name}</label>
                                  </li>
                                )
                              }) : ""}
                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setColorchecked([]); setShortobj({}); hendledropdownhide("color") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getmetallist(); hendledropdownhide("color") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Last Modified Date")}</th>
                    <th>{t("Status")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                          ref={dropdownRefstatus}
                        >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "Status", value: -1 }); hendledropdownhide("Status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "Status", value: 1 }); hendledropdownhide("Status") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {statusfilter?.length ? statusfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input radio d-inline-block me-2 cursor-pointer" name="mixmatchradio" type="radio" id={key + "status"} value={result?.value}
                                      onClick={(e) => setStatuschecked(result?.value)} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key + "status"}>{result?.lable}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={(e) => { setStatuschecked(""); setShortobj({}); hendledropdownhide("Status") }} >{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getmetallist(); hendledropdownhide("Status") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {metaldata?.length ? metaldata.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td className="pe-0">
                          <div className="com-check">
                            <input id={result?._id} type="checkbox"
                              onChange={(e) => handlefilerChange(e, result?._id, "export")}
                              checked={
                                exportid ? exportid.includes(result?._id) : ""
                              } />
                            <label htmlFor={result?._id} className="com-check-label" />
                          </div>
                        </td>
                        <td>{key + 1}</td>
                        {parmissiondatashow?.Master?.Edit ?
                          <td
                            className="link-color cursor-pointer"
                            onClick={() => { setEditItem(true); setDataid(result?._id); setEdit(true); setCreate(false); handleScrollToThetop(); }}>
                            {result?.name ? result?.name : ""}</td> :
                          <td>{result?.name ? result?.name : ""}</td>
                        }
                        <td>{result?.code ? result?.code : ""}</td>
                        <td>{result?.color_name ? result?.color_name : ""}</td>
                        <td>{result?.updated ? result?.updated : ""}</td>
                        <td>
                          {result?.Status === "1" ?
                            <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Active")}</span>
                            :
                            <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold">
                              <span className="inactive-badge-dot me-2"></span>{t("InActive")}</span>
                          }

                        </td>
                        <td className="text-center">
                          <img
                            onClick={() => { setEditItem(true); setDataid(result?._id); setEdit(true); setCreate(false) }}
                            className={parmissiondatashow?.Master?.Edit ? "cursor-pointer me-4" : "d-none"}
                            src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                          <img
                            data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                            onClick={() => setDataid(result?._id)}
                            className={parmissiondatashow?.Master?.Delete ? "cursor-pointer" : "d-none"} src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                    </tr>}
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {metaldata?.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
          <div className={editItem ? "col-md-6 d-block" : "d-none"} ref={metalListRef}>
            <EditNameCode setEditItem={setEditItem}
              type={"Metal"}
              dataid={dataid}
              edit={edit}
              setUpdatedata={setUpdatedata}
              create={create}
              setCreate={setCreate}
              setDataid={setDataid}
            />
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={deletemetal} ></Confirmationmodel>
        <Importmodal
          setImportfile={setImportfile}
          readExcel={readExcel}
          importfile={importfile}
          SampleExcel={SampleExcel} />
      </div>
    </>
  )
}

export default MetalMasterMetal;