import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export const ReportMenuModal = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
  const [, setPagename] = useState();
  const callmenu = (event) => {
    setShow(!show);
  }

  useEffect(() => {
    const pathurl = window.location.pathname;
    const pathname = pathurl.split("report/");
    setPagename(pathname[1]);
  }, []);
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  return (
    <div className="main-body-top-status-bar-dropdown" ref={dropdownRef}>
      <input className="main-body-top-status-bar-dropdown-input" value={state ? state.name : "Reserve"} id="menudropdown" readOnly type="text" onClick={callmenu} />
      {show ?
        <ul className="main-body-top-status-bar-dropdown-ul">
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/reserve" state={{ name: t("Reserve") }}>
            {t("Reserve")}
          </Link></li>
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/po" state={{ name: t("Po") }}>{t("Po")}</Link></li>
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/purchase" state={{ name: t("Purchase") }}>{t("Purchase")}</Link></li>
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/stocktransfer" state={{ name: t("Stock Transfer") }}>{t("Stock Transfer")}</Link></li>
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/stockreceive" state={{ name: t("Stock Receive") }}>{t("Stock Receive")}</Link></li>
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/stocktake" state={{ name: t("Stock Take") }}>{t("Stock Take")}</Link></li>
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/lowstockplanning" state={{ name: t("Low Stock Planning") }}>{t("Low Stock Planning")}</Link></li>
          <li className="main-body-top-status-bar-dropdown-ul-li"><Link className="main-body-top-status-bar-dropdown-ul-li-a" to="/app/inventory/report/vendor" state={{ name: t("Vendor") }}>{t("Vendor")}</Link></li>
        </ul>
        : ''}
    </div>

  );
};
