import React from 'react'

export default function DeleteIcon(props) {
    const { width, height, color, type } = props;
    return ( 
        <>
        {type === 'curved' ? 
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 5L15.1327 17.1425C15.0579 18.1891 14.187 19 13.1378 19H4.86224C3.81296 19 2.94208 18.1891 2.86732 17.1425L2 5M7 9V15M11 9V15M12 5V2C12 1.44772 11.5523 1 11 1H7C6.44772 1 6 1.44772 6 2V5M1 5H17" stroke="#FF5757" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        :
        <svg width={width ? width : "24"} color={color ? color : '#8A8A8A'} height={height ? height : "24"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> 
        <path fill={color ? color : '#8A8A8A'} d="M5 2V0H15V2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5ZM4 4V18H16V4H4ZM7 7H9V15H7V7ZM11 7H13V15H11V7Z" />
        </svg>
        }
        </>
    )
}
