import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../common/helpers/formatDate";
import Calendar from 'admin/common/icons/sidebar/Calendar';


export const DateRange = (props) => {
    const { setDatefilter } = props
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(new Date());
    const datePickerRef = useRef(null);
    const changedate = (update) => {
        if (update[1] === null) {
            const startOfDay = update[0]
                ? new Date(update[0].setHours(12, 10, 10, 999))
                : null;
            setStartDate(startOfDay);
            setEndDate(update[1])
            setDatefilter([startOfDay, new Date()]);
        } else {
            const startOfDay = update[0]
                ? new Date(update[0].setHours(12, 10, 10, 999))
                : null;
            const endOfDay = update[1]
                ? new Date(update[1].setHours(23, 59, 59, 999))
                : null;
            setStartDate(startOfDay);
            setEndDate(endOfDay)
            setDatefilter([startOfDay, endOfDay]);
        }



    }
    useEffect(() => {
        const prevMonth = new Date();
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setStartDate(prevMonth);
        setDatefilter([prevMonth, endDate]);
        // eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <div className="dateranger position-relative" >
                <div className=" main-body-top-status-bar-multi-date-picker-wrap " onClick={() => datePickerRef.current.setOpen(true)}>
                    <input type="text" value={startDate ? formatDate(startDate) : "DD/MM/YYYY"} readOnly />
                    <span className="date-arrow">
                        <img
                            src={base_assets + "images/icons/calendar_arrow.svg"}
                            className="calendar_arrow" alt=""
                        />
                    </span>
                    <input type="text" value={endDate ? formatDate(endDate) : formatDate(new Date())} readOnly />
                    <span className="main-body-top-status-bar-multi-date-picker-wrap-calender">
                        <img
                            src={base_assets + "images/icons/calendar-green.svg"}
                            alt=""
                        />
                    </span>
                </div>
                <DatePicker
                    selectsRange={true}
                    monthsShown={2}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        changedate(update);
                    }}
                    dateFormat="yyyy-MM-dd"
                    ref={datePickerRef}
                    className='d-flex'
                    showMonthDropdown="true"
                    showYearDropdown="true"
                    popperPlacement='auto'
                    autoComplete='none'
                />
            </div>
        </React.Fragment>
    );
}

export const DateRangeNew = (props) => {
    const { setDatefilter } = props
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(new Date());
    const datePickerRef = useRef(null);
    const changedate = (update) => {
        if (update[1] === null) {
            const startOfDay = update[0]
                ? new Date(update[0].setHours(12, 10, 10, 999))
                : null;
            setStartDate(startOfDay);
            setEndDate(update[1])
            setDatefilter([startOfDay, new Date()]);
        } else {
            const startOfDay = update[0]
                ? new Date(update[0].setHours(12, 10, 10, 999))
                : null;
            const endOfDay = update[1]
                ? new Date(update[1].setHours(23, 59, 59, 999))
                : null;
            setStartDate(startOfDay);
            setEndDate(endOfDay)
            setDatefilter([startOfDay, endOfDay]);
        }



    }
    useEffect(() => {
        const prevMonth = new Date();
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setStartDate(prevMonth);
        setDatefilter([prevMonth, endDate]);
        // eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <div className="dateranger new position-relative" >
                <div className=" main-body-top-status-bar-multi-date-picker-wrap new" onClick={() => datePickerRef.current.setOpen(true)}>
                    <Calendar color="#A4A4A4" width="15px" height="15px"/>
                    <input type="text" value={startDate ? formatDate(startDate) : "DD/MM/YYYY"} readOnly />
                    -
                   
                    <input type="text" value={endDate ? formatDate(endDate) : formatDate(new Date())} readOnly />
                    
                </div>
                <DatePicker
                    selectsRange={true}
                    monthsShown={1}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        changedate(update);
                    }}
                    dateFormat="yyyy-MM-dd"
                    ref={datePickerRef}
                    className='d-flex'
                    showMonthDropdown="true"
                    showYearDropdown="true"
                    popperPlacement='auto'
                    autoComplete='none'
                    
                />
            </div>
        </React.Fragment>
    );
}
// export default DateRange;