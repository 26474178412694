import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import CheckoutBlank from '../checkout/checkoutBlank'
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import './ReserveOrder.scss'
import { useSelector } from 'react-redux';
import CustomerSearchModal from "../common/module/customerSearch";
import { useTranslation } from 'react-i18next';
import { _Api } from "../common/api/_call"
import { Customsubtable } from "../Custom/subtable/Customsubtable";
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function';
import TableColumnModal from "../../components/common/modules/tableColumn";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { posreserveorder_head } from "../../components/common/Tablehead";
import { store } from '../../source/index'
import $ from 'jquery'
const ReserveOrder = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const customer = useSelector((state) => state.app.customer);
  const [newcolumndata, setnewcolumndata] = useState(posreserveorder_head);
  const [, setfilterby] = useState("");
  const [orderid, setOrderid] = useState('');
  const [searchproductstatus, setsearchproductstatus] = useState(false);
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [showpayment, setShowpayment] = useState(false);
  const [UpdatedCart, setUpdatedCart] = useState();
  const [orderlist, setOrderlist] = useState();
  const [excuteTable, setexcuteTable] = useState(false);
  const [TransactionDate, setTransactionDate] = useState()
  const [reserve_status, setReserve_status] = useState()
  const reserve_sell = true
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }
  useEffect(() => {
    callcolumns();
  }, []);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "reserveorder_tbl",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const GetReserveorder = async () => {
    setexcuteTable(false);
    var expand_col = document.querySelector(".table_btn_expand_col");
    if (expand_col) { expand_col.classList.remove("rotate-90"); }
    try {
      setTimeout(async function () {
        UnSetdataTable('com-data-area_table');
        const postdata = {
          "customer_id": customer?.id,
          "date": TransactionDate,
          'order_type': 'reserve'
        };
        let res = await _Api(postdata, `api/v1/POS/customOrder/getOrderList`);
        if (res.code === 200) {
          setexcuteTable(true);
          SetdataTable('com-data-area_table', '5', newcolumndata, [1]);
          setOrderlist(res?.data);
        } else {
          SetdataTable('com-data-area_table', '5', newcolumndata, [1]);
        }
      }, 100)
    }
    catch (err) {
      console.log(err, 'err');
    }
  }

  const GetCartdetailorder = async () => {
    try {
      const postdata = {
        "order_id": orderid,
        'order_type': 'reserve'
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setShowcheckout('sell');
      } else {
        navigate('/app/pos/reserve/reserveorder')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const setSubTablecustom = async (order_id) => {
    var e = document.querySelector("#table-btn_" + order_id);
    var row = document.querySelector('#common-main-table-wrap-' + order_id);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      var alllist_data = [...orderlist];
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = null
      })
      setOrderlist(alllist_data);
    } else {
      classList_.add("rotate-90");
      alllist_data = [...orderlist];
      const postdata = {
        "Order_id": order_id
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/customOrder/getSkuByOrderList`);
      var html_ = Customsubtable(prioritydata, order_id, t);
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = html_
      })
      setOrderlist(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + order_id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  }

  useEffect(() => {
    if (customer?.id) {
      GetReserveorder();
    } else {
      GetReserveorder();
    }
    // eslint-disable-next-line
  }, [customer?.id, TransactionDate]);
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="com-data-area">
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left reserveorder-header">
                    <Link to="/app/pos/reserve">
                      <img className="reserveorder-header-backbtn" src={base_assets + 'images/pos/backbtn.png'} alt=""></img>
                    </Link>
                    <h1 className="heading reserveorder-header-heading">
                      {t("Reserve List")}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="com-data-area-header delivered-header">
                <div className="delivered-header-left custom-order-search">
                  <CustomerSearchModal ></CustomerSearchModal>
                </div>
                <div className="customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date">
                  <DatePicker
                    className="placeholder-up-input-field"
                    selected={TransactionDate}
                    onChange={selectTransactionDate}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    placeholderText='MM/DD/YYYY'
                    autoComplete='none'
                  />
                  <img
                    className="placeholder-up-input-field-calender"
                    src={base_assets + 'images/icons/calendar-green.svg'}
                    alt=""
                  />
                </div>
              </div>
              <div className="com-data-area-inner">
                <div
                  className="main-body-main-table-wrap reserveorder-table vertical-scroll-table mt-3"
                  id="table-scroll"
                >
                  <table
                    id="com-data-area_table"
                    className="common-table first-row-gray w-100 reserveorder-table-main"
                  >
                    <Tablehead tablehead={posreserveorder_head} tblname={"reserveorder_tbl"} setfilterby={setfilterby} />
                    <tbody className='dataTables_wrapper'>
                      <>
                        {orderlist?.length ? orderlist.map((result, key) => {
                          return (
                            <React.Fragment key={key}>
                              <tr
                                id={`common-main-table-wrap-${result?.order_id}`} key={result?.order_id} onClick={() => {
                                  result?.reserve_status === 0 ? setOrderid(result?.order_id) : setOrderid('');
                                  setReserve_status(result?.reserve_status)
                                }}    >
                                <td className="row-check">
                                  <div className="com-check radio">
                                    <input type="radio"
                                      id={result?.order_id}
                                    />
                                    <label htmlFor={result?.order_id} className="com-check-label" />
                                  </div>
                                </td>
                                <td>{key + 1}</td>
                                <td>{result?.transaction_date}</td>
                                <td className='link-color'>{result?.order_no}</td>
                                <td className='sku-col table_btn_expand_col'
                                  onClick={() => setSubTablecustom(result?.order_id)}
                                  id={"table-btn_" + result?.order_id}>  <img
                                    className="sku-col-arrow cursor-pointer"
                                    src={
                                      base_assets +
                                      "/images/icons/right_green_button.png"
                                    }
                                    alt=""
                                  />
                                  {result?.SKU}</td>
                                <td>{result?.Qty}</td>
                                <td>{toFormatPrice(result?.labour, { addSymbol: true })}</td>
                                <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                <td className='main-green-text'>{toFormatPrice(result?.payment, { addSymbol: true })}</td>
                                <td className='danger-text'>{toFormatPrice(result?.balance_due, { addSymbol: true })}</td>
                                <td className='danger-text'>{result?.delivery_date}</td>
                                <td>{result?.location_name}</td>
                                <td>{result?.reserve_status === 0 ?
                                  <label className='status-tab production-col'>Reserve</label>
                                  :
                                  result?.reserve_status === 1 ?
                                    <label className='status-tab gray-tab'>Close Order</label>
                                    : ''
                                }
                                </td>
                              </tr>
                              {
                                excuteTable ? (
                                  <>
                                    {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                  </>
                                ) : null
                              }
                            </React.Fragment>
                          );
                        }) : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.Qty)),
                              0
                            )
                            : ""
                        }</td>
                        <td>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.labour)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.amount)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td className='main-green-text'>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.payment)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td className='danger-text'>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.balance_due)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className='com-data-area-footer-btn'>
                  <button onClick={() => GetCartdetailorder()} className={orderid !== '' && showcheckout === 'blank' ? 'com-btn ms-auto' : 'com-btn ms-auto btn-disabled'}>{t("Submit")}</button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
                reserve_status={reserve_status}
                ordertype={"pos_order"}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                order_id={orderid ? orderid : ''}
                order_type_reserve={"pos_order"}
                reserve_sell={reserve_sell} />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
      <TableColumnModal tablehead={posreserveorder_head} tblname={"reserveorder_tbl"} setnewcolumndata={setnewcolumndata}
        calldatatable={GetReserveorder} ></TableColumnModal>
    </>
  )
}
export default ReserveOrder
