import React, { useState, useEffect, useCallback } from "react";
import { HiDesktopComputer } from "react-icons/hi";
import { FaMobileAlt, FaTabletAlt } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import Tabfeature from "./Tabfeature";
import Mobfeature from "./mobfeature";
import Select from 'react-select';
import circleEarth from "./assets/images/circle-EarthFull.svg"
import circleEarthTab from "./assets/images/Tab-circle-earth.svg"
import boxtopBtn from "./assets/images/box-topbtottom.svg"
import boxBottomTop from "./assets/images/box-btmtop.svg"
import tabtopCube from "./assets/images/tab-topcube.svg"
import axios from "axios";
import cromeImg from "./assets/images/chrome.png"
import compasImg from "./assets/images/compas.png"
import appStoreImg from "./assets/images/appstore.png"
import topPcImg from "./assets/images/toppcimage.png"
import usaFlag from "./assets/images/usaFlag.png"
import chinaFlag from "./assets/images/china.png"
import thailandFlag from "./assets/images/thailand.png"
import japanFlag from "./assets/images/japan.png"
import saudiflag from "./assets/images/saudi-arabia.png"
import franchiseImg from "./assets/images/franchise.svg"
import cloudImg from "./assets/images/Cloud.svg"
import ecomImg from "./assets/images/Ecom.svg"
import rfidImg from "./assets/images/rfid.svg"
import gidCircleImg from "./assets/images/GisCircleImg.svg"
import frameImg from "./assets/images/Frame.png"
import blockchainImg from "./assets/images/blockchain.png"
import traceabilityImg from "./assets/images/traceability.png"
import transparencyImg from "./assets/images/transparency.png"
import securityImg from "./assets/images/security.png"
import boundlessImg from "./assets/images/boundless.png"
import checkTick from "./assets/images/bi_check.png"
import unionMobile from "./assets/images/Union-mobile.png"

const Content = (props) => {
  const { activeDiv } = props;
  const [showCookieBox, setShowCookieBox] = useState(localStorage.getItem('privacyPolicyAccepted'));
  const [formData, setFormData] = useState({
    'name': '',
    'email': '',
    'business': [],
    'country': '',
    'phoneCode': '66',
    'phone': '',
    'company': '',
    'description': ''
  });
  const [formErrors, setFormErrors] = useState();
  const [submissionStatus, setSubmissionStatus] = useState({
    data: "",
    status: false
  });
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const isPrivacyPolicyAccepted = localStorage.getItem('privacyPolicyAccepted');
    if (isPrivacyPolicyAccepted) {

      setShowCookieBox(showCookieBox);
    }
  }, [showCookieBox]);

  const formsubmiturl = 'https://adminapi.gis247.net/';

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const checkboxinput = (e) => {
    let array = { ...formData };
    let checkarray = array?.business;

    if (e.target.checked) {
      setFormErrors({});
      if (e.target.value === 'Others') {
        let inputvalue = document.querySelector(".enquiry-checkInput");
        checkarray.push({ 'inputvalue': inputvalue.value })
      } else {
        checkarray.push(e.target.value);
      }
    } else {
      if (e.target.value === 'Others') {
        checkarray = checkarray.filter(b => typeof b !== 'object');
      } else {
        checkarray = checkarray.filter(b => b !== e.target.value);
      }
    }
    setFormData({ ...formData, 'business': checkarray });
  }

  const changeinput = (e) => {
    setFormErrors({});
    let array = { ...formData };
    array?.business.forEach((value) => {
      if (typeof value === 'object') {
        value.inputvalue = e.target.value;
      }
    })
    setFormData(array);
  }

  const submitform = async (e) => {
    let other_input_value = document.querySelector(".enquiry-checkInput");
    e.preventDefault();
    setFormErrors({});
    if (formData.name === "") {
      setFormErrors({ "name": true });
      return;
    }
    if (!formData.business.length) {
      setFormErrors({ "bussinesscheck": true });
      return;
    }
    if (formData.business.filter(b => typeof b === 'object')[0] && !other_input_value.value) {
      setFormErrors({ "business": true });
      return;
    }

    if (formData.email === '' || !emailRegex.test(formData.email)) {
      setFormErrors({ "email": true });
      return;
    }
    if (!formData.phone) {
      setFormErrors({ "phone": true });
      return;
    }
    if (formData.company === "") {
      setFormErrors({ "company": true });
      return;
    }
    if (formData.business.filter(b => typeof b === 'object')[0]) {
      formData?.business.forEach((value) => {
        if (typeof value === 'object') {
          formData?.business.push(value.inputvalue);

          ;
        }
      })
      formData['business'] = formData?.business.filter(b => typeof b !== 'object');
    }
    const config = {
      'url': formsubmiturl + "api/v1/enquries",
      'method': 'POST',
      'content-type': 'application/json',
      'data': formData
    }
    await axios(config).then((res) => {
      if (res?.data?.code === 200) {
        setSubmissionStatus(prevState => ({
          ...prevState,
          data: res?.data?.message,
          status: true
        }));
        other_input_value.value = '';
        setFormData({
          'name': '',
          'email': '',
          'business': [],
          'country': '',
          'phoneCode': '',
          'phone': '',
          'company': '',
          'description': ''
        })
      } else {
        setSubmissionStatus(prevState => ({
          ...prevState,
          data: res?.data?.message,
          status: false
        }));
      }
    }).catch((err) => {
      // console.log(err, "err")
    })
  }

  const getCountry = async () => {
    const config = {
      'url': formsubmiturl + "api/v1/getAllCountry",
      'method': 'POST',
      'header': {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      'data': {}
    }
    await axios(config)
      .then((res) => {
        if (res?.data?.code === 200) {
          setCountries(res?.data?.country);
        }
      })
      .catch((err) => {
        // console.error('Error fetching countries:', err);
      })
  }
  const SelectCountryChange = (selectedOption, selectBox) => {
    if (selectBox === 'country') {
      setFormData({
        ...formData,
        country: selectedOption ? selectedOption.value : '',
        phoneCode: selectedOption ? selectedOption.code : '',
      });
    } else if (selectBox === 'phoneCode') {
      setFormData({
        ...formData,
        phoneCode: selectedOption ? selectedOption.value : '',
      });
    }
  }


  const handleAccept = () => {

    localStorage.setItem('privacyPolicyAccepted', true);
    setShowCookieBox(true);

  };

  const handleDecline = () => {
    setShowCookieBox(true);
  };



  const [width, setWidth] = useState(window.innerWidth > 1400);

  useEffect(() => {

    getCountry();

    const handleResize = () => {
      setWidth(window.innerWidth > 1400);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const enquiryShow = () => {
    const getEnquiry = document.getElementById('contactus')
    getEnquiry.scrollIntoView({ behavior: 'smooth' });
  }


  const scrollToBox = useCallback(() => {
    const getBox = document.getElementById(activeDiv);

    if (!getBox) return;

    const tabData = document.querySelectorAll(".loading-header-navbar-nav .loading-header-nav-item .loading-header-nav-link");
    tabData.forEach((item) => {
      const attr = item.getAttribute("data-id");
      item.classList.toggle("active-nav", attr === activeDiv);
    });

    getBox.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    if (getBox.id === "home") {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [activeDiv]);

  useEffect(() => {
    if (activeDiv) {
      scrollToBox();
    }
  }, [activeDiv, scrollToBox]);

  return (
    <main className=" main-home" id="home">
      <div className=" main-home-hero-section " >
        <span className="tab-top-cubebox"><img src={tabtopCube} alt="tab top cube" /></span>
        <div className="banner-img main-home-hero-section-content">
          <div className="hero-text">
            <h2>
              Manage your jewelry business easier with <span>GIS247</span>
            </h2>

            <p>
              Jewelry inventory software provides a functional system to manage
              your warehouse and POS on one platform, anywhere and anytime.
            </p>
            <hr />
            <button className="landing-common-button" onClick={() => enquiryShow()}>Enquiry</button>
          </div>
          <div className="content-img">
            <img
              src={topPcImg}
              className="second-img"
              alt="second img"
            ></img>
          </div>
        </div>
      </div>

      <section className="intro getIntroBox" id="gis247">
        <h1>
          <span className="intro-underline">What's GIS247 </span>
        </h1>
        <p className="para show-parafirst-onresp">
          <span>GIS247(Global Inventory System)</span> is an exceptional jewelry
          Inventory management cloud-based that functions in real-time with <br /> a
          flexible database and essential retail features to grow business
          globally. With our software management, <br /> you can keep track of your
          inventory well and take your business to the next level.
        </p>
        <p className="para show-paraSec-onresp">
          <span>GIS247(Global Inventory System)</span> is an exceptional jewelry
          Inventory management cloud-based that functions in real-time with a
          flexible database and essential retail features to grow business
          globally. With our software management, you can keep track of your
          inventory well and take your business to the next level.
        </p>
        <div className="logo-list">
          <div className="works-first-box">
            <div className="logo-show-first-box">
              <span className="works-on">Works on</span>
              <div className="image-column">
                <img
                  src={cromeImg}
                  className="logo-size"
                  alt="chrome-img"
                ></img>
                <img
                  src={compasImg}
                  className="logo-size"
                  alt="compas-img"
                ></img>
                <div className="appstore-img-box">
                  <img src={appStoreImg} alt="appstore"></img>
                </div>
              </div>
            </div>
          </div>

          <div className="intro-box-line"></div>
          <div className="logo-show-box">
            <div className="works-on">Multilingual Supports</div>
            <div className="logo-parent">
              <div className="logo-flex">
                <div className="logos country">
                  <div>
                    <img
                      src={usaFlag}
                      className="logo-size"
                      alt="usa img "
                    />
                  </div>
                  <div className="row common-row-show">English(US)</div>
                </div>
                <div className="logos country">
                  <div>
                    <img
                      src={chinaFlag}
                      className="logo-size"
                      alt="china flag"
                    />
                  </div>
                  <div className="row common-row-show">Chinese</div>
                </div>
              </div>
              <div className="logo-flex">
                <div className="logos country">
                  <div>
                    <img
                      src={japanFlag}
                      className="logo-size"
                      alt="japan flag"
                    />
                  </div>
                  <div className="row common-row-show">Japanese</div>
                </div>
                <div className="logos country">
                  <div>
                    <img
                      src={thailandFlag}
                      className="logo-size"
                      alt="thailand flag"
                    />
                  </div>
                  <div className="row common-row-show">Thai</div>
                </div>
                <div className="logos country">
                  <div>
                    <img
                      src={saudiflag}
                      className="logo-size"
                      alt="sa flag"
                    />
                  </div>
                  <div className="row common-row-show">Arabic</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="gis-channel-container " >
        <div className="gis-channel-container-first">
          <div className="">
            <div className="gis-wrapper">
              <div className="gis-wrapper-box">
                <h2>Multi-Store concept</h2>
                <span className="gis-channel-container-first-img-box">
                  <img src={franchiseImg} alt="franchise img"></img>
                </span>
              </div>
              <div className="gis-wrapper-box">
                <h2>On a Cloud server</h2>
                <span className="gis-channel-container-first-img-box">
                  <img src={cloudImg} alt="cloud img"></img>
                </span>
              </div>
              <div className="gis-wrapper-box">
                <h2>E-commerce Integration</h2>
                <span className="gis-channel-container-first-img-box">
                  <img src={ecomImg} alt="ecom img"></img>
                </span>
              </div>
              <div className="gis-wrapper-box">
                <h2>Barcode & RFID readers</h2>
                <span className="gis-channel-container-first-img-box">
                  <img src={rfidImg} alt="rfid img"></img>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="omnichannel gis-channel-container-second">
          <div className="omnichannel-container">
            <div className="ecom omnichannel-container-ecommerce">
              <h2> OMNICHANNEL </h2>
              <p>
                GIS247 lets jewelry businesses create experiences that are
                seamless and integrated for customers. Because all devices,
                accounts, and platforms are connected, customers can communicate
                via a variety of channels.
              </p>
              <hr />
            </div>
            <div className="omnichannel-container-imgBox">
              <img
                src={gidCircleImg}
                className="ecom-img"
                alt="img3"
              ></img>
            </div>
          </div>
        </div>
      </section>

      <div className="features features-container " id="features">
        <div className="box-show-twoimg">
          <img src={boxtopBtn} className="box-tap-show-second" alt="img4" />
          <img src={boxBottomTop} className="box-tap-show-first" alt="img4" />
          <img src={unionMobile} className="box-mobile-show-top" alt="img4" />
          <img src={unionMobile} className="box-mobile-show-btm" alt="img4" />
        </div>
        <h1>
          <span className="underline">Features </span>
        </h1>

        {width ? <Tabfeature countries={countries} /> : <Mobfeature countries={countries} />}
      </div>

      <section className="bg-block " id="ecommerces">
        <div className="blockchain" >
          <div className=" blockchain-conatiner">
            <div className=" block-tech">
              <h2 >
                Build your own <span className="show-highlight-content" >Jewelry website</span> with us
              </h2>
              <p className="block-tech-paraShow" >
                We provide full services for your jewelry retail store, allowing
                you to establish your own E-Commerce website with our free
                templates.
              </p>
              <hr className="blockchain-hr-tag" />
              <ul className="ecom">
                <li className="blockspace">
                  <span className="check-sign-img-box">
                    <img src={checkTick} alt="check sign" />
                  </span>
                  Easy and quick to create your website
                </li>
                <li className="blockspace">
                  <span className="check-sign-img-box">
                    <img src={checkTick} alt="check sign" />
                  </span>
                  No additional charge</li>
                <li className="blockspace">
                  <span className="check-sign-img-box">
                    <img src={checkTick} alt="check sign" />
                  </span>
                  Plug-in with GIS247 software</li>
              </ul>
            </div>
            <div className="block-tech-sec-img">
              <img
                src={frameImg}
                className="block-img"
                alt="imgframe"
              ></img>
            </div>
          </div>
        </div>

        <div className="register">
          <div className="">
            <div className="centered">
              <h1>Register to GIS today</h1>
              <h2>
                Enjoy the best jewelry software solution <br /> on a cloud server from
                <span> anywhere, at any time</span>.
              </h2>
              <div className="icons register-icon">
                <div className="register-icon-singlebox">
                  <HiDesktopComputer color="white" size={44} />
                  <span className="text">PC</span>
                </div>
                <div className="register-icon-singlebox">
                  <FaMobileAlt color="white" size={36} />
                  <span className="text">Mobile</span>
                </div>
                <div className="register-icon-singlebox">
                  <FaTabletAlt color="white" size={36} />
                  <span className="text">Tablet</span>
                </div>
              </div>
              <button className="landing-common-button">Enquiry</button>
            </div>
          </div>
        </div>

        <div className="inter-block-chain">
          <div className=" inter-blockchain-conatiner">
            <div className="block-tech-sec-img">
              <img
                src={blockchainImg}
                className="block-img"
                alt="blockchain"
              ></img>
            </div>
            <div className="block-content inter-block-chain-tech ">
              <h2 className="">
                Integrated with <span>Blockchain</span> technology
              </h2>
              <p className="com-para-show inter-block-chain-tech-para pe-4">
                It is a decentralized blockchain platform that allows customers
                to safely store databases through the use of technology.
              </p>
              <div className="block">
                <div className="block-first-box">
                  <div className="block-box">
                    <img src={securityImg} alt="security"></img>
                    <p>Security</p>
                  </div>
                  <div className="block-box">
                    <img src={traceabilityImg} alt="trace"></img>
                    <p>Traceability</p>
                  </div>
                </div>
                <div className="block-first-box">
                  <div className="block-box">
                    <img src={boundlessImg} alt="boundless"></img>
                    <p>Boundless</p>
                  </div>
                  <div className="block-box">
                    <img src={transparencyImg} alt="transparency"></img>
                    <p>Transparency</p>
                  </div>
                </div>
              </div>
              <hr className="blockchain-hr" />
              <ul className="ecom">
                <li className="blockspace d-flex align-items-center">
                  <span className="check-sign-img-box">
                    <img src={checkTick} alt="check sign" />
                  </span>
                  <div className="blockspace-para">Support for Ethereum, Polygon, and Fantom platforms</div>
                </li>
                <li className="blockspace blockchain-para-charge">
                  <span className="check-sign-img-box">
                    <img src={checkTick} alt="check sign" />
                  </span>
                  <div className="blockchain-contact">
                    <div className="block-br-first blockspace-para"> Additional fee is charged</div>
                    <div className="block-br">
                      (Contact us for the Blockchain service)
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="contactus-container " id="contactus">
            <div className="contact-block">
              <div className="enquiry">
                <div className="direct-contact ">
                  <h1 className="contactUs-underline">CONTACT US</h1>
                  <div className="">
                    <ul className="contact-list">
                      <li className="list-item">
                        <GrLocation color="#5CC7C0" size={28} />
                        <div className="contact-text place">
                          1249/138 E, 15th Floor, Gems Tower Building
                          Charoenkrung Road, Suriyawongse, Bangrak, Sathon,
                          Bangkok 10500 THAILAND
                        </div>
                      </li>
                      <li className="list-item">
                        <BsTelephone color="#5CC7C0" size={28} />
                        <div className="contact-text contact-text-anchor phone">
                          <a href="tel:1-212-555-5555" title="Give me a call" className="contact-text-anchor">
                            +662 287 4925 (EN/TH)
                          </a>
                        </div>
                      </li>
                      <li className="list-item">
                        <CiMail color="#5CC7C0" size={28} />
                        <div className="contact-text gmail">
                          <a href="mailto:hello@gis247.net" title="Send me an email" className="contact-text-anchor">
                            hello@gis247.net
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="circle-earth-imgbox">
                  <img
                    src={circleEarth}
                    className="contact-img"
                    alt="circle-earth"
                  ></img>
                  <img
                    src={circleEarthTab}
                    className="contact-img-second"
                    alt="circletab"
                  ></img>
                </div>
              </div>

              <div className="contact-form">
                <h2 className="contact-form-topHeader">Enquiry</h2>
                <p className="contact-form-topPara"> Please feel free to contact us.</p>

                <form className="enquiry-from-box">
                  <div className="row common-row-show">
                    <div className="col-75 common-row-show-innerBox">
                      <input
                        type="text"
                        id="fname"
                        name="name"
                        placeholder="Name *"
                        className={formErrors?.name ? "border-red com-input-show required-text-show" : "com-input-show required-text-show"}
                        value={formData.name}
                        onChange={(e) => { setFormData({ ...formData, name: e.target.value }); setFormErrors({}) }}
                      />
                    </div>
                  </div>

                  <div className="row common-row-show">
                    <label htmlFor="buisnesstype" className="buisness">
                      Business Type <span className="asterick-red">*</span>
                    </label>
                    <div className="contact-check common-row-show-innerBox">
                      <div className="contact-check-singlerow">
                        <label className="contact">
                          Manufacturer
                          <input type="checkbox" className="enquiry-com-checkbox" value='Manufacturer' onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="contact">
                          Retailer
                          <input type="checkbox" className="enquiry-com-checkbox" value="Retailer" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="contact-check-singlerow">
                        <label className="contact">
                          Gem Stones /Diamonds
                          <input type="checkbox" className="enquiry-com-checkbox" value="Gem Stones /Diamonds" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="contact">
                          Wholesaler
                          <input type="checkbox" className="enquiry-com-checkbox" value="Wholesaler" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="contact-check-singlerow">
                        <label className="contact">
                          Agent
                          <input type="checkbox" className="enquiry-com-checkbox" value="Agent" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="contact other-checkmain">
                          Others :
                          <input type="checkbox" className="enquiry-com-checkbox" value="Others" onChange={(e) => checkboxinput(e)} />
                          <input
                            onChange={(e) => changeinput(e)}
                            className={formErrors?.business ? "border-red enquiry-checkInput " : "enquiry-checkInput "}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div className={formErrors?.bussinesscheck ? " business-error-show d-block" : " d-none "}>Please Select the Business Role</div>
                  </div>

                  <div className="row common-row-show">
                    <div className="col-25 common-row-show-innerBox"></div>
                    <div className="col-75 common-row-show-innerBox">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email *"
                        className={formErrors?.email ? "border-red com-input-show " : "com-input-show "}
                        value={formData.email}
                        onChange={(e) => { setFormData({ ...formData, email: e.target.value }); setFormErrors({}) }}
                      />
                    </div>
                  </div>

                  <div className="row common-row-show">
                    <div className="col-25 common-row-show-innerBox"></div>
                    <div className="col-75 common-row-show-innerBox">
                      <Select
                        placeholder="Country"
                        className="country-selector"
                        options={countries.map((country) => ({
                          value: country.name,
                          label: country.name,
                          code: country.phoneCode
                        }))}
                        value={formData.country ? { label: formData.country, value: formData.country, code: formData.phoneCode } : null}
                        onChange={(selectedOption) => SelectCountryChange(selectedOption, 'country')}
                      />
                    </div>
                  </div>

                  <div className="row common-row-show">
                    <div className="col-75 common-row-show-innerBox">
                      <div className={formErrors?.phone ? "border-red phone-tab" : "phone-tab"}>
                        <Select
                          placeholder="66"
                          className="select-phone"
                          options={countries.map((country) => ({
                            value: country.phoneCode,
                            label: country.phoneCode,
                          }))}
                          value={formData.phoneCode ? { label: formData.phoneCode, value: formData.phoneCode } : null}
                          onChange={(selectedOption) => SelectCountryChange(selectedOption, 'phoneCode')}
                        />
                        <input
                          type="number"
                          className="phone-input com-input-show"
                          id="phone"
                          name="phone"
                          placeholder="Phone"
                          value={formData.phone}
                          onChange={(e) => { setFormData({ ...formData, phone: e.target.value.slice(0, 10) }); setFormErrors({}) }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row common-row-show">
                    <div className="col-75 common-row-show-innerBox">
                      <input
                        type="text"
                        name="company"
                        placeholder="Company *"
                        className={formErrors?.company ? "border-red com-input-show " : "com-input-show "}
                        value={formData.company}
                        onChange={(e) => { setFormData({ ...formData, company: e.target.value }); setFormErrors({}) }}
                      />
                    </div>
                  </div>

                  <div className="row common-row-show">
                    <div className="col-25 common-row-show-innerBox"></div>
                    <div className="col-75 common-row-show-innerBox">
                      <textarea
                        id="subject"
                        name="subject"
                        placeholder="Description"
                        style={{ height: "144px" }}
                        className="com-textarea"
                        value={formData.description}
                        onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }}
                      ></textarea>
                    </div>
                  </div>

                  <div>
                    {submissionStatus?.status ?
                      <p className="text-success">{submissionStatus?.data}</p> :
                      <p className="text-danger">{submissionStatus?.data}</p>
                    }
                  </div>

                  <button onClick={submitform} className="contact-common-button">Enquiry</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {!showCookieBox && (
        <div className="wrapper-cookie">
          <div className="data">
            <p>
              If you accept cookies, we'll use them to improve and customise
              your experience and enable our partners to show you personalised
              ads when you visit other sites.
              <a href="/#"> Manage cookies and learn more</a>
            </p>
          </div>

          <div className="buttons">
            <button onClick={handleAccept} className="button" id="acceptBtn">
              Accept
            </button>
            <button className="button" onClick={handleDecline} id="declineBtn">
              Decline
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default Content;
