import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { SetdataTable, UnSetdataTable } from '../../common/api/setdatatable';
import './productList.scss';
import { success, error } from '../../common/helpers/toastify';
import { _Api } from '../../common/api/_call';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const ProductListTable = (props) => {
  const {
    ProductListTable,
    setProductListTable,
    filter,
    setsearchproductstatus,
    setUpdatedCart,
    setShowcheckout,
    parkid,
    order_id,
    order_type,
    order_edit,
    setInv_orderid,
    inv_orderid,
    setInv_ordertype,
    inv_ordertype,
    setBy_customorder,
    by_customorder,
    setBy_repair_order,
    by_repair_order,
    addparmission,
    finalprodata,
    uuid } = props
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const saleperson = useSelector((state) => state.app.saleperson)
  const [check, setCheck] = useState()
  const customer = useSelector((state) => state.app.customer)
  const navigate = useNavigate();
  useEffect(() => {
    callcolumns()
  }, [])
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_pos_catalogproduct',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const wishlistset = async (sku, product_id, wishliststatus) => {
    if (!customer?.id) {
      error(t('Customer is required!'))
      return
    }
    try {
      const postdata = {
        customer_id: customer?.id,
        isWishlist: wishliststatus,
        SKU: sku,
        type: filter === true ? 'catalog' : 'inventory',
        product_id: product_id,
      }
      let res = await _Api(postdata, `api/v1/POS/wishlist/updateWishlist`)
      if (res.code === 200) {
        let arry = [...ProductListTable];
        arry
          .filter((item) => item?.product_id === product_id)
          .map((result) => {
            result['isWishlist'] = res?.data?.isWishlist;
            return result;
          });
        setProductListTable(arry);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const AddtoCart = async (product_id, orderid) => {
    if (!customer?.id) {
      error(t('Customer is required!'))
      return
    }
    if (!saleperson?.value) {
      error(t('SalePerson is required!'))
      return
    }
    try {
      const postdata = {
        product_id: product_id,
        type: filter === true ? 'catalog' : 'inventory',
        customer_id: customer?.id,
        order_type: 'pos_order',
        sales_person_id: saleperson?.value,
        park_id: parkid,
        order_id: order_id ? order_id : orderid,
        sessioncartid: uuid ? uuid : "",
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`)
      if (res.code === 200) {
        setsearchproductstatus(true);
        success(t('Successfully Add to Cart!!'))
        if (parkid) {
          GetCartdetailpark()
        }
        else {
          if ((order_id && order_edit && order_type) || by_customorder || by_repair_order) {
            GetCartdetailpark();
          } else {
            setsearchproductstatus(true);
            setShowcheckout('sell');
          }
          setShowcheckout('sell');
        }
      } else {
        error(t('This Product Already in cart!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const GetCartdetailpark = async () => {
    try {
      const postdata = {
        park_id: parkid,
        order_id: inv_orderid ? inv_orderid : order_id,
        order_edit: order_edit,
        order_type: inv_ordertype ? inv_ordertype : order_type,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        if ((order_id && order_edit && order_type) || by_customorder || by_repair_order) {
          setUpdatedCart(res?.data);
          setsearchproductstatus(true);
          setShowcheckout('sell')
        }
        setUpdatedCart(res?.data);
        setShowcheckout('sell')
      } else {
        navigate('/app/pos/custom/createYourOwn')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    UnSetdataTable("pos_order_table")
    if (finalprodata?.length || ProductListTable?.length) {
      if (filter === true) {
        SetdataTable('pos_order_table', '4', [], [], '3')
      } else {
        SetdataTable('pos_order_table', '5', [], [], '4')
      }
    }
  }, [finalprodata, ProductListTable, filter])

  return (
    <>
      {
        filter === true ?
          <div className="list-view">
            {/* Catalog */}
            <div className="main-body-main-table-wrap">
              <table id="pos_order_table" className="common-table first-row-gray" style={{ width: "100%" }}>
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th className="pe-0">
                    </th>
                    <th>#</th>
                    <th className="user-name-width">Image</th>
                    <th>SKU</th>
                    <th>Collection</th>
                    <th className=" text-center">Metal</th>
                    <th className="text-center">Stone</th>
                    <th className="text-center">Size</th>
                    <th className="text-center">Price</th>
                    <th className="text-center"></th>
                    <th className="text-center"></th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>

                  {ProductListTable?.length
                    ? ProductListTable.map((result, tkey) => {
                      return (
                        <tr key={result?.product_id}>
                          <td>
                            <div className="com-check">
                              <input
                                id={result?.product_id}
                                className="pos_catalogproduct"
                                type="checkbox"
                              />
                              <label
                                htmlFor={result?.product_id}
                                className="com-check-label"
                              ></label>
                            </div>
                          </td>
                          <td>{tkey + 1}</td>
                          <td>
                            <div className="pro-img">
                              <img
                                src={
                                  result?.main_image ? result?.main_image : ''
                                }
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="link-color">
                            {result?.SKU ? result?.SKU : ''}
                          </td>
                          <td>
                            {result?.Collection_name
                              ? result?.Collection_name
                              : ''}
                          </td>
                          <td>
                            {result?.metal_name ? result?.metal_name : ''}
                          </td>
                          <td>
                            {result?.stone_name ? result?.stone_name : ''}
                          </td>
                          <td>{result?.size_name ? result?.size_name : ''}</td>
                          <td>{result?.price ? result?.price : ''}</td>
                          <td>  {result?.product_variants_enable === "1" ?
                            <img src={base_assets + "images/icons/variant-sm.png"} alt=""></img> :
                            result?.product_variants_enable === "2" ?
                              <img src={base_assets + "images/pro-ring-img.png"} alt=""></img> : ""}</td>
                          <td>
                            <div
                              onClick={() => result?.isWishlist ?
                                (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.product_id, result?.isWishlist)) :
                                (parmissiondatashow?.WishList?.Add && wishlistset(result?.SKU, result?.product_id, result?.isWishlist))}
                            >
                              <i
                                className={
                                  result?.isWishlist === 1
                                    ? 'fa fa-heart wishlist-icon'
                                    : 'fa fa-heart-o wishlist-icon'
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </td>
                          <td className="addtocart">
                            <button className={addparmission ? "btn-style" : "d-none"}
                              onClick={() =>
                                AddtoCart(result?.product_id, result?.order_id)}>
                              <img
                                src={
                                  base_assets + 'images/pos/icons/add-pro.png'
                                }
                                alt=""
                              />
                            </button>
                          </td>

                        </tr>
                      )
                    })
                    : ''}
                </tbody>
              </table>
            </div>
          </div>
          :
          <>
            <div className="list-view table-responsive">
              {/* inventary */}
              <div className="main-body-main-table-wrap table-responsive">
                <table id="pos_order_table" className="common-table first-row-gray">
                  <thead>
                    <tr className="text-nowrap align-middle">
                      <th className="pe-0">
                      </th>
                      <th>#</th>
                      <th className="px-2"></th>
                      <th className="user-name-width">Image</th>
                      <th>SKU</th>
                      <th>Collection</th>
                      <th className=" text-center">Metal</th>
                      <th className="text-center">Stone</th>
                      <th className="text-center">Size</th>
                      <th className="text-center">Stock ID</th>
                      <th className="text-center">Qty</th>
                      <th className="text-center">Price</th>
                      <th className="text-center">Location</th>
                      <th className="text-center">Status</th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalprodata?.length
                      ? finalprodata.map((result, tkey) => {
                        return (
                          <tr key={result?.product_id}>
                            <td>
                              <div className={
                                result?.status_type === "transit" || result?.status_type === "warehouse"
                                  ? "com-check checkbox-disabled"
                                  : (result?.status_type === "custom_order" && customer?.id === result?.pos?.customer_id) ||
                                    (result?.status_type === "repair_order" && customer?.id === result?.customer_id)
                                    ? "com-check"
                                    : result?.pos?.customer_id || result?.customer_id || result?.status_type === "reserve"
                                      ? "com-check checkbox-disabled"
                                      : "com-check"
                              }>
                                <input
                                  id={result?.product_id}
                                  className="pos_catalogproduct"
                                  onClick={(e) => {
                                    setBy_customorder(result?.by_custom_order);
                                    setInv_orderid(result?.order_id);
                                    setInv_ordertype(result?.order_type);
                                    setBy_repair_order(result?.by_repair_order);
                                    setCheck(e.target.checked);

                                  }}
                                  type="checkbox"
                                />
                                <label
                                  htmlFor={result?.product_id}
                                  className="com-check-label"
                                ></label>
                              </div>
                            </td>

                            <td>{tkey + 1}</td>
                            <td className='sku-col table_btn_expand_col' >
                              <div className="td-icons-wrap d-inline-block">
                                {result?.allocatable === true ? <div className="td-icons-wrap-link">
                                  <img
                                    className="img"
                                    src={
                                      base_assets +
                                      'images/icons/link-icon.svg'
                                    }
                                    alt=""
                                  />
                                </div> : ""}
                              </div>
                              <div className="td-icons-wrap-user d-inline-block">
                                <div className='tooltip-area-up'>
                                  {result?.status_stock_id === "custom_order" && result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user d-inline-block">
                                    <img
                                      className="img"
                                      src={
                                        base_assets +
                                        'images/icons/user-red.png'
                                      }
                                      alt=""
                                    />

                                    <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}
                                      {result?.POS_Order}<br />{result?.delivery_date}</span>
                                  </div> :
                                    result?.status_stock_id === "reserve" ?
                                      <div className="td-icons-wrap-user d-inline-block">
                                        <img
                                          className="img"
                                          src={
                                            base_assets +
                                            'images/icons/user-purple.png'
                                          }
                                          alt=""
                                        />

                                        <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}
                                          {result?.POS_Order}<br />{result?.delivery_date}</span>
                                      </div> :
                                      result?.status_stock_id === "repair_order" ?
                                        <div className="td-icons-wrap-user d-inline-block">
                                          <img
                                            className="img"
                                            src={
                                              base_assets +
                                              'images/icons/user-purple.png'
                                            }
                                            alt=""
                                          />

                                          <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}
                                            {result?.POS_Order}<br />{result?.delivery_date}</span>
                                        </div> :
                                        ""}

                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="pro-img">
                                <img
                                  src={
                                    result?.main_image ? result?.main_image : ''
                                  }
                                  alt=""
                                />
                              </div>
                            </td>
                            <td className="link-color">
                              {result?.SKU ? result?.SKU : ''}
                            </td>
                            <td>
                              {result?.Collection_name
                                ? result?.Collection_name
                                : ''}
                            </td>
                            <td>
                              {result?.metal_name ? result?.metal_name : ''}
                            </td>
                            <td>
                              {result?.stone_name ? result?.stone_name : ''}
                            </td>
                            <td>{result?.size_name ? result?.size_name : ''}</td>
                            {
                              result?.status_stock_id === "stock" ?
                                <td className="light-blue-text">
                                  <div className='tooltip-area-up'>
                                    {result?.stock_id ? result?.stock_id : ""}
                                    <span className="tooltip-area-up-text">{result?.delivery_date}</span>
                                  </div>
                                </td> :
                                result?.status_stock_id === "transit" ?
                                  <td className="status-green-text">
                                    <div className='tooltip-area-up'>
                                      {result?.stock_id ? result?.stock_id : ""}
                                      <span className="tooltip-area-up-text">{result?.delivery_date}</span>
                                    </div>
                                  </td> :
                                  result?.status_stock_id === "warehouse" ?
                                    <td className="status-gray-text">
                                      <div className='tooltip-area-up'>
                                        {result?.stock_id ? result?.stock_id : ""}
                                        <span className="tooltip-area-up-text">{result?.delivery_date}</span>
                                      </div>
                                    </td> :
                                    result?.status_stock_id === "reserve" ?
                                      <td className="yellow-text">
                                        <div className='tooltip-area-up'>
                                          {result?.stock_id ? result?.stock_id : ""}
                                          <span className="tooltip-area-up-text">{result?.customer_name} {result?.customer_name ? <br /> : ''}  {result?.delivery_date}</span>
                                        </div>
                                      </td> :
                                      result?.status_stock_id === "repair_order" ?
                                        <td className="purple-text">
                                          <div className='tooltip-area-up'>
                                            {result?.stock_id ? result?.stock_id : ""}
                                            <span className="tooltip-area-up-text">{result?.customer_name} {result?.customer_name ? <br /> : ''}  <br /> {result?.delivery_date}</span>
                                          </div>
                                        </td> :
                                        result?.status_stock_id === "custom_order" ?
                                          <td className="danger-text">
                                            <div className='tooltip-area-up'>
                                              {result?.stock_id ? result?.stock_id : ""}
                                              <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{result?.delivery_date}</span>
                                            </div>
                                          </td> :
                                          <td></td>
                            }
                            <td>{result?.po_QTY ? result?.po_QTY : ''}</td>
                            <td>{result?.price ? result?.price : ''}</td>
                            <td className='location-td'> <span className='link-color'>  {result?.location_name ? result?.location_name : ""} </span>   <span className='text-center location-td-sub'>{result?.warehouse_location_name ? result?.warehouse_location_name : ''}</span>   </td>
                            <td className=''>
                              {
                                result?.status_type === "stock" ?
                                  <div className="status-dot light-blue-bg  tooltip-area-up"><span className='tooltip-area-up-text'>
                                    {t("Stock")}</span></div> :
                                  result?.status_type === "transit" ?
                                    <div className="status-dot green-bg  tooltip-area-up"><span className='tooltip-area-up-text'>
                                      {t("Transit")}</span></div> :
                                    result?.status_type === "warehouse" ?
                                      <div className="status-dot status-gray-bg tooltip-area-up"><span className='tooltip-area-up-text'>
                                        {t("Warehouse")}</span></div> :
                                      result?.status_type === "reserve" ?
                                        <div className="status-dot yellow-bg tooltip-area-up"><span className='tooltip-area-up-text'>
                                          {t("Reserve")}</span></div> :
                                        result?.status_type === "repair_order" ?
                                          <div className="status-dot purple-bg tooltip-area-up"><span className='tooltip-area-up-text'>
                                            {t("Repair Order")}</span></div> :
                                          result?.status_type === "custom_order" ?
                                            <div className="status-dot danger-bg tooltip-area-up">
                                              <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{result?.delivery_date}</span>
                                            </div> : ""
                              }
                            </td>
                            <td>  {result?.product_variants_enable === "1" ?
                              <img src={base_assets + "images/icons/variant-sm.png"} alt=""></img> :
                              result?.product_variants_enable === "2" ?
                                <img src={base_assets + "images/pro-ring-img.png"} alt=""></img> : ""}</td>
                            <td>
                              <div
                                onClick={() => result?.isWishlist ?
                                  (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.product_id, result?.isWishlist)) :
                                  (parmissiondatashow?.WishList?.Add && wishlistset(result?.SKU, result?.product_id, result?.isWishlist))}
                              >
                                <i
                                  className={
                                    result?.isWishlist === 1
                                      ? 'fa fa-heart wishlist-icon'
                                      : 'fa fa-heart-o wishlist-icon'
                                  }
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </td>
                            <td className="addtocart">
                              <button className="btn-style"
                                onClick={() => { check === true ? AddtoCart(result?.product_id, result?.order_id) : error(t("No item Selected!!")) }}>
                                <img
                                  src={
                                    base_assets + 'images/pos/icons/add-pro.png'
                                  }
                                  alt=""
                                />
                              </button>
                            </td>
                          </tr>
                        )
                      })
                      : ''}
                  </tbody>
                </table>
              </div>
            </div>
          </>
      }
    </>
  )
}

export default ProductListTable;
