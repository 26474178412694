import React from 'react'

const AppoinmentIcon = (props) => {
    const { width, height } = props
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <g clipPath="url(#clip0_2934_101573)">
                <path d="M5.5998 0V8M18.3998 0V8M4.7998 12H9.5998M19.1998 12H14.3998M4.7998 16.8H9.5998M14.3998 16.8H19.1998M2.3998 4H21.5998C22.0242 4 22.4311 4.16857 22.7312 4.46863C23.0312 4.76869 23.1998 5.17565 23.1998 5.6V21.6C23.1998 22.0243 23.0312 22.4313 22.7312 22.7314C22.4311 23.0314 22.0242 23.2 21.5998 23.2H2.3998C1.97546 23.2 1.56849 23.0314 1.26843 22.7314C0.968376 22.4313 0.799805 22.0243 0.799805 21.6V5.6C0.799805 5.17565 0.968376 4.76869 1.26843 4.46863C1.56849 4.16857 1.97546 4 2.3998 4Z" stroke="black" />
            </g>
            <defs>
                <clipPath id="clip0_2934_101573">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg >

    )
}

export default AppoinmentIcon