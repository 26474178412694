import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { _Api } from '../../api/_call'
const Device = (props) => {
  const { setDashboardhideshow, setDefaultset } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const [devicelist, setDevicelist] = useState()
  const { t } = useTranslation();
  const Devicedata = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/dashboard/deviceStatics`)
      if (res?.code === 200) {
        setDevicelist(res?.statics)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    Devicedata()
  }, [])
  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Device")}
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li
                onClick={() => {
                  setDashboardhideshow(prevState => ({
                    ...prevState,
                    Device: 0
                  }));
                  setDefaultset(true)
                }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area py-3">
          <div className="d-flex align-items-center my-4">
            <i className="fa fa-mobile fs-3 me-2" aria-hidden="true"></i>
            <span className="me-auto">{t("Mobile")}</span>
            <span>{devicelist?.Mobile ? parseFloat(devicelist?.Mobile).toFixed(2) : 0.00}%</span>
          </div>
          <div className="d-flex align-items-center my-4">
            <i className="fa fa-desktop fs-6 me-2" aria-hidden="true"></i>
            <span className="me-auto">{t("Computer")}</span>
            <span>{devicelist?.Computer ? parseFloat(devicelist?.Computer).toFixed(2) : 0.00}%</span>
          </div>
          <div className="d-flex align-items-center my-4">
            <i className="fa fa-tablet fs-4 me-2" aria-hidden="true"></i>
            <span className="me-auto">{t("Tablet")}</span>
            <span>{devicelist?.Tablet ? parseFloat(devicelist?.Tablet).toFixed(2) : 0.00}%</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Device
