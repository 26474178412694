import React, { useEffect, useState } from "react";
import RecentView from "../../components/RecentView";
import CollectionSlider from "../../components/CollectionSlider"
import ImageGallery from 'react-image-gallery';
import HeartIcon from "../../assets/icons/HeartIcon";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import MinusIcon from "../../assets/icons/MinusIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import { _Api } from "../../../common/api";
import AddToCartModal from "../../components/AddToCart";
import CartNotification from "../../components/CartNotification"
import ArroeIcontop from '../../assets/images/arrowIcontop.png'
import SizeGuideModal from "../../components/SizeGuide";
import Spinner from "../../../common/Spinner";
import { wishlistobjectheader } from "../../../../../source/app/appSlice";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
const ProductDetailpage = () => {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(1);
    const [showdropDown, setShowDropdown] = useState(false)
    const [productdetails, setProductdetails] = useState()
    const [variantcombo, setVariantcombo] = useState({ metal: '', stone: '', size: '' });
    const [isopennotifycart, setIsopennotifycart] = useState(false)
    const [isopencart, setisopencart] = useState(false)
    const [loading, setloading] = useState(true)
    const fullPath = useLocation();
    const parts = fullPath.pathname.split('/');
    console.log(parts, "check parts")
    const params = new URLSearchParams(fullPath.search);
    const vValue = params.get('v');
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    let navigate = useNavigate();
    const [variantdata, setVarientdata] = useState([])
    const [varientloading, setVarientloading] = useState(false)
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
    const setwishlist = () => {
        let setobj = { ...productdetails }
        console.log(setobj, "setwishlist")
        if (setobj.isWishlist) {
            setobj.isWishlist = 0
            let removewislistarr = [...localmywishlistremove]
            removewislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(removewislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
            if (localmywishlist?.length) {
                const updatedData = localmywishlist.filter(id => id !== setobj?._id);
                localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
                dispatch(wishlistobjectheader(updatedData));
            }
        } else {
            setobj.isWishlist = 1
            let addwislistarr = [...localmywishlist]
            addwislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(addwislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlist', JSON.stringify(unique_ids ? unique_ids : []));
            dispatch(wishlistobjectheader(unique_ids));
            if (localmywishlistremove?.length) {
                const updatedData = localmywishlistremove.filter(removeid => removeid !== setobj?._id);
                localStorage.setItem('mywishlistremove', JSON.stringify(updatedData ? updatedData : []));
            }
        }
        setProductdetails(setobj)
    }
    const getproductdetails = async () => {
        setloading(true)

        let localRecentViewed = localStorage.getItem('RecentViewed') ? JSON.parse(localStorage.getItem('RecentViewed')) : [];
        setTimeout(() => {
            if (!localRecentViewed.filter(e => e === state?.id).length) {
                localRecentViewed.push(state?.id);
                localStorage.setItem('RecentViewed', JSON.stringify(localRecentViewed));
            }
        }, 15000)

        try {
            let postdata = {
                id: parts[2],
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/getProductDetails`)
            if (res?.statusCode === 200) {
                console.log(res?.data, "product detail")
                setProductdetails(res?.data);
                if (!vValue) {
                    const combo = { metal: res?.data?.metal?.length ? res?.data?.metal[0] : '', stone: res?.data?.stone?.length ? res?.data?.stone[0] : '', size: '' }
                    setVariantcombo(combo);
                }

            }
            setloading(false)
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const getvarient = async () => {
        setVarientloading(true)
        try {
            let postdata = {
                id: parts[2],
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `product/get-product-variants`)
            if (res?.statusCode === 200) {
                setVarientdata(res?.data)
                if (vValue) {
                    if (res?.data?.length) {
                        res?.data?.forEach((result) => {
                            if (vValue === result?._id) {
                                const combo = { metal: result?.metal_name ? result?.metal_name : '', stone: result?.stone_name ? result?.stone_name : '', size: result?.size_name ? result?.size_name : '' }
                                setVariantcombo(combo);
                            }
                        });
                    }
                }
                setVarientloading(false)
            } else {
                setVarientloading(false)
            }
        }
        catch (err) {
            console.log(err, "err")
        }

    }
    const combination_variant = (variant, data) => {
        let variant_sku = '';
        if (variant === 'metal') {
            variant_sku = productdetails?.SKU + data?.value + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, metal: data });
        }
        if (variant === 'stone') {
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + data?.value + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, stone: data });
        }
        if (variant === 'size') {
            let allsize = document.querySelectorAll(".sizeselect");
            allsize.forEach(sizze => {
                sizze.classList.remove('selectedSize');
                if (sizze.textContent === data?.label) {
                    sizze.classList.add('selectedSize');
                }
            });
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + data?.value;
            setVariantcombo({ ...variantcombo, size: data });
        }
        if (variant_sku) {
            let variant = variantdata?.length ? variantdata.filter(e => e?.SKU === variant_sku)[0] : '0.00';
            document.getElementById("variantprice").textContent = variant?.price;
            document.getElementById("variantprice").setAttribute('data-caritemid', variant?._id);
            if (variant?.main_image) {
                let imagearray = [{
                    "original": variant?.main_image,
                    "thumbnail": variant?.main_image
                }];
                if (productdetails?.images?.length) {
                    const newArr2 = [...productdetails?.images];
                    newArr2[0] = imagearray[0];
                    setProductdetails({
                        ...productdetails,
                        images: newArr2
                    });
                }

            }
        }
    }
    const addtocart = () => {
        const itemid = document.getElementById("variantprice").getAttribute('data-caritemid');
        if (itemid) {
            let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
            let checkitem = localitem.filter(e => e?.id === itemid);
            if (checkitem?.length) {
                localitem.forEach((e) => {
                    if (e?.id === itemid) {
                        e.qty += parseInt(quantity);
                    }
                });
                localStorage.setItem('CartItems', JSON.stringify(localitem));
            } else {
                localitem.push({ 'id': itemid, 'qty': quantity });
                localStorage.setItem('CartItems', JSON.stringify(localitem));
            }
            setIsopennotifycart(true);

        }

    }
    useEffect(() => {
        if (state?.id || parts[2]) {
            getproductdetails();
            getvarient()
        } else {
            navigate("/");
        }
        // eslint-disable-next-line 
    }, [state?.id, vValue, parts[2]])

    return (
        <>
            {
                loading ?
                    (<div className=" w-100 vh-100 d-flex justify-content-center align-items-center" >
                        <Spinner />
                    </div >) : (


                        <div className="ecommerce-main-layout">
                            <div className="ecommerce-main-layout-product-details">
                                <div className="ecommerce-main-layout-product-details-image-section">
                                    <ImageGallery
                                        items={productdetails?.images ? productdetails?.images : []}
                                        showPlayButton={false}
                                        showFullscreenButton={false}
                                        showNav={false}
                                        infinite={true}
                                        disableSwipe={false}
                                        disableThumbnailSwipe={false}
                                        additionalClass="img-view-slider"
                                    />
                                </div>
                                <div className="ecommerce-main-layout-product-details-section">
                                    <div className="ecommerce-main-layout-product-details-section-product-details">
                                        <div className={`ecommerce-main-layout-product-details-section-product-details-name d-flex justify-content-between align-items-center ${productdetails?.isWishlist ? "likeHeartBtn" : ""}`}>
                                            {productdetails?.name}
                                            <div className="d-inline-block" onClick={() => setwishlist()}>
                                                <HeartIcon />
                                            </div>
                                        </div>
                                        <span className="ecommerce-main-layout-product-details-section-product-details-serialNumber">
                                            {productdetails?.SKU}
                                        </span>
                                        <div className="ecommerce-main-layout-product-details-section-product-details-collectionName d-flex justify-content-between align-items-center">
                                            {productdetails?.Matatag}
                                        </div>
                                    </div>
                                    <span className="ecommerce-main-layout-product-details-section-product-price " id="variantprice" data-caritemid={productdetails?._id} >
                                        {productdetails?.price}
                                    </span>
                                    {varientloading ? (<div style={{ marginTop: '20px', marginBottom: '20px' }} >
                                        <Spinner />
                                    </div>) : (
                                        <div>
                                            {productdetails?.metal?.length ?
                                                <div className="ecommerce-main-layout-product-details-section-product-attributes">
                                                    <label>{t("Metal")}</label>
                                                    <Select
                                                        className="ecommerce-dropdown"
                                                        options={productdetails?.metal}
                                                        value={variantcombo?.metal}
                                                        onChange={(e) => combination_variant('metal', e)}
                                                        classNamePrefix="common-select"
                                                        isSearchable={false}

                                                    />
                                                </div>
                                                : ''}
                                            {productdetails?.stone?.length ?
                                                <div className="ecommerce-main-layout-product-details-section-product-attributes">
                                                    <label>{t("Stone")}</label>
                                                    <Select
                                                        className="ecommerce-dropdown"
                                                        options={productdetails?.stone}
                                                        value={variantcombo?.stone}
                                                        onChange={(e) => combination_variant('stone', e)}
                                                        classNamePrefix="common-select"
                                                        isSearchable={false}

                                                    />
                                                </div>
                                                : ""}

                                            {productdetails?.size?.length ?
                                                <>
                                                    <div className="ecommerce-main-layout-product-details-section-product-attributes">
                                                        <div className="d-flex justify-content-between align-items-center my-3">
                                                            <label>{t("Size")}</label>
                                                            <button style={{ border: 'none', background: 'none' }} data-bs-toggle="modal" data-bs-target="#sizeGuideBack" className="size-guide">{t("Size Guide")} <span className="size-guide-imgBox"> <img src={ArroeIcontop} alt="" /></span></button>
                                                        </div>
                                                        <div className="ecommerce-main-layout-product-details-section-product-attributes-ringSizes-container d-flex flex-wrap align-items-center">
                                                            {productdetails?.size?.length ? productdetails?.size.map((result, key) => {
                                                                return (
                                                                    <span key={key} onClick={() => combination_variant('size', result)} className={variantcombo?.size?.label === result?.label ? "d-inline-flex align-items-center justify-content-center sizeselect selectedSize" : "d-inline-flex align-items-center justify-content-center sizeselect"}>
                                                                        {result?.label}
                                                                    </span>
                                                                )
                                                            }) : ""}
                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                        </div>
                                    )}

                                    <div className="ecommerce-main-layout-product-details-section-product-attributes">
                                        <label className="ecommerce-main-layout-product-details-section-product-attributes-quantityLabel">{t("Quantity")}</label>
                                        <div className="ecommerce-main-layout-product-details-section-product-attributes-qutantityCounter">
                                            <button disabled={quantity > 1 ? false : true} onClick={() => { setQuantity(quantity - 1) }} className="minusCountBtn">-</button>
                                            <span className="d-inline-block text-center quantityShow">{quantity}</span>
                                            <button onClick={() => setQuantity(quantity + 1)} className="plusCountBtn">+</button>
                                        </div>

                                    </div>


                                    <button disabled={!productdetails?.size?.length ? (!variantcombo?.size ? false : true) : (!variantcombo?.size ? true : false)}
                                        onClick={addtocart} className="ecommerce-main-layout-product-details-section-addToCartBtn">
                                        {t("Add to Cart")}
                                    </button>
                                    <div className={`ecommerce-main-layout-product-details-section-product-discription ${showdropDown && "border-line"} `}>
                                        <div className="d-flex justify-content-between align-items-center title" onClick={() => setShowDropdown(!showdropDown)}>
                                            <label>{t("Description")}</label>
                                            {showdropDown ? <MinusIcon width={20} height={20} /> :
                                                <PlusIcon width={20} height={20} />}
                                        </div>
                                        {showdropDown ? <div className="ecommerce-main-layout-product-details-section-product-discription-text">
                                            {productdetails?.Description}
                                        </div> : null}
                                    </div>
                                </div>
                            </div>

                            <div className="ecommerce-main-layout-collection">
                                <CollectionSlider />
                            </div>
                            <div className="ecommerce-main-layout-recent_view-product">
                                <RecentView currentid={state?.id}
                                    setWishliatdata={setWishliatdata} wishlistdata={wishlistdata} />
                            </div>
                            <AddToCartModal setisopencart={setisopencart} isopencart={isopencart} />
                            {isopennotifycart ? <CartNotification isopennotifycart={isopennotifycart} setIsopennotifycart={setIsopennotifycart} setisopencart={setisopencart} /> : ''}
                            <SizeGuideModal />
                        </div >
                    )}
        </>
    )
}

export default ProductDetailpage