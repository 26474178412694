import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RightArrow from '../../common/icons/right-arrow'
import { _Api } from '../../api/_call'
import { useLocation } from 'react-router-dom'
const VoucherType = (props) => {
  const { locationid, locationkey } = props
  const dataFetched = useRef();
  const { state } = useLocation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation()
  const [grpoulist, setGrouplist] = useState()
  const getgrpouplist = useCallback(async () => {
    try {
      let postdata = {
        "location_id": state?.location_id ? state?.location_id : locationid
      }
      let res = await _Api(postdata, `api/v1/App/quicksetup/groupList`)
      if (res?.code === 200) {
        setGrouplist(res?.data)
        dataFetched.current = false;
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [state, locationid]);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getgrpouplist()
  }, [getgrpouplist])
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        {!locationkey ?
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              <Link
                className="text-decoration-none"
                to="/app/admin/setup/quickstoresetup"
              >
                {t('Quick Store')}
              </Link>
              <RightArrow />
              <span className="">{t('Voucher Type')}</span>
            </h1>
          </div>
          : ""}
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="bg-white p-4 rounded-3">
            <h6 className="mb-5">
              {t("Locations")}   {'  '}
              <Link
                to="/app/admin/setup/quickstoresetup"
                className="main-green-color text-decoration-none"
              >
                {grpoulist?.location_name ? grpoulist?.location_name : ""}
              </Link>
            </h6>
            <div className="row">
              <div className="col-md-6 mb-5">
                <div className="border rounded-3">
                  <div className="d-flex w-100 align-items-center mb-4 p-3 border-bottom">
                    <h6 className="main-green-text fw-semibold mb-0">
                      {t('INVENTORY')}
                    </h6>
                    <Link
                      to="/app/admin/setup/quickstoresetup/vouchertypedetail"
                      className="ms-auto"
                      state={{
                        location_id: state?.location_id ? state?.location_id : locationid,
                        type: "inventory",
                        locationkey: locationkey
                      }}
                    >
                      <img
                        src={base_assets + 'images/admin/icons/Edit.png'}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="m-3">
                    {grpoulist?.inv?.length ? grpoulist?.inv.map((result, key) => {
                      return (
                        <div className="mb-3" key={key}>
                          {result?.Status ? <img
                            className="me-3"
                            src={base_assets + 'images/icons/True.png'}
                            alt=""
                          /> :
                            <img
                              className="me-3"
                              src={base_assets + 'images/icons/False.png'}
                              alt=""
                            />}

                          <span>{result?.title ? t(result?.title) : ""}</span>
                        </div>
                      )
                    }) : ""}

                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="border rounded-3">
                  <div className="d-flex w-100 align-items-center mb-4 p-3 border-bottom">
                    <h6 className="main-green-text fw-semibold mb-0">
                      {t('POS')}
                    </h6>
                    <Link
                      to="/app/admin/setup/quickstoresetup/vouchertypedetail"
                      className="ms-auto"
                      state={{
                        location_id: state?.location_id ? state?.location_id : locationid,
                        type: "pos",
                        locationkey: locationkey
                      }}
                    >
                      <img
                        src={base_assets + 'images/admin/icons/Edit.png'}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="m-3">
                    {grpoulist?.pos?.length ? grpoulist?.pos.map((result, key) => {
                      return (
                        <div className="mb-3" key={key}>
                          {result?.Status ? <img
                            className="me-3"
                            src={base_assets + 'images/icons/True.png'}
                            alt=""
                          /> :
                            <img
                              className="me-3"
                              src={base_assets + 'images/icons/False.png'}
                              alt=""
                            />}

                          <span>{result?.title ? t(result?.title) : ""}</span>
                        </div>
                      )
                    }) : ""}

                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-5">
                <div className="border rounded-3">
                  <div className="row mb-4 mx-0">
                    <div className="col-md-3 p-3 border-bottom">
                      <h6 className="fw-semibold mb-0">
                        {t('Cash register name')}
                      </h6>
                    </div>
                    <div className="col-md-9 p-3 border-bottom">
                      <div className="d-flex w-100 align-items-center">
                        <h6 className="main-green-text fw-semibold mb-0">
                          {t('Cash Register')}
                        </h6>
                        <Link
                          state={{
                            location_id: state?.location_id ? state?.location_id : locationid,
                            locationkey: locationkey
                          }}
                          to="/app/admin/setup/quickstoresetup/cashregister"
                          className="ms-auto"
                        >
                          <img
                            src={base_assets + 'images/admin/icons/Edit.png'}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-9">
                      {grpoulist?.cashRegistar?.length ? grpoulist?.cashRegistar.map((result, key) => {
                        return (
                          <div className="mb-3" key={key}>
                            {result?.Status ? <img
                              className="me-3"
                              src={base_assets + 'images/icons/True.png'}
                              alt=""
                            /> :
                              <img
                                className="me-3"
                                src={base_assets + 'images/icons/False.png'}
                                alt=""
                              />}

                            <span>{result?.title ? t(result?.title) : ""}</span>
                          </div>
                        )
                      }) : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoucherType
