import $ from 'jquery';
import 'datatables.net-dt'
import 'datatables.net-fixedcolumns';
export const SetdataTable = function (ID = "pos_order_table", fixedColumns = 5, newcolumndata = [], numericsort, fixedColumnsRight = 0) {
  var columns_ = [];
  var nonsortarray = [];
  newcolumndata?.map((result, key) => {
    if (result?.status) {
      columns_.push({ "visible": true });
      if (result?.sort === 0) {
        nonsortarray.push(key);
      }
    } else {
      columns_.push({ "visible": false });
    }
    return true;
  })
  setTimeout(() => {
    var table_opt = {
      destroy: true,
      scrollY: "300px",
      scrollX: true,
      scrollCollapse: true,
      paging: false,
      bFilter: false,
      bInfo: false,
      rowId: [0],
      autoWidth: true,
      searching: false,
      processing: true,
      fixedColumns: {
        left: fixedColumns,
        right: fixedColumnsRight
      },
      columnDefs: [
        { "type": "html-num-fmt", "targets": numericsort },
        {
          'targets': nonsortarray,
          'orderable': false,
        }
      ],
    };
    if (columns_.length) {
      table_opt.columns = columns_;
    }

    $('#' + ID).DataTable(table_opt);

  }, 100);
};

export const UnSetdataTable = (ID = "pos_order_table") => {
  var table = $('#' + ID).DataTable();
  table.destroy();
  return true;
};

