import React from 'react'
import './DefaultErrorPage.scss'
import errorpage from '../assets/images/pos/errorpage.gif'
import { Link } from 'react-router-dom'

const DefaultErrorPage = () => {
    return(
        <div className='defaultpage ecommerce-main-layout-bodyContent'>
            <div className='defaultpage-container'>
                <div className='defaultpage-container-image'><img src={errorpage} alt='errorpage'/></div>
                <div className='defaultpage-container-title'>Sorry, this page is currently <span className='specialtext'> Unavailable !</span></div>
                <div className='defaultpage-container-buttons'>
                    <Link to="https://gis247.net/" className='defaultpage-container-buttons-btn'>Explore GIS</Link>
                    <Link to="https://gis247.net/login" className='defaultpage-container-buttons-btn'>Start for free</Link>
                </div>
            </div>
        </div>
    )
}

export default DefaultErrorPage

