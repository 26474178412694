import currencyFormatter from "currency-formatter";
import { store } from "../../../source/index";
import { _Api } from '../../api/_call';
import {
    updateTotalCounts,
} from '../../../source/app/appSlice';
export const toFormatPrice = (amount, args = {}, customcurrency) => {
    if (amount === null || amount === undefined) {
        return 0;
    }
    var state = store.getState();
    var OrgSettings = state?.app?.organisation_settings;
    var price_format_options = OrgSettings?.price_format;
    var currency = customcurrency ? customcurrency : price_format_options?.currency || "USD";
    var decimal_point = price_format_options?.decimal_point || 2;
    var thousand_separator = price_format_options?.thousand_separator || ",";
    var decimal_separator = '.';
    if (thousand_separator.search(".")) {
        decimal_separator = ",";
    }
    if (thousand_separator === ".") {
        let amountString = amount?.toString();
        let formattedAmount = amountString?.replace(/\.00$/, '');
        amount = formattedAmount?.replace(/\./g, '');
    }
    var options = {
        code: currency,
        decimal: decimal_separator,
        thousand: thousand_separator,
        precision: decimal_point,
        format: '%v'
    }

    if (args.addSymbol) {
        options.format = '%s %v';

        if (currency === "AED") {
            options.format = '%v %s';
            options.symbol = "AED";
        }
    }
    amount = currencyFormatter.format(amount, options);
    return amount;
}


export const toUnformatPrice = (amount, args = {}) => {
    if (amount === null || amount === undefined) {
        return 0;
    }
    var state = store.getState();
    var OrgSettings = state?.app?.organisation_settings;
    var price_format_options = OrgSettings?.price_format;
    var currency = price_format_options?.currency || "USD";
    var decimal_point = price_format_options?.decimal_point || 2;
    var thousand_separator = price_format_options?.thousand_separator || ",";
    var decimal_separator = '.';
    if (thousand_separator.search(".")) {
        decimal_separator = ",";
    }
    if (thousand_separator === ".") {
        let amountString = amount?.toString();
        let formattedAmount = amountString?.replace(/\.00$/, '');
        amount = formattedAmount?.replace(/\./g, '');
    }

    var options = {
        code: currency,
        decimal: decimal_separator,
        thousand: thousand_separator,
        precision: decimal_point,
        format: '%v'
    }
    if (args.addSymbol) {
        options.format = '%s %v';
    }
    amount = currencyFormatter.unformat(amount, options);
    return amount;
}

export const transactionTotals = async () => {
    try {
        const postdata = {};
        let countdata = await _Api(postdata, `api/v1/Inventory/common/transactionTotals`);
        if (countdata.code === 200) {
            store.dispatch(updateTotalCounts(countdata?.data));

        }
    } catch (err) {
        console.log(err, "err");
    }
}
