import React, { useState, useEffect, useCallback } from 'react'
import Chart from 'react-apexcharts'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const SalesTheMost = (props) => {
  const { t } = useTranslation();
  const { duplicateHandler, deletedublicate } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const [graphdata, setGraphdata] = useState()
  const [tabledata, setTabledata] = useState([])
  const [piechartdata, setPiechartdata] = useState({ label: [], data: [] })
  const [completeDataObject, setCompliteDataObject] = useState({
    "topnumber": {
      lable: "Top 5",
      value: 5
    },
    "timeset": "Monthly",
    "graphtype": "Chart",
    "Collection": "Collection"
  })
  let time_periods = ['Daily', 'Monthly', 'Yearly', 'Weekly']
  let topsetnumber = [
    {
      lable: "ALL",
      value: 0
    },
    {
      lable: "Top 5",
      value: 5
    },
    {
      lable: "Top 10",
      value: 10
    },
    {
      lable: "Top 15",
      value: 15
    }
  ]
  const Collectionarr = ["Item", "Collection", "SKU", "Locations"]
  const getorderthemost = useCallback(async () => {
    try {
      let postdata = {
        "graphtype": completeDataObject?.graphtype,
        "duration": completeDataObject?.timeset,
        "type": completeDataObject?.Collection,
        "topNumber": completeDataObject?.topnumber?.value
      }
      let res = await _Api(postdata, `api/v1/dashboard/salesMostStatics`)
      if (res?.code === 200) {
        if (postdata?.graphtype === "Chart") {
          setGraphdata(res?.data)
        } else if (postdata?.graphtype === "Table") {
          setTabledata(res?.statics)
        } else if (postdata?.graphtype === "PieChart") {
          setPiechartdata(res?.statics)
        }
      } else {
        setGraphdata()
        setTabledata([])
        setPiechartdata({ label: [], data: [] })
      }
    } catch (err) {
      console.log(err, "err")
    }
  }, [completeDataObject]);

  useEffect(() => {
    getorderthemost()
  }, [completeDataObject, getorderthemost])
  const salesTheMostChart = {
    series: graphdata?.data?.length ? graphdata?.data : [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '65%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: graphdata?.xaxis?.length ? graphdata?.xaxis : [],
      },
      yaxis: [
        {
          title: {
            text: 'Qty',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Value',
          },
        },
      ],
      fill: {
        opacity: 1,
      },
      colors: graphdata?.color?.length ? graphdata?.color : [],
    },
  };
  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const paiChart = {
    options: {
      chart: {
        type: "donut",
        width: 170,
        height: 170,
      },
      labels: piechartdata?.xaxis?.length ? piechartdata?.xaxis : [],
      title: {
        text: "Pie Chart",
        align: "center",
        style: {
          fontSize: "20px",
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
      legend: {
        position: "bottom",
      },
      colors: Array.from({ length: piechartdata?.xaxis?.length }, () => generateRandomColor()),
    },
    series: piechartdata?.data?.length ? piechartdata?.data : [],
  };
  const SalesTheMostInstance = () => {
    return (

      <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
        <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
        <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
          <div className="dashboard-area-cards-single-header d-flex align-items-center">
            <div className="w-auto pe-0 drag-btn">
              <img
                className="drag-btn-icon"
                src={base_assets + 'images/admin/icons/drag-icon.png'}
                alt=""
              />
            </div>
            <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown ms-3">
              <span
                className="btn dropdown-toggle fw-semibold"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t(`${completeDataObject?.topnumber?.lable}`)}
                <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
              </span>
              <ul className="dropdown-menu">
                {topsetnumber?.length ? topsetnumber.map((result, key) => {
                  return (
                    <li key={key}
                      onClick={() =>
                        setCompliteDataObject(prevState => ({
                          ...prevState,
                          topnumber: result
                        }))}>
                      <span className="dropdown-item">{result?.lable ? result?.lable : ""}</span>
                    </li>
                  )
                }) : ""}

              </ul>
            </div>
            <div className="dashboard-area-cards-single-heading fw-semibold ms-2 me-auto">
              {t("Sales The Most")}
            </div>
            <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
              <span
                className="btn dropdown-toggle border-right-radius-0 fw-semibold"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t(`${completeDataObject?.Collection}`)}
                <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
              </span>
              <ul className="dropdown-menu">
                {Collectionarr?.length ? Collectionarr.map((result, key) => {
                  return (
                    <li key={key + 1}
                      onClick={() =>
                        setCompliteDataObject(prevState => ({
                          ...prevState,
                          Collection: result
                        }))}>
                      <span className="dropdown-item">{t(`${result}`)}</span>
                    </li>
                  )
                }) : ""}
              </ul>
            </div>
            <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
              <span
                className="btn dropdown-toggle border-left-radius-0 fw-semibold"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t(`${completeDataObject?.timeset}`)}
                <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
              </span>
              <ul className="dropdown-menu">
                {time_periods?.length ? time_periods.map((result, key) => {
                  return (
                    <li key={key}
                      onClick={() =>
                        setCompliteDataObject(prevState => ({
                          ...prevState,
                          timeset: result
                        }))}>
                      <span className="dropdown-item">{t(`${result}`)}</span>
                    </li>
                  )
                }) : ""}
              </ul>
            </div>
            <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
              <span
                className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt='' />
              </span>
              <ul className="dropdown-menu">
                <li
                  onClick={() =>
                    setCompliteDataObject(prevState => ({
                      ...prevState,
                      graphtype: "Chart"
                    }))}>
                  <span className="dropdown-item">
                    <i className="fa fa-line-chart me-2" aria-hidden="true"></i>{' '}
                    {t("Chart")}
                  </span>
                </li>
                <li
                  onClick={() =>
                    setCompliteDataObject(prevState => ({
                      ...prevState,
                      graphtype: "PieChart"
                    }))}>
                  <span className="dropdown-item">
                    <i className="fa fa-pie-chart me-2" aria-hidden="true"></i>{' '}
                    {t("Pie Chart")}
                  </span>
                </li>
                <li
                  onClick={() =>
                    setCompliteDataObject(prevState => ({
                      ...prevState,
                      graphtype: "Table"
                    }))}>
                  <span className="dropdown-item">
                    <i className="fa fa-table me-2" aria-hidden="true"></i> {t("Table")}
                  </span>
                </li>
                <li onClick={() => setFixed(current => !current)}>
                  <span className="dropdown-item">
                    {fixed ?
                      <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                      :
                      <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                    }
                  </span>
                </li>
                <li
                  onClick={() => duplicateHandler("SalesTheMost")}>
                  <span className="dropdown-item">
                    <i className="fa fa-files-o me-2" aria-hidden="true"></i> {t("Duplicate")}
                  </span>
                </li>
                <li
                  //  onClick={() => {
                  //   setDashboardhideshow(prevState => ({
                  //     ...prevState,
                  //     SalesTheMost: 0
                  //   }));
                  //   setDefaultset(true)
                  // }}
                  onClick={() => deletedublicate("SalesTheMost")}
                >
                  <span className="dropdown-item">
                    <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                    {t("Delete")}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="dashboard-area-cards-single-chart-area py-3">
            {completeDataObject?.graphtype === "Chart" ?
              <div className='dashboard-area-cards-single-chart-area-inner' id="salesTheMostChart">
                <Chart
                  options={salesTheMostChart.options}
                  series={salesTheMostChart.series}
                  type="bar"
                  height={350}
                />
              </div>
              : completeDataObject?.graphtype === "Table" ?
                <div className='dashboard-area-cards-single-chart-area-table'>
                  <table className='w-100'>
                    <thead>
                      <tr>
                        <th className='text-center'>Value</th>
                        <th className='text-center'>qty</th>
                        <th className='text-center'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tabledata?.length ? tabledata.map((result, key) => {
                        return (
                          <tr key={key}>
                            <td className='text-center'>{result?.amount ? result?.amount : ""}</td>
                            <td className='text-center'>{result?.qty ? result?.qty : ""}</td>
                            <td className='text-center'>{result?.label ? result?.label : ""}</td>
                          </tr>
                        )
                      }) : ""}

                    </tbody>
                  </table>
                </div> :
                completeDataObject?.graphtype === "PieChart" ?
                  <div className='dashboard-area-cards-single-chart-area-inner'>
                    <Chart
                      options={paiChart.options}
                      series={paiChart.series}
                      type="donut"
                    />
                  </div> : ""}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      {/* {instances.map((instance, index) => (
        <div key={index}>{instance}</div>
      ))} */}
      <SalesTheMostInstance />
    </div>
  );
};

export default SalesTheMost
