import React, { useState, useRef, useEffect } from 'react';
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import { _Api } from '../api/_call';
const LocationShippingMethodModal = (props) => {
    const { setShipping, shipingId } = props
    const { t } = useTranslation();
    const [shippingMethodSave, setShippingMethodSave] = useState({
        "type": "",
        "price": "",
        "description": "",
        "location_id": shipingId?.location_id
    })
    const [showmessage, setShowmessage] = useState({
        show: false
    })
    const [isModalOpen,] = useState(true);
    const modalRef = useRef(null);
    const createShipping = async () => {
        try {
            let GetloadingID = loading();
            let postdata = shippingMethodSave
            let res = await _Api(postdata, `api/v1/APP/location/save-shipping`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                if (res?.data?._id) {
                    let shippingdata = [...shipingId?.shippingList]
                    shippingdata.push(res?.data)
                    setShipping(prevState => ({
                        ...prevState,
                        shippingList: shippingdata
                    }));
                }

                if (modalRef.current) {
                    modalRef.current.hide();
                }
                setShippingMethodSave(prevState => ({
                    ...prevState,
                    type: "", price: "", description: "",
                }))
            } else {
                setShowmessage(prevState => ({
                    ...prevState,
                    res,
                    show: true
                }));
                Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'price' && /^\d*\.?\d{0,2}$/.test(value)) {
            setShippingMethodSave({ ...shippingMethodSave, [name]: value });
        } else if (name !== 'price') {
            setShippingMethodSave({ ...shippingMethodSave, [name]: value });
        }
        setShowmessage(prevState => ({
            ...prevState,
            show: false
        }));
    };
    useEffect(() => {
        modalRef.current = new window.bootstrap.Modal(document.getElementById('LocationShippingMethodModal'));
    }, []);
    return (
        <div className={`modal fade com-modal ${isModalOpen ? 'show' : ''}`} id="LocationShippingMethodModal" tabIndex="-1" aria-labelledby="LocationShippingMethodModalLabel" aria-hidden={!isModalOpen}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title fw-semibold" id="LocationShippingMethodModalLabel">{t("Shipping Method")}</h6>
                        <button onClick={() => setShippingMethodSave(prevState => ({
                            ...prevState,
                            type: "", price: "", description: "",
                        }))} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="v3-input-group">
                            <label className="v3-input-group-label fw-semibold fs-14">{t("Name")}</label>
                            <input type="text" className="v3-input-group-input w-100" placeholder="Example: Express Shipping"
                                value={shippingMethodSave.type}
                                name="type"
                                onChange={handleChange} />
                            {showmessage?.show ? <span className={`d-flex ${showmessage?.res?.code === 200 ? "main-green-text" : "text-danger"}`} >{showmessage?.res?.errors?.type}</span> : ""}
                        </div>

                        <div className="v3-input-group">
                            <label className="v3-input-group-label fw-semibold fs-14">{t("Price")}</label>
                            <input type="text" className="v3-input-group-input w-100" placeholder="Example: 400"
                                value={shippingMethodSave.price}
                                name="price"
                                onChange={handleChange} />
                            {showmessage?.show ? <span className={`d-flex text-danger`} >{showmessage?.res?.errors?.price}</span> : ""}

                        </div>


                        <div className="v3-input-group">
                            <label className="v3-input-group-label fw-semibold fs-14">{t("Description")}</label>
                            <input type="text" className="v3-input-group-input w-100" placeholder="Example: Arrive in 3-4 Bussiness Days"
                                value={shippingMethodSave.description}
                                name="description"
                                onChange={handleChange} />
                            {showmessage?.show ? <span className={`d-flex text-danger`} >{showmessage?.res?.errors?.description}</span> : ""}

                        </div>
                        {showmessage?.res?.show ?
                            <span className={`d-flex justify-content-center
                                 ${showmessage?.res?.code === 200 ? "main-green-text" : "text-danger"}`}>{showmessage?.res?.message}</span> : ""}

                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => createShipping()} className="btn text-white main-green-bg borde-0"
                        > {t("Submit")}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default LocationShippingMethodModal;