import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Link, useLocation } from 'react-router-dom'
import { _Api } from '../../api/_call'
import RightArrow from '../../common/icons/right-arrow'
import Confirmationmodel from "../../common/modules/confirmationmodel"
import { loading, Updateloading, error } from "../../common/helpers/toastify";
const AdminCashRegister = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation()
  const dataFetched = useRef();
  const [editItem, setEditItem] = useState(false)
  const { state } = useLocation()
  const [cashregisterlist, setCashregisterlist] = useState([])
  const [paymentmethodarr, setPaymentmethodarr] = useState([])
  const [paymentMathodlist, setPaymentMethodlist] = useState([])
  const [dataid, setDataid] = useState()
  const [completeDataObject, setCompleteObject] = useState({
    Status: 1,
    CashRegister: ""
  })
  const onChangeValueUpdate = (keyName, value) => {
    setCompleteObject((prevState) => ({
      ...prevState,
      [keyName]: value,
    }))
  }
  const typeOption = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Credit Card', value: 'Credit Card' },
    { label: 'Bank', value: 'Bank' },
  ]
  const getcashregister = useCallback(async () => {
    try {
      let postdata = {
        location_id: state?.locationdata?._id ? state?.locationdata?._id : state?.location_id,
      }
      let res = await _Api(postdata, `api/v1/App/quicksetup/get-cashregister`)
      if (res?.code === 200) {
        setCompleteObject((prevState) => ({
          ...prevState,
          CashRegister: res?.CashRegister,
        }))
        setCashregisterlist(res?.data)
        dataFetched.current = false;
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [state]);

  const getpaymentlist = useCallback(async () => {
    try {
      let postdata = {
        location_id: state?.locationdata?._id ? state?.locationdata?._id : state?.location_id,
      }
      let res = await _Api(postdata, `api/v1/App/location/paymentMathodList`)
      if (res?.code === 200) {
        setPaymentmethodarr(res?.data)
        dataFetched.current = false;
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [state]);
  const typechange = async (type, key) => {
    let array = []
    setCompleteObject((prevState) => ({
      ...prevState,
      payment_method_name: {
        value: "",
        label: ""
      },
    }))
    paymentmethodarr.forEach((result) => {
      if (type === 'Bank') {
        if (result?.Paymentmethod_type === 'Bank') {
          array.push({
            value: result?._id,
            label: result?.name
          })
        }
      } else if (type === 'Credit Card') {
        if (result?.Paymentmethod_type === 'Credit Card') {
          array.push({
            value: result?._id,
            label: result?.name
          })
        }
      } else {
        array = []
      }
    })

    setPaymentMethodlist(array)
  }
  const viercashregister = async (id) => {
    try {
      let postdata = {
        "id": id
      }
      let res = await _Api(postdata, `api/v1/App/quicksetup/view-cashregister`)
      if (res?.code === 200) {
        let obj = { CashRegister: completeDataObject?.CashRegister }
        if (!res?.data?.payment_method_name?._id) {
          obj = {
            CashRegister: completeDataObject?.CashRegister, payment_method_name: {
              value: "",
              label: ""
            }
          }
        } else {
          obj = {
            CashRegister: completeDataObject?.CashRegister, payment_method_name: {
              value: res?.data?.payment_method_name?._id,
              label: res?.data?.payment_method_name?.name
            }
          }
        }
        const mergedObject = { ...res?.data, ...obj };
        setCompleteObject(mergedObject)
        let array = []
        paymentmethodarr.forEach((result) => {
          if (res?.data?.Paymentmethod_type === 'Bank') {
            if (result?.Paymentmethod_type === 'Bank') {
              array.push({
                value: result?._id,
                label: result?.name
              })
            }
          } else if (res?.data?.Paymentmethod_type === 'Credit Card') {
            if (result?.Paymentmethod_type === 'Credit Card') {
              array.push({
                value: result?._id,
                label: result?.name
              })
            }
          } else {
            array = []
          }
        })
        setPaymentMethodlist(array)
      }
    }
    catch (err) {
      console.log(err, 'err')
    }
  }
  const deletecashregister = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        "id": dataid
      }
      let res = await _Api(postdata, `api/v1/App/quicksetup/delete-cashregister`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getcashregister()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }

    catch (err) {
      console.log(err, "err")
    }
  }
  const createcashregister = async () => {
    if (!completeDataObject?.name) {
      error("Name is Require")
      return;
    }
    if (!completeDataObject?.Paymentmethod_type) {
      error("Type is Require")
      return;
    }
    let GetloadingID = loading()
    try {
      let postdata;
      if (completeDataObject?._id) {
        postdata = {
          "id": completeDataObject?._id,
          "name": completeDataObject?.name,
          "Paymentmethod_type": completeDataObject?.Paymentmethod_type,
          "PaymentMethod": completeDataObject?.payment_method_name?.value,
          "Status": completeDataObject?.Status ? 1 : 0,
          "payment_method_name": completeDataObject?.payment_method_name,
          "CashRegister": completeDataObject?.CashRegister ? completeDataObject?.CashRegister : completeDataObject?.name,
          "Locations": state?.locationdata?._id ? state?.locationdata?._id : state?.location_id,
        }
      } else {
        postdata = {
          "name": completeDataObject?.name,
          "Paymentmethod_type": completeDataObject?.Paymentmethod_type,
          "Locations": state?.locationdata?._id ? state?.locationdata?._id : state?.location_id,
          "CashRegister": completeDataObject?.CashRegister ? completeDataObject?.CashRegister : completeDataObject?.name,
          "PaymentMethod": completeDataObject?.payment_method_name?.value,
          "Status": completeDataObject?.Status ? 1 : 0,
          "payment_method_name": completeDataObject?.payment_method_name
        }
      }
      let res = await _Api(postdata, `api/v1/App/quicksetup/add-edit-cashregister`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getcashregister()
        setEditItem(false)
      } else {
        Updateloading(t, GetloadingID, res?.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getcashregister()
    getpaymentlist()
  }, [getcashregister, getpaymentlist])
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              {state?.locationkey ? <Link
                className="text-decoration-none"
                to={`/app/admin/location/detail/${state?.locationdata?._id}`}
                state={{ dataid: state?.locationdata?._id ? state?.locationdata?._id : state?.location_id, }}
              >
                {t('Location Detail')}
              </Link> :
                <Link
                  className="text-decoration-none"
                  to="/app/admin/setup/quickstoresetup"
                >
                  {t('Quick Store')}
                </Link>
              }
              <RightArrow />
              <span>{t('Cash Register')}</span>
            </h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <button
              className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"
              onClick={() => { setEditItem(true); setCompleteObject({ Status: 1, CashRegister: completeDataObject?.CashRegister }) }}
            >
              <img
                className="me-2"
                src={base_assets + 'images/admin/icons/add-icon-white.png'}
                alt=""
              />
              {t('Create')}
            </button>
          </div>
        </div>

        <div className="row">
          <div className={editItem ? 'col-md-6' : 'col-md-12'}>
            <div className="bg-white p-4 rounded-3 mb-4">
              <div className="row v3-input-groups mb-4">
                <div className="col-md-3 v3-input-group-label">
                  {t('Store / Outlet')}
                </div>
                <div className="col-md-5 v3-input-group-label">
                  {cashregisterlist[0]?.locationName}
                </div>
              </div>
              <div className="row v3-input-group">
                <div className="col-md-3 v3-input-group-label">
                  {t('Cash register name')}
                </div>
                <div className="col-md-5">
                  <input
                    onChange={(e) => onChangeValueUpdate("CashRegister", e.target.value)}
                    className="v3-input-group-input w-100"
                    type="text"
                    value={completeDataObject?.CashRegister}
                  />
                </div>
              </div>
            </div>
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle"
              >
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th>#</th>
                    <th>{t('Name')}</th>
                    <th>{t('Location')}</th>
                    <th>{t('Type')}</th>
                    <th>{t('Payment Method')}</th>
                    <th className="text-center">{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {cashregisterlist?.length
                      ? cashregisterlist.map((result, key) => {
                        return (
                          <tr className="text-nowrap" key={key}>
                            <td>{key + 1}</td>
                            <td>{result?.name ? result?.name : ''}</td>
                            <td>
                              {result?.locationName ? result?.locationName : ''}
                            </td>
                            <td>
                              {result?.Paymentmethod_type
                                ? result?.Paymentmethod_type
                                : ''}
                            </td>
                            <td>
                              {result?.type
                                ? result?.type
                                : ''}
                            </td>
                            <td className="text-center">
                              <img
                                className="cursor-pointer me-4"
                                onClick={() => { setEditItem(true); viercashregister(result?.id) }}
                                src={
                                  base_assets + 'images/admin/icons/Edit.png'
                                }
                                alt=""
                              />
                              <img
                                onClick={() => { setDataid(result?.id) }}
                                className="cursor-pointer"
                                src={
                                  base_assets + 'images/admin/icons/Delete.png'
                                }
                                alt=""
                                data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                              />
                            </td>
                          </tr>
                        )
                      })
                      : ''}
                  </>
                </tbody>
              </table>
            </div>
          </div>
          <div className={editItem ? 'col-md-6 d-block' : 'd-none'}>
            <div className="edit-item-area bg-white row">
              <div className="col-md-8 px-0">
                <div className="edit-item-area-body">
                  <div className="row">
                    <div className="col-md-12 ps-0">
                      <div className="row mx-0">
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">
                              {t('Name')}
                            </label>
                            <input
                              type="text"
                              className="v3-input-group-input w-100"
                              value={completeDataObject?.name ? completeDataObject?.name : ""}
                              onChange={(e) => onChangeValueUpdate("name", e.target.value)}
                            />
                          </div>
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">
                              {t('Type')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={typeOption}
                              value={{
                                label: completeDataObject?.Paymentmethod_type,
                                value: completeDataObject?.Paymentmethod_type
                              }}
                              onChange={(e) => {
                                onChangeValueUpdate("Paymentmethod_type", e.value)
                                typechange(e.value)
                              }}
                              classNamePrefix="common-select"
                              isSearchable={true}
                            />
                          </div>
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">
                              {t('Payment Method')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={
                                paymentMathodlist?.length
                                  ? paymentMathodlist
                                  : []
                              }
                              value={completeDataObject?.payment_method_name}
                              onChange={(e) => onChangeValueUpdate("payment_method_name", e)}
                              classNamePrefix="common-select"
                              isSearchable={false}
                              getOptionLabel={(e) => (
                                <div className="common-select-icon">
                                  {e.label}
                                </div>

                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 offset-1 pe-0">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                  <button
                    className="square-btn square-btn-white btn-close focus-none"
                    onClick={() => {
                      setEditItem(false)
                    }}
                  ></button>
                  <button className="square-btn"
                    onClick={() => createcashregister()}>
                    <img
                      src={base_assets + 'images/icons/white-save.png'}
                      alt=""
                    />
                  </button>
                </div>
                <div className="form-check form-switch ps-0 ms-2 text-end">
                  <label className="form-check-label" htmlFor="mixmatch">
                    {t('Status')}
                  </label>
                  <input
                    className="form-check-input float-none ms-2"
                    type="checkbox"
                    id="mixmatch"
                    checked={completeDataObject?.Status ? true : false}
                    onChange={(e) => onChangeValueUpdate("Status", e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={deletecashregister} ></Confirmationmodel>
      </div >
    </>
  )
}

export default AdminCashRegister
