import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import Select from "react-select"
import './frontCms.scss'
import ImageGallary from '../../../Ecommerce/template_two/default/assets/icons/ImageGallary';
import ImageUploading from 'react-images-uploading';
import { _Api, _Apiauth } from '../../api/_call';
import Theme2 from '../../../assets/images/icons/theme2.png'
import CloseIcon from '../../common/icons/CloseIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import Apiauth from '../../../API/Apiauth';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import { success } from '../../common/helpers/toastify';
import { error } from 'jquery';

const FrontCms = () => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { t } = useTranslation();
    const {state} = useLocation();
    const modalRef = useRef(null);
    const [images, setImages] = useState([]);
    const navigation = useNavigate();
    const [activeTheme, setActiveTheme] = useState();
    const [favIcon, setFavIcon] = useState();
    const [alldata, setAllData] = useState({});
    const [locationData, setLocationdata] = useState(state?.location);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const themesData = [
        {name: "Sparkle", image: Theme2},
        {name: "Sward", image: Theme2},
        {name: "Mirage", image: Theme2},
    ]
    useEffect(()=>{
        getData();
    },[])

  const getData = useCallback(async () => {
    try {
      const postdata = {
        "location_id": locationData
    };
      let apiRes = await Apiauth(postdata, `cms/get-frontcms`);
      
      if (apiRes.code === 200) {
        console.log(apiRes.data, "get frontcms")
       setAllData(apiRes?.data);
       setImages([{data_url : apiRes?.data?.websiteInfo?.logo}]);
       setFavIcon([{data_url : apiRes?.data?.websiteInfo?.favicon}])
       if(apiRes?.data?.websiteInfo?.theme === "template_two"){
        setActiveTheme("Sparkle")
       }
       if(apiRes?.data?.websiteInfo?.theme === 'template_one'){
        setActiveTheme("Sward")
       }
       if(apiRes?.data?.websiteInfo?.theme === 'template_three'){
        setActiveTheme("Mirage")
       }
       console.log(apiRes?.data?.websiteInfo?.logo, "check response")
      }
    } catch (err) {
      console.error(err, 'err');
    }
  }, []);
  
    // const onChange = (imageList, addUpdateIndex) => {
    //    console.log(imageList, images, "checkimagelist")
    //     const file = imageList[0]?.file;
    //     console.log(file, images, "imageList")
    //     if(file){
    //         if (file.type.startsWith("image/")) {
                
    //         const reader = new FileReader();
    //         console.log(file.type.startsWith("image/"), reader?.result,"result")
    //         reader.onloadend = async () => {
    //             try {
    //                 const postdata = {
    //                     image: reader.result,
    //                 };
    //                 console.log(postdata, file, "postdaa");
                    
    //                 setImages((prev)=>prev={image: reader.result, name : file.name})
    //                 // let res = await _Api(postdata, `redis/uplaod`)
    //                 // if (res.statusCode === 200) {
    //                 //     console.log(res?.url, "engraving logo")
    //                 // }
    //             }
    //             catch (err) {
    //                 console.log(err, "err")
    //             }
    //         };
    //         if (file) {
    //             reader?.readAsDataURL(file);
    //         }
    //     }
    //     }
    //     // setEngravingFormData((state)=>({...state, imageFile: imageList[0]}))
    //   };

    
      const onChange = async (imageList, addUpdateIndex) => {
        try {
        let Output = await getImagetoUrl(imageList);
        
        if(Output){
            console.log(Output, "check the final output", imageList?.[0]?.file?.name)
            setImages([{data_url : Output, name :imageList?.[0]?.file?.name}])
        }
        }catch (error) {
            console.error("Error fetching image URL:", error);
        }
    };

    //   const getImagetoUrl = async(imagelist) =>{
    //     console.log(imagelist, "check image we imported")
    //     const file = imagelist[0]?.file;
    //     if(file){
    //         if (file.type.startsWith("image/")) {
               
    //         const reader = new FileReader();
    //         console.log(file.type.startsWith("image/"), reader?.result,"result")
    //         reader.onloadend = async () => {
    //             try {
    //                 const postdata = {
    //                     image: reader.result,
    //                     name: imagelist[0]?.file?.name,
    //                 };
    //                 console.log(postdata, "postdaa")
    //                 let res = await _Apiauth(postdata, `/APP/customer/uploadimage`)
    //                 if (res.code === 200) {
    //                     console.log(res?.data?.images?.url, "Here you get image url")
    //                     return res?.data?.images?.url
    //                 }
    //             }
    //             catch (err) {
    //                 console.log(err, "err")
    //             }
    //         };
    //         if (file) {
    //             reader?.readAsDataURL(file);
    //         }
    //     }
    //     }
    //   }
    const getImagetoUrl = async (imagelist) => {
        console.log(imagelist, "check image we imported");
        const file = imagelist[0]?.file;
    
        if (file) {
            if (file.type.startsWith("image/")) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
    
                    reader.onloadend = async () => {
                        try {
                            const postdata = {
                                image: reader.result,
                                name: imagelist[0]?.file?.name,
                            };
                            console.log(postdata, "postdata");
                            let res = await _Apiauth(postdata, `/APP/customer/uploadimage`);
                            
                            if (res.code === 200) {
                                console.log(res?.data?.images?.url, "Here you get image url");
                                resolve(res?.data?.images?.url);
                            } else {
                                reject(new Error("Failed to upload image"));
                            }
                        } catch (err) {
                            reject(err);
                        }
                    };
    
                    reader.onerror = (error) => {
                        reject(new Error("File reading error: " + error));
                    };
    
                    reader.readAsDataURL(file);
                });
            }
        }
    
        return null; // Or handle the case where the file is not an image
    };

      const onFavIconChange = async (imageList, addUpdateIndex) => {
        // data for submit
        try {
            let Output = await getImagetoUrl(imageList);
            
            if(Output){
                console.log(Output, "check fav icon", imageList?.[0]?.file?.name)
                setFavIcon([{data_url : Output, name :imageList?.[0]?.file?.name}])
            }
            }catch (error) {
                console.error("Error fetching image URL:", error);
            }
        // console.log(imageList, addUpdateIndex);
        // setFavIcon(imageList);
      }

      const handleSave = async () =>{
        console.log(alldata, activeTheme, images, favIcon, locationData, "HandleAll Data");
        try {
 
            const postdata = {
                "location_id": locationData,
                "websiteInfo": {
                    "name": alldata?.name ? alldata?.name : "",
                    "metaTitle": alldata?.websiteInfo?.metaTitle ? alldata?.websiteInfo?.metaTitle : "",
                    "metaDescription":  alldata?.websiteInfo?.metaDescription ? alldata?.websiteInfo?.metaDescription : "",
                    "logo": images?.[0]?.data_url ? images?.[0]?.data_url : "",
                    "favicon": favIcon?.[0]?.data_url ? favIcon?.[0]?.data_url : "",
                    "theme": activeTheme === "Sparkle" ? "template_two" : activeTheme === "Sward" ? "template_one" : activeTheme === "Mirage" ? "template_three" : "",
                    "socialLink": alldata?.websiteInfo?.socialLink ? alldata?.websiteInfo?.socialLink : {},
                    "footerText": alldata?.websiteInfo?.footerText ? alldata?.websiteInfo?.footerText : "",
                    "cookieConsent": alldata?.websiteInfo?.cookieConsent ? alldata?.websiteInfo?.cookieConsent : "",
                    "googleAnalytics": alldata?.websiteInfo?.googleAnalytics ? alldata?.websiteInfo?.googleAnalytics : ""
                }
            }
            let apiRes = await Apiauth(postdata, `cms/post-frontcms`);
            
            if (apiRes.code === 200) {
                success(t("Successfully Updated !"))
            }
          } catch (err) {
                error(t("Something Went Wrong !"))
          }
      }
      const showPopup = () => {
        handleSave();
      };

      const handleConfirm = () => {
        setIsPopupVisible(false);
        handleSave();
      };
    
      const handleCancel = () => {
        setIsPopupVisible(false);
      };

      const customiseTheme = (theme) =>{
        console.log(theme, "This is our theme")
        navigation(`${theme}`)
      } 

    return (
        <div className="main-content-wrapper-body">
           <div className='frontcms'>
                <div class="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
                    <div class="main-content-wrapper-body-top-bar-left">
                        <h1 class="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                            <span>{t("Front CMS")}</span>
                        </h1>
                    </div>
                    <div class="main-content-wrapper-body-top-bar-right d-flex align-items-center">
                        <button class="square-btn" onClick={showPopup}>
                            <img src={base_assets + 'images/icons/white-save.png'} alt="" />
                        </button>
                    </div>
                </div>
                <div className='frontcms-container'>
                <div className='frontcms-container-section'>
                <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Website Name")}</div>
                        <div className='frontcms-container-section-property-content'>
                            <input className='frontcms-container-section-property-content-footer' 
                            type='text' 
                            placeholder='Google.com' 
                            value={alldata?.name} 
                            onChange={(e)=>setAllData((state)=>({...state, name : e.target.value}))}
                            />
                        </div>
                    </div>
                    <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Meta Title")}</div>
                        <div className='frontcms-container-section-property-content'>
                            <input className='frontcms-container-section-property-content-footer' 
                            type='text' 
                            placeholder='Google' 
                            value={alldata?.websiteInfo?.metaTitle} 
                            onChange={(e)=>setAllData((state)=>({...state, websiteInfo : { ...state.websiteInfo, "metaTitle" : e.target.value}}))}
                            />
                        </div>
                    </div>
                    <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Meta Description")}</div>
                        <div className='frontcms-container-section-property-content'>
                            <input className='frontcms-container-section-property-content-footer' 
                            type='text' 
                            placeholder='Description' 
                            value={alldata?.websiteInfo?.metaDescription} 
                            onChange={(e)=>setAllData((state)=>({...state, websiteInfo : { ...state.websiteInfo, "metaDescription" : e.target.value}}))}
                            />
                        </div>
                    </div>
                    <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Logo")} ({t("369px X 76px")})</div>
                        <div className='frontcms-container-section-property-content logo'>
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={1}
                                dataURLKey="data_url"
                            >
                                {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                   
                                            {imageList?.length ? 
                                             imageList.map((image, index) => (
                                                <div key={index} className={`image-item logo-uploader ${isDragging ? "active" : ""}`} onClick={() => onImageUpdate(index)} {...dragProps}>
                                                    <img src={image['data_url']} alt="" width="100" />
                                                    <div className="image-item__btn-wrapper">
                                                    </div>
                                                    <div className='logo-uploader-cover'>
                                                        <div className='logo-uploader-cover-close' 
                                                            onClick={(e)=>{e.stopPropagation();
                                                            onImageRemove(index); setImages([]); }}>
                                                            <CloseIcon color={"#fff"}/> 
                                                        </div>
                                                        <span className='logo-uploader-cover-inner'>
                                                            <span className='logo-uploader-cover-inner-name'>{image['name']}</span>
                                                            <span className='logo-uploader-cover-inner-sub'>{t("Drag and drop a file here or click")}</span>
                                                        </span> 
                                                    </div>
                                                </div>
                                                ))
                                                :
                                                <div className={`logo-uploader ${isDragging ? "active" : ""}`} onClick={onImageUpload}  {...dragProps}>
                                                    <div>
                                                        <ImageGallary width="24px" height="24px"/>
                                                    </div>
                                                    <div className="">
                                                        <span>{("Drag and drop a file here or click")} </span>
                                                    </div>
                                                    <div className='logo-uploader-cover'>
                                                        <span className='logo-uploader-cover-inner'>
                                                            <ImageGallary width="24px" height="24px" color="#fff"/>
                                                            <span className='logo-uploader-cover-inner-sub'>{t("Drag and drop a file here or click")}</span>
                                                        </span> 
                                                    </div>
                                                </div>
                                            }
                                </div>
                                )}
                            </ImageUploading>
                        </div>
                    </div>
                    <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Favicon")} {t("32px X 32px")}</div>
                        <div className='frontcms-container-section-property-content favicon'>
                        <ImageUploading
                                multiple
                                value={favIcon}
                                onChange={onFavIconChange}
                                maxNumber={1}
                                dataURLKey="data_url"
                            >
                                {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                   
                                            {imageList?.length ? 
                                             imageList.map((image, index) => (
                                                <div key={index} className={`image-item favicon-uploader ${isDragging ? "active" : ""}`} onClick={() => onImageUpdate(index)} {...dragProps}>
                                                    <img src={image['data_url']} alt="" width="100" />
                                                    <div className="image-item__btn-wrapper">
                                                    </div>
                                                    <div className='favicon-uploader-cover'>
                                                        <div className='favicon-uploader-cover-close' 
                                                            onClick={(e)=>{e.stopPropagation();
                                                            onImageRemove(index); setFavIcon([]) }}>
                                                            <CloseIcon color={"#fff"}/> 
                                                        </div>
                                                        <span className='favicon-uploader-cover-inner'>
                                                            <span className='favicon-uploader-cover-inner-name'>{favIcon?.[0]?.name}</span>
                                                        </span> 
                                                    </div>
                                                </div>
                                                ))
                                                :
                                                <div className={`favicon-uploader ${isDragging ? "active" : ""}`} onClick={onImageUpload}  {...dragProps}>
                                                    <div>
                                                        <ImageGallary width="24px" height="24px"/>
                                                    </div>
                                                    <div className="">
                                                        <span>{("Drag and drop a file here or click")} </span>
                                                    </div>
                                                    <div className='favicon-uploader-cover'>
                                                        <span className='favicon-uploader-cover-inner'>
                                                            <ImageGallary width="24px" height="24px" color="#fff"/>
                                                            <span className='favicon-uploader-cover-inner-sub'>{t("Drag and drop a file here or click")}</span>
                                                        </span> 
                                                    </div>
                                                </div>
                                            }
                                </div>
                                )}
                            </ImageUploading>
                        </div>
                    </div>
                    <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Footer Text")}</div>
                        <div className='frontcms-container-section-property-content'>
                            <input className='frontcms-container-section-property-content-footer' 
                            type='text' 
                            placeholder='© XYZ jewellery 2024 All rights reserved' 
                            value={alldata?.websiteInfo?.footerText} 
                            onChange={(e)=>setAllData((state)=>({...state, websiteInfo : { ...state.websiteInfo, "footerText" : e.target.value}}))}
                            />
                        </div>
                    </div>
                    <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Cookie Consent")}</div>
                        <div className='frontcms-container-section-property-content'>
                        <textarea className='frontcms-container-section-property-content-cookie' 
                        placeholder='Cookie Consent' 
                        onChange={(e)=>setAllData((state)=>({...state, websiteInfo : { ...state.websiteInfo, "cookieConsent" : e.target.value}}))}
                        value={alldata?.websiteInfo?.cookieConsent}
                        ></textarea>
                        </div>
                    </div>
                    <div className='frontcms-container-section-property'>
                        <div className='frontcms-container-section-property-title'>{t("Google Analytics")}</div>
                        <div className='frontcms-container-section-property-content'>
                        <textarea className='frontcms-container-section-property-content-google' 
                        placeholder={`Enter Google Analytics script`}
                        value={alldata?.websiteInfo?.googleAnalytics}
                        onChange={(e)=>setAllData((state)=>({...state, websiteInfo : { ...state.websiteInfo, "googleAnalytics" : e.target.value}}))}
                        ></textarea>
                        </div>
                    </div>
                </div>
                <div className='frontcms-container-section'>
                            <div className='frontcms-container-section-property'>
                            <div className='frontcms-container-section-property-title'>{("WhatsApp URL")}</div>
                            <div className='frontcms-container-section-property-linkbox'>
                                <input className='frontcms-container-section-property-linkbox-input' 
                                placeholder={`https://www.whatsapp.com/a`} 
                                type='text'
                                value={alldata?.websiteInfo?.socialLink?.whatsapp}
                                onChange={(e)=>setAllData((prevState) => ({
                                    ...prevState,
                                    websiteInfo: {
                                        ...prevState.websiteInfo,
                                        socialLink: {
                                            ...prevState.websiteInfo.socialLink,
                                            whatsapp: e.target.value
                                        }
                                    }
                                }))}
                                />
                            </div>
                        </div>

                        <div className='frontcms-container-section-property'>
                            <div className='frontcms-container-section-property-title'>{("Facebook URL")}</div>
                            <div className='frontcms-container-section-property-linkbox'>
                                <input className='frontcms-container-section-property-linkbox-input' 
                                placeholder={`https://www.whatsapp.com/a`} 
                                type='text'
                                value={alldata?.websiteInfo?.socialLink?.facebook}
                                onChange={(e)=>setAllData((prevState) => ({
                                    ...prevState,
                                    websiteInfo: {
                                        ...prevState.websiteInfo,
                                        socialLink: {
                                            ...prevState.websiteInfo.socialLink,
                                            facebook: e.target.value
                                        }
                                    }
                                }))}
                                />
                            </div>
                        </div>
                      
                        <div className='frontcms-container-section-property'>
                            <div className='frontcms-container-section-property-title'>{("Twitter URL")}</div>
                            <div className='frontcms-container-section-property-linkbox'>
                                <input className='frontcms-container-section-property-linkbox-input' 
                                placeholder={`https://www.twitter.com/a`} 
                                type='text'
                                value={alldata?.websiteInfo?.socialLink?.twitter}
                                onChange={(e)=>setAllData((prevState) => ({
                                    ...prevState,
                                    websiteInfo: {
                                        ...prevState.websiteInfo,
                                        socialLink: {
                                            ...prevState.websiteInfo.socialLink,
                                            twitter: e.target.value
                                        }
                                    }
                                }))}
                                />
                            </div>
                        </div>
                       
   
                        <div className='frontcms-container-section-property'>
                            <div className='frontcms-container-section-property-title'>{("Youtube URL")}</div>
                            <div className='frontcms-container-section-property-linkbox'>
                                <input className='frontcms-container-section-property-linkbox-input' 
                                placeholder={`https://www.youttube.com/a`} 
                                type='text'
                                value={alldata?.websiteInfo?.socialLink?.youtube}
                                onChange={(e)=>setAllData((prevState) => ({
                                    ...prevState,
                                    websiteInfo: {
                                        ...prevState.websiteInfo,
                                        socialLink: {
                                            ...prevState.websiteInfo.socialLink,
                                            youtube: e.target.value
                                        }
                                    }
                                }))}
                                />
                            </div>
                        </div>
                       
     
                        <div className='frontcms-container-section-property'>
                            <div className='frontcms-container-section-property-title'>{("LinkedIn URL")}</div>
                            <div className='frontcms-container-section-property-linkbox'>
                                <input className='frontcms-container-section-property-linkbox-input' 
                                placeholder={`https://www.linkedin.com/a`} 
                                type='text'
                                value={alldata?.websiteInfo?.socialLink?.linkedin}
                                onChange={(e)=>setAllData((prevState) => ({
                                    ...prevState,
                                    websiteInfo: {
                                        ...prevState.websiteInfo,
                                        socialLink: {
                                            ...prevState.websiteInfo.socialLink,
                                            linkedin: e.target.value
                                        }
                                    }
                                }))}
                                />
                            </div>
                        </div>
                       
                        <div className='frontcms-container-section-property'>
                            <div className='frontcms-container-section-property-title'>{("Instagram URL")}</div>
                            <div className='frontcms-container-section-property-linkbox'>
                                <input className='frontcms-container-section-property-linkbox-input' 
                                placeholder={`https://www.instagram.com/a`} 
                                type='text'
                                value={alldata?.websiteInfo?.socialLink?.instagram}
                                onChange={(e)=>setAllData((prevState) => ({
                                    ...prevState,
                                    websiteInfo: {
                                        ...prevState.websiteInfo,
                                        socialLink: {
                                            ...prevState.websiteInfo.socialLink,
                                            instagram: e.target.value
                                        }
                                    }
                                }))}
                                />
                            </div>
                        </div>
                       
                        <div className='frontcms-container-section-property'>
                            <div className='frontcms-container-section-property-title'>{("Pinterest URL")}</div>
                            <div className='frontcms-container-section-property-linkbox'>
                                <input className='frontcms-container-section-property-linkbox-input' 
                                placeholder={`https://www.pinterest.com/a`} 
                                type='text'
                                value={alldata?.websiteInfo?.socialLink?.pinterest}
                                onChange={(e)=>setAllData((prevState) => ({
                                    ...prevState,
                                    websiteInfo: {
                                        ...prevState.websiteInfo,
                                        socialLink: {
                                            ...prevState.websiteInfo.socialLink,
                                            pinterest: e.target.value
                                        }
                                    }
                                }))}
                                />
                            </div>
                        </div>
                        
                            
                </div>
                <div className='frontcms-container-section w-100'>
                    <div className='frontcms-container-section-themes'>
                        <div className='frontcms-container-section-themes-title'>
                            {("Current Theme ")}
                        </div>
                        <div className='frontcms-container-section-themes-contents'>
                            { themesData?.length ? 
                                themesData.map((item, i)=>{
                                    return(
                                    <div className={`frontcms-container-section-themes-contents-card ${activeTheme === item?.name ? "active" : ""}`} key={i} onClick={()=>setActiveTheme(item?.name)}>
                                        <img src={item?.image}/>
                                        <div className={`frontcms-container-section-themes-contents-card-name ${activeTheme === item?.name ? "active" : ""}`}>
                                            {item?.name}
                                        </div>
                                        <div className='frontcms-container-section-themes-contents-card-cover'>
                                            <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} onClick={()=>customiseTheme(item?.name)}/>
                                        </div>
                                    </div>
                                    )
                                })
                            : ""}
                        </div>
                    </div>
                </div>
                </div>
           </div>
           {/* <ConfirmationPopup
            isVisible={isPopupVisible}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            sub="Save Data, Are You Sure ?"
           /> */}
        </div >
    )
}

export default FrontCms