import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { _Api } from "../api/_call";
import { useTranslation } from 'react-i18next';
const CustomOrderSearch = (props) => {
  const { t } = useTranslation();
  const { setOptiondetails, setvariant, optionid, from, daimond_id, mixmatchsearch, setSkip, scrvicelist, scrviceremark, showpage, Engraving, editproduct, remark, customcart, items, order_id, order_edit, order_type } = props;
  const [isWarehouse, setIsWarehouse] = useState(optionid === true ? true : false);
  const [searchlist, setSearchlist] = useState();
  const [scarchvalue, setScarchvalue] = useState("")
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const warehouseClick = event => {
    setIsWarehouse(current => !current);
    setOptiondetails(current => !current);
  };
  const getsearchlist = async (value) => {
    setScarchvalue(value)
    try {
      const posdata = {
        "search": value,
        "type": isWarehouse === true ? "catalog" : "inventory",
        "from": from
      }

      let res = await _Api(posdata, `api/v1/POS/customOrder/skuNameBySearch`)
      if (res.code === 200) {
        setSkip(0)
        setSearchlist(res?.data);
      } else {
        setSearchlist([]);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (optionid !== undefined) {
      setOptiondetails(optionid === true ? true : false);
    }
    // eslint-disable-next-line 
  }, [optionid]);

  return (
    <>
      <div className="common-navigation-header-right">
        <div className="com-search-area com-search-area-large">
          <div
            className={
              isWarehouse
                ? "com-search-area-large-home warehouse"
                : "com-search-area-large-home"
            }
            onClick={() => { warehouseClick(); setSkip(0) }}
          >
            <img
              className="img-1"
              src={base_assets + "images/pos/icons/warehouse.png"}
              alt=""
            />
            <img
              className="img-2"
              src={base_assets + "images/pos/icons/crown-outline.png"}
              alt=""
            />
          </div>
          <button className="com-search-area-left-img scan-img">
            <img src={base_assets + "images/pos/icons/scan-icon.png"} alt="" />
          </button>
          <input type="text" value={scarchvalue} onChange={(e) => { getsearchlist(e.target.value) }} placeholder={t("Scan barcode, Search SKU")} id="dropdownMenuButton1" data-bs-toggle="dropdown" autoComplete="off" />
          <ul className="dropdown-menu custom-search-dropdown" aria-labelledby="dropdownMenuButton1">
            {searchlist?.length ? searchlist.map((result, key) => {

              return (

                mixmatchsearch ?

                  <li key={key}>
                    <Link className="dropdown-item" onClick={() => setvariant(result?._id)} to="/app/pos/custom/mixdiamondsetting"
                      state={{
                        daimond_id: daimond_id,
                        Engraving: Engraving,
                        isWarehouse: isWarehouse === true ? true : false,
                        product_id: result?._id,
                        scrvicelist: scrvicelist,
                        scrviceremark: scrviceremark,
                        showpage: showpage ? true : false,
                        editproduct: editproduct,
                        remark: remark,
                        customcart: customcart,
                        items: items,
                        order_edit: order_edit,
                        order_type: order_type,
                        order_id: order_id
                      }}>

                      {result?.name}<span>{result?.SKU}{result?.stock_id ? '-' + result?.stock_id : ''}
                      </span>
                    </Link>
                  </li>
                  :

                  <li key={key}>
                    <Link
                      to="/app/pos/custom/createyourowndetails"
                      state={{
                        product_id: result?._id,
                        filter: isWarehouse === true ? true : false,
                        order_edit: order_edit,
                        order_type: order_type,
                        order_id: order_id

                      }}
                      className="dropdown-item">
                      {result?.name}<span>{result?.SKU}{result?.stock_id ? '-' + result?.stock_id : ''}
                      </span>
                    </Link>
                  </li>
              );
            }) : ''}
          </ul>
          <img
            className="com-search-area-img" style={{ margin: "0 0.7vw" }}
            src={base_assets + "images/pos/icons/magnifying-glass.png"}
            alt=""
          />
        </div>
        <div className="custom-tooltip">
          <Link to="/app/pos/custom/customOrderDelivered">
            <img
              className="custom-order-img"
              src={base_assets + "images/pos/icons/custom-order-icon.png"}
              alt=""
            />
            <span className="com-btn custom-tooltip-text">{t("Custom Order")}</span>
          </Link>
        </div>
      </div >
    </>
  );
};

export default CustomOrderSearch;
