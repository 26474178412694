import React from "react";
import { Link } from "react-router-dom";
import CheckoutBlank from "../checkout/checkoutBlank"
import "./Reserve.scss";
import { DiamondSearchReserveCatalogModal } from '../common/module/DiamondSearchReserve';
import { useTranslation } from 'react-i18next';
const Diamondreserve = () => {
    const { t } = useTranslation();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    return (
        <div className="main-body">
            <div className="main-body-row">
                <div className="main-body-row-left-col p-0 ">
                    <div className="catalog-area bg-white page-menu-block reserve">
                        <div className="common-navigation-header">
                            <div className="common-navigation-header-left">
                                <h3 className="common-navigation-header-left-heading">

                                    {t("RESERVE")}
                                </h3>
                            </div>
                            <div className="common-navigation-header-right">

                                <div className="custom-tooltip">
                                    <Link to="/app/pos/reserve/ReserveListDiamond">
                                        <img
                                            className="custom-order-img"
                                            src={base_assets + "images/pos/work-schedule 1.png"}
                                            alt=""
                                        /></Link>
                                    <span className="com-btn custom-tooltip-text">
                                        {t("Reserve list")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="selection-process">
                            <div className="dropdown common-dropdown diamond-dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownDiamond" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span><img src={base_assets + "images/pos/icons/diamond-icon-green.png"} alt="" /></span>{t("Diamond")}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownDiamond">
                                    <li>
                                        <Link className="dropdown-item" to="/app/pos/reserve">
                                            <span><img className="reserve-sku_ring_img" src={base_assets + "images/pos/icons/sku-ring-green.png"} alt="" /></span>
                                            {t("Jewelry")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="selection-process-single">
                                <img
                                    className="selection-process-single-img"
                                    src={base_assets + "images/pos/steps/s1.png"}
                                    alt=""
                                />
                                <span className="selection-process-single-name">
                                    {t("Select Daimond")}
                                </span>
                            </div>
                            <span className="selection-process-line"></span>
                            <div className="selection-process-single">
                                <img
                                    className="selection-process-single-img"
                                    src={base_assets + "images/pos/steps/s11.png"}
                                    alt=""
                                />
                                <span className="selection-process-single-name">
                                    {t("Reserve")}
                                </span>
                            </div>

                        </div>
                        <DiamondSearchReserveCatalogModal />
                    </div>
                </div>
                <div className="main-body-row-right-col">
                    <CheckoutBlank />
                </div>
            </div>
        </div>
    )

}
export default Diamondreserve;