import React, { useState, useEffect } from 'react'
import { Container, Draggable } from '@smooth-dnd/react'
import { arrayMoveImmutable } from 'array-move'
import { useTranslation } from 'react-i18next';
import './dashboard.scss';
import LifeStage from './graphData/LifeStage';
import TotalSales from './graphData/TotalSales';
import SalesTheMost from './graphData/SalesTheMost';
import InventoryStock from './graphData/InventoryStock';
import SummaryPurchaseOrder from './graphData/SummaryPurchaseOrder';
import PurchaseOrder from './graphData/PurchaseOrder';
import Purchase from './graphData/Purchase';
import CustomOrder from './graphData/CustomOrder';
import OrderTheMost from './graphData/OrderTheMost';
import SalesBySalePerson from './graphData/SalesBySalePerson';
import PaymentMethods from './graphData/PaymentMethods';
import AllLocation from './graphData/AllLocations';
import AgeAndGender from './graphData/AgeAndGender';
import SpecifiedOccasion from './graphData/SpecifiedOccasion';
import Device from './graphData/Device';
import { _Api } from '../api/_call';

const AdminDashboard = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [showCheckArea, setShowCheckArea] = useState(false);
  const [headerdata, setHeaderdata] = useState()
  const [dashboardhideshow, setDashboardhideshow] = useState()
  const [defoultset, setDefaultset] = useState(false)
  const onChangeValueUpdate = (keyName, value) => {
    let obj = { ...dashboardhideshow }
    obj[keyName] = value ? 1 : 0
    setDashboardhideshow(obj)
  }

  const deshbordheader = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/dashboard/headerStatics`)
      if (res?.code === 200) {
        setHeaderdata(res?.statics)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  useEffect(() => {
    deshbordheader()
  }, [])
  const [dataSelect, setdataSelect] = useState([])
  const duplicateHandler = async (graphname) => {
    const itemdaata = localStorage.getItem("dashboarddata")
    const parsedObject = JSON.parse(itemdaata);
    let data = [...parsedObject]
    let dashdata = []
    if (data?.length) {
      data.forEach((result) => {
        let checkdata = { ...result }
        if (result.name === graphname) {
          checkdata.index = result?.index + 1
          dashdata.push(checkdata)
        }
      });
      let array1 = []
      let array2 = []
      data.forEach((result, key) => {
        if (result?.index >= dashdata[0]?.index) {
          result.index = key + 1
          array2.push(result)
        } else {
          array1.push(result)
        }
      })
      array1.push(dashdata[0])
      let finaldata = array1.concat(array2)
      try {
        let postdata = {
          settings: finaldata
        }
        let res = await _Api(postdata, `api/v1/dashboard/defaultSettingOrderSave`)
        if (res?.code === 200) {
          const updatedDataSelect = finaldata.map(({ name, columnClass }) => (
            defaultdata.find((item) => item?.name === name)
          ));
          dashboardgetarray(updatedDataSelect)
        }
      } catch (err) {
        console.log(err, "err")
      }
    }
  };
  const deletedublicate = async (graphname) => {
    const itemdaata = localStorage.getItem("dashboarddata")
    const itemdaataindex = localStorage.getItem("dashboarddataindex")
    const parsedObject = JSON.parse(itemdaata);
    let data = [...parsedObject]
    let checkdublicate = []
    if (data?.length) {
      data.forEach((result) => {
        if (result?.name === graphname) {
          checkdublicate.push(result)
        }
      })
    }
    if (checkdublicate?.length > 1) {
      const index = data.findIndex(item => item.index === parseInt(itemdaataindex));
      if (index !== -1) {
        data.splice(index, 1);
      }
      if (data?.length) {
        data.forEach((result) => {
          if (result?.index > index) {
            result.index = result?.index - 1
          }
        })
      }
      try {
        let postdata = {
          settings: data
        }
        let res = await _Api(postdata, `api/v1/dashboard/defaultSettingOrderSave`)
        if (res?.code === 200) {
          const updatedDataSelect = data.map(({ name, columnClass }) => (
            defaultdata.find((item) => item?.name === name)
          ));
          dashboardgetarray(updatedDataSelect)
        }
      } catch (err) {
        console.log(err, "err")
      }
    } else {
      setDashboardhideshow(prevState => ({
        ...prevState,
        [graphname]: 0
      }));
      setDefaultset(true)
    }
  }

  const defaultdata = [
    {
      name: "TotalSales",
      content: (
        <TotalSales
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: 'mb-4 col-md-6 col-12',
    },
    {
      name: "SalesTheMost",
      content: (
        <SalesTheMost
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset}
          duplicateHandler={duplicateHandler}
          deletedublicate={deletedublicate}
        />

      ),
      columnClass: 'mb-4 col-md-6 col-12',
    },
    {
      name: "Inventory",
      content: (
        <InventoryStock
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: 'mb-4 col-md-12 col-12'
    },
    {
      name: "SummaryPurchaseOrder",
      content: (
        <SummaryPurchaseOrder
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: 'mb-4 col-md-6 col-12'
    },
    {
      name: "PurchaseOrder",
      content: (
        <PurchaseOrder
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: 'mb-4 col-xxl-3 col-md-6 col-12'
    },
    {
      name: "Purchase",
      content: (
        <Purchase
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: "mb-4 col-xxl-3 col-md-6 col-12"
    },
    {
      name: "CustomOrder",
      content: (
        <CustomOrder
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: "mb-4 col-md-6 col-12"
    },
    {
      name: "OrdersTheMost",
      content: (
        <OrderTheMost
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset}
          duplicateHandler={duplicateHandler}
          deletedublicate={deletedublicate} />
      ),
      columnClass: "mb-4 col-md-6 col-12"
    },
    {
      name: "SalesBySalesperson",
      content: (
        <SalesBySalePerson
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset}
          duplicateHandler={duplicateHandler}
          deletedublicate={deletedublicate} />
      ),
      columnClass: "mb-4 col-md-6 col-12"
    },
    {
      name: "PaymentMethod",
      content: (
        <PaymentMethods
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset}
          duplicateHandler={duplicateHandler}
          deletedublicate={deletedublicate} />
      ),
      columnClass: "mb-4 col-md-6 col-12"
    },
    {
      name: "AllLocations",
      content: (
        <AllLocation
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: "mb-4 col-xxl-3 col-md-6 col-12"
    },
    {
      name: "AgeandGender",
      content: (
        <AgeAndGender
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: "mb-4 col-xxl-3 col-md-6 col-12"
    },
    {
      name: "SpecifiedOccasion",
      content: (
        <SpecifiedOccasion
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: "mb-4 col-xxl-3 col-md-6 col-12"
    },
    {
      name: "Device",
      content: (
        <Device
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: "mb-4 col-xxl-3 col-md-6 col-12"
    },
    {
      name: "Lifestage",
      content: (
        <LifeStage
          setDashboardhideshow={setDashboardhideshow}
          setDefaultset={setDefaultset} />
      ),
      columnClass: "mb-4 col-xxl-3 col-md-6 col-12"
    }
  ]
  const saveshow = async () => {
    try {
      let postdata = dashboardhideshow
      let res = await _Api(postdata, `api/v1/dashboard/defaultSettingSave`)
      if (res?.code === 200) {
        showdashboard()
        setDefaultset(false)
        setShowCheckArea(false)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (defoultset) {
      saveshow()
    }
    // eslint-disable-next-line
  }, [defoultset])
  const onMoveContent = async ({ removedIndex, addedIndex }) => {
    const newDataSelect = arrayMoveImmutable(dataSelect, removedIndex, addedIndex)
    let data = newDataSelect.map((result, index) => {
      return {
        columnClass: result.columnClass,
        index,
        name: result?.name,
      };
    })
    try {
      let postdata = {
        settings: data
      }
      let res = await _Api(postdata, `api/v1/dashboard/defaultSettingOrderSave`)
      if (res?.code === 200) {
        setdataSelect(newDataSelect)
        dashboardgetarray(newDataSelect)
      }
    } catch (err) {
      console.log(err, "err")
    }

  }
  const dashboardgetarray = async (arraydata) => {
    localStorage.removeItem("dashboarddata")
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/dashboard/defaultSettingOrder`)
      if (res?.code === 200) {
        if (res?.settings?.length) {
          let array = [...arraydata]
          const updatedDataSelect = res?.settings.map(({ name, columnClass }) => (
            array.find((item) => item?.name === name)
          ));
          setdataSelect(updatedDataSelect)
          const jsonString = JSON.stringify(res?.settings);
          localStorage.setItem('dashboarddata', jsonString);
        } else {
          setdataSelect(arraydata)
        }

      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const showdashboard = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/dashboard/defaultSetting`)
      if (res?.code === 200) {
        let array = []
        if (dataSelect?.length) {
          array = [...dataSelect]
        } else {
          array = [...defaultdata]
        }
        if (array?.length) {
          array.forEach((column) => {
            if (res?.settings.hasOwnProperty(column.name)) {
              column.show = res?.settings[column.name];
            }
          });
        }
        setDashboardhideshow(res?.settings)
        dashboardgetarray(array)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    showdashboard()
    // eslint-disable-next-line
  }, [])
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <span>{t("Dashboard")}</span>
          </h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
          <div className="print-export-dropdown dropdown">
            <button
              className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
            </button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="dropdown-item print-dropdown-item">
                <div className="">
                  <img
                    src={base_assets + "images/icons/printer-icon.png"}
                    alt=""
                  />
                </div>
                <span className='mx-3'>{t("print")}</span>
              </li>
              <li className="dropdown-item" onClick={() => setShowCheckArea(current => !current)}>
                <div className="dropdown-item-icon">
                  <i className="fa fa-cog" aria-hidden="true"></i>
                </div>
                <span className='mx-3'>{t("Setting")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="dashboard-area">
        {showCheckArea ? <div className='dashboard-area-check-row d-flex flex-wrap mb-4 bg-white p-3'>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
              onChange={(e) => onChangeValueUpdate("TotalSales", e.target.checked)}
              checked={dashboardhideshow?.TotalSales ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s1" >{t("Total Sales")} </label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s2"
              onChange={(e) => onChangeValueUpdate("SalesTheMost", e.target.checked)}
              checked={dashboardhideshow?.SalesTheMost ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s2">{t("Sales The Most")} </label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s3"
              onChange={(e) => onChangeValueUpdate("Inventory", e.target.checked)}
              checked={dashboardhideshow?.Inventory ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s3">{t("Inventory")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s4"
              onChange={(e) => onChangeValueUpdate("SummaryPurchaseOrder", e.target.checked)}
              checked={dashboardhideshow?.SummaryPurchaseOrder ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s4">{t("Summary Purchase Order")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s5"
              onChange={(e) => onChangeValueUpdate("PurchaseOrder", e.target.checked)}
              checked={dashboardhideshow?.PurchaseOrder ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s5">{t("Purchase Order")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s6"
              onChange={(e) => onChangeValueUpdate("Purchase", e.target.checked)}
              checked={dashboardhideshow?.Purchase ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s6">{t("Purchase")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s7"
              onChange={(e) => onChangeValueUpdate("CustomOrder", e.target.checked)}
              checked={dashboardhideshow?.CustomOrder ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s7">{t("Custom Order")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s8"
              onChange={(e) => onChangeValueUpdate("OrdersTheMost", e.target.checked)}
              checked={dashboardhideshow?.OrdersTheMost ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s8">{t("Orders The Most")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s9"
              onChange={(e) => onChangeValueUpdate("SalesBySalesperson", e.target.checked)}
              checked={dashboardhideshow?.SalesBySalesperson ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s9">{t("Sales By Salesperson")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s10"
              onChange={(e) => onChangeValueUpdate("PaymentMethod", e.target.checked)}
              checked={dashboardhideshow?.PaymentMethod ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s10">{t("Payment Method")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s11"
              onChange={(e) => onChangeValueUpdate("AllLocations", e.target.checked)}
              checked={dashboardhideshow?.AllLocations ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s11">{t("All Locations")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s12"
              onChange={(e) => onChangeValueUpdate("AgeandGender", e.target.checked)}
              checked={dashboardhideshow?.AgeandGender ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s12">{t("Age and Gender")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s13"
              onChange={(e) => onChangeValueUpdate("SpecifiedOccasion", e.target.checked)}
              checked={dashboardhideshow?.SpecifiedOccasion ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s13">{t("Specified Occasion")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s14"
              onChange={(e) => onChangeValueUpdate("Device", e.target.checked)}
              checked={dashboardhideshow?.Device ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s14">{t("Device")}</label>
          </div>
          <div className='dashboard-area-check-row-single d-flex mb-2'>
            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s15"
              onChange={(e) => onChangeValueUpdate("Lifestage", e.target.checked)}
              checked={dashboardhideshow?.Lifestage ? true : false} />
            <label className="form-check-label cursor-pointer" htmlFor="s15">{t("LifeStage")}</label>
          </div>
          <div className='w-100 mt-3 d-flex justify-content-between align-items-center'><span className='text-decoration-underline link-color cursor-pointer' onClick={() => {
            setDashboardhideshow(prevState => ({
              ...prevState,
              "TotalSales": 1,
              "SalesTheMost": 1,
              "Inventory": 1,
              "SummaryPurchaseOrder": 1,
              "PurchaseOrder": 1,
              "Purchase": 1,
              "CustomOrder": 1,
              "OrdersTheMost": 1,
              "SalesBySalesperson": 1,
              "PaymentMethod": 1,
              "AllLocations": 1,
              "AgeandGender": 1,
              "SpecifiedOccasion": 1,
              "Device": 1,
              "Lifestage": 1
            }))
              ; setDefaultset(true)

          }}>{t("Reset Default")}</span>
            <button className='square-btn' onClick={() => saveshow()}><img src={base_assets + "/images/icons/white-save.png"} alt="" /></button>
          </div>
        </div> : ""}

        <div className="main-body-top-inventory main-content-wrapper-body-top-cards ">
          <div className="main-body-top-inventory-status">
            <div className="main-body-top-inventory-status-card">
              <div className="left-side rounded-circle p-2 redlightbg">
                <img
                  src={base_assets + "images/admin/icons/shopping-list.png"}
                  className="inventory-img"
                  alt="free-stock"
                />
              </div>
              <div className="right-side">
                <span className="inventory-name">{t("Total Order")}</span>
                <h2 className="inventory-value redlightcolor">{headerdata?.order} <span className='text-info fs-11'><i className="fa fa-line-chart" aria-hidden="true"></i> {parseFloat(headerdata?.growth).toFixed(2)}%</span></h2>
              </div>
            </div>
            <div className="main-body-top-inventory-status-card">
              <div className="left-side rounded-circle p-2 totalsalebg">
                <img
                  src={base_assets + "images/admin/icons/increasing.png"}
                  className="inventory-img"
                  alt="sku"
                />
              </div>
              <div className="right-side">
                <span className="inventory-name">{t("Total Sales")}</span>
                <h2 className="inventory-value totalsaletext">{headerdata?.sales}</h2>
              </div>
            </div>
            <div className="main-body-top-inventory-status-card">
              <div className="left-side rounded-circle p-2 allcustomergreenbg">
                <img
                  src={base_assets + "images/admin/icons/customer.png"}
                  className="inventory-img"
                  alt="value"
                />
              </div>
              <div className="right-side">
                <span className="inventory-name">{t("All Customers")}</span>
                <h2 className="inventory-value allcustomergreencolor">{headerdata?.Customer}</h2>
              </div>
            </div>
            <div className="main-body-top-inventory-status-card">
              <div className="left-side rounded-circle p-2 productcolorbg">
                <img
                  src={base_assets + "images/admin/icons/pro-count.png"}
                  className="inventory-img"
                  alt="free-stock"
                />
              </div>
              <div className="right-side">
                <span className="inventory-name">{t("Product Counts")}</span>
                <h2 className="inventory-value productcolor">{headerdata?.product}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className='dashboard-area-cards row'>

          <Container dragHandleSelector='.drag-btn' onDrop={onMoveContent}>
            {dataSelect?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Draggable className={item?.show ? item?.columnClass : "d-none"} key={index} onClick={() => localStorage.setItem('dashboarddataindex', index)}>{item?.content}</Draggable>
                </React.Fragment>
              )
            })}
          </Container>


        </div>
      </div>

    </div>
  )
}

export default AdminDashboard
