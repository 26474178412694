import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export const Reportmenupos = (props) => {
    const { t } = useTranslation();
    const { Reportname } = props
    return (
        <>
            <div className="dropdown">
                <button className="dropdown-toggle Dailysalereport-content-area-header-inner-dropdown" type="text" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {Reportname ? t(Reportname) : t("Daily sale report")}
                </button>
                <ul className="Dailysalereport-content-area-header-inner-dropdown-menu dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Dailysalereport" state={{ name: t("Daily sale report") }}>{t("Daily sale report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Cashregisterreport" state={{ name: t("Cash register report") }}>{t("Cash register report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Salepersonreport" state={{ name: t("Sale person report") }}>{t("Sale person report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Customerreport" state={{ name: t("Customer Report") }}>{t("Customer Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Reservereport" state={{ name: t("Reserve Report") }}>{t("Reserve Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Wishlistreport" state={{ name: t("Wishlist Report") }}>{t("Wishlist Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Exchangereport" state={{ name: t("Exchange Report") }}>{t("Exchange Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Depositreport" state={{ name: t("Deposit Report") }}>{t("Deposit Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Refundreport" state={{ name: t("Refund Report") }}>{t("Refund Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Giftcardreport" state={{ name: t("Gift Card Report") }}>{t("Gift Card Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Creditnotereport" state={{ name: t("Credit Note Report") }}>{t("Credit Note Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Customorderreport" state={{ name: t("Custom Order Report") }}>{t("Custom Order Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Repairorderreport" state={{ name: t("Repair Order Report") }}>{t("Repair Order Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Paymentreport" state={{ name: t("Payment Report") }}>{t("Payment Report")}</Link></li>
                    <li><Link className="dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item" to="/app/pos/Debitreport" state={{ name: t("Debit Note Report") }}>{t("Debit Note Report")}</Link></li>
                </ul>
            </div>
        </>
    );
};

