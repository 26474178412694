import CloseIcon from 'admin/common/icons/CloseIcon';
import LinkAttachmentIcon from 'admin/common/icons/LinkAttachmentIcon';
import PlusIcon from 'admin/common/icons/PlusIcon'
import PrintIcon from 'admin/common/icons/PrintIcon';
import RightArrow from 'admin/common/icons/right-arrow';
import SearchIcon from 'admin/common/icons/SearchIcon';
import FilterIconMain from 'admin/common/icons/sidebar/FilterIconMain';
import TableLoader from 'admin/common/TableLoader';
import { DateRange, DateRangeNew } from 'components/common/helpers/daterange';
import { Tablehead } from 'components/common/modules/Tablehead';
import { Pagination } from 'components/common/Pagination';
import { zoneAddInventory } from 'components/common/Tablehead';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Select from "react-select";
import ZoneFilter from './ZoneFilter';


export default function AddInventory() {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [limit, setLimit] = useState();
    const [filtername, setFiltername] = useState("");
    const [datefilter, setDatefilter] = useState([]);
    const [datalodaer, setDatsLodaer] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [state, setstate] = useState({totalPages: 20,currentPage: 1,});
    const { totalPages, currentPage } = state;

    const selectOptions = [
      { label: 100, value: 100 },
      { label: 200, value: 200 },
      { label: 300, value: 300 }
    ]
    const inventoryData = [
        {id: 'FG5d67', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:1, status:1},
        {id: 'FG5c67', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:3, status:3},
        {id: 'FG5s67', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:2, status:2},
        {id: 'FxG567', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:2, status:2},
        {id: 'FGxz567', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:2, status:2},
        {id: 'FG567', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:2, status:2},
        {id: 'FGxcv567', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:2, status:2},
        {id: 'FGc567', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:2, status:2},
        {id: 'FGz567', input: true, image: "", sku:"98SGF", item: 'Ring', size: '24', stockid: '294857845', qty:2, status:2}
    ]

    const getlimit = (limit) => {
      setLimit(limit);
    };
    const handlePaginations = (current) => {
        setstate({ ...state, currentPage: current });
        // getlowstocklist(current, "");
    
      };

  return (
    <>
    <div className="main-body zone_module">
      <div className="container">
        <div className="row">
        <div className="col-lg-12 d-flex justify-content-between">
              <h1 className="main-body-heading"> 
                <span>
                  {t('Inventory')} <span className='ms-2'><RightArrow/></span>
                </span>
                <span>
                  {t('Zone')} <span className='ms-2'><RightArrow/></span>
                </span>
                <span>
                  {t('Create Issue')} <span className='ms-2'><RightArrow/></span>
                </span>
                {t("Add Inventory")}
              </h1>
              <Link
                    to={-1}
                    className="confirmbtn"
                  >
                      {t("Confirm")}
              </Link>
        </div>
        <div className='zone_module-inner'>
        <div className="main-body-top-status-bar">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />
               
                <div className="main-body-top-status-bar-multi-date-picker">
                <div className={`main-body-top-status-bar-multi-date-picker-iconholder ${openSearch ? 'active' : ''}`}><span onClick={()=>setOpenSearch(true)}><SearchIcon color="#A4A4A4"/></span><div className={`main-body-top-status-bar-multi-date-picker-iconholder-searchbar ${openSearch ? 'active' : ""}`}><input type="text" className='input'/> <span onClick={()=>setOpenSearch(false)}><CloseIcon/></span> </div></div>
                  <span onClick={()=>setToggleFilter(!toggleFilter)} className='main-body-top-status-bar-multi-date-picker-iconholder'><FilterIconMain width="24px" height="24px"/></span>
                  <DateRangeNew setDatefilter={setDatefilter} />
                 
                </div>
        </div>
        <div className='zone_module-inner-table'>
        <div
                className="main-body-main-table-wrap position-relative"
                style={{ maxWidth: '100%', overflowX: 'auto' }}
              >
                <table
                  id="my_inventory"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead tablehead={zoneAddInventory} tblName={'zoneEditcreate_table'}  />
                  <tbody>
                    <>

                      {inventoryData?.length ? inventoryData.map((result, key) => {
                        return (
                          <tr key={key} className="">
                            <td>
                              <div className={'com-check'}>
                                <input key={key}
                                  className='inventorytablecheck inventorytableHead gisClassTh'
                                  id={result?.id}
                                  type="checkbox"
                                />
                                <label htmlFor={result?.id} className="com-check-label" />
                              </div>
                            </td>
                            <td className="">{key+1}</td>
                            <td className="table-pro-img" >
                              <img src='https://www.figma.com/file/Q1DYtqeDh9a6U0axVOhrhp/image/8898bd31c4c4c238fcbad26c8df744b0d47f95b1'/>
                            </td>
                            <td className="">
                              {result?.sku ? result?.sku : "-"}
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.item ? result?.item : "-"}</div>
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.size ? result?.size : "-"}</div>
                            </td>
                            <td>
                              <div className={`tooltip-area-up ${key === 1 ? 'stockactive cursor-pointer' : ""}`}>{result?.stockid ? result?.stockid : "-"} {key < 2 ? <LinkAttachmentIcon/> : null} </div>
                            </td>
                            <td className="">{result?.qty ? result?.qty : "-"}</td>
                            <td className=""><div className={`ball ${result?.status === 1 ? 'yellow' : result?.status === 2 ? 'blue' : result?.status === 3 ?'platinum' : 'blue'}`}></div></td>
                          </tr>
                        );
                      }) : ''}
                    </>
                  </tbody>
                </table>
                {datalodaer && <TableLoader />}
              </div>
        </div>
        <div className='zone_module-inner-bottom'>
            <div>
                {t("Showing")} 1 {t("to")} 10 {t("of")} 10 {t("Entries")}
            </div>
        {inventoryData.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
        </div>
        <div className='zone_module-inner-back' onClick={()=>navigate(-1)}>
            {t("Back")}
        </div>
        </div>
    </div>
    </div>
    </div>
    { toggleFilter &&
      <ZoneFilter setToggleFilter={setToggleFilter}/>
    }
    </>
    )
}
