import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import './catalog.scss'
import CheckoutBlank from '../checkout/checkoutBlank'
import { DaimondSearchModal } from '../catalog/DaimondSearch';
import CatalogNavbar from './CatalogNavbar'
const CatalogDiamond = () => {
  const [pNavid, setproductNavid] = useState();
  const navigate = useNavigate();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  useEffect(() => {
    if (pNavid) {
      navigate('/app/pos/catalog/CollectionList', {
        state: { navids: pNavid },
      })
    }
  }, [navigate, pNavid]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area">
              <div className="catalog-area-header-area">
                <CatalogNavbar setproductNavid={setproductNavid} location={"/app/pos/CatalogDiamond"} />
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left">
                    <button className="catalog-area-header-area-control-left-btn">
                      <img
                        src={base_assets + 'images/pos/icons/warehouse.png'}
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <button className="com-search-area-left-img scan-img">
                        <img
                          src={base_assets + 'images/pos/icons/scan-icon.png'}
                          alt=""
                        />
                      </button>
                      <input
                        type="text"
                        placeholder="Scan barcode, Search SKU"
                      />
                      <img
                        className="com-search-area-img"
                        src={
                          base_assets + 'images/pos/icons/magnifying-glass.png'
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <DaimondSearchModal />
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )
}

export default CatalogDiamond;