import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { Container, Draggable } from '@smooth-dnd/react'
import { arrayMoveImmutable } from 'array-move'
import { _Api, graphqlPostapp } from "../api/_call";
import RightArrow from "../common/icons/right-arrow";
import { Link } from 'react-router-dom'
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const SettingCurrency = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [currencygraph, setCurrencygraph] = useState([])
  const [completeDataObject, setCompliteDataObject] = useState(
    { "All_Currency": "", "Default_currency": "" }
  )
  const onChangeValueUpdate = (keyName, value) => {
    if (keyName === "Default_currency") {
      setCompliteDataObject(prevState => ({
        ...prevState,
        "Default_currency": value
      }));
    }
  }
  const currencyview = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/profile/currency-view`)
      if (res?.code === 200) {
        setCompliteDataObject(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getCurrencyList = async () => {
    try {
      const postdata = `{
          currency{
              currency
              flag
              currency_name
              }
      }`;
      let responce = await graphqlPostapp(postdata);
      setCurrencygraph(responce.data?.currency);

    } catch (err) {
      console.log(err, "err");

    }
  };
  useEffect(() => {
    currencyview()
    getCurrencyList()
  }, [])

  const editcurrency = async () => {
    let All_Currency = []
    let finaldata = { ...completeDataObject }
    finaldata?.All_Currency.forEach((element) => {
      All_Currency.push(element.currency)
    })
    let GetloadingID = loading();
    try {
      let postdata = {
        "Default_currency": completeDataObject?.Default_currency?.currency,
        "All_Currency": All_Currency
      }
      let res = await _Api(postdata, `api/v1/App/profile/currency-edit`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        currencyview()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const addEventList = () => {
    let newValues_ = { ...completeDataObject };
    let newValues = [...newValues_?.All_Currency];
    newValues.push({
      "currency": "",
      "flag": "",
      "currency_name": ""
    });
    newValues_.All_Currency = newValues;
    setCompliteDataObject(newValues_);

  };

  const handleRemoveElement = (indexToRemove) => {
    let newValues_ = { ...completeDataObject };
    let newValues = [...newValues_?.All_Currency];
    let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
    newValues_.All_Currency = updatedArray;
    setCompliteDataObject(newValues_);
  };

  const handleSelectChange = (index, value) => {
    let newValues_ = { ...completeDataObject };
    let newValues = [...newValues_?.All_Currency];
    newValues[index] = value;
    newValues_.All_Currency = newValues;
    setCompliteDataObject(newValues_);
  };
  const onMoveContent = async ({ removedIndex, addedIndex }) => {
    const newDataSelect = arrayMoveImmutable(completeDataObject?.All_Currency, removedIndex, addedIndex)
    let All_Currency = []
    newDataSelect.forEach((element) => {
      All_Currency.push(element.currency)
    })
    let GetloadingID = loading();
    try {
      let postdata = {
        "Default_currency": completeDataObject?.Default_currency?.currency,
        "All_Currency": All_Currency
      }
      let res = await _Api(postdata, `api/v1/App/profile/currency-edit`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        currencyview()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <Link className="text-decoration-none" to="/app/admin/settings">{t("Setting")}</Link>  <RightArrow /> <span className="">{t("Currency")}</span></h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center"
          onClick={() => editcurrency()}>
          <button className="square-btn"><img src={base_assets + "images/icons/white-save.png"} alt="" /></button>
        </div>
      </div>
      <div className="admin-main-card">
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <div className="v3-input-group">
                  <label className="v3-input-group-label">
                    {t("Default currency")}
                  </label>
                  <Select
                    className="select-set select w-100 p-0"
                    placeholder=""
                    options={currencygraph}
                    value={completeDataObject?.Default_currency}
                    onChange={(e) => {
                      onChangeValueUpdate("Default_currency", e)
                    }}
                    classNamePrefix="common-select"
                    isSearchable={false}
                    getOptionLabel={(e) => (
                      <div className="common-select-icon">
                        <img src={base_assets + "/images/square-flags/" + e.flag} alt="" /><span>{e.currency} - {e.currency_name}</span>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 ms-4">
            <div className="row">
              <div className="col-md-12">
                <div className="v3-input-group">
                  <label className="v3-input-group-label">{t("All currencies")}</label>

                  <Container lockAxis="y" dragHandleSelector='.drag-btn' onDrop={onMoveContent}>
                    {completeDataObject?.All_Currency?.length ? completeDataObject?.All_Currency.map((result, key) => {
                      return (
                        <Draggable key={key}>
                          <div className="row d-flex align-items-center mb-3">
                            <div className="w-auto pe-0 drag-btn">
                              <img
                                className="drag-btn-icon"
                                src={base_assets + 'images/admin/icons/drag-icon.png'} alt=""
                              />
                            </div>
                            <div className="col-md-8">
                              <Select
                                className="select-set select w-100 p-0"
                                placeholder=""
                                options={currencygraph}
                                value={result}
                                onChange={(e) =>
                                  handleSelectChange(
                                    key,
                                    e
                                  )}
                                classNamePrefix="common-select"
                                isSearchable={false}
                                getOptionLabel={(e) => (
                                  <div className="common-select-icon">
                                    <img src={base_assets + "/images/square-flags/" + e.flag} alt="" /><span>{e.currency} - {e.currency_name}</span>
                                  </div>
                                )}
                              />
                            </div>
                            {key !== 0 ?
                              <div className="w-auto px-0"
                                onClick={() => handleRemoveElement(key)}>
                                <img src={base_assets + 'images/icons/minus-detail.png'} alt="" />
                              </div>
                              : ""}
                          </div>
                        </Draggable>
                      )
                    }) : ""}


                  </Container>

                  <button className="border-none main-green-bg text-white rounded-1 py-1 px-2 mt-4 d-block fs-14"
                    onClick={() => addEventList()}>
                    <img className='me-1' src={base_assets + "images/icons/add-icon-white.png"} alt="" /> {t("Add More Currencies")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingCurrency
