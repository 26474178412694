import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import "../common/multi-select/multiSelect.scss";
import { useForm } from "react-hook-form";
import { graphqlPost, _Api } from "../api/_call";
import ProductMenu from "./productMenu";
import { success, error, loading, Updateloading } from "../common/helpers/toastify";
import {
  ItemList,
  CollectionsList,
  StylesList,
  EcomcategorieList,
  GetLangList,
} from "../product/masters";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const Product = () => {
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const { t } = useTranslation();
  const { productId_url } = useParams();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  var gender_value = [];
  var ConsumerLifestage_value = [];
  var SpecifiedOccasion_value = [];
  const [product_value_arr, setproduct_value_arr_val] = useState([{ product_id: "", name: "", varient_type: "", sku: "" }]);
  const getProductUrl_details = async () => {
    if (productId_url !== undefined) {
      try {
        const postdata = {
          id: productId_url,
        };
        let resPoOrders = await _Api(postdata, `api/v1/APP/product/getProductType`);
        if (resPoOrders.code === 200) {
          setproduct_value_arr_val(resPoOrders.data);
          setProduct_id(resPoOrders.data[0]?.product_id);
          getVarientImage(resPoOrders?.data[0]?.varient_type);
        } else {
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [img, setImg] = useState("");
  const [IsDisable, setIs_disable] = useState(false);
  const [varient_image, setVarient_image] = useState("");
  const [call, setcall] = useState("");
  const [designStatus, setDesignStatus] = useState("");
  const [selectedButton, setButton] = useState("");
  const [productimage, setproduct_image] = useState("");
  const [productConsumerLifestage_, SetConsumerLifestage_] = useState([]);
  const [Item_product, SetItem_product] = useState("");
  const [Collections_product, SetCollections_product] = useState("");
  const [stype_product, SetStype_product] = useState("");
  const [product_variants_enable_, setproduct_variants_enable_] = useState("1");
  const [Point_of_sale_, setPoint_of_sale] = useState(false);
  const [point_of_sale_eCommerce_, setPoint_of_sale_eCommerce] =
    useState(false);
  const [GenderProduct_value_onload_, SetGenderProduct_value_onload] = useState(
    []
  );
  const [ecomcategorie_, setEcomcategorie_] = useState([]);

  const [SpecifiedOccasion_value_onload_, SetSpecifiedOccasion_value_onload] =
    useState([]);
  const [product_id_state, setProduct_id] = useState("");
  const [productClone] = useState(false);
  const [form_description_field, setDescription_field] = useState([]);
  const [getCustomErrors, setCustomErrors] = useState({});
  const [generatedisdata, setGeneratedisdata] = useState()
  const handleClick_eCommerce_ = () =>
    setPoint_of_sale_eCommerce(!point_of_sale_eCommerce_);
  const handleClick_Point_of_sale_ = () => setPoint_of_sale(!Point_of_sale_);

  const setconsumerSelected = (data) => {
    SetConsumerLifestage_(data);
  };

  const [getGeneral_product, setgetGeneral_product] = useState({
    Item: "",
    Gender: "",
  });


  const [ecomcategorie, setEcomcategorie] = useState([]);
  const arrayColumn = (arr_, index) => arr_?.map((val) => val[index]);


  const consumer = [
    {
      value: "Teen",
      label: "Teen",
      icon: <img src={base_assets + "images/icons/Teenager.png"} alt="" />,
    },
    {
      value: "Young Adult",
      label: "Young Adult",
      icon: <img src={base_assets + "images/icons/young-icon.png"} alt="" />,
    },
    {
      value: "Adult",
      label: "Adult",
      icon: <img src={base_assets + "images/icons/adult-icon.png"} alt="" />,
    },
    {
      value: "Retired",
      label: "Retired",
      icon: <img src={base_assets + "images/icons/Elderly_old.png"} alt="" />,
    },

  ];

  const gender = [
    {
      value: "Female",
      label: "Female",
      icon: <img src={base_assets + "images/icons/female-icon.png"} alt="" />,
    },
    {
      value: "Male",
      label: "Male",
      icon: <img src={base_assets + "images/icons/male-icon.png"} alt="" />,
    },
    {
      value: "Group",
      label: "Group",
      icon: <img src={base_assets + "images/icons/group.png"} alt="" />,
    },
    {
      value: "Other",
      label: "Other",
      icon: <img src={base_assets + "images/icons/transgenders-icon.png"} alt="" />,
    },
    {
      value: "Couples",
      label: "Couples",
      icon: <img src={base_assets + "images/icons/couple.png"} alt="" />,
    },
  ];

  const occasion = [
    {
      value: "Personal",
      label: "Personal",
      icon: <img src={base_assets + "images/icons/personal-icon.png"} alt="" />,
    },
    {
      value: "Gift",
      label: "Gift",
      icon: <img src={base_assets + "images/icons/gift-icon.png"} alt="" />,
    },
    {
      value: "Anniversary",
      label: "Anniversary",
      icon: <img src={base_assets + "images/icons/Anniversary.png"} alt="" />,
    },
    {
      value: "Wedding",
      label: "Wedding",
      icon: <img src={base_assets + "images/icons/wedding.png"} alt="" />,
    },
    {
      value: "Other",
      label: "Other",
      icon: <img src={base_assets + "images/icons/other.png"} alt="" />,
    },
  ];

  const status = [
    {
      value: "1",
      dot: (
        <span className="product-ct-wrap-col-right-status-form-status-active-dot green-bg"></span>
      ),
      label: (
        <label className="product-ct-wrap-col-right-status-form-status-active-text status-green-text">
          Active
        </label>
      ),
    },
    {
      value: "0",
      dot: (
        <span className="product-ct-wrap-col-right-status-form-status-active-dot danger-bg"></span>
      ),
      label: (
        <label className="product-ct-wrap-col-right-status-form-status-active-text danger-text">
          Inactive
        </label>
      ),
    },
  ];
  // const [selectedStatus, setSelectedStatus] = useState(status[0]);
  const [statusselectedOptions, setstatusSelectedOptions] = useState(status[0]);
  useEffect(() => {
    if (getGeneral_product.Category) {
      setEcomcategorie_(getGeneral_product.Category);
    }
  }, [getGeneral_product.Category]);

  useEffect(() => {
    reset(getGeneral_product);
    // eslint-disable-next-line
  }, [getGeneral_product]);
  const [product_value_arr_, setProduct_value_arr] = useState([]);

  const onImageChange = (e) => {
    const [file] = e.target.files;
    setImg(URL.createObjectURL(file));
    convertBase64(file);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        setproduct_image(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  var setected_opt_ = {
    generalInfo: "active",
    advanced: "",
    variant: "",
    choice: "",
  };
  const onSubmit = async (data) => {
    getCustomErrors.name = false;
    getCustomErrors.Matatag = false;
    getCustomErrors.Description = false;
    getCustomErrors.ID = false;
    getCustomErrors.SKU = false;
    setCustomErrors(getCustomErrors);
    let GetloadingID = loading();
    setButton("pe-none ct-disable");
    data.product_image = productimage;
    data.Category = arrayColumn(ecomcategorie, "id");
    data.Gender = arrayColumn(GenderProduct_value_onload_, "value");
    data.ConsumerLifestage_ = arrayColumn(productConsumerLifestage_, "value");
    data.SpecifiedOccasion = arrayColumn(
      SpecifiedOccasion_value_onload_,
      "value"
    );
    data.status = statusselectedOptions?.value;
    data.product_variants_enable = product_value_arr[0].varient_type;
    data.Item = Item_product;
    data.Collection = Collections_product;
    data.Style = stype_product;
    data.alternateDescription = form_description_field;
    data.Point_of_sale = Point_of_sale_ === true ? 1 : 0;
    data.Point_of_sale_eCommerce = point_of_sale_eCommerce_ === true ? 1 : 0;
    data.product_id = product_id_state;



    try {
      const postdata = data;
      let resPoOrders = await _Api(postdata, `api/v1/APP/product/saveGeneral`);
      if (resPoOrders.code === 200) {
        setProduct_id(resPoOrders.id);

        Product_value_arr_data("product_id", resPoOrders.id);
        Updateloading(t, GetloadingID, t("Added successfully"));
        //success("Added successfully");
        setButton("pe-none ct-disable");
      } else {
        setButton("ErrorShowBtn");
        if (resPoOrders.errors) {
          Updateloading(t, GetloadingID, resPoOrders.errors, 'error');
        } else {
          Updateloading(t, GetloadingID, resPoOrders.message, 'error');
        }

        if (resPoOrders.errors?.name) {
          getCustomErrors.name = true;

          setCustomErrors(getCustomErrors);
        }
        if (resPoOrders.errors?.Matatag) {
          getCustomErrors.Matatag = true;
          setCustomErrors(getCustomErrors);
        }
        if (resPoOrders.errors?.Description) {
          getCustomErrors.Description = true;
          setCustomErrors(getCustomErrors);
        }
        if (resPoOrders.errors?.ID) {
          getCustomErrors.ID = true;
          setCustomErrors(getCustomErrors);
        }
        if (resPoOrders.errors?.SKU) {
          getCustomErrors.SKU = true;
          setCustomErrors(getCustomErrors);

        }


      }
    } catch (err) {
      console.log(err, "err");
      //error(err);
      Updateloading(t, GetloadingID, t("something went wrong"), 'error');
      setButton("ErrorShowBtn");

    }
  };
  const Product_value_arr_data = (key, value) => {
    if (key === "varient_type") {
      setproduct_variants_enable_(value);
    }
    var product_value_arr_ = [...product_value_arr];
    product_value_arr_[0][key] = value;
    setProduct_value_arr(product_value_arr_);
  };
  let addDescription_field = () => {
    setDescription_field([
      ...form_description_field,
      { alternateDescription: "", language: "" },
    ]);
  };
  const description_field_map = (key, value, index) => {
    var form_description_field_arr = [...form_description_field];
    form_description_field_arr[index][key] = value;
    setDescription_field(form_description_field_arr);
  };

  const removeDescription_field = (index) => {
    let form_description_field_ = [...form_description_field];
    const result = form_description_field_.filter((element, ind) => {
      return ind !== index;
    });
    setDescription_field(result);
  };
  useEffect(() => {
    if (product_id_state) {
      getGeneral();
    } else {
      Product_value_arr_data(
        "varient_type",
        product_variants_enable_
      )
    }
    // eslint-disable-next-line
  }, [product_id_state]);

  useEffect(() => {
    getProductUrl_details();
    generalsetup();
    // eslint-disable-next-line
  }, []);
  const getGeneral = async () => {
    try {
      const postdata = {
        id: product_id_state,
      };
      let resPoOrders = await _Api(postdata, `api/v1/APP/product/getGeneral`);
      if (resPoOrders.code === 200) {
        setgetGeneral_product(resPoOrders.data);
        setImg(resPoOrders.data.main_image);
        setProduct_value_arr([
          {
            product_id: resPoOrders.data._id,
            name: resPoOrders.data.name,
            varient_type: resPoOrders.data.product_variants_enable,
            type_: productClone
          },
        ]);

        if (productClone === 'clone') {
          resPoOrders.data.SKU = '';
          resPoOrders.data.ID = '';
          setIs_disable("");
        }
        SetItem_product(resPoOrders?.data?.Item);
        SetCollections_product(resPoOrders?.data?.Collection);
        SetStype_product(resPoOrders?.data?.Style);
        resPoOrders.data.Gender?.forEach((ele, index) => {
          gender?.forEach((ele_, index_) => {
            if (ele_.value === ele) {
              gender_value.push(ele_);
            }
          });
        });
        resPoOrders?.data?.SpecifiedOccasion?.forEach((ele, index) => {
          occasion?.forEach((ele_, index_) => {
            if (ele_.value === ele) {
              SpecifiedOccasion_value.push(ele_);
            }
          });
        });
        resPoOrders?.data?.ConsumerLifestage_?.forEach((ele, index) => {
          consumer?.forEach((ele_, index_) => {
            if (ele_.value === ele) {
              ConsumerLifestage_value.push(ele_);
            }
          });
        });
        SetConsumerLifestage_(ConsumerLifestage_value);
        SetGenderProduct_value_onload(gender_value);
        SetSpecifiedOccasion_value_onload(SpecifiedOccasion_value);
        setPoint_of_sale(resPoOrders.data.Point_of_sale);
        var Point_of_sale_eCommerces =
          resPoOrders.data.Point_of_sale_eCommerce === 1 ? true : false;
        var Point_of_sales = resPoOrders.data.Point_of_sale === 1 ? true : false;
        setPoint_of_sale_eCommerce(Point_of_sale_eCommerces);
        setPoint_of_sale(Point_of_sales);
        setproduct_variants_enable_(resPoOrders.data.product_variants_enable);
        setEcomcategorie(resPoOrders.data.Category);
        setDescription_field(resPoOrders.data.alternateDescription)

        if (resPoOrders?.data?.status === "0") {
          setstatusSelectedOptions(status[1])
        }
      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!getGeneral_product.Item) {
        getGeneral_product.Item = "value";
      }
      setcall(1);
    }, 100);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  const setgenderSelectedOptions = (opt) => {
    SetGenderProduct_value_onload(opt);
  };
  const setoccasionSelectedOptions = (opt) => {
    SetSpecifiedOccasion_value_onload(opt);
  };
  const generalsetup = async () => {
    try {
      const postdata = `
      {
                              generalsetup {
                                   productChoice
 
                                }
                              }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setDesignStatus(responce?.data?.generalsetup?.productChoice);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const getVarientImage = (type) => {
    var image = "";
    if (type === 1) {
      image = base_assets + "images/icons/variant-sm.png";
    } else if (type === 2) {
      image = base_assets + "images/pro-ring-img.png";
    }
    setVarient_image(image);
  };

  const generatedeis = async (metaTag, name, language, index, label) => {
    if (label === "Description") {
      setGeneratedisdata(prevState => ({
        ...prevState,
        data: {
          loader: true,
        }
      }));
    } else if (label === "alternateDescription") {
      let arr = [...form_description_field]
      if (arr?.length) {
        arr[index].alternateDescription = ""
        arr[index].loader = true
      }
      setDescription_field(arr)
    }
    try {
      let postdata = {
        "name": name ? name : generatedisdata?.name,
        "metaTag": metaTag ? metaTag : generatedisdata?.metaTag,
        "language": language ? language : "english"
      }
      let responce = await _Api(postdata, `api/v1/APP/ai/product-discription`)
      if (responce?.code === 200) {
        success("Description Successfuly Generate")
        if (label === "Description") {
          setGeneratedisdata(prevState => ({
            ...prevState,
            data: {
              loader: false,
            }
          }));
          setgetGeneral_product(prevState => ({
            ...prevState,
            Description: responce?.data
          }))
        } else if (label === "alternateDescription") {
          let arr = [...form_description_field]
          if (arr?.length) {
            arr[index].alternateDescription = responce?.data
            arr[index].loader = false
          }
          setDescription_field(arr)
        }
      } else {
        setGeneratedisdata(prevState => ({
          ...prevState,
          data: {
            loader: false,
          }
        }));
        responce?.errors ? error(t(responce?.errors)) : error(t(responce?.message))
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  return (
    <React.Fragment key="1">
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <ProductMenu
                  product_value_arr={product_value_arr_}
                  setected_opt={setected_opt_}
                  selectedButton_={selectedButton}
                  setIs_disable={setIs_disable}
                  productId_url={productId_url}
                />
                <div className={"product-ct-wrap product-pt " + IsDisable}>
                  <div className="product-ct-wrap-col-left">
                    <div className="product-ct-wrap-col-left-name d-none">
                      <h1 className="product-ct-wrap-col-left-name-text">#{getGeneral_product?.name}</h1>
                      <h1 className="product-ct-wrap-col-left-name-text ms-auto">{product_value_arr[0]?.sku}</h1>
                      <img className="product-ct-wrap-col-left-name-img" src={varient_image} alt="" />
                    </div>
                    <div className="row">
                      <div className="col-md-7 mb-4  product-name-input">
                        <div className="placeholder-up-input form-floating w-100">
                          <input
                            type="text"
                            {...register(
                              "name",
                              { value: getGeneral_product?.name },
                              {
                                required: "Required",
                              }
                            )}
                            onChange={(e) => setGeneratedisdata(prevState => ({
                              ...prevState,
                              name: e.target.value
                            }))}
                            className={getCustomErrors?.name ? 'form-control is-invalid placeholder-up-input-field form-control' : "placeholder-up-input-field form-control"}
                            required="" placeholder="Product Name"

                          />
                          <label className="placeholder-up-input-label">
                            # {t("Product Name")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="placeholder-up-input form-floating textarea-add-ct w-100">
                          <textarea
                            rows="4"
                            {...register(
                              "Matatag",
                              getGeneral_product?.Matatag,
                              {
                                required: "Required",
                              }
                            )}
                            onChange={(e) => setGeneratedisdata(prevState => ({
                              ...prevState,
                              metaTag: e.target.value
                            }))}
                            className={`placeholder-up-input-field  form-control ${(getCustomErrors.Matatag && "form-control is-invalid") || ""
                              }`}
                            defaultValue={getGeneral_product?.Matatag} placeholder='Meta Tag'
                          ></textarea>
                          <label className="placeholder-up-input-label">
                            {t("Meta Tag")}
                          </label>

                        </div>
                      </div>
                      <div className="col-md-12 mb-5">
                        <div className="placeholder-up-input form-floating textarea-add-ct w-100">
                          <textarea
                            {...register(
                              "Description",
                              getGeneral_product?.Description,
                              {
                                required: "Required",
                              }
                            )}
                            className={`placeholder-up-input-field form-control ${(getCustomErrors.Description && "form-control is-invalid") || ""
                              }`}
                            defaultValue={getGeneral_product?.Description}
                            rows="6" placeholder="Description"
                          ></textarea>
                          <label className="placeholder-up-input-label">
                            {t("Description")}
                          </label>
                          <button
                            className={"textarea-add-ct-btn ct-disable-btn tooltip-area"}
                            onClick={() => addDescription_field()}
                            type="button"
                          >
                            <img
                              src={base_assets + "images/icons/add-detail.png"} alt=""
                            />
                            <span className="tooltip-area-text main-green-bg px-2 py-1 rounded-1 text-nowrap bottom-100">Add Description</span>
                          </button>
                          {generatedisdata?.data?.loader ?
                            <span className="loader-dots sparkle-loader position-absolute end-0 me-3 me-xl-4 bottom-0 mb-3"></span> :
                            <button
                              className={"textarea-add-ct-btn ct-disable-btn bottom-0 mb-2 tooltip-area"}
                              style={{ "top": "auto" }}
                              type="button"
                              onClick={() => generatedeis(getGeneral_product?.Matatag, getGeneral_product?.name, "english", "", "Description")}
                            >
                              <img
                                src={base_assets + "images/icons/sparkle-icon.png"}
                                alt="" />
                              <span className="tooltip-area-text main-green-bg px-2 py-1 rounded-1 text-nowrap bottom-100">Generate Description</span>
                            </button>}
                        </div>
                      </div>
                      {form_description_field.map((element, index) => (

                        <React.Fragment key={index}>
                          <div className="col-md-12 mb-4 position-relative">
                            <div className="col-md-4">
                              <GetLangList
                                SetLangList_map={form_description_field}
                                index_list={index}
                                setDescription_language={setDescription_field}
                                selected_id={element.language}
                              ></GetLangList>
                            </div>
                            <button
                              className={"textarea-add-ct-btn ct-disable-btn"}
                              onClick={() => removeDescription_field(index)}
                              type="button"
                            >
                              <img
                                src={base_assets + "images/icons/minus-detail.png"}
                                alt="" />
                            </button>
                          </div>
                          <div className="col-md-12 mb-5">
                            <div className="placeholder-up-input textarea-add-ct w-100">
                              <textarea
                                onChange={(e) =>
                                  description_field_map(
                                    "alternateDescription",
                                    e.target.value,
                                    index
                                  )
                                }
                                dir={
                                  element?.language ===
                                    "arabic"
                                    ? "rtl"
                                    : ""
                                }
                                className={
                                  `placeholder-up-input-field `
                                }
                                rows="6"
                                value={element.alternateDescription}
                              ></textarea>
                              <label className="placeholder-up-input-label">
                                {t("Description")}
                              </label>
                              {element?.loader ?
                                <span className="loader-dots sparkle-loader position-absolute end-0 me-3 me-xl-4 bottom-0 mb-3"></span> :
                                <button
                                  className={"textarea-add-ct-btn ct-disable-btn bottom-0 mb-2 tooltip-area"}
                                  style={{ "top": "auto" }}
                                  type="button"
                                  onClick={() => {
                                    generatedeis(getGeneral_product?.Matatag, getGeneral_product?.name, element?.language, index, "alternateDescription")
                                  }}
                                >
                                  <img
                                    src={base_assets + "images/icons/sparkle-icon.png"} alt=""
                                  />
                                  <span className="tooltip-area-text main-green-bg px-2 py-1 rounded-1 text-nowrap bottom-100">Generate Description</span>
                                </button>
                              }
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>

                    <div className="row product-ct-wrap-col-left-input-select-wrap mb-4">
                      <div className="col-xl-7 col-md-12 product-ct-wrap-col-left-input-select-wrap-input">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="placeholder-up-input form-floating w-100">
                              <input
                                defaultValue={getGeneral_product?.ID}
                                type="text"
                                {...register("ID", getGeneral_product?.ID, {
                                  required: "Required",
                                })}
                                className={`placeholder-up-input-field form-control border-0 ${(getCustomErrors.ID && "form-control is-invalid") ||
                                  ""
                                  }`} placeholder="Product ID"
                              />
                              <label className="placeholder-up-input-label">
                                {t("Product ID")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="placeholder-up-input form-floating w-100">
                              <input
                                defaultValue={getGeneral_product?.upc_no}
                                type="text"
                                {...register(
                                  "upc_no",
                                  getGeneral_product?.upc_no,
                                  {
                                    required: "Required",
                                  }
                                )}
                                className={`placeholder-up-input-field form-control border-0 ${(errors.name && "form-control is-invalid") ||
                                  ""
                                  }`} placeholder="UPC No."
                              />
                              <label className="placeholder-up-input-label">
                                {t("UPC No.")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="placeholder-up-input form-floating w-100">
                              <input
                                defaultValue={getGeneral_product?.SKU}
                                type="text"
                                {...register("SKU", getGeneral_product?.SKU, {
                                  required: "Required",
                                })}
                                className={`placeholder-up-input-field form-control border-0 ${(getCustomErrors.SKU && "form-control is-invalid") ||
                                  ""
                                  }`} placeholder="SKU"
                              />
                              <label className="placeholder-up-input-label">
                                {t("SKU")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row product-ct-wrap-col-left-input-select-wrap mb-5">
                      <div className="col-xl-7 col-md-12 product-ct-wrap-col-left-input-select-wrap-input">
                        <div className="row">
                          <div className="col-md-4">
                            {call ? (
                              <ItemList
                                SetItem_product={SetItem_product}
                                selected_id={Item_product}
                              ></ItemList>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <CollectionsList
                              SetCollections_product={SetCollections_product}
                              selected_id={Collections_product}
                            ></CollectionsList>
                          </div>
                          <div className="col-md-4">
                            <StylesList
                              SetStype_product={SetStype_product}
                              selected_id={stype_product}
                            ></StylesList>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        "product-ct-wrap-col-left-additional-info-box "
                      }
                    >
                      <h4 className="product-ct-wrap-col-left-additional-info-box-heading">
                        {t("Additional Information")}
                        <span className="question-mark">?</span>
                      </h4>
                      <div className="product-multi-select">
                        <label className="product-multi-select-label">
                          {t("Consumer Lifestage")}
                        </label>
                        <div className="product-multi-select-box ">
                          <Select
                            options={consumer}
                            value={productConsumerLifestage_}
                            placeholder={t("Consumer Lifestage")}
                            onChange={(opt) => setconsumerSelected(opt)}
                            classNamePrefix="product-multi-select"
                            getOptionLabel={(e) => (
                              <div className="product-multi-select__multi-value__label-ct ">
                                {e.icon}
                                <span>{e.label}</span>
                              </div>
                            )}
                            isSearchable={false}
                            isMulti

                          />
                        </div>
                      </div>
                      <div className="product-multi-select">
                        <label className="product-multi-select-label">
                          {t("Gender")}
                        </label>
                        <div className="product-multi-select-box">
                          <Select
                            options={gender}
                            placeholder={t("Gender")}
                            value={GenderProduct_value_onload_}
                            onChange={(opt) => setgenderSelectedOptions(opt)}
                            classNamePrefix="product-multi-select"
                            getOptionLabel={(e) => (
                              <div className="product-multi-select__multi-value__label-ct">
                                {e.icon}
                                <span>{e.label}</span>
                              </div>
                            )}
                            isSearchable={false}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className="product-multi-select">
                        <label className="product-multi-select-label">
                          {t("Specified Occasion")}
                        </label>
                        <div className="product-multi-select-box">

                          <Select
                            options={occasion}
                            value={SpecifiedOccasion_value_onload_}
                            placeholder={t("Specified Occasion")}
                            onChange={(opt) => setoccasionSelectedOptions(opt)}
                            classNamePrefix="product-multi-select"
                            getOptionLabel={(e) => (
                              <div className="product-multi-select__multi-value__label-ct">
                                {e.icon}
                                <span>{e.label}</span>
                              </div>
                            )}
                            isSearchable={false}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>

                    {call ? (
                      <EcomcategorieList
                        setEcomcategorie={setEcomcategorie}
                        ecomcategorie_={ecomcategorie_}
                        setEcomcategorie_={setEcomcategorie_}
                      ></EcomcategorieList>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="product-ct-wrap-col-right">
                    <div className="product-ct-wrap-col-right-img">
                      <span className="danger-text">{t("Max Size 100KB")}</span>
                      <div
                        className={
                          img
                            ? "product-ct-wrap-col-right-img-box bg-none"
                            : "product-ct-wrap-col-right-img-box"
                        }
                      >
                        {img ? <img src={img} alt="no img" /> : null}
                        <input
                          type="file"
                          id="main_product_image"
                          onChange={onImageChange}
                        />
                      </div>
                    </div>

                    <div className="product-ct-wrap-col-right-status product-sidebar-box">
                      <div className="product-ct-wrap-col-right-status-form product-sidebar-box-form">
                        <div className="product-ct-wrap-col-right-status-form-status">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <label>{t("Status")}</label>
                                </td>
                                <td>
                                  <div className="product-ct-wrap-col-right-status-form-status-active">
                                    <Select
                                      options={status}
                                      onChange={(opt) =>
                                        setstatusSelectedOptions(opt)
                                      }
                                      classNamePrefix="status"
                                      value={statusselectedOptions}
                                      getOptionLabel={(e) => (
                                        <div className="product-ct-wrap-col-right-status-form-status-active-ct">
                                          {e.dot}
                                          {e.label}
                                        </div>
                                      )}
                                      isSearchable={false}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {IsDisable && getGeneral_product?.createdAt ? <div className="qr-code">
                          <table>
                            <tbody>
                              <tr className="status-gray-text">
                                <td>{t("Date Created")}</td>
                                <td>{getGeneral_product?.createdAt ? getGeneral_product?.createdAt : ''}</td>
                              </tr>
                              <tr className="danger-text">
                                <td>{t("Date Modified")}</td>
                                <td>{getGeneral_product?.updatedAt ? getGeneral_product?.updatedAt : ''}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="qr-code-wrap">
                            <img src={getGeneral_product?.QRCode ? getGeneral_product?.QRCode : base_assets + "images/qr-img.png"} alt="" />
                          </div>
                        </div> : ""}
                        <div className="chanel-box">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <label>{t("Channel")}</label>
                                </td>
                                <td>
                                  <div className="">
                                    <div className="com-check mb-2">
                                      <input
                                        onChange={() =>
                                          handleClick_Point_of_sale_()
                                        }
                                        type="checkbox"
                                        checked={Point_of_sale_}
                                        className={`checked ${(errors.Chanel &&
                                          "form-control is-invalid") ||
                                          ""
                                          }`}
                                        id="c1"
                                      />
                                      <label
                                        className="com-check-label"
                                        htmlFor="c1"
                                      ></label>
                                      <label
                                        className="com-check-label-text"
                                        htmlFor="c1"
                                      >
                                        {t("Point of Sale")}
                                      </label>
                                    </div>
                                    <div className="com-check">
                                      <input
                                        onChange={() =>
                                          handleClick_eCommerce_()
                                        }
                                        type="checkbox"
                                        checked={point_of_sale_eCommerce_}
                                        className={`checked ${(errors.Chanel &&
                                          "form-control is-invalid") ||
                                          ""
                                          }`}
                                        id="c2"
                                      />
                                      <label
                                        className="com-check-label"
                                        htmlFor="c2"
                                      ></label>
                                      <label
                                        className="com-check-label-text"
                                        htmlFor="c2"
                                      >
                                        {t("eCommerce")}
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="product-sidebar-box box-bg">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <label>{t("Type")}</label>
                            </td>
                            <td>
                              <div className="">
                                <div className="com-check radio mb-2">
                                  <input
                                    type="radio"
                                    name="product_variants_enable"
                                    className="checked"
                                    checked={product_variants_enable_ === "0"}
                                    value="0"
                                    id="r1"
                                    onChange={(e) =>
                                      Product_value_arr_data(
                                        "varient_type",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <label
                                    className="com-check-label"
                                    htmlFor="r1"
                                  ></label>
                                  <label
                                    className="com-check-label-text"
                                    htmlFor="r1"
                                  >
                                    {t("No Variants")}
                                  </label>
                                </div>
                                <div className="com-check radio mb-2">
                                  <input
                                    type="radio"
                                    value="1"
                                    name="product_variants_enable"
                                    checked={product_variants_enable_ === "1"}
                                    className="checked"
                                    id="r2"
                                    onChange={(e) =>
                                      Product_value_arr_data(
                                        "varient_type",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <label
                                    className="com-check-label"
                                    htmlFor="r2"
                                  ></label>
                                  <label
                                    className="com-check-label-text"
                                    htmlFor="r2"
                                  >
                                    {t("Variants")}
                                  </label>
                                </div>
                                {designStatus === "1" ?
                                  <div className="com-check radio mb-2">
                                    <input
                                      type="radio"
                                      className="checked"
                                      value="2"
                                      id="r3"
                                      name="product_variants_enable"
                                      checked={product_variants_enable_ === "2"}
                                      onChange={(e) =>
                                        Product_value_arr_data(
                                          "varient_type",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <label
                                      className="com-check-label"
                                      htmlFor="r3"
                                    ></label>
                                    <label
                                      className={generalsettings?.data?.generalsetup?.productChoice === '1' ? "com-check-label-text" : "d-none"}
                                      htmlFor="r3"
                                    >
                                      {t("Design")}
                                    </label>
                                  </div> : ''}
                                <div className="com-check radio">
                                  <input
                                    type="radio"
                                    className="checked"
                                    value="3"
                                    id="r4"
                                    name="product_variants_enable"
                                    checked={product_variants_enable_ === "3"}
                                    onChange={(e) =>
                                      Product_value_arr_data(
                                        "varient_type",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <label
                                    className="com-check-label"
                                    htmlFor="r4"
                                  ></label>
                                  <label
                                    className="com-check-label-text"
                                    htmlFor="r4"
                                  >
                                    {t("Packaging")}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="product-sidebar-box product-sidebar-box-tag box-bg">
                      <label className="mb-1">
                        {t("Tag")} <span className="question-mark">?</span>
                      </label>
                      <div className="search-input">
                        <img
                          src={
                            base_assets +
                            "images/icons/search-icon-2.png"
                          } alt=""
                        />
                        <input
                          {...register("product_tags", {})}
                          defaultValue={
                            getGeneral_product?.product_tags
                          }
                        />
                      </div>
                    </div>
                    <div className="product-sidebar-box product-sidebar-box-tag box-bg">
                      <label className="mb-1">
                        {t("Loyalty points")}
                        <span className="question-mark">?</span>
                      </label>
                      <div className="search-input">
                        <img
                          src={base_assets + "images/icons/search-icon-2.png"} alt=""
                        />
                        <input
                          {...register("Additional_loyalty_points", {})}
                          defaultValue={
                            getGeneral_product?.Additional_loyalty_points
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Product;
