import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TableColumnModal from "../../components/common/modules/tableColumn";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { posexchange_head } from "../../components/common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { _Api } from "../common/api/_call";
import CheckoutBlank from "../checkout/checkoutBlank";
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import { useTranslation } from 'react-i18next';
import { error } from "../common/helpers/toastify";
import { useSelector } from 'react-redux';
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function';
import TableLoader from '../../admin/common/TableLoader';
import { store } from '../../source/index'
const Exchange = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [newcolumndata, setnewcolumndata] = useState(posexchange_head);
  const customer = useSelector((state) => state.app.customer);
  const [, setfilterby] = useState("");
  const [orderid, setOrderid] = useState('');
  const [cart_id, setCart_id] = useState('');
  const [UpdatedCart, setUpdatedCart] = useState()
  const [TransactionDate, setTransactionDate] = useState();
  const [exchangedata, setExchangedata] = useState([])
  const [deletestatus, setdeletestatus] = useState(false)
  const [searchproductstatus, setsearchproductstatus] = useState()
  const [showcheckout, setShowcheckout] = useState('blank')
  const [showpayment, setShowpayment] = useState(false);
  const [parmission, setParmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [dataloder, setDataloder] = useState(false)

  useEffect(() => {
    if (parmissiondatashow?.pos_exchange?.View)
      setParmission(parmissiondatashow?.pos_exchange?.Add);
    // eslint-disable-next-line
  }, []);

  const selectTransactionDate = (input) => {
    setTransactionDate(input);
  };
  useEffect(() => {
    callcolumns();
  }, []);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_pos_exchange",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const addtoexchangecart = async (order_id, order_edit, order_type) => {
    try {
      const postdata = {
        "order_id": orderid ? orderid : order_id,
        'order_type': order_type ? order_type : 'exchange_order',
        "order_edit": order_edit,
        "cartid": cart_id

      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setShowcheckout('sell');
        setsearchproductstatus(true);

      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const getexchangelist = async () => {
    UnSetdataTable("com-data-area_table");
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      SetdataTable("com-data-area_table", 4, newcolumndata, 0);
      return;
    }
    try {
      setDataloder(true);
      const postdata = {
        date: TransactionDate,
        customer_id: customer?.id,
        'order_type': 'exchange_order',
      };
      let res = await _Api(postdata, `api/v1/POS/repair/getSalesOrderList`);
      if (res?.code === 200) {
        setDataloder(false);
        setExchangedata(res?.data);
        UnSetdataTable("com-data-area_table");
        SetdataTable("com-data-area_table", 4, newcolumndata, 0);
      } else {
        setDataloder(false);
        UnSetdataTable("com-data-area_table");
        SetdataTable("com-data-area_table", 4, newcolumndata, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    if (state?.order_id && state?.order_edit && state?.order_type) {
      addtoexchangecart(state?.order_id, state?.order_edit, state?.order_type)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getexchangelist();
    // eslint-disable-next-line
  }, [customer?.id, TransactionDate, deletestatus])

  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
    // eslint-disable-next-line
  }, [showcheckout]);

  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className='com-data-area'>
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left">
                    <h1 className="heading"><Link to="/app/pos/menu" className='gray-text text-decoration-none'>{t("EXCHANGE")}</Link></h1>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                  </div>
                </div>
              </div>
              <div className="com-data-area-inner">
                <div className='com-data-area-header'>
                  <h2 className='com-data-area-header-heading'>{t("PURCHASE HISTORY")}</h2>
                  <div className='customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date'>
                    <DatePicker
                      className="placeholder-up-input-field"
                      selected={TransactionDate}
                      onChange={selectTransactionDate}
                      name="TransactionDate"
                      placeholderText='MM/DD/YYYY'
                      dateFormat={OrgSettings?.date_picker}
                      autoComplete='none'
                    />
                    <img
                      className="placeholder-up-input-field-calender"
                      src={base_assets + "images/icons/calendar-green.svg"}
                      alt=""
                    />
                  </div>
                </div>
                <div className="main-body-main-table-wrap vertical-scroll-table position-relative" id="table-scroll" >
                  <table id='com-data-area_table' className="common-table first-row-gray w-100">
                    <Tablehead tablehead={posexchange_head} tblName={"tbl_pos_exchange"} setfilterby={setfilterby} />
                    <tbody>

                      {exchangedata?.length ? exchangedata.map((result, exkey) => {
                        return (
                          <tr key={result?.cart_id}  >
                            <td className="row-check">
                              <div className="com-check radio">
                                <input type="radio" name='customchack'
                                  id={result?.cart_id} onClick={() => { setOrderid(result?.order_id); setCart_id(result?.cart_id) }}
                                />
                                <label htmlFor={result?.cart_id} className="com-check-label" />
                              </div>
                            </td>
                            <td>{exkey + 1}</td>
                            <td>
                              <div className="pro-img">
                                <img src={result?.image} alt="" />
                              </div>
                            </td>
                            <td>{result?.date}</td>
                            <td className='link-color'>{result?.ref_no}</td>
                            <td className='link-color'>{result?.SKU}</td>
                            <td>{result?.metal_name}</td>
                            <td>{result?.stone_name}</td>
                            <td>{result?.size_name}</td>
                            <td>{result?.stock_id}</td>
                            <td>{result?.location}</td>
                            <td>{result?.Qty}</td>
                            <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                          </tr>
                        )
                      })
                        : null}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{
                          exchangedata?.length
                            ? exchangedata?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                val?.Qty),
                              0
                            )
                            : ""
                        }</td>
                        <td>{
                          toFormatPrice(
                            exchangedata?.length
                              ? exchangedata?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.amount)),
                                0
                              )
                              : "",
                            { addSymbol: true })
                        }</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className='position-relative'>
                    {dataloder && <TableLoader />}
                  </div>
                </div>
                <div className='com-data-area-footer-btn'>
                  <button onClick={() => addtoexchangecart()} className={(orderid !== '' && showcheckout === 'blank') && (parmission) ? 'com-btn ms-auto' : 'com-btn ms-auto btn-disabled'}>{t("Submit")}</button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                searchproductstatus={searchproductstatus}
                setsearchproductstatus={setsearchproductstatus}
                order_id={orderid ? orderid : state?.order_id}
                ordertype={'exchange_order'}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} order_edit={state?.order_edit}
                order_type={state?.order_type} order_id={state?.order_id} />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
      <TableColumnModal tablehead={posexchange_head} tblName={"tbl_pos_exchange"} setnewcolumndata={setnewcolumndata}
        calldatatable={getexchangelist} ></TableColumnModal>


    </>
  )
}

export default Exchange;