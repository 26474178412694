import { graphqlPost } from "../api/_call";
import { useEffect, useCallback, useRef } from "react";
export const LocationList = (props) => {
    const dataFetched = useRef();
    const { setLocationarrayList } = props;
    const getLocationList = useCallback(async () => {
        try {
            const postData = `{
                locations {
                    id
                    name 
                }
            }`;

            let response = await graphqlPost(postData);
            let locationArray = [];
            if (response.data?.locations) {
                response.data?.locations.forEach((result, key) => {
                    locationArray.push({
                        id: result?.id,
                        name: result?.name,
                    });
                })

            }

            setLocationarrayList(locationArray);

        } catch (err) {
            console.log(err, "err");
        }
    }, [setLocationarrayList]);

    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true;
        getLocationList();
    }, [getLocationList]);

};

export const GetCountrygraph = (props) => {
    const { setCountrynamearr } = props;
    const getCountry = useCallback(async () => {
        try {
            const postdata = `{
                       countries{
                        name
                        id
                        sortname
                        phoneCode  
                        }
                      }`;
            let responce = await graphqlPost(postdata, "App/graphql");
            let countriesarray = [];
            if (responce.data?.countries) {
                responce.data.countries.forEach((result) => {
                    countriesarray.push(
                        {
                            id: result?.id,
                            name: result?.name,
                            sortname: result?.sortname,
                            phoneCode: result?.phoneCode


                        }
                    )
                });
            }
            setCountrynamearr(countriesarray)
        } catch (err) {
            console.log(err, "err");
        }
    }, [setCountrynamearr]);
    useEffect(() => {
        getCountry()
    }, [getCountry])
}
export const GetCountryCodegraph = (props) => {
    const { setCountryarray } = props;

    const getCountry = useCallback(async () => {

        try {
            const postdata = `{
                       countries{
                        name
                        id
                        sortname
                        phoneCode  
                        }
                      }`;
            let res = await graphqlPost(postdata, "App/graphql");
            let countriesarray = [];
            if (res.data?.countries) {
                countriesarray = res.data.countries.map((result) => ({
                    label: result?.phoneCode,
                    value: result?.phoneCode
                }));
            }
            setCountryarray(countriesarray);
        } catch (err) {
            console.log(err, "err");
        }
    }, [setCountryarray]);
    useEffect(() => {
        getCountry()
    }, [getCountry]
    )
}
export const GetTexgraph = (props) => {
    const { setTexgrapharray } = props;

    const getTexgraphdata = useCallback(async () => {
        try {
            const postdata = `{
        taxs{
          id
          name
        }
      }`;
            let res = await graphqlPost(postdata, "App/graphql");
            let Texgraphdataarray = [];

            if (res.data?.taxs) {
                Texgraphdataarray = res.data.taxs.map((result) => ({
                    value: result?.id,
                    label: result?.name,
                }));
            }
            setTexgrapharray(Texgraphdataarray);
        } catch (err) {
            console.log(err, "err");
        }
    }, [setTexgrapharray]);
    useEffect(() => {
        getTexgraphdata();
    }, [getTexgraphdata]);
}

export const Getstonegroupgraph = (props) => {
    const { setStonegroupharray } = props;

    const getTexgraphdata = useCallback(async () => {

        try {
            const postdata = `{
                stonegroups{
                        id
                        name
                        }
                      }`;
            let res = await graphqlPost(postdata, "App/graphql");
            let stonegroupdataarray = [];

            if (res.data?.stonegroups) {
                res.data.stonegroups.forEach((result) => {
                    stonegroupdataarray.push({
                        value: result?.id,
                        label: result?.name,
                    });
                });
            }
            setStonegroupharray(stonegroupdataarray);
        } catch (err) {
            console.log(err, "err");
        }
    }, [setStonegroupharray]);
    useEffect(() => {
        getTexgraphdata()
    }, [getTexgraphdata])
}

export const Getshapegroupgraph = (props) => {
    const { setShapearrayList } = props;
    const getShapesList = useCallback(async () => {
        try {
            const postdata = `{
            shapes {
                id
                name 
            }
           }`;
            let responce = await graphqlPost(postdata, "App/graphql");
            let shapearray = [];
            if (responce.data?.shapes) {
                shapearray = responce.data.shapes.map((result) => ({
                    value: result?.id,
                    label: result?.name
                }));
            }
            setShapearrayList(shapearray);

        } catch (err) {
            console.log(err, "err");
        }
    }, [setShapearrayList]);
    useEffect(() => {
        getShapesList();
    }, [getShapesList]);
}

export const Getshapegraph = (props) => {
    const { setShapearrayList } = props;
    const getShapesList = useCallback(async () => {
        try {
            const postdata = `{
                Shapegroups{
                    id
                    name
                }
            }`
            let responce = await graphqlPost(postdata, "App/graphql");
            let shapearray = [];
            if (responce.data?.Shapegroups) {
                responce.data.Shapegroups.forEach((result) => {
                    shapearray.push({
                        value: result?.id,
                        label: result?.name
                    });
                });
            }
            setShapearrayList(shapearray);

        } catch (err) {
            console.log(err, "err");
        }
    }, [setShapearrayList]);
    useEffect(() => {
        getShapesList();
    }, [getShapesList]);
}
export const Ecomcategoriegraph = (props) => {
    const { setecomcategoriearrayList } = props;
    const getecomcategorie = useCallback(async () => {
        try {
            const postdata = `{
                ecomcategorie {
                    id
                    name 
                    parent_id
                }
            }`;
            let responce = await graphqlPost(postdata, "App/graphql");
            let ecomcategoryarray = [];
            if (responce.data?.ecomcategorie) {
                responce.data.ecomcategorie.forEach((result) => {
                    ecomcategoryarray.push({
                        name: result?.name,
                        id: result?.id,
                        parent_id: result?.parent_id
                    });
                });
            }
            setecomcategoriearrayList(ecomcategoryarray);
        } catch (err) {
            console.log(err, "err");
        }
    }, [setecomcategoriearrayList]);
    useEffect(() => {
        getecomcategorie();
    }, [getecomcategorie]);
}