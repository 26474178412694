import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Organization from "../admin/organization/Organization.js"
export default function OrganizationRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Organization />} > </Route>
        </Routes>
    )
}
