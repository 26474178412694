import React, { useEffect, useState, useRef } from "react";
import { graphqlPost, _Api } from "../api/_call";
import Select, { components } from "react-select";
import { useTranslation } from 'react-i18next';

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
//  metal list
export const MetalsList = (props) => {
  const dataFetched = useRef();
  const { updateData_productbymain, metalid, setcoloridbymetal, addclass } = props;
  const [MetalList, setMetalList] = useState();
  const [MetalarrayList, setMetalarrayList] = useState([]);
  const getMetalList = async () => {
    try {
      const postdata = `{
         metals {
             id
             name 
             color
         }
     }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let metalarray = [];
      if (responce.data?.metals) {
        responce.data?.metals?.forEach((result) => {
          metalarray.push(
            {
              value: result?.id,
              label: result?.name,
              color: result?.color

            }
          );
        })
      }
      setMetalarrayList(metalarray);
      if (metalid) {
        const selected = metalarray.filter(option => option.value === metalid);
        setMetalList({ label: selected[0]?.label, value: metalid });
        setcoloridbymetal(selected[0]?.color);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getMetalList();
    // eslint-disable-next-line 
  }, [metalid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={addclass ? "product-modal-row-col-left-row-input selectbox-inactive" : "product-modal-row-col-left-row-input"}>
        <Select
          className="common-select yellow-select"
          placeholder={t("Metal")}
          options={MetalarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={MetalList}
          onChange={(e) => { setMetalList(e); updateData_productbymain('Metal', e?.value, 'metal_name', e?.label); setcoloridbymetal(e?.color) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// color list
export const ColorsList = (props) => {
  const { updateData_productbymain, colorid } = props;
  const [ColorList, setColorList] = useState();
  const [ColorarrayList, setColorarrayList] = useState([]);
  const getColorsList = async () => {
    try {
      const postdata = `{
                colors {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let colorarray = [];
      if (responce.data?.colors) {
        responce.data?.colors?.forEach((result) => {
          colorarray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      }
      setColorarrayList(colorarray);
      if (colorid) {
        const selected = colorarray.filter(option => option.value === colorid);
        setColorList({ label: selected[0]?.label, value: colorid });
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getColorsList();
    // eslint-disable-next-line 
  }, [colorid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="product-modal-row-col-left-row-input ">
        <Select
          className="common-select"
          placeholder={t("Color")}
          options={ColorarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={ColorList}
          onChange={(e) => { setColorList(e); updateData_productbymain('Color', e?.value, 'color_name', e?.label) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// Size List
export const SizesList = (props) => {
  const { updateData_productbymain, sizeid, product_id, addclass } = props;
  const [SizeList, setSizeList] = useState();
  const [SizearrayList, setSizearrayList] = useState([]);
  const getSizesList = async () => {
    try {
      const postdata = { id: product_id }
      let responce = await _Api(postdata, `api/v1/APP/product/getsizes`);
      let sizearray = [];
      if (responce.data) {
        responce.data?.forEach((result) => {
          sizearray.push(
            {
              value: result?._id,
              label: result?.name

            }
          );
        })
      }
      setSizearrayList(sizearray);
      if (sizeid) {
        const selected = sizearray.filter(option => option.value === sizeid);
        setSizeList({ label: selected[0]?.label, value: sizeid });
      }
    } catch (err) {
      // console.log(err, "err");
    }
  };
  useEffect(() => {
    getSizesList();
    // eslint-disable-next-line 
  }, [sizeid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={addclass ? "product-modal-row-col-left-row-input selectbox-inactive" : "product-modal-row-col-left-row-input"}>
        <Select
          className="common-select yellow-select"
          placeholder={t("Size")}
          options={SizearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={SizeList}
          onChange={(e) => { setSizeList(e); updateData_productbymain('Size', e?.value, 'size_name', e?.label) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// Stones List
export const StonesList = (props) => {
  const { updateData_productContent, stoneid, groupid, index, addclass, yellow_color } = props;
  const [StoneList, setStoneList] = useState();
  const [StonearrayList, setStonearrayList] = useState([]);
  const getStonesList = async () => {
    try {
      let postdata = {};
      if (groupid) {
        postdata = `{
          stones(group_id:"`+ groupid + `") {
              id
              name 
            }
          }`;

      } else {
        postdata = `{
          stones {
              id
              name 
              code
              stonegroup
          }
      }`;
      }
      let responce = await graphqlPost(postdata, "App/graphql");
      let stonearray = [];
      if (responce.data?.stones) {
        responce.data?.stones?.forEach((result) => {
          stonearray.push(
            {
              value: result?.id,
              label: result?.name,
              stonegroupid: result?.stonegroup ? result?.stonegroup : ''
            }
          );
        })
      }
      setStonearrayList(stonearray);
      if (stoneid) {
        const selected = stonearray.filter(option => option.value === stoneid);
        setStoneList({ label: selected[0]?.label, value: stoneid });
      }
    } catch (err) {
      // console.log(err, "err");
    }
  };
  useEffect(() => {
    getStonesList();
    // eslint-disable-next-line 
  }, [stoneid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={addclass ? "product-modal-row-col-left-row-input selectbox-inactive" : "product-modal-row-col-left-row-input"}>
        <Select
          className={yellow_color ? "common-select yellow-select" : "common-select"}
          placeholder={t("Stone")}
          options={StonearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={StoneList}
          onChange={(e) => {
            setStoneList(e);
            updateData_productContent(index, 'stone', e?.value);
            if (!groupid) {
              updateData_productContent(index, 'stone_group', e?.stonegroupid);
            }
          }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};


// stone for new create
export const StoneAll = () => {
  const [StonesList, setStonesList] = useState();
  const [StonesarrayList, setStonesarrayList] = useState([]);
  const getStonesLists = async () => {
    try {
      const postdata = `{
        stones {
            id
            name 
            code
            stonegroup
        }
    }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let stonearray = [];
      if (responce.data?.stones) {
        responce.data?.stones?.forEach((result) => {
          stonearray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      }
      setStonesarrayList(stonearray);
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getStonesLists();
  }, []);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="product-modal-row-col-left-row-input">
        <Select
          className="common-select"
          placeholder={t("Stones")}
          options={StonesarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={StonesList}
          onChange={(e) => { setStonesList(e) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};

// Shapes List
export const ShapesList = (props) => {
  const { updateData_productContent, shapeid, index, addclass, light_yellow_color } = props;
  const [ShapeList, setShapeList] = useState();
  const [ShapearrayList, setShapearrayList] = useState([]);
  const getShapesList = async () => {
    try {
      const postdata = `{
        shapes {
            id
            name 
        }
       }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let shapearray = [];
      if (responce.data?.shapes) {
        responce.data?.shapes?.forEach((result) => {
          shapearray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      }
      setShapearrayList(shapearray);
      if (shapeid) {
        const selected = shapearray.filter(option => option.value === shapeid);
        setShapeList({ label: selected[0]?.label, value: shapeid });
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getShapesList();
    // eslint-disable-next-line 
  }, [shapeid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={addclass ? "product-modal-row-col-left-row-input selectbox-inactive" : "product-modal-row-col-left-row-input"}>
        <Select
          className={light_yellow_color ? "common-select light-yellow-select" : "common-select"}
          placeholder={t("Shape")}
          options={ShapearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={ShapeList}
          onChange={(e) => { setShapeList(e); updateData_productContent(index, 'shape', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// cut list
export const CutsList = (props) => {
  const { updateData_productContent, cutid, index, addclass, light_yellow_color } = props;
  const [CutList, setCutList] = useState();
  const [CutarrayList, setCutarrayList] = useState([]);
  const getCutsList = async () => {
    try {
      const postdata = `{
        cuts {
            id
            name 
        }
       }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let cutarray = [];
      if (responce.data?.cuts) {
        responce.data?.cuts?.forEach((result) => {
          cutarray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      }
      setCutarrayList(cutarray);
      if (cutid) {
        const selected = cutarray.filter(option => option.value === cutid);
        setCutList({ label: selected[0]?.label, value: cutid });
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getCutsList();
    // eslint-disable-next-line 
  }, [cutid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={addclass ? "product-modal-row-col-left-row-input selectbox-inactive" : "product-modal-row-col-left-row-input"}>
        <Select
          className={light_yellow_color ? "common-select light-yellow-select" : "common-select"}
          placeholder={t("Cut")}
          options={CutarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={CutList}
          onChange={(e) => { setCutList(e); updateData_productContent(index, 'Cut', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// Clarity List
export const ClaritysList = (props) => {
  const { updateData_productContent, clarityid, index, addclass, light_yellow_color } = props;
  const [ClarityList, setClarityList] = useState();
  const [ClarityarrayList, setClarityarrayList] = useState([]);
  const getClaritysList = async () => {
    try {
      const postdata = `{
        claritys {
            id
            name 
          }
        }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let clarityarray = [];
      if (responce.data?.claritys) {
        responce.data?.claritys?.forEach((result) => {
          clarityarray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      }
      setClarityarrayList(clarityarray);
      if (clarityid) {
        const selected = clarityarray.filter(option => option.value === clarityid);
        setClarityList({ label: selected[0]?.label, value: clarityid });
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getClaritysList();
    // eslint-disable-next-line 
  }, [clarityid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={addclass ? "product-modal-row-col-left-row-input selectbox-inactive" : "product-modal-row-col-left-row-input"}>
        <Select
          className={light_yellow_color ? "common-select light-yellow-select" : "common-select"}
          placeholder={t("Clarity")}
          options={ClarityarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={ClarityList}
          onChange={(e) => { setClarityList(e); updateData_productContent(index, 'clarity', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// Stonecolor List
export const StonecolorsList = (props) => {
  const { updateData_productContent, stonecolorid, index, addclass, light_yellow_color } = props;
  const [StonecolorList, setStonecolorList] = useState();
  const [StonecolorarrayList, setStonecolorarrayList] = useState([]);
  const getStonecolorsList = async () => {
    try {
      const postdata = `{
        stonecolors {
            id
            name 
          }
        }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let stonecolorarray = [];
      if (responce.data?.stonecolors) {
        responce.data?.stonecolors?.forEach((result) => {
          stonecolorarray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      }
      setStonecolorarrayList(stonecolorarray);
      if (stonecolorid) {
        const selected = stonecolorarray.filter(option => option.value === stonecolorid);
        setStonecolorList({ label: selected[0]?.label, value: stonecolorid });
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getStonecolorsList();
    // eslint-disable-next-line 
  }, [stonecolorid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={addclass ? "product-modal-row-col-left-row-input selectbox-inactive" : "product-modal-row-col-left-row-input"}>
        <Select
          className={light_yellow_color ? "common-select light-yellow-select" : "common-select"}
          placeholder={t("Color")}
          options={StonecolorarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={StonecolorList}
          onChange={(e) => { setStonecolorList(e); updateData_productContent(index, 'color', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// CertificateType list
export const CertificateTypeList = (props) => {
  const { updateData_productContent, certificatelistid, index } = props;
  const [CertificateList, setCertificateList] = useState();
  const certificate_opt = [
    { value: "AGS", label: "AGS" },
    { value: "EFCO", label: "EFCO" },
    { value: "EGL", label: "EGL" },
    { value: "GEMSCAN", label: "GEMSCAN" },
    { value: "GIA", label: "GIA" },
    { value: "GRS", label: "GRS" },
    { value: "HRD", label: "HRD" },
    { value: "IGI", label: "IGI" },
    { value: "UGL", label: "UGL" },
  ];
  const getCertificateList = async () => {
    if (certificatelistid) {
      const selected = certificate_opt.filter(option => option.value === certificatelistid);
      setCertificateList({ label: selected[0]?.label, value: certificatelistid });
    }

  };
  useEffect(() => {
    getCertificateList();
    // eslint-disable-next-line 
  }, [certificatelistid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="product-modal-row-col-left-row-input">
        <Select
          className="common-select"
          placeholder={t("Certificate Type")}
          options={certificate_opt}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={CertificateList}
          onChange={(e) => { setCertificateList(e); updateData_productContent(index, 'certificate', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// getpointer by shape
export const GetPointers = (props) => {
  const { updateData_productContent, pointerid, shape_id, index } = props;
  const [PointerList, setPointerList] = useState();
  const [PointerarrayList, setPointerarrayList] = useState([]);
  const getPointerList = async () => {
    try {
      const postdata = { id: shape_id }
      let responce = await _Api(postdata, `api/v1/APP/product/getPointerByShapeID`);
      let pointerarray = [];
      responce.data?.length ? responce.data.forEach((result) => {
        pointerarray.push(
          {
            value: result?.value,
            label: result?.label
          }
        );
      }) : setPointerList('');
      setPointerarrayList(pointerarray);
      if (pointerid) {
        const selected = pointerarray.filter(option => option.value === pointerid);
        setPointerList({ label: selected[0]?.label, value: pointerid });
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getPointerList();
    // eslint-disable-next-line 
  }, [pointerid, shape_id])
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="product-modal-row-col-left-row-input">
        <Select
          className="common-select"
          placeholder={t("Pointer")}
          options={PointerarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={PointerList}
          onChange={(e) => { setPointerList(e); updateData_productContent(index, 'Pointer', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// getstonesize by shape
export const StonesizeList = (props) => {
  const { updateData_productContent, shape_id, stonesizeid, index } = props;
  const [StonesizeList, setStonesizeList] = useState();
  const [StonesizearrayList, setStonesizearrayList] = useState([]);
  const getStonesizeList = async () => {
    try {
      const postdata = {
        id: shape_id,
        // mixMatch: 1
      };
      let responce = await _Api(postdata, `api/v1/APP/product/getShapeToStoneSize`);
      let stonesizearray = [];
      responce.data?.length ? responce.data.forEach((result) => {
        stonesizearray.push(
          {
            value: result?.value,
            label: result?.label
          }
        );
      }) : setStonesizeList('');
      setStonesizearrayList(stonesizearray);
      if (stonesizeid) {
        const selected = stonesizearray.filter(option => option.value === stonesizeid);
        setStonesizeList({ label: selected[0]?.label, value: stonesizeid });
      }
    } catch (err) {
      //console.log(err, "err");
    }
  };
  useEffect(() => {
    getStonesizeList();
    // eslint-disable-next-line 
  }, [stonesizeid, shape_id]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="product-modal-row-col-left-row-input">
        <Select
          className="common-select"
          placeholder={t("Size")}
          options={StonesizearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={StonesizeList}
          onChange={(e) => { setStonesizeList(e); updateData_productContent(index, 'Size', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};
// StonesettingType
export const StonesettingType = (props) => {
  const { updateData_productContent, settingid, index } = props;
  const [SettingList, setSettingList] = useState();
  const [SettingarrayList, setSettingarrayList] = useState([]);
  const getSettingList = async () => {
    try {
      const postdata = `{
        settingType {
            id
            name 
        }
    }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let settingarray = [];
      if (responce.data?.settingType) {
        responce.data?.settingType.forEach((result) => {
          settingarray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      }

      setSettingarrayList(settingarray);
      if (settingid) {
        const selected = settingarray.filter(option => option.value === settingid);
        setSettingList({ label: selected[0]?.label, value: settingid });
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getSettingList();
    // eslint-disable-next-line 
  }, [settingid]);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="product-modal-row-col-left-row-input">
        <Select
          className="common-select"
          placeholder={t("Setting")}
          options={SettingarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={SettingList}
          onChange={(e) => { setSettingList(e); updateData_productContent(index, 'setting_type', e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={true}
        />
      </div>
    </React.Fragment>
  );
};










