import React, { useEffect, useState } from "react";
import ImageUploading from 'react-images-uploading';
import './gallery.scss';
import { useTranslation } from 'react-i18next';
import { error } from "../helpers/toastify";

export function Gallery(props) {
  const { setImages_product, setproductsImages } = props;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [DBimages, setDBImages] = useState(setproductsImages);
  const [images, setImages] = useState([]);

  const maxNumber = 10;

  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, "imageList")
    if (imageList?.length > maxNumber) {
      error("You are only allowed to upload a maximum of 10 files")
      imageList = imageList.slice(0, maxNumber);
      // return;
    }
    setImages(imageList);
    setImages_product(imageList);
    setDBImages([]);
  };
  useEffect(() => {
    setDBImages(setproductsImages);
  }, [setproductsImages]);

  const { t } = useTranslation();
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      // maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className="row  mb-5"
          style={{ border: isDragging ? '2px dashed red' : '' }}
          {...dragProps}>
          <h4 className="pro-sub-heading">
            {t("Additional product image")}
          </h4>
          <div className="col-md-12">
            <div className="gallery-upload-area">
              <div className="App">

                <div
                  className="gallery-upload-area-wrap"


                >
                  <div className="gallery-upload-area-wrap-row">
                    {imageList.map((image, index) => (
                      <div key={index} className="gallery-upload-area-wrap-row-img">
                        <img
                          className='img'
                          onClick={() => onImageUpdate(index)}
                          src={image['data_url']}
                          alt=""
                          width="100"
                        />
                        <button
                          type="button"
                          className="gallery-upload-area-wrap-row-img-close"
                          onClick={() => onImageRemove(index)}
                        >
                          <img src={`${base_assets}images/icons/minus-detail.png`} alt="" />
                        </button>
                      </div>
                    ))}

                    {DBimages && DBimages.map((image, index) => (
                      <div key={index + 1000} className="gallery-upload-area-wrap-row-img">
                        <img
                          className='img'
                          onClick={() => onImageUpdate(index + 1000)}
                          src={image['image']}
                          alt=""
                          width="100"
                        />
                      </div>
                    ))}
                  </div>

                  <div className="gallery-upload-area-wrap-btn-row">
                    <button
                      type="button"
                      className="gallery-upload-area-wrap-btn-row-btn"
                      onClick={onImageUpload}

                    >
                      <img className="rotateupload" src={`${base_assets}images/icons/upload-green.png`} alt="" /> {t("upload")}
                    </button>
                    <button
                      type="button"
                      className="gallery-upload-area-wrap-btn-row-btn danger-text"
                      onClick={onImageRemoveAll}
                    >
                      <img src={`${base_assets}images/icons/minus-detail.png`} alt="" /> {t("Remove all")}
                    </button>
                  </div>

                  <div className="gallery-upload-area-wrap-btn-row-btn danger-text">
                    {t("Note :- You are only allowed to upload a maximum of 10 files")}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </ImageUploading>
  );
}

export default Gallery;
