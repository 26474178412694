import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { _Api, graphqlPost } from '../api/_call';
import Slider from 'react-slick';
import DateTime from '../common/DateTime';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './inventory.scss';
const ItemSearch = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [searchvalue, setSearchvalue] = useState()
  const [show, setShow] = useState(false)
  const [metallist, setMetallist] = useState([])
  const [stonelist, setStonelist] = useState([])
  const [sizelist, setSizelist] = useState([])
  const [searchlist, setSearchlist] = useState('')
  const [resultlist, setResultlist] = useState('')
  const [image, setImage] = useState([])
  const [po_id, setPo_id] = useState()
  const [, setStatus] = useState(false)
  const [, setQty] = useState()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [imageid, setImageid] = useState()
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const getsearchvalue = (event) => {
    var value = event.target.getAttribute('data-search')
    setSearchvalue(value)
    setShow(false)
    callsearchdetails(value)
  }
  const getsearchlist = async (value) => {
    try {
      const postdata = {
        search: value,
      }
      let res = await _Api(postdata, `api/v1/Inventory/quickview/search`)
      if (res.code === 200) {
        setSearchlist(res.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callsearchdetails = async (searchkey) => {
    try {
      const postdata = {
        search: searchkey ? searchkey : state?.SKU ? state?.SKU : searchvalue,
        metal: metallist,
        size: sizelist,
        stone: stonelist,
      }
      let resSearchdata = await _Api(
        postdata,
        `api/v1/Inventory/quickview/result`,
      )
      if (resSearchdata.code === 200) {
        setResultlist(resSearchdata?.productList)
        setQty(resSearchdata?.productList?.total_qty)
        setImageid(resSearchdata?.productList?.main_id)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const update_filters = (label, id) => {
    if (label === 'metal') {
      let metal = [...metallist]
      setSizelist([])
      setStonelist([])
      let elem = document.getElementById(id)
      if (elem.classList.contains('active')) {
        resultlist?.metal.forEach((result) => {
          if (result?.select) {
            if (result?.id === id) {
              if (metallist?.length) {
                elem.classList.add('deactive')
                let updatemetal = metallist.filter((item) => item !== result?.id)
                metal = updatemetal
              } else {
                elem.classList.add('active')
                metal.push(result?.id)
              }
            }
          }
        })
      } else {
        resultlist?.metal.forEach((result) => {
          if (result?.id === id) {
            elem.classList.add('active')
            metal.push(result?.id)
          }
          // else if (result?.id !== id) {
          //   if (result?.select) {
          //     metal.push(result?.id)
          //   }
          // }
        })
      }
      setMetallist(metal)
    }
    if (label === 'stone') {
      setSizelist([])
      let stone = [...stonelist]
      let elem = document.getElementById(id)
      if (elem.classList.contains('active')) {
        resultlist?.Stone.forEach((result) => {
          if (result?.select) {
            if (result?.id === id) {
              if (stonelist.length) {
                elem.classList.add('deactive')
                let updatestone = stonelist.filter((item) => item !== result?.id)
                stone = updatestone
              } else {
                elem.classList.add('active')
                stone.push(result?.id)
              }
            }
          }
        })
      } else {
        resultlist?.Stone.forEach((result) => {
          if (result?.id === id) {
            elem.classList.add('active')
            stone.push(result?.id)
          }
          // else if (result?.id !== id) {
          //   if (result?.select) {
          //     stone.push(result?.id)
          //   }
          // }
        })
      }
      setStonelist(stone)
    }
    if (label === 'size') {
      let size = [...sizelist]
      let elem = document.getElementById(id)
      if (elem.classList.contains('active')) {
        resultlist?.size.forEach((result) => {
          if (result?.select) {
            if (result?.id === id) {
              if (sizelist.length) {
                elem.classList.add('deactive')
                let updatesize = sizelist.filter((item) => item !== result?.id)
                size = updatesize
              } else {
                elem.classList.add('active')
                size.push(result?.id)
              }
            }
          }
        })
      } else {
        resultlist?.size.forEach((result) => {
          if (result?.id === id) {
            elem.classList.add('active')
            size.push(result?.id)
          }
          // else if (result?.id !== id) {
          //   if (result?.select) {
          //     size.push(result?.id)
          //   }
          // }
        })
      }
      setSizelist(size)
    }
    if (label === 'lcoation') {
      let elem = document.getElementById(id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
      } else {
        elem.classList.add('active')

      }
    }
  }

  useEffect(() => {
    if (searchvalue || state?.SKU) {
      callsearchdetails()
    }
    // eslint-disable-next-line
  }, [metallist, stonelist, sizelist])

  const getImage = async () => {
    try {
      const postdata =
        `{
                 ProductImages(product_id:"` +
        imageid +
        `") {
                  image
                  name
              }
           
      }`
      let responce = await graphqlPost(postdata, 'App/graphql')
      setImage(responce?.data?.ProductImages?.image)
    } catch (err) {
      console.log(err, 'err')
    }
  }

  useEffect(() => {
    if (imageid) {
      getImage()
    }
    // eslint-disable-next-line
  }, [imageid])

  return (
    <React.Fragment>
      <div className="main-body quick-view">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading">
                <Link
                  className="page-back-btn position-static"
                  to="/app/inventory/myInventory"
                >
                  <img
                    src={base_assets + 'images/icons/arrow-left.png'}
                    alt=""
                  />
                </Link>
                {t('Quick View')}
              </h1>
              <div className="quick-view-inner">
                <div className="quick-view-inner-left">
                  <div className="main-body-top-status-bar-filter">
                    <input
                      type="text"
                      value={searchvalue ? searchvalue : state?.SKU}
                      className="main-body-top-status-bar-filter-input position-relative border-start-0"
                      placeholder={t('SKU')}
                      onChange={(e) => {
                        setSearchvalue(e.target.value)
                        setShow(true)
                        getsearchlist(e.target.value)
                      }}
                    />
                    <button className="main-body-top-status-bar-filter-search">
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                    {show && searchvalue !== '' && searchlist.length > 0 ? (
                      <div className="item_list_card_all">
                        <div className="item_list_card">
                          <div className="custom_search_title text-green">
                            {t('Custom Search')}
                          </div>
                          <ul
                            id="item_list_cardul_custom"
                            className="item_list_cardul_custom"
                          >
                            <li>{searchvalue}</li>
                          </ul>
                          <div className="custom_search_title text-green">
                            {t('Product')}
                          </div>
                          <ul className="item_list_cardul">
                            {searchlist
                              ? searchlist.map((result, key) => {
                                return (
                                  <li
                                    data-search={
                                      result?.SKU ? result?.SKU : ''
                                    }
                                    className="grid-li"
                                    onClick={(e) => {
                                      getsearchvalue(e)
                                    }}
                                    key={key}
                                  >
                                    {result?.SKU ? result?.SKU : ''}
                                  </li>
                                )
                              })
                              : ''}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="quick-view-inner-left-slider">
                    <div className="product-slider p-0">
                      <Slider {...settings}>
                        {image.length
                          ? image.map((result, key) => {
                            return (
                              <div className="quick-view-inner-left-slider-single" key={key}>
                                <img
                                  src={
                                    result?.original ? result?.original : '1'
                                  }
                                  alt=""
                                />
                              </div>
                            )
                          })
                          : ''}
                      </Slider>
                    </div>
                  </div>

                  {po_id && parmissiondatashow?.reserve_list?.Add ?
                    <div className="quick-view-inner-left-btn">
                      <Link to="/app/inventory/createreserves" className='text-decoration-none'
                        state={{ datalist: po_id }}>
                        <div className="border-inner-btn hover-effect ">
                          <button className=" ">{t('RESERVE')}</button>
                        </div>
                      </Link>
                    </div>
                    :
                    <div className="quick-view-inner-left-btn btn-disabled">
                      <Link to="" className='text-decoration-none'
                        state={{ datalist: po_id }}>
                        <div className="border-inner-btn hover-effect ">
                          <button className=" ">{t('RESERVE')}</button>
                        </div>
                      </Link>
                    </div>
                  }

                </div>
                <div className="quick-view-inner-right">
                  {resultlist?.SKU ?
                    <>
                      <div className="quick-view-inner-right-header">
                        <div className="quick-view-inner-right-header-left">
                          <h4 className="quick-view-inner-right-header-left-sku-heading">
                            {t('SKU')} : {resultlist?.SKU ? resultlist?.SKU : '-'}
                          </h4>
                          <span className="quick-view-inner-right-header-left-sku-heading-sub">
                            #{resultlist?.name ? resultlist?.name : '-'}
                          </span>
                        </div>
                        <div className="quick-view-inner-right-header-right">
                          <span>{t('Stock')}:</span>
                          <span className="quick-view-inner-right-header-right-value">
                            {resultlist?.total_qty
                              ? resultlist?.total_qty
                              : '0'}
                          </span>
                        </div>
                      </div>
                      <div className="quick-view-inner-right-body">
                        <div className="quick-view-inner-right-body-option-area">
                          <h6 className="quick-view-inner-right-body-option-area-heading">
                            {t('Metal')}
                            <label className="quick-view-inner-right-body-option-area-heading-select-all text-decoration-none"
                              onClick={() => { setMetallist([]); setStonelist([]); setSizelist([]) }}>
                              {t('Select All')}
                            </label>
                          </h6>
                          <div className="quick-view-inner-right-body-option-area-row">
                            {resultlist?.metal
                              ? resultlist?.metal.map((result, key) => {
                                return (
                                  <div
                                    key={'m' + key}
                                    className="quick-view-inner-right-body-option-area-row-single"
                                    onClick={() => { setStatus(true) }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={result?.id}
                                      className={
                                        result?.exsits === 1
                                          ? 'active'
                                          : result?.select === 0
                                            ? 'disable-input'
                                            : result?.exsits === 0 ? 'border' : ''
                                      }
                                      onChange={() => update_filters('metal', result?.id)}
                                    />
                                    <label
                                      className="quick-view-inner-right-body-option-area-row-single-label"
                                      htmlFor={result?.id}
                                    >
                                      {result?.name}
                                    </label>
                                  </div>
                                )
                              })
                              : ''}
                          </div>
                        </div>
                        <div className="quick-view-inner-right-body-option-area">
                          <h6 className="quick-view-inner-right-body-option-area-heading">
                            {t('Stone')}
                          </h6>
                          <div className="quick-view-inner-right-body-option-area-row">
                            {resultlist?.Stone
                              ? resultlist?.Stone.map((result, key) => {
                                return (
                                  <div
                                    key={'st' + key}
                                    className="quick-view-inner-right-body-option-area-row-single"
                                  >
                                    <input
                                      type="checkbox"
                                      id={result?.id}
                                      className={
                                        result?.exsits === 1
                                          ? 'active'
                                          : result?.select === 0
                                            ? 'disable-input'
                                            : result?.exsits === 0 ? 'border' : ''
                                      }
                                      onChange={() =>
                                        update_filters('stone', result?.id)
                                      }
                                    />
                                    <label
                                      className="quick-view-inner-right-body-option-area-row-single-label"
                                      htmlFor={result?.id}
                                    >
                                      {result?.name}
                                    </label>
                                  </div>
                                )
                              })
                              : ''}
                          </div>
                        </div>
                        <div className="quick-view-inner-right-body-option-area">
                          <h6 className="quick-view-inner-right-body-option-area-heading">
                            {t('Size')}
                          </h6>
                          <div className="quick-view-inner-right-body-option-area-row">
                            {resultlist?.size
                              ? resultlist?.size.map((result, key) => {
                                return (
                                  <div
                                    key={'s' + key}
                                    className="quick-view-inner-right-body-option-area-row-single"

                                  >
                                    <input
                                      type="checkbox"
                                      id={result?.id}
                                      className={
                                        result?.exsits === 1
                                          ? 'active'
                                          : result?.select === 0
                                            ? 'disable-input'
                                            : result?.exsits === 0 ? 'border' : ''
                                      }
                                      onChange={() =>
                                        update_filters('size', result?.id, result?.select)
                                      }
                                    />
                                    <label
                                      className="quick-view-inner-right-body-option-area-row-single-label"
                                      htmlFor={result?.id}
                                    >
                                      {result?.name}
                                    </label>
                                  </div>
                                )
                              })
                              : ''}
                          </div>
                        </div>
                        <div className="quick-view-inner-right-body-option-area">
                          <h6 className="quick-view-inner-right-body-option-area-heading">
                            {t('Location')}
                          </h6>
                          <div className="quick-view-inner-right-body-option-area-row">
                            {resultlist?.lcoation
                              ? resultlist?.lcoation.map((result, key) => {
                                return (
                                  <div
                                    key={'l' + key}
                                    className="quick-view-inner-right-body-option-area-row-single"
                                    onClick={() => setPo_id(result?.po_id)}
                                  >
                                    <input
                                      type="checkbox"
                                      id={result?.id}
                                      className={
                                        result?.select === 0
                                          ? 'disable-input'
                                          : ''

                                      }
                                      onClick={() =>
                                        update_filters('lcoation', result?.id)
                                      }
                                    />
                                    <label
                                      className="quick-view-inner-right-body-option-area-row-single-label"
                                      htmlFor={result?.id}
                                    >
                                      {result?.name} <span className='danger-text'> (
                                        {result?.qty
                                          ? result?.qty
                                          : '0'}
                                        )</span>
                                    </label>
                                  </div>
                                )
                              })
                              : ''}
                          </div>
                        </div>
                      </div>
                    </>
                    : <div className='d-flex justify-content-center h-100 align-items-center fa-2x'>
                      No Product, Please Search SKU
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ItemSearch;
