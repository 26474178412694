import axios from 'axios';
import { error } from "../common/helpers/toastify";
export const graphqlPost = function (querystring = "", url = 'Inventory/graphql') {
  return new Promise(async (resolve, reject) => {
    var data = JSON.stringify({
      query: querystring,
      variables: {}
    });
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + 'api/v1/' + url,
      headers: {
        'authorization': localStorage.getItem("UserToken"),
        'Content-Type': 'application/json'
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 403) {
          localStorage.removeItem("UserToken");
          localStorage.removeItem('updateModulePermission');
          localStorage.removeItem('updateUserPermission');
          var currentPath = window.location.pathname;
          window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
        } else { resolve(response?.data); }


      })
      .catch(function (err) {
        error(err?.message)
      });
  });
};

export const graphqlPostapp = function (querystring = "", url = 'App/graphql') {
  return new Promise(async (resolve, reject) => {
    var data = JSON.stringify({
      query: querystring,
      variables: {}
    });
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + 'api/v1/' + url,
      headers: {
        'authorization': localStorage.getItem("UserToken"),
        'Content-Type': 'application/json'
      },
      data: data
    };



    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 403) {
          localStorage.removeItem("UserToken");
          localStorage.removeItem('updateModulePermission');
          localStorage.removeItem('updateUserPermission');
          var currentPath = window.location.pathname;
          window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
        } else { resolve(response?.data); }


      })
      .catch(function (err) {
        error(err?.message)
      });
  });
};
export const _Api = function (querystring = "", Url = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + Url,
      headers: {
        'authorization': localStorage.getItem("UserToken"),
        'Content-Type': 'application/json'
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 403) {
          localStorage.removeItem("UserToken");
          localStorage.removeItem('updateModulePermission');
          localStorage.removeItem('updateUserPermission');
          var currentPath = window.location.pathname;
          window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
        } else { resolve(response?.data); }
      })
      .catch(function (err) {
        error(err?.message)
      });
  });
};

export const Api_form = function (querystring = "", Url = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + Url,
      headers: {
        'authorization': localStorage.getItem("UserToken"),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 403) {
          localStorage.removeItem("UserToken");
          localStorage.removeItem('updateModulePermission');
          localStorage.removeItem('updateUserPermission');
          var currentPath = window.location.pathname;
          window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
        } else { resolve(response?.data); }
      })
      .catch(function (err) {
        error(err?.message)
      });
  });
};

export const _tableHeadAPI = function (querystring = "") {
  return new Promise(async (resolve, reject) => {
    var data = {
      "name": querystring
    }
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + `api/v1/Inventory/common/getColumnHeads`,
      headers: {
        'authorization': localStorage.getItem("UserToken"),
        'Content-Type': 'application/json'
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 200) {
          resolve(response?.data.data);
        } else if (response?.data?.code === 403) {
          localStorage.removeItem("UserToken");
          localStorage.removeItem('updateModulePermission');
          localStorage.removeItem('updateUserPermission');
          var currentPath = window.location.pathname;
          window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
        } else {
          resolve(false);
        }
      })
      .catch(function (err) {
        error(err?.message)
      });
  });

};
export const _Apiauth = function (querystring = "", Url = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + 'api/v1/' + Url,
      headers: {
        'authorization': localStorage.getItem("UserToken"),
        'Content-Type': 'application/json'
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 403) {
          localStorage.removeItem("UserToken");
          localStorage.removeItem('updateModulePermission');
          localStorage.removeItem('updateUserPermission');
          var currentPath = window.location.pathname;
          window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
        } else {
          resolve(response?.data);
        }
      })
      .catch(function (err) {
        error(err?.message)
      });
  });
};



