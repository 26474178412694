import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import '../../catalog/catalog.scss'
import CheckoutBlank from '../../checkout/checkoutBlank'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import { _Api } from '../../common/api/_call'
import { toFormatPrice } from '../../common/helpers/function'
import Loading from '../../common/module/Loading/Loading'
import { useTranslation } from 'react-i18next'
const Mixdiamondsetting = () => {
  const { t } = useTranslation()
  const dataFetched = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { state } = useLocation()
  const [productlist, setProductlist] = useState()
  const [MetalOption, setMetalOption] = useState([])
  const [StoneOption, setStoneOption] = useState([])
  const [SizeOption, setSizeOption] = useState([])
  const [PointerOption, setPointerOption] = useState([])
  const [pricevalue, setPricevalue] = useState(0)
  const [imagearr, setImagearr] = useState([])
  const [Loadingstatus, setLoadingstatus] = useState(false)
  const [allchangeitems, setAllchangeitems] = useState({
    metal: '',
    stone: '',
    size: '',
    shape: '',
    pointer: '',
    price: 0,
    metal_name: '',
    size_name: '',
  })
  const getproductdetails = async () => {
    setLoadingstatus(true)
    try {
      const postdata = {
        product_id: state?.product_id,
        Metal: allchangeitems?.metal,
        Size: allchangeitems?.size,
        Stone: allchangeitems?.stone,
        type:
          state?.filter?.Optiondetails || state?.isWarehouse
            ? 'catalog'
            : 'inventory',
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/mixAndMatch/getProductDetailById`,
      )
      if (res.code === 200) {
        setLoadingstatus(false)
        let marray = []
        let starray = []
        let sarray = []
        let iarray = []
        setProductlist(res?.data)
        if (res?.data?.metal_value?.length) {
          res?.data?.metal_value.forEach((result) => {
            marray.push({ value: result?._id, label: result?.name })
          })
        }
        if (res?.data?.stone_value?.length) {
          res?.data?.stone_value.forEach((result) => {
            starray.push({ value: result?._id, label: result?.name })
          })
        }
        if (res?.data?.size_value?.length) {
          res?.data?.size_value.forEach((result) => {
            sarray.push({ value: result?._id, label: result?.name })
          })
        }
        if (res?.data?.main_image_Arr?.length) {
          res?.data?.main_image_Arr.forEach((result) => {
            iarray.push({
              original: result?.image,
              thumbnail: result?.thumb,
            })
          })
        }
        if (allchangeitems?.metal === '') {
          setAllchangeitems((previousInputs) => ({
            ...previousInputs,
            metal: res?.data?.metal_value[0]?._id,
          }))
        }
        if (allchangeitems?.size === '') {
          setAllchangeitems((previousInputs) => ({
            ...previousInputs,
            size: res?.data?.size_value[0]?._id,
          }))
        }
        if (allchangeitems?.stone === '') {
          setAllchangeitems((previousInputs) => ({
            ...previousInputs,
            stone: res?.data?.stone_value[0]?._id,
          }))
        }
        if (allchangeitems?.metal_name === '') {
          setAllchangeitems((previousInputs) => ({
            ...previousInputs,
            metal_name: res?.data?.metal_value[0]?.name,
          }))
        }
        if (allchangeitems?.size_name === '') {
          setAllchangeitems((previousInputs) => ({
            ...previousInputs,
            size_name: res?.data?.size_value[0]?.name,
          }))
        }

        setAllchangeitems((previousInputs) => ({
          ...previousInputs,
          shape: res?.data?.selected_data?.shape,
        }))
        setAllchangeitems((previousInputs) => ({
          ...previousInputs,
          price: res?.data?.selected_data?.price
            ? res?.data?.selected_data?.price
            : 0,
        }))
        setAllchangeitems((previousInputs) => ({
          ...previousInputs,
          pointer: res?.data?.selected_data?.pointer,
        }))
        setMetalOption(marray)
        setStoneOption(starray)
        setSizeOption(sarray)
        setImagearr(iarray)
        dataFetched.current = false;
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getPointer = (data) => {
    if (data?.length) {
      let array = []
      let pointerarray = []
      array.push.apply(array, data)
      array.forEach((result) => {
        pointerarray.push({ value: result?.pointer, label: result?.pointer })
      })
      setPointerOption(pointerarray)
    }
  }
  const getPrice = (pointer) => {
    const main = productlist?.choice_data
    let pricearray = ''
    main.forEach((result) => {
      if (result?.shape?.id === allchangeitems?.shape) {
        pricearray = result?.data.filter((item) => item.pointer === pointer)
      }
    })
    setPricevalue(pricearray[0]?.price)
    setAllchangeitems((previousInputs) => ({
      ...previousInputs,
      pointer: pointer,
    }))
    setAllchangeitems((previousInputs) => ({
      ...previousInputs,
      price: pricearray[0]?.price,
    }))
  }
  const changemetal_size = (input, key) => {
    if (key === 'metal') {
      setAllchangeitems((previousInputs) => ({
        ...previousInputs,
        metal: input?.value,
      }))
      setAllchangeitems((previousInputs) => ({
        ...previousInputs,
        metal_name: input?.label,
      }))
    } else {
      setAllchangeitems((previousInputs) => ({
        ...previousInputs,
        size: input?.value,
      }))
      setAllchangeitems((previousInputs) => ({
        ...previousInputs,
        size_name: input?.label,
      }))
    }
  }
  useEffect(() => {
    if (state?.product_id) {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getproductdetails()
    }
    // eslint-disable-next-line
  }, [allchangeitems?.metal, allchangeitems?.stone, allchangeitems?.size])
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area">
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left">
                    <Link
                      to="/app/pos/custom/mixAndMatchProduct"
                      state={{
                        items: state?.items,
                        daimond_id: state?.daimond_id,
                        optiondetailid: state?.filter?.Optiondetails,
                        product_id: state?.showpage ? productlist?.product_id : "",
                        Engraving: state?.Engraving,
                        editproduct: state?.editproduct,
                        customcart: state?.customcart,
                        scrvicelist: state?.scrvicelist
                          ? state?.scrvicelist
                          : [],
                        scrviceremark: state?.scrviceremark,
                        remark: state?.remark ? state?.remark : '',
                        showpage: state?.showpage,
                        order_edit: state?.order_edit,
                        order_id: state?.order_id,
                        order_type: state?.order_type,
                      }}
                      className="page-back"
                    >
                      <img
                        className="page-back-img"
                        src={
                          base_assets + 'images/pos/icons/arrow-gray-left.png'
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="catalog-area-ct bg-white">
                {Loadingstatus ? <Loading /> : ''}
                <div className="products-details">
                  <div className="products-details-row">
                    <div className="products-details-row-col products-details-row-col-left">
                      <div className="products-details-row-col-left-slider">
                        <ImageGallery
                          items={imagearr}
                          showPlayButton={false}
                          showFullscreenButton={false}
                          showNav={false}
                          infinite={true}
                          disableSwipe={false}
                          disableThumbnailSwipe={false}
                          additionalClass="img-view-slider"
                        />
                      </div>
                      <div className={productlist?.inventory_statistics?.total ? "products-details-row-col-left-location" : "d-none"}>
                        <div className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              className="products-details-row-col-left-location-img"
                              src={
                                base_assets +
                                'images/pos/icons/location-icon.png'
                              }
                              alt=""
                            />
                            <span>
                              {productlist?.inventory_statistics?.total}
                            </span>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {productlist?.inventory_statistics?.response?.length
                              ? productlist?.inventory_statistics?.response.map(
                                (result, lockey) => {
                                  return (
                                    <li key={lockey}>
                                      <Link className="dropdown-item">
                                        {result?.currency ? (
                                          <img
                                            src={
                                              base_assets +
                                              'images/flags/' +
                                              result?.currency.toLowerCase() +
                                              '.png'
                                            }
                                            alt=""
                                          />
                                        ) : (
                                          ''
                                        )}
                                        <span className="fw-light">
                                          {result?.location_name}
                                        </span>
                                        <span className="ms-auto fw-light">
                                          {result?.qty}
                                        </span>
                                      </Link>
                                    </li>
                                  )
                                },
                              )
                              : ''}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="products-details-row-col products-details-row-col-right">
                      <h1 className="main-heading">{productlist?.name}</h1>
                      <h2 className="products-details-row-col-right-sku-heading">
                        {productlist?.SKU}
                      </h2>
                      <p className="products-details-row-col-right-short-desc">
                        {productlist?.Matatag}
                      </p>
                      <div className="products-details-row-col-right-select">
                        <div className="products-details-row-col-right-select-single">
                          <label className="products-details-row-col-right-select-single-label">
                            {t('Metal')} :
                          </label>
                          <div
                            className={
                              productlist?.Status_SMS?.is_stone === 'Yes'
                                ? 'common-select'
                                : 'pe-none common-select'
                            }
                          >
                            {MetalOption?.length ? (
                              <Select
                                onChange={(input) =>
                                  changemetal_size(input, 'metal')
                                }
                                options={MetalOption}
                                classNamePrefix="common-select"
                                defaultValue={{
                                  value: MetalOption[0]?.value,
                                  label: MetalOption[0]?.label,
                                }}
                                isSearchable={false}
                              />
                            ) : (
                              <span className="not_available">
                                {t('Not Available')}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="products-details-row-col-right-select-single">
                          <label className="products-details-row-col-right-select-single-label">
                            {t('Stone')} :
                          </label>
                          <div
                            className={
                              productlist?.Status_SMS?.is_stone === 'Yes'
                                ? 'common-select'
                                : 'pe-none common-select'
                            }
                          >
                            {StoneOption?.length ? (
                              <Select
                                onChange={(input) =>
                                  setAllchangeitems({
                                    ...allchangeitems,
                                    stone: input?.value,
                                  })
                                }
                                options={StoneOption}
                                classNamePrefix="common-select"
                                defaultValue={{
                                  value: StoneOption[0]?.value,
                                  label: StoneOption[0]?.label,
                                }}
                                isSearchable={false}
                              />
                            ) : (
                              <span className="not_available">
                                {t('Not Available')}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="products-details-row-col-right-select-single">
                          <label className="products-details-row-col-right-select-single-label">
                            {t('Size')} :
                          </label>
                          <div
                            className={
                              productlist?.Status_SMS?.is_size === 'Yes'
                                ? 'common-select'
                                : 'pe-none common-select'
                            }
                          >
                            {SizeOption?.length ? (
                              <Select
                                onChange={(input) =>
                                  changemetal_size(input, 'size')
                                }
                                options={SizeOption}
                                classNamePrefix="common-select"
                                defaultValue={{
                                  value: SizeOption[0]?.value,
                                  label: SizeOption[0]?.label,
                                }}
                                isSearchable={false}
                              />
                            ) : (
                              <span className="not_available">
                                {t('Not Available')}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="products-details-row-col-right-select-diamond">
                          <label className="products-details-row-col-right-select-single-label">
                            {t('Center Diamond')} :
                          </label>
                          <div className="products-details-row-col-right-select-diamond-select">
                            {productlist?.choice_data?.length
                              ? productlist?.choice_data.map((result, skey) => {
                                if (!PointerOption?.length && skey === 0) {
                                  getPointer(result?.data)
                                }
                                return (
                                  <div
                                    key={skey}
                                    onClick={() => {
                                      getPointer(result?.data)
                                      setAllchangeitems({
                                        ...allchangeitems,
                                        shape: result?.shape?.id,
                                      })
                                    }}
                                    className={
                                      allchangeitems?.shape === ''
                                        ? result?.shape?.id ===
                                          productlist?.selected_data?.shape
                                          ? 'products-details-row-col-right-select-diamond-select-hover active'
                                          : 'products-details-row-col-right-select-diamond-select-hover'
                                        : result?.shape?.id ===
                                          allchangeitems?.shape
                                          ? 'products-details-row-col-right-select-diamond-select-hover active'
                                          : 'products-details-row-col-right-select-diamond-select-hover'
                                    }
                                  >
                                    <div className="products-details-row-col-right-select-diamond-select-round">
                                      <img
                                        className="products-details-row-col-right-select-diamond-select-round-img"
                                        src={result?.shape?.logo}
                                        alt=""
                                      />
                                    </div>
                                    <p className="products-details-row-col-right-select-diamond-select-inner">
                                      {result?.shape?.name}
                                    </p>
                                  </div>
                                )
                              })
                              : ''}
                          </div>
                        </div>
                        <div className="products-details-row-col-right-select-single">
                          <label className="products-details-row-col-right-select-single-label">
                            {t('Pointer')} :
                          </label>
                          <div className="common-select">
                            {PointerOption?.length ? (
                              <Select
                                onChange={(input) => getPrice(input?.label)}
                                options={PointerOption}
                                classNamePrefix="common-select"
                                defaultValue={{
                                  value: PointerOption[0]?.value,
                                  label: PointerOption[0]?.label,
                                }}
                                isSearchable={false}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="products-details-row-col-right-price">
                        {toFormatPrice(
                          pricevalue
                            ? pricevalue
                            : productlist?.selected_data?.price,
                          { addSymbol: true },
                        )}
                      </p>
                      <div className="products-details-row-col-right-btn-wrap">
                        <div className="border-inner-btn hover-effect">
                          {state?.daimond_id ? (
                            <Link
                              to="/app/pos/custom/mixAndMatchProduct"
                              className="add-to-cart"
                              state={{
                                itemchanges: allchangeitems,
                                daimond_id: state?.daimond_id,
                                items: state?.items,
                                product_id: productlist?.product_id,
                                optiondetailid: state?.isWarehouse
                                  ? state?.isWarehouse
                                  : state?.filter?.Optiondetails,
                                Engraving: state?.Engraving,
                                fromring: state?.showpage ? true : false,
                                editproduct: state?.editproduct,
                                customcart: state?.customcart,
                                scrvicelist: state?.scrvicelist
                                  ? state?.scrvicelist
                                  : [],
                                scrviceremark: state?.scrviceremark,
                                remark: state?.remark,
                                order_edit: state?.order_edit,
                                order_id: state?.order_id,
                                order_type: state?.order_type,
                              }}
                            >
                              {t('SELECT THIS')}
                            </Link>
                          ) : (
                            <Link
                              to="/app/pos/custom/mixAndMatch"
                              className="add-to-cart"
                              state={{
                                itemchanges: allchangeitems,
                                daimond_id: '',
                                items: state?.items,
                                product_id: productlist?.product_id,
                                optiondetailid: state?.isWarehouse
                                  ? state?.isWarehouse
                                  : state?.filter?.Optiondetails,
                                bychangeorder: true,
                                Engraving: state?.Engraving,
                                editproduct: state?.editproduct,
                                customcart: state?.customcart,
                              }}
                            >
                              {t('SELECT THIS')}
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="products-details-row-col-right-tabs-area">
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#Description"
                            >
                              {t('Description')}
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane container active"
                            id="Description"
                          >
                            {productlist?.Description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div >
    </>
  )
}
export default Mixdiamondsetting
