

export function getBarcodeByStockId(barcodeArray,stock_id){
  let barcode  = '';
  console.log(barcodeArray)
  
  barcodeArray.map((v,k)=>{
   const skustring = v.stock_id
   const search = skustring.search(stock_id);
   console.log(v,'vv')
   console.log(stock_id,'sku')
   console.log(v.barcodeImg,'v.barcodeImg')
   console.log(search,'search')
     if(search>=0){
 
       barcode =  v.barcodeImg;
         }else{
       console.log("else")
       
      
 
     }
   })
   return barcode;
 
 }

export function getQrcodeByStockId(QrArray=[],stock_id=""){
  let barcode  = '';
  QrArray.map((v,k)=>{
   const skustring = v.stock_id
   const search = skustring.search(stock_id);

     if(search>=0){
 
       barcode =  v.qrcodeImg;
         }else{
       console.log("else")
       
      
 
     }
   })
   return barcode;
 
 }
export function getQrcodeByProductId(QrArray=[],product_id=""){
  let barcode  = '';
  QrArray.map((v,k)=>{
   const skustring = v.product_id
   const search = skustring.search(product_id);

     if(search>=0){
 
       barcode =  v.qrcodeImg;
         }else{
       console.log("else")
       
      
 
     }
   })
   return barcode;
 
 }



 export function getBarcodeBySku(barcodeArray,sku){
 let barcode  = '';
 
 barcodeArray.map((v,k)=>{
  const skustring = v.sku
  const search = skustring.search(sku);

    if(search>=0){

      barcode =  v.barcodeImg;
        }else{
      console.log("else")
      
     

    }
  })
  return barcode;

}