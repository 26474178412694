import React from 'react'

const LeftSideArrowIcon = (props) => {
    const { width, height } = props;
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.64647 0.646484L0.292969 8.99998L8.64647 17.3535L9.35347 16.6465L1.70697 8.99998L9.35347 1.35348L8.64647 0.646484Z" fill="black"/>
        </svg>

    )
}

export default LeftSideArrowIcon;