import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Saleperson } from '../common/graphql/commonlayouts';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { store } from '../../source/index'

const CheckoutBlankCopy = (props) => {
  let state = store.getState()
  let OrgSettings = state?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const saleperson = useSelector((state) => state.app.saleperson)
  const [TransactionDate, setTransactionDate] = useState(new Date())
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }

  return (
    <>
      <div className="main-body-row-right-col-inner" id="checkoutblankdiv">
        <div className="main-body-row-right-col-inner-ct-top">
          <div className="main-body-row-right-col-inner-ct-top-inner">
            <div className="main-body-row-right-col-inner-ct-top-inner-date-area">
              <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner">
                <span>XXXX</span> :
                <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner-input date">
                  <DatePicker
                    className="date-field"
                    selected={TransactionDate}
                    onChange={selectTransactionDate}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    autoComplete='none'
                    popperPlacement='top-start'
                  />
                  <img
                    className="date-img"
                    src={base_assets + 'images/icons/calendar-green.svg'}
                    alt=""
                  />
                </div>
              </div>
              <div className={
                saleperson?.value
                  ? 'main-body-row-right-col-inner-ct-top-inner-date-area-select'
                  : 'main-body-row-right-col-inner-ct-top-inner-date-area-select yellow-select'
              }
              >
                <Saleperson />
              </div>
            </div>
            <div className="main-body-row-right-col-inner-ct-top-inner-search yellow-search">
              <div className="com-search-area"><input type="text" className="main-body-top-status-bar-filter-input placeholder-up-input-field form-control position-relative border-start-0 ps-0 text-truncate bg-white" placeholder="Search customer name, phone number, Email" autoComplete="off" />
                <button className="com-search-area-large-filter btn-style" data-bs-toggle="modal" data-bs-target="#customerSearchDetailModal">
                  <svg className="com-search-area-img" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9055 15.8618L19.7925 18.7474C19.9272 18.8868 20.0017 19.0735 20 19.2673C19.9983 19.4611 19.9206 19.6465 19.7835 19.7835C19.6465 19.9206 19.4611 19.9983 19.2673 20C19.0735 20.0017 18.8868 19.9272 18.7474 19.7925L15.8604 16.9055C13.9737 18.5219 11.5344 19.3447 9.05418 19.2013C6.57396 19.058 4.24567 17.9595 2.55788 16.1365C0.870078 14.3135 -0.0460314 11.9076 0.00178169 9.42375C0.0495948 6.93986 1.05761 4.57102 2.81432 2.81432C4.57102 1.05761 6.93986 0.0495948 9.42375 0.00178169C11.9076 -0.0460314 14.3135 0.870078 16.1365 2.55788C17.9595 4.24567 19.058 6.57396 19.2013 9.05418C19.3447 11.5344 18.5219 13.9737 16.9055 15.8604V15.8618ZM9.6088 17.7392C11.7651 17.7392 13.8331 16.8826 15.3579 15.3579C16.8826 13.8331 17.7392 11.7651 17.7392 9.6088C17.7392 7.45248 16.8826 5.38447 15.3579 3.85972C13.8331 2.33497 11.7651 1.47838 9.6088 1.47838C7.45248 1.47838 5.38447 2.33497 3.85972 3.85972C2.33497 5.38447 1.47838 7.45248 1.47838 9.6088C1.47838 11.7651 2.33497 13.8331 3.85972 15.3579C5.38447 16.8826 7.45248 17.7392 9.6088 17.7392Z" fill="#5CC7C1">
                    </path>
                  </svg>
                </button>
              </div>
              <span className="main-body-row-right-col-inner-ct-top-inner-search-user" data-bs-toggle="modal" data-bs-target="#customerAddModal">
                <img src={base_assets + 'images/pos/icons/add-user.png'} alt="" />
              </span>
            </div>
          </div>
        </div>

        <div className="main-body-row-right-col-inner-progress">
          <div className="main-body-row-right-col-inner-progress-line">
            <div className="main-body-row-right-col-inner-progress-line-single col"></div>
            <div className="main-body-row-right-col-inner-progress-line-single col"></div>
            <div className="main-body-row-right-col-inner-progress-line-single col"></div>
            <div className="main-body-row-right-col-inner-progress-line-single col"></div>
          </div>

          <div className="main-body-row-right-col-inner-progress-step">
            <div className="main-body-row-right-col-inner-progress-step-single">
              <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
              <span className="main-body-row-right-col-inner-progress-step-single-text">
                sell
              </span>
            </div>
            <div className="main-body-row-right-col-inner-progress-step-single second">
              <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
              <span className="main-body-row-right-col-inner-progress-step-single-text">
                payment
              </span>
            </div>
            <div className="main-body-row-right-col-inner-progress-step-single">
              <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
              <span className="main-body-row-right-col-inner-progress-step-single-text">
                done
              </span>
            </div>
          </div>
        </div>

        <div className="main-body-row-right-col-inner-list box-1"></div>
        <div className="main-body-row-right-col-inner-summary box-2">


        </div>
        <div className="main-body-row-right-col-inner-footer">

          <p className="footer">© 2022. All rights reserved.</p>
        </div>

      </div>
    </>
  )
}

export default CheckoutBlankCopy;
