import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import './catalog.scss'
import { _Api } from '../common/api/_call'
import NoOrdersYet from '../common/module/NoOrdersYet/NoOrdersYet'
import Loading from '../common/module/Loading/Loading'
import { useTranslation } from 'react-i18next'
const Itemlist = (props) => {
  const { t } = useTranslation()
  const {
    NavId,
    Optiondetails,
    parkid,
    order_id,
    order_edit,
    order_type,
  } = props
  const [Loadingstatus, setLoadingstatus] = useState(false)
  const [CatalogJewelrylist, setCatlogjewelrylist] = useState()
  const getjewelrylist = async () => {
    setLoadingstatus(true)
    try {
      const postdata = {
        category_id: NavId,
        type: Optiondetails === true ? 'catalog' : 'inventory',
      }
      let res = await _Api(postdata, `api/v1/POS/home/getItemListwithCategory`)
      if (res?.code === 200) {
        setLoadingstatus(false)
        setCatlogjewelrylist(res?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (NavId) {
      getjewelrylist()
    }
    // eslint-disable-next-line 
  }, [NavId, Optiondetails])

  return (
    <>
      <div className="position-relative h-100">
        {Loadingstatus ? <Loading /> : ''}
        <div className="product-cat">
          <h1 className="main-heading">{t('Jewelry')}</h1>
          <div className="product-cat-row">
            {CatalogJewelrylist?.length ? (
              CatalogJewelrylist?.map((result, catkey) => {
                return (
                  <Link
                    to="/app/pos/products"
                    state={{
                      itemid: result?._id,
                      Optiondetails: Optiondetails,
                      NavId: NavId,
                      itemname: result?.name,
                      parkid: parkid,
                      order_id: order_id,
                      order_edit: order_edit,
                      order_type: order_type,
                    }}
                    className="product-cat-row-single-link"
                    key={catkey}
                  >
                    <div key={catkey} className="product-cat-row-single">
                      <img
                        className="product-cat-row-single-img"
                        src={result?.logo ? result?.logo : ' '}
                        alt=""
                      />
                      <h5 className="product-cat-row-single-name">
                        {result?.name ? result?.name : ' '}
                      </h5>
                    </div>
                  </Link>
                )
              })
            ) : (
              <NoOrdersYet />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Itemlist
