import React, { useState, useEffect, useCallback } from 'react'
import Chart from 'react-apexcharts'
import { _Api } from '../../api/_call'
import { LocationList } from "../../common/Commongraphql"
import { useTranslation } from 'react-i18next';
const Inventory = (props) => {
  const { setDashboardhideshow, setDefaultset } = props
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [completeDataObject, setCompliteDataObject] = useState({
    "timeset": "Monthly",
    graphtype: "Chart",
    location: {
      name: "All",
      id: ""
    }
  })
  const [Stockobj, setStockobj] = useState({
    "graphtype": "Chart",
    "type": "Locations"
  })
  const [stockdata, setStockdata] = useState()
  const [locationarrayList, setLocationarrayList] = useState([])
  const [inventoryStatics, setInventoryStatics] = useState([])
  const [tabledata, setTabledata] = useState([])
  const [stocktabledata, setStockTabledata] = useState([])
  const [pinchartdata, setPinchartdata] = useState({ label: [], data: [] })
  let time_periods = ['Daily', 'Monthly', 'Yearly', 'Weekly']
  let type = ["Item", "Collection", "SKU", "Locations"]
  const inventorydata = useCallback(async () => {
    try {
      let postdata = {
        duration: completeDataObject?.timeset,
        graphtype: completeDataObject?.graphtype,
        location: completeDataObject?.location?.id
      }
      let res = await _Api(postdata, `api/v1/dashboard/inventoryStatics`)
      if (res?.code === 200) {
        if (postdata?.graphtype === "Chart") {
          setInventoryStatics(res?.data)
        } else if (postdata?.graphtype === "Table") {
          setTabledata(res?.statics)
        }
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [completeDataObject]);
  useEffect(() => {
    inventorydata()
  }, [completeDataObject, inventorydata])
  const stockgraph = useCallback(async () => {
    try {
      let postdata = {
        "graphtype": Stockobj?.graphtype,
        "type": Stockobj?.type
      }
      let res = await _Api(postdata, `api/v1/dashboard/invetoryStockStatics`)
      if (res?.code === 200) {
        if (postdata?.graphtype === "Chart") {
          setStockdata(res?.data)
        } else if (postdata?.graphtype === "Table") {
          setStockTabledata(res?.statics)
        } else if (postdata?.graphtype === "PieChart") {
          setPinchartdata(res?.statics)
        }
      } else {
        setStockdata()
        setStockTabledata([])
        setPinchartdata({ label: [], data: [] })
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [Stockobj])
  useEffect(() => {
    stockgraph()
  }, [stockgraph, Stockobj])
  const inventoryChart = {
    series: inventoryStatics?.data?.length ? inventoryStatics?.data : [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '65%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: inventoryStatics?.xaxis?.length ? inventoryStatics?.xaxis : [],
      },
      yaxis: [
        {
          title: {
            text: inventoryStatics?.yaxes,
          },
        }
      ],
      fill: {
        opacity: 1,
      },
      colors: inventoryStatics?.color?.length ? inventoryStatics?.color : [],
    },
  };
  const stockChart = {
    series: stockdata?.data?.length ? stockdata?.data : [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '65%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: stockdata?.xaxis?.length ? stockdata?.xaxis : [],
      },
      yaxis: [
        {
          title: {
            text: 'Qty',
          },
        }
      ],
      fill: {
        opacity: 1,
      },
      colors: stockdata?.color?.length ? stockdata?.color : [],
    },
  };
  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const paiChart = {
    options: {
      chart: {
        type: "donut",
        width: 170,
        height: 170,
      },
      labels: pinchartdata?.xaxis?.length ? pinchartdata?.xaxis : [],
      title: {
        text: "Pie Chart",
        align: "center",
        style: {
          fontSize: "20px",
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
      legend: {
        position: "bottom",
      },
      colors: Array.from({ length: pinchartdata?.xaxis?.length }, () => generateRandomColor()),
    },
    series: pinchartdata?.data?.length ? pinchartdata?.data : [],
  };
  return (
    <div className="dashboard-area-cards-single h-100">
      <div className='row bg-white rounded-3 m-0'>
        <div className='col-xl-8 col-sm-6'>
          <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
            <div className="dashboard-area-cards-single-header d-flex align-items-center">
              <div className="w-auto pe-0 drag-btn">
                <img
                  className="drag-btn-icon"
                  src={base_assets + 'images/admin/icons/drag-icon.png'}
                  alt=""
                />
              </div>
              <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
                {t("Inventory")}
              </div>
              <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
                <span
                  className="btn dropdown-toggle border-right-radius-0 fw-semibold"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t(`${completeDataObject?.location?.name}`)}
                  <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
                </span>
                <ul className="dropdown-menu">
                  <li
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        "location": {
                          name: "All",
                          id: ""
                        }
                      }))}>
                    <span className="dropdown-item">All</span>
                  </li>
                  {
                    locationarrayList?.length ? locationarrayList.map((result, key) => {
                      return (
                        <li
                          key={key}
                          onClick={() =>
                            setCompliteDataObject(prevState => ({
                              ...prevState,
                              "location": result
                            }))}>
                          <span className="dropdown-item">{result?.name ? result?.name : ""}</span>
                        </li>
                      )
                    }) : ""
                  }
                </ul>
              </div>
              <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
                <span
                  className="btn dropdown-toggle border-left-radius-0 fw-semibold"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t(`${completeDataObject?.timeset}`)}
                  <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
                </span>
                <ul className="dropdown-menu">
                  {time_periods?.length ? time_periods.map((result, key) => {
                    return (
                      <li key={key}
                        onClick={() =>
                          setCompliteDataObject(prevState => ({
                            ...prevState,
                            timeset: result
                          }))}>
                        <span className="dropdown-item">{t(`${result}`)}</span>
                      </li>
                    )
                  }) : ""}
                </ul>
              </div>
              <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
                <span
                  className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
                </span>
                <ul className="dropdown-menu">
                  <li
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        graphtype: "Chart"
                      }))}>
                    <span className="dropdown-item">
                      <i className="fa fa-line-chart me-2" aria-hidden="true"></i>
                      {t("Chart")}
                    </span>
                  </li>
                  <li
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        graphtype: "Table"
                      }))}>
                    <span className="dropdown-item">
                      <i className="fa fa-table me-2" aria-hidden="true"></i> {t("Table")}
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      setDashboardhideshow(prevState => ({
                        ...prevState,
                        Inventory: 0
                      }));
                      setDefaultset(true)
                    }}>
                    <span className="dropdown-item">
                      <i className="fa fa-trash-o me-2" aria-hidden="true"></i>
                      {t("Delete")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dashboard-area-cards-single-chart-area py-3">
              {completeDataObject?.graphtype === "Chart" ?

                <div className='dashboard-area-cards-single-chart-area-inner' id="inventoryChart">
                  <Chart
                    options={inventoryChart.options}
                    series={inventoryChart.series}
                    type="bar"
                    height={350}
                  />
                </div> :
                completeDataObject?.graphtype === "Table" ?
                  <div className='dashboard-area-cards-single-chart-area-table position-relative'>
                    <table className='w-100'>
                      <thead>
                        <tr>
                          <th className='text-center'>Stock</th>
                          <th className='text-center'>(Reserved)</th>
                          <th className='text-center'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tabledata?.length ? tabledata.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td className='text-center'>{result?.Stock ? result?.Stock : 0}</td>
                              <td className='text-center'>{result?.reserve ? result?.reserve : 0}</td>
                              <td className='text-center'>{result?.label ? result?.label : ""}</td>
                            </tr>
                          )
                        }) : ""}

                      </tbody>
                    </table>
                  </div> : ''}
            </div>
          </div>
        </div>
        <div className='col-xl-4 col-sm-6'>
          <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
            <div className="dashboard-area-cards-single-header d-flex align-items-center">
              <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
                {t("Stock")}
              </div>

              <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
                <span
                  className="btn dropdown-toggle fw-semibold"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {Stockobj?.type}
                  <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
                </span>
                <ul className="dropdown-menu">
                  {type?.length ? type.map((result, key) => {
                    return (
                      <li key={key}
                        onClick={() =>
                          setStockobj(prevState => ({
                            ...prevState,
                            type: result
                          }))}>
                        <span className="dropdown-item">{result}</span>
                      </li>
                    )
                  }) : ""}
                </ul>
              </div>
              <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
                <span
                  className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
                </span>
                <ul className="dropdown-menu">
                  <li
                    onClick={() =>
                      setStockobj(prevState => ({
                        ...prevState,
                        graphtype: "Chart"
                      }))}>
                    <span className="dropdown-item" >
                      <i className="fa fa-line-chart me-2" aria-hidden="true"></i>{' '}
                      {t("Chart")}
                    </span>
                  </li>
                  <li
                    onClick={() =>
                      setStockobj(prevState => ({
                        ...prevState,
                        graphtype: "PieChart"
                      }))}>
                    <span className="dropdown-item" >
                      <i className="fa fa-pie-chart me-2" aria-hidden="true"></i>{' '}
                      {t("Pie Chart")}
                    </span>
                  </li>
                  <li
                    onClick={() =>
                      setStockobj(prevState => ({
                        ...prevState,
                        graphtype: "Table"
                      }))}>
                    <span className="dropdown-item" >
                      <i className="fa fa-table me-2" aria-hidden="true"></i> {t("Table")}
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      setDashboardhideshow(prevState => ({
                        ...prevState,
                        Inventory: 0
                      }));
                      setDefaultset(true)
                    }}>
                    <span className="dropdown-item" >
                      <i className="fa fa-trash-o me-2" aria-hidden="true"></i>
                      {t("Delete")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dashboard-area-cards-single-chart-area py-3">
              {Stockobj?.graphtype === "Chart" ?
                <div className='dashboard-area-cards-single-chart-area-inner' id="stockChart">
                  <Chart
                    options={stockChart.options}
                    series={stockChart.series}
                    type="bar"
                    height={350}
                  />
                </div> :
                Stockobj?.graphtype === "Table" ? <div className='dashboard-area-cards-single-chart-area-table position-relative'>
                  <table className='w-100'>
                    <thead>
                      <tr>
                        <th className='text-center'></th>
                        <th className='text-center'>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stocktabledata?.length ? stocktabledata.map((result, key) => {
                        return (
                          <tr key={key}>
                            <td className='text-center'>{result?.label ? result?.label : ''}</td>
                            <td className='text-center'>{result?.qty ? result?.qty : 0}</td>
                          </tr>
                        )
                      }) : ""}

                    </tbody>
                  </table>
                </div> :
                  Stockobj?.graphtype === "PieChart" ?
                    <div className='dashboard-area-cards-single-chart-area-inner'>
                      <Chart
                        options={paiChart.options}
                        series={paiChart.series}
                        type="donut"
                      />
                    </div> : ""}



            </div>
          </div>
        </div>
      </div>
      <LocationList setLocationarrayList={setLocationarrayList} />
    </div>
  )
}
export default Inventory
