import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Productfiltermodal = (props) => {
  const { t } = useTranslation()
  const {
    productfilterdetails,
    setCollectionsFilter,
    setItemsFilter,
    setFilterApply,
  } = props
  const [CollectionsFilter_, setCollectionsFilter_] = useState([])
  const [ItemsFilter_, setItemsFilter_] = useState([])

  useEffect(() => {
    setCollectionsFilter(CollectionsFilter_)
    // eslint-disable-next-line
  }, [CollectionsFilter_])

  useEffect(() => {
    setItemsFilter(ItemsFilter_)
    // eslint-disable-next-line
  }, [ItemsFilter_])

  const setItemsFilter_data = (event) => {
    if (event.target.checked) {
      const ItemsFilter_a = [...ItemsFilter_]
      ItemsFilter_a.push(event.target.value)
      setItemsFilter_(ItemsFilter_a)
    } else {
      setItemsFilter_((data) =>
        data.filter((item) => item !== event.target.value),
      )
    }
  }
  const setCollectionsFilter_data = (event) => {
    if (event.target.checked) {
      const CollectionsFilter_a = [...CollectionsFilter_]
      CollectionsFilter_a.push(event.target.value)
      setCollectionsFilter_(CollectionsFilter_a)
    } else {
      setCollectionsFilter_((data) =>
        data.filter((item) => item !== event.target.value),
      )
    }
  }

  const clearAllFilters = () => {
    var master_product_checkbox = document.querySelectorAll(
      '.checkbox_values_filter:checked',
    )
    master_product_checkbox?.forEach(function (checkBox_selectAble) {
      checkBox_selectAble.checked = false
    })
    setItemsFilter_([])
    setCollectionsFilter_([])
  }
  return (
    <div
      className="modal fade filter-modal"
      id="productfiltermodal"
      tabIndex={-1}
      aria-labelledby="productModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="productModalLabel">
              {t('Filters')}
            </h5>
            <button
              type="button"
              className="filter-modal-clear-all"
              onClick={() => clearAllFilters()}
              aria-label="Close"
            >
              {t('Clear All')}
            </button>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body filter-modal-row filter-modal-row-height">
            <div className="filter-modal-row-box">
              <div className="filter-modal-row-box-header">
                <h4 className="filter-modal-row-box-header-heading">
                  {t('Item')}
                </h4>
              </div>
              <div className="filter-modal-row-box-content">
                {productfilterdetails
                  ? Object.values(productfilterdetails)[0].map(
                    (items, itemkey) => {
                      return (
                        <div
                          key={items?.id}
                          className="checkbox checkbox-custom com-check"
                        >
                          <input
                            id={items?.id}
                            className="checkbox_values_filter"
                            onChange={(e) => setItemsFilter_data(e)}
                            type="checkbox"
                            value={items?.id}
                            name="product_item"
                          />
                          <label
                            className="com-check-label"
                            htmlFor={items?.id}
                          ></label>
                          <label
                            className=" mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                            htmlFor={items?.id}
                          >
                            {items?.name ? items?.name : ''}
                          </label>
                        </div>
                      )
                    },
                  )
                  : ''}
              </div>
            </div>
            <div className="filter-modal-row-box">
              <div className="filter-modal-row-box-header">
                <h4 className="filter-modal-row-box-header-heading">
                  {t('Collection')}
                </h4>
              </div>
              <div className="filter-modal-row-box-content">
                {productfilterdetails
                  ? Object.values(productfilterdetails)[1].map(
                    (collections, collectionkey) => {
                      return (
                        <div
                          key={collections?.id}
                          className="checkbox checkbox-custom com-check"
                        >
                          <input
                            className="checkbox_values_filter"
                            onChange={(e) => setCollectionsFilter_data(e)}
                            id={collections?.id}
                            type="checkbox"
                            value={collections?.id}
                            name="product_collection"
                          />
                          <label
                            className="com-check-label"
                            htmlFor={collections?.id}
                          ></label>
                          <label
                            className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                            htmlFor={collections?.id}
                          >
                            {collections?.name ? collections?.name : ''}
                          </label>
                        </div>
                      )
                    },
                  )
                  : ''}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="filter-modal-apply-btn"
              onClick={() => setFilterApply(true)}
              data-bs-dismiss="modal"
            >
              {t('Apply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Productfiltermodal
