import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom'
export const subtableallocationpo = (prioritydata, t, key, Qty) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;

  return (
    <>
      {prioritydata?.code === 200 ?
        <tr className="common-sub-table-wrap add-row" key={key}>
          <td colSpan="16" >
            <table id='data_table_set_allocation' className="common-table common-sub-table">
              <thead>
                <tr>
                  <th><div className="com-check"><input className="subtablecheck_main" id="subtable_main" type="checkbox" /><label htmlFor="subtable_main" className="com-check-label " /></div></th>
                  <th className="col-highlight">#</th>
                  <th className="col-highlight">{t("Date")}</th>
                  <th className="col-highlight">{t("POS No.")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th className="col-highlight">{t("Customer")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Delivery Date")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Ship to")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Qty")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Status")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Priority")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Stock ID")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Remark")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Design")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.values(prioritydata.data).map((result, key) => {
                  return (
                    <React.Fragment key={key}>
                      <tr key={key}>
                        <td className="row-check">
                          <div className="com-check">
                            <input type="checkbox" className='allocationselect allosubtablecheck' id={result._id}
                              disabled={Qty < result?.Qty}
                              on
                            />
                            <label htmlFor={result._id} className="com-check-label" />
                          </div>
                        </td>
                        <td className="col-highlight">{key + 1}</td>
                        <td className="col-highlight"> {result.createdAt}</td>
                        <td className="po-no col-highlight"><Link>{result.pos}</Link></td>
                        <td className="po-no col-highlight">{result.customer_name}</td>
                        <td>{result.delivery_date}</td>
                        <td>{result.location_name}</td>
                        <td>{result.Qty}</td>

                        {result.status.cart_status === 1 ?
                          <td><label className="status-tab gray-tab">{t("Open Order")}</label></td>
                          : result.status.cart_status === 0 ?
                            <td><label className="status-tab gray-tab">{t("Close Order")}</label></td>
                            : ""
                        }

                        {
                          result.status.priority === 0 ?
                            <td><label className="status-tab low-priority">{t("Low")}</label></td>
                            : result.status.priority === 1 ?
                              <td><label className="status-tab medium-priority">{t("Medium")} </label></td>
                              :
                              <td><label className="status-tab high-priority">{t("High")} </label></td>
                        }
                        <td>
                          {result.stock_id = " " ? "-"
                            : <span className='red-highlight'>{result.stock_id}
                            </span>}
                        </td>
                        <td>{result.remark}</td>
                        <td className="text-center">
                          {result?.printURL === "" ? "-"
                            : <div onClick={() => {
                              if (result?.printURL) {
                                window.open(result?.printURL, '_blank', 'noreferrer')
                              }
                            }}><img
                                className="pdf-image"
                                src={base_assets + "images/icons/pdf-icon.png"}
                                alt=""
                              /></div>
                          }
                        </td>
                        <td></td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
        : ''}
    </>
  )
}

$(document).on("click", "#subtable_main", function () {
  var maincheckbox = document.querySelector("#subtable_main");
  var allcheckbox = document.querySelectorAll(".allosubtablecheck");
  if (maincheckbox.checked === true) {
    allcheckbox.forEach(function (checkBox_selectAble) {
      checkBox_selectAble.checked = true;
    });
  } else {
    allcheckbox.forEach(function (checkBox_selectAble) {
      checkBox_selectAble.checked = false;
    });
  }
});


