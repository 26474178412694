import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import { loading, Updateloading, error } from "../common/helpers/toastify";
import Select from "react-select";
import "./location.scss";
import { _Api } from "../api/_call";
import VoucherType from "../setup/quickStoreSetup/VoucherType";
import { GetTexgraph, GetCountrygraph, GetCountryCodegraph } from "../common/Commongraphql"
import Confirmationmodel from "../common/modules/confirmationmodel"
import Zonedetails from "./Zonedetails";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Domainchngemodal from "./Domainchngemodal";
import LocationExistingDomainModal from "./LocationExistingDomainModal";
import LocationShippingMethodModal from "./LocationShippingMethodModal";
import Lodingspinner from '../../components/common/Lodingspinner'
const AdminLocationDetail = () => {
  const { token } = useParams();
  const LOCATIONID = token;
  const navigate = useNavigate()
  const selectRef = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [edit, setEdit] = useState(true)
  const [currencyarr, setCurrencyarr] = useState([])
  const [contactArr, setContactArr] = useState([])
  const [timeZoneOption, setTimeZoneOption] = useState([])
  const [countryarray, setCountryarray] = useState([])
  const [countrynamearr, setCountrynamearr] = useState([])
  const [texgrapharray, setTexgrapharray] = useState([])
  const [profile, setProfile] = useState()
  const [locationid, setLocationid] = useState()
  const [lodingdata, setLodingdata] = useState(false)
  const [domainid, setDomainid] = useState({ "domainids": [], "domainid": "", "domainname": "", "location_id": LOCATIONID, "defaultdomainid": "", deletedomain: "" });
  const [shipingId, setShipping] = useState({ "shippingid": "", "shippingList": [], "location_id": LOCATIONID, "deleteship": "", });
  const date = LOCATIONID ? "" : new Date();
  const [informationdetails, setInformationdetails] = useState({ location: "", type: { label: "", value: "" }, room: "", website: "" })
  const [contact, setContact] = useState({
    Address: "",
    Province: "",
    City: "",
    website: "",
    Zip_Code: "",
    Country: "",
    Email: ""
  })
  const [displaydata, setDisplaydata] = useState({
    Currency: "",
    Amount_Separator: { label: "", value: "" },
    Tax: { label: "", value: "" },
    Date_Format: { label: "", value: "" },
    Time_Zone: { label: "", value: "" },
    tax_id: "",
    branch_id: ""
  })
  const [websiteinfo, setWebsiteinfo] = useState(
    {
      "name": "",
      "metaTitle": "",
      "metaDescription": "",
      "logo": "",
      "favicon": "",
      "theme": "template_one"
    })
  const mobileImage = <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />;
  const phoneImage = <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />;
  const typeOption = [
    {
      value: "Store",
      label: "Store",
      icon: <img src={base_assets + "images/admin/icons/store-icon.png"} alt="" />,
    },
    {
      value: "eCommerce",
      label: "eCommerce",
      icon: <img src={base_assets + "images/admin/icons/shoping-cart-icon.png"} alt="" />,
    },
    {
      value: "Warehouse",
      label: "Warehouse",
      icon: <img src={base_assets + "images/pos/icons/warehouse.png"} alt="" />,
    }
  ]
  const themeOptions = [
    {
      value: "template_one",
      label: "Sward",
    },
    {
      value: "template_two",
      label: "Sparkle",
    },
    {
      value: "template_three",
      label: "Mirage",
    }
  ]
  var typevalue = {};
  if (informationdetails?.type?.value === "Store") {
    typevalue = {
      value: "Store",
      label: "Store",
      icon: <img src={base_assets + "images/admin/icons/store-icon.png"} alt="" />
    }
  } else if (informationdetails?.type?.value === "eCommerce") {
    typevalue = {
      value: "eCommerce",
      label: "eCommerce",
      icon: <img src={base_assets + "images/admin/icons/shoping-cart-icon.png"} alt="" />,
    }
  } else if (informationdetails?.type?.value === "Warehouse") {
    typevalue = {
      value: "Warehouse",
      label: "Warehouse",
      icon: <img src={base_assets + "images/pos/icons/warehouse.png"} alt="" />,
    }
  }

  const phoneOption = [
    {
      value: 1,
      icon: <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />
    },
    {
      value: 2,
      icon: <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />
    }
  ];
  const separatorOption = [
    {
      value: "comma",
      label: ","
    },
    {
      value: "dot",
      label: "."
    }
  ];

  const dateFormatOption = [
    {
      value: "d/m/Y",
      label: "DD/MM/YYYY",
    },
    {
      value: "Y/m/d",
      label: "YYYY/MM/DD",
    },
    {
      value: "m/d/Y",
      label: "MM/DD/YYYY",
    },
    {
      value: "m/Y/d",
      label: "MM/YYYY/DD",
    }
  ];

  const timeschedulearr = [
    {
      "Day": "Monday",
      "check": false,
      "opening_time": "2024-03-14T08:55:20.663Z",
      "formate_opentime": "",
      "closeing_time": date,
      "formate_closetime": ""
    },
    {
      "Day": "Tuesday",
      "check": false,
      "opening_time": date,
      "formate_opentime": "",
      "closeing_time": date,
      "formate_closetime": ""
    },
    {
      "Day": "Wednesday",
      "check": false,
      "opening_time": date,
      "formate_opentime": "",
      "closeing_time": date,
      "formate_closetime": ""
    },
    {
      "Day": "Thursday",
      "check": false,
      "opening_time": date,
      "formate_opentime": "",
      "closeing_time": date,
      "formate_closetime": ""
    },
    {
      "Day": "Friday",
      "check": false,
      "opening_time": date,
      "formate_opentime": "",
      "closeing_time": date,
      "formate_closetime": ""
    },
    {
      "Day": "Saturday",
      "check": false,
      "opening_time": date,
      "formate_opentime": "",
      "closeing_time": date,
      "formate_closetime": ""
    },
    {
      "Day": "Sunday",
      "check": false,
      "opening_time": date,
      "formate_opentime": "",
      "closeing_time": date,
      "formate_closetime": ""
    }
  ]

  const [openingtime, setOpeningtime] = useState(timeschedulearr)
  const timeschedule = (event, key, label, settime) => {
    const timestamp = event;
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`
    let schedule = [...openingtime]
    if (schedule[key].check) {
      if (label === "opening_time") {
        schedule[key].opening_time = event
        schedule[key].formate_opentime = formattedTime ? formattedTime : "09:30"
      } else if (label === "closeing_time") {
        schedule[key].closeing_time = event
        schedule[key].formate_closetime = formattedTime ? formattedTime : "18:30"
      }
    }
    if (label === "checktime") {
      if (event.target.checked) {
        schedule[key].check = true
        schedule[key].opening_time = "2024-03-14T05:00:20.663+01:00"
        schedule[key].formate_opentime = "09:30"
        schedule[key].closeing_time = "2024-03-14T14:00:20.663+01:00"
        schedule[key].formate_closetime = "18:30"
      } else {
        schedule[key].check = false
        schedule[key].opening_time = ""
        schedule[key].formate_opentime = ""
        schedule[key].closeing_time = ""
        schedule[key].formate_closetime = ""
      }
    }
    setOpeningtime(schedule)
  }
  const locationdetails = useCallback(async (location_id) => {
    if (LOCATIONID || location_id) {
      setLodingdata(true)
      try {
        let postdata = {
          "id": LOCATIONID ? LOCATIONID : location_id
        }
        let res = await _Api(postdata, `api/v1/App/location/getLocation`)
        if (res?.code === 200) {
          setLodingdata(false)
          setContactArr(res?.data?.contacts)
          setProfile(res?.data?.location_logo)
          setInformationdetails(existingValues => ({
            ...existingValues,
            location: res?.data?.name,
            type: {
              value: res?.data?.type,
              label: res?.data?.type
            },
            room: res?.data?.room,
            website: res?.data?.website

          }))
          setOpeningtime(res?.data?.schedule)
          setDisplaydata(
            existingValues => ({
              ...existingValues,
              Currency: {
                value: res?.data?.currency,
                label: res?.data?.currency
              },
              Amount_Separator: {
                value: res?.data?.Thousandseparator,
                label: res?.data?.Thousandseparator
              },
              Tax: {
                value: res?.data?.tax,
                label: res?.data?.tax_name
              },
              Date_Format: {
                value: res?.data?.Dateformat,
                label: res?.data?.Dateformat
              },
              Time_Zone: {
                value: res?.data?.TimeZone,
                label: res?.data?.TimeZone
              },
              tax_id: res?.data?.tax_id ? res?.data?.tax_id : "",
              branch_id: res?.data?.branch_id ? res?.data?.branch_id : "",
            })
          )
          setContact(
            existingValues => ({
              ...existingValues,
              Address: res?.data?.address,
              Province: res?.data?.state,
              City: res?.data?.city,
              Zip_Code: res?.data?.zipcode,
              Country: res?.data?.country,
              Email: res?.data?.email

            }))

          setWebsiteinfo(res?.data?.websiteInfo)
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    } else {
      let contactObj = { icon: 1, phoneCode: "", no: '' };
      setContactArr([contactObj])
    }
    // eslint-disable-next-line 
  }, [LOCATIONID])
  const gettimezone = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/location/Timezonelist`)
      if (res?.code === 200) {
        let array = []
        if (res?.data?.length) {
          res?.data.forEach((result, key) => {
            let obj = {
              label: result?.label,
              value: result?.label
            }
            array.push(obj)
          })
        }
        setTimeZoneOption(array)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getcurrencylist = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/organisation/getAll_Currency`)
      let currencyarr = []
      res?.data.forEach((result) => {
        currencyarr.push({
          label: result,
          value: result
        })
      })
      setCurrencyarr(currencyarr)
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (LOCATIONID) {
      setEdit(false)
    }
    gettimezone()
    locationdetails()
    getcurrencylist()
  }, [locationdetails, LOCATIONID])

  const onImageChange = (e, labels) => {
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`);
        if (resfront.code === 200) {
          if (labels === "profile") {
            setProfile(resfront?.data?.images?.url);
          } else if (labels === "logo") {
            setWebsiteinfo(existingValues => ({
              ...existingValues,
              logo: resfront?.data?.images?.url
            }))
          } else if (labels === "favicon") {
            setWebsiteinfo(existingValues => ({
              ...existingValues,
              favicon: resfront?.data?.images?.url
            }))
          }

        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  };
  const locatonedit = async () => {
    if (!informationdetails?.type?.value) {
      error(t("Location Type is required"))
      return;
    }
    if (!contact?.Email) {
      error(t("Email is required"))
      return;
    } else {
      const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
      if (!isEmail(contact?.Email)) {
        error(t("Invalid Email"));
        return;
      }
    }
    let GetloadingID = loading();
    try {
      let postdata = {
        id: LOCATIONID ? LOCATIONID : locationid,
        name: informationdetails?.location ? informationdetails?.location : "",
        type: informationdetails?.type?.value ? informationdetails?.type?.value : "",
        room: informationdetails?.room ? informationdetails?.room : "",
        website: informationdetails?.website ? informationdetails?.website : "",
        address: contact?.Address ? contact?.Address : "",
        state: contact?.Province ? contact?.Province : "",
        city: contact?.City ? contact?.City : "",
        zipcode: contact?.Zip_Code ? contact?.Zip_Code : "",
        country: contact?.Country?.id ? contact?.Country?.id : "",
        email: contact?.Email ? contact?.Email : "",
        currency: displaydata?.Currency?.value ? displaydata?.Currency?.value : "",
        Thousandseparator: displaydata?.Amount_Separator?.label ? displaydata?.Amount_Separator?.label : "",
        tax_id: displaydata?.tax_id ? displaydata?.tax_id : "",
        branch_id: displaydata?.branch_id ? displaydata?.branch_id : "",
        tax: displaydata?.Tax?.value ? displaydata?.Tax?.value : "",
        Dateformat: displaydata?.Date_Format?.value ? displaydata?.Date_Format?.value : "",
        TimeZone: displaydata?.Time_Zone?.value ? displaydata?.Time_Zone?.value : "",
        contacts: contactArr ? contactArr : [],
        schedule: openingtime ? openingtime : [],
        location_logo: profile ? profile : "",
        thumb: profile ? profile : "",
        websiteInfo: websiteinfo,
        domainID: domainid?.domainids ? domainid?.domainids : []
      }
      console.log(postdata, "check location")
      let res = await _Api(postdata, `api/v1/App/location/updateLocation`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t("Location Successfully Update"));
        setEdit(false)
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
        setEdit(true)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const locatoncreate = async () => {
    console.log(websiteinfo, "Here is this website info")
    if (!informationdetails?.type?.value) {
      error(t("Location Type is required"))
      return;
    }
    if (!contact?.Email) {
      error(t("Email is required"))
      return;
    } else {
      const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
      if (!isEmail(contact?.Email)) {
        error(t("Invalid Email"));
        return;
      }
    }
    let GetloadingID = loading();
    try {
      let postdata = {
        name: informationdetails?.location ? informationdetails?.location : "",
        type: informationdetails?.type?.value ? informationdetails?.type?.value : "",
        room: informationdetails?.room ? informationdetails?.room : "",
        website: informationdetails?.website ? informationdetails?.website : "",
        address: contact?.Address ? contact?.Address : "",
        state: contact?.Province ? contact?.Province : "",
        city: contact?.City ? contact?.City : "",
        zipcode: contact?.Zip_Code ? contact?.Zip_Code : "",
        country: contact?.Country?.id ? contact?.Country?.id : "",
        email: contact?.Email ? contact?.Email : "",
        currency: displaydata?.Currency?.value ? displaydata?.Currency?.value : "",
        Thousandseparator: displaydata?.Amount_Separator?.label ? displaydata?.Amount_Separator?.label : "",
        tax_id: displaydata?.tax_id ? displaydata?.tax_id : "",
        branch_id: displaydata?.branch_id ? displaydata?.branch_id : "",
        tax: displaydata?.Tax?.value ? displaydata?.Tax?.value : "",
        Dateformat: displaydata?.Date_Format?.value ? displaydata?.Date_Format?.value : "",
        TimeZone: displaydata?.Time_Zone?.value ? displaydata?.Time_Zone?.value : "",
        contacts: contactArr ? contactArr : [],
        schedule: openingtime ? openingtime : [],
        location_logo: profile ? profile : "",
        thumb: profile ? profile : "",
        websiteInfo: websiteinfo,
        domainID: domainid?.domainids ? domainid?.domainids : []
      }
      console.log(postdata, "posdata here")
      let res = await _Api(postdata, `api/v1/App/location/createLocation`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        setEdit(false)
        locationdetails(res?.data)
        setLocationid(res?.data)
      } else {
        Updateloading(t, GetloadingID, res?.errors, 'error');
        setEdit(true)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const handleRemoveElement = (indexToRemove) => {
    let newValues = [...contactArr];
    let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
    setContactArr(updatedArray);

  };

  const handleSelectChange = (selectedOption, index, code, no) => {
    const newValues = [...contactArr];
    newValues[index] = { icon: selectedOption, phoneCode: code, no: no };
    setContactArr(newValues);
  };

  const addEventList = () => {
    let newValues = [...contactArr];
    newValues.push({ icon: 2, phoneCode: "", no: '' });
    setContactArr(newValues);
  };
  const [domaindata, setDomaindata] = useState([]);
  const [shippingdata, setShippingdata] = useState([])

  const domainlist = async () => {
    try {
      let postdata = {
        "_id": domainid?.domainid ? domainid?.domainid : "",
        "location_id": LOCATIONID ? LOCATIONID : ""
      }
      let res = await _Api(postdata, `api/v1/APP/domain/location/get-list`)
      if (res?.code === 200) {
        setDomaindata(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const domaintypechange = async () => {
    let GetloadingID = loading();
    try {
      let postdata = {
        "id": domainid?.defaultdomainid ? domainid?.defaultdomainid : "",
        "type": 1,
        "location_id": LOCATIONID ? LOCATIONID : ""
      }
      let res = await _Api(postdata, `api/v1/APP/domain/location/type-change`)
      if (res?.code === 200) {
        domainlist()
        Updateloading(t, GetloadingID, (res?.message));
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const deletedomain = async () => {
    let GetloadingID = loading();
    try {
      let postdata = {
        "id": domainid?.deletedomain ? domainid?.deletedomain : "",
        "location_id": LOCATIONID ? LOCATIONID : ""
      }
      let res = await _Api(postdata, `api/v1/APP/domain/location/delete`)
      if (res?.code === 200) {
        domainlist();
        Updateloading(t, GetloadingID, (res?.message));
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }


  // Shipping Method 
  const shippingMethodlist = async () => {
    if (LOCATIONID) {
      try {
        let postdata = {
          "_id": domainid?.domainid ? domainid?.domainid : "",
          "location_id": LOCATIONID ? LOCATIONID : ""
        }
        let res = await _Api(postdata, `api/v1/APP/location/list-shipping`)
        if (res?.code === 200) {
          setShippingdata(res?.data)
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    } else {
      setShippingdata(shipingId?.shippingList)
    }

  }
  useEffect(() => {
    if (informationdetails?.type?.value === "eCommerce") {
      domainlist()
      shippingMethodlist()
    }
    // eslint-disable-next-line 
  }, [domainid?.domainid, shipingId, informationdetails?.type?.value])
  const deleteship = async () => {
    let GetloadingID = loading();
    try {
      let postdata = {
        id: shipingId?.deleteship
      }
      let res = await _Api(postdata, `api/v1/App/location/delete-shipping`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        let update = shipingId?.shippingList.filter(e => e?._id !== shipingId?.deleteship);
        setShipping(prevState => ({
          ...prevState,
          shippingList: update
        }));
        setShippingdata(update)
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              <Link
                className="text-decoration-none lighgraytext"
                to="/app/admin/location">{t("Location")}</Link> <RightArrow /> <span className="">{t("Location Detail")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            {edit ?
              <>
                <button
                  onClick={() => { LOCATIONID || locationid ? setEdit(false) : navigate("/app/admin/location") }}
                  type="button"
                  className="btn-close mx-3 v3-edit-customer-modal-header-btn focus-none ms-auto save p-0 border bg-white"
                  aria-label="Close">
                </button>
                <button
                  type="button"
                  onClick={() => { LOCATIONID || locationid ? locatonedit() : locatoncreate() }}
                  className="v3-edit-customer-modal-header-btn bg-main-green save">
                  <img className="" src={base_assets + "images/icons/white-save.png"} alt="" />
                </button>
              </>
              :
              <>
                <button
                  type="button"
                  onClick={() => { setEdit(true) }}
                  className="v3-edit-customer-modal-header-btn bg-main-green save">
                  <img className="" src={base_assets + "images/admin/icons/pencil-white.png"} alt="" />
                </button>
              </>
            }
          </div>
        </div>
        {edit ?
          <div className="location-detail">
            <div className="location-detail-card bg-white rounded-2 com-card mb-4">
              <div className="row">
                <div className="col-md-4" style={{ maxWidth: "380px" }}>
                  <div className="com-img-choose position-relative">
                    <label htmlFor="location-image" className="cursor-pointer mx-auto d-block rounded-circle">
                      <div className="com-img-choose-hover d-flex align-items-center justify-content-center w-100 h-100 position-absolute top-0" style={{ background: "rgba(0, 0, 0, 0.5)" }}>
                        <img className="cursor-pointer" src={base_assets + "images/icons/add-img-circle.png"} alt="" />
                      </div>
                      <img className="com-img-choose-img w-100" src={profile ? profile : base_assets + "images/blank-img-2.png"} alt="" />
                      <input id="location-image" className="d-none" type="file" onChange={(e) => onImageChange(e, "profile")}
                        accept="image/*" />
                    </label>
                  </div>
                  <label className="v3-edit-customer-modal-left-img-label mt-2 mb-3 text-center d-block">{t("File: PNG, JPG, JPEG")} <br /> {t("Maximum size 200KB")}</label>
                </div>
                <div className="col-md-6 non-edit">
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="v3-input-group-heading mb-4">{t("Information")}</label>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Location")} <sup className="danger-text fs-6 ">*</sup></label>
                        <input type="text" className="v3-input-group-input w-100"
                          value={informationdetails?.location}
                          onChange={(e) => setInformationdetails(existingValues => ({ ...existingValues, location: (e.target.value) }))} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Type")} </label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={typeOption}
                          value={typevalue}
                          onChange={(e) => setInformationdetails(existingValues => ({ ...existingValues, type: (e) }))}
                          classNamePrefix="common-select"
                          getOptionLabel={(e) => (
                            <div className="common-select-icon">{e.icon}
                              <span>{e.label}</span></div>
                          )}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Room, Floor")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          value={informationdetails?.room}
                          onChange={(e) => setInformationdetails(existingValues => ({
                            ...existingValues,
                            room: (e.target.value)
                          }))} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Website")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          value={informationdetails?.website}
                          onChange={(e) => setInformationdetails(existingValues => ({
                            ...existingValues,
                            website: (e.target.value)
                          }))} />
                      </div>
                    </div>
                    {/* website domain code */}

                    {informationdetails?.type?.value === "eCommerce" ?
                      <>
                        <div className="col-md-12">
                          <div className="partition my-4"></div>
                        </div>
                        <div className="col-lg-12">
                          <label className="v3-input-group-heading mb-4">{t("Website Domain Information")}</label>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Website Name")}</label>
                            <input type="text" className="v3-input-group-input w-100"
                              value={websiteinfo?.name}
                              onChange={(e) => setWebsiteinfo(existingValues => ({
                                ...existingValues,
                                name: e.target.value
                              }))}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Meta Title")}</label>
                            <input type="text" className="v3-input-group-input w-100"
                              value={websiteinfo?.metaTitle}
                              onChange={(e) => setWebsiteinfo(existingValues => ({
                                ...existingValues,
                                metaTitle: e.target.value
                              }))}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Meta Description")}</label>
                            <textarea rows="3" type="text" className="v3-input-group-input w-100 h-auto"
                              value={websiteinfo?.metaDescription}
                              onChange={(e) => setWebsiteinfo(existingValues => ({
                                ...existingValues,
                                metaDescription: e.target.value
                              }))}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Theme")} </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={themeOptions}
                              value={websiteinfo?.theme === "" ? themeOptions[0] : { value: websiteinfo?.theme, label: themeOptions.filter((result) => result.value === websiteinfo?.theme)[0]?.label }}
                              onChange={(e) => {
                                setWebsiteinfo(existingValues => ({
                                  ...existingValues,
                                  theme: e.value
                                }))
                              }}
                              classNamePrefix="common-select"
                              isSearchable={true}
                              getOptionLabel={(opt) => opt.label}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label text-center">{t("Logo")}</label>
                            <div className="com-img-choose position-relative">
                              <label htmlFor="logo-image" className="cursor-pointer mx-auto d-block rounded-circle">
                                <div className="com-img-choose-hover d-flex align-items-center justify-content-center w-100 h-100 position-absolute top-0 darkgraybg">
                                  <img className="cursor-pointer" src={base_assets + "images/icons/add-img-circle.png"} alt="" />
                                </div>
                                <img className="com-img-choose-img rounded-5 w-100 object-fit-contain" src={websiteinfo?.logo ? websiteinfo?.logo : base_assets + "images/blank-img-2.png"} alt="" />
                                <input id="logo-image" className="d-none" type="file" onChange={(e) => onImageChange(e, "logo")}
                                  accept="image/*" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label text-center">{t("FavIcon")}</label>
                            <div className="com-img-choose position-relative">
                              <label htmlFor="favicon-image" className="cursor-pointer mx-auto d-block rounded-circle">
                                <div className="com-img-choose-hover d-flex align-items-center justify-content-center w-100 h-100 position-absolute top-0 darkgraybg">
                                  <img className="cursor-pointer" src={base_assets + "images/icons/add-img-circle.png"} alt="" />
                                </div>
                                <img className="com-img-choose-img rounded-5 w-100 object-fit-contain" src={websiteinfo?.favicon ? websiteinfo?.favicon : base_assets + "images/blank-img-2.png"} alt="" />
                                <input id="favicon-image" className="d-none" type="file" onChange={(e) => onImageChange(e, "favicon")}
                                  accept="image/*" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="partition my-4"></div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-between align-items-center h-100">
                          <label className="v3-input-group-heading">{t("Website Domains")}</label>
                          <button className="bg-main-green border-0 text-bg-danger rounded-pill h-75"
                            data-bs-toggle="modal"
                            data-bs-target="#LocationExistingDomainModal">
                            {t("Add Custom Domain")}
                          </button>
                        </div>
                        <div className="col-lg-12">
                          <div className="location-detail-card bg-white rounded-2 com-card mb-4 p-0 mt-4">
                            <table className="location-detail-card-table-separate w-100 com-custom-table-tag position-relative">
                              <thead>
                                <tr>
                                  <th className="fw-semibold" style={{ width: "3%" }}>#</th>
                                  <th className="fw-semibold text-center" style={{ width: "50%" }}>{t("Website Domain")}</th>
                                  <th className="fw-semibold text-center" style={{ width: "50%" }}>{t("Action")}</th>
                                  <th className="fw-semibold" style={{ minWidth: "150px" }}>{t("Domain Status")}</th>
                                  <th className="fw-semibold text-end" style={{ width: "20%" }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  {domaindata?.length ? domaindata.map((result, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td className="text-center" >{result?.name}</td>
                                        <td className="text-center cursor-pointer">
                                          {result?.is_varified === 1 ?
                                            <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Verified")}</span> :
                                            <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold"
                                              onClick={() => setDomainid(prevState => ({
                                                ...prevState,
                                                domainid: result?.id,
                                                domainname: result?.name
                                              }))}>
                                              <span className="inactive-badge-dot me-2"  ></span>{t("Not Verified")}</span>
                                          }
                                        </td>
                                        {result?.primary ?
                                          <td className="cursor-pointer main-green-text">{t("Default Domain")}</td>
                                          :
                                          <td className="cursor-pointer danger-text"
                                            data-bs-toggle="modal"
                                            data-bs-target="#Setdefaultdomain"
                                            onClick={() => {
                                              setDomainid(prevState => ({
                                                ...prevState,
                                                defaultdomainid: result?.id
                                              }));
                                            }}>{t("Make Default Domain")}</td>
                                        }
                                        <td className="text-center">
                                          {result?.is_delete === 1 ? <img
                                            onClick={() => {
                                              setDomainid(prevState => ({
                                                ...prevState,
                                                deletedomain: result?.id
                                              }));
                                              setShipping(prevState => ({
                                                ...prevState,
                                                deleteship: ""
                                              }))
                                            }}
                                            data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                                            className="cursor-pointer"
                                            src={base_assets + "images/admin/icons/Delete.png"} alt="" /> : ""

                                          }
                                        </td>

                                      </tr>
                                    )
                                  }) : ""}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* ShippingMethod Code Start  */}

                        <div className="col-md-12">
                          <div className="partition my-4"></div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-between align-items-center h-100">
                          <label className="v3-input-group-heading">{t("Shipping Method")}</label>
                          <button className="bg-main-green border-0 text-bg-danger rounded-pill h-75"
                            data-bs-toggle="modal"
                            data-bs-target="#LocationShippingMethodModal">
                            {t("Add Shipping Method")}
                          </button>
                        </div>
                        <div className="col-lg-12">
                          <div className="location-detail-card bg-white rounded-2 com-card mb-4 p-0 mt-4">
                            <table className="location-detail-card-table-separate w-100 com-custom-table-tag">
                              <thead>
                                <tr>
                                  <th className="fw-semibold" style={{ width: "3%" }}>#</th>
                                  <th className="fw-semibold" style={{ width: "50%" }}>{t("Name")}</th>
                                  <th className="fw-semibold" style={{ width: "20%" }}>{t("Price")}</th>
                                  <th className="fw-semibold" style={{ minWidth: "250px" }}>{t("Description")}</th>
                                  <th className="fw-semibold text-end" style={{ width: "20%" }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  {shippingdata?.length ? shippingdata.map((result, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td  >{result?.type}</td>
                                        <td  >{result?.price} </td>
                                        <td >{result?.description}</td>
                                        <td className="text-center"
                                          onClick={() => {
                                            setDomainid(prevState => ({
                                              ...prevState,
                                              deletedomain: ""
                                            }));
                                            setShipping(prevState => ({
                                              ...prevState,
                                              deleteship: result?._id
                                            }))
                                          }}
                                          data-bs-toggle="modal" data-bs-target="#createPurchaseModal">
                                          <img
                                            className="cursor-pointer"
                                            src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                                        </td>
                                      </tr>
                                    )
                                  }) : ""}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </> : ""}




                    {/* content code */}
                    <div className="col-md-12">
                      <div className="partition my-4"></div>
                    </div>
                    <div className="col-lg-12">
                      <label className="v3-input-group-heading mb-4">{t("Contact")}</label>
                    </div>
                    <div className="col-md-12">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Address")}</label>
                        <textarea rows="3" type="text" className="v3-input-group-input w-100 h-auto"
                          value={contact?.Address}
                          onChange={(e) => setContact(existingValues => ({
                            ...existingValues,
                            Address: (e.target.value)
                          }))} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Province/State")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          value={contact?.Province}
                          onChange={(e) => setContact(existingValues => ({
                            ...existingValues,
                            Province: (e.target.value)
                          }))} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("City")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          value={contact?.City}
                          onChange={(e) => setContact(existingValues => ({
                            ...existingValues,
                            City: (e.target.value)
                          }))} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Zip Code")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          value={contact?.Zip_Code}
                          onChange={(e) => setContact(existingValues => ({
                            ...existingValues,
                            Zip_Code: (e.target.value)
                          }))} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Country")} </label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={countrynamearr}
                          value={{ id: contact?.Country?.id, name: contact?.Country?.name }}
                          onChange={(e) => {
                            setContact(existingValues => ({ ...existingValues, Country: (e) }));
                            handleSelectChange(
                              contactArr[0]?.icon,
                              0,
                              e.phoneCode,
                              contactArr[0]?.no
                            )
                          }}
                          classNamePrefix="common-select"
                          isSearchable={true}
                          getOptionLabel={(opt) => opt.name}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Email")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          value={contact?.Email}
                          onChange={(e) => setContact(existingValues => ({ ...existingValues, Email: (e.target.value) }))} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="v3-input-group mb-0">
                        <label className="v3-input-group-label">{t("Phone")}</label>
                      </div>
                      {contactArr?.length ? contactArr?.map((value, index) => {
                        return (
                          <div className="phone-select-group mb-3" key={index}>

                            <div className="v3-input-group phone-select-group-icon mb-0">
                              <Select
                                ref={selectRef}
                                className="select-set select w-100 p-0"
                                placeholder=""
                                options={phoneOption}
                                value={{ value: value?.icon, icon: value?.icon === 1 ? mobileImage : phoneImage }}
                                onChange={(e) =>
                                  handleSelectChange(
                                    e.value ? e.value : value?.icon,
                                    index,
                                    value?.phoneCode,
                                    value?.no
                                  )}
                                classNamePrefix="common-select"
                                isSearchable={false}
                                getOptionLabel={(e) => (
                                  <div className="common-select-icon">{e.icon}</div>
                                )}
                              />
                            </div>
                            <div className="d-flex align-items-center v3-input-group phone-select-group-input-grp mb-0">
                              <Select
                                className="select-set select p-0 flex-shrink-0"
                                placeholder=""
                                options={countryarray}
                                value={{ value: value?.phoneCode, label: value?.phoneCode }}
                                onChange={(e) =>
                                  handleSelectChange(
                                    value?.icon,
                                    index,
                                    e.value ? e.value : value?.phoneCode,
                                    value?.no
                                  )}
                                classNamePrefix="common-select"
                                isSearchable={true}
                                getOptionLabel={(opt) => opt.label}
                                menuPlacement="auto"
                              />
                              <input type="number" className="form-control border-start-0 v3-input-group-input"
                                value={value?.no}
                                onChange={(e) => handleSelectChange(
                                  value?.icon,
                                  index,
                                  value?.phoneCode,
                                  e.target.value ? e.target.value : index?.no
                                )}
                              />
                            </div>
                            {index !== 0 ?
                              <button className="border-0 bg-transparent p-0"
                                onClick={() => handleRemoveElement(index)}><img className="=" src={base_assets + "images/icons/minus-detail.png"} alt="" /></button>
                              : ""}
                          </div>
                        )
                      }) : ""}
                    </div>
                    <div className="col-md-12 mt-3">
                      <button className="border-0 bg-transparent p-0 main-green-text"
                        onClick={() => addEventList()}>
                        <img src={base_assets + "images/icons/add-detail.png"} alt="" />
                        <span className="ms-2">{t("Add Phone")}</span>
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div className="partition my-4"></div>
                    </div>
                    <div className="col-lg-12">
                      <label className="v3-input-group-heading mb-4">{t("Opening Time")}</label>
                    </div>
                    {openingtime?.length ? openingtime.map((result, key) => {
                      return (
                        <div className="col-lg-12 mb-2" key={key}>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="form-check form-switch ps-0 text-nowrap align-items-center d-flex">
                                <input className="form-check-input float-none ms-0 me-2 cursor-pointer mb-0" type="checkbox"
                                  onChange={(e) => timeschedule(e, key, "checktime", "check")}
                                  checked={result?.check} />
                                <label className="form-check-label">{result?.Day ? t(result?.Day) : ""}</label>
                              </div>
                            </div>
                            {result?.check ?
                              <div className="col-md-7 d-flex gap-2 align-items-center v3-input-group mb-0">
                                <DatePicker
                                  selected={result?.opening_time ? new Date(result?.opening_time) : new Date()}
                                  onChange={(e) => timeschedule(e, key, "opening_time", "gettime")}
                                  shouldCloseOnSelect={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={30}
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  className="v3-input-group-input w-100"
                                  autoComplete='none'
                                />
                                -
                                <div className="dropdown custom-timepicker-dropdown">
                                  <DatePicker
                                    selected={result?.closeing_time ? new Date(result?.closeing_time) : new Date()}
                                    onChange={(e) => timeschedule(e, key, "closeing_time", "gettime")}
                                    shouldCloseOnSelect={false}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    className="v3-input-group-input w-100"
                                    autoComplete='none'
                                  />
                                </div>
                              </div> : ""}

                          </div>
                        </div>
                      )
                    }) : ""}
                    <div className="col-md-12">
                      <div className="partition my-4"></div>
                    </div>
                    <div className="col-lg-12">
                      <label className="v3-input-group-heading mb-4">{t("Display")}</label>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Branch Id")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          onChange={(e) => setDisplaydata(existingValues => ({ ...existingValues, branch_id: e.target.value }))}
                          value={displaydata?.branch_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Tax Id")}</label>
                        <input type="text" className="v3-input-group-input w-100"
                          onChange={(e) => setDisplaydata(existingValues => ({ ...existingValues, tax_id: e.target.value }))}
                          value={displaydata?.tax_id}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Currency")} <sup className="danger-text fs-6 ">*</sup></label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={currencyarr}
                          value={displaydata?.Currency}
                          onChange={(e) => setDisplaydata(existingValues => ({ ...existingValues, Currency: (e) }))}
                          classNamePrefix="common-select"
                          getOptionLabel={(e) => (
                            <div className="common-select-icon">{e.label}</div>
                          )}
                          isSearchable={false}
                          menuPlacement="auto"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Amount Separator")} <sup className="danger-text fs-6 ">*</sup></label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={separatorOption}
                          value={displaydata?.Amount_Separator}
                          onChange={(e) => setDisplaydata(existingValues => ({ ...existingValues, Amount_Separator: (e) }))}
                          classNamePrefix="common-select"
                          isSearchable={false}
                          menuPlacement="auto"
                        />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Tax")} <sup className="danger-text fs-6 ">*</sup></label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={texgrapharray}
                          value={displaydata?.Tax}
                          onChange={(e) => setDisplaydata(existingValues => ({ ...existingValues, Tax: (e) }))}
                          classNamePrefix="common-select"
                          isSearchable={false}
                          menuPlacement="auto"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Date Format")} <sup className="danger-text fs-6 ">*</sup></label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={dateFormatOption}
                          value={displaydata?.Date_Format}
                          onChange={(e) => setDisplaydata(existingValues => ({ ...existingValues, Date_Format: (e) }))}
                          classNamePrefix="common-select"
                          isSearchable={false}
                          menuPlacement="auto"
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Time Zone")} <sup className="danger-text fs-6 ">*</sup></label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={timeZoneOption}
                          value={displaydata?.Time_Zone}
                          onChange={(e) => setDisplaydata(existingValues => ({ ...existingValues, Time_Zone: (e) }))}
                          classNamePrefix="common-select"
                          isSearchable={true}
                          menuPlacement="auto"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          lodingdata ?
            <Lodingspinner /> :
            <div className="location-detail">
              <div className="location-detail-card bg-white rounded-2 com-card mb-4">
                <div className="row">
                  <div className="col-md-4" style={{ maxWidth: "380px" }}>
                    <div className="com-img-choose">
                      <img className="com-img-choose-img w-100 object-fit-cover" src={profile ? profile : base_assets + "images/blank-img-2.png"} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6 non-edit">
                    <label className="v3-input-group-heading mb-4">{t("Information")}</label>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Location")}</label>
                        <span className="non-edit-input">{informationdetails?.location}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Type")}</label>
                        <span className="non-edit-input non-edit-input-with-img"> {typevalue?.icon}{informationdetails?.type?.label}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Room, Floor")}</label>
                        <span className="non-edit-input"> {informationdetails?.room}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Website")}</label>
                        <span className="non-edit-input"> {informationdetails?.website}</span>
                      </div>
                    </div>
                    {informationdetails?.type?.label === "eCommerce" ?
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="partition my-4"></div>
                          </div>
                          <div className="col-md-7 mt-3 mb-4">
                            <label className="v3-input-group-heading mb-4">{t("Website Domain Information")}</label>
                            <label className="non-edit-label">{t("Website Name")}</label>
                            <span className="non-edit-input">{websiteinfo?.name}</span>
                          </div>
                          <div className="col-md-12 mb-4">
                            <label className="non-edit-label">{t("Meta Title")}</label>
                            <span className="non-edit-input">{websiteinfo?.metaTitle}</span>
                          </div>
                          <div className="col-md-12 mb-4">
                            <label className="non-edit-label">{t("Meta Description")}</label>
                            <span className="non-edit-input">{websiteinfo?.metaDescription}</span>
                          </div>
                          <div className="col-md-12 mb-4">
                            <label className="non-edit-label">{t("Theme")}</label>
                            <span className="non-edit-input">{themeOptions.filter((result) => result.value === websiteinfo?.theme)[0]?.label}</span>
                          </div>
                          <div className="col-md-12 mb-4 d-flex gap-5 justify-content-evenly">
                            <div className="col-md-6" style={{ maxWidth: "110px" }}>
                              <div className="com-img-choose">
                                <label className="non-edit-label text-center ">{t("Logo")}</label>
                                <img className="com-img-choose-img w-100 object-fit-contain" src={websiteinfo?.logo ? websiteinfo?.logo : base_assets + "images/blank-img-2.png"} alt="" />
                              </div>
                            </div>
                            <div className="col-md-6" style={{ maxWidth: "110px" }}>
                              <div className="com-img-choose">
                                <label className="non-edit-label text-center">{t("FavIcon")}</label>
                                <img className="com-img-choose-img w-100 object-fit-contain" src={websiteinfo?.favicon ? websiteinfo?.favicon : base_assets + "images/blank-img-2.png"} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="partition my-4"></div>
                          </div>
                          <div className="col-lg-12 d-flex justify-content-between align-items-center h-100">
                            <label className="v3-input-group-heading">{t("Website Domains")}</label>
                          </div>
                          <div className="col-lg-12">
                            <div className="location-detail-card bg-white rounded-2 com-card mb-4 p-0 mt-4">
                              <table className="location-detail-card-table-separate w-100 com-custom-table-tag">
                                <thead>
                                  <tr>
                                    <th className="fw-semibold" style={{ width: "3%" }}>#</th>
                                    <th className="fw-semibold text-center" style={{ width: "50%" }}>{t("Website Domain")}</th>
                                    <th className="fw-semibold text-center" style={{ width: "50%" }}>{t("Action")}</th>
                                    <th className="fw-semibold" style={{ minWidth: "150px" }}>{t("Domain Status")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {domaindata?.length ? domaindata.map((result, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td className="text-center" >{result?.name}</td>
                                          <td className="text-center cursor-pointer">
                                            {result?.is_varified === 1 ?
                                              <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Verified")}</span> :
                                              <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold">
                                                <span className="inactive-badge-dot me-2"></span>{t("Not Verified")}</span>
                                            }
                                          </td>
                                          {result?.primary ?
                                            <td className="cursor-pointer main-green-text">{t("Default Domain")}</td>
                                            :
                                            <td className="cursor-pointer danger-text"
                                              onClick={() => {
                                                setDomainid(prevState => ({
                                                  ...prevState,
                                                  defaultdomainid: result?.id
                                                }));
                                              }}>{t("Make Default Domain")}</td>
                                          }
                                        </tr>
                                      )
                                    }) : ""}
                                  </>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div >

                        {/* Shipping Method View Start  */}

                        <div className="row">
                          <div className="col-md-12">
                            <div className="partition my-4"></div>
                          </div>
                          <div className="col-lg-12 d-flex justify-content-between align-items-center h-100">
                            <label className="v3-input-group-heading">{t("Shipping Method")}</label>
                          </div>
                          <div className="col-lg-12">
                            <div className="location-detail-card bg-white rounded-2 com-card mb-4 p-0 mt-4">
                              <table className="location-detail-card-table-separate w-100 com-custom-table-tag">
                                <thead>
                                  <tr>
                                    <th className="fw-semibold" style={{ width: "3%" }}>#</th>
                                    <th className="fw-semibold" style={{ width: "50%" }}>{t("Name")}</th>
                                    <th className="fw-semibold" style={{ width: "20%" }}>{t("Price")}</th>
                                    <th className="fw-semibold" style={{ minWidth: "250px" }}>{t("Description")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {shippingdata?.length ? shippingdata.map((result, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td  >{result?.type}</td>
                                          <td  >{result?.price} </td>
                                          <td >{result?.description}</td>
                                        </tr>
                                      )
                                    }) : ""}
                                  </>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div >

                      </> :
                      ""
                    }

                    <div className="row">
                      <div className="col-md-12">
                        <div className="partition my-4"></div>
                      </div>
                      <div className="col-md-7 mt-3 mb-4">
                        <label className="v3-input-group-heading mb-4">{t("Contact")}</label>
                        <label className="non-edit-label">{t("Address")}</label>
                        <span className="non-edit-input">{contact?.Address}</span>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="non-edit-label">{t("Email")}</label>
                        <span className="non-edit-input">{contact?.Email}</span>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="non-edit-label">{t("Phone")}</label>
                        {contactArr?.length ? contactArr.map((result, key) => {
                          return (
                            <span className="non-edit-input non-edit-input-with-img d-block mb-2" key={key}>
                              <img src={result?.icon === 1 ? base_assets + "images/icons/phone-icon-1.png" :
                                base_assets + "images/icons/phone-icon-2.png"} alt="" />
                              {result?.phoneCode} {result?.no}</span>
                          )
                        }) : ""}

                      </div>
                      <div className="col-md-12">
                        <div className="partition my-4"></div>
                      </div>
                      <div className="col-md-12 mt-3 mb-3">
                        <label className="v3-input-group-heading mb-4">{t("Opening Time")}</label>
                        <div className="row">
                          <div className="col-md-12">
                            {openingtime?.length ? openingtime.map((result, key) => {
                              return (
                                <div className="row mb-2 " key={key}>
                                  <div className="col-md-3">
                                    <span className="non-edit-input">{t(result?.Day)}</span>
                                  </div>
                                  <div className="col-md-7">
                                    <span className="non-edit-input">
                                      {result?.formate_opentime} - {result?.formate_closetime}</span>
                                  </div>
                                </div>
                              )
                            }) : ""}

                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="partition my-4"></div>
                      </div>
                      <div className="col-md-12 mb-4 mt-3">
                        <label className="v3-input-group-heading">{t("Display")}</label>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Branch Id")}</label>
                        <span className="non-edit-input">{displaydata?.branch_id ? displaydata?.branch_id : "-"}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Tax Id")}</label>
                        <span className="non-edit-input">{displaydata?.tax_id ? displaydata?.tax_id : "-"}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Currency")}</label>
                        <span className="non-edit-input non-edit-input-with-img"> {displaydata?.Currency?.label}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Amount Separator")}</label>
                        <span className="non-edit-input">{displaydata?.Amount_Separator?.label}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Tax")}</label>
                        <span className="non-edit-input">{displaydata?.Tax?.label}</span>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="non-edit-label">{t("Time Zone")}</label>
                        <span className="non-edit-input">{displaydata?.Time_Zone?.label}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        }
        <div
          className="modal fade common-modal"
          id="Setdefaultdomain"
          tabIndex={-1}
          aria-labelledby="SetdefaultdomainLabel"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img className="common-modal-exclamation-img"
                  src={base_assets + "images/icons/exclamation-mark-green.png"}
                  alt=""
                />
                <p className="modal-content-text">
                  {t("Are you sure you want to set default domain")}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn modal-content-no"
                  data-bs-dismiss="modal"
                >
                  {t("No")}
                </button>
                <button type="button" data-bs-dismiss="modal" className="btn modal-content-yes"
                  onClick={() => domaintypechange()}>
                  {t("yes")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={domainid?.deletedomain ? deletedomain : deleteship} ></Confirmationmodel>
        <Domainchngemodal />
        <LocationExistingDomainModal setDomainid={setDomainid} domainid={domainid} />
        <LocationShippingMethodModal setShipping={setShipping} shipingId={shipingId} />

        {LOCATIONID ?
          <>
            <VoucherType
              locationid={LOCATIONID}
              locationkey={true} />
            <Zonedetails
              locationid={LOCATIONID} />
          </>
          : ""}
        <GetTexgraph setTexgrapharray={setTexgrapharray} />
        <GetCountryCodegraph setCountryarray={setCountryarray} />
        <GetCountrygraph setCountrynamearr={setCountrynamearr} />
      </div>
    </>
  )
}

export default AdminLocationDetail;