import React from 'react'
import { useTranslation } from "react-i18next";


const Spinner = ({themed}) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="d-flex justify-content-center align-items-center ">
                <div className={`spinner-border ${ themed ? 'themecolor' : 'spinnercolor'}`} role="status">
                    <span className="sr-only">{t("Loading")}...</span>
                </div>
            </div>
        </div>
    )
}

export default Spinner