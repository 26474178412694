import React, { useEffect, useState, useRef, useCallback } from "react";
import { graphqlPost } from "../api/_call";
import SelectSearch from "react-select-search";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { updateSaleperson } from '../../../source/app/appSlice';
import { useTranslation } from 'react-i18next';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
export const LocationList = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const { setcommanSelected_loc, locationid, addclass, hidelocationid } = props;
  const [LocationList, setLocationList] = useState();
  const [LocationarrayList, setLocationarrayList] = useState([]);
  const getLocationList = useCallback(async () => {
    try {
      const postdata = `{
            locations {
                id
                name 
            }
        }`;
      let responce = await graphqlPost(postdata);
      let locationarray = [];
      if (hidelocationid) {
        if (responce.data?.locations) {
          responce.data?.locations.filter(item => item?.id !== hidelocationid).forEach((result) => {
            locationarray.push(
              {
                value: result?.id,
                label: result?.name

              }
            );
          })
        }
      } else if (responce.data?.locations) {
        responce.data?.locations?.forEach((result) => {
          locationarray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      }
      setLocationarrayList(locationarray);
      if (locationid) {
        const selected = locationarray.filter(option => option.value === locationid);
        setLocationList({ label: selected[0].label, value: locationid });
        setcommanSelected_loc(locationid);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [locationid, hidelocationid, setLocationarrayList, setLocationList, setcommanSelected_loc]);
  useEffect(() => {
    if (locationid) {
      getLocationList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getLocationList();
    }
  }, [getLocationList, locationid]);
  return (
    <React.Fragment>
      <div className={addclass ? "placeholder-up-input row-inactive" : "placeholder-up-input"} >
        <Select
          className=" select-set select  p-0"
          placeholder={t("Ship to")}
          options={LocationarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={LocationList}
          onChange={(e) => { setLocationList(e); setcommanSelected_loc(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};

export const SupplierList = (props) => {
  const [SupplierList, setSupplierList] = useState();
  const dataFetched = useRef();
  const [SupplierListarray, setSupplierListarray] = useState([]);
  const { setcommanSelected_sup, supplierid, addclass } = props;
  const getSupplierList = useCallback(async () => {
    try {
      const postdata = `{
            suppliers {
                id
                name 
                organization
            }
        }`;
      let responce = await graphqlPost(postdata);
      let supplierarray = [];
      if (responce.data?.suppliers) {
        responce.data?.suppliers?.forEach((result) => {
          supplierarray.push(
            {
              value: result?.id,
              label: result?.organization
            }
          );
        })
      }
      setSupplierListarray(supplierarray);
      if (supplierid) {
        const selected = supplierarray.filter(option => option.value === supplierid);
        setSupplierList({ label: selected[0].label, value: supplierid });
        setcommanSelected_sup(supplierid);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [supplierid, setSupplierListarray, setcommanSelected_sup]);
  useEffect(() => {
    if (supplierid) {
      getSupplierList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getSupplierList();
    }
  }, [getSupplierList, supplierid]);

  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className={addclass ? "placeholder-up-input row-inactive" : "placeholder-up-input"}>
        <Select
          className=" select-set select  p-0"
          placeholder={t("Supplier")}
          options={SupplierListarray}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={SupplierList}
          onChange={(e) => { setSupplierList(e); setcommanSelected_sup(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};

export const VoucherList = (props) => {
  const { setcommanSelected_vouc, group, voucherid, addclass } = props;
  const dataFetched = useRef();
  const [VoucherList, setVoucherList] = useState([]);
  const [voucher_id, setVoucher_id] = useState(voucherid);
  const [voucher_name, setVoucher_name] = useState();
  const getVoucherList = useCallback(async () => {
    try {
      const postdata = `{
        vouchers(group: "`+ group + `") {
          id
          name 
      }
        }`;
      let responce = await graphqlPost(postdata);
      var data_arr = [];
      responce.data.vouchers.forEach((result) => {
        var id = responce.data.vouchers[0].id ? responce.data.vouchers[0].id : '';
        if (id) {
          setcommanSelected_vouc(id);
        }
        setVoucher_name(responce.data.vouchers[0].name)
        data_arr.push({ 'name': result.name, 'value': result.id });
      })
      setVoucherList(data_arr);
    } catch (err) {
      console.log(err, "err");
    }
  }, [setcommanSelected_vouc, group]);
  const selectVoucher = (input) => {
    setcommanSelected_vouc(input);
  }
  useEffect(() => {
    if (voucherid) {
      getVoucherList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getVoucherList();
    }
  }, [getVoucherList, voucherid]);
  useEffect(() => {
    setVoucher_id(voucherid);
  }, [voucherid]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectSearch
        className={addclass ? "dmc-switch  dark-style row-inactive" : "dmc-switch  dark-style"}
        options={VoucherList}
        onChange={selectVoucher}
        value={voucher_id}
        name="language"
        placeholder={voucher_name ? voucher_name : t('no voucher!!')}
      />

    </React.Fragment>
  );
};
export const Saleperson = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const dispatch = useDispatch();
  const saleperson = useSelector((state) => state.app.saleperson);
  const itemdaata = localStorage.getItem("salesdetails")
  const parsedObject = JSON.parse(itemdaata);
  const [SalesarrayList, setSalesarrayList] = useState([]);
  const getSalesList = useCallback(async () => {
    try {
      const postdata = `{
          salespersons {
              id
              name 
          }
        }`;

      let responce = await graphqlPost(postdata);
      let Salesarray = [];
      if (responce.data?.salespersons) {
        responce.data?.salespersons?.forEach((result) => {
          Salesarray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      }
      setSalesarrayList(Salesarray);
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line 
  }, [setSalesarrayList, saleperson?.value]);
  const reduxsaleperson = (e) => {
    let jsondata = JSON.stringify(e);
    localStorage.setItem('salesdetails', jsondata);
    dispatch(updateSaleperson(e));
  }
  useEffect(() => {
    if (parsedObject?.value) {
      dispatch(updateSaleperson(parsedObject));
      let jsondata = JSON.stringify(parsedObject);
      localStorage.setItem('salesdetails', jsondata);
    }
    // eslint-disable-next-line 
  }, []);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getSalesList();
  }, [getSalesList]);

  return (
    <React.Fragment>
      <Select
        className=" select-set select  p-0"
        placeholder={t("Select Sale Person")}
        options={SalesarrayList}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        value={parsedObject}
        onChange={(e) => { reduxsaleperson(e) }}
        classNamePrefix="common-select"
        getOptionLabel={(opt) => opt.label}
        isSearchable={false}
      />

    </React.Fragment>
  );

};


export const WarehouseList = (props) => {
  const { setcommanSelected_warehouse, warehouseid } = props;
  const dataFetched = useRef();
  const [warehouseList, setwarehouseList] = useState();
  const [warehousearrayList, setwarehousearrayList] = useState([]);
  const getLocationList = useCallback(async () => {
    try {
      const postdata = `{
            locations (type: "Warehouse") {
                id
                name 
            }
        }`;
      let responce = await graphqlPost(postdata);
      let warehousearray = [];
      if (responce.data?.locations) {
        responce.data?.locations?.forEach((result) => {
          warehousearray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      }
      setwarehousearrayList(warehousearray);
      if (warehouseid) {
        const selected = warehousearray.filter(option => option.value === warehouseid);
        setwarehouseList({ label: selected[0].label, value: warehouseid });
        setcommanSelected_warehouse(warehouseid);
      }

    } catch (err) {
      console.log(err, "err");
    }
  }, [setwarehouseList, setcommanSelected_warehouse, warehouseid]);
  useEffect(() => {
    if (warehouseid) {
      getLocationList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getLocationList();
    }
  }, [warehouseid, getLocationList]);

  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className="placeholder-up-input">
        <Select
          className=" select-set select  p-0"
          placeholder={t("Warehouse")}
          options={warehousearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={warehouseList}
          onChange={(e) => { setwarehouseList(e); setcommanSelected_warehouse(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};

export const StoresList = (props) => {
  const { setcommanSelected_warehouse, warehouseid } = props;
  const dataFetched = useRef();
  const [warehouseList, setwarehouseList] = useState();
  const [warehousearrayList, setwarehousearrayList] = useState([]);

  const getLocationList = useCallback(async () => {
    try {
      const postdata = `{
            locations (type: "Store") {
                id
                name 
            }
        }`;
      let responce = await graphqlPost(postdata);
      let warehousearray = [];
      if (responce.data?.locations) {
        responce.data?.locations?.forEach((result) => {
          warehousearray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      }
      setwarehousearrayList(warehousearray);
      if (warehouseid) {
        const selected = warehousearray.filter(option => option.value === warehouseid);
        setwarehouseList({ label: selected[0].label, value: warehouseid });
        setcommanSelected_warehouse(warehouseid);
      }

    } catch (err) {
      console.log(err, "err");
    }
  }, [setwarehouseList, setcommanSelected_warehouse, warehouseid]);


  useEffect(() => {
    if (warehouseid) {
      getLocationList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getLocationList();
    }
  }, [warehouseid, getLocationList]);

  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className="placeholder-up-input">
        <Select
          className=" select-set select  p-0"
          placeholder={t("Locations")}
          options={warehousearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={warehouseList}
          onChange={(e) => { setwarehouseList(e); setcommanSelected_warehouse(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};
