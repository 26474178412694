export const po_status_class = (status_key) => {
  let status_class = ""
  if (status_key === 2) {
    status_class = "status-pending";
  } else if (status_key === 1) {
 status_class = "status-completed";
  } else if (status_key === 3) {
    status_class = "status-approved";
  } else {
   status_class = "status-canceled";
  }
  return status_class;
};
export const status_transfer_class = (status_key) => {
  let status_class = ""
  if (status_key === 2) {
    status_class = "status-pending";
  } else if (status_key === 3) {
    status_class = "status-transit";
  } else if (status_key === 1) {
    status_class = "status-approved";
  } else if (status_key === 4 || status_key === 5) {
    status_class = "status-completed";
  }
  else {
    status_class = "status-canceled";
  }
  return status_class;
};
export const status_receive_class = (status_key) => {
  let status_class = ""
  if (status_key === 4) {
    status_class = "status-received";
  } else if (status_key === 3) {
    status_class = "status-transit";
  } else if (status_key === 5) {
    status_class = "status-canceled";
  }
  return status_class;
};
export const status_priority = (priority_key) => {
  let status_class = ""
  if (priority_key === 0) {
    status_class = "low-priority";
  } else if (priority_key === 1) {
    status_class = "medium-priority";
  } else {
    status_class = "high-priority";
  }
  return status_class;
};
export const purchase_status = (status_key) => {
  let status_class = ""
  if (status_key === 2) {
    status_class = "status-pending";
  } else if (status_key === 1) {
    status_class = "status-approved";
  } else {
    status_class = "status-canceled";
  }
  return status_class;
};

export const customOrder_status = (status_key) => {
  let status_class = ""
  status_class = status_key === 1 || status_key === 0 ?
     "open-order-bg"
     :
     status_key === 2 ?
      "production-col"
      //  <label className='status-tab production-col'>{t("Production")}</label>
       :
       status_key === 3 ?
       "mgf-stock-bg"
        //  <label className='status-tab mgf-stock-bg'>{t("MFG Stock")}</label>
         :
         status_key === 4 ?
         "transit-bg-col"
          //  <label className='status-tab transit-bg-col'>{t("transit")} </label>
           :
           status_key === 5 ?
            "stoke-bg"
            //  <label className='status-tab stoke-bg'>{t("Stock")}</label>
             :
             status_key === 6 ?
             "sol-out-col"
              //  <label className='status-tab sol-out-col'>{t("Sold Out")} </label>
               :
               status_key === 9 ?
               "text-danger"
                //  <label className='status-tab gray-tab'>{t("Close Order")}</label>
                 : ''

                 return status_class;
}
