import { _Api } from 'Ecommerce/common/api';
import React, { useEffect, useState } from 'react';
import './locationpagestyle.scss';
import LeftArrow from 'Ecommerce/common/icons/LeftArrow';
import { useTranslation } from 'react-i18next';
import LeftIcon from 'admin/common/icons/sidebar/LeftIcon';
import CrossIcon from 'admin/common/icons/CrossIcon';
import { useNavigate } from 'react-router-dom';

export default function LocationPage() {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [locationData, setLocationData] = useState([]);
    const [activeData, setActiveData] = useState();

    useEffect(() => {
      getlocationdata();
    }, [])
    

    const getlocationdata = async () => {  
        try {
            let postdata = {}
            let res = await _Api(postdata, `redis/shipingMathod`)
            if (res?.statusCode === 200) {
                if(res?.StorePickup?.length){
                    let NewLocation = [];
                    console.log(res?.StorePickup, "Check new location");
                    setLocationData(res?.StorePickup);
                }
                
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
  return (
    <div className='ecommerce-main-layout-bodyContent locationpage'>
        <div className='locationpage-header'>
               <span className='cursor-pointer' onClick={()=>navigate("/")}><LeftArrow/></span>  <span className='cursor-pointer' onClick={()=>navigate("/")}>{t("Back to Home")}</span>
        </div>
        <div className='locationpage-title'>
                <div className='locationpage-title-head'>
                    <span className='responsive cursor-pointer' onClick={()=>navigate("/")}><LeftIcon/></span>
                    <span>
                        <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.0001 3.66634H2.33341V0.333008H29.0001V3.66634ZM20.7334 13.6663H4.06675L5.06675 8.66634H26.2667L26.9334 11.9997C28.1667 12.0663 29.3501 12.3163 30.4834 12.7663L29.0001 5.33301H2.33341L0.666748 13.6663V16.9997H2.33341V26.9997H16.5001C15.9501 25.6663 15.6667 24.2497 15.6667 22.833V23.6663H5.66675V16.9997H15.6667V22.833C15.6667 20.0663 16.7334 17.283 18.8334 15.1663C19.4334 14.583 20.0667 14.083 20.7334 13.6663ZM37.9834 31.9997L35.6667 34.3163L30.4667 29.1997C29.3167 29.9163 27.9501 30.333 26.5001 30.333C22.3334 30.333 19.0001 26.9997 19.0001 22.833C19.0001 18.6663 22.3334 15.333 26.5001 15.333C30.6667 15.333 34.0001 18.6663 34.0001 22.833C34.0001 24.2997 33.5834 25.683 32.8501 26.833L37.9834 31.9997ZM30.6667 22.833C30.6667 21.7279 30.2278 20.6681 29.4464 19.8867C28.665 19.1053 27.6052 18.6663 26.5001 18.6663C25.395 18.6663 24.3352 19.1053 23.5538 19.8867C22.7724 20.6681 22.3334 21.7279 22.3334 22.833C22.3334 23.9381 22.7724 24.9979 23.5538 25.7793C24.3352 26.5607 25.395 26.9997 26.5001 26.9997C27.6052 26.9997 28.665 26.5607 29.4464 25.7793C30.2278 24.9979 30.6667 23.9381 30.6667 22.833Z" fill="#4F4A3E"/>
                        </svg>
                    </span>
                    <span>{t("Store Location")}</span></div>
                <div className='locationpage-title-sub'>{t("Information about store.")}</div>
        </div>
        <div className='locationpage-content'>
            { locationData.length ? 
            <div className='locationpage-content-list'>
                { locationData.map((locationdata, index)=>{
                    return(
                        <div className='locationpage-content-list-item' key={index}>
                            <div className='locationpage-content-list-item-image'><img src={locationdata?.Location_image} alt={locationdata?.name}/></div>
                            <div className='locationpage-content-list-item-data'>
                                <div className='storetitle'>
                                    <span className='bold'>{locationdata?.name}</span>
                                    <span className='light'>{locationdata?.store_Address}</span>
                                </div>
                                <div className='contact'><span className='bold'>{t("Phone number")}:</span> <span className='light'> {locationdata?.phone}</span></div>
                                <div className='bold'>{t("Store Hours")}</div>
                                <div className='storehours'>
                                    {locationdata?.store_timeSchedule ?
                                    
                                        Object.entries(locationdata?.store_timeSchedule).map( (item,i) => {
                                          return(
                                            <div className='storehours-inner'><div className='light'>{item[0]}</div><div className='light'>{item[1]}</div></div>
                                          )
                                        })
                                    : <div>-</div>}
                                        
                                </div>
                                <div className='morebtn'>
                                    <div className='btnbold smround' onClick={()=> setActiveData(locationdata)}>
                                   <span> {t("see more details")}</span>
                                   </div>
                                </div>
                            </div>
                        </div>
                    )
                })

                }
            </div>
            : null }
        </div>
        {activeData ?
       
        <div className='locationpage-popup'>
             {console.log(activeData, "your active data") }
            <div className='locationpage-popup-inner'>
                <div className='locationpage-popup-inner-heading'>
                    <span>{t("Store Information")}</span>
                    <span className='curspor-pointer' onClick={()=>setActiveData(null)}><CrossIcon/></span>
                </div>
                <div className='locationpage-popup-inner-image'>
                    <img src={activeData?.Location_image} alt={activeData?.name}/>
                </div>
                <div className='locationpage-popup-inner-data'>
                                <div className='storetitle'>
                                    <span className='bold'>{activeData?.name}</span>
                                    <span className='light'>{activeData?.store_Address}</span>
                                </div>
                                <div className='contact'><span className='bold'>{t("Phone number")}:</span> <span className='light'> {activeData?.phone}</span></div>
                                <div className='bold'>{t("Store Hours")}</div>
                                <div className='storehours'>
                                    {activeData?.store_timeSchedule ?
                                    
                                        Object.entries(activeData?.store_timeSchedule).map( (item,i) => {
                                          return(
                                            <div className='storehours-inner'><div className='light'>{item[0]}</div><div className='light'>{item[1]}</div></div>
                                          )
                                        })
                                    : <div>-</div>}
                                        
                                </div>
                            </div>
            </div>
        </div>
        : null}
    </div>
  )
}
