import React, { useRef, useState, useEffect, useCallback } from "react"
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { success, error } from '../../common/helpers/toastify';
import 'react-datepicker/dist/react-datepicker.css';
import '../custom.scss';
import { _Api } from '../../common/api/_call';
import CheckoutBlank from '../../checkout/checkoutBlank';
import CustomOrderSearch from '../../common/module/customOrderSearch';
import { useSelector } from 'react-redux';
import NoOrdersYet from '../../common/module/NoOrdersYet/NoOrdersYet';
import Loading from '../../common/module/Loading/Loading';
import { useTranslation } from 'react-i18next';
const CreateYourOwn = () => {
  const dataFetched = useRef();
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { state } = useLocation()
  let navigate = useNavigate()
  const saleperson = useSelector((state) => state.app.saleperson)
  const customer = useSelector((state) => state.app.customer)
  const [listdata, setListdata] = useState();
  const [skip, setSkip] = useState(0);
  const [Totalproduct, setTotalproduct] = useState(0);
  const [Loadingstatus, setLoadingstatus] = useState(false)
  const [addtocartstatus, setaddtocartstatus] = useState(true)
  const [Optiondetails, setOptiondetails] = useState(state?.optiondetailid || false)
  const [Item, setItem] = useState()
  const [itemvalue, setitemvalue] = useState()
  const [variant, setvariant] = useState()
  const [shoremorelodar, setShowmorelodar] = useState(false)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.pos_custom_order?.View)
      setaddtocartstatus(parmissiondatashow?.pos_custom_order?.Add);


  }, [parmissiondatashow]);

  const getcreateown = useCallback(async () => {
    if (shoremorelodar) {
      setShowmorelodar(true)
    } else {
      setLoadingstatus(true)
    }
    try {
      const postdata = {
        customer_id: customer?.id ? customer?.id : '',
        type: Optiondetails ? 'catalog' : 'inventory',
        item_id: itemvalue?.id ? itemvalue?.id : '',
        skip: skip,
        limit: 10
      }
      let res = await _Api(postdata, `api/v1/POS/catalog/list`)
      if (res.code === 200) {
        setShowmorelodar(false)
        setLoadingstatus(false)

        if (skip === 0) {
          setListdata(res?.data);
        } else {
          if (res?.data?.catalog?.length) {
            var catalogarray = {
              ...res?.data,
              catalog: [...listdata?.catalog, ...res?.data?.catalog],
            };
            setListdata(catalogarray);
          }
        }
        setTotalproduct(res?.total);
        dataFetched.current = false;
      }
    } catch (err) {
      console.log(err, 'err')
    }
    // eslint-disable-next-line
  }, [customer, Optiondetails, itemvalue, skip]);
  const getitem = async () => {
    try {
      const posdata = { type: 'custom_order' }
      let res = await _Api(posdata, `api/v1/POS/home/itemList`)
      if (res.code === 200) {
        setItem(res?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const AddtoCart = async (product_id) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'));
      return
    }
    if (!saleperson?.value) {
      error(t('SalePerson is Requried!!'))
      return
    }
    try {
      setaddtocartstatus(false)
      const postdata = {
        product_id: product_id,
        type: Optiondetails ? 'catalog' : 'inventory',
        customer_id: customer?.id,
        sales_person_id: saleperson?.value,
        order_type: 'custom_order',
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`)
      if (res.code === 200) {
        setaddtocartstatus(true)
        success(t('Successfully Add to Cart!!'))

        if (state?.order_id && state?.order_edit && state?.order_type) {
          navigate('/app/pos/custom/createYourOwnSetting', {
            state: { cartid: res?.data, filtertype: Optiondetails, order_id: state?.order_id, order_edit: state?.order_edit, order_type: state?.order_type },
          })
        }
        else {
          navigate('/app/pos/custom/createyourowndetails', {
            state: { cartid: res?.data, filtertype: Optiondetails, },
          })
        }

      } else {
        error(t('This Product Already in cart!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const wishlistset = async (sku, product_id, wishliststatus, i, name) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    try {
      const postdata = {
        customer_id: customer?.id,
        isWishlist: wishliststatus,
        SKU: sku,
        type: Optiondetails ? 'catalog' : 'inventory',
        product_id: product_id,
        order_type: "custom_order"
      }
      let res = await _Api(postdata, `api/v1/POS/wishlist/updateWishlist`)
      if (res.code === 200) {
        let arry = { ...listdata }
        if (name === 'bywishlist') {
          arry['WishList'][i] = {
            ...arry['WishList'][i],
            'isWishlist': res?.data?.isWishlist,
          }
        } else {
          arry['catalog'][i] = {
            ...arry['catalog'][i],
            'isWishlist': res?.data?.isWishlist,
          }
        }
        setListdata(arry)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getcreateown()
  }, [customer, Optiondetails, itemvalue?.id, skip, getcreateown])
  useEffect(() => {
    getitem()
  }, [])

  useEffect(() => {
    if (variant) {
      AddtoCart(variant)
    }
    // eslint-disable-next-line
  }, [variant])


  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            {Loadingstatus ? <Loading /> : ''}
            <div className="catalog-area page-menu-block">
              <div className="common-navigation-header">
                <div className="common-navigation-header-left">
                  <h3 className="common-navigation-header-left-heading">

                    <Link
                      to="/app/pos/custom/custom"
                      className="gray-mid text-decoration-none"
                    >
                      {t('CUSTOM ORDER')}
                    </Link>
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'}
                      alt=""
                    />
                    {t('Create your own')}
                  </h3>
                </div>
                <CustomOrderSearch
                  setOptiondetails={setOptiondetails}
                  optionid={state?.optiondetailid}
                  setvariant={setvariant}
                  from="createYourOwn"
                  setSkip={setSkip}
                  order_id={state?.order_id}
                  order_edit={state?.order_edit}
                  order_type={state?.order_type}
                />
              </div>
              <div className="selection-process">
                <div
                  className={
                    state?.changecolor
                      ? 'selection-process-single active-yellow'
                      : 'selection-process-single active-green'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s1.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s1-g.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-yellow"
                    src={base_assets + 'images/pos/steps/s1-y.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Select Setting')}
                  </span>
                </div>
                <span className="selection-process-line"></span>
                <div className="selection-process-single">
                  <img
                    className="selection-process-single-img"
                    src={base_assets + 'images/pos/steps/s2.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Design')}
                  </span>
                </div>
              </div>

              <div className="position-relative h-100 overflow-auto bg-white create-your-own-data">
                <div className="dropdown common-dropdown border rounded  mx-4 p-1 mt-4">
                  <button
                    className="dropdown-toggle text-start text-uppercase"
                    type="button"
                    id="dropdownRing"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {itemvalue?.name ? itemvalue?.name : 'All'}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownRing"
                  >
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => { setitemvalue({ id: '', name: '' }); setSkip(0) }}
                      >
                        {t('ALL')}
                      </span>
                    </li>
                    {Item?.length
                      ? Item.map((result, key) => {
                        return (
                          <li key={key}>
                            <span
                              className="dropdown-item"
                              onClick={() => {
                                setitemvalue({
                                  id: result?._id,
                                  name: result?.name,
                                }); setSkip(0)
                              }
                              }
                            >
                              {result?.name}
                            </span>
                          </li>
                        )
                      })
                      : ''}
                  </ul>
                </div>


                <div className="products-list catalog-area-ct overflow-visible">
                  {listdata?.WishList?.length ? (
                    <>
                      <h1 className="main-heading">{t('Wishlist')}</h1>

                      <div className="products-list-row">
                        {listdata?.WishList?.length
                          ? listdata?.WishList.map((result, wkey) => {
                            return (
                              <div
                                className="products-list-row-single"
                                key={wkey}
                              >
                                <Link
                                  to="/app/pos/custom/createyourowndetails"
                                  state={{
                                    product_id: result?.product_id,
                                    filter: Optiondetails,

                                  }}
                                >
                                  <div className="products-list-row-single-img">
                                    <img
                                      className="products-cat-row-single-img"
                                      src={result?.main_image}
                                      alt=""
                                    />
                                  </div>
                                </Link>

                                <div className="products-list-row-single-ct">
                                  <div className='products-list-row-single-ct-name-wrap'>
                                    <h2 className="products-list-row-single-ct-name mb-0">
                                      {result?.name}
                                    </h2>
                                    <span className='products-list-row-single-ct-name d-block fw-semibold h-auto' style={{ color: "#5CC7C1" }}>{result?.SKU}</span>
                                  </div>
                                  <div className="products-list-row-single-ct-bottom">
                                    <p className="products-list-row-single-ct-bottom-price">
                                      {result?.price}
                                    </p>
                                    <Link
                                      to="/app/pos/custom/createyourowndetails"
                                      state={{
                                        product_id: result?.product_id,
                                        filter: Optiondetails,

                                      }}
                                    >
                                      <button
                                        className={
                                          addtocartstatus
                                            ? 'products-list-row-single-ct-bottom-add'
                                            : 'products-list-row-single-ct-bottom-add pe-none'
                                        }

                                      >
                                        <img
                                          src={
                                            base_assets +
                                            'images/pos/icons/add-pro.png'
                                          }
                                          alt=""
                                        />
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <div className="products-list-row-single-top-icons">
                                  {result?.product_variants_enable === "1" ?
                                    <img className="" src={base_assets + "images/icons/variant-sm.png"} alt="" /> :
                                    result?.product_variants_enable === "2" ?
                                      <img className="" src={base_assets + "images/pro-ring-img.png"} alt="" /> : ""}
                                  <i
                                    onClick={() => result?.isWishlist ?
                                      (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, wkey,
                                        'bywishlist')) :
                                      (parmissiondatashow?.WishList?.Add && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, wkey,
                                        'bywishlist'))}
                                    className={
                                      result?.isWishlist === 1
                                        ? 'fa fa-heart wishlist-icon position-static'
                                        : 'fa fa-heart-o wishlist-icon position-static'
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            )
                          })
                          : ''}
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {listdata?.catalog?.length ? (
                    <>

                      <div className="products-list-row">
                        {listdata?.catalog.map((result, key) => {
                          return (
                            <div className="products-list-row-single" key={key}>
                              <Link
                                to="/app/pos/custom/createyourowndetails"
                                state={{
                                  product_id: result?.product_id,
                                  filter: Optiondetails,
                                  changecolor: state?.changecolor,
                                  order_id: state?.order_id,
                                  order_edit: state?.order_edit,
                                  order_type: state?.order_type
                                }}
                              >
                                <div className="products-list-row-single-img">
                                  <img
                                    className="products-cat-row-single-img"
                                    src={result?.main_image}
                                    alt=""
                                  />
                                </div>
                              </Link>
                              <div className="products-list-row-single-ct">
                                <div className='products-list-row-single-ct-name-wrap'>
                                  <h2 className="products-list-row-single-ct-name mb-0">
                                    {result?.name}
                                  </h2>
                                  <span className='products-list-row-single-ct-name d-block fw-semibold h-auto' style={{ color: "#5CC7C1" }}>{result?.SKU}</span>
                                </div>
                                <div className="products-list-row-single-ct-bottom">
                                  <p className="products-list-row-single-ct-bottom-price">
                                    {result?.price}
                                  </p>
                                  <Link
                                    to="/app/pos/custom/createyourowndetails"
                                    state={{
                                      product_id: result?.product_id,
                                      filter: Optiondetails,
                                      changecolor: state?.changecolor,
                                      order_id: state?.order_id,
                                      order_edit: state?.order_edit,
                                      order_type: state?.order_type
                                    }}>
                                    <button

                                      className={
                                        addtocartstatus
                                          ? 'products-list-row-single-ct-bottom-add'
                                          : 'products-list-row-single-ct-bottom-add pe-none'
                                      }
                                    >
                                      <img
                                        src={
                                          base_assets +
                                          'images/pos/icons/add-pro.png'
                                        }
                                        alt=""
                                      />
                                    </button>
                                  </Link>
                                </div>
                              </div>
                              <div className="products-list-row-single-top-icons">
                                {result?.product_variants_enable === "1" ?
                                  <img className="" src={base_assets + "images/icons/variant-sm.png"} alt="" /> :
                                  result?.product_variants_enable === "2" ?
                                    <img className="" src={base_assets + "images/pro-ring-img.png"} alt="" /> : ""}
                                <i
                                  onClick={() => result?.isWishlist ?
                                    (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, key,
                                      'byproduct')) :
                                    (parmissiondatashow?.WishList?.Add && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, key,
                                      'byproduct'))}
                                  className={
                                    result?.isWishlist === 1
                                      ? 'fa fa-heart wishlist-icon position-static'
                                      : 'fa fa-heart-o wishlist-icon position-static'
                                  }
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : customer?.id && listdata?.WishList?.length ? (
                    ''
                  ) : (
                    <NoOrdersYet />
                  )}
                  {shoremorelodar ? <div className="border-bottom-0 d-block main-green-text mx-auto my-4 spinner-border flex-shrink-0" role="status"></div>
                    : ""}


                </div>

              </div>
              {
                listdata?.catalog?.length && listdata?.catalog?.length !== Totalproduct && Totalproduct && !shoremorelodar ? (
                  <div className="show-more">
                    <span className='show-more-displaying'>{t("Displaying")} {listdata?.catalog?.length} {t("of")} {Totalproduct} {t("items")}</span>
                    <button className='btn show-more-btn' onClick={() => { setSkip(skip + 10); setShowmorelodar(true) }}>
                      {t("Show more")} <img src={base_assets + "images/icons/add-icon.png"} alt="" />
                    </button>
                  </div>
                )
                  : ""}
            </div>
          </div>

          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateYourOwn;