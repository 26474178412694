import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { _Api } from "../api/_call";
import { Pagination } from "../common/Pagination";
import Slider from 'rc-slider';
import Confirmationmodel from "../common/modules/confirmationmodel"
import { loading, Updateloading } from "../common/helpers/toastify";
import { useSelector } from 'react-redux';
import TableLoader from "../common/TableLoader";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const AdminLocation = () => {
  const dropdownRefname = useRef(null);
  const dropdownRefcity = useRef(null);
  const dropdownReftype = useRef(null);
  const dropdownRefZone = useRef(null);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [filtername, setFiltername] = useState('');
  const [locationlist, setLocationlist] = useState([])
  const [shortobj, setShortobj] = useState({})
  const [searchVal, setSearchVal] = useState("");
  const [locationfilter, setLocationfilter] = useState([])
  const [locationchecked, setLocationChecked] = useState([])
  const [cityfilter, setCityfilter] = useState([])
  const [citychecked, setCtiychecked] = useState([])
  const [typefilter, settypefilter] = useState([])
  const [typechecked, settypechecked] = useState([]);
  const [showloader, setShowloader] = useState(false);
  const [Zone, setZone] = useState([0, 100])
  const [Zoneobj, setZoneobj] = useState()
  const [dataid, setDataid] = useState()
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const limit = process.env.REACT_APP_MASTER_LIST_LIMIT
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;

  const getLocationlist = async (current) => {
    setShowloader(true);
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "search": filtername,
        "limit": limit,
        "skip": skipNumber,
        "filter": {
          "name": locationchecked ? locationchecked : [],
          "type": typechecked ? typechecked : [],
          "city": citychecked ? citychecked : [],
          "zone": {
            "min": Zone[0],
            "max": Zone[1] ? Zone[1] : 100,
          }
        },
        "sort": { [shortobj?.key]: shortobj?.value }
      }
      let res = await _Api(postdata, `api/v1/App/location/locationList`)
      if (res?.code === 200) {
        setLocationlist(res?.data)
        setShowloader(false)
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const filterbyname = () => {
    getLocationlist(currentPage);
    setstate({ ...state, currentPage: 1 });
  };

  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getLocationlist(current);

  };
  const locationfilterapi = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/location/locationfilter`)
      if (res?.code === 200) {
        setLocationfilter(res?.data?.name)
        setCityfilter(res?.data?.city)
        settypefilter(res?.data?.type)
        setZone([0, res?.data?.zone]);
        setZoneobj({ 'min': 0, 'max': res?.data?.zone });

      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getLocationlist()
    locationfilterapi()
    // eslint-disable-next-line 
  }, [shortobj])

  const hendledropdownhide = (lable) => {
    if (lable === "name") {
      dropdownRefname.current.classList.remove("show")
    } else if (lable === "city") {
      dropdownRefcity.current.classList.remove("show")
    } else if (lable === "type") {
      dropdownReftype.current.classList.remove("show")
    } else if (lable === "Zone") {
      dropdownRefZone.current.classList.remove("show")
    }
  }
  const handleCheckAllChange = (e, lable) => {
    if (lable === "name") {
      setLocationChecked(
        e.target.checked ? locationfilter.map((c) => c) : []
      );
    } else if (lable === "city") {
      setCtiychecked(
        e.target.checked ? cityfilter.map((c) => c) : []
      );
    } else if (lable === "type") {
      settypechecked(
        e.target.checked ? typefilter.map((c) => c) : []
      );
    }

  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "name") {
      setLocationChecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "city") {
      setCtiychecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "type") {
      settypechecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    }


  };

  const getscarchvalue = async (event, lable) => {
    if (lable === "name") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...locationfilter];
      let arr = []
      if (query) {
        updatedList.filter((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setLocationfilter(arr);
          return true;
        });
      } else {
        locationfilterapi()
      }
    } else if (lable === "city") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...cityfilter];
      let arr = []
      if (query) {
        updatedList.filter((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setCityfilter(arr);
          return true;
        });
      } else {
        locationfilterapi()
      }
    } else if (lable === "type") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...typefilter];
      let arr = []
      if (query) {
        updatedList.filter((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          settypefilter(arr);
          return true;

        });
      } else {
        locationfilterapi()
      }
    }
  }
  const deletelocation = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        id: dataid
      }
      let res = await _Api(postdata, `api/v1/App/location/deleteLocation`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getLocationlist()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    header_values.push(
      '#',
      'image',
      'Location',
      'City',
      'Type',
      'Zone',
    )
    if (locationlist.length) {
      locationlist.forEach((result, key) => {
        bodyarray.push([
          key + 1,
          result?.location_logo,
          result?.name,
          result?.city,
          result?.type,
          result?.zone,
        ])
      })
    }

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'Location List')
  }
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0"><span className="">{t("Location")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <div className="com-search-bar position-relative">
              <img onClick={filterbyname} className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search Location" className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                onChange={(e) => setFiltername(e.target.value)} />
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item"
                  onClick={() => downloadexclsheet()}>
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
              </ul>
            </div>
            <Link
              to="/app/admin/location/detail"
              className={parmissiondatashow?.Location?.Add ? "text-decoration-none" : "text-decoration-none d-none"}>
              <button className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3">
                <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
              </button>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th>#</th>
                    <th></th>
                    <th className="user-name-width">{t("Location")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                          ref={dropdownRefname}>
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "name", value: 1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" />{t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "name", value: -1 }); hendledropdownhide("name") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" />{t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "name")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                  onChange={(e) => handleCheckAllChange(e, "name")}
                                  checked={locationchecked.length === locationfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                              </div>
                              <label>{locationchecked.length}/{locationfilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {locationfilter?.length ? locationfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key}
                                      checked={
                                        locationchecked ? locationchecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "name")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={key}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                          >
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={() => { setLocationChecked([]); setShortobj({}) }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getLocationlist(); hendledropdownhide("name") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("City")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden code"
                          ref={dropdownRefcity} >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "city", value: 1 }); hendledropdownhide("city") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" />{t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "city", value: -1 }); hendledropdownhide("city") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" />{t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "city")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                  onChange={(e) => handleCheckAllChange(e, "city")}
                                  checked={citychecked.length === cityfilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                              </div>
                              <label>{citychecked.length}/{cityfilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {cityfilter?.length ? cityfilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={result}
                                      checked={
                                        citychecked ? citychecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "city")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={result}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={() => { setCtiychecked([]); setShortobj({}) }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getLocationlist(); hendledropdownhide("city") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Type")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden type"
                          ref={dropdownReftype} >
                          <div className="asc-des-order">
                            <div onClick={() => { setShortobj({ key: "type", value: 1 }); hendledropdownhide("type") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" />{t("Sort Ascending")}</div>
                            <div onClick={() => { setShortobj({ key: "type", value: -1 }); hendledropdownhide("type") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" />{t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-search">
                            <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                              <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                              <input className="border-0" type="text" placeholder="Search"
                                onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "type")}
                                onChange={(e) => setSearchVal(e.target.value)} />
                            </div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                              <div>
                                <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                  onChange={(e) => handleCheckAllChange(e, "type")}
                                  checked={typechecked.length === typefilter.length} />
                                <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                              </div>
                              <label>{typechecked.length}/{typefilter.length} {t("Selected")}</label>
                            </div>
                            <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                              {typefilter?.length ? typefilter.map((result, key) => {
                                return (
                                  <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={result}
                                      checked={
                                        typechecked ? typechecked.includes(result) : ""
                                      }
                                      onChange={(e) => handlefilerChange(e, result, "type")} />
                                    <label className="form-check-label cursor-pointer" htmlFor={result}>{result}</label>
                                  </li>
                                )
                              }) : ""}

                            </ul>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={() => { settypechecked([]); setShortobj({}) }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getLocationlist(); hendledropdownhide("type") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>{t("Zone")}
                      <div className="dropdown d-inline-block com-custom-table-tag-filter">
                        <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                          <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                        </button>
                        <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                          ref={dropdownRefZone}

                        >
                          <div className="asc-des-order">
                            <div className="asc-des-order-single cursor-pointer"
                              onClick={() => { setShortobj({ key: "Zone", value: 1 }); hendledropdownhide("Zone") }}> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" />{t("Sort Ascending")}</div>
                            <div className="asc-des-order-single cursor-pointer"
                              onClick={() => { setShortobj({ key: "Zone", value: -1 }); hendledropdownhide("Zone") }} > <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" />{t("Sort Descending")}</div>
                          </div>
                          <div className="com-custom-table-tag-filter-menu-select">
                            <div className="range-area mt-0">
                              <div className="range-area-num d-flex justify-content-between mb-4">
                                <div className="range-area-num-single">
                                  <span className="range-area-num-unit">
                                    {Zone[0]}
                                  </span>
                                </div>
                                <div className="range-area-num-single justify-content-end">
                                  <span className="range-area-num-unit">
                                    {Zone[1]}
                                  </span>
                                </div>
                              </div>
                              <Slider
                                range
                                min={Zoneobj?.min}
                                max={Zoneobj?.max}
                                value={Zone}
                                onChange={setZone}
                              />
                            </div>
                          </div>


                          <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                              onClick={() => { setZone([0, 0]); setShortobj({}); hendledropdownhide("Zone") }}>{t("Clear")}</button>
                            <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { getLocationlist(); hendledropdownhide("Zone") }}>{t("Apply")}</button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {locationlist?.length ? locationlist.map((result, key) => (
                    <tr className="text-nowrap" key={key + 1}>
                      <td>{key + 1}</td>
                      <td>
                        <div className="pro-border-img">
                          <img className="h-100 w-100 mw-100 object-fit-cover"
                            src={result?.location_logo ? result?.location_logo :
                              ""} alt="" />
                        </div>
                      </td>
                      <td>{
                        parmissiondatashow?.Location?.Edit ?
                          <Link state={{ dataid: result?.id }} className="text-decoration-none" to={`/app/admin/location/detail/${result?.id}`}>
                            {result?.name ? result?.name : ""}
                          </Link> :
                          result?.name ? result?.name : ""
                      }</td>
                      <td>{result?.city ? result?.city : ""}</td>
                      <td>{result?.type ? result?.type : ""}</td>
                      <td>{result?.zone ? result?.zone : 0}</td>
                      <td className="text-center">
                        <Link state={{ dataid: result?.id }} to={`/app/admin/location/detail/${result?.id}`}
                          className={parmissiondatashow?.Location?.Edit ? " " : "d-none"}>
                          <img className="cursor-pointer me-4" src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                        </Link>
                        <img onClick={() => setDataid(result?.id)} data-bs-toggle="modal" data-bs-target="#createPurchaseModal" className={parmissiondatashow?.Location?.Delete ? "cursor-pointer" : "d-none"} src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                      </td>
                    </tr>
                  )) : null}
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
          </div>
        </div>
        {locationlist.length > 0 && (
          <Pagination
            total={totalPages}
            current={currentPage}
            pagination={(crPage) => handlePaginations(crPage)}
          />
        )}
        <Confirmationmodel statement={"Delete"} handler_submit={deletelocation} ></Confirmationmodel>
      </div>
    </>
  )
}

export default AdminLocation;