import  React, { useState , useEffect } from 'react'
export const DateTime = () => {
    var [date,setDate] = useState(new Date());  
      
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });



    return(
        <div>
            <p className="main-body-current-day-time">
            {date.toLocaleTimeString('en-IT')}  {date.toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric'   })}
            {/* 12:00:00 PM May 24, 2022  */}

          </p> 
        </div>
    )
}

export default DateTime