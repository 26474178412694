import React from 'react';
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import footerLogoImg from "./assets/images/footer-logo.png"
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer--light">
        <div className="footer-big">
          <div className="footer-main">
            <div className="row">

              <div className='footer-all'>
                <div className="footer-all-firstbox">
                  <div className="footer-widget">
                    <div className="widget-about">
                      <img src={footerLogoImg} alt=''></img>
                      <p className='widget-about-para' >{t("GIS247 (Global Inventory System) is a comprehensive solution on mobile app and web browser that can help you manage your global jewelry showrooms efficiently.")}</p>
                    </div>
                  </div>
                </div>


                <div className='footer-flex'>
                  <div className="footer-flex-first-box">
                    <div className="footer-widget">
                      <div className="footer-menu">
                        <h4 className="footer-widget-title">{t("Sitemap")}</h4>
                        <ul>
                          <li>
                            <a href="#home">{t("Home")}</a>
                          </li>
                          <li>
                            <a href="#gis247">{t("GIS247")}</a>
                          </li>
                          <li>
                            <a href="#features">{t("Features")}</a>
                          </li>
                          <li>
                            <a href="#ecommerce">{t("E-Commerce")}</a>
                          </li>
                          <li>
                            <a href="#contactus">{t("Contact Us")}</a>
                          </li>

                        </ul>
                      </div>

                    </div>

                  </div>


                  <div className="footer-flex-second-box">
                    <div className="footer-widget">
                      <div className="footer-menu p-0">
                        <h4 className="footer-widget-title">Follow us on </h4>
                        <ul>
                          <li>
                            <FaFacebookSquare color='white' size={32} style={{ marginRight: "20px" }} />
                            <FaLinkedin color='white' size={32} />

                          </li>
                          <div className='facebook-widget-img'>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fjewealofficial%2F&tabs&width=340&height=70&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="100%" height="100%" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" className='footer-img' title='Facebook Page Widget' >
                            </iframe>
                          </div>

                        </ul>


                      </div>

                    </div>

                  </div>
                </div>
              </div>




              <div className='mini-footer'>
                <div className=''>© 2022 Jeweal. All rights reserved.</div>  <span className="dot"></span>
                <div className='terms'>
                  <span>Terms & Conditions</span>
                  <div className="vl-footer"></div> <span>Privacy Policy</span>
                </div>

              </div>

            </div>

          </div>

        </div>



      </footer>
    </>

  );
};

export default Footer;