import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { _Api } from '../common/api/_call';
import { success, error } from '../common/helpers/toastify';
import '../Custom/MixAndMatch/DiamondDetail.scss';
import CheckoutBlank from '../checkout/checkoutBlank';
import CheckoutSell from '../checkout/checkoutSell';
import CheckoutPayment from '../checkout/checkoutPayment';
import { useSelector } from 'react-redux';
import Loading from '../common/module/Loading/Loading';
import { useTranslation } from 'react-i18next';
const CatalogDiamondDetails = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const customer = useSelector((state) => state.app.customer)
  const saleperson = useSelector((state) => state.app.saleperson)
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [showpayment, setShowpayment] = useState(false)
  const [, setdeletestatus] = useState(false)
  let checksale = localStorage.getItem('showcheckout') === "sell" ? true : false
  const [searchproductstatus, setsearchproductstatus] = useState(checksale || false);
  const [UpdatedCart, setUpdatedCart] = useState()
  const [Loadingstatus, setLoadingstatus] = useState(false)
  const { state } = useLocation()
  const [items, setItem] = useState()
  const [details, setDetails] = useState()
  const getdetails = async () => {
    try {
      const posdata = { id: state?.daimondid }
      let res = await _Api(posdata, `api/v1/Inventory/diamond/getDetails`)
      if (res.code === 200) {
        setDetails(res.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (state?.daimondid) {
      getdetails()
      getitem()
    }
    // eslint-disable-next-line
  }, [])
  const AddtoCart = async () => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    if (!saleperson?.value) {
      error(t('SalePerson is Requried!!'))
      return
    }
    try {
      const postdata = {
        diamond_id: state?.daimondid,
        order_type: 'pos_order',
        customer_id: customer?.id,
        product_type: 'inventory',
        sales_person_id: saleperson?.value,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/addDiamondToCart`)
      if (res.code === 200) {
        success(t('Successfully Add to Cart!!'))
        setShowcheckout('sell')
        setsearchproductstatus(true)
      } else {
        error(t('This Product Already in cart!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getitem = async () => {
    setLoadingstatus(true)
    try {
      const posdata = { type: 'mixAndMatch' }
      let res = await _Api(posdata, `api/v1/POS/home/itemList`)
      if (res.code === 200) {
        setLoadingstatus(false)
        setItem(res.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area bg-white page-menu-block">
              <div className="common-navigation-header diamond-detail-header">
                <div className="common-navigation-header-left">
                  <Link
                    to="/app/pos/catalog/CollectionList"
                    className="page-back"
                  >
                    <img
                      className="page-back-img"
                      src={base_assets + 'images/pos/icons/arrow-gray-left.png'}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="position-relative h-100 overflow-auto">
                {Loadingstatus ? <Loading /> : ''}
                <div className="diamond-detail-section">
                  <div className="diamond-detail-section-row">
                    <div className="diamond-detail-section-row-col">
                      <div className="diamond-detail-section-row-col-pro-img">
                        <img
                          src={
                            details?.image[0]
                              ? details?.image[0]
                              : base_assets + 'images/pos/ring-4.png'
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="diamond-detail-section-row-col">
                      <div className="diamond-detail-section-row-col-detail">
                        <h1 className="diamond-detail-section-row-col-detail-heading">
                          {details?.Carat} {t("Carats")} {details?.Shape} -
                          {details?.Colour} / {details?.Clarity}
                        </h1>
                        <span className="diamond-detail-section-row-col-detail-heading-num">
                          {details?.StockID}
                        </span>
                        <p className="diamond-detail-section-row-col-detail-heading-sub">
                          {details?.Carat} {t("carats")}, {details?.Colour} {t("colour")},
                          {details?.Clarity} {t("clarity")} {details?.Shape} ,
                          {details?.Cut} {t("Cut")}
                        </p>
                        <div className="diamond-detail-section-row-col-detail-gia-report">
                          <img
                            className="diamond-detail-section-row-col-detail-gia-report-img"
                            src={base_assets + 'images/icons/gia.png'}
                            alt=""
                          />
                          <span className="diamond-detail-section-row-col-detail-gia-report-title">
                            {details?.GradedBy} {t("Report")}
                          </span>
                          <a
                            className="diamond-detail-section-row-col-detail-gia-report-sku link-color"
                            href={details?.CertificationUrl}
                          >
                            {details?.Certification}
                          </a>
                          <img
                            className="diamond-detail-section-row-col-detail-gia-report-graph-img"
                            src={base_assets + 'images/icons/exp-view.png'}
                            alt=""
                          />
                        </div>
                        <span className="diamond-detail-section-row-col-detail-price">
                          {details?.Price}
                        </span>

                        <div className="diamond-detail-section-row-col-detail-btn-row">
                          {details?.Status === 2 ? (
                            <div className="border-inner-btn hover-effect overflow-visible common-dropdown w-100">
                              <button className="add-to-cart ">{t("Reserved")}</button>
                            </div>
                          ) : (
                            <>
                              <div className="border-inner-btn hover-effect overflow-visible common-dropdown w-100">
                                <button
                                  onClick={AddtoCart}
                                  className="add-to-cart "
                                >
                                  {t("ADD TO CART")}
                                </button>
                              </div>
                              <div className="dropdown common-dropdown diamond-dropdown ms-0 green-hover-dropdown">
                                <button
                                  className="hover-effect-inner add-to-order"
                                  id="dropdownDiamond"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {t("ADD TO ORDER")}
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownDiamond"
                                >
                                  {items?.length
                                    ? items.map((result, i) => {
                                      return (
                                        <li key={i}>
                                          <Link
                                            className="dropdown-item justify-content-start"
                                            to="/app/pos/custom/mixAndMatchProduct"
                                            state={{
                                              daimond_id: state.daimondid,
                                              items: {
                                                id: result?._id,
                                                name: result?.name,
                                              },
                                            }}
                                          >
                                            <span className="dropdown-item-img">
                                              <img
                                                src={
                                                  result?.logo
                                                    ? result?.logo
                                                    : ''
                                                }
                                                alt=""
                                              />
                                            </span>
                                            {result?.name}
                                          </Link>
                                        </li>
                                      )
                                    })
                                    : ''}
                                </ul>
                              </div>
                              <div className="wishlist-icon">
                                <i
                                  className="fa fa-heart wishlist-icon"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="diamond-detail-section-diamond-detail">
                    <div className="diamond-detail-section-diamond-detail-fieldset">
                      <div className="diamond-detail-section-diamond-detail-fieldset-legend">
                        {t("Diamond Details")}
                      </div>
                      <ul className="diamond-detail-section-diamond-detail-fieldset-ul">
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Stock ID")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.StockID}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Shape")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Shape}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Carat")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Carat}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Colour")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Colour}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Clarity")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Clarity}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Cut")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Cut}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <ul className="diamond-detail-section-diamond-detail-fieldset-ul">
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Polish")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Polish}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Symmetry")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Symmetry}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Fluorescence")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Fluoresence}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Depth")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Depth}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Table")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Table}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Girdle")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Gridle}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <ul className="diamond-detail-section-diamond-detail-fieldset-ul">
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Culet")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Culet}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Measurements")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Measurement}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Graded By")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.GradedBy}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t("Certification")}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Certification}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setdeletestatus={setdeletestatus}
                ordertype={'pos_order'}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CatalogDiamondDetails;
