import React from 'react';
import "./NoOrdersYet.scss";
import { useTranslation } from 'react-i18next';
const NoOrdersYet = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;

  return (
    <div className="no-orders-yet">
      <img
        className="no-orders-yet-img"
        src={base_assets + 'images/pos/blank-cart-vector.png'}
        alt=""
      />
      <span className="no-orders-yet-heading">{t("No Product yet")}</span>
      <span className="no-orders-yet-text">
        {t("But keep checking this section from time to time.")}
      </span>
    </div>
  )
}

export default NoOrdersYet;
