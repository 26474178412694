import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import TableColumnModal from '../../components/common/modules/tableColumn'
import { Tablehead } from '../../components/common/modules/Tablehead'
import { posrefund_head } from '../../components/common/Tablehead'
import 'react-datepicker/dist/react-datepicker.css'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import { _Api } from '../common/api/_call'
import CheckoutBlank from '../checkout/checkoutBlank'
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import { useTranslation } from 'react-i18next'
import { error } from '../common/helpers/toastify'
import { useSelector } from 'react-redux'
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function'
import TableLoader from '../../admin/common/TableLoader'
import { store } from '../../source/index'
const Refund = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [newcolumndata, setnewcolumndata] = useState(posrefund_head)
  const [, setfilterby] = useState('')
  const customer = useSelector((state) => state.app.customer)
  const [UpdatedCart, setUpdatedCart] = useState()
  const [orderid, setOrderid] = useState('')
  const [cart_id, setCart_id] = useState('')
  const [TransactionDate, setTransactionDate] = useState()
  const [refunddata, setRefundedata] = useState()
  const [deletestatus, setdeletestatus] = useState()
  const [searchproductstatus, setsearchproductstatus] = useState()
  const [showcheckout, setShowcheckout] = useState('blank')
  const [showpayment, setShowpayment] = useState(false)
  const [parmission, setParmission] = useState(0)
  const [showloader, setShowloader] = useState(true);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

  useEffect(() => {
    if (parmissiondatashow?.pos_refund?.View)
      setParmission(parmissiondatashow?.pos_refund?.Add);
    // eslint-disable-next-line
  }, []);
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_pos_refund',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const addtorefundcart = async (order_id, order_edit, order_type) => {
    console.log("in list")
    try {
      const postdata = {
        order_id: orderid ? orderid : order_id,
        order_type: order_type ? order_type : 'refund_order',
        order_edit: order_edit,
        cartid: cart_id,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data)
        setShowcheckout('sell')
      } else {
        error(t('This Product Already in cart!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getrefundlist = async () => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      SetdataTable('com-data-area_table', 4, newcolumndata, 0)
      setShowloader(false)
      return
    }
    UnSetdataTable('com-data-area_table')
    try {
      const postdata = {
        date: TransactionDate,
        customer_id: customer?.id,
        order_type: 'refund_order',
      }
      let res = await _Api(postdata, `api/v1/POS/repair/getSalesOrderList`)
      if (res?.code === 200) {
        setRefundedata(res?.data)
        setShowloader(false);
        SetdataTable('com-data-area_table', 4, newcolumndata, 0)
      } else {
        SetdataTable('com-data-area_table', 4, newcolumndata, 0)
        setShowloader(false);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (state?.order_id && state?.order_edit && state?.order_type) {
      addtorefundcart(state?.order_id, state?.order_edit, state?.order_type)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getrefundlist()
    // eslint-disable-next-line
  }, [customer?.id, TransactionDate, deletestatus])

  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
    // eslint-disable-next-line
  }, [showcheckout]);

  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="com-data-area">
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left">
                    <h1 className="heading">
                      <Link
                        to="/app/pos/menu"
                        className="gray-text text-decoration-none"
                      >
                        {t('REFUND')}
                      </Link>
                    </h1>
                  </div>
                  <div className="catalog-area-header-area-control-right"></div>
                </div>
              </div>
              <div className="com-data-area-header">
                <h2 className="com-data-area-header-heading">
                  {t('PURCHASE HISTORY')}
                </h2>
                <div className="customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date">
                  <DatePicker
                    className="placeholder-up-input-field"
                    selected={TransactionDate}
                    onChange={selectTransactionDate}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    placeholderText="MM/DD/YYYY"
                    autoComplete='none'
                  />
                  <img
                    className="placeholder-up-input-field-calender"
                    src={base_assets + 'images/icons/calendar-green.svg'}
                    alt=""
                  />
                </div>
              </div>
              <div className="com-data-area-inner overflow-auto">
                <div className="main-body-main-table-wrap" id="table-scroll" style={{ overflowX: 'auto' }}>
                  <table
                    id="com-data-area_table"
                    className="common-table first-row-gray w-100"
                  >
                    <Tablehead
                      tablehead={posrefund_head}
                      tblName={'tbl_pos_refund'}
                      setfilterby={setfilterby}
                    />

                    <tbody>
                      <>
                        {refunddata?.length
                          ? refunddata.map((result, exkey) => {
                            return (
                              <tr key={result?.cart_id}>
                                <td className="row-check">
                                  <div className="com-check radio">
                                    <input
                                      type="radio"
                                      name="customchack"
                                      id={result?.cart_id}
                                      onClick={() => {
                                        setOrderid(result?.order_id)
                                        setCart_id(result?.cart_id)
                                      }}
                                    />
                                    <label
                                      htmlFor={result?.cart_id}
                                      className="com-check-label"
                                    />
                                  </div>
                                </td>
                                <td>{exkey + 1}</td>
                                <td>
                                  <div className="pro-img">
                                    <img src={result?.image} alt="" />
                                  </div>
                                </td>
                                <td>{result?.date}</td>
                                <td className="link-color">
                                  {result?.ref_no}
                                </td>
                                <td className="link-color">{result?.SKU}</td>
                                <td>{result?.metal_name}</td>
                                <td>{result?.stone_name}</td>
                                <td>{result?.size_name}</td>
                                <td>{result?.stock_id}</td>
                                <td>{result?.location}</td>
                                <td>{result?.Qty}</td>
                                <td>
                                  {toFormatPrice(result?.amount, {
                                    addSymbol: true,
                                  })}
                                </td>
                              </tr>
                            )
                          })
                          : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {refunddata?.length
                            ? refunddata?.reduce(
                              (interation, val) =>
                                (interation = interation + val?.Qty),
                              0,
                            )
                            : ''}
                        </td>
                        <td>
                          {toFormatPrice(
                            refunddata?.length
                              ? refunddata?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.amount)),
                                0,
                              )
                              : '',
                            { addSymbol: true },
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className='position-relative'>
                    {showloader && <TableLoader />}
                  </div>
                </div>
                <div className="com-data-area-footer-btn">
                  <button
                    onClick={() => addtorefundcart()}
                    className={
                      orderid !== '' && showcheckout === 'blank' && parmission
                        ? 'com-btn ms-auto'
                        : 'com-btn ms-auto btn-disabled'
                    }
                  >
                    {t('Submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                searchproductstatus={searchproductstatus}
                setsearchproductstatus={setsearchproductstatus}
                order_id={orderid ? orderid : state?.order_id}
                ordertype={'refund_order'}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                order_id={orderid ? orderid : state?.order_id}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={posrefund_head}
        tblName={'tbl_pos_refund'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={getrefundlist}
      ></TableColumnModal>
    </>
  )
}

export default Refund
