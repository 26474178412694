import React from 'react';
import Tabs from "./Tabs";
const TabInv = ({ id, title, content,style }) => {

  
 
  return (
      <>
      <div className=""><Tabs/> </div>
      </>
   
  );
};

export default TabInv;
