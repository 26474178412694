import React, { useState, useRef, useEffect } from 'react';
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import { _Api } from '../api/_call';
const LocationExistingDomainModal = (props) => {
    const { setDomainid, domainid } = props
    const { t } = useTranslation();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [showstatus, setShowstatus] = useState(false)
    const [showmessage, setShowmessage] = useState({
        show: false
    })
    const [isModalOpen,] = useState(true);
    const modalRef = useRef(null);
    const handleCopyClick = async (cupyinput) => {
        try {
            let a = document.querySelectorAll(`.${cupyinput}`);
            await navigator.clipboard.writeText(a[0].value);
            setShowstatus(true)
            setTimeout(() => {
                setShowstatus(false);
            }, 2000);
        } catch (err) {
            console.log(err, "err");
        }
    };


    const adddomain = async () => {
        try {
            let GetloadingID = loading();
            let postdata = {
                "name": domainid?.domainname,
                "id": domainid?.domainid,
                "location_id": domainid?.location_id
            }
            let res = await _Api(postdata, `api/v1/APP/domain/location/add`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                if (res?.id) {
                    let domainids = [...domainid?.domainids]
                    domainids.push(res?.id)
                    setDomainid(prevState => ({
                        ...prevState,
                        domainids: domainids
                    }));
                }
                setShowmessage(prevState => ({
                    ...prevState,
                    res,
                    show: true
                }));
                setDomainid(prevState => ({
                    ...prevState,
                    domainid: res?.id
                }));
                if (modalRef.current) {
                    modalRef.current.hide();
                }
                setDomainid(prevState => ({
                    ...prevState,
                    domainname: ""
                }))
            } else {
                setShowmessage(prevState => ({
                    ...prevState,
                    res,
                    show: true
                }));
                Updateloading(t, GetloadingID, (res?.message), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        modalRef.current = new window.bootstrap.Modal(document.getElementById('LocationExistingDomainModal'));
    }, []);
    return (
        <div className={`modal fade com-modal ${isModalOpen ? 'show' : ''}`} id="LocationExistingDomainModal" tabIndex="-1" aria-labelledby="LocationExistingDomainModalLabel" aria-hidden={!isModalOpen}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title fw-semibold" id="LocationExistingDomainModalLabel">{t("Connect Existing Domain")}</h6>
                        <button onClick={() => setDomainid(prevState => ({
                            ...prevState,
                            domainname: ""
                        }))} type="button" className="btn-close shadow-none focus-none" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="v3-input-group">
                            <label className="v3-input-group-label fw-semibold fs-14">{t("Domain")}</label>
                            <input type="text" className="v3-input-group-input w-100" placeholder="Example: example.com"
                                onChange={(e) => setDomainid(prevState => ({
                                    ...prevState,
                                    domainname: e.target.value
                                }))}
                                value={domainid?.domainname}
                                onKeyPress={(e) => e.key === 'Enter' && setDomainid(prevState => ({
                                    ...prevState,
                                    domainname: e.target.value
                                }))} />
                            <label className="v3-input-group-label mt-3">{t("Enter the domain you want to connect")}.</label>
                        </div>
                        {showmessage?.show ?
                            <span className={`d-flex justify-content-center
                                 ${showmessage?.res?.code === 200 ? "main-green-text" : "text-danger"}`}>{showmessage?.res?.message}</span> : ""}

                        {showmessage?.res?.requiredIp ?
                            <>
                                <div className=''>
                                    <div className="v3-input-group">
                                        <label className="v3-input-group-label fw-semibold fs-14">{t("REQUIRD IP")}</label>
                                        <div className='v3-input-group-input d-flex wheatbg'>
                                            <input type="text" className="w-100 border-0 bg-transparent pointer-none input1"
                                                value={showmessage?.res?.requiredIp}
                                                disabled
                                            />
                                            <span className='copy-clip-img-box cursor-pointer'>
                                                <img onClick={() => handleCopyClick("input1")} src={base_assets + "images/icons/clone.svg"} alt='' />
                                            </span>
                                        </div>
                                        {showstatus ?
                                            <span className='d-flex justify-content-center main-green-text'>
                                                {t("Successfully Copy Your Text")}</span>
                                            : ""}
                                    </div>
                                </div>

                            </> : ""}

                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => adddomain()} className="btn text-white main-green-bg borde-0"
                        > {t("Verified Now")}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default LocationExistingDomainModal;