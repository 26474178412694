import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {
  MetalsList,
  ColorsList,
  SizesList,
  StonesList,
  ShapesList,
  CutsList,
  ClaritysList,
  StonecolorsList,
  CertificateTypeList,
  GetPointers,
  StonesizeList,
  StonesettingType
} from "../../common/graphql/productmastermodal";
import { useLocation} from 'react-router-dom';

const ProductModal = (props) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { data_product, setOneproduct, editproduct_submit } = props;
  const { t } = useTranslation();
  const [showbutton, setShowbutton] = useState(false);
  const [coloridbymetal, setcoloridbymetal] = useState();
  const [grosswt, setGrosswt] = useState();
  const [netwt, setNetWt] = useState();
  const location = useLocation()
  const updateData_productContent = (index, key, value) => {
    let dataupdate = { ...data_product };
    if (dataupdate['Stones'] && dataupdate['Stones'][index]) {
      if (key === "certificate") {
        dataupdate['Stones'][index][key]['type'] = value
      } else if (key === "certificatenumber") {
        dataupdate['Stones'][index]["certificate"]['number'] = value
      } else {
        dataupdate['Stones'][index][key] = value
      }
      setOneproduct(dataupdate);
    }
  }
  const updateData_productbymain = (id, value, name, lable) => {
    let dataupdate = { ...data_product };
    if (dataupdate) {
      dataupdate[id] = value;
      dataupdate[name] = lable;
      setOneproduct(dataupdate);
    }
  }
  const updateData_priceContent = (index, key, value) => {
    let dataupdate = { ...data_product };
    dataupdate['PriceDetails'][index][key] = value
    setOneproduct(dataupdate);
  }

  const addmore = () => {
    let dataupdate = { ...data_product };
    const array = {
      "stone_group": "",
      "stone": "",
      "Variant": 0,
      "Align": "",
      "shape": "",
      "Cut": "",
      "quality": "",
      "clarity": "",
      "color": "",
      "Size": "",
      "Unit": "cts",
      "Pcs": "",
      "Cts": "",
      "Price": "",
      "Pointer": "",
      "setting_type": "",
      "certificate": {
        "type": "",
        "number": ""
      }
    }
    dataupdate["Stones"].push(array);
    setOneproduct(dataupdate);
  }
  const removestone = (key) => {
    let dataupdate = { ...data_product };
    dataupdate["Stones"].splice(key, 1);
    setOneproduct(dataupdate);
  }

  useEffect(() => {
    if (data_product) {
      setGrosswt(data_product?.GrossWt);
      setNetWt(data_product?.NetWt);
    }
  }, [data_product]);
  return (
    <>
      <div className="modal fade product-modal product-sidebar-area" id="ProductInfoModal" tabIndex="-1" aria-labelledby="ProductInfoModalLabel" aria-hidden="true">
        <div className={showbutton ? "modal-dialog bg-white rounded" : "modal-dialog not-edit bg-white rounded"} >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ProductInfoModalLabel">{t("Product")}</h5>
              <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body ">
              <div className="product-modal-header-wrap">
                <div className='product-modal-header'>
                  <h1 className='product-modal-header-heading'>{t("Information")}</h1>
                  {
                    location.pathname === "/app/pos/custom/createYourOwnSetting" || location.pathname === "/app/pos/reserveproduct" ?
                      data_product?.product_type === 'variant' ? "" :
                        <>
                          <button className={!showbutton ? "product-modal-header-btn btn-style border-none" : "d-none"} >
                            <img className='product-modal-header-btn-img'
                              onClick={() => setShowbutton(true)}
                              src={base_assets + "images/icons/edit-pencil.png"} alt="" />
                          </button>
                          <button className={showbutton ? "product-modal-header-btn btn-style border-none" : "d-none"} >
                            <img className="product-modal-header-btn-img" data-bs-dismiss="modal"
                              onClick={() => { setShowbutton(false); editproduct_submit() }}
                              src={base_assets + "images/icons/save-icon.png"} alt="" />
                          </button>
                        </>
                      : ''
                  }
                </div>
                <div className='product-modal-row product-modal-row-detail'>
                  <div className=' product-modal-row-detail-inner'>
                    <div className='product-modal-row-detail-inner-left'>
                      <img src={data_product?.main_image} alt="" />
                    </div>
                    <div className='product-modal-row-detail-inner-right'>
                      <h3 className='product-modal-row-detail-inner-right-pro-heading'>#{data_product?.name ? data_product?.name : "-"}</h3>
                      <p className='product-modal-row-detail-inner-right-pro-para'>{data_product?.Matatag ? data_product?.Matatag : ""}</p>
                      <div className='product-modal-row-detail-inner-right-cols'>
                        <div className='product-modal-row-detail-inner-right-cols-single'>
                          <label className='product-modal-row-detail-inner-right-cols-single-label'>{t("Product ID")}</label>
                          <span className='product-modal-row-detail-inner-right-cols-single-text'>{data_product?.ID ? data_product?.ID : '-'}</span>
                        </div>
                        <div className='product-modal-row-detail-inner-right-cols-single'>
                          <label className='product-modal-row-detail-inner-right-cols-single-label'>{t("SKU")}</label>
                          <span className='product-modal-row-detail-inner-right-cols-single-text'>{data_product?.SKU ? data_product?.SKU : "-"}</span>
                        </div>
                        <div className='product-modal-row-detail-inner-right-cols-single'>
                          {data_product?.product_type === "variant" && data_product?.is_design === 0 ?
                            <img src={base_assets + "images/icons/variant-sm.png"} alt="" />
                            : data_product?.is_design === 1 ?
                              <img src={base_assets + "images/pro-ring-img.png"} alt="" />
                              : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='product-modal-scroll'>
                <div className='product-modal-row'>
                  <div className='product-modal-row-col-left'>
                    <div className='product-modal-row-col-left-row'>
                      <MetalsList updateData_productbymain={updateData_productbymain} setcoloridbymetal={setcoloridbymetal} metalid={data_product?.Metal ? data_product?.Metal : ''} addclass={(data_product?.product_type === 'variant') && (data_product?.is_variant === 1 || data_product?.is_design === 1) ? true : false} />
                      <ColorsList updateData_productbymain={updateData_productbymain} colorid={coloridbymetal ? coloridbymetal : data_product?.Color} />
                      <SizesList updateData_productbymain={updateData_productbymain} product_id={data_product?.id} sizeid={data_product?.Size ? data_product?.Size : ''} addclass={(data_product?.product_type === 'variant') && (data_product?.is_variant === 1 || data_product?.is_design === 1) ? true : false} />
                    </div>
                  </div>
                  <div className='product-modal-row-col-right'>
                    <div className="product-sidebar-box box-bg box-input-40">
                      <div className="product-sidebar-box-form">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <label>{t("Gross Wt")}</label>
                              </td>
                              <td>
                                <div className="placeholder-up-input w-100">
                                  <input className="box-input" value={grosswt ? grosswt : ""} onChange={(e) => { setGrosswt(e.target.value); updateData_productbymain('GrossWt', e.target.value) }} placeholder='' />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>{t("Net Wt")}</label>
                              </td>
                              <td>
                                <div className="placeholder-up-input w-100">
                                  <input className="box-input" value={netwt ? netwt : ""} onChange={(e) => { setNetWt(e.target.value); updateData_productbymain('NetWt', e.target.value) }} placeholder='' />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {data_product?.Stones?.length ? data_product?.Stones.map((result, key) => {
                  return (

                    <div className={result?.Variant == null ? 'product-modal-row not-edit' : 'product-modal-row'} key={key}>
                      <div className='product-modal-row-col-left'>
                        <div className='product-modal-row-col-left-row'>
                          <StonesList updateData_productContent={updateData_productContent} index={key} stoneid={result?.stone} groupid={result?.stone_group} addclass={(data_product?.product_type === 'variant') && (data_product?.is_variant === 1 || data_product?.is_design === 1) ? true : false} yellow_color={result?.Variant === 2 ? true : false} />

                          <ShapesList updateData_productContent={updateData_productContent} index={key} shapeid={result?.shape} addclass={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_variant === 1 ? true : false} light_yellow_color={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_design === 1 && result?.Variant === 2 ? true : false} />

                          <CutsList updateData_productContent={updateData_productContent} cutid={result?.Cut} index={key} addclass={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_variant === 1 ? true : false} light_yellow_color={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_design === 1 && result?.Variant === 2 ? true : false} />
                        </div>
                        <div className='product-modal-row-col-left-row'>
                          <div className="col">
                            <ClaritysList updateData_productContent={updateData_productContent} clarityid={result?.clarity} index={key} addclass={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_variant === 1 ? true : false} light_yellow_color={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_design === 1 && result?.Variant === 2 ? true : false} />
                          </div>
                          <div className="col">
                            <StonecolorsList updateData_productContent={updateData_productContent} stonecolorid={result?.color} index={key} addclass={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_variant === 1 ? true : false} light_yellow_color={(data_product?.product_type === 'product' || data_product?.product_type === 'variant') && data_product?.is_design === 1 && result?.Variant === 2 ? true : false} />
                          </div>
                        </div>
                        <div className='product-modal-row-col-left-row'>
                          <CertificateTypeList updateData_productContent={updateData_productContent} certificatelistid={result?.certificate?.type} index={key} />
                          <div className='product-modal-row-col-left-row-input fg-4'>
                            <div className="placeholder-up-input form-floating w-100">
                              <input type="text" className="placeholder-up-input-field form-control bg-white" value={result?.certificate?.number} onChange={(e) => { updateData_productContent(key, 'certificatenumber', e.target.value) }} placeholder='text' />
                              <label className="placeholder-up-input-label">{t("Certificate No.")}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='product-modal-row-col-right'>
                        <div className="product-sidebar-box box-bg box-input-40">
                          <div className="product-sidebar-box-form">
                            <table>
                              <tbody>
                                {result?.Variant === 2 ?
                                  <tr>
                                    <td>
                                      <label>{t("Pointer")}</label>
                                    </td>
                                    <td>
                                      <GetPointers updateData_productContent={updateData_productContent} shape_id={result?.shape} index={key} pointerid={result?.Pointer} />
                                    </td>
                                  </tr>
                                  : ''
                                }

                                <tr>
                                  <td>
                                    <label>{t("Size")}</label>
                                  </td>
                                  <td>
                                    <StonesizeList updateData_productContent={updateData_productContent} shape_id={result?.shape} stonesizeid={result?.Size} index={key} />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label>{t("Pcs")}</label>
                                  </td>
                                  <td>
                                    <div className="placeholder-up-input w-100">
                                      <input className="box-input" value={result?.Pcs} onChange={(e) => { updateData_productContent(key, 'Pcs', e.target.value) }} placeholder='' />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label>{t("Weight")}</label>
                                  </td>
                                  <td>
                                    <div className="placeholder-up-input w-100">
                                      <input className="box-input" value={result?.Cts} onChange={(e) => { updateData_productContent(key, 'Cts', e.target.value) }} placeholder='' />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label>{t("Setting")}</label>
                                  </td>
                                  <td>
                                    <StonesettingType updateData_productContent={updateData_productContent} settingid={result?.setting_type} index={key} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {key !== 0 ? data_product?.product_type === 'product' && (data_product?.is_design === 1 || data_product?.is_variant === 1) ?
                          <button type="button" onClick={() => { removestone(key) }} className="add-more-pro h-auto mt-3 danger-text "><img src={base_assets + "images/icons/minus-detail.png"} alt=""/>{t("Remove")}</button>
                          : '' : ''
                        }
                      </div>
                    </div>
                  );
                }) : ''}

                {data_product?.product_type === 'product' && (data_product?.is_design === 1 || data_product?.is_variant === 1) ?
                  <button onClick={() => { addmore() }} type="button" className="add-more-pro m-auto my-2 "><img src={base_assets + "images/icons/add-detail.png"} alt=""/>{t("Add more stones")}</button>
                  : ''
                }

                {location.pathname === "/app/pos/custom/createYourOwnSetting" ?
                  <div className="product-modal-row product-modal-currency-price">
                    <div className="product-modal-row-col-left">
                      <table className="product-modal-currency-price-table">
                        <thead>
                          <tr>
                            <th>{t("Currency")}</th>
                            <th>{t("Retail price")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {
                              data_product?.PriceDetails ? data_product?.PriceDetails.map((result, key) => {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <div className="product-modal-currency-price-table-row">
                                        <span className="product-modal-currency-price-table-row-icon">
                                          <img src={base_assets  + "images/flags/" + result?.currency.toLowerCase() + ".png"} alt=""/>
                                        </span>
                                        {result?.currency ? result?.currency : ''}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="placeholder-up-input">
                                        <input className="product-modal-currency-price-table-row-input placeholder-up-input-field" value={result?.retailprice_Inc ? result?.retailprice_Inc : 0} onChange={(e) => { updateData_priceContent(key, 'retailprice_Inc', e.target.value) }} type="text" />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }) : ''
                            }
                          </>
                        </tbody>
                      </table>
                    </div>
                    <div className='product-modal-row-col-right'>
                      <div className="product-sidebar-box box-bg box-input-40">
                        <div className="product-sidebar-box-form">
                          <textarea className="product-sidebar-box-form-textarea" onChange={(e) => updateData_productbymain('extra_po_information', e.target.value, '', '')} defaultValue={data_product?.extra_po_information ? data_product?.extra_po_information : ''} >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''}
              </div>

            </div>

          </div>
        </div>
      </div>


    </>
  );
};

export default ProductModal;

