import React, { useEffect, useState } from 'react';
import './AddCustomerAddressPopup.scss'
import FIlterIcon from '../../../Ecommerce/template_one/default/assets/icons/ArrowDownSide';
import EditIcon from '../../../Ecommerce/template_one/default/assets/icons/EditIcon';
import { updateCustomer } from "../../../source/app/appSlice";
import { useSelector, useDispatch } from 'react-redux';
import { _Api, graphqlPost } from '../../common/api/_call';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Select, { components } from "react-select";
import {
    loading,
    Updateloading,
} from '../../common/helpers/toastify'
function AddCustomerAddressPopup(props) {
    const { t } = useTranslation();
    const [getActiveAddress, setGetActiveAddress] = useState();
    const dispatch = useDispatch();
    const { ValueContainer, Placeholder } = components;
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const customer = useSelector((state) => state.app.customer)
    const [getlistdata, setListdata] = useState([])
    const [showDropdown, setShowDropdown] = useState()
    const [alladdressdata, setAlladdressdata] = useState({ values: { is_default: 0 } })
    const [countryarray, setCountryarray] = useState([]);
    const [serchdata, setSerchdata] = useState([])
    const itemdaata = localStorage.getItem("addcustomerdtaa")
    const parsedObject = JSON.parse(itemdaata);
    let isArabic = localStorage.getItem('UserLang');
    const [customeraddress, setCustomeraddress] =
        useState(
            {
                billing_address: getlistdata[0]?.billing_address.find(address => address.is_default === 1),
                shipping_address: getlistdata[1]?.shipping_address.find(address => address.is_default === 1)
            })
    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, (child) =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };
    const handleShowDropdown = (ind) => {
        if (ind === showDropdown) {
            setShowDropdown()
        } else {
            setShowDropdown(ind)
        }
    }
    const setselect = (index, address, lable) => {
        let newArray = [...getlistdata];
        newArray[index].select = address?.fullAddress;
        setListdata(newArray);
        let setobj = { ...customeraddress };
        if (lable === "billing") {
            setobj.billing_address = { ...address };
            setobj.billing_address.country = address?.country?.value;
            delete setobj.billing_address.fullAddress;
        } else if (lable === "shipping") {
            setobj.shipping_address = { ...address };
            setobj.shipping_address.country = address?.country?.value;
            delete setobj.shipping_address.fullAddress;
        }
        setCustomeraddress(setobj)
        let data = JSON.stringify(setobj);
        localStorage.setItem('addcustomerdtaa', data);
    }


    const addresslist = async () => {
        try {
            let postdata = {
                "customer_id": customer?.id
            }
            let res = await _Api(postdata, `api/v1/Customer/address/list`)
            if (res?.code === 200) {
                const defaultshipAddress = res?.data?.shipping_address?.length ? res?.data?.shipping_address.find(address => address.is_default === 1) : [];
                const defaultbillAddress = res?.data?.billing_address?.length ? res?.data?.billing_address.find(address => address.is_default === 1) : [];
                if ((parsedObject?.billing_address?.UDID === defaultbillAddress?.UDID && parsedObject?.shipping_address?.UDID === defaultshipAddress?.UDID) || !parsedObject?.billing_address?.UDID || !parsedObject?.shipping_address?.UDID) {
                    const dataArray = [
                        {
                            title: 'Billing Address',
                            billing_address: res?.data?.billing_address?.length ? res?.data?.billing_address : [],
                            select: defaultbillAddress?.fullAddress
                        },
                        {
                            title: 'Shipping Address',
                            shipping_address: res?.data?.shipping_address?.length ? res?.data?.shipping_address : [],
                            select: defaultshipAddress?.fullAddress
                        }
                    ];
                    setListdata(dataArray);
                    setSerchdata(dataArray)
                    const billAddressCopy = { ...defaultbillAddress };
                    const shipAddressCopy = { ...defaultshipAddress };
                    if (defaultbillAddress?.country?.value) {
                        billAddressCopy.country = defaultbillAddress?.country?.value
                    }
                    delete billAddressCopy.fullAddress;
                    if (defaultshipAddress?.country?.value) {
                        shipAddressCopy.country = defaultshipAddress?.country?.value
                    }
                    delete shipAddressCopy.fullAddress;
                    setCustomeraddress({ billing_address: billAddressCopy, shipping_address: shipAddressCopy });
                    let lastobj = { billing_address: billAddressCopy, shipping_address: shipAddressCopy }
                    let data = JSON.stringify(lastobj);
                    localStorage.setItem('addcustomerdtaa', data);
                    let customerdata = { id: customer?.id, name: customer?.name, existsInAddress: Object.keys(lastobj?.shipping_address).length && Object.keys(lastobj?.billing_address).length }
                    let jsondata = JSON.stringify(customerdata);
                    localStorage.setItem('customerdetails', jsondata);
                    dispatch(
                        updateCustomer(customerdata)
                    );
                } else {
                    let data = { ...parsedObject }
                    data.shipping_address = res?.data?.shipping_address.find(address => address.UDID === parsedObject?.shipping_address?.UDID)
                    data.billing_address = res?.data?.billing_address.find(address => address.UDID === parsedObject?.billing_address?.UDID)
                    const dataArray = [
                        {
                            title: 'Billing Address',
                            billing_address: res?.data?.billing_address?.length ? res?.data?.billing_address : [],
                            select: data?.billing_address?.fullAddress
                        },
                        {
                            title: 'Shipping Address',
                            shipping_address: res?.data?.shipping_address?.length ? res?.data?.shipping_address : [],
                            select: data?.shipping_address?.fullAddress
                        }
                    ];
                    setListdata(dataArray);
                    setSerchdata(dataArray)
                    const billAddress = { ...data.billing_address };
                    const shipAddress = { ...data.shipping_address };
                    billAddress.country = defaultbillAddress?.country?.value;
                    delete billAddress.fullAddress;
                    shipAddress.country = defaultshipAddress?.country?.value;
                    delete shipAddress.fullAddress;
                    setCustomeraddress({ billing_address: billAddress, shipping_address: shipAddress });

                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getCountry = async () => {
        try {
            const postdata = `{
                       countries{
                        name
                        id
                        sortname
                        phoneCode  
                        }
                      }`;
            let res = await graphqlPost(postdata, "App/graphql");
            let countryarray = [];
            res?.data?.countries.forEach((cnames) => {
                countryarray.push({
                    value: cnames?.id,
                    label: cnames?.name,
                });
            });
            setCountryarray(countryarray);

        } catch (err) {
            console.log(err, "err");
        }
    }
    useEffect(() => {
        if (customer?.id) {
            addresslist()
            getCountry()
        }
        // eslint-disable-next-line
    }, [customer?.id])
    const validationSchema = Yup.object().shape({
        address: Yup.string()
            .required('Required'),
        country: Yup.object().required("Required"),
        state: Yup.string()
            .required('Required'),
        city: Yup.string()
            .required('Required'),
        zipcode: Yup.string()
            .required('Required'),
    });

    const validater = useFormik({
        initialValues: {
            address: alladdressdata?.values?.address,
            state: alladdressdata?.values?.state,
            city: alladdressdata?.values?.city,
            zipcode: alladdressdata?.values?.zipcode,
            country: alladdressdata?.values?.country
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            editaddress(values)
        },
    },
    );
    const handleClearForm = () => {
        validater.resetForm();
    };
    const editaddress = async (values) => {
        let mainobj = { ...alladdressdata?.values, ...values };
        if (getActiveAddress === 1) {
            mainobj.type_billing = false
        } else {
            mainobj.type_billing = true
        }
        delete mainobj.fullAddress
        mainobj.customer_id = customer?.id
        mainobj.country = values?.country?.value
        if (mainobj?.tax_number === null) {
            delete mainobj.tax_number
        }
        try {
            let GetloadingID = loading();
            let postdata = mainobj
            console.log(postdata, "postdata")
            let res = await _Api(postdata, `api/v1/Customer/address/edit-address`)
            if (res?.statusCode === 200) {
                setAlladdressdata({ values: { is_default: 0 } })
                Updateloading(t, GetloadingID, (res?.message));
                setGetActiveAddress(3);
                addresslist()
                handleClearForm()
            } else {
                Updateloading(t, GetloadingID, (res?.errors), 'error');
            }

        }
        catch (err) {
            console.log(err, "err")
        }
    }



    const handleSearch = (searchQuery, label) => {
        let searchResults = [...serchdata];
        if (searchQuery) {
            if (label === "Billing Address") {
                let billsearch = [...searchResults[0]?.billing_address]
                billsearch = billsearch?.filter(obj =>
                    Object.values(obj).some(value =>
                        typeof value === 'string' && value.match(new RegExp(searchQuery, 'i'))
                    )
                );
                setListdata(prevListData => prevListData.map(item => {
                    if (item.title === 'Billing Address') {
                        return {
                            ...item,
                            billing_address: billsearch,
                        };
                    }
                    return item;
                }))
            } else {
                let shipsearch = [...searchResults[1]?.shipping_address]
                shipsearch = shipsearch?.filter(obj =>
                    Object.values(obj).some(value =>
                        typeof value === 'string' && value.match(new RegExp(searchQuery, 'i'))
                    )
                );
                setListdata(prevListData => prevListData.map(item => {
                    if (item.title === 'Shipping Address') {
                        return {
                            ...item,
                            shipping_address: shipsearch,
                        };
                    }
                    return item;
                }))
            }
        }
    };


    return (
        <div>
            {getActiveAddress === 0 || getActiveAddress === 1 ?
                <div className='main-body-row-right-col-inner-list box-1'>
                    <div className='addresssection'>
                        <div className='addresssection-heading'>
                            {t(`${getActiveAddress === 0 ? 'Billing Address' : getActiveAddress === 1 ? 'Shipping Address' : ''}`)}
                        </div>
                        <div className='addresssection-inputs'>
                            <div className="form-data">
                                <div className="text-box placeholder-up-input form-floating textarea">
                                    <textarea name='address' onChange={validater.handleChange} value={validater.values.address} className={validater?.errors?.address ? "text-box-inner col form-control placeholder-up-input-field border-danger" : "text-box-inner col form-control placeholder-up-input-field "}
                                        rows="4" cols="50" placeholder="Address">
                                    </textarea>
                                    <label className="placeholder-up-input-label">{t("Address")}</label>
                                    <div className='placeholder-up-input-label text-danger mt-2'>{t(`${validater?.errors?.address ? validater?.errors?.address : ""}`)}</div>
                                </div>
                            </div>
                            <div className="form-data addresssection-inputs-wrapper">
                                <div className="choose-select addresssection-inputs-wrapper-inputfield">
                                    <Select
                                        className={validater?.errors?.country ? "select-set select p-0 border-danger" : "select-set select  p-0"}
                                        placeholder={t("Country")}
                                        options={countryarray}
                                        components={{
                                            ValueContainer: CustomValueContainer,
                                        }}
                                        value={validater.values.country}
                                        name='country'
                                        onChange={(e) => { validater.setFieldValue('country', e) }}
                                        classNamePrefix="common-select"
                                        getOptionLabel={(opt) => opt.label}
                                        isSearchable={true}
                                    />
                                    <div className='placeholder-up-input-label text-danger mt-2'>{t(`${validater?.errors?.country ? validater?.errors?.country : ""}`)}</div>
                                </div>
                                <div className='addresssection-inputs-wrapper-inputfield'>
                                    <div className="placeholder-up-input form-floating w-100">
                                        <input
                                            name='state'
                                            onChange={validater.handleChange}
                                            value={validater.values.state}
                                            type="text"
                                            className={validater?.errors?.state ? "input-set placeholder-up-input-field form-control border-danger" : "input-set placeholder-up-input-field form-control"}
                                            id="source" placeholder="State" />
                                        <label className="placeholder-up-input-label" htmlFor="">
                                            {t("State")}
                                        </label>
                                    </div>
                                    <div className='placeholder-up-input-label text-danger mt-2'>{t(`${validater?.errors?.state ? validater?.errors?.state : ""}`)}</div>
                                </div>
                            </div>
                            <div className="form-data addresssection-inputs-wrapper">
                                <div className='addresssection-inputs-wrapper-inputfield'>
                                    <div className="placeholder-up-input form-floating w-100">
                                        <input
                                            name='city'
                                            onChange={validater.handleChange}
                                            value={validater.values.city}
                                            type="text"
                                            className={validater?.errors?.city ? "input-set placeholder-up-input-field form-control border-danger" : "input-set placeholder-up-input-field form-control"}
                                            id="city" placeholder="City" />
                                        <label className="placeholder-up-input-label" htmlFor="">
                                            {t("City")}
                                        </label>
                                    </div>
                                    <div className='placeholder-up-input-label text-danger mt-2'>{t(`${validater?.errors?.city ? validater?.errors?.city : ""}`)}</div>
                                </div>
                                <div className='addresssection-inputs-wrapper-inputfield'>
                                    <div className="placeholder-up-input form-floating ">
                                        <input
                                            name='zipcode'
                                            onChange={validater.handleChange}
                                            value={validater.values.zipcode}
                                            type="text"
                                            className={validater?.errors?.zipcode ? "input-set placeholder-up-input-field form-control border-danger" : "input-set placeholder-up-input-field form-control"}
                                            id="zipcode" placeholder="Zipcode" />
                                        <label className="placeholder-up-input-label" htmlFor="">
                                            {t("Zip Code")}
                                        </label>
                                    </div>
                                    <div className='placeholder-up-input-label text-danger mt-2'>{t(`${validater?.errors?.zipcode ? validater?.errors?.zipcode : ""}`)}</div>
                                </div>
                            </div>
                            <div className="form-data addresssection-inputs-wrapper">
                                <div className="placeholder-up-input form-floating w-100">
                                    <input
                                        name='texId'
                                        onChange={(e) => setAlladdressdata((state) => ({
                                            ...state,
                                            values: {
                                                ...state?.values,
                                                tax_number: e.target.value
                                            }
                                        }))}
                                        value={alladdressdata?.values?.tax_number}
                                        type="text" className="input-set placeholder-up-input-field form-control" placeholder="Tax ID No.(optional)" />
                                    <label className="placeholder-up-input-label" htmlFor="">
                                        {t("Tax ID No.(optional)")}
                                    </label>
                                </div>
                            </div>
                            <div className="form-data">
                                <div className='form-data addresssection-inputs-radiobtn'>
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("Default")} {getActiveAddress === 1 ? `${t('Shipping')}` : getActiveAddress === 0 ? `${t('Billing')}` : ''} {t("Address")}</label>
                                    <div className="form-check form-switch">

                                        <input onChange={(e) => setAlladdressdata((state) => ({
                                            ...state,
                                            values: {
                                                ...state?.values,
                                                is_default: e.target.checked ? 1 : 0
                                            }
                                        }))}
                                            checked={alladdressdata?.values?.is_default === 1 ? true : false} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='addcustomeraddress'>
                    <div className='addcustomeraddress-inner'>
                        <div className='addcustomeraddress-inner-title'>
                            {t("Address Customer")}
                        </div>
                        <div className='addcustomeraddress-inner-content'>
                            {getlistdata?.length ? getlistdata.map((item, i) => {
                                return (
                                    <div className='addcustomeraddress-inner-content-billing ' key={i}>
                                        <div className='addcustomeraddress-inner-content-billing-head'>{t(`${item.title}`)}</div>
                                        <div className='addcustomeraddress-inner-content-billing-addressbox'>
                                            <div className='addcustomeraddress-inner-content-billing-addressbox-default' onClick={() => handleShowDropdown(i)}>
                                                <div>
                                                    {item?.select}
                                                </div>
                                                {showDropdown === i ? <div className='addcustomeraddress-inner-content-billing-addressbox-default-upicon'><FIlterIcon /></div> : <FIlterIcon />}
                                            </div>
                                            {showDropdown === i &&
                                                <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox'>
                                                    <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside'>
                                                        <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-searchbar' onClick={() => handleSearch("", item?.title)}>
                                                            <img className={isArabic === 'SA.png' ? 'itsrtl' : ''} src={base_assets + 'images/pos/icons/magnifying-glass.png'} alt='' />
                                                            <input onChange={(e) => handleSearch(e.target.value, item?.title)} type='text' />
                                                        </div>
                                                        {item?.billing_address?.length ? item?.billing_address.map((result, key) => {
                                                            return (
                                                                <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-searchlist' key={key}>
                                                                    <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-searchlist-item'>
                                                                        <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-searchlist-item-text'
                                                                            onClick={() => { setselect(i, result, "billing"); setShowDropdown("") }}>{result?.fullAddress}</div>
                                                                        <div onClick={() => {
                                                                            setGetActiveAddress(i);
                                                                            setAlladdressdata({ values: result })
                                                                        }}><EditIcon /></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : ""}
                                                        {item?.shipping_address?.length ? item?.shipping_address.map((result, key) => {
                                                            return (
                                                                <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-searchlist' key={key}>
                                                                    <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-searchlist-item'>
                                                                        <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-searchlist-item-text'
                                                                            onClick={() => { setselect(i, result, "shipping"); setShowDropdown("") }}>{result?.fullAddress}</div>
                                                                        <div onClick={() => {
                                                                            setGetActiveAddress(i);
                                                                            setAlladdressdata({ values: result })
                                                                        }}><EditIcon /></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : ""}
                                                        <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-addnew' onClick={() => {
                                                            setGetActiveAddress(i);
                                                        }}>
                                                            <img src={base_assets + 'images/icons/adplus.png'} alt="" />
                                                            <span>{t("Add New Address")}</span>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                )
                            })

                                : ""
                            }
                        </div>
                    </div>
                </div>}
            <div className="main-body-row-right-col-inner-footer addresssection-footer mt-3 mb-3">
                <button className="addresssection-footer-cancelbtn"
                    onClick={() => { setGetActiveAddress(3); setAlladdressdata({ values: { is_default: 0 } }); handleClearForm() }}>
                    {t("Cancel")}
                </button>
                <button type='button' onClick={validater.handleSubmit} className="addresssection-footer-savebtn">
                    {t("Save")}
                </button>
            </div>
        </div>
    )
}

export default AddCustomerAddressPopup;