import React from 'react'

export default function ListIcon({color}) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 19.8008H21.5V17.8008H7.5V19.8008ZM7.5 13.8008H21.5V11.8008H7.5V13.8008ZM7.5 5.80078V7.80078H21.5V5.80078H7.5Z" fill={color ? color : "#212121"}/>
    <path d="M5.5 19.8008H3.5V17.8008H5.5V19.8008ZM5.5 13.8008H3.5V11.8008H5.5V13.8008ZM5.5 5.80078V7.80078H3.5V5.80078H5.5Z" fill={color ? color : "#212121"}/>
    </svg>
  )
}
