import React, { useEffect, useState, useRef } from 'react'
import { graphqlPost } from '../../common/api/_call';
import { useTranslation } from 'react-i18next';
import { store } from "../../../source/index";
const Mixandmatchservice = (props) => {
    const [showmodal, setShowmodal] = useState(false)
    const [servicelabour, setServicelabour] = useState([])
    const dropdownRef = useRef(null);
    const { setShowseqs, setScrvicecharge, setScrvicelist, scrvicelist, scrviceremark, setScrviceremark, service_saved, edit_cart, setServicechange, engraving_design } = props
    var state = store.getState();
    var OrgSettings = state?.app?.posorganisation_settings;
    var currency = OrgSettings?.symbol;
    const { t } = useTranslation()
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const getSalesList = async () => {
        try {
            const postdata = `{
                ServiceLabour{
                       name
                       code
                       id
                   }
        }`;

            let responce = await graphqlPost(postdata, "App/graphql");
            let ServiceLabour = [];
            if (responce.data?.ServiceLabour) {
                responce.data?.ServiceLabour?.forEach((result) => {
                    ServiceLabour.push(
                        {
                            id: result?.id,
                            code: result?.code,
                            name: result?.name


                        }
                    );
                })
            }
            setServicelabour(ServiceLabour);
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        getSalesList();
    }, []);
    const selectedproduct = (event, id, code, name, index) => {
        if (event.target.checked) {
            let list = []
            if (scrvicelist?.length) {
                let data = [...scrvicelist]
                if (data[index]?.id !== id) {
                    data.push({ id: id, name: name, code: code, scrviceamount: 0 })
                }
                setScrvicelist(data)
            } else {
                list.push({ id: id, name: name, code: code, scrviceamount: 0 })
                setScrvicelist(list)
            }
        } else {
            const resultlist = scrvicelist.filter((element) => {
                return element?.id !== event.target.id;
            });
            setScrvicelist(resultlist)
        }
    };

    const amount = (id, e, key) => {
        let array = [...scrvicelist]
        array[key] = { ...array[key], scrviceamount: parseInt(e) }
        setScrvicelist(array)
        var total = 0;
        for (var i = 0; i < array.length; i++) {
            total = total + array[i].scrviceamount;
        }
        setScrvicecharge(total)
        setScrvicelist((current) =>
            current.filter((item) => item?.id !== id)
        );
    }
    const [isSwitchActive, setIsSwitchActive] = useState(false);

    const switchActive = event => {
        setIsSwitchActive(current => !current);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsSwitchActive(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <>
            <div className='position-relative h-100'>
                <div className='d-flex' style={{ justifyContent: "space-between" }}>
                    <div className='switch-select-area text-start' style={{ width: "20.625vw" }}>
                        <div className='d-flex'>
                            <label className="service-modal-label pb-2 switch-select-area-label">
                                {t('Service Labour')}
                            </label>

                            {showmodal ? (
                                <span className='ms-auto danger-text cursor-pointer' style={{ fontSize: "0.9vw" }}
                                    onClick={() => { setScrvicelist([]) }}>{t("Remove All")}</span>
                            ) : ""}
                        </div>

                        <div className="dropdown common-dropdown service-modal-content-dropdown switch-select-area-dropdown"
                            ref={dropdownRef}>
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownDiamond"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={switchActive}
                            >
                                {t("Service Labour")}
                            </button>
                            <ul

                                className={isSwitchActive ? "dropdown-menu switch-select-area-dropdown-menu active" : "dropdown-menu switch-select-area-dropdown-menu"}
                                aria-labelledby="dropdownDiamond"
                            >
                                {servicelabour?.length ? servicelabour.map((result, key) => {
                                    return (
                                        <li className="dropdown-item" key={key}>
                                            <div className="com-check">
                                                <input
                                                    checked={scrvicelist?.some(item => item.id === result?.id)}
                                                    id={result?.id}
                                                    data-code={result?.code}
                                                    data-name={result?.name}
                                                    className='scrvicecheck'
                                                    type="checkbox"
                                                    onChange={(e) => selectedproduct(e, result?.id, result?.code, result?.name, key)}
                                                />
                                                <label
                                                    htmlFor={result?.id}
                                                    className="com-check-label"
                                                ></label>
                                            </div>
                                            <label>{result?.code}</label>
                                            <label onClick={() => { setShowmodal(true); switchActive() }}>{result?.name}</label>
                                        </li>
                                    )
                                }) : ""}


                            </ul>
                            {showmodal || scrvicelist?.length ? (

                                <ul className="switch-select-area-dropdown-menu switch-select-area-dropdown-menu-2">
                                    {scrvicelist?.length ? scrvicelist.map((result, key) => {
                                        return (
                                            <li className="dropdown-item service-modal-content-dropdown-item" key={key}>
                                                <div className=""
                                                    onClick={() => { amount(result?.id, 0, key) }}>
                                                    <img style={{ width: "1.25vw" }}

                                                        src={base_assets + 'images/pos/icons/red-delete.png'} alt=""
                                                    />
                                                </div>
                                                <div>{result?.code}</div>
                                                <div className='switch-select-area-dropdown-item-name'>{result?.name}</div>
                                                <div className="ms-auto">{currency}
                                                    <input type="number"
                                                        value={result?.scrviceamount ? result?.scrviceamount : ''}
                                                        className="active-input arrow-none text-end"
                                                        onChange={(e) => amount("", e.target.value, key)} />
                                                </div>
                                            </li>
                                        )

                                    }) : <li>{t("No Data Available")}</li>}



                                </ul>

                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className='w-50'>
                        <label className="create-own-setting-right-sketch-area-inner-concept-label mb-2">{t("Remark")}</label>
                        <textarea className="create-own-setting-right-sketch-area-inner-concept-text" placeholder="Remark"
                            value={scrviceremark}
                            onChange={(e) => scrvicelist?.length ? setScrviceremark(e.target.value) : ""}></textarea>
                    </div>
                    {service_saved ? <button className='create-own-setting-right-sketch-area-inner-engraving-finish-btn com-btn'
                        onClick={() => { setShowseqs(3); edit_cart(engraving_design); setServicechange(false) }}>{t("Next")}</button> :
                        <button className='create-own-setting-right-sketch-area-inner-engraving-finish-btn com-btn'
                            onClick={() => { setShowseqs(3); setServicechange(false) }}>{t("Next")}</button>}
                </div>

            </div>
        </>
    )
}
export default Mixandmatchservice;