import React, { useEffect, useRef, useState } from 'react';
import brochureImg1 from "./assets/images/brochure-img1.svg"
import brochureImg2 from "./assets/images/brochure-img2.svg"
import brochureImg3 from "./assets/images/brochure-img3.svg"
import handImg from "./assets/images/hand.svg"

const TabContent = ({ activeTab, tabNames, tabImages, style }) => {
  const tabContents = {
    tab1: "Stored diamonds and jewelry that can track the status and location of each product",
    tab2: "Displays the status and total amount of stock in real time",
    tab3: "Evaluating product costs, availability, quality, appearance and other aspects",
    tab4: "Fill in the SKU number to see product information such as quantity, location, material, and more",
    tab5: "Check stocks by using RFID technology",
    tab6: "Planning to refill stock automatically by calculating the purchased order and minimum stock",
    tab7: "Notification alert when a new order comes in or low stock levels in the inventory",
    tab8: "Easy to transfer and tracking stock",
    tab9: "Records of overall inventory performance",
  };

  return (
    <div className="tab-content invertory-showtab" id="pills-tabContent">
      {Object.keys(tabContents).map((tab, index) => (
        <div
          key={tab}
          className={`tab-pane fade${activeTab === tab ? " show active" : ""}`}
          id={`pills-${tab}`}
          role="tabpanel"
          aria-labelledby={`pills-${tab}-tab`}
        >
          <div className="tab-box tab-inventory-box-show" >
            <div className='d-flex justify-content-center' style={{margin: "auto", marginTop: "10px" }}>
              <img src={tabImages[index]} alt="" style={{ width: "30px", height: "30px", marginRight: "10px" }} />
              <h3 style={{ fontFamily: "inter,sans-serif" }}>{tabNames[index]}</h3>
            </div>
            <div className="tab-para" style={{ textAlign: "center", fontSize: "16px", lineHeight: "27.5px", marginTop: "18px", color: "#707070", fontFamily: "inter,sans-serif" }}>{tabContents[tab]}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Tabs = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentSlide = carouselRef.current.querySelector('.active');
      const nextSlide = currentSlide.nextElementSibling || carouselRef.current.firstElementChild;

      currentSlide.classList.remove('active');
      nextSlide.classList.add('active');
    }, 5000);


    return () => clearInterval(intervalId);
  }, []);
  const [activeTab, setActiveTab] = useState("tab1"); // Set the initial activeTab to "tab1"

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const columns = 3;
  const rows = 3;

  // Array of tab names
  const tabNames = ["Inventory", "Summary", "Product Analysis", "Quick View", "Stock Take", "Low Stock Planning", "Purchase Order", "Stock Transfer", "Reports"];

  // Array of tab images (replace with your actual image paths)
  const tabImages = [
    "/assets/landingNew/inventory-2.svg",
    "/assets/landingNew/inventory.svg",
    "/assets/landingNew/ProductAnalysis1.svg",
    "/assets/landingNew/QuickViewProduct1.svg",
    "/assets/landingNew/StockTake1.svg",
    "/assets/landingNew/LowStockPlaning1.svg",
    "/assets/landingNew/Purchaseorder1.svg",
    "/assets/landingNew/StockTransfer1.svg",
    "/assets/landingNew/Report1.svg",
  ];

  return (
    <div className="tabs-container">
      <div className='tablet-tab features-respons-show'>

        <div className='brochure'>
          <div id="carouselExample" className="carousel slide">
            <div className="carousel-inner" ref={carouselRef}>
              <div className="carousel-item active">
                <img src={brochureImg1} alt="First slide" className="brochure-img" />
              </div>
              <div className="carousel-item">
                <img src={brochureImg2} alt="Second slide" className="brochure-img" />
              </div>
              <div className="carousel-item">
                <img src={brochureImg3} alt="Third slide" className="brochure-img" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <TabContent className="tablet-content" activeTab={activeTab} tabNames={tabNames} tabImages={tabImages} style={{ fontFamily: "inter,sans-serif" }} />
      </div>


      <div className="row-all features-manu-box">

        {[...Array(rows)].map((_, rowIndex) => (
          <div key={`row-${rowIndex}`} className="row features-manu-box-singlerow">
            {[...Array(columns)].map((_, colIndex) => {
              const tabIndex = rowIndex * columns + colIndex + 1;
              const tab = `tab${tabIndex}`;
              return (
                <div key={tab} className="col features-manu-box-singlebox">
                  <ul className="nav nav-pills" id={`pills-tab-${tab}`} role="tablist">
                    <li className="nav-pills-nav-item nav-item" role="presentation" style={{ display: "flex", flexDirection: "row-reverse", }}>
                      {activeTab === tab && (
                        <img
                          src={handImg}
                          alt=""
                          style={{
                            width: "18px",
                            height: "21px",
                            marginTop: "5px",
                            margin: "auto"
                          }}
                        />
                      )}
                      <button
                        className={`nav-pills-nav-link nav-link navlink-custom${activeTab === tab ? " active" : ""}`}
                        id={`pills-${tab}-tab`}
                        onClick={() => handleTabClick(tab)}
                        style={{
                          color: activeTab === tab ? "#5CC7C0" : "#2B486C",
                          fontWeight: activeTab === tab ? "700" : "500",
                          fontFamily: "inter, sans-serif",
                          backgroundColor: activeTab === tab ? "transparent" : "transparent",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >

                        <img
                          src={tabImages[tabIndex - 1]}
                          alt=""
                          style={{
                            fontFamily: "inter,sans-serif",
                            width: "30px",
                            height: "30px",
                            margin: "auto",
                            marginBottom: "10px"
                          }}
                        />

                        {tabNames[tabIndex - 1]}
                      </button>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        ))}
      </div>

    </div>
  );
};

export default Tabs;
