import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/appSlice'; // Correct the import path
export const store = configureStore({
  reducer: {
    app: appReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: true
});
