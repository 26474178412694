import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Test from '../components/product/test';
import ProductMain from '../components/product/mainList';
import ProductClone from '../components/product/productClone';
import ProductImage from '../components/product/productImage';
import Product from '../components/product/product';
import ProductAdvanced from '../components/product/productAdvanced';
import ProductVariant from '../components/product/productVariant';
import ProductChoice from '../components/product/productChoice';
import InventoryHeader from './InventoryHeader';
import CustomSwitch from '../CustomSwitch';
import PageNotFound from '../components/common/404';
export default function ProductRoutes(props) {
    const { Fulhandle } = props;
    useEffect(() => {
        localStorage.removeItem("customerdetails")
        localStorage.removeItem("salesdetails")
    }, [])
    return (
        <CustomSwitch>
            <Route element={<InventoryHeader Fulhandle={Fulhandle} />}>
                <Route path='/' element={<ProductMain />} > </Route>
                <Route path='add-new' element={<Product />} > </Route>
                <Route path='add-new/:productId_url' element={<Product />} > </Route>
                <Route path='productAdvanced/:productId_url' element={<ProductAdvanced />} > </Route>
                <Route path='productVariant/:productId_url' element={<ProductVariant />} > </Route>
                <Route path='productChoice/:productId_url' element={<ProductChoice />} > </Route>
                <Route path='test' element={<Test />} > </Route>
                <Route path='main' element={<ProductMain />} > </Route>
                <Route path='importproductimage' element={<ProductImage />} > </Route>
                <Route path='clone/:productId_url' element={<ProductClone />} > </Route>
                <Route path='*' element={<PageNotFound />} ></Route>
            </Route>
        </CustomSwitch>)
}
