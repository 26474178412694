import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Wishlist.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { _Api } from '../common/api/_call'
import CheckoutBlank from '../checkout/checkoutBlank'
import { error } from '../common/helpers/toastify'
import { useSelector } from 'react-redux'
import { SetdataTable } from '../common/api/setdatatable'
import { poscustomerwishlist_head } from "../../components/common/Tablehead";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { useTranslation } from 'react-i18next';
import NoOrdersYet from '../common/module/NoOrdersYet/NoOrdersYet'

const Wishlist = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const customer = useSelector((state) => state.app.customer)
  const [searchlist, setSearchlist] = useState();
  const [searchsku, setSearchsku] = useState();
  const [productdetails, setProductDetails] = useState();
  const [Customerlist, setCustomerlist] = useState();
  const [wishlistdetails, setWishlistdetails] = useState();

  const getwishlist = useCallback(async (a) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    try {
      const postdata = {
        customer_id: customer?.id,
      }
      let res = await _Api(postdata, `api/v1/POS/wishlist/getProductWishList`)
      if (res.code === 200) {
        setWishlistdetails(res?.data)
      } else {
        setWishlistdetails('')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [customer?.id, t])
  const wishlistset = async (sku, product_id, i) => {
    try {
      const postdata = {
        customer_id: customer?.id,
        isWishlist: 1,
        SKU: sku,
        product_id: product_id,
      }
      let res = await _Api(postdata, `api/v1/POS/wishlist/updateWishlist`)
      if (res.code === 200) {
        getwishlist()
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getsearchproductlist = async (value) => {
    try {
      const posdata = {
        "search": value,
        "type": "catalog",
      }
      let res = await _Api(posdata, `api/v1/POS/customOrder/skuNameBySearch`)
      if (res.code === 200) {
        setSearchlist(res?.data);
      } else {
        setSearchlist([]);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getwishlistbycustomers = async (SKU) => {
    try {
      const posdata = {
        "SKU": SKU
      }
      let res = await _Api(posdata, `api/v1/POS/wishlist/wishlistbycustomer`)
      if (res.code === 200) {
        setProductDetails(res?.product);
        setCustomerlist(res?.customer);
      } else {
        error((res?.message))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  useEffect(() => {
    getwishlist();
  }, [customer.id, getwishlist])

  useEffect(() => {
    SetdataTable('wishlist_customer_table', 0)
  }, [])
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  return (
    <>

      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 wishlist-area">
            <div className="com-data-area overflow-auto">
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left ">
                    <h1 className="heading">{t("WISHLIST")}</h1>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <button className="com-search-area-left-img wishlist-scan-icon scan-img">
                        <img
                          src={base_assets + 'images/pos/icons/scan-icon.png'}
                          alt=""
                        />
                      </button>
                      <input type="text" value={searchsku} onChange={(e) => { getsearchproductlist(e.target.value); setSearchsku(e.target.value) }} placeholder={t("Scan barcode, Search SKU")} id="dropdownMenuButton1" data-bs-toggle="dropdown" autoComplete="off" />
                      <ul className="dropdown-menu custom-search-dropdown" aria-labelledby="dropdownMenuButton1">
                        {searchlist?.length ? searchlist.map((result, key) => {
                          return (<li key={key}><Link className="dropdown-item" onClick={() => { setSearchsku(result?.SKU); getwishlistbycustomers(result?.SKU) }} >{result?.name}<span>{result?.SKU}{result?.stock_id ? '-' + result?.stock_id : ''}</span></Link></li>);
                        }) : ''}
                      </ul>
                      <img
                        className="com-search-area-img"
                        src={
                          base_assets + 'images/pos/icons/magnifying-glass.png'
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="wishlist-header">
                <h2 className="wishlist-header-heading">{t("PRODUCT")}</h2>
              </div>
              {wishlistdetails?.length ?
                <div className="products-list-row wistlist-list-row">

                  {wishlistdetails.map((result, key) => {
                    return (
                      <div className="products-list-row-single" key={key}>
                        <div className="products-list-row-single-img">
                          <img
                            className="products-cat-row-single-img"
                            src={result?.main_image}
                            alt=""
                          />
                        </div>
                        <div className="products-list-row-single-ct">
                          <div className='products-list-row-single-ct-name-wrap'>
                            <h2 className="products-list-row-single-ct-name mb-0">
                              {result?.name}
                            </h2>
                            <span className='products-list-row-single-ct-name d-block fw-semibold h-auto spinnercolor'>{result?.SKU}</span>
                          </div>
                          <div className="products-list-row-single-ct-bottom">
                            <p className="products-list-row-single-ct-bottom-price">
                              {result?.price}
                            </p>
                            {result?.order_type === "custom_order" || result?.type === "catalog" ?
                              <Link
                                to="/app/pos/custom/createyourowndetails"
                                className="products-list-row-single-ct-bottom-add"
                                state={{
                                  product_id: result?.id,
                                  filter: result?.type === "catalog" ? true : false
                                }}
                              >
                                <img
                                  src={
                                    base_assets + 'images/pos/icons/add-pro.png'
                                  }
                                  alt=""
                                />
                              </Link>

                              : result?.order_type === "pos_order" ?
                                <Link
                                  to="/app/pos/products"
                                  className="products-list-row-single-ct-bottom-add"
                                  state={{
                                    product_id: result?.id,
                                    filter: result?.type === "catalog" ? true : false,
                                    itemid: result?.item_id
                                  }}
                                >
                                  <img
                                    src={
                                      base_assets + 'images/pos/icons/add-pro.png'
                                    }
                                    alt=""
                                  />
                                </Link>
                                : ""}

                          </div>
                        </div>

                        <div
                          onClick={() =>
                            (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.id, key))}
                        >
                          <i
                            className="fa fa-heart wishlist-icon"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    )
                  })}
                </div>
                : <div className='w-100 text-center h-100 position-relative'><NoOrdersYet /></div>}
              {
                productdetails ?
                  <div className="create-own-setting-right-product-detail bg-white">
                    <div className="create-own-setting-right-product-detail-left">
                      <img
                        className="create-own-setting-right-product-detail-left-img"
                        src={productdetails?.main_image}
                        alt=""
                      />
                    </div>
                    <div className="create-own-setting-right-product-detail-right">
                      <h3 className="create-own-setting-right-product-detail-right-title">
                        {productdetails?.name}
                      </h3>
                      <button
                        className="create-own-setting-right-product-detail-right-sku btn-style"
                      >
                        {productdetails?.SKU}
                      </button>
                      <p className="create-own-setting-right-product-detail-right-para">
                        {productdetails?.Matatag}
                      </p>
                      <div className="create-own-setting-right-product-detail-right-detail">
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t("Metal")} :
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.metal_name}
                          </span>
                        </div>
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t("Stone")} :
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.stone_name}
                          </span>
                        </div>
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t("Size")} :
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.size_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''
              }

              {
                Customerlist?.length ?
                  <div className="wishlist-area-customer">
                    <div className=" wishlist-area-customer-header d-flex align-items-center justify-content-between w-100">
                      <h3 className="wishlist-area-customer-header-heading">
                        {t("Total Customer")}
                      </h3>
                      <span className="wishlist-area-customer-header-heart">
                        <i className="fa fa-heart wishlist-icon" aria-hidden="true"></i>
                        {Customerlist?.length}
                      </span>
                    </div>
                    <div className="main-body-main-table-wrap">
                      <table
                        id="wishlist_customer_table"
                        className="common-table first-row-gray w-100"
                      >
                        <Tablehead tablehead={poscustomerwishlist_head} tblName={""} setfilterby={""} />
                        <tbody>
                          {Customerlist.map((result, ckeyy) => {
                            return (
                              <tr key={result?._id}>
                                <td>{ckeyy + 1}</td>
                                <td>
                                  <div className="pro-img">
                                    <img
                                      src={result?.profile}
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td>{result?.customerid}</td>
                                <td>{result?.fname} {result?.lname}</td>
                                <td className='crown-img'>  {
                                  result?.group === 'Silver' ?
                                    <img className="group-crown" src={base_assets + "images/icons/silver-medal.png"} alt="" /> :
                                    result?.group === 'Gold' ?
                                      <img className="group-crown" src={base_assets + "images/icons/gold-medal.png"} alt="" /> :
                                      result?.group === 'Platinum' ?
                                        <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : result?.group === 'Diamond' ?
                                          <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : ''
                                }{result?.group}</td>
                                <td>{result?.phone_code}-{result?.mobile}</td>
                                <td>{result?.email}</td>
                              </tr>
                            )

                          })}

                        </tbody>
                      </table>
                    </div>
                  </div> : ''
              }
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )
}
export default Wishlist;
