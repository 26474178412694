import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { DateRange } from "../common/helpers/daterange";
import { useTranslation } from 'react-i18next';
import { _Api } from "../api/_call";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import RightArrow from "../common/icons/right-arrow";
import Select from "react-select"
import "./customers.scss";
import $ from 'jquery'
import { toFormatPrice } from '../common/helpers/function';
import CustomerInformation from "./customerInformation/CustomerInformation";
const HistoryPartialPayment = () => {
  const dropdownRefrefno = useRef(null);
  const dropdownRefType = useRef(null);
  const dropdownReflocation = useRef(null)
  const dataFetched = useRef();
  const { t } = useTranslation();
  const [shortobj, setShortobj] = useState({})
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { state } = useLocation();
  const [datefilter, setDatefilter] = useState([]);
  const [partialpaymen, setPartialpaymen] = useState([])
  const [paytype, setpaytype] = useState('ALL')
  const [limit, setLimit] = useState(100);
  const [customerdetails, setCustomerdetails] = useState()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const [cusid, setCusid] = useState("")
  const [filterdata, setFilterdata] = useState()
  const [searchVal, setSearchVal] = useState("");
  const [checkedfilter, setcheckedfilter] = useState({
    "order_no": [],
    "location": [],
    "outstanding_amount": 167400,
    "deposit_amount": 35480,
    "Type": []
  })
  const gethistorypartialpayment = async (firstlimit) => {
    try {
      let postdata = {
        "customer_id": state?.customer_id,
        "type": paytype ? paytype : "ALL",
        "limit": firstlimit ? firstlimit : limit,
        "start_date": datefilter ? datefilter[0] : '',
        "end_date": datefilter ? datefilter[1] : '',
        "order_no": checkedfilter?.order_no,
        "location": checkedfilter?.location,
        "Type": checkedfilter?.Type,
        "sort": { [shortobj?.key]: shortobj?.value }
      }
      let res = await _Api(postdata, `api/v1/Customer/customer/partialPayment`)
      if (res?.code === 200) {
        setPartialpaymen(res?.data)
        dataFetched.current = false;
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const getcustomerdeteails = async () => {
    try {
      let postdata = {
        "customer_id": state?.customer_id,

      }
      let res = await _Api(postdata, `api/v1/Customer/customer/customerData`)
      if (res?.code === 200) {
        setCustomerdetails(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const partialfilter = async () => {
    try {
      let postdata = {
        "customer_id": state?.customer_id,
        "type": paytype ? paytype : "ALL",
      }
      let res = await _Api(postdata, `api/v1/Customer/customer/partialLayByPaymentFilter`)
      if (res?.code === 200) {
        setFilterdata(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      gethistorypartialpayment()
      getcustomerdeteails()
      partialfilter()
    }
    // eslint-disable-next-line
  }, [paytype, datefilter, cusid, shortobj])

  const setSubTablecustom = async (id, type) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector(`common-main-table-wrap-${id}`);
    var classList_ = e.classList;
    if (classList_.contains("rotate-180")) {
      classList_.remove("rotate-180");
      let alllist_data = [...partialpaymen];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = null
      })
      setPartialpaymen(alllist_data);
    } else {
      classList_.add("rotate-180");
      let alllist_data = [...partialpaymen];
      const postdata = {
        "id": id,
        "type": type
      };
      let prioritydata = await _Api(postdata, `api/v1/Customer/customer/transactionHistory`);
      if (prioritydata?.code === 200) {
        alllist_data.filter(item => item.id === id).forEach((result) => {
          result.subTabledata = prioritydata?.data
        })
        setPartialpaymen(alllist_data);
        var checkRowSub = setInterval(function () {
          var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
          if (rowSub) {
            $(row).after(rowSub);
            clearInterval(checkRowSub);
          }
        }, 100);
      }
    }
  }
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    header_values.push("#", "Date Time", "Ref No.", "Type ", "Location", "Amount", "Payment", "Outstanding", "Due Date")
    if (partialpaymen.length) {
      partialpaymen.forEach((result, key) => {
        bodyarray.push([key + 1, result?.date, result?.vr_no, result?.order_type, result?.location, result?.amount, result?.payment, result?.outstanding])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "Customer History Partial Payment");
  }

  const hendledropdownhide = (lable) => {
    if (lable === "ordertype") {
      dropdownRefrefno.current.classList.remove("show")
    } else if (lable === "type") {
      dropdownRefType.current.classList.remove("show")
    } else if (lable === "location") {
      dropdownReflocation.current.classList.remove("show")
    }
  }
  const getscarchvalue = async (event, lable) => {
    if (lable === "ordertype") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...filterdata?.order_no];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setFilterdata(prevState => ({
            ...prevState,
            order_no: arr
          }));
        });
      } else {
        partialfilter()
      }
    } else if (lable === "type") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...filterdata?.Type];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.value?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setFilterdata(prevState => ({
            ...prevState,
            Type: arr
          }));
        });
      } else {
        partialfilter()
      }
    } else if (lable === "location") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...filterdata?.location];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setFilterdata(prevState => ({
            ...prevState,
            location: arr
          }));
        });
      } else {
        partialfilter()
      }
    }
  }
  const handleCheckAllChange = (e, lable) => {
    if (lable === "ordertype") {
      if (e.target.checked) {
        let orderfilter = filterdata?.order_no.map((c) => c)
        setcheckedfilter(prevState => ({
          ...prevState,
          order_no: orderfilter
        }));
      } else {
        setcheckedfilter(prevState => ({
          ...prevState,
          order_no: []
        }));
      }
    } else if (lable === "type") {
      if (e.target.checked) {
        let orderfilter = filterdata?.Type.map((c) => c?.value)
        setcheckedfilter(prevState => ({
          ...prevState,
          Type: orderfilter
        }));
      } else {
        setcheckedfilter(prevState => ({
          ...prevState,
          Type: []
        }));
      }
    } else if (lable === "location") {
      if (e.target.checked) {
        let locationfilter = filterdata?.location.map((c) => c?._id)
        setcheckedfilter(prevState => ({
          ...prevState,
          location: locationfilter
        }));
      } else {
        setcheckedfilter(prevState => ({
          ...prevState,
          location: []
        }));
      }
    }
  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "ordertype") {
      if (event.target.checked) {
        let filterorder = [...checkedfilter?.order_no]
        filterorder.push(data)
        setcheckedfilter(prevState => ({
          ...prevState,
          order_no: filterorder
        }));
      } else {
        setcheckedfilter(prevState => ({
          ...prevState,
          order_no: checkedfilter?.order_no.filter((item) => item !== data)
        }));
      }
    } else if (lable === "type") {
      if (event.target.checked) {
        let filterorder = [...checkedfilter?.Type]
        filterorder.push(data)
        setcheckedfilter(prevState => ({
          ...prevState,
          Type: filterorder
        }));
      } else {
        setcheckedfilter(prevState => ({
          ...prevState,
          Type: checkedfilter?.Type.filter((item) => item !== data)
        }));
      }
    } else if (lable === "location") {
      if (event.target.checked) {
        let filtelocation = [...checkedfilter?.location]
        filtelocation.push(data)
        setcheckedfilter(prevState => ({
          ...prevState,
          location: filtelocation
        }));
      } else {
        setcheckedfilter(prevState => ({
          ...prevState,
          location: checkedfilter?.location.filter((item) => item !== data)
        }));
      }
    }
  };

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  return (
    <React.Fragment>
      <div className="main-body main-content-wrapper-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
              <div className="main-content-wrapper-body-top-bar-left">
                <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                  {t("Customer")} <RightArrow /><Link className="text-decoration-none" to="/app/admin/customers">{t("Customers")} </Link> <RightArrow /> <span>{t("History")}</span>
                </h1>
              </div>
            </div>
            <div className="customer-history-tabs">
              <Link className="customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none me-2"
                to="/app/admin/customers/HistoryPayment"
                state={{ customer_id: state?.customer_id }}>{t("Payment")}</Link>
              <Link className="customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none mx-2 "
                to="/app/admin/customers/historypurchase"
                state={{ customer_id: state?.customer_id }}>{t("Purchase")}</Link>
              <Link className="active customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none mx-2" to="/app/admin/customers/historypartialpayment"
                state={{ customer_id: state?.customer_id }}> {t("Partial Payment")}</Link>
              <Link className="customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none mx-2"
                to="/app/admin/customers/historycreditnote"
                state={{ customer_id: state?.customer_id }}>{t("Credit Note")}</Link>
            </div>
            <div className="customer-history-inner-ct bg-white pb-4">
              <CustomerInformation
                cusid={cusid}
                setCusid={setCusid} />
              <div className="customer-history-inner-ct-top-bar d-flex align-items-center px-4">
                <img className="me-3 customer-history-inner-ct-top-bar-img" src={customerdetails?.profile} alt="" />
                <span className="customer-history-inner-ct-top-bar-name bg-transparent border-0 p-0 link-color cursor-pointer"
                  data-bs-toggle="modal" data-bs-target="#customerAddModal"
                  onClick={() => { setCusid(state?.customer_id) }}
                >{customerdetails?.name ? customerdetails?.name : "-"}</span>
                {customerdetails?.group === "Platinum" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : customerdetails?.group === "Gold" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/gold-medal.png"} alt="" /> : customerdetails?.group === "Silver" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/silver-medal.png"} alt="" /> : customerdetails?.group === "Diamond" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : ""}
                <i className="fa fa-smile-o mx-3 fs-5 lighgreencolor" aria-hidden="true"></i>
                <span className="d-flex align-items-center"><img src={base_assets + "images/icons/gray-location.png"} alt="" />
                  {" - "}  {customerdetails?.location ? customerdetails?.location : 0}</span>
                <span className="ms-auto gray-mid">{customerdetails?.country ? customerdetails?.country : ""}</span>
              </div>
              <div className="px-4">
                <ul className="nav nav-tabs customer-history-inner-ct-tabs mt-5 mb-4 border-0">
                  <li className="nav-item">
                    <a className="nav-link text-center fw-semibold border-0 active" data-bs-toggle="tab" href="#all"
                      onClick={() => setpaytype('ALL')}>{t("All")}</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-center fw-semibold border-0" data-bs-toggle="tab" href="#layby"
                      onClick={() => setpaytype('LayBy')}>{t("Layby")}</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-center fw-semibold border-0" data-bs-toggle="tab" href="#installment"
                      onClick={() => setpaytype('Installment')}>{t("Installment")}</a>
                  </li>
                </ul>
              </div>
              <div className="main-body-top-status-bar mb-3 mt-4 px-4 main-content-wrapper-body-top-status-bar main-content-wrapper-body-top-bar-right">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); gethistorypartialpayment(e.value) }}
                  isSearchable={false}
                />
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 justify-content-center bg-white toggle-btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item print-dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>
                      <li className="dropdown-item"
                        onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div className={`tab-pane p-0 container ${paytype === 'ALL' ? "active" : ""}`} id="all">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                                  ref={dropdownRefrefno}>
                                  <div className="asc-des-order">
                                    <div onClick={() => {
                                      setShortobj({ key: "order_no", value: 1 });
                                      hendledropdownhide("ordertype")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => {
                                      setShortobj({ key: "order_no", value: -1 });
                                      hendledropdownhide("ordertype")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "ordertype")}
                                        onChange={(e) => setSearchVal(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="ordertypeallcheck"
                                          onChange={(e) => handleCheckAllChange(e, "ordertype")}
                                          checked={checkedfilter?.order_no?.length === filterdata?.order_no?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="ordertypeallcheck">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.order_no?.length}/{filterdata?.order_no?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.order_no?.length ? filterdata?.order_no.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"ordertype" + key}
                                              checked={
                                                checkedfilter?.order_no?.length ? checkedfilter?.order_no.includes(result) : ""
                                              }
                                              onChange={(e) => handlefilerChange(e, result, "ordertype")} />
                                            <label className="form-check-label cursor-pointer" htmlFor={"ordertype" + key}>{result}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                                  >
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          order_no: []
                                        })); setShortobj({}); hendledropdownhide("ordertype")
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => {
                                      hendledropdownhide("ordertype");
                                      gethistorypartialpayment()
                                    }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{("Type")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                                  ref={dropdownRefType}>
                                  <div className="asc-des-order">
                                    <div onClick={() => {
                                      setShortobj({ key: "Type", value: 1 });
                                      hendledropdownhide("type")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => {
                                      setShortobj({ key: "Type", value: -1 });
                                      hendledropdownhide("type")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "type")}
                                        onChange={(e) => setSearchVal(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="typeallcheck"
                                          onChange={(e) => handleCheckAllChange(e, "type")}
                                          checked={checkedfilter?.Type?.length === filterdata?.Type?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="typeallcheck">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.Type?.length}/{filterdata?.Type?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.Type?.length ? filterdata?.Type.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"type" + key}
                                              checked={
                                                checkedfilter?.Type?.length ? checkedfilter?.Type.includes(result?.value) : ""
                                              }
                                              onChange={(e) => handlefilerChange(e, result?.value, "type")} />
                                            <label className="form-check-label cursor-pointer" htmlFor={"type" + key}>
                                              {result?.lable}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                                  >
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          Type: []
                                        })); setShortobj({}); hendledropdownhide("type")
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => {
                                      hendledropdownhide("type");
                                      gethistorypartialpayment()
                                    }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{t("Location")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                                  ref={dropdownReflocation}>
                                  <div className="asc-des-order">
                                    <div onClick={() => { setShortobj({ key: "location", value: 1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => { setShortobj({ key: "location", value: -1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt=""
                                      />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "location")}
                                        onChange={(e) => setSearchVal(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                          onChange={(e) => handleCheckAllChange(e, "location")}
                                          checked={checkedfilter?.location?.length === filterdata?.location?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.location?.length}/{filterdata?.location?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.location?.length ? filterdata?.location.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox"
                                              id={result?._id + key}
                                              onChange={(e) => handlefilerChange(e, result?._id, "location")}
                                              checked={
                                                checkedfilter?.location ? checkedfilter?.location.includes(result?._id) : ""
                                              } />
                                            <label className="form-check-label cursor-pointer" htmlFor={result?._id + key}>{result?.name}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setShortobj({}); hendledropdownhide("location");
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          location: []
                                        }))
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { gethistorypartialpayment(); hendledropdownhide("location") }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{t("Amount")}</th>
                            <th>{t("Payment")}</th>
                            <th>{t("Outstanding")} </th>
                            <th>{t("Due Date")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          {partialpaymen.length ? partialpaymen.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <tr className="text-nowrap" key={key}>
                                  <td>{key + 1}</td>
                                  <td>{result?.date ? result?.date : ""}</td>
                                  <td>{result?.vr_no ? result?.vr_no : ""}</td>
                                  <td>{result?.order_type ? result?.order_type : ""}</td>
                                  <td>{result?.location ? result?.location : ""}</td>
                                  <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                  <td
                                    className="sku-col table_btn_expand_col col-highlight"
                                    id={"table-btn_" + result?.id}
                                  >
                                    <div className="td-icons-wrap">
                                      <div>
                                        <span>
                                          {result?.payment ? result?.payment : "0"}</span>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            "images/admin/icons/expand-icon.png"
                                          }
                                          alt="" onClick={() => setSubTablecustom(result?.id, result?.order_type)}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                  <td>{result?.due_date ? result?.due_date : ""}</td>
                                </tr>
                                <tr className={result.subTabledata?.length ? "com-custom-table-tag-subtable" : "com-custom-table-tag-subtable d-none"}>
                                  <td colSpan="100">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{t("Due Date")}</th>
                                          <th>{t("Receive")}</th>
                                          <th>{t("Amount")}</th>
                                          <th>{t("Outstanding")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {result.subTabledata?.length ? result.subTabledata.map((index, skey) => {
                                          return (
                                            <tr key={skey}>
                                              <td>{skey + 1}</td>
                                              <td>{index?.due_date ? index?.due_date : "-"}</td>
                                              <td>{index?.receive_date ? index?.receive_date : "-"}</td>
                                              <td>{toFormatPrice(index?.payment_receive, { addSymbol: true })}</td>
                                              <td>{toFormatPrice(index?.outstanding, { addSymbol: true })}</td>
                                            </tr>
                                          )
                                        }) : ""}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane p-0 container fade" id="layby">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                                  ref={dropdownRefrefno}>
                                  <div className="asc-des-order">
                                    <div onClick={() => {
                                      setShortobj({ key: "order_no", value: 1 });
                                      hendledropdownhide("ordertype")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => {
                                      setShortobj({ key: "order_no", value: -1 });
                                      hendledropdownhide("ordertype")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "ordertype")}
                                        onChange={(e) => setSearchVal(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="ordertypeallcheck"
                                          onChange={(e) => handleCheckAllChange(e, "ordertype")}
                                          checked={checkedfilter?.order_no?.length === filterdata?.order_no?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="ordertypeallcheck">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.order_no?.length}/{filterdata?.order_no?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.order_no?.length ? filterdata?.order_no.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"ordertype" + key}
                                              checked={
                                                checkedfilter?.order_no?.length ? checkedfilter?.order_no.includes(result) : ""
                                              }
                                              onChange={(e) => handlefilerChange(e, result, "ordertype")} />
                                            <label className="form-check-label cursor-pointer" htmlFor={"ordertype" + key}>{result}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                                  >
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          order_no: []
                                        })); setShortobj({}); hendledropdownhide("ordertype")
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => {
                                      hendledropdownhide("ordertype");
                                      gethistorypartialpayment()
                                    }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{t("Type")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                                  ref={dropdownRefType}>
                                  <div className="asc-des-order">
                                    <div onClick={() => {
                                      setShortobj({ key: "Type", value: 1 });
                                      hendledropdownhide("type")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => {
                                      setShortobj({ key: "Type", value: -1 });
                                      hendledropdownhide("type")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "type")}
                                        onChange={(e) => setSearchVal(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="typeallcheck"
                                          onChange={(e) => handleCheckAllChange(e, "type")}
                                          checked={checkedfilter?.Type?.length === filterdata?.Type?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="typeallcheck">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.Type?.length}/{filterdata?.Type?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.Type?.length ? filterdata?.Type.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"type" + key}
                                              checked={
                                                checkedfilter?.Type?.length ? checkedfilter?.Type.includes(result?.value) : ""
                                              }
                                              onChange={(e) => handlefilerChange(e, result?.value, "type")} />
                                            <label className="form-check-label cursor-pointer" htmlFor={"type" + key}>
                                              {result?.lable}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                                  >
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          Type: []
                                        })); setShortobj({}); hendledropdownhide("type")
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => {
                                      hendledropdownhide("type");
                                      gethistorypartialpayment()
                                    }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{t("Location")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                                  ref={dropdownReflocation}>
                                  <div className="asc-des-order">
                                    <div onClick={() => { setShortobj({ key: "location", value: 1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => { setShortobj({ key: "location", value: -1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt=""
                                      />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "location")}
                                        onChange={(e) => setSearchVal(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                          onChange={(e) => handleCheckAllChange(e, "location")}
                                          checked={checkedfilter?.location?.length === filterdata?.location?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.location?.length}/{filterdata?.location?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.location?.length ? filterdata?.location.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox"
                                              id={result?._id + key}
                                              onChange={(e) => handlefilerChange(e, result?._id, "location")}
                                              checked={
                                                checkedfilter?.location ? checkedfilter?.location.includes(result?._id) : ""
                                              } />
                                            <label className="form-check-label cursor-pointer" htmlFor={result?._id + key}>{result?.name}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setShortobj({}); hendledropdownhide("location");
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          location: []
                                        }))
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { gethistorypartialpayment(); hendledropdownhide("location") }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{t("Amount")} </th>
                            <th>{t("Payment")}</th>
                            <th>{t("Outstanding")}</th>
                            <th>{t("Due Date")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {partialpaymen.length ? partialpaymen.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <tr className="text-nowrap" key={key}>
                                  <td>{key + 1}</td>
                                  <td>{result?.date ? result?.date : ""}</td>
                                  <td>{result?.vr_no ? result?.vr_no : ""}</td>
                                  <td>{result?.order_type ? result?.order_type : ""}</td>
                                  <td>{result?.location ? result?.location : ""}</td>
                                  <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                  <td
                                    className="sku-col table_btn_expand_col col-highlight"
                                    id={"table-btn_" + result?.id}
                                  >
                                    <div className="td-icons-wrap">
                                      <div>
                                        <span>
                                          {result?.payment ? result?.payment : "0"}</span>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            "images/admin/icons/expand-icon.png"
                                          }
                                          alt="" onClick={() => setSubTablecustom(result?.id, "lay_by")}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                  <td>{result?.due_date ? result?.due_date : ""}</td>
                                </tr>
                                <tr className={result.subTabledata?.length ? "com-custom-table-tag-subtable" : "com-custom-table-tag-subtable d-none"}>
                                  <td colSpan="100">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{t("Due Date")}</th>
                                          <th>{t("Receive")}</th>
                                          <th>{t("Amount")}</th>
                                          <th>{t("Outstanding")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {result.subTabledata?.length ? result.subTabledata.map((index, skey) => {
                                          return (
                                            <tr key={skey}>
                                              <td>{skey + 1}</td>
                                              <td>{index?.due_date ? index?.due_date : "-"}</td>
                                              <td>{index?.receive_date ? index?.receive_date : "-"}</td>
                                              <td>{toFormatPrice(index?.payment_receive, { addSymbol: true })}</td>
                                              <td>{toFormatPrice(index?.payment_receive, { addSymbol: true })}</td>
                                            </tr>
                                          )
                                        }) : ""}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </React.Fragment >
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane p-0 container fade" id="installment">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                                  ref={dropdownRefrefno}>
                                  <div className="asc-des-order">
                                    <div onClick={() => {
                                      setShortobj({ key: "order_no", value: 1 });
                                      hendledropdownhide("ordertype")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => {
                                      setShortobj({ key: "order_no", value: -1 });
                                      hendledropdownhide("ordertype")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "ordertype")}
                                        onChange={(e) => setSearchVal(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="ordertypeallcheck"
                                          onChange={(e) => handleCheckAllChange(e, "ordertype")}
                                          checked={checkedfilter?.order_no?.length === filterdata?.order_no?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="ordertypeallcheck">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.order_no?.length}/{filterdata?.order_no?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.order_no?.length ? filterdata?.order_no.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"ordertype" + key}
                                              checked={
                                                checkedfilter?.order_no?.length ? checkedfilter?.order_no.includes(result) : ""
                                              }
                                              onChange={(e) => handlefilerChange(e, result, "ordertype")} />
                                            <label className="form-check-label cursor-pointer" htmlFor={"ordertype" + key}>{result}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                                  >
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          order_no: []
                                        })); setShortobj({}); hendledropdownhide("ordertype")
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => {
                                      hendledropdownhide("ordertype");
                                      gethistorypartialpayment()
                                    }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{("Type")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                                  ref={dropdownRefType}>
                                  <div className="asc-des-order">
                                    <div onClick={() => {
                                      setShortobj({ key: "Type", value: 1 });
                                      hendledropdownhide("type")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => {
                                      setShortobj({ key: "Type", value: -1 });
                                      hendledropdownhide("type")
                                    }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "type")}
                                        onChange={(e) => setSearchVal(e.target.value)} />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="typeallcheck"
                                          onChange={(e) => handleCheckAllChange(e, "type")}
                                          checked={checkedfilter?.Type?.length === filterdata?.Type?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="typeallcheck">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.Type?.length}/{filterdata?.Type?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.Type?.length ? filterdata?.Type.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={"type" + key}
                                              checked={
                                                checkedfilter?.Type?.length ? checkedfilter?.Type.includes(result?.value) : ""
                                              }
                                              onChange={(e) => handlefilerChange(e, result?.value, "type")} />
                                            <label className="form-check-label cursor-pointer" htmlFor={"type" + key}>
                                              {result?.lable}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                                  >
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          Type: []
                                        })); setShortobj({}); hendledropdownhide("type")
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => {
                                      hendledropdownhide("type");
                                      gethistorypartialpayment()
                                    }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{t("Location")}
                              <div className="dropdown d-inline-block com-custom-table-tag-filter">
                                <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                                </button>
                                <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                                  ref={dropdownReflocation}>
                                  <div className="asc-des-order">
                                    <div onClick={() => { setShortobj({ key: "location", value: 1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                    <div onClick={() => { setShortobj({ key: "location", value: -1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-search">
                                    <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                      <img src={base_assets + "images/icons/search-icon.png"} alt=""
                                      />
                                      <input className="border-0" type="text" placeholder="Search"
                                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "location")}
                                        onChange={(e) => setSearchVal(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-select">
                                    <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                      <div>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                          onChange={(e) => handleCheckAllChange(e, "location")}
                                          checked={checkedfilter?.location?.length === filterdata?.location?.length} />
                                        <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                                      </div>
                                      <label>{checkedfilter?.location?.length}/{filterdata?.location?.length} {t("Selected")}</label>
                                    </div>
                                    <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                      {filterdata?.location?.length ? filterdata?.location.map((result, key) => {
                                        return (
                                          <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                            <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox"
                                              id={result?._id + key}
                                              onChange={(e) => handlefilerChange(e, result?._id, "location")}
                                              checked={
                                                checkedfilter?.location ? checkedfilter?.location.includes(result?._id) : ""
                                              } />
                                            <label className="form-check-label cursor-pointer" htmlFor={result?._id + key}>{result?.name}</label>
                                          </li>
                                        )
                                      }) : ""}
                                    </ul>
                                  </div>
                                  <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                      onClick={() => {
                                        setShortobj({}); hendledropdownhide("location");
                                        setcheckedfilter(prevState => ({
                                          ...prevState,
                                          location: []
                                        }))
                                      }}>{t("Clear")}</button>
                                    <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { gethistorypartialpayment(); hendledropdownhide("location") }}>{t("Apply")}</button>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th>{t("Amount")}</th>
                            <th>{t("Payment")}</th>
                            <th>{t("Outstanding")}</th>
                            <th>{t("Due Date")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {partialpaymen.length ? partialpaymen.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <tr className="text-nowrap" key={key}>
                                  <td>{key + 1}</td>
                                  <td>{result?.date ? result?.date : ""}</td>
                                  <td>{result?.vr_no ? result?.vr_no : ""}</td>
                                  <td>{result?.order_type ? result?.order_type : ""}</td>
                                  <td>{result?.location ? result?.location : ""}</td>
                                  <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                  <td
                                    className="sku-col table_btn_expand_col col-highlight"
                                    id={"table-btn_" + result?.id}
                                  >
                                    <div className="td-icons-wrap">
                                      <div>
                                        <span>
                                          {result?.payment ? result?.payment : "0"}</span>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            "images/admin/icons/expand-icon.png"
                                          }
                                          alt="" onClick={() => setSubTablecustom(result?.id, "Installment")}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                  <td>{result?.due_date ? result?.due_date : ""}</td>
                                </tr>
                                <tr className={result.subTabledata?.length ? "com-custom-table-tag-subtable" : "com-custom-table-tag-subtable d-none"}>
                                  <td colSpan="100">
                                    <table width="100%">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>{t("Due Date")}</th>
                                          <th>{t("Receive")}</th>
                                          <th>{t("Amount")}</th>
                                          <th>{t("Outstanding")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {result.subTabledata?.length ? result.subTabledata.map((index, skey) => {
                                          return (
                                            <tr key={skey}>
                                              <td>{skey + 1}</td>
                                              <td>{index?.due_date ? index?.due_date : "-"}</td>
                                              <td>{index?.receive_date ? index?.receive_date : "-"}</td>
                                              <td>{toFormatPrice(index?.payment_receive, { addSymbol: true })}</td>
                                              <td>{toFormatPrice(index?.outstanding, { addSymbol: true })}</td>
                                            </tr>
                                          )
                                        }) : ""}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default HistoryPartialPayment;