import $ from 'jquery';
import 'datatables.net-dt'
import 'datatables.net-fixedcolumns';

export const SetdataTable_old = function (ID = "pos_order_table", fixedColumns = 5, newcolumndata = [], destroy = false) {
  var columns_ = [];
  newcolumndata?.forEach(result => {
    if (result?.status) {
      columns_.push(null);
    } else {
      columns_.push({ className: "d-none" });
    }
  });


  setTimeout(() => {
    let TableID = $('#' + ID);
    let TableOptions = {
      scrollY: "300px",
      scrollX: true,
      scrollCollapse: true,
      paging: false,
      bFilter: false,
      bInfo: false,
      autoWidth: true,
      searching: false,
      fixedColumns: { left: fixedColumns },
      columns: columns_,
    };
    TableID.DataTable(TableOptions);
  }, 100);

};

export const SetdataTable = function (ID = "pos_order_table", fixedColumns = 5, newcolumndata = [], numericsort) {
  var columns_ = [];
  var nonsortarray = [];
  newcolumndata?.forEach((result, key) => {
    if (result?.status) {
      columns_.push({ visible: true });
      if (result?.sort === 0) {
        nonsortarray.push(key);
      }
    } else {
      columns_.push({ visible: false });
    }
  });

  setTimeout(() => {
    var table_opt = {
      destroy: true,
      scrollY: "300px",
      scrollX: true,
      scrollCollapse: true,
      paging: false,
      bFilter: false,
      bInfo: false,
      rowId: [0],
      autoWidth: true,
      searching: false,
      processing: true,
      fixedColumns: { left: fixedColumns },
      columnDefs: [
        {
          "type": "html-num-fmt", "targets": numericsort,

        },
        {
          'targets': nonsortarray,
          'orderable': false,
        }
      ],
    };

    if (columns_.length) {
      table_opt.columns = columns_;
    }


    $('#' + ID).DataTable(table_opt);

  }, 100);
};

export const UnSetdataTable = (ID = "pos_order_table") => {
  var table = $('#' + ID).DataTable();
  table.destroy();
  return true;
};


export const UnClearSetdataTable = (ID = "pos_order_table") => {
  var table = $('#' + ID).DataTable({
    dom: 'Bfrtip',
    retrieve: true,
  });
  table.row()
    .remove()
    .draw();
  return true;
};