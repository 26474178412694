import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { _Api, Api_form } from "../common/api/_call";
import TableColumnModal from "../../components/common/modules/tableColumn";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { poscustomer_head } from "../../components/common/Tablehead";
import "react-datepicker/dist/react-datepicker.css";
import './customer.scss';
import '../../components/common/selectbox/selectBox';
import { success, error, Updateloading, loading } from '../common/helpers/toastify';
import CheckoutBlank from "../checkout/checkoutBlank";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import DeleteModal from "../common/module/deletemodal";
import CustomerSearchDetailModal from "../common/module/CustomerSearchDetailModal";
import { useTranslation } from 'react-i18next';
import { updateCustomer } from "../../source/app/appSlice";
import { useDispatch } from 'react-redux'
import Importmodal from "../common/Modals/Importmodal";
import { Pagination } from "../common/Pagination";
import Showloader from "../common/Showloader";
import { useSelector } from 'react-redux';
import Select from "react-select"
import CustomerInformation from '../../admin/customers/customerInformation/CustomerInformation'
const Customer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [setfilterby] = useState("");
  const [importfile, setImportfile] = useState()
  const [setnewcustomersid] = useState();
  const [limit, setLimit] = useState(100);
  const [Namedata, setNamedata] = useState([]);
  const [countrydata, setCountrydata] = useState([]);
  const [citydata, setCitydata] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [KeyCount, setKeyCount] = useState(0);
  const [customerdetails, setCustomerdetails] = useState();
  const [filter, setFilter] = useState(false);
  const [cusid, setCusid] = useState();
  const [newcolumndata, setnewcolumndata] = useState(poscustomer_head);
  const [cussearchkey, setCussearchkey] = useState('');
  const alphabates = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const [country, setCountry] = useState([])
  const [city, setCity] = useState([])
  const [dataloder, setDataloder] = useState(false)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    callcolumns();
  }, []);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_pos_customerlist",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const clearall = () => {
    let get = document.getElementsByName('clear');
    for (let i = 0; i < get.length; i++) {
      get[i].checked = false;
    }
    setNamedata([]);
    setCountrydata([])
    setCitydata([])
  }
  const checkallName = () => {
    let allletter = document.querySelectorAll(".namescheck");
    for (let i = 0; i < allletter.length; i++) {
      allletter[i].checked = true;
    }
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const callfilters = (event, label) => {
    if (label === "name") {
      const namedata = [...Namedata];
      if (event.target.checked) {
        if (namedata.filter(e => e === event.target.value).length === 0) {
          namedata.push(event.target.value);
          setNamedata(namedata);
        }
      } else {
        setNamedata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    } else if (label === "country") {
      const countryarr = [...countrydata];
      if (event.target.checked) {
        if (countryarr.filter(e => e === event.target.value).length === 0) {
          countryarr.push(event.target.value);
          setCountrydata(countryarr);
        }
      } else {
        setNamedata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }

    } else if (label === "city") {
      const cityarr = [...citydata];

      if (event.target.checked) {
        if (cityarr.filter(e => e === event.target.value).length === 0) {
          cityarr.push(event.target.value);
          setCitydata(cityarr);
        }
      } else {
        setNamedata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    }
  }

  const getlimit = (limit) => {
    setLimit(limit);
    GetcustomersDetails(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    GetcustomersDetails(current, "");

  };
  const GetcustomersDetails = async (current, firstlimit) => {
    UnSetdataTable('customer_table');
    setDataloder(true);
    try {
      setTimeout(async function () {
        const postdata = {
          "name": Namedata,
          "country": countrydata,
          "city": citydata,
          "search": cussearchkey,
          "limit": firstlimit ? firstlimit : limit,
          "skip": current ? (current - 1) * limit : 0,
        };
        let res = await _Api(postdata, `api/v1/Customer/customer/getList`);
        if (res.code === 200) {
          setTimeout(async function () {
            setDataloder(false);

            SetdataTable('customer_table', '4', newcolumndata, [1]);
            setKeyCount(0);
            setCustomerdetails(res?.data);
            setstate({
              ...state,
              totalPages: Math.ceil(res?.total / postdata.limit),
              currentPage: current ? current : 1
            });
          }, 100)

        } else {
          setDataloder(false);
          SetdataTable('customer_table', '4', newcolumndata, [1]);
        }
      }, 100)
    }

    catch (err) {
      console.log(err, 'err');
    }
  }
  const handler_delete = async () => {
    try {
      let GetloadingID = loading();
      let postdata = {
        id: cusid
      }
      let res = await _Api(postdata, `api/v1/APP/customer/delete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        GetcustomersDetails()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (KeyCount === 3) {
      GetcustomersDetails();
    }
    // eslint-disable-next-line
  }, [KeyCount]);
  useEffect(() => {
    if (!cussearchkey) {
      GetcustomersDetails();
    }
    // eslint-disable-next-line
  }, [cussearchkey, cusid]);

  const creditfunction = (name, id) => {
    dispatch(updateCustomer({ id: id, name: name }));
    let jsondata = JSON.stringify({ id: id, name: name });
    localStorage.setItem('customerdetails', jsondata);
  }

  const readExcel = async () => {
    if (!importfile) {
      error(t('Choose file!'));
      return;
    }
    try {
      const formData = new FormData()
      formData.append('file', importfile)
      formData.append('type', 'CustomerImport')
      let res = await Api_form(
        formData,
        `api/v1/App/customer/uploadCustomer`,
      )
      if (res.code === 200) {
        success(
          t('File Sucessfully Uploaded and Backend Process have  Started Now,Please wait!!'),
        )
        GetcustomersDetails();
      } else {
        error(t('Please Wait or try again later!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }

  }

  const getcustomerfiltter = async () => {

    try {
      const postdata = {}
      let res = await _Api(postdata, `api/v1/App/customer/customerFilter`);
      if (res.code === 200) {
        setCountry(res?.data?.country)
        setCity(res?.data?.city)
      }
    }

    catch (err) {
      console.log(err, 'err');
    }
  }
  useEffect(() => {
    getcustomerfiltter();
  }, []);

  const getscarchvalue = async (event, lable) => {
    if (lable === "country") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...country];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setCountry(arr)

        });
      } else {
        getcustomerfiltter()
      }
    }
    else if (lable === "city") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...city];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setCity(arr)

        });
      } else {
        getcustomerfiltter()
      }
    }



  }
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="customer">
              <div className='customer-inner'>
                <div className="customer-inner-tophead">
                  <h4 className="main-heading">{t("Customer")}</h4>
                  <div className='customer-inner-tophead-right'>
                    <div className="com-search-area com-search-area-large bg-white">
                      <input type="text" onKeyDown={(e) => (e.key === 'Enter' && GetcustomersDetails()) || setKeyCount(KeyCount + 1)} value={cussearchkey} onChange={(e) => setCussearchkey(e.target.value)} placeholder={t("Search Customer")} />
                      <img className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                      <button onClick={() => setFilter(!filter)} className="com-search-area-large-filter btn-style" >
                        <img src={base_assets + "images/icons/filter.png"} alt="" />
                      </button>
                    </div>
                    <div className={parmissiondatashow?.pos_customer_reports?.Add ? "customer-inner-tophead-right-custom d-flex" : "d-none"}>
                      <button className='cst-btn img-btn-hover' onClick={() => { setCusid() }} data-bs-toggle="modal" data-bs-target="#customerAddModal">
                        <img src={base_assets + "images/icons/adplus.png"} className='img-btn-hover-img-1' alt='filter' />
                        <img src={base_assets + "images/icons/adplus-w.png"} className='img-btn-hover-img-2' alt='filter' />
                        {t("Customer")}</button>
                      <Link className='cst-btn img-btn-hover text-decoration-none' to="/app/pos/appointment">
                        <img src={base_assets + "images/icons/calendar-green.svg"} className='img-btn-hover-img-1' alt='icon' />
                        <img src={base_assets + "images/icons/white-calander.png"} className='img-btn-hover-img-2' alt='icon' />
                        {t("Appointment")}</Link>
                    </div>

                  </div>
                </div>
                <div className="customer-inner-data">
                  <div className='search'>
                    <div className='search-custom d-flex align-items-center'>
                      <span className='title'>{t("Show")}:</span>
                      <Select
                        className="limit-select short z-10"
                        options={selectOptions}
                        value={{ label: limit, value: limit }}
                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}

                        isSearchable={false}
                      />

                    </div>
                    <div className='data-set'>
                      <button className='db-btn' data-bs-toggle="modal"
                        data-bs-target="#ImportModal"><img src={base_assets + "images/icons/export.png"} className='' alt='export' /></button>
                    </div>
                  </div>
                  <div className="main-body-main-table-wrap vertical-scroll-table withdropdown">
                    <table id="customer_table" className="common-table first-row-gray w-100">
                      <Tablehead tablehead={poscustomer_head} tblName={"tbl_pos_customerlist"} setfilterby={setfilterby} />
                      <tbody>
                        <>
                          {customerdetails ? customerdetails.map((result, key) => {
                            console.log(customerdetails, "get customer details")
                            return (
                              <tr key={key}>
                                <td>
                                  <div className="com-check">
                                    <input id={result?._id} type="checkbox" className='poscustomertablehead' />
                                    <label htmlFor={result?._id} className="com-check-label"></label>
                                  </div>
                                </td>
                                <td>{key + 1}</td>
                                <td>
                                  <div className="pro-img">
                                    <img src={result?.profile ? result?.profile : base_assets + 'images/icons/customer-card-icon.png'} alt="" />
                                  </div>
                                </td>
                                <td>{typeof(result?.customerid) === 'string' ? result?.customerid : ''}</td>
                                <td onClick={() => { setCusid(result?._id) }} data-bs-toggle="modal" data-bs-target="#customerAddModal" className="link-color cursor-pointer">
                                  {result?.name ? result?.name : ''}
                                </td>
                                <td className='crown-img text-start d-flex  p-2'>  {
                                  result?.group === 'Silver' ?
                                    <img className="group-crown" src={base_assets + "images/icons/silver-medal.png"} alt="" /> :
                                    result?.group === 'Gold' ?
                                      <img className="group-crown" src={base_assets + "images/icons/gold-medal.png"} alt="" /> : result?.group === 'Platinum' ?
                                        <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : result?.group === 'Diamond' ?
                                          <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : ''
                                }{result?.group}</td>
                                <td>{result?.phone ? result?.phone : ""}</td>
                                <td>{result?.email ? result?.email : ""}</td>
                                <td>{result?.modified}</td>
                                <td>
                                  <div className="customer-table-dropdown dropdown">
                                    <button className='btn-style dropdown-toggle' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={base_assets + 'images/icons/ellipsis-circular.png'} alt="" /></button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                      <li onClick={() => { setCusid(result?._id) }} data-bs-toggle="modal" data-bs-target="#customerAddModal">
                                        <Link className="dropdown-item img-btn-hover" >
                                          <div className="dropdown-menu-img">
                                            <img className='img-btn-hover-img-1' src={base_assets + 'images/pos/icons/i1.png'} alt="" />
                                            <img className='img-btn-hover-img-2' src={base_assets + 'images/pos/icons/i1w.png'} alt="" />
                                          </div>
                                          {t("Preview")}</Link>
                                      </li>
                                      <li onClick={(e) => creditfunction(result?.name, result?._id)}>
                                        <Link className="dropdown-item img-btn-hover" to="/app/pos/customer/paymentHistory">
                                          <div className="dropdown-menu-img">
                                            <img className='img-btn-hover-img-1' src={base_assets + 'images/pos/icons/i2.png'} alt="" />
                                            <img className='img-btn-hover-img-2' src={base_assets + 'images/pos/icons/i2w.png'} alt="" />
                                          </div>
                                          {t("Payment History")}</Link>
                                      </li>
                                      <li
                                        onClick={(e) => creditfunction(result?.name, result?._id)}>
                                        <Link className="dropdown-item img-btn-hover" to="/app/pos/customer/purchaseHistory">
                                          <div className="dropdown-menu-img">
                                            <img className='img-btn-hover-img-1' src={base_assets + 'images/pos/icons/i3.png'} alt="" />
                                            <img className='img-btn-hover-img-2' src={base_assets + 'images/pos/icons/i3w.png'} alt="" />
                                          </div>
                                          {t("Purchase History")}</Link>
                                      </li>
                                      <li onClick={(e) => creditfunction(result?.name, result?._id)}>
                                        <Link className="dropdown-item img-btn-hover" to="/app/pos/customer/PartialPaymentHistory">
                                          <div className="dropdown-menu-img">
                                            <img className='img-btn-hover-img-1' src={base_assets + 'images/pos/icons/i6.png'} alt="" />
                                            <img className='img-btn-hover-img-2' src={base_assets + 'images/pos/icons/i6w.png'} alt="" />
                                          </div>
                                          {t("Partial Payment History")}</Link>
                                      </li>
                                      <li
                                        onClick={(e) => creditfunction(result?.name, result?._id)}>
                                        <Link className="dropdown-item img-btn-hover" to="/app/pos/customer/CreditNote"
                                        >
                                          <div className="dropdown-menu-img">
                                            <img className='img-btn-hover-img-1' src={base_assets + 'images/pos/icons/i4.png'} alt="" />
                                            <img className='img-btn-hover-img-2' src={base_assets + 'images/pos/icons/i4w.png'} alt="" />
                                          </div>
                                          {t("Credit Note")} </Link>
                                      </li>
                                      <li className={parmissiondatashow?.pos_customer_reports?.Delete ? 'customer-table-dropdown-last-border' : "d-none"} data-bs-toggle="modal" data-bs-target="#delete-listedModal" onClick={() => setCusid(result?._id)} >
                                        <Link className="dropdown-item img-btn-hover" to="#">
                                          <div className="dropdown-menu-img">
                                            <img className='img-btn-hover-img-1' src={base_assets + 'images/pos/icons/i5.png'} alt="" />
                                            <img className='img-btn-hover-img-2' src={base_assets + 'images/pos/icons/i5w.png'} alt="" />
                                          </div>
                                          {t("Delete")}</Link>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          }) : ""}
                        </>
                      </tbody>
                    </table>
                  </div>

                </div>

              </div>
              {customerdetails ? (
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  pagination={(crPage) => handlePaginations(crPage)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
      <TableColumnModal tablehead={poscustomer_head} tblName={"tbl_pos_customerlist"} setnewcolumndata={setnewcolumndata}
        calldatatable={GetcustomersDetails}></TableColumnModal>
      <div className='confirmPopupHandler'>
        <CustomerInformation cusid={cusid} setCusid={setCusid} />
      </div>
      {dataloder ? <Showloader /> : ""}
      <CustomerSearchDetailModal setnewcustomersid={setnewcustomersid} setCusid={setCusid} />
      <DeleteModal statement={"Customer"} handler_delete={handler_delete} />
      <Importmodal
        setImportfile={setImportfile}
        readExcel={readExcel}
      />
      <div className={filter ? "customer-search-filter-modal d-flex" : "customer-search-filter-modal"}>
        <div className="modal fade d-block" id="customerSearchFilterModal" aria-labelledby="customerSearchFilterModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button className="clear-btn btn-style link-color" onClick={clearall}>Clear All</button>
                <h5 className="modal-title" id="customerSearchFilterModalLabel">Filter</h5>
                <button type="button" className="btn-close focus-none shadow-none" onClick={() => setFilter(!filter)}></button>
              </div>
              <div className="modal-body">
                <div className="customer-search-filter-modal-row">
                  <div className="customer-search-filter-modal-row-col col">
                    <div className="customer-search-filter-modal-row-col-header">
                      <label className="customer-search-filter-modal-row-col-header-label">Name</label>
                      <button className="customer-search-filter-modal-row-col-header-btn btn-style" onClick={checkallName}>Select All</button>
                    </div>
                    <div className="customer-search-filter-modal-row-col-body alphabates-box">
                      <div className="customer-search-filter-modal-row-col-body-check-data">
                        {alphabates.map((result, key) => {
                          return (

                            <div key={key} className="customer-search-filter-modal-row-col-body-check-data-single">
                              <div className="com-check">
                                <input type="checkbox" id={key} value={result} className='namescheck' name="clear" onClick={(e) => callfilters(e, "name")} />
                                <label className="com-check-label" htmlFor={key}></label>
                              </div>
                              <label htmlFor={key}>{result}</label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="customer-search-filter-modal-row-col col">
                    <div className="customer-search-filter-modal-row-col-header">
                      <label className="customer-search-filter-modal-row-col-header-label">City</label>
                      <button className="customer-search-filter-modal-row-col-header-btn btn-style">Select All</button>
                    </div>
                    <div className="customer-search-filter-modal-row-col-body">
                      <div className="customer-search-filter-modal-row-col-body-search">
                        <img onClick={(e) => getscarchvalue(e, "city")} className="" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                        <input type="text" placeholder="Search"
                          onKeyDown={(e) => e.key === 'Enter' && getscarchvalue(e, "city")}
                          onChange={(e) => setSearchVal(e.target.value)} />
                      </div>
                      <div className="customer-search-filter-modal-row-col-body-check-data">
                        {city?.length ? city.map((result, key) => {
                          return (
                            <div className="customer-search-filter-modal-row-col-body-check-data-single" key={key}>
                              <div className="com-check">
                                <input type="checkbox" id={key + "city"} value={result || ''} name="clear" onClick={(e) => callfilters(e, "city")} />
                                <label className="com-check-label" htmlFor={key + "city"}></label>
                              </div>
                              <label htmlFor={"city" + key}>{result}</label>
                            </div>
                          )

                        })
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="customer-search-filter-modal-row-col col">
                    <div className="customer-search-filter-modal-row-col-header">
                      <label className="customer-search-filter-modal-row-col-header-label">Country</label>
                      <button className="customer-search-filter-modal-row-col-header-btn btn-style">Select All</button>
                    </div>
                    <div className="customer-search-filter-modal-row-col-body">
                      <div className="customer-search-filter-modal-row-col-body-search">
                        <img onClick={(e) => getscarchvalue(e, "country")} className="" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                        <input type="text" placeholder="Search" onKeyDown={(e) => e.key === 'Enter' && getscarchvalue(e, "country")}
                          onChange={(e) => setSearchVal(e.target.value)} />
                      </div>
                      <div className="customer-search-filter-modal-row-col-body-check-data">
                        {country?.length ? country.map((result, key) => {
                          return (
                            <div className="customer-search-filter-modal-row-col-body-check-data-single" key={key}>
                              <div className="com-check">
                                <input type="checkbox" id={key + "country"} value={result?.id} name="clear" onClick={(e) => callfilters(e, "country")} />
                                <label className="com-check-label" htmlFor={key + "country"}></label>
                              </div>
                              <label htmlFor={key + "country"}>{result?.name ? result?.name : ''}</label>
                            </div>
                          )
                        }) : ""

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-style" onClick={() => { setFilter(!filter); GetcustomersDetails() }}>{t("Apply")}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Customer;