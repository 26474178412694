import React, { useState, useEffect } from "react"
import { _Api } from "../api/_call";
import { SetdataTable, UnSetdataTable } from "../api/setdatatable";
import { Link, useLocation } from 'react-router-dom';
import { DateRange } from "../common/helpers/daterange";
import SocialModal from "../common/modules/SocialModal/SocialModal";
import { reportvendorpurchaseorderlisthead } from "../common/Tablehead";
import DateTime from "../common/DateTime";
import TableColumnModal from "../common/modules/tableColumn";
import { Tablehead } from "../common/modules/Tablehead";
import "./report.scss";
import { toFormatPrice, toUnformatPrice } from "../common/helpers/function";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import Select from "react-select";
const VendorPurchaseOrder = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [socialurl, setSocialurl] = useState();
  const [filtername, setFiltername] = useState('');
  const [limit, setLimit] = useState(100);
  const [newcolumndata, setnewcolumndata] = useState(reportvendorpurchaseorderlisthead);
  const [datefilter, setDatefilter] = useState([]);
  const [vendorpurchaseorderlist, setVendorpurchaseorderlist] = useState([]);
  const [shareloader, setShareloader] = useState(false);
  const [location, setLoccation] = useState()
  const [name, setName] = useState()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [datalodaer, setDatsLodaer] = useState(false)
  const getsharevendorpurchaseorder = async () => {
    setShareloader(true);
    try {
      const postdata = {
        "supplier_id": state ? state?.vendor_id : "",
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/vendorPO`);

      if (res.code === 200) {
        setSocialurl(res?.link);
        setShareloader(false);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }

  }
  const getprintvendorpurchaseorder = async () => {
    let GetloadingID = loading();
    try {
      const postdata = {

        "supplier_id": state ? state?.vendor_id : "",
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/vendorPO`);
      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a');
          exportLinkElement.hidden = true;
          exportLinkElement.download = res?.name ? res?.name : "report.pdf";
          exportLinkElement.href = res?.link;
          exportLinkElement.text = "downloading...";
          exportLinkElement.setAttribute('target', '_blank');
          document.body.appendChild(exportLinkElement);
          exportLinkElement.click();
          exportLinkElement.remove();
          Updateloading(t, GetloadingID, t("download successfully"));

        }, 50)
        setSocialurl(res?.link);
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, t('error'));
    }

  }
  const filterbyname = (search) => {
    if (filtername) {
      getvendorpurchaseorder(limit, search);
    } else {
      getvendorpurchaseorder(limit, search);
    }
  }
  useEffect(() => {
    callcolumns();
  }, []);

  const getvendorpurchaseorder = async (firstlimit, search) => {
    UnSetdataTable("vendor_purchaseorder_table")
    setDatsLodaer(true)
    try {
      const postdata = {
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : "",
        "vendorId": state ? state?.vendor_id : ''
      };
      let res = await _Api(postdata, `api/v1/Inventory/report/vendorPurchaseOrder`);
      if (res.code === 200) {
        setVendorpurchaseorderlist(res.data);
        SetdataTable("vendor_purchaseorder_table", '', newcolumndata, [0]);
        setLoccation(res?.location)
        setName(res?.name)
        setDatsLodaer(false)
      }
    }
    catch (err) {
      SetdataTable("vendor_purchaseorder_table", '', newcolumndata, [0]);
      console.log(err, 'err');
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "vendor_purchaseorder_table",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      getvendorpurchaseorder();
    }
    // eslint-disable-next-line 
  }, [datefilter]);
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    reportvendorpurchaseorderlisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (vendorpurchaseorderlist.length) {
      vendorpurchaseorderlist.forEach((result, key) => {
        bodyarray.push([key + 1, result?.date, result?.po_no, result?.shipto, result?.qty, result?.amount])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "report_vendor");

  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <React.Fragment>
      <div className="main-body report-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link to="/app/inventory/report/vendor" className="page-back-btn"><img src={base_url_assets + "images/icons/arrow-left.png"} alt="back" /></Link>
                  <h2 className="main-body-heading">{t("Vendor Report")}</h2>
                </div>
              </div>
              <div className='report-location-header'>
                <span className='report-location-header-location'>{location}</span>
                <span className='report-location-header-divider'>|</span>
                <span className='report-location-header-location-sub'> {name}</span>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div
                  className="main-body-top-tab-bar-left-col nav nav-pills"
                  role="tablist"
                >
                  <Link to=""
                    className="main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh active"

                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        className="tab-hover-img-1"
                        src={base_url_assets + "images/icons/price-list-icon.png"}
                        alt=""
                      />
                      <img
                        className="tab-hover-img-2"
                        src={base_url_assets + "images/icons/price-list-icon-green.png"}
                        alt=""
                      />
                      {t("Purchase Order")}
                    </span>
                  </Link>
                  <Link to="/app/inventory/report/vendorPurchase"
                    state={{ vendor_id: state?.vendor_id }}

                    className="main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh"

                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        className="tab-hover-img-1"
                        src={base_url_assets + "images/icons/replenish-icon.png"}
                        alt=""
                      />
                      <img
                        className="tab-hover-img-2"
                        src={base_url_assets + "images/icons/replenish-icon-green.png"}
                        alt=""
                      />
                      {t("Purchase")}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="tab-content">
                <div id="replenish_order" className="tab-pane active">

                  <div className="main-body-top-status-bar">
                    <Select
                      className="limit-select short z-10"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); getvendorpurchaseorder(e.value) }}
                    />
                    <div className="main-body-top-status-bar-filter">
                      <input
                        type="text"
                        className="main-body-top-status-bar-filter-input"
                        placeholder={t("Product name/Stock Id/SKU/Customer/Barcode")}
                        onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                        onChange={(e) => setFiltername(e.target.value)}
                      />
                      {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_url_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                      <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                        <i className="fa fa-search" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="main-body-top-status-bar-multi-date-picker">
                      <DateRange setDatefilter={setDatefilter} />
                      <button className="print-export-dropdown-selected-icon d-none">
                        <img
                          src={base_url_assets + "images/icons/printer-icon.png"}
                          alt=""
                        />
                      </button>
                      <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {" "}
                          <img
                            src={base_url_assets + "images/icons/ellipsis-circular.png"}
                            alt=""
                          />{" "}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className={parmissiondatashow?.inventory_reports?.Print ? "dropdown-item print-dropdown-item" : "d-none"} onClick={(e) => { getprintvendorpurchaseorder(e.target.value); }}>
                            <div className="dropdown-item-img">
                              <img
                                src={base_url_assets + "images/icons/printer-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("print")}</span>
                          </li>
                          <li className="dropdown-item" onClick={(e) => { downloadexclsheet(e.target.value); }}>
                            <div className="dropdown-item-img">
                              <img
                                src={base_url_assets + "images/icons/export-x-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("export")}</span>
                          </li>
                          {socialurl ?
                            <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#socialModal">
                              <div className="dropdown-item-img">
                                <img
                                  src={base_url_assets + "images/icons/share-icon.png"}
                                  alt=""
                                />
                              </div>
                              <span >{t("share")}</span>
                            </li>
                            :
                            <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#socialModal" onClick={() => getsharevendorpurchaseorder()}>
                              <div className="dropdown-item-img">
                                <img
                                  src={base_url_assets + "images/icons/share-icon.png"}
                                  alt=""
                                />
                              </div>
                              <span >{t("share")}</span>
                            </li>
                          }

                          <li
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#columnModal"
                          >
                            <div className="dropdown-item-img">
                              <img
                                src={base_url_assets + "images/icons/column-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("column")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="common-sub-table-wrap add-row">
                    <div className="main-body-main-table-wrap position-relative">
                      <table
                        id="vendor_purchaseorder_table"
                        className="common-table product-base-table w-100"
                      >
                        <Tablehead
                          tablehead={reportvendorpurchaseorderlisthead}
                          tblName={"vendor_purchaseorder_table"}
                          setfilterby={""}
                        />
                        <tbody>
                          <>
                            {vendorpurchaseorderlist ? vendorpurchaseorderlist.map((result, key) => {
                              return (
                                <tr className="" key={key}>
                                  <td className="fixed-side">{key + 1}</td>
                                  <td className="fixed-side">{result?.date ? result?.date : ''}</td>
                                  <td className="fixed-side  link-color">{result?.po_no ? result?.po_no : ''}</td>
                                  <td className="fixed-side">{result?.shipto ? result?.shipto : ''}</td>
                                  <td className="fixed-side">{result?.qty ? result?.qty : ''}</td>
                                  <td className="fixed-side">{result?.amount ? result?.amount : ''}</td>
                                </tr>
                              );
                            }) : ''}
                          </>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td >{vendorpurchaseorderlist
                              ? vendorpurchaseorderlist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val.qty)),
                                0
                              )
                              : ""}</td>
                            <td >{
                              toFormatPrice(vendorpurchaseorderlist
                                ? vendorpurchaseorderlist?.reduce(
                                  (interation, val) =>
                                  (interation =
                                    interation +
                                    toUnformatPrice(val?.amount)),
                                  0
                                )
                                : "", { addSymbol: true })
                            }</td>
                          </tr>
                        </tfoot>
                      </table>
                      <div id="table_loader" className={datalodaer ? "mt-4" : "d-none"}>
                        <div className="dots">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
      </div>
      <TableColumnModal tablehead={reportvendorpurchaseorderlisthead} tblName={"vendor_purchaseorder_table"} setnewcolumndata={setnewcolumndata} calldatatable={getvendorpurchaseorder} ></TableColumnModal>



    </React.Fragment>
  );


}

export default VendorPurchaseOrder;