import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DateTime from '../../common/DateTime'
import { allocationlisttablehead } from '../../common/Tablehead'
import TableColumnModal from '../../common/modules/tableColumn'
import { Tablehead } from '../../common/modules/Tablehead'
import { _Api } from '../../api/_call'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import SocialModal from '../../common/modules/SocialModal/SocialModal'
import { DateRange } from '../../common/helpers/daterange'
import ProductImageSliderModal from '../../common/modules/productImageslider'
import { loading, Updateloading } from '../../common/helpers/toastify'
import { Pagination } from '../../common/Pagination'
import { useTranslation } from 'react-i18next'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import Select from "react-select";
import { formatDate } from '../../common/helpers/formatDate'
const AllocationList = (props) => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const navigate = useNavigate()
  const [limit, setLimit] = useState(100)
  const [imageid, setImageid] = useState()
  const [datefilter, setDatefilter] = useState([])
  const [filtername, setFiltername] = useState('')
  const [allocationlistpo, setAllocationlistpo] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(allocationlisttablehead)
  const [product_id, setProduct_id] = useState()
  console.log(product_id, "product_id")
  const [ShowSubmitbutton, setShowSubmitbutton] = useState(true)
  const [customer_id, setCustomer_id] = useState()
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const deallcocation = async (event) => {
    setShowSubmitbutton(false)
    let GetloadingID = loading()
    try {
      const postdata = {
        po_product_id: product_id,
        customOrderId: customer_id,
        type: event,
      }

      let res = await _Api(postdata, `api/v1/Inventory/po/deallocation`)
      if (res.code === 200) {
        setShowSubmitbutton(true)
        Updateloading(t, GetloadingID, t('Deallocation susses!!'))
        const timer = setTimeout(() => {
          navigate('/app/inventory/allocationpo')
        }, 100)
        return () => clearTimeout(timer)
      } else {
        setShowSubmitbutton(true)
        Updateloading(t, GetloadingID, t('No item Selected!!'), 'error')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const filterbyname = (search) => {
    getallocationlist(currentPage, limit, search)
    setstate({ ...state, currentPage: 1 })
  }
  const getlimit = (limit) => {
    setLimit(limit)
    getallocationlist(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })

  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    getallocationlist(current, '')
  }
  const getallocationlist = async (current, firstlimit, search) => {
    setDatsLodaer(true)
    UnSetdataTable('tbl_po_allocationlist')
    try {
      const postdata = {
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        skip: current ? (current - 1) * limit : 0,
      }
      let resPoallocationlist = await _Api(postdata, `api/v1/inventory/po/allocatedList`)
      if (resPoallocationlist?.code === 200) {
        setTimeout(() => {
          SetdataTable('tbl_po_allocationlist', 7, newcolumndata, 0)
        }, 100)
        setstate({
          ...state,
          totalPages: Math.ceil(resPoallocationlist?.count / postdata.limit),
          currentPage: current ? current : 1,
        })
        setAllocationlistpo(resPoallocationlist?.data)
        setDatsLodaer(false)
      } else {
        setTimeout(() => {
          SetdataTable('tbl_po_allocationlist', 7, newcolumndata, 0)
        }, 100)
        setAllocationlistpo(resPoallocationlist?.data)
        setDatsLodaer(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_po_allocationlist',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      getallocationlist()
    }
    // eslint-disable-next-line
  }, [datefilter])
  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    allocationlisttablehead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (allocationlistpo.length) {
      allocationlistpo.map((result, key) => {
        return (
          bodyarray.push([
            '',
            key + 1,
            result?.po_product?.main_image,
            result?.po_product?.SKU,
            result?.createdAt,
            result?.po_product?.po_no,
            result?.po_product?.pos?.ref,
            result?.po_product?.customer_name,
            result?.delivery_date,
            result?.delivery_date,
            result?.po_product?.location_name,
            result?.po_product?.po_QTY,
            result?.po_product?.status?.priority === 0
              ? 'Low'
              : result?.po_product?.status?.priority === 1
                ? 'Medium'
                : 'High',
            result?.po_product?.remark,
            result?.po_product?.printURL,
          ])
        )
      })
    }
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'allocationlist')
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const productids = (event, id, cart_id) => {
    if (event) {
      setProduct_id(id)
      setCustomer_id(cart_id)
    } else {
      setProduct_id("")
      setCustomer_id("")
    }
  }
  return (
    <React.Fragment>
      <div>
        <div className="main-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="main-body-current-day-time">
                  <DateTime></DateTime>{' '}
                </span>
                <div className="top-heading-area">
                  <div className="top-heading-area-left">
                    <Link to="/app/inventory">
                      <span className="page-back-btn">
                        <img
                          src={base_assets + 'images/icons/arrow-left.png'}
                          alt=""
                        />
                      </span>
                    </Link>
                  </div>
                  <h1 className="main-heading text-center">
                    {t('Allocation')}
                  </h1>
                </div>
                <div className="main-body-top-tab-bar create-purchase-top-bar">
                  <div
                    className="main-body-top-tab-bar-left-col nav nav-pills"
                    role="tablist"
                  >
                    <Link
                      to="/app/inventory/allocationpo"
                      className="main-body-top-tab-bar-left-col-a nav-link"
                    >
                      <span className="main-body-top-tab-bar-left-col-a-text">
                        {' '}
                        <img
                          className="linktwo"
                          src={base_assets + 'images/icons/Link-black.png'}
                          alt=""
                        />
                        <img
                          className="linkone"
                          src={base_assets + 'images/icons/Link.png'}
                          alt=""
                        />{' '}
                        {t('Allocation PO')}
                      </span>
                    </Link>
                    <Link
                      to="/app/inventory/allocatedlist"
                      className="main-body-top-tab-bar-left-col-a nav-link active"
                    >
                      <span className="main-body-top-tab-bar-left-col-a-text">
                        {' '}
                        <img
                          className="usgreen"
                          src={base_assets + 'images/icons/user-green.png'}
                          alt=""
                        />
                        <img
                          className="usblack"
                          src={base_assets + 'images/icons/user.png'}
                          alt=""
                        />{' '}
                        {t('Allocated List')}
                      </span>
                    </Link>
                  </div>
                  <div className="main-body-top-inputs-bar-col-right">
                    <button
                      className={
                        ShowSubmitbutton
                          ? 'main-body-top-inputs-bar-col-right-submit-btn com-btn '
                          : 'main-body-top-inputs-bar-col-right-submit-btn com-btn d-block  deactive'
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#allocation-listedModal"
                    >
                      {t('Submit')}
                    </button>
                  </div>
                </div>
                <div className="tab-content">
                  <div id="replenish_order" className="tab-pane active">
                    <div className="main-body-top-status-bar">
                      <Select
                        className="limit-select short"
                        options={selectOptions}
                        value={{ label: limit, value: limit }}
                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                      />
                      <div className="main-body-top-status-bar-filter">
                        <input
                          type="text"
                          value={filtername}
                          className="main-body-top-status-bar-filter-input"
                          placeholder={t('POS No./StockID/Metal ...')}
                          onKeyPress={(e) =>
                            e.key === 'Enter' && filterbyname(e.target.value)
                          }
                          onChange={(e) => setFiltername(e.target.value)}
                        />
                        {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                        <button
                          onClick={() => filterbyname(filtername)}
                          className="main-body-top-status-bar-filter-search"
                        >
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="main-body-top-status-bar-multi-date-picker">
                        <button className="print-export-dropdown-selected-icon d-none">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </button>
                        <div className=" main-body-top-status-bar-multi-date-picker">
                          <DateRange setDatefilter={setDatefilter} />
                        </div>
                        <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                          <button
                            className="btn dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {' '}
                            <img
                              src={
                                base_assets +
                                'images/icons/ellipsis-circular.png'
                              }
                              alt=""
                            />{' '}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li
                              className="dropdown-item"
                              onClick={() => downloadexclsheet()}
                            >
                              <div className="dropdown-item-img">
                                <img
                                  src={
                                    base_assets +
                                    'images/icons/export-x-icon.png'
                                  }
                                  alt=""
                                />
                              </div>
                              <span>{t('export')}</span>
                            </li>
                            <li
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#columnModal"
                            >
                              <div className="dropdown-item-img">
                                <img
                                  src={
                                    base_assets + 'images/icons/column-icon.png'
                                  }
                                  alt=""
                                />
                              </div>
                              <span>{t('column')}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="common-sub-table-wrap add-row">
                      <div className="main-body-main-table-wrap position-relative">
                        <table
                          id="tbl_po_allocationlist"
                          className="common-table product-base-table w-100"
                        >

                          <Tablehead
                            tablehead={allocationlisttablehead}
                            tblName={'tbl_po_allocationlist'}
                            setfilterby={""}
                          />
                          <tbody>
                            <>
                              {allocationlistpo.length
                                ? allocationlistpo.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td className="row-check">
                                        <div className="com-check radio">
                                          <input
                                            type="radio"
                                            name="allocation_radio"
                                            className="allocationcheck"
                                            id={result.product_id}
                                            onChange={(e) => {
                                              productids(e.target.checked,
                                                result?.product_id, result?.cart_id
                                              )

                                            }}
                                          />
                                          <label
                                            htmlFor={result.product_id}
                                            className="com-check-label"
                                          />
                                        </div>
                                      </td>
                                      <td>{key + 1}</td>
                                      <td>
                                        <div
                                          className="table-pro-img"
                                          onClick={() =>
                                            setImageid(result?.product_id)
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#ProductViewModal"
                                        >
                                          <img
                                            src={
                                              result?.main_image
                                                ? result?.main_image
                                                : '-'
                                            }
                                            alt=""
                                          ></img>
                                        </div>
                                      </td>
                                      <td className="po-no">
                                        {result?.SKU}
                                      </td>
                                      <td>{result?.createdAt}</td>
                                      <td>{result?.po_no}</td>
                                      <td className="fixed-side danger-text po-no">
                                        <div className='tooltip-area-up'>
                                          <div className="td-icons-wrap-user tooltip-area-up">
                                            <img
                                              className="img "
                                              src={
                                                base_assets +
                                                'images/icons/user-red.png'
                                              }
                                              alt=""
                                            />
                                            <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.pos?.ref}<br />{formatDate(result?.pos?.delivery_date)}</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="danger-text po-no">
                                        {result?.pos?.ref}
                                      </td>
                                      <td className="danger-text">
                                        {' '}
                                        {result?.customer_name}
                                      </td>
                                      <td className="danger-text">
                                        {result?.delivery_date}
                                      </td>
                                      <td className="danger-text">
                                        {result?.due_days}
                                      </td>
                                      <td className="danger-text">
                                        {result?.location_name}
                                      </td>
                                      <td className="danger-text">
                                        {result?.po_QTY}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.priority === 0 ? (
                                          <label className="status-tab low-priority">
                                            {t('Low')}
                                          </label>
                                        ) : result?.priority === 1 ? (
                                          <label className="status-tab medium-priority">
                                            {t('Medium')}
                                          </label>
                                        ) : result?.priority === 2 ? (
                                          <label className="status-tab high-priority">
                                            {t('High')}
                                          </label>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td className="text-end">
                                        {result?.remark}
                                      </td>
                                      <td className="row-check">
                                        {result?.url === '' ? (
                                          '-'
                                        ) : (
                                          <div
                                            onClick={() => {
                                              if (result?.url) {
                                                window.open(
                                                  result?.url,
                                                  '_blank',
                                                  'noreferrer',
                                                )
                                              }
                                            }}
                                          >
                                            <img
                                              className="pdf-image"
                                              src={
                                                base_assets +
                                                'images/icons/pdf-icon.png'
                                              }
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                        </table>
                        <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                          <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {allocationlistpo.length > 0 ? (
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        pagination={(crPage) => handlePaginations(crPage)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SocialModal />
        </div>
        <div
          className="modal fade common-modal common-modal-danger"
          id="allocation-listedModal"
          tabIndex={-1}
          aria-labelledby="allocation-listedModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img
                  className="common-modal-exclamation-img"
                  src={base_assets + 'images/icons/exclamation-mark-red.png'}
                  alt=""
                />
                <p className="modal-content-text">
                  {t('Are you sure you want to Free this item ?')}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn modal-content-no"
                  data-bs-dismiss="modal"
                  onClick={(e) => deallcocation('NO')}
                >
                  {t('Order Close')}
                </button>
                <button
                  type="button"
                  className="btn modal-content-yes"
                  data-bs-dismiss="modal"
                  onClick={(e) => deallcocation('YES')}
                >
                  {t('Deallocate')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade common-modal"
          id="transactionallocationModal"
          tabIndex={-1}
          aria-labelledby="transactionallocationLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img
                  className="common-modal-exclamation-img"
                  src={base_assets + 'images/icons/exclamation-mark-green.png'}
                  alt=""
                />
                <span className="modal-content-text">
                  {t('Are you sure you want to change status?')}
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn modal-content-no"
                  data-bs-dismiss="modal"
                >
                  {t('No')}
                </button>
                <button type="button" className="btn modal-content-yes">
                  {t('yes')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade column-modal"
          id="columnModal"
          tabIndex={-1}
          aria-labelledby="columnModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="columnModalLabel">
                  <img
                    className="sku-col-arrow"
                    src={base_assets + 'images/icons/green-column.png'}
                    alt=""
                  />{' '}
                  {t('Column')}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="column-modal-search-area">
                  <div className="column-modal-search-area-wrap">
                    <input
                      placeholder={t('PO No./Supplier/Ship to ...')}
                      type="text"
                    />
                    <button>
                      <img
                        src={base_assets + 'images/icons/search-icon.png'}
                        alt=""
                      />
                    </button>
                  </div>
                  <button className="column-modal-search-area-apply">
                    {t('apply')}
                  </button>
                </div>
                <div className="column-modal-checkbox-area">
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('date')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Qty')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Priority')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('PO No.')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Delivery Date')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Remark')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Supplier')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Due Days')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Created')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Ship to')} </span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Amount')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Modified')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('SKU')}</span>
                  </div>
                  <div className="column-modal-checkbox-area-check">
                    <div className="com-check">
                      <input type="checkbox" />
                      <label className="com-check-label" />
                    </div>{' '}
                    <span>{t('Status')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={allocationlisttablehead}
        tblName={'tbl_po_allocationlist'}
        calldatatable={getallocationlist}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#transactionallocationModal"
        id="status_query_select"
      ></button>
      <ProductImageSliderModal main_id={imageid}></ProductImageSliderModal>
    </React.Fragment>
  )
}
export default AllocationList
