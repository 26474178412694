
import React, { useState, useEffect, useCallback } from "react";
import "./login-signup.scss";
import { useTranslation } from 'react-i18next';
import { _Apiauth } from '../../api/_call';
import { Link } from 'react-router-dom'


const Changepin = () => {
    const { t } = useTranslation();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [forgetpass, setForgetpass] = useState({
        "username": "",
        "storeName": ""
    })
    const [error, setError] = useState({})
    const forgetpin = useCallback(async () => {
        const handleValidation = (e) => {
            setError(prevState => ({
                ...prevState,
                loader: {
                    status: true
                }
            }));
            let formIsValid = true;
            if (!forgetpass?.storeName) {
                formIsValid = false;
                setError(prevState => ({
                    ...prevState,
                    storeName: {
                        message: "Pleace enter store name",
                        status: true
                    }
                }));
                setError(prevState => ({
                    ...prevState,
                    loader: {
                        status: false
                    }
                }));
            } else {
                formIsValid = true;
                setError(prevState => ({
                    ...prevState,
                    storeName: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!forgetpass?.username) {
                formIsValid = false;
                setError(prevState => ({
                    ...prevState,
                    username: {
                        message: "Pleace enter email",
                        status: true
                    }
                }));
                setError(prevState => ({
                    ...prevState,
                    loader: {
                        status: false
                    }
                }));
            } else {
                formIsValid = true;
                setError(prevState => ({
                    ...prevState,
                    username: {
                        message: "",
                        status: false
                    }
                }));
            }
            return formIsValid;
        }
        if (handleValidation()) {
            try {
                let postdata = forgetpass
                let res = await _Apiauth(postdata, `Auth/Web/forgotPin`)
                if (res?.code === 200) {
                    setError(prevState => ({
                        ...prevState,
                        success: {
                            message: res?.message,
                            status: true
                        }
                    }));
                    setError(prevState => ({
                        ...prevState,
                        loader: {
                            status: false
                        }
                    }));
                    setError(prevState => ({
                        ...prevState,
                        success: {
                            message: res?.message,
                            status: true,
                            hidefrom: true
                        }
                    }));
                } else {
                    setError(prevState => ({
                        ...prevState,
                        success: {
                            message: res?.message,
                            status: false,
                            hidefrom: false
                        }
                    }));
                    setError(prevState => ({
                        ...prevState,
                        success: {
                            message: res?.message,
                            status: false
                        }
                    }));
                    setError(prevState => ({
                        ...prevState,
                        loader: {
                            status: false
                        }
                    }));
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }

    }, [forgetpass])

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                forgetpin();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [forgetpin]);
    useEffect(() => {
        let bodyid = document.querySelector('#body_id');
        if (localStorage.getItem("UserLang") === 'SA.png') {
            bodyid.classList.add('rtl');
        } else {
            bodyid.classList.remove('rtl');
        }
    }, [])
    return (
        <div className="main-body bg-white p-0">
            <div className="login-page position-relative d-flex align-items-center justify-content-center">
                <img className="login-page-bg position-absolute end-0 bottom-0 w-75" src={base_assets + "images/login-bg.png"} alt="" />
                <div className="login-page-row d-flex justify-content-center align-items-center w-100">
                    <div className="text-center login-page-left-col">
                        <Link to="/">
                            <img className="login-page-left-col-img" src={base_assets + "images/gis-xl-logo.png"} alt="" />
                        </Link>
                        <span className="d-block mt-4 fs-4 fw-semibold login-page-left-col-text">{t("Start your journey with us ")} <span className="main-green-text">{t(" today")}</span></span>
                    </div>
                    <div className="login-page-right-col">
                        <h1 className="login-page-right-col-heading fs-2 fw-semibold">{t("Forgot PIN")}</h1>
                        {error?.success?.hidefrom ?
                            <div className="login-page-right-col main-green-text">{error?.success?.message}
                                <Link to="/login" className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center mb-4 text-decoration-none">
                                    <span className="h-100 w-100 justify-content-center align-items-center d-flex"> {t("Back to Login")}</span>
                                </Link>
                            </div>
                            :
                            <div className="login-page-right-col-form">
                                <div className="col-md-12">
                                    <div className={`placeholder-up-input w-100 form-floating ${error?.storeName?.status ? "md-1" : "mb-4"}`}>
                                        <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${error?.storeName?.status ? "border-danger" : ""}`} id="" placeholder="Your Store Address Name"
                                            onChange={(e) => setForgetpass(prevState => ({
                                                ...prevState,
                                                storeName: e.target.value
                                            }))} />
                                        <label className="placeholder-up-input-label fs-6" htmlFor="">
                                            {t("Your Store Address Name")}
                                        </label>
                                        <span className="placeholder-up-input-inner-position main-green-text position-absolute">.gis247.net</span>
                                    </div>
                                    {error?.storeName?.status ? <span className="d-block mb-4 text-danger">{error?.storeName?.message ?
                                        t(error?.storeName?.message) : ""}</span> : ""}
                                </div>
                                <div className="col-md-12">
                                    <div className={`placeholder-up-input w-100 form-floating ${error?.username?.status ? "md-1" : "mb-4"}`}
                                        onChange={(e) => setForgetpass(prevState => ({
                                            ...prevState,
                                            username: e.target.value
                                        }))}>
                                        <input type="text" className={`placeholder-up-input-field form-control fs-6 px-3 ${error?.username?.status ? "border-danger" : ""}`} id="" placeholder="Email"
                                        />
                                        <label className="placeholder-up-input-label fs-6" htmlFor="">{t("Email")}</label>
                                    </div>
                                    {error?.username?.status ? <span className="d-block mb-4 text-danger">{error?.username?.message ?
                                        t(error?.username?.message) : ""}</span> : ""}
                                </div>
                                <p>
                                    {error?.success?.status ? <span className="main-green-text">{error?.success?.message}</span> : <span className="text-danger">{error?.success?.message}</span>}
                                </p>
                                <button className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center mb-4">
                                    {error?.loader?.status ?
                                        <div className="border-bottom-0 border-width-2px d-block text-white mx-auto spinner-border flex-shrink-0" role="status"></div> :
                                        <span className="h-100 w-100 justify-content-center align-items-center d-flex" onClick={() => {
                                            forgetpin()
                                        }}> {t("Submit")}</span>
                                    }
                                </button>
                                <p className="login-page-right-col-form-foot mb-0">
                                    <Link to="/loginwithpin" className="main-green-text fw-semibold text-decoration-none">
                                        {t("Back to Login")}</Link>
                                </p>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Changepin;