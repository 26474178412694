import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
export const Importmodal = (props) => {

    const { t } = useTranslation();
    const { setImportfile, readExcel } = props
    const base_url = process.env.REACT_APP_BASE_ASSETS_URL
    useEffect(() => { }, [])

    const SampleExcel = () => {

        const exportLinkElement = document.createElement('a')
        exportLinkElement.hidden = true
        exportLinkElement.download = 'Customers.xlsx'
        exportLinkElement.href = base_url + "Customers.xlsx"
        exportLinkElement.text = 'downloading...'
        document.body.appendChild(exportLinkElement)
        exportLinkElement.click()
        exportLinkElement.remove()
    }


    return (
        <div
            className="modal fade import-modal"
            id="ImportModal"
            tabIndex={-1}
            aria-labelledby="ImportModalLabel"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="import-modal-choose-file">
                            {t("choose file")}
                            <input
                                type="file"
                                onChange={(e) => {
                                    const selectedFile = e.target.files[0];
                                    const allowedExtensions = ['.xls', '.xlsx'];
                                    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

                                    if (!allowedExtensions.includes('.' + fileExtension)) {
                                        alert("Please select an Excel file.");
                                        e.target.value = '';
                                    } else {
                                        setImportfile(selectedFile);
                                    }
                                }}
                                name="files"
                                accept=".xls, .xlsx"
                            />
                        </div>
                    </div>
                    <div id="clickonme" className="modal-footer">
                        <button
                            type="button"
                            onClick={readExcel}
                            data-bs-dismiss="modal"
                            className="btn modal-content-yes"
                        >
                            {t("Submit")}
                        </button>
                        <button

                            onClick={SampleExcel}
                            id="download"
                            type="button"

                            className="btn modal-content-yes"
                        >
                            {t("Download Sample")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Importmodal;
