import React from 'react'

const FIlterIcon = (props) => {
    const { width, height } = props
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.558 7V8H13.423V7H10.558ZM6.808 11.5V12.5H17.173V11.5H6.808ZM4 16V17H20V16H4Z" fill="black" />
        </svg>

    )
}

export default FIlterIcon