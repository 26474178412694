import React, { useState, useEffect } from "react";
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { _Api } from "../api/_call";
import { Pagination } from "../common/Pagination"
import { error, loading, Updateloading } from "../common/helpers/toastify";
import Confirmationmodel from "../common/modules/confirmationmodel"
import { useSelector } from 'react-redux';
import TableLoader from "../common/TableLoader";
const TaxRules = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [editItem, setEditItem] = useState(false);
  const [taxlist, setTexlist] = useState([])
  const [filtername, setFiltername] = useState('');
  const [limit, setLimit] = useState(100);
  const [showloader, setShowloader] = useState(false);
  const [dataid, setDtataid] = useState("")
  const [completeDataObject, setCompliteDataObject] = useState(
    {
      "name": "",
      "label": "",
      "rate": "",
      "type": ""
    }
  )
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const typeOption = [
    {
      value: "Exclusive",
      label: "Exclusive"
    },
    {
      value: "Inclusive",
      label: "Inclusive"
    },
    {
      value: "On Total",
      label: "On Total"
    }
  ]
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  const getlimit = (limit) => {
    setLimit(limit);
    getlist(1, limit);
    setstate({ ...state, currentPage: 1 });
  };
  const filterbyname = () => {
    getlist(currentPage);
    setstate({ ...state, currentPage: 1 });
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getlist(current);

  };
  const getlist = async (current, firstlimit) => {
    setShowloader(true)
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "search": filtername,
        "limit": firstlimit ? firstlimit : limit,
        "skip": skipNumber,
      }
      let res = await _Api(postdata, `api/v1/App/setup/tax-list`)
      if (res?.code === 200) {
        setTexlist(res?.data)
        setShowloader(false)
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getlist()
    // eslint-disable-next-line 
  }, [])

  const addtex = async () => {
    if (!completeDataObject?.name) {
      error(t("Name field is required"))
      return
    }
    if (!completeDataObject?.label) {
      error(t("Lable field is required"))
      return
    }
    if (!completeDataObject?.rate) {
      error(t("Tax-Rate field is required"))
      return
    }
    if (!completeDataObject?.type) {
      error(t("Type field is required"))
      return
    }
    let GetloadingID = loading();
    try {
      let postdata = completeDataObject
      let res;
      if (completeDataObject?._id) {
        res = await _Api(postdata, `api/v1/App/setup/edit-tax`)
      } else {
        res = await _Api(postdata, `api/v1/App/setup/add-tax`)
      }
      if (res.code === 200) {
        setCompliteDataObject({
          "name": "",
          "label": "",
          "rate": "",
          "type": ""
        })
        Updateloading(t, GetloadingID, (res?.message));
        getlist()
        setEditItem(false)
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const taxview = async (id) => {
    try {
      let postdata = {
        id: id
      }
      let res = await _Api(postdata, `api/v1/App/setup/view-tax`)
      if (res?.code === 200) {
        setCompliteDataObject(res?.data)
      }
    }
    catch (err) {
      console.log(err, "Err")
    }
  }

  const deletetax = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        ids: [dataid]
      }
      let res = await _Api(postdata, `api/v1/App/setup/delete-tax`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        getlist()
        setDtataid("")
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">{t("Setup")} <RightArrow /> <span className="">{t("TAX RULES")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <Select
              className="limit-select mx-3"
              options={selectOptions}
              value={{ label: limit, value: limit }}
              onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
            />
            <div className="com-search-bar position-relative">
              <img onClick={filterbyname} className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search" className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                onChange={(e) => setFiltername(e.target.value)} />
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("print")}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export.png"}
                      alt=""
                    />
                  </div>
                  <span >{t("Import")}</span>
                </li>
              </ul>
            </div>
            <button
              className={parmissiondatashow?.gis_setup_tax_rules?.Add ?
                "com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3" : "d-none"} onClick={() => {
                  setEditItem(true); setCompliteDataObject({
                    "name": "",
                    "label": "",
                    "rate": "",
                    "type": ""
                  })
                }}>
              <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className={editItem ? "col-md-6" : "col-md-12"}>
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th>#</th>
                    <th>{t("Name")}</th>
                    <th>{t("Label")}</th>
                    <th>{t("Tax rate")}</th>
                    <th>{t("Type")}</th>
                    <th className="text-center">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {taxlist?.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td>{key + 1}</td>
                        <td>{result?.name ? result?.name : "-"}</td>
                        <td>{result?.label ? result?.label : "-"}</td>
                        <td>{result?.rate ? result?.rate : "0"}</td>
                        <td>{result?.type ? result?.type : "-"}</td>
                        <td className="text-center">
                          <img onClick={() => { setEditItem(true); taxview(result?._id) }} className={parmissiondatashow?.gis_setup_tax_rules?.Edit ? "cursor-pointer me-4" : "d-none"} src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                          <img
                            className={parmissiondatashow?.gis_setup_tax_rules?.Delete ? "cursor-pointer me-4" : "d-none"}
                            data-bs-toggle="modal"
                            data-bs-target="#createPurchaseModal" onClick={() => setDtataid(result?._id)} src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {taxlist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
          <div className={editItem ? "col-md-6 d-block" : "d-none"}>
            <div className="edit-item-area bg-white row">
              <div className="col-md-9 px-0">
                <div className="edit-item-area-body">
                  <div className="row">
                    <div className="col-md-12 ps-0">
                      <div className="row mx-0">
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                            <input type="text" className="v3-input-group-input w-100"
                              value={completeDataObject?.name}
                              onChange={(e) => onChangeValueUpdate("name", e.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Label")} <sup>*</sup></label>
                            <input type="text" className="v3-input-group-input w-100"
                              value={completeDataObject?.label}
                              onChange={(e) => onChangeValueUpdate("label", e.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Tax Rate")} <sup>*</sup></label>
                            <input type="text" className="v3-input-group-input w-100"
                              value={completeDataObject?.rate}
                              onChange={(e) => onChangeValueUpdate("rate", e.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label"> {t("Type")} <sup>*</sup></label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={typeOption}
                              value={{ label: completeDataObject?.type, value: completeDataObject?.type }}
                              onChange={(e) => onChangeValueUpdate("type", e.value)}
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 pe-0">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                  <button className="square-btn square-btn-white btn-close focus-none" onClick={() => setEditItem(false)}></button>
                  <button className="square-btn" onClick={() => addtex()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={deletetax} ></Confirmationmodel>
      </div>
    </>
  )
}

export default TaxRules;