import React from 'react'
import NoProduntImage from '../common/images/NoProuductFound.png'
import { useTranslation } from "react-i18next";
const NoProductAvilable = () => {
    const { t } = useTranslation();
    return (
        <div className=' d-flex justify-content-center  align-content-center w-100 h-100' >
            <div className='justify-content-center align-items-center d-flex flex-column'>

                <img src={NoProduntImage} width={65} height={65} className=' text-center mb-2 ' alt="No Product Available" />
                <div className='ecommerce-recent_view-container-title mb-2 '>
                    {t("No Product yet")}
                </div>
                <p className=' text-center  SizeGuied-subtitle' >
                {t("But keep checking this section from time to time")}
                </p>
            </div>

        </div>
    )
}

export default NoProductAvilable