import React, { useState, useRef, useEffect } from 'react'
import "./common2.scss"
import SideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { _Apiauth } from '../../common/api'
import { useTranslation } from 'react-i18next';
const ResetPassword = (props) => {
    const { setOpenmodal, forgetemail, SetNewpassworddata } = props
    const { t } = useTranslation()
    const [showreasndpassword, setShowresandpassword] = useState(false)
    const [validation, setValidation] = useState()
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
    const [otp, setOtp] = useState()
    const [resettolen, setResettoken] = useState()
    const [lodaer, setLodaer] = useState(false)
    const inputRefs = useRef(Array(6).fill(null).map(() => React.createRef()));
    const handleInput = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].current.focus();
        } else if (value.length === 0 && index > 0) {
            inputRefs.current[index - 1].current.focus();
        }
        setOtp(newInputValues.join(''))

    };
    setTimeout(() => {
        setShowresandpassword(true)
    }, 15000);
    const getresandpassword = async () => {
        try {
            let postdata = { email: forgetemail?.email }
            let res = await _Apiauth(postdata, `auth/resend-otp`)
            if (res?.statusCode === 200) {
                setValidation(prevState => ({
                    ...prevState,
                    sussess: {
                        message: res?.message,
                        status: true
                    }
                }));
                setResettoken(res?.resetToken)
            }
        }
        catch (err) {
            console.log(err, "err")
            if (err.response.status) {
                setValidation(prevState => ({
                    ...prevState,
                    sussess: {
                        message: err.response.data.message,
                        status: true
                    }
                }));
            }
        }
    }
    // eslint-disable-next-line
    const getreast = async () => {
        try {
            setLodaer(true)
            let postdata = {
                "otp": otp,
                "resetToken": resettolen ? resettolen : forgetemail?.resetToken
            }
            let res = await _Apiauth(postdata, `auth/verify-otp`)
            if (res?.statusCode === 200) {
                setLodaer(false)
                setOpenmodal(prevState => ({
                    ...prevState,
                    showLoginModel: false,
                    showRegisterModel: false,
                    showemailvarifymodal: false,
                    forgetpassword: false,
                    resetpassword: false,
                    newpassword: true

                }))
                SetNewpassworddata(res?.resetpasswordToken)
            } else {
                setLodaer(false)
            }
        }
        catch (err) {
            console.log(err, "err")
            setLodaer(false)
            if (err.response.status) {
                setValidation(prevState => ({
                    ...prevState,
                    sussess: {
                        message: err.response.data.message,
                        status: true
                    }
                }));
            }
        }
    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                getreast();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [getreast]);
    return (
        <div className='ecommerce-login-overlay'>
            <div className='ecommerce-login'>
                <div className='ecommerce-login-inner forget-password d-flex flex-column h-100 '>
                    <div className='formdata'>
                    <div className='d-flex flex-column '>
                    <img className='ecommerce-login-inner-logo' src={SideBanner} alt='side_banner' />
                        <div className='ecommerce-login-inner-title text-center'>
                            <div className='ecommerce-login-inner-title-heading'>{t("Password Reset")}</div>
                            <div className='ecommerce-login-inner-title-sub-title'>{t("We sent a code to")} {forgetemail?.email}  </div>
                        </div >
                        <div className='ecommerce-login-inner-input_section w-75 m-auto'>
                            <div className="ecommerce-login-inner-input_section-inner">
                                <div className='d-flex gap-2'>
                                    {inputValues.map((value, index) => (
                                        <input
                                            key={index}
                                            className="ecommerce-login-inner-input_section-inner-input resetpassword-input text-center"
                                            maxLength="1"
                                            value={value}
                                            onChange={(e) => handleInput(index, e.target.value)}
                                            ref={inputRefs.current[index]}
                                        />
                                    ))}
                                </div>
                            </div>
                            {validation?.sussess?.status ?
                                <div className='text-danger'>{t(`${validation?.sussess?.message}`)}</div> : ""
                            }
                            {lodaer ?
                                <button className='ecommerce-login-inner-input_section-btn'>
                                    <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div></button> :
                                <button className='ecommerce-login-inner-input_section-btn' onClick={() => getreast()}>{t("Continue")}</button>}

                        </div>
                        {showreasndpassword ?
                            <div className='ecommerce-login-inner-create-account'>
                                <div className='ecommerce-login-inner-create-account-register_btn'>
                                    {t("Didn’t receive the email")}?
                                    <span onClick={() => getresandpassword()}>
                                        {t("Click to resend")}
                                    </span>
                                </div>
                            </div>
                            : ""}
                    </div>
                    <div className='ecommerce-login-inner-create-account mt-auto'>
                        <div className='ecommerce-login-inner-create-account-register_btn'>
                            {t("Already have an account")}?
                            <span onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: true,
                                    showRegisterModel: false,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false

                                }))
                            }}>
                                {t("SIGN IN")}
                            </span>
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice'>
                            {t("By continuing, I confirm that I have read and agree to the")} {t("Privacy Notice")} {t("and Term and Conditions")}
                        </div>
                    </div>
                    </div>
                </div >
                <div className='ecommerce-login-sidebanner'>
                <span className='Cross_icon'
                        onClick={() => {
                            setOpenmodal(prevState => ({
                                ...prevState,
                                showLoginModel: false,
                                showRegisterModel: false,
                                showemailvarifymodal: false,
                                forgetpassword: false,
                                resetpassword: false,
                                newpassword: false
                            }));
                        }} >
                        <CrossIcon width={30} height={30} />
                    </span>
                    <div className='ecommerce-login-sidebanner-holder'>
                        <img src={SideBanner} alt='side_banner' />
                    </div>
                </div >
            </div >
        </div>

    )
}

export default ResetPassword