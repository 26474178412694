import React, { useState, useEffect, useCallback } from "react";
import "./login-signup.scss";
import { useTranslation } from 'react-i18next';
import { _Apiauth } from '../../api/_call';
import { Link, useParams } from 'react-router-dom';
const Changepassword = () => {
    const { t } = useTranslation();
    const { token } = useParams();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [updatepassword, setUpdatepassword] = useState("")
    const [confirmpass, setConfirmpass] = useState("")
    const [showpage, setshowpage] = useState(false)
    const [chatcherror, setCatcherror] = useState({})
    const checkauth = async (authToken) => {
        try {
            let postdata = {
                authToken: authToken
            }
            let res = await _Apiauth(postdata, `Auth/Web/reset-password-check`)
            if (res?.code === 200) {
                setshowpage(true)
                setCatcherror(prevState => ({
                    ...prevState,
                    validlink: {
                        message: res?.message,
                        status: res?.status
                    }
                }));
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const setforgotpassword = useCallback(async () => {
        if (!confirmpass) {
            setCatcherror(prevState => ({
                ...prevState,
                error: {
                    message: "Please Enter Password",
                    status: true
                }
            }));
            return;
        }
        if (updatepassword === confirmpass) {
            setCatcherror(prevState => ({
                ...prevState,
                loader: {
                    status: true
                }
            }));
            try {
                let postdata = {
                    authToken: token ? token : "",
                    password: confirmpass,
                }
                let res = await _Apiauth(postdata, `Auth/Web/reset-password`)
                if (res.code === 200) {
                    setCatcherror(prevState => ({
                        ...prevState,
                        validlink: {
                            success: res?.message,
                            status: false
                        }
                    }));
                    setCatcherror(prevState => ({
                        ...prevState,
                        error: {
                            message: "",
                            status: false
                        }
                    }));
                    setCatcherror(prevState => ({
                        ...prevState,
                        success: {
                            message: res?.message,
                            status: true
                        }
                    }));
                    setCatcherror(prevState => ({
                        ...prevState,
                        loader: {
                            status: false
                        }
                    }));

                } else {
                    setCatcherror(prevState => ({
                        ...prevState,
                        success: {
                            message: res?.message,
                            status: false
                        }
                    }));
                    setCatcherror(prevState => ({
                        ...prevState,
                        loader: {
                            status: false
                        }
                    }));
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        } else {
            setCatcherror(prevState => ({
                ...prevState,
                error: {
                    message: "Password is not match",
                    status: true
                }
            }));
        }

    }, [token, confirmpass, updatepassword])
    useEffect(() => {
        if (token) {
            checkauth(token)
        }
    }, [token]);
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                setforgotpassword();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [setforgotpassword]);
    return (
        <div className="main-body bg-white p-0">
            {showpage ?
                <div className="login-page position-relative d-flex align-items-center justify-content-center">
                    <img className="login-page-bg position-absolute end-0 bottom-0 w-75" src={base_assets + "images/login-bg.png"} alt="" />
                    <div className="login-page-row d-flex justify-content-center align-items-center w-100">
                        <div className="text-center login-page-left-col">
                            <Link to="/">
                                <img className="login-page-left-col-img" src={base_assets + "images/gis-xl-logo.png"} alt="" />
                            </Link>
                            <span className="d-block mt-4 fs-4 fw-semibold login-page-left-col-text">{t("Start your journey with us")} <span className="main-green-text">{t(" today")}</span></span>
                        </div>

                        <div className="login-page-right-col">
                            <h1 className="login-page-right-col-heading fs-2 fw-semibold">{t("Change Password")}</h1>
                            {chatcherror?.validlink?.status ?
                                <div className="login-page-right-col-form">
                                    <div className="placeholder-up-input w-100 form-floating mb-4">
                                        <input type="password" className="placeholder-up-input-field form-control fs-6 px-3" id="" placeholder="new password"
                                            onChange={(e) => setUpdatepassword(e.target.value)} />
                                        <label className="placeholder-up-input-label fs-6" htmlFor="">{t("New Password")}</label>
                                    </div>
                                    <div className="placeholder-up-input w-100 form-floating mb-4">
                                        <input type="password" className="placeholder-up-input-field form-control fs-6 px-3" id="" placeholder="confirm  password"
                                            onChange={(e) => setConfirmpass(e.target.value)} />
                                        <label className="placeholder-up-input-label fs-6" htmlFor="">{t("Confirm Password")} </label>
                                    </div>
                                    {chatcherror?.error?.status ? <span className="d-block mb-4 text-danger">{chatcherror?.error?.message ? chatcherror?.error?.message : ""}</span> : ""}
                                    {chatcherror?.success?.status ? <span className="d-block mb-4 main-green-text">{chatcherror?.success?.message ? chatcherror?.success?.message : chatcherror?.success?.message}</span> : ""}
                                    <button className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center">
                                        {chatcherror?.loader?.status ?
                                            <div className="border-bottom-0 border-width-2px d-block text-white mx-auto spinner-border flex-shrink-0" role="status"></div> :
                                            <span className="h-100 w-100 justify-content-center align-items-center d-flex" onClick={() => {
                                                setforgotpassword()
                                            }}> {t("Submit")}</span>
                                        }
                                    </button>
                                </div>
                                : <div className={`login-page-right-col  ${chatcherror?.validlink?.message ? "text-danger" : "main-green-text"}`}>
                                    {chatcherror?.validlink?.message ? chatcherror?.validlink?.message :
                                        chatcherror?.validlink?.success}
                                    <Link to="/login" className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center mb-4 text-decoration-none">
                                        <span className="h-100 w-100 justify-content-center align-items-center d-flex"> {t("Back to Login")}</span>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div> :
                <div className="spinner-border text-info position-fixed start-50 top-50" role="status">
                    <span className="visually-hidden">{t("Loading...")}</span>
                </div>
            }


        </div>
    )
}

export default Changepassword;