import React from 'react';
import { Link } from "react-router-dom";
import CheckoutBlank from '../checkout/checkoutBlank';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const Receive = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area bg-white page-menu-block">
              <div className='catalog-area-header-area detail-area-header'>
                <div className='catalog-area-header-area-control'>
                  <div className='catalog-area-header-area-control-left'>
                    <h1 className='heading text-uppercase'>{t("Receive")}</h1>
                  </div>
                </div>
              </div>
              <div className='page-menu-block-inner bg-white'>
                <h1 className="main-heading">{t("Select your option")}</h1>
                <div className="menu-block-row">
                  <div className={parmissiondatashow?.pos_deposit?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                    <Link to="/app/pos/receive/deposit" className="menu-block-row-col-a hover-effect-inner">
                      <span className="menu-block-row-col-a-img position-relative">
                        <img className="hover-effect-inner-img hover-effect-inner-img-g" src={base_assets + "images/pos/icons/deposit.png"} alt="" />
                        <img className="hover-effect-inner-img hover-effect-inner-img-w" src={base_assets + "images/pos/icons/deposit-w.png"} alt="" />
                      </span>
                      <span className="menu-block-row-col-a-title">{t("Deposit")}</span>
                    </Link>
                  </div>

                  <div className={parmissiondatashow?.Receive?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                    <Link to="/app/pos/receive/receivePayment" className="menu-block-row-col-a hover-effect-inner">
                      <span className="menu-block-row-col-a-img position-relative">
                        <img
                          className="hover-effect-inner-img hover-effect-inner-img-g"
                          src={base_assets + "images/pos/icons/receive-payment.png"}
                          alt=""
                        />
                        <img
                          className="hover-effect-inner-img hover-effect-inner-img-w"
                          src={base_assets + "images/pos/icons/receive-payment-w.png"}
                          alt=""
                        />
                      </span>
                      <span className="menu-block-row-col-a-title">{t("Receive")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )

}

export default Receive;