import currencyFormatter from "currency-formatter";
import { store } from "../../../source/index";
export const toFormatPrice = (amount, args = {}) => {
    const OrgSettings1 = localStorage.getItem("getPOSSettings");
    let OrgSettings = JSON.parse(OrgSettings1);
    var price_format_options = OrgSettings?.price_format;
    var currency = price_format_options?.currency;
    var show_amount_in = price_format_options?.show_amount_in || "Million";
    var decimal_point = price_format_options?.decimal_point || 2;
    var thousand_separator = price_format_options?.thousand_separator || ",";
    var decimal_separator = '.';

    if (thousand_separator.search(".")) {
        decimal_separator = ",";
    }

    if (show_amount_in === "Million") {
        var options = {
            code: currency,
            decimal: decimal_separator,
            thousand: thousand_separator,
            precision: decimal_point,
            format: '%v' // %s is the symbol and %v is the value
        }
        if (args.addSymbol) {
            options.format = '%s %v';
        }
        if (currency === "AED") {
            options.format = '%v %s';
            options.symbol = "AED";
        }
        amount = currencyFormatter.format(amount, options);
    } else {
        var old_amount = Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR", minimumFractionDigits: decimal_point }).format(amount);
        amount = old_amount.replaceAll(",", thousand_separator); // thousand_separator
        amount = old_amount.replace(".", decimal_separator); //decimal_separator      
        if (args.addSymbol) {
            var currency_symbol = currencyFormatter.findCurrency(currency);
            amount = old_amount.replace("₹", currency_symbol?.symbol + " ");
        }
    }
    return amount;
}
// export const toUnformatPrice = (amount, args = {}) => {
//     var state = store.getState();
//     var OrgSettings = state?.app?.posorganisation_settings;
//     var price_format_options = OrgSettings?.price_format;
//     var currency = price_format_options?.currency || "USD";
//     var show_amount_in = price_format_options?.show_amount_in || "Million";
//     var decimal_point = price_format_options?.decimal_point || 2;
//     var thousand_separator = price_format_options?.thousand_separator || ",";
//     var decimal_separator = '.';

//     if (thousand_separator.search(".")) {
//         decimal_separator = ",";
//     }

//     if (show_amount_in === "Million") {
//         var options = {
//             code: currency,
//             decimal: decimal_separator,
//             thousand: thousand_separator,
//             precision: decimal_point,
//             format: '%v' // %s is the symbol and %v is the value
//         }
//         if (args.addSymbol) {
//             options.format = '%s %v';
//         }
//         amount = currencyFormatter.unformat(amount, options);
//     } else {
//         var old_amount = Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR", minimumFractionDigits: decimal_point }).format(amount);
//         amount = old_amount.replaceAll(",", thousand_separator); // thousand_separator
//         amount = old_amount.replace(".", decimal_separator); //decimal_separator     
//         if (args.addSymbol) {
//             var currency_symbol = currencyFormatter.findCurrency(currency);
//             amount = old_amount.replace("₹", currency_symbol?.symbol + " ");
//         }
//     }

//     return amount;
// }
export const toUnformatPrice = (amount, args = {}) => {
    var state = store.getState();
    var OrgSettings = state?.app?.posorganisation_settings;
    var price_format_options = OrgSettings?.price_format;
    var currency = price_format_options?.currency || "USD";
    var show_amount_in = price_format_options?.show_amount_in || "Million";
    var decimal_point = price_format_options?.decimal_point || 2;
    var thousand_separator = price_format_options?.thousand_separator || ",";
    var decimal_separator = '.';
    if (thousand_separator === '.') {
        decimal_separator = ',';
    }
    if (typeof amount == 'string') {
        let parts = amount?.split('.');
        if (parts.length > 2) {
            let integerPart = parts.slice(0, -1).join('');
            let decimalPart = parts[parts.length - 1];
            amount = integerPart + '.' + decimalPart;
            return parseFloat(amount);
        }
    }
    if (show_amount_in === "Million") {
        var options = {
            // locale: 'en-IN',
            code: currency,
            decimal: decimal_separator,
            thousand: thousand_separator,
            precision: decimal_point,
            format: '%v' // %s is the symbol and %v is the value
        }
        if (args.addSymbol) {
            options.format = '%s %v';
        }
        amount = currencyFormatter.unformat(amount, options);
    } else {
        var old_amount = Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR", minimumFractionDigits: decimal_point }).format(amount);

        amount = old_amount.replaceAll(thousand_separator, ""); // thousand_separator
        amount = old_amount.replace(decimal_separator, "."); //decimal_separator      

        if (args.addSymbol) {
            var currency_symbol = currencyFormatter.findCurrency(currency);
            amount = old_amount.replace(currency_symbol?.symbol + " ", "");
        }
    }
    return amount;
}





