import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import './productList.scss';
import { success, error } from '../../common/helpers/toastify';
import { _Api } from '../../common/api/_call';
import { useTranslation } from 'react-i18next';
import { formatDate } from "../../common/helpers/formatDate";
const ProductListGrid = (props) => {
  const {
    ProductListGrid,
    filter,
    setShowcheckout,
    NavId,
    itemname,
    itemid,
    setProductListGrid,
    setsearchproductstatus,
    parkid,
    order_id,
    setUpdatedCart,
    order_type,
    order_edit,
    setInv_orderid,
    inv_orderid,
    setInv_ordertype,
    setBy_repair_order,
    inv_ordertype,
    by_repair_order,
    by_customorder,
    setBy_customorder,
    addparmission,
    custom_data,
    uuid,
    setStockid,

  } = props
  const { t } = useTranslation()
  const navigate = useNavigate();
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const saleperson = useSelector((state) => state.app.saleperson)
  const customer = useSelector((state) => state.app.customer)
  const AddtoCart = async (product_id, orderid) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    if (!saleperson?.value) {
      error(t('SalePerson is Requried!!'))
      return
    }
    try {
      const postdata = {
        product_id: product_id,
        type: filter === true ? 'catalog' : 'inventory',
        customer_id: customer?.id,
        order_type: 'pos_order',
        sales_person_id: saleperson?.value,
        park_id: parkid,
        order_id: order_id ? order_id : orderid,
        sessioncartid: uuid ? uuid : "",
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`)
      if (res.code === 200) {
        success((res?.message))
        if (parkid || inv_orderid) {
          GetCartdetailpark()
        } else {
          if (order_id && order_edit && (order_type || by_customorder)) {
            GetCartdetailpark()
          } else {
            setsearchproductstatus(true);
            setShowcheckout('sell');
          }
          setsearchproductstatus(true);
          setShowcheckout('sell');
        }
      } else {
        error((res?.message))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const wishlistset = async (sku, product_id, wishliststatus, i) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    try {
      const postdata = {
        customer_id: customer?.id,
        isWishlist: wishliststatus,
        SKU: sku,
        type: filter === true ? 'catalog' : 'inventory',
        product_id: product_id,
        order_type: "pos_order",
        item_id: itemid
      }
      let res = await _Api(postdata, `api/v1/POS/wishlist/updateWishlist`)
      if (res.code === 200) {
        let arry = [...ProductListGrid]
        arry[i] = {
          ...arry[i],
          'isWishlist': res?.data?.isWishlist,
        }
        setProductListGrid(arry)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const GetCartdetailpark = async () => {
    try {
      const postdata = {
        park_id: parkid,
        order_id: inv_orderid ? inv_orderid : order_id,
        order_type: inv_ordertype ? inv_ordertype : order_type,
        order_edit: order_edit,
        by_repair_order: by_repair_order

      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        if (order_id && order_edit && (order_type || by_customorder)) {
          setUpdatedCart(res?.data);
          setsearchproductstatus(true);
          setShowcheckout('sell')
        }
        setUpdatedCart(res?.data);
        setsearchproductstatus(true);
        setShowcheckout('sell')
      } else {
        navigate('/app/pos/custom/createYourOwn')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const setproductdata = (productid) => {
    let data = {
      productid: productid ? productid : "",
      filter: filter ? filter : false,
      NavId: NavId ? NavId : "",
      itemname: itemname ? itemname : "",
      itemid: itemid ? itemid : "",
      parkid: parkid ? parkid : "",
      order_id: order_id ? order_id : "",
      order_type: order_type ? order_type : "",
      order_edit: order_edit ? order_edit : false,
      uuid: uuid
    }
    let stringify = JSON.stringify(data)
    localStorage.setItem('productdata', stringify);
  }
  return (
    <>

      <div className="products-list scrollable overflow-visible">
        {custom_data?.length ? (
          <>
            <div className="products-list-row">
              {custom_data?.length ?
                custom_data?.map((result, prokey) => {
                  return (
                    <div key={prokey} className="products-list-row-single">
                      <Link
                        to="/app/pos/products/ProductDetails"
                        className="link-style"
                        onClick={() => setproductdata(result?.product_id)}
                      >
                        <div className="products-list-row-single-img">
                          <img
                            className="products-cat-row-single-img"
                            src={result?.main_image ? result?.main_image : ''}
                            alt=""
                          />
                        </div>
                      </Link>
                      <div className="products-list-row-single-ct">
                        <div className='products-list-row-single-ct-name-wrap'>
                          <h2 className="products-list-row-single-ct-name mb-0">
                            {result?.name}
                          </h2>
                          <span className='products-list-row-single-ct-name d-block fw-semibold h-auto spinnercolor'>{(result?.SKU)}</span>
                        </div>
                        <div className="products-list-row-single-ct-bottom">
                          <p className="products-list-row-single-ct-bottom-price">
                            {result?.price ? result?.price : ''}
                          </p>

                          {
                            result?.status_type === "transit" ?
                              <div className="status-dot green-bg  tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                {t("Transit")}</span></div> :
                              result?.status_type === "warehouse" ?
                                <div className="status-dot status-gray-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                  {t("Warehouse")}</span></div> :
                                result?.status_type === "reserve" ?
                                  <div className="status-dot yellow-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                    {t("Reserve")}</span></div> :
                                  result?.status_type === "repair_order" ?
                                    <div className="status-dot purple-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                      {t("Repair Order")}</span></div> :
                                    result?.status_type === "custom_order" ?
                                      <div className="status-dot danger-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                        {result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{formatDate(result?.pos?.delivery_date)}</span></div>
                                      : result?.status_type === "stock" ?
                                        <div className="status-dot light-blue-bg  tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                          {t("Stock")}</span></div> : ""
                          }
                          {filter === false ? (
                            <button
                              onClick={() => {
                                AddtoCart(result?.product_id, result?.order_id);
                                setInv_orderid(result?.order_id);
                                setInv_ordertype(result?.order_type);
                                setBy_repair_order(result?.by_repair_order)
                                setBy_customorder(result?.by_custom_order);
                              }}
                              className={
                                (result?.status_type === "transit" || result?.status_type === "warehouse" || !addparmission) ? "d-none" :
                                  (
                                    (result?.status_type === "custom_order" && customer?.id === result?.pos?.customer_id) ||
                                    (result?.status_type === "repair_order" && customer?.id === result?.customer_id)
                                  ) ? "products-list-row-single-ct-bottom-add" :
                                    (
                                      result?.pos?.customer_id || result?.customer_id || result.status_type === "reserve"
                                    ) ? "d-none" :
                                      "products-list-row-single-ct-bottom-add"
                              }
                            >
                              <img
                                src={base_assets + 'images/pos/icons/add-pro.png'}
                                alt=""
                              />
                            </button>
                          ) : (
                            <Link
                              className={
                                (result?.status_type === "transit" || result?.status_type === "warehouse" || !addparmission) ? "d-none" :
                                  (
                                    (result?.status_type === "custom_order" && customer?.id === result?.pos?.customer_id) ||
                                    (result?.status_type === "repair_order" && customer?.id === result?.customer_id)
                                  ) ? "products-list-row-single-ct-bottom-add link-style" :
                                    (
                                      result?.pos?.customer_id || result?.customer_id || result?.status_type === "reserve"
                                    ) ? "d-none" :
                                      "products-list-row-single-ct-bottom-add link-style"
                              }
                              to="/app/pos/products/ProductDetails"
                              onClick={() => setproductdata(result?.product_id)}

                            >
                              <img

                                src={base_assets + 'images/pos/icons/add-pro.png'}
                                alt=""
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="products-list-row-single-top-icons">
                        {result?.product_variants_enable === "1" ?
                          <img src={base_assets + "images/icons/variant-sm.png"} alt=""></img> :
                          result?.product_variants_enable === "2" ?
                            <img src={base_assets + "images/pro-ring-img.png"} alt=""></img> : ""}
                        <i
                          onClick={() => result?.isWishlist ?
                            (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, prokey)) :
                            (parmissiondatashow?.WishList?.Add && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, prokey))}
                          className={
                            result?.isWishlist === 1
                              ? 'fa fa-heart wishlist-icon position-static'
                              : 'fa fa-heart-o wishlist-icon position-static'
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  )

                }) : ""
              }</div></>
        ) : ""
        }
        <div className="products-list-row">
          {ProductListGrid?.length
            ? ProductListGrid?.map((result, prokey) => {
              return (
                <div key={prokey} className="products-list-row-single">
                  <Link
                    to="/app/pos/products/ProductDetails"
                    className="link-style"
                    onClick={() => setproductdata(result?.product_id)}
                  >
                    <div className="products-list-row-single-img">
                      <img
                        className="products-cat-row-single-img"
                        src={result?.main_image ? result?.main_image : ''}
                        alt=""
                      />
                    </div>
                  </Link>
                  <div className="products-list-row-single-ct">
                    <div className='products-list-row-single-ct-name-wrap'>
                      <h2 className="products-list-row-single-ct-name mb-0">
                        {result?.name}
                      </h2>
                      <span className='products-list-row-single-ct-name d-block fw-semibold h-auto spinnercolor'>{result?.SKU}</span>
                    </div>
                    <div className="products-list-row-single-ct-bottom">
                      <p className="products-list-row-single-ct-bottom-price">
                        {result?.price ? result?.price : ''}
                      </p>
                      {
                        result?.status_type === "transit" ?
                          <div className="status-dot green-bg  tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                            {t("Transit")}</span></div> :
                          result?.status_type === "warehouse" ?
                            <div className="status-dot status-gray-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                              {t("Warehouse")}</span></div> :
                            result?.status_type === "reserve" ?
                              <div className="status-dot yellow-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                {t("Reserve")}</span></div> :
                              result?.status_type === "repair_order" ?
                                <div className="status-dot purple-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                  {t("Repair Order")}</span></div> :
                                result?.status_type === "custom_order" ?
                                  <div className="status-dot danger-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                    {result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS_Order}<br />{formatDate(result?.pos?.delivery_date)}</span></div>
                                  : result?.status_type === "stock" ?
                                    <div className="status-dot light-blue-bg  tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                      {t("Stock")}</span></div> : ""
                      }
                      {filter === false ? (
                        <button
                          onClick={() => {
                            setStockid(result?.stock_id)
                            AddtoCart(result?.product_id, result?.order_id);
                            setInv_orderid(result?.order_id);
                            setInv_ordertype(result?.order_type);
                            setBy_repair_order(result?.by_repair_order)
                            setBy_customorder(result?.by_custom_order);
                          }}
                          className={
                            result?.status_type === "transit" || result?.status_type === "warehouse" || !addparmission
                              ? "d-none"
                              : (result?.status_type === "custom_order" && customer?.id === result?.pos?.customer_id) ||
                                (result?.status_type === "repair_order" && customer?.id === result?.customer_id)
                                ? "products-list-row-single-ct-bottom-add"
                                : result?.pos?.customer_id || result?.customer_id || result?.status_type === "reserve"
                                  ? "d-none"
                                  : "products-list-row-single-ct-bottom-add"
                          }
                        >
                          <img
                            src={base_assets + 'images/pos/icons/add-pro.png'}
                            alt=""
                          />
                        </button>
                      ) : (
                        <Link
                          className={
                            result?.status_type === "transit" || result?.status_type === "warehouse" || !addparmission || result?.status_type === "reserve"
                              ? "d-none"
                              : (result?.status_type === "custom_order" && customer?.id === result?.pos?.customer_id) ||
                                (result?.status_type === "repair_order" && customer?.id === result?.customer_id)
                                ? "products-list-row-single-ct-bottom-add link-style"
                                : (result?.pos?.customer_id || result?.customer_id)
                                  ? "d-none"
                                  : "products-list-row-single-ct-bottom-add link-style"
                          }
                          to="/app/pos/products/ProductDetails"
                          onClick={() => setproductdata(result?.product_id)}
                        >
                          <img
                            src={base_assets + 'images/pos/icons/add-pro.png'}
                            alt=""
                          />
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className='products-list-row-single-top-icons'>
                    {result?.product_variants_enable === "1" ?
                      <img src={base_assets + "images/icons/variant-sm.png"} alt=""></img> :
                      result?.product_variants_enable === "2" ?
                        <img src={base_assets + "images/pro-ring-img.png"} alt=""></img> : ""}
                    <i
                      onClick={() => result?.isWishlist ?
                        (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, prokey)) :
                        (parmissiondatashow?.WishList?.Add && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, prokey))}
                      className={
                        result?.isWishlist === 1
                          ? 'fa fa-heart wishlist-icon position-static'
                          : 'fa fa-heart-o wishlist-icon position-static'
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              )
            })
            : ''}
        </div>
      </div >
    </>
  )
}

export default ProductListGrid;
