import React, { useState, useEffect, useCallback } from "react";
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import Generalview from "./Generalview";
import { _Api, graphqlPost } from "../api/_call";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useDispatch, useSelector } from 'react-redux';
import {
  updateGeneralSettings

} from '../../source/app/appSlice';
const SetupGeneral = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch();
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const DefultValue = {
    "_id": "0",
    "Activate_loyalty_points": "0",
    "ACTIVE_COUPON": "0",
    "Users_to_enter_PIN_for_every_sale": "1",
    "Product_master_display_cost": "1",
    "Cash_Register_close_every_day": "1",
    "Allow_user_manually": "0",
    "Already_exchange_item": "0",
    "Switch_between_user": "0",
    "Every_customer_must": "0",
    "Allow_processing_Refund": "0",
    "Prompt_to_enter_delivery": "0",
    "Cashier_to_required_manager_permission": "0",
    "logistic_status": "1",
    "POS_partial_payment": "1",
    "POS_change_price": "1",
    "Low_Stock_Notification_Email": "0",
    "productChoice": "1",
    "customOrder_delivery": {
      "status": "1",
      "weeks": "4"
    },
    "repairOrder_delivery": {
      "status": "1",
      "weeks": "4"
    },
    "purchaseOrder_delivery": {
      "status": "1",
      "weeks": "4"
    },
    "Template_with_Image": "1",
    "allowWarehouse": "1",
    "reserve_delivery": {
      "status": "1",
      "weeks": "1"
    },
    "servicelabers": "1",
    "servicelandabers": "1",
    "isQuotation": "1",
    "isPark": "1",
    "blockChainLedger": "0"
  }
  const [completeDataObject, setCompliteDataObject] = useState(DefultValue);

  const updateCompleteDataObject = (newObject) => {
    setCompliteDataObject((prevData) => {
      const mergedObject = { ...prevData };
      Object.keys(newObject).forEach((key) => {
        if (mergedObject.hasOwnProperty(key)) {
          mergedObject[key] = newObject[key];
        }
      });
      return mergedObject;
    });
  };
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  const getgeneralsetting = async () => {
    try {
      const postdata = `{
        generalsetup {
        id 
        productChoice
        allowWarehouse
        POS_change_price
        Template_with_Image
        logistic_status
        purchaseOrder_delivery
        Users_to_enter_PIN_for_every_sale
        servicelabers
        isPark
        isQuotation
        POS_partial_payment
              
    }
}`;
      let res = await graphqlPost(postdata, "App/graphql");
      dispatch(updateGeneralSettings(res));
    } catch (err) {
      console.log(err, "err");
    }
  }
  const updategeneral = async () => {
    let GetloadingID = loading();
    try {
      let postdata = completeDataObject
      let res = await _Api(postdata, `api/v1/App/setup/general-update`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        viewgeneral()
        getgeneralsetting()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const viewgeneral = useCallback(async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/setup/general-list`)
      if (res?.code === 200) {
        updateCompleteDataObject(res?.data);
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [])
  useEffect(() => {
    viewgeneral()
  }, [viewgeneral])
  return (
    <>

      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">{t("Setup")} <RightArrow /> <span className="">{t("General")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("print")}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export.png"}
                      alt=""
                    />
                  </div>
                  <span >{t("Import")}</span>
                </li>
              </ul>
            </div>
            {edit && <button className={parmissiondatashow?.gis_setup_general?.Edit ? "square-btn square-btn-white btn-close focus-none bg-white" : "d-none"} onClick={() => setEdit(false)}></button>}

            {edit ? <button className="square-btn" onClick={() => { updategeneral(); setEdit(false) }}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button> :
              <button className={parmissiondatashow?.gis_setup_general?.Edit ? "square-btn" : "d-none"} onClick={() => setEdit(true)}><img src={base_assets + 'images/admin/icons/pencil-white.png'} alt="" /></button>}

          </div>
        </div>
        {edit ?
          <div className="row">
            <div className="col-md-12">
              <div className="edit-item-area bg-white row">
                <div className="col-md-12 px-0">
                  <div className="edit-item-area-body">

                    <div className="row">
                      <div className="col-md-12 ps-0">
                        <div className="row mx-0">

                          <div className="col-md-12">
                            <div className="v3-input-group">
                              <label className="v3-input-group-heading">{t("ACTIVATE OR REMOVE FEATURES")}</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="feature1"
                                defaultChecked={completeDataObject?.Activate_loyalty_points === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Activate_loyalty_points", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="feature1">{t("Activate loyalty points rewards")}</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="feature2"
                                defaultChecked={completeDataObject?.ACTIVE_COUPON === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("ACTIVE_COUPON", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="feature2">{t("Active Gift Card")} </label>
                            </div>
                          </div>


                          <div className="col-md-12">
                            <div className="v3-input-group">
                              <label className="v3-input-group-heading">{t("GENERAL RULES")}</label>
                            </div>

                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules1"
                                defaultChecked={completeDataObject?.Template_with_Image === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Template_with_Image", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules1">{t("Template With Image")}</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules2"
                                defaultChecked={completeDataObject?.Users_to_enter_PIN_for_every_sale === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Users_to_enter_PIN_for_every_sale", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules2">{t("Users to enter PIN for every sale")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules3"
                                defaultChecked={completeDataObject?.Product_master_display_cost === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Product_master_display_cost", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules3">{t("Product master display cost")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules4"
                                defaultChecked={completeDataObject?.Cash_Register_close_every_day === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Cash_Register_close_every_day", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules4">{t("Cash Register close every day")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules5"
                                defaultChecked={completeDataObject?.Allow_user_manually === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Allow_user_manually", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules5">{t("Allow user manually lock POS screen")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules6"
                                defaultChecked={completeDataObject?.Already_exchange_item === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Already_exchange_item", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules6">{t("Already exchange item can be Exchange & Return again")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules7"
                                defaultChecked={completeDataObject?.Switch_between_user === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Switch_between_user", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules7">{t("Switch between user using PIN")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules8"
                                defaultChecked={completeDataObject?.Every_customer_must === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Every_customer_must", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules8">{t("Every customer must have a unique phone number")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules9"
                                defaultChecked={completeDataObject?.allowWarehouse === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("allowWarehouse", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules9">{t("Allow Warehouse")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules10"
                                defaultChecked={completeDataObject?.Allow_processing_Refund === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Allow_processing_Refund", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules10">{t("Allow processing Refund & Exchange at different outlet")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules11"
                                defaultChecked={completeDataObject?.Prompt_to_enter_delivery === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Prompt_to_enter_delivery", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules11">{t("Prompt to enter delivery address for every sale")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules12"
                                defaultChecked={completeDataObject?.Cashier_to_required_manager_permission === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Cashier_to_required_manager_permission", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules12">{t("Cashier to required manager permission to discount a product price to lower then its cost price")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules13"
                                defaultChecked={completeDataObject?.POS_partial_payment === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("POS_partial_payment", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules13">{t("POS partial payment ( lay By , On-Account)")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules14"
                                defaultChecked={completeDataObject?.POS_change_price === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("POS_change_price", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules14">{t("POS : Allow to change price provision")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules15"
                                defaultChecked={completeDataObject?.logistic_status === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("logistic_status", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules15">{t("Allow Logistic in Stock Transfer")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules16"
                                defaultChecked={completeDataObject?.productChoice === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("productChoice", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules16">{t("Allow Product Design")} </label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules17"
                                defaultChecked={completeDataObject?.customOrder_delivery?.status === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("customOrder_delivery", {
                                  status: e.target.checked ? "1" : "0",
                                  weeks: completeDataObject?.customOrder_delivery?.weeks
                                })} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules17">{t("Custom order standard delivery date")}   <input className="px-2 mx-1 border rounded-1" style={{ width: "40px" }}
                                defaultValue={completeDataObject?.customOrder_delivery?.weeks}
                                onChange={(e) => onChangeValueUpdate("customOrder_delivery", {
                                  status: completeDataObject?.customOrder_delivery?.status,
                                  weeks: e.target.value
                                })} />   Weeks</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules18"
                                defaultChecked={completeDataObject?.reserve_delivery?.status === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("reserve_delivery", {
                                  status: e.target.checked ? "1" : "0",
                                  weeks: completeDataObject?.reserve_delivery?.weeks
                                })} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules18">{t("Reserve Delivery")}
                                <input className="px-2 mx-1 border rounded-1" style={{ width: "40px" }}
                                  defaultValue={completeDataObject?.reserve_delivery?.weeks}
                                  onChange={(e) => onChangeValueUpdate("reserve_delivery", {
                                    status: completeDataObject?.reserve_delivery?.status,
                                    weeks: e.target.value
                                  })} /> Days</label>

                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules19"
                                defaultChecked={completeDataObject?.repairOrder_delivery?.status === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("repairOrder_delivery", {
                                  status: e.target.checked ? "1" : "0",
                                  weeks: completeDataObject?.repairOrder_delivery?.weeks
                                })} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules19">{t("Repair order standard delivery date")}
                                <input className="px-2 mx-1 border rounded-1" style={{ width: "40px" }}
                                  defaultValue={completeDataObject?.repairOrder_delivery?.weeks}
                                  onChange={(e) => onChangeValueUpdate("repairOrder_delivery", {
                                    status: completeDataObject?.repairOrder_delivery?.status,
                                    weeks: e.target.value
                                  })} />   Weeks</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules20"
                                defaultChecked={completeDataObject?.purchaseOrder_delivery?.status === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("purchaseOrder_delivery", {
                                  status: e.target.checked ? "1" : "0",
                                  weeks: completeDataObject?.purchaseOrder_delivery?.weeks
                                })} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules20">{t("Purchase order standard delivery date")}    <input className="px-2 mx-1 border rounded-1" style={{ width: "40px" }}
                                defaultValue={completeDataObject?.purchaseOrder_delivery?.weeks}
                                onChange={(e) => onChangeValueUpdate("purchaseOrder_delivery", {
                                  status: completeDataObject?.purchaseOrder_delivery?.status,
                                  weeks: e.target.value
                                })} />  Weeks</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules21"
                                defaultChecked={completeDataObject?.servicelabers === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("servicelabers", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules21">{t("Allow Service labour")}</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules22"
                                defaultChecked={completeDataObject?.isPark === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("isPark", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules22">{t("Park")}</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules23"
                                defaultChecked={completeDataObject?.isQuotation === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("isQuotation", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="generalRules23">{t("Quotation")}</label>
                            </div>

                          </div>
                          <div className="col-md-12">
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="blockChainLedger"
                                defaultChecked={completeDataObject?.blockChainLedger === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("blockChainLedger", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="blockChainLedger">{t("Block Chain Ledger")}</label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="v3-input-group">
                              <label className="v3-input-group-heading">{t("INVENTORY NOTIFICATIONS")}</label>
                            </div>
                            <div className="mb-4">
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="inventoryNotification1"
                                defaultChecked={completeDataObject?.Low_Stock_Notification_Email === "1" ? true : false}
                                onChange={(e) => onChangeValueUpdate("Low_Stock_Notification_Email", e.target.checked ? "1" : "0")} />
                              <label className="form-check-label cursor-pointer" htmlFor="inventoryNotification1">{t("Low stock notification on Email")}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          : <Generalview completeDataObject={completeDataObject} />}
      </div>

    </>
  )
}

export default SetupGeneral;