import React from 'react';
import { toFormatPrice } from '../common/helpers/function';
export const Appointmentsubtbl = (prioritydata, id, t, setSelecteditem) => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    return (
        <React.Fragment>
            {prioritydata.code === 200 ?
                <tr className="common-sub-table-wrap add-row remove" id={`common-sub-table-wrap-${id}`}>
                    <td colSpan="14">
                        <table id={'data_subtable_customorder' + id} className="common-table common-sub-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t("Image")}</th>
                                    <th>{t("SKU")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Metal")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Stone")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Size")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Stock ID")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Qty")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Price")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Amount")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(prioritydata?.data).map((result, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td> <div className='pro-img'><img src={result.image ? result.image : ""} alt=""></img></div></td>
                                            <td>{result.SKU ? result.SKU : "-"}</td>
                                            <td>{result.metal ? result.metal : "-"}</td>
                                            <td>{result.stone ? result.stone : "-"}</td>
                                            <td>{result.size ? result.size : "-"}</td>
                                            <td>{result.stock_id ? result.stock_id : "-"}</td>
                                            <td>{result.qty ? result.qty : "-"}</td>
                                            <td>{toFormatPrice(result.price, { addSymbol: true })}</td>
                                            <td>{toFormatPrice(result.amount, { addSymbol: true })}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
                : ""}
        </React.Fragment>
    )
}
export default Appointmentsubtbl;