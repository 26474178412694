import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DateTime from "../common/DateTime";
import { Tablehead } from "../common/modules/Tablehead";
import { usersTablethead } from "../common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../api/setdatatable";
import ConfirmationModel from "../common/modules/confirmationmodel";
import { _Api } from "../api/_call";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useSelector } from 'react-redux';
import TableLoader from "../../admin/common/TableLoader";

const Users = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [newcolumndata, setnewcolumndata] = useState(usersTablethead);
  const [userlistdata, setUserlistdata] = useState([])
  const [filtername, setFiltername] = useState('');
  const [userreportlist, setUserrepostlist] = useState([])
  const [operationlist, setOperationlist] = useState([])
  const [showloader, setShowloader] = useState(false)
  const [statement] = useState("Delete");
  const [userid, setUserid] = useState()
  const [, setfilterby] = useState("");
  const userlist = async (search) => {
    setShowloader(true)
    UnSetdataTable("userlist_user")
    try {
      const postdata = {
        "search": search ? search : "",
      };
      let res = await _Api(postdata, `api/v1/App/User/UserList`);
      if (res?.code === 200) {
        setTimeout(() => {
          SetdataTable("userlist_user", 4, newcolumndata, [0]);
        }, 50)

        setUserlistdata(res?.data)
        setShowloader(false)
      }

    }
    catch (err) {
      SetdataTable("userlist_user", 4, newcolumndata);
      console.log(err, 'err');
    }
  }
  useEffect(() => {
    userlist();
    // eslint-disable-next-line
  }, [newcolumndata]);

  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_user",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    callcolumns();
  }, []);
  const filterbyname = (search) => {
    userlist(search);
  }
  const deleteuserlist = async () => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "user_id": userid,

      };
      let res = await _Api(postdata, `api/v1/App/User/DeleteUser`);
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t("successfully Delete"));
        userlist()
      }
    }
    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, 'error');
    }
  }
  const userlogrepostlist = async () => {
    try {
      const postdata = {
        "limit": 5,
      };
      let res = await _Api(postdata, `api/v1/App/User/AllLoginDetails`);
      if (res.code === 200) {
        setUserrepostlist(res?.data)
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const operationlogrepostlist = async () => {
    try {
      const postdata = {
        "limit": 5,
      };
      let res = await _Api(postdata, `api/v1/App/User/OperationLogs`);
      if (res.code === 200) {
        setOperationlist(res?.data)
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }

  const [addparmission, setAddparmission] = useState(0)
  const [editparmission, setEditparmission] = useState(0)
  const [deleteparmission, setDeleteparmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

  useEffect(() => {
    if (parmissiondatashow?.User?.View)
      setAddparmission(parmissiondatashow?.User?.Add)
    setEditparmission(parmissiondatashow?.User?.Edit)
    setDeleteparmission(parmissiondatashow?.User?.Delete)
  }, [parmissiondatashow]);
  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading mb-4">
                {t("Users")}
              </h1>

              <div className="main-body-top-status-bar">

                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("Search")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker align-items-center">
                  <div className="dropdown user-log-dropdown d-flex align-items-center">
                    <button className="btn-style stock-take-inner-row-col-mid-header-right-btn mx-1 dropdown-toggle"
                      onClick={() => { userlogrepostlist(); operationlogrepostlist() }} data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                      <img className="note" src={base_assets + "images/icons/note-board-icon-dark.png"} alt="" />
                    </button>

                    <ul className="dropdown-menu">
                      <ul className="nav nav-tabs user-log-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="user-tab" data-bs-toggle="tab" data-bs-target="#user-tab-pane" type="button" role="tab" aria-controls="user-tab-pane" aria-selected="true">{t("User Log Report")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="operation-tab" data-bs-toggle="tab" data-bs-target="#operation-tab-pane" type="button" role="tab" aria-controls="operation-tab-pane" aria-selected="false">{t("Operation Log Report")}</button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="user-tab-pane" role="tabpanel" aria-labelledby="user-tab" tabIndex="0">
                          <table>
                            <thead>
                              <>
                                {userreportlist.length ? userreportlist.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td className="fixed-side table-pro-img w-12">
                                        <img className="w-100" src={result?.myProfile ? result?.myProfile : "https://gis247.s3.us-east-2.amazonaws.com/2023in0076/Customer/profile/1711620112703.png"} alt="" />
                                      </td>
                                      <td>
                                        <b className="fw-semibold">{result?.name ? result?.name : " "}</b><br />
                                        <span>{result?.date ? result?.date : ""}</span><br />
                                      </td>
                                      <td>{result?.type ? result?.type : ""}</td>
                                      <td>
                                        <Link to="/app/users/UserLogs" state={{ userid: result?.id }} className="text-decoration-none main-green-text">{t("View")}</Link>
                                      </td>
                                    </tr>
                                  )

                                }) :
                                  <tr>
                                    <td>
                                      {t("No Data Available In Table")}
                                    </td>
                                  </tr>
                                }
                              </>
                            </thead>
                          </table>
                        </div>
                        <div className="tab-pane fade" id="operation-tab-pane" role="tabpanel" aria-labelledby="operation-tab" tabIndex="0">
                          <table>
                            <tbody>
                              <>
                                {operationlist.length ? operationlist.map((result, key) => {
                                  return (

                                    <tr key={key}>
                                      <td className="fixed-side table-pro-img w-12">
                                        <img className="w-100" src={result?.myProfile ? result?.myProfile : ""} alt="" />
                                      </td>
                                      <td>
                                        <b className="fw-semibold">{ }</b><br />
                                        <span>{result?.date ? result?.date : ""}</span><br />
                                      </td>
                                      <td>{result?.Operation_Detail ? result?.Operation_Detail : ""}</td>
                                      <td>
                                        <Link to="/app/users/OperationLogReport" className="text-decoration-none main-green-text">{t("View")}</Link>
                                      </td>
                                    </tr>

                                  )
                                }) :
                                  <tr>
                                    <td>
                                      {t("No Data Available In Table")}
                                    </td>
                                  </tr>
                                }
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ul>
                  </div>

                  <button className="print-export-dropdown-selected-icon ms-1">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <Link to="/app/users/adduser"
                    className={addparmission ? "main-body-top-inputs-bar-col-right-submit-btn com-btn d-flex align-items-center" : "d-none"}>
                    <i className="fa fa-user-o me-2" aria-hidden="true"></i> {t("Add User")}</Link>
                </div>
              </div>


              <div
                className="main-body-main-table-wrap position-relative"
                style={{ maxWidth: '100%', overflowX: 'auto' }}
              >
                {showloader ?
                  <TableLoader />
                  :
                  <table
                    id="userlist_user"
                    className="stripe row-border order-column common-table table-striped main-table w-100"
                  >
                    <Tablehead
                      tablehead={usersTablethead}
                      tblName={"tbl_user"}
                      setfilterby={setfilterby}
                    />
                    <tbody>
                      <>

                        {userlistdata?.length ? userlistdata.map((result, key) => {
                          return (
                            <tr className="" key={key}>
                              <td className="fixed-side">{key + 1}</td>
                              <td className="fixed-side table-pro-img">
                                <img src={result?.profile_image ? result?.profile_image : ""} alt="" />
                              </td>
                              <td className="fixed-side">
                                <Link
                                  to={editparmission ? "/app/users/adduser" : ""}
                                  state={{ user_id: result?.id }}
                                  className="gray-text text-decoration-none link-color">
                                  {result?.name ? result?.name : "-"}
                                </Link></td>
                              <td className="fixed-side">{result?.email ? result?.email : "-"}	</td>
                              <td className="fixed-side">+{result?.contacts?.length ? result?.contacts[0]?.code : ""}-{result?.contacts?.length ? result?.contacts[0]?.no : "-"}</td>
                              <td className="fixed-side">{result?.postion ? result?.postion : "-"}</td>
                              <td className="fixed-side">{result?.country ? result?.country : "-"}</td>
                              <td className="fixed-side">{result?.role ? result?.role : "-"}</td>
                              <td className="fixed-side">{result?.created_date ? result?.created_date : "-"}	</td>
                              <td className="fixed-side">{result?.laste_login ? result?.laste_login : "-"}	</td>
                              <td className="fixed-side "> {result?.status === "1" ?
                                <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Active")}</span> :
                                <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold">
                                  <span className="inactive-badge-dot me-2"></span>{t("InActive")}</span>
                              }	</td>
                              <td className="fixed-side">
                                <Link to="/app/users/UserLogs"
                                  state={{ userid: result?.id }}
                                  className="btn-style mx-3">
                                  <img className="note" style={{ width: "18px" }} src={base_assets + "images/icons/note-board-icon-dark.png"} alt="" />
                                </Link>
                                <Link to="/app/users/adduser"
                                  state={{ user_id: result?.id }}>
                                  <button className={editparmission ? "btn-style mx-3" : "d-none"}>
                                    <img style={{ width: "20px" }}
                                      src={base_assets + "images/icons/edit-icon.png"}
                                      alt=""
                                    />

                                  </button>
                                </Link>
                                <button className={deleteparmission ? "btn-style" : "d-none"} data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                                  onClick={() => setUserid(result?.id)} >
                                  <img style={{ width: "20px" }}
                                    src={base_assets + "images/icons/delete-icon.png"}
                                    alt=""
                                  />
                                </button>
                              </td>
                            </tr>
                          )
                        }) : ""}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                }
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModel statement={statement} handler_submit={deleteuserlist} ></ConfirmationModel>
      </div>
    </>
  );
};
export default Users;