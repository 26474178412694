import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { _Api } from '../../api/_call';
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable';
import DateTime from '../../common/DateTime';
import TableColumnModal from '../../common/modules/tableColumn';
import ConfirmationModel from '../../common/modules/confirmationmodel';
import AgentModel from '../../common/modules/agentmodal';
import { Tablehead } from '../../common/modules/Tablehead';
import { stocktransfertablehead } from '../../common/Tablehead';
import { DateRange } from '../../common/helpers/daterange'
import { Pagination } from "../../common/Pagination";
import {
  toFormatPrice,
  toUnformatPrice,
  transactionTotals,
} from '../../common/helpers/function'
import Select from 'react-select'
import { success, error } from '../../common/helpers/toastify'
import {
  status_transfer_class,
  status_priority,
} from '../../common/helpers/status_class'
import { useSelector } from 'react-redux'
import { Stocktransferdetails } from '../../transaction/subTables/StockTransfer/stocktransferdetails'
import ProductModal from '../../common/modules/ProductModal'
import ProductImageSliderModal from '../../common/modules/productImageslider'
import SocialModal from '../../common/modules/SocialModal/SocialModal'
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { formatDate } from '../../common/helpers/formatDate';
import TableLoader from '../../../admin/common/TableLoader';
const Stock_transfer = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const dataFetched = useRef();
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const totalcountsnumber = useSelector((state) => state.app.totalcountsnumber)
  const [limit, setLimit] = useState(100)
  const [filtername, setFiltername] = useState('')
  const [datefilter, setDatefilter] = useState([])
  const [statement, setStatement] = useState('')
  const [transfer_sataus, settransfer_sataus] = useState([])
  const [stocktransferlist, setStocktransferlist] = useState([])
  const [agentvalue, setAgentvalue] = useState()
  const [AWBvalues, setAWBvalue] = useState()
  const [imageid, setImageid] = useState()
  const [Productmodaldata, setProductmodaldata] = useState([])
  const [transfer_priority, settransfer_priority] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(stocktransfertablehead)
  const [skip, setSkip] = useState()
  const [filterchangestatus, setfilterchangestatus] = useState()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [datalodaer, setDatsLodaer] = useState(false)
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
      }
    },
  }
  const select_transfer_status = (index, value, transfer_id) => {
    setStatement('Change Status')
    settransfer_sataus([
      { index: index, status: value, transfer_id: transfer_id },
    ])
    if (value?.value === 3 && generalsettings?.data?.generalsetup?.logistic_status === "1") {
      document.querySelector('#statustransit_query_select').click()
    } else {
      document.querySelector('#status_query_select').click()
    }
  }
  const priorityarray = [
    { label: 'low', value: 0, color: '#D9D9D9', className: 'low-text' },
    { label: 'medium', value: 1, color: '#D2E4FF', className: 'medium-text' },
    { label: 'high', value: 2, color: '#FFD2D9', className: 'high-text' },
  ]
  const filter_status = [
    { label: t('Pending'), labelcount: 'pending', value: 2, color: 'yellow' },
    {
      label: t('Approved'),
      labelcount: 'approved',
      value: 1,
      color: 'main-green',
    },
    { label: t('Transit'), labelcount: 'transit', value: 3, color: 'transit' },
    {
      label: t('Completed'),
      labelcount: 'completed',
      value: 4,
      color: 'light-blue',
    },
    { label: t('Canceled'), labelcount: 'cancelled', value: 0, color: 'danger' },
  ]
  const select_transfer_priority = (index, value, transfer_id) => {
    setStatement('Change Priority')
    settransfer_priority([
      { index: index, status: value, transfer_id: transfer_id },
    ])
    var mainarray = [...stocktransferlist]
    mainarray[index]['status'] = {
      ...mainarray[index]['status'],
      'priority': value?.value,
    }
    setStocktransferlist(mainarray)
    document.querySelector('#status_query_select').click()
  }

  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    Getstocktransfer(current, "", "");

  };
  const filterbyname = (search) => {
    setstate({ ...state, currentPage: 1 });
    Getstocktransfer(currentPage, limit, filterchangestatus, search);

  };
  const getlimit = (limit) => {
    setLimit(limit);
    Getstocktransfer(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [excuteTable, setexcuteTable] = useState(false)
  const Getstocktransfer = useCallback(async (current, firstlimit, filter_status, search) => {
    setexcuteTable(false)
    setDatsLodaer(true)
    setfilterchangestatus(filter_status)
    try {
      setTimeout(async function () {
        UnSetdataTable('transaction_subtable3')
        const postdata = {
          search: search ? search : "",
          limit: firstlimit ? firstlimit : limit,
          skip: skip === 0 ? skip : current ? (current - 1) * limit : 0,
          status: filter_status ? filter_status : 0,
          start_date: datefilter ? datefilter[0] : '',
          end_date: datefilter ? datefilter[1] : '',
        }
        let resstocktransfer = await _Api(
          postdata,
          `api/v1/Inventory/stocktransfer/getStockTransferList`,
        )
        if (resstocktransfer.code === 200) {
          setexcuteTable(true)
          transactionTotals()
          setStocktransferlist(resstocktransfer?.data)
          SetdataTable('transaction_subtable3', 5, newcolumndata)
          setstate({
            ...state,
            totalPages: Math.ceil(resstocktransfer?.count / postdata.limit),
            currentPage: current ? current : 1
          });
          setDatsLodaer(false)
          dataFetched.current = false;
        }
      }, 100)
    } catch (err) {
      console.log(err, 'err')
    }
  }, [newcolumndata, datefilter, limit, skip, state]);
  const Getstocktransferstatus = async () => {
    const handleValidation = (e) => {
      let formIsValid = true
      if (transfer_sataus[0].status?.value === 3) {
        if (!agentvalue?.value) {
          formIsValid = false
          error(t('Select the Agent Option!!'))
        }
        if (!AWBvalues) {
          formIsValid = false
          error(t('Enter the AWB NO.!!'))
        }
      } else {
        formIsValid = true
      }
      return formIsValid
    }
    if (handleValidation) {
      try {
        const postdata = {
          logistic_id: agentvalue?.value ? agentvalue?.value : '',
          awb_number: AWBvalues ? AWBvalues : '',
          status: transfer_sataus[0].status?.value,
          stock_transfer_id: transfer_sataus[0].transfer_id,
        }
        let resstocktransferstatus = await _Api(
          postdata,
          `api/v1/Inventory/stocktransfer/editStockTransferStatus`,
        )
        if (resstocktransferstatus.code === 200) {
          success(t('Successfully Status Updated!!'))
          Getstocktransfer()
        } else {
          error(t('Something Went Wrong!!'))
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }
  const Getstocktransferpriority = async () => {
    try {
      const postdata = {
        status: transfer_priority[0].status?.value,
        stock_transfer_id: transfer_priority[0].transfer_id,
      }
      let resstocktransferstatus = await _Api(
        postdata,
        `api/v1/Inventory/stocktransfer/editStockTransferPriority`,
      )
      if (resstocktransferstatus.code === 200) {
        success(t('Successfully Priority Updated!!'))
        Getstocktransfer()
      } else {
        error(t('Something Went Wrong!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const setSubTable = async (id) => {
    var e = document.querySelector('#table-btn_' + id)
    var row = document.querySelector('#common-main-table-wrap-' + id)
    var classList_ = e.classList
    if (classList_.contains('rotate-90')) {
      classList_.remove('rotate-90')
      let alllist_data = [...stocktransferlist]
      alllist_data
        .filter((item) => item.id === id)
        .forEach((result) => {
          result.subTable = null
        })
      setStocktransferlist(alllist_data)
    } else {
      classList_.add('rotate-90')
      let alllist_data = [...stocktransferlist]
      const postdata = {
        st_order_id: id,
      }
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/stocktransfer/getSTDetails`,
      )
      if (prioritydata?.code === 200) {
        var html_ = Stocktransferdetails(
          prioritydata,
          setImageid,
          setProductmodaldata, t
        )
        alllist_data
          .filter((item) => item.id === id)
          .forEach((result) => {
            result.subTable = html_
          })
      }
      setStocktransferlist(alllist_data)
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-table-sub-row' + id)
        if (rowSub) {
          $(row).after(rowSub)
          clearInterval(checkRowSub)
        }
      }, 100)
    }
  }

  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Getstocktransfer()
    }
    // eslint-disable-next-line
  }, [datefilter])

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    stocktransfertablehead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (stocktransferlist.length) {
      stocktransferlist.forEach((result, key) => {
        bodyarray.push([result?.createdAt, result?.st_no, result?.transfer_from_loc_name, result?.transfer_to_location_name, result?.sku, result?.total_quantity, result?.delivery_date, result?.due_days, result?.total_amount,
        result.status.st_status === 1 ? "Approved" :
          result.status.st_status === 2 ? "Pending" :
            result.status.st_status === 3 ? "Transit" :
              result.status.st_status === 4 ? "Completed" :
                result.status.st_status === 0 ? "Canceled" : ""
          , result.status.priority === 0 ? "Low" :
          result.status.priority === 1 ? "Medium" : "High",
        result?.awb_number, result?.agent_name, result?.remark, result?.user_actions?.created_by_name, result?.user_actions?.updated_by_name,
        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "stock_transfer");
  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading">{t('Transaction')}</h1>
              <div className="main-body-top-tab-bar">
                <div
                  className="main-body-top-tab-bar-left-col nav nav-pills"
                  role="tablist"
                >
                  <Link
                    className={parmissiondatashow?.purchase_order?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t('Purchase Order')}
                    </span>
                    <span className="num-tab">
                      {totalcountsnumber?.PO?.total
                        ? totalcountsnumber?.PO?.total
                        : '0'}
                    </span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_purchase?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/transactionPurchase"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t('Purchase')}
                    </span>
                    <span className="num-tab">
                      {totalcountsnumber?.PU?.total
                        ? totalcountsnumber?.PU?.total
                        : '0'}
                    </span>
                  </Link>
                  <Link
                    className="main-body-top-tab-bar-left-col-a nav-link active"
                    to="/app/inventory/stock_transfer"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t('Stock Transfer')}
                    </span>
                    <span
                      className="num-tab"
                      data-bs-toggle="collapse"
                      data-bs-target="#tStockTransfer"
                    >
                      {totalcountsnumber?.ST?.total
                        ? totalcountsnumber?.ST?.total
                        : '0'}
                    </span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_stock_Receive?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/transactionStockReceive"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t('Stock Receive')}
                    </span>
                    <span className="num-tab">
                      {totalcountsnumber?.SR ? totalcountsnumber?.SR : '0'}
                    </span>
                  </Link>
                </div>
                <div className="main-body-top-tab-bar-right-col">
                  <Link
                    to="/app/inventory/createTransferInventory"
                    className={parmissiondatashow?.inventory_transaction_stock_transfer?.Add ? "main-body-top-tab-bar-right-col-a" : "d-none"}
                  >
                    <img
                      src={
                        base_url_assets + 'images/icons/plus-circle-white.png'
                      }
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t('Create')}
                    </span>
                  </Link>
                </div>
              </div>
              <div id="tStockTransfer" className="collapse show">
                <div className="main-body-top-color-bar">
                  {filter_status.map((result, key) => {
                    return (
                      <div
                        className="main-body-top-color-bar-single"
                        onClick={() => {
                          Getstocktransfer('', '', result?.value); setSkip(0)
                        }}
                        key={key}
                      >
                        <div
                          className={
                            'main-body-top-color-bar-single-color ' +
                            result?.color +
                            '-bg'
                          }
                        />
                        <span
                          className={
                            'main-body-top-color-bar-single-color-status ' +
                            result?.color +
                            '-text'
                          }
                        >
                          {result?.label}
                        </span>
                        <span>
                          {totalcountsnumber?.ST?.[`${result?.labelcount}`]
                            ? totalcountsnumber?.ST?.[`${result?.labelcount}`]
                            : '0'}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('TF No./StockID/SKU/Metal/Stone')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_url_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          base_url_assets + 'images/icons/ellipsis-circular.png'
                        }
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + 'images/icons/export-x-icon.png'
                            }
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + 'images/icons/column-icon.png'
                            }
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
                id="table-scroll"
              >
                <table
                  id="transaction_subtable3"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead
                    tablehead={stocktransfertablehead}
                    setfilterby={""}
                    tblName={'tbl_inventory_stocktransfer'}
                  />
                  <tbody>
                    <>
                      {stocktransferlist ? stocktransferlist.map((result, key) => {
                        var status_class_val = status_transfer_class(
                          result.status.st_status,
                        )
                        var priority_class_val = status_priority(
                          result.status.priority,
                        )
                        var valueofpriority = []
                        if (result.status.priority === 0) {
                          valueofpriority = [
                            { label: 'Low', value: 0, color: '#D9D9D9' },
                          ]
                        } else if (result.status.priority === 1) {
                          valueofpriority = [
                            { label: 'Medium', value: 1, color: '#D2E4FF' },
                          ]
                        } else {
                          valueofpriority = [
                            { label: 'High', value: 2, color: '#FFD2D9' },
                          ]
                        }
                        var colorsArray = []
                        var valueofstatus = []

                        if (result.status.st_status === 1) {
                          valueofstatus = [
                            {
                              label: 'Approved',
                              value: result.status.st_status,
                              color: '#0ECB81',
                            },
                          ]
                          colorsArray = [
                            { label: 'Transit', value: 3, color: '#97BFD9' },
                            { label: 'Pending', value: 2, color: '#F9BC44' },
                          ]
                        } else if (result.status.st_status === 0) {
                          valueofstatus = [
                            {
                              label: 'Canceled',
                              value: result.status.st_status,
                              color: '#FF5757',
                            },
                          ]
                          colorsArray = [
                            { label: 'Pending', value: 2, color: '#F9BC44' },
                          ]
                        } else if (result.status.st_status === 2) {
                          valueofstatus = [
                            {
                              label: 'Pending',
                              value: result.status.st_status,
                              color: '#F9BC44',
                            },
                          ]
                          colorsArray = [
                            { label: 'Approved', value: 1, color: '#0ECB81' },
                            { label: 'Canceled', value: 0, color: '#FF5757' },
                          ]
                        } else if (result.status.st_status === 4) {
                          valueofstatus = [
                            {
                              label: 'Completed',
                              value: result.status.st_status,
                              color: '#68B8F2',
                            },
                          ]
                        } else {
                          valueofstatus = [
                            {
                              label: 'Transit',
                              value: result.status.st_status,
                              color: '#97BFD9',
                            },
                          ]
                          colorsArray = [
                            { label: 'Approved', value: 1, color: '#0ECB81' },
                          ]
                        }
                        return (
                          <React.Fragment key={key}>
                            <tr
                              id={`common-main-table-wrap-${result?.id}`}
                              key={key}
                            >
                              <>
                                <td className="fixed-side"> {result?.createdAt ? result?.createdAt : ''} </td>
                                <td className="po-no">
                                  <Link
                                    to={parmissiondatashow?.inventory_transaction_stock_transfer?.Edit ? "/app/inventory/edit-stocktransfer" : ""}
                                    state={{
                                      stockid: result?.id,
                                      transitiondate: result?.transaction_date,
                                      deliverydate:
                                        result?.delivery_date_unformated,
                                      voucher_id: result?.voucher_id,
                                      tf_no: result?.st_no,
                                      location_id: result?.transfer_to_location,
                                      stock_status: result?.status?.st_status,
                                      shipfromid: result?.transfer_from_loc,
                                      printparmission: parmissiondatashow?.inventory_transaction_stock_transfer?.Print
                                    }}
                                  >
                                    {result?.st_no ? result?.st_no : ''}
                                  </Link>
                                </td>
                                <td>{result?.transfer_from_loc_name ? result?.transfer_from_loc_name : ''}
                                </td>
                                <td> {result?.transfer_to_location_name ? result?.transfer_to_location_name : ''} </td>
                                <td
                                  className="sku-col table_btn_expand_col"
                                  id={'table-btn_' + result.id}
                                  onClick={() => setSubTable(result.id)}
                                >
                                  <div className="td-icons-wrap">
                                    <div>
                                      <img
                                        className="sku-col-arrow cursor-pointer"
                                        src={
                                          base_url_assets +
                                          "images/icons/right_green_button.png"
                                        }
                                        alt=""
                                      />
                                      <span>{result?.sku ? result?.sku : "0"}</span>
                                    </div>
                                    {result.allocatable ? <div className="td-icons-wrap-link">
                                      <img
                                        className="img "
                                        src={
                                          base_url_assets +
                                          'images/icons/link-icon.svg'
                                        }
                                        alt=""
                                      />
                                    </div> : ""}
                                    <div className='tooltip-area-up'>
                                      {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user tooltip-area-up"><img
                                        className="img"
                                        src={
                                          base_url_assets +
                                          'images/icons/gray-user.png'
                                        }
                                        alt=""
                                      /><span className="tooltip-area-up-text">{t("Customer")}</span>
                                        <span>{result?.pos_Qty}</span>
                                      </div> : ""}
                                      <span className="tooltip-area-up-text">{result?.pos?.customer_name}{result?.pos?.customer_name ? <br /> : ''}{result?.pos?.ref}<br />{formatDate(result?.pos?.delivery_date)}</span>
                                    </div>
                                  </div>
                                </td>
                                <td className='col-highlight'>{result?.total_quantity ? result?.total_quantity : ''}
                                </td>
                                <td className='col-highlight'>{result?.delivery_date ? result?.delivery_date : ''}
                                </td>
                                <td className="text-end col-highlight">{result?.due_days ? result?.due_days : '0'} </td>
                                <td className='col-highlight'>{result?.total_amount ? toFormatPrice(result?.total_amount, { addSymbol: true }) : ''}</td>
                                <td className='col-highlight'>
                                  <div
                                    className={
                                      'dropdown status-dropdown status-dropdown-select ' +
                                      status_class_val
                                    }
                                  >
                                    <Select
                                      options={colorsArray}
                                      isDisabled={
                                        result.status.st_status === 4 ||
                                          result.status.st_status === 5
                                          ? true
                                          : false
                                      }
                                      value={valueofstatus}
                                      onChange={(event) =>
                                        select_transfer_status(
                                          key,
                                          event,
                                          result.id,
                                        )
                                      }
                                      classNamePrefix="status-dropdown-select"
                                      styles={colorStyles}
                                      getOptionLabel={(e) => (
                                        <div className="">{e.label}</div>
                                      )}
                                      isSearchable={false}
                                    />
                                  </div>
                                </td>
                                <td className='col-highlight'>
                                  <div
                                    className={
                                      'dropdown status-dropdown status-dropdown-select ' +
                                      priority_class_val
                                    }
                                  >
                                    <Select
                                      options={priorityarray}
                                      value={valueofpriority}
                                      onChange={(event) =>
                                        select_transfer_priority(
                                          key,
                                          event,
                                          result.id,
                                        )
                                      }
                                      classNamePrefix="status-dropdown-select"
                                      styles={colorStyles}
                                      getOptionLabel={(e) => (
                                        <div className={e.class}>{e.label}</div>
                                      )}
                                      isSearchable={false}
                                    />
                                  </div>
                                </td>
                                <td className='col-highlight'>{result?.awb_number ? result?.awb_number : ''} </td>
                                <td className='col-highlight'> {result?.agent_name ? result?.agent_name : ''}</td>
                                <td className='col-highlight'> {result?.note ? result?.note : ''}</td>
                                <td className='col-highlight'>{result?.user_actions?.created_by_name ? result?.user_actions?.created_by_name : ''} </td>
                                <td className='col-highlight'>{result?.user_actions?.updated_by_name ? result?.user_actions?.updated_by_name : ''}</td>
                              </>
                            </tr>
                            {excuteTable ? (
                              <>
                                {typeof result.subTable == 'undefined'
                                  ? (result.subTable = '')
                                  : result.subTable}
                              </>
                            ) : null}
                          </React.Fragment>
                        );
                      }) : ''}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        {' '}
                        {stocktransferlist
                          ? stocktransferlist?.reduce(
                            (interation, val) =>
                            (interation =
                              interation + parseInt(val.total_quantity)),
                            0,
                          )
                          : ''}
                      </td>
                      <td />
                      <td />
                      <td>
                        {toFormatPrice(
                          stocktransferlist
                            ? stocktransferlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.total_amount)),
                              0,
                            )
                            : '', { addSymbol: true }
                        )}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {datalodaer && <TableLoader />}
              </div>
            </div>
            {stocktransferlist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <SocialModal />
        <ProductModal
          data_product={Productmodaldata}
          setOneproduct={setProductmodaldata}
          editproduct_submit={''}
        />
        <ProductImageSliderModal main_id={imageid}></ProductImageSliderModal>
      </div>
      <TableColumnModal
        tablehead={stocktransfertablehead}
        tblName={'tbl_inventory_stocktransfer'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={Getstocktransfer}
      ></TableColumnModal>
      <ConfirmationModel
        statement={statement}
        handler_submit={
          statement === 'Change Status'
            ? Getstocktransferstatus
            : Getstocktransferpriority
        }
        handler_dismiss={Getstocktransfer}
      ></ConfirmationModel>
      <AgentModel
        handler_submit={Getstocktransferstatus}
        handler_dismiss={Getstocktransfer}
        setAgentvalue={setAgentvalue}
        setAWBvalue={setAWBvalue}
      ></AgentModel>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#createPurchaseModal"
        id="status_query_select"
      ></button>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#agentModal"
        id="statustransit_query_select"
      ></button>
    </React.Fragment>
  );
}

export default Stock_transfer;
