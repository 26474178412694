import React from "react";
import { useTranslation } from 'react-i18next'

const Generalview = (props) => {
    const { t } = useTranslation()

    const { completeDataObject } = props
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="edit-item-area bg-white row">
                        <div className="col-md-12 px-0">
                            <div className="edit-item-area-body">

                                <div className="row">
                                    <div className="col-md-12 ps-0">
                                        <div className="row mx-0">

                                            <div className="col-md-12">
                                                <div className="v3-input-group">
                                                    <label className="v3-input-group-heading">{t("ACTIVATE OR REMOVE FEATURES")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="feature1" checked={completeDataObject?.Activate_loyalty_points === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="feature1">{t("Activate loyalty points rewards")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="feature2"
                                                        checked={completeDataObject?.ACTIVE_COUPON === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="feature2">{t("Active Gift Card")} </label>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="v3-input-group">
                                                    <label className="v3-input-group-heading">{t("GENERAL RULES")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules1"
                                                        checked={completeDataObject?.Template_with_Image === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules1">{t("Template With Image")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules2"
                                                        checked={completeDataObject?.Users_to_enter_PIN_for_every_sale === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules2">{t("Users to enter PIN for every sale")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules3"
                                                        checked={completeDataObject?.Product_master_display_cost === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules3">{t("Product master display cost")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules4"
                                                        checked={completeDataObject?.Cash_Register_close_every_day === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules4">Cash Register close every day </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules5"
                                                        checked={completeDataObject?.Allow_user_manually === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules5">{t("Allow user manually lock POS screen")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules6"
                                                        checked={completeDataObject?.Already_exchange_item === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules6">{t("Already exchange item can be Exchange & Return again")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules7"
                                                        checked={completeDataObject?.Switch_between_user === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules7">{t("Switch between user using PIN")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules8"
                                                        checked={completeDataObject?.Every_customer_must === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules8">{t("Every customer must have a unique phone number")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules9"
                                                        checked={completeDataObject?.allowWarehouse === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules9">{t("Allow Warehouse")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules10"
                                                        checked={completeDataObject?.Allow_processing_Refund === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules10">{t("Allow processing Refund & Exchange at different outlet")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules11"
                                                        checked={completeDataObject?.Prompt_to_enter_delivery === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules11">{t("Prompt to enter delivery address for every sale")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules12"
                                                        checked={completeDataObject?.Cashier_to_required_manager_permission === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules12">{t("Cashier to required manager permission to discount a product price to lower then its cost price")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules13"
                                                        checked={completeDataObject?.POS_partial_payment === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules13">{t("POS partial payment ( lay By , On-Account)")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules14"
                                                        checked={completeDataObject?.POS_change_price === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules14">{t("POS : Allow to change price provision")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules15"
                                                        checked={completeDataObject?.logistic_status === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules15">{t("Allow Logistic in Stock Transfer")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules16"
                                                        checked={completeDataObject?.productChoice === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules16">{t("Allow Product Design")} </label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules17"
                                                        checked={completeDataObject?.customOrder_delivery?.status === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules17">{t("Custom order standard delivery date")}  <label className="px-3 mx-1 border rounded-1">{completeDataObject?.customOrder_delivery?.weeks}</label>  Weeks</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules18"
                                                        checked={completeDataObject?.reserve_delivery?.status === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules18">{t("Reserve Delivery")}
                                                        <label className="px-3 mx-1 border rounded-1">{completeDataObject?.reserve_delivery?.weeks}</label> {t("Days")}</label>

                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules19"
                                                        checked={completeDataObject?.repairOrder_delivery?.status === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules19">{t("Repair order standard delivery date")}
                                                        <label className="px-3 mx-1 border rounded-1">
                                                            {completeDataObject?.repairOrder_delivery?.weeks}</label>  {t("Weeks")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules20"
                                                        checked={completeDataObject?.purchaseOrder_delivery?.status === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules20">{t("Purchase order standard delivery date")}    <label className="px-3 mx-1 border rounded-1">
                                                        {completeDataObject?.purchaseOrder_delivery?.weeks}</label> {t("Weeks")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules21"
                                                        checked={completeDataObject?.servicelabers === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules21">{t("Allow Service labour")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules22"
                                                        checked={completeDataObject?.isPark === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules22">{t("Park")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="generalRules23"
                                                        checked={completeDataObject?.isQuotation === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="generalRules23">{t("Quotation")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="blockChainLedger"
                                                        checked={completeDataObject?.blockChainLedger === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="blockChainLedger">
                                                        {t("Block Chain Ledger")}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="v3-input-group">
                                                    <label className="v3-input-group-heading">{t("INVENTORY NOTIFICATIONS")}</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="inventoryNotification1"
                                                        checked={completeDataObject?.Low_Stock_Notification_Email === "1" ? true : false} readOnly/>
                                                    <label className="form-check-label cursor-pointer" htmlFor="inventoryNotification1">{t("Low stock notification on Email")}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Generalview;