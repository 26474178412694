import React, { useState, useEffect, useRef } from 'react'
import RightArrow from '../../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import { _Api } from '../../api/_call'
import './barcode.scss'
import TableLoader from '../../common/TableLoader'
import { loading, Updateloading } from '../../common/helpers/toastify'
import Select from 'react-select'
import { Pagination } from '../../common/Pagination'
import SearchIcon from '../../common/icons/SearchIcon'
import CloseIcon from '../../common/icons/CloseIcon'
import GreenTick from '../../common/icons/GreenTick'
import { DateRange } from '../../../components/common/helpers/daterange'
import PrintIcon from '../../../Ecommerce/template_two/default/assets/icons/PrintIcon'
import CalendarIcon from '../../common/icons/sidebar/CalendarIcon'
import Calendar from '../../common/icons/sidebar/Calendar'

const BarcodeSummary = () => {
    const [activeTab, setActiveTab] = useState("stockid");
    const [selectedRows, setSelectedRows] = useState([])
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { t } = useTranslation();
    const [showSubtasks, setShowSubtasks] = useState(false);
    const [activeSubtask, setActiveSubtask] = useState('Collapse all');
    const subtaskRef = useRef(null);
    const [state, setstate] = useState({
      totalPages: 20,
      currentPage: 1,
    });
  const [showloader, setShowloader] = useState(false);
  const { totalPages, currentPage } = state;
  const [showSearch, setShowSearch] = useState(false);
  const [filtername, setFiltername] = useState('');
  const [limit, setLimit] = useState(100);
  const [datefilter, setDatefilter] = useState([]);
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getlogdata(current);

  };
  const handleclosefilter = () => {
   
  }
  const getlimit = (limit) => {
      setLimit(limit);
      getlogdata(1, limit);
      setstate({ ...state, currentPage: 1 });
    };

    const filterbyname = () => {
      getlogdata(currentPage);
      setstate({ ...state, currentPage: 1 });
    };

  const getlogdata = async (current, firstlimit) => {
      setShowloader(true);
      try {
        var skipNumber = current ? (current - 1) * limit : 0;
        let postdata = {
          "search": filtername,
          "limit": firstlimit ? firstlimit : limit,
          "skip": skipNumber,
        }
        let res = await _Api(postdata, `api/v1/App/applicationkey/get-list`)
        if (res?.code === 200) {
          // setlog(res?.data)
          setShowloader(false)
          setstate({
            ...state,
            totalPages: Math.ceil(res?.total / postdata.limit),
            currentPage: current ? current : 1
          });
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }

  const selectOptions = [
      { label: 100, value: 100 },
      { label: 200, value: 200 },
      { label: 300, value: 300 }
    ]

    const handleRowClick = (id)=>{
      if(selectedRows.includes(id)){
        let newRows = selectedRows.filter((item)=> item !== id);
        setSelectedRows((prev)=>[...newRows])
        console.log(id, selectedRows, "alreadu present")
      }else{
       
        selectedRows.push(id)
        console.log(id, selectedRows, "new value")
      }
    }

    const logdata = [
      {user: "1", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "2", printout : "4"},
      {user: "2", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "2", printout : "4"},
      {user: "2", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "2", printout : "4"},
      {user: "2", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "2", printout : "4"},
      {user: "2", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "2", printout : "4"},
      {user: "2", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "2", printout : "4"},
      {user: "2", date: "22/07/2024", time: "1:11:50 PM", SKU: "SB025145", stockid: "1890089", template : "2", printout : "4"}
    ]
    return (
        <>
            <div className="main-content-wrapper-body summarypage">
                <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
                    <div className="main-content-wrapper-body-top-bar-left">
                        <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                            {t('Setup')} <RightArrow /> {t('Barcode')} <RightArrow />
                            <span className="">{t('Logs')}</span>
                        </h1>
                    </div>
                </div>
                <div className="main-body-top-tab-bar">
                    <div className="main-body-top-tab-bar-left-col nav nav-pills" role="tablist">
                      <div
                        className={
                          activeTab === 'stockid'
                            ? 'main-body-top-tab-bar-left-col-a nav-link active cursor-pointer'
                            : 'main-body-top-tab-bar-left-col-a nav-link cursor-pointer'
                        }
                        onClick={()=>setActiveTab("stockid")}
                      >
                        <span class="main-body-top-tab-bar-left-col-a-text">{t("Stock ID")}</span> 
                      </div>
                      <div
                        className={
                          activeTab === 'sku'
                            ? 'main-body-top-tab-bar-left-col-a nav-link active cursor-pointer'
                            : 'main-body-top-tab-bar-left-col-a nav-link cursor-pointer'
                        }
                        onClick={()=>setActiveTab("sku")}
                      >
                       <span class="main-body-top-tab-bar-left-col-a-text">{t("SKU")}</span>
                      </div>
                    </div>
                </div>
                <div className="main-content-wrapper-body-top-bar-right summarypage-subheader">
                    <div className='summarypage-subheader-left'>
                      <Select
                          className="limit-select"
                          options={selectOptions}
                          value={{ label: limit, value: limit }}
                          onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                      />
                      <div className='summarypage-subheader-left-filters'>
                        <span className='summarypage-subheader-left-filters-title'>{t("Smart Filters")}:</span>
                        <div className='position-relative' ref={subtaskRef}>
                          <div className={`calendarlist-headbar-left-subtasks ${activeSubtask === 'Expand all' ? 'active' : ''}`} onClick={() => { setShowSubtasks(!showSubtasks); handleclosefilter() }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16 13C17.1046 13 18 12.1046 18 11C18 9.89543 17.1046 9 16 9C14.8954 9 14 9.89543 14 11C14 12.1046 14.8954 13 16 13Z" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                              <path d="M16 21C17.1046 21 18 20.1046 18 19C18 17.8954 17.1046 17 16 17C14.8954 17 14 17.8954 14 19C14 20.1046 14.8954 21 16 21Z" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                              <path d="M5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3Z" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                              <path d="M5 8V15C5 16.8856 5 17.8284 5.58579 18.4142C6.17157 19 7.11438 19 9 19H14" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                              <path d="M5 7C5 8.88562 5 9.82843 5.58579 10.4142C6.17157 11 7.11438 11 9 11H14" stroke={activeSubtask === 'Expand all' ? '#52CBC4' : "#A0A0A0"} />
                            </svg>
                            <span>{t("Subtasks")}</span>
                          </div>
                          {showSubtasks ?
                            <div className='calendarlist-headbar-left-subtaskdropdown'>
                              <div className='calendarlist-headbar-left-subtaskdropdown-inner'>
                                <div className='calendarlist-headbar-left-subtaskdropdown-inner-title'>{t("Show Subtasks")}</div>
                                <div className='calendarlist-headbar-left-subtaskdropdown-inner-list'>
                                  <div className='calendarlist-headbar-left-subtaskdropdown-inner-list-item' onClick={() => { setActiveSubtask('Collapse all'); setShowSubtasks(!showSubtasks) }}>
                                    <div className='heading'>
                                      {t("Collapse all")}
                                      <span className='default'>{t("(default)")}</span>
                                    </div>
                                    {activeSubtask === 'Collapse all' ?
                                      <div>
                                        <GreenTick />
                                      </div>
                                      : ""}
                                  </div>
                                  <div className='calendarlist-headbar-left-subtaskdropdown-inner-list-item' onClick={() => { setActiveSubtask('Expand all'); setShowSubtasks(!showSubtasks) }}>
                                    <div>{t("Expand all")}</div>
                                    {activeSubtask === 'Expand all' ?
                                      <div>
                                        <GreenTick />
                                      </div>
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className='summarypage-subheader-right'>
                    <span className='summarypage-subheader-right-printer'>
                    <div className={`com-search-bar2 position-relative ${showSearch ? "active" : ""}`}>
                        <span className="com-search-bar2-searchbar" onClick={()=>{setShowSearch(!showSearch); filterbyname()}}><SearchIcon/></span>
                        
                        <input
                        placeholder="Search Device"
                        className="h-100 border-0 bg-transparent"
                          onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                          onChange={(e) => setFiltername(e.target.value)}
                        />
                        <span className='com-search-bar2-closeicon' onClick={()=>{setShowSearch(false);setFiltername("")}}><CloseIcon/></span>
                    </div>
                    </span>
                    <div className='summarypage-subheader-right-calendarholder'>
                    <span className='summarypage-subheader-right-calendarholder-icon'> <Calendar color="#A4A4A4" width="20px" height="20px" /></span>
                    <DateRange setDatefilter={setDatefilter}/>
                    </div>
                    <span className='summarypage-subheader-right-printer'><PrintIcon color="#A4A4A4"/></span>
                    </div>
                </div>
                {activeTab === 'stockid' ?
                
                //stock id table 

                <div className="row mt-4">
          <div className="col-md-12">
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="bg-white text-nowrap align-middle">
                    <th className='bg-white'><input type='checkbox'/></th>
                    <th className="bg-white">#</th>
                    <th className="bg-white">{t("StockID")}	</th>
                    <th className="bg-white">{t("SKU")}	</th>
                    <th className="bg-white">{t("User")}	</th>
                    <th className="bg-white">{t("Template")}</th>
                    <th className="bg-white">{t("Print out")}	</th>
                    <th className="bg-white">{t("Date & Time")}	</th>
                    <th className="bg-white">{t("Action")}	</th>
                  </tr>
                </thead>
                <tbody>
                  {logdata.length > 0 ? logdata.map((result, key) => {
                    return (
                      <>
                      <tr className="hoverrow text-nowrap" key={key}>
                        <td><span className='d-flex align-items-center gap-2' onClick={()=>handleRowClick(key)}>{selectedRows.includes(key) ? "" : <RightArrow color="#52CBC4"/>}<input type='checkbox'/></span></td>
                        <td><span>{key + 1}</span></td>
                        <td><span className='link-color'>{result?.stockid ? result?.stockid : "-"}</span></td>
                        <td><span className='link-color'>{result?.SKU}</span></td>
                        <td><span>{result?.user ? result?.user : "-"}</span></td>
                        <td><span>{result?.template ? result?.template : "-"}</span></td>
                        <td><span>{result?.printout ? result?.printout : "-"}</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                       
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr>
                      {selectedRows.includes(key) ? 
                      <div className='row'>
                       <div className="col-md-12">
            <div
              className="main-body-main-table-wrap table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                <tr className="bg-white text-nowrap align-middle">
                    <th className='bg-white'><input type='checkbox'/></th>
                    <th className="bg-white">{t("User")}	</th>
                    <th className="bg-white">{t("Template")}</th>
                    <th className="bg-white">{t("Print out")}	</th>
                    <th className="bg-white">{t("Date & Time")}	</th>
                    <th className="bg-white">{t("Action")}	</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {logdata.length > 0 ? logdata.map((result, key) => {
                    return ( */}
                     
                      <tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr>
                      <tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr><tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr><tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr>
                  {/* //   )
                  // }) 
                  // :
                  //   <tr>
                  //     <td colSpan="50" className="text-center">
                  //       {' '}
                  //       {t("No Data Available In Table")}
                  //     </td>
                  //   </tr>
                  // } */}
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
                      </div>
                      </div> 
                      :null }
                      </>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center">
                        {' '}
                        {t("No Data Available In Table")}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {logdata.length > 0 && (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            )}
          </div>
                </div>
                :
                //sku table
                <div className="row mt-4">
          <div className="col-md-12">
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="bg-white text-nowrap align-middle">
                    <th className='bg-white'><input type='checkbox'/></th>
                    <th className="bg-white">#</th>
                    <th className="bg-white">{t("SKU")}	</th>
                    <th className="bg-white">{t("User")}	</th>
                    <th className="bg-white">{t("Template")}</th>
                    <th className="bg-white">{t("Print out")}	</th>
                    <th className="bg-white">{t("Date & Time")}	</th>
                    <th className="bg-white">{t("Action")}	</th>
                  </tr>
                </thead>
                <tbody>
                  {logdata.length > 0 ? logdata.map((result, key) => {
                    return (
                      <>
                      <tr className="hoverrow text-nowrap" key={key}>
                        <td><span className='d-flex align-items-center gap-2' onClick={()=>handleRowClick(key)}>{selectedRows.includes(key) ? "" : <RightArrow color="#52CBC4"/>}<input type='checkbox'/></span></td>
                        <td><span>{key + 1}</span></td>
                        <td><span className='link-color'>{result?.SKU}</span></td>
                        <td><span>{result?.user ? result?.user : "-"}</span></td>
                        <td><span>{result?.template ? result?.template : "-"}</span></td>
                        <td><span>{result?.printout ? result?.printout : "-"}</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                       
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr>
                      {selectedRows.includes(key) ? 
                      <div className='row'>
                       <div className="col-md-12">
            <div
              className="main-body-main-table-wrap table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                <tr className="bg-white text-nowrap align-middle">
                    <th className='bg-white'><input type='checkbox'/></th>
                    <th className="bg-white">{t("User")}	</th>
                    <th className="bg-white">{t("Template")}</th>
                    <th className="bg-white">{t("Print out")}	</th>
                    <th className="bg-white">{t("Date & Time")}	</th>
                    <th className="bg-white">{t("Action")}	</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {logdata.length > 0 ? logdata.map((result, key) => {
                    return ( */}
                     
                      <tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr>
                      <tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr><tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr><tr className="hoverrow text-nowrap" key={key}>
                        <td><span><input type='checkbox'/></span></td>
                        <td><span>Jay Park</span></td>
                        <td><span className='link-color'>TEMP01 test add profile</span></td>
                        <td><span className='link-color'>1</span></td>
                        <td><span>{result?.date ? result?.date : "-"} {result?.time ? result?.time : "-"}</span></td>
                        <td><span><PrintIcon color="#A4A4A4"/></span></td>
                        
                      </tr>
                  {/* //   )
                  // }) 
                  // :
                  //   <tr>
                  //     <td colSpan="50" className="text-center">
                  //       {' '}
                  //       {t("No Data Available In Table")}
                  //     </td>
                  //   </tr>
                  // } */}
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
                      </div>
                      </div> 
                      :null }
                      </>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center">
                        {' '}
                        {t("No Data Available In Table")}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {logdata.length > 0 && (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            )}
          </div>
                </div>

                }
            </div>

        </>
    )
}

export default BarcodeSummary
