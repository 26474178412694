import { Helmet } from 'react-helmet';

const UpdateTitle = ({ title }) => {
    const baseTitle = 'GIS247 : Inventory and POS Management Software for Jewellery Industry';
    const newTitle = title ? `GIS247 : ${title}` : baseTitle;

    return (
        <Helmet>
            <title>{newTitle} </title>
        </Helmet>
    );
};

export default UpdateTitle;
