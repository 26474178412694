import React, { useState, useEffect } from 'react';
import Select from "react-select"
import { _Api } from "../api/_call";
import { error, loading, Updateloading } from "../common/helpers/toastify";
import RightArrow from "../common/icons/right-arrow";
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const SmtpEmail = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [completeDataObject, setCompliteDataObject] = useState()
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  const getsmtp = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/profile/get-smtp`)
      if (res?.code === 200) {
        setCompliteDataObject(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getsmtp()
  }, [])
  const encryptionOption = [
    { label: "SSl", value: "SSl" },
    { label: "TSL", value: "TSL" },
    { label: "None", value: "None" },
  ]
  const editsmtp = async () => {
    if (!completeDataObject?.smtp_host) {
      error(t("SMTP Host field is required"))
      return;
    }
    if (!completeDataObject?.smtp_port) {
      error(t("SMTP Port field is required"))
      return;
    }
    if (!completeDataObject?.smtp_username) {
      error(t("SMTP Username field is required"))
      return;
    }
    if (!completeDataObject?.smtp_password) {
      error(t("SMTP Password field is required"))
      return;
    }
    if (!completeDataObject?.smtp_encryption) {
      error(t("SMTP Encryption field is required"))
      return;
    }
    let GetloadingID = loading();
    try {
      let postdata = completeDataObject
      let res = await _Api(postdata, `api/v1/App/profile/edit-smtp`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        getsmtp()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <Link className="text-decoration-none" to="/app/admin/settings">{t("Setting")}</Link>  <RightArrow /> <span className="">{t("SMTP Email")}</span></h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center"
          onClick={() => editsmtp()}>
          <button className="square-btn"><img src={base_assets + "images/icons/white-save.png"} alt="" /></button>
        </div>
      </div>
      <div className="admin-main-card">
        <div className="row">
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("SMTP HOSTS")}</label>
              <input type="text" className="v3-input-group-input w-100"
                value={completeDataObject?.smtp_host || ''}
                onChange={(e) => onChangeValueUpdate("smtp_host", e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("SMTP PORT")}</label>
              <input type="text" className="v3-input-group-input w-100"
                value={completeDataObject?.smtp_port || ''}
                onChange={(e) => onChangeValueUpdate("smtp_port", e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("SMTP Email UserName")}</label>
              <input type="text" className="v3-input-group-input w-100"
                value={completeDataObject?.smtp_username || ''}
                onChange={(e) => onChangeValueUpdate("smtp_username", e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("SMTP Email Password")}</label>
              <input type="password" className="v3-input-group-input w-100"
                value={completeDataObject?.smtp_password || ''}
                onChange={(e) => onChangeValueUpdate("smtp_password", e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("SMTP Encryption")}</label>
              <Select
                className="select-set select w-100 p-0"
                placeholder=""
                options={encryptionOption}
                value={{ label: completeDataObject?.smtp_encryption, value: completeDataObject?.smtp_encryption }}
                onChange={(e) => onChangeValueUpdate("smtp_encryption", e.value)}
                classNamePrefix="common-select"
                isSearchable={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmtpEmail
