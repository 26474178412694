import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import TasksTick from '../../admin/common/icons/sidebar/TasksTick';
import RightIcon from '../../admin/common/icons/sidebar/RightIcon';
import { Draggable } from '@fullcalendar/interaction';
import ThreeDotRows from '../common/icons/sidebar/ThreeDotRows';
import CalendarModal from './CalendarModal';
import { _Api } from '../api/_call';
import { formatDate } from '../common/helpers/formatDate';
import CustomerList from './CustomerList/Index';
import MultipleUserIconFilled, { MultipleUserIcon } from '../common/icons/MultipleUserIconFilled';
export default function TaskSidebar(props) {
  const {
    taskbardata,
    getcalenderlist,
    taskbarlistupdate,
    setTaskbarlistupdate
  } = props
  const [currentEvents, setCurrentEvents] = useState([])
  const [tasktypes, setTasktypes] = useState({
    "type": "",
    "filter_type": []
  })
  const { t } = useTranslation();
  const draggableRef = useRef(null);
  const selectdropdwonitems = [
    { title: "Repair Order", type: "repair", total: 0 },
    { title: "Custom Order", type: "custom", total: 0 },
    { title: "Reserved Order", type: "reservee", total: 0 },
    { title: "Purchase Order", type: "purchase", total: 0 },
    { title: "Appointment", type: "appointment", total: 0 }
  ]
  const dropdownRef = useRef(null);
  const [activeTask, setActiveTask] = useState('All');
  const [getTaskCounts, setTaskCounts] = useState({});
  const [showSelectedDropdown, setShowSelectedDropdown] = useState(false);
  const [loadingdata, setLodaingdata] = useState(false)
  const [customerCount, setCustomerCount] = useState(0);
  const [customerData, setCustomerData] = useState();
  const [taskBarInnerFliter, settaskBarInnerFliter] = useState(selectdropdwonitems);
  const [dataid, setDataid] = useState({ _id: "", index: "" })
  const taskElementRef = useRef(null);
  useEffect(() => {
    const draggableEl = draggableRef.current;
    if (draggableEl) {
      const draggable = new Draggable(draggableEl, {
        itemSelector: '.draggable-item'
      });

      return () => {
        draggable.destroy();
      };
    }
  }, []);

  useEffect(() => {
    getCustomerlist();
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSelectedDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getCustomerlist = async () => {
    let postdata = {
      "search": ''
    }
    try {
      let res = await _Api(postdata, `api/v1/App/calender/getCalender-customer-info`)
      if (res?.code === 200) {
        setCustomerData(res?.Data);
        setCustomerCount(res?.Data?.length)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const getCounttaskbar = useCallback(async () => {
    setTaskbarlistupdate(false)
    setLodaingdata(true);
    if (!taskbardata?.date) {
      let date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`
      taskbardata.date = formattedDate;
    }
    try {
      let postdata = {
        "date": taskbardata?.date,
        "type": tasktypes?.type,
        "filter_type": tasktypes?.filter_type
      }
      let res = await _Api(postdata, `api/v1/App/calender/getCalenderData-list-counts`)
      if (res?.code === 200) {
        setTaskCounts(res?.data);
        let resData = res?.data?.filtersCount;
        let updateFlitersCount = [
          { title: "Repair Order", type: "repair", total: resData["Repair Order"] },
          { title: "Custom Order", type: "custom", total: resData?.["Custom Order"] },
          { title: "Reserved Order", type: "reservee", total: resData?.['Reserved Order'] },
          { title: "Purchase Order", type: "purchase", total: resData?.["Purchase Order"] },
          { title: "Appointment", type: "appointment", total: resData?.Appointment }
        ]
        settaskBarInnerFliter(updateFlitersCount);
      }
    }
    catch (err) {
      console.log(err, "Err")
    }
  }, [setTaskbarlistupdate, taskbardata, tasktypes?.filter_type, tasktypes?.type])

  const gettaskbar = useCallback(async () => {
    setTaskbarlistupdate(false)
    setLodaingdata(true);
    if (!taskbardata?.date) {
      let date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`
      taskbardata.date = formattedDate;
    }
    try {
      let postdata = {
        "date": taskbardata?.date,
        "type": tasktypes?.type,
        "filter_type": tasktypes?.filter_type
      }
      let res = await _Api(postdata, `api/v1/App/calender/getCalenderData-list`)
      if (res?.code === 200) {
        setCurrentEvents(res?.data)
        setLodaingdata(false)
      }
    }
    catch (err) {
      console.log(err, "Err")
    }
  }, [setTaskbarlistupdate, taskbardata, tasktypes?.filter_type, tasktypes?.type])
  useEffect(() => {
    if (taskbardata?.runapi) {
      gettaskbar();
      getCounttaskbar();
    }

  }, [getCounttaskbar, gettaskbar, taskbardata, tasktypes])
  useEffect(() => {
    if (taskbarlistupdate) {
      getCounttaskbar();
      gettaskbar()
    }

  }, [getCounttaskbar, gettaskbar, taskbarlistupdate])
  useEffect(() => {
    getCounttaskbar();
    gettaskbar()
  }, [getCounttaskbar, gettaskbar])
  const changeselect = (label) => {
    let updatedArray = tasktypes?.filter_type
    if (updatedArray.includes(label)) {
      updatedArray = updatedArray.filter(item => item !== label);
    } else {
      updatedArray.push(label);
    }
    setTasktypes((state) => ({
      ...state,
      filter_type: updatedArray
    }))
    console.log(tasktypes, "task types", showSelectedDropdown)
  }
  return (
    <div className={`tasksidebar makevisible ${taskbardata?.runapi ? 'makevisible' : ""}`}>
      <div className='tasksidebar-title'>
        <div className='tasksidebar-title-left'><TasksTick /><div className='mx-2'>{t("Tasks")}</div></div>
        <div className='tasksidebar-title-right'>{taskbardata?.date ? taskbardata?.date : formatDate(new Date())}</div>
      </div>
      <div className='tasksidebar-tabs'>
        <div
          className="tasksidebar-tabs-inner"
          role="tablist"
        >
          <div onClick={() => {
            setActiveTask('All')
            setTasktypes((state) => ({
              ...state,
              type: ""
            }))
          }} className={`tasksidebar-tabs-inner-item cursor-pointer ${activeTask === 'All' ? 'active' : ''}`}>
            <span className="tasksidebar-tabs-inner-item-text">
              {t("All Tasks")} ({getTaskCounts?.AllTask})
            </span>
          </div>
          <div
            onClick={() => {
              setActiveTask('Overdue');
              setTasktypes((state) => ({
                ...state,
                type: "Overdue"
              }))
            }}
            className={`tasksidebar-tabs-inner-item cursor-pointer ${activeTask === 'Overdue' ? 'active' : ''}`}>
            <span className="tasksidebar-tabs-inner-item-text">
              {t("Overdue")} ({getTaskCounts?.Overdue})
            </span>
          </div>
          <div
            onClick={() => {
              setActiveTask('Completed');
              setTasktypes((state) => ({
                ...state,
                type: "Completed"
              }))
            }}
            className={`tasksidebar-tabs-inner-item cursor-pointer ${activeTask === 'Completed' ? 'active' : ''}`}>
            <span className="tasksidebar-tabs-inner-item-text">
              {t("Completed")}  ({getTaskCounts?.Completed})
            </span>
          </div>
          <div onClick={() => { setActiveTask('ShowCustomers') }} className={`tasksidebar-tabs-inner-item type-customerlist cursor-pointer ${activeTask === 'ShowCustomers' ? 'active' : ''}`}>
            {activeTask === 'ShowCustomers' ?
              <span className="tasksidebar-tabs-inner-item-text">
                <MultipleUserIconFilled />
              </span>
              :
              <span className="tasksidebar-tabs-inner-item-text">
                <MultipleUserIcon />
              </span>
            }
            <div className='type-customerlist-outer'><div className='type-customerlist-outer-count'>{customerCount}</div></div>
          </div>
          <div>
          </div>
        </div>

      </div>
      {activeTask !== 'ShowCustomers' ?
        <div className='tasksidebar-filterTask d-flex justify-content-between align-items-center position-relative' ref={dropdownRef}>
          <div className='tasksidebar-filterTask-left align-items-center'>
            <div className='me-2'>{t("Filters")}</div>
            <div className='tasksidebar-filterTask-left-select d-flex align-items-center cursor-pointer' onClick={(e) => { setShowSelectedDropdown(!showSelectedDropdown) }}>
              <span className='cursor-pointer'>{t("Selected ")}{tasktypes.filter_type.length > 0 ? `(${tasktypes.filter_type.length})` : ""}</span>
              <div className={`tasksidebar-filterTask-left-icon ${showSelectedDropdown ? 'open' : "close"}`}><RightIcon /></div>
            </div>
          </div>
          <div className='tasksidebar-filterTask-right'>
            {currentEvents?.length} {currentEvents?.length > 1 ? t("Tasks") : t("Task")}
          </div>
          {
            showSelectedDropdown ?
              <div className='tasksidebar-filterTask-selectdropdown' >
                <div className='tasksidebar-filterTask-selectdropdown-inner'>
                  <div className='tasksidebar-filterTask-selectdropdown-inner-title'>
                    <div className='tasksidebar-filterTask-selectdropdown-inner-title-left'>{t("Quick Filters")}</div>
                    <div className='cursor-pointer' onClick={() => {
                      setShowSelectedDropdown(!showSelectedDropdown);
                      setTasktypes((state) => ({
                        ...state,
                        filter_type: []
                      }))
                    }}>{t("Clear All")}</div>
                  </div>
                  <div className='tasksidebar-filterTask-selectdropdown-inner-content'>
                    {
                      taskBarInnerFliter?.map((item, i) => {
                        return (
                          <div key={i} className='tasksidebar-filterTask-selectdropdown-inner-content-holder gap-2'>
                            <div className="com-check">
                              <input id={item?.title} type="checkbox"
                                checked={tasktypes?.filter_type.includes(item?.title)}
                                onChange={() => changeselect(item?.title)}
                                readOnly />
                              <label htmlFor={item?.title} className="com-check-label"
                              />
                            </div>
                            <div className={`tasksidebar-filterTask-selectdropdown-inner-content-holder-type ${item?.type}`}></div>
                            <div>{item?.title} ({item?.total})</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              : ""
          }
        </div>
        :
        ""}
      <div ref={draggableRef}>
        {loadingdata ?
          <div className='loader-holder'>
            <div id="dark_loader" className="mt-4">
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          :
          <div className={`tasksidebar-taskbar ${activeTask === "ShowCustomers" ? "forCustomerlist" : ""}`}>
            {activeTask === 'All' || activeTask === 'Overdue' || activeTask === 'Completed' ?
              currentEvents?.length ? currentEvents.map((event, index) => {
                // const isDate = !isNaN(Date.parse(event?.CompletedDate));
                return (
                  <div
                    key={index}
                    className='cursorhandle cursor-move'
                  >
                    <div
                      className='tasksidebar-taskbar-task w-100 cursor-pointer draggable-item'
                      ref={taskElementRef}
                      data-bs-toggle="modal"
                      data-bs-target="#showtaskmodel"
                      key={index}
                      data={JSON.stringify(event)}
                      onClick={() => setDataid({ index: index + 1, _id: event?._id })}
                    >
                      <div className='d-flex flex-start w-100'>
                        <div
                          draggable="true"
                          key={index}
                          className='tasksidebar-taskbar-task-handleicon'
                        >
                          <ThreeDotRows />
                        </div>
                        <div className={`tasksidebar-taskbar-task-type ${event?.Activity} me-2`}></div>
                        <div className='tasksidebar-taskbar-task-inside w-100 d-block'>
                          <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className={`${event?.order_status === 'Completed' ? "text-dark text-decoration-line-through" : "text-dark"}`}>{event.Activity} : {event.OrderNumber}</div>
                            <div className={event?.OverdueStatus ? 'text-danger' : ""}>{event?.CompletedDate}</div>
                          </div>
                          <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className={`${event?.order_status === 'Completed' ? "text-decoration-line-through" : ""}`}>{event?.Customername}</div>
                            <div className='text-ao'>{t("SKU")}({event?.SKU}) | {t("QTY")}({event?.QTY})</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) : ""
              : activeTask === 'ShowCustomers' ?
                <div className='tasksidebar-taskbar-customerwrapper'> <CustomerList customerData={customerData} setCustomerCount={setCustomerCount} /> </div>
                : ""
            }
          </div>
        }
      </div>
      <CalendarModal currentEvents={currentEvents} dataid={dataid} getcalenderlist={getcalenderlist} gettaskbar={gettaskbar} />
    </div >
  )
}