import React, { useState, useEffect, useCallback } from 'react';
import { _Api } from '../api/_call';
import TableColumnModal from '../common/modules/tableColumn';
import { Tablehead } from '../common/modules/Tablehead';
import DateTime from '../common/DateTime';
import { inventorystockidmovementlisthead } from '../common/Tablehead';
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import 'datatables.net-fixedcolumns';
import { DateRange } from '../common/helpers/daterange';
import SocialModal from '../common/modules/SocialModal/SocialModal';
import Select from "react-select";
import {
  loading,
  Updateloading,
} from '../common/helpers/toastify';
import ProductImageSliderModal from '../common/modules/productImageslider';
import ProductModal from '../common/modules/ProductModal';
import Filtermodal from "../common/modules/Filtermodal";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { useSelector } from 'react-redux';
import TableLoader from '../../admin/common/TableLoader';
const StockidMovement = (props) => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [filtername, setFiltername] = useState('')
  const [datefilter, setDatefilter] = useState([])
  const [limit, setLimit] = useState("100")
  const [newcolumndata, setnewcolumndata] = useState(inventorystockidmovementlisthead)
  const [stockidmovementlist, setstockidmovementlist] = useState()
  const [stockidmovementcounts, setstockidmovementcounts] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [socialurl, setSocialurl] = useState()
  const [imageid, setImageid] = useState()
  const [getoneproduct, setOneproduct] = useState()
  const [SelectAllStatus, setSelectAllStatus] = useState()
  const [selecteditem, setSelecteditem] = useState([]);
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [filterdata, setFilterdata] = useState()
  const filterbyname = (search) => {
    getstockidmovement(100, filterdata, search)
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const [state] = useState({
    totalPages: 20,
    currentPage: 1,
  });


  const getstockidmovement = useCallback(async (firstlimit, filter, search) => {
    setDatsLodaer(true)
    setFilterdata(filter)
    try {
      UnSetdataTable('stockid_movement')
      const postdata = {
        item: filter?.item ? filter?.item : [],
        collection: filter?.collection ? filter?.collection : [],
        metal: filter?.metal ? filter?.metal : [],
        size: filter?.size ? filter?.size : [],
        stone: filter?.stone ? filter?.stone : [],
        productStatus: filter?.productStatus ? filter?.productStatus : [],
        location: filter?.location ? filter?.location : [],
        price: filter?.price ? filter?.price : [],
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        skip: 0,
      }
      let resPoinventory = await _Api(postdata, `api/v1/Inventory/productMovement/StockIDWithSKUMovement`)
      if (resPoinventory?.code === 200) {
        SetdataTable('stockid_movement', '5', newcolumndata)
        setstockidmovementlist(resPoinventory?.data)
        setstockidmovementcounts(resPoinventory?.count)
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable('stockid_movement', '5', newcolumndata)
      }
    } catch (err) {
      console.log(err, "err")
    }
    // eslint-disable-next-line
  }, [filtername, limit, state]);

  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_stockid_movement',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    getstockidmovement()
    // eslint-disable-next-line
  }, [])

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    inventorystockidmovementlisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })

    if (stockidmovementlist.length) {
      stockidmovementlist.forEach((result, key) => {

        bodyarray.push([key + 1, result?.main_image, result?.stock_id, result?.SKU, result?.metal_name, result?.stone_name, result?.size_name,
        result?.item_name, result?.collection_name, result?.locations, result?.aging, result?.inward_Qty, result?.outward_Qty,
        result?.closing_Qty, result?.closing_amount
        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "STOCKID_MOVEMENT");

  }
  const getprintstockid = async () => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "search": filtername,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : "",
        "product_ids": selecteditem ? selecteditem : []
      };
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/stockIDCard`);

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a');
          exportLinkElement.hidden = true;
          exportLinkElement.download = res?.name ? res?.name : "report.pdf";
          exportLinkElement.href = res?.link;
          exportLinkElement.text = "downloading...";
          exportLinkElement.setAttribute('target', '_blank');
          document.body.appendChild(exportLinkElement);
          exportLinkElement.click();
          exportLinkElement.remove();
          Updateloading(t, GetloadingID, t("download successfully"));
        }, 50)
        setSocialurl(res?.link);
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    }

    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, t('error'));
    }
  }
  const getsharestockidmovement = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        product_ids: selecteditem ? selecteditem : [],
      }
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/stockIDCard`)
      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const selectedproduct = (event) => {
    if (event.target.checked) {
      const selected = document.querySelectorAll(".inventorystockidmovementtablecheck");
      const mainselected = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute("data-id");
          mainselected.push(product_id);
        }
      }
      setSelecteditem(mainselected);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  };
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".inventorystockidmovementtablecheck");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute("data-id");
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h2 className="main-body-heading">
                <span>
                  {t('Product Analysis')} {'>'}
                </span>
                {t('Stock ID Analysis')}
              </h2>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/free-stock.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Stock')}</span>
                      <h2 className="inventory-value stock">
                        {stockidmovementcounts?.stocks}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/sku.png'}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('SKU')}</span>
                      <h2 className="inventory-value sku">
                        {stockidmovementcounts?.SKU}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/location.png'}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Total Value')}</span>
                      <h2 className="inventory-value location">
                        {stockidmovementcounts?.amount ? stockidmovementcounts?.amount : 0}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={
                          base_assets + 'images/icons/loacation_on_green.png'
                        }
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Locations')}</span>
                      <h2 className="inventory-value location">
                        {stockidmovementcounts?.location}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getstockidmovement(e.value) }}

                />
                <div className="main-body-top-status-bar-filter">
                  <button
                    className="main-body-top-status-bar-filter-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#filterinventory"
                  >
                    <img
                      className="d-block"
                      src={base_assets + '/images/icons/search-outline.png'}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('PO No./Supplier/Ship to ...')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className={parmissiondatashow?.stock_analysis?.Print ? "dropdown-item print-dropdown-item" : "d-none"}
                        onClick={(e) => {
                          getprintstockid()
                        }}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#socialModal"
                        onClick={() => getsharestockidmovement()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/share-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('share')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  id="stockid_movement"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >

                  <Tablehead
                    tablehead={inventorystockidmovementlisthead}
                    tblName={'tbl_stockid_movement'}
                    setfilterby={""}
                    setShowoption={setSelectAllStatus}
                  />
                  <tbody>
                    <>
                      {stockidmovementlist
                        ? stockidmovementlist.map((result, key) => {
                          return (
                            <tr key={key} className="">
                              <td className="fixed-side">
                                <div
                                  className='com-check'
                                >
                                  <input
                                    id={result?.id}
                                    data-id={result?.id}
                                    data-locationid={result?.location_id}
                                    className="inventorystockidmovementtablecheck stockidmovementHead"
                                    type="checkbox"
                                    onChange={selectedproduct}
                                  />
                                  <label
                                    htmlFor={result?.id}
                                    className="com-check-label"
                                  />
                                </div>
                              </td>
                              <td>{key + 1}</td>
                              <td
                                className="table-pro-img"
                                onClick={() => setImageid(result?.product_id)}
                                data-bs-toggle="modal"
                                data-bs-target="#ProductViewModal"
                              >
                                <img
                                  src={
                                    result?.main_image
                                      ? result?.main_image
                                      : base_assets +
                                      'images/icon/camera_profile.png'
                                  }
                                  alt=""
                                />
                              </td>

                              <td className='fixed-side cursor-pointer'>
                                <Link to="/app/inventory/StockidMovementdetails"
                                  className='text-decoration-none link-color'
                                  state={{
                                    SKU: result?.SKU,
                                    stock_id: result?.stock_id
                                  }}> {result?.stock_id}</Link>
                              </td>
                              <td
                                className="fixed-side link-color">

                                {result?.SKU ? result?.SKU : ''}
                              </td>

                              <td className="fixed-side">
                                {result?.metal_name ? result?.metal_name : ''}
                              </td>
                              <td className="fixed-side">
                                {result?.stone_name ? result?.stone_name : '-'}
                              </td>
                              <td className="fixed-side">
                                {result?.size_name ? result?.size_name : ''}
                              </td>
                              <td className="fixed-side">
                                {result?.item_name ? result?.item_name : ''}
                              </td>
                              <td className="fixed-side">
                                {result?.collection_name
                                  ? result?.collection_name
                                  : ''}
                              </td>
                              <td className="location-td">
                                <span className="link-color">
                                  {result?.location_name
                                    ? result?.location_name
                                    : ''}
                                </span>
                                <span className="text-center location-td-sub">
                                  {result?.warehouse_location_name
                                    ? result?.warehouse_location_name
                                    : ''}
                                </span>
                              </td>
                              <td className="fixed-side">
                                {result?.aging ? result?.aging : '0'}
                              </td>
                              <td className="fixed-side">
                                {result?.inward_Qty ? result?.inward_Qty : '-'}
                              </td>
                              <td className="fixed-side">
                                {result?.outward_Qty
                                  ? result?.outward_Qty
                                  : '-'}
                              </td>
                              <td className="fixed-side">
                                {result?.closing_Qty
                                  ? result?.closing_Qty
                                  : '-'}
                              </td>
                              <td className="fixed-side">
                                {result?.closing_amount
                                  ? result?.closing_amount
                                  : '-'}
                              </td>
                            </tr>
                          )
                        })
                        : ''}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {datalodaer && <TableLoader />}
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <ProductImageSliderModal main_id={imageid}></ProductImageSliderModal>
        <ProductModal
          data_product={getoneproduct}
          setOneproduct={setOneproduct}
        />
        <Filtermodal submitfiltter={getstockidmovement} limit={limit} custom_filter_enable={false} />
      </div>
      <TableColumnModal
        tablehead={inventorystockidmovementlisthead}
        tblName={'tbl_stockid_movement'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={getstockidmovement}
      ></TableColumnModal>
    </React.Fragment >
  )
}
export default StockidMovement;
