import { useTranslation } from 'react-i18next';
import CloseIcon from '../../common/icons/CloseIcon';
import FilterIconMain from '../../common/icons/sidebar/FilterIconMain';
import CircledTick from '../../common/icons/sidebar/CircledTick';
import { useCallback, useEffect, useRef, useState } from 'react';
import UserIconCo from '../../common/icons/UserIconCo';
import UserIconRepair from '../../common/icons/UserIconRepair';
import ConfirmationPopup from './ConfirmationPopup';
import { _Api, graphqlPost } from '../../api/_call';
import UserIcon from '../../common/icons/UserIcon';
import KanbanModal from './KanbanModal';
import { Link } from 'react-router-dom';
import BacklogFilter from './BacklogFilter';


export default function Po({ setApplyfilter, applyfilter }) {


    const { t } = useTranslation();
    const modalRef = useRef(null);
    const [draggedItem, setDraggedItem] = useState();
    const [activeLocation, setActiveLocation] = useState();
    const [targetCard, setTargetCard] = useState();
    const [createPOList, setCreatePOList] = useState([]);
    const [POlist, setPOlist] = useState([]);
    const [GetOrderDetails, setGetOrderDetails] = useState({ order_id: "", order_number: "", order_type: "", order_status: "" });
    const [confirmationResponse, setConfirmationResponse] = useState('');
    const [showbacklogFilters, setShowbacklogFilters] = useState(false);
    const backlogFilterRef = useRef(null);
    const [location, setLocation] = useState();
    const [activeScroll, setActiveScroll] = useState();

    const [cardBordListUpdate, setCardBordListUpdate] = useState(false);

    useEffect(() => {
        getLocationList();
        // Function to close popup when clicked outside
        const handleClickOutside = (event) => {
            if (backlogFilterRef.current && !backlogFilterRef.current.contains(event.target)) {
                setShowbacklogFilters(false);
            }
        };

        // Attach event listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getLocationList = useCallback(async () => {
        try {
            const postData = `{
            locations { 
              name    
            }
          }`;
            const response = await graphqlPost(postData);
            let locationArray = [];
            if (response.data?.locations) {
                const filteredLocations = response.data?.locations;
                filteredLocations.map((item) => { locationArray.push(item?.name) })
                setLocation(prev => prev = locationArray)
                // setBacklogFilters((prev)=>({...prev,location : locationArray})); 
            }
        } catch (err) {
            console.error(err, "err");
        }

    }, []);

    const [backlogFilters, setBacklogFilters] = useState({ "create_at": [], "location": [], "order_type": [] })

    const handleDragStart = (event, data, index) => {
        let dummydata = index === 1 ? 'pending' : index === 2 ? 'approved' : index === 3 ? 'cancel' : index === 4 ? 'completed' : 'error'
        setDraggedItem(prev => prev = [dummydata, data]);
    };

    const handleDrop = (event, targetindex) => {
        event.preventDefault();
        let dummydata = targetindex === 1 ? 'pending' : targetindex === 2 ? 'approved' : targetindex === 3 ? 'cancel' : targetindex === 4 ? 'completed' : 'error'
        setTargetCard(prev => prev = dummydata);
        if ((draggedItem[0] === 'pending' || draggedItem[0] === 'approved') && dummydata === 'pending') {
            const modalElement = modalRef.current;
            const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
            bootstrapModal.show();
        } else {
            setDraggedItem([])
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    const handleLocationItems = (backLogDataResult) => {
        const cartIds = backLogDataResult?.cart_id || [];
        const isChecked = createPOList.some(id => cartIds.includes(id));

        if (activeLocation === undefined) {
            setActiveLocation(backLogDataResult?.location_id);
            setCreatePOList(prev => [...prev, ...cartIds]);
        } else {
            if (isChecked) {
                // Remove the cart IDs from createPOList
                setCreatePOList(prev => prev.filter(id => !cartIds.includes(id)));
            } else {
                // Add the cart IDs to createPOList
                setCreatePOList(prev => [...prev, ...cartIds]);
            }
        }
    };

    const getKanbanPOlistApi = useCallback(async () => {
        try {
            let postdata = {
                "location": applyfilter?.location?.length ? applyfilter?.location : [],
                "search": applyfilter?.search ? applyfilter?.search : "",
                "duesFilters": applyfilter?.duesFilters ? applyfilter?.duesFilters : [],
                "backlog": { "create_at": backlogFilters?.create_at ? backlogFilters?.create_at : [], "location": backlogFilters?.location ? backlogFilters?.location : [], "order_type": backlogFilters?.order_type ? backlogFilters?.order_type : [] }
            }
            let res = await _Api(postdata, `api/v1/App/kanban/getkanbanPOlist`);
            if (res?.code === 200) {
                setPOlist(res?.data);
                setActiveLocation();
                setCreatePOList([]);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }, [applyfilter, backlogFilters]);

    useEffect(() => {
        if (Array.isArray(applyfilter?.location)) {
            getKanbanPOlistApi();
        }
        // eslint-disable-next-line
    }, [backlogFilters, applyfilter])
    useEffect(() => {
        if (cardBordListUpdate) {
            getKanbanPOlistApi();
        }
        // eslint-disable-next-line
    }, [cardBordListUpdate]);

    useEffect(() => {
        handledraganddrop();
        // eslint-disable-next-line
    }, [confirmationResponse])

    const handleLocationCheckbox = (location) => {
        if (activeLocation === location?.location_id) {
            setActiveLocation();
        } else {
            setActiveLocation(location?.location_id)
        }
        setCreatePOList([])
    }

    const handledraganddrop = async () => {
        const modalElement = modalRef.current;
        const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
        bootstrapModal.hide();
        if (confirmationResponse === 'confirm') {
            console.log(draggedItem, targetCard, "target")
            // dummy[draggedItem[0]] = POlist[draggedItem[0]].filter((item)=>item._id != draggedItem[1]._id);
            // dummy[targetCard] = [...dummy[targetCard], draggedItem[1]];
            try {
                let postdata = {
                    'po_order_id': draggedItem[1]._id,
                    'status': draggedItem[0] === 'pending' && targetCard === 'approved' ? 3 : draggedItem[0] === 'pending' && targetCard === 'cancel' ? 0 : draggedItem[0] === 'approved' && targetCard === 'pending' ? 2 : null
                }
                let res = await _Api(postdata, `api/v1/Inventory/po/editStatusPO`);
                if (res?.code === 200) {
                    getKanbanPOlistApi();

                }
            }
            catch (err) {
                console.log(err, "err")
            }
            setConfirmationResponse('')
            setDraggedItem([]);
        } else {
            setConfirmationResponse('')
            setDraggedItem([]);
        }
    }



    const handleShowFilter = () => {
        setShowbacklogFilters(!showbacklogFilters)
    }

    //   useEffect(() => {
    //     console.log(backlogFilters, "backlog filters")
    //     let filterBacklog = POlist.backlogs
    //     console.log(filterBacklog, "chekcfilters", backlogFilters)
    //   }, [backlogFilters])

    const getShortForm = (location) => {
        const words = location.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase());
        return initials.join('');
    }

    const handleScroll = (e, type) => {
        console.log(e, "handleScroll");
        setActiveScroll(type);
    }

    return (
        <div className='kanbanpage-content-po'>
            <div className='kanbanpage-content-po-cardcontainer'>
                {/* backlog card started */}
                {applyfilter?.Status?.includes('Backlog') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${showbacklogFilters ? 'withfilter' : ""}${activeScroll === 'backlog' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'backlog')} onMouseLeave={() => setActiveScroll('')} ref={backlogFilterRef}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 5H21M11 9H16" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 5H4C3.44772 5 3 5.44772 3 6V8C3 8.55228 3.44772 9 4 9H6C6.55228 9 7 8.55228 7 8V6C7 5.44772 6.55228 5 6 5Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11 15H21M11 19H16" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 15H4C3.44772 15 3 15.4477 3 16V18C3 18.5523 3.44772 19 4 19H6C6.55228 19 7 18.5523 7 18V16C7 15.4477 6.55228 15 6 15Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text'>{t("Backlog")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {POlist?.backlogs?.backlogsdata?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-subhead'>

                            <Link className='plusicon'
                                to={"/app/inventory/createpo"}
                                state={{
                                    createlist: createPOList,
                                    hidelocationid: activeLocation,
                                }}><span>{t('Create')}</span><CloseIcon /></Link>
                            <span className='filtericon' onClick={() => setShowbacklogFilters(!showbacklogFilters)}><span>{t('Filter')}</span><FilterIconMain width={24} height={24} /></span>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists'>
                            <div className='kanbanpage-content-po-cardcontainer-card-lists-locationlist'>
                                {(backlogFilters?.order_type.includes('Replenishment Order') || backlogFilters?.order_type?.length === 0) &&
                                    POlist?.backlogs?.locationdata?.map((location, index) => {
                                        return (
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-locationlist-item' key={index}>
                                                <div className="custom-checkbox">
                                                    <input type="checkbox"
                                                        id={location?.location_id}
                                                        // data-id={result?.id}
                                                        // data-locationid={result?.location_id}
                                                        // warehouse_id={result?.warehouse_id}
                                                        value={location?.location_id}
                                                        checked={location?.location_id === activeLocation ? true : false}
                                                        // data-status={result?.status_type}
                                                        onClick={() => handleLocationCheckbox(location, index)} />
                                                    <label htmlFor='myCheckbox' />
                                                </div>
                                                <span className='tag'>{location?.location_name}</span>
                                                <span className='icon position-relative'>
                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.99609 8.24997H16.0034V17C16.0034 17.8284 15.3318 18.5 14.5034 18.5H5.49609C4.66767 18.5 3.99609 17.8284 3.99609 17V8.24997Z" stroke="#A4A4A4" strokeLinejoin="round" />
                                                        <path d="M1 5.23478L3.73837 8.23478" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M19 5.23478L16.2616 8.23478" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.0273 13.3751H13.7657" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.0273 15.625H13.7657" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8.97375 3.1875L7.26227 4.75001M7.26227 4.75001L5.55078 3.1875M7.26227 4.75001L7.26227 1" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.1339 3.1875L13.4224 4.75001M13.4224 4.75001L11.7109 3.1875M13.4224 4.75001L13.4224 1" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className='kanbanpage-tooltip'>{t("Replenishment Order")}</span>
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {(backlogFilters?.order_type.includes('POS Order') || backlogFilters?.order_type?.length === 0) &&
                                POlist?.backlogs?.backlogsdata?.map((result, index) => {
                                    return (
                                        <div className={`kanbanpage-content-po-cardcontainer-card-lists-content backlog ${activeLocation === undefined || activeLocation === result?.location_id ? '' : 'row-inactive'} `}
                                            key={index}
                                            data-bs-toggle="modal"
                                            data-bs-target="#showtaskmodel"
                                            data-id={result?._id}
                                            onClick={() => setGetOrderDetails({ order_id: result?._id, order_number: result?.po_no, order_type: "CO", order_status: 0 })}
                                        >
                                            <div className="custom-checkbox">
                                                <input type='checkbox'
                                                    id={result?._id}
                                                    data-id={result?._id}
                                                    value={result?._id}
                                                    onClick={() => handleLocationItems(result)}
                                                    // checked={activeLocation === result?.location_id && createPOList.includes(result?._id) ? true : false}
                                                    checked={activeLocation === result?.location_id && createPOList.some(id => result.cart_id.includes(id))}

                                                />
                                                <label htmlFor='myCheckbox' />
                                            </div>

                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                    <div className='title'>
                                                        <div className='titletext'>{result?.po_no}</div>
                                                        {result?.PosQty > 0 ? (
                                                            result?.order_type === 1 ? (
                                                                <div className='iconholder kanbanpage-tooltipholder'>
                                                                    <UserIconRepair />
                                                                    <span className='kanbanpage-tooltip'>{result?.customer_name} <br /> {getShortForm(result?.location_name)}-{result?.po_no}</span>
                                                                </div>
                                                            ) : (
                                                                <div className='iconholder kanbanpage-tooltipholder position-relative'>
                                                                    <UserIconCo />
                                                                    <span className='kanbanpage-tooltip'>{result?.customer_name} <br /> {getShortForm(result?.location_name)}-{result?.po_no}</span>
                                                                </div>
                                                            )
                                                        ) : ""}
                                                    </div>
                                                    <div className='date'
                                                        style={{
                                                            color:
                                                                result.Date.includes("Due in") ? "#FABB2D" :
                                                                    result.Date.includes("Overdue") ? "#FF5757" : ""
                                                        }}
                                                    >{result.Date}</div>
                                                </div>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                    {result.location_name}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {showbacklogFilters ? <BacklogFilter handleShowFilter={handleShowFilter} backlogFilters={backlogFilters} setBacklogFilters={setBacklogFilters} type="po" location={location} /> : ""}
                    </div>
                    : ""}
                {/* backlog card ended */}
                {/* pending card started */}
                {applyfilter?.Status?.includes('Pending') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${activeScroll === 'pending' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'pending')} onMouseLeave={() => setActiveScroll('')}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top pending'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 6V10L13 13M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text'>{t("Pending")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {POlist.pending?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 1)}
                            onDragOver={handleDragOver}>
                            {
                                draggedItem && draggedItem?.[0] === 'approved' ?
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div>Drag and Drop here</div>
                                        </div>
                                    </div>
                                    : ""
                            }

                            {POlist?.pending?.map((pending, index) => {
                                return (
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab'
                                        key={index}
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, pending, 1)}
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#confirmationpopup"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showtaskmodel"
                                        data-id={pending?._id}
                                        onClick={() => setGetOrderDetails({ order_id: pending?._id, order_number: pending?.po_no, order_type: "PO", order_status: 2 })}
                                    >
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                <div className='title'><span>{pending?.po_no}</span>
                                                    {pending?.PosQty > 0 ? (
                                                        <span>
                                                            <UserIcon /> {pending?.PosQty}
                                                        </span>
                                                    ) : ""}
                                                </div>
                                                <div className='date'
                                                    style={{
                                                        color:
                                                            pending.Date.includes("Due in") ? "#FABB2D" :
                                                                pending.Date.includes("Overdue") ? "#FF5757" : ""
                                                    }}
                                                >{pending?.Date}</div>
                                            </div>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                {pending?.location_name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : ""}
                {/* pending card ended */}
                {/* approved card started */}
                {applyfilter?.Status?.includes('Approved') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${activeScroll === 'approved' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'approved')} onMouseLeave={() => setActiveScroll('')}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top approved'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <CircledTick />
                                </span>
                                <span className='text'>{t("Approved")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {POlist.approved?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 2)}
                            onDragOver={handleDragOver}>
                            {draggedItem?.[0] === 'pending' ?
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div>Drag and Drop here</div>
                                    </div>
                                </div>
                                : ""
                            }
                            {
                                POlist?.approved?.map((approve, index) => {
                                    return (
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab' key={index}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, approve, 2)}
                                            data-id={approve?._id}
                                            data-bs-toggle="modal"
                                            data-bs-target="#showtaskmodel"
                                            onClick={() => setGetOrderDetails({ order_id: approve?._id, order_number: approve?.po_no, order_type: "PO", order_status: 3 })}

                                        >
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                    <div className='title'><span>{approve?.po_no}</span>
                                                        {approve?.PosQty > 0 ? (
                                                            <span>
                                                                <UserIcon /> {approve?.PosQty}
                                                            </span>
                                                        ) : ""}
                                                    </div>
                                                    <div className='date'
                                                        style={{
                                                            color:
                                                                approve?.Date.includes("Due in") ? "#FABB2D" :
                                                                    approve?.Date.includes("Overdue") ? "#FF5757" : ""
                                                        }}
                                                    >{approve?.Date}</div>
                                                </div>
                                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                    {approve?.location_name}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : ""}
                {/* approved card ended */}
                {/* cancel card started */}
                {applyfilter?.Status?.includes('Cancel') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${activeScroll === 'backlog' ? 'cancel' : ''}`} onScroll={(e) => handleScroll(e, 'cancel')} onMouseLeave={() => setActiveScroll('')}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top cancel'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 12L10 10M10 10L12 8M10 10L8 8M10 10L12 12M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text'>{t("Cancel")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {POlist?.cancel?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 3)}
                            onDragOver={handleDragOver}>
                            {
                                draggedItem?.[0] === 'pending' ?
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div>Drag and Drop here</div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {POlist?.cancel?.map((canceldata, index) => {
                                return (
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab' key={index}
                                        data-id={canceldata?._id}
                                        data-bs-toggle="modal"
                                        data-bs-target="#showtaskmodel"
                                        onClick={() => setGetOrderDetails({ order_id: canceldata?._id, order_number: canceldata?.po_no, order_type: "PO", order_status: 0 })}

                                    >
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                <div className='title'><span>{canceldata?.po_no}</span></div>
                                                <div className='date'>{canceldata?.Date}</div>
                                            </div>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                {canceldata?.location_name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : ""}
                {/* cancel card ended */}
                {/* complete card started */}
                {applyfilter?.Status?.includes('Completed') || applyfilter?.Status?.length <= 0 ?
                    <div className={`kanbanpage-content-po-cardcontainer-card ${activeScroll === 'complete' ? 'showscroll' : ''}`} onScroll={(e) => handleScroll(e, 'complete')} onMouseLeave={() => setActiveScroll('')}>
                        <div className='kanbanpage-content-po-cardcontainer-card-top completed'>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                                <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 1V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H15" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H10L15 6V11M15 11V19Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5 11.8125L6.6875 13.5L11.1875 9" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text'>{t("Completed")}</span>
                            </div>
                            <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                                {POlist?.complete?.length || 0}
                            </div>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-lists'>
                            {POlist?.complete?.map((complete, index) => {
                                return (
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content' key={index}
                                        data-id={complete?._id}
                                        data-bs-toggle="modal"
                                        data-bs-target="#showtaskmodel"
                                        onClick={() => setGetOrderDetails({ order_id: complete?._id, order_number: complete?.po_no, order_type: "PO", order_status: 1 })}
                                    >
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                <div className='title'><span>{complete?.po_no}</span></div>
                                                <div className='date'>{complete?.Date}</div>
                                            </div>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                {complete?.location_name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : ""}
                {/* complete card ended */}
            </div>
            <ConfirmationPopup setConfirmationResponse={setConfirmationResponse} modalRef={modalRef} />
            <KanbanModal GetOrderDetails={GetOrderDetails}
                Activity={
                    GetOrderDetails?.order_type === "CO"
                        ? t('Sales Order')
                        : t('Purchase Order')
                }
                setCardBordListUpdate={setCardBordListUpdate}
            />
        </div>
    )
}
