import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DateTime from "../../common/DateTime";
import { LocationList, SupplierList, VoucherList } from "../../common/graphql/commonlayouts";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { _Api } from "../../api/_call";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TableColumnModal from "../../common/modules/tableColumn";
import { Tablehead } from "../../common/modules/Tablehead";
import NotesModalLabel from "../../common/modules/NotesModalLabel";
import { tbl_allocation_table } from "../../common/Tablehead";
import TransAndDeliverDate from "../../common/modules/TransAndDeliverDate";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import ProductModal from "../../common/modules/ProductModal";
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import Select from "react-select";
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next';
const EditpoReplenish = (props) => {
  const { t } = useTranslation();
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  let navigate = useNavigate();
  const { state } = useLocation();
  const datePickerRef = useRef(null);
  const { locationid, po_order_id, supplierid, po_status, deliverydate, transitiondate, voucher_id, editparmission, statusparmission } = state;
  const [orderType, set_orderType] = useState(null);
  const [masterSearchData, setMasterSearchData] = useState([]);
  const [limit, setLimit] = useState(100);
  const [TransactionDate, setTransactionDate] = useState(new Date(transitiondate));
  const [DeliveryDate, setDeliveryDate] = useState(new Date(deliverydate));
  const [Replenish_OrdersList, setReplenish_OrdersList] = useState([]);
  const [commanSelected_loc, setcommanSelected_loc] = useState(locationid);
  const [commanSelected_sup, setcommanSelected_sup] = useState(supplierid);
  const [setcommanSelected_vouc] = useState();
  const [searchKey, setSearchKey] = useState(null);
  const [startdate, setStartdate] = useState();
  const [enddate, setEnddate] = useState();
  const [datelable, setDatelable] = useState('Replenish');
  const [getoneproduct, setOneproduct] = useState();
  const [oneproductindex, setoneproductindex] = useState();
  const [newcolumndata, setnewcolumndata] = useState(tbl_allocation_table);
  const [cuppyqty, setCuppyqty] = useState()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

  useEffect(() => {
    SetdataTable("replenish_order_table", 6, newcolumndata);
  }, [newcolumndata]);
  useEffect(() => {
    callcolumns();
  }, []);

  const getReplenishList = async (firstlimit) => {
    UnSetdataTable("replenish_order_table");
    try {
      if (!commanSelected_loc) {
        error(t("Please select location"));
        return;
      }
      if (!commanSelected_sup) {
        error(t("Please select supplier"));
        return;
      }
      let GetloadingID = loading();
      const postdata = {
        location: commanSelected_loc,
        supplier: commanSelected_sup,
        "start_date": startdate ? startdate : new Date(),
        "end_date": enddate ? enddate : new Date(),
        skip: 0,
        "limit": firstlimit ? firstlimit : limit
      };
      let resReplenishList = await _Api(
        postdata,
        `api/v1/Inventory/po/getReplenishList`
      );
      if (resReplenishList.code === 200) {
        Updateloading(t, GetloadingID, "Data show successfully!");
        SetdataTable("replenish_order_table", 6, newcolumndata);
        setReplenish_OrdersList(resReplenishList.data);
      } else {
        setReplenish_OrdersList([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_allocation_table",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const addLowStockEdit = () => {
    let allcheck = document.querySelectorAll(".allocation_OrdersList");
    let product_details = [];
    if (allcheck?.length) {
      allcheck.forEach((checkbox) => {
        if (checkbox.checked) {
          Replenish_OrdersList.forEach((result) => {
            if (result.product_id === checkbox.id) {
              product_details.push(result);
            }
          });
        }
      });
    }
    if (product_details.length) {
      localStorage.setItem('yourAppStateKeyEditPurchaseOrder', '');
      navigate("/app/inventory/edit-po-pos", {
        state: {
          posorder: product_details,
          posid: po_order_id,
          po_status: po_status,
          transitiondate: transitiondate,
          deliverydate: deliverydate,
          voucher_id: voucher_id,
          editparmission: editparmission,
          statusparmission: statusparmission
        }
      });
    } else {
      error(t("No item Selected!!"))
    }
  };
  const productSearch = async () => {
    try {
      const postdata = {
        search: searchKey,
      };
      let resp = await _Api(
        postdata,
        `api/v1/Inventory/po/getMasterProductSearch`
      );
      if (resp.code === 200) {
        setMasterSearchData(resp.data);
      } else {
        setMasterSearchData([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  useEffect(() => {
    if (searchKey) {
      productSearch();
    }
    // eslint-disable-next-line
  }, [searchKey]);

  const filterbyname = () => {
    if (searchKey) {
      getReplenishList();
    } else {
      getReplenishList();
    }
  };
  const getMasterProductById = async (product_id) => {
    try {
      const postdata = {
        id: product_id,
      };
      let resp = await _Api(
        postdata,
        `api/v1/Inventory/po/getMasterProductById`
      );
      if (resp.code === 200) {
        UnSetdataTable('replenish_order_table');
        let Replenish_OrdersList_arr = [];
        if (orderType !== "Low Stock") { Replenish_OrdersList_arr = [...Replenish_OrdersList]; }
        Replenish_OrdersList_arr.push(resp.data);
        set_orderType("Master Product");
        setReplenish_OrdersList(Replenish_OrdersList_arr);
        setTimeout(() => {
          SetdataTable("replenish_order_table", 6, newcolumndata);
        }, 100);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getNewMasterProduct = (product_id) => {
    getMasterProductById(product_id);
    setSearchKey('');
    setMasterSearchData([]);
  };
  const changeDates = (lable) => {
    const MainDate = new Date();
    if (lable === 'Today') {
      setStartdate(new Date());
      setEnddate(new Date());
      setDatelable('Today');
    } else if (lable === 'Yesterday') {
      const yesterdaydate = new Date(MainDate.setDate(MainDate.getDate() - 1));
      setStartdate(yesterdaydate);
      setEnddate(yesterdaydate);
      setDatelable('Yesterday');
    } else if (lable === '7days') {
      const sevendays = new Date(MainDate.setDate(MainDate.getDate() - 7));
      setStartdate(sevendays);
      setEnddate(new Date());
      setDatelable('7days');
    } else if (lable === '30days') {
      const days = new Date(MainDate.setDate(MainDate.getDate() - 30));
      setStartdate(days);
      setEnddate(new Date());
      setDatelable('30days');
    } else if (lable === 'ThisMonth') {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 1);
      const lastDay = new Date(MainDate.getFullYear(), MainDate.getMonth() + 1, 0);
      setStartdate(firstDay);
      setEnddate(lastDay);
      setDatelable('ThisMonth');
    } else if (lable === "LastMonth") {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth() - 1, 1);
      const lastDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 0);
      setStartdate(firstDay);
      setEnddate(lastDay);
      setDatelable('LastMonth');
    }
  }
  const editproduct_submit = () => {
    var mainarray = [...Replenish_OrdersList];
    mainarray[oneproductindex] = getoneproduct;
    setReplenish_OrdersList(mainarray);
  }
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    tbl_allocation_table.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (Replenish_OrdersList.length) {
      Replenish_OrdersList.forEach((result, key) => {
        bodyarray.push(["", key + 1, result?.main_image, result?.name, result?.SKU, result?.metal_name, result?.stone_name, result?.size_name, result?.total_stock, result?.SalesOrder, result?.AvailableStock, result?.ReorderPoint_total, result?.ReorderQuantity_total, result?.order_qty
        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "create_replenish_order");

  }
  const changeorder_qty = (event, key) => {
    var mainarray = [...Replenish_OrdersList];

    if (event.target.value > 0) {

      mainarray[key] = {
        ...mainarray[key],
        'order_qty': event.target.value
      }
      setReplenish_OrdersList(mainarray);
    } else {

      error(t("Quantity should be greater than 0"))

    }

  }
  const cuppyProduct = () => {
    const mainarray = [...Replenish_OrdersList];
    for (var i = 0; i < mainarray.length; i++) {
      mainarray[i] = {
        ...mainarray[i],
        'order_qty': parseInt(cuppyqty)
      }
    }
    setReplenish_OrdersList(mainarray);
    UnSetdataTable('replenish_order_table');
    setTimeout(() => {
      SetdataTable("replenish_order_table", 6, newcolumndata);
    }, 100);

  }
  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link to="/app/inventory/edit-po-pos" state={{
                    posid: po_order_id, po_status: po_status, transitiondate: transitiondate, deliverydate: deliverydate, voucher_id: voucher_id, editparmission: editparmission,
                    statusparmission: statusparmission
                  }} className="page-back-btn">
                    <img
                      src={base_url_assets + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </Link>
                  <img
                    src={base_url_assets + "images/icons/home-icon.png"}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Purchase_order"}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center danger-text">
                  {t("edit Purchase Order")}
                </h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills">
                  <Link className="main-body-top-tab-bar-left-col-a nav-link active">
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        src={
                          base_url_assets +
                          "images/icons/price-list-icon-green.png"
                        }
                        alt=""
                      />
                      {t("Replenish Order")}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="main-body-top-inputs-bar">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">{state?.ponumber}</label>
                  <LocationList
                    setcommanSelected_loc={setcommanSelected_loc}
                    locationid={locationid}
                  />
                  <SupplierList
                    setcommanSelected_sup={setcommanSelected_sup}
                    supplierid={supplierid}
                  />
                  <TransAndDeliverDate TransactionDate={TransactionDate} DeliveryDate={DeliveryDate} setTransactionDate={setTransactionDate} setDeliveryDate={setDeliveryDate} />
                </div>
                <div className={parmissiondatashow?.purchase_order?.Add ? "main-body-top-inputs-bar-col-right cursor-pointer" : "d-none"}>
                  <div
                    onClick={() => addLowStockEdit()}
                    className="main-body-top-tab-bar-right-col-a"
                  >
                    <img
                      className="link-img"
                      src={
                        base_url_assets + "images/icons/plus-circle-white.png"
                      }
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text ">
                      {t("Add")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getReplenishList(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={searchKey ? searchKey : ""}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("SKU/Metal/Stone")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                    onChange={(e) => setSearchKey(e.target.value)}
                  />
                  {searchKey ? <img className="main-body-top-status-bar-filter-clear" src={base_url_assets + 'images/icons/False.png'} onClick={(e) => setSearchKey("")} alt="" /> : ""}
                  <button onClick={filterbyname} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                  {searchKey ?
                    <div className="item_list_card_all">
                      <div className="item_list_card">
                        <div className="custom_search_title text-green">{t("Custom Search")}</div>
                        <ul id="item_list_cardul_custom" className="item_list_cardul_custom">
                          <li>{searchKey}</li>
                        </ul>
                        <div className="custom_search_title text-green">{t("Product")}</div>
                        <ul id="item_list_cardul" className="item_list_cardul">
                          {masterSearchData?.map((result, index) => {
                            return (
                              <li onClick={() => getNewMasterProduct(result._id)} key={index}>
                                <span>{result.name}</span>
                                <br />
                                <small>{result.SKU}</small>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                    : " "}
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_url_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <div className="print-export-dropdown-selected-icon">
                    <button className={
                      cuppyqty
                        ? "deletebtn btn-style d-block"
                        : "d-none"
                    } onClick={cuppyProduct}  >
                      <img src={base_url_assets + "images/icons/clone.svg"} alt="" />
                    </button>
                  </div>
                  <div className="main-body-top-status-bar-video-btn">
                    <img
                      onClick={() => {
                        getReplenishList();
                        set_orderType("Low Stock");
                      }}
                      src={base_url_assets + "images/icons/play-icon.png"}
                      alt=""
                    />
                  </div>

                  <div className="main-body-top-status-bar-note-button common-dropdown replenish-date" >
                    <button className="dropdown-toggle btn-style align-items-center d-flex" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className="me-2" src={base_url_assets + "images/icons/clock-icon.png"} alt="" />
                      {datelable}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li className={`dropdown-item ${datelable === 'Today' || datelable === 'Replenish' ? "active" : ""}`} onClick={() => changeDates('Today')} >{t("Today")}</li>
                      <li className={`dropdown-item ${datelable === 'Yesterday' ? "active" : ""}`} onClick={() => changeDates('Yesterday')}>{t("Yesterday")}</li>
                      <li className={`dropdown-item ${datelable === '7days' ? "active" : ""}`} onClick={() => changeDates('7days')}>{t("Last 7 days")}</li>
                      <li className={`dropdown-item ${datelable === '30days' ? "active" : ""}`} onClick={() => changeDates('30days')}>{t("Last 30 days")}</li>
                      <li className={`dropdown-item ${datelable === 'ThisMonth' ? "active" : ""}`} onClick={() => changeDates('ThisMonth')}>{t("This Month")}</li>
                      <li className={`dropdown-item ${datelable === 'LastMonth' ? "active" : ""}`} onClick={() => changeDates('LastMonth')}>{t("Last Month")}</li>
                      <li className={`dropdown-item ${datelable === 'Custom Range' ? "active" : ""}`} onClick={() => datePickerRef.current.setOpen(true)}>{t("Custom Range")}</li>
                    </ul>
                    <DatePicker
                      selectsRange={true}
                      monthsShown={2}
                      startDate={startdate}
                      endDate={enddate}
                      onChange={(update) => {
                        setStartdate(update[0]);
                        setEnddate(update[1]);
                        setDatelable("Custom Range");
                      }}
                      dateFormat="yyyy-MM-dd"
                      ref={datePickerRef}
                      autoComplete='none'
                    />
                  </div>
                  <div
                    className="main-body-top-status-bar-note-button"
                    data-bs-toggle="modal"
                    data-bs-target="#notesModal"
                  >
                    <img
                      src={base_url_assets + "images/icons/comment-icon.png"}
                      alt=""
                    />
                    {t("Note")}
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          base_url_assets + "images/icons/ellipsis-circular.png"
                        }
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item"
                        onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + "images/icons/export-x-icon.png"
                            }
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + "images/icons/column-icon.png"
                            }
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap" style={{ maxWidth: '100%', overflowX: 'auto' }}>
                  <table
                    id="replenish_order_table"
                    className="common-table product-base-table w-100"
                  >
                    <Tablehead
                      tablehead={tbl_allocation_table}
                      tblName={"replenish_order_table"}
                      setfilterby={""}
                    />

                    <tbody>
                      {Replenish_OrdersList ?
                        Replenish_OrdersList?.map((result, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="com-check">
                                  <input
                                    data-key={index}
                                    data-id={result.product_id}
                                    className="allocation_OrdersList editreplenish_all"
                                    type="checkbox"
                                    id={result.product_id}
                                  />
                                  <label
                                    htmlFor={result.product_id}
                                    className="com-check-label"
                                  />
                                </div>
                              </td>
                              <td>{index + 1}</td>
                              <td>
                                <div className="table-pro-img">
                                  <img src={result.main_image} alt="" />
                                </div>
                              </td>
                              <td>{result.name}</td>
                              <td className="po-no link-color cursor-pointer" onClick={() => { setOneproduct(result); setoneproductindex(index) }} data-bs-toggle="modal" data-bs-target="#ProductInfoModal">
                                {result?.SKU ? result?.SKU : ""}
                              </td>
                              <td> {result.metal_name}</td>
                              <td>{result.stone_name}</td>
                              <td className="col-highlight">
                                {result.size_name}
                              </td>
                              <td className="col-highlight">
                                {result.total_stock}
                              </td>
                              <td className="col-highlight">
                                {result.SalesOrder}
                              </td>
                              <td className="col-highlight">
                                {result.AvailableStock}
                              </td>
                              <td className="col-highlight">
                                {result.ReorderQuantity_total}
                              </td>
                              <td className="col-highlight">
                                {result.ReorderQuantity_total}
                              </td>
                              <td className="col-highlight">
                                <div className="col-input">
                                  <input
                                    onChange={(e) => { changeorder_qty(e, index); setCuppyqty(e.target.value) }}
                                    className="col-width"
                                    type="text"
                                    defaultValue={result.order_qty}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        }) : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit={editproduct_submit} />
      </div>
      <TableColumnModal tablehead={tbl_allocation_table}
        tblName={"tbl_allocation_table"}
        calldatatable={getReplenishList}
        setnewcolumndata={setnewcolumndata}></TableColumnModal>
      <NotesModalLabel></NotesModalLabel>
    </>
  );
};

export default EditpoReplenish;
