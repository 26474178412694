import React from "react";
import { Link } from "react-router-dom";
import "./menu.scss";
import CheckoutBlankCopy from "../checkout/checkoutBlankcopy";
import { useTranslation } from 'react-i18next';
import useFavicon from './useFavicon';
const DuplicateTabMessage = () => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const { t } = useTranslation();
    useFavicon('/assets/v2/images/error-gis-icons.png');
    return (
        <>
            <div className="main-body">
                <div className="main-body-row">
                    <div className="main-body-row-left-col p-0">
                        <div className="menu-block">
                            <h1 className="main-heading">MENU</h1>
                            <div className="menu-block-row">
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/custom/custom"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/custom.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/custom-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">custom</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/repair"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/repair.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/repair-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">repair</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/exchange"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/exchange.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/exchange-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">exchange</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/refund"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/refund.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/refund-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">refund</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/customer/customer"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/user-big.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/user-big-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">customer</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/reserve"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/reserve.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/reserve-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">reserve</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/giftCard"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/gift-card.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/gift-card-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">
                                            gift-card
                                        </span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/receive"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/receive.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/receive-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">Receive</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/wishlist"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/heart-big.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/heart-big-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">wishlist</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/catalog/CollectionList"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/catalog-big.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/catalog-big-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">catalog</span>
                                    </Link>
                                </div>
                                <div className="menu-block-row-col hover-effect">
                                    <Link
                                        to="/app/pos/Quotation"
                                        className="menu-block-row-col-a hover-effect-inner"
                                    >
                                        <span className="menu-block-row-col-a-img position-relative">
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-g"
                                                src={base_assets + "images/pos/icons/quotation.png"}
                                                alt=""
                                            />
                                            <img
                                                className="hover-effect-inner-img hover-effect-inner-img-w"
                                                src={base_assets + "images/pos/icons/quotation-w.png"}
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-block-row-col-a-title">Quotation</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body-row-right-col">
                        <CheckoutBlankCopy />
                    </div>
                </div>
                {/* Model Start  */}
                <div className="duplicatemsg store-modal">
                    <div className="store-modal-body">

                        <div className="store-modal-body-ct">
                            <p>{t("POS is open in another window. Click `Use here` to use POS in this window.")}</p>

                        </div>
                        <div className="modal-footer footer gap-3">
                            <button type="button" className="cancel-btn">
                                {t("Cancel")}
                            </button>
                            <button type="button" className="com-btn" onClick={() => window.location.reload()}>
                                {t("Use here")}
                            </button>

                        </div>
                    </div>
                </div>
                {/* Model Code END   */}


            </div>
        </>
    );
};

export default DuplicateTabMessage;
