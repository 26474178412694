import React from "react";

const DeleteModal = (props) => {
  const { handler_delete, statement } = props; 
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL; 
  return (
    <div
      className="modal fade common-modal common-modal-danger"
      id="delete-listedModal"
      tabIndex={-1}
      aria-labelledby="delete-listedModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <img className="common-modal-exclamation-img"
              src={base_assets + "images/icons/exclamation-mark-red.png"}
              alt=""
            />
            <p className="modal-content-text">
                Are you sure you want to delete the <br /> {statement} ?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn modal-content-no"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              onClick={handler_delete}
              data-bs-dismiss="modal"
              className="btn modal-content-yes"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
