import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DateTime from '../common/DateTime';
import { _Api } from '../api/_call';
import { error, loading, Updateloading } from "../common/helpers/toastify";
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import { Tablehead } from "../common/modules/Tablehead";
import { createreserveinventoryhead } from '../common/Tablehead';
import { Saleperson, VoucherList } from "../common/graphql/commonlayouts";
import CustomerSearchModal from "../common/modules/customerSearch";
import CustomerSearchDetailModal from "../common/modules/CustomerSearchDetailModal";
import TransAndDeliverDate from "../common/modules/TransAndDeliverDate";
import { reserveDuedate } from "../common/helpers/formatDate";
import ConfirmationModel from "../common/modules/confirmationmodel";
import ProductModal from "../common/modules/ProductModal";
import { toFormatPrice } from "../common/helpers/function";
import { useTranslation } from 'react-i18next';
import CustomerInformation from "../../admin/customers/customerInformation/CustomerInformation";
import TableLoader from "../../admin/common/TableLoader";
import { useSelector } from "react-redux";
const CreateReserve = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [getoneproduct, setOneproduct] = useState();
  const [deliveryDate, set_deliveryDate] = useState();
  const [transactionDate, set_transactionDate] = useState(new Date());
  const [commanSelected_sal, setcommanSelected_sal] = useState();
  const [commanSelected_vouc, setcommanSelected_vouc] = useState();
  const [cusid, setCusid] = useState();
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [selecteditem, setSelecteditem] = useState([]);
  const [ShowSubmitbutton, setShowSubmitbutton] = useState(true);
  const [customerid, setCustomerid] = useState();
  const [reservelist, setReservelist] = useState([]);
  const [newcustomersid, setnewcustomersid] = useState();
  const [, setfilterby] = useState("");
  const [oldreservelist, setOldreservelist] = useState();
  const [, setCreatecustomer] = useState(false)
  const [datalodaer, setDatsLodaer] = useState(false)
  const navigate = useNavigate();
  const { state } = useLocation();

  const getdeliverydate = async () => {
    set_deliveryDate(await reserveDuedate(new Date()));
  }
  useEffect(() => {
    getdeliverydate();
  }, []);
  const GetReservelist = async () => {
    setDatsLodaer(true)
    try {
      const postdata = {
        "ids": state?.datalist ? state?.datalist : []
      };
      let res = await _Api(postdata, `api/v1/Inventory/my/getCreateReservelist`);
      if (res?.code === 200) {
        setDatsLodaer(false)
        SetdataTable('create_reserve_table', '',);
        setReservelist(res?.data);
        setOldreservelist(res?.data)
      } else {
        setDatsLodaer(false)
        SetdataTable('create_reserve_table', '',);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const submit = async () => {
    const handleValidation = (e) => {
      let formIsValid = true;
      if (!deliveryDate) {
        formIsValid = false;
        error(t("DeliveryDate is Requried!"));
        return;
      }
      if (!transactionDate) {
        formIsValid = false;
        error(t("TransactionDate is Requried!"));
        return;
      }
      if (!commanSelected_sal) {
        formIsValid = false;
        error(t("SalePerson is Requried!"));
        return;
      }
      if (!commanSelected_vouc) {
        formIsValid = false;
        error(t("Choose Voucher!"));
        return;
      }
      if (!customerid) {
        formIsValid = false;
        error(t("Customer is required."));
        return;
      }
      var allcheck = document.querySelectorAll(".createreserveinventory");
      for (var j = 0; j < allcheck.length; j++) {
        let qty = allcheck[j].getAttribute('data-qty');
        if (parseInt(qty) === 0) {
          let qtycheck = document.querySelectorAll(".reserveqty");
          qtycheck[j].style.border = "1px solid red ";
          formIsValid = false;
        } else {
          let qtycheck = document.querySelectorAll(".reserveqty");
          qtycheck[j].style.border = "1px solid #f4f4f4 ";
        }
      }
      return formIsValid;
    };
    if (handleValidation()) {
      let details = [];
      let reservedata = [];
      var allcheck = document.querySelectorAll(".createreserveinventory");
      for (let i = 0; i < allcheck.length; i++) {
        if (allcheck[i].checked) {
          let product_id = allcheck[i].getAttribute('data-id');
          setReservelist((current) =>
            current.filter((item) => item?.id !== product_id)
          );
          reservelist.forEach((result) => {
            if (result.id === allcheck[i]?.id) {
              details.push(result);
            }
          });
        }
      }
      for (var keys = 0; keys < details.length; keys++) {
        reservedata.push({
          "stock_id": details[keys]?.stock_id,
          "reserve_qty": parseInt(details[keys]?.po_QTY),
          "po_product_id": details[keys]?.id,
          "remark": details[keys]?.remark

        });
      }
      if (!reservedata?.length) {
        error(t("No item Selected!!"));
        return;
      }
      setShowSubmitbutton(false);
      let GetloadingID = loading();
      try {
        const postdata = {
          "customer_id": customerid,
          "ref_no": "",
          delivery_date: deliveryDate,
          transaction_date: transactionDate,
          "sales_person_id": commanSelected_sal,
          "voucher_id": commanSelected_vouc,
          "reserve": reservedata,
        };
        let res = await _Api(postdata, `api/v1/Inventory/my/createReserve`);
        if (res?.code === 200) {
          setShowSubmitbutton(true);
          Updateloading(t, GetloadingID, t("Successfully list Reserved!!"));
          setTimeout(() => {
            navigate("/app/inventory/myInventory");
          }, 500);
        } else {
          setShowSubmitbutton(true);
          Updateloading(t, GetloadingID, t("Something Went Wrong!!"), 'error');
        }
      }
      catch (err) {
        console.log(err, 'err');
      }
    }
  }
  const deleteProduct = () => {
    const selected = document.querySelectorAll(".createreserveinventory");
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let product_id = selected[i].getAttribute('data-id');
        setReservelist((current) =>
          current.filter((item) => item?.id !== product_id)
        );
        selected[i].checked = false;
      }
    }
    setSelecteditem([]);
    UnSetdataTable('create_reserve_table');
    setTimeout(() => {
      SetdataTable('create_reserve_table', '',);
    }, 100);
  }
  const selectedproduct = (event) => {
    if (event.target.checked) {
      const mainselected = [...selecteditem, event.target.value];
      setSelecteditem(mainselected);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  }
  const changeqty = (event, i) => {
    var main = [...reservelist];
    let oldmain = [...oldreservelist];
    if (event.target.value <= oldmain[i].po_QTY) {
      const { value, name } = event.target;
      main[i] = {
        ...main[i],
        [name]: value,
      };
      setReservelist(main);
    } else {
      error(t("Enter Quantity less than") + oldmain[i].po_QTY);
    }
  };
  const changeremark = (event, i) => {
    var main = [...reservelist];
    const { value, name } = event.target;
    main[i] = {
      ...main[i],
      [name]: value,
    };
    setReservelist(main);
  }

  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".createreserveinventory");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id');
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);

  useEffect(() => {
    if (state?.datalist) {
      GetReservelist();
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => { }, [deliveryDate]);
  return (
    <>
      <div className="main-body createreserve">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <div onClick={() => navigate(-1)} className="page-back-btn">
                    <img
                      src={base_assets + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </div>
                  <img src={base_assets + "images/icons/home-icon.png"} alt="" />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Inventory_Reserve"}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">{t("Create Reserve")}</h1>
              </div>

              <div className="main-body-top-inputs-bar top-bar-input">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">{t("REF-XXX")}</label>
                  <CustomerSearchModal setSearchid={setCustomerid} newcustomer={newcustomersid} setCreatecustomer={setCreatecustomer}></CustomerSearchModal>
                  {parmissiondatashow?.pos_customer_reports?.Add ?
                    <button className="btn-user" data-bs-toggle="modal" data-bs-target="#customerAddModal"
                      onClick={() => (setCreatecustomer(true))}>
                      <img className="" src={base_assets + "images/pos/icons/add-user.png"} alt="" />
                    </button>
                    :
                    ""}
                  <Saleperson
                    setcommanSelected_sal={setcommanSelected_sal}
                  />
                  <TransAndDeliverDate TransactionDate={transactionDate} DeliveryDate={deliveryDate} setTransactionDate={set_transactionDate} setDeliveryDate={set_deliveryDate} />
                </div>
                <div className="main-body-top-inputs-bar-col-right">
                  <button className={
                    selecteditem.length > 0
                      ? "deletebtn btn-style d-block"
                      : "d-none"
                  } onClick={deleteProduct}>
                    <img src={base_assets + "images/icons/delete-icon.png"} alt="" />
                  </button>
                  <button
                    className={
                      ShowSubmitbutton
                        ? "main-body-top-inputs-bar-col-right-submit-btn com-btn "
                        : "main-body-top-inputs-bar-col-right-submit-btn com-btn d-block  deactive"
                    }
                    data-bs-toggle="modal"
                    data-bs-target="#createPurchaseModal"
                  >
                    {t("Submit")}
                  </button>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap">
                  <table id="create_reserve_table" className="common-table product-base-table w-100">
                    <Tablehead tablehead={createreserveinventoryhead} tblName={"tbl_inventory_createreserve"} setShowoption={setSelectAllStatus} setfilterby={setfilterby} />
                    <tbody>
                      <>
                        {reservelist?.length ? reservelist.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <div className="com-check">
                                  <input id={result?.id} data-id={result?.id} data-qty={result?.po_QTY} onChange={selectedproduct} type="checkbox" className="createreserveinventory" />
                                  <label htmlFor={result?.id} className="com-check-label" />
                                </div>
                              </td>
                              <td className="sku-col">{key + 1}</td>
                              <td>
                                <div className="tooltip-area-up">
                                  <span className="table-pro-tooltip">{result?.name ? result?.name : ''}</span>
                                  <span className="tooltip-area-up-text text-white">
                                    {result?.name ? result?.name : ''}
                                  </span>
                                </div>
                              </td>
                              <td className="link-color" onClick={() => { setOneproduct(result) }} data-bs-toggle="modal" data-bs-target="#ProductInfoModal"> {result?.SKU ? result?.SKU : ''}</td>
                              <td className="po-no">{result?.stock_id ? result?.stock_id : ''}</td>
                              <td>
                                {toFormatPrice(result?.retailprice_Inc, { addSymbol: true })}</td>
                              <td>{result?.location_name ? result?.location_name : ''}</td>
                              <td>
                                <div className="col-input input-field-bg">
                                  <input
                                    type="text"
                                    className="reserveqty"
                                    id="qtycheck"
                                    name="po_QTY"
                                    value={result?.po_QTY}
                                    onChange={(e) => changeqty(e, key)}
                                  />
                                </div>
                              </td>
                              <td><div className="col-input input-field-bg"><input type="text" value={result?.remark}
                                onChange={(e) => changeremark(e, key)} name='remark' /></div></td>
                            </tr>
                          );
                        }) : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3" className="main-green-text left-0 text-start">
                          {selecteditem.length > 0 ? (
                            <span> {selecteditem.length} {t("item Selected")}</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{reservelist?.reduce(
                          (interation, val) => (interation = interation + parseInt(val.po_QTY ? val.po_QTY : 0)),
                          0
                        )}</td>
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  {datalodaer && <TableLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit={''} />
        <CustomerInformation
          cusid={cusid}
          setCusid={setCusid}
        />
        <ConfirmationModel
          handler_submit={submit}
          statement={"Reserved this lists"}
          handler_dismiss={GetReservelist}
        ></ConfirmationModel>
        <CustomerSearchDetailModal setnewcustomersid={setnewcustomersid} newcustomersid={newcustomersid} setCusid={setCusid} />
      </div>
    </>
  );
}

export default CreateReserve;