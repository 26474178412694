import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateTime from '../common/DateTime'
import Select, { components } from "react-select";
import { _Api, graphqlPost } from "../api/_call";
import { error, loading, Updateloading } from "../common/helpers/toastify";
const AddUser = (props) => {
  const { t } = useTranslation()
  const { state } = useLocation();
  const navigate = useNavigate();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [addusergraphlistarray, setAddusergraphlistarray] = useState([])
  const [LocationarrayList, setLocationarrayList] = useState([]);
  const [countriesgraph, srtCountriesgraph] = useState([])
  const [phonecodegraph, setPhonecodegraph] = useState([])
  const [firstname, setFirstname] = useState("")
  const [lasttname, setLasttname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loginpin, setLoginpin] = useState()
  const [position, setPosition] = useState("")
  const [status, setStatus] = useState("1")
  const [rolesid, setRolesid] = useState("")
  const [locations, setLocations] = useState([])
  const [locationcheck,] = useState()
  const [authentication, setAuthentication] = useState()
  const [office_time, setOffice_time] = useState()
  const [country, setCountry] = useState("")
  const [userImage, setUserImage] = useState("")
  const [passEye, setPassEye] = useState(false);
  const [alllocation, setAlllocation] = useState(false)
  const [checked, setChecked] = useState([]);
  const [contacts, setContacts] = useState([])
  const [userType, setUserType] = useState("")
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const onImageupload = (e) => {
    let GetloadingID = loading();
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/App/User/uploadimage`);
        if (resfront.code === 200) {
          setUserImage(resfront?.data?.images?.url);
          Updateloading(t, GetloadingID, t("Successfully image uploaded"));
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  };
  const handleCheckAllChange = (e) => {
    setChecked(
      e.target.checked ? LocationarrayList.map((c) => c.id) : []
    );
  };
  const handleCountryChange = (e, c) => {
    setChecked((prevChecked) =>
      e.target.checked
        ? [...prevChecked, c.id]
        : prevChecked.filter((item) => item !== c.id)

    );
  };

  const adduser = async () => {
    const handleValidation = (e) => {
      let formIsValid = true;
      if (firstname === "") {
        error(t("Enter firstname"));
        formIsValid = false;
        return;
      }
      if (lasttname === "") {
        error(t("Enter lastname"));
        formIsValid = false;
        return;
      }
      if (!email) {
        error(t("Enter Email"));
        formIsValid = false;
        return;
      } else {
        if (state?.user_id) {

        } else {
          if (loginpin?.length < 6 || !loginpin) {
            error(t("loginpin is only 6 digits"))
            const pinauth = (auth) =>
              /^[0-9\b]+$/.test(auth);
            if (!pinauth(loginpin)) {
              error(t("Login pin must be in number"))
            }
          }
        }

        const isEmail = (email) =>
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        if (!isEmail(email)) {
          error(t("Invalid Email"));
          formIsValid = false;
          return;
        }
      }
      if (!state?.user_id && password === "") {
        formIsValid = false;
        error(t("Enter password"));
        return;
      }
      if (!state?.user_id && (loginpin?.length < 6 || !loginpin)) {
        error(t("Loginpin is only 6 digits"))
        const pinauth = (auth) =>
          /^[0-9\b]+$/.test(auth);
        if (!pinauth(loginpin)) {
          error(t("Login pin must be in nunber"))
        }
        return;
      }
      if (position === "") {
        error(t("Enter Position"));

        formIsValid = false;
        return;
      }
      if (!country) {
        error(t("Choose country"))
        formIsValid = false;
        return;

      }
      if (!contacts[0]?.no) {
        error(t("Enter Phone Number"));
        formIsValid = false;
        return;
      }
      if (status === "") {
        error(t("Choose status"));
        formIsValid = false;
        return;
      }
      if (rolesid === "") {
        error(t("Choose roles"));
        formIsValid = false;
        return;
      }

      if (state?.user_id) {
      } else {
        if (!checked?.length) {
          error(t("Choose location"));
          formIsValid = false;
          return;
        }
      }
      return formIsValid;


    };
    if (handleValidation()) {
      let GetloadingID = loading();
      try {
        const postdata = {
          "firstname": firstname,
          "lasttname": lasttname,
          "email": email,
          "password": password,
          "loginpin": loginpin,
          "position": position,
          "country": country?.id,
          "status": status,
          "authentication": authentication ? true : false,
          "Office_time": office_time ? true : false,
          "roles_id": rolesid,
          "location_id": checked?.length ? checked : locationcheck,
          "profile_image": userImage ? userImage : "https://gis247.s3.us-east-2.amazonaws.com/2023in0076/Customer/profile/1711620112703.png",
          "contacts": contacts,
          "user_id": state?.user_id ? state?.user_id : "",
          "userType": userType
        }
        let res = await _Api(postdata, `api/v1/App/User/AddUser`);
        if (res.code === 200) {
          if (state?.user_id) {
            Updateloading(t, GetloadingID, t("Successfully Edit User"));
          } else {
            Updateloading(t, GetloadingID, t("Successfully Add User"));
          }

          navigate("/app/users");
        } else {
          if (res?.message) {
            Updateloading(t, GetloadingID, (res?.message), 'error');
          } else {
            Updateloading(t, GetloadingID, (res?.errors), 'error');
          }

        }
      }
      catch (err) {
        console.log(err, 'err');
        error(t("ERROR"));
      }
    }
  }

  const activeStatus = [
    { value: 'Active', label: <div className="active-dot-area text-success"><span className="active-dot-area-dot bg-success rounded-5"></span> {t("Active")}</div>, id: "1" },
    { value: 'InActive', label: <div className="active-dot-area text-danger"><span className="active-dot-area-dot bg-danger rounded-5"></span> {t("InActive")}</div>, id: "0" }
  ]


  const getSupplierList = async () => {
    try {
      const postdata = `   {
          roles{
                       _id
                       name
                    }
      }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let rolesarr = [];
      if (responce.data?.roles) {
        responce.data?.roles?.forEach((result) => {
          rolesarr.push(
            {
              id: result?._id,
              name: result?.name

            }
          );
        })
      }
      setAddusergraphlistarray(rolesarr);


    } catch (err) {
      console.log(err, "err");
    }
  };
  const getLocationList = async () => {
    try {
      const postdata = `{
              locations {
                  id
                  name 
              }
          }`;
      let responce = await graphqlPost(postdata);
      let locationarray = [];

      if (responce.data?.locations) {
        responce.data?.locations.forEach((result) => {
          locationarray.push(
            {
              id: result?.id,
              name: result?.name

            }
          );
        })
      }

      setLocationarrayList(locationarray);


    } catch (err) {
      console.log(err, "err");
    }
  };

  const Countries = async () => {
    try {
      const postdata = `  {
        countries{
                     id
                     name
                     sortname
                     phoneCode
                  }
    }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let countriesarray = [];
      let codearr = []
      if (responce.data?.countries) {
        responce.data?.countries.forEach((result) => {
          countriesarray.push(
            {
              id: result?.id,
              name: result?.name,
              sortname: result?.sortname,
              phoneCode: result?.phoneCode


            }
          );
          codearr.push({
            label: result?.phoneCode,
            value: result?.phoneCode
          })


        })
      }
      srtCountriesgraph(countriesarray);
      setPhonecodegraph(codearr)

    } catch (err) {
      console.log(err, "err");
    }
  };


  useEffect(() => {
    getLocationList();
    Countries()
    getSupplierList();
  }, []);
  const contentarr = (code, no) => {
    let arr = [...contacts]
    arr[0] = { code: code, no: no, icon: 1 }
    setContacts(arr)
  }

  const viweuserlist = async () => {
    try {
      const postdata = {
        "user_id": state?.user_id
      };
      let res = await _Api(postdata, `api/v1/App/User/vieweUser`);
      if (res.code === 200) {
        setUserImage(res?.data?.myProfile)
        setFirstname(res?.data?.first_name)
        setLasttname(res?.data?.last_name)
        setEmail(res?.data?.email)
        setLoginpin(res?.data?.loginPIN)
        setPosition(res?.data?.position)
        setStatus(res?.data?.status)
        setLocations(res?.data?.locationObj)
        setRolesid(res?.data?.role_id)
        setAuthentication(res?.data?.twofactorauth)
        setOffice_time(res?.data?.Office_time)
        setContacts(res?.data?.contacts)
        setUserType(res?.data?.userType)
        setCountry({
          id: res?.data?.CountryObj?.id,
          name: res?.data?.CountryObj?.name,
          phoneCode: res?.data?.CountryObj?.phoneCode
        })
        setChecked(res?.data?.locations)

      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }

  useEffect(() => {
    if (state?.user_id) {
      viweuserlist()
    }
    // eslint-disable-next-line
  }, [state?.user_id]);

  var valueofstatus = [];
  if (status === "0") {
    valueofstatus = { value: 'InActive', label: <div className="active-dot-area text-danger"><span className="active-dot-area-dot bg-danger rounded-5"></span> {t("InActive")}</div>, id: "0" }
  } else if (status === "1") {
    valueofstatus = { value: 'Active', label: <div className="active-dot-area text-success"><span className="active-dot-area-dot bg-success rounded-5"></span> {t("Active")}</div>, id: "1" }
  }

  const checkvalue = (i, e, id) => {

    let locationset = [...locations]
    if (e.target.checked) {
      if (locationset[i]?.isChecked) {
        locationset[i] = {
          ...locationset[i],
          "isChecked": false
        }
      } else {
        locationset[i] = {
          ...locationset[i],
          "isChecked": true

        }
      }
    } else {
      locationset[i] = {
        ...locationset[i],
        "isChecked": false
      }

    }
    setLocations(locationset)
  }

  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading mb-4">
                {' '}
                <Link
                  className="align-text-top me-3 page-back-btn" to='/app/users'
                >
                  <img
                    src={base_assets + 'images/icons/arrow-left.png'}
                    alt=""
                  />
                </Link>
                {state?.user_id ? t("Edit User") : t("Add User")}


              </h1>

              <div className="add-user-form container-md pb-5">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4 text-center mb-4">
                    <label htmlFor='chooseImg' className="add-user-form-user-img border rounded-4 text-center mx-auto cursor-pointer" onChange={onImageupload}>
                      <img
                        className="w-100 h-100"
                        src={userImage ? userImage : base_assets + 'images/icons/user-img.png'} alt=""
                      />
                    </label>
                    <div className='add-user-form-user-img-btn'>
                      <input onChange={onImageupload} type='file' className="com-btn mt-4 d-none" id='chooseImg' />
                      <label className='com-btn mt-4 cursor-pointer' htmlFor='chooseImg'>{t("Upload")}</label>
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input form-floating w-100 shadow-sm">
                      <input
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        type="text"
                        className="placeholder-up-input-field form-control bg-white border-0"
                        placeholder="text"
                      />
                      <label className="placeholder-up-input-label">
                        {t("First & Middle Name")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input form-floating w-100 shadow-sm">
                      <input
                        value={lasttname}
                        onChange={(e) => setLasttname(e.target.value)}
                        type="text"
                        className="placeholder-up-input-field form-control bg-white border-0"
                        placeholder="text"
                      />
                      <label className="placeholder-up-input-label">
                        {t("Last Name")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input form-floating w-100 shadow-sm">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        className="placeholder-up-input-field form-control bg-white border-0"
                        placeholder="text"
                      />
                      <label className="placeholder-up-input-label">
                        {t("Email")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input form-floating w-100 shadow-sm">
                      <input
                        defaultValue={state?.user_id ? "....." : ""}
                        onChange={(e) => setPassword(e.target.value)}
                        type={passEye ? "text" : "password"}
                        className="placeholder-up-input-field form-control bg-white border-0"
                        placeholder={"text"}
                      />
                      <i onClick={() => setPassEye(current => !current)} className={state?.user_id ? "" : passEye ? "fa fa-eye pass-eye" : "fa fa-eye-slash pass-eye"} aria-hidden="true"></i>
                      <label className="placeholder-up-input-label">
                        {t("Password")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input form-floating w-100 shadow-sm">
                      <input
                        defaultValue={state?.user_id ? "....." : ""}
                        value={loginpin}
                        maxLength={6}
                        pattern="[0-9]*"
                        onChange={(e) => setLoginpin(e.target.value)}
                        type="password"
                        className="placeholder-up-input-field form-control bg-white border-0"
                        placeholder="text"
                      />
                      <label className="placeholder-up-input-label">
                        {t("Login PIN 6 digit")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input form-floating w-100 shadow-sm">
                      <input
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        type="text"
                        className="placeholder-up-input-field form-control bg-white border-0"
                        placeholder="text"
                      />
                      <label className="placeholder-up-input-label">
                        {t("Position")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input w-100">
                      <Select
                        value={country}
                        onChange={(e) => { setCountry(e); contentarr(e?.phoneCode, contacts[0]?.no) }}
                        className="select-set select p-0 shadow-sm border-0"
                        placeholder={t("Country")}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        options={countriesgraph}
                        classNamePrefix="common-select"
                        getOptionLabel={(opt) => opt.name}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="row">
                      <div className="col-md-3 col-sm-3">

                        <Select
                          className="main-body-top-status-bar-select-num shadow-sm border-0 p-0"
                          placeholder={t("code")}
                          classNamePrefix="common-select"
                          onChange={(e) => { contentarr(e?.value, contacts[0]?.no) }}
                          value={{ label: contacts[0]?.code, value: contacts[0]?.code }}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          options={phonecodegraph}
                          getOptionLabel={(e) => <div >
                            <span >{e.label}</span></div>}
                          isSearchable={true}
                        />

                      </div>
                      <div className="col-md-9 col-sm-9 ps-0">
                        <div className="placeholder-up-input form-floating w-100 shadow-sm">
                          <input
                            value={contacts[0]?.no}
                            maxLength={17}
                            pattern="[0-9]*"
                            onChange={(e) => contentarr(contacts[0]?.code, e.target.value)}
                            type="number"
                            className="placeholder-up-input-field form-control bg-white border-0"
                            placeholder="Number"
                          />
                          <label className="placeholder-up-input-label">
                            {t("Phone Number")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="placeholder-up-input w-100">
                      <Select
                        onChange={(e) => setStatus(e?.id)}
                        value={valueofstatus}
                        className="select-set select p-0 shadow-sm border-0"
                        placeholder="Status"
                        options={activeStatus}
                        classNamePrefix="common-select"
                        getOptionLabel={(e) => <div className="">
                          {e.label}</div>}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className='col-md-12 add-user-form-bottom-data'>
                    <div className='row'>
                      <div className="col-md-12 mb-4">
                        <div className="d-flex align-items-center mb-3">
                          <div className="com-check">
                            <input type="checkbox" defaultChecked={authentication} id="two-factor" className="checked" onClick={(e) => setAuthentication(e.target.checked)} />
                            <label className="com-check-label" htmlFor="two-factor"></label>
                          </div>
                          <label htmlFor="two-factor" className="cursor-pointer ps-2 me-2">{t("Enable two factor authentication")}</label>
                          <span className="tooltip-area">
                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                            <span className="tooltip-area-text text-dark p-2" style={{ whiteSpace: "nowrap" }}>lorem iptum text herelorem iptum text herelorem iptum text here</span>
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="com-check">
                            <input type="checkbox" id="office-time" defaultChecked={office_time} className="checked" onClick={(e) => setOffice_time(e.target.checked)} />
                            <label className="com-check-label" htmlFor="office-time"></label>
                          </div>
                          <label htmlFor="office-time" className="cursor-pointer ps-2 me-2">{t("Office time")} </label>
                          <span className="tooltip-area">
                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                            <span className="tooltip-area-text text-dark p-2" style={{ whiteSpace: "nowrap" }}>lorem iptum text herelorem iptum text herelorem </span>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <hr className="mt-0 mb-4" />
                        <h4 className="main-green-text fw-semibold mb-4">{t("ROLES")}</h4>
                      </div>
                      {addusergraphlistarray?.length ? addusergraphlistarray.map((result, key) => {
                        return (
                          <div className="col-md-6 d-flex align-items-center mb-3" key={key}>
                            <div className="com-check radio">
                              <input type="radio"
                                checked={rolesid === result?.id ? true : false}
                                onClick={() => setRolesid(result?.id)}
                                id={result?.id}
                                name="rolesRadio" readOnly />
                              <label className="com-check-label" htmlFor={result?.id}></label>
                            </div>
                            <label htmlFor={result?.id} className="ps-2 cursor-pointer">{result?.name ? t(result?.name) : "-"}</label>
                          </div>
                        )

                      }) : ""}
                      <div className="col-md-12">
                        <hr className="mt-0 mb-4" />
                        <h4 className="main-green-text fw-semibold mb-4">{t("LOCATIONS / OUTLETS")}</h4>
                      </div>
                      <div className="col-md-6 d-flex align-items-center mb-3">
                        <div className="com-check">
                          <input type="checkbox" id="allLocations" name="locations-outlets" className='adduserlocationcheck_main'
                            onClick={(e) => { setAlllocation(e.target.checked) }}
                            onChange={handleCheckAllChange}
                            checked={checked?.length === LocationarrayList.length} />
                          <label className="com-check-label " htmlFor="allLocations"></label>
                        </div>
                        <label htmlFor="allLocations" className="ps-2 cursor-pointer">{t("All Locations")}</label>
                      </div>
                      {locations?.length ? locations.map((result, key) => {
                        return (
                          <div className="col-md-6 d-flex align-items-center mb-3" key={key} >
                            <div className="com-check">
                              <input
                                type="checkbox"
                                data-id={result?.id}
                                onClick={(e) => { checkvalue(key, e, result?.id) }}
                                id={result?.id}
                                className='adduserlocationcheck edituserlocationcheck'
                                name="locations-outlets"
                                checked={
                                  result?.isChecked
                                    ? "checked"
                                    : alllocation ? checked.includes(result.id) : ""
                                }
                                onChange={(e) => handleCountryChange(e, result)}

                              />
                              <label className="com-check-label" htmlFor={result?.id}></label>
                            </div>
                            <label htmlFor={result?.id} className="ps-2 cursor-pointer">{result?.name ? result?.name : ""}</label>
                          </div >
                        )

                      }) : LocationarrayList?.length ? LocationarrayList.map((result, key) => {

                        return (
                          <div className="col-md-6 d-flex align-items-center mb-3" key={key} >
                            <div className="com-check">
                              <input type="checkbox"
                                data-id={result?.id}
                                className='adduserlocationcheck'
                                id={result?.id}
                                name="locations-outlets"
                                onChange={(e) => handleCountryChange(e, result)}
                                checked={checked.includes(result.id)} />
                              <label className="com-check-label" htmlFor={result?.id}></label>
                            </div>
                            <label htmlFor={result?.id} className="ps-2 cursor-pointer">{result?.name ? result?.name : ""}</label>
                          </div >
                        )
                      }) : ""}



                      <div className="col-md-12">
                        <hr className="mt-0 mb-4" />
                      </div>
                      <div className="col-md-3 mx-auto" onClick={() => adduser()}>
                        <button className="com-btn w-100">{t("Submit")}</button>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div >
    </>
  )
}
export default AddUser
