import React, { useEffect, useState } from "react";
import "./cashregister.scss"
import Cashinmodal from "../../common/Modals/cashregistermodals/Cashinmodal";
import Cashoutmodal from "../../common/Modals/cashregistermodals/Csahoutmodal";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { _Api } from "../../common/api/_call"
import { toFormatPrice } from '../../common/helpers/function'
import { loading, Updateloading } from "../../common/helpers/toastify";
const Cashregister = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const posloc = JSON.parse(localStorage.getItem("pos-loc"));
    const [cashtype, setCashtype] = useState("");
    const [InoutStatus, setInoutStatus] = useState(false);
    const [cashregisterlist, setCashregisterlist] = useState()
    const [counted, setCounted] = useState({ 'cash': 0, 'credit_card': 0, 'bank': 0 });
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const getcashregisterlist = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `api/v1/POS/cashRegister/cashRegister`);
            if (res?.code === 200) {
                setCashregisterlist(prevState => ({
                    ...prevState,
                    header: res?.data[0]?.header,
                    id: res?.data[0]?.id,
                    payment: res?.data[0]?.payment,
                    payment_summery: res?.data[0]?.payment_summery,
                    sales_summary: res?.data[0]?.sales_summary,
                    tax_summery: res?.data[0]?.tax_summery,
                }));
                setInoutStatus(false);
            }
        }
        catch (err) {
            console.log(err, 'err')
        }
    }
    const getfloat = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `api/v1/POS/cashRegister/getFloat`)
            if (res?.code === 200) {
                let inarr = []
                let outarr = []
                if (res?.data?.in_out?.length) {
                    res?.data?.in_out.forEach((item) => {
                        if (item?.AmountType === "CR") {
                            inarr.push(item?.amount)
                        } else if (item?.AmountType === "DR") {
                            outarr.push(item?.amount)
                        }
                    })
                }
                let finalin = inarr?.reduce(
                    (interation, val) =>
                    (interation =
                        interation + parseInt(val)),
                    0
                )
                let finalout = outarr?.reduce(
                    (interation, val) =>
                    (interation =
                        interation + parseInt(val)),
                    0
                )
                let finalfloat = (res?.data?.actual_float + finalin) - finalout
                setCashregisterlist(prevState => ({
                    ...prevState,
                    float: {
                        "opening_float": res?.data?.opening_float,
                        "in_out": res?.data?.in_out,
                        "actual_float": finalfloat,
                        "total_cashBalance": res?.data?.total_cashBalance,
                        "net_cash_enclosed": res?.data?.net_cash_enclosed
                    }
                }));
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        if (InoutStatus) {
            getfloat()
        } else {
            getcashregisterlist()
            getfloat()
        }
    }, [InoutStatus])

    const getsaveregister = async (event) => {
        let GetloadingID = loading();
        try {
            const postdata = {
                "id": event,
                "float": {
                    "actual_float": parseInt(parseInt(counted?.cash) + parseInt(cashregisterlist?.float?.actual_float)),
                    "total_cashBalance": parseInt(parseInt(counted?.cash) + parseInt(cashregisterlist?.float?.total_cashBalance)),
                    "net_cash_enclosed": cashregisterlist?.float?.net_cash_enclosed
                },
                "payment": {
                    "cash": {
                        "expected": cashregisterlist?.payment?.cash?.expected,
                        "counted": parseInt(counted?.cash),
                        "difference": (cashregisterlist?.payment?.cash?.expected) - (counted?.cash)
                    },
                    "credit_card": {
                        "expected": cashregisterlist?.payment?.credit_card?.expected,
                        "counted": parseInt(counted?.credit_card),
                        "difference": (cashregisterlist?.payment?.credit_card?.expected) - (counted?.credit_card),
                        "card_details": cashregisterlist?.payment?.credit_card?.card_details,
                    },
                    "bank": {
                        "expected": cashregisterlist?.payment?.bank?.expected,
                        "counted": parseInt(counted?.bank),
                        "difference": (cashregisterlist?.payment?.bank?.expected) - (counted?.bank),
                        "bank_details": cashregisterlist?.payment?.credit_card?.bank_details,

                    }
                },
                "sales_summary": {
                    "sales": cashregisterlist?.sales_summary?.sales,
                    "giftcard": cashregisterlist?.sales_summary?.giftcard,
                    "deposit": cashregisterlist?.sales_summary?.deposit,
                    "custom_order": cashregisterlist?.sales_summary?.custom_order,
                    "creditnote": cashregisterlist?.sales_summary?.creditnote,
                    "no_transaction": cashregisterlist?.sales_summary?.no_transaction,
                    "num_cust": cashregisterlist?.sales_summary?.num_cust,
                    "totalNetSale": cashregisterlist?.sales_summary?.totalNetSale,
                    "avg_sales": parseFloat(cashregisterlist?.sales_summary?.avg_sales.toFixed(2))
                },
                payment_summery: {
                    "receive": cashregisterlist?.payment_summery?.receive ? cashregisterlist?.payment_summery?.receive : 0,
                    "redund": cashregisterlist?.payment_summery?.redund ? cashregisterlist?.payment_summery?.redund : 0,
                    "net_receive": cashregisterlist?.payment_summery?.net_receive ? cashregisterlist?.payment_summery?.net_receive : 0,
                },
                tax_summery: {
                    "sales": cashregisterlist?.tax_summery?.sales,
                    "tax": cashregisterlist?.tax_summery?.tax,
                    "total": cashregisterlist?.tax_summery?.total,
                }

            }
            let res = await _Api(postdata, `api/v1/POS/cashRegister/savecashRegister`);
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, t("Successfully Save"));
                navigate("/app/pos");
            } else {
                Updateloading(t, GetloadingID, res?.errors, 'error');
                getcashregisterlist()
            }
        }
        catch (err) {
            console.log(err, 'err')
        }
    }

    return (
        <div className="main-body">
            <div className="main-body-row">
                <div className="main-body-row-left-col p-0 w-100">
                    <div className="catalog-area bg-white page-menu-block cash-register">
                        <div className="cash-register-header">
                            <h4 className="cash-register-header-title danger-text">{t("Cash Register")}</h4>
                            <button type="button" className="close" data-bs-dismiss="modal">
                                <img
                                    src={base_assets + "images/icons/close.png"}
                                    alt="close-img"
                                />
                            </button>
                        </div>
                        <div className="cash-register-body">
                            <div className="cash-register-nav">
                                <div className="cash-register-nav-left">
                                    <div className="cash-register-nav-left-single">
                                        <label>{t("Outlet")}</label>
                                        <span>{cashregisterlist?.header?.outlate}</span>
                                    </div>

                                    <div className="cash-register-nav-left-single">
                                        <label>{t("Clouser")}</label>
                                        <span>#{cashregisterlist?.header?.closure_no}</span>
                                    </div>

                                    <div className="cash-register-nav-left-single">
                                        <label>{t("Support")}</label>
                                        <span>{t("Support")}</span>
                                    </div>
                                </div>
                                <div className="cash-register-nav-right">
                                    <label className="">{t("Opening time")}</label>
                                    <span> {cashregisterlist?.header?.data}
                                    </span>
                                </div>
                            </div>
                            <div className="cash-register-main-body">
                                <div className="cash-register-main-body-table main-body-main-table-wrap">
                                    <table className="common-table first-row-gray w-100 cash-register-main-body-table">
                                        <thead>
                                            <tr>
                                                <th className="main-green-text payment common-head">{t("Payment")}</th>
                                                <th>{t("Expected")}({posloc?.symbol})</th>
                                                <th>{t("Counted")}({posloc?.symbol})</th>
                                                <th className="diffrence">{t("Difference")}({posloc?.symbol})</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="payment"> {t("cash(Net cash payment " + toFormatPrice(cashregisterlist?.float?.opening_float, { addSymbol: true }) + ")")}  </td>
                                                <td>
                                                    {toFormatPrice(cashregisterlist?.payment?.cash?.expected, { addSymbol: true })}</td>
                                                <td><input className="cash-register-main-body-table-textarea"
                                                    type="text"
                                                    defaultValue={cashregisterlist?.payment?.cash?.counted}
                                                    onChange={(e) => {
                                                        setCounted(existingIds => ({ ...existingIds, cash: e.target.value }));
                                                    }}

                                                /></td>
                                                <td className="diffrence">
                                                    {counted?.cash !== 0 ?
                                                        toFormatPrice(cashregisterlist?.payment?.cash?.expected - counted?.cash, { addSymbol: true }) : 0}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="payment">{t("Credit Card")}
                                                    <span></span></td>
                                                <td>{toFormatPrice(cashregisterlist?.payment?.credit_card?.expected, { addSymbol: true })}</td>
                                                <td><input className="cash-register-main-body-table-textarea"
                                                    type="text"
                                                    defaultValue={cashregisterlist?.payment?.credit_card?.counted}
                                                    onChange={(e) => setCounted(existingIds => ({ ...existingIds, credit_card: e.target.value }))}
                                                /></td>
                                                <td className="diffrence">

                                                    {counted?.credit_card !== 0 ?
                                                        toFormatPrice(cashregisterlist?.payment?.credit_card?.expected - counted?.credit_card, { addSymbol: true }) : 0}
                                                </td>
                                            </tr>
                                            {cashregisterlist?.payment?.credit_card?.card_details?.length ? cashregisterlist?.payment?.credit_card?.card_details.map((result, key) => {
                                                return (
                                                    <tr className="bank-details" key={key}>
                                                        <td className="bank-details-inner">{result?.name}</td>
                                                        <td className="bank-details-cny">{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                );

                                            }) : ""}
                                            <tr className="bank-row">
                                                <td className="payment">{t("Bank")}</td>
                                                <td>{toFormatPrice(cashregisterlist?.payment?.bank?.expected, { addSymbol: true })}</td>
                                                <td><input className="cash-register-main-body-table-textarea"
                                                    type="text"
                                                    defaultValue={cashregisterlist?.payment?.bank?.counted}
                                                    onChange={(e) => setCounted(existingIds => ({ ...existingIds, bank: e.target.value }))}
                                                /></td>
                                                <td className="diffrence">
                                                    {counted?.bank !== 0 ? toFormatPrice(cashregisterlist?.payment?.bank?.expected - counted?.bank, { addSymbol: true }) : 0} </td>
                                            </tr>
                                            {cashregisterlist?.payment?.bank?.bank_details?.length ? cashregisterlist?.payment?.bank?.bank_details.map((result, key) => {
                                                return (
                                                    <tr className="bank-details" key={key}>
                                                        <td className="bank-details-inner">{result?.name}</td>
                                                        <td className="bank-details-cny">{toFormatPrice(result?.amount)}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                );
                                            }) : ""}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className="payment footer">{t("Total")}</td>
                                                <td></td>
                                                <td></td>
                                                <td className="diffrence footer"> {toFormatPrice(cashregisterlist?.payment?.total,
                                                    { addSymbol: true })}</td>
                                            </tr>
                                        </tfoot>
                                        <tfoot>
                                            <tr>
                                                <td className="payment footer">{t("Sub Total")}</td>
                                                <td></td>
                                                <td></td>
                                                <td className="diffrence footer">
                                                    {toFormatPrice(cashregisterlist?.payment?.sub_total, { addSymbol: true })}

                                                </td>
                                            </tr>
                                        </tfoot>
                                        <tfoot>
                                            <tr>
                                                <td className="payment footer">{t("Float")}</td>
                                                <td></td>
                                                <td></td>
                                                <td className="diffrence footer">-{toFormatPrice(cashregisterlist?.payment?.float, { addSymbol: true })}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div className='placeholder-up-input' >
                                        <textarea
                                            className="placeholder-up-input-field cash-register-main-body-table-input"
                                            placeholder={t("Notes")}
                                            type="text" />
                                    </div>
                                </div>
                                <div className="cash-register-main-body-right">
                                    <div className="cash-register-main-body-right-inner">
                                        <label className="main-green-text common-head">{t("Float")}</label>
                                        <div> <span className="link-color cursor-pointer" data-bs-toggle="modal"
                                            data-bs-target="#cashinmodal" onClick={() => setCashtype("IN")} >{t("In")} </span>| <span className="link-color cursor-pointer" data-bs-toggle="modal"
                                                data-bs-target="#cashoutmodal" onClick={() => setCashtype("OUT")} >{t("Out")}</span></div>
                                    </div >
                                    <div className="cash-register-main-body-right-inner-box">
                                        <div className="main-body-main-table-wrap">
                                            <table className="common-table first-row-gray w-100 cash-register-main-body-table">
                                                <thead>
                                                    <tr className="cash-register-main-body-right-inner-box-data register-header">
                                                        <th className="register-header-main">{t("Opening float")}</th>
                                                        <th className="main-green-bg"></th>
                                                        <th className="register-header-value main-green-bg">{toFormatPrice(cashregisterlist?.float?.opening_float, { addSymbol: true })}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <>
                                                        {cashregisterlist?.float?.in_out ? cashregisterlist?.float?.in_out.map((result, key) => {
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    {result?.AmountType === "CR" ?
                                                                        <tr className="cash-register-main-body-right-inner-box-data">
                                                                            <td className="cash-register-main-body-right-inner-box-data-inner">{t("Cash in")}</td>
                                                                            <td></td>
                                                                            <td className="cash-register-main-body-right-inner-box-data-value">

                                                                                {toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                                                        </tr> : result?.AmountType === "DR" ?
                                                                            <tr className="cash-register-main-body-right-inner-box-data">
                                                                                <td className="cash-register-main-body-right-inner-box-data-inner">
                                                                                    {result?.hod ? t("Cash Out(hod)") : t("Cash Out")}</td>
                                                                                <td></td>
                                                                                <td className="cash-register-main-body-right-inner-box-data-value">
                                                                                    {toFormatPrice(result?.amount, { addSymbol: true })}
                                                                                </td>
                                                                            </tr>
                                                                            : ""}
                                                                </React.Fragment>
                                                            )
                                                        }) : ""}
                                                    </>
                                                </tbody>
                                                <tfoot>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner cash-register-main-body-table footer">{t("Net Cash Remittance Enclosed")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">{toFormatPrice(cashregisterlist?.float?.net_cash_enclosed, { addSymbol: true })}</td>
                                                    </tr>
                                                </tfoot>
                                                <tfoot>
                                                    <tr className="cash-register-main-body-right-inner-box-data" >
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer">{t("Actual float")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">
                                                            {toFormatPrice(parseInt(counted?.cash) + cashregisterlist?.float?.actual_float, { addSymbol: true })}</td>
                                                    </tr>
                                                </tfoot>
                                                <tfoot>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer">
                                                            {t("Total Cash Balance")} </td>
                                                        <td>
                                                        </td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">
                                                            {counted?.cash ?
                                                                toFormatPrice(parseFloat(counted?.cash) + cashregisterlist?.float?.total_cashBalance, { addSymbol: true }) : toFormatPrice(cashregisterlist?.float?.total_cashBalance, { addSymbol: true })}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="cash-register-main-body-right-inner-box">
                                        <div className="main-body-main-table-wrap">
                                            <table className="common-table first-row-gray w-100 cash-register-main-body-table">
                                                <thead>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <th className="common-head cash-register-main-body-right-inner-box-data-inner" data-bs-toggle="modal"
                                                            data-bs-target="#cashregistermodal">
                                                            {t("Payment Summary")}
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">
                                                            {t("Payment Received")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">
                                                            {toFormatPrice(cashregisterlist?.payment_summery?.receive, { addSymbol: true })}
                                                        </td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">{t("Refund")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">
                                                            {toFormatPrice(cashregisterlist?.payment_summery?.redund, { addSymbol: true })}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer">
                                                            {t("Net Received")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer"> {toFormatPrice(cashregisterlist?.payment_summery?.net_receive, { addSymbol: true })}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="cash-register-main-body-right-inner-box">
                                        <div className="main-body-main-table-wrap">
                                            <table className="common-table first-row-gray w-100 cash-register-main-body-table">
                                                <thead>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <th className="common-head cash-register-main-body-right-inner-box-data-inner">{t("Sales Summary")} </th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">{t("Sales")}</td>
                                                        <td className="main-green-text">+</td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">
                                                            {toFormatPrice(cashregisterlist?.sales_summary?.sales, { addSymbol: true })}</td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">{t("Gift card")}</td>
                                                        <td className="main-green-text">+</td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">
                                                            {toFormatPrice(cashregisterlist?.sales_summary?.giftcard, { addSymbol: true })}</td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">{t("Deposit")}</td>
                                                        <td className="main-green-text">+</td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">
                                                            {toFormatPrice(cashregisterlist?.sales_summary?.deposit, { addSymbol: true })}</td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">{t("Custom & Repair Order")}</td>
                                                        <td className="main-green-text">+</td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">
                                                            {toFormatPrice(cashregisterlist?.sales_summary?.custom_order, { addSymbol: true })}</td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">{t("Credit Note")}</td>
                                                        <td className="main-green-text">+</td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">
                                                            {toFormatPrice(cashregisterlist?.sales_summary?.creditnote,
                                                                { addSymbol: true })}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer">{t("Net Sales")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">{toFormatPrice(cashregisterlist?.sales_summary?.totalNetSale, { addSymbol: true })}</td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer">{t("Average Sales Value")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">{toFormatPrice(cashregisterlist?.sales_summary?.avg_sales, { addSymbol: true })}</td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer">{t("Number of Transaction")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">{cashregisterlist?.sales_summary?.no_transaction}</td>
                                                    </tr>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer">{t("Number of Customer")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">{cashregisterlist?.sales_summary?.num_cust}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="cash-register-main-body-right-inner-box">
                                        <div className="main-body-main-table-wrap">
                                            <table className="common-table first-row-gray w-100 cash-register-main-body-table">
                                                <thead>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <th className="common-head cash-register-main-body-right-inner-box-data-inner">{t("Tax Summary")}</th>
                                                        <th>{t("Sales")}({posloc?.symbol})</th>
                                                        <th className="cash-register-main-body-right-inner-box-data-value">{t("Tax")}({posloc?.symbol})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner">{t("Sales")}</td>
                                                        <td>{toFormatPrice(cashregisterlist?.tax_summery?.sales, { addSymbol: true })}</td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value">{toFormatPrice(cashregisterlist?.tax_summery?.tax, { addSymbol: true })}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr className="cash-register-main-body-right-inner-box-data">
                                                        <td className="cash-register-main-body-right-inner-box-data-inner footer"> {t("Tax total")}</td>
                                                        <td></td>
                                                        <td className="cash-register-main-body-right-inner-box-data-value footer">{toFormatPrice(cashregisterlist?.tax_summery?.total, { addSymbol: true })}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="cash-register-main-body-right-close-btn" onClick={() => {
                                        getsaveregister(cashregisterlist?.id)
                                    }}>
                                        <button className="com-btn">
                                            {t("CLOSE REGISTER")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Cashinmodal cashtype={cashtype} setInoutStatus={setInoutStatus} />
            <Cashoutmodal cashtype={cashtype} setInoutStatus={setInoutStatus} />
        </div>
    )
}
export default Cashregister;