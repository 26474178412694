import React from 'react'

export default function CloseIcon({color, height, width}) {
    
  return (
    <svg width={width ? width : "14"} height={height ? height : "14"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 13L7 7M7 7L1 1M7 7L13 1M7 7L1 13" stroke={color ? color : "#A4A4A4"} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
  )
}
