import React from 'react'

export default function PurchaseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.707 2.293C11.6143 2.2 11.5041 2.12624 11.3827 2.07596C11.2614 2.02568 11.1313 1.99986 11 2H6C5.86867 1.99986 5.7386 2.02568 5.61727 2.07596C5.49594 2.12624 5.38574 2.2 5.293 2.293L2.293 5.293C2.19996 5.38571 2.12617 5.4959 2.07589 5.61724C2.0256 5.73857 1.99981 5.86866 2 6V11C2 11.266 2.105 11.52 2.293 11.707L12.293 21.707C12.3857 21.8002 12.4958 21.8741 12.6171 21.9246C12.7385 21.9751 12.8686 22.001 13 22.001C13.1314 22.001 13.2615 21.9751 13.3829 21.9246C13.5042 21.8741 13.6143 21.8002 13.707 21.707L21.707 13.707C21.7999 13.6142 21.8737 13.504 21.924 13.3827C21.9743 13.2614 22.0002 13.1313 22.0002 13C22.0002 12.8687 21.9743 12.7386 21.924 12.6173C21.8737 12.496 21.7999 12.3858 21.707 12.293L11.707 2.293ZM13 19.586L4 10.586V6.414L6.414 4H10.586L19.586 13L13 19.586Z" fill="black"/>
    <path d="M8.35305 10.0001C9.26267 10.0001 10.0001 9.26267 10.0001 8.35305C10.0001 7.44344 9.26267 6.70605 8.35305 6.70605C7.44344 6.70605 6.70605 7.44344 6.70605 8.35305C6.70605 9.26267 7.44344 10.0001 8.35305 10.0001Z" fill="black"/>
    </svg>
    
  )
}
