import React from 'react';
import { useTranslation } from 'react-i18next'
import { toFormatPrice } from '../../common/helpers/function'
const PaymentInstall = (props) => {
  const { installment, Grand_Total, installdivtim, installementdata } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { t } = useTranslation()

  return (
    <>
      <div
        className="modal fade common-modal payconf-modal"
        id="PayInstallModal"
        tabIndex={-1}
        aria-labelledby="PayInstallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <img
                className="order-successful-modal-img mb-2"
                src={base_assets + 'images/icons/exclamation-mark-green.png'}
                alt='payment'
              />
              <h5 className="order-successful-modal-heading">
                {t("Payment Confirmation")}
              </h5>

              <table className="order-successful-modal-table">
                <tbody>
                  <tr>
                    <td>{t("Grand Total")}</td>
                    <td><b>{toFormatPrice(Grand_Total, { addSymbol: true })}</b></td>
                  </tr>
                  <tr>
                    <td>{t("Installment")}</td>
                    <td><b>{installementdata.length} X{installdivtim?.installment?.label}</b></td>
                  </tr>
                </tbody>
                <tbody>
                  <>
                    {installment?.length ? installment.map((result, key) => {
                      return (
                        <tr key={key}>
                          <td> <b>{result?.key}</b> {t("Received")}</td>
                          <td>
                            <span> <b>{toFormatPrice(result?.amount, { addSymbol: true })}</b></span>
                          </td>
                        </tr>
                      )

                    }) : ""}
                  </>
                </tbody>
                <tfoot>
                  <tr>
                    <td>{t("Outstanding Balance")}</td>
                    <td className='danger-text'>
                      <span> <b>{toFormatPrice((Grand_Total - (installdivtim?.receive?.reduce(
                        (interation, val) => (interation = interation + parseInt(val.amount)),
                        0
                      ))), { addSymbol: true })}</b></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="modal-footer mt-0">
              <button
                type="button"
                className="btn modal-content-no"
                data-bs-dismiss="modal"
              >
                {t('No')}
              </button>
              <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal">{t("yes")}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default PaymentInstall;