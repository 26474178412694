import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SetupDevice from "../admin/setup/Device";
import SetupGeneral from "../admin/setup/General";
import SetupPaymentMethod from "../admin/setup/PaymentMethod.js"
import SetupTaxRules from "../admin/setup/TaxRules.js"
import SetupApplicationKeys from "../admin/setup/ApplicationKeys.js"
import SetupEmail from "../admin/setup/email/Email.js"
import SetupEmailTemplate from "../admin/setup/email/EmailTemplate.js"
import SetupDomain from "../admin/setup/domain/Domain.js"
import QuickStoreSetup from "../admin/setup/quickStoreSetup/QuickStoreSetup.js"
import AdminCashRegister from "../admin/setup/quickStoreSetup/CashRegister.js"
import AdminVoucherType from "../admin/setup/quickStoreSetup/VoucherType.js"
import AdminVoucherTypeDetail from "../admin/setup/quickStoreSetup/VoucherTypeDetail.js"
import { useSelector } from 'react-redux';
// import BarcodeTemplate from "../admin/setup/Barcode/template.js"
import BarcodePrintOut from "../admin/setup/barcodePrintOutTemplate/BarcodePrintOut.js";
import BarcodeLogs from "../admin/setup/Barcode/logs.js"
import BarcodeSummary from "../admin/setup/Barcode/summary.js"

export default function SetupRoutes() {
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
    return (
        <Routes>
            {parmissiondatashow?.Device?.View &&
                <Route exact path='device' element={<SetupDevice />} > </Route>
            }
            {parmissiondatashow?.gis_setup_general?.View &&
                <Route exact path='general' element={<SetupGeneral />} > </Route>}
            {parmissiondatashow?.gis_setup_payment_method?.View &&
                <Route exact path='paymentmethod' element={<SetupPaymentMethod />} > </Route>}
            {parmissiondatashow?.gis_setup_tax_rules?.View &&
                <Route exact path='taxrules' element={<SetupTaxRules />} > </Route>
            }
            <Route exact path='applicationkeys' element={<SetupApplicationKeys />} > </Route>
            <Route exact path='email' element={<SetupEmail />} > </Route>
            <Route exact path='email/template' element={<SetupEmailTemplate />} > </Route>
            <Route exact path='domain' element={<SetupDomain />} > </Route>
            <Route exact path='quickstoresetup' element={<QuickStoreSetup />} > </Route>
            <Route exact path='quickstoresetup/cashregister' element={<AdminCashRegister />} > </Route>
            <Route exact path='quickstoresetup/vouchertype' element={<AdminVoucherType />} > </Route>
            <Route exact path='quickstoresetup/vouchertypedetail' element={<AdminVoucherTypeDetail />} > </Route>

            <Route exact path='barcode' element={<BarcodePrintOut />} > </Route>

            <Route exact path='/barcode/profile' element={<BarcodeSummary />} > </Route>
            <Route exact path='/barcode/profile/:id' element={<BarcodeSummary />} > </Route>
            <Route exact path='/barcode/profile/:id/v' element={<BarcodeSummary />} > </Route>

            <Route exact path='/barcode/printing' element={<BarcodeSummary />} > </Route>
            <Route exact path='/barcode/inventory' element={<BarcodeSummary />} > </Route>

            <Route exact path='/barcode/log' element={<BarcodeLogs />} > </Route>
            <Route exact path='/barcode/s' element={<BarcodeSummary />} > </Route>


        </Routes>
    )
}
