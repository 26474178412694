import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'
import { error, loading, Updateloading } from '../../common/helpers/toastify'
import DateTime from '../../common/DateTime'
import 'react-datepicker/dist/react-datepicker.css'
import { _Api, Api_form } from '../../api/_call'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import { Tablehead } from '../../common/modules/Tablehead'
import TableColumnModal from '../../common/modules/tableColumn'
import { toFormatPrice, toUnformatPrice } from '../../common/helpers/function'
import { ImportExcelModal } from '../../common/modules/importExcel'
import { Pagination } from "../../common/Pagination";
import SocialModal from '../../common/modules/SocialModal/SocialModal'
import Select from "react-select"
import {
  LocationList,
  SupplierList,
  VoucherList,
  WarehouseList,
} from '../../common/graphql/commonlayouts'
import { createpurchasetablehead } from '../../common/Tablehead'
import { createpurchase } from '../../transaction/subTables/purchase/createpurchase'
import TransAndDeliverDate from '../../common/modules/TransAndDeliverDate'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { useSelector } from 'react-redux';
const CreatePurchase = (props) => {
  const generalsettings = useSelector((state) => state.app.generalsettings);

  const { t } = useTranslation();
  let total_qty = 0
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const navigate = useNavigate()
  const [limit, setLimit] = useState(100)
  const [filtername, setFiltername] = useState('')
  const [masterProduct, setPO_OrdersList] = useState('')
  const [newcolumndata, setnewcolumndata] = useState(createpurchasetablehead)
  const [commanSelected_loc, setcommanSelected_loc] = useState()
  const [commanSelected_vouc, setcommanSelected_vouc] = useState()
  const [commanSelected_sup, setcommanSelected_sup] = useState()
  const [commanSelected_warehouse, setcommanSelected_warehouse] = useState()
  const [excelloges, setExcelloges] = useState([])
  const [TransactionDate, setTransactionDate] = useState(new Date())
  const [DeliveryDate, setDeliveryDate] = useState(new Date())
  const [importfile, setImportfile] = useState()
  const [puexportdata, setPuexportdata] = useState([])
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const [po_id, setPo_id] = useState([])
  const [datalodaer, setDatsLodaer] = useState(false)
  useEffect(() => { }, [DeliveryDate])
  const setSubTable = async (event, key, id) => {
    if (event.target.checked) {
      let purchaseist_data = [...masterProduct]
      const postdata = {
        po_order_id: id,
      }
      let data = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPUOutStandingListDetails`,
      )
      var html_ = createpurchase(data, t)
      purchaseist_data[key].subTable = html_
      setPO_OrdersList(purchaseist_data)
    } else {
      let purchaseist_data = [...masterProduct]
      purchaseist_data[key].subTable = null
      setPO_OrdersList(purchaseist_data)
    }
  }
  const setSubTableforsku = async (key, id) => {
    var e = document.querySelector('#table-btn_' + key)
    var classList_ = e.classList
    if (classList_.contains('rotate-90')) {
      classList_.remove('rotate-90')
      let purchaseist_data = [...masterProduct]
      purchaseist_data[key].subTable = null
      setPO_OrdersList(purchaseist_data)
    } else {
      classList_.add('rotate-90')
      let purchaseist_data = [...masterProduct]
      const postdata = {
        po_order_id: id,
      }
      let data = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPUOutStandingListDetails`,
      )
      var html_ = createpurchase(data, t, key)
      purchaseist_data[key].subTable = html_
      setPO_OrdersList(purchaseist_data)
    }
  }

  const filterbyname = (search) => {
    GetPoOrders(currentPage, limit, search);
    setstate({ ...state, currentPage: 1 });
  };
  const getlimit = (limit) => {
    setLimit(limit);
    GetPoOrders(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    GetPoOrders(current, "");

  };
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const GetPoOrders = async (current, firstlimit, search) => {
    setDatsLodaer(true)
    try {
      UnSetdataTable('tbl_createPurchase_list')
      const postdata = {
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
        sku: [],
        item: [],
        collection: [],
        metal: [],
        location: [],
        location_id: commanSelected_loc,
        supplier_id: commanSelected_sup,
      }

      let resPurchase = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPuOutStandingList`,
      )
      if (resPurchase?.code === 200) {
        SetdataTable('tbl_createPurchase_list', '', newcolumndata)
        setstate({
          ...state,
          totalPages: Math.ceil(resPurchase?.count / postdata.limit),
          currentPage: current ? current : 1
        });
        setPO_OrdersList(resPurchase?.data)
        setDatsLodaer(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_create_purchase',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  useEffect(() => {
    callcolumns()
  }, [])

  useEffect(() => {
    if (commanSelected_loc && commanSelected_sup) {
      GetPoOrders()
    }
    // eslint-disable-next-line
  }, [commanSelected_loc, commanSelected_sup])

  useEffect(() => {
    SetdataTable('tbl_createPurchase_list', '', newcolumndata)
    // eslint-disable-next-line
  }, [newcolumndata])

  const callsubmit = () => {
    var getcheckbox = document.querySelectorAll('.createPurchaseselect')
    const array = []
    let po_order_id = ''
    for (var i = 0; i < getcheckbox.length; i++) {
      if (getcheckbox[i].checked) {
        array.push(getcheckbox[i].id)
        po_order_id = getcheckbox[i].getAttribute('data-po_order_id')
      }
    }
    const handleValidation = (e) => {
      let formIsValid = true
      if (!commanSelected_vouc) {
        formIsValid = false
        error(t('Please select voucher'))
      }
      if (generalsettings?.data?.generalsetup?.allowWarehouse === '1') {
        if (!commanSelected_warehouse) {
          formIsValid = false
          error(t('Please select Warehouse!!'))
        }
      }

      if (array.length === 0) {
        formIsValid = false
        error(t('No item Selected!!'))
      }
      return formIsValid
    }
    if (handleValidation()) {
      navigate('/app/inventory/purchaseOrdersOutstanding', {
        state: {
          purchaseoustandingids: array,
          po_order_id: po_order_id,
          type: 'pu_Outstanding_main',
          location_id: commanSelected_loc,
          supplier_id: commanSelected_sup,
          warehouse_id: commanSelected_warehouse,
          Voucher_id: commanSelected_vouc,
          transactiondate: TransactionDate,
          deliverydate: DeliveryDate,
        },
      })
    }
  }
  const SampleExcel = async () => {
    const exportLinkElement = document.createElement('a')
    exportLinkElement.hidden = true
    exportLinkElement.download = 'ReplenishOrder.xlsx'
    exportLinkElement.href =
       process.env.REACT_APP_BASE_ASSETS_URL+'files/GISpurchaseImportnew.xlsx'
    exportLinkElement.text = 'downloading...'
    document.body.appendChild(exportLinkElement)
    exportLinkElement.click()
    exportLinkElement.remove()
  }
  const readExcel = async () => {
    const handleValidation = (e) => {
      let formIsValid = true
      if (!importfile) {
        formIsValid = false
        error('Choose file!')
      }
      return formIsValid
    }
    let GetloadingID = loading();
    if (handleValidation()) {
      try {
        const formData = new FormData()
        formData.append('file', importfile)
        let res = await Api_form(
          formData,
          `api/v1/Inventory/purchase/uploadPUExcel`,
        )
        if (res.code === 200) {
          setExcelloges(res?.logs)
          Updateloading(t, GetloadingID, (res?.message));
          setTimeout(() => {
            navigate('/app/inventory/purchaseOrdersOutstanding', {
              state: {
                importarray: res.data,
                type: 'pu_Outstanding_main',
                location_id: commanSelected_loc ? commanSelected_loc : res?.data[0]?.location_id,
                supplier_id: commanSelected_sup ? commanSelected_sup : res?.data[0]?.supplier_id,
                warehouse_id: commanSelected_warehouse,
                Voucher_id: commanSelected_vouc,
                transactiondate: TransactionDate,
                deliverydate: DeliveryDate,
                excelloges1: res?.logs
              },
            })
          }, 500)
        } else {
          Updateloading(t, GetloadingID);
          error((res?.message))
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    header_values.push("Product ID", "SKU", "StockID", "Metal", "Stone", "Size", "Location", "QTY", "Price", "PO_NO", "POS_NO", "Remark", 'Gwt', "Nwt", "Stone", "Shape", "Cut", "Clarity", "Color", "Pointer", "Size", "Pcs", "Weight", "Setting_Type", "C_Type", "C_No"
    )

    if (puexportdata.length) {
      puexportdata.forEach((result, key) => {

        if (result?.Stones?.length) {
          bodyarray.push([result?.ID, result?.SKU, result?.stock_id, result?.metal_name, result?.stone_name, result?.size_name,
          result?.location_name, result?.po_QTY, result?.price, result?.po_no, result?.pos_no, result?.remark, result?.GrossWt,
          result?.NetWt, result?.Stones[0]?.stone_name, result?.Stones[0]?.shape_name, result?.Stones[0]?.cut_name, result?.Stones[0]?.clarity_name, result?.Stones[0]?.color_name, result?.Stones[0]?.Pointer, result?.Stones[0]?.size_name, result?.Stones[0]?.Pcs, result?.Stones[0]?.Cts, result?.Stones[0]?.setting_name, result?.Stones[0]?.certificate?.type,
          result?.Stones[0]?.certificate?.number]
          )
        } else {
          bodyarray.push([result?.ID, result?.SKU, "", result?.metal_name, result?.stone_name, result?.size_name, "",
          result?.order_qty, result?.price, result?.pos, result?.remark, result?.GrossWt, result?.NetWt])
        }
        if (result?.Stones?.length) {
          result?.Stones.forEach((res, key) => {
            if (key >= 1) {
              bodyarray.push(["", "", "", "", "", "", "", "", "", "", "", "", "", "", res?.stone_name, res?.shape_name, res?.cut_name,
                res?.clarity_name, res?.color_name, res?.Pointer, res?.size_name, res?.Pcs, res?.Cts, res?.setting_name,
                res?.certificate?.type, res?.certificate?.number]
              )
            }
          })
        }
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'PU IMPORT');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "create_purchase");
  }

  const puimport = async () => {

    const handleValidation = (e) => {
      let formIsValid = true
      if (!po_id.length) {
        formIsValid = false
        error(t('No item Selected!!'))
      }
      return formIsValid
    }
    if (handleValidation()) {
      let GetloadingID = loading();
      try {
        let postdata = {
          po_order_id: po_id
        }
        let res = await _Api(postdata, "api/v1/Inventory/purchase/getPUOutStandingListExport")
        if (res?.code === 200) {
          if (res?.data?.length) {
            setPuexportdata(res?.data)
            Updateloading(t, GetloadingID, t("Excel Export Successfully!!"));
          }

        }
      }
      catch {

      }
    }


  }

  useEffect(() => {
    if (puexportdata.length) {
      downloadexclsheet()
    }
    // eslint-disable-next-line
  }, [puexportdata.length])

  const selecteditem = async (event) => {
    if (event.target.checked) {
      const select = document.querySelectorAll(".puitems");
      const mainselected = []
      for (let i = 0; i < select.length; i++) {
        if (select[i].checked) {
          let puid = select[i].getAttribute("data-id");
          mainselected.push(puid);
        }
      }
      setPo_id(mainselected)
    } else {
      const result = po_id.filter((element) => {
        return element !== event.target.value;
      });
      setPo_id(result);
    }
  }

  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link
                    to="/app/inventory/transactionPurchase"
                    className="page-back-btn"
                  >
                    <img
                      src={base_assets + 'images/icons/arrow-left.png'}
                      alt=""
                    />
                  </Link>
                  <img
                    src={base_assets + 'images/icons/home-icon.png'}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={'Purchase'}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">
                  {t('Create Purchase')}
                </h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills">
                  <button className="main-body-top-tab-bar-left-col-a nav-link active table-refresh">
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        src={
                          base_assets +
                          'images/icons/outstanding-green-icon.png'
                        }
                        alt=""
                      />
                      {t('Purchase Order Outstanding')}
                    </span>
                  </button>
                </div>
              </div>
              <div className="main-body-top-inputs-bar">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">
                    {t('PU-XXX')}
                  </label>
                  <LocationList setcommanSelected_loc={setcommanSelected_loc} />
                  <SupplierList setcommanSelected_sup={setcommanSelected_sup} />
                  {generalsettings?.data?.generalsetup?.allowWarehouse === '1' && (
                    <WarehouseList setcommanSelected_warehouse={setcommanSelected_warehouse} />
                  )}
                  <TransAndDeliverDate
                    TransactionDate={TransactionDate}
                    DeliveryDate={DeliveryDate}
                    setTransactionDate={setTransactionDate}
                    setDeliveryDate={setDeliveryDate}
                  />
                </div>
                <div className="main-body-top-inputs-bar-col-right">
                  {excelloges?.length ?
                    <div className="dropdown user-log-dropdown d-flex align-items-center">
                      <button className="btn-style stock-take-inner-row-col-mid-header-right-btn mx-1 dropdown-toggle"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                        <img className="note" src={base_assets + "images/icons/note-board-icon-dark.png"} alt="" />
                      </button>

                      <ul className="dropdown-menu">
                        <ul className="nav nav-tabs user-log-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="user-tab" data-bs-toggle="tab" data-bs-target="#user-tab-pane" type="button" role="tab" aria-controls="user-tab-pane" aria-selected="true">{t("Excel Import Loges")}</button>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="user-tab-pane" role="tabpanel" aria-labelledby="user-tab" tabIndex="0">
                            <table>
                              <tbody>
                                {excelloges?.length ? excelloges.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        <b className="fw-semibold">{result?.SKU ? result?.SKU : ""}</b><br />
                                        <span>{result?.message ? result?.message : ""}</span><br />
                                      </td>

                                    </tr>
                                  )
                                })
                                  : ""}

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </ul>
                    </div> : ""}
                  <button
                    className="main-body-top-inputs-bar-col-right-submit-btn com-btn"
                    onClick={callsubmit}
                  >
                    {t('Submit')}
                  </button>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('PO No./Supplier/Ship to ...')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item" onClick={() => { puimport() }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#ImportModal"

                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/import-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('import')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="tbl_createPurchase_list"
                    className="common-table first-row-gray w-100"
                  >
                    <Tablehead
                      tablehead={createpurchasetablehead}
                      tblName={'tbl_create_purchase'}
                      setfilterby={""}
                      setShowoption={0}
                    />

                    <tbody>
                      <>
                        {masterProduct
                          ? masterProduct?.map((result, key) => {
                            total_qty += result?.quantity ? result?.quantity : 0
                            return (
                              <React.Fragment key={key}>
                                <tr key={key}>
                                  <td>
                                    <div className="com-check">
                                      <input
                                        id={key}
                                        data-id={result?.id}
                                        className="createPurchase_select  puitems"
                                        type="checkbox"
                                        onChange={(e) => { setSubTable(e, key, result.id); selecteditem(e) }
                                        }

                                      />
                                      <label
                                        htmlFor={key}
                                        className="com-check-label"
                                      />
                                    </div>
                                  </td>
                                  <td>{key + 1}</td>
                                  <td>

                                    {result?.createdAt ? result?.createdAt : ''}
                                  </td>
                                  <td className="po-no">
                                    {result?.po_no ? result?.po_no : ''}
                                  </td>
                                  <td>
                                    {result?.supplier_name
                                      ? result?.supplier_name
                                      : ''}
                                  </td>
                                  <td>
                                    {result?.location_name
                                      ? result?.location_name
                                      : ''}
                                  </td>
                                  {result?.sku === 0 ? (
                                    <td className="sku-col">
                                      {result?.sku ? result?.sku : '0'}
                                    </td>
                                  ) : (
                                    <td
                                      className="sku-col"
                                      onClick={() =>
                                        setSubTableforsku(key, result.id)
                                      }
                                      id={'table-btn_' + key}
                                    >
                                      <div className="td-icons-wrap">
                                        <div>
                                          <img
                                            className="sku-col-arrow"
                                            src={
                                              base_assets +
                                              "/images/icons/right_green_button.png"
                                            }
                                            alt=""
                                          />
                                          <span>{result?.sku ? result?.sku : "0"}</span>
                                        </div>
                                        {result.allocatable ? <div className="td-icons-wrap-link">
                                          <img
                                            className="img "
                                            src={
                                              base_assets +
                                              'images/icons/link-icon.svg'
                                            }
                                            alt=""
                                          />
                                        </div> : ""}
                                        <div className='tooltip-area-up'>
                                          {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user"><img
                                            className="img"
                                            src={
                                              base_assets +
                                              'images/icons/gray-user.png'
                                            }
                                            alt=""
                                          /> <span>{result?.pos_Qty}</span>
                                          </div> : ""}
                                          <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.pos_no}<br />{result?.delivery_date}</span>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  <td>
                                    {result?.quantity ? result?.quantity : '-'}
                                  </td>
                                  <td>
                                    {result?.delivery_date
                                      ? result?.delivery_date
                                      : ''}
                                  </td>
                                  <td>10</td>
                                  <td>
                                    {result?.total_price
                                      ? toFormatPrice(result?.total_price, { addSymbol: true })
                                      : '0.00'}
                                  </td>
                                </tr>
                                {typeof result.subTable == 'undefined'
                                  ? (result.subTable = '')
                                  : result.subTable}
                              </React.Fragment>
                            )
                          })
                          : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{total_qty}</td>
                        <td />
                        <td />
                        <td>
                          {toFormatPrice(
                            masterProduct
                              ? masterProduct?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.total_price)),
                                0,
                              )
                              : '', { addSymbol: true }
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              {masterProduct.length > 0 ? (
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  pagination={(crPage) => handlePaginations(crPage)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <SocialModal />
      </div>
      <ImportExcelModal
        setImportfile={setImportfile}
        readExcel={readExcel}
        SampleExcel={SampleExcel}
        importfile={importfile}
      />
      <TableColumnModal
        tablehead={createpurchasetablehead}
        tblName={'tbl_create_purchase'}
        calldatatable={GetPoOrders}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
    </React.Fragment>
  );
};

export default CreatePurchase;
