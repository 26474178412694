import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Sidebar from './Sidebar';
import PlusIcon from 'Ecommerce/common/icons/PlusIcon';
import DeleteIcon from 'admin/common/icons/DeleteIcon';
import { _Api } from 'Ecommerce/common/api';
import EditIcon from 'admin/common/icons/EditIcon';
import LeftIcon from 'admin/common/icons/sidebar/LeftIcon';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';

export default function AccountBillingAndShipping() {
  const [addressdata, setAddressdata] = useState()
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const getaddress = async () => {
      try {
          let postdata = {}
          let res = await _Api(postdata, `account/getAddresses`)
          if (res?.statusCode === 200) {
              setAddressdata(res?.data)
          }
      }
      catch (err) {
          console.log(err, "err")
      }
  }
  useEffect(() => {
      getaddress()
  }, [])
  const deleteaddress = async (id, billing_address) => {
      try {
          let postdata = {
              "id": id,
              "billing_address": billing_address ? billing_address : false
          }
          let res = await _Api(postdata, `account/deleteAddress`)
          if (res?.statusCode === 200) {
              getaddress()
          }
      }
      catch (err) {
          console.log(err, "err")
      }
  }
  return (
    <div className='bg-white d-flex'>
    <Sidebar />
   
    <div className="account_details">
      {screenSize.width < 525 ?
    <div className="account_details_topbar">
        <div className='account_details_topbar-icon' onClick={()=>navigate('/account')}>
          <LeftIcon/>
        </div>
        <span>{t("Billing and Shipping")}</span>
    </div>
    :null}
    {screenSize.width < 1200 && screenSize.width > 525 ?
    <div className="account_details_desktoptopbar">
        <div className='account_details_desktoptopbar-icon' onClick={()=>navigate('/account')}>
          <LeftIcon/>
        </div>
        <span>{t("Billing and Shipping")}</span>
    </div>
    :null}
        <div className='account-address-container'>
            <div className='account-address-container-header'>
              <span className='account-address-container-header-heading'>{t("Billing and Shipping")}{" "}{screenSize.width < 1200 && 'Details'}</span>
              <span className='account-address-container-header-subheading'>{t("Manage  your Billing & Shipping address.")}</span>
              </div>
            <div className='account-address-container-mainBody'>
            <div className='account-address-container-mainBody-firstBox'>
                    <div className='account-address-container-mainBody-firstBox-shippingAddr '>
                        <span className='account-com-subHeading'>{t("Billing Address")}</span>
                        <Link className="text-decoration-none" to="/account/addressdetails"
                            state={{
                                id: "",
                                billing_address: true,
                                address: "Billing Information"
                            }}>
                            <div className='account-address-container-mainBody-firstBox-shippingAddr-addNew'> <span className='addIcon'><PlusIcon width={16} height={16} /></span><p>{t("Add New Billing Address")}</p></div>
                        </Link>
                    </div>
                    {addressdata?.billing_address?.length ? addressdata?.billing_address.map((result, key) => {
                        return (
                            <div className='account-address-container-mainBody-firstBox-addressBox ecom-addressShowBox'>
                                <div className='ecom-addressShowBox-headerBox'>
                                    <h3 className='ecom-addressShowBox-headerBox-heading'>{result?.name}</h3>
                                    <div className='ecom-addressShowBox-headerBox-iconBox'>
                                        <Link className="text-decoration-none" to="/account/addressdetails"
                                            state={{
                                                id: result?.UDID,
                                                billing_address: true,
                                                address: "Billing Information"
                                            }}>
                                            <span>
                                                <EditIcon
                                                    width={22}
                                                    height={22}
                                                />
                                            </span>
                                        </Link>
                                        {!result?.is_default ?
                                            <div className="pointer" onClick={() => deleteaddress(result?.UDID, true)}><DeleteIcon color={'#DA1414'} /></div>
                                            : ""}
                                    </div>
                                </div>
                                <p className='ecom-addressShowBox-AddrparaBox'>{result?.completeAddress}</p>
                                <p className='ecom-addressShowBox-phonePara'>{result?.phone}</p>
                               {result?.email ? <p className='ecom-addressShowBox-phonePara'>{result?.email}</p> : null}
                                {result?.taxId ? <p className='ecom-addressShowBox-phonePara'>{t("Tax ID")} : {result?.taxId}</p> : null}
                                {result?.is_default ?
                                    <button className='ecom-addressShowBox-defaultAddrBtn' ><div className='dot'></div><span>{t("Default")}</span></button>

                                    : ""}
                            </div>
                        )
                    }) : ""}
                </div>
                <div className='account-address-container-mainBody-firstBox'>
                    <div className='account-address-container-mainBody-firstBox-shippingAddr '>
                        <span className='account-com-subHeading'>{t("Shipping Address")}</span>
                        <Link className='text-decoration-none'
                            state={{
                                id: "",
                                billing_address: false,
                                address: "Shipping Information"
                            }}
                            to="/account/addressdetails">
                            <div className='account-address-container-mainBody-firstBox-shippingAddr-addNew'> <span className='addIcon'><PlusIcon width={16} height={16} /></span><p>{t("Add New Shipping Address")}</p></div>
                        </Link>

                    </div>
                    {addressdata?.shipping_address?.length ? addressdata?.shipping_address.map((result, key) => {
                        return (
                            <div className='account-address-container-mainBody-firstBox-addressBox ecom-addressShowBox' key={key}>
                                <div className='ecom-addressShowBox-headerBox'>
                                    <h3 className='ecom-addressShowBox-headerBox-heading'>{result?.name}</h3>
                                    <div className='ecom-addressShowBox-headerBox-iconBox'>
                                        <Link className="text-decoration-none" state={{
                                            id: result?.UDID,
                                            billing_address: false,
                                            address: "Shipping Information"
                                        }} to="/account/addressdetails">
                                            <span>   <EditIcon
                                                width={22}
                                                height={22}
                                            /> </span>
                                        </Link>
                                        {!result?.is_default ?
                                            <div className="pointer" onClick={() => deleteaddress(result?.UDID, false)}><DeleteIcon width={18} height={18} color={'#DA1414'} /></div>
                                            : ""}
                                    </div>
                                </div>
                                <p className='ecom-addressShowBox-AddrparaBox'>{result?.completeAddress}</p>
                                <p className='ecom-addressShowBox-phonePara'>{result?.phone} </p>
                                {result?.email ? <p className='ecom-addressShowBox-phonePara'>{result?.email}</p> : null}
                                {result?.taxId ? <p className='ecom-addressShowBox-phonePara'>{t("Tax ID")} : {result?.taxId}</p> : null}
                                {result?.is_default ?
                                    <button className='ecom-addressShowBox-defaultAddrBtn'><div className='dot'></div><span>{t("Default")}</span></button> : ""}

                            </div>
                        )
                    }) : ""}

                </div>
               
            </div>

        </div>
    </div >
</div >
  )
}
