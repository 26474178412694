import React, { useEffect, useState } from 'react'
import { graphqlPost } from '../../api/_call'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
const ProductImageSliderModal = (props) => {
  const { main_id } = props
  const [image, setImage] = useState([])
  const [name, setName] = useState()
  const [showloader, setShowloader] = useState(true)
  const getImage = async () => {
    setShowloader(true);
    setImage([]);
    setName('');
    try {
      const postdata =
        `{
                 ProductImages(product_id:"` +
        main_id +
        `") {
                  image
                  name
              }
      }`
      let responce = await graphqlPost(postdata, 'App/graphql')
      if (responce.data) {
        setShowloader(false);
        setImage(responce?.data?.ProductImages?.image)
        setName(responce?.data?.ProductImages?.name)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (main_id) {
      getImage()
    }
    // eslint-disable-next-line
  }, [main_id])

  return (
    <div
      className="modal fade common-modal pro-img-modal"
      id="ProductViewModal"
      tabIndex="-1"
      aria-labelledby="ProductViewModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ProductViewModalLabel">
              {name ? name : ''}
            </h5>
            <button
              type="button"
              className="btn-close shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {showloader && <div id="table_loader" className="">
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>}
            <ImageGallery
              items={image}
              showPlayButton={false}
              showFullscreenButton={false}
              infinite={true}
              disableSwipe={false}
              disableThumbnailSwipe={false}
              additionalClass="pro-img-slider"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductImageSliderModal
