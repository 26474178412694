import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { toFormatPrice } from '../../common/helpers/function'
const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
const Savelaybymodal = (props) => {
    const { checkoutdetails } = props

    const { t } = useTranslation()
    return (
        <>
            <div className="modal common-modal order-successful-modal d-block" id="orderSuccessfulllaybyModal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img className='order-successful-modal-img' src={base_assets + "images/pos/icons/check-success.png"} alt="" />
                            <h5 className='order-successful-modal-heading'>{t("Order Successful")}</h5>
                            <table className='order-successful-modal-table'>
                                <tbody>
                                    <tr className='gray-text'>
                                        <td>{t("Order Summary")}</td>
                                        <td>{checkoutdetails?.count} {t("Item")}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr className='gray-text'>
                                        <td> {t("Grand Total")}</td>
                                        <td><span>{toFormatPrice(parseFloat(checkoutdetails?.totalAmt), { addSymbol: true })}</span></td>
                                    </tr>
                                    {checkoutdetails?.pay_layby_data.length ? checkoutdetails?.pay_layby_data.map((result, key) => {

                                        return (
                                            <tr className='gray-text' key={key}>
                                                <td> {result?.Received}</td>
                                                <td><span>{toFormatPrice(parseFloat(result?.amount), { addSymbol: true })}</span></td>
                                            </tr>
                                        )

                                    }) : ""}

                                    <tr className='danger-text'>
                                        <td className="fw-normal">{t("Outstanding Balance")}</td>
                                        <td className="fw-normal"><span>{toFormatPrice(checkoutdetails?.outst_bal, { addSymbol: true })}</span></td>
                                    </tr>
                                    <tr className='gray-text'>
                                        <td> {t("Due Date")}</td>
                                        <td><span>{checkoutdetails?.layby_due_date}</span></td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div className='order-successful-modal-icons'>
                                <a className='btn-style' href={checkoutdetails?.url ? checkoutdetails?.url : '/app/pos/custom/custom'} target={'_blank'} rel="noreferrer">
                                    <img src={base_assets + "images/pos/icons/g-printer.png"} alt="" />
                                </a>
                                <button className='btn-style' data-bs-toggle="modal" data-bs-target="#emailmodal">
                                    <img src={base_assets + "images/pos/icons/g-msg.png"} alt="" />
                                </button>
                                {/* <button className='btn-style'>
                                    <img src={base_assets + "images/pos/icons/g-upload.png"} alt="" />
                                </button> */}
                            </div>
                            <Link to="/app/pos/menu" className="btn linear-bg order-successful-modal-btn">{t("BACK TO POS")}</Link>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default Savelaybymodal;
