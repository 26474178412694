import React, { useEffect, useState, useCallback } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import NavBar from '../common/navbar';
import "../style.scss";
import "../rtlApp.scss";
import DataPurge from "../common/modules/DataPurgeModal";
import { _Apiauth } from "../api/_call";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const { Fulhandle } = props;

  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const location = useLocation();
  const [loginData, setLoginData] = useState(null);
  const [LanguageList, setLanguageData] = useState(null);
  const [CurrentLan_flg, setLanguageSelectData] = useState('US.png');
  const [dataPurge, setDatapurge] = useState(true);
  const [dataNotificationsData, setNotifications] = useState([]);
  const [totalNotification, setTotalNotification] = useState(0);
  const moduleparmissiondatashow = useSelector((state) => state.app.ModulePermission);
  const [checkApiCall, setCheckApiCall] = useState(true);
  const [isSidebar, setActive] = useState(false);
  const [productHeader, setproductHeader] = useState(false);
  const [fixedproductHeader, setFixedproductHeader] = useState(false);
  const Pathstring = location?.pathname;
  var Pathstring_array = Pathstring.split("/");

  useEffect(() => {
    // Function to be called every 10 seconds
    const interval = setInterval(() => {
      // Call your function here
      getNotifications();
    }, 40000); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, [])


  const getUserData = async () => {
    setCheckApiCall(false);
    const postdata = {};
    let profileData = await _Apiauth(postdata, `App/profile/get-auth`);
    if (profileData.code === 200) {
      setLoginData(profileData?.data);
      setLanguageData(profileData?.languageList);
    }

  }
  const rtlView = useCallback((type, l) => {
    localStorage.setItem('UserLang', l);
    setLanguageSelectData(localStorage.getItem('UserLang'));
    let bodyid = document.querySelector('#body_id');
    if (type === 'rtl') {
      bodyid.classList.add('rtl');
    } else {
      bodyid.classList.remove('rtl');
    }
  }, [setLanguageSelectData]);
  useEffect(() => {
    const fetchData = async () => {
      if (checkApiCall) {
        await getUserData();
      }

      if (localStorage.getItem("UserLang")) {
        setLanguageSelectData(localStorage.getItem("UserLang"));
      } else {
        setLanguageSelectData('US.png');
      }

      if (localStorage.getItem("UserLang") === 'SA.png') {
        rtlView('rtl', localStorage.getItem("UserLang"));
      }


    };

    fetchData();
  }, [checkApiCall, setLanguageSelectData, rtlView]);

  const getNotifications = useCallback(async () => {
    try {
      const postdata = {};
      let resNotification = await _Apiauth(postdata, `Inventory/common/getNotification`);
      if (resNotification.code === 200) {
        setNotifications(resNotification.data);
        countNotification(resNotification.data);
      }
    } catch (err) {
      console.error(err, 'err');
    }
  }, []);

  const updateNotifications = async (itemid) => {
    try {
      const postdata = {
        "id": itemid,
        "status": 1
      };
      let resNotification = await _Apiauth(postdata, `Inventory/common/updateNotification`);
      if (resNotification.code === 200) {
        getNotifications();
      }
    } catch (err) {
      console.error(err, 'err');
    }
  }


  const toggleClass = () => {
    setActive(!isSidebar);
  };


  useEffect(() => {
    if (Pathstring_array[3]) {
      let productsPageHeader = Pathstring_array[3].trim();
      if (productsPageHeader === "main" || productsPageHeader === "add-new" || productsPageHeader === "productAdvanced" || productsPageHeader === "productVariant" || productsPageHeader === "productChoice" || productsPageHeader === "importproductimage") {
        setproductHeader('product');
      }
      else if (productsPageHeader === "customers") {

        setproductHeader('customers');

      }
      else if (productsPageHeader === "roles" || productsPageHeader === "UserLogReport" || productsPageHeader === "OperationLogReport" || productsPageHeader === "users" || productsPageHeader === "adduser" || productsPageHeader === "UserLogs" || productsPageHeader === "rolesUser") {
        setproductHeader('users');
      }
      else {
        setproductHeader(false)
      }
      if (productsPageHeader === "add-new" || productsPageHeader === "productAdvanced" || productsPageHeader === "productVariant" || productsPageHeader === "productChoice") {
        setFixedproductHeader('product');
      }
    } else if (Pathstring_array[2] === "users") {
      setproductHeader('users');
    }
    else if (Pathstring_array[3] === "customers") {
      setproductHeader('customers');
    }
    else {
      setproductHeader(false);
      setFixedproductHeader(false);
    }
  }, [Pathstring_array]);

  const createMarkup = (html) => {
    return { __html: html };
  };
  const countNotification = (data) => {
    let totalCount = data.filter((item) => item.status === 0)
    setTotalNotification(totalCount.length)
  }

  const handleStoppingDropdownClose = (e) => {
    e.stopPropagation();
  }

  return (

    <>
      <ToastContainer></ToastContainer>
      <header className={fixedproductHeader ? "main-header main-header-fixed" : "main-header"}>
        <div className="main-header-logo-side">
          <button className="main-header-toggle" onClick={toggleClass}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          <Link to="/app/admin/dashboard" className="main-header-logo">
            <img src={base_assets + "images/header-images/gis-logo-white.png"} alt="" />
          </Link>
        </div>
        <NavBar nav={productHeader ? productHeader : 'inventory'} activeHeader={Pathstring_array[3] ? Pathstring_array[3].trim() : "inventory"} />
        <ul className="main-header-right">
          <li className="dropdown common-dropdown notification-area">
            <button className="notification-area-btn dropdown-toggle" type="button" id="notify" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-bell" aria-hidden="true"></i>

              {totalNotification > 0 ?
                <span className='notification-area-count'>
                  <span className='notification-area-count-num'>
                    {totalNotification}
                  </span>
                </span>
                : ""}

            </button>
            <div className="dropdown-menu" aria-labelledby="notify" onClick={(e) => handleStoppingDropdownClose(e)}>
              <div className='notification-area-heading'>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='d-flex'>{t("Notifications")} {totalNotification > 0 ? <div className='notification-area-heading-noti'>{t(`${totalNotification}`)}</div> : ""}</span>
                  <div className='notification-area-heading-menuicons cursor-pointer'>
                    <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
                  </div>
                </div>
              </div>
              <div className='tasksidebar-tabs'>
                <div
                  className="tasksidebar-tabs-inner"
                  role="tablist"
                >
                  <div className={`tasksidebar-tabs-inner-item cursor-pointer`}>
                    <span className="tasksidebar-tabs-inner-item-text">
                      {t("All")}
                    </span>
                  </div>
                  <div
                    className={`tasksidebar-tabs-inner-item cursor-pointer active`}>
                    <span className="tasksidebar-tabs-inner-item-text">
                      {t("Mentions")}
                    </span>
                  </div>
                </div>
              </div>
              <ul className='notification-area-list'>
                {dataNotificationsData?.length ? dataNotificationsData.map((result, key) => {
                  return (
                    <li className={`notification-area-list-single ${result?.status === 0 ? 'unread' : ""}`} key={key} onClick={() => updateNotifications(result?.id)}>
                      <Link target={result?.url ? '_blank' : ""} to={result?.url ? result?.url : '#'} className='notification-area-list-single-a'>

                        <img className='notification-area-list-single-image' src={result?.image} alt="createdbyProfile" />
                        <div className='notification-area-list-single-wrapper'>
                          <span className='notification-area-list-single-text' dangerouslySetInnerHTML={createMarkup(result?.msg)}></span>
                          <div className='notification-area-list-single-foot'>
                            <span className='notification-area-list-single-foot-time'>{result?.time}</span>
                            <span className='notification-area-list-single-foot-user'>
                              {/* <i className="fa fa-user notification-area-list-single-foot-user-icon" aria-hidden="true"></i> */}
                              {result?.created_by}
                            </span>
                          </div>
                        </div>
                      </Link>
                      {result?.status === 0 ? <div className='notification-area-list-single-notificationmark'></div> : ""}
                    </li>
                  )

                }) :
                  // <div className='mt-4'><TableLoader/> 

                  // </div> 
                  <div className='notification-area-list notification-area-no-notification'>
                    <i className="fa fa-bell-slash-o" aria-hidden="true"></i>
                    <span className='notification-area-no-notification-text'>
                      {t("No Notifications Here")}
                    </span>
                  </div>
                }
              </ul>
              <div className='notification-area-list-bottom'>
                <div className='notification-area-list-bottom-left'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L11.9497 16.9497L22.5572 6.34326M2.0498 12.0503L6.99955 17M17.606 6.39355L12.3027 11.6969" stroke={`${totalNotification > 0 ? '#5CC7C1' : '#B3B3B3'}`} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className={`${totalNotification > 0 ? '' : 'disable'}`}>{t("Mark all as read")}</span>
                </div>
                <button className='notification-area-list-bottom-right'>
                  {t("View all notifications")}
                </button>
              </div>
            </div>
          </li>
          <li className="main-header-right-zoom" >
            {!Fulhandle.active ?
              <span onClick={Fulhandle.enter}> <img src={base_assets + 'images/header-images/zoom-icon.png'} alt="" /></span> : <span onClick={Fulhandle.exit}> <img src={base_assets + "images/header-images/zoom-icon-exit.png"} alt="zoomin" /></span>
            }

          </li>
          <li className="main-header-right-country">
            <div className="dropdown">
              <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={base_assets + 'images/flags/' + CurrentLan_flg} alt="icon" />
              </button>
              <ul className="dropdown-menu">
                {
                  LanguageList?.map((LanguageCode, key) =>
                    <li key={key}>
                      {LanguageCode.Lan_value === 'arabic' ?
                        <button className="dropdown-item" onClick={() => { i18n.changeLanguage(LanguageCode.Lan_value); rtlView('rtl', LanguageCode.Lan_photo); }} ><img src={base_assets + 'images/flags/' + LanguageCode.Lan_photo} alt="" /> <span>{LanguageCode.Lan_text}</span>
                        </button>
                        :
                        <button className="dropdown-item" onClick={() => { i18n.changeLanguage(LanguageCode.Lan_value); rtlView('ltr', LanguageCode.Lan_photo); }} ><img src={base_assets + 'images/flags/' + LanguageCode.Lan_photo} alt="" /> <span>{LanguageCode.Lan_text}</span>
                        </button>
                      }
                    </li>
                  )
                }
              </ul>
            </div>
          </li>
          <li className="main-header-right-user">
            <div className="dropdown">
              <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <img style={{ borderRadius: "50%" }} src={loginData?.myProfile ? loginData?.myProfile : base_assets + "images/header-images/user-icon.svg"} alt="" />
                <span className="main-header-right-user-name"> {loginData?.fname}</span>
              </button>
              <ul className="dropdown-menu main-header-right-user-dropdown">
                {loginData?.userType === "Administrator" ?
                  <li>
                    <div className='dropdown-menu-single' data-bs-toggle="modal" data-bs-target="#dataPurgeModal" onClick={() => {
                      setDatapurge(true)
                    }}>
                      <div className="main-header-right-user-dropdown-img">
                        <i className="fa fa-database" aria-hidden="true"></i>
                      </div>
                      <span >{t("Data Purge")}</span>
                    </div>
                  </li> : ""}

                <li>
                  <Link className='dropdown-menu-single' to="/app/admin/profile">
                    <div className="main-header-right-user-dropdown-img">
                      <i className="fa fa-user-o" aria-hidden="true"></i>
                    </div>
                    <span>{t("My profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-menu-single' to="/logout">
                    <div className="main-header-right-user-dropdown-img">
                      <i className="fa fa-power-off" aria-hidden="true"></i>
                    </div>
                    <span>{t("logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <DataPurge dataPurge={dataPurge} setDatapurge={setDatapurge} />
      </header>

      <aside className={isSidebar ? "main-sidebar-aside open" : "main-sidebar-aside"}>
        <ul className="main-sidebar-aside-menu">

          <li className={moduleparmissiondatashow?.Dashboard ? "main-sidebar-aside-menu-li" : "d-none"}>
            <Link to="/app/admin/dashboard" className=" main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-1.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-1.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Home")}</label>
            </Link>
          </li>
          <li className={moduleparmissiondatashow?.Organization ? "main-sidebar-aside-menu-li" : "d-none"}>
            <Link to="/app/admin/organization" className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-2.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-2.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Org")}</label>
            </Link>
          </li>
          <li className={moduleparmissiondatashow?.User || moduleparmissiondatashow?.user_reports || moduleparmissiondatashow?.user_roles ? "main-sidebar-aside-menu-li" : "d-none"}>
            <Link to="/app/users" className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-8.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-8.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("User")}</label>
            </Link>
          </li>
          <li className={moduleparmissiondatashow?.Location ? "main-sidebar-aside-menu-li" : "d-none"}>
            <Link to="/app/admin/location" className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-10.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-10.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Location")}</label>
            </Link>
          </li>
          <li className={moduleparmissiondatashow?.Master ? "main-sidebar-aside-menu-li" : "d-none"}>
            <Link to="/app/admin/master/itemmaster/item" className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-3.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-3.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Master")}</label>
            </Link>
          </li>
          <li className={moduleparmissiondatashow?.Product ? "main-sidebar-aside-menu-li" : "d-none"}>
            <NavLink to='/app/product/main' className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-4.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-4.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Product")}</label>
            </NavLink>
          </li>
          <li className={moduleparmissiondatashow?.Inventory ? "main-sidebar-aside-menu-li" : "d-none"}>
            <NavLink
              to="/app/inventory"
              className="main-sidebar-aside-menu-li-a" >
              <img className='show-default'
                src={base_assets + "images/icons/sbi-5.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-5.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Inventory")}</label>
            </NavLink>
          </li>
          <li className={moduleparmissiondatashow?.Point_of_sale ? "main-sidebar-aside-menu-li" : "d-none"}>
            <Link to="/app/pos/" className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-6.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-6.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("POS")}</label>
            </Link>
          </li>
          <li className={"main-sidebar-aside-menu-li position-relative"}>
            <span className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-9.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-9.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Customer")}</label>
            </span>
            <div className='main-sidebar-aside-menu-li-dropdown'>
              <Link to="/app/admin/customers/" className="main-sidebar-aside-menu-li-dropdown-single text-decoration-none d-block text-start">Customers</Link>
              <Link to="/app/admin/customers/customersreport" className="main-sidebar-aside-menu-li-dropdown-single text-decoration-none d-block text-start">Report</Link>
            </div>
          </li>
          <li className={moduleparmissiondatashow?.GIS_setup ? "main-sidebar-aside-menu-li" : "d-none"}>
            <Link to="/app/admin/setup/general" className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-11.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-11.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Setup")}</label>
            </Link>
          </li>
          <li className="main-sidebar-aside-menu-li">
            <Link to="/app/admin/settings" className="main-sidebar-aside-menu-li-a">
              <img className='show-default'
                src={base_assets + "images/icons/sbi-7.png"}
                alt=""
              />
              <img className='show-hover'
                src={base_assets + "images/icons/sib-7.png"}
                alt=""
              />
              <label className='main-sidebar-aside-menu-li-a-label'>{t("Setting")}</label>
            </Link>
          </li>
        </ul>
      </aside>

    </>

    //) : null
  );

}
export default Header;