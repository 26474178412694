import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import DateTime from "../common/DateTime";
import { DateRange } from "../common/helpers/daterange";
import { Tablehead } from "../common/modules/Tablehead";
import { operationLogTablethead } from "../common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../api/setdatatable";
import { Pagination } from "../common/Pagination";
import { useSelector } from 'react-redux';
import Select from "react-select";
import { _Api } from "../api/_call";
import TableLoader from "../../admin/common/TableLoader";
const OperationLogReport = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [newcolumndata, setnewcolumndata] = useState(operationLogTablethead);
  const [operationlogrepostlist, setOperationlogrepostlist] = useState([])
  const [datefilter, setDatefilter] = useState([]);
  const [filtername, setFiltername] = useState('');
  const [limit, setLimit] = useState(100);
  const [printparmission, setPrintparmission] = useState(0)
  const [showloader, setShowloader] = useState(false)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.user_reports?.View)
      setPrintparmission(parmissiondatashow?.user_reports?.Print);
    // eslint-disable-next-line
  }, []);
  const operationlogrepost = async (current, firstlimit, search) => {
    setShowloader(true)
    try {
      setTimeout(async () => {
        UnSetdataTable("operationlogrepostlist");
        const postdata = {
          "search": search ? search : "",
          "start_date": datefilter ? datefilter[0] : "",
          "end_date": datefilter ? datefilter[1] : "",
          "limit": firstlimit ? firstlimit : limit,
          "skip": current ? (current - 1) * limit : 0,
        };

        let res = await _Api(postdata, `api/v1/App/User/OperationLogs`);
        if (res.code === 200) {
          SetdataTable("operationlogrepostlist", 0, newcolumndata, [0]);
          setOperationlogrepostlist(res?.data)
          setShowloader(false)
          setstate({
            ...state,
            totalPages: Math.ceil(res?.count / postdata.limit),
            currentPage: current ? current : 1
          });
        } else {
          setOperationlogrepostlist([])

        }
      }, 100)

    }
    catch (err) {
      SetdataTable("operationlogrepostlist", 0, newcolumndata, [0]);
      console.log(err, 'err');
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      if (!showloader) {
        operationlogrepost();
      }
    }
    // eslint-disable-next-line
  }, [datefilter]);
  const filterbyname = (search) => {
    if (!showloader) {
      operationlogrepost(currentPage, limit, search);
      setstate({ ...state, currentPage: 1 });
    }
  };
  const getlimit = (limit) => {
    if (!showloader) {
      setLimit(limit);
      operationlogrepost(1, limit);
      setstate({ ...state, currentPage: 1 });
    }
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    if (!showloader) {
      setstate({ ...state, currentPage: current });
      operationlogrepost(current, "");
    }
  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "operationlogrepostlist_report",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    callcolumns();
  }, []);

  const selectOptions = [
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "300", value: "300" }
  ]

  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading mb-4">
                {t("Operation Log Report")}
              </h1>

              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />

                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("Search")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker align-items-center">
                  <DateRange setDatefilter={setDatefilter} />
                  <button className={printparmission ? "btn-style stock-take-inner-row-col-mid-header-right-btn mx-1" : "d-none"} type="button">
                    <img className="note" src={base_assets + "images/icons/printer-icon.png"} alt="" />
                  </button>
                </div>
              </div>

              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  id="operationlogrepostlist"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead
                    tablehead={operationLogTablethead}
                    tblName={"operationlogrepostlist_report"}
                  />
                  <tbody>
                    <>
                      {operationlogrepostlist.length ? operationlogrepostlist.map((result, key) => {
                        return (
                          <tr className="" key={key}>
                            <td className="fixed-side">{result?.date ? result?.date : "-"}  <span className="badge bg-danger rounded-1">{result?.country ? result?.country : ""}</span></td>
                            <td className="fixed-side">{result?.user ? result?.user : "-"}</td>
                            <td className="fixed-side">{result?.Category ? result?.Category : "-"}</td>
                            <td className="fixed-side">{result?.action ? result?.action : "-"}</td>
                            <td className="fixed-side">{result?.Operation_Detail ? result?.Operation_Detail : "-"}</td>
                            <td className="fixed-side">{result?.device ? result?.device : "-"}</td>
                          </tr>
                        )
                      }) : !showloader &&
                      <tr>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"> {t("No Data Available In Table")}</td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                      </tr>
                      }
                    </>

                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {showloader && <TableLoader />}
              </div>
            </div>
          </div>
        </div>
        {operationlogrepostlist.length > 0 ? (
          <Pagination
            total={totalPages}
            current={currentPage}
            pagination={(crPage) => handlePaginations(crPage)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default OperationLogReport;