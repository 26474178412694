import React, { useEffect, useState } from 'react';
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Tablehead } from '../../../components/common/modules/Tablehead';
import { posPark_head } from '../../../components/common/Tablehead';
import { _Api } from '../../common/api/_call';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { store } from '../../../source/index'
const SaleHistory = () => {
  const { t } = useTranslation();
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [TransactionDate, setTransactionDate] = useState(new Date())
  const [, setfilterby] = useState('');
  const [newcolumndata, setnewcolumndata] = useState(posPark_head)
  const [filtername, setFiltername] = useState('')
  const [parklist, setParklist] = useState()
  const [deleteid, setDeleteid] = useState()
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }

  useEffect(() => {
    callcolumns()
  }, [])
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_pos_parktable',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getparklist = async () => {
    UnSetdataTable('sale-history-table')
    try {
      const posdata = {
        search: filtername,
      }
      let res = await _Api(posdata, `api/v1/POS/park/getParkList`)
      if (res.code === 200) {
        SetdataTable('sale-history-table', '0', newcolumndata, [1])
        setParklist(res?.data)
      } else {
        SetdataTable('sale-history-table', '0', newcolumndata, [1])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const deletepark = async (park_id) => {
    try {
      const posdata = {
        park_id: park_id,
      }
      let res = await _Api(posdata, `api/v1/POS/park/removePark`)
      if (res.code === 200) {
        setDeleteid()
        getparklist()
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const filterbyname = () => {
    if (filtername) {
      getparklist()
    } else {
      getparklist()
    }
  }
  useEffect(() => {
    getparklist()
    // eslint-disable-next-line
  }, [TransactionDate])

  const [isActive, setIsActive] = useState(false)
  const deleteModal = (id) => {
    setDeleteid(id)
    setIsActive((current) => !current)
  }

  return (
    <div
      className="modal fade sale-history-modal "
      id="sale-history-Modal"
      tabIndex="-1"
      aria-labelledby="sale-history-Modal-Label"
      aria-hidden="true"
      data-bs-backdrop="false"
    >
      <div className="modal-dialog modal-dialog-centered sale-history-modal-dialog">
        <div className="modal-content sale-history-modal-content">
          <div className="modal-body sale-history-modal-body p-0">
            <h1
              className="modal-title sale-history-modal-title"
              id="exampleModalLabel"
            >
              {t("Sale History")}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                className="sale-history-btn-img"
                src={base_assets + 'images/pos/close-btn.png'}
                alt=""
              />
            </button>

            <div className="sale-history-modal-header d-flex justify-content-between">
              <div className="com-search-area com-search-area-large ">
                <input
                  type="text"
                  placeholder="Search"
                  onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                  onChange={(e) => setFiltername(e.target.value)}
                />
                <img
                  onClick={filterbyname}
                  className="com-search-area-img"
                  src={base_assets + 'images/pos/icons/magnifying-glass.png'}
                  alt=""
                />
              </div>

              <div className="customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date">
                <DatePicker
                  className="placeholder-up-input-field"
                  selected={TransactionDate}
                  onChange={selectTransactionDate}
                  name="TransactionDate"
                  dateFormat={OrgSettings?.date_picker}
                  autoComplete='none'
                />
                <img
                  className="placeholder-up-input-field-calender"
                  src={base_assets + 'images/icons/calendar-green.svg'}
                  alt=""
                />
              </div>
            </div>
            <div className="main-body-main-table-wrap withdropdown salhistory" id="table-scroll">
              <table
                id="sale-history-table"
                className="common-table first-row-gray w-100"
              >
                <Tablehead
                  tablehead={posPark_head}
                  tblName={'tbl_pos_parktable'}
                  setfilterby={setfilterby}
                />
                <tbody>
                  <>
                    {parklist?.length
                      ? parklist.map((result, parkkey) => {
                        return (
                          <tr key={result?.id}>
                            <td>{parkkey + 1}</td>
                            <td>{result?.date}</td>
                            <td>{result?.SO_no}</td>
                            <td>{result?.customer_name}</td>
                            <td>{result?.qty}</td>
                            <td>{result?.amount}</td>
                            <td className='d-flex justify-content-center'>
                              {result?.status?.is_park === 1 ? (
                                <label className="status-tab">{t("PARK")}</label>
                              ) : result?.status?.is_park === 2 ? (
                                <label className="status-tab status-approved">
                                  {t("Sold Out")}
                                </label>
                              ) : (
                                <label className="status-tab status-canceled">
                                  {t("Expire")}
                                </label>
                              )}
                            </td>
                            <td>
                              <div className="customer-table-dropdown dropdown">
                                <div className="position-relative customer-table-dropdown-toggle-wrap">
                                  <button
                                    className="btn-style dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src={
                                        base_assets +
                                        'images/icons/ellipsis-circular.png'
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    {result?.status?.is_park === 1 ? (
                                      <li>
                                        <Link
                                          to="/app/pos/catalog/CollectionList"
                                          state={{ parkid: result?.id }}
                                          className="dropdown-item img-btn-hover"
                                        >
                                          <div className="dropdown-menu-img">
                                            <img
                                              className="img-btn-hover-img-1"
                                              src={
                                                base_assets +
                                                'images/pos/icons/recall.png'
                                              }
                                              alt=""
                                            />
                                            <img
                                              className="img-btn-hover-img-2"
                                              src={
                                                base_assets +
                                                'images/pos/icons/recall-w.png'
                                              }
                                              alt=""
                                            />
                                          </div>
                                          {t("Recall")}
                                        </Link>
                                      </li>
                                    ) : (
                                      ''
                                    )}
                                    <li onClick={() => deleteModal(result?.id)}>
                                      <Link className="dropdown-item img-btn-hover">
                                        <div className="dropdown-menu-img">
                                          <img
                                            className="img-btn-hover-img-1"
                                            src={
                                              base_assets +
                                              'images/pos/icons/i5.png'
                                            }
                                            alt=""
                                          />
                                          <img
                                            className="img-btn-hover-img-2"
                                            src={
                                              base_assets +
                                              'images/pos/icons/i5w.png'
                                            }
                                            alt=""
                                          />
                                        </div>
                                        {t("Delete")}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                      : ''}
                  </>
                </tbody>
              </table>
            </div>
          </div>

          <div
            className={
              isActive
                ? 'modal fade common-modal common-modal-danger d-block show'
                : 'modal fade common-modal common-modal-danger'
            }
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <img
                    className="common-modal-exclamation-img"
                    src={base_assets + 'images/icons/exclamation-mark-red.png'}
                    alt=""
                  />
                  <p className="modal-content-text">
                    {t("Do you want to delete the row.")}
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn modal-content-no"
                    data-bs-dismiss="modal"
                    onClick={deleteModal}
                  >
                    {t("No")}
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn modal-content-yes"
                    onClick={() => {
                      deletepark(deleteid)
                      deleteModal()
                    }}
                  >
                    {t("Yes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SaleHistory;
