
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Report.scss";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { Reportmenupos } from "./Reportmenupos";
import { toFormatPrice } from '../common/helpers/function'
import SocialModal from "../../components/common/modules/SocialModal/SocialModal";
import { loading, Updateloading } from "../common/helpers/toastify";
import { Daterangepos } from "../common/Daterangepos";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { posdailysalereport_head } from "../../components/common/Tablehead";
import { _Api } from "../common/api/_call";
import TableModal from "../common/Modals/tableColumnPos";
import { useTranslation } from 'react-i18next';
import { Pagination } from "../common/Pagination";
import Select from "react-select";
import { useSelector } from 'react-redux';
import TableLoader from "../../admin/common/TableLoader";
import { parseDateWithFormat } from "../common/helpers/formatDate";
const Dailysalereport = () => {
    const { t } = useTranslation();
    const [datefilter, setDatefilter] = useState([]);
    const [newcolumndata, setnewcolumndata] = useState(posdailysalereport_head);
    const showseqs = 2;
    const [socialurl, setSocialurl] = useState();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [filtername, setFiltername] = useState("");
    const [dailysaledata, setDailysaledata] = useState([])
    const [uppercount, setUppercount] = useState()
    const [lowercount, setLowercount] = useState()
    const [shareloader, setShareloader] = useState(false);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState()
    const [dataloder, setDataloder] = useState(true)
    const [saletype, setSaletype] = useState("sales")
    const [printparmission, setPrintparmission] = useState(0)
    const [editparmission, setEditparmission] = useState(0)
    const [tapstatus, setTapstatus] = useState(true);
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

    const selectOptions = [
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 }
    ]

    const callcolumns = async () => {
        try {
            const postdata = {
                name: "report_dailysalereportchange_tbl",
            };
            let columnhead = await _Api(
                postdata,
                `api/v1/Inventory/common/getColumnHeads`
            );
            if (columnhead.code === 200) {
                setnewcolumndata(columnhead?.data?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        callcolumns();

    }, []);
    const getsharedallysallreport = async () => {
        setShareloader(true);
        try {
            const postdata = {
                "search": filtername,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/dailySalesPrint/Print`);

            if (res.code === 200) {
                setSocialurl(res?.link);
                setShareloader(false);
            } else {
                setShareloader(false);
            }
        }
        catch (err) {
            console.log(err, 'err');
        }

    }
    const getprintdallysalerreport = async () => {

        let GetloadingID = loading();
        try {
            const postdata = {
                "search": filtername,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/dailySalesPrint/Print`);

            if (res.code === 200) {


                setTimeout(() => {
                    const exportLinkElement = document.createElement('a');
                    exportLinkElement.hidden = true;
                    exportLinkElement.download = res?.name ? res?.name : "report.pdf";
                    exportLinkElement.href = res?.link;
                    exportLinkElement.text = "downloading...";
                    exportLinkElement.setAttribute('target', '_blank');
                    document.body.appendChild(exportLinkElement);
                    exportLinkElement.click();
                    exportLinkElement.remove();
                    Updateloading(t, GetloadingID, t("download successfully"));

                }, 50)
                setSocialurl(res?.link);
            } else {
                Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, 'err');
            Updateloading(t, GetloadingID, err, t('error'));
        }

    }
    const getlimit = (limit) => {
        setLimit(limit);
        getdailysaledata(1, limit);
        setstate({ ...state, currentPage: 1 });

    };
    const [state, setstate] = useState({
        totalPages: 20,
        currentPage: 1,
    });

    const { totalPages, currentPage } = state;
    const handlePaginations = (current) => {
        setstate({ ...state, currentPage: current });
        getdailysaledata(current, "");

    };
    const getdailysaledata = useCallback(async (current, firstlimit, type, search) => {
        try {
            setTapstatus(false);
            setDataloder(true)
            const tableIdMap = {
                sales: "com-data-area_table",
                exchange_order: "com-data-area_table-exchange",
                refund_order: "com-data-area_table-refund",
                deposit: "com-data-area_table-Deposit",
                gift_card_order: "com-data-area_table-Gift-Card",
                custom_order: "com-data-area_table-Custom-Order",
                repair_order: "com-data-area_table-Repair-Order"
            };
            const tableId = tableIdMap[type];
            setTimeout(async () => {
                UnSetdataTable("com-data-area_table");
                UnSetdataTable("com-data-area_table-exchange");
                UnSetdataTable("com-data-area_table-refund");
                UnSetdataTable("com-data-area_table-Deposit");
                UnSetdataTable("com-data-area_table-Gift-Card");
                UnSetdataTable("com-data-area_table-Custom-Order");
                UnSetdataTable("com-data-area_table-Repair-Order");
            }, 100)
            const postdata = {
                "search": search ? search : "",
                "limit": firstlimit ? firstlimit : limit,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : "",
                "type": type ? type : "sales",
                "skip": current ? (current - 1) * limit : skip === 0 ? skip : 0
            }
            let res = await _Api(postdata, `api/v1/POS/report/dailySales`)
            if (res?.code === 200) {
                setTapstatus(true);
                const sortedData = [...res?.data].sort((a, b) => parseDateWithFormat(b.date) - parseDateWithFormat(a.date));
                setDailysaledata(sortedData)
                setstate({
                    ...state,
                    totalPages: Math.ceil(res?.count / postdata.limit),
                    currentPage: current ? current : 1
                });
                setUppercount(res?.uppercount)
                setLowercount(res?.lowercount)
                setTimeout(() => {
                    setDataloder(false)
                }, 300)

                setTimeout(async () => {
                    if (tableId) {
                        SetdataTable(tableId, 5, newcolumndata, [0]);
                    }
                }, 300)
            }

            else {
                if (tableId) {
                    SetdataTable(tableId, 5, newcolumndata, [0]);
                }
                setTimeout(() => {
                    setDataloder(false)
                }, 300)
                setTapstatus(true);
            }
        }
        catch (err) {
            console.log(err, "err");
        }
    }, [datefilter, limit, newcolumndata, skip, state])
    const downloadexclsheet = async () => {
        let GetloadingID = loading()
        try {
            let postdata = {
                "type": saletype ? saletype : "sales",
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            }
            let res = await _Api(postdata, `api/v1/POS/dailySalesPrint/export`)
            if (res?.code === 200) {
                window.open(res?.filexlsx, '_blank', 'noreferrer')
                Updateloading(t, GetloadingID, (res?.message))
            } else {
                Updateloading(t, GetloadingID, (res?.message), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const downloadexclsheeterp = async () => {
        let GetloadingID = loading()
        try {
            let postdata = {
                "type": saletype ? saletype : "sales",
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            }
            let res = await _Api(postdata, `api/v1/pos/dailySalesPrint/exportforERP`)
            if (res?.code === 200) {
                window.open(res?.filexlsx, '_blank', 'noreferrer')
                Updateloading(t, GetloadingID, (res?.message))
            } else {
                Updateloading(t, GetloadingID, (res?.message), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const filterbyname = (search) => {
        getdailysaledata(currentPage, limit, saletype, search);
        setstate({ ...state, currentPage: 1 });
    };

    useEffect(() => {
        if (parmissiondatashow?.pos_reports?.View)
            setPrintparmission(parmissiondatashow?.pos_reports?.Print)
        setEditparmission(parmissiondatashow?.pos_reports?.Edit);
    }, [parmissiondatashow?.pos_reports?.Edit, parmissiondatashow?.pos_reports?.Print, parmissiondatashow?.pos_reports?.View]);

    useEffect(() => {
        if (datefilter[0] && datefilter[1]) {           
            getdailysaledata(1, 100, "sales");
        }
       // eslint-disable-next-line
    }, [datefilter]);
    console.log(datefilter,"datefilterdatefilterdatefilterdatefilterdatefilter");
    return (
        <>
            <div className="main-body">
                <div className="main-body-row">
                    <div className="main-body-row-left-col p-0 w-100">
                        <div className="catalog-area bg-white page-menu-block Dailysalereport">
                            <div className="Dailysalereport-header">
                                <div className="Dailysalereport-header-left">
                                    <h3 className='common-navigation-header-left-heading Dailysalereport-head'> <Link to="" className='gray-mid text-decoration-none Dailysalereport-head-report'>{t("REPORT")}</Link> <img className="Dailysalereport-head-arrow mx-3" src={base_assets + "images/pos/report/arrow-right.png"} alt="" /><span className="Dailysalereport-head-pagename">{t("Daily sale report")}</span> </h3>
                                </div>
                                <div className="Dailysalereport-header-reportdetail">
                                    <div className="Dailysalereport-header-reportdetail-box">
                                        <div className="Dailysalereport-header-reportdetail-box-inner">
                                            <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/stock-img.png"} alt=""></img>
                                        </div>
                                        <div className="Dailysalereport-header-reportdetail-box-text">
                                            <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Qty")}</span>
                                            <span className="Dailysalereport-header-reportdetail-box-text-data light-blue-text">
                                                {uppercount?.qty ? uppercount?.qty : 0}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box">
                                        <div className="Dailysalereport-header-reportdetail-box-inner">
                                            <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/sku-img.png"} alt=""></img>
                                        </div>
                                        <div className="Dailysalereport-header-reportdetail-box-text">
                                            <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("SKU")}</span>
                                            <span className="Dailysalereport-header-reportdetail-box-text-data yellow-text">
                                                {uppercount?.SKU ? uppercount?.SKU : 0}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box">
                                        <div className="Dailysalereport-header-reportdetail-box-inner">
                                            <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/customer-img.png"} alt=""></img>
                                        </div>
                                        <div className="Dailysalereport-header-reportdetail-box-text">
                                            <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Customer")}</span>
                                            <span className="Dailysalereport-header-reportdetail-box-text-data lightgray">
                                                {uppercount?.customer ? uppercount?.customer : 0}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box">
                                        <div className="Dailysalereport-header-reportdetail-box-inner">
                                            <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/value-img.png"} alt=""></img>
                                        </div>
                                        <div className="Dailysalereport-header-reportdetail-box-text">
                                            <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Value")}</span>
                                            <span className="Dailysalereport-header-reportdetail-box-text-data maingreen">
                                                {uppercount?.netSales ? toFormatPrice(uppercount?.netSales, { addSymbol: true }) : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Dailysalereport-content-area">
                                <div className="Dailysalereport-content-area-header">
                                    <div className="Dailysalereport-content-area-header-inner">
                                        <Reportmenupos
                                            Reportname={"Daily sale report"}
                                        />
                                        <Select
                                            className="limit-select short mx-3"
                                            options={selectOptions}
                                            value={{ label: limit, value: limit }}
                                            onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                                        />
                                        <div className="date"> <Daterangepos setDatefilter={setDatefilter} /></div>
                                    </div>
                                    <div className="catalog-area-header-area-control-right">
                                        <div className="com-search-area">
                                            <input type="text" placeholder={t("Search  by Ref number, voucher type, ...")}
                                                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                                                onBlur={(e) => { filterbyname(e.target.value); setFiltername(e.target.value) }}
                                            />
                                            <img onClick={() => filterbyname()} className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />

                                        </div>
                                        <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                                            <button className='btn-style' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={base_assets + 'images/icons/ellipsis-circular.png'} alt="" /></button>
                                            <ul className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li onClick={() => getprintdallysalerreport()} className={printparmission ? "" : "d-none"}>
                                                    <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                        <div className="dropdown-menu-img">
                                                            <img src={base_assets + "images/pos/report/print-icon.png"} alt="" />
                                                        </div>
                                                        {t("Print")}</Link>
                                                </li>
                                                <li onClick={() => downloadexclsheet()}>
                                                    <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                        <div className="dropdown-menu-img">
                                                            <img src={base_assets + "images/pos/report/export-icon.png"} alt="" />
                                                        </div>
                                                        {t("Export")}</Link>
                                                </li>
                                                <li onClick={() => downloadexclsheeterp()}>
                                                    <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                        <div className="dropdown-menu-img">
                                                            <img src={base_assets + "images/pos/report/export-icon.png"} alt="" />
                                                        </div>
                                                        {t("Export ERP")}</Link>
                                                </li>
                                                <li data-bs-toggle="modal" data-bs-target="#socialModal" className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" onClick={() => getsharedallysallreport()}>
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/share-icon.png"} alt="" />
                                                    </div>
                                                    {t("Share")}
                                                </li>
                                                <li data-bs-toggle="modal" data-bs-target="#columnModal">
                                                    <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                        <div className="dropdown-menu-img">
                                                            <img src={base_assets + "images/icons/column-icon.png"} alt="" />
                                                        </div>
                                                        {t("Column")}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="Dailysalereport-content-area-main">
                                    {
                                        dataloder ?
                                            <ul className="Dailysalereport-content-area-navbar nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item Dailysalereport-content-area-navbar-item">
                                                    <button className="nav-link active Dailysalereport-content-area-navbar-item-link">
                                                        {t("Sale")}</button>
                                                </li>
                                                <li className="nav-item Dailysalereport-content-area-navbar-item">
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link">
                                                        {t("Exchange")}</button>
                                                </li>
                                                <li className="nav-item Dailysalereport-content-area-navbar-item">
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link">
                                                        {t("Refund")}</button>
                                                </li>
                                                <li className="nav-item Dailysalereport-content-area-navbar-item">
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link">
                                                        {t("Deposit")}</button>
                                                </li>
                                                <li className="nav-item Dailysalereport-content-area-navbar-item">
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link">
                                                        {t("Gift Card")}</button>
                                                </li>
                                                <li className="nav-item Dailysalereport-content-area-navbar-item">
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" >{t("Custom Order")}</button>
                                                </li>
                                                <li className="nav-item Dailysalereport-content-area-navbar-item">
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" type="button">{t("Repair Order")}</button>
                                                </li>
                                            </ul>
                                            :
                                            <ul className="Dailysalereport-content-area-navbar nav-tabs" id="myTab" role="tablist">
                                                <li className={tapstatus ? "nav-item Dailysalereport-content-area-navbar-item" : "nav-item Dailysalereport-content-area-navbar-item pointer-none"} role="presentation" onClick={(e) => { getdailysaledata("", "", "sales"); setSaletype("sales"); setSkip(0) }}>
                                                    <button className="nav-link active Dailysalereport-content-area-navbar-item-link" id="Sale-tab" data-bs-toggle="tab" data-bs-target="#Sale" type="button" role="tab" aria-controls="Sale" aria-selected="true">{t("Sale")}</button>
                                                </li>
                                                <li className={tapstatus ? "nav-item Dailysalereport-content-area-navbar-item" : "nav-item Dailysalereport-content-area-navbar-item pointer-none"} role="presentation" onClick={(e) => {
                                                    getdailysaledata("", "", "exchange_order");
                                                    setSaletype("exchange_order"); setSkip(0)
                                                }}>
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" id="Exchange-tab" data-bs-toggle="tab" data-bs-target="#Exchange" type="button" role="tab" aria-controls="Exchange" aria-selected="false">{t("Exchange")}</button>
                                                </li>
                                                <li className={tapstatus ? "nav-item Dailysalereport-content-area-navbar-item" : "nav-item Dailysalereport-content-area-navbar-item pointer-none"} role="presentation" onClick={(e) => {
                                                    getdailysaledata("", "", "refund_order");
                                                    setSaletype("refund_order"); setSkip(0)
                                                }}>
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" id="Refund-tab" data-bs-toggle="tab" data-bs-target="#Refund" type="button" role="tab" aria-controls="Refund" aria-selected="false">{t("Refund")}</button>
                                                </li>
                                                <li className={tapstatus ? "nav-item Dailysalereport-content-area-navbar-item" : "nav-item Dailysalereport-content-area-navbar-item pointer-none"} role="presentation" onClick={(e) => { getdailysaledata("", "", "deposit"); setSaletype("deposit"); setSkip(0) }}>
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" id="Deposit-tab" data-bs-toggle="tab" data-bs-target="#Deposit" type="button" role="tab" aria-controls="Deposit" aria-selected="false">{t("Deposit")}</button>
                                                </li>
                                                <li className={tapstatus ? "nav-item Dailysalereport-content-area-navbar-item" : "nav-item Dailysalereport-content-area-navbar-item pointer-none"} role="presentation" onClick={(e) => {
                                                    getdailysaledata("", "", "gift_card_order");
                                                    setSaletype("gift_card_order"); setSkip(0)
                                                }}>
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" id="Gift-Card-tab" data-bs-toggle="tab" data-bs-target="#Gift-Card" type="button" role="tab" aria-controls="Gift-Card" aria-selected="false">{t("Gift Card")}</button>
                                                </li>
                                                <li className={tapstatus ? "nav-item Dailysalereport-content-area-navbar-item" : "nav-item Dailysalereport-content-area-navbar-item pointer-none"} role="presentation" onClick={(e) => {
                                                    getdailysaledata("", "", "custom_order");
                                                    setSaletype("custom_order"); setSkip(0)
                                                }}>
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" id="Custom-Order-tab" data-bs-toggle="tab" data-bs-target="#Custom-Order" type="button" role="tab" aria-controls="Custom-Order" aria-selected="false">{t("Custom Order")}</button>
                                                </li>
                                                <li className={tapstatus ? "nav-item Dailysalereport-content-area-navbar-item" : "nav-item Dailysalereport-content-area-navbar-item pointer-none"} role="presentation" onClick={(e) => {
                                                    getdailysaledata("", "", "repair_order");
                                                    setSaletype("repair_order"); setSkip(0)
                                                }}>
                                                    <button className="nav-link Dailysalereport-content-area-navbar-item-link" id="Repair-Order-tab" data-bs-toggle="tab" data-bs-target="#Repair-Order" type="button" role="tab" aria-controls="Repair-Order" aria-selected="false">{t("Repair Order")}</button>
                                                </li>
                                            </ul>}
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="Sale" role="tabpanel" aria-labelledby="Sale-tab">
                                            <div className="main-body-main-table-wrap Dailysalereport-tabs-table position-relative" id="table-scroll">
                                                <table
                                                    id='com-data-area_table'
                                                    className="common-table first-row-gray w-100 Dailysalereport-table-main"
                                                >
                                                    <Tablehead
                                                        tablehead={posdailysalereport_head}
                                                        tblname={"report_dailysalereportchange_tbl"} />
                                                    <tbody>
                                                        <>
                                                            {dailysaledata?.length ? dailysaledata.map((result, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td> {result.date ? result?.date : "-"}</td>
                                                                        <td>{result.voucher_type ? result?.voucher_type : "-"}</td>
                                                                        <td className="link-color">
                                                                            {result?.order_type === "custom_order" ?
                                                                                result?.soldOutStatus === "YES" ?
                                                                                    <>
                                                                                        {result?.ref_no ? result?.ref_no : "-"}
                                                                                    </> :
                                                                                    <Link

                                                                                        className="link-color text-decoration-none" to={editparmission ? "/app/pos/custom/createYourOwnSetting" : ""}
                                                                                        state={{
                                                                                            order_id: result?.order_id,
                                                                                            order_edit: true,
                                                                                            order_type: result?.order_type,

                                                                                        }}>
                                                                                        {result.ref_no ? result?.ref_no : "-"}</Link> :

                                                                                result?.order_type === "pos_order" ?

                                                                                    <Link className="link-color text-decoration-none"
                                                                                        to={editparmission ? "/app/pos/catalog/CollectionList" : ""}
                                                                                        state={{
                                                                                            order_id: result?.order_id,
                                                                                            order_edit: true,
                                                                                            order_type: result?.order_type,
                                                                                            service_labour_enable: ""

                                                                                        }}>


                                                                                        {result.ref_no ? result?.ref_no : "-"}</Link> : result?.order_type === "exchange_order" ?
                                                                                        <Link className="link-color text-decoration-none"
                                                                                            to={editparmission ? "/app/pos/exchange" : ""}
                                                                                            state={{
                                                                                                order_id: result?.order_id,
                                                                                                order_edit: true,
                                                                                                order_type: result?.order_type
                                                                                            }}>
                                                                                            {result?.ref_no ? result?.ref_no : "-"}</Link> : result?.order_type === "gift_card_order" ? <Link
                                                                                                to={editparmission ? "/app/pos/giftCard" : ""}
                                                                                                className="text-decoration-none"
                                                                                                state={{
                                                                                                    order_id: result?.order_id,
                                                                                                    order_edit: true,
                                                                                                    order_type: result?.order_type
                                                                                                }}
                                                                                            >
                                                                                                {result?.ref_no ? result?.ref_no : "-"}
                                                                                            </Link> : result?.order_type === "repair_order" ? <Link
                                                                                                to={editparmission ? "/app/pos/repair/repairOrderProcess" : ""} className="text-decoration-none"
                                                                                                state={{
                                                                                                    order_id: result?.order_id,
                                                                                                    order_edit: true,
                                                                                                    order_type: result?.order_type
                                                                                                }}
                                                                                            >
                                                                                                {result?.ref_no ? result?.ref_no : "-"}
                                                                                            </Link> : result?.order_type === "deposit" ? <Link to={editparmission ? "/app/pos/receive/deposit" : ""} className="text-decoration-none"
                                                                                                state={{
                                                                                                    order_id: result?.order_id,
                                                                                                    order_edit: true,
                                                                                                    order_type: result?.order_type
                                                                                                }}
                                                                                            >
                                                                                                {result?.ref_no ? result?.ref_no : "-"}
                                                                                            </Link> : result?.order_type === "refund_order" ? <Link
                                                                                                to={editparmission ? "/app/pos/refund" : ""} className="text-decoration-none"
                                                                                                state={{
                                                                                                    order_id: result?.order_id,
                                                                                                    order_edit: true,
                                                                                                    order_type: result?.order_type
                                                                                                }}
                                                                                            >
                                                                                                {result?.ref_no ? result?.ref_no : "-"}
                                                                                            </Link> : result?.order_type === "mix_and_match" ? <Link
                                                                                                to={editparmission ? "/app/pos/custom/mixAndMatchProduct" : ""} className="text-decoration-none"
                                                                                                state={{
                                                                                                    order_id: result?.order_id,
                                                                                                    order_edit: true,
                                                                                                    order_type: result?.order_type,
                                                                                                    fromring: showseqs ? true : false,
                                                                                                    daimond_id: result?.editOrder?.daimond_id,
                                                                                                    product_id: result?.editOrder?.product_id,
                                                                                                    customer_id: result?.editOrder?.customer_id,
                                                                                                    type: result?.editOrder?.type,
                                                                                                }}
                                                                                            >
                                                                                                {result?.ref_no ? result?.ref_no : "-"}
                                                                                            </Link> : result?.ref_no
                                                                            }

                                                                        </td>
                                                                        <td>{result.customer_name ? result?.customer_name : "-"}</td>
                                                                        <td>{result.location_name ? result?.location_name : "-"}</td>
                                                                        <td className={result?.order_type === "exchange_order" || result?.order_type === "refund_order" ? "danger-text" : ""} >{result.qty ? result?.qty : 0}</td>
                                                                        {result?.order_type === "exchange_order" || result?.order_type === "refund_order" ?
                                                                            <td className="danger-text">   ({result.grossSales ?
                                                                                toFormatPrice(result?.grossSales, { addSymbol: true }) : 0})</td> :
                                                                            <td>   {result.grossSales ? toFormatPrice(result?.grossSales, { addSymbol: true }) : 0}</td>}
                                                                        <td className="danger-text">
                                                                            {result.hidden_discount ? toFormatPrice(result?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                                        {result?.order_type === "exchange_order" || result?.order_type === "refund_order" ? <td className="danger-text">({result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0})</td> :
                                                                            <td>{result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0}</td>}

                                                                        <td className="danger-text">
                                                                            {result.discount ? toFormatPrice(result?.discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.labour ? toFormatPrice(result?.labour, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.shipping ? toFormatPrice(result?.shipping, { addSymbol: true }) : 0}</td>
                                                                        {result?.order_type === "exchange_order" || result?.order_type === "refund_order" ?
                                                                            <td className="danger-text">
                                                                                ({result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0})
                                                                            </td> : <td>{result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0}</td>
                                                                        }


                                                                        {result?.order_type === "exchange_order" || result?.order_type === "refund_order" ?
                                                                            <td className="danger-text">({result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0})</td> : <td>{result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0}</td>}
                                                                        {result?.order_type === "exchange_order" || result?.order_type === "refund_order" ?
                                                                            <td className="danger-text">({result?.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0})</td> :
                                                                            <td>{result.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0}</td>}

                                                                         
                                                                            {result?.order_type === "exchange_order" ||  result?.order_type === "refund_order" ? <td className="danger-text">({result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0})</td>
                                                                            : <td>{result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0}</td>}


                                                                        {result?.order_type === "refund_order" ? <td className="danger-text">({result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0})</td>
                                                                            : <td>{result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0}</td>}

                                                                        <td>{result.credit_amount ? toFormatPrice(result?.credit_amount, { addSymbol: true }) : 0}</td>

                                                                        {result?.order_type === "refund_order" ? <td className="danger-text">({result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0})</td>
                                                                            : <td>{result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0}</td>}
                                                                        <td>{result.balanceDue ? toFormatPrice(result?.balanceDue, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.count ? result.count : 0}</td>
                                                                        <td>{result.loyality_points ? toFormatPrice(result?.loyality_points, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.sales_person ? result?.sales_person : "-"}</td>
                                                                        <td>{result.user ? result?.user : "-"}</td>
                                                                        <td>
                                                                            {result?.printURL === "" ? "-"
                                                                                : <div onClick={() => {
                                                                                    if (result?.printURL) {
                                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                                    }
                                                                                }}><img
                                                                                        className="pdf-image cursor-pointer"
                                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                                        alt=""
                                                                                    /></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{lowercount?.qty ? lowercount?.qty : 0}</td>
                                                            <td>{lowercount?.grossSales ? toFormatPrice(lowercount?.grossSales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">
                                                                {lowercount?.hidden_discount ? toFormatPrice(lowercount?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.total_Sales ? toFormatPrice(lowercount?.total_Sales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">{lowercount?.discount ? toFormatPrice(lowercount?.discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.labour ? toFormatPrice(lowercount?.labour, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.shipping ? toFormatPrice(lowercount?.shipping, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.subTotal ? toFormatPrice(lowercount?.subTotal, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.taxAmount ? toFormatPrice(lowercount?.taxAmount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.netSales ? toFormatPrice(lowercount?.netSales, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_notes ? toFormatPrice(lowercount?.credit_notes, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.cash ? toFormatPrice(lowercount?.cash, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_amount ? toFormatPrice(lowercount?.credit_amount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.bank ? toFormatPrice(lowercount?.bank, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.balanceDue ? toFormatPrice(lowercount?.balanceDue, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.count ? lowercount?.count : 0}</td>
                                                            <td>{lowercount?.loyality_points ? toFormatPrice(lowercount?.loyality_points, { addSymbol: true }) : 0}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {dataloder && <TableLoader />}
                                            </div>

                                        </div>
                                        <div className="tab-pane fade" id="Exchange" role="tabpanel" aria-labelledby="Exchange-tab">
                                            <div className="main-body-main-table-wrap Dailysalereport-tabs-table position-relative" id="table-scroll">
                                                <table id='com-data-area_table-exchange' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                                    <Tablehead tablehead={posdailysalereport_head} tblname={"report_dailysalereportchange_tbl"} />
                                                    <tbody>
                                                        <>
                                                            {dailysaledata?.length ? dailysaledata.map((result, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td> {result.date ? result?.date : "-"}</td>
                                                                        <td>{result.voucher_type ? result?.voucher_type : "-"}</td>
                                                                        <td className="link-color">
                                                                            <Link
                                                                                to={editparmission ? "/app/pos/exchange" : ""}
                                                                                className="text-decoration-none"
                                                                                state={{
                                                                                    order_id: result?.order_id,
                                                                                    order_edit: true,
                                                                                    order_type: result?.order_type
                                                                                }}
                                                                            >
                                                                                {result.ref_no ? result?.ref_no : "-"}
                                                                            </Link></td>
                                                                        <td>{result.customer_name ? result?.customer_name : "-"}</td>
                                                                        <td>{result.location_name ? result?.location_name : "-"}</td>
                                                                        <td>{result.qty ? result?.qty : 0}</td>
                                                                        <td>{result.grossSales ? toFormatPrice(result?.grossSales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.hidden_discount ? toFormatPrice(result?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.discount ? toFormatPrice(result?.discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.labour ? toFormatPrice(result?.labour, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.shipping ? toFormatPrice(result?.shipping, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">{result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_amount ? toFormatPrice(result?.credit_amount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.balanceDue ? toFormatPrice(result?.balanceDue, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.count ? result.count : 0}</td>
                                                                        <td>{result.loyality_points ? toFormatPrice(result?.loyality_points, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.sales_person ? result?.sales_person : "-"}</td>
                                                                        <td>{result.user ? result?.user : "-"}</td>
                                                                        <td>
                                                                            {result?.printURL === "" ? "-"
                                                                                : <div onClick={() => {
                                                                                    if (result?.printURL) {
                                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                                    }
                                                                                }}><img
                                                                                        className="pdf-image cursor-pointer"
                                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                                        alt=""
                                                                                    /></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{lowercount?.grossSales ? toFormatPrice(lowercount?.grossSales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">
                                                                {lowercount?.hidden_discount ? toFormatPrice(lowercount?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.total_Sales ? toFormatPrice(lowercount?.total_Sales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">{lowercount?.discount ? toFormatPrice(lowercount?.discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.labour ? toFormatPrice(lowercount?.labour, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.shipping ? toFormatPrice(lowercount?.shipping, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.subTotal ? toFormatPrice(lowercount?.subTotal, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.taxAmount ? toFormatPrice(lowercount?.taxAmount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.netSales ? toFormatPrice(lowercount?.netSales, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_notes ? toFormatPrice(lowercount?.credit_notes, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.cash ? toFormatPrice(lowercount?.cash, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_amount ? toFormatPrice(lowercount?.credit_amount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.bank ? toFormatPrice(lowercount?.bank, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.balanceDue ? toFormatPrice(lowercount?.balanceDue, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.count ? lowercount?.count : 0}</td>
                                                            <td>{lowercount?.loyality_points ? toFormatPrice(lowercount?.loyality_points, { addSymbol: true }) : 0}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>

                                                {dataloder && <TableLoader />}

                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Refund" role="tabpanel" aria-labelledby="Refund-tab">
                                            <div className="main-body-main-table-wrap Dailysalereport-tabs-table position-relative" id="table-scroll">
                                                <table id='com-data-area_table-refund' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                                    <Tablehead tablehead={posdailysalereport_head} tblname={"report_dailysalereportchange_tbl"} />
                                                    <tbody>
                                                        <>
                                                            {dailysaledata?.length ? dailysaledata.map((result, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td> {result.date ? result?.date : "-"}</td>
                                                                        <td>{result.voucher_type ? result?.voucher_type : "-"}</td>

                                                                        <td className="link-color">
                                                                            <Link
                                                                                to={editparmission ? "/app/pos/refund" : ""} className="text-decoration-none"
                                                                                state={{
                                                                                    order_id: result?.order_id,
                                                                                    order_edit: true,
                                                                                    order_type: result?.order_type
                                                                                }}
                                                                            >
                                                                                {result?.ref_no ? result?.ref_no : "-"}
                                                                            </Link> </td>
                                                                        <td>{result.customer_name ? result?.customer_name : "-"}</td>
                                                                        <td>{result.location_name ? result?.location_name : "-"}</td>
                                                                        <td>{result.qty ? result?.qty : 0}</td>
                                                                        <td>{result.grossSales ? toFormatPrice(result?.grossSales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.hidden_discount ? toFormatPrice(result?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.discount ? toFormatPrice(result?.discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.labour ? toFormatPrice(result?.labour, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.shipping ? toFormatPrice(result?.shipping, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">{result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_amount ? toFormatPrice(result?.credit_amount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.balanceDue ? toFormatPrice(result?.balanceDue, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.count ? result.count : 0}</td>
                                                                        <td>{result.loyality_points ? toFormatPrice(result?.loyality_points, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.sales_person ? result?.sales_person : "-"}</td>
                                                                        <td>{result.user ? result?.user : "-"}</td>
                                                                        <td>
                                                                            {result?.printURL === "" ? "-"
                                                                                : <div onClick={() => {
                                                                                    if (result?.printURL) {
                                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                                    }
                                                                                }}><img
                                                                                        className="pdf-image cursor-pointer"
                                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                                        alt=""
                                                                                    /></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{lowercount?.grossSales ? toFormatPrice(lowercount?.grossSales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">
                                                                {lowercount?.hidden_discount ? toFormatPrice(lowercount?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.total_Sales ? toFormatPrice(lowercount?.total_Sales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">{lowercount?.discount ? toFormatPrice(lowercount?.discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.labour ? toFormatPrice(lowercount?.labour, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.shipping ? toFormatPrice(lowercount?.shipping, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.subTotal ? toFormatPrice(lowercount?.subTotal, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.taxAmount ? toFormatPrice(lowercount?.taxAmount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.netSales ? toFormatPrice(lowercount?.netSales, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_notes ? toFormatPrice(lowercount?.credit_notes, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.cash ? toFormatPrice(lowercount?.cash, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_amount ? toFormatPrice(lowercount?.credit_amount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.bank ? toFormatPrice(lowercount?.bank, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.balanceDue ? toFormatPrice(lowercount?.balanceDue, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.count ? lowercount?.count : 0}</td>
                                                            <td>{lowercount?.loyality_points ? toFormatPrice(lowercount?.loyality_points, { addSymbol: true }) : 0}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {dataloder && <TableLoader />}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Deposit" role="tabpanel" aria-labelledby="Deposit-tab">
                                            <div className="main-body-main-table-wrap Dailysalereport-tabs-table position-relative" id="table-scroll">
                                                <table id='com-data-area_table-Deposit' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                                    <Tablehead tablehead={posdailysalereport_head} tblname={"report_dailysalereportchange_tbl"} />
                                                    <tbody>
                                                        <>
                                                            {dailysaledata?.length ? dailysaledata.map((result, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td> {result.date ? result?.date : "-"}</td>
                                                                        <td>{result.voucher_type ? result?.voucher_type : "-"}</td>
                                                                        <td className="link-color"><Link
                                                                            to={editparmission ? "/app/pos/receive/deposit" : ""}
                                                                            className="text-decoration-none"
                                                                            state={{
                                                                                order_id: result?.order_id,
                                                                                order_edit: true,
                                                                                order_type: result?.order_type
                                                                            }}
                                                                        >
                                                                            {result.ref_no ? result?.ref_no : "-"}
                                                                        </Link></td>
                                                                        <td>{result.customer_name ? result?.customer_name : "-"}</td>
                                                                        <td>{result.location_name ? result?.location_name : "-"}</td>
                                                                        <td>{result.qty ? result?.qty : 0}</td>
                                                                        <td>{result.grossSales ? toFormatPrice(result?.grossSales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.hidden_discount ? toFormatPrice(result?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.discount ? toFormatPrice(result?.discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.labour ? toFormatPrice(result?.labour, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.shipping ? toFormatPrice(result?.shipping, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_amount ? toFormatPrice(result?.credit_amount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.balanceDue ? toFormatPrice(result?.balanceDue, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.count ? result.count : 0}</td>
                                                                        <td>{result.loyality_points ? toFormatPrice(result?.loyality_points, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.sales_person ? result?.sales_person : "-"}</td>
                                                                        <td>{result.user ? result?.user : ""}</td>
                                                                        <td>
                                                                            {result?.printURL === "" ? "-"
                                                                                : <div onClick={() => {
                                                                                    if (result?.printURL) {
                                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                                    }
                                                                                }}><img
                                                                                        className="pdf-image cursor-pointer"
                                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                                        alt=""
                                                                                    /></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{lowercount?.grossSales ? toFormatPrice(lowercount?.grossSales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">
                                                                {lowercount?.hidden_discount ? toFormatPrice(lowercount?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.total_Sales ? toFormatPrice(lowercount?.total_Sales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">{lowercount?.discount ? toFormatPrice(lowercount?.discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.labour ? toFormatPrice(lowercount?.labour, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.shipping ? toFormatPrice(lowercount?.shipping, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.subTotal ? toFormatPrice(lowercount?.subTotal, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.taxAmount ? toFormatPrice(lowercount?.taxAmount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.netSales ? toFormatPrice(lowercount?.netSales, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_notes ? toFormatPrice(lowercount?.credit_notes, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.cash ? toFormatPrice(lowercount?.cash, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_amount ? toFormatPrice(lowercount?.credit_amount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.bank ? toFormatPrice(lowercount?.bank, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.balanceDue ? toFormatPrice(lowercount?.balanceDue, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.count ? lowercount?.count : 0}</td>
                                                            <td>{lowercount?.loyality_points ? toFormatPrice(lowercount?.loyality_points, { addSymbol: true }) : 0}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {dataloder && <TableLoader />}
                                            </div></div>
                                        <div className="tab-pane fade" id="Gift-Card" role="tabpanel" aria-labelledby="Gift-Card-tab">
                                            <div className="main-body-main-table-wrap Dailysalereport-tabs-table position-relative" id="table-scroll">
                                                <table id='com-data-area_table-Gift-Card' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                                    <Tablehead tablehead={posdailysalereport_head} tblname={"report_dailysalereportchange_tbl"} />
                                                    <tbody>
                                                        <>
                                                            {dailysaledata?.length ? dailysaledata.map((result, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td> {result.date ? result?.date : "-"}</td>
                                                                        <td>{result.voucher_type ? result?.voucher_type : "-"}</td>
                                                                        <td className="link-color"> <Link
                                                                            to={editparmission ? "/app/pos/giftCard" : ""}
                                                                            className="text-decoration-none"
                                                                            state={{
                                                                                order_id: result?.order_id,
                                                                                order_edit: true,
                                                                                order_type: result?.order_type
                                                                            }}
                                                                        >
                                                                            {result?.ref_no ? result?.ref_no : "-"}
                                                                        </Link></td>
                                                                        <td>{result.customer_name ? result?.customer_name : "-"}</td>
                                                                        <td>{result.location_name ? result?.location_name : "-"}</td>
                                                                        <td>{result.qty ? result?.qty : 0}</td>
                                                                        <td>{result.grossSales ? toFormatPrice(result?.grossSales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.hidden_discount ? toFormatPrice(result?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.discount ? toFormatPrice(result?.discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.labour ? toFormatPrice(result?.labour, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.shipping ? toFormatPrice(result?.shipping, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_amount ? toFormatPrice(result?.credit_amount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.balanceDue ? toFormatPrice(result?.balanceDue, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.count ? result.count : 0}</td>
                                                                        <td>{result.loyality_points ? toFormatPrice(result?.loyality_points, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.sales_person ? result?.sales_person : "-"}</td>
                                                                        <td>{result.user ? result?.user : "-"}</td>
                                                                        <td>
                                                                            {result?.printURL === "" ? "-"
                                                                                : <div onClick={() => {
                                                                                    if (result?.printURL) {
                                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                                    }
                                                                                }}><img
                                                                                        className="pdf-image cursor-pointer"
                                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                                        alt=""
                                                                                    /></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{lowercount?.grossSales ? toFormatPrice(lowercount?.grossSales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">
                                                                {lowercount?.hidden_discount ? toFormatPrice(lowercount?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.total_Sales ? toFormatPrice(lowercount?.total_Sales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">{lowercount?.discount ? toFormatPrice(lowercount?.discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.labour ? toFormatPrice(lowercount?.labour, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.shipping ? toFormatPrice(lowercount?.shipping, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.subTotal ? toFormatPrice(lowercount?.subTotal, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.taxAmount ? toFormatPrice(lowercount?.taxAmount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.netSales ? toFormatPrice(lowercount?.netSales, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_notes ? toFormatPrice(lowercount?.credit_notes, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.cash ? toFormatPrice(lowercount?.cash, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_amount ? toFormatPrice(lowercount?.credit_amount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.bank ? toFormatPrice(lowercount?.bank, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.balanceDue ? toFormatPrice(lowercount?.balanceDue, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.count ? lowercount?.count : 0}</td>
                                                            <td>{lowercount?.loyality_points ? toFormatPrice(lowercount?.loyality_points, { addSymbol: true }) : 0}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {dataloder && <TableLoader />}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Custom-Order" role="tabpanel" aria-labelledby="Custom-Order-tab">
                                            <div className="main-body-main-table-wrap Dailysalereport-tabs-table position-relative" id="table-scroll">
                                                <table id='com-data-area_table-Custom-Order' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                                    <Tablehead tablehead={posdailysalereport_head} tblname={"report_dailysalereportchange_tbl"} />
                                                    <tbody>
                                                        <>
                                                            {dailysaledata?.length ? dailysaledata.map((result, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td> {result.date ? result?.date : "-"}</td>
                                                                        <td>{result.voucher_type ? result?.voucher_type : "-"}</td>
                                                                        <td className="link-color">
                                                                            {result?.order_type === "custom_order" ? <Link className="link-color text-decoration-none"
                                                                                to={editparmission ? "/app/pos/custom/createYourOwnSetting" : ""}
                                                                                state={{
                                                                                    order_id: result?.order_id,
                                                                                    order_edit: true,
                                                                                    order_type: result?.order_type
                                                                                }}>
                                                                                {result.ref_no ? result?.ref_no : "-"}</Link> :
                                                                                result?.order_type === "mix_and_match" ? <Link
                                                                                    to={editparmission ? "/app/pos/custom/mixAndMatchProduct" : ""} className="text-decoration-none"
                                                                                    state={{
                                                                                        order_id: result?.order_id,
                                                                                        order_edit: true,
                                                                                        order_type: result?.order_type,
                                                                                        fromring: showseqs ? true : false,
                                                                                        daimond_id: result?.editOrder?.daimond_id,
                                                                                        product_id: result?.editOrder?.product_id,
                                                                                        customer_id: result?.editOrder?.customer_id,
                                                                                        type: result?.editOrder?.type
                                                                                    }}
                                                                                >
                                                                                    {result?.ref_no ? result?.ref_no : "-"}
                                                                                </Link> : result?.ref_no}

                                                                        </td>
                                                                        <td>{result.customer_name ? result?.customer_name : "-"}</td>
                                                                        <td>{result.location_name ? result?.location_name : "-"}</td>
                                                                        <td>{result.qty ? result?.qty : 0}</td>
                                                                        <td>{result.grossSales ? toFormatPrice(result?.grossSales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.hidden_discount ? toFormatPrice(result?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.discount ? toFormatPrice(result?.discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.labour ? toFormatPrice(result?.labour, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.shipping ? toFormatPrice(result?.shipping, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_amount ? toFormatPrice(result?.credit_amount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.balanceDue ? toFormatPrice(result?.balanceDue, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.count ? result.count : 0}</td>
                                                                        <td>{result.loyality_points ? toFormatPrice(result?.loyality_points, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.sales_person ? result?.sales_person : "-"}</td>
                                                                        <td>{result.user ? result?.user : "-"}</td>
                                                                        <td>
                                                                            {result?.printURL === "" ? "-"
                                                                                : <div onClick={() => {
                                                                                    if (result?.printURL) {
                                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                                    }
                                                                                }}><img
                                                                                        className="pdf-image cursor-pointer"
                                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                                        alt=""
                                                                                    /></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{lowercount?.grossSales ? toFormatPrice(lowercount?.grossSales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">
                                                                {lowercount?.hidden_discount ? toFormatPrice(lowercount?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.total_Sales ? toFormatPrice(lowercount?.total_Sales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">{lowercount?.discount ? toFormatPrice(lowercount?.discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.labour ? toFormatPrice(lowercount?.labour, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.shipping ? toFormatPrice(lowercount?.shipping, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.subTotal ? toFormatPrice(lowercount?.subTotal, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.taxAmount ? toFormatPrice(lowercount?.taxAmount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.netSales ? toFormatPrice(lowercount?.netSales, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_notes ? toFormatPrice(lowercount?.credit_notes, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.cash ? toFormatPrice(lowercount?.cash, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_amount ? toFormatPrice(lowercount?.credit_amount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.bank ? toFormatPrice(lowercount?.bank, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.balanceDue ? toFormatPrice(lowercount?.balanceDue, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.count ? lowercount?.count : 0}</td>
                                                            <td>{lowercount?.loyality_points ? toFormatPrice(lowercount?.loyality_points, { addSymbol: true }) : 0}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {dataloder && <TableLoader />}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Repair-Order" role="tabpanel" aria-labelledby="Repair-Order-tab">
                                            <div className="main-body-main-table-wrap Dailysalereport-tabs-table position-relative" id="table-scroll">
                                                <table id='com-data-area_table-Repair-Order' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                                    <Tablehead tablehead={posdailysalereport_head} tblname={"report_dailysalereportchange_tbl"} />
                                                    <tbody>
                                                        <>
                                                            {dailysaledata?.length ? dailysaledata.map((result, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td> {result.date ? result?.date : "-"}</td>
                                                                        <td>{result.voucher_type ? result?.voucher_type : "-"}</td>
                                                                        <td className="link-color"><Link
                                                                            to={editparmission ? "/app/pos/repair/repairOrderProcess" : ""} className="text-decoration-none"
                                                                            state={{
                                                                                order_id: result?.order_id,
                                                                                order_edit: true,
                                                                                order_type: result?.order_type
                                                                            }}
                                                                        >
                                                                            {result?.ref_no ? result?.ref_no : "-"}
                                                                        </Link> </td>
                                                                        <td>{result.customer_name ? result?.customer_name : "-"}</td>
                                                                        <td>{result.location_name ? result?.location_name : "-"}</td>
                                                                        <td>{result.qty ? result?.qty : 0}</td>
                                                                        <td>{result.grossSales ? toFormatPrice(result?.grossSales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.hidden_discount ? toFormatPrice(result?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.total_Sales ? toFormatPrice(result?.total_Sales, { addSymbol: true }) : 0}</td>
                                                                        <td className="danger-text">
                                                                            {result.discount ? toFormatPrice(result?.discount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.labour ? toFormatPrice(result?.labour, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.shipping ? toFormatPrice(result?.shipping, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.subTotal ? toFormatPrice(result?.subTotal, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.taxAmount ? toFormatPrice(result?.taxAmount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.netSales ? toFormatPrice(result?.netSales, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_notes ? toFormatPrice(result?.credit_notes, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.cash ? toFormatPrice(result?.cash, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.credit_amount ? toFormatPrice(result?.credit_amount, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.bank ? toFormatPrice(result?.bank, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.balanceDue ? toFormatPrice(result?.balanceDue, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.count ? result.count : 0}</td>
                                                                        <td>{result.loyality_points ? toFormatPrice(result?.loyality_points, { addSymbol: true }) : 0}</td>
                                                                        <td>{result.sales_person ? result?.sales_person : "-"}</td>
                                                                        <td>{result.user ? result?.user : "-"}</td>
                                                                        <td>
                                                                            {result?.printURL === "" ? "-"
                                                                                : <div onClick={() => {
                                                                                    if (result?.printURL) {
                                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                                    }
                                                                                }}><img
                                                                                        className="pdf-image cursor-pointer"
                                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                                        alt=""
                                                                                    /></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{lowercount?.grossSales ? toFormatPrice(lowercount?.grossSales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">
                                                                {lowercount?.hidden_discount ? toFormatPrice(lowercount?.hidden_discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.total_Sales ? toFormatPrice(lowercount?.total_Sales, { addSymbol: true }) : 0}</td>
                                                            <td className="danger-text">{lowercount?.discount ? toFormatPrice(lowercount?.discount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.labour ? toFormatPrice(lowercount?.labour, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.shipping ? toFormatPrice(lowercount?.shipping, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.subTotal ? toFormatPrice(lowercount?.subTotal, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.taxAmount ? toFormatPrice(lowercount?.taxAmount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.netSales ? toFormatPrice(lowercount?.netSales, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_notes ? toFormatPrice(lowercount?.credit_notes, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.cash ? toFormatPrice(lowercount?.cash, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.credit_amount ? toFormatPrice(lowercount?.credit_amount, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.bank ? toFormatPrice(lowercount?.bank, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.balanceDue ? toFormatPrice(lowercount?.balanceDue, { addSymbol: true }) : 0}</td>
                                                            <td>{lowercount?.count ? lowercount?.count : 0}</td>
                                                            <td>{lowercount?.loyality_points ? toFormatPrice(lowercount?.loyality_points, { addSymbol: true }) : 0}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                {dataloder && <TableLoader />}
                                            </div>
                                        </div>
                                    </div>
                                    {dailysaledata.length > 0 ? (
                                        <Pagination
                                            total={totalPages}
                                            current={currentPage}
                                            pagination={(crPage) => handlePaginations(crPage)}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <SocialModal shareUrl={socialurl} shareloader={shareloader} />

            <TableModal tablehead={posdailysalereport_head} tblName={"report_dailysalereportchange_tbl"} setnewcolumndata={setnewcolumndata} />
        </>
    )
}
export default Dailysalereport;




























