
import moment from 'moment';
import { store } from "../../../source/index";

export const formatDate = (date) => {
    var state = store.getState();
    var OrgSettings = state?.app?.posorganisation_settings;
    let response = moment(date).utc(true).format(OrgSettings?.date_format);
    return response;
}

const date_format_change = (date_format) => {
    var response;
    if (date_format === "d/m/Y") {
        response = "DD/MM/YYYY";
    } else if (date_format === 'Y/m/d') {
        response = "YYYY/MM/DD";
    } else if (date_format === 'm/d/Y') {
        response = "MM/DD/YYYY";
    } else if (date_format === 'm/Y/d') {
        response = "MM/YYYY/DD";
    }
    return response;
}
export const parseDateWithFormat = (dateString) => {
    let day, month, year;
    var state = store.getState();
    var OrgSettings = state?.app?.posorganisation_settings;
    if (OrgSettings?.date_format === "DD/MM/YYYY") {
        [day, month, year] = dateString.split('/');
    } else if (OrgSettings?.date_format === 'YYYY/MM/DD') {
        [year, month, day] = dateString.split('/');
    } else if (OrgSettings?.date_format === 'MM/DD/YYYY') {
        [month, day, year] = dateString.split('/');
    } else if (OrgSettings?.date_format === 'MM/YYYY/DD') {
        [month, year, day] = dateString.split('/');
    }
    return new Date(year, month - 1, day);
}
export const handleContractDateChange = () => {
    const formattedDate = moment("30/08/2022", date_format_change('d/m/Y'));
    return formattedDate;

}
