import React, { useEffect, useState } from "react";
import { Navigate, useParams } from 'react-router-dom';
import { _Api } from "../api/_call";
const ProductClone = (props) => {
  const [rediractUrl, setrediractUrl] = useState();

  const { productId_url } = useParams();
  const getProductUrl_Clone = async () => {
    if (productId_url !== undefined) {
      try {
        const postdata = {
          id: productId_url,
        };
        let resclone = await _Api(postdata, `api/v1/APP/product/createClone`);
        if (resclone.code === 200) {
          setrediractUrl('/app/product/add-new/' + resclone.id);

        } else {
          setrediractUrl('/app/product/main');
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };


  useEffect(() => {
    getProductUrl_Clone();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment key="1">

      <Navigate to={rediractUrl} />


    </React.Fragment>
  );
};

export default ProductClone;
