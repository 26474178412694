import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { _Api } from '../common/api/_call'
import './Report.scss'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import {
  loading,
  Updateloading,
} from '../common/helpers/toastify'
import { Reportmenupos } from './Reportmenupos'
import SocialModal from '../../components/common/modules/SocialModal/SocialModal'
import { poscreditnotereport_head } from '../../components/common/Tablehead'
import { Daterangepos } from '../common/Daterangepos'
import { Tablehead } from '../../components/common/modules/Tablehead'
import TableModal from '../common/Modals/tableColumnPos'
import { useTranslation } from 'react-i18next';
import { Pagination } from "../common/Pagination";
import { toFormatPrice } from '../common/helpers/function';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { Creditnotesubtable } from './Creditnotesubtable'
import $ from 'jquery';
import { useSelector } from 'react-redux';
import Select from "react-select";

const Creditnotereport = () => {
  const { t } = useTranslation();
  const [newcolumndata, setnewcolumndata] = useState(poscreditnotereport_head)
  const [socialurl, setSocialurl] = useState()
  const [datefilter, setDatefilter] = useState([])
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [creditnote, setCreditnote] = useState([])
  const [, setfilterby] = useState('');
  const [filtername, setFiltername] = useState('')
  const [totalcount, SetTotalcount] = useState()
  const [limit, setLimit] = useState(100)
  const [shareloader, setShareloader] = useState(false);
  const [excuteTable, setexcuteTable] = useState(false);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const [printparmission, setPrintparmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

  useEffect(() => {
    if (parmissiondatashow?.pos_reports?.View)
      setPrintparmission(parmissiondatashow?.pos_reports?.Print)
    // eslint-disable-next-line
  }, []);


  const getsharecreditnotereport = async () => {
    setShareloader(true);
    try {
      const postdata = {
        "search": null,
        "start_date": null,
        "end_date": null
      };
      let res = await _Api(postdata, `api/v1/POS/reports/print/creditNote`);

      if (res.code === 200) {
        setSocialurl(res?.link);
        setShareloader(false);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }

  }
  const getprintcreditreport = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/creditNote`)

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'report_creditnotereport_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])

  const filterbyname = (search) => {
    getcreditnotereport(currentPage, limit, search);
    setstate({ ...state, currentPage: 1 });
  };
  const getlimit = (limit) => {
    setLimit(limit);
    getcreditnotereport(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getcreditnotereport(current, "");

  };
  const getcreditnotereport = async (current, firstlimit, search) => {
    setexcuteTable(false);
    UnSetdataTable('creditnote_report')
    try {
      const postdata = {
        search: search ? search : "",
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        "limit": firstlimit ? firstlimit : limit,
        "skip": current ? (current - 1) * limit : 0,
      }
      let listdata = await _Api(postdata, `api/v1/POS/report/creditNote`)
      if (listdata.code === 200) {
        setexcuteTable(true);
        setCreditnote(listdata?.data)
        setTimeout(() => {
          SetdataTable('creditnote_report', 0, newcolumndata, [])

        }, 50)
        setstate({
          ...state,
          totalPages: Math.ceil(listdata?.total / postdata.limit),
          currentPage: current ? current : 1
        });
        SetTotalcount(listdata?.count)
      } else {
        SetdataTable('creditnote_report', 0, newcolumndata, [])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      getcreditnotereport()
    }
    // eslint-disable-next-line
  }, [newcolumndata, datefilter])

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    poscreditnotereport_head.map(async (r, k) => {
      header_values.push(r?.lable)
    })

    if (creditnote.length) {
      creditnote.forEach((result, key) => {
        bodyarray.push([key + 1, result?.date, result?.voucher_no, result?.order_no, result?.customer_name, result?.currency, result?.formated_opening_balance, result?.formated_redeem, result?.formated_remaining, result?.debAndcr, result?.remark])
      })
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "creditnote_report");

  }
  const setSubTablecustom = async (id) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector('#common-main-table-wrap-' + id);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...creditnote];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = null
      })
      setCreditnote(alllist_data);
    } else {
      classList_.add("rotate-90");
      let alllist_data = [...creditnote];
      const postdata = {
        "creditNoteId": id
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/report/creditNoteTransectionDetails`);
      var html_ = Creditnotesubtable(prioritydata, id, t);
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = html_
      })
      setCreditnote(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 w-100">
            <div className="catalog-area bg-white page-menu-block Dailysalereport">
              <div className="Dailysalereport-header">
                <div className="Dailysalereport-header-left">
                  <h3 className="common-navigation-header-left-heading Dailysalereport-head">
                    <Link
                      to=""
                      className="gray-mid text-decoration-none Dailysalereport-head-report"
                    >
                      {t("REPORT")}
                    </Link>
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'} alt=""
                    />
                    <span className="Dailysalereport-head-pagename">
                      {t("Credit Note Report")}
                    </span>
                  </h3>
                </div>
                <div className="Dailysalereport-header-reportdetail">
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={
                          base_assets + 'images/pos/report/customer-blue.png'
                        } alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t("Total Customer")}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data lightblue">
                        {totalcount?.customer ? totalcount?.customer : ''}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report/value-img.png'} alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t("Total Value")}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-value maingreen">
                        {totalcount?.opening_balance ? totalcount?.opening_balance : ""}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={
                          base_assets + 'images/pos/report/RedeemedAmount.png'
                        } alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t("Redeemed Amount")}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-value yellow-text">
                        {totalcount?.redeem ? totalcount?.redeem : ""}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={
                          base_assets + 'images/pos/report/RemainingBalance.png'
                        } alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t("Remaining Balance")}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-value lightgray">
                        {totalcount?.remaining_balance ? totalcount?.remaining_balance : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Dailysalereport-content-area">
                <div className="Dailysalereport-content-area-header">
                  <div className="Dailysalereport-content-area-header-inner">
                    <Reportmenupos
                      Reportname={"Credit Note Report"} />
                    <Select
                      className="limit-select short mx-3"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                    />
                    <div className="date">
                      <Daterangepos setDatefilter={setDatefilter} />
                    </div>
                  </div>
                  <div className="catalog-area-header-area-control-right">

                    <div className="com-search-area">
                      <input type="text" value={filtername} placeholder={t("Search  by Ref number, voucher type, ...")}
                        onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                        onChange={(e) => setFiltername(e.target.value)} />
                      {filtername ? <img className="com-search-area-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                      <img onClick={() => filterbyname(filtername)} className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                    </div>
                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                      <button
                        className="btn-style"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            base_assets + 'images/icons/ellipsis-circular.png'
                          }
                          alt=""
                        />
                      </button>
                      <ul
                        className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li className={printparmission ? "" : "d-none"}>
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                            onClick={(e) => {
                              getprintcreditreport(e.target.value)
                            }}
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/print-icon.png'
                                } alt=""
                              />
                            </div>
                            {t("Print")}
                          </Link>
                        </li>
                        <li onClick={(e) => {
                          downloadexclsheet(e.target.value)
                        }}>
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/export-icon.png'
                                } alt=""
                              />
                            </div>
                            {t("Export")}
                          </Link>
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#socialModal"
                          className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                          onClick={() => getsharecreditnotereport()}
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={
                                base_assets + 'images/pos/report/share-icon.png'
                              } alt=""
                            />
                          </div>
                          {t("Share")}
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#columnModal"
                        >
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets + 'images/icons/column-icon.png'
                                } alt=""
                              />
                            </div>
                            {t("Column")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="Dailysalereport-content-area-main">
                  <div
                    className="main-body-main-table-wrap Reservereport-table"
                    id="table-scroll"
                  >
                    <table
                      id="creditnote_report"
                      className="common-table first-row-gray w-100 Reservereport-table-main"
                    >
                      <Tablehead
                        tablehead={poscreditnotereport_head}
                        tblname={'report_creditnotereport_tbl'}
                        setfilterby={setfilterby}
                      />
                      <tbody>
                        <>
                          {creditnote
                            ? creditnote.map((result, key) => {
                              return (
                                <React.Fragment key={key}>
                                  <tr id={`common-main-table-wrap-${result?.id}`}>
                                    <td>{key + 1}</td>
                                    <td>{result?.date ? result?.date : ''}</td>
                                    <td>
                                      {result?.voucher_no
                                        ? result?.voucher_no
                                        : ''}
                                    </td>
                                    <td className='sku-col table_btn_expand_col'
                                      onClick={() => setSubTablecustom(result?.id)}
                                      id={"table-btn_" + result?.id}>  <img
                                        className="sku-col-arrow cursor-pointer"
                                        src={
                                          base_assets +
                                          "/images/icons/right_green_button.png"
                                        }
                                        alt=""
                                      />
                                      {result?.order_no ? result?.order_no : ''}</td>
                                    <td>
                                      {result?.customer_name
                                        ? result?.customer_name
                                        : ''}
                                    </td>
                                    <td>
                                      {result?.currency ? result?.currency : ''}
                                    </td>
                                    <td>
                                      {result?.formated_opening_balance ? result?.formated_opening_balance : ""}
                                    </td>
                                    <td className="yellowcolor">
                                      {result?.formated_redeem ? result?.formated_redeem : ""}
                                    </td>
                                    <td className="lightgray">
                                      {result?.formated_remaining ? result?.formated_remaining : ""}
                                    </td>
                                    <td>
                                      {result?.debAndcr ? result?.debAndcr : ''}
                                    </td>
                                    <td>
                                      {result?.remark ? result?.remark : ''}
                                    </td>
                                  </tr>
                                  {
                                    excuteTable ? (
                                      <>
                                        {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                      </>
                                    ) : null
                                  }
                                </React.Fragment>
                              )
                            })
                            : ''}
                        </>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {toFormatPrice(totalcount?.opening_balance, { addSymbol: true })}
                          </td>
                          <td className="yellowcolor">
                            {toFormatPrice(totalcount?.redeem, { addSymbol: true })}
                          </td>
                          <td className="lightgray">
                            {toFormatPrice(totalcount?.remaining_balance, { addSymbol: true })}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                {creditnote.length > 0 ? (
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePaginations(crPage)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <TableModal tablehead={poscreditnotereport_head} tblName={"report_creditnotereport_tbl"} setnewcolumndata={setnewcolumndata} />
      </div>
    </>
  )
}
export default Creditnotereport;
