import React from 'react';

const DotTyping = () => {
  const snippetStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  };

  const dotStyle = {
    position: 'relative',
    left: '-9999px',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    backgroundColor: '#5CC7C0',
    color: '#5CC7C0',
    animation: 'dot-typing 1.0s infinite linear'
  };

  return (
    <div className='w-100 d-flex justify-content-center align-items-center vh-100'>
      <div className="snippet" data-title="dot-typing" style={snippetStyle}>
        <div className="stage">
          <div className="dot-typing" style={dotStyle}></div>

        </div>
      </div>
      <style>
        {`
        @keyframes dot-typing {
          0% {
            box-shadow: 9984px 0 0 0 #5CC7C0, 10009px 0 0 0 #5CC7C0, 10034px 0 0 0 #5CC7C0;
          }
          16.667% {
            box-shadow: 9984px -10px 0 0 #5CC7C0, 10009px 0 0 0 #5CC7C0, 10034px 0 0 0 #5CC7C0;
          }
          33.333% {
            box-shadow: 9984px 0 0 0 #5CC7C0, 10009px 0 0 0 #5CC7C0, 10034px 0 0 0 #5CC7C0;
          }
          50% {
            box-shadow: 9984px 0 0 0 #5CC7C0, 10009px -10px 0 0 #5CC7C0, 10034px 0 0 0 #5CC7C0;
          }
          66.667% {
            box-shadow: 9984px 0 0 0 #5CC7C0, 10009px 0 0 0 #5CC7C0, 10034px 0 0 0 #5CC7C0;
          }
          83.333% {
            box-shadow: 9984px 0 0 0 #5CC7C0, 10009px 0 0 0 #5CC7C0, 10034px -10px 0 0 #5CC7C0;
          }
          100% {
            box-shadow: 9984px 0 0 0 #5CC7C0, 10009px 0 0 0 #5CC7C0, 10034px 0 0 0 #5CC7C0;
          }
        }
        `}
      </style>
    </div>
  );
};

export default DotTyping;













