import React, { useState, useEffect, useCallback } from 'react'
import Chart from 'react-apexcharts'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const CustomOrder = (props) => {
  const { setDashboardhideshow, setDefaultset } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const { t } = useTranslation();
  const [completeDataObject, setCompliteDataObject] = useState({ "timeset": "Monthly", graphtype: "Chart" })
  const [customorderdata, setCustomorderdata] = useState()
  const [tabledata, setTabledata] = useState()
  let time_periods = ['Daily', 'Monthly', 'Yearly', 'Weekly']

  const customgraph = useCallback(async () => {
    try {
      let postdata = {
        duration: completeDataObject?.timeset,
        graphtype: completeDataObject?.graphtype
      }
      let res = await _Api(postdata, `api/v1/dashboard/customOrderStatics`)
      if (res?.code === 200) {
        if (postdata?.graphtype === "Chart") {
          setCustomorderdata(res?.data)
        } else if (postdata?.graphtype === "Table") {
          setTabledata(res?.statics)
        }
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [completeDataObject]);
  useEffect(() => {
    customgraph()
  }, [completeDataObject, customgraph])
  const CustomOrderChart = {
    series: customorderdata?.data?.length ? customorderdata?.data : [],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: customorderdata?.labels?.length ? customorderdata?.labels : [],
      yaxis: [
        {
          title: {
            text: 'Qty',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Value',
          },
        },
      ],
      colors: customorderdata?.color?.length ? customorderdata?.color : [],
    },
  };

  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Custom Order")}
          </div>

          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t(`${completeDataObject?.timeset}`)}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              {time_periods?.length ? time_periods.map((result, key) => {
                return (
                  <li key={key}
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        timeset: result
                      }))}>
                    <span className="dropdown-item">{t(`${result}`)}</span>
                  </li>
                )
              }) : ""}
            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    graphtype: "Chart"
                  }))}>
                <span className="dropdown-item">
                  <i className="fa fa-line-chart me-2" aria-hidden="true"></i>{' '}
                  {t("Chart")}
                </span>
              </li>
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    graphtype: "Table"
                  }))}>
                <span className="dropdown-item">
                  <i className="fa fa-table me-2" aria-hidden="true"></i> {t("Table")}
                </span>
              </li>
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li
                onClick={() => {
                  setDashboardhideshow(prevState => ({
                    ...prevState,
                    CustomOrder: 0
                  }));
                  setDefaultset(true)
                }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area py-3">
          {completeDataObject?.graphtype === "Chart" ?
            <div className='dashboard-area-cards-single-chart-area-inner' id="CustomOrderChart">
              <Chart options={CustomOrderChart.options}
                series={CustomOrderChart.series}
                type="bar"
                height={350}
              />

            </div>
            :
            completeDataObject?.graphtype === "Table" ?
              <div className='dashboard-area-cards-single-chart-area-table position-relative'>
                <table className='w-100'>
                  <thead>
                    <tr>
                      <th className='text-center'></th>
                      <th className='text-center'>{t("Qty")}</th>
                      <th className='text-center'>{t("Value")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabledata?.length ? tabledata.map((result, key) => {
                      return (
                        <tr key={key}>
                          <td className='text-center'>{result?.label ? result?.label : ''}</td>
                          <td className='text-center'>{result?.qty ? result?.qty : 0}</td>
                          <td className='text-center'>{result?.amount ? result?.amount : 0}</td>
                        </tr>
                      )
                    }) : ""}

                  </tbody>
                </table>
              </div> : ""}

        </div>
      </div>
    </div>
  )
}
export default CustomOrder
