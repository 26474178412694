import React from 'react'

export default function ChangePasswordIcon(props) {
    const { width, height, type } = props
    return (
        <>
        {type === 'dark' ? 
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.586 16.4139C1.2109 16.7889 1.00011 17.2975 1 17.8279V19.9999C1 20.2651 1.10536 20.5195 1.29289 20.707C1.48043 20.8946 1.73478 20.9999 2 20.9999H5C5.26522 20.9999 5.51957 20.8946 5.70711 20.707C5.89464 20.5195 6 20.2651 6 19.9999V18.9999C6 18.7347 6.10536 18.4803 6.29289 18.2928C6.48043 18.1053 6.73478 17.9999 7 17.9999H8C8.26522 17.9999 8.51957 17.8946 8.70711 17.707C8.89464 17.5195 9 17.2651 9 16.9999V15.9999C9 15.7347 9.10536 15.4803 9.29289 15.2928C9.48043 15.1053 9.73478 14.9999 10 14.9999H10.172C10.7024 14.9998 11.211 14.789 11.586 14.4139L12.4 13.5999C13.7898 14.0841 15.3028 14.0822 16.6915 13.5947C18.0801 13.1071 19.2622 12.1628 20.0444 10.916C20.8265 9.66933 21.1624 8.19409 20.9971 6.73165C20.8318 5.26922 20.1751 3.90617 19.1344 2.86549C18.0937 1.8248 16.7307 1.1681 15.2683 1.00281C13.8058 0.837511 12.3306 1.17341 11.0839 1.95556C9.83716 2.7377 8.89279 3.91979 8.40525 5.30844C7.91771 6.69708 7.91585 8.21008 8.4 9.59992L1.586 16.4139Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.5 7C15.7761 7 16 6.77614 16 6.5C16 6.22386 15.7761 6 15.5 6C15.2239 6 15 6.22386 15 6.5C15 6.77614 15.2239 7 15.5 7Z" fill="black" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        :
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 7.00008C15.5304 7.00008 16.0391 7.21079 16.4142 7.58586C16.7893 7.96093 17 8.46964 17 9.00008M21 9.00008C21.0003 9.93725 20.781 10.8615 20.3598 11.6986C19.9386 12.5358 19.3271 13.2627 18.5744 13.821C17.8216 14.3792 16.9486 14.7535 16.0252 14.9136C15.1018 15.0737 14.1538 15.0153 13.257 14.7431L11 17.0001H9V19.0001H7V21.0001H4C3.73478 21.0001 3.48043 20.8947 3.29289 20.7072C3.10536 20.5196 3 20.2653 3 20.0001V17.4141C3.00006 17.1489 3.10545 16.8946 3.293 16.7071L9.257 10.7431C9.00745 9.91809 8.93857 9.04902 9.05504 8.19502C9.17152 7.34102 9.47062 6.52215 9.93199 5.79412C10.3934 5.0661 11.0062 4.44602 11.7287 3.97609C12.4512 3.50617 13.2665 3.19743 14.1191 3.07088C14.9716 2.94434 15.8415 3.00296 16.6693 3.24276C17.4972 3.48256 18.2637 3.89791 18.9166 4.46054C19.5696 5.02317 20.0936 5.71987 20.4531 6.50322C20.8127 7.28656 20.9992 8.13817 21 9.00008Z" stroke="#1D1D1F" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        }
        </>
    )}
