import React, { useState } from 'react'
import EyeShow from '../../assets/icons/EyeShow'
import { _Api } from '../../../common/api'
import AccountSidebar from './AccountSidebar'
import PasswordHide from '../../assets/images/hide.png'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
const ChangePassword = () => {
    const [changepassworddata, setChanepassworddata] = useState()
    const [showPassword, setShowPassword] = useState();
    const [validaction, setValidaction] = useState()
    const navigate = useNavigate();
    const { t } = useTranslation();
    const setdata = (keyname, value) => {
        setChanepassworddata((state) => ({
            ...state,
            [keyname]: value
        }))
    }
    const getchangepassword = async () => {
        const valid = () => {
            let fromisvalid = true
            if (!changepassworddata?.oldpassword) {
                setValidaction((state) => ({
                    ...state,
                    oldpassword: "Old password is required"
                }))
                fromisvalid = false
            } else {
                setValidaction((state) => ({
                    ...state,
                    oldpassword: ""
                }))
            }
            if (!changepassworddata?.newpassword) {
                setValidaction((state) => ({
                    ...state,
                    newpassword: "Password is required"
                }))
                fromisvalid = false
            } else {
                setValidaction((state) => ({
                    ...state,
                    newpassword: ""
                }))
            }
            if (!changepassworddata?.password) {
                setValidaction((state) => ({
                    ...state,
                    password: "Confirm Password is required"
                }))
                fromisvalid = false
            } else {
                setValidaction((state) => ({
                    ...state,
                    password: ""
                }))
            }
            return fromisvalid;
        }
        if (valid()) {
            if (changepassworddata?.password === changepassworddata?.newpassword) {
                try {
                    let postdata = {
                        "oldpassword": changepassworddata?.oldpassword,
                        "password": changepassworddata?.password
                    }
                    let res = await _Api(postdata, `account/reset-password`)
                    if (res?.statusCode === 200) {
                        setValidaction((state) => ({
                            ...state,
                            sussesmessage: res?.message,
                            errormessage: ""
                        }))
                        navigate("/account")
                    }
                }
                catch (err) {
                    console.log(err, "err")
                    setValidaction(prevState => ({
                        ...prevState,
                        errormessage: err.response.data.message,
                        sussesmessage: "",

                    }));
                }
            } else {
                setValidaction((state) => ({
                    ...state,
                    errormessage: "Password Not Match"
                }))
            }
        }
    }
    const togglePasswordVisibility = (label) => {
        if (label === "oldpassword") {
            setShowPassword((state) => ({
                ...state,
                oldpassword: !showPassword?.oldpassword
            }))
        } else if (label === "newpassword") {
            setShowPassword((state) => ({
                ...state,
                newpassword: !showPassword?.newpassword
            }))
        } else if (label === "password") {
            setShowPassword((state) => ({
                ...state,
                password: !showPassword?.password
            }))
        }

    }

    return (
        <div className='d-flex accountDetailMainPage  ecommerce-main-layout-bodyContent'>
            <AccountSidebar />
            <div className="account-details">
                <div className='changePassword-container'>
                    <div className='account-com-heading changePassword-container-header'>{t("Change Password")}</div>
                    <div className='changePassword-container-mainBody'>
                        <div className='changePassword-container-mainBody-allPasswordBox'>
                            <div className='changePassword-container-mainBody-allPasswordBox-singlePass '>
                                <span className='changePassword-container-mainBody-allPasswordBox-singlePass-subHeading'>{t("Old password")} <span className='com-starShow'>*</span></span>
                                <div className=
                                    {validaction?.oldpassword ? 'changePassword-container-mainBody-allPasswordBox-singlePass-inputBox border-danger' : "changePassword-container-mainBody-allPasswordBox-singlePass-inputBox"
                                    }>
                                    <input className="pass-input"
                                        type={showPassword?.oldpassword ? 'text' : 'password'}
                                        placeholder='Enter Current Password'
                                        value={changepassworddata?.oldpassword}
                                        onChange={(e) => setdata("oldpassword", e.target.value)} />
                                    <span onClick={() => togglePasswordVisibility("oldpassword")}>  {showPassword?.oldpassword ? <img style={{ width: "24px", height: "24px" }} src={PasswordHide} alt='' /> : <EyeShow width={24} height={24} />}</span>
                                </div>
                                {validaction?.oldpassword ?
                                    <p className='text-danger'>{validaction?.oldpassword}</p>
                                    : ""}
                            </div>
                            <div className='changePassword-container-mainBody-allPasswordBox-singlePass '>
                                <span className='changePassword-container-mainBody-allPasswordBox-singlePass-subHeading'>{t("New Password")} <span className='com-starShow'>*</span></span>
                                <div className={validaction?.newpassword ? 'changePassword-container-mainBody-allPasswordBox-singlePass-inputBox border-danger' : "changePassword-container-mainBody-allPasswordBox-singlePass-inputBox"
                                }>
                                    <input
                                        className="pass-input"
                                        type={showPassword?.newpassword ? 'text' : 'password'}
                                        placeholder='Enter New Password'
                                        value={changepassworddata?.newpassword}
                                        onChange={(e) => setdata("newpassword", e.target.value)} />
                                    <span onClick={() => togglePasswordVisibility("newpassword")}>  {showPassword?.newpassword ? <img style={{ width: "24px", height: "24px" }} src={PasswordHide} alt='' /> : <EyeShow width={24} height={24} />}</span>
                                </div>
                                {validaction?.newpassword ?
                                    <p className='text-danger'>{validaction?.newpassword}</p>
                                    : ""}
                            </div>
                            <div className='changePassword-container-mainBody-allPasswordBox-singlePass '>
                                <span className='changePassword-container-mainBody-allPasswordBox-singlePass-subHeading'>{t("Confirm Password")} <span className='com-starShow'>*</span></span>
                                <div className={validaction?.password ? 'changePassword-container-mainBody-allPasswordBox-singlePass-inputBox border-danger' : "changePassword-container-mainBody-allPasswordBox-singlePass-inputBox"
                                }>
                                    <input
                                        className="pass-input"
                                        type={showPassword?.password ? 'text' : 'password'}
                                        placeholder='Enter Confirm Password'
                                        value={changepassworddata?.password}
                                        onChange={(e) => setdata("password", e.target.value)}
                                    /> <span onClick={() => togglePasswordVisibility("password")}>  {showPassword?.password ? <img style={{ width: "24px", height: "24px" }} src={PasswordHide} alt='' /> : <EyeShow width={24} height={24} />}</span>
                                </div>
                                {validaction?.password ?
                                    <p className='text-danger'>{validaction?.password}</p>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    {validaction?.errormessage ?
                        <div className='text-end text-danger'>{t(`${validaction?.errormessage}`)}</div>
                        : validaction?.sussesmessage ?
                            <div className='text-end spinnercolor'>{t(`${validaction?.sussesmessage}`)}</div> : ""
                    }
                    <div className='com-formSaveBtnbox changePassSaveBox'>
                        <button className='com-formSaveBtnbox-cancelBtn changePassSaveBox-cancelBtnMake' onClick={() => navigate(-1)}>{t("Cancel")}</button>
                        <button className='com-formSaveBtnbox-saveBtn changePassSaveBox-saveBtnMake' onClick={() => getchangepassword()}>{t("Change Password")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword