import React, { useEffect, useState, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CheckoutBlank from '../checkout/checkoutBlank';
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import './GiftCard.scss';
import DatePicker from 'react-datepicker';
import { error } from '../common/helpers/toastify';
import { useSelector } from 'react-redux';
import NoOrdersYet from '../common/module/NoOrdersYet/NoOrdersYet';
import { _Api } from '../common/api/_call';
import { useTranslation } from 'react-i18next';
import { toFormatPrice } from '../common/helpers/function'
import { useLocation } from 'react-router-dom';
import Select from "react-select"
import { store } from '../../source/index'
const GiftCard = () => {
  const { t } = useTranslation()
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const { state } = useLocation();
  const modalRef = useRef(null);
  const customer = useSelector((state) => state.app.customer)
  const saleperson = useSelector((state) => state.app.saleperson)
  const [cardnumber, setCardnumber] = useState('')
  const [cardname, setCardname] = useState('')
  const [amount, setAmount] = useState()
  const [UpdatedCart, setUpdatedCart] = useState()
  const [deletestatus, setdeletestatus] = useState(false)
  const [searchproductstatus, setsearchproductstatus] = useState()
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [showpayment, setShowpayment] = useState(false);
  const [remark, setRemark] = useState('')
  const [limit, setLimit] = useState(100)
  const [giftcard, setGiftcard] = useState('')
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [TransactionDate, setTransactionDate] = useState(new Date())
  const [parmission, setParmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.pos_coupon?.View)
      setParmission(parmissiondatashow?.pos_coupon?.Add);
  }, [parmissiondatashow]);
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const getgiftcard = async (firstlimit) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    try {
      const postdata = {
        customer_id: customer?.id,
        limit: firstlimit ? firstlimit : limit,
      }
      let res = await _Api(postdata, `api/v1/POS/giftCard/getGiftCard`)
      if (res.code === 200) {
        setGiftcard(res.data)
        if (state?.order_id && state?.order_edit && state?.order_type) {
          addtoexchangecart(state?.order_id, state?.order_edit, state?.order_type)
          setShowcheckout('sell');
        }
        setdeletestatus(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const creategiftcard = async () => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    if (!cardname) {
      error(t("Gift card name is Requried!!"));
      return;
    }
    if (!cardnumber) {
      error(t("Gift card number is Requried!!"));
      return;
    }
    if (!TransactionDate) {
      error(t("Date is Requried!!"));
      return;
    }
    if (!amount) {
      error(t("Amount is Requried!!"));
      return;
    }
    try {
      const postdata = {
        customer_id: customer?.id,
        sales_person_id: saleperson?.value,
        coupon_amount: parseFloat(amount),
        coupon_code: parseInt(cardnumber),
        coupon_name: cardname,
        coupon_expire_date: TransactionDate,
        coupon_remark: remark,
      }
      let res = await _Api(postdata, `api/v1/POS/giftCard/createGiftCard`)
      if (res.code === 200) {
        getgiftcard();
        setsearchproductstatus(true);
        setShowcheckout('sell');
        if (modalRef.current) {
          modalRef.current.hide();
        }
      } else {
        error((res?.message));
      }

    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (customer?.id) {
      getgiftcard()
    }
    // eslint-disable-next-line
  }, [customer?.id, deletestatus])
  const addtoexchangecart = async (order_id, order_edit, order_type) => {
    try {
      const postdata = {
        "order_id": order_id,
        'order_type': order_type ? order_type : 'gift_card_order',
        "order_edit": order_edit,
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        if (order_id || order_edit || order_type) {
          setUpdatedCart(res?.data);
          setShowcheckout('sell');
          setsearchproductstatus(true);
        }
      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    if (state?.order_id && state?.order_edit && state?.order_type) {
      addtoexchangecart(state?.order_id, state?.order_edit, state?.order_type)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (showcheckout) {
      localStorage.setItem('showcheckout', showcheckout);
    }
  }, [showcheckout]);
  useEffect(() => {
    modalRef.current = new window.bootstrap.Modal(document.getElementById('giftcardADDModal'));
  }, []);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="gift-card-page">
              <div className="customer-inner-tophead">
                <h4 className="main-heading">{t('Gift Card')}</h4>
                <div className="customer-inner-tophead-right">
                  <div className="customer-inner-tophead-right-custom">
                    <button
                      className={parmission ? "cst-btn btn img-btn-hover" : "d-none"}
                      data-bs-toggle="modal"
                      data-bs-target="#giftcardADDModal"
                      onClick={() => { setCardnumber(""); setCardname(""); setAmount(""); setRemark("") }}
                    >
                      <img
                        src={base_assets + 'images/icons/adplus.png'}
                        className="img-btn-hover-img-1"
                        alt="filter"
                      />
                      <img
                        src={base_assets + 'images/icons/adplus-w.png'}
                        className="img-btn-hover-img-2"
                        alt="filter"
                      />
                      {t('Gift Card')}
                    </button>
                    <div
                      className="modal fade giftcard-modal"
                      id="giftcardADDModal"
                      tabIndex="-1"
                      aria-labelledby="giftcardADDModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered giftcard-modal-popupbox">
                        <div className="modal-content giftcard-modal-popupbox-outer">
                          <div className="giftcard-modal-popupbox-content">
                            <div className="giftcard-modal-popupbox-content-header">
                              <h1 className="giftcard-modal-popupbox-content-heading">
                                {t('Create Gift card')}
                              </h1>
                              <div className="giftcard-modal-popupbox-content-close">
                                <img
                                  className="giftcard-modal-popupbox-content-close-btn"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  src={base_assets + 'images/pos/close-btn.png'} alt=""
                                ></img>
                              </div>
                            </div>
                            <div className="giftcard-modal-popupbox-content-inner">
                              <div className="placeholder-up-input form-floating">
                                <input
                                  value={cardname}
                                  type="text"
                                  className="form-control placeholder-up-input-field giftcard-modal-popupbox-content-inner-from"
                                  placeholder="Gift card name *"
                                  onChange={(e) =>
                                    setCardname(e.target.value)
                                  }
                                  aria-label="Gift card name *"
                                />
                                <label className="placeholder-up-input-label">
                                  {t('Gift card name')} <sup>*</sup>
                                </label>
                              </div>
                              <div className="placeholder-up-input form-floating">
                                <input
                                  value={cardnumber}
                                  type="text"
                                  className=" form-control placeholder-up-input-field giftcard-modal-popupbox-content-inner-from"
                                  placeholder="Gift card number *"
                                  aria-label="Gift card number *"
                                  onChange={(e) =>
                                    setCardnumber(e.target.value)
                                  }
                                />
                                <label className="placeholder-up-input-label">
                                  {t('Gift card number')} <sup>*</sup>
                                </label>
                              </div>
                              <div className="giftcard-modal-popupbox-content-inner-from-date-remark">
                                <div className="giftcard-modal-popupbox-content-inner-from-date">
                                  <div className=" placeholder-up-input-date date ">
                                    <DatePicker
                                      className="placeholder-up-input-field popup-date"
                                      selected={TransactionDate}
                                      onChange={selectTransactionDate}
                                      name="TransactionDate"
                                      dateFormat={OrgSettings?.date_picker}
                                      autoComplete='none'
                                    />

                                    <img
                                      className="placeholder-up-input-field-calender"
                                      src={
                                        base_assets +
                                        'images/icons/calendar-green.svg'
                                      }
                                      alt=""
                                    />
                                    <label className="placeholder-up-input-label">
                                      {t('Date')}
                                    </label>
                                  </div>
                                  <div className="placeholder-up-input form-floating">
                                    <input
                                      type='number'
                                      value={amount}
                                      className="popup-amount form-control placeholder-up-input-field"
                                      placeholder="Amount *"
                                      aria-label="Amount *"
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                    />
                                    <label className="placeholder-up-input-label">
                                      {t('Amount')} <sup>*</sup>
                                    </label>
                                  </div>
                                </div>
                                <div className="giftcard-modal-popupbox-content-inner-remark-confirm-btn">
                                  <div className="giftcard-modal-popupbox-content-inner-remark">

                                    <span>{t('Remark')} :</span>
                                    <input
                                      value={remark}
                                      type="text"
                                      className="giftcard-modal-popupbox-content-inner-remark-border"
                                      onChange={(e) =>
                                        setRemark(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="giftcard-modal-popupbox-content-inner-confirm-btn-outer">
                                    <button
                                      className="giftcard-modal-popupbox-content-inner-confirm-btn-inner"
                                      onClick={(e) =>
                                        creategiftcard(e.target.value)
                                      }
                                    >
                                      {t('Confirm')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-custom giftcard-search-custom d-flex align-items-center">
                <span className="title">{t('Show')} :</span>
                <div style={{ minWidth: '4.2vw', width: 'fit-content' }}>
                  <Select
                    className="select p-0 main-content-wrapper-body-top-status-bar-select-num mx-2"
                    options={selectOptions}
                    value={{ label: limit, value: limit }}
                    onChange={(e) => { setLimit(e.value); getgiftcard(e.value) }}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="main-giftcard position-relative">
                <div className="main-giftcard-inner">
                  {giftcard?.length
                    ? giftcard.map((result, key) => {
                      return (
                        <div className="gificard" key={key}>
                          <div className="gificard-inner-right">
                            <div className="gificard-inner-right-inner">
                              <div className="gificard-inner-right-inner-head">
                                <span className="gificard-inner-right-inner-heading">
                                  {result?.card_no}
                                </span>
                                <span className="gificard-inner-right-inner-text">
                                  {result?.expire_date}
                                </span>
                              </div>
                              <div className="gificard-inner-right-inner-scanner">
                                <img
                                  className="gificard-inner-right-inner-scanner-qrcode"
                                  src={
                                    result?.qr_code
                                  } alt=""
                                />
                                <img
                                  className="gificard-inner-right-inner-scanner-barcode"
                                  src={
                                    result?.barcode
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <img
                              className="gificard-inner-right-triangle"
                              src={
                                base_assets +
                                'images/pos/icons/triangle-shape.png'
                              } alt=""
                            />
                            <img
                              className="gificard-inner-right-triangle gificard-inner-right-triangle-bottom"
                              src={
                                base_assets +
                                'images/pos/icons/triangle-shape.png'
                              } alt=""
                            />
                          </div>
                          <div className="gificard-inner-left">
                            <div className="gificard-inner-left-inner">
                              <span className="gificard-inner-left-inner-heading">
                                {result?.name}
                              </span>
                              <span className="gificard-inner-left-inner-rupess">
                                {toFormatPrice(result?.amount, { addSymbol: true })}
                              </span>
                              {result?.remark ?
                                <div className="gificard-inner-left-inner-text">
                                  <label className="gificard-inner-left-inner-text-remark">
                                    {t('Remark')}:
                                  </label>
                                  <label>{result?.remark}</label>
                                </div>
                                : ""}
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : <NoOrdersYet />}
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                searchproductstatus={searchproductstatus}
                setsearchproductstatus={setsearchproductstatus}
                ordertype={'gift_card_order'}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
                order_id={state?.order_id}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} order_edit={state?.order_edit}
                order_type={state?.order_type}
                order_id={state?.order_id} />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default GiftCard;
