import React from 'react';
import {  error } from '../../helpers/toastify'; 
import { Link } from 'react-router-dom';
import './SocialModal.scss';
import { useTranslation } from 'react-i18next';
export const SocialModal = (props) => {
  const { t } = useTranslation()
  const { shareUrl, shareloader } = props
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL

  return (
    <>
      <div
        className="modal fade social-modal"
        id="socialModal"
        tabIndex="-1"
        aria-labelledby="socialLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <img
              className="social-modal-small-diamonds"
              src={base_url_assets + 'images/pos/small-diamonds.png'}
              alt=""
            />
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="social-modal-ct">
                <h1 className="social-modal-ct-heading">{t('Share')}</h1>
                {!shareloader || shareUrl ? (
                  <div className="social-modal-ct-icons">
                    <Link
                      to={
                        shareUrl
                          ? `https://twitter.com/intent/tweet?text=${shareUrl}%23.Y2o2bSK7RiE.twitter&related=`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap twitter"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo twitter-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <path
                            d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('twitter')}
                      </label>
                    </Link>

                    <Link
                      to={
                        shareUrl
                          ? `https://www.facebook.com/login.php?skip_api_login=1&api_key=140586622674265&signed_next=1&next=${shareUrl}%2523.Y2oyRZYtNeM.facebook%26client_id%3D140586622674265%26ret%3Dlogin&cancel_url=https%3A%2F%2Fs7.addthis.com%2Fstatic%2Fthankyou.html%3Ferror_code%3D4201%26error_message%3DUser%2Bcanceled%2Bthe%2BDialog%2Bflow%23_%3D_&display=popup&locale=en_GB`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap facebook"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo facebook-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <path
                            d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('facebook')}
                      </label>
                    </Link>

                    <Link
                      to={
                        shareUrl
                          ? `https://mail.google.com/mail/u/0/?fs=1&to&su=${shareUrl}%23.Y2o7fryfP4w.gmail&ui=2&tf=cm`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap gmail"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo gmail-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <g fillRule="evenodd" />
                          <path opacity=".3" d="M7.03 8h17.94v17H7.03z" />
                          <path d="M7.225 8h-.41C5.815 8 5 8.84 5 9.876v13.248C5 24.16 5.812 25 6.815 25h.962V12.714L16 19.26l8.223-6.546V25h.962C26.188 25 27 24.16 27 23.124V9.876C27 8.84 26.186 8 25.185 8h-.41L16 15.506 7.225 8z" />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('gmail')}
                      </label>
                    </Link>
                    <Link
                      to={
                        shareUrl
                          ? `https://www.facebook.com/login.php?api_key=140586622674265&skip_api_login=1&display=page&cancel_url=${shareUrl}%2523.Y2o8Zl0O_H0.messenger%26from_login%3D1`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap messanger"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo messanger-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <path
                            d="M16 6C9.925 6 5 10.56 5 16.185c0 3.205 1.6 6.065 4.1 7.932V28l3.745-2.056c1 .277 2.058.426 3.155.426 6.075 0 11-4.56 11-10.185C27 10.56 22.075 6 16 6zm1.093 13.716l-2.8-2.988-5.467 2.988 6.013-6.383 2.868 2.988 5.398-2.987-6.013 6.383z"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('messanger')}
                      </label>
                    </Link>

                    <Link
                      to={
                        shareUrl
                          ? `https://telegram.me/share/url?url=${shareUrl}%23.Y2o993ySRos.telegram&text=MongoDB+Create+Database+Username+Password+to+Secure+Data+%7C+ObjectRocket`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap telegram"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo telegram-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <g fillRule="evenodd" />
                          <path
                            d="M15.02 20.814l9.31-12.48L9.554 17.24l1.92 6.42c.225.63.114.88.767.88l.344-5.22 2.436 1.494z"
                            opacity=".6"
                          />
                          <path
                            d="M12.24 24.54c.504 0 .727-.234 1.008-.51l2.687-2.655-3.35-2.054-.344 5.22z"
                            opacity=".3"
                          />
                          <path d="M12.583 19.322l8.12 6.095c.926.52 1.595.25 1.826-.874l3.304-15.825c.338-1.378-.517-2.003-1.403-1.594L5.024 14.727c-1.325.54-1.317 1.29-.24 1.625l4.98 1.58 11.53-7.39c.543-.336 1.043-.156.633.214" />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('telegram')}
                      </label>
                    </Link>

                    <Link
                      to={
                        shareUrl
                          ? `https://api.addthis.com/oexchange/0.8/forward/wechat/offer?url=${shareUrl}%23.Y2o-fiOKjSE.wechat&title=MongoDB+Create+Database+Username+Password+to+Secure+Data+%7C+ObjectRocket&pco=binv-300&pubid=ra-5d530f84697c9404&sid=636a02e115165088`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap wechat"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo wechat-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <path
                            d="M20.285 12.248c-2.042.107-3.818.727-5.26 2.128-1.457 1.415-2.122 3.15-1.94 5.3-.798-.1-1.525-.208-2.256-.27-.254-.02-.554.01-.767.13-.71.4-1.39.854-2.198 1.36.148-.67.244-1.26.414-1.823.123-.416.066-.647-.316-.917-2.452-1.734-3.485-4.328-2.712-7 .716-2.47 2.473-3.97 4.86-4.75 3.26-1.066 6.92.022 8.902 2.613a6.113 6.113 0 0 1 1.27 3.228zM24.873 25c-.647-.288-1.24-.72-1.87-.787-.63-.066-1.292.298-1.95.365-2.005.205-3.8-.354-5.282-1.726-2.816-2.61-2.414-6.61.845-8.75 2.897-1.9 7.145-1.266 9.187 1.37 1.782 2.302 1.572 5.357-.603 7.29-.63.56-.857 1.02-.453 1.757.074.136.083.308.126.48zM10.886 11.416c.02-.49-.404-.93-.906-.944a.915.915 0 0 0-.952.886.904.904 0 0 0 .895.94.93.93 0 0 0 .963-.882zm4.904-.944c-.504.01-.93.44-.92.93a.92.92 0 0 0 .944.9.903.903 0 0 0 .91-.928.91.91 0 0 0-.934-.902zm1.722 7.39c.412 0 .75-.323.766-.73a.767.767 0 0 0-.76-.794.775.775 0 0 0-.77.79.768.768 0 0 0 .764.734zm4.745-1.523a.762.762 0 0 0-.755.728.75.75 0 0 0 .745.79c.413 0 .74-.313.755-.725a.757.757 0 0 0-.745-.794z"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('wechat')}
                      </label>
                    </Link>

                    <Link
                      to={
                        shareUrl
                          ? `https://api.whatsapp.com/send?text=${shareUrl}%23.Y2owVm2N5OA.whatsapp`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap whatsapp"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo whatsapp-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <path
                            d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('whatsapp')}
                      </label>
                    </Link>
                    <Link
                      to={
                        shareUrl
                          ? `https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=13&ct=1667907319&rver=7.1.6819.0&wp=MBI_SSL&wreply=${shareUrl} %252523.Y2oC4STQd28.skype%2526lang%253Den%2526flow_id%253D636a02e1aa2f100b%2526source%253DAddThis&lc=1033&id=293290&mkt=en-US&psi=skype&lw=1&cobrandid=2befc4b5-19e3-46e8-8347-77317a16a5a5&client_flight=ReservedFlight33%2CReservedFlight67`
                          : () => error(t('error'))
                      }
                      target="_blank"
                      className="social-modal-ct-icons-diamond-wrap skype"
                    >
                      <span className="social-modal-ct-icons-diamond-wrap-shape"></span>
                      <svg
                        className="social-modal-ct-icons-diamond-wrap-social-logo skype-fill"
                        viewBox="0 0 32 32"
                      >
                        <g>
                          <path
                            d="M22.453 19c0-.52-.1-.997-.305-1.43a3.438 3.438 0 0 0-.757-1.07 4.837 4.837 0 0 0-1.14-.766 8.692 8.692 0 0 0-1.29-.53 17.54 17.54 0 0 0-1.366-.36l-1.625-.375a49.96 49.96 0 0 1-.69-.165 5.017 5.017 0 0 1-.546-.18c-.218-.083-.375-.167-.468-.25a1.31 1.31 0 0 1-.258-.328.92.92 0 0 1-.117-.47c0-.8.75-1.202 2.25-1.202a3.6 3.6 0 0 1 1.204.188c.354.125.635.273.843.445.21.172.407.346.594.523.19.178.397.33.626.454.23.125.48.188.75.188.49 0 .883-.167 1.18-.5.297-.333.445-.734.445-1.203 0-.574-.29-1.092-.874-1.556-.583-.463-1.323-.815-2.218-1.055-.896-.24-1.844-.36-2.844-.36a8.73 8.73 0 0 0-2.063.242 7.19 7.19 0 0 0-1.866.735 3.782 3.782 0 0 0-1.39 1.36c-.35.577-.524 1.246-.524 2.007 0 .635.1 1.19.297 1.664.198.474.49.867.875 1.18.385.312.802.565 1.25.757a9.66 9.66 0 0 0 1.61.508l2.28.563c.938.23 1.52.416 1.75.562.334.208.5.52.5.938 0 .406-.208.742-.625 1.007-.416.266-.963.4-1.64.4-.53 0-1.008-.084-1.43-.25-.422-.168-.76-.368-1.015-.603a13 13 0 0 1-.71-.703 3.737 3.737 0 0 0-.72-.6 1.536 1.536 0 0 0-.844-.25c-.52 0-.914.155-1.18.467-.265.313-.398.703-.398 1.172 0 .96.635 1.78 1.906 2.46 1.27.683 2.787 1.025 4.547 1.025.76 0 1.49-.096 2.188-.288a7.115 7.115 0 0 0 1.915-.836 4.048 4.048 0 0 0 1.383-1.46c.343-.61.515-1.296.515-2.056zM28 22c0 1.656-.586 3.07-1.758 4.242C25.07 27.414 23.656 28 22 28a5.845 5.845 0 0 1-3.656-1.25c-.802.167-1.584.25-2.344.25-1.49 0-2.914-.29-4.273-.867-1.36-.578-2.532-1.36-3.516-2.344-.983-.985-1.765-2.157-2.343-3.517A10.81 10.81 0 0 1 5 16c0-.76.083-1.542.25-2.344A5.845 5.845 0 0 1 4 10c0-1.656.586-3.07 1.758-4.242C6.93 4.586 8.344 4 10 4c1.354 0 2.573.417 3.656 1.25C14.458 5.083 15.24 5 16 5c1.49 0 2.914.29 4.273.867 1.36.578 2.532 1.36 3.516 2.344.983.985 1.765 2.157 2.343 3.517A10.81 10.81 0 0 1 27 16c0 .76-.083 1.542-.25 2.344A5.845 5.845 0 0 1 28 22z"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                      <label className="social-modal-ct-icons-diamond-wrap-label">
                        {t('skype')}
                      </label>
                    </Link>
                  </div>
                ) : (
                  <div className="spinner-border-wrap">
                    <div className="spinner-border" role="status"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SocialModal;
