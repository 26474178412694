import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Report.scss"
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { Reportmenupos } from "./Reportmenupos";
import { Daterangepos } from "../common/Daterangepos";
import TableModal from "../common/Modals/tableColumnPos";
import { _Api } from "../common/api/_call"
import { Tablehead } from "../../components/common/modules/Tablehead";
import { posCashregisterreport_head } from "../../components/common/Tablehead";
import { useTranslation } from 'react-i18next';
import { Pagination } from "../common/Pagination";
import Select from "react-select";

const Cashregisterreport = () => {
    const { t } = useTranslation();
    const [newcolumndata, setnewcolumndata] = useState(posCashregisterreport_head);
    const [limit, setLimit] = useState(100);
    const [datefilter, setDatefilter] = useState([]);
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [cashdata, setCashdata] = useState([])
    const [filtername, setFiltername] = useState("");
    const [upercount, setUpercount] = useState()
    const callcolumns = async () => {
        try {
            const postdata = {
                name: "report_Cashregisterreport_tbl",
            };
            let columnhead = await _Api(
                postdata,
                `api/v1/Inventory/common/getColumnHeads`
            );
            if (columnhead.code === 200) {
                setnewcolumndata(columnhead?.data?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        callcolumns();
    }, []);

    const filterbyname = (search) => {
        getcashregisterreport(currentPage, limit, search);
        setstate({ ...state, currentPage: 1 });
    };
    const getlimit = (limit) => {
        setLimit(limit);
        getcashregisterreport(1, limit);
        setstate({ ...state, currentPage: 1 });

    };
    const [state, setstate] = useState({
        totalPages: 20,
        currentPage: 1,
    });

    const { totalPages, currentPage } = state;
    const handlePaginations = (current) => {
        setstate({ ...state, currentPage: current });
        getcashregisterreport(current, "");

    };
    const getcashregisterreport = async (current, firstlimit, search) => {
        UnSetdataTable("cash_register_report");
        try {

            const postdata = {
                "search": search ? search : "",
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : "",
                "limit": firstlimit ? firstlimit : limit,
                "skip": current ? (current - 1) * limit : 0,

            };
            let res = await _Api(postdata, `api/v1/POS/report/cashRegister`)
            if (res.code === 200) {
                SetdataTable("cash_register_report", 0, newcolumndata, []);
                setstate({
                    ...state,
                    totalPages: Math.ceil(res?.total / postdata.limit),
                    currentPage: current ? current : 1
                });
                setCashdata(res.data)
                setUpercount(res?.count)
            }
            else {
                SetdataTable("cash_register_report", 0, newcolumndata, []);
            }
        }
        catch (err) {
            console.log(err, "err");
        }
    }
    useEffect(() => {
        if (datefilter[0] && datefilter[1]) {
            getcashregisterreport();
        }
        // eslint-disable-next-line
    }, [newcolumndata, datefilter]);

    const selectOptions = [
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 }
    ]
    return (<>
        <div className="main-body">
            <div className="main-body-row">
                <div className="main-body-row-left-col p-0 w-100">
                    <div className="catalog-area bg-white page-menu-block Dailysalereport">
                        <div className="Dailysalereport-header">
                            <div className="Dailysalereport-header-left">
                                <h3 className='common-navigation-header-left-heading Dailysalereport-head'> <Link to="" className='gray-mid text-decoration-none Dailysalereport-head-report'>{t("REPORT")}</Link> <img className="Dailysalereport-head-arrow mx-3" src={base_assets + "images/pos/report/arrow-right.png"} alt="" /><span className="Dailysalereport-head-pagename">{t("Cash register report")}</span> </h3>
                            </div>
                            <div className="Dailysalereport-header-reportdetail">
                                <div className="Dailysalereport-header-reportdetail-box">

                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/register.png"} alt="" />
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Register")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data lightblue">{upercount?.register}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box">

                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/cashier.png"} alt=""></img>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Cashier")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data yellow-text">{upercount?.user}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/loacation.png"} alt=""></img>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Location")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data lightgray">{upercount?.location}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <div className="Dailysalereport-header-reportdetail-box-inner">
                                            <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/value-img.png"} alt=""></img>
                                        </div>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Value")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data maingreen">{upercount?.total}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Dailysalereport-content-area">
                            <div className="Dailysalereport-content-area-header">
                                <div className="Dailysalereport-content-area-header-inner">
                                    <Reportmenupos
                                        Reportname={"Cash register report"} />
                                    <Select
                                        className="limit-select short mx-3"
                                        options={selectOptions}
                                        value={{ label: limit, value: limit }}
                                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                                    />
                                    <div className="date"> <Daterangepos setDatefilter={setDatefilter} /></div>
                                </div>
                                <div className="catalog-area-header-area-control-right">
                                    <div className="com-search-area">
                                        <input type="text" value={filtername} placeholder={t("Search  by Ref number, voucher type, ...")}
                                            onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                                            onChange={(e) => setFiltername(e.target.value)} />

                                        {filtername ? <img className="com-search-area-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}

                                        <img onClick={() => filterbyname(filtername)} className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />

                                    </div>

                                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                                        <button className='btn-style' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={base_assets + 'images/icons/ellipsis-circular.png'} alt="" /></button>
                                        <ul className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <Link className="dropdown-item  Dailysalereport-content-area-header-leftdropdown-menu-item"
                                                    to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/export-icon.png"} alt="" />
                                                    </div>
                                                    {t("Export")}</Link>
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target="#columnModal">
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/icons/column-icon.png"} alt="" />
                                                    </div>
                                                    {t("Column")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="Dailysalereport-content-area-main">
                                <div className="main-body-main-table-wrap Dailysalereport-table" id="table-scroll">
                                    <table id='cash_register_report' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                        <Tablehead tablehead={posCashregisterreport_head} tblname={"report_Cashregisterreport_tbl"} />
                                        <tbody>
                                            <>
                                                {cashdata.length ? cashdata.map((result, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{result?.outlate ? result?.outlate : "-"}</td>
                                                            <td>{result?.cashier ? result?.cashier : ""}</td>
                                                            <td>{result?.open_date ? result?.open_date : ""}</td>
                                                            <td>{result?.close_date ? result?.close_date : ""}</td>
                                                            <td>{result?.formatted_expected ? result?.formatted_expected : ""}</td>
                                                            <td>{result?.formatted_counted ? result?.formatted_counted : ""}</td>
                                                            <td>{result?.formatted_difference ? result?.formatted_difference : ""}</td>
                                                            <td>
                                                                {result?.printURL ? <div onClick={() => {
                                                                    if (result?.printURL) {
                                                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                                                    }
                                                                }}><img
                                                                        className="pdf-image"
                                                                        src={base_assets + "images/icons/pdf-icon.png"}
                                                                        alt=""
                                                                    /></div> : "-"
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : ""}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {cashdata.length > 0 ? (
                                <Pagination
                                    total={totalPages}
                                    current={currentPage}
                                    pagination={(crPage) => handlePaginations(crPage)}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <TableModal tablehead={posCashregisterreport_head} tblName={"report_Cashregisterreport_tbl"} setnewcolumndata={setnewcolumndata} />

        </div>
    </>
    )
}
export default Cashregisterreport;

























