import React, { useRef, useState, useEffect, useCallback } from "react"
import DateTime from '../common/DateTime';
import './StockTake.scss';
import { _Api } from '../api/_call';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { error, loading, Updateloading } from '../common/helpers/toastify';
import SocialModal from '../common/modules/SocialModal/SocialModal';
import Filtermodal from "../common/modules/Filtermodal";
import { VoucherList } from '../common/graphql/commonlayouts';
import { useTranslation } from 'react-i18next';
const StockTake = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [val1, setVal1] = useState([100])
  const [val2, setVal2] = useState([100])
  const [FiltersData, SetFiltersData] = useState('')
  const [stocklist, setStocklist] = useState()
  const [Total, setTotal] = useState()
  const [Searchvalue, setSearchvalue] = useState("")
  const [scannedlist, setScannedlist] = useState([])
  const [unknownlist, setUnknownlist] = useState([])
  const [commanSelected_vouc, setcommanSelected_vouc] = useState()
  const [socialurl, setSocialurl] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [details, setDetails] = useState()

  const stockTakelist = useCallback(async (current, firstlimit, filter) => {
    SetFiltersData(filter)
    try {
      const postdata = {
        item: filter?.item ? filter?.item : [],
        collection: filter?.collection ? filter?.collection : [],
        metal: filter?.metal ? filter?.metal : [],
        stone: filter?.stone ? filter?.stone : [],
        location: filter?.location ? filter?.location : [],
      }
      let res = await _Api(postdata, `api/v1/Inventory/stocktake/list`)
      if (res?.code === 200) {
        setStocklist(res?.data)
        let count = 0
        res?.data.forEach((result) => {
          count = count + result?.po_QTY
        })
        setTotal(count)
        setScannedlist([])
        setUnknownlist([])
      } else {
        error(t('Something Went Wrong!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [t]);
  const addedlist = () => {
    if (Searchvalue) {
      const mainarray = [...stocklist];
      const scannedarray = [...scannedlist];
      const unknownarray = [...unknownlist];
      const filtered = mainarray.filter(item =>
        parseInt(item?.stock_id) === parseInt(Searchvalue) || item?.SKU.toLowerCase() === Searchvalue.toLowerCase()
      );
      if (filtered?.length) {
        filtered.forEach(filter => {
          if (parseInt(filter?.stock_id) === parseInt(Searchvalue) || filter?.SKU.toLowerCase() === Searchvalue.toLowerCase()) {
            scannedarray.push(filter);
          } else {
            unknownarray.push({
              po_QTY: filter?.po_QTY,
              SKU: Searchvalue,
              _id: filter?._id,
              stock_id: Searchvalue,
              location_id: filter?.location_id,
            });
          }
          setStocklist(current => current.filter(item => item?._id !== filter?._id));
        });
        setScannedlist(scannedarray);
        setUnknownlist(unknownarray);
      } else {
        unknownarray.push({
          po_QTY: 0,
          SKU: Searchvalue,
          _id: "",
          stock_id: Searchvalue,
          location_id: "",
        });
        setUnknownlist(unknownarray);
      }
    }

    setSearchvalue('')
  }
  const deleteunknown = (key, product_id, array) => {
    setUnknownlist((current) => current.filter((item, index) => index !== key))
    if (product_id) {
      setStocklist([...stocklist, array])
    }
  }
  const deletescanned = (product_id, array) => {
    setScannedlist((current) =>
      current.filter((item) => item?._id !== product_id),
    )
    setStocklist([...stocklist, array])
  }
  const savelist = async () => {
    try {
      let total_scanned_qty = scannedlist
        ? scannedlist?.reduce(
          (interation, val) => (interation = interation + parseInt(val.po_QTY)),
          0,
        )
        : ''
      let total_unscanned_qty = stocklist
        ? stocklist?.reduce(
          (interation, val) => (interation = interation + parseInt(val.po_QTY)),
          0,
        )
        : ''
      let unknown_qty = unknownlist?.length ? unknownlist.reduce((interation, val) => (interation = interation + 1), 0) : 0
      let GetloadingID = loading();
      const postdata = {
        unscanned: stocklist,
        scanned: scannedlist,
        voucher_id: commanSelected_vouc,
        conflict: [],
        unknown: unknownlist,
        total_scanned_qty: total_scanned_qty,
        total_unscanned_qty: total_unscanned_qty,
        conflict_qty: 0,
        unknown_qty: unknown_qty,
        filter: {
          item: FiltersData?.item ? FiltersData?.item : [],
          collection: FiltersData?.collection ? FiltersData?.collection : [],
          metal: FiltersData?.metal ? FiltersData?.metal : [],
          location: FiltersData?.location ? FiltersData?.location : [],
          stone: FiltersData?.stone ? FiltersData?.stone : [],
        }
      }
      let res = await _Api(postdata, `api/v1/Inventory/stocktake/create`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, t('Successfully Created!!'));
      } else {
        Updateloading(t, GetloadingID, t('Something Went Wrong!!'), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getdetails = async (product_id) => {
    try {
      const postdata = {
        poProductId: product_id,
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/stocktake/stockTakePuDetailes`,
      )
      if (res?.code === 200) {
        setDetails(res?.data)
      } else {
        error(t('Something Went Wrong!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    stockTakelist()
  }, [stockTakelist])
  const getsharelowstocktake = async () => {
    let total_scanned_qty = scannedlist
      ? scannedlist?.reduce(
        (interation, val) => (interation = interation + parseInt(val.po_QTY)),
        0,
      )
      : ''
    let total_unscanned_qty = stocklist
      ? stocklist?.reduce(
        (interation, val) => (interation = interation + parseInt(val.po_QTY)),
        0,
      )
      : ''
    let unknown_qty = unknownlist?.length ? unknownlist.reduce((interation, val) => (interation = interation + 1), 0) : 0
    setShareloader(true)
    try {
      const postdata = {
        unscanned: stocklist,
        scanned: scannedlist,
        voucher_id: commanSelected_vouc,
        conflict: [],
        unknown: unknownlist,
        total_scanned_qty: total_scanned_qty,
        total_unscanned_qty: total_unscanned_qty,
        conflict_qty: 0,
        unknown_qty: unknown_qty,
        filter: {
          item: FiltersData?.item ? FiltersData?.item : [],
          collection: FiltersData?.collection ? FiltersData?.collection : [],
          metal: FiltersData?.metal ? FiltersData?.metal : [],
          location: FiltersData?.location ? FiltersData?.location : [],
          stone: FiltersData?.stone ? FiltersData?.stone : [],
          fromSave: false
        }
      }
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/stockTakeDetails`)

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const getprintstocktake = async () => {
    let total_scanned_qty = scannedlist
      ? scannedlist?.reduce(
        (interation, val) => (interation = interation + parseInt(val.po_QTY)),
        0,
      )
      : ''
    let total_unscanned_qty = stocklist
      ? stocklist?.reduce(
        (interation, val) => (interation = interation + parseInt(val.po_QTY)),
        0,
      )
      : ''
    let unknown_qty = unknownlist?.length ? unknownlist.reduce((interation, val) => (interation = interation + 1), 0) : 0
    let GetloadingID = loading();
    try {
      const postdata = {
        unscanned: stocklist,
        scanned: scannedlist,
        voucher_id: commanSelected_vouc,
        conflict: [],
        unknown: unknownlist,
        total_scanned_qty: total_scanned_qty,
        total_unscanned_qty: total_unscanned_qty,
        conflict_qty: 0,
        unknown_qty: unknown_qty,
        filter: {
          item: FiltersData?.item ? FiltersData?.item : [],
          collection: FiltersData?.collection ? FiltersData?.collection : [],
          metal: FiltersData?.metal ? FiltersData?.metal : [],
          location: FiltersData?.location ? FiltersData?.location : [],
          stone: FiltersData?.stone ? FiltersData?.stone : [],
        },
        fromSave: false
      }
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/stockTakeDetails`);

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a');
          exportLinkElement.hidden = true;
          exportLinkElement.download = res?.name ? res?.name : "report.pdf";
          exportLinkElement.href = res?.link;
          exportLinkElement.text = "downloading...";
          exportLinkElement.setAttribute('target', '_blank');
          document.body.appendChild(exportLinkElement);
          exportLinkElement.click();
          exportLinkElement.remove();
          Updateloading(t, GetloadingID, t("download successfully"));
        }, 50)
        setSocialurl(res?.link);
      } else {
        Updateloading(t, GetloadingID, res?.message, 'error');
      }
    }

    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, t('error'));
    }


  }
  return (
    <>
      <div className="main-body stock-take">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <h1 className="main-heading position-static">
                  {t('Stock Take')}
                </h1>
              </div>
              <div className="top-heading-area d-none">
                <VoucherList
                  setcommanSelected_vouc={setcommanSelected_vouc}
                  group={'Stock_Take'}
                ></VoucherList>
              </div>
              <div className="stock-take-inner">
                <div className="stock-take-inner-row">
                  <div className="stock-take-inner-row-col stock-take-inner-row-col-left">
                    <div className="stock-take-inner-row-col-left-connect stock-take-inner-row-col-left-btn">
                      <img
                        className="stock-take-inner-row-col-left-btn-img"
                        src={base_assets + '/images/icons/wifi-icon.png'} alt=""
                      />
                      {t('CONNECT')}
                    </div>
                    <div className="stock-take-inner-row-col-left-btn-row">
                      <button className="stock-take-inner-row-col-left-btn-row-col stock-take-inner-row-col-left-btn">
                        <img
                          className="stock-take-inner-row-col-left-btn-img"
                          src={base_assets + '/images/icons/play-icon.png'} alt=""
                        />
                        {t('START')}
                      </button>
                      <button className="stock-take-inner-row-col-left-btn-row-col stock-take-inner-row-col-left-btn link-color">
                        <img
                          className="stock-take-inner-row-col-left-btn-img"
                          src={base_assets + '/images/icons/refresh-icon.png'} alt=""
                        />
                        {t('REFRESH')}
                      </button>
                    </div>

                    <div className="stock-take-inner-row-col-left-power">
                      <button className="stock-take-inner-row-col-left-power-btn">
                        {t('POWER')}
                      </button>
                      <div className="stock-take-inner-row-col-left-power-progress-bar-area">
                        <div className="stock-take-inner-row-col-left-power-progress-bar-area-slider range-area-2">
                          <div className="range-area-2-num-row">
                            <span className="range-area-2-num-row-single">
                              {val1}
                            </span>
                            <span className="range-area-2-num-row-single">
                              1000
                            </span>
                          </div>
                          <Slider
                            min={0}
                            max={1000}
                            value={val1}
                            onChange={setVal1}
                          />
                        </div>
                        <div className="stock-take-inner-row-col-left-power-progress-bar-area-indicator">
                          <img
                            className="stock-take-inner-row-col-left-power-progress-bar-area-indicator-img"
                            src={base_assets + '/images/icons/power-icon.png'} alt=""
                          />
                          <span className="stock-take-inner-row-col-left-power-progress-bar-area-indicator-text">
                            {t('Power')}
                          </span>
                        </div>
                      </div>
                      <div className="stock-take-inner-row-col-left-power-progress-bar-area">
                        <div className="stock-take-inner-row-col-left-power-progress-bar-area-slider range-area-2">
                          <div className="range-area-2-num-row">
                            <span className="range-area-2-num-row-single">
                              {val2}
                            </span>
                            <span className="range-area-2-num-row-single">
                              1000
                            </span>
                          </div>
                          <Slider
                            min={0}
                            max={1000}
                            value={val2}
                            onChange={setVal2}
                          />
                        </div>
                        <div className="stock-take-inner-row-col-left-power-progress-bar-area-indicator">
                          <img
                            className="stock-take-inner-row-col-left-power-progress-bar-area-indicator-img"
                            src={
                              base_assets + '/images/icons/frequency-icon.png'
                            } alt=""
                          />
                          <span className="stock-take-inner-row-col-left-power-progress-bar-area-indicator-text">
                            {t("Frequency")}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="stock-take-inner-row-col-left-status-box total">
                      <label className="stock-take-inner-row-col-left-status-box-label">
                        {t('TOTAL')}
                      </label>
                      <span className="stock-take-inner-row-col-left-status-box-num">
                        {Total}
                      </span>
                    </div>
                    <div className="stock-take-inner-row-col-left-status-box scanned">
                      <label className="stock-take-inner-row-col-left-status-box-label">
                        {t('SCANNED')}
                      </label>
                      <span className="stock-take-inner-row-col-left-status-box-num">
                        {scannedlist
                          ? scannedlist?.reduce(
                            (interation, val) =>
                              (interation = interation + parseInt(val.po_QTY)),
                            0,
                          )
                          : ''}
                      </span>
                    </div>
                    <div className="stock-take-inner-row-col-left-status-box unscanned">
                      <label className="stock-take-inner-row-col-left-status-box-label">
                        {t('UNSCANNED')}
                      </label>
                      <span className="stock-take-inner-row-col-left-status-box-num">
                        {stocklist?.reduce(
                          (interation, val) => (interation = interation + parseInt(val.po_QTY)),
                          0,
                        )}
                      </span>
                    </div>
                    <div className="stock-take-inner-row-col-left-status-box-row">
                      <div className="stock-take-inner-row-col-left-status-box stock-take-inner-row-col-left-status-box-row-single conflict mb-0">
                        <label className="stock-take-inner-row-col-left-status-box-label">
                          {t('CONFLICT')}
                        </label>
                        <span className="stock-take-inner-row-col-left-status-box-num">
                          <img
                            src={
                              base_assets + '/images/icons/conflict-icon.png'
                            } alt=""
                          />{' '}
                          0
                        </span>
                      </div>
                      <div className="stock-take-inner-row-col-left-status-box stock-take-inner-row-col-left-status-box-row-single conflict mb-0">
                        <label>{t('UNKNOWN')}</label>
                        <span className="stock-take-inner-row-col-left-status-box-num">
                          {unknownlist?.length ? unknownlist.reduce(
                            (interation, val) =>
                              (interation = interation + 1),
                            0,
                          )
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="stock-take-inner-row-col stock-take-inner-row-col-mid">
                    <div className="stock-take-inner-row-col-mid-header">
                      <div className="stock-take-inner-row-col-mid-header-left">
                        <div className="stock-take-inner-row-col-mid-header-left-search">
                          <button
                            className="stock-take-inner-row-col-mid-header-left-search-filter btn-style"
                            data-bs-toggle="modal"
                            data-bs-target="#filterinventory"
                          >
                            <img
                              src={
                                base_assets +
                                'images/icons/filter-dark-icon.png'
                              } alt=""
                            />
                          </button>
                          <input
                            className="stock-take-inner-row-col-mid-header-left-search-input"
                            onKeyPress={(e) => e.key === 'Enter' && addedlist()}
                            value={Searchvalue}
                            onChange={(e) => setSearchvalue(e.target.value)}
                            placeholder={t('Barcode/QR code')}
                            type="text"
                          />
                          {Searchvalue ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => setSearchvalue("")} alt="" /> : ""}
                          <button
                            onClick={addedlist}
                            className="stock-take-inner-row-col-mid-header-left-search-icon btn-style"
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <div className="stock-take-inner-row-col-mid-header-right">
                        <button
                          className="btn-style stock-take-inner-row-col-mid-header-right-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#socialModal"
                          onClick={() => getsharelowstocktake()}
                        >
                          <img
                            className="share"
                            src={
                              base_assets + '/images/icons/share-icon-2x.png'
                            } alt=""
                          />
                        </button>
                        <button className="btn-style stock-take-inner-row-col-mid-header-right-btn"
                          onClick={() => getprintstocktake()}>
                          <img
                            className="printer"
                            src={
                              base_assets + '/images/icons/printer-icon-2x.png'
                            } alt=""
                          />
                        </button>
                        <button
                          className="btn-style stock-take-inner-row-col-mid-header-right-btn"
                          onClick={() => savelist()}
                        >
                          <img
                            className="save"
                            src={base_assets + '/images/icons/save-icon.png'} alt=""
                          />
                        </button>
                      </div>
                    </div>
                    <div className="stock-take-inner-row-col-mid-body">
                      <div className="stock-take-inner-row-col-mid-body-col">
                        <div className="stock-take-inner-row-col-mid-body-col-head sku">
                          {t('SKU')}
                        </div>
                        <div className="stock-take-inner-row-col-mid-body-col-table-wrap">
                          <table className="stock-take-inner-row-col-mid-body-col-table">
                            <tbody>
                              <>
                                {stocklist
                                  ? stocklist.map((result, key) => {
                                    return (
                                      <tr key={key}>
                                        <td
                                          className="link-color"
                                          onClick={() =>
                                            getdetails(result?._id)
                                          }
                                        >
                                          {result?.stock_id}
                                        </td>
                                        <td
                                          className="link-color cursor-pointer"
                                          onClick={() =>
                                            getdetails(result?._id)
                                          }
                                        >
                                          {result?.SKU}
                                        </td>
                                        <td>{result?.po_QTY}</td>
                                      </tr>
                                    )
                                  })
                                  : ''}
                              </>
                            </tbody>
                          </table>
                        </div>
                        <div className="stock-take-inner-row-col-mid-body-col-footer">
                          {stocklist?.reduce(
                            (interation, val) => (interation = interation + parseInt(val.po_QTY)),
                            0,
                          )}
                        </div>
                      </div>

                      <div className="stock-take-inner-row-col-mid-body-col">
                        <div className="stock-take-inner-row-col-mid-body-col-head scanned">
                          {t('SCANNED')}
                        </div>
                        <div className="stock-take-inner-row-col-mid-body-col-table-wrap">
                          <table className="stock-take-inner-row-col-mid-body-col-table">
                            <tbody>
                              <>
                                {scannedlist?.length
                                  ? scannedlist.map((result, key) => {
                                    return (
                                      <tr key={key}>
                                        <td
                                          className="link-color"
                                          onClick={() =>
                                            getdetails(result?._id)
                                          }
                                        >
                                          {result?.stock_id}
                                        </td>
                                        <td
                                          className="link-color"
                                          onClick={() =>
                                            getdetails(result?._id)
                                          }
                                        >
                                          {result?.SKU}
                                        </td>
                                        <td>{result?.po_QTY}</td>
                                        <td>
                                          <button
                                            className="stock-take-inner-row-col-mid-body-col-table-remove-btn btn-style"
                                            onClick={() =>
                                              deletescanned(
                                                result?._id,
                                                result,
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                base_assets +
                                                '/images/icons/minus-detail.png'
                                              } alt=""
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  : ''}
                              </>
                            </tbody>
                          </table>
                        </div>
                        <div className="stock-take-inner-row-col-mid-body-col-footer">
                          {scannedlist
                            ? scannedlist?.reduce(
                              (interation, val) =>
                                (interation = interation + parseInt(val.po_QTY)),
                              0,
                            )
                            : ''}
                        </div>
                      </div>

                      <div className="stock-take-inner-row-col-mid-body-col">
                        <div className="stock-take-inner-row-col-mid-body-col-head unknown">
                          {t('CONFLCT/UNKNOWN')}
                        </div>
                        <div className="stock-take-inner-row-col-mid-body-col-table-wrap">
                          <table className="stock-take-inner-row-col-mid-body-col-table">
                            <tbody>
                              <>
                                {unknownlist?.length
                                  ? unknownlist.map((result, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>{result?.SKU}</td>
                                        <td>{result?.po_QTY}</td>
                                        <td>
                                          <button
                                            className="stock-take-inner-row-col-mid-body-col-table-remove-btn btn-style"
                                            onClick={() =>
                                              deleteunknown(
                                                key,
                                                result?._id,
                                                result,
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                base_assets +
                                                '/images/icons/minus-detail.png'
                                              } alt=""
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  })
                                  : ''}
                              </>
                            </tbody>
                          </table>
                        </div>
                        <div className="stock-take-inner-row-col-mid-body-col-footer">
                          {unknownlist
                            ? unknownlist?.reduce(
                              (interation, val) =>
                                (interation = interation + 1),
                              0,
                            )
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  {details ? (
                    <div className="stock-take-inner-row-col stock-take-inner-row-col-right">
                      <div className="stock-take-inner-row-col-right-product">
                        <div className="stock-take-inner-row-col-right-product-img">
                          <img src={details?.mainImage} alt="" />
                        </div>
                        <h4 className="stock-take-inner-row-col-right-product-title">
                          {t('SKU :')} {details?.SKU}
                        </h4>
                        <label className="stock-take-inner-row-col-right-product-sku">
                          #{details?.name}
                        </label>
                        <ul className="stock-take-inner-row-col-right-product-detail">
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Metal')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              {details?.metal}
                            </span>
                          </li>
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Stone')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              {details?.Stone}
                            </span>
                          </li>
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Size')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              {details?.size}
                            </span>
                          </li>
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Collection')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              {details?.Collection}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="stock-take-inner-row-col-right-box">
                        <div className="stock-take-inner-row-col-right-box-data">
                          <span>{details?.locationName}</span>
                          <label>{t('Qty')}</label>
                        </div>
                        <div className="stock-take-inner-row-col-right-box-data">
                          <span>{details?.locationZone}</span>
                          <span>{details?.qty}</span>
                        </div>
                        <div className="stock-take-inner-row-col-right-box-description">
                          {details?.extraInfo}
                        </div>
                      </div>
                    </div>
                  ) :
                    <div className="stock-take-inner-row-col stock-take-inner-row-col-right">
                      <div className="stock-take-inner-row-col-right-product">
                        <div className="stock-take-inner-row-col-right-product-img">
                          <img src={base_assets + "images/blank-img-2.png"} alt="" />
                        </div>
                        <h4 className="stock-take-inner-row-col-right-product-title">
                          {t('SKU :')} XXXXXXX
                        </h4>
                        <label className="stock-take-inner-row-col-right-product-sku">
                          #{details?.name}
                        </label>
                        <ul className="stock-take-inner-row-col-right-product-detail">
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Metal')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              xx
                            </span>
                          </li>
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Stone')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              xx
                            </span>
                          </li>
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Size')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              xx
                            </span>
                          </li>
                          <li className="stock-take-inner-row-col-right-product-detail-single">
                            <label className="stock-take-inner-row-col-right-product-detail-single-label">
                              {t('Collection')}
                            </label>
                            <span className="stock-take-inner-row-col-right-product-detail-single-data">
                              xx
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="stock-take-inner-row-col-right-box">
                        <div className="stock-take-inner-row-col-right-box-data">
                          <span>{t("Location Name")}</span>
                          <label>{t('Qty')}</label>
                        </div>
                        <div className="stock-take-inner-row-col-right-box-data">
                          <span>xxx</span>
                          <span>xx</span>
                        </div>
                        <div className="stock-take-inner-row-col-right-box-description">

                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <Filtermodal submitfiltter={stockTakelist} limit={0} custom_filter_enable={false} />
      </div >
    </>
  );
};

export default StockTake;
