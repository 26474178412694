import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { _Api } from '../common/api/_call'
import CustomerSearchModal from '../common/module/customerSearch';
import { Saleperson } from '../common/graphql/commonlayouts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../catalog/catalog.scss';
import './checkout.scss';
import { useSelector } from 'react-redux';
import SaleHistory from '../common/Modals/SaleHistoryModal';
import { useTranslation } from 'react-i18next';
import AddCustomerAddressPopup from './addCustomerPopup/AddCustomerAddressPopup';
import CustomerInformation from '../../admin/customers/customerInformation/CustomerInformation'
import addressicon from '../../assets/images/pos/icons/script.png'
import { error } from '../common/helpers/toastify';
import notaddressicon from '../../assets/images/pos/icons/scriptnot.png'
import { store } from '../../source/index'
const CheckoutBlank = (props) => {
  const { t } = useTranslation();
  const location = useLocation()
  let state = store.getState()
  let OrgSettings = state?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [parkcount, setparkcount] = useState(0)
  const customer = useSelector((state) => state.app.customer)
  const saleperson = useSelector((state) => state.app.saleperson)
  const [cusid, setCusid] = useState();
  const [toggleAddressCustomer, setToggleAddressCustomer] = useState(false);
  const [TransactionDate, setTransactionDate] = useState(new Date())
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const getparkcount = async () => {
    try {
      const postdata = {};
      let res = await _Api(postdata, `api/v1/POS/park/getParkCount`);
      if (res.code === 200) {
        setparkcount(res?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    getparkcount();
  }, []);
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const parts = location.pathname.split('/');
  return (
    <>
      <div className="main-body-row-right-col-inner" id="checkoutblankdiv">
        <div className="main-body-row-right-col-inner-ct-top">
          <div className="main-body-row-right-col-inner-ct-top-inner">
            <div className="main-body-row-right-col-inner-ct-top-inner-date-area">
              <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner">
                <span>XXXX</span> :
                <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner-input date">
                  <DatePicker
                    className="date-field"
                    selected={TransactionDate}
                    onChange={(input) => setTransactionDate(input)}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    autoComplete="off"
                    popperPlacement='top-start'
                  />
                  <img
                    className="date-img"
                    src={base_assets + 'images/icons/calendar-green.svg'}
                    alt=""
                  />
                </div>
              </div>
              <div
                className={
                  saleperson?.value
                    ? 'main-body-row-right-col-inner-ct-top-inner-date-area-select ms-2'
                    : 'main-body-row-right-col-inner-ct-top-inner-date-area-select grey-select ms-2'
                }
              >
                <Saleperson />
              </div>
            </div>
            <div
              className={
                customer?.id
                  ? 'main-body-row-right-col-inner-ct-top-inner-search'
                  : 'main-body-row-right-col-inner-ct-top-inner-search grey-search'
              }
            >
              <CustomerSearchModal
                setCusid={setCusid}
              ></CustomerSearchModal>
              {parmissiondatashow?.pos_customer_reports?.Add ?
                <span
                  className="main-body-row-right-col-inner-ct-top-inner-search-user"
                  data-bs-toggle="modal"
                  data-bs-target="#customerAddModal"
                  onClick={() => setCusid("")}
                >
                  <img
                    src={base_assets + 'images/pos/icons/add-user.png'}
                    alt=""
                  />
                </span> : ""}

              {customer?.id ? (
                <span
                  className={
                    parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive" && parts[3] !== "reserveproduct" && parts[3] !== "reserveproduct"
                      ? "main-body-row-right-col-inner-ct-top-inner-search-user"
                      : "d-none"
                  }
                  onClick={() => { setToggleAddressCustomer(!toggleAddressCustomer); }}
                >
                  {customer && customer.existsInAddress ? (
                    <img src={addressicon} alt="Address Icon" />
                  ) : (
                    <img className="iconwithnotification" src={notaddressicon} alt="Not Address Icon" />
                  )}
                </span>
              ) : (
                <span
                  className={
                    parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive"
                      && parts[3] !== "reserveproduct" && parts[3] !== "reserveproduct"
                      ? "main-body-row-right-col-inner-ct-top-inner-search-user"
                      : "d-none"
                  }
                  onClick={() => { error(t("Customer is required!!")); }}
                >
                  <img src={addressicon} alt="Address Icon" />
                </span>
              )}
            </div>
          </div>
        </div>
        <div>
          {customer?.id ?
            <div>
              {toggleAddressCustomer ?
                <AddCustomerAddressPopup />
                :
                <>
                  <div className=' main-body-row-right-col-inner-progress'>
                    <div className="main-body-row-right-col-inner-progress-line">
                      <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                      <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                      <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                      <div className="main-body-row-right-col-inner-progress-line-single col"></div>
                    </div>

                    <div className="main-body-row-right-col-inner-progress-step">
                      <div className="main-body-row-right-col-inner-progress-step-single">
                        <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
                        <span className="main-body-row-right-col-inner-progress-step-single-text">
                          {t("sell")}
                        </span>
                      </div>
                      <div className="main-body-row-right-col-inner-progress-step-single second">
                        <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
                        <span className="main-body-row-right-col-inner-progress-step-single-text">
                          {t("Payment")}
                        </span>
                      </div>
                      <div className="main-body-row-right-col-inner-progress-step-single">
                        <div className="main-body-row-right-col-inner-progress-step-single-circle"></div>
                        <span className="main-body-row-right-col-inner-progress-step-single-text">
                          {t("done")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="main-body-row-right-col-inner-list box-1"></div>
                </>
              }
            </div>
            :
            <div className="main-body-row-right-col-inner-list box-1"></div>

          }
        </div>
        <div className="main-body-row-right-col-inner-summary box-2">
          {parkcount > 0 && generalsettings?.data?.generalsetup?.isPark === '1' ?
            <div
              className="sale-history-btn "
              data-bs-toggle="modal"
              data-bs-target="#sale-history-Modal"
            >
              <img
                className="sale-history-btn-img"
                src={base_assets + 'images/pos/icons/timer-icon.png'}
                alt=""
              />
              <span className="sale-history-btn-num">{parkcount}</span>
            </div>
            : ''
          }
        </div>
        {!toggleAddressCustomer &&
          <div className="main-body-row-right-col-inner-footer">
            <div className="main-body-row-right-col-inner-footer-row">
              <button className="pointer-none btn main-body-row-right-col-inner-footer-row-btn">
                {t("park")}
              </button>
              <button className="pointer-none btn checkout main-body-row-right-col-inner-footer-row-btn">
                {t("check out")}
              </button>
            </div>
            <p className="footer">{t("© 2022. All rights reserved.")}</p>
          </div>
        }
        {parkcount > 0 ? <SaleHistory /> : ''}
      </div>
      {parts[3] === 'customer' ? (
        ''
      ) : (
        <CustomerInformation
          cusid={cusid}
          setCusid={setCusid} />
      )}
    </>
  )
}

export default CheckoutBlank;
