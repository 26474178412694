import React, { useState, useEffect } from 'react'
import RightArrow from '../../common/icons/right-arrow'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TableLoader from '../../common/TableLoader'
import { _Api } from '../../api/_call'
const QuickStoreSetup = () => {
  const { t } = useTranslation()

  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [quicksetuplist, setQuicksetuplist] = useState([])
  const [showloader, setShowloader] = useState(false);
  const quicksetup = async () => {
    setShowloader(true);
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/quicksetup/get-location-list`)
      if (res?.code === 200) {
        setQuicksetuplist(res?.data)
        setShowloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    quicksetup()
  }, [])
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            {t('Setup')} <RightArrow />{' '}
            <span className="">{t('Quick Store/Outlet Setup')}</span>
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div
            className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
          >
            <table
              id="my_customer"
              className="com-custom-table-tag table align-middle"
            >
              <thead>
                <tr className="text-nowrap align-middle">
                  <th>#</th>
                  <th>{t('Store / Outlet')}</th>
                  <th>{t('Cash Register')}</th>
                </tr>
              </thead>
              <tbody>
                {quicksetuplist?.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td>{key + 1}</td>
                        <td>
                          <Link
                            to="/app/admin/setup/quickstoresetup/vouchertype"
                            className="text-decoration-none link-color"
                            state={{ location_id: result?._id }}
                          >
                            {result?.name && result?.name}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to="/app/admin/setup/quickstoresetup/cashregister"
                            state={{
                              locationdata: result,
                            }}
                            className="main-green-text text-decoration-none"
                          >
                            <img
                              className="me-2"
                              src={
                                base_assets + 'images/icons/add-detail.png'
                              }
                              alt=""
                            />
                            {result?.CashRegister && result?.CashRegister}
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            {showloader && <TableLoader />}
          </div>
        </div>
      </div>
    </div >
  )
}

export default QuickStoreSetup
