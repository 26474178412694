import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import { _Api } from '../api/_call'
import { SetdataTable, UnSetdataTable } from '../api/setdatatable'
import { Tablehead } from '../common/modules/Tablehead'
import { reservelisthead } from '../common/Tablehead'
import DeleteModal from '../common/modules/deletemodal'
import TableColumnModal from '../common/modules/tableColumn'
import SocialModal from '../common/modules/SocialModal/SocialModal'
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function'
import { Pagination } from '../common/Pagination'
import ProductImageSliderModal from '../common/modules/productImageslider'
import { loading, Updateloading } from '../common/helpers/toastify'
import { useTranslation } from 'react-i18next'
import * as FileSaver from 'file-saver'
import Select from "react-select";
import * as XLSX from 'xlsx'
import { useSelector } from 'react-redux'
import Filtermodal from "../common/modules/Filtermodal";
import TableLoader from '../../admin/common/TableLoader';
const ReserveList = (props) => {
  let navigate = useNavigate()
  const { t } = useTranslation()
  const dataFetched = useRef();
  const [reserverlist, setReservelist] = useState([])
  const [limit, setLimit] = useState(100)
  const [filtername, setFiltername] = useState('')
  const [reserverlistcount, setReserverlistcount] = useState([])
  const [FiltersData, SetFiltersData] = useState('')
  const [newcolumndata, setnewcolumndata] = useState(reservelisthead)
  const [selecteditem, setSelecteditem] = useState([])
  const [imageid, setImageid] = useState()
  const [SelectAllStatus, setSelectAllStatus] = useState()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [socialurl, setSocialurl] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [selecttype, setSelecttype] = useState()
  const [customer_id, setCustomer_id] = useState()
  const [reserve_id, setreserve_id] = useState()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [datalodaer, setDatsLodaer] = useState(false)
  const getsharerservelist = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        sku: FiltersData?.sku ? FiltersData?.sku : [],
        item: FiltersData?.item ? FiltersData?.item : [],
        collection: FiltersData?.collection ? FiltersData?.collection : [],
        metal: FiltersData?.metal ? FiltersData?.metal : [],
        size: FiltersData?.size ? FiltersData?.size : [],
        productStatus: FiltersData?.productStatus
          ? FiltersData?.productStatus
          : [],
        location: FiltersData?.location ? FiltersData?.location : [],
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/inventory/print/reserveList`,
      )

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getprintrservelist = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: filtername,
        sku: FiltersData?.sku ? FiltersData?.sku : [],
        item: FiltersData?.item ? FiltersData?.item : [],
        collection: FiltersData?.collection ? FiltersData?.collection : [],
        metal: FiltersData?.metal ? FiltersData?.metal : [],
        size: FiltersData?.size ? FiltersData?.size : [],
        productStatus: FiltersData?.productStatus
          ? FiltersData?.productStatus
          : [],
        location: FiltersData?.location ? FiltersData?.location : [],
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/inventory/print/reserveList`,
      )

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const filterbyname = (search) => {
    GetReservelist(currentPage, limit, FiltersData, search)
    setstate({ ...state, currentPage: 1 })
  }
  const getlimit = (limit) => {
    setLimit(limit)
    GetReservelist(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })

  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    GetReservelist(current, '')
  }
  const GetReservelist = useCallback(async (current, firstlimit, filter, search) => {
    SetFiltersData(filter)
    setDatsLodaer(true)
    try {
      const postdata = {
        item: filter?.item ? filter?.item : [],
        collection: filter?.collection ? filter?.collection : [],
        metal: filter?.metal ? filter?.metal : [],
        size: filter?.size ? filter?.size : [],
        stone: filter?.stone ? filter?.stone : [],
        productStatus: filter?.productStatus
          ? filter?.productStatus
          : [],
        location: filter?.location ? filter?.location : [],
        price: filter?.price ? filter?.price : [],
        limit: firstlimit ? firstlimit : limit,
        search: search ? search : '',
        skip: current ? (current - 1) * limit : 0,
      }
      UnSetdataTable('reserve_list')
      let res = await _Api(postdata, `api/v1/Inventory/my/getReserveList`)
      if (res?.code === 200) {
        SetdataTable('reserve_list', '4', newcolumndata)
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1,
        })
        setReservelist(res.data)
        setReserverlistcount(res.count)
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable('reserve_list', '4', newcolumndata)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [newcolumndata, limit, state]);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_inventory_reservelist',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const deleteProduct = async () => {
    try {
      const postdata = {
        ids: selecteditem,
      }
      let res = await _Api(postdata, `api/v1/Inventory/my/deleteReserveList`)
      if (res?.code === 200) {
        setSelecteditem([])
        GetReservelist()
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll('.reservelisthead')
      let arrya = []
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let reserve_id = selected[i].getAttribute('data-id')
          arrya.push(reserve_id)
        }
      }
      setSelecteditem(arrya)
    } else {
      setSelecteditem([])
    }
  }, [SelectAllStatus])
  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    GetReservelist()
  }, [GetReservelist, FiltersData])

  const deallcocation = async (event) => {
    let GetloadingID = loading()
    try {
      const postdata = {
        po_product_id: reserve_id,
        customOrderId: customer_id,
        type: event,
      }
      let res = await _Api(postdata, `api/v1/Inventory/po/deallocation`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t('Deallocation susses!!'))
        setreserve_id()
        setCustomer_id()
        const timer = setTimeout(() => {
          navigate('/app/inventory/allocationpo')
        }, 100)
        return () => clearTimeout(timer)
      } else {
        Updateloading(t, GetloadingID, t('No item Selected!!'), t('error'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const secectitem = (event) => {
    const array = [...selecteditem]
    const mainarray = [...reserverlist]
    if (event.target.checked) {
      const currentid = mainarray.filter((all) => {
        return all.reserve_id === event.target.value
      })
      setSelecttype(currentid[0]?.type)
      mainarray.forEach((result, key) => {
        if (result.type === 'POS_Order') {
          if (result?.reserve_id !== currentid[0]?.reserve_id) {
            mainarray[key] = {
              ...mainarray[key],
              'rowclass': 'row-inactive',
            }
          }
        } else {
          if (result?.type !== currentid[0]?.type) {
            mainarray[key] = {
              ...mainarray[key],
              'rowclass': 'row-inactive',
            }
          } else {
            mainarray[key] = {
              ...mainarray[key],
              'rowclass': '',
            }
          }
        }
      })
      setReservelist(mainarray)
      array.push(event.target.value)
      setSelecteditem(array)
    } else {
      if (array.length === 1) {
        setSelecteditem((current) =>
          current.filter((item) => item !== event.target.value),
        )
        mainarray.forEach((result, key) => {
          mainarray[key] = {
            ...mainarray[key],
            'rowclass': '',
          }
          setReservelist(mainarray)
        })
      } else {
        setSelecteditem((current) =>
          current.filter((item) => item !== event.target.value),
        )
      }
    }
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    reservelisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (reserverlist.length) {

      reserverlist.forEach((result, key) => {
        bodyarray.push([
          '',
          key + 1,
          result?.main_image,
          result?.CreatedDate,
          result?.SKU,
          result?.stockId,
          '',
          result?.metal_name,
          result?.stone_name,
          result?.size_name,
          result?.price,
          result?.Qty,
          result?.location_name,
          result?.POS,
          result?.customer_name,
          result?.DeliveryDate,
          result?.due_days,
          result?.salesPerson_name,
          result?.remark,
          result?.printURL,

        ])
      })
    }
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'reserve_list')
  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="main-body-current-day-time"></p>
              <h1 className="main-body-heading">{t('Reserve List')}</h1>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/reserved-qty.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Reserved Qty')}</p>
                      <h2 className="inventory-value stock">
                        {reserverlistcount?.qty ? reserverlistcount?.qty : '0'}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/sku.png'}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('SKU')}</p>
                      <h2 className="inventory-value sku">
                        {reserverlistcount?.SKU ? reserverlistcount?.SKU : '0'}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/customer.png'}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Customer')}</p>
                      <h2 className="inventory-value value">
                        {reserverlistcount?.customer
                          ? reserverlistcount?.customer
                          : '0'}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/location.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Location')}</p>
                      <h2 className="inventory-value location">
                        {reserverlistcount?.location
                          ? reserverlistcount?.location
                          : '0'}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />

                <div className="main-body-top-status-bar-filter">
                  <button
                    className="main-body-top-status-bar-filter-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#filterinventory"
                  >
                    <img
                      className="d-block"
                      src={base_assets + '/images/icons/search-outline.png'}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('Product name/StockID/SKU/Metal/Stone')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <div className="status-inventory">
                    <div className="status-inventory-stock">
                      {selecttype === 'POS_Order' ? (
                        <button
                          className={
                            selecteditem.length > 0
                              ? 'deletebtn btn-style d-block'
                              : 'd-none'
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#allocation-listedModal"
                        >
                          <img
                            src={base_assets + 'images/icons/delete-icon.png'}
                            alt=""
                          />
                        </button>
                      ) : (
                        <button
                          className={
                            selecteditem.length > 0
                              ? 'deletebtn btn-style d-block'
                              : 'd-none'
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#delete-listedModal"
                        >
                          <img
                            src={base_assets + 'images/icons/delete-icon.png'}
                            alt=""
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className={
                          parmissiondatashow?.reserve_list?.Print
                            ? 'dropdown-item print-dropdown-item'
                            : 'd-none'
                        }
                        onClick={(e) => {
                          getprintrservelist(e.target.value)
                        }}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={(e) => {
                          downloadexclsheet(e.target.value)
                        }}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#socialModal"
                        onClick={() => getsharerservelist()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/share-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('share')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  id="reserve_list"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead
                    tablehead={reservelisthead}
                    tblName={'tbl_inventory_reservelist'}
                    setShowoption={setSelectAllStatus}
                  />
                  <tbody>
                    <>
                      {reserverlist?.length
                        ? reserverlist.map((result, key) => {
                          return (
                            <tr
                              key={key}
                              className={
                                result?.rowclass ? result?.rowclass : ''
                              }
                            >

                              <td>
                                <div className="com-check" id="">
                                  <input
                                    className="reservelisthead"
                                    value={result?.reserve_id}
                                    onChange={secectitem}
                                    data-id={result?.reserve_id}
                                    type={result?.rowclass === "row-inactive" ? "" : "checkbox"}
                                    id={result?.reserve_id}
                                    onClick={(e) => {
                                      setreserve_id(result?.reserve_id)
                                      setCustomer_id(result?.cart_id)
                                    }}
                                  />
                                  <label
                                    htmlFor={result?.reserve_id}
                                    className="com-check-label"
                                  />
                                </div>
                              </td>
                              <td >{key + 1}</td>
                              <td
                                className="fixed-side table-pro-img"
                                onClick={() => setImageid(result?.product_id)}
                                data-bs-toggle="modal"
                                data-bs-target="#ProductViewModal"
                              >
                                <img
                                  className="inventory-items"
                                  src={
                                    result?.main_image
                                      ? result?.main_image
                                      : base_assets +
                                      'images/icon/camera_profile.png'
                                  }
                                  alt=""
                                />
                              </td>
                              <td className="fixed-side">
                                {result?.CreatedDate
                                  ? result?.CreatedDate
                                  : ''}{' '}
                              </td>
                              <td className="fixed-side light-blue-text">
                                {result?.SKU ? result?.SKU : ''}
                              </td>
                              <td
                                className={
                                  result.type === 'POS_Order'
                                    ? 'danger-text'
                                    : 'yellow-text'
                                }
                              >
                                {result?.stockId ? result?.stockId : ''}
                              </td>
                              <td className="fixed-side danger-text po-no">
                                <div className="td-icons-wrap">
                                  {result?.pos_Qty !== 0 ? (
                                    <div
                                      className="td-icons-wrap-user tooltip-area-up"
                                      id="deletereserve"
                                      data-bs-toggle="modal"
                                      data-bs-target="#allocation-listedModal"
                                      onClick={() => {
                                        setreserve_id(result?.reserve_id)
                                        setCustomer_id(result?.cart_id)
                                      }}
                                    >
                                      <img
                                        className="img cursor-pointer"
                                        src={
                                          base_assets +
                                          'images/icons/user-red.png'
                                        }
                                        alt=""
                                      />
                                      <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.POS}<br />{result?.pos_delivery_date}</span>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </td>
                              <td>
                                {result?.metal_name ? result?.metal_name : ''}
                              </td>
                              <td>
                                {result?.stone_name ? result?.stone_name : ''}
                              </td>
                              <td>
                                {result?.size_name ? result?.size_name : ''}
                              </td>
                              <td className="fixed-side">
                                {result?.price ? result?.price : ''}
                              </td>
                              <td className="fixed-side">
                                {result?.Qty ? result?.Qty : ''}
                              </td>
                              <td className="fixed-side link-color text-center">
                                {result?.location_name
                                  ? result?.location_name
                                  : ''}
                                <span className="td-sub-text text-danger">
                                  {result?.warehouse_name
                                    ? result?.warehouse_name
                                    : ''}
                                </span>
                              </td>
                              <td className="fixed-side danger-text">
                                {result?.POS ? result?.POS : ''}
                              </td>
                              <td className="fixed-side danger-text">
                                {result?.customer_name
                                  ? result?.customer_name
                                  : ''}
                              </td>
                              <td className="text-danger">
                                {result?.DeliveryDate
                                  ? result?.DeliveryDate
                                  : ''}
                              </td>
                              <td className="text-danger">
                                {result?.due_days ? result?.due_days : '0'}
                              </td>
                              <td className="fixed-side danger-text">
                                {result?.salesPerson_name
                                  ? result?.salesPerson_name
                                  : '-'}
                              </td>
                              <td className="fixed-side danger-text">
                                {result?.remark ? result?.remark : '-'}
                              </td>

                              <td className="fixed-side">
                                {result?.printURL === '' ? (
                                  '-'
                                ) : (
                                  <div
                                    onClick={() => {
                                      if (result?.printURL) {
                                        window.open(
                                          result?.printURL,
                                          '_blank',
                                          'noreferrer',
                                        )
                                      }
                                    }}
                                  >
                                    <img
                                      className="pdf-image"
                                      src={
                                        base_assets +
                                        'images/icons/pdf-icon.png'
                                      }
                                      alt=""
                                    />
                                  </div>
                                )}

                              </td>
                            </tr>
                          )
                        })

                        : ''}
                    </>
                  </tbody >
                  <tfoot>
                    <tr>
                      <td
                        colSpan={4}
                        className="main-green-text left-0 text-start"
                      >
                        {selecteditem.length > 0 ? (
                          <span>
                            {selecteditem.length} {t('item Selected')}
                          </span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        {toFormatPrice(
                          reserverlist?.reduce(
                            (interation, val) =>
                            (interation =
                              interation +
                              parseFloat(toUnformatPrice(val.price))),
                            0,
                          ),
                          { addSymbol: true })}
                      </td>
                      <td>
                        {reserverlist?.reduce(
                          (interation, val) =>
                            (interation = interation + parseInt(val.Qty)),
                          0,
                        )}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table >
                {datalodaer && <TableLoader />}
              </div >
            </div >
            {
              reserverlist.length > 0 ? (
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  pagination={(crPage) => handlePaginations(crPage)}
                />
              ) : (
                ''
              )
            }
          </div >
        </div >
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <Filtermodal submitfiltter={GetReservelist} limit={limit} custom_filter_enable={true} />
        <ProductImageSliderModal main_id={imageid}></ProductImageSliderModal>
        <div
          className="modal fade common-modal common-modal-danger"
          id="allocation-listedModal"
          tabIndex={-1}
          aria-labelledby="allocation-listedModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img
                  className="common-modal-exclamation-img"
                  src={base_assets + 'images/icons/exclamation-mark-red.png'}
                  alt=""
                />
                <p className="modal-content-text">
                  {t('Are you sure you want to Free this item ?')}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn modal-content-no"
                  data-bs-dismiss="modal"
                  onClick={(e) => deallcocation('NO')}
                >
                  {t('Order Close')}
                </button>
                <button
                  type="button"
                  className="btn modal-content-yes"
                  data-bs-dismiss="modal"
                  onClick={(e) => deallcocation('YES')}
                >
                  {t('Deallocate')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div >
      <DeleteModal
        statement="Reserve Product"
        handler_delete={deleteProduct}
      ></DeleteModal>
      <TableColumnModal
        tablehead={reservelisthead}
        tblName={'tbl_inventory_reservelist'}
        calldatatable={GetReservelist}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
    </React.Fragment >
  )
}

export default ReserveList
