import React, { useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { toggleSourceCodeMode1, setToggleFunction } from "./customtoolbar";
Quill.register("modules/imageResize", ImageResize);

const RichTextEditor = (props) => {
  const quillRef = useRef();
  const { availableTags, editorValue, setEditorValue } = props
  const [sourceCodeMode, setSourceCodeMode] = useState(false);
  const [sourceCode, setSourceCode] = useState("");
  const toggleSourceCodeMode = () => {
    setSourceCodeMode(!sourceCodeMode);
    setEditorValue(sourceCode);
    const quillEditor = quillRef.current.getEditor();
    const containerElement = quillEditor.container;
    if (!sourceCodeMode) {
      if (containerElement) {
        containerElement.style.cssText = `display: ${"none !important"}`;
      }
    } else {
      containerElement.style.cssText = `display: ${""}`;
    }
  };

  setToggleFunction(toggleSourceCodeMode);

  const colorOptions = [
    "red",
    "white",
    "black",
    "blue",
    "yellow",
    "green",
    "orange",
    "purple",
    "pink",
    "brown",
    "cyan",
    "magenta",
    "#666",
  ];

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline", { background: colorOptions }],
        [{ color: colorOptions }],
        ["link", "image"],
        ["clean"],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ align: [] }],
        ["blockquote", "video", "code-block"],
        [{ size: ["small", false, "large", "huge"] }],
      ],
      handlers: {
        "code-block": toggleSourceCodeMode1,
      },
    },
    imageResize: {
      modules: ["Resize", "DisplaySize"],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "color",
    "list",
    "bullet",
    "link",
    "image",
    "background",
    "clean",
    "script",
    "indent",
    "direction",
    "align",
    "blockquote",

    "code",
    "video",
    "size",
  ];

  const handleEditorChange = (value) => {
    setEditorValue(value);
    setSourceCode(value);
  };

  const handleTagClick = (tag) => {
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      const cursorPosition = selection.index;
      quill.insertText(cursorPosition, `[${tag}]`, "user");
      quill.setSelection(cursorPosition + tag.length + 2, 0);
    } else {
      quill.insertText(quill.getLength(), `[${tag}]`, "user");
      quill.setSelection(quill.getLength(), 0);
    }
  };

  return (
    <div>
      <div>
        {availableTags?.length ? availableTags.map((tag, key) =>
        (
          <button
            key={key}
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={() => handleTagClick(tag?.name)}
          >
            {tag?.name}
          </button>
        )) : ""}
        <ReactQuill
          theme="snow"
          ref={quillRef}
          value={editorValue}
          onChange={handleEditorChange}
          modules={modules}
          formats={formats}
        />
        <textarea
          style={{
            width: "100%",
            height: "300px",
            display: sourceCodeMode ? "block" : "none",
          }}
          value={sourceCode}
          onChange={(e) => setSourceCode(e.target.value)}
        />
      </div>
    </div>
  );
};

export default RichTextEditor;
