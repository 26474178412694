import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './customer.scss';
import CheckoutBlank from "../checkout/checkoutBlank";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { _Api } from "../common/api/_call";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { poscreditnote_head } from "../../components/common/Tablehead";
import { useTranslation } from 'react-i18next';
import { error } from '../common/helpers/toastify';
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function';
import { store } from '../../source/index'
const CreditNote = () => {
  const { t } = useTranslation();
  const [newcolumndata, setnewcolumndata] = useState(poscreditnote_head);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [creditlist, setCreditlist] = useState([])
  const [TransactionDate, setTransactionDate] = useState(new Date());
  const itemdaata = localStorage.getItem("customerdetails")
  const parsedObject = JSON.parse(itemdaata);
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const selectTransactionDate = (input) => {
    setTransactionDate(input);

  };
  const getcreditnote = async () => {
    UnSetdataTable('credit_note_table');
    if (!parsedObject?.id) {
      error(t("Customer is Requried!!"));
    }

    try {
      let postdata = {
        "customer_id": parsedObject.id,
        "date": TransactionDate
      }
      let res = await _Api(postdata, `api/v1/POS/checkout/creditnote/getCreditNote`)
      if (res?.code === 200) {
        setCreditlist(res?.data)
        SetdataTable("credit_note_table", 0, newcolumndata, [0]);
      }
      else {
        SetdataTable("credit_note_table", 0, newcolumndata, [0]);
      }
    }
    catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    getcreditnote();
    // eslint-disable-next-line
  }, [newcolumndata, parsedObject?.id, TransactionDate])
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "creditnote_tbl",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);

      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    callcolumns();
  }, []);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="customer">
              <div className='customer-inner'>
                <div className="customer-inner-tophead">
                  <div className=" transactionhistory-heading d-flex gap-3 align-items-center">
                    <Link to="/app/pos/customer/customer" >
                      <img className="transactionhistory-heading-backbtn" src={base_assets + "images/pos/backbtn.png"} alt=""></img></Link>
                    <h1 className="main-heading">{t("Credit Note")}</h1>
                  </div>
                </div>
                <div className="customer-inner-data">
                  <div className='customer-inner-data-payment-table-top-bar'>
                    <div className='customer-inner-data-payment-table-top-bar-left'>
                    </div>
                    <div className='customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date'>
                      <DatePicker
                        className="placeholder-up-input-field"
                        selected={TransactionDate}
                        onChange={selectTransactionDate}
                        name="TransactionDate"
                        dateFormat={OrgSettings?.date_picker}
                        autoComplete='none'
                      />
                      <img
                        className="placeholder-up-input-field-calender"
                        src={base_assets + "images/icons/calendar-green.svg"}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="main-body-main-table-wrap vertical-scroll-table" id="table-scroll">
                    <table id="credit_note_table" className="common-table first-row-gray w-100">
                      <Tablehead tablehead={poscreditnote_head} tblname={"creditnote_tbl"} setfilterby={""} />
                      <tbody>
                        <>
                          {creditlist.length ? creditlist.map((result, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date}</td>
                                <td>{result?.type}</td>
                                <td>{result?.Ref_No}</td>
                                <td>{result?.location_name}</td>
                                <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                              </tr>
                            )
                          }) : ""}
                        </>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{creditlist?.reduce(
                            (interation, val) => (interation = interation + toUnformatPrice(val.amount)),
                            0
                          )}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )
}

export default CreditNote;