import React, { useEffect } from 'react'
import "./common.scss"
import loginsideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { useState } from 'react'
import RightArrowIcon from "../assets/icons/RightArrowIcon"
import { _Apiauth } from '../../common/api'
import { useTranslation } from "react-i18next";
const ForgetPassword = (props) => {
    const { setOpenmodal, setForgetemail } = props
    const { t } = useTranslation();
    const [logindata, setLogindata] = useState()
    const [validation, setValidation] = useState()
    const [lodaer, setLodaer] = useState(false)
    const alllogindata = (keyName, value) => {
        setLogindata((prevState) => ({
            ...prevState,
            [keyName]: value,
        }))
    }
    // eslint-disable-next-line
    const forgetpasswordapi = async () => {
        if (!logindata?.email) {
            setValidation((state) => ({
                ...state,
                emailvalid: {
                    status: true,
                    message: "Email is required"

                }
            }))
            return;
        } else {
            setValidation((state) => ({
                ...state,
                emailvalid: {
                    status: false,
                    message: ""

                }
            }))
        }

        try {
            setLodaer(true)
            let postdata = logindata
            let res = await _Apiauth(postdata, `auth/forgot-password`)
            if (res?.statusCode === 200) {
                setLodaer(false)
                await setForgetemail((state) => ({
                    ...state,
                    email: logindata?.email,
                    resetToken: res?.resetToken
                })
                )
                setOpenmodal(prevState => ({
                    ...prevState,
                    showLoginModel: false,
                    showRegisterModel: false,
                    showemailvarifymodal: false,
                    forgetpassword: false,
                    resetpassword: true,
                    newpassword: false
                }))

            } else {
                setLodaer(false)
            }
        }
        catch (err) {
            setLodaer(false)
            console.log(err, "err")
            if (err.response.status) {
                setValidation(prevState => ({
                    ...prevState,
                    sussess: {
                        message: err.response.data.message,
                        status: true
                    }
                }));
            }
        }
    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                forgetpasswordapi();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [forgetpasswordapi]);
    return (
        <div className='ecommerce-login-overlay'>
            <div className='ecommerce-login'>
                <span className='Cross_icon' onClick={() => {
                    setOpenmodal(prevState => ({
                        ...prevState,
                        showLoginModel: false,
                        showRegisterModel: false,
                        showemailvarifymodal: false,
                        forgetpassword: false,
                        resetpassword: false,
                        newpassword: false
                    }));
                }} >
                    <CrossIcon width={30} height={30} />
                </span>
                <div className='ecommerce-login-inner forget-password d-flex flex-column h-100' >
                    <div className='d-flex flex-column'>
                        <div className='ecommerce-login-inner-title'>
                            <div className='ecommerce-login-inner-title-heading text-center'>{t("Forgot Password")}</div>
                            <div className='ecommerce-login-inner-title-sub-title text-center'> {t("No worries, we’ll send you reset instructions")}. </div>
                        </div >
                        <div className='ecommerce-login-inner-input_section'>
                            <div className={`ecommerce-login-inner-input_section-inner ${validation?.emailvalid?.status && "required"}`}>
                                <label>
                                    {t("Enter Email Address")}
                                </label>
                                <input className={`ecommerce-login-inner-input_section-inner-input ${validation?.emailvalid?.status && "border-danger"}`}
                                    onChange={(e) => alllogindata("email", e.target.value)} />
                                {validation?.emailvalid?.status ?
                                    <div className='text-danger'>
                                        {t(`${validation?.emailvalid?.message}`)}
                                    </div>
                                    : ""}
                            </div>
                            {validation?.sussess?.status ?
                                <div className='text-danger'>{t(`${validation?.sussess?.message}`)}</div> : ""
                            }
                            {lodaer ?
                                <button className='ecommerce-login-inner-input_section-btn'
                                > <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div></button> :
                                <button className='ecommerce-login-inner-input_section-btn'
                                    onClick={() => {
                                        forgetpasswordapi()
                                    }} >{t("Submit")}</button>
                            }

                        </div>
                        <div className='ecommerce-login-inner-create-account'>
                            <div className='ecommerce-login-inner-create-account-register_btn' onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: true,
                                    showRegisterModel: false,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false

                                }))
                            }}>
                                <RightArrowIcon wvw="0.938vw" /> {t("Back to Sign In")}
                            </div>
                        </div>
                    </div>
                    <div className='ecommerce-login-inner-create-account mt-auto'>
                        <div className='ecommerce-login-inner-create-account-register_btn'>
                            {t("Already have an account")}?
                            <span onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: true,
                                    showRegisterModel: false,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false

                                }))
                            }}>
                                {t("SIGN IN")}
                            </span>
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice'>
                            {t("By continuing, I confirm that I have read and agree to the")}
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice no_padding'>
                           {t("Privacy Notice")} {t("and Term and Conditions")}
                        </div>
                    </div>
                </div >
                <div className='ecommerce-login-sidebanner'>

                    <img src={loginsideBanner} alt='side_banner' />
                </div >
            </div >
        </div >
    )
}

export default ForgetPassword