import React from "react";

const Organization = () => {
  return(
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.75 2C9.82164 2 7.93657 2.57183 6.33319 3.64317C4.72982 4.71451 3.48013 6.23726 2.74218 8.01884C2.00422 9.80042 1.81114 11.7608 2.18735 13.6521C2.56355 15.5434 3.49215 17.2807 4.85571 18.6443C6.21928 20.0079 7.95656 20.9365 9.84787 21.3127C11.7392 21.6889 13.6996 21.4958 15.4812 20.7578C17.2627 20.0199 18.7855 18.7702 19.8568 17.1668C20.9282 15.5634 21.5 13.6784 21.5 11.75C21.4973 9.16498 20.4692 6.68661 18.6413 4.85872C16.8134 3.03084 14.335 2.00273 11.75 2ZM9.27782 15.5H14.2222C13.7188 17.2194 12.875 18.7691 11.75 19.9897C10.625 18.7691 9.78125 17.2194 9.27782 15.5ZM8.9375 14C8.68876 12.5103 8.68876 10.9897 8.9375 9.5H14.5625C14.8113 10.9897 14.8113 12.5103 14.5625 14H8.9375ZM3.5 11.75C3.49935 10.9892 3.60442 10.2319 3.81219 9.5H7.41782C7.19407 10.9917 7.19407 12.5083 7.41782 14H3.81219C3.60442 13.2681 3.49935 12.5108 3.5 11.75ZM14.2222 8H9.27782C9.78125 6.28062 10.625 4.73094 11.75 3.51031C12.875 4.73094 13.7188 6.28062 14.2222 8ZM16.0822 9.5H19.6878C20.1041 10.9711 20.1041 12.5289 19.6878 14H16.0822C16.3059 12.5083 16.3059 10.9917 16.0822 9.5ZM19.0972 8H15.7756C15.3929 6.49392 14.7501 5.06623 13.8763 3.78125C14.9927 4.08127 16.0339 4.61162 16.9331 5.33818C17.8323 6.06475 18.5694 6.97146 19.0972 8ZM9.62375 3.78125C8.7499 5.06623 8.10713 6.49392 7.72438 8H4.40282C4.93056 6.97146 5.66772 6.06475 6.56689 5.33818C7.46606 4.61162 8.50733 4.08127 9.62375 3.78125ZM4.40282 15.5H7.72438C8.10713 17.0061 8.7499 18.4338 9.62375 19.7188C8.50733 19.4187 7.46606 18.8884 6.56689 18.1618C5.66772 17.4352 4.93056 16.5285 4.40282 15.5ZM13.8763 19.7188C14.7501 18.4338 15.3929 17.0061 15.7756 15.5H19.0972C18.5694 16.5285 17.8323 17.4352 16.9331 18.1618C16.0339 18.8884 14.9927 19.4187 13.8763 19.7188Z" fill="white"/>
      </svg>

    </>
  )
}

export default Organization;