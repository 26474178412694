import React from 'react'

export default function RightIcon(props) {
  const {color} = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 7L15 12L10 17" stroke={color ? color : "#767676"} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
