import React, { useEffect, useState } from "react";
import "./selectBox.scss";
import { useTranslation } from 'react-i18next';
import Select, { components } from "react-select";
const SelectBox = (props) => {
  const { t } = useTranslation();
  const { option, placeholder_val, setstatusSelectedOptions, selected_val } =
    props;

  const status = [
    { value: "s1", label: <label className="">{t("active")}</label> },
    { value: "s2", label: <label className="">{t("inactive")}</label> },
  ];
  const [selectBoxselected_val, setSelectBoxselected_val] = useState();
  function statusSelect(data, full_data) {
    setstatusSelectedOptions(data);
  }
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  useEffect(() => {

    if (selected_val) {

      setSelectBoxselected_val(selected_val);
    }
  }, [selected_val]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  return (
    <div className="common-select">
      <Select
        placeholder={placeholder_val ? placeholder_val : "Select"}
        options={option ? option : status}
        value={selectBoxselected_val}
        onChange={(opt) => statusSelect(opt.value, opt)}
        classNamePrefix="common-select"
        components={{
          ValueContainer: CustomValueContainer,
        }}
        getOptionLabel={(opt) => opt.label}
        isSearchable={true}
        onKeyDown={handleKeyPress}
      />
    </div>
  );
};

export default SelectBox;
