import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { _Api } from "../../api/_call";
import { loading, Updateloading } from '../../common/helpers/toastify'
const ExistinfDomainModal = () => {
  const { t } = useTranslation();
  const [domainname, setDomainname] = useState()
  console.log(domainname, "domainname")
  const [showmessage, setShowmessage] = useState()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [showstatus, setShowstatus] = useState(false)
  const adddomain = async () => {
    let GetloadingID = loading()
    try {
      let postdata = { "name": domainname }

      let res = await _Api(postdata, `api/v1/APP/domain/add`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        setShowmessage(prevState => ({
          ...prevState,
          res
        }));
      } else {
        setShowmessage(prevState => ({
          ...prevState,
          res
        }));
        Updateloading(t, GetloadingID, res.message ? (res.message) : (res.errors), 'error')
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const handleCopyClick = async (cupyinput) => {
    try {
      let a = document.querySelectorAll(`.${cupyinput}`);
      await navigator.clipboard.writeText(a[0].value);
      setShowstatus(true)
      setTimeout(() => {
        setShowstatus(false);
      }, 2000);
    } catch (err) {
      console.log(err, "err");
    }
  };
  return (
    <div className="modal fade com-modal" id="ExistinfDomainModal" tabIndex="-1" aria-labelledby="ExistinfDomainModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title fw-semibold" id="ExistinfDomainModalLabel">{t("Connect Existing Domain")}</h6>
            <button onClick={() => {
              setShowmessage("");
              setDomainname("")

            }} type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="v3-input-group">
              <label className="v3-input-group-label fw-semibold fs-14">{t("Domain")}</label>
              <input type="text" value={domainname} className="v3-input-group-input w-100" placeholder="Example: example.com"
                onChange={(e) => setDomainname(e.target.value)} />
              <label className="v3-input-group-label mt-3">{t("Enter the domain you want to connect")}.</label>
            </div>
            <div className={`v3-input-group-label fw-semibold fs-14 text-center danger-text  ${showmessage?.res?.code === 200 ? "main-green-text" : "text-danger"}`}>
              {showmessage?.res?.message}
            </div>
            {showmessage?.res?.requiredIp ?
              <>
                <div className=''>
                  <div className="v3-input-group">
                    <label className="v3-input-group-label fw-semibold fs-14">{t("REQUIRD IP")}</label>
                    <div className='v3-input-group-input d-flex wheatbg'>
                      <input type="text" className="w-100 border-0 bg-transparent pointer-none input1"
                        value={showmessage?.res?.requiredIp}
                        disabled
                      />
                      <span className='copy-clip-img-box cursor-pointer'>
                        <img onClick={() => handleCopyClick("input1")} src={base_assets + "images/icons/clone.svg"} alt='' />
                      </span>
                    </div>
                    {showstatus ?
                      <span className='d-flex justify-content-center main-green-text'>
                        {t("Successfully Copy Your Text")}</span>
                      : ""}
                  </div>
                </div>

              </> : ""}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn text-white main-green-bg borde-0"
              onClick={() => adddomain()}> {t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ExistinfDomainModal;