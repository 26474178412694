import DeleteIcon from 'Ecommerce/template_three/assets/icons/DeleteIcon';
import React from 'react'
import { useTranslation } from 'react-i18next';
import './Delete.scss'

export default function Deletedeletepopup({ isVisible, onConfirm, onCancel, message}) {
    const { t } = useTranslation();
    if (!isVisible) return null;
  return (
    <div className="deletepopup" onClick={onCancel}>
    <div className="deletepopup-inner" onClick={(e)=>e.stopPropagation()}>
        {/* <button
                      type="button"
                      className="closebtn shadow-none border-0 bg-transparent"
                      onClick={onCancel}
                    >
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 13L7 7M7 7L1 1M7 7L13 1M7 7L1 13" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button> */}
     <div className='deletepopup-inner-deleteheader'>
        <div className='deletepopup-inner-deleteheader-bigtitle'>
            <span>
                <svg width="132" height="132" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M66 0.375C83.4048 0.375 100.097 7.28904 112.404 19.5961C124.711 31.9032 131.625 48.5952 131.625 66C131.625 83.4048 124.711 100.097 112.404 112.404C100.097 124.711 83.4048 131.625 66 131.625C48.5952 131.625 31.9032 124.711 19.5961 112.404C7.28904 100.097 0.375 83.4048 0.375 66C0.375 48.5952 7.28904 31.9032 19.5961 19.5961C31.9032 7.28904 48.5952 0.375 66 0.375ZM66 122.25C80.9184 122.25 95.2258 116.324 105.775 105.775C116.324 95.2258 122.25 80.9184 122.25 66C122.25 51.0816 116.324 36.7742 105.775 26.2252C95.2258 15.6763 80.9184 9.75 66 9.75C51.0816 9.75 36.7742 15.6763 26.2252 26.2252C15.6763 36.7742 9.75 51.0816 9.75 66C9.75 80.9184 15.6763 95.2258 26.2252 105.775C36.7742 116.324 51.0816 122.25 66 122.25ZM73.0312 96.4688C73.0312 98.3336 72.2905 100.122 70.9718 101.441C69.6532 102.759 67.8648 103.5 66 103.5C64.1352 103.5 62.3468 102.759 61.0282 101.441C59.7095 100.122 58.9688 98.3336 58.9688 96.4688C58.9688 94.6039 59.7095 92.8155 61.0282 91.4969C62.3468 90.1783 64.1352 89.4375 66 89.4375C67.8648 89.4375 69.6532 90.1783 70.9718 91.4969C72.2905 92.8155 73.0312 94.6039 73.0312 96.4688ZM66 28.5C67.2432 28.5 68.4355 28.9939 69.3146 29.8729C70.1936 30.752 70.6875 31.9443 70.6875 33.1875V75.375C70.6875 76.6182 70.1936 77.8105 69.3146 78.6896C68.4355 79.5686 67.2432 80.0625 66 80.0625C64.7568 80.0625 63.5645 79.5686 62.6854 78.6896C61.8064 77.8105 61.3125 76.6182 61.3125 75.375V33.1875C61.3125 31.9443 61.8064 30.752 62.6854 29.8729C63.5645 28.9939 64.7568 28.5 66 28.5Z" fill="#EE0004"/>
                </svg>
            </span>
        </div>
        <div className='deletepopup-inner-deleteheader-subtitle'>{("Do you want to delete this item?")}</div>
     </div>
      <div className="deletepopup-inner-footer">
        <button className="deletepopup-inner-footer-buttons remove" onClick={onConfirm}>{t("Delete")}</button>
        <button className="deletepopup-inner-footer-buttons" onClick={onCancel}>{t("Cancel")}</button>
      </div>
    </div>
  </div>
  )
}
