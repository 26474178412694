import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "./catalog.scss";
import CheckoutSell from '../checkout/checkoutSell';
import CheckoutBlank from '../checkout/checkoutBlank';
import CheckoutPayment from '../checkout/checkoutPayment';
import { _Api } from "../common/api/_call";
import { success, error } from "../common/helpers/toastify";
import "react-image-gallery/styles/css/image-gallery.css";
import { toFormatPrice } from '../common/helpers/function';
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import Loading from '../common/module/Loading/Loading';
import { useTranslation } from 'react-i18next';
import { formatDate } from "../common/helpers/formatDate";
const ProductDetails = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  let navigate = useNavigate();
  const saleperson = useSelector((state) => state.app.saleperson);
  const customer = useSelector((state) => state.app.customer);
  const [productdetails, setProductdetails] = useState();
  const [Images, setImages] = useState([]);
  let checksale = localStorage.getItem('showcheckout') === "sell" ? true : false
  const [searchproductstatus, setsearchproductstatus] = useState(checksale || false);
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [showpayment, setShowpayment] = useState(false);
  const [UpdatedCart, setUpdatedCart] = useState();
  const [, setdeletestatus] = useState(false)
  const [MetalOption, setMetalOption] = useState([]);
  const [StoneOption, setStoneOption] = useState([]);
  const [SizeOption, setSizeOption] = useState([]);
  const [allchangeitems, setAllchangeitems] = useState({ 'metal': '', 'stone': '', 'size': '' });
  const [Loadingstatus, setLoadingstatus] = useState(false);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const itemdaata = localStorage.getItem("productdata")
  const parsedObject = JSON.parse(itemdaata);
  const getproductdetails = async () => {
    setLoadingstatus(true);
    try {
      const postdata = {
        "customer_id": customer?.id,
        "product_id": parsedObject?.productid,
        "Metal": allchangeitems?.metal ? allchangeitems?.metal : parsedObject?.metal,
        "Size": allchangeitems?.size ? allchangeitems?.size : parsedObject?.Size,
        "Stone": allchangeitems?.stone ? allchangeitems?.stone : parsedObject?.Stone,
        'type': parsedObject?.filter === true ? 'catalog' : 'inventory'
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/getProductDetail_Variants`);
      if (res?.code === 200) {
        setLoadingstatus(false);
        let iarray = []; let marray = []; let starray = []; let sarray = [];
        setProductdetails(res?.data);
        res?.data?.main_image_Arr.forEach((result) => {
          iarray.push({
            original: result?.image,
            thumbnail: result?.thumb,

          });
        })
        if (res?.data?.metal_value?.length) {
          res?.data?.metal_value.forEach((result) => {
            marray.push({ value: result?._id, label: result?.name })
          })
        }
        if (res?.data?.stone_value) {
          res?.data?.stone_value.forEach((result) => {
            starray.push({ value: result?._id, label: result?.name })
          })
        }
        if (res?.data?.size_value) {
          res?.data?.size_value.forEach((result) => {
            sarray.push({ value: result?._id, label: result?.name })
          })
        }
        setImages(iarray);
        setMetalOption(marray);
        setStoneOption(starray);
        setSizeOption(sarray);
      } else {
        setLoadingstatus(false);
        error(res?.message)
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const AddtoCart = async (product_id) => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    try {
      const postdata = {
        "product_id": product_id,
        'type': parsedObject?.filter === true ? 'catalog' : 'inventory',
        'order_type': 'pos_order',
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "park_id": parsedObject?.parkid,
        "order_id": parsedObject?.order_id,
        "sessioncartid": parsedObject?.uuid ? parsedObject?.uuid : "",
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`);
      if (res.code === 200) {
        success(t("Successfully Add to Cart!!"));
        if (parsedObject?.parkid) {
          GetCartdetailpark()
        } else {
          if (parsedObject?.order_id && parsedObject?.order_edit && parsedObject?.order_type) {
            GetCartdetailpark()
          }
          setsearchproductstatus(true);
          setShowcheckout('sell');
        }
      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const addtocustomcart = async (product_id) => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    try {
      const clearpostdata = {
      };
      let res = await _Api(clearpostdata, `api/v1/POS/customOrder/cartClear`);
      if (res?.code === 200) {
        localStorage.removeItem("showcheckout")
        const postdata = {
          "product_id": product_id,
          'type': parsedObject?.filter === true ? 'catalog' : 'inventory',
          'order_type': "custom_order",
          "customer_id": customer?.id,
          "sales_person_id": saleperson?.value
        };
        let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`);
        if (res.code === 200) {
          success(t("Successfully Add to Cart!!"));
          navigate("/app/pos/custom/createYourOwnSetting", { state: { cartid: res?.data, filtertype: parsedObject?.filter } });
        } else {
          error(t("This Product Already in cart!!"));
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const GetCartdetailpark = async () => {
    try {
      const postdata = {
        "park_id": parsedObject?.parkid,
        "order_id": parsedObject?.order_id,
        "order_edit": parsedObject?.order_edit,
        "order_type": parsedObject?.order_type,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setsearchproductstatus(true);
        setShowcheckout('sell');
      } else {
        navigate('/app/pos/catalog/CollectionList');
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (parsedObject?.parkid) {
      GetCartdetailpark();
    } else if (parsedObject?.order_id && parsedObject?.order_edit && parsedObject?.order_type) {
      GetCartdetailpark();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getproductdetails()
    // eslint-disable-next-line
  }, [allchangeitems])

  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  const wishlistset = async (sku, product_id, wishliststatus, i) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    try {
      const postdata = {
        customer_id: customer?.id,
        isWishlist: wishliststatus,
        SKU: sku,
        type: parsedObject?.filter === true ? 'catalog' : 'inventory',
        product_id: product_id,
        order_type: "pos_order",
        item_id: parsedObject?.itemid
      }
      let res = await _Api(postdata, `api/v1/POS/wishlist/updateWishlist`)
      if (res.code === 200) {
        let wishlistobj = { ...productdetails }
        wishlistobj.isWishlist = res?.data?.isWishlist
        setProductdetails(wishlistobj)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area">
              <div className='catalog-area-header-area detail-area-header'>
                <div className='catalog-area-header-area-control'>
                  <div className='catalog-area-header-area-control-left'>
                    <Link to="/app/pos/products" state={{ itemid: parsedObject?.itemid, Optiondetails: parsedObject?.filter, NavId: parsedObject?.NavId, itemname: parsedObject?.itemname, parkid: parsedObject?.parkid }} className='page-back'>
                      <img className='page-back-img' src={base_assets + "images/pos/icons/arrow-gray-left.png"} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="catalog-area-ct bg-white">
                {
                  Loadingstatus ? <Loading /> : ''
                }
                <div className='products-details'>
                  <div className='products-details-row'>
                    <div className='products-details-row-col products-details-row-col-left'>
                      <div className='products-details-row-col-left-slider'>
                        <ImageGallery
                          items={Images}
                          showPlayButton={false}
                          showFullscreenButton={false}
                          showNav={false}
                          infinite={true}
                          disableSwipe={false}
                          disableThumbnailSwipe={false}
                          additionalClass="img-view-slider"
                        />
                      </div>
                      <div className={productdetails?.inventory_statistics?.total ? 'products-details-row-col-left-location' : "d-none"}>
                        <div className="dropdown">
                          <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img className='products-details-row-col-left-location-img' src={base_assets + "images/pos/icons/location-icon.png"} alt="" />
                            <span>{productdetails?.inventory_statistics?.total}</span>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {productdetails?.inventory_statistics?.response?.length ? productdetails?.inventory_statistics?.response.map((result, lockey) => {
                              return (
                                <li key={lockey}>
                                  <Link className="dropdown-item" >
                                    {result?.currency ?
                                      <img src={base_assets + "images/flags/" + result?.currency.toLowerCase() + ".png"} alt="" />
                                      : ''
                                    }
                                    <span className='fw-light'>{result?.location_name}</span>
                                    <span className='ms-auto fw-light'>{result?.qty}</span>
                                  </Link>
                                </li>
                              );
                            }) : ''}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='products-details-row-col products-details-row-col-right'>
                      <h1 className='main-heading'>
                        {productdetails?.name}
                      </h1>
                      <h2 className="products-details-row-col-right-sku-heading">
                        {productdetails?.SKU ? productdetails?.SKU : ""}
                      </h2>
                      <p className='products-details-row-col-right-short-desc'>{productdetails?.Matatag}</p>
                      <div className='products-details-row-col-right-select'>
                        <div className='products-details-row-col-right-select-single'>
                          <label className='products-details-row-col-right-select-single-label'>{t("Metal")} :</label>
                          <div className="common-select">
                            {MetalOption?.length ?
                              <Select
                                onChange={(input) => setAllchangeitems({ ...allchangeitems, metal: input?.value })}
                                options={MetalOption}
                                classNamePrefix="common-select"
                                defaultValue={{ value: MetalOption[0]?.value, label: MetalOption[0]?.label }}
                                isSearchable={false}
                              />
                              : <span className='not_available'>{t("Not Available")}</span>}
                          </div>
                        </div>
                        <div className='products-details-row-col-right-select-single'>
                          <label className='products-details-row-col-right-select-single-label'>{t("Stone")} :</label>
                          <div className="common-select">
                            {
                              StoneOption?.length ?
                                <Select
                                  onChange={(input) => setAllchangeitems({ ...allchangeitems, stone: input?.value })}
                                  options={StoneOption}
                                  classNamePrefix="common-select"
                                  defaultValue={{ value: StoneOption[0]?.value, label: StoneOption[0]?.label }}
                                  isSearchable={false}
                                />
                                : <span className='not_available'>{t("Not Available")}</span>
                            }
                          </div>
                        </div>
                        <div className='products-details-row-col-right-select-single'>
                          <label className='products-details-row-col-right-select-single-label'>{t("Size")} :</label>
                          <div className="common-select">
                            {
                              SizeOption?.length ?
                                <Select
                                  onChange={(input) => setAllchangeitems({ ...allchangeitems, size: input?.value })}
                                  options={SizeOption}
                                  classNamePrefix="common-select"
                                  defaultValue={{ value: SizeOption[0]?.value, label: SizeOption[0]?.label }}
                                  isSearchable={false}
                                />
                                : <span className='not_available'>{t("Not Available")}</span>
                            }
                          </div>
                        </div>
                      </div>
                      <p className='products-details-row-col-right-price'>
                        {productdetails?.retailprice_Inc ? toFormatPrice(productdetails?.retailprice_Inc, { addSymbol: true }) : ""}
                      </p>
                      <div className='products-details-row-col-right-btn-wrap'>
                        {
                          (productdetails?.status_type === "transit" || productdetails?.status_type === "warehouse" || productdetails?.status_type === "reserve" || parsedObject?.filter === true) ? "" :
                            ((!productdetails?.status_type) ||
                              ((productdetails?.status_type === "custom_order" && customer?.id === productdetails?.pos?.customer_id) ||
                                (productdetails?.status_type === "repair_order" && customer?.id === productdetails?.customer_id))
                            ) ? (
                              <div className='border-inner-btn hover-effect'>
                                <button onClick={() => AddtoCart(productdetails?.product_id)} className={parmissiondatashow?.POS?.Add ? 'add-to-cart' : "add-to-cart btn-disabled"}>
                                  {t("ADD TO CART")}
                                </button>
                              </div>
                            ) : ''
                        }
                        <div className='hover-effect'>
                          <button className={parmissiondatashow?.pos_custom_order?.Add ? 'hover-effect-inner add-to-order' : "hover-effect-inner add-to-order btn-disabled"} onClick={() => addtocustomcart(productdetails?.product_id)}>{t("ADD TO ORDER")}</button>
                        </div>
                        <div className='wishlist-icon'
                          onClick={() => productdetails?.isWishlist ?
                            (parmissiondatashow?.WishList?.Delete && wishlistset(productdetails?.SKU, productdetails?.product_id, productdetails?.isWishlist)) :
                            (parmissiondatashow?.WishList?.Add && wishlistset(productdetails?.SKU, productdetails?.product_id, productdetails?.isWishlist))}>
                          <i className={productdetails?.isWishlist ? "fa fa-heart wishlist-icon" : "fa fa-heart-o wishlist-icon"} aria-hidden="true"></i>
                        </div>
                        {
                          productdetails?.status_type === "transit" ?
                            <div className="status-dot green-bg  tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                              {t("Transit")}</span></div> :
                            productdetails?.status_type === "warehouse" ?
                              <div className="status-dot status-gray-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                {t("Warehouse")}</span></div> :
                              productdetails?.status_type === "reserve" ?
                                <div className="status-dot yellow-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                  {t("Reserve")}</span></div> :
                                productdetails?.status_type === "repair_order" ?
                                  <div className="status-dot purple-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                    {t("Repair Order")}</span></div> :
                                  productdetails?.status_type === "custom_order" ?
                                    <div className="status-dot danger-bg tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                      {productdetails?.customer_name}{productdetails?.customer_name ? <br /> : ''}{productdetails?.POS_Order}<br />{formatDate(productdetails?.pos?.delivery_date)}</span></div>
                                    : productdetails?.status_type === "stock" ?
                                      <div className="status-dot light-blue-bg  tooltip-area-up ms-2"><span className='tooltip-area-up-text'>
                                        {t("Stock")}</span></div> : ""
                        }
                      </div>

                      <div className='products-details-row-col-right-tabs-area'>
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#Description">{t("Description")}</a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div className="tab-pane container active" id="Description">
                            {productdetails?.Description ? productdetails?.Description : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                parkid={parsedObject?.parkid}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                setdeletestatus={setdeletestatus}
                ordertype={'pos_order'}
                showpayment={showpayment}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
                inv_ordertype={productdetails?.order_type}
                inv_orderid={productdetails?.order_id}
                order_edit={parsedObject?.order_edit}
                order_type={parsedObject?.order_type}
                order_id={parsedObject?.order_id}
                by_repair_order={productdetails?.by_repair_order}
                by_customorder={productdetails?.by_custom_order}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                order_edit={parsedObject?.order_edit}
                order_id={productdetails?.order_id ? productdetails?.order_id : parsedObject?.order_id}
                order_type={productdetails?.order_type === "custom_order" ? "pos_order" : productdetails?.order_type ? productdetails?.order_type : parsedObject?.order_type}
                by_custom_order={productdetails?.by_custom_order}
                by_repair_order={productdetails?.by_repair_order}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
    </>
  )

}

export default ProductDetails;