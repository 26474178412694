import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Report.scss"
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { Reportmenupos } from "./Reportmenupos";
import SocialModal from "../../components/common/modules/SocialModal/SocialModal";
import { Daterangepos } from "../common/Daterangepos";
import TableModal from "../common/Modals/tableColumnPos";
import { _Api } from "../common/api/_call"
import { Tablehead } from "../../components/common/modules/Tablehead";
import { poscustomerreport_head } from "../../components/common/Tablehead";
import { useTranslation } from 'react-i18next';
import { loading, Updateloading } from "../common/helpers/toastify";
import { Pagination } from "../common/Pagination";
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import Select from "react-select";
import * as XLSX from 'xlsx';
const Customerreport = () => {
    const { t } = useTranslation();
    const [customerdata, setCustomerdata] = useState([])
    const [shareloader, setShareloader] = useState(false);
    const [newcolumndata, setnewcolumndata] = useState(poscustomerreport_head);
    const [datefilter, setDatefilter] = useState([]);
    const [socialurl, setSocialurl] = useState();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [filtername, setFiltername] = useState("");
    const [lowercount, setLowercount] = useState()
    const [upercount, setUpercount] = useState()
    const [limit, setLimit] = useState(100);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

    const getsharecustomerreport = async () => {
        setShareloader(true);
        try {
            const postdata = {
                "search": filtername,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/reports/print/customer`);

            if (res.code === 200) {
                setSocialurl(res?.link);
                setShareloader(false);
            }
        }
        catch (err) {
            console.log(err, 'err');
        }

    }
    const getprintcustomerreport = async () => {
        let GetloadingID = loading();
        try {
            const postdata = {
                "search": filtername,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/reports/print/customer`);

            if (res.code === 200) {
                setTimeout(() => {
                    const exportLinkElement = document.createElement('a');
                    exportLinkElement.hidden = true;
                    exportLinkElement.download = res?.name ? res?.name : "report.pdf";
                    exportLinkElement.href = res?.link;
                    exportLinkElement.text = "downloading...";
                    exportLinkElement.setAttribute('target', '_blank');
                    document.body.appendChild(exportLinkElement);
                    exportLinkElement.click();
                    exportLinkElement.remove();
                    Updateloading(t, GetloadingID, t("download successfully"));

                }, 50)
                setSocialurl(res?.link);
            } else {
                Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, 'err');
            Updateloading(t, GetloadingID, err, t('error'));
        }

    }
    const callcolumns = async () => {
        try {
            const postdata = {
                name: "report_customerreport_tbl",
            };
            let columnhead = await _Api(
                postdata,
                `api/v1/Inventory/common/getColumnHeads`
            );
            if (columnhead.code === 200) {
                setnewcolumndata(columnhead?.data?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        callcolumns();
    }, []);

    const filterbyname = (search) => {
        getcustomerreport(currentPage, limit, search);
        setstate({ ...state, currentPage: 1 });
    };
    const getlimit = (limit) => {
        setLimit(limit);
        getcustomerreport(1, limit);
        setstate({ ...state, currentPage: 1 });

    };
    const [state, setstate] = useState({
        totalPages: 20,
        currentPage: 1,
    });

    const { totalPages, currentPage } = state;
    const handlePaginations = (current) => {
        setstate({ ...state, currentPage: current });
        getcustomerreport(current, "");

    };
    const getcustomerreport = async (current, firstlimit, search) => {
        UnSetdataTable("customer_report");
        try {
            const postdata = {
                "search": search ? search : "",
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : "",
                "limit": firstlimit ? firstlimit : limit,
                "skip": current ? (current - 1) * limit : 0,

            };
            let listdata = await _Api(postdata, `api/v1/POS/report/customer`);
            if (listdata?.code === 200) {
                setCustomerdata(listdata?.data);
                SetdataTable("customer_report", 0, newcolumndata, []);
                setstate({
                    ...state,
                    totalPages: Math.ceil(listdata?.count / postdata.limit),
                    currentPage: current ? current : 1
                });
                setUpercount(listdata?.uppercount);
                setLowercount(listdata?.lowercount);

            } else {
                SetdataTable("customer_report", 0, newcolumndata, []);
            }
        }
        catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        if (datefilter[0] && datefilter[1]) {
            getcustomerreport();
        }
        // eslint-disable-next-line
    }, [newcolumndata, datefilter]);

    const selectOptions = [
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 }
    ]

    const downloadexclsheet = async () => {
        let header_values = [];
        let bodyarray = [];
        poscustomerreport_head.map(async (r, k) => {
            header_values.push(r?.lable)
        })

        if (customerdata.length) {
            customerdata.forEach((result, key) => {

                bodyarray.push([key + 1, result?.customer_id, result?.customer_name, result?.customer_group, result?.customer_mobile, result?.customer_email, result?.wishlist, result?.custom, result?.repair, result?.exchange, result?.sales])
            })
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
        const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([fileContent], { type: fileType });
        FileSaver.saveAs(data, "customer_report");

    }
    return (<>

        <div className="main-body ">
            <div className="main-body-row">
                <div className="main-body-row-left-col p-0 w-100">
                    <div className="catalog-area bg-white page-menu-block Dailysalereport">
                        <div className="Dailysalereport-header">
                            <div className="Dailysalereport-header-left">
                                <h3 className='common-navigation-header-left-heading Dailysalereport-head'> <Link to="" className='gray-mid text-decoration-none Dailysalereport-head-report'>{t("REPORT")}</Link> <img className="Dailysalereport-head-arrow mx-3" src={base_assets + "images/pos/report/arrow-right.png"} alt="" /><span className="Dailysalereport-head-pagename">{t("Customer Report")}</span> </h3>
                            </div>
                            <div className="Dailysalereport-header-reportdetail">
                                <div className="Dailysalereport-header-reportdetail-box Customerreport-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/customer-green.png"} alt="" />
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Customer")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-value">{upercount?.customer}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box  Customerreport-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/Wishlist-icon.png"} alt="" />
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Wishlist")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data yellow-text">{upercount?.wishlist}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box Customerreport-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/Customorder.png"} alt="" />
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Custom Order")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data lightpink">{upercount?.custom}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box Customerreport-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/Repairorder.png"} alt="" />
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Repair Order")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data graycolor">{upercount?.repair}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box Customerreport-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/Refund.png"} alt="" />
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Exchange / Refund")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data maingray">{upercount?.exchange}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box Customerreport-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/Sales.png"} alt="" />
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Sales")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data maingreen">{upercount?.sales}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Dailysalereport-content-area">
                            <div className="Dailysalereport-content-area-header">
                                <div className="Dailysalereport-content-area-header-inner">
                                    <Reportmenupos
                                        Reportname={"Customer Report"}
                                    />
                                    <Select
                                        className="limit-select short mx-3"
                                        options={selectOptions}
                                        value={{ label: limit, value: limit }}
                                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                                    />
                                    <div className="date"> <Daterangepos setDatefilter={setDatefilter} /></div>
                                </div>
                                <div className="catalog-area-header-area-control-right">
                                    <div className="com-search-area">
                                        <input type="text" value={filtername} placeholder={t("Search  by Ref number, voucher type, ...")}
                                            onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                                            onChange={(e) => setFiltername(e.target.value)} />
                                        {filtername ? <img className="com-search-area-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                                        <img onClick={() => filterbyname(filtername)} className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />

                                    </div>
                                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                                        <button className='btn-style' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={base_assets + 'images/icons/ellipsis-circular.png'} alt="" /></button>
                                        <ul className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li onClick={(e) => getprintcustomerreport(e.target.value)}
                                                className={parmissiondatashow?.pos_reports?.Print ? "" : "d-none"}>
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/print-icon.png"} alt="" />
                                                    </div>
                                                    {t("Print")}</Link>
                                            </li>
                                            <li onClick={(e) => downloadexclsheet(e.target.value)}>
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/export-icon.png"} alt="" />
                                                    </div>
                                                    {t("Export")}</Link>
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target="#socialModal" className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" onClick={() => getsharecustomerreport()}>
                                                <div className="dropdown-menu-img">
                                                    <img src={base_assets + "images/pos/report/share-icon.png"} alt="" />
                                                </div>
                                                {t("Share")}
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target="#columnModal">
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/icons/column-icon.png"} alt="" />
                                                    </div>
                                                    {t("Column")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="Dailysalereport-content-area-main">
                                <div className="main-body-main-table-wrap Dailysalereport-table Customerreport-table" id="table-scroll">
                                    <table id='customer_report' className="common-table first-row-gray w-100 Dailysalereport-table-main Customerreport-table-main">
                                        <Tablehead tablehead={poscustomerreport_head} tblname={"report_customerreport_tbl"} />
                                        <tbody>
                                            <>
                                                {customerdata.length ? customerdata.map((result, key) => {
                                                    return (
                                                        <tr key={key} >
                                                            <td>{key + 1}</td>

                                                            <td>
                                                                <Link to="/app/pos/CustomerDatails"
                                                                    state={{
                                                                        id: result?.id,
                                                                        name: result?.customer_name
                                                                    }} className="text-decoration-none">
                                                                    {result?.customer_id ? result?.customer_id : ""}
                                                                </Link>
                                                            </td>

                                                            <td>{result?.customer_name ? result?.customer_name : ""}</td>
                                                            <td className='crown-img'>  {
                                                                result?.customer_group === 'Silver' ?
                                                                    <img className="group-crown" src={base_assets + "images/icons/silver-medal.png"} alt="" /> :
                                                                    result?.customer_group === 'Gold' ?
                                                                        <img className="group-crown" src={base_assets + "images/icons/gold-medal.png"} alt="" /> :
                                                                        result?.customer_group === 'Platinum' ?
                                                                            <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : result?.customer_group === 'Diamond' ?
                                                                                <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : ""

                                                            }{result?.customer_group ? result?.customer_group : ""}</td>
                                                            <td>{result?.customer_mobile ? result?.customer_mobile : ""}</td>
                                                            <td>{result?.customer_email ? result?.customer_email : ""}</td>
                                                            <td className="yellowcolor">{result?.wishlist ? result?.wishlist : "0"}</td>
                                                            <td className="danger-text">{result?.custom ? result?.custom : "0"}</td>
                                                            <td className="graycolor">{result?.repair ? result?.repair : "0"}</td>
                                                            <td>{result?.exchange ? result?.exchange : "0"}</td>
                                                            <td className="main-green-text">{result?.sales ? result?.sales : "0"}</td>
                                                        </tr>
                                                    )
                                                }) : ""}
                                            </>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="yellowcolor">{lowercount?.wishlist}</td>
                                                <td className="danger-text">{lowercount?.custom}</td>
                                                <td className="graycolor">{lowercount?.repair}</td>
                                                <td>{lowercount?.exchange}</td>
                                                <td className="main-green-text">{lowercount?.sales}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            {customerdata.length > 0 ? (
                                <Pagination
                                    total={totalPages}
                                    current={currentPage}
                                    pagination={(crPage) => handlePaginations(crPage)}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <TableModal tablehead={poscustomerreport_head} tblName={"report_customerreport_tbl"} setnewcolumndata={setnewcolumndata} />
    </>
    )
}
export default Customerreport;




















