import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const Stockidmodal = (props) => {
    const { t } = useTranslation();
    const base_url = process.env.REACT_APP_BASE_ASSETS_URL;
    const [stockidcount, setStockidcount] = useState()
    const { countstockid } = props
    return (
        <div
            className="modal notes-modal notes-modal-account"
            id="Stockidmodal"
            tabIndex={-1}
            aria-labelledby="StockidmodalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="notesModalLabel">
                            <img
                                src={base_url + "images/icons/comment-icon-green.png"}
                                alt=""
                            />
                            {t("Stock ID")}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">
                        <textarea
                            className="notes-modal-text"
                            placeholder="Type your note here."
                            onChange={(e) => setStockidcount(e.target.value)}
                        />
                        <div className="main-body-top-inputs-bar-col-right d-flex justify-content-center mb-2">
                            <button className="main-body-top-inputs-bar-col-right-submit-btn com-btn"
                                data-bs-dismiss="modal"
                                aria-label="Close" onClick={() => countstockid(stockidcount)}>{t("Submit")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stockidmodal;
