import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { _Api, Api_form } from "../api/_call";
import "datatables.net-fixedcolumns";
import { useTranslation } from 'react-i18next';
import FilterCustomerModal from "../common/modules/FilterCustomerModal";
import { Pagination } from "../common/Pagination";
import RightArrow from "../common/icons/right-arrow";
import { success, error, loading, Updateloading } from "../common/helpers/toastify";
import Confirmationmodel from "../common/modules/confirmationmodel"
import "./customers.scss";
import Select from "react-select"
import CustomerInformation from "./customerInformation/CustomerInformation";
import TableLoader from "../common/TableLoader";
import { useSelector } from "react-redux";
const Customers = (props) => {
  const dropdowngroup = useRef(null);
  const dropdowncountry = useRef(null);
  const dropdowncity = useRef(null);
  const { t } = useTranslation();
  const [importfile, setImportfile] = useState()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [limit, setLimit] = useState(100);
  const [getcusomerlist, setCustomerlist] = useState([])
  const [customercount, setCustomercount] = useState()
  const [filtername, setFiltername] = useState('')
  const [cusid, setCusid] = useState("")
  const [Namedata, setNamedata] = useState([]);
  const [countrydata, setCountrydata] = useState([]);
  const [citydata, setCitydata] = useState([]);
  const [genderdata, setGenderdata] = useState([])
  const [groupdata, setGroupdata] = useState([])
  const [maxAge, setMaxage] = useState([0, 100])
  const [exportid, setExportid] = useState([])
  const [filterdata, setfilterdata] = useState()
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL
  const [shortobj, setShortobj] = useState({})
  const [showloader, setShowloader] = useState(true)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const customerlist = async (current, firstlimit) => {
    setShowloader(true)
    try {
      const postdata = {
        search: filtername,
        "limit": firstlimit ? firstlimit : limit,
        "skip": current ? (current - 1) * limit : 0,
        "name": Namedata,
        "country": countrydata,
        "city": citydata,
        "maxAge": maxAge[1],
        "minAge": maxAge[0],
        "gender": genderdata,
        "group": groupdata,
        "sort": { [shortobj?.key]: shortobj?.value }

      }
      let res = await _Api(postdata, `api/v1/Customer/customer/getList`)
      if (res?.code === 200) {
        if (res?.data) {
          setShowloader(false)
        }
        setCustomerlist(res?.data)
        setCustomercount(res?.count)
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  useEffect(() => {
    customerlist()
    // eslint-disable-next-line 
  }, [shortobj, cusid])
  const filterbyname = () => {
    customerlist(currentPage, limit);
    setstate({ ...state, currentPage: 1 });
  };
  const getlimit = (limit) => {
    setLimit(limit);
    customerlist(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    customerlist(current, "");

  };

  const downloadexclsheet = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        ids: exportid
      }
      let res = await _Api(postdata, `api/v1/Customer/customer/customerExport`)
      if (res?.code === 200) {
        window.open(res?.filexlsx, '_blank', 'noreferrer')
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, (res?.message), 'error');
      }

    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getcustomerfiltter = async () => {
    try {
      const postdata = {}
      let res = await _Api(postdata, `api/v1/App/customer/customerFilter`);
      if (res.code === 200) {
        setfilterdata(res?.data)
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  useEffect(() => {
    getcustomerfiltter();
  }, []);

  const SampleExcel = () => {
    const exportLinkElement = document.createElement('a')
    exportLinkElement.hidden = true
    exportLinkElement.download = 'Customers.xlsx'
    exportLinkElement.href = base_url + "Customersexport.xlsx"
    exportLinkElement.text = 'downloading...'
    document.body.appendChild(exportLinkElement)
    exportLinkElement.click()
    exportLinkElement.remove()
  }

  const readExcel = async () => {
    if (!importfile) {
      error(t('Choose file!'));
      return;
    }
    try {
      const formData = new FormData()
      formData.append('file', importfile)
      formData.append('type', 'CustomerImport')
      let res = await Api_form(
        formData,
        `api/v1/App/customer/uploadCustomer`,
      )
      if (res.code === 200) {
        success(
          t('File Sucessfully Uploaded and Backend Process have  Started Now,Please wait!!'),
        )
        customerlist()
      } else {
        error(t('Please Wait or try again later!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }

  }
  const handleCheckAllChange = (e, lable) => {
    if (lable === "export") {
      setExportid(
        e.target.checked ? getcusomerlist.map((c) => c?._id) : []
      )
    } else if (lable === "group") {
      setGroupdata(
        e.target.checked ? filterdata?.group.map((c) => c) : []
      )
    } else if (lable === "country") {
      setCountrydata(
        e.target.checked ? filterdata?.country.map((c) => c?.id) : []
      )
    } else if (lable === "city") {
      setCitydata(
        e.target.checked ? filterdata?.city.map((c) => c) : []
      )
    }

  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "export") {
      setExportid((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)
      )
    } else if (lable === "group") {
      setGroupdata((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "country") {
      setCountrydata((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data?.id]
          : prevChecked.filter((item) => item !== data?.id)

      );
    } else if (lable === "city") {
      setCitydata((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    }
  };
  const getscarchvalue = async (event, lable) => {
    if (lable === "group") {
      const query = event.target.value ? event.target.value : "";
      let updatedList = [...filterdata?.group];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setfilterdata(prevState => ({
            ...prevState,
            group: arr
          }));
        });
      } else {
        getcustomerfiltter()
      }
    } else if (lable === "country") {
      const query = event.target.value ? event.target.value : "";
      let updatedList = [...filterdata?.country];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setfilterdata(prevState => ({
            ...prevState,
            country: arr
          }));
        });
      } else {
        getcustomerfiltter()
      }
    }
  }
  const hendledropdownhide = (lable) => {
    if (lable === "group") {
      dropdowngroup.current.classList.remove("show")
    } else if (lable === "country") {
      dropdowncountry.current.classList.remove("show")
    } else if (lable === "city") {
      dropdowncity.current.classList.remove("show")
    }
  }
  const handler_delete = async () => {
    try {
      let GetloadingID = loading();
      let postdata = {
        id: cusid
      }
      let res = await _Api(postdata, `api/v1/APP/customer/delete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        setCusid("")
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <React.Fragment>
      <div className="main-body main-content-wrapper-body">
        <div className="row">
          <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
            <div className="main-content-wrapper-body-top-bar-left">
              <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                {t("Customer")} <RightArrow /> <span>{t("Customers")}</span>
              </h1>
            </div>
          </div>
          <div className="main-body-top-inventory main-content-wrapper-body-top-cards">
            <CustomerInformation
              cusid={cusid}
              setCusid={setCusid} />
            <div className="main-body-top-inventory-status">
              <div className="main-body-top-inventory-status-card">
                <div className="left-side">
                  <img
                    src={base_assets + "images/icons/customer-card-icon.png"}
                    className="inventory-img"
                    alt="free-stock"
                  />
                </div>
                <div className="right-side">
                  <span className="inventory-name">{t("Customer")}</span>
                  <h2 className="inventory-value">{customercount?.customer ? customercount?.customer : "-"}</h2>
                </div>
              </div>
              <div className="main-body-top-inventory-status-card">
                <div className="left-side">
                  <img
                    src={base_assets + "images/icons/platinum-medal.png"}
                    className="inventory-img"
                    alt="sku"
                  />
                </div>
                <div className="right-side">
                  <span className="inventory-name">{t("Platinum")}</span>
                  <h2 className="inventory-value">{customercount?.Platinum ? customercount?.Platinum : "-"}</h2>
                </div>
              </div>
              <div className="main-body-top-inventory-status-card">
                <div className="left-side">
                  <img
                    src={base_assets + "images/icons/gold-medal.png"}
                    className="inventory-img"
                    alt="value"
                  />
                </div>
                <div className="right-side">
                  <span className="inventory-name">{t("Gold")}</span>
                  <h2 className="inventory-value">{customercount?.Gold ? customercount?.Gold : "-"}</h2>
                </div>
              </div>
              <div className="main-body-top-inventory-status-card">
                <div className="left-side">
                  <img
                    src={base_assets + "images/icons/silver-medal.png"}
                    className="inventory-img"
                    alt="free-stock"
                  />
                </div>
                <div className="right-side">
                  <span className="inventory-name">{t("Silver")}</span>
                  <h2 className="inventory-value">{customercount?.Silver ? customercount?.Silver : "-"}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-top-status-bar mb-3 main-content-wrapper-body-top-status-bar">

            <Select className="limit-select"
              options={selectOptions}
              value={{ label: limit, value: limit }}
              onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
            />
            <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center ms-auto">
              <div className="com-search-bar position-relative large">
                <button
                  onClick={filterbyname}
                  className="main-body-top-status-bar-filter-search pe-1 h-auto">
                  <img className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
                </button>
                <input
                  type="text"
                  className="h-100 border-0 bg-transparent"
                  placeholder="Search Customer"
                  onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                  onChange={(e) => setFiltername(e.target.value)}
                />
                <div className="bg-transparent border-0 p-0 cursor-pointer" data-bs-toggle="modal" data-bs-target="#filterCustomerModal"><img className="d-block" src={base_assets + "images/admin/icons/green-filter.png"} alt="" /></div>
              </div>
              <button className="print-export-dropdown-selected-icon d-none">
                <img
                  src={base_assets + "images/icons/printer-icon.png"}
                  alt=""
                />
              </button>
              <div className="print-export-dropdown dropdown">
                <button
                  className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={base_assets + "images/admin/icons/gray-ellipsis.png"}
                    alt=""
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="dropdown-item print-dropdown-item">
                    <div className="dropdown-item-img">
                      <img
                        src={base_assets + "images/icons/printer-icon.png"}
                        alt=""
                      />
                    </div>
                    <span>{t("print")}</span>
                  </li>
                  <li className="dropdown-item" onClick={() => { downloadexclsheet(); }}>
                    <div className="dropdown-item-img"   >
                      <img
                        src={base_assets + "images/icons/export-x-icon.png"}
                        alt=""
                      />
                    </div>
                    <span>{t("export")}</span>
                  </li>
                  <li className="dropdown-item" data-bs-toggle="modal"
                    data-bs-target="#ImportModal">
                    <div className="dropdown-item-img">
                      <img
                        src={base_assets + "images/icons/export.png"}
                        alt=""
                      />
                    </div>
                    <span >{t("Import")}</span>
                  </li>
                </ul>
              </div>
              {parmissiondatashow?.pos_customer_reports?.Add ?
                <button className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"
                  data-bs-toggle="modal" data-bs-target="#customerAddModal"
                  onClick={() => { setCusid("") }}>
                  <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}</button> :
                ""}
              <Link to='/app/admin/customers/appointmenthistory' className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3">
                <img className="me-2" src={base_assets + "images/admin/icons/white-calander.png"} alt="" />{t("Appointment")}</Link>

            </div>
          </div>
          <div
            className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
          >
            <table
              id="my_customer"
              className="com-custom-table-tag table align-middle">
              <thead>
                <tr className="text-nowrap align-middle">
                  <th className="pe-0">
                    <div className="com-check">
                      <input id="check1" type="checkbox"
                        onChange={(e) => handleCheckAllChange(e, "export")}
                        checked={exportid.length === getcusomerlist.length} />
                      <label htmlFor="check1" className="com-check-label"
                      />
                    </div>
                  </th>
                  <th>#</th>
                  <th className="px-2"></th>
                  <th className="user-name-width">{t("Name")}
                    <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" />
                  </th>
                  <th>{t("Group")}
                    <div className="dropdown d-inline-block com-custom-table-tag-filter">
                      <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                        <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                      </button>
                      <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                        ref={dropdowngroup}>
                        <div onClick={() => { setShortobj({ key: "group", value: 1 }); hendledropdownhide("group") }}
                          className="asc-des-order">
                          <div className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                          <div onClick={() => { setShortobj({ key: "group", value: -1 }); hendledropdownhide("group") }}
                            className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-search">
                          <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                            <img src={base_assets + "images/icons/search-icon.png"} alt=""
                            />
                            <input className="border-0" type="text" placeholder="Search"
                              onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "group")}
                              onChange={(e) => getscarchvalue(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-select">
                          <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                            <div>
                              <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                onChange={(e) => handleCheckAllChange(e, "group")}
                                checked={groupdata.length === filterdata?.group.length}
                              />
                              <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                            </div>
                            <label>  {groupdata.length}/{filterdata?.group.length}{t("Selected")}</label>
                          </div>
                          <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                            {filterdata?.group.length ? filterdata?.group.map((item, index) => {
                              return (
                                <li className="com-custom-table-tag-filter-menu-select-list-single" key={index}>
                                  <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={index + "group"}
                                    checked={
                                      groupdata ? groupdata.includes(item) : ""
                                    }
                                    onChange={(e) => handlefilerChange(e, item, "group")} />
                                  <label className="form-check-label cursor-pointer" htmlFor={index + "group"} >{item}</label>
                                </li>
                              )
                            }) : ""}
                          </ul>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                        >
                          <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={() => { setGenderdata([]); setShortobj({}) }}>{t("Clear")}</button>
                          <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { customerlist(); hendledropdownhide("group") }}>{t("Apply")}</button>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>{t("Country")}
                    <div className="dropdown d-inline-block com-custom-table-tag-filter">
                      <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                        <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                      </button>
                      <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                        ref={dropdowncountry}>
                        <div onClick={() => { setShortobj({ key: "country", value: 1 }); hendledropdownhide("country") }}
                          className="asc-des-order">
                          <div className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                          <div onClick={() => { setShortobj({ key: "country", value: -1 }); hendledropdownhide("country") }}
                            className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-search">
                          <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                            <img src={base_assets + "images/icons/search-icon.png"} alt=""
                            />
                            <input className="border-0" type="text" placeholder="Search"
                              onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "country")}
                              onChange={(e) => getscarchvalue(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-select">
                          <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                            <div>
                              <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                onChange={(e) => handleCheckAllChange(e, "country")}
                                checked={countrydata.length === filterdata?.country.length}
                              />
                              <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                            </div>
                            <label>  {countrydata.length}/{filterdata?.country.length}{t("Selected")}</label>
                          </div>
                          <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                            {filterdata?.country.length ? filterdata?.country.map((item, index) => {
                              return (
                                <li className="com-custom-table-tag-filter-menu-select-list-single" key={index}>
                                  <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={index?.id}
                                    checked={
                                      countrydata ? countrydata.includes(item?.id) : ""
                                    }
                                    onChange={(e) => handlefilerChange(e, item, "country")} />
                                  <label className="form-check-label cursor-pointer" htmlFor={index?.id} >{item?.name}</label>
                                </li>
                              )
                            }) : ""}
                          </ul>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                        >
                          <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={() => { setCountrydata([]); setShortobj({}) }}>{t("Clear")}</button>
                          <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { customerlist(); hendledropdownhide("country") }}>{t("Apply")}</button>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>{t("City")}
                    <div className="dropdown d-inline-block com-custom-table-tag-filter">
                      <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                        <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                      </button>
                      <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                        ref={dropdowncity}>
                        <div onClick={() => { setShortobj({ key: "city", value: 1 }); hendledropdownhide("city") }}
                          className="asc-des-order">
                          <div className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                          <div onClick={() => { setShortobj({ key: "city", value: -1 }); hendledropdownhide("city") }}
                            className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-search">
                          <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                            <img src={base_assets + "images/icons/search-icon.png"} alt=""
                            />
                            <input className="border-0" type="text" placeholder="Search"
                              onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "city")}
                              onChange={(e) => getscarchvalue(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-select">
                          <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                            <div>
                              <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                onChange={(e) => handleCheckAllChange(e, "city")}
                                checked={citydata.length === filterdata?.city.length}
                              />
                              <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                            </div>
                            <label>  {citydata.length}/{filterdata?.city.length} {t("Selected")}</label>
                          </div>
                          <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                            {filterdata?.city.length ? filterdata?.city.map((item, index) => {
                              return (
                                <li className="com-custom-table-tag-filter-menu-select-list-single" key={index}>
                                  <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={index + "city"}
                                    checked={
                                      citydata ? citydata.includes(item) : ""
                                    }
                                    onChange={(e) => handlefilerChange(e, item, "city")} />
                                  <label className="form-check-label cursor-pointer" htmlFor={index + "city"} >{item}</label>
                                </li>
                              )
                            }) : ""}
                          </ul>
                        </div>
                        <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                        >
                          <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor" onClick={() => { setCitydata([]); setShortobj({}) }}>{t("Clear")}</button>
                          <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { customerlist(); hendledropdownhide("city") }}>{t("Apply")}</button>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>{t("Phone No")}</th>
                  <th>{t("Email")}</th>
                  <th>{t("Last Modified Date")}</th>
                  <th>{t("Status")}

                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {getcusomerlist?.length ? getcusomerlist.map((result, key) => {
                  return (
                    
                    <tr className="text-nowrap" key={key}>
                      <td className="pe-0">
                        <div className="com-check">
                          <input id={result?._id} type="checkbox"
                            onChange={(e) => handlefilerChange(e, result?._id, "export")}
                            checked={
                              exportid ? exportid.includes(result?._id) : ""
                            } />
                          <label htmlFor={result?._id} className="com-check-label" />
                        </div>
                      </td>
                      <td>{key + 1} </td>
                      <td className="px-2">
                        {result?.profile ?
                          <img className="table-user-img rounded-circle" src={result?.profile} alt="" /> :
                          <img className="table-user-img rounded-circle" src={base_assets + 'images/icons/customer-card-icon.png'} alt="" />
                        }

                      </td>
                      <td className="link-color cursor-pointer" onClick={() => { setCusid(result?._id) }}><span className="bg-transparent border-0 p-0 link-color" data-bs-toggle="modal" data-bs-target="#customerAddModal" >{result?.name ? result?.name : "-"}</span> <i className="fa fa-smile-o ms-2 fs-6 lighgreencolor" aria-hidden="true"></i></td>

                      <td className="text-center">
                        {result?.group === "Gold" ?
                          <img className="medal-img" src={base_assets + "images/icons/gold-medal.png"} alt="" /> :
                          result?.group === "Silver" ?
                            <img className="medal-img" src={base_assets + "images/icons/silver-medal.png"} alt="" /> :
                            result?.group === "Platinum" ?
                              <img className="medal-img" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> :
                              result?.group === "Diamond" ?
                                <img className="medal-img" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> :
                                ""}

                      </td>
                      <td>{result?.country ? result?.country : "-"}</td>
                      <td>{result?.city_name ? result?.city_name : "-"}</td>
                      <td>{result?.phone ? result?.phone : "-"}</td>
                      <td>{result?.email ? result?.email : "-"}</td>
                      <td>{result?.modified ? result?.modified : "-"}</td>
                      <td>
                        {result?.status === "1" ?
                          <span className="active-badge  rounded-pill px-2 py-1 fw-semibold"> <span className="active-badge-dot me-2"></span>{t("Active")}</span> :
                          <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold">
                            <span className="inactive-badge-dot me-2"></span>{t("InActive")}</span>
                        }
                      </td>
                      <td>
                        <div className="dropdown com-custom-table-tag-dropdown">
                          <button className="dropdown-toggle after-none bg-transparent p-0 border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img className="" src={base_assets + "images/admin/icons/horizontal-ellipsis.png"} alt="" />
                          </button>
                          <ul className="dropdown-menu border-0 shadow-sm py-1">

                            <li className="py-1"><Link className="dropdown-item drop-hover-2 px-3 py-2"
                              to="/app/admin/customers/historypayment"
                              state={{ customer_id: result?._id }}>
                              <span className="com-custom-table-tag-dropdown-img d-inline-block me-2 opacity-75">
                                <img className="w-100 h-100" src={base_assets + "images/icons/i2.png"} alt="" />
                              </span>
                              {t("Payment History")}
                            </Link>
                            </li>
                            <li className="py-1"><Link className="dropdown-item drop-hover-2 px-3 py-2"
                              to="/app/admin/customers/historypurchase"
                              state={{ customer_id: result?._id }}>
                              <span className="com-custom-table-tag-dropdown-img d-inline-block me-2 opacity-75">
                                <img className="w-100 h-100" src={base_assets + "images/icons/i3.png"} alt="" /></span>
                              {t("Purchase History")}</Link>
                            </li>
                            <li className="py-1"><Link className="dropdown-item drop-hover-2 px-3 py-2"
                              to="/app/admin/customers/historypartialpayment"
                              state={{ customer_id: result?._id }}>
                              <span className="com-custom-table-tag-dropdown-img d-inline-block me-2 opacity-75">
                                <img className="w-100 h-100" src={base_assets + "images/icons/i6.png"} alt="" /></span>
                              {t("Partial Payment History")}</Link>
                            </li>
                            <li className="py-1"><Link className="dropdown-item drop-hover-2 px-3 py-2"
                              to="/app/admin/customers/historycreditnote"
                              state={{ customer_id: result?._id }}>
                              <span className="com-custom-table-tag-dropdown-img d-inline-block me-2 opacity-75">
                                <img className="w-100 h-100" src={base_assets + "images/icons/i4.png"} alt="" /></span>
                              {t("Credit Note")}
                            </Link>
                            </li>
                            {parmissiondatashow?.pos_customer_reports?.Delete ?
                              <li className="py-1" data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                                onClick={() => setCusid(result?._id)}><Link className="dropdown-item drop-hover-2 px-3 py-2"
                                  to="" >
                                  <span className="com-custom-table-tag-dropdown-img d-inline-block me-2 opacity-75">
                                    <img className="w-100 h-100" src={base_assets + "images/pos/icons/i5.png"} alt="" /></span>
                                  {t("Delete")}
                                </Link>
                              </li> : ""}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )
                }) : !showloader &&
                <tr>
                  <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                </tr>
                }

              </tbody>
            </table>
            {showloader && <TableLoader />}
          </div>
        </div>
        {getcusomerlist.length > 0 ? (
          <Pagination
            total={totalPages}
            current={currentPage}
            pagination={(crPage) => handlePaginations(crPage)}
          />
        ) : (
          ""
        )}
        <FilterCustomerModal
          getcustomerfiltter={getcustomerfiltter}
          setfilterdata={setfilterdata}
          filterdata={filterdata}
          setNamedata={setNamedata}
          Namedata={Namedata}
          setCountrydata={setCountrydata}
          countrydata={countrydata}
          setCitydata={setCitydata}
          citydata={citydata}
          setGenderdata={setGenderdata}
          genderdata={genderdata}
          setGroupdata={setGroupdata}
          groupdata={groupdata}
          setMaxage={setMaxage}
          maxAge={maxAge}
          customerlist={customerlist}
        />
        <div
          className="modal fade import-modal"
          id="ImportModal"
          tabIndex={-1}
          aria-labelledby="ImportModalLabel"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="import-modal-choose-file">
                  {t("choose file")}
                  <input
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0]
                      setImportfile(file)
                    }}
                    name="files"
                  />
                </div>
              </div>
              <div className="import-modal-file  w-100">
                {importfile?.name}
              </div>
              <div id="clickonme" className="modal-footer">
                <button
                  type="button"
                  onClick={readExcel}
                  data-bs-dismiss="modal"
                  className="btn modal-content-yes"
                >
                  {t("Submit")}
                </button>
                <button

                  onClick={SampleExcel}
                  id="download"
                  type="button"

                  className="btn modal-content-yes"
                >
                  {t("Download Sample")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={handler_delete} ></Confirmationmodel>
      </div>

    </React.Fragment >
  );
};
export default Customers;
