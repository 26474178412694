import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import CheckoutBlank from '../checkout/checkoutBlank'
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import ProductModal from '../common/module/ProductModal'
import { success, error } from '../common/helpers/toastify'
import { useSelector } from 'react-redux'
import './ReserveProduct.scss'
import { _Api } from '../common/api/_call'
import { useTranslation } from 'react-i18next'
import TableLoader from '../../admin/common/TableLoader'
const ReserveProduct = () => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [getoneproduct, setOneproduct] = useState()
  const [oneproductindex, setoneproductindex] = useState()
  const [searchproductstatus, setsearchproductstatus] = useState(false)
  const [showcheckout, setShowcheckout] = useState('blank')
  const [showpayment, setShowpayment] = useState(false)
  const [deletestatus, setdeletestatus] = useState(false)
  const [UpdatedCart, setUpdatedCart] = useState()
  const customer = useSelector((state) => state.app.customer)
  const saleperson = useSelector((state) => state.app.saleperson)
  const [stockid, setStockid] = useState()
  const [reservedata, setReservedata] = useState()
  const { state } = useLocation()
  const [deleteparmission, setDeleteparmission] = useState(0)
  const [dataloder, setDataloder] = useState(true)

  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

  useEffect(() => {
    if (parmissiondatashow?.pos_reserve?.View)
      setDeleteparmission(parmissiondatashow?.pos_reserve?.Delete);
  }, [parmissiondatashow]);
  const getreservelistbyids = async () => {
    setDataloder(true)
    try {
      const postdata = {
        search: '',
        ids: state?.ids,
      }
      let res = await _Api(postdata, `api/v1/POS/common/getMyinventoryList`)
      if (res?.code === 200) {
        setReservedata(res?.data)
        setDataloder(false)
      } else {
        setReservedata([])
        setDataloder(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const changeRemark = (e, key) => {
    let array = [...reservedata]
    array[key] = {
      ...array[key],
      remark: e.target.value,
    }
    setReservedata(array)
  }
  const editproduct_submit = () => {
    var mainarray = [...reservedata]
    mainarray[oneproductindex] = getoneproduct
    setReservedata(mainarray)
  }
  const AddtoCart = async () => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    if (!saleperson?.value) {
      error(t('SalePerson is Requried!!'))
      return
    }
    if (!reservedata?.length) {
      error(t('No Product for Reserved!!'))
      return
    }
    try {
      const postdata = {
        cartItems: reservedata,
        order_type: 'reserve',
        customer_id: customer?.id,
        sales_person_id: saleperson?.value,
      }

      let res = await _Api(postdata, `api/v1/POS/reserve/reserveToCart`)
      if (res.code === 200) {
        success(t('Successfully Add to Cart!!'))
        setShowcheckout('sell')
        setsearchproductstatus(true)
      } else {
        error(t('This Product Already in cart!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const deleteproduct = (id) => {
    if (deletestatus && !id) {
      setReservedata((current) => current.filter((item) => item?.stock_id !== stockid))
    } else {
      setReservedata((current) => current.filter((item) => item?.id !== id))
    }

  }

  useEffect(() => {
    getreservelistbyids()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (deletestatus) {
      deleteproduct()
    }
    // eslint-disable-next-line
  }, [deletestatus])
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 reserveproduct">
            <div className="catalog-area bg-white page-menu-block reserveproduct">
              <div className="common-navigation-header">
                <div className="common-navigation-header-left">
                  <h3 className="common-navigation-header-left-heading d-flex align-items-center">
                    <span className="a ">
                      {t('RESERVE')} </span>
                  </h3>
                </div>
                {/* <div className="common-navigation-header-right">
                  <div className="custom-tooltip">
                    <Link to="/app/pos/reserve/reserveorder">
                      <img
                        className="custom-order-img"
                        src={base_assets + 'images/pos/work-schedule 1.png'}
                        alt=""
                      />
                    </Link>
                    <span className="com-btn custom-tooltip-text">
                      {t('Reserve list')}
                    </span>
                  </div>
                </div> */}
              </div>
              <div className="selection-process">
                <div className="selection-process-single active-green">
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s1-g.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Select Product')}
                  </span>
                </div>
                <span className="selection-process-line"></span>
                <div
                  className={
                    showcheckout === 'sell'
                      ? 'selection-process-single active-green'
                      : 'selection-process-single'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s11.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s11-g.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Reserve')}
                  </span>
                </div>
              </div>
              {dataloder ? <TableLoader /> :
                <div className="reserveproduct-main">
                  {reservedata?.length ? (
                    reservedata.map((result, rkey) => {
                      return (
                        <div className="reserveproduct-main-even" key={rkey}>
                          <div className="reserveproduct-main-mainbody">
                            <span className="reserveproduct-main-mainbody-number">
                              {rkey + 1}
                            </span>
                            <div className="reserveproduct-main-mainbody-inner">
                              <div className="reserveproduct-main-mainbody-inner-ringimg">
                                <img
                                  className="reserveproduct-main-mainbody-inner-ringimg-img"
                                  src={result?.main_image}
                                  alt=""
                                />
                              </div>
                              <div className="reserveproduct-main-mainbody-inner-details">
                                <div
                                  className={
                                    deleteparmission
                                      ? 'reserveproduct-main-mainbody-inner-details-head'
                                      : 'd-none'
                                  }
                                >
                                  <span>{result?.name}</span>
                                  <img
                                    onClick={() => {
                                      deleteproduct(result?.id)
                                      setStockid(result?.stock_id)
                                    }}
                                    className="reserveproduct-main-mainbody-inner-details-head-deleteimg"
                                    src={
                                      base_assets + 'images/pos/delete-btn.png'
                                    }
                                    alt=""
                                  ></img>
                                </div>
                                <span
                                  className="reserveproduct-main-mainbody-inner-details-heading link-color text-decoration-none cursor-pointer"
                                  onClick={() => {
                                    setOneproduct(result)
                                    setoneproductindex(rkey)
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#ProductInfoModal"
                                >
                                  {result?.SKU}
                                </span>
                                <span className="reserveproduct-main-mainbody-inner-details-text">
                                  -
                                </span>
                                <div className="reserveproduct-main-mainbody-inner-details-productdetails">
                                  <div className="reserveproduct-main-mainbody-inner-details-productdetails-inner">
                                    <span className="reserveproduct-main-mainbody-inner-details-productdetails-inner-metal">
                                      {t('Metal')} :
                                    </span>
                                    <span className="reserveproduct-main-mainbody-inner-details-productdetails-inner-metalweight">
                                      {result?.metal_name}
                                    </span>
                                  </div>
                                  <div className="reserveproduct-main-mainbody-inner-details-productdetails-inner">
                                    <span className="reserveproduct-main-mainbody-inner-details-productdetails-inner-stone">
                                      {t('Stone')} :
                                    </span>
                                    <span className="reserveproduct-main-mainbody-inner-details-productdetails-inner-stonetype">
                                      {result?.stone_name}
                                    </span>
                                  </div>
                                  <div className="reserveproduct-main-mainbody-inner-details-productdetails-inner">
                                    <span className="reserveproduct-main-mainbody-inner-details-productdetails-inner-size">
                                      {t('Size')} :
                                    </span>
                                    <span className="reserveproduct-main-mainbody-inner-details-productdetails-inner-sizevalue">
                                      {result?.size_name}
                                    </span>
                                  </div>
                                </div>
                                <div className="reserveproduct-main-mainbody-inner-details-remark">
                                  <input
                                    type="text"
                                    value={result?.remark}
                                    onChange={(e) => changeRemark(e, rkey)}
                                    className="reserveproduct-main-mainbody-inner-details-remark-inner"
                                    placeholder="Remark"
                                    aria-label="Remark"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="reserveproduct-main-no-data gap-2">
                      {t('No Reserved Product')}
                      <Link to="/app/pos/reserve">Go to back</Link>
                    </div>
                  )}
                </div>}

              <div className="reserveproduct-button">
                <button
                  className={showcheckout === "blank" ? "reserveproduct-button-btn com-btn" : "reserveproduct-button-btn com-btn btn-disabled"}
                  onClick={AddtoCart}
                >
                  {t('Reserve')}
                </button>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setUpdatedCart={setUpdatedCart}
                setdeletestatus={setdeletestatus}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
                ordertype={'reserve'}
                stockid={stockid}
                setStockid={setStockid}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
      <ProductModal
        data_product={getoneproduct}
        setOneproduct={setOneproduct}
        editproduct_submit={editproduct_submit}
      />
    </>
  )
}
export default ReserveProduct
