import { jsPDF } from "jspdf";
import { getBarcodeBySku, getBarcodeByStockId, getQrcodeByProductId, getQrcodeByStockId } from "../helpers/barcodeHelper";


export const template2 = async (barcode, dataList, qrcodedata, qrcode = "offline", config = {}, returnType = "save") => {


  // let generatedQrcode ;
  // const {data} = barcode

  // const fontSizeStoneBylength = {
  //     "2" : 5,
  //     "3" : 4,
  //     "4" : 3,
  //     "5" : 3
  //   }
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [75, 13],
    compressPdf: false,
    userUnit: 300, // set the DPI here. Web uses 72 but you can change to 150 or 300

  });

  // console.log(InventoryList,'InventoryList')
  dataList.map(async (v, k) => {


    // const qr_data = {
    //   stone_count: v.stone_pcs_total,
    //   stone_code: v.stone_code,
    //   size_name: v.size_name,
    //   metal_name: v.metal_name,
    //   net_weight: v.NetWt,
    //   Stones: v.Stones
    // }






    // doc.addFileToVFS("calibri-bold.ttf",font)
    // doc.addFont('calibri-bold-bold.ttf', 'calibri-bold', 'bold');
    // doc.setFont('calibri', "bold")

    // var callAddFont = function () {
    //   this.addFileToVFS('calibri-bold-bold.ttf', font);
    //   this.addFont('calibri-bold-bold.ttf', 'calibri-bold', 'bold');
    //   };

    //   jsPDF.API.events.push(['addFonts', callAddFont])

    // doc.setFont('helvetica', 'bold');

    // console.log(doc)

    doc.setFont('helvetica', 'bold');
    doc.setFontSize('6');

    doc.text(v.SKU, 31.5, 2.5, 0);

    if (config.barcode === true) {

      if (config.barcode_display === "stock_id") {
        doc.addImage(getBarcodeByStockId(barcode.data, v.stock_id), 'PNG', 31.5, 3.3, 20, 4.0, null, "SLOW");
      } else if (config.barcode_display === "sku") {
        console.log(barcode.data, 'skuuuu')
        console.log(v.SKU, 'skuuuu')
        doc.addImage(getBarcodeBySku(barcode.data, v.SKU), 'PNG', 31.5, 3.3, 20, 4.0, null, "SLOW");
      } else {
        doc.addImage(getBarcodeByStockId(barcode.data, v.stock_id), 'PNG', 31.5, 3.3, 20, 4.0, null, "SLOW");

      }

    }


    // doc.setFontSize( '6');

    // Remove the currency symbol and commas

    // let numericString = parseFloat(v.price.trim().replace(/[฿, ]/g, '')).toFixed(2);
  

    let numericString ;
    if(isNaN(v.price)){
      numericString = v.price.replace(/[^0-9.]/g, '');
    }else{
      numericString = v.price;
    }

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
    

    if (config.price === true) {

      if(v.price){
      
        if (config.price_display==="with_currency") {
          if (isNaN(numericString)) {
            doc.text(v.currency + " " +numberWithCommas(numericString), 31.5, 12, 0);
      
          } else {
        
            doc.text(v.currency + " " + numberWithCommas(parseInt(numericString)), 31.5, 12, 0);
      
          }
      
        } else {
          if (isNaN(numericString)) {
          
            doc.text(numberWithCommas(numericString), 31.5, 12, 0);
      
          }else{
            doc.text(numberWithCommas(parseFloat(numericString).toFixed(2)), 31.5, 12, 0);
      
          }
        }
        
      
      
      }
         
      
          }

    

    doc.text(v.stock_id, 31.5, 9.8, 0);

    if (config.metal === true) {
      if(v.metal_code){
              doc.text(v.metal_code, 44.5, 9.8, 0);

      }
      doc.text(v.metal_code, 44.5, 9.8, 0);
    }

    const nwt = v.NetWt + " G"
    const gwt = v.GrossWt + " G"


    if (config.weight === true) {
      if (config.weight_display === "nwt") {
        doc.text(nwt, 45.5, 12, 0);
        // doc.text( nwt, 47 ,12, 0);

      } else {
        doc.text(gwt, 45.5, 12, 0); //for no currency layout
        // doc.text( gwt, 47 ,12, 0);

      }
    }



    if (config.qr === true) {

      if (config.qr_type_display === "online") {
        doc.addImage(qrcodedata.data, 'PNG', 60, 1.5, 10, 10, null, "SLOW");

      }else if(config.qr_type_display === "product_page") {
        doc.addImage(getQrcodeByProductId(qrcodedata.data, v.product_id), 'PNG', 60, 1, 11.5, 11.5, null, "SLOW");

      }else {
        doc.addImage(getQrcodeByStockId(qrcodedata.data, v.stock_id), 'PNG', 60, 1, 11.5, 11.5, null, "SLOW");

      }
    } else {
      //   v.Stones.map((v,k,arr)=>{
      //   if(v.stone_code.length>=v.shape_code.length){
      //     doc.setFontSize( fontSizeStoneBylength[v.stone_code.length])
      //   }else{
      //     doc.setFontSize( fontSizeStoneBylength[v.shape_code.length]);
      //   }
      //  let space = 2.5;
      //   // const text = v.stone_code+" "+v.shape_code+" "+" "+v.Pcs+" "+v.Cts;
      //   doc.text( v.stone_code, 55,space*(k+1), 0);
      //   doc.text( v.shape_code, 60,space*(k+1), );
      //   doc.text( v.Pcs, 65,space*(k+1));
      //   doc.text( v.Cts, 70,space*(k+1), );
      // })
    }
    // doc.addImage("", 'PNG', 55, 1.5, 10,10,null,"SLOW");


    if (k + 1 < dataList.length) {
      doc.addPage();

    }
  })
  switch (returnType) {
    case "save":
      return doc.save("barcode.pdf");
    case "uri":
      return doc.output('datauristring');
     
    case "printout":
      doc.autoPrint({ variant: 'non-conform' })
      return doc.output('dataurlnewwindow');
      // return doc.addJS('print({});');
  
    default:
      return doc.save("barcode.pdf");
  }

}
