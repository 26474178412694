import React from 'react';
import $ from 'jquery';

export const Stocktransferdetails = (prioritydata, setImageid, setProductmodaldata, t) => {

   const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
   return (
      <React.Fragment>
         {prioritydata?.code === 200 ?
            <tr className="common-sub-table-wrap add-row remove" id={"common-table-sub-row" + prioritydata?.data?.id}>
               <td colSpan="16" >
                  <table id={"data_table_set_" + prioritydata?.data?.id} className="common-table common-sub-table">
                     <thead>
                        <tr>
                           <th className="col-highlight"></th>
                           <th className="col-highlight">#</th>
                           <th className="col-highlight">{t("img.")}</th>
                           <th className="col-highlight">{t("Product Name")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th className="col-highlight">{t("SKU")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th>{t("Stock ID")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th>{t("Metal")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th>{t("Stone")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th>{t("Size")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th>{t("Qty")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th className="danger-text">{t("POS No.")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th className="danger-text">{t("Customer")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th className="danger-text">{t("Delivery Date")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th className="danger-text">{t("Due Days")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th>{t("Remark")}
                              <div className="up-down-arrow-btn">
                                 <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                 <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                              </div>
                           </th>
                           <th>{t("Design")}</th>
                        </tr>
                     </thead>
                     <tbody>
                        <>
                           {Object.values(prioritydata?.data?.po_product_data).map((result, key) => {
                              return (
                                 <tr key={key}>
                                    <td className='sku-col table_btn_expand_col'>
                                       <div className="td-icons-wrap">
                                          {result?.allocatable === true ? <div className="td-icons-wrap-link">
                                             <img
                                                className="img"
                                                src={
                                                   base_url_assets +
                                                   'images/icons/link-icon.svg'
                                                }
                                                alt=""
                                             />
                                          </div> : ""}
                                       </div>
                                       <div className="td-icons-wrap-user">
                                          <div className='tooltip-area-up' >
                                             {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user"><img
                                                className="img"
                                                src={
                                                   base_url_assets +
                                                   'images/icons/user-red.png'
                                                }
                                                alt=""
                                             />
                                             </div> : ""}
                                             <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.pos_no}<br />{result?.delivery_date}</span>
                                          </div>
                                       </div>
                                    </td>
                                    <td className="col-highlight">{key + 1}</td>
                                    <td className="col-highlight"><div className="table-pro-img" onClick={() => setImageid(result?.product_id)} data-bs-toggle="modal" data-bs-target="#ProductViewModal"><img src={result?.main_image} alt="" /></div></td>
                                    <td className="po-no col-highlight">{result.name ? result.name : "-"}</td>
                                    <td className="po-no col-highlight link-color" data-bs-toggle="modal" onClick={() => { setProductmodaldata(result) }} data-bs-target="#ProductInfoModal" >{result.SKU ? result.SKU : "-"}</td>
                                    <td className="col-highlight"> <span className='red-highlight'>{result.stock_id ? result.stock_id : "-"}</span>
                                    </td>
                                    <td>{result.metal_name ? result.metal_name : "-"}</td>
                                    <td>{result.stone_name ? result.stone_name : "-"}</td>
                                    <td>{result.size_name ? result.size_name : "-"}</td>
                                    <td>{result.po_QTY ? result.po_QTY : "0"}</td>
                                    <td>{result.pos_no ? result.pos_no : "-"}</td>
                                    <td>{result.customer_name ? result.customer_name : "-"}</td>
                                    <td>{result.delivery_date ? result.delivery_date : "-"}</td>
                                    <td>{result.due_days ? result.due_days : "0"}</td>
                                    <td className="text-center">{result.remark ? result.remark : "-"}</td>
                                    <td>
                                       {result?.pos?.url ?
                                          <a href={result?.pos?.url} target="_blank" rel="noreferrer">
                                             <img
                                                className="pdf-image"
                                                src={base_url_assets + "images/icons/pdf-icon.png"}
                                                alt=""
                                             />
                                          </a>
                                          : '-'
                                       }
                                    </td>
                                 </tr>
                              );
                           })}
                        </>
                     </tbody>
                     <tfoot>
                        <tr>
                           <td />
                           <td />
                           <td />
                           <td />
                           <td />
                           <td />
                           <td />
                           <td />
                           <td />
                           <td >{prioritydata?.data?.po_product_data
                              ? prioritydata?.data?.po_product_data?.reduce(
                                 (interation, val) =>
                                 (interation =
                                    interation + parseInt(val.po_QTY)),
                                 0
                              )
                              : ""}</td>
                           <td />
                           <td />
                           <td />
                           <td />
                           <td />
                        </tr>
                     </tfoot>
                  </table>
               </td>
            </tr>
            : ''
         }

      </React.Fragment>

   );
}

$(document).on("click", ".subtablecheck_main", function () {


   var maincheckbox = document.querySelector(".subtablecheck_main");

   var allcheckbox = document.querySelectorAll(".createPurchaseselect");
   if (maincheckbox.checked === true) {
      allcheckbox.forEach(function (checkBox_selectAble) {
         checkBox_selectAble.checked = true;
      });
   } else {
      allcheckbox.forEach(function (checkBox_selectAble) {
         checkBox_selectAble.checked = false;
      });
   }

});




