import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Users from "../components/Users/Users"
import AddUser from "../components/Users/AddUser"
import Roles from "../components/Users/Roles"
import RolesUser from "../components/Users/RolesUser"
import UserLogReport from "../components/Users/UserLogReport"
import OperationLogReport from "../components/Users/OperationLogReport"
import UserLogs from "../components/Users/UserLogs"
import InventoryHeader from './InventoryHeader';
import CustomSwitch from '../CustomSwitch.js';
import PageNotFound from '../components/common/404.js';
import { useSelector } from 'react-redux';

export default function UserRoutes(props) {
    const { Fulhandle } = props;
    useEffect(() => {
        localStorage.removeItem("customerdetails")
        localStorage.removeItem("salesdetails")
    }, [])
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
    return (
        <CustomSwitch>
            <Route element={<InventoryHeader Fulhandle={Fulhandle} />}>
                <Route>

                    <Route exact path='/' element={parmissiondatashow?.User?.View ? <Users /> :
                        parmissiondatashow?.user_roles?.View ? <Roles /> : parmissiondatashow?.user_reports?.View ? <UserLogs /> : ""} />
                    {parmissiondatashow?.User?.View ?
                        <>
                            <Route exact path='/' element={<Users />} />
                            {parmissiondatashow?.User?.Add && <Route exact path='adduser' element={<AddUser />
                            } />}
                        </> : ""}
                    {parmissiondatashow?.user_roles?.View &&
                        <>
                            <Route exact path='Roles' element={<Roles />} > </Route>
                            <Route exact path='RolesUser' element={<RolesUser />} > </Route>
                        </>}
                    {parmissiondatashow?.user_reports?.View &&
                        <>
                            <Route exact path='UserLogs' element={<UserLogs />} > </Route>
                            <Route exact path='UserLogReport' element={<UserLogReport />} > </Route>
                            <Route exact path='OperationLogReport' element={<OperationLogReport />} > </Route>
                        </>}

                    <Route path='*' element={<PageNotFound />} ></Route>
                </Route>
            </Route>
        </CustomSwitch>
    )
}
