import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
const Advancedfiltermodel = (props) => {
  const { t } = useTranslation();
  useEffect(() => { }, []);

  const [formValues, setFormValues] = useState([{ colomn: "", condition: "", condition_val: "" }]);
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };
  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { colomn: "", condition: "", condition_val: "" },
    ]);
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;

  return (
    <div
      className="modal fade filter-modal"
      id="exampleModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("Advanced filters")}
            </h5>
            <button
              type="button"
              className="filter-modal-clear-all"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {t("Clear All")}
            </button>
            <button className="filter-modal-apply-btn">{t("apply")}</button>
          </div>
          <div className="modal-body">
            <div className="filter-modal-row">
              <div className="filter-modal-row-col-left">{t("Where")}</div>
              <div
                className="filter-modal-row-col-right"
                id="filter-modal-row_data"
              >
                {formValues.map((element, index) => (
                  <div key={index} className="filter-modal-row-col-right-row">
                    <select
                      defaultValue={element.colomn || ""}
                      name="colomn"
                      onChange={(e) => handleChange(index, e)}
                      className="filter-modal-row-col-right-row-column"
                    >
                      <option value="">Select Column</option>
                      <option value="Column">Column</option>
                      <option value="Column1">Column1</option>
                      <option value="Column2">Column2</option>
                    </select>
                    <select
                      defaultValue={element.condition || ""}
                      name="condition"
                      onChange={(e) => handleChange(index, e)}
                      className="filter-modal-row-col-right-row-column"
                    >
                      <option value="">Select Condition</option>
                      <option value="Condition1">Condition1</option>
                      <option value="Condition2">Condition2</option>
                    </select>
                    <input
                      className="filter-modal-row-col-right-row-column filter-modal-row-col-right-row-value"
                      placeholder="Value"
                      type="text"
                      onChange={(e) => handleChange(index, e)}
                      value={element.condition_val}
                      name="condition_val"
                    />
                    <button
                      onClick={() => removeFormFields(index)}
                      className="btn remove"
                    >
                      <svg
                        width={16}
                        height={17}
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.3886 0.477295L8 6.86587L1.61143 0.477295L0 2.08872L6.38857 8.47729L0 14.8659L1.61143 16.4773L8 10.0887L14.3886 16.4773L16 14.8659L9.61143 8.47729L16 2.08872L14.3886 0.477295Z"
                          fill="#2A2A2A"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <p
              onClick={addFormFields}
              className="filter-modal-add-filter"
              id="add-filter-row"
            >
              <img src={base_url + "images/icons/add_ww.png"} alt="" />
              {t("Add new filter")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advancedfiltermodel;
