import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckoutBlank from "../checkout/checkoutBlank";
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import "./Deposit.scss";
import { error, loading, Updateloading } from "../common/helpers/toastify";
import { _Api } from "../common/api/_call";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toFormatPrice } from '../common/helpers/function';
import { store } from '../../source/index'
const Deposit = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const modalRef = useRef(null);
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { ValueContainer, Placeholder } = components;
  const [refno, setRefno] = useState();
  const [deletestatus, setdeletestatus] = useState()
  const [searchproductstatus, setsearchproductstatus] = useState()
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [showpayment, setShowpayment] = useState(false);
  const [UpdatedCart, setUpdatedCart] = useState()
  const [typeamount, setTypeamount] = useState({ 'cash': 0, 'creditcard': 0, 'bank': 0 })
  const [amount, setAmount] = useState();
  const [price, setPrice] = useState()
  const [transtype, setTranstype] = useState({
    label: "Cash",
    value: "cash"
  });
  const [remark, setRemark] = useState();
  const saleperson = useSelector((state) => state.app.saleperson);
  const customer = useSelector((state) => state.app.customer);
  const [TransactionDate, setTransactionDate] = useState(new Date());
  const [depositcustomer, setDepositcustomer] = useState()
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const Type = [
    {
      label: "Cash",
      value: "cash"
    },
    {
      label: "Credit Card",
      value: "creditcard"
    },
    {
      label: "Bank",
      value: "bank"
    }
  ]
  const selectTransactionDate = (input) => {
    setTransactionDate(input);
  };
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const adddeposit = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    let GetloadingID = loading();
    try {
      const postdata = {
        "Ref_No": refno,
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "amount": parseFloat(amount),
        "transaction_type": transtype?.value,
        "remark": remark,
        "order_type": "deposit"
      }
      let res = await _Api(postdata, `api/v1/POS/deposit/addDeposittocart`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t("Add successfully"));
        if (state?.order_id && state?.order_edit && state?.order_type) {
          addtoexchangecart(state?.order_id, state?.order_edit, state?.order_type)
        }
        setShowcheckout('sell');
        setsearchproductstatus(true);
        if (transtype?.value === 'cash') {
          setTypeamount(previousInputs => ({ ...previousInputs, cash: (parseFloat(typeamount?.cash) + parseFloat(amount)) }))
        } else if (transtype?.value === 'creditcard') {
          setTypeamount(previousInputs => ({ ...previousInputs, creditcard: (parseFloat(typeamount?.creditcard) + parseFloat(amount)) }))
        } else {
          setTypeamount(previousInputs => ({ ...previousInputs, bank: (parseFloat(typeamount?.bank) + parseFloat(amount)) }))
        }
        setRefno('');
        setTranstype({
          label: "Cash",
          value: "cash"
        });
        setRemark('');
        setAmount('');
        if (modalRef.current) {
          modalRef.current.hide();
        }
      } else {
        Updateloading(t, GetloadingID, res?.errors, 'error');

      }
    }
    catch (err) {
      console.log(err, "err");
    }
  }
  const getcustomerdetails = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    try {
      const postdata = {
        "id": customer?.id,
      };
      let res = await _Api(postdata, `api/v1/APP/customer/get`);
      if (res.code === 200) {

        setDepositcustomer(res.data);

      } else {
        setDepositcustomer('')
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const updatetranscation = () => {
    if (deletestatus?.status === true) {
      if (deletestatus?.type === 'cash') {
        setTypeamount(previousInputs => ({ ...previousInputs, cash: (parseFloat(typeamount?.cash) - parseFloat(deletestatus?.amount)) }))
      }
      if (deletestatus?.type === 'creditcard') {
        setTypeamount(previousInputs => ({ ...previousInputs, creditcard: (parseFloat(typeamount?.creditcard) - parseFloat(deletestatus?.amount)) }))
      }
      if (deletestatus?.type === 'bank') {
        setTypeamount(previousInputs => ({ ...previousInputs, bank: (parseFloat(typeamount?.bank) - parseFloat(deletestatus?.amount)) }))
      }

    }
    setdeletestatus(false);
  }
  useEffect(() => {
    if (customer?.id) {
      getcustomerdetails();
    }
    // eslint-disable-next-line
  }, [customer?.id]);
  useEffect(() => {
    updatetranscation();
    // eslint-disable-next-line
  }, [deletestatus]);

  const addtoexchangecart = async (order_id, order_edit, order_type) => {

    try {
      const postdata = {
        "order_id": order_id,
        'order_type': order_type ? order_type : 'deposit',
        "order_edit": order_edit,
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        var cash = 0;
        var bank = 0;
        var creditcard = 0;
        if (order_id && order_edit && order_type) {
          setUpdatedCart(res?.data);
          setShowcheckout('sell');
          setsearchproductstatus(true);
          var result = res?.data?.sell_info?.cart.reduce((sum, item) => sum + item.price, 0);
          setPrice(result)
          res?.data?.sell_info?.cart.forEach((result) => {
            if (result?.transaction_type === "cash") {
              cash += result?.price;
              setTypeamount(previousInputs => ({ ...previousInputs, cash: (parseFloat(cash)) }))
            }
            if (result?.transaction_type === "creditcard") {
              creditcard += result?.price;
              setTypeamount(previousInputs => ({ ...previousInputs, creditcard: (parseFloat(creditcard)) }))
            }
            if (result?.transaction_type === "bank") {
              bank += result?.price;
              setTypeamount(previousInputs => ({ ...previousInputs, bank: (parseFloat(bank)) }))
            }
          })
        } else {
          if (res?.data?.length) {
            res?.data?.forEach((result) => {
              if (result?.transaction_type === "cash") {
                cash += result?.price;
                setTypeamount(previousInputs => ({ ...previousInputs, cash: (parseFloat(cash)) }))
              }
              if (result?.transaction_type === "creditcard") {
                creditcard += result?.price;
                setTypeamount(previousInputs => ({ ...previousInputs, creditcard: (parseFloat(creditcard)) }))
              }
              if (result?.transaction_type === "bank") {
                bank += result?.price;
                setTypeamount(previousInputs => ({ ...previousInputs, bank: (parseFloat(bank)) }))
              }
            })
          }
        }
      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    if (state?.order_id && state?.order_edit && state?.order_type) {
      addtoexchangecart(state?.order_id, state?.order_edit, state?.order_type)
    }
    // eslint-disable-next-line
  }, [deletestatus, state?.order_id])
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  useEffect(() => {
    if (showcheckout === "sell") {
      addtoexchangecart()
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    modalRef.current = new window.bootstrap.Modal(document.getElementById('customerADDModal'));
  }, []);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="deposit-page">
              <div className="customer-inner-tophead">
                <div className='common-navigation-header-left'>
                  <h3 className='common-navigation-header-left-heading'> <Link to="/app/pos/receive/" className='gray-mid text-decoration-none'>{t("Receive")} </Link>
                    <img
                      className='Dailysalereport-head-arrow mx-3'
                      src={base_assets + "images/pos/report/arrow-right.png"}
                      alt=""
                    /> {t("Deposit")}</h3>
                </div>

                <div className="customer-inner-tophead-right">
                  <div className={parmissiondatashow?.pos_deposit?.Add ? "customer-inner-tophead-right-custom" : "customer-inner-tophead-right-custom d-none"}>
                    <button
                      className="cst-btn btn img-btn-hover"
                      data-bs-toggle="modal"
                      data-bs-target="#customerADDModal"
                    >
                      <img
                        src={base_assets + "images/icons/adplus.png"}
                        className="img-btn-hover-img-1"
                        alt="filter"
                      />
                      <img
                        src={base_assets + "images/icons/adplus-w.png"}
                        className="img-btn-hover-img-2"
                        alt="filter"
                      />
                      {t("Deposit")}
                    </button>
                    <div
                      className="modal fade deposit-modal"
                      id="customerADDModal"
                      tabIndex="-1"
                      aria-labelledby="customerModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog deposit-modal-popupbox modal-dialog-centered">
                        <div className="modal-content ">
                          <div className="deposit-modal-popupbox-from">
                            <div className="modal-header deposit-modal-popupbox-from-header border-0 text-center">
                              <h5
                                className="modal-title deposit-modal-popupbox-from-header-add-deposit w-100"
                                id="customerModalLabel"
                              >
                                {t("Add Deposit")}
                              </h5>
                              <img className="deposit-modal-popupbox-from-header-close-btn btn" data-bs-dismiss="modal"
                                aria-label="Close" src={base_assets + "images/pos/close-btn.png"} alt=""></img>
                            </div>
                            <div className="modal-body deposit-modal-popupbox-from-body">
                              <div className="deposit-modal-popupbox-from-body-box">
                                <div className=
                                  "deposit-modal-popupbox-from-body-box-inner">
                                  <div className="deposit-popup  deposit-modal-popupbox-from-body-box-inner-row">
                                    <div className="col placeholder-up-input-date date deposit-modal-popupbox-from-body-box-inner-row-col">
                                      <DatePicker
                                        className="placeholder-up-input-field "
                                        selected={TransactionDate}
                                        onChange={selectTransactionDate}
                                        name="TransactionDate"
                                        dateFormat={OrgSettings?.date_picker}
                                        autoComplete='none'
                                      />
                                      <img
                                        className="placeholder-up-input-field-calender"
                                        src={
                                          base_assets +
                                          "images/icons/calendar-green.svg"
                                        }
                                        alt=""
                                      />
                                      <label className="placeholder-up-input-label"> {t("Date")}</label>
                                    </div>
                                    <div className="col deposit-modal-popupbox-from-body-box-inner-row-col deposit-modal-popupbox-from-body-box-inner-row-col placeholder-up-input form-floating">
                                      <input
                                        type="text"
                                        className="form-control deposit-modal-popupbox-from-body-box-inner-row-col-inner placeholder-up-input-field"
                                        placeholder="Ref No."
                                        value={refno ? refno : ""}
                                        aria-label="Ref No."
                                        onChange={(e) => setRefno(e.target.value)}
                                      />
                                      <label className="placeholder-up-input-label">{t("Ref No.")}</label>
                                    </div>
                                  </div>
                                  <div className="  deposit-modal-popupbox-from-body-box-inner-row">
                                    <div className="col deposit-modal-popupbox-from-body-box-inner-row-col ">
                                      <Select
                                        className="select"
                                        value={transtype}
                                        onChange={(e) => setTranstype(e)}
                                        placeholder={t("Type")}
                                        components={{
                                          ValueContainer: CustomValueContainer,
                                        }}
                                        classNamePrefix="common-select"
                                        options={Type}
                                        getOptionLabel={(e) => (
                                          <div className="">{e.label}</div>
                                        )}
                                        isSearchable={false}
                                      />
                                    </div>
                                    <div className="col deposit-modal-popupbox-from-body-box-inner-row-col placeholder-up-input form-floating">
                                      <input
                                        type="number"
                                        className="form-control deposit-modal-popupbox-from-body-box-inner-row-col-inner placeholder-up-input-field"
                                        placeholder="Amount"
                                        value={amount ? amount : ""}
                                        aria-label="Amount"
                                        onChange={(e) => setAmount(e.target.value)}
                                      />
                                      <label className="placeholder-up-input-label">{t("Amount")}</label>
                                    </div>
                                  </div>
                                  <div className="col-12 deposit-modal-popupbox-from-body-box-inner-row-col placeholder-up-input form-floating">
                                    <input
                                      type="text"
                                      className="form-control deposit-modal-popupbox-from-body-box-inner-row-col-remark placeholder-up-input-field"
                                      placeholder="Remark"
                                      value={remark ? remark : ""}
                                      aria-label="Remark"
                                      onChange={(e) => setRemark(e.target.value)}
                                    ></input>
                                    <label className="placeholder-up-input-label">{t("Remark")}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer border-0">
                                <button
                                  className="btn deposit-popupbox-submit deposit-modal-popupbox-from-body-submit-btn"
                                  onClick={adddeposit}
                                >
                                  {t("Submit")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-customer-details">
              <div className="customer-details">
                <div className="customer">
                  <div className="customer-img">
                    <img src={depositcustomer?.profile ? depositcustomer?.profile : ''} alt=""></img>
                  </div>
                  <div className="customer-text">
                    <div className="customer-info">
                      <label className="customer-info-id">{t("Customer ID")}</label>
                      <label className="customer-info-name">:{depositcustomer?.customerid}</label>
                    </div>
                    <div className="customer-info">
                      <label className="customer-info-id">{t("Customer name")} </label>
                      <label className="customer-info-name">
                        : {depositcustomer?.fname}{' '}{depositcustomer?.mname}{' '}{depositcustomer?.lname}
                      </label>
                    </div>
                    <div className="group">
                      {
                        depositcustomer?.group === 'Silver' ?
                          <img className="group-crown" src={base_assets + "images/icons/silver-medal.png"} alt="" /> :
                          depositcustomer?.group === 'Gold' ?
                            <img className="group-crown" src={base_assets + "images/icons/gold-medal.png"} alt="" /> :
                            depositcustomer?.group === 'Platinum' ?
                              <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : depositcustomer?.group === 'Diamond' ?
                                <img className="group-crown" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : ''
                      }
                      <span>{depositcustomer?.group}</span>
                    </div>
                  </div>
                </div>
                <div className="transaction">
                  <div className="transaction-history">
                    {depositcustomer ? <Link to="/app/pos/customer/paymentHistory" state={{ navigatename: 'deposit' }}>
                      <img className="transaction-history-img" src={base_assets + "images/pos/histary.png"} alt=""></img></Link> : ''}</div>
                  <h3 className="transaction-rupess">

                    {state?.order_edit ? toFormatPrice(parseFloat(price), { addSymbol: true }) : toFormatPrice(parseFloat(typeamount?.cash) + parseFloat(typeamount?.creditcard) + parseFloat(typeamount?.bank), { addSymbol: true })}
                  </h3>
                </div>
              </div>
              <div>
              </div>
              <div className="payment-method-text">
                <span>{t("PAYMENT METHOD")}</span>
              </div>
              <div className="payment">
                <div className="payment-method">
                  <div className="payment-method-type">
                    <div className="payment-method-type-img">
                      <img className="payment-method-cash" src={base_assets + "images/pos/cash.png"} alt=""></img>
                    </div>
                    <span className="payment-detals">{t("Cash")}</span>
                  </div>
                  <span className="rupess">
                    {toFormatPrice(typeamount?.cash, { addSymbol: true })}</span>
                </div>
                <div className="payment-method">
                  <div className="payment-method-type">
                    <div className="payment-method-type-img">
                      <img
                        className="payment-method-credit-card"
                        src={base_assets + "images/pos/credit-card.png"} alt=""
                      />
                    </div>
                    <span className="payment-detals">{t("Credit Card")}</span>
                  </div>
                  <span className="rupess">
                    {toFormatPrice(typeamount?.creditcard, { addSymbol: true })}
                  </span>
                </div>
                <div className="payment-method">
                  <div className="payment-method-type">
                    <div className="payment-method-type-img">
                      <img className="payment-method-bank" src={base_assets + "images/pos/bank.png"} alt=""></img>
                    </div>
                    <span className="payment-detals">{t("Bank")}</span>
                  </div>
                  <span className="rupess">
                    {toFormatPrice(typeamount?.bank, { addSymbol: true })}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                searchproductstatus={searchproductstatus}
                setsearchproductstatus={setsearchproductstatus}
                ordertype={"deposit"}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
                order_id={state?.order_id}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} order_edit={state?.order_edit}
                order_type={state?.order_type}
                order_id={state?.order_id} />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Deposit;
















