import React from 'react';
import './Loading.scss';
import { useTranslation } from 'react-i18next';
const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="loading-modal">
      <div className="loading-modal-body">
        <div className="spinner-border"></div>
        <span>{t("Loading...")}</span>
      </div>
    </div>
  )
}

export default Loading;
