import CloseIcon from 'admin/common/icons/CloseIcon';
import MessageIcon from 'admin/common/icons/MessageIcon';
import PageWithPencile from 'admin/common/icons/PageWithPencile'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ZoneSidebarPopup({activeStockID,setShowSidebar}) {
    const {t} = useTranslation();
  return (
    <div className='sidebarpopup'>
        <div className='sidebarpopup-inner'>
            {activeStockID === 'stockid' ?
            <div className='sidebarpopup-inner-top'>
                <div className='sidebarpopup-inner-top-content'>
                <PageWithPencile/>
                <span>{t("Stock ID")}</span>
                </div>
                <div className='cursor-pointer' onClick={()=>setShowSidebar(false)}>
                    <CloseIcon/>
                </div>
            </div>
            :
            <div className='sidebarpopup-inner-top'>
                <div className='sidebarpopup-inner-top-content'>
                <MessageIcon/>
                <span>{t("Remark")}</span>
                </div>
                <div className='cursor-pointer' onClick={()=>setShowSidebar(false)}>
                    <CloseIcon/>
                </div>
            </div>
            }
            <textarea placeholder={activeStockID === 'stockid' ? 'Type your stock ID here.'  : 'Type your remark here.'}/>
            <div className='sidebarpopup-inner-bottom'>
                <button>{t("Submit")}</button>
            </div>
        </div>
    </div>
  )
}
