import React from 'react';
import { toFormatPrice } from '../../common/helpers/function';
export const Quotationsubtable = (prioritydata, id, t) => {

    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    return (
        <React.Fragment>
            {prioritydata.code === 200 ?
                <tr className="common-sub-table-wrap add-row remove" id={`common-sub-table-wrap-${id}`} >
                    <td colSpan="14">
                        <table id={'data_subtable_customorder' + id} className="common-table common-sub-table">
                            <thead>
                                <tr>
                                    <th>  <div className="com-check">
                                        <input id="customordertable" type="checkbox" />
                                        <label htmlFor="customordertable" className="com-check-label"></label>
                                    </div></th>
                                    <th>#</th>
                                    <th>{t("Image")}</th>
                                    <th>{t("Product Name")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("SKU")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Stock ID")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Metal")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Stone")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Size")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Qty")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Price")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Amount")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(prioritydata?.data).map((result, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className="row-check">
                                                <div className="com-check">
                                                    <input type="checkbox" className='allocationselect' id={result?.cart_id}
                                                    />
                                                    <label htmlFor={result?.cart_id} className="com-check-label" />
                                                </div></td>
                                            <td>{key + 1}</td>
                                            <td> <div className='pro-img'><img src={result.main_image ? result.main_image : ""} alt=""></img></div></td>
                                            <td>{result.name ? result.name : ""}</td>
                                            <td>{result.SKU ? result.SKU : ""}</td>
                                            <td>{result.stock_id ? result.stock_id : ""}</td>
                                            <td>{result.Metal ? result.Metal : ""}</td>
                                            <td>{result.stone_name ? result.stone_name : ""}</td>
                                            <td>{result.Size ? result.Size : ""}</td>
                                            <td>{result.Qty ? result.Qty : ""}</td>
                                            <td>{toFormatPrice(result.price, { addSymbol: true })}</td>
                                            <td> {toFormatPrice(result.amount, { addSymbol: true })}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
                : ""}
        </React.Fragment>
    )
}
export default Quotationsubtable;