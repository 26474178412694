import React from "react";

const Inventory = () => {
  return(
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.76457 17.9384L3.76464 17.9383L3.77497 17.9441L11.675 22.3841L11.7037 22.4003L11.73 22.42C11.7928 22.4671 11.884 22.5 12 22.5C12.116 22.5 12.2072 22.4671 12.27 22.42L12.2963 22.4003L12.325 22.3841L20.225 17.9441L20.2249 17.9439L20.2374 17.9374C20.3168 17.8956 20.3833 17.8329 20.4296 17.7561C20.4759 17.6792 20.5002 17.5911 20.5 17.5014V17.5V8.5C20.5 8.31086 20.3974 8.1476 20.2354 8.06156L20.2354 8.06168L20.225 8.05588L12.325 3.61588L12.2963 3.59975L12.27 3.58C12.2072 3.53293 12.116 3.5 12 3.5C11.884 3.5 11.7928 3.53293 11.73 3.58L11.7037 3.59975L11.675 3.61588L3.77497 8.05588L3.77504 8.056L3.76457 8.06156C3.60261 8.1476 3.5 8.31086 3.5 8.5V17.5C3.5 17.6891 3.60261 17.8524 3.76457 17.9384ZM11.755 4.71413L12.0009 4.57593L12.2463 4.71489L14.1363 5.78489L14.8986 6.21642L14.1394 6.65335L8.24941 10.0433L8.002 10.1857L7.7536 10.0451L5.7936 8.93507L5.02247 8.49836L5.79501 8.06413L11.755 4.71413ZM18.2055 8.06444L18.9793 8.50064L18.205 8.93587L12.245 12.2859L12.0002 12.4235L11.7553 12.286L9.79529 11.186L9.02992 10.7565L9.78971 10.3172L15.6697 6.91715L15.9168 6.77425L16.1655 6.91444L18.2055 8.06444ZM19.5 16.91V17.2022L19.2454 17.3456L13.2454 20.7256L12.5 21.1455V20.29V13.58V13.2874L12.7551 13.1441L18.7551 9.77406L19.5 9.3557V10.21V16.91ZM4.75459 17.3456L4.5 17.2022V16.91V10.21V9.3557L5.24485 9.77406L11.2449 13.1441L11.5 13.2874V13.58V20.29V21.1455L10.7546 20.7256L4.75459 17.3456Z" stroke="white"/>
      </svg>



    </>
  )
}

export default Inventory;
