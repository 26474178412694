import React, { useEffect, useState } from 'react';
import "../style.scss";
import { _Api } from "../../common/api";
import { Link, useLocation } from 'react-router-dom';
import HeartIcon from '../assets/icons/HeartIcon'
import Mywishlist from "./Mywishlist"
import { useTranslation } from "react-i18next";
const RecentView = (props) => {
    const { currentid, wishlistdata, setWishliatdata } = props;
    const location = useLocation();
    const { t } = useTranslation();
    let isArabic = localStorage.getItem('i18nextLng');
    const accessToken = localStorage.getItem("ecomaccesstoken");
    let localRecentViewed = localStorage.getItem('RecentViewed') ? JSON.parse(localStorage.getItem('RecentViewed')) : [];
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    const [recentviewddata, setRecentvieweddata] = useState([]);
    // const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
    // useEffect(() => {
    //     setWishliatdata(wishlist)
    // }, [wishlist])
    const addrecentviewed = async () => {
        try {
            let postdata = {
                productIds: localRecentViewed
            }
            await _Api(postdata, `checkout/addProductToView`);
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getrecentviewed = async () => {
        try {
            let postdata = {
                "id": currentid ? currentid : '',
                "productIds": localRecentViewed,
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `checkout/recentView`);
            if (res?.statusCode === 200) {
                setRecentvieweddata(res?.data);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        if (localRecentViewed?.length && accessToken) {
            if (location.pathname.includes("/product")) {
                addrecentviewed();
            }
        }
        // eslint-disable-next-line
    }, [localRecentViewed?.length, accessToken])

    useEffect(() => {
        getrecentviewed();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {
                (recentviewddata?.length) ?
                    <div className='ecommerce-recent_view-container'>
                        <div className='ecommerce-recent_view-container-title'>
                            {t("See Similar Designs")}
                        </div>
                        <div className='ecommerce-recent_view-container-inner'>
                            {
                                recentviewddata.map((item, key) => {
                                    return (

                                        <Link to={`/product/${item?.Slug}`} state={{ id: item?._id }} className='ecommerce-recent_view-container-inner-product' key={key}>
                                            <div className='ecommerce-recent_view-container-inner-product-image'>
                                                <div className="ecommerce-recent_view-container-inner-product-image-imgBoxShow"><img src={item?.main_image} alt='product' /></div>
                                                <div className={isArabic === 'arabic' ? 'heartIconBoxrtl' : 'heartIconBox'} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                                                    {item?.isWishlist ?
                                                        <i onClick={() => {
                                                            setWishliatdata((state) => ({
                                                                ...state,
                                                                id: item?._id,
                                                                add: false,
                                                                remove: true,
                                                                index: key
                                                            }))
                                                        }} className="fa fa-sharp fa-solid fa-heart text-danger" style={{ fontSize: "20px" }}></i> :
                                                        <span
                                                            onClick={() => {
                                                                setWishliatdata((state) => ({
                                                                    ...state,
                                                                    id: item?._id,
                                                                    add: true,
                                                                    remove: false,
                                                                    index: key
                                                                }))
                                                            }
                                                            }>   <HeartIcon color={"red"} width={24} height={24} /></span>}
                                                </div>


                                            </div>
                                            <div className="ecommerce-recent_view-container-inner-product-details text-decoration-none ">
                                                <div className='ecommerce-recent_view-container-inner-product-details-wrapper'>
                                                    <div className="ecommerce-recent_view-container-inner-product-details-wrapper-productName">
                                                        {item?.name}
                                                    </div>
                                                    <span>{item?.SKU} </span>
                                                </div>
                                                <div className='ecommerce-recent_view-container-inner-product-price'>
                                                    {item?.price}
                                                </div>
                                            </div>
                                        </Link>

                                    )
                                })
                            }
                        </div>
                        <Mywishlist
                            wishlistdata={wishlistdata}
                            productdata={recentviewddata}
                            setProductdata={setRecentvieweddata}
                        />
                    </div >
                    : ''
            }
        </>
    )
}

export default RecentView 