import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select, { components } from "react-select";
import "../common/multi-select/multiSelect.scss";
import ProductMenu from "./productMenu";
// import { SetdataTable ,UnSetdataTable} from "../api/setdatatable";
import { graphqlPost, _Api } from "../api/_call";
import { useForm } from "react-hook-form";
import CopyToAllchoice from "./CopyToAll_choice_model";
import { error, loading, Updateloading } from "../common/helpers/toastify";
import "./product.scss";
import { useTranslation } from 'react-i18next';
const ProductChoice = (props) => {
  const { t } = useTranslation();
  const { productId_url } = useParams();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [IsDisable, setIs_disable] = useState(false);
  const [, setVarient_image] = useState("");
  const [selectedButton, setButton] = useState("");
  const [, setSelectedPointer_shape] = useState([]);
  const [productTabIndex, setTabIndex] = useState(0);
  const [pointerInputs, setPointerInputs] = useState([0]);
  const [data_stoneShape_list, setStonesShapeList] = useState([]);
  const [productChoiceData, setProductChoiceData] = useState([]);
  const [productChoiceLocation, setProductChoiceLocation] = useState([]);
  const [data_location_list, setLocationList] = useState([]);
  const [useFirst] = useState(true);
  const [call, setCall] = useState('');

  var checked_location_ind = [];
  const [product_value_arr_varient, setproduct_value_arr_varient] = useState([
    { product_id: "", name: "", varient_type: "" },
  ]);
  const status = [
    { value: "1", label: <span className="dot-single"></span> },
    { value: "0", label: <span className="dot-single red"></span> },
  ];
  var pointer_value = [];
  const [checked_shape, setChecked_shape] = useState(false);
  const [checked_pointer, setChecked_pointer] = useState(true);
  const [checked_location, setChecked_location] = useState(false);
  const handleClick_shape = () => setChecked_shape(!checked_shape);
  const handleClick_pointer = () => setChecked_pointer(!checked_pointer);
  const handleClick_location = () => setChecked_location(!checked_location);
  const [data_shape, setShape] = useState([]);
  const [data_location, setLocation] = useState([]);
  const [shape_load, setShape_load] = useState([]);
  const [location_load, setLocation_load] = useState([]);
  const [product_value_arr, setproduct_value_arr_val] = useState([{ product_id: "", name: "", varient_type: "" }]);
  const getProductUrl_details = async () => {
    if (productId_url && productId_url !== undefined) {
      try {
        const postdata = {
          id: productId_url,
        };
        let resPoOrders = await _Api(postdata, `api/v1/APP/product/getProductType`);
        if (resPoOrders.code === 200) {
          setproduct_value_arr_val(resPoOrders.data);
        } else {
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };

  var setected_opt = {
    generalInfo: "",
    advanced: "",
    variant: "",
    choice: "active",
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getLocationList();
    }, 200);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [product_value_arr]);
  useEffect(() => {
    setproduct_value_arr_varient(product_value_arr);
    getVarientImage(product_value_arr[0].varient_type);
    // eslint-disable-next-line
  }, [product_value_arr]);
  useEffect(() => {
    getProductUrl_details();
    stoneShapeList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // setTable();
    // eslint-disable-next-line
  }, [productTabIndex]);

  // const setTable = () => {
  //   const timer = setTimeout(() => {
  //     SetdataTable("choiceTable_" + productTabIndex, 4);
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // };

  const stoneShapeList = async () => {
    try {
      var stone_list = [];
      const postdata = `{
        shapes {
            id
            name 
        }
      }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      stone_list = responce?.data?.shapes?.map(result => ({
        value: result.id,
        label: result.name,
        pointer: [],
        isFixed: true,
      })) || [];;
      setStonesShapeList(stone_list);
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getLocationList = async () => {

    try {
      const postdata = {
        id: productId_url,
      };
      let location_data = await _Api(
        postdata,
        `api/v1/APP/product/getLocationByProductID`
      );
      if (location_data.code === 200) {
        setLocationList(location_data?.data);
      } else {
        error(t(location_data?.errors));
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const handleSelectShape = (data) => {
    setShape(data);
    setSelectedPointer_shape(data);
  };
  const handleSelectPointer = (data, index) => {
    var data_shap_arr = [...data_shape];
    data_shap_arr[index]['pointer'] = data;
    setSelectedPointer_shape(data_shap_arr)
  };
  const handleSelectLocation = (data) => {
    setLocation(data);
  };
  const createChoice = async () => {
    // UnSetdataTable("choiceTable_" + productTabIndex);
    if (!data_location.length) {
      error("please choose Location")
      return;
    }
    if (!data_shape) {
      error("please choose shape")
      return;
    }
    try {
      var postdata = {
        id: product_value_arr[0].product_id,
        variants: {
          shape: {
            title: "shape",
            status: checked_shape ? 1 : 0,
            values: data_shape,
          },
          location: {
            title: "location",
            status: checked_location ? 1 : 0,
            values: data_location,
          },
        },
      };

      let resPoOrders = await _Api(postdata, `api/v1/APP/product/createDesign`);
      if (resPoOrders.code === 200) {
        setProductChoiceData(resPoOrders.data);
        setProductChoiceLocation(resPoOrders?.data[productTabIndex]?.location);

      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (productChoiceData[productTabIndex] !== undefined) {
      setProductChoiceLocation(productChoiceData[productTabIndex]?.location);
    }
    // eslint-disable-next-line
  }, [productTabIndex]);

  useEffect(() => {
  }, [productChoiceData]);

  const getVarientImage = (type) => {
    var image = "";
    if (type === 1) {
      image = base_assets + "images/icons/variant-sm.png";
    } else if (type === 2) {
      image = base_assets + "images/pro-ring-img.png";
    }
    setVarient_image(image);
  };

  const setTabIndex_fun = (index) => {
    setTabIndex(index);
  };
  const choice_product_add = async () => {
    var choice_Options = {
      id: product_value_arr_varient[0].product_id,
      variants: {
        shape: {
          title: "shape",
          status: checked_shape ? 1 : 0,
          values: data_shape,
        },
        location: {
          title: "location",
          status: checked_location ? 1 : 0,
          values: data_location,
        },
      },
    };
    const postdata = {
      id: product_value_arr[0].product_id,
      choice_Options: choice_Options,
      choice: productChoiceData,
    };
    let GetloadingID = loading();
    let resPoOrders = await _Api(postdata, `api/v1/APP/product/saveDesign`);
    if (resPoOrders.code === 200) {
      Updateloading(t, GetloadingID, t('Choice Successfully updated'));
      setButton("pe-none ct-disable");
    } else {
      setButton("ErrorShowBtn");
      Updateloading(t, GetloadingID, t(resPoOrders.errors), t('error'));
    }

  };
  const setVariants_priceMap = (
    variants_index,
    price_index,
    price_subindex,
    value
  ) => {
    var productChoiceData_arr = [...productChoiceData];
    productChoiceData_arr[productTabIndex]["variants"][variants_index]["price"][
      price_index
    ][price_subindex] = value;
    setProductChoiceData(productChoiceData_arr);
  };
  const { handleSubmit } = useForm();
  useEffect(() => {
    if (data_shape) {
      getPointer();
      setCall(0);
    }
    // eslint-disable-next-line
  }, [data_shape]);

  const MultiValueRemove = (props) => {

    return <components.MultiValueRemove {...props} />;
  };
  const getPointer = async (id) => {
    data_shape.map(async (ele, index) => {
      try {
        const postdata = {
          id: ele.value,
        };
        let shape_data = await _Api(
          postdata,
          `api/v1/APP/product/getPointerByShapeID`
        );
        if (shape_data.code === 200) {
          (pointer_value[ele.value] = pointer_value[ele.value] || []).push({ 'shape_id': ele.value, 'shape_name': ele.label, 'pointer': shape_data?.data });
          setPointerInputs(pointer_value);
        }
      } catch (err) {
        console.log(err, "err");
      }
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setCall(1);
    }, 300);
    return () => clearTimeout(timer);

  }, [pointerInputs]);

  const getDesignOptions = async (id) => {

    try {
      const postdata = {
        id: product_value_arr[0]?.product_id,
      };
      let shape_data = await _Api(
        postdata,
        `api/v1/APP/product/getDesignOptions`
      );
      if (shape_data.code === 200) {
        var choice_data = shape_data.data;
        var choice_Options = choice_data?.choice_Options;
        if (choice_Options?.variants !== undefined) {
          var variants = choice_Options?.variants;
          var location = variants?.location;
          var shape = variants?.shape !== undefined ? variants?.shape : [];
          setShape(shape?.values);
          setLocation(location?.values);
          var shapeStatus = shape.status === 1 ? true : false;
          var locationStatus = location.status === 1 ? true : false;
          handleClick_shape(shapeStatus);
          handleClick_location(locationStatus);
          setLocation_load(location?.values);
          setShape_load(shape?.values);
        }

      }
    } catch (err) {
      console.log(err, "err");
    }

  };
  useEffect(() => {
    if (product_value_arr[0]?.product_id && product_value_arr[0]?.product_id !== undefined) {
      if (shape_load || location_load) {
        createChoice();
      }
    }
    // eslint-disable-next-line
  }, [shape_load, location_load]);
  useEffect(() => {
    if (product_value_arr[0]?.product_id) {
      setIs_disable('pe-none  ct-disable');
      getDesignOptions();
    }
    // eslint-disable-next-line
  }, [product_value_arr]);

  const setVariants_statusMap = (variants_index, value) => {
    var productChoiceData_arr = [...productChoiceData];
    productChoiceData_arr[productTabIndex]["variants"][variants_index]["Status"] = value;
    setProductChoiceData(productChoiceData_arr);
  }
  return (
    <React.Fragment key={1}>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

              <form onSubmit={handleSubmit(choice_product_add)}>
                <ProductMenu
                  product_value_arr={product_value_arr_varient}
                  setected_opt={setected_opt}
                  selectedButton_={selectedButton}
                  setIs_disable={setIs_disable}
                  productId_url={productId_url}

                />

                <div className={"product-ct-wrap product-pt " + IsDisable}>
                  <div className="product-ct-wrap-col-left col-left-wrap w-100">
                    <div className="product-com-box-layout">
                      <div className="product-ct-wrap-col-left-name">
                        <h1 className="product-ct-wrap-col-left-name-text">
                          # {product_value_arr_varient[0]?.name}
                        </h1>
                        <h1 className="product-ct-wrap-col-left-name-text ms-auto">{product_value_arr[0]?.sku}</h1>

                        <img
                          className="product-ct-wrap-col-left-name-img"
                          src={base_assets + "images/pro-ring-img.png"} alt=""
                        />
                      </div>
                      <div className="row product-ct-wrap-col-left-input-select-wrap">
                        <h4 className="pro-sub-heading">{t("Choice")}</h4>
                        <div className="col-md-12 product-ct-wrap-col-left-input-select-wrap-input">
                          <div className="product-multi-select-checkbox">
                            <div className="com-check">
                              <input
                                type="checkbox"
                                onChange={() => handleClick_shape()}
                                id="check"
                                defaultChecked={true}
                              />
                              <label
                                className="com-check-label"
                                htmlFor="check"
                              />
                            </div>
                            <div className="product-multi-select">
                              <label className="product-multi-select-label">
                                {t("Shape")}
                              </label>
                              <div className="product-multi-select-box">
                                <Select
                                  options={data_stoneShape_list}
                                  components={{ MultiValueRemove }}
                                  placeholder={t("Shape")}
                                  value={data_shape}
                                  onChange={handleSelectShape}
                                  classNamePrefix="product-multi-select"
                                  getOptionLabel={(e) => (
                                    <div className="product-multi-select__multi-value__label-ct">
                                      {e.icon}
                                      <span>{e.label}</span>
                                    </div>
                                  )}
                                  isSearchable={false}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                          {call ? data_shape.map((ele, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="product-multi-select-checkbox">
                                  <div className="com-check">
                                    <input
                                      type="checkbox"
                                      onChange={() => handleClick_pointer()}
                                      id={"check2" + index}
                                      defaultChecked={true}
                                    />
                                    <label
                                      className="com-check-label"
                                      htmlFor={"check2" + index}
                                    />
                                  </div>
                                  <div className="product-multi-select">
                                    <label className="product-multi-select-label">
                                      {ele.label} {t("Pointer")}
                                    </label>
                                    <div className="product-multi-select-box">
                                      <Select
                                        options={pointerInputs[ele.value] !== undefined ? pointerInputs[ele.value][0]?.pointer : []}
                                        placeholder={t("Pointer")}
                                        value={ele.pointer !== undefined ? ele.pointer : []}
                                        onChange={(data) => handleSelectPointer(data, index)}
                                        classNamePrefix="product-multi-select"
                                        components={{ MultiValueRemove }}
                                        getOptionLabel={(e) => (
                                          <div className="product-multi-select__multi-value__label-ct">
                                            {e.icon}
                                            <span>{e.label}</span>
                                          </div>
                                        )}
                                        isSearchable={false}
                                        isMulti
                                      />
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          }) : ''}
                          <div className="product-multi-select-checkbox">
                            <div className="com-check">
                              <input
                                type="checkbox"
                                onChange={() => handleClick_location()}
                                defaultChecked={true}
                                id="check3"
                              />
                              <label
                                className="com-check-label"
                                htmlFor="check3"
                              />
                            </div>
                            <div className="product-multi-select">
                              <label className="product-multi-select-label">
                                {t("Location")}
                              </label>
                              <div className="product-multi-select-box">
                                <Select
                                  options={data_location_list}
                                  components={{ MultiValueRemove }}
                                  placeholder={t("Location")}
                                  value={data_location}
                                  onChange={handleSelectLocation}
                                  classNamePrefix="product-multi-select"
                                  getOptionLabel={(e) => (
                                    <div className="product-multi-select__multi-value__label-ct">
                                      {e.icon}
                                      <span>{e.label}</span>
                                    </div>
                                  )}
                                  isSearchable={false}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="box-create">
                          <span className="box-create-text">
                            {t("Select the box create choice.")}
                          </span>
                          <button
                            onClick={() => createChoice()}
                            type="button"
                            className="box-create-btn"
                          >
                            {t("Create Choice")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className={"product-ct-wrap "}>
                <div className="product-ct-wrap-col-left col-left-wrap w-100">
                  <div className="product-com-box-layout">
                    <div className="choice-tab-wrap">
                      {productChoiceData?.length ?
                        <>
                          <div
                            className="choice-tab-wrap-tab-row"
                            id="nav-tab"
                            role="tablist"
                          >
                            {productChoiceData?.map((ele, index) => {
                              if (index === 0 && useFirst === true) {
                                ele.active = "active";
                              }
                              return (
                                <React.Fragment key={index}>
                                  <button
                                    onClick={() => setTabIndex_fun(index)}
                                    className={"nav-link " + ele.active}
                                    id={"nav-" + index + "-tab"}
                                    data-bs-toggle="tab"
                                    data-bs-target={"#nav-" + index}
                                    type="button"
                                    role="tab"
                                    aria-controls={"nav-" + index}
                                    aria-selected="true"
                                  >
                                    {ele?.shape?.label}
                                  </button>
                                </React.Fragment>
                              );
                            })}
                            <button
                              className="variant-table-copy"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#CopyToAll_choice"
                            >
                              {t("Copy to all")}
                            </button>
                          </div>

                          <div className="tab-content" id="nav-tabContent">
                            {productChoiceData?.map((ele, index) => {
                              if (index === 0 && useFirst === true) {
                                ele.active = "active";
                              }
                              return (
                                <React.Fragment key={index}>
                                  <div
                                    className={"tab-pane fade show " + ele.active}
                                    id={"nav-" + index}
                                    role="tabpanel"
                                    aria-labelledby={"nav-" + index + "-tab"}
                                  >
                                    <div className="choice-table">
                                      <table
                                        className={"choice-table-inner " + IsDisable}
                                        id={"choiceTable_" + index}
                                      >
                                        <thead>
                                          <tr >
                                            <th rowSpan="2"></th>
                                            <th rowSpan="2">{t("Variant")}</th>
                                            <th rowSpan="2">{t("Product ID")}</th>
                                            <th rowSpan="2">{t("Shape")}</th>
                                            {ele?.location?.length ? ele?.location?.map(
                                              (location_val, location_index) => {
                                                var pointer_length =
                                                  ele?.pointer[location_index].length;
                                                var Currency_lower =
                                                  location_val?.currency?.toLowerCase();
                                                if (
                                                  location_val.price_check !==
                                                  undefined &&
                                                  location_val.price_check === true
                                                ) {
                                                  checked_location_ind.push(
                                                    location_index
                                                  );
                                                }
                                                return (
                                                  <React.Fragment key={location_index}>
                                                    <th colSpan={pointer_length}>
                                                      <div className="country-th">
                                                        <img
                                                          src={
                                                            base_assets +
                                                            "images/flags/" +
                                                            Currency_lower +
                                                            ".png"
                                                          } alt=""
                                                        />{" "}
                                                        {location_val.currency}
                                                      </div>
                                                    </th>
                                                  </React.Fragment>
                                                );
                                              }
                                            ) : ""}
                                          </tr>
                                          <tr className="choice-table-inner-hidden-row">
                                            {ele?.pointer?.length ? ele?.pointer?.map(
                                              (pointer_val_, pointer_index_) => {
                                                return pointer_val_?.map(
                                                  (pointer_val_1, pointer_index_) => {

                                                    return <React.Fragment key={pointer_index_}><th></th></React.Fragment>;
                                                  }
                                                );
                                              }
                                            ) : ''}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {ele?.pointer?.map(
                                              (pointer_val_, pointer_index_) => {
                                                return pointer_val_?.map(
                                                  (pointer_val_1, pointer_index_) => {
                                                    return (
                                                      <React.Fragment key={pointer_index_}>
                                                        <td className="choice-table-inner-pointer">
                                                          {pointer_val_1}
                                                        </td>
                                                      </React.Fragment>
                                                    );
                                                  }
                                                );
                                              }
                                            )}
                                          </tr>
                                          {ele?.variants?.map(
                                            (variants_val, variants_index) => {
                                              let status_;
                                              if (variants_val.Status === 1) {
                                                status_ = status[0];
                                              } else {
                                                status_ = status[1];
                                              }
                                              return (
                                                <React.Fragment key={variants_index}>
                                                  <tr>
                                                    <td className="choice-table-inner-dot-td">
                                                      <div className="variant-table-inner-dot">
                                                        <Select
                                                          placeholder=""
                                                          value={status_}
                                                          onChange={(value) =>
                                                            setVariants_statusMap(
                                                              variants_index,
                                                              value?.value
                                                            )
                                                          }
                                                          options={status}
                                                          classNamePrefix="dot-status"
                                                          isSearchable={false}
                                                        />
                                                      </div>
                                                    </td>
                                                    <td>{variants_val.SKU}</td>
                                                    <td>
                                                      <div className="placeholder-up-input w-100">
                                                        <input
                                                          type="text"
                                                          className="placeholder-up-input-field variant-table-inner-input"
                                                          required=""
                                                          defaultValue={variants_val.ID}
                                                          disabled={true}
                                                        />
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="choice-table-inner-shape">
                                                        <img
                                                          src={
                                                            base_assets +
                                                            "images/diamonds/round-diamond.svg"
                                                          } alt=""
                                                        />
                                                      </div>
                                                    </td>

                                                    {variants_val?.price?.map(
                                                      (price_val, price_index) => {
                                                        variants_val.locationId = ele?.location[price_index].value;
                                                        return price_val?.map(
                                                          (
                                                            price_val_,
                                                            price_index_
                                                          ) => {
                                                            var price_check =
                                                              ele?.location[price_index]
                                                                ?.price_check;
                                                            if (price_check !== undefined && price_check === true) {
                                                              var copy_priceValue = ele?.variants[0].price[price_index][price_index_];
                                                              price_val_ = copy_priceValue;
                                                              price_val[price_index_] = copy_priceValue;
                                                            }
                                                            return (
                                                              <React.Fragment key={price_index_}>
                                                                <td>
                                                                  <div className="placeholder-up-input w-100">
                                                                    <input
                                                                      type="text"
                                                                      className="placeholder-up-input-field variant-table-inner-input"
                                                                      required=""
                                                                      onChange={(e) =>
                                                                        setVariants_priceMap(
                                                                          variants_index,
                                                                          price_index,
                                                                          price_index_,
                                                                          e.target.value
                                                                        )
                                                                      }
                                                                      value={price_val_}
                                                                    />
                                                                  </div>
                                                                </td>
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        );
                                                      }
                                                    )}
                                                  </tr>
                                                </React.Fragment>
                                              );
                                            }
                                          )}

                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CopyToAllchoice
        productChoiceLocation={productChoiceLocation}
        productChoiceData={productChoiceData}
        setProductChoiceData={setProductChoiceData}
        productTabIndex={productTabIndex}
      ></CopyToAllchoice>
    </React.Fragment>
  );
};

export default ProductChoice;
