import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import CheckoutBlank from '../checkout/checkoutBlank'
import { error } from '../common/helpers/toastify'
import { useTranslation } from 'react-i18next';
import { Tablehead } from '../../components/common/modules/Tablehead'
import { posreceivepayment_head } from '../../components/common/Tablehead'
import { _Api } from '../common/api/_call'
import $ from 'jquery';
import { Reseivesubtable } from "../Custom/subtable/Reseivesubtable";
import { useSelector } from 'react-redux'
import CheckoutPayment from '../checkout/checkoutPayment'
import { toFormatPrice } from '../common/helpers/function';
const ReceivePayment = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const customer = useSelector((state) => state.app.customer)
  const saleperson = useSelector((state) => state.app.saleperson)
  const [newcolumndata, setnewcolumndata] = useState(posreceivepayment_head)
  const [reseivedata, setReseivedata] = useState([])
  const [paytype, setpaytype] = useState('ALL')
  const [order_id, setOrder_id] = useState();
  const [searchname, setSearchname] = useState('');
  const [showcheckout, setShowcheckout] = useState('blank')
  const [, setfilterby] = useState("");
  const [UpdatedCart, setUpdatedCart] = useState()
  const [laybyid, setLaybyid] = useState()
  const [excuteTable, setexcuteTable] = useState(false);
  const [custom_order_id, setCustom_order_id] = useState()
  const [laybylist, setLaybylist] = useState([])
  const [greandtotal, setGreandtotal] = useState(0)
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'report_receivepayment_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const getreseivepayment = async () => {

    setexcuteTable(false);

    var expand_col = document.querySelector(".table_btn_expand_col");
    if (expand_col) { expand_col.classList.remove("rotate-90"); }
    var expand_col1 = document.querySelector(".table_btn_expand_col1");
    if (expand_col1) { expand_col1.classList.remove("rotate-90"); }
    var expand_col2 = document.querySelector(".table_btn_expand_col2");
    if (expand_col2) { expand_col2.classList.remove("rotate-90"); }

    try {
      setTimeout(async function () {
        UnSetdataTable('receivePaymentTable')
        UnSetdataTable('receivePaymentTable_layby')
        UnSetdataTable('receivePaymentTable_Installment')
        let postdata = {
          "type": paytype,
          "search": searchname
        }
        let res = await _Api(postdata, `api/v1/POS/receive/LayByList`);
        if (res?.code === 200) {

          setReseivedata(res?.data);
          setexcuteTable(true);
          SetdataTable('receivePaymentTable', 4, newcolumndata, 0)
          SetdataTable('receivePaymentTable_layby', 4, newcolumndata, 0)
          SetdataTable('receivePaymentTable_Installment', 4, newcolumndata, 0)
        } else {
          SetdataTable('receivePaymentTable', 4, 0)
          SetdataTable('receivePaymentTable_layby', 4, 0)
          SetdataTable('receivePaymentTable_Installment', 4, 0)
        }
      }, 100)

    }
    catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getreseivepayment()
    // eslint-disable-next-line
  }, [newcolumndata, paytype])
  const setsubtable = async (id, order_type) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector('#common-main-table-wrap-' + id);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...reseivedata];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = null
      })
      setReseivedata(alllist_data);

    } else {
      classList_.add("rotate-90");
      let alllist_data = [...reseivedata];
      const postdata = {
        "id": id,
        "type": order_type
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/receive/getLayBySKUList`);
      var html_ = Reseivesubtable(prioritydata, id, t);
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = html_
      })
      setReseivedata(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);

    }
  }
  const setsubtable1 = async (id, order_type) => {
    var e = document.querySelector("#table-btn1_" + id);
    var row = document.querySelector(`common-main-table-wrap1-${id}`);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...reseivedata];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = null
      })
      setReseivedata(alllist_data);

    } else {
      classList_.add("rotate-90");
      let alllist_data = [...reseivedata];
      const postdata = {
        "id": id,
        "type": order_type
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/receive/getLayBySKUList`);
      var html_ = Reseivesubtable(prioritydata, id, t);
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = html_
      })
      setReseivedata(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);

    }
  }
  const setsubtable2 = async (id, order_type) => {
    var e = document.querySelector("#table-btn2_" + id);
    var row = document.querySelector(`common-main-table-wrap2-${id}`);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...reseivedata];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = null
      })
      setReseivedata(alllist_data);

    } else {
      classList_.add("rotate-90");
      let alllist_data = [...reseivedata];
      const postdata = {
        "id": id,
        "type": order_type
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/receive/getLayBySKUList`);
      var html_ = Reseivesubtable(prioritydata, id, t);
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = html_
      })
      setReseivedata(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);

    }
  }
  const filterbyname = () => {
    if (searchname) {
      getreseivepayment();
    } else {
      getreseivepayment();
    }
  }
  const callpayementcheckout = async (amount, order_type, installmentarray) => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    try {
      const postdata = {}
      const credit_notepostdata = {
        customer_id: customer?.id
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/checkout/payment/getCashMethodList`,
      )
      let res_credit_note = await _Api(
        credit_notepostdata,
        `api/v1/POS/checkout/creditnote/getCreditNote`,
      )
      if (res.code === 200) {
        setUpdatedCart({
          sell_info: {
            cart: [],
            deletecart_ids: [],
            summary_order: {},
            status_type: order_type,
            totalamount: amount,
            park_id: ''
          },
          totalamount: amount,
          receivestatus: 'receivepay',
          installmentarray: installmentarray ? installmentarray : [],
          payment_Options: res?.data,
          credit_note_options: res_credit_note?.data,
          oldcredit_note_options: res_credit_note?.data,
        })
        setShowcheckout('payment')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 quotation">
            <div className="com-data-area">
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left">
                    <h3 className='common-navigation-header-left-heading'> <Link to="/app/pos/receive" className='gray-mid text-decoration-none'>{t("Receive")} </Link>
                      <img
                        className='Dailysalereport-head-arrow mx-3'
                        src={base_assets + "images/pos/report/arrow-right.png"}
                        alt=""
                      /> {t("Receive Payment")}</h3>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <input
                        type="text"
                        value={searchname}
                        onChange={(e) => setSearchname(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                        placeholder={t("Search By Invoice/SKU/Stock ID/Location")}
                      />
                      <img onClick={filterbyname} className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />

                    </div>
                  </div>
                </div>
              </div>
              <div className="com-data-area-inner bg-white">
                <div className="Dailysalereport-content-area-main quotation-main mt-0">
                  <ul
                    className="Dailysalereport-content-area-navbar nav-tabs quotation-main-navbar mt-4"
                    id="myTab"
                    role="tablist"
                  >
                    <li
                      className="nav-item Dailysalereport-content-area-navbar-item quotation-main-navbar-item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active Dailysalereport-content-area-navbar-item-link quotation-main-navbar-item-link"
                        id="Sale-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Sale"
                        type="button"
                        role="tab"
                        aria-controls="Sale"
                        aria-selected="true"
                        onClick={() => setpaytype('ALL')}
                      >
                        {t("ALL")}
                      </button>
                    </li>
                    <li
                      className={generalsettings?.data?.generalsetup?.POS_partial_payment === '1' ? "nav-item quotation-main-navbar-item" :
                        "d-none"}
                      role="presentation"
                    >
                      <button
                        className="nav-link quotation-main-navbar-item-link quotation-main-navbar-item-link-custom Dailysalereport-content-area-navbar-item-link"
                        id="Layby-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#layby"
                        type="button"
                        role="tab"
                        aria-controls="layby"
                        aria-selected="false"
                        onClick={() => setpaytype('LayBy')}
                      >
                        {t("Layby")}
                      </button>
                    </li>
                    <li
                      className={generalsettings?.data?.generalsetup?.POS_partial_payment === '1' ? "nav-item quotation-main-navbar-item" : "d-none"}
                      role="presentation"
                    >
                      <button
                        className="nav-link quotation-main-navbar-item-link Dailysalereport-content-area-navbar-item-link"
                        id="Installment-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Installment"
                        type="button"
                        role="tab"
                        aria-controls="Installment"
                        aria-selected="false"
                        onClick={() => setpaytype('Installment')}
                      >
                        {t("Installment")}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="Sale"
                      role="tabpanel"
                      aria-labelledby="Sale-tab"
                    >
                      <div
                        className="main-body-main-table-wrap vertical-scroll-table Dailysalereport-table mt-4"
                        id="table-scroll"
                      >
                        <table
                          id="receivePaymentTable"
                          className="common-table first-row-gray w-100 quotation-table Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={posreceivepayment_head}
                            tblname={'report_receivepayment_tbl'}
                            setfilterby={setfilterby}
                          />
                          <tbody>
                            <>
                              {reseivedata?.length ? reseivedata.map((result, key) => {
                                return (
                                  <React.Fragment key={key}>
                                    <tr id={`common-main-table-wrap-${result?.id}`} key={result?.id} onClick={() => {
                                      setLaybyid(result?.id);

                                    }}>
                                      <td>{key + 1}</td>
                                      <td> {result?.date ? result?.date : ""}</td>
                                      <td>{result?.vr_type ? result?.vr_type : ""}</td>
                                      <td className="link-color">{result?.vr_no ? result?.vr_no : ""}</td>
                                      <td className="link-color link-color sku-col table_btn_expand_col"
                                        onClick={() => setsubtable(result?.id, result?.order_type)}
                                        id={"table-btn_" + result?.id}>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            "/images/icons/right_green_button.png"
                                          }
                                          alt=""
                                        />{result?.SKU ? result?.SKU : ""}</td>
                                      <td>{result?.customer ? result?.customer : ""}</td>
                                      <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                      <td className='main-green-text'>{toFormatPrice(result?.payment, { addSymbol: true })}</td>
                                      <td className='danger-text'>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                      <td>{result?.location ? result?.location : ""}</td>
                                      <td>{result?.due_date ? result?.due_date : ""}</td>
                                      <td>
                                        <div className="customer-table-dropdown dropdown">
                                          <button
                                            className="btn-style dropdown-toggle show"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src={
                                                base_assets +
                                                'images/icons/ellipsis-circular.png'
                                              }
                                              alt=""
                                            />
                                          </button>
                                          <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            {result?.status?.is_pending !== 0 ?
                                              <li
                                                onClick={() => {
                                                  callpayementcheckout(result?.outstanding, result?.order_type, result?.installment); setOrder_id(result?.order_id);
                                                  setCustom_order_id(result?.custom_order_id)
                                                  setLaybylist(result?.pay_layby_data)
                                                  setGreandtotal(result?.amount)
                                                  setLaybyid(result?.id)
                                                }}
                                                className="dropdown-item img-btn-hover">

                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i6.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i6w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("Receive")}

                                              </li>
                                              : ''
                                            }
                                            {result?.printURL === "" ? "" :
                                              <li className="dropdown-item img-btn-hover"
                                                onClick={() => {
                                                  if (result?.printURL) {
                                                    window.open(result?.printURL, '_blank', 'noreferrer')
                                                  }
                                                }}>
                                                <div className="dropdown-menu-img"
                                                >
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/report/print-icon.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/print-icon-w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("print")}
                                              </li>
                                            }
                                            <li
                                              data-bs-toggle="modal"
                                            >
                                              <Link
                                                className="dropdown-item img-btn-hover"
                                                to="#"
                                              >
                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i5.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i5w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("Delete")}
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                    {
                                      excuteTable ? (
                                        <>
                                          {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                        </>
                                      ) : null
                                    }
                                  </React.Fragment>
                                )
                              }) : ""}

                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade "
                      id="layby"
                      role="tabpanel"
                      aria-labelledby="layby-tab"
                    >
                      <div
                        className="main-body-main-table-wrap vertical-scroll-table Dailysalereport-table mt-4"
                        id="table-scroll"
                      >
                        <table
                          id="receivePaymentTable_layby"
                          className="common-table first-row-gray w-100 quotation-table Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={posreceivepayment_head}
                            tblname={'report_receivepayment_tbl'}
                            setfilterby={setfilterby}
                          />
                          <tbody>
                            <>
                              {reseivedata?.length ? reseivedata.map((result, key) => {
                                return (
                                  <React.Fragment key={key}>
                                    <tr id={`common-main-table-wrap1-${result?.id}`} key={result?.id} onClick={() => setLaybyid(result?.id)}>
                                      <td>{key + 1}</td>
                                      <td> {result?.date ? result?.date : ""}</td>
                                      <td>{result?.vr_type ? result?.vr_type : ""}</td>
                                      <td className="link-color">{result?.vr_no ? result?.vr_no : ""}</td>
                                      <td className="link-color link-color sku-col table_btn_expand_col1"
                                        onClick={() => setsubtable1(result?.id, result?.order_type)}
                                        id={"table-btn1_" + result?.id}>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            "/images/icons/right_green_button.png"
                                          }
                                          alt=""
                                        />{result?.SKU ? result?.SKU : ""}</td>
                                      <td>{result?.customer ? result?.customer : ""}</td>
                                      <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                      <td className='main-green-text'>{toFormatPrice(result?.payment, { addSymbol: true })}</td>
                                      <td className='danger-text'>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                      <td>{result?.location ? result?.location : ""}</td>
                                      <td>{result?.due_date ? result?.due_date : ""}</td>
                                      <td>
                                        <div className="customer-table-dropdown dropdown">
                                          <button
                                            className="btn-style dropdown-toggle show"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src={
                                                base_assets +
                                                'images/icons/ellipsis-circular.png'
                                              }
                                              alt=""
                                            />
                                          </button>
                                          <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            {result?.status?.is_pending !== 0 ?
                                              <li
                                                onClick={() => {
                                                  callpayementcheckout(result?.outstanding, result?.order_type);
                                                  setOrder_id(result?.order_id);
                                                  setCustom_order_id(result?.custom_order_id)
                                                  setLaybylist(result?.pay_layby_data)
                                                  setGreandtotal(result?.amount)
                                                  setLaybyid(result?.id)
                                                }}
                                                className="dropdown-item img-btn-hover">

                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i6.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i6w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("Receive")}

                                              </li>
                                              : ''
                                            }
                                            <li
                                              onClick={() => {
                                                if (result?.printURL) {
                                                  window.open(result?.printURL, '_blank', 'noreferrer')
                                                }
                                              }}>
                                              <Link
                                                className="dropdown-item img-btn-hover"
                                                to="#"
                                              >
                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/report/print-icon.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/print-icon-w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("print")}
                                              </Link>
                                            </li>

                                            <li
                                              data-bs-toggle="modal"
                                            >
                                              <Link
                                                className="dropdown-item img-btn-hover"
                                                to="#"
                                              >
                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i5.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i5w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("Delete")}
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                    {
                                      excuteTable ? (
                                        <>
                                          {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                        </>
                                      ) : null
                                    }
                                  </React.Fragment>
                                )
                              }) : ""}
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade "
                      id="Installment"
                      role="tabpanel"
                      aria-labelledby="Installment-tab"
                    >
                      <div
                        className="main-body-main-table-wrap vertical-scroll-table Dailysalereport-table mt-4"
                        id="table-scroll"
                      >
                        <table
                          id="receivePaymentTable_Installment"
                          className="common-table first-row-gray w-100 quotation-table Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={posreceivepayment_head}
                            tblname={'report_receivepayment_tbl'}
                            setfilterby={setfilterby}
                          />
                          <tbody>
                            <>
                              {reseivedata?.length ? reseivedata.map((result, key) => {
                                return (
                                  <React.Fragment key={key}>
                                    <tr id={`common-main-table-wrap2-${result?.id}`} key={result?.id} onClick={() => setLaybyid(result?.id)}>
                                      <td>{key + 1}</td>
                                      <td> {result?.date ? result?.date : ""}</td>
                                      <td>{result?.vr_type ? result?.vr_type : ""}</td>
                                      <td className="link-color">{result?.vr_no ? result?.vr_no : ""}</td>
                                      <td className="link-color link-color sku-col table_btn_expand_col2"
                                        onClick={() => setsubtable2(result?.id, result?.order_type)}
                                        id={"table-btn2_" + result?.id}>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            "/images/icons/right_green_button.png"
                                          }
                                          alt=""
                                        />{result?.SKU ? result?.SKU : ""}</td>
                                      <td>{result?.customer ? result?.customer : ""}</td>
                                      <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                      <td className='main-green-text'>{toFormatPrice(result?.payment, { addSymbol: true })}</td>
                                      <td className='danger-text'>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                      <td>{result?.location ? result?.location : ""}</td>
                                      <td>{result?.due_date ? result?.due_date : ""}</td>
                                      <td>
                                        <div className="customer-table-dropdown dropdown">
                                          <button
                                            className="btn-style dropdown-toggle show"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src={
                                                base_assets +
                                                'images/icons/ellipsis-circular.png'
                                              }
                                              alt=""
                                            />
                                          </button>
                                          <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            {result?.status?.is_pending !== 0 ?
                                              <li
                                                onClick={() => {
                                                  callpayementcheckout(result?.outstanding, result?.order_type, result?.installment); setOrder_id(result?.order_id);
                                                  setCustom_order_id(result?.custom_order_id)
                                                }}
                                                className="dropdown-item img-btn-hover">

                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i6.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i6w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("Receive")}

                                              </li>
                                              : ''
                                            }
                                            <li
                                              onClick={() => {
                                                if (result?.printURL) {
                                                  window.open(result?.printURL, '_blank', 'noreferrer')
                                                }
                                              }}>
                                              <Link
                                                className="dropdown-item img-btn-hover"
                                                to="#"
                                              >
                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/report/print-icon.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/print-icon-w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("print")}
                                              </Link>
                                            </li>

                                            <li
                                              data-bs-toggle="modal"
                                            >
                                              <Link
                                                className="dropdown-item img-btn-hover"
                                                to="#"
                                              >
                                                <div className="dropdown-menu-img">
                                                  <img className='img-btn-hover-img-1'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i5.png'
                                                    }
                                                    alt=""
                                                  />
                                                  <img className='img-btn-hover-img-2'
                                                    src={
                                                      base_assets +
                                                      'images/pos/icons/i5w.png'
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                {t("Delete")}
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                    {
                                      excuteTable ? (
                                        <>
                                          {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                        </>
                                      ) : null
                                    }
                                  </React.Fragment>
                                )
                              }) : ""}
                            </>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">

            {showcheckout === 'payment' ? (
              <CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} order_id={order_id}
                custom_order_id={custom_order_id} laybylist={laybylist} greandtotal={greandtotal} laybyid={laybyid} />
            ) : (
              <CheckoutBlank />
            )}

          </div>
        </div>
      </div>
    </>
  )
}

export default ReceivePayment;
