import React, { useEffect, useState } from "react";
import { _Api, Api_form } from "../api/_call";
import { useTranslation } from 'react-i18next';

import { success, error } from "../common/helpers/toastify";
const ProductImportExportModel = (props) => {
  const { t } = useTranslation();
  const { master_product_checkbox_value, getProductExportList, setGetProductExportList } = props;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;

  const [importfile, setImportfile] = useState('');
  const [importmesssage, setImportMessage] = useState();
  const [exportmesssage, setExportMessage] = useState();
  const [checkedProduct, setProduct] = useState(false);
  const [checkedVariants, setVariants] = useState(false);
  const [checkedReorder, setReorder] = useState(false);
  const [checkedPricing, setPricing] = useState(false);
  const [checkedChoice, setChoice] = useState(false);
  const [productexportList, setProductexportList] = useState([]);
  const handleClickProduct = () => setProduct(!checkedProduct);
  const handleClickVariants = () => setVariants(!checkedVariants);
  const handleClickReorder = () => setReorder(!checkedReorder);
  const handleClickPricing = () => setPricing(!checkedPricing);
  const handleClickChoice = () => setChoice(!checkedChoice);



  const readExcel = async () => {
    try {

      const formData = new FormData();
      formData.append("file", importfile);
      formData.append("type", "ProductImport");
      let res = await Api_form(formData, `api/v1/App/productimport/uploadXls`);
      if (res.code === 200) {
        setImportMessage((res.message));
        success((res.message));
      } else {
        setImportMessage("Please Wait or try again later!!");
        error((res.message));
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const exportProduct = async () => {
    var checked_ids = master_product_checkbox_value;

    try {
      var checkedProduct_val = checkedProduct === true ? 1 : 0;
      var checkedVariants_val = checkedVariants === true ? 1 : 0;
      var checkedReorder_val = checkedReorder === true ? 1 : 0;
      var checkedPricing_val = checkedPricing === true ? 1 : 0;
      var checkedChoice_val = checkedChoice === true ? 1 : 0;
      const postData = {
        "type": "Productexport",
        "product": checkedProduct_val,
        "variants": checkedVariants_val,
        "reorder": checkedReorder_val,
        "pricing": checkedPricing_val,
        "key": checked_ids,
        "choice": checkedChoice_val
      }
      let res = await _Api(postData, `api/v1/App/productexport/createExport`);
      if (res.code === 200) {
        success((res.message));
        setExportMessage('Export request submited successfully');
      } else {
        setExportMessage("Please Wait or try again later!!");
        error((res.message));

      }
    } catch (err) {
      console.log(err, "err");
      error(err);

    }
  };
  const getExportList = async () => {

    try {

      var postdata = { "type": "Productexport" }
      let res = await _Api(postdata, `api/v1/App/productexport/getList`);
      if (res.code === 200) {
        setProductexportList(res.data);
        console.log(productexportList, "productexportlist")
        setGetProductExportList(false);
      } 
    } catch (err) {
      console.log(err, "err");
      setGetProductExportList(false);
    }
  };
  useEffect(() => {
    if(getProductExportList){
    getExportList();
    }
  // eslint-disable-next-line
  }, [getProductExportList]);

  const [file, setFile] = React.useState([]);
  const handleUpload = (event) => {
    setFile((file) => [...file, event.target.files[0]]);
  }


  return (
    <div
      className="modal fade import-export-modal"
      id="productImportExportModel_data"
      tabIndex={-1}
      aria-labelledby="productModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body import-export-modal-row">
            <div className="import-export-modal-row-box">
              <h4 className="import-export-modal-row-box-heading">{t("Product Import")}</h4>
              <div className="filter-modal-row-box-content">
                <div className="modal-content">
                  <div className="upload-msg">{importmesssage ? importmesssage : ""}</div>
                  <div className='import-modal-choose-file'>
                    {t("choose file")}
                    <input
                      type="file"
                      name="files"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setImportfile(file);
                        handleUpload(e);
                      }}
                      accept=".xlsx, .xls"

                    />
                  </div>
                  <label className="import-modal-choose-file-name">{file[0]?.name}</label>

                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={readExcel}
                      className="btn com-btn modal-content-yes"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="import-export-modal-row-box">
              <h4 className="import-export-modal-row-box-heading">{t("Product Export")}</h4>
              <div className="filter-modal-row-box-content">
                <div className="upload-msg">{exportmesssage ? t(exportmesssage) : ""}</div>
                <div className="checkbox-row">
                  <div className="checkbox checkbox-custom checkbox-row-single">

                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="Product"
                        onChange={() => handleClickProduct()}
                      />
                      <label htmlFor="Product" className="com-check-label"></label>
                    </div>
                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="Product"
                    >
                      {t("Product")}
                    </label>
                  </div>
                  <div className="checkbox checkbox-custom checkbox-row-single">
                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="Variants"
                        onChange={() => handleClickVariants()}
                      />
                      <label htmlFor="Variants" className="com-check-label"></label>
                    </div>
                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="Variants"
                    >
                      {t("Variants")}
                    </label>
                  </div>
                  <div className="checkbox checkbox-custom checkbox-row-single">
                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="Reorder"
                        onChange={() => handleClickReorder()}
                      />
                      <label htmlFor="Reorder" className="com-check-label"></label>
                    </div>
                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="Reorder"
                    >
                      {t("Reorder")}
                    </label>
                  </div>
                  <div className="checkbox checkbox-custom checkbox-row-single">
                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="Pricing"
                        onChange={() => handleClickPricing()}
                      />
                      <label htmlFor="Pricing" className="com-check-label"></label>
                    </div>
                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="Pricing"
                    >
                      {t("Pricing")}
                    </label>
                  </div>
                  <div className="checkbox checkbox-custom checkbox-row-single">
                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="Choice"
                        onChange={() => handleClickChoice()}
                      />
                      <label htmlFor="Choice" className="com-check-label"></label>
                    </div>

                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="Choice"
                    >
                      {t("Choice")}
                    </label>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => exportProduct()}
                    data-bs-dismiss="modal"
                    className="btn com-btn modal-content-yes"
                  >
                    {t("Export Request")}
                  </button>
                </div>
                <div className="dropdown product-edit-detail">
                  <button
                    className="btn btn-secondary dropdown-toggle product-edit-detail-btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={base_assets + "images/icons/note-board-icon-dark.png"} alt="" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="mb-2">{t("LIst Export Complete Your Data")}</li>
                    {productexportList.map((ele, index) => {
                      return (
                        <li className="dropdown-menu-detail-single" key={index}>
                          <a
                            className="dropdown-menu-detail-single-time"
                            href={ele.filepath} download=''
                          >
                            <span className="date">{ele.date}</span>
                          </a>
                          <span><i className="fa fa-user" aria-hidden="true"></i> {ele.name}</span>
                        </li>)
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImportExportModel;
