import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CalendarPage from '../admin/calendar/CalendarPage.js';
export default function CalendarRoutes() {
    return (
        <Routes>
            <Route path='/' element={<CalendarPage />} > </Route>
        </Routes>
    )
}
