import React from 'react'
import './ZoneModal.scss'
import CircledTick from 'admin/common/icons/sidebar/CircledTick'
import { useTranslation } from 'react-i18next'

export default function ZoneModal({setOpenModal, popupContent}) {
    const {t} = useTranslation()
  return (
    <div className='zonemodal' onClick={()=>setOpenModal(false)}>
        <div className='zonemodal-inner' onClick={(e)=>e.stopPropagation()}>
            {popupContent?.withicon &&
            <div className='zonemodal-inner-icon'>
                <CircledTick type='i'/>
            </div>
            }
            <div className='zonemodal-inner-content'>
                <div className='zonemodal-inner-content-header'>
                    {popupContent?.header ? popupContent?.header : 'Are you sure'}
                </div>
                <div className='zonemodal-inner-content-subheader'>
                    {popupContent?.subheader ? popupContent?.subheader : ''} <br/>
                    {popupContent?.subline2 ? popupContent?.subline2 : ''}
                </div>
                <div className='zonemodal-inner-content-bottom'>
                    <button className='cancel' onClick={()=> setOpenModal(false)}>{t("Cancel")}</button>
                    { popupContent?.deleteicon ? <button className='delete'>{t("Delete")}</button> : <button className='submit'>{t("Submit")}</button>}
                </div>
            </div>
        </div>
    </div>
  )
}
