import React from 'react'

export default function Messegeboxblue({width, height}) {
  return (
    <svg width={width ? width : "24"} height={height ? height : "22"} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.3335 6.58304L7.95016 9.29551C8.5724 9.76217 9.42792 9.76217 10.0502 9.29551L13.6668 6.58301" stroke="#419AF8" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.0833 5.41699H4.91667C4.27233 5.41699 3.75 5.93933 3.75 6.58366V12.417C3.75 13.0613 4.27233 13.5837 4.91667 13.5837H13.0833C13.7277 13.5837 14.25 13.0613 14.25 12.417V6.58366C14.25 5.93933 13.7277 5.41699 13.0833 5.41699Z" stroke="#419AF8" stroke-linecap="round" />
    </svg>

  )
}
