import React, { useRef, useState, useEffect, useCallback } from 'react'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const SpecifiedOccasion = (props) => {
  const dataFetched = useRef();
  const { setDashboardhideshow, setDefaultset } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const { t } = useTranslation();
  const [occasiondata, setOccasiondata] = useState()
  const occasion = useCallback(async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/dashboard/occasionStatics`)
      if (res?.code === 200) {
        setOccasiondata(res?.statics)
        dataFetched.current = false;
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, []);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    occasion()
  }, [occasion])
  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Specified Occasion")}
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li
                onClick={() => {
                  setDashboardhideshow(prevState => ({
                    ...prevState,
                    SpecifiedOccasion: 0
                  }));
                  setDefaultset(true)
                }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area dashboard-area-cards-single-chart-area-occasion py-3">
          <div className="d-flex align-items-center my-4">
            <img
              className="me-2"
              src={base_assets + 'images/icons/Anniversary.png'}
              alt=""
            />
            <span className="me-auto">{t("Anniversary")}</span>
            <span>{occasiondata?.Anniversary ? parseFloat(occasiondata?.Anniversary).toFixed(2) : 0}%</span>
          </div>
          <div className="d-flex align-items-center my-4">
            <img className="me-2" src={base_assets + 'images/icons/gift-svg.svg'} alt="" />
            <span className="me-auto">{t("Gift")}</span>
            <span>{occasiondata?.Gift ? parseFloat(occasiondata?.Gift).toFixed(2) : 0}%</span>
          </div>
          <div className="d-flex align-items-center my-4">
            <img
              className="me-2"
              src={base_assets + 'images/icons/personal-svg.svg'}
              alt=""
            />
            <span className="me-auto">{t("Personal")}</span>
            <span>{occasiondata?.Personal ? parseFloat(occasiondata?.Personal).toFixed(2) : 0}%</span>
          </div>
          <div className="d-flex align-items-center my-4">
            <img
              className="me-2"
              src={base_assets + 'images/icons/prize.png'}
              alt=""
            />
            <span className="me-auto">{t("Prize")}</span>
            <span>{occasiondata?.Prize ? parseFloat(occasiondata?.Prize).toFixed(2) : 0}%</span>
          </div>
          <div className="d-flex align-items-center my-4">
            <img
              className="me-2"
              src={base_assets + 'images/icons/wedding.png'}
              alt=""
            />
            <span className="me-auto">{t("Wedding")}</span>
            <span>{occasiondata?.Wedding ? parseFloat(occasiondata?.Wedding).toFixed(2) : 0}%</span>
          </div>
          <div className="d-flex align-items-center my-4">
            <img
              className="me-2"
              src={base_assets + 'images/icons/other-pink.png'}
              alt=""
            />
            <span className="me-auto">{t("Other")}</span>
            <span>{occasiondata?.Other ? parseFloat(occasiondata?.Other).toFixed(2) : 0}%</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SpecifiedOccasion
