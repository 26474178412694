import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Report.scss'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import { Reportmenupos } from './Reportmenupos'
import SocialModal from '../../components/common/modules/SocialModal/SocialModal'
import TableModal from '../common/Modals/tableColumnPos'
import { Tablehead } from '../../components/common/modules/Tablehead'
import { pospaymentreport_head } from '../../components/common/Tablehead'
import { useTranslation } from 'react-i18next'
import { error, loading, Updateloading } from '../common/helpers/toastify'
import { _Api } from '../common/api/_call'
import { Pagination } from '../common/Pagination'
import * as FileSaver from 'file-saver'
import { useSelector } from 'react-redux'
import Select from "react-select";
import * as XLSX from 'xlsx'
const Paymentreport = () => {
  const { t } = useTranslation()
  const [newcolumndata, setnewcolumndata] = useState(pospaymentreport_head)
  const [socialurl, setSocialurl] = useState()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [startdate, setStartdate] = useState()
  const [enddate, setEnddate] = useState()
  const [datelable, setDatelable] = useState('ALL')
  const [shareloader, setShareloader] = useState(false)
  const [paymentdata, setPaymentdata] = useState([])
  const [upercount, setUpercount] = useState()
  const [lowercount, setLowercount] = useState()
  const [limit, setLimit] = useState(100)
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const [printparmission, setPrintparmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.pos_reports?.View)
      setPrintparmission(parmissiondatashow?.pos_reports?.Print);
  }, [parmissiondatashow]);
  const getsharepaymentreport = async (firstlimit) => {
    setShareloader(true)
    try {
      const postdata = {
        limit: firstlimit ? firstlimit : limit,
        start_date: startdate ? startdate : "",
        end_date: enddate ? enddate : "",
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/payment`)
      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      } else {
        error('data not fuond')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const getprintpaymentrreport = async (firstlimit) => {
    let GetloadingID = loading()
    try {
      const postdata = {
        limit: firstlimit ? firstlimit : limit,
        start_date: startdate ? startdate : new Date(),
        end_date: enddate ? enddate : new Date(),
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/payment`)

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, t('data not found'))
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'report_paymentreport_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const getlimit = (limit) => {
    setLimit(limit)
    getpaymentreport(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })

  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    getpaymentreport(current, '')
  }
  const getpaymentreport = async (current, firstlimit) => {
    UnSetdataTable('payment_report')
    try {
      const postdata = {
        search: '',
        start_date: startdate ? startdate : "",
        end_date: enddate ? enddate : "",
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
      }
      let listdata = await _Api(postdata, `api/v1/POS/report/payment`)
      if (listdata.code === 200) {
        setPaymentdata(listdata?.data)
        setTimeout(() => {
          SetdataTable('payment_report', 0, newcolumndata, [0])
        }, 50)
        setstate({
          ...state,
          totalPages: Math.ceil(listdata?.count / postdata.limit),
          currentPage: current ? current : 1,
        })
        setUpercount(listdata?.uppercount)
        setLowercount(listdata?.lowercount)
      } else {
        SetdataTable('payment_report', 0, newcolumndata, [0])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (startdate && enddate) {
      getpaymentreport()
    } else {
      getpaymentreport()
    }
    // eslint-disable-next-line
  }, [startdate, enddate])
  const changeDates = (lable) => {
    const MainDate = new Date()
    if (lable === 'Today') {
      setStartdate(new Date())
      setEnddate(new Date())
      setDatelable('Today')
    } else if (lable === 'Yesterday') {
      const yesterdaydate = new Date(MainDate.setDate(MainDate.getDate() - 1))
      setStartdate(yesterdaydate)
      setEnddate(yesterdaydate)
      setDatelable('Yesterday')
    } else if (lable === 'Last 7 days') {
      const sevendays = new Date(MainDate.setDate(MainDate.getDate() - 7))
      setStartdate(sevendays)
      setEnddate(new Date())
      setDatelable('Last 7 days')
    } else if (lable === 'This Week') {
      const days = new Date(
        MainDate.setDate(MainDate.getDate() - MainDate.getDay()),
      )
      setStartdate(days)
      setEnddate(new Date())
      setDatelable('This Week')
    } else if (lable === 'Last Week') {
      const firstDay = new Date(
        MainDate.setDate(MainDate.getDate() - (MainDate.getDay() + 7)),
      )
      const lastDay = new Date(
        MainDate.setDate(MainDate.getDate() - MainDate.getDay() + 7),
      )
      setStartdate(firstDay)
      setEnddate(lastDay)
      setDatelable('Last Week')
    } else if (lable === 'Last 30 days') {
      const days = new Date(MainDate.setDate(MainDate.getDate() - 30))
      setStartdate(days)
      setEnddate(new Date())
      setDatelable('Last 30 days')
    } else if (lable === 'This Month') {
      const firstDay = new Date(
        MainDate.getFullYear(),
        MainDate.getMonth(),
        1 + 1,
      )
      setStartdate(firstDay)
      setEnddate(new Date())
      setDatelable('This Month')
    } else if (lable === 'Last Month') {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth() - 1)
      const lastDay = new Date(MainDate.getFullYear(), MainDate.getMonth())
      setStartdate(firstDay)
      setEnddate(lastDay)
      setDatelable('Last Month')
    } else if (lable === 'Year to Date') {
      const firstDay = new Date(MainDate.getFullYear(), 0, 1 + 1)
      setStartdate(firstDay)
      setEnddate(new Date())
      setDatelable('Year to Date')
    } else if (lable === "ALL") {
      setStartdate("")
      setEnddate("")
    }
  }
  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    pospaymentreport_head.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (paymentdata?.length) {
      paymentdata.forEach((result, key) => {
        bodyarray.push([
          key + 1,
          result?.name,
          result?.type,
          result?.location,
          result?.formatted_amount,
          result?.formatted_refund,
          result?.formatted_net,
        ])
      })
    }
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'payment_report')
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 w-100">
            <div className="catalog-area bg-white page-menu-block Dailysalereport">
              <div className="Dailysalereport-header">
                <div className="Dailysalereport-header-left">
                  <h3 className="common-navigation-header-left-heading Dailysalereport-head">
                    {' '}
                    <Link
                      to=""
                      className="gray-mid text-decoration-none Dailysalereport-head-report"
                    >
                      {t('REPORT')}
                    </Link>{' '}
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'}
                      alt=""
                    />
                    <span className="Dailysalereport-head-pagename">
                      {t('Payment Report')}
                    </span>{' '}
                  </h3>
                </div>
                <div className="Dailysalereport-header-reportdetail">
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report/value-img.png'}
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('Revenue')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data maingreen">
                        {upercount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Dailysalereport-content-area">
                <div className="Dailysalereport-content-area-header">
                  <div className="Dailysalereport-content-area-header-inner">
                    <Reportmenupos
                      Reportname={"Payment Report"}
                    />
                    <Select
                      className="limit-select short mx-3"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                    />
                    <div className="dropdown ">
                      <button
                        className="dropdown-toggle Dailysalereport-content-area-header-inner-dropdown"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {datelable}
                      </button>
                      <ul
                        className="dropdown-menu Dailysalereport-content-area-header-inner-dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          className={`dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Today' || datelable === t('ALL')
                            ? ' '
                            : ''
                            }`}
                          onClick={() => changeDates('ALL')}
                        >
                          {t('ALL')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Today'
                            }`}
                          onClick={() => changeDates('Today')}
                        >
                          {t('Today')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Yesterday'
                            }`}
                          onClick={() => changeDates('Yesterday')}
                        >
                          {t('Yesterday')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Last 7 days'
                            }`}
                          onClick={() => changeDates('Last 7 days')}
                        >
                          {t('Last 7 days')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'This Week'
                            }`}
                          onClick={() => changeDates('This Week')}
                        >
                          {t('This Week')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Last Week'
                            }`}
                          onClick={() => changeDates('Last Week')}
                        >
                          {t('Last Week')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Last 30 days'
                            }`}
                          onClick={() => changeDates('Last 30 days')}
                        >
                          {t('Last 30 days')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'This Month'
                            }`}
                          onClick={() => changeDates('This Month')}
                        >
                          {t('This Month')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Last Month'
                            }`}
                          onClick={() => changeDates('Last Month')}
                        >
                          {t('Last Month')}
                        </li>
                        <li
                          className={`dropdown-item dropdown-item Dailysalereport-content-area-header-inner-dropdown-menu-item ${datelable === 'Year to Date'
                            }`}
                          onClick={() => changeDates('Year to Date')}
                        >
                          {t('Year to Date')}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                      <button
                        className="btn-style"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            base_assets + 'images/icons/ellipsis-circular.png'
                          }
                          alt=""
                        />
                      </button>
                      <ul
                        className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          onClick={() => getprintpaymentrreport()}
                          className={printparmission ? '' : 'd-none'}
                        >
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/print-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Print')}
                          </Link>
                        </li>
                        <li onClick={() => downloadexclsheet()}>
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/export-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Export')}
                          </Link>
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#socialModal"
                          className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                          onClick={() => getsharepaymentreport()}
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={
                                base_assets + 'images/pos/report/share-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          {t('Share')}
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#columnModal"
                        >
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets + 'images/icons/column-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Column')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="Dailysalereport-content-area-main">
                  <div
                    className="main-body-main-table-wrap paymentreport-table"
                    id="table-scroll"
                  >
                    <table
                      id="payment_report"
                      className="common-table first-row-gray w-100 paymentreport-table-main"
                    >
                      <Tablehead
                        tablehead={pospaymentreport_head}
                        tblname={'report_paymentreport_tbl'}
                      />
                      <tbody>

                        {paymentdata?.length
                          ? paymentdata.map((result, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>
                                  <Link
                                    className="paymentreport-table-main-name"
                                    to="/app/pos/Paymentreportdetails"
                                    state={{
                                      name: result?.name,
                                      id: result?.id,
                                      type: result?.type,
                                    }}
                                  >
                                    {result?.name ? result?.name : '-'}
                                  </Link>
                                </td>
                                <td>{result?.type ? result?.type : '-'}</td>
                                <td>
                                  {result?.location
                                    ? result?.location
                                    : '-'}
                                </td>
                                <td className="Dailysalereport-table-main-innerdata">
                                  {result?.formatted_amount
                                    ? result?.formatted_amount
                                    : '-'}
                                </td>
                                <td className="Dailysalereport-table-main-innerdata">
                                  {result?.formatted_refund
                                    ? result?.formatted_refund
                                    : '-'}
                                </td>
                                <td className="Dailysalereport-table-main-innerdata">
                                  {result?.formatted_net
                                    ? result?.formatted_net
                                    : '-'}
                                </td>
                              </tr>
                            )
                          })
                          : ''}

                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{lowercount?.collect}</td>
                          <td>{lowercount?.refund}</td>
                          <td>{lowercount?.net}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                {paymentdata.length > 0 ? (
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePaginations(crPage)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <TableModal
          tablehead={pospaymentreport_head}
          tblName={'report_paymentreport_tbl'}
          setnewcolumndata={setnewcolumndata}
        />
      </div>
    </>
  )
}
export default Paymentreport;