import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import FilterIconMain from '../../admin/common/icons/sidebar/FilterIconMain';
import Printer from '../../admin/common/icons/sidebar/Printer';
import CallCalendar from './CallCalendar';
import List from './CalendarList/Index';
import KanbanPage from './Kanban/Index';

export default function CalendarPage() {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [activeTab, setActiveTab] = useState('Calendar');
  const handleShowFilter = () => {
    setShowFilter(!showFilter)
  }
  const closeCommonFilter = () =>{
    setShowFilter(false);
  }

  return (
    <div className='calendarpage main-content-wrapper-body'>
      <h1 className='main-body-heading'>{t("Calendar")}</h1>
      <div className='main-body-top-tab-bar'>
        <div
          className="main-body-top-tab-bar-left-col nav nav-pills"
          role="tablist"
        >

          <Link className={`main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh disable  ${activeTab === 'List' ? "active" : "disable"}`} onClick={() => setActiveTab("List")
          }
          >
            <span className="main-body-top-tab-bar-left-col-a-text">
              {t("List")}
            </span>
          </Link>
          <Link to=""
            className={`main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh  ${activeTab === 'Calendar' ? "active" : "disable"}`} onClick={() => setActiveTab("Calendar")
            }
          >
            <span className="main-body-top-tab-bar-left-col-a-text">
              {t("Calendar")}
            </span>
          </Link>
          <Link to=""
            className={`main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh disable   ${activeTab === 'Kanban' ? "active" : "disable"}`} onClick={() => setActiveTab("Kanban")
            }>
            <span className="main-body-top-tab-bar-left-col-a-text">
              {t("Kanban")}
            </span>
          </Link>
        </div>
        <div className='main-body-top-tab-bar-right-col'>
          <div className='main-body-top-tab-bar-right-col-filtericon mx-1 cursor-pointer' onClick={handleShowFilter}>
            <FilterIconMain width={24} height={24} />
            {filterCount > 0 ? <div className='main-body-top-tab-bar-right-col-filtericon-count'>{filterCount}</div> : ""}
          </div>
          <div className='mx-1 cursor-pointer'>
            <Printer />
          </div>
        </div>
      </div>
      {activeTab === 'Calendar' ?
        <div className='main-body-wrpper d-flex w-100 position-relative'>
          <CallCalendar showFilter={showFilter} setShowFilter={setShowFilter} handleShowFilter={handleShowFilter} setFilterCount={setFilterCount} />
        </div>
        :
        activeTab === 'List' ?
          <div>
            <List showFilter={showFilter} setShowFilter={setShowFilter} handleShowFilter={handleShowFilter} setFilterCount={setFilterCount} />
          </div>
          :
          <div>
            <KanbanPage closeCommonFilter={closeCommonFilter} showFilter={showFilter} setShowFilter={setShowFilter} handleShowFilter={handleShowFilter} setFilterCount={setFilterCount} />
          </div>
      }
    </div>
  )
}
