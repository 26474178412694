import React from 'react'

export default function SaveButtonIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V7.35703C17.5 6.91499 17.3244 6.49107 17.0118 6.17851L13.8215 2.98816C13.5089 2.67559 13.085 2.5 12.643 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.83337 2.5V6.66667H12.5V2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.83337 17.5V12.5H14.1667V17.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
