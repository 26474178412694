import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
const NotesModal = (props) => {
  const { t } = useTranslation();
  const { setForm_note, form_note } = props;
  let handleChange = (e) => {
    setForm_note(e.target.value);
  };
  useEffect(() => { }, []);
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;

  return (
    <div
      className="modal notes-modal"
      id="notesModal"
      tabIndex={-1}
      aria-labelledby="notesModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="notesModalLabel">
              <img
                src={base_url + "images/icons/comment-icon-green.png"}
                alt=""
              />
              {t("notes")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <textarea
              onChange={(e) => handleChange(e)}
              className="notes-modal-text"
              placeholder="Type your note here."
              defaultValue={form_note ? form_note : ''}
            />
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default NotesModal;
