import React, { useRef, useState, useEffect, useCallback } from 'react';
import { _Api } from '../api/_call';
import TableColumnModal from '../common/modules/tableColumn';
import ConfirmationModel from '../common/modules/confirmationmodel';
import { Tablehead } from '../common/modules/Tablehead';
import DateTime from '../common/DateTime';
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import { inventorySummarytableHead } from '../common/Tablehead';
import 'datatables.net-fixedcolumns';
import SocialModal from '../common/modules/SocialModal/SocialModal';
import ProductModal from '../common/modules/ProductModal';
import ProductImageSliderModal from '../common/modules/productImageslider';
import Select from "react-select";
import {
  loading,
  Updateloading,
} from '../common/helpers/toastify';
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { useSelector } from 'react-redux';
const InventorySummary = (props) => {
  const { t } = useTranslation()
  const dataFetched = useRef();
  const [summarylist, setSummarylist] = useState()
  const [limit, setLimit] = useState(100)
  const [totalcount, setTotalcount] = useState()
  const [imageid, setImageid] = useState()
  const [getoneproduct, setOneproduct] = useState()
  const [newcolumndata, setnewcolumndata] = useState(inventorySummarytableHead)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [socialurl, setSocialurl] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const GetSummary = useCallback(async (firstlimit, search) => {
    setDatsLodaer(true)
    UnSetdataTable('tbl_my_inventorySummary')
    try {
      const postdata = {
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        skip: 0,
      }
      let res = await _Api(postdata, `api/v1/Inventory/my/getSummery`)
      if (res?.code === 200) {
        setDatsLodaer(false)
        SetdataTable('tbl_my_inventorySummary', '5', newcolumndata, [0])
        setSummarylist(res?.data)
        setTotalcount(res?.count)
      } else {
        SetdataTable('tbl_my_inventorySummary', '5', newcolumndata, [0])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [newcolumndata, limit,]);

  useEffect(() => {
    callcolumns()
  }, [])

  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_my_inventorySummary',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    GetSummary()
  }, [GetSummary])
  const getsharesummary = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: "",
      }
      let res = await _Api(postdata, `api/v1/Inventory/inventory/print/summery`)

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getprintsummary = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: "",
      }
      let res = await _Api(postdata, `api/v1/Inventory/inventory/print/summery`)

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    inventorySummarytableHead.map(async (r, k) => {
      header_values.push(r?.lable)
    })

    if (summarylist.length) {
      summarylist.forEach((result, key) => {

        bodyarray.push([key + 1, result?.main_image, result?.SKU, result?.item_code, result?.item_name, result?.collection_name, result?.metal_name, result?.stone_name, result?.location_name, result?.totalStock, result?.reserveStock, result?.availableStock
        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "my_inventorySummary");

  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading">{t('Summary')}</h1>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/free-stock.png'}
                        className="inventory-img"
                        alt="Stock"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Stock')}</p>
                      <h2 className="inventory-value stock">
                        {totalcount?.totalStock ? totalcount?.totalStock : 0}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/reserved-icon.png'}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Reserved')}</p>
                      <h2 className="inventory-value sku">
                        {totalcount?.reserveStock
                          ? totalcount?.reserveStock
                          : 0}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/available-icon.png'}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Available')}</p>
                      <h2 className="inventory-value value">
                        {totalcount?.availableStock
                          ? totalcount?.availableStock
                          : 0}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/location.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('Locations')}</p>
                      <h2 className="inventory-value location">
                        {totalcount?.location ? totalcount?.location : 0}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); GetSummary(e.value) }}
                />
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className={parmissiondatashow?.Summary?.Print ? "dropdown-item print-dropdown-item" : "d-none"}
                        onClick={(e) => {
                          getprintsummary(e.target.value)
                        }}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li className="dropdown-item" onClick={(e) => {
                        downloadexclsheet(e.target.value)
                      }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#socialModal"
                        onClick={() => getsharesummary()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/share-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('share')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  id="tbl_my_inventorySummary"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >

                  <Tablehead
                    tablehead={inventorySummarytableHead}
                    tblName={'tbl_my_inventorySummary'}
                  />
                  <thead></thead>

                  <tbody>
                    <>
                      {summarylist
                        ? summarylist.map((result, key) => {
                          return (
                            <tr key={key} className="">
                              <td className="fixed-side">{key + 1}</td>
                              <td
                                className="table-pro-img"
                                onClick={() => setImageid(result?.product_id)}
                                data-bs-toggle="modal"
                                data-bs-target="#ProductViewModal"
                              >
                                <img
                                  src={
                                    result?.main_image
                                      ? result?.main_image
                                      : base_assets +
                                      'images/icon/camera_profile.png'
                                  }
                                  alt=""
                                />
                              </td>
                              <td className="sku-col link-color">
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setOneproduct(result)
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#ProductInfoModal"
                                >

                                  {result?.SKU ? result?.SKU : ''}
                                </span>
                              </td>
                              <td>
                                <div className="tooltip-area-up">
                                  {result?.item_code ? result?.item_code : ''}
                                  <span className="tooltip-area-up-text">
                                    {result?.item_name ? result?.item_name : ''}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="tooltip-area-up">
                                  {result?.collection_code
                                    ? result?.collection_code
                                    : ''}
                                  <span className="tooltip-area-up-text">
                                    {result?.collection_name
                                      ? result?.collection_name
                                      : ''}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="tooltip-area-up">
                                  {result?.metal_code ? result?.metal_code : ''}
                                  <span className="tooltip-area-up-text">
                                    {result?.metal_name
                                      ? result?.metal_name
                                      : ''}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="tooltip-area-up">
                                  {result?.stone_code ? result?.stone_code : ''}
                                  <span className="tooltip-area-up-text">
                                    {result?.stone_name
                                      ? result?.stone_name
                                      : ''}
                                  </span>
                                </div>
                              </td>
                              <td>
                                {result?.sizename ? result?.sizename : ''}
                              </td>
                              <td className="link-color">
                                {result?.location_name
                                  ? result?.location_name
                                  : ''}
                              </td>
                              <td className="light-blue-text">
                                {result?.totalStock ? result?.totalStock : '0'}
                              </td>
                              <td className="yellow-text">

                                {result?.reserveStock
                                  ? result?.reserveStock
                                  : '0'}
                              </td>
                              <td className="main-green-text">
                                {result?.availableStock
                                  ? result?.availableStock
                                  : '0'}
                              </td>
                            </tr>
                          )
                        })
                        : ''}
                    </>
                  </tbody>
                </table>
                <div id="table_loader" className={datalodaer ? "mt-4" : "d-none"}>
                  <div className="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <ProductModal
          data_product={getoneproduct}
          setOneproduct={setOneproduct}
          editproduct_submit={''}
        />
        <ProductImageSliderModal main_id={imageid}></ProductImageSliderModal>
      </div>
      <TableColumnModal
        tablehead={inventorySummarytableHead}
        tblName={'tbl_my_inventorySummary'}
        calldatatable={GetSummary}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <ConfirmationModel></ConfirmationModel>
    </React.Fragment>
  )
}
export default InventorySummary;
