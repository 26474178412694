import React from 'react'

const CartIcon = (props) => {
    const { width, height } = props;
    return (
            
    <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 4.216V1.906C5.00013 1.53306 5.14838 1.17544 5.41213 0.911777C5.67589 0.648116 6.03356 0.5 6.4065 0.5H18.5935C18.9665 0.5 19.3243 0.648184 19.588 0.911954C19.8518 1.17572 20 1.53347 20 1.9065V14.094C19.9999 14.4669 19.8517 14.8244 19.588 15.088C19.3244 15.3517 18.9669 15.4999 18.594 15.5H16.258" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.594 4.5H2.4055C2.03265 4.50027 1.67515 4.64857 1.4116 4.91231C1.14805 5.17605 1 5.53365 1 5.9065V18.0935C1 18.4665 1.14818 18.8243 1.41195 19.088C1.67572 19.3518 2.03347 19.5 2.4065 19.5H14.594C14.9669 19.4999 15.3244 19.3517 15.588 19.088C15.8517 18.8244 15.9999 18.4669 16 18.094V5.9055C15.9997 5.53273 15.8515 5.17532 15.5879 4.91178C15.3242 4.64824 14.9668 4.50013 14.594 4.5Z" stroke="black" stroke-linejoin="round"/>
    </svg>


    )
}

export default CartIcon