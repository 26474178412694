import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import { _Api } from "../../../common/api/_call"
import StoreModal from "../StoreModal"
const Cashregistermodal = props => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    let navigate = useNavigate()
    const { t } = useTranslation();
    const [amount, setAmount] = useState()
    const [openstore, setOpenstore] = useState(false)
    const [closeRegister, setCloseRegister] = useState(false);
    const posloc = JSON.parse(localStorage.getItem("pos-loc"));

    const createcashregister = async () => {
        try {
            let posdata = {
                "float_Amount": parseInt(amount) ? parseInt(amount) : 0,
                "location-id": posloc?.loc ? posloc?.loc : ""
            }
            const res = await _Api(posdata, `api/v1/POS/cashRegister/createCashRagister`)
            if (res.code === 200) {
                navigate('/app/pos/menu')
            }
        }
        catch (err) {
            console.log(err, 'err')
        }
    }
    return (

        <>
            <div
                className={closeRegister || openstore ? "cashregister-modal d-none" : "cashregister-modal"}
                id="cashregistermodal"
            >
                <div className="modal-dialog cashregister-modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <Link to="/app/pos" className="cashregister-modal-close btn-style" onClick={() => setOpenstore(true)}>
                            <img src={base_assets + "/images/pos/close-btn.png"} alt="" />
                        </Link>
                        <div className="modal-header cashregister-modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                {t("Open Register")}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="cashregister-modal-location-area">
                                <div className="cashregister-modal-location-area-heading">
                                    <span className="heading">{t("Store")} :</span>
                                    <span>{posloc?.voc ? posloc?.voc : ''}</span>
                                </div>
                                <div className="cashregister-modal-location-area-flag">
                                    <img src={base_assets + "images/flags/" + posloc?.curr.toLowerCase() + ".png"} alt=""></img>
                                </div>
                            </div>
                            <div className="cashregister-modal-body mx-3 mt-5">
                                <div className="cashregister-modal-body-inner">
                                    <span>{t("Opening float")}</span>
                                    <span><b>{posloc?.curr ? posloc?.curr : ''}</b></span>
                                </div>
                                <div className='placeholder-up-input cashregister-modal-body-input'>

                                    <input type="number" className='placeholder-up-input-field cashregister-modal-body-input-area arrow-none'

                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <Link to="/app/pos/menu" className="text-decoration-none">
                            <div className="modal-footer footer" onClick={createcashregister}>
                                <button type="button" className="com-btn" onClick={() => setCloseRegister(true)}>
                                    {t("Open Register")}
                                </button>

                            </div>
                        </Link>
                    </div>
                </div>
            </div >
            {openstore ? <StoreModal /> : ""}
        </>
    )
}
export default Cashregistermodal;