import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Reports = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  let encode = token;
  const iframeRef = useRef(null);
  useEffect(() => {
    const fromBinary = (encoded) => {
      try {
        const binary = atob(encoded);
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < bytes.length; i++) {
          bytes[i] = binary.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint16Array(bytes.buffer));
      } catch (error) {
        console.error('Error decoding:', error);
        navigate('/unauthorized');
        return null;
      }
    };

    const decodeURL = fromBinary(encode);

    if (!decodeURL) {
      return;
    }

    const url = `https://docs.google.com/gview?embedded=true&url=${decodeURL}`;

    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.type = 'application/pdf';

    if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1) {
      iframe.src = url;
    } else {
      iframe.src = decodeURL;
    }

    const currentIframeRef = iframeRef?.current;

    if (currentIframeRef) {
      currentIframeRef.appendChild(iframe);
    }

    return () => {
      if (currentIframeRef && currentIframeRef.contains(iframe)) {
        currentIframeRef.removeChild(iframe);
      }
    };
  }, [encode, navigate]);

  return (
    <>
      <style>
        {`
          html, body {
            margin: 0;
            padding: 0;
            border: 0;
            width: 100%;
            height: 100%;
          }

          iframe {
            width: 100%;
            height: 100vh !important;
            margin: 0;
            padding: 0;
            background: #fff;
            border: none;
            display: inline-block;
          }
        `}
      </style>
      <div ref={iframeRef} />
    </>
  );
};

export default Reports;
