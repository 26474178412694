
import React from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { useTranslation } from 'react-i18next';
export default function FileExport(props) {
  const header = ["Firstname", "Lastname", "Age"];
  const { t } = useTranslation();
  const body = [
    ["Edison", "Padilla", 14],
    ["Cheila", "Rodriguez", 56]
  ];

  /**
   * @description:
   *  also accepts an array of objects; the method (downloadExcel) will take
   *  as order of each column, the order that each property of the object brings with it.
   *  the method(downloadExcel) will only take the value of each property.
   */
  const body2 = [
    { firstname: "Edison", lastname: "Padilla", age: 14 },
    { firstname: "Cheila", lastname: "Rodriguez", age: 56 }
  ];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "tanvitest",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        body: body || body2
      }
    });
  }

  return (
    <span onClick={handleDownloadExcel}>{t("export")}</span>
  );
}



