import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { _Api } from "../common/api/_call";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './customer.scss';
import { error } from "../common/helpers/toastify";
import CheckoutBlank from "../checkout/checkoutBlank";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import TableColumnModal from "../../components/common/modules/tableColumn";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { poscustomerpurchasehistory_head } from "../../components/common/Tablehead";
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function';
import { Customsubtable } from "../Custom/subtable/Customsubtable";
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import TableLoader from '../../admin/common/TableLoader';
import { store } from '../../source/index'
const PurchaseHistory = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const { t } = useTranslation();
  const [TransactionDate, setTransactionDate] = useState();
  const [newcolumndata, setnewcolumndata] = useState(poscustomerpurchasehistory_head);
  const [excuteTable, setexcuteTable] = useState(false);
  const [orderlist, setOrderlist] = useState();
  const [showloader, setShowloader] = useState(false)
  const itemdaata = localStorage.getItem("customerdetails")
  const parsedObject = JSON.parse(itemdaata);
  const selectTransactionDate = (input) => {
    setTransactionDate(input);
  };
  useEffect(() => {
    callcolumns();
  }, []);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_pos_customerpurchasehistory",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const customerspurchasehistory = async () => {
    setShowloader(true)
    if (!parsedObject?.id) {
      SetdataTable('purchasecustomer_table', '4', newcolumndata, [0]);
      error(t("Customer is Requried!!"));
      return;
    }
    setexcuteTable(false);
    var expand_col = document.querySelector(".table_btn_expand_col");
    if (expand_col) { expand_col.classList.remove("rotate-90"); }

    try {
      setTimeout(async function () {
        UnSetdataTable('purchasecustomer_table');
        let time = TransactionDate ? new Date(TransactionDate) : null;
        let formattedDate = time ? `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}` : null;
        const postdata = {
          'customer_id': parsedObject?.id,
          // 'date': TransactionDate,
          'type': "PURCHASE",
          "date": formattedDate
        };
        let res = await _Api(postdata, `api/v1/POS/customer/purcahseHistory`);
        if (res.code === 200) {
          SetdataTable('purchasecustomer_table', '4', newcolumndata, [0]);
          setOrderlist(res?.data)
          setexcuteTable(true);
          setShowloader(false)
        } else {
          SetdataTable('purchasecustomer_table', '4', newcolumndata, [0]);
        }
      }, 100)
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const setSubTablecustom = async (order_id) => {
    var alllist_data = [...orderlist];
    var e = document.querySelector("#table-btn_" + order_id);
    var row = document.querySelector('#common-main-table-wrap-' + order_id);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      alllist_data = [...orderlist];
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = null
      })
      setOrderlist(alllist_data);
    } else {
      classList_.add("rotate-90");
      alllist_data = [...orderlist];
      const postdata = {
        "Order_id": order_id
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/customOrder/getSkuByOrderList`);
      var html_ = Customsubtable(prioritydata, order_id, t);
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = html_
      })
      setOrderlist(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + order_id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  }

  useEffect(() => {
    customerspurchasehistory();
    // eslint-disable-next-line
  }, [parsedObject?.id, TransactionDate]);

  return (
    <>

      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="customer">
              <div className='customer-inner'>
                <div className="customer-inner-tophead">
                  <div className=" transactionhistory-heading d-flex gap-3 align-items-center">
                    <Link to="/app/pos/customer/customer" >
                      <img className="transactionhistory-heading-backbtn" alt='' src={base_assets + "images/pos/backbtn.png"}></img></Link>
                    <h1 className="main-heading">Purchase History</h1>
                  </div>
                </div>
                <div className="customer-inner-data">
                  <div className='customer-inner-data-payment-table-top-bar'>
                    <div className='customer-inner-data-payment-table-top-bar-left'>
                      <div className='customer-inner-data-payment-table-top-bar-left-multi-select'>
                      </div>
                    </div>
                    <div className='customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date'>
                      <DatePicker
                        className="placeholder-up-input-field"
                        selected={TransactionDate}
                        onChange={selectTransactionDate}
                        name="TransactionDate"
                        dateFormat={OrgSettings?.date_picker}
                        placeholderText='MM/dd/yyyy'
                        autoComplete='none'
                      />
                      <img
                        className="placeholder-up-input-field-calender"
                        src={base_assets + "images/icons/calendar-green.svg"}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="main-body-main-table-wrap vertical-scroll-table position-relative">
                    <table id="purchasecustomer_table" className="common-table first-row-gray w-100">
                      <Tablehead tablehead={poscustomerpurchasehistory_head} tblName={"tbl_pos_customerpurchasehistory"} setfilterby={""} />
                      <tbody>
                        <>
                          {orderlist?.length ? orderlist?.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <tr id={`common-main-table-wrap-${result?.order_id}`} key={result?.order_id}>
                                  <td>{key + 1}</td>
                                  <td>{result?.date}</td>
                                  <td>{result?.order_type}</td>
                                  <td>{result?.order_no}</td>
                                  <td className='sku-col table_btn_expand_col'
                                    onClick={() => setSubTablecustom(result?.order_id)}
                                    id={"table-btn_" + result?.order_id}>  <img
                                      className="sku-col-arrow cursor-pointer"
                                      src={
                                        base_assets +
                                        "/images/icons/right_green_button.png"
                                      }
                                      alt=""
                                    />
                                    {result?.SKU}</td>
                                  <td>{result?.location}</td>
                                  <td>{result?.total_Qty}</td>
                                  <td>{toFormatPrice(result?.total_amount, { addSymbol: true })}</td>
                                </tr>
                                {
                                  excuteTable ? (
                                    <>
                                      {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                    </>
                                  ) : null
                                }
                              </React.Fragment>
                            );
                          }) : ""}
                        </>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{
                            orderlist
                              ? orderlist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.total_Qty)),
                                0
                              )
                              : ""
                          }</td>
                          <td>{toFormatPrice(
                            orderlist
                              ? orderlist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.total_amount)),
                                0
                              )
                              : ""
                            , { addSymbol: true })}</td>
                        </tr>

                      </tfoot>
                    </table>
                    {showloader && <TableLoader />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>


      <TableColumnModal tablehead={poscustomerpurchasehistory_head} tblName={"tbl_pos_customerpurchasehistory"} setnewcolumndata={setnewcolumndata} calldatatable={customerspurchasehistory}></TableColumnModal>

    </>
  )
}

export default PurchaseHistory;