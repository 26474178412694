import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DateTime from '../common/DateTime';
import { _Api } from '../api/_call';
import { Tablehead } from '../common/modules/Tablehead';
import TableColumnModal from "../common/modules/tableColumn";
import ConfirmationModel from "../common/modules/confirmationmodel";
import { SetdataTable } from '../api/setdatatable';
import { posordertableHead } from '../common/Tablehead';
import SocialModal from "../common/modules/SocialModal/SocialModal";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import Select from "react-select";
import * as XLSX from 'xlsx'
import TableLoader from '../../admin/common/TableLoader';
const PosOrder = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [, setfilterby] = useState('');
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState('');
  const [posorderlist, setPosorderlist] = useState([]);
  const [newcolumndata, setnewcolumndata] = useState(posordertableHead);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [productid, setProductid] = useState()
  const [selected_id, setSelected_id] = useState([])
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const filterbyname = () => {
    if (filtername) {
      GetPOsorder();
    } else {
      GetPOsorder();
    }
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const GetPOsorder = async () => {
    setDatsLodaer(true)
    try {
      const postdata = {
        "id": state ? state?.selectedid : ""
      }
      let resPosorder = await _Api(postdata, `api/v1/Inventory/po/allocationConnect`);
      if (resPosorder?.code === 200) {
        SetdataTable('pos_order_table', 6, newcolumndata);
        setPosorderlist(resPosorder?.data);
        setDatsLodaer(false)
      } else {
        SetdataTable('pos_order_table', 6, newcolumndata);
        setDatsLodaer(false)
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_inventory_posorder',
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    callcolumns();

  }, []);

  useEffect(() => {
    GetPOsorder();
    // eslint-disable-next-line
  }, []);

  const allocationsave = async () => {
    let GetloadingID = loading();
    let selected = [selected_id]
    try {
      const postdata = {
        "po_product_id": productid,
        "selected_id": selected,
      }
      let res = await _Api(postdata, `api/v1/Inventory/po/allocationConnectSave`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t("Allocation connection susses!!"));
      }
      else {
        Updateloading(t, GetloadingID, t("No item Selected!!"), "error")
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    posordertableHead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (posorderlist.length) {
      posorderlist.forEach((result, key) => {
        bodyarray.push(["", key + 1, result?.main_image, result?.SKU, result?.createdAt, result?.pos, result?.stock_id, result?.metal_name, result?.stone_name, result?.size_name, result?.Qty, result?.status?.cart_status === 0 ?
          "Close Order"
          : "Open Order"
          ,
          result?.status?.priority === 1 ?
            "Low"
            : result?.status?.priority === 2 ?
              "Medium"
              : result?.status?.priority === 0 ?
                "High"
                : ''
          , result?.location_name, result?.customer_name, result?.delivery_date, result?.due_days, result?.remark, result?.printURL
        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "pos_order");
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="main-body-current-day-time">
                  <DateTime></DateTime>
                </span>
                <div className="main-body-top-tab-bar create-purchase-top-bar">
                  <div className='page-back-heading'>
                    <div className='page-back-btn'>
                      <div onClick={() => navigate(-1)} className="page-back-btn">
                        <img
                          src={base_assets + "images/icons/arrow-left.png"}
                          alt=""
                        />
                      </div>
                      <div
                        className="main-body-top-tab-bar-left-col nav nav-pills"
                        role="tablist"
                      >
                        <div
                          onClick={() => navigate(-1)}
                          className="main-body-top-tab-bar-left-col-a nav-link active"
                        >
                          <span className="main-body-top-tab-bar-left-col-a-text">
                            {t("POS Order")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-body-top-tab-bar-right-col" data-bs-toggle="modal" data-bs-target="#createPurchaseModal">
                    <Link
                      to="" className="main-body-top-tab-bar-right-col-a">
                      <img className="link-img" src={base_assets + "images/icons/link-icon-white.png"} alt="" />
                      <span className="main-body-top-tab-bar-right-col-a-text">{t("Allocation")}</span>
                    </Link>
                  </div>
                </div>
                <div className="main-body-top-status-bar">
                  <Select
                    className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                    options={selectOptions}
                    value={{ label: limit, value: limit }}
                    onChange={(e) => { setLimit(e.value); GetPOsorder(e.value) }}
                    isSearchable={false}
                  />
                  <div className="main-body-top-status-bar-filter">
                    <input
                      type="text"
                      value={filtername}
                      className="main-body-top-status-bar-filter-input"
                      placeholder={t("TF No./StockID/SKU/Metal/Stone")}
                      onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                      onChange={(e) => setFiltername(e.target.value)}
                    />
                    {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                    <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="main-body-top-status-bar-multi-date-picker">
                    <button className="print-export-dropdown-selected-icon d-none">
                      <img
                        src={base_assets + "images/icons/printer-icon.png"}
                        alt=""
                      />
                    </button>
                    <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={base_assets + "images/icons/ellipsis-circular.png"}
                          alt=""
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                          <div className="dropdown-item-img">
                            <img
                              src={base_assets + "images/icons/export-x-icon.png"}
                              alt=""
                            />
                          </div>
                          <span>{t("export")}</span>
                        </li>
                        <li
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#columnModal"
                        >
                          <div className="dropdown-item-img">
                            <img
                              src={base_assets + "images/icons/column-icon.png"}
                              alt=""
                            />
                          </div>
                          <span>{t("column")}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="common-sub-table-wrap add-row">
                  <div className="main-body-main-table-wrap">
                    <table id="pos_order_table" className="common-table product-base-table table-striped w-100">
                      <Tablehead tablehead={posordertableHead} tblName={"tbl_inventory_posorder"} setfilterby={setfilterby} />
                      <tbody>
                        <>
                          {posorderlist?.length ? posorderlist.map((result, key) => {
                            return (
                              <tr key={key}>
                                <td key={key}>
                                  <div className="com-check">
                                    <input className='posordertableHead' id="poscheck" type="checkbox"
                                      onClick={(e) => { setSelected_id(result.id); setProductid(result.product_id) }} />
                                    <label htmlFor="poscheck" className="com-check-label" />
                                  </div>
                                </td>
                                <td>{key + 1}</td>
                                <td>
                                  <div className="table-pro-img">
                                    <img
                                      src={result?.main_image ? result?.main_image : ''}
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td className="po-no">
                                  <Link>{result?.SKU ? result?.SKU : ''} </Link>
                                </td>
                                <td>{result?.createdAt ? result?.createdAt : ''}</td>
                                <td className="po-no sku-col">
                                  <Link>
                                    {result?.pos ? result?.pos : ""}
                                    <img
                                      className="link-img"
                                      src={base_assets + "images/icons/link-icon.svg"}
                                      alt=""
                                    />
                                  </Link>
                                </td>
                                <td className="col-highlight">{result?.stock_id ? result?.stock_id : ""}</td>
                                <td className="col-highlight">{result?.metal_name ? result?.metal_name : ""}</td>
                                <td className="col-highlight">{result?.stone_name ? result?.stone_name : ""}</td>
                                <td className="col-highlight">{result?.size_name ? result?.size_name : ""}</td>
                                <td className="col-highlight">{result?.Qty ? result?.Qty : ""}</td>
                                <td>
                                  {result?.status?.cart_status === 0 ?
                                    <label className="status-tab gray-tab">
                                      {t("Close Order")}
                                    </label>
                                    : <label className="status-tab gray-tab">
                                      {t("Open Order")}
                                    </label>
                                  }
                                </td>
                                <td> {result?.status?.priority === 1 ?
                                  <label className="status-tab low-priority">{t("Low")}</label>
                                  : result?.status?.priority === 2 ?
                                    <label className="status-tab medium-priority">{t("Medium")}</label>
                                    : result?.status?.priority === 0 ?
                                      <label className="status-tab high-priority">{t("High")}</label>
                                      : ''
                                }</td>
                                <td className="danger-text">  {result?.location_name ? result?.location_name : ""}</td>
                                <td className="danger-text">{result?.customer_name ? result?.customer_name : ""}</td>
                                <td className="danger-text">{result?.delivery_date ? result?.delivery_date : ""}</td>
                                <td className="danger-text">{result?.due_days ? result?.due_days : ""}</td>
                                <td>{result?.remark ? result?.remark : ""}</td>
                                <td className="text-center">
                                  {result?.printURL ? <div onClick={() => {
                                    window.open(result?.printURL, '_blank', 'noreferrer')
                                  }}>
                                    <img
                                      className="pdf-image"
                                      src={base_assets + "images/icons/pdf-icon.png"}
                                      alt=""
                                    /></div> : ""
                                  }

                                </td>
                              </tr>
                            );
                          }) : ""}
                        </>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>{posorderlist?.reduce(
                            (interation, val) => (interation = interation + parseInt(val.Qty)),
                            0
                          )}</td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tfoot>
                    </table>
                    {datalodaer && <TableLoader />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SocialModal />
        </div>
      </div>
      <TableColumnModal tablehead={posordertableHead} tblName={"tbl_inventory_posorder"} calldatatable={GetPOsorder} setnewcolumndata={setnewcolumndata} ></TableColumnModal>
      <ConfirmationModel statement={t("connect Allocation")} handler_submit={allocationsave} handler_dismiss={GetPOsorder}
      ></ConfirmationModel>
    </React.Fragment>
  );
}
export default PosOrder;

