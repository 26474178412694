import React from "react";
import { useTranslation } from 'react-i18next';
const Deallcocatmodal = props => {
    const { t } = useTranslation();
    const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const { deallcocation_order_close, deallcocation_deallocate } = props;
    return (
        <>
            <div className="modal fade common-modal common-modal-danger" id="deallcocat-modal" tabIndex={-1} aria-labelledby="allocation-listedModalLabel" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img className="common-modal-exclamation-img" src={base_url_assets + "images/icons/exclamation-mark-red.png"} alt="" />
                            <p className="modal-content-text">{t("Are you sure you want to Free this item ?")}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn modal-content-no" data-bs-dismiss="modal" onClick={(e) => deallcocation_order_close('NO')}>{t("Order Close")}</button>
                            <button type="button" className="btn modal-content-yes" data-bs-dismiss="modal" onClick={(e) => deallcocation_deallocate('YES')}>{t("Deallocate")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Deallcocatmodal;