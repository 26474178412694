import React, { useRef, useState, useEffect, useCallback } from 'react'
import RightArrow from "../../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Pagination } from "../../common/Pagination"
import { _Api } from "../../api/_call";
import Select from 'react-select'
import Confirmationmodel from "../../common/modules/confirmationmodel"
import { loading, Updateloading } from '../../common/helpers/toastify'
import TableLoader from '../../common/TableLoader';
const SetupEmail = () => {
  const dataFetched = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [emaillist, setEmaillist] = useState([])
  const [filtername, setFiltername] = useState('');
  const [limit, setLimit] = useState(100);
  const [showloader, setShowloader] = useState(false)
  const [deleteid, setDeleteid] = useState([])
  const getlimit = (limit) => {
    setLimit(limit);
    getlist(1, limit);
    setstate({ ...statepage, currentPage: 1 });
  };
  const filterbyname = (search) => {
    getlist(currentPage, limit, search);
    setstate({ ...statepage, currentPage: 1 });
  };

  const [statepage, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = statepage;
  const handlePaginations = (current) => {
    setstate({ ...statepage, currentPage: current });
    getlist(current);

  };
  const getlist = useCallback(async (current, firstlimit, search) => {
    setShowloader(true)
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": skipNumber,
      }
      let res = await _Api(postdata, `api/v1/App/emailtemplate/get-list`)
      if (res?.code === 200) {
        setEmaillist(res?.data)
        setShowloader(false)
        setstate({
          ...statepage,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
        dataFetched.current = false;
      }
    }
    catch (err) {
      console.log(err, "err")
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getlist()
    // eslint-disable-next-line
  }, [])
  const deleteemail = async () => {
    let GetloadingID = loading()
    try {
      let postdata = { "ids": deleteid }
      let res = await _Api(postdata, `api/v1/App/emailtemplate/delete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getlist()
        setDeleteid([])
      } else {
        Updateloading(t, GetloadingID, res.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const handleCheckAllChange = (event) => {
    setDeleteid(
      event.target.checked ? emaillist.map((c) => c?._id) : []
    );
  };
  const handleFlterchange = (event, data) => {
    setDeleteid((prevChecked) =>
      event.target.checked
        ? [...prevChecked, data]
        : prevChecked.filter((item) => item !== data)
    );
  };
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">{t("Setup")} <RightArrow /> <span className="" data-bs-toggle="modal" data-bs-target="#sessionModal">{t("Email")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            {deleteid?.length ?
              <img
                data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                className="cursor-pointer"
                src={
                  base_assets + 'images/admin/icons/Delete.png'
                }
                alt=""
              /> : ""}

            <Select
              className="limit-select mx-3"
              options={selectOptions}
              value={{ label: limit, value: limit }}
              onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
            />
            <div className="com-search-bar position-relative">
              <img className="com-search-bar-search-img"
                onClick={filterbyname} src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search Email" value={filtername} className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                onChange={(e) => setFiltername(e.target.value)} />
              {filtername ? <img className="search-bar-clear" onClick={(e) => {
                setFiltername("");
                getlist(1, 100, "")
              }} src={base_assets + 'images/icons/False.png'} alt="" /> : ""}
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("print")}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
                <li className="dropdown-item" >
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export.png"}
                      alt=""
                    />
                  </div>
                  <span >{t("Import")}</span>
                </li>
              </ul>
            </div>
            <Link to="/app/admin/setup/email/template" className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3 text-decoration-none">
              <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th className="pe-0">
                      <div className="com-check">
                        <input id="check1" type="checkbox"
                          onChange={(e) => handleCheckAllChange(e)}
                          checked={deleteid.length === emaillist.length} />
                        <label htmlFor="check1" className="com-check-label" />
                      </div>
                    </th>
                    <th>{t("Name")}</th>
                    <th>{t("Subject")}</th>
                    <th>{t("Location")}</th>
                    <th>{t("Last Modified")} </th>
                    <th className="text-center">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {emaillist?.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td className="pe-0">
                          <div className="com-check">
                            <input id={result?._id} type="checkbox"
                              onChange={(e) => handleFlterchange(e, result?._id)}
                              checked={
                                deleteid ? deleteid.includes(result?._id) : ""
                              } />
                            <label htmlFor={result?._id} className="com-check-label" />
                          </div>
                        </td>
                        <td>{result?.name ? result?.name : "-"}</td>
                        <td>{result?.subject ? result?.subject : "-"}</td>
                        <td>{result?.locationName ? result?.locationName : "-"}</td>
                        <td>{result?.updatedAt ? result?.updatedAt : "-"}</td>
                        <td className="text-center">
                          <Link to="/app/admin/setup/email/template"
                            state={{ id: result?._id }}>
                            <img className="cursor-pointer me-4" src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                          </Link>
                          <img className="cursor-pointer" data-bs-toggle="modal"
                            onClick={() => setDeleteid([result?._id])}
                            data-bs-target="#createPurchaseModal" src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {emaillist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={deleteemail} ></Confirmationmodel>
      </div>
    </>
  )
}

export default SetupEmail;