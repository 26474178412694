import React from 'react';
import "datatables.net-fixedcolumns";
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';


const FilterCustomerModal = (props) => {
  const { t } = useTranslation();
  const { Namedata, setNamedata, citydata, setCitydata, countrydata, setCountrydata, groupdata, setGroupdata, genderdata, setGenderdata, maxAge, setMaxage, customerlist, filterdata, setfilterdata, getcustomerfiltter } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const alphabates = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const behaviorData = ['Royal', 'VIP', 'Polite', 'Noise', 'Thief', 'Frustation'];
  const callfilters = (event, label) => {
    if (label === "name") {
      const namedata = [...Namedata];
      if (event.target.checked) {
        if (namedata.filter(e => e === event.target.value).length === 0) {
          namedata.push(event.target.value);
          setNamedata(namedata);
        }
      } else {
        setNamedata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    } else if (label === "country") {
      const countryarr = [...countrydata];
      if (event.target.checked) {
        if (countryarr.filter(e => e === event.target.value).length === 0) {
          countryarr.push(event.target.value);
          setCountrydata(countryarr);
        }
      } else {
        setCountrydata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }

    } else if (label === "city") {
      const cityarr = [...citydata];

      if (event.target.checked) {
        if (cityarr.filter(e => e === event.target.value).length === 0) {
          cityarr.push(event.target.value);
          setCitydata(cityarr);
        }
      } else {
        setCitydata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    } else if (label === "gender") {
      const genderarr = [...genderdata];
      if (event.target.checked) {
        if (genderarr.filter(e => e === event.target.value).length === 0) {
          genderarr.push(event.target.value);
          setGenderdata(genderarr);
        }
      } else {
        setGenderdata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    } else if (label === "group") {
      const grouparr = [...groupdata];
      if (event.target.checked) {
        if (grouparr.filter(e => e === event.target.value).length === 0) {
          grouparr.push(event.target.value);
          setGroupdata(grouparr);
        }
      } else {
        setGroupdata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    }
  }

  const getscarchvalue = async (event, lable) => {
    if (lable === "country") {
      const query = event.target.value ? event.target.value : "";
      let updatedList = [...filterdata?.country];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setfilterdata(prevState => ({
            ...prevState,
            country: arr
          }));
        });
      } else {
        getcustomerfiltter()
      }
    } else if (lable === "city") {
      const query = event.target.value ? event.target.value : "";
      let updatedList = [...filterdata?.city];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setfilterdata(prevState => ({
            ...prevState,
            city: arr
          }));
        });
      } else {
        getcustomerfiltter()
      }
    }
  }
  const clearall = () => {
    let get = document.getElementsByName('clear');
    for (let i = 0; i < get.length; i++) {
      get[i].checked = false;
    }
    setNamedata([]); setCitydata([]); setCountrydata([]); setGroupdata([]);
    setGenderdata([]); setMaxage([0, 100]);
  }
  return (
    <>
      <div className="modal fade filter-customer-modal" id="filterCustomerModal" tabIndex="-1" aria-labelledby="filterCustomerModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <img className="d-block" src={base_assets + "images/icons/search-outline.png"} alt="" />
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='filter-customer-modal-single-box'>
                <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                  <label>{t("Name")}</label>
                  <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">{t("Select All")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                <div className="collapse show" id="collapseOne">
                  <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                    {alphabates.length ? alphabates.map((result, key) => {
                      return (
                        <div className='com-label-select' key={key}>
                          <input className='' type='checkbox' id={result} value={result || ''} name="clear" onClick={(e) => callfilters(e, "name")} />
                          <label className='text-center bg-f2' htmlFor={result}>{result}</label>
                        </div>
                      )
                    }) : ""}
                  </div>
                </div>
              </div>
              <div className='filter-customer-modal-single-box'>
                <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                  <label>{t("Age")}</label>
                  <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRange" aria-expanded="false" aria-controls="collapseRange">{t("Select All")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                <div className="collapse show" id="collapseRange">
                  <div className='filter-customer-modal-alpha filter-customer-modal-label-row pb-4 pt-2'>
                    <div className="range-area mt-0">
                      <Slider
                        range
                        min={0}
                        max={100}
                        value={maxAge}
                        onChange={setMaxage}
                      />
                      <div className="range-area-num d-flex justify-content-between">
                        <div className="range-area-num-single">
                          <span className="range-area-num-unit">
                            {maxAge[0]}
                          </span>
                        </div>
                        <div className="range-area-num-single">
                          <span className="range-area-num-unit">
                            {maxAge[1]}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='filter-customer-modal-single-box'>
                <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                  <label>{t("Gender")}</label>
                  <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">{t("Select All")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                <div className="collapse" id="collapseTwo">
                  <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                    {filterdata?.gender.map((result, key) => {
                      return (
                        <div className='com-label-select' key={key}>
                          <input className='' type='checkbox' id={result} value={result || ''} name="clear" onClick={(e) => callfilters(e, "gender")} />
                          <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto' htmlFor={result}>{result}</label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='filter-customer-modal-single-box'>
                <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                  <label>{t("Group")}</label>
                  <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">{t("Select All")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                <div className="collapse" id="collapseThree">
                  <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                    {filterdata?.group.map((result, key) => {
                      return (
                        <div className='com-label-select' key={key}>
                          <input className='' type='checkbox' id={result} name="clear" value={result || ''} onClick={(e) => callfilters(e, "group")} />
                          <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto' htmlFor={result}>{result}</label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='filter-customer-modal-single-box'>
                <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                  <label>{t("Behavior")}</label>
                  <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">{t("Select All")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                <div className="collapse" id="collapseFour">
                  <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                    {behaviorData.map((result, key) => {
                      return (
                        <div className='com-label-select' key={key}>
                          <input className='' type='checkbox' name="clear" id={result} />
                          <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto' htmlFor={result}>{result}</label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='filter-customer-modal-single-box'>
                <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                  <label>{t("Country")}</label>
                  <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">{t("Select All")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                <div className="collapse" id="collapseFive">
                  <div className='filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                    <div className='filter-customer-modal-alpha-input mb-3'>
                      <button className='border-0 p-0 bg-transparent pe-1'>
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input className='border-0 ps-2' type='text' placeholder='Search'
                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "country")}
                        onChange={(e) => getscarchvalue(e.target.value)} />
                    </div>
                    <div className='row w-100'>
                      {filterdata?.country.length ? filterdata?.country.map((result, key) => {
                        return (
                          <div className='col-md-6 mb-3' key={key}>
                            <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id={result?.id}
                              value={result?.id || ''}
                              onClick={(e) => callfilters(e, "country")} />
                            <label className="form-check-label cursor-pointer" name="clear" htmlFor={result?.id}>{result?.name ? result?.name : ""}</label>
                          </div>
                        )

                      })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className='filter-customer-modal-single-box'>
                <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                  <label>{t("City")}</label>
                  <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">{t("Select All")} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                </div>
                <div className="collapse" id="collapseSix">
                  <div className='filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                    <div className='filter-customer-modal-alpha-input mb-3'>
                      <button className='border-0 p-0 bg-transparent pe-1'>
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <input className='border-0 ps-2' type='text' placeholder='Search'
                        onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "city")}
                        onChange={(e) => getscarchvalue(e.target.value)} />
                    </div>
                    <div className='row w-100'>
                      {
                        filterdata?.city?.length ? filterdata?.city.map((result, key) => {
                          return (
                            <div className='col-md-6 mb-3' key={key}>
                              <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox"
                                id={`city${key}`}
                                value={result || ''}
                                name="clear"
                                onClick={(e) => callfilters(e, "city")} />
                              <label className="form-check-label cursor-pointer" htmlFor={`city${key}`}>{result}</label>
                            </div>
                          )
                        })

                          : ""}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer py-2">
              <button type="button" className="p-0 bg-transparent border-0 fs-6 me-auto fw-semibold graythemecolor"
                onClick={(e) => {
                  clearall()
                }}>{t("Clear All")}</button>
              <button type="button" className="com-btn fs-6 h-auto p-3 py-2 rounded-2 fw-semibold" data-bs-dismiss="modal"
                onClick={() => { customerlist() }}>{t("Save")}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterCustomerModal;