import React, { useState, useEffect, useRef } from "react";
import "../master.scss"
import { _Api } from "../../api/_call";
import Select, { components } from "react-select";
import {
  LocationList,
  GetCountrygraph
} from "../../common/Commongraphql"
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';


const EditSalesPerson = (peops) => {
  const selectRef = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { setEditItem, dataid, setEditSales, create, setDataid, setCreate } = peops
  const { t } = useTranslation();
  const [country, setCountry] = useState();
  const [name, setName] = useState({ fname: "", mname: "", lname: "" })
  const [position, setPosition] = useState("")
  const [LocationarrayList, setLocationarrayList] = useState([]);
  const [contactArr, setContactArr] = useState([{ icon: 1, code: "", no: "" }])
  const [countryarray, setCountryarray] = useState([])
  const [commission, setCommission] = useState({ percentage: 0, amount: 0 })
  const [active, setActive] = useState(true)
  const [checked, setChecked] = useState([]);
  const [userImage, setUserImage] = useState("")
  const mobileImage = <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />;
  const phoneImage = <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />;
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const phoneOption = [
    {
      value: 1,
      icon: <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />
    },
    {
      value: 2,
      icon: <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />
    }
  ];
  const getsalespersondata = async () => {
    if (create) {
      let contactObj = { icon: 1, code: "", no: '' };
      setName(existingValues => ({ ...existingValues, fname: "" }))
      setName(existingValues => ({ ...existingValues, mname: "" }))
      setName(existingValues => ({ ...existingValues, lname: "" }))
      setCountry({
        "id": "",
        "sortname": "",
        "name": "",
        "phoneCode": ""
      })
      setPosition("")
      setUserImage("")
      setContactArr([contactObj])
      setActive(true)
    } else if (dataid) {
      let postdata = {
        id: dataid ? dataid : ""
      }
      let res = await _Api(postdata, `api/v1/App/master/salesPersonMasterView`)
      if (res.code === 200) {
        setName(existingValues => ({ ...existingValues, fname: (res?.data?.fname) }))
        setName(existingValues => ({ ...existingValues, mname: (res?.data?.mname) }))
        setName(existingValues => ({ ...existingValues, lname: (res?.data?.lname) }))
        setCountry(res?.data?.country)
        setUserImage(res?.data?.profile)
        setContactArr(res?.data?.contacts)
        setPosition(res?.data?.position)
        setChecked(res?.data?.locations);
        if (res?.data?.Status === "1") {
          setActive(true)
        } else {
          setActive(false)
        }

      }
    }

  }
  const handleSelectChange = (selectedOption, index, code, no) => {
    const newValues = [...contactArr];
    newValues[index] = { icon: selectedOption, code: code, no: no };
    setContactArr(newValues);
  };
  useEffect(() => {
    if (dataid || create) {
      getsalespersondata()
    }
    // eslint-disable-next-line
  }, [dataid, create])

  const editSalesPersondata = async () => {
    if (!name.fname) {
      error(t("The name is required"))
      return
    }
    if (!checked.length) {
      error(t("The locations is required"))
      return
    }
    if (!country.id) {
      error(t("The country is required"))
      return
    }
    if (!position) {
      error(t("The position is required"))
      return
    }
    let GetloadingID = loading();
    try {
      let postdata = {
        "id": dataid,
        "profile": userImage,
        "position": position,
        "fname": name?.fname ? name?.fname : "",
        "mname": name?.mname ? name?.mname : "",
        "lname": name?.fname ? name?.lname : "",
        "country": country?.id,
        "commission": commission,
        "contacts": contactArr,
        "Status": active ? "1" : "0",
        "locations": checked.length ? checked : [],
        "edit": dataid ? true : false
      }
      let res = await _Api(postdata, `api/v1/App/master/salesPersonMasterEdit`)
      if (res?.code === 200) {
        if (dataid) {
          Updateloading(t, GetloadingID, (res?.message));
        } else {
          Updateloading(t, GetloadingID, (res?.message));
        }
        setEditSales(true)
        setEditItem(false)
        setDataid("")
        setCreate(false)
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const handleCheckAllChange = (e) => {
    setChecked(
      e.target.checked ? LocationarrayList.map((c) => c?.id) : []
    );
  };

  const handleCountryChange = (e, c) => {
    let location = [...checked];
    if (e.target.checked) {
      location.push(c);
      setChecked(location);
    } else {
      location = location.filter(item => item !== c);
      setChecked(location);

    }
  };


  const onImageupload = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      let GetloadingID = loading();
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/App/User/uploadimage`);
        if (resfront.code === 200) {
          Updateloading(t, GetloadingID, t("Successfully Upload Image"));
          setUserImage(resfront?.data?.images?.url);
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  };

  const addEventList = () => {
    let newValues = [...contactArr];
    newValues.push({ icon: 2, code: "", no: '' });
    setContactArr(newValues);
  };

  const handleRemoveElement = (indexToRemove) => {
    let newValues = [...contactArr];
    let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
    setContactArr(updatedArray);

  };

  return (
    <>
      <div className="edit-item-area bg-white row">
        <div className="col-md-9 px-0">
          <div className="edit-item-area-body">

            <div className="edit-item-area-body-circle-img text-center mb-4">
              <div className="edit-item-area-body-circle-img-choose">
                <input id="chooseImg" type="file" className="d-none"
                  onChange={onImageupload} />
                <label htmlFor="chooseImg" className="rounded-circle overflow-hidden">
                  <img className="" src={userImage ? userImage : base_assets + "images/blank-img-2.png"} alt="" />
                </label>
              </div>
              <label>{"File: PNG, JPG, JPEG"}<br /> {t("Maximum size 200KB")}</label>
            </div>
            <div className="row mx-0">
              <div className="col-md-6">
                <div className="v3-input-group">
                  <label className="v3-input-group-label">{t("First Name")} <sup>*</sup></label>
                  <input type="text" className="v3-input-group-input w-100"
                    value={name?.fname || ''}
                    onChange={(e) => setName(existingValues => ({ ...existingValues, fname: (e.target.value) }))} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="v3-input-group">
                  <label className="v3-input-group-label">{t("Middle Name")} <sup>*</sup></label>
                  <input type="text" className="v3-input-group-input w-100"
                    value={name?.mname || ''}
                    onChange={(e) => setName(existingValues => ({ ...existingValues, mname: (e.target.value) }))} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="v3-input-group">
                  <label className="v3-input-group-label">{t("Last Name")} <sup>*</sup></label>
                  <input type="text" className="v3-input-group-input w-100"
                    value={name?.lname || ''}
                    onChange={(e) => setName(existingValues => ({ ...existingValues, lname: (e.target.value) }))} />
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <div className="v3-input-group">
                  <label className="v3-input-group-label">{t("Position")} <sup>*</sup></label>
                  <input type="text" className="v3-input-group-input w-100"
                    value={position || ''}
                    onChange={(e) => setPosition(e.target.value)} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="v3-input-group">
                  <label className="v3-input-group-label">{t("Country")} <sup>*</sup></label>
                  <Select
                    className="select-set select w-100 p-0"
                    placeholder=""
                    options={countryarray}
                    value={country}
                    onChange={(e) => {
                      setCountry(e); handleSelectChange(
                        contactArr[0]?.icon,
                        0,
                        e.phoneCode ? e.phoneCode : contactArr[0]?.code,
                        contactArr[0]?.no
                      )
                    }}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                    classNamePrefix="common-select"
                    isSearchable={true}
                    getOptionLabel={(opt) => opt.name}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="v3-input-group mb-0">
                  <label className="v3-input-group-label">{t("Phone")}</label>
                </div>
                {contactArr?.length ? contactArr.map((index, value) => {
                  return (
                    <div className="phone-select-group mb-3"
                      id="othermobile" key={value}>
                      {value !== 0 ?
                        <button className="border-0 bg-transparent p-0"
                          onClick={() => handleRemoveElement(value)}><img className="=" src={base_assets + "images/icons/minus-detail.png"} alt="" /></button>
                        : ""}
                      <div className="v3-input-group phone-select-group-icon mb-0">
                        <Select
                          ref={selectRef}
                          className="select-set select w-100 p-0"
                          placeholder=""
                          id="othermobilicon"
                          options={phoneOption}
                          value={{ value: index?.icon, icon: index?.icon === 1 ? mobileImage : phoneImage }}
                          onChange={(e) =>
                            handleSelectChange(
                              e.value ? e.value : index?.icon,
                              value,
                              index?.code,
                              index?.no
                            )}
                          classNamePrefix="common-select"
                          isSearchable={false}
                          getOptionLabel={(e) => (
                            <div className="common-select-icon">{e.icon}</div>
                          )}
                        />
                      </div>
                      <div className="d-flex align-items-center v3-input-group phone-select-group-input-grp mb-0">
                        <Select
                          className="select-set select p-0 flex-shrink-0"
                          id="otherphonecode"
                          placeholder=""
                          options={countryarray}
                          value={
                            { value: index?.code, phoneCode: index?.code }
                          }
                          onChange={(e) =>
                            handleSelectChange(
                              index?.icon,
                              value,
                              e.phoneCode ? e.phoneCode : index?.code,
                              index?.no
                            )}
                          classNamePrefix="common-select"
                          isSearchable={true}
                          getOptionLabel={(opt) => opt.phoneCode}
                        />
                        <input type="number" className="form-control border-start-0 v3-input-group-input"
                          maxLength={17}
                          pattern="[0-9]*"
                          value={index?.no || ''}
                          onChange={(e) => handleSelectChange(
                            index?.icon,
                            value,
                            index?.code,
                            e.target.value
                          )}
                        />
                      </div>
                    </div>
                  )
                })
                  : ""}
              </div>

              <div className="col-md-12 mt-3">
                <button className="border-0 bg-transparent p-0 main-green-text"
                  onClick={() => addEventList("addRelatedEvent")}>
                  <img src={base_assets + "images/icons/add-detail.png"} alt="" />
                  <span className="ms-2"> {t("Add phone")}</span>
                </button>
              </div>
              <div className="col-md-12">
                <div className="partition my-4"></div>
              </div>
              <div className="col-md-12">
                <div className="v3-input-group">
                  <label className="v3-input-group-heading">{t("Location")}</label>
                </div>
                <div className="d-flex gap-5">
                  <div className="flex-shrink-0">
                    <input className="form-check-input fill-com-check me-3 cursor-pointer" type="checkbox" id="allLocations"
                      onChange={handleCheckAllChange}
                      checked={checked?.length === LocationarrayList?.length} />
                    <label className="form-check-label cursor-pointer" htmlFor="allLocations">{("All Locations")} </label>
                  </div>
                  <div className="d-flex flex-wrap">
                    {LocationarrayList?.length ? LocationarrayList.map((result, key) => {
                      return (
                        <div className="mb-4 me-4" key={key}>
                          <input className="form-check-input fill-com-check me-3 cursor-pointer"
                            type="checkbox"
                            id={result?.id}
                            onChange={(e) => handleCountryChange(e, result?.id)}
                            checked={checked.includes(result.id)} />
                          <label className="form-check-label cursor-pointer"
                            htmlFor={result?.id}>{result?.name ? result?.name : ""} </label>
                        </div>
                      )
                    }) : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="partition my-4"></div>
              </div>
              <div className="col-md-12">
                <div className="v3-input-group">
                  <label className="v3-input-group-heading">{t("Commission")}</label>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Commission")} (%) <sup>*</sup></label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={commission?.type}
                        onChange={(e) => setCommission(existingValues => ({ ...existingValues, percentage: parseFloat(e.target.value) }))} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Commission")}{t("(Amount)")} <sup>*</sup></label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={commission?.value}
                        onChange={(e) => setCommission(existingValues => ({ ...existingValues, amount: parseFloat(e.target.value) }))} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-md-3 pe-0">
          <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
            <button className="square-btn square-btn-white btn-close focus-none" onClick={() => {
              setEditItem(false);
              setDataid("");
              setCreate(false)
            }}></button>
            <button className="square-btn" onClick={() => editSalesPersondata()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
          </div>
          <div className="text-end">
            <div className="form-check form-switch ps-0">
              <label className="form-check-label">{t("Active")}</label>
              <input className="form-check-input float-none ms-2 cursor-pointer" type="checkbox"
                checked={active ? true : false}
                onChange={(e) => setActive(e.target.checked)} />
            </div>
          </div>
        </div>
      </div>
      <LocationList setLocationarrayList={setLocationarrayList} />
      <GetCountrygraph setCountrynamearr={setCountryarray} />
    </>
  )
}

export default EditSalesPerson;