import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../custom.scss';
import '../../catalog/catalog.scss';
import CheckoutBlank from '../../checkout/checkoutBlank';
import CheckoutSell from '../../checkout/checkoutSell';
import CheckoutPayment from '../../checkout/checkoutPayment';
import MixMatchProductList from '../../common/productList/mixmatchproductlist';
import 'react-image-gallery/styles/css/image-gallery.css';
import CommonForm from '../../common/Form/Form';
import ImageGallery from 'react-image-gallery';
import CustomOrderSearch from '../../common/module/customOrderSearch';
import { useSelector } from 'react-redux';
import { _Api } from '../../common/api/_call';
import { toFormatPrice, toUnformatPrice } from '../../common/helpers/function';
import { useTranslation } from 'react-i18next';
import { success, error } from "../../common/helpers/toastify";
import Mixandmatchservice from "./Mixandmatchservice"
import Loading from '../../common/module/Loading/Loading';
const MixAndMatchProduct = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { state } = useLocation()
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const { t } = useTranslation();
  const [selectestate] = useState(state)
  console.log(selectestate, "selectestate")
  const [searchproductstatus, setsearchproductstatus] = useState(false);
  const [Showcolor, setshowcolor] = useState(false)
  const [showpage, setshowpage] = useState(false)
  const [Optiondetails, setOptiondetails] = useState(false)
  const [Images, setImages] = useState([])
  const [addtocartstatus, setaddtocartstatus] = useState(true)
  const [remark, setRemark] = useState('')
  const [showcheckout, setShowcheckout] = useState('blank')
  const [showbutton, setShowbutton] = useState(true)
  const [, setdeletestatus] = useState()
  const [UpdatedCart, setUpdatedCart] = useState()
  const [showpayment, setShowpayment] = useState(false)
  const [product_id, setproduct_id] = useState()
  const [Loadingstatus, setLoadingstatus] = useState(false);
  const [mixmatchproduct, setmixmatchproduct] = useState()
  const [Engraving, setEngraving] = useState(selectestate?.Engraving ? selectestate?.Engraving : mixmatchproduct?.custom_design)
  const [variant, setvariant] = useState()
  const [price, setprice] = useState()
  const [customcart, setCustomcart] = useState(state?.customcart || "")
  const [servicechange, setServicechange] = useState(false)
  const [engraving_edit, setEngraving_edit] = useState(false)
  const [mixmatchedit, setMixmtchedit] = useState(false)
  const customer = useSelector((state) => state.app.customer)
  const Saleperson = useSelector((state) => state.app.saleperson)
  const [scrvicecharge, setScrvicecharge] = useState(0)
  const [skip, setSkip] = useState(0)
  const [scrvicelist, setScrvicelist] = useState(state?.scrvicelist?.length ? state?.scrvicelist : [])
  const [scrviceremark, setScrviceremark] = useState(state?.scrviceremark || "")
  const [service_saved, setService_saved] = useState(false)
  const [editproduct, setEditproduct] = useState(state?.editproduct || false)

  const [showseqs, setShowseqs] = useState(
    selectestate?.fromdaimond || selectestate?.fromring
      ? 3
      : selectestate?.itemchanges || selectestate?.byitemsorder
        ? 1
        : 0,
  )
  const getmixmatchdetails = async (cart_id) => {
    try {
      setLoadingstatus(true)
      const postdata = {
        custom_id: selectestate?.customcart ? [selectestate?.customcart] : [],
        order_edit: selectestate?.order_edit,
        type: selectestate?.type ? selectestate?.type : state?.optiondetailid
          ? state?.optiondetailid
            ? 'catalog'
            : 'inventory'
          : Optiondetails
            ? 'catalog'
            : 'inventory',
        product_id: product_id ? product_id : selectestate?.product_id,
        diamond_id: selectestate?.daimond_id,
        customer_id: selectestate?.customer_id ? selectestate?.customer_id : customer?.id,
        cart_id: cart_id ? [cart_id] : []
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/mixAndMatch/getMixMatchItemDetail`,
      )
      if (res.code === 200) {
        let iarray = []
        setmixmatchproduct(res?.data)
        if (res?.data?.service_labour?.service_laburelist?.length) {
          setScrvicelist(res?.data?.service_labour?.service_laburelist)
          setScrviceremark(res?.data?.service_labour?.service_remark)
        } else if (scrvicelist?.length) {
          setScrvicelist(scrvicelist)
          setScrviceremark(scrviceremark)
        }
        if (state?.remark) {
          setRemark(state?.remark)
        }
        if (editproduct && customcart && !state?.order_edit) {
          mixmatcheditproduct()
        }
        setprice(res?.data?.Product?.price)
        res?.data?.Diamond?.image.forEach((result) => {
          iarray.push({ original: result, thumbnail: result })
        })
        setImages(iarray)
        setLoadingstatus(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (
      selectestate?.daimond_id && product_id
        ? product_id
        : selectestate?.product_id && showseqs === 3
    ) {
      if (selectestate?.order_edit) {
        if (selectestate?.mixmatchedit) {
          getmixmatchdetails()
        }
      } else {
        getmixmatchdetails()
      }

    }
    // eslint-disable-next-line
  }, [showseqs, customer?.id, variant])
  useEffect(() => {
    if (variant) {
      setproduct_id(variant)
      setShowseqs(3)
    }
  }, [variant])

  const addmixmatch = async () => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    if (!Saleperson?.value) {
      error(t('Saleperson is Requried!!'))
      return
    }
    try {
      setaddtocartstatus(false)
      const postdata = {
        items: selectestate?.itemchanges ? selectestate?.itemchanges : {
          price: parseInt(
            toUnformatPrice(price)) + parseInt(scrvicecharge)
        },
        product_id: product_id ? product_id : selectestate?.product_id,
        diamond_id: selectestate?.daimond_id ? selectestate?.daimond_id : '',
        remark: remark,
        custom_design: {
          "engraving_text": Engraving?.engraving_text ? Engraving?.engraving_text : "",
          "engraving_position": Engraving?.engraving_position ? Engraving?.engraving_position : "",
          "engraving_logo": Engraving?.engraving_logo ? Engraving?.engraving_logo : "",
          "font": Engraving?.font ? Engraving?.font : "",
          "logo_position": Engraving?.logo_position ? Engraving?.logo_position : ""
        },
        "service_labour": {
          "service_remark": scrviceremark,
          "service_laburelist": scrvicelist,
          "product_price": selectestate?.itemchanges
            ? parseInt(selectestate?.itemchanges?.price)
            : parseInt(mixmatchproduct?.Product?.price),
        },
        service_saved: scrvicelist.length ? true : false,
        order_type: 'mix_and_match',
        customer_id: customer?.id,
        saleperson_id: Saleperson?.value,
        product_type: selectestate?.type ? selectestate?.type : state?.optiondetailid
          ? state?.optiondetailid
            ? 'catalog'
            : 'inventory'
          : Optiondetails
            ? 'catalog'
            : 'inventory',
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/mixAndMatch/addMixMatchToCart`,
      )
      if (res.code === 200) {
        if (selectestate?.mixmatchedit) {
          addtoexchangecart()
        } else {
          setShowbutton(false)
          setaddtocartstatus(true)
          setShowcheckout('sell')
          setsearchproductstatus(true)
        }

      } else {
        error(t(res?.message))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const mixmatcheditproduct = async () => {
    setaddtocartstatus(false)
    setShowbutton(false)
    try {
      const postdata = {
        items: selectestate?.itemchanges ? selectestate?.itemchanges : {
          price: parseInt(
            toUnformatPrice(price)) + parseInt(scrvicecharge),

        },
        custom_design: {
          "engraving_text": Engraving?.engraving_text ? Engraving?.engraving_text : "",
          "engraving_position": Engraving?.engraving_position ? Engraving?.engraving_position : "",
          "engraving_logo": Engraving?.engraving_logo ? Engraving?.engraving_logo : "",
          "font": Engraving?.font ? Engraving?.font : "",
          "logo_position": Engraving?.logo_position ? Engraving?.logo_position : ""
        },
        "service_labour": {
          "service_remark": scrviceremark ? scrviceremark : state?.scrviceremark,
          "service_laburelist": scrvicelist?.length ? scrvicelist : state?.scrvicelist,
          "product_price": selectestate?.itemchanges
            ? parseInt(selectestate?.itemchanges?.price)
            : parseInt(mixmatchproduct?.Product?.price),
        },
        service_saved: scrvicelist?.length || state?.scrvicelist?.length ? true : false,
        cart_id: customcart ? customcart : state?.customcart,
        "product_id": product_id ? product_id : selectestate?.product_id,
        "product_type": selectestate?.type ? selectestate?.type : state?.optiondetailid
          ? state?.optiondetailid
            ? 'catalog'
            : 'inventory'
          : Optiondetails
            ? 'catalog'
            : 'inventory',
        diamond_id: selectestate?.daimond_id ? selectestate?.daimond_id : '',
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/ChangeMMDetails`)
      if (res.code === 200) {
        setaddtocartstatus(false)
        setShowbutton(false)
        addtoexchangecart()
      } else {
        error(t(res?.message))
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  useEffect(() => {
    setOptiondetails(state?.optiondetailid)
  }, [state?.optiondetailid])
  const addtoexchangecart = async () => {
    try {
      const postdata = {
        "order_id": selectestate?.order_id,
        'order_type': selectestate?.order_type ? selectestate?.order_type : "mix_and_match",
        "order_edit": selectestate?.order_edit,
        "mixmatchedit": selectestate?.mixmatchedit
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setShowbutton(false)
        if (res?.data?.sell_info?.cart.length) {
          getmixmatchdetails(res?.data?.sell_info?.cart[0]?.custom_cart_id)
          setCustomcart(res?.data?.sell_info?.cart[0]?.custom_cart_id)
        }
        if (selectestate?.mixmatchedit) {
          setShowbutton(true)
          setsearchproductstatus(true);
          setShowcheckout('sell');
          setUpdatedCart(res?.data);
        }
        else if (editproduct && customcart) {
          setaddtocartstatus(true)
          setsearchproductstatus(true);
          setShowcheckout('sell');
          if (res?.data.length) {
            res?.data.forEach((result) => {
              if (result?.Service_labour?.service_laburelist?.length) {
                setScrvicelist(result?.Service_labour?.service_laburelist)
              }
            })
          }
        } else {
          setShowbutton(false)
          setUpdatedCart(res?.data);
          setShowcheckout('sell');
          setsearchproductstatus(true);
        }
      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }

  useEffect(() => {
    if (selectestate?.order_id && selectestate?.order_edit && selectestate?.order_type) {
      if (!selectestate?.mixmatchedit && !editproduct) {
        addtoexchangecart()
      } else if (showseqs === 3) {
        mixmatcheditproduct()
      }
      setMixmtchedit(true)
    }
    // eslint-disable-next-line
  }, [])
  const edit_cart = async (Engraving) => {
    try {
      const postdata = {
        "custom_id": engraving_edit || service_saved ? [] : [customcart],
        "cart_id": customcart,
        "order_id": selectestate?.order_id,
        "engraving_edit": engraving_edit,
        "custom_design": {
          "engraving_text": Engraving?.engraving_text ? Engraving?.engraving_text : "",
          "engraving_position": Engraving?.engraving_position ? Engraving?.engraving_position : "",
          "engraving_logo": Engraving?.engraving_logo ? Engraving?.engraving_logo : "",
          "font": Engraving?.font ? Engraving?.font : "",
          "logo_position": Engraving?.logo_position ? Engraving?.logo_position : ""
        },
        service_saved: scrvicelist.length ? true : false,
        "service_labour": {
          "service_remark": scrviceremark ? scrviceremark : "",
          "service_laburelist": scrvicelist.length ? scrvicelist : [],
          "product_price": ""
        }
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/editMixMatchCart`)
      if (res.code === 200) {
        success((res?.message))
        addtoexchangecart()
      } else {
        error((res?.message))
      }
    } catch (err) {
      console.log(err, "err");
    }
  }

  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          {
            Loadingstatus ? <Loading /> : ''
          }
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area bg-white page-menu-block">
              <div className="common-navigation-header">
                <div className="common-navigation-header-left">
                  <h3 className="common-navigation-header-left-heading">
                    <Link
                      to="/app/pos/custom/custom"
                      className="gray-mid text-decoration-none"
                    >
                      {t("CUSTOM ORDER")}
                    </Link>
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'}
                      alt=""
                    />
                    {t("Mix and Match")}
                  </h3>
                </div>
                {showseqs === 0 ? (
                  <CustomOrderSearch
                    setOptiondetails={setOptiondetails}
                    optionid={state?.optiondetailid}
                    setvariant={setvariant}
                    from="mixAndMatch"
                    Optiondetails={Optiondetails}
                    daimond_id={selectestate?.daimond_id}
                    mixmatchsearch={true}
                    setSkip={setSkip}
                    Engraving={Engraving}
                    showpage={selectestate?.showpage ? selectestate?.showpage : showpage}
                    customcart={customcart}
                    editproduct={editproduct}
                    scrvicelist={scrvicelist}
                    scrviceremark={scrviceremark}
                    skip={skip}
                    remark={remark}
                    items={selectestate?.items}
                    order_id={selectestate?.order_id}
                    order_edit={selectestate?.order_edit}
                    order_type={selectestate?.order_type}
                  />
                ) : (
                  ""
                )}
              </div>
              <div
                className={
                  selectestate?.daimond_id
                    ? 'selection-process large-selection-process'
                    : 'selection-process change-order large-selection-process'
                }
              >
                <div
                  className={
                    selectestate?.daimond_id || selectestate?.order_edit
                      ? 'selection-process-single active-green order-0'
                      : 'selection-process-single order-0'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s3.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s3-g.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-yellow"
                    src={base_assets + 'images/pos/steps/s3-y.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t("Select Diamond")}
                  </span>
                </div>
                <span className="selection-process-line order-1"></span>
                <div
                  className={
                    (showseqs === 0) &&
                      showseqs !== 1 &&
                      showseqs !== 2
                      ? selectestate?.product_id || product_id
                        ? 'selection-process-single active-yellow order-2'
                        : 'selection-process-single order-2'
                      : 'selection-process-single active-green order-2'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s1.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s1-g.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-yellow"
                    src={base_assets + 'images/pos/steps/s1-y.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t("Select Setting")}
                  </span>
                </div>
                <span className="selection-process-line order-3"></span>
                <div
                  className={
                    showseqs >= 2 || showseqs === 1
                      ? Showcolor
                        ? 'selection-process-single active-yellow order-4'
                        : showseqs === 2 || showseqs === 3
                          ? 'selection-process-single active-green order-4'
                          : 'selection-process-single order-4'
                      : selectestate?.product_id || product_id
                        ? 'selection-process-single active-green order-4'
                        : 'selection-process-single order-4'

                  }

                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s4.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s4-g.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-yellow"
                    src={base_assets + 'images/pos/steps/s4-y.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t("Engraving")}
                  </span>
                </div>
                <span className={generalsettings?.data?.generalsetup?.servicelabers === '1' ? "selection-process-line order-4" : "d-none"}></span>
                <div
                  className={
                    generalsettings?.data?.generalsetup?.servicelabers === '1' ?
                      showseqs >= 3 || showseqs === 1 || showseqs === 2
                        ? servicechange
                          ? 'selection-process-single active-yellow order-5'
                          : showseqs === 3
                            ? 'selection-process-single active-green order-5'
                            : Showcolor ?
                              'selection-process-single active-green order-5' :
                              "selection-process-single order-5"
                        :
                        selectestate?.product_id
                          ? 'selection-process-single active-green order-5'
                          : 'selection-process-single order-5'
                      : "d-none"}
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s10.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s10-g.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-yellow"
                    src={base_assets + 'images/pos/steps/s10-y.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t("Service")}
                  </span>
                </div>
                <span className="selection-process-line order-5"></span>
                <div
                  className={
                    showbutton
                      ? 'selection-process-single order-6'
                      : 'selection-process-single active-green order-6'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s5.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s5-g.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t("Complete")}
                  </span>
                </div>
              </div>

              <div className="catalog-area-ct bg-white">
                {showseqs === 0 ? (
                  <MixMatchProductList
                    setShowseqs={setShowseqs}
                    setproduct_id={setproduct_id}
                    Engraving={Engraving}
                    showpage={selectestate?.showpage ? selectestate?.showpage : showpage}
                    setprice={setprice}
                    filter={{ Optiondetails: Optiondetails }}
                    setEngraving_edit={setEngraving_edit}
                    order_edit={selectestate?.order_edit}
                    order_id={selectestate?.order_id}
                    order_type={selectestate?.order_type}
                    customcart={customcart}
                    editproduct={editproduct}
                    scrvicelist={scrvicelist}
                    scrviceremark={scrviceremark}
                    skip={skip}
                    setSkip={setSkip}
                    remark={remark}
                    showseqs={showseqs}
                  />
                ) : showseqs === 1 ? (
                  <CommonForm
                    setShowseqs={setShowseqs}
                    setEngraving={setEngraving}
                    Engraving={Engraving}
                    setshowcolor={setshowcolor}
                    edit_cart={edit_cart}
                    engraving_edit={engraving_edit}
                    custom_design={mixmatchproduct?.custom_design}
                    editproduct={editproduct}
                    engraving_logo={Engraving?.engraving_logo
                      ? Engraving?.engraving_logo
                      : mixmatchproduct?.custom_design?.engraving_logo}
                  />
                ) : generalsettings?.data?.generalsetup?.servicelabers === '1' && showseqs === 2 ? (
                  <Mixandmatchservice
                    showseqs={showseqs}
                    setShowseqs={setShowseqs}
                    setScrvicecharge={setScrvicecharge}
                    setScrvicelist={setScrvicelist}
                    scrvicelist={scrvicelist}
                    setScrviceremark={setScrviceremark}
                    service_saved={service_saved}
                    setServicechange={setServicechange}
                    edit_cart={edit_cart}
                    scrviceremark={scrviceremark}
                    engraving_design={Engraving ? Engraving : mixmatchproduct?.custom_design}
                  />
                ) : showseqs === 3 ? (
                  <div className="mix-and-match-engraving">
                    <div className="mix-and-match-engraving-wrap">
                      <div className="mix-and-match-engraving-left">
                        <div className="products-details-row-col-left-slider">
                          <ImageGallery
                            items={Images}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showNav={false}
                            infinite={true}
                            disableSwipe={false}
                            disableThumbnailSwipe={false}
                            additionalClass="img-view-slider"
                          />
                        </div>

                        <textarea
                          className="mix-and-match-engraving-left-remark"
                          placeholder="Remark"
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="mix-and-match-engraving-right">
                        <h1 className="mix-and-match-engraving-right-heading">
                          {mixmatchproduct?.Product?.name}
                        </h1>
                        <h1 className="mix-and-match-engraving-right-heading">
                          {mixmatchproduct?.Diamond?.Carat} {t("Carats")}
                          {mixmatchproduct?.Diamond?.Shape} -
                          {mixmatchproduct?.Diamond?.Colour} /
                          {mixmatchproduct?.Diamond?.Clarity}
                        </h1>

                        <div className="mix-and-match-engraving-right-box">
                          <div className="mix-and-match-engraving-right-box-head">
                            <label className="mix-and-match-engraving-right-box-head-label">
                              {state?.items?.name} {t("Setting")} :
                            </label>
                            <span className="mix-and-match-engraving-right-box-head-price">
                              {toFormatPrice(
                                selectestate?.itemchanges
                                  ? selectestate?.itemchanges?.price
                                  : mixmatchproduct?.Product?.price,
                                { addSymbol: true },
                              )}
                            </span>
                          </div>
                          <div className="mix-and-match-engraving-right-box-input-row">
                            <div className="mix-and-match-engraving-right-box-input-row-col">
                              <label className="mix-and-match-engraving-right-box-input-row-col-label">
                                {t("Metal")} :
                              </label>
                              <span className="mix-and-match-engraving-right-box-input-row-col-data">
                                {selectestate?.itemchanges
                                  ? selectestate?.itemchanges?.metal_name
                                  : mixmatchproduct?.Product?.metal_name}
                              </span>
                            </div>
                            <div className="mix-and-match-engraving-right-box-input-row-col">
                              <label className="mix-and-match-engraving-right-box-input-row-col-label">
                                {t("Size")} :
                              </label>
                              <span className="mix-and-match-engraving-right-box-input-row-col-data">
                                {selectestate?.itemchanges
                                  ? selectestate?.itemchanges?.size_name
                                  : mixmatchproduct?.Product?.size_name}
                              </span>
                            </div>
                            <div className="mix-and-match-engraving-right-box-input-row-col">
                              <label className="mix-and-match-engraving-right-box-input-row-col-label">
                                {t("Pointer")} :
                              </label>
                              <span className="mix-and-match-engraving-right-box-input-row-col-data">
                                {selectestate?.itemchanges
                                  ? selectestate?.itemchanges?.pointer
                                  : mixmatchproduct?.Product?.pointer}
                              </span>
                            </div>
                          </div>
                          {selectestate?.order_edit ?
                            <Link
                              to="/app/pos/custom/DiamondDetail"

                              state={{
                                daimond_id: selectestate?.daimond_id,
                                order_edit: selectestate?.order_edit,
                                product_id: selectestate?.product_id,
                                mixmatchedit: mixmatchedit,
                                order_id: selectestate?.order_id,
                                order_type: selectestate?.order_type,
                                editproduct: true,
                                showpage: true,
                                customcart: customcart,
                                scrvicelist: scrvicelist,
                                scrviceremark: scrviceremark,
                                remark: remark,
                                Engraving: Engraving ? Engraving : mixmatchproduct?.custom_design
                              }}
                            >
                              <button
                                className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                              >
                                {t("Change")} {mixmatchproduct?.Product?.item_name ? mixmatchproduct?.Product?.item_name : state?.items?.name}
                              </button>
                            </Link>
                            :

                            <button
                              onClick={() => {
                                setShowseqs(0)
                                setshowpage(true)
                                setEditproduct(true)
                              }}
                              className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                            >
                              {t("Change")} {mixmatchproduct?.Product?.item_name ? mixmatchproduct?.Product?.item_name : state?.items?.name}
                            </button>
                          }

                        </div>

                        <div className="mix-and-match-engraving-right-box">
                          <div className="mix-and-match-engraving-right-box-head">
                            <label className="mix-and-match-engraving-right-box-head-label">
                              {t("Choose diamond")} :
                            </label>
                            <span className="mix-and-match-engraving-right-box-head-price">
                              {toFormatPrice(
                                mixmatchproduct?.Diamond?.TagPrice,
                                { addSymbol: true },
                              )}
                            </span>
                          </div>
                          <p className="mix-and-match-engraving-right-box-para">
                            {mixmatchproduct?.Diamond?.Carat} Carats
                            {mixmatchproduct?.Diamond?.Shape} -
                            {mixmatchproduct?.Diamond?.Colour} /
                            {mixmatchproduct?.Diamond?.Clarity}
                          </p>
                          {selectestate?.order_edit ?
                            <Link
                              onClick={() => setEditproduct(true)}
                              className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                              to="/app/pos/custom/mixAndMatch"
                              state={{
                                order_edit: selectestate?.order_edit,
                                customcart: customcart,
                                mixmatchedit: mixmatchedit,
                                order_type: selectestate?.order_type,
                                order_id: selectestate?.order_id,
                                daimond_id: selectestate?.daimond_id,
                                items: selectestate?.items ? selectestate?.items : {
                                  id: mixmatchproduct?.Product?.item_id,
                                  name: mixmatchproduct?.Product?.item_name,
                                },
                                Engraving: Engraving ? Engraving : mixmatchproduct?.custom_design,
                                itemchanges: selectestate?.itemchanges ? selectestate?.itemchanges : {
                                  "metal": mixmatchproduct?.Product?.metal,
                                  "stone": mixmatchproduct?.Product?.stone,
                                  "size": mixmatchproduct?.Product?.Size,
                                  "shape": mixmatchproduct?.Product?.shape,
                                  "pointer": mixmatchproduct?.Product?.pointer,
                                  "price": mixmatchproduct?.Product?.price,
                                  "metal_name": mixmatchproduct?.Product?.metal_name,
                                  "size_name": mixmatchproduct?.Product?.size_name
                                },
                                product_id: product_id ? product_id : selectestate?.product_id,
                                optiondetailid: state?.optiondetailid
                                  ? state?.optiondetailid
                                  : Optiondetails,
                                scrvicelist: scrvicelist,
                                scrviceremark: scrviceremark,
                                remark: remark,
                                editproduct: true,

                              }}
                            >
                              {t("Change Diamond")}
                            </Link> :
                            <Link
                              className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                              onClick={() => setEditproduct(true)}
                              to="/app/pos/custom/mixAndMatch"
                              state={{
                                order_edit: selectestate?.order_edit,
                                customcart: customcart,
                                mixmatchedit: mixmatchedit,
                                order_type: selectestate?.order_type,
                                order_id: selectestate?.order_id,
                                daimond_id: selectestate?.daimond_id,
                                items: selectestate?.items,
                                Engraving: Engraving,
                                itemchanges: selectestate?.itemchanges,
                                product_id: product_id ? product_id : selectestate?.product_id,
                                optiondetailid: state?.optiondetailid
                                  ? state?.optiondetailid
                                  : Optiondetails,
                                editproduct: true,
                                scrvicelist: scrvicelist,
                                scrviceremark: scrviceremark,
                                remark: remark
                              }}
                            >
                              {t("Change Diamond")}
                            </Link>
                          }
                        </div>

                        <div className="mix-and-match-engraving-right-box">
                          <div className="mix-and-match-engraving-right-box-head">
                            <label className="mix-and-match-engraving-right-box-head-label">
                              {t("Engraving")} :
                            </label>
                          </div>
                          <div className="mix-and-match-engraving-right-box-tabs">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-bs-toggle="tab"
                                  href="#text"
                                >
                                  {t("Text")}
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-bs-toggle="tab"
                                  href="#logo"
                                >
                                  {t("Logo")}
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                className="tab-pane active mix-and-match-engraving-right-box-tabs-text"
                                id="text"
                              >
                                <span
                                  className="mix-and-match-engraving-right-box-tabs-text-design"
                                  style={{
                                    'fontFamily': Engraving?.font,
                                    'textAlign': Engraving?.engraving_position,
                                  }}
                                >
                                  {Engraving?.engraving_text
                                    ? Engraving?.engraving_text
                                    : mixmatchproduct?.custom_design?.engraving_text}
                                </span>
                                <div className="mix-and-match-engraving-right-box-tabs-text-style">
                                  <div className="mix-and-match-engraving-right-box-tabs-text-style-single">
                                    <label className="mix-and-match-engraving-right-box-tabs-text-style-single-label">
                                      {t("Text")}
                                    </label>
                                    <span className="mix-and-match-engraving-right-box-tabs-text-style-single-data">
                                      {Engraving?.engraving_text
                                        ? Engraving?.engraving_text
                                        : mixmatchproduct?.custom_design?.engraving_text}
                                    </span>
                                  </div>
                                  <div className="mix-and-match-engraving-right-box-tabs-text-style-single">
                                    <label className="mix-and-match-engraving-right-box-tabs-text-style-single-label">
                                      {t("Position")}
                                    </label>
                                    <span className="mix-and-match-engraving-right-box-tabs-text-style-single-data">
                                      {Engraving?.engraving_position
                                        ? Engraving?.engraving_position
                                        : mixmatchproduct?.custom_design?.engraving_position}
                                    </span>
                                  </div>
                                  <div className="mix-and-match-engraving-right-box-tabs-text-style-single">
                                    <label className="mix-and-match-engraving-right-box-tabs-text-style-single-label">
                                      {t("Font")}
                                    </label>
                                    <span className="mix-and-match-engraving-right-box-tabs-text-style-single-data font-family">
                                      {Engraving?.font ? Engraving?.font : mixmatchproduct?.custom_design?.font}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="logo">
                                <img
                                  className="w-50 img-fluid"
                                  src={
                                    Engraving?.engraving_logo
                                      ? Engraving?.engraving_logo
                                      : mixmatchproduct?.custom_design?.engraving_logo
                                  } alt=""
                                />
                              </div>
                            </div>
                          </div>
                          {selectestate?.order_edit ?
                            <button
                              className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                              onClick={() => {
                                setShowseqs(1)
                                setshowcolor(true)
                                setEngraving_edit(true)
                              }}
                            >
                              {t("Change Engraving")}
                            </button>
                            : <button
                              className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                              onClick={() => {
                                setShowseqs(1)
                                setshowcolor(true)
                                setEditproduct(true)
                              }}
                            >
                              {t("Change Engraving")}
                            </button>
                          }
                        </div>
                        <div className={generalsettings?.data?.generalsetup?.servicelabers === '1' ? "mix-and-match-engraving-right-box" : "d-none"}>
                          <div className="mix-and-match-engraving-right-box-head">
                            <label className="mix-and-match-engraving-right-box-head-label">
                              {t("Service Labour")} :
                            </label>
                            <span className="mix-and-match-engraving-right-box-head-price">
                              {
                                toFormatPrice(
                                  scrvicelist?.length
                                    ? scrvicelist?.reduce(
                                      (interation, val) =>
                                      (interation =
                                        interation +
                                        toUnformatPrice(val?.scrviceamount)),
                                      0
                                    )
                                    : mixmatchproduct?.service_labour?.service_laburelist ?
                                      mixmatchproduct?.service_labour?.service_laburelist.reduce(
                                        (interation, val) =>
                                        (interation =
                                          interation +
                                          toUnformatPrice(val?.scrviceamount)),
                                        0
                                      ) : "",
                                  { addSymbol: true })
                              }

                            </span>
                          </div>

                          {
                            mixmatchproduct?.service_labour?.service_laburelist ? mixmatchproduct?.service_labour?.service_laburelist.map((result, key) => {

                              return (
                                <div key={key}>
                                  <div className="mix-and-match-engraving-right-box-head" style={{ "marginBottom": "0.5vw" }}>
                                    <label className="mix-and-match-engraving-right-box-para">
                                      {result?.name} :
                                    </label>
                                    <span className="mix-and-match-engraving-right-box-head-price">
                                      {toFormatPrice(
                                        result?.scrviceamount,
                                        { addSymbol: true },
                                      )}
                                    </span>
                                  </div>

                                </div>
                              )
                            }) :
                              scrvicelist?.length ? scrvicelist.map((result, key) => {

                                return (
                                  <div key={key}>
                                    <div className="mix-and-match-engraving-right-box-head" style={{ "marginBottom": "0.5vw" }}>
                                      <label className="mix-and-match-engraving-right-box-para">
                                        {result?.name} :
                                      </label>
                                      <span className="mix-and-match-engraving-right-box-head-price">
                                        {toFormatPrice(
                                          result?.scrviceamount,
                                          { addSymbol: true },
                                        )}
                                      </span>
                                    </div>

                                  </div>
                                )
                              }) : ""}


                          {selectestate?.order_edit ?
                            <button
                              className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                              onClick={() => {
                                setShowseqs(2);
                                setScrvicecharge(scrvicecharge);
                                setService_saved(true)
                                setServicechange(true)

                              }}
                            >
                              {t("Change Service")}
                            </button>
                            : <button
                              className="mix-and-match-engraving-right-box-switch-step-btn btn-style text-decoration-none"
                              onClick={() => {
                                setShowseqs(2);
                                setScrvicecharge(scrvicecharge);
                                setEditproduct(true)
                                setServicechange(true)
                              }}
                            >
                              {t("Change Service")}
                            </button>
                          }


                        </div>
                        <div className="mix-and-match-engraving-right-footer">
                          <div className="mix-and-match-engraving-right-footer-total">
                            <label className="mix-and-match-engraving-right-footer-total-label">
                              {t("Total")}
                            </label>
                            <span className="mix-and-match-engraving-right-footer-total-price">
                              {toFormatPrice(
                                parseInt(mixmatchproduct?.Diamond?.TagPrice) +
                                parseInt(
                                  toUnformatPrice(
                                    selectestate?.itemchanges ? selectestate?.itemchanges?.price
                                      :
                                      mixmatchproduct?.Product?.price)
                                ) +
                                (scrvicelist?.length ? scrvicelist?.reduce(
                                  (interation, val) =>
                                  (interation =
                                    parseInt(interation +
                                      toUnformatPrice(val?.scrviceamount))),
                                  0
                                ) : 0),
                                { addSymbol: true },
                              )}
                            </span>
                          </div>
                          <div
                            className={

                              showbutton ? 'border-inner-btn hover-effect'
                                : 'border-inner-btn hover-effect btn-disabled'
                            }
                          >
                            <button
                              className={
                                addtocartstatus
                                  ? 'add-to-cart'
                                  : 'add-to-cart pe-none'
                              }
                              onClick={() => addmixmatch()}
                            >
                              {t("ADD TO CART")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mix-and-match-engraving-tab-area">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#productDetails"
                          >
                            {t("Product Details")}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#DiamondDetails"
                          >
                            {t("Diamond Details")}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active " id="productDetails">
                          <div className="mix-and-match-engraving-tab-area-text-col">
                            <div className="mix-and-match-engraving-tab-area-text-col-single">
                              <label className="mix-and-match-engraving-tab-area-text-col-single-label">
                                {t("Description")}
                              </label>
                              <p className="mix-and-match-engraving-tab-area-text-col-single-para">
                                {mixmatchproduct?.Product?.Description}
                              </p>
                            </div>
                            <div className="mix-and-match-engraving-tab-area-text-col-single mid-col">
                              <div className="mid-col-row">
                                <label className="mix-and-match-engraving-tab-area-text-col-single-label">
                                  {t("Metal")}
                                </label>
                                <span className="mix-and-match-engraving-tab-area-text-col-single-data">

                                  {mixmatchproduct?.Product?.metal_name}
                                </span>
                              </div>
                              <div className="mid-col-row">
                                <label className="mix-and-match-engraving-tab-area-text-col-single-label">
                                  {t("Stone")}
                                </label>
                                <span className="mix-and-match-engraving-tab-area-text-col-single-data">
                                  {mixmatchproduct?.Product?.stone_name} Ct
                                </span>
                              </div>
                              <div className="mid-col-row">
                                <label className="mix-and-match-engraving-tab-area-text-col-single-label">
                                  {t("Reference Number")}
                                </label>
                                <span className="mix-and-match-engraving-tab-area-text-col-single-label"></span>
                              </div>
                            </div>
                            <div className="mix-and-match-engraving-tab-area-text-col-single">
                              <label className="mix-and-match-engraving-tab-area-text-col-single-label">
                                {t("Details")}
                              </label>
                              <p className="mix-and-match-engraving-tab-area-text-col-single-para">
                                {mixmatchproduct?.Product?.Specification}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="DiamondDetails">
                          <div className="diamond-detail-section-diamond-detail">
                            <div className="diamond-detail-section-diamond-detail-fieldset border-0 mt-0 p-0">
                              <ul className="diamond-detail-section-diamond-detail-fieldset-ul p-0">
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Stock ID")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.StockID}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Shape")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Shape}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Carat")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Carat}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Colour")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Colour}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Clarity")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Clarity}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Cut")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Cut}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                              <ul className="diamond-detail-section-diamond-detail-fieldset-ul p-0">
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Polish")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Polish}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Symmetry")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Symmetry}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Fluorescence")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Fluoresence}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      t{("Depth")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Depth}%
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Table")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Table}%
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Girdle")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Gridle}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                              <ul className="diamond-detail-section-diamond-detail-fieldset-ul p-0">
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Culet")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Culet}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Measurements")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Measurement}mm
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Graded By")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.GradedBy}
                                    </span>
                                  </div>
                                </li>
                                <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                                  <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                                      {t("Certification")}
                                    </span>
                                    <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                                      {mixmatchproduct?.Diamond?.Certification}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : ""}
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                UpdatedCart={UpdatedCart}
                setsearchproductstatus={setsearchproductstatus}
                ordertype={'mix_and_match'}
                searchproductstatus={searchproductstatus}
                order_id={selectestate?.order_id}
                order_edit={selectestate?.order_edit}
                order_type={selectestate?.order_type}
                mixmatchedit={selectestate?.mixmatchedit}
                setCustomcart={setCustomcart}
                scrvicelistmixmatch={scrvicelist}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                order_id={selectestate?.order_id}
                order_edit={selectestate?.order_edit}
                order_type={selectestate?.order_type}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MixAndMatchProduct;
