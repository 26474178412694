import React, { useEffect, useState } from 'react'
import RightArrow from '../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { _Api } from "../api/_call";
import { loading, Updateloading } from "../common/helpers/toastify";
const Integratedapplications = () => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { t } = useTranslation();
    const [editItem, setEditItem] = useState(false)
    const [completeDataObject, setCompliteDataObject] = useState()
    const [listdata, setListdata] = useState()
    const [savedata, setSavedata] = useState(false)
    const getapplicationlist = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `api/v1/App/applicationkey/get-credentials`)
            if (res?.code === 200) {
                setListdata(res?.data)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getapplicationlist()
    }, [])
    const viewapplication = async (event, data, keys) => {
        try {
            let array = [...listdata]
            if (event) {
                let postdata = { "id": data?._id }
                let res = await _Api(postdata, `api/v1/App/applicationkey/view-credentials`)
                if (res?.code === 200) {
                    setCompliteDataObject(res?.data)
                    array[keys].status = 1
                    setEditItem(true)
                    setCompliteDataObject(array[keys])
                }
            } else {
                array[keys].status = 0
                let editdata = { ...data }
                delete editdata?.defaultId
                setCompliteDataObject(editdata)
                setSavedata(true)
            }
            setListdata(array)
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const editapplication = async (completeData) => {
        const finaldata = { ...completeData }
        if (!finaldata?.logo) {
            finaldata.logo = "https://gis247.s3.amazonaws.com/2023in0076/User/profile/1713524909925.png"
        }
        finaldata.id = completeData?._id;
        delete finaldata._id;
        try {
            let GetloadingID = loading()
            let postdata = finaldata
            let res = await _Api(postdata, `api/v1/App/applicationkey/edit-credentials`)
            if (res?.code === 200) {
                setSavedata(false)
                Updateloading(t, GetloadingID, (res?.message))
                setEditItem(false)
            } else {
                setSavedata(false)
                if (res?.message) {
                    Updateloading(t, GetloadingID, (res?.message), 'error');
                } else {
                    Updateloading(t, GetloadingID, res?.errors, 'error');
                }

            }
            getapplicationlist()
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        if (savedata) {
            editapplication(completeDataObject)
        }
        // eslint-disable-next-line
    }, [savedata])
    return (
        <>
            <div className="main-content-wrapper-body">
                <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
                    <div className="main-content-wrapper-body-top-bar-left">
                        <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                            <Link className="text-decoration-none" to="/app/admin/settings">{t("Setting")}</Link>  <RightArrow /> <span className="">{t("Integrated Applications")}</span>
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className={editItem ? 'col-md-6' : 'col-md-12'}>
                        <div className="main-body-main-table-wrap com-custom-table table-responsive position-relative">
                            <table id="my_customer" className="com-custom-table-tag table align-middle">
                                <thead>
                                    <tr className="text-nowrap align-middle">
                                        <th>#</th>
                                        <th>{t("Image")}</th>
                                        <th>{t("Name")}</th>
                                        <th>{t("Type")}</th>
                                        <th>{t("Article")}</th>
                                        <th>{t("Action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listdata?.length && listdata.map((result, key) => (
                                        <tr className="text-nowrap" key={key}>
                                            <td>{key + 1}</td>
                                            <td className="px-2"><img className="table-user-img rounded-circle" src={result?.logo} alt="" /></td>
                                            <td>{result?.status ? <span onClick={(e) => viewapplication(true, result, key)} className='link-color cursor-pointer'>{result?.name}</span> : <span>{result?.name}</span>}</td>
                                            <td>{result?.type}</td>
                                            <td>{result?.instructions ? <a href={result?.instructions} target='_blank' rel='noopener noreferrer'> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none"><path id="Vector" d="M20.7778 24.7218H1.22222C0.898069 24.7218 0.587192 24.593 0.357981 24.3638C0.128769 24.1346 0 23.8237 0 23.4996V1.49957C0 1.17541 0.128769 0.864535 0.357981 0.635324C0.587192 0.406113 0.898069 0.277344 1.22222 0.277344H20.7778C21.1019 0.277344 21.4128 0.406113 21.642 0.635324C21.8712 0.864535 22 1.17541 22 1.49957V23.4996C22 23.8237 21.8712 24.1346 21.642 24.3638C21.4128 24.593 21.1019 24.7218 20.7778 24.7218ZM19.5556 22.2773V2.72179H2.44444V22.2773H19.5556ZM4.88889 5.16623H9.77778V10.0551H4.88889V5.16623ZM4.88889 12.4996H17.1111V14.944H4.88889V12.4996ZM4.88889 17.3885H17.1111V19.8329H4.88889V17.3885ZM12.2222 6.38845H17.1111V8.8329H12.2222V6.38845Z" fill="#606060" /></svg></a> : ""}</td>
                                            <td><div className="col-md-3 pe-0">
                                                <div className="form-check form-switch ps-0">
                                                    <label className="form-check-label" htmlFor="mixmatch"></label>
                                                    <input
                                                        className="form-check-input float-none ms-2"
                                                        type="checkbox"
                                                        id="mixmatch"
                                                        checked={result?.status}
                                                        onChange={(e) => viewapplication(e.target.checked, result, key)}
                                                    />
                                                </div>
                                            </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={editItem ? 'col-md-6 d-block' : 'd-none'}>
                        <div className="edit-item-area bg-white row">
                            <div className="col-md-9 px-0">
                                <div className="edit-item-area-body">
                                    <div className="edit-item-area-body-circle-img text-center mb-4">
                                        <div className="edit-item-area-body-circle-img-choose">
                                            <input
                                                id="chooseImg"
                                                type="file"
                                                className="d-none"
                                                accept="image/*"
                                            />
                                            <label
                                                htmlFor="chooseImg"
                                                className="rounded-circle overflow-hidden"
                                            >
                                                <img
                                                    className=""
                                                    src={
                                                        completeDataObject?.logo
                                                            ? completeDataObject?.logo
                                                            : base_assets + 'images/blank-img-2.png'
                                                    }
                                                    alt=""
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 ps-0">
                                            <div className="row mx-0">
                                                <div className="col-md-12">
                                                    {completeDataObject?.credentials_Keys?.length ?
                                                        completeDataObject?.credentials_Keys.map((result, key) => {
                                                            return (
                                                                <div className="v3-input-group" key={key}>
                                                                    <label className="v3-input-group-label">
                                                                        {result?.key}<sup>*</sup>
                                                                    </label>
                                                                    <input
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value;
                                                                            setCompliteDataObject((state) => ({
                                                                                ...state,
                                                                                credentials_Keys: state.credentials_Keys.map((item, index) => {
                                                                                    if (index === key) {
                                                                                        return {
                                                                                            ...item,
                                                                                            value: newValue
                                                                                        };
                                                                                    } else {
                                                                                        return item;
                                                                                    }
                                                                                })
                                                                            }));
                                                                        }}
                                                                        value={result?.value}
                                                                        type="text"
                                                                        className="v3-input-group-input w-100"
                                                                    />
                                                                </div>
                                                            )
                                                        }) : ""}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 pe-0">
                                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                                    <button
                                        className="square-btn square-btn-white btn-close focus-none"
                                        onClick={() => { setEditItem(false); getapplicationlist() }}
                                    ></button>
                                    <button
                                        className="square-btn"
                                        onClick={() => setSavedata(true)}
                                    >
                                        <img
                                            src={base_assets + 'images/icons/white-save.png'}
                                            alt=""
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Integratedapplications
