import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminLocation from '../admin/location/Location';
import AdminLocationDetail from '../admin/location/LocationDetail';
export default function LocationRoutes() {
    return (
        <Routes>
            <Route exact path='/' element={<AdminLocation />} > </Route>
            <Route exact path='detail' element={<AdminLocationDetail />} > </Route>
            <Route exact path='detail/:token' element={<AdminLocationDetail />} > </Route>
        </Routes>
    )
} 