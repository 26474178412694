import EyeShow from 'Ecommerce/template_three/assets/icons/EyeShow';
import Sidebar from './Sidebar'
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import { _Api } from 'Ecommerce/common/api';
import PasswordHide from 'Ecommerce/template_three/assets/icons/PasswordHide';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';
import LeftIcon from 'admin/common/icons/sidebar/LeftIcon';
import EditIcon from 'admin/common/icons/EditIcon';

export default function AccountChangePassword() {
  const [changepassworddata, setChanepassworddata] = useState()
    const [showPassword, setShowPassword] = useState();
    const [validaction, setValidaction] = useState();
    const navigate = useNavigate();
    const screenSize = useScreenSize();
    const [editValues, setEditValues] = useState(false);
    const { t } = useTranslation();
    const setdata = (keyname, value) => {
        setChanepassworddata((state) => ({
            ...state,
            [keyname]: value
        }))
    }
    const getchangepassword = async () => {
        const valid = () => { 
            let fromisvalid = true
            if (!changepassworddata?.oldpassword) {
                setValidaction((state) => ({
                    ...state,
                    oldpassword: "Old password is required"
                }))
                fromisvalid = false
            } else {
                setValidaction((state) => ({
                    ...state,
                    oldpassword: ""
                }))
            }
            if (!changepassworddata?.newpassword) {
                setValidaction((state) => ({
                    ...state,
                    newpassword: "Password is required"
                }))
                fromisvalid = false
            } else {
                setValidaction((state) => ({
                    ...state,
                    newpassword: ""
                }))
            }
            if (!changepassworddata?.password) {
                setValidaction((state) => ({
                    ...state,
                    password: "Confirm Password is required"
                }))
                fromisvalid = false
            } else {
                setValidaction((state) => ({
                    ...state,
                    password: ""
                }))
            }
            return fromisvalid;
        }
        if (valid()) {
            if (changepassworddata?.password === changepassworddata?.newpassword) {
                try {
                    let postdata = {
                        "oldpassword": changepassworddata?.oldpassword,
                        "password": changepassworddata?.password
                    }
                    let res = await _Api(postdata, `account/reset-password`)
                    if (res?.statusCode === 200) {
                        setValidaction((state) => ({
                            ...state,
                            sussesmessage: res?.message,
                            errormessage: ""
                        }))
                        navigate("/account")
                    }
                }
                catch (err) {
                    console.log(err, "err")
                    setValidaction(prevState => ({
                        ...prevState,
                        errormessage: err.response.data.message,
                        sussesmessage: "",

                    }));
                }
            } else {
                setValidaction((state) => ({
                    ...state,
                    errormessage: "Password Not Match"
                }))
            }
        }
    }
    const togglePasswordVisibility = (label) => {
        if (label === "oldpassword") {
            setShowPassword((state) => ({
                ...state,
                oldpassword: !showPassword?.oldpassword
            }))
        } else if (label === "newpassword") {
            setShowPassword((state) => ({
                ...state,
                newpassword: !showPassword?.newpassword
            }))
        } else if (label === "password") {
            setShowPassword((state) => ({
                ...state,
                password: !showPassword?.password
            }))
        }

    }
  return (
    <div className="account_page d-flex">
    <Sidebar/>
    <div className="account_details">
    {screenSize.width < 525 ?
        <div className="account_details_topbar">
            <div className='account_details_topbar-icon' onClick={()=>navigate('/account')}>
              <LeftIcon/>
            </div>
            <span>{t("Change Password")}</span>
        </div>
        :null}
        {screenSize.width < 1200 && screenSize.width > 525 ?
        <div className="account_details_desktoptopbar">
            <div className='account_details_desktoptopbar-icon' onClick={()=>navigate('/account')}>
              <LeftIcon/>
            </div>
            <span>{t("Change Password")}</span>
        </div>
        :null}
                <div className='changePassword-container'>
                    <div className='changePassword-container-header'>
                      {t("Change Password")}
                      <div className='changePassword-container-header-subheading'>{t("change your password")}</div>
                      </div>
                    <div className='changePassword-container-mainBody'>
                       {screenSize.width > 1200 ?  <div className={`ms-auto btnbold sm ${editValues ? 'disablebtn' : ''}`} aria-disabled={editValues ? true : false} onClick={()=>setEditValues(true)}><EditIcon/><span>{t("Edit")}</span></div> : null}
                        <div className='changePassword-container-mainBody-allPasswordBox'>
                            <div className='changePassword-container-mainBody-allPasswordBox-singlePass '>
                                <span className='changePassword-container-mainBody-allPasswordBox-singlePass-subHeading'>{t("Old password")} <span className='com-starShow'>*</span></span>
                                <div className=
                                    {validaction?.oldpassword ? 'ecom-formInputBox border-danger' : "ecom-formInputBox"
                                    }>
                                    <input className={`ecom-formInputBox-input ${validaction?.oldpassword ? 'border-danger' : ''}`}
                                        type={showPassword?.oldpassword ? 'text' : 'password'}
                                        placeholder='Enter Current Password'
                                        value={changepassworddata?.oldpassword}
                                        onChange={(e) => setdata("oldpassword", e.target.value)} 
                                        disabled={editValues ? false : true}
                                        />
                                    <span className='ecom-formInputBox-inputrightIcon' onClick={() => togglePasswordVisibility("oldpassword")}><EyeShow width={24} height={24} /></span>
                                </div>
                                {validaction?.oldpassword ?
                                    <p className='text-danger'>{validaction?.oldpassword}</p>
                                    : ""}
                            </div>
                            <div className='changePassword-container-mainBody-allPasswordBox-singlePass '>
                                <span className='changePassword-container-mainBody-allPasswordBox-singlePass-subHeading'>{t("New Password")} <span className='com-starShow'>*</span></span>
                                <div className="ecom-formInputBox">
                                    <input
                                        className={`ecom-formInputBox-input ${validaction?.newpassword ? 'border-danger' : ''}`}
                                        type={showPassword?.newpassword ? 'text' : 'password'}
                                        placeholder='Enter New Password'
                                        value={changepassworddata?.newpassword}
                                        onChange={(e) => setdata("newpassword", e.target.value)} disabled={editValues ? false : true}/>
                                    <span className='ecom-formInputBox-inputrightIcon' onClick={() => togglePasswordVisibility("newpassword")}>   <EyeShow width={24} height={24}/></span>
                                </div>
                                {validaction?.newpassword ?
                                    <p className='text-danger'>{validaction?.newpassword}</p>
                                    : ""}
                            </div>
                            <div className='changePassword-container-mainBody-allPasswordBox-singlePass '>
                                <span className='changePassword-container-mainBody-allPasswordBox-singlePass-subHeading'>{t("Confirm Password")} <span className='com-starShow'>*</span></span>
                                <div className="ecom-formInputBox ">
                                    <input
                                        className={`ecom-formInputBox-input ${validaction?.password ? 'border-danger' : ''}`}
                                        type={showPassword?.password ? 'text' : 'password'}
                                        placeholder='Enter Confirm Password'
                                        value={changepassworddata?.password}
                                        onChange={(e) => setdata("password", e.target.value)}
                                        disabled={editValues ? false : true}
                                    /> <span className='ecom-formInputBox-inputrightIcon' onClick={() => togglePasswordVisibility("password")}><EyeShow width={24} height={24} /></span>
                                </div>
                                {validaction?.password ?
                                    <p className='text-danger'>{validaction?.password}</p>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    {validaction?.errormessage ?
                        <div className='text-start mt-2 text-danger'>{t(`${validaction?.errormessage}`)}</div>
                        : validaction?.sussesmessage ?
                            <div className='text-start mt-2 spinnercolor'>{t(`${validaction?.sussesmessage}`)}</div> : ""
                    }
                    {screenSize.width > 1200 ? 
                    <div className='changePassword-container-mainBody-changePassSaveBox'>
                        <button className='btnwithborder smround' onClick={() => setEditValues(false)}>{t("Cancel")}</button>
                        <button className='btnbold smround' onClick={() => getchangepassword()}>{t("Save")}</button>
                    </div>
                    : 
                    <div className='changePassword-container-mainBody-changePassSaveBox'>
                      {editValues ? 
                       <button className='btnbold smround' onClick={() => getchangepassword()}>{t("Save")}</button>
                        :
                        <div className={`m-auto btnbold sm ${editValues ? 'disablebtn' : ''}`} aria-disabled={editValues ? true : false} onClick={()=>setEditValues(true)}><EditIcon/><span>{t("Edit")}</span></div>
                        }
                      </div>
                    }
                </div>
            </div>
    </div>
  )
}
