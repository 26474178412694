import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Closeregistermodal = () => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  return (
    <div className="common-modal closecash-danger" id="cashregistermodal">
      <div className="modal-dialog modal-dialog-centered closecash-danger-dialog">
        <div className="modal-content pt-3 px-0 pb-3">
          <div className="modal-body">
            <img
              className="common-modal-exclamation-img"
              src={base_assets + 'images/icons/exclamation-mark-red.png'}
              alt=""
            />
            <p className="modal-content-text">
              {t('Your Cash Register is currently Open')}{' '}
            </p>
          </div>
          <div className="modal-footer">
            <Link to="/app/pos/menu">
              <button type="button" className="btn modal-content-no">
                {t('Cancel')}
              </button>
            </Link>

            <Link to="/app/pos/Cashregister">
              {' '}
              <button type="button" className="btn modal-content-yes">
                {t('CLOSE CASH REGISTER')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Closeregistermodal
