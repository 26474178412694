import React, { useState, useEffect } from "react"
import { _Api } from '../api/_call'
import TableColumnModal from '../common/modules/tableColumn'
import { Tablehead } from '../common/modules/Tablehead'
import DateTime from '../common/DateTime'
import { reportlowstocklisthead } from '../common/Tablehead'
import { SetdataTable, UnSetdataTable } from '../api/setdatatable'
import 'datatables.net-fixedcolumns'
import { ReportMenuModal } from '../common/modules/reportMenu'
import { DateRange } from '../common/helpers/daterange'
import SocialModal from '../common/modules/SocialModal/SocialModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  loading,
  Updateloading,
} from '../common/helpers/toastify'
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import Select from "react-select";
const Lowstockreport = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [filtername, setFiltername] = useState('')
  const [limit, setLimit] = useState(100)
  const [rowlength, setRowlength] = useState(0)
  const [datefilter, setDatefilter] = useState([])
  const [reportlowstocklist, setReportlowstocklist] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(reportlowstocklisthead)
  const [socialurl, setSocialurl] = useState()
  const [reportlowstockcounts, setReportlowstockcounts] = useState([])
  const [totalresult, setTotalresult] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const filterbyname = (search) => {
    if (filtername) {
      Getreportlowstock(limit, search)
    } else {
      Getreportlowstock(limit, search)
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const getsharelowstockplanning = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/lowstock`)

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getprintlowstockplanning = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/lowstock`)
      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const Getreportlowstock = async (firstlimit, search) => {
    UnSetdataTable('report_lowstock')
    setDatsLodaer(true)
    try {
      const postdata = {
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        skip: 0,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/Inventory/report/lowStock`)
      if (res.code === 200) {
        setReportlowstocklist(res?.data)
        setRowlength(0)
        setTimeout(() => {
          SetdataTable('report_lowstock', 4, newcolumndata, [0])
        }, 50)
        setReportlowstockcounts(res?.count)
        setTotalresult(res?.total);
        setDatsLodaer(false)
      }
    } catch (err) {
      SetdataTable('report_lowstock', 4, newcolumndata, [0])
      console.log(err, 'err')
    }
  }
  const fetchMoreData = () => {
    if (totalresult !== reportlowstocklist?.length) {
      var len = rowlength + parseInt(limit)
      setRowlength(len)
      UnSetdataTable('report_lowstock')
      setTimeout(async () => {
        try {
          const posdata = {
            search: filtername,
            limit: limit,
            skip: len,
            start_date: datefilter ? datefilter[0] : '',
            end_date: datefilter ? datefilter[1] : '',
          }
          let res = await _Api(posdata, `api/v1/Inventory/report/lowStock`)
          if (res.code === 200) {
            setTimeout(() => {
              SetdataTable('report_lowstock', 4, newcolumndata, [0])
            }, 50)
            setReportlowstocklist(res.data)
          }
        } catch (err) {
          console.log(err, 'err')
        }
      }, 1000)
    } else {
      UnSetdataTable('report_lowstock')
      setTimeout(() => {
        SetdataTable('report_lowstock', 4, newcolumndata, [0])
      }, 50)
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_report_lowstockplan',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Getreportlowstock()
    }
    // eslint-disable-next-line
  }, [datefilter])
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    reportlowstocklisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (reportlowstocklist.length) {
      reportlowstocklist.forEach((result, key) => {
        bodyarray.push([key + 1, result?.date, result?.ls_no, result?.supplier_name, result?.stores, result?.type, result?.SKUcount, result?.user, result?.stock, result?.sales_order, result?.available, result?.reorderpoint, result?.reorderquantity, result?.created_by])
      })
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "report_lowstock");
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <React.Fragment>
      <div className="main-body report-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h2 className="main-body-heading">
                <span>
                  {t('report')} {'>'}
                </span>
                &nbsp;&nbsp;{t('Low Stock Planning')}
              </h2>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/free-stock.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Total Qty')}</span>
                      <h2 className="inventory-value stock">
                        {reportlowstockcounts?.totalStock}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/sku-red.png'}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Sale Order')}</span>
                      <h2 className="inventory-value sku-red">
                        {reportlowstockcounts?.totalSalesOrder}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/Available.png'}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Available')}</span>
                      <h2 className="inventory-value grsummary-text">
                        {reportlowstockcounts?.available}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/value-green.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">
                        {t('Reorder Quantity')}
                      </span>
                      <h2 className="inventory-value value">
                        {reportlowstockcounts?.totalRQ}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <ReportMenuModal />
                <Select
                  className="limit-select short z-10"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); Getreportlowstock(e.value); setRowlength(0) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    value={filtername}
                    type="text"
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('Search Ref No.')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className={parmissiondatashow?.inventory_reports?.Print ? "dropdown-item print-dropdown-item" : "d-none"}
                        onClick={(e) => {
                          getprintlowstockplanning(e.target.value)
                        }}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li className="dropdown-item" onClick={downloadexclsheet}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>

                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#socialModal"
                        onClick={() => getsharelowstockplanning()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/share-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('share')}</span>
                      </li>

                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <InfiniteScroll
                  dataLength={
                    reportlowstocklist ? reportlowstocklist?.length : ''
                  }
                  next={totalresult > rowlength ? fetchMoreData : ''}
                  hasMore={totalresult > rowlength ? true : false}
                  loader={
                    reportlowstocklist?.length !== totalresult ? (
                      totalresult > rowlength ? (
                        <h6>{t('Loading more items...')}</h6>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )
                  }
                >
                  <table
                    id="report_lowstock"
                    className="stripe row-border order-column common-table table-striped main-table w-100"
                  >
                    <Tablehead
                      tablehead={reportlowstocklisthead}
                      tblName={'tbl_report_lowstockplan'}
                      setfilterby={""}
                    />
                    <tbody>
                      <>
                        {reportlowstocklist.length
                          ? reportlowstocklist.map((result, key) => {
                            return (
                              <tr className="" key={key}>
                                <td className="fixed-side">{key + 1}</td>
                                <td className="fixed-side">{result?.date}</td>
                                <td className="fixed-side">{result?.ls_no}</td>
                                <td className="fixed-side">
                                  {result?.supplier_name}
                                </td>
                                <td className="fixed-side">{result?.stores}</td>
                                <td className="fixed-side">{result?.type}</td>
                                <td className="fixed-side">
                                  {result?.SKUcount}
                                </td>
                                <td className="fixed-side">{result?.user}</td>
                                <td className="fixed-side light-blue-text">
                                  {result?.stock}
                                </td>
                                <td className="fixed-side danger-text">
                                  {result?.sales_order}
                                </td>
                                <td className="fixed-side main-green-text">
                                  {result?.available}
                                </td>
                                <td className="fixed-side yellow-text">
                                  {result?.reorderpoint}
                                </td>
                                <td className="fixed-side status-green-text">
                                  {result?.reorderquantity}
                                </td>
                                <td className="fixed-side">-</td>
                              </tr>
                            )
                          })
                          : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "mt-4" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
      </div>
      <TableColumnModal
        tablehead={reportlowstocklisthead}
        tblName={'tbl_report_lowstockplan'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={Getreportlowstock}
      ></TableColumnModal>
    </React.Fragment>
  )
}
export default Lowstockreport
