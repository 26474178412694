import React, { useState, useEffect } from 'react'
import DateTime from '../common/DateTime'
import { Link, useLocation } from 'react-router-dom'
import { _Api } from '../api/_call'
import { success } from '../common/helpers/toastify'
import DeleteModal from '../common/modules/deletemodal'
import { formatDate } from '../common/helpers/formatDate'
import { useTranslation } from 'react-i18next';
const DetailDiamond = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { state } = useLocation()
  const [details, setDetails] = useState()
  const getdetails = async () => {
    try {
      const posdata = { id: state.daimondid }
      let res = await _Api(posdata, `api/v1/Inventory/diamond/getDetails`)
      if (res.code === 200) {
        setDetails(res.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const deletereserve = async () => {
    try {
      const posdata = { id: state.daimondid }
      let res = await _Api(posdata, `api/v1/Inventory/diamond/removeReserve`)
      if (res.code === 200) {
        success(t('Successfully Removed'))
        setDetails((prevState) => ({
          ...prevState,
          Status: 1,
        }))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (state.daimondid) {
      getdetails()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left mb-3">
                  <div className="page-back-heading">
                    <Link
                      to="/app/inventory/diamondSearch"
                      className="page-back-btn"
                    >
                      <img
                        src="/assets/v2/images/icons/arrow-left.png"
                        alt="back"
                      />
                      <h2 className="head-name">{t('Diamond Details')}</h2>
                    </Link>
                  </div>
                </div>
                <div className="top-main-body">
                  <div className="top-main-body-inner">
                    <div className="top-head">
                      <div className="left-part top-head-left">
                        <img
                          src={details?.image[0]}
                          className="left-part-pro-img top-head-left-img"
                          alt="no-img"
                        />
                      </div>
                      <div className="right-part">
                        <h5 className="title">
                          {details?.Carat} {t('Carats')} {details?.Shape} -
                          {details?.Colour} / {details?.Clarity}
                        </h5>
                        <p className="sub-title">
                          {details?.Carat} {t('Carats')}, {details?.Colour}
                          {t('Colour')}, {details?.Clarity} {t('Clarity')}
                          {details?.Shape} ,{details?.Cut} {t('Cut')}
                        </p>
                        <div className="gia-report">
                          <div className="left-part">
                            <p className="left-part-title">
                              <img
                                src="/assets/v2/images/icons/gia.png"
                                className=""
                                alt="no-img"
                              />
                              {details?.GradedBy} {t('Report')}
                            </p>
                            {details?.CertificationUr ?
                              <a
                                href={details?.CertificationUrl}
                                target="_blank"
                                className="left-part-link link-color"
                                rel="noreferrer"
                              >
                                {details?.Certification}
                                &nbsp;
                                <img
                                  src="/assets/v2/images/icons/exp-view.png"
                                  className=""
                                  alt="no-img"
                                />
                              </a> :
                              <span>{details?.Certification}</span>}

                          </div>
                          <div className="right-part">
                            <span
                              className={
                                details?.Status === 1
                                  ? 'status-color light-blue tooltip-area-up'
                                  : details?.Status === 2
                                    ? 'status-color light-yellow-bg tooltip-area-up'
                                    : details?.Status === 3
                                      ? 'status-color bg-danger tooltip-area-up'
                                      : 'status-color bg-main-green tooltip-area-up'
                              }
                            >
                              {details?.reserveDate ? (
                                <span className="tooltip-area-up-text">

                                  {details?.customer} |
                                  {formatDate(details?.reserveDate)}
                                </span>
                              ) : details?.Status === 1 ? (
                                <span className="tooltip-area-up-text">
                                  {t('Stock')}
                                </span>
                              ) : details?.Status === 3 ? (
                                <span className="tooltip-area-up-text">
                                  {t('Custom Order')}
                                </span>
                              ) : details?.Status === 4 ? (
                                <span className="tooltip-area-up-text">
                                  {t('Transit')}
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="gia-location">
                          <h6 className="gia-location-price">

                            {details?.Price}
                          </h6>

                          <p className="gia-location-place">
                            <img
                              src="/assets/v2/images/icons/location-green.png"
                              className=""
                              alt="no-img"
                            />
                            &nbsp;
                            {details?.Location}
                          </p>
                        </div>
                        <div className="position-relative">
                          <div
                            className={
                              details?.Status === 1
                                ? 'border-inner-btn hover-effect'
                                : ' border-inner-btn hover-effect reserve-btn-disable'
                            }
                          >
                            <Link
                              to={'/app/inventory/createReserve'}
                              state={{ daimondid: state?.daimondid }}
                              className={
                                details?.Status === 1
                                  ? 'reserve-btn button'
                                  : 'reserve-btn button reserve-btn-disable'
                              }
                            >
                              <span>{t('RESERVE')}</span>
                            </Link>
                          </div>
                          {details?.Status === 1 ? (
                            ''
                          ) : (
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#delete-listedModal"
                              className="delete-row-btn"
                            >
                              <img
                                src={
                                  base_assets + 'images/icons/delete-icon.png'
                                }
                                alt=""
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="top-data">
                      <div className="top-data-inner">
                        <fieldset>
                          <legend>{t('Diamond Details')}:</legend>
                          <div className="maintable-box">
                            <div className="maintable-box-inner">
                              <div className="diamond-table">
                                <div className="diamond-table-data">
                                  <div className="row-data">
                                    <div className="name">{t('Stock Id')}</div>
                                    <div className="vale">
                                      {details?.StockID}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Shape')}</div>
                                    <div className="vale">{details?.Shape}</div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Carat')}</div>
                                    <div className="vale">{details?.Carat}</div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Colour')}</div>
                                    <div className="vale">
                                      {details?.Colour}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Clarity')}</div>
                                    <div className="vale">
                                      {details?.Clarity}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Cut')}</div>
                                    <div className="vale">{details?.Cut}</div>
                                  </div>
                                </div>
                                <div className="diamond-table-data">
                                  <div className="row-data">
                                    <div className="name">{t('Polish')}</div>
                                    <div className="vale">
                                      {details?.Polish}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Symmetry')}</div>
                                    <div className="vale">
                                      {details?.Symmetry}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">
                                      {t('Fluorescence')}
                                    </div>
                                    <div className="vale">
                                      {details?.Fluoresence}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Depth')}</div>
                                    <div className="vale">{details?.Depth}</div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Table')}</div>
                                    <div className="vale">{details?.Table}</div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Gridle')}</div>
                                    <div className="vale">
                                      {details?.Gridle}
                                    </div>
                                  </div>
                                </div>
                                <div className="diamond-table-data">
                                  <div className="row-data">
                                    <div className="name">{t('Culet')}</div>
                                    <div className="vale">{details?.Culet}</div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">
                                      {t('Measurements')}
                                    </div>
                                    <div className="vale">
                                      {details?.Measurement}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">{t('Graded By')}</div>
                                    <div className="vale">
                                      {details?.GradedBy}
                                    </div>
                                  </div>
                                  <div className="row-data">
                                    <div className="name">
                                      {t('Certification')}
                                    </div>
                                    <div className="vale">
                                      {details?.Certification}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        statement="Reserve Daimond"
        handler_delete={deletereserve}
      ></DeleteModal>
    </>
  )
}

export default DetailDiamond;
