import React from 'react'

export default function PlusIcon(props) {
    const { width, height, color, type } = props;
    return (
        <>
        { type === 'doublelayered' ?

            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 8.33334C17.5 6.95264 16.3807 5.83334 15 5.83334H8.33333C6.95258 5.83334 5.83333 6.95264 5.83333 8.33334V15C5.83333 16.3808 6.95258 17.5 8.33333 17.5H15C16.3807 17.5 17.5 16.3808 17.5 15V8.33334Z" stroke="#A4A4A4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6667 11.6667V9.16666M11.6667 11.6667V14.1667M11.6667 11.6667H9.16669M11.6667 11.6667H14.1667" stroke="#A4A4A4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1667 2.5H5.00002C3.61927 2.5 2.50002 3.61929 2.50002 5V14.1667" stroke="#A4A4A4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        :
        <svg width={width ? width : "16"} height={height ? height : "16"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23 13C23 13.5523 22.5523 14 22 14H2C1.44772 14 1 13.5523 1 13C1 12.4477 1.44772 12 2 12H22C22.5523 12 23 12.4477 23 13Z" fill={color ? color : "#292D32"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 24C11.4477 24 11 23.5523 11 23L11 3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3L13 23C13 23.5523 12.5523 24 12 24Z" fill={color ? color : "#292D32"} />
        </svg>
        }</>
    )
}
