import React, { useRef, useState, useEffect, useCallback } from 'react'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const LifeStage = (props) => {
    const dataFetched = useRef();
    const { setDashboardhideshow, setDefaultset } = props
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [fixed, setFixed] = useState(false)
    const [lifestagedata, setLifeStagedata] = useState()
    const { t } = useTranslation();
    const LifeStagestatic = useCallback(async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `api/v1/dashboard/lifeStageStatics`)
            if (res?.code === 200)
                setLifeStagedata(res?.statics)
        }
        catch (err) {
            console.log(err, "err")
        }
    }, []);
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true;
        LifeStagestatic()
    }, [LifeStagestatic])
    return (
        <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
            <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
            <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
                <div className="dashboard-area-cards-single-header d-flex align-items-center">
                    <div className="w-auto pe-0 drag-btn">
                        <img
                            className="drag-btn-icon"
                            src={base_assets + 'images/admin/icons/drag-icon.png'}
                            alt=""
                        />
                    </div>
                    <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
                        {t("LifeStage")}
                    </div>
                    <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
                        <span
                            className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
                        </span>
                        <ul className="dropdown-menu">
                            <li onClick={() => setFixed(current => !current)}>
                                <span className="dropdown-item">
                                    {fixed ?
                                        <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                                        :
                                        <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                                    }
                                </span>
                            </li>
                            <li
                                onClick={() => {
                                    setDashboardhideshow(prevState => ({
                                        ...prevState,
                                        Lifestage: 0
                                    }));
                                    setDefaultset(true)
                                }}>
                                <span className="dropdown-item">
                                    <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                                    {t("Delete")}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="dashboard-area-cards-single-chart-area dashboard-area-cards-single-chart-area-occasion py-3">
                    <div className="d-flex align-items-center my-4">
                        <img
                            className="me-2"
                            src={base_assets + 'images/icons/Teenager.png'}
                            alt=""
                        />
                        <span className="me-auto">{t("Teen")}</span>
                        <span>{lifestagedata?.Teen ? parseFloat(lifestagedata?.Teen).toFixed(2) : 0}%</span>
                    </div>
                    <div className="d-flex align-items-center my-4">
                        <img className="me-2" src={base_assets + 'images/icons/young-icon.png'} alt="" />
                        <span className="me-auto">{t("Young Adult")}</span>
                        <span>{lifestagedata?.Young_Adult ? parseFloat(lifestagedata?.Young_Adult).toFixed(2) : 0}%</span>
                    </div>
                    <div className="d-flex align-items-center my-4">
                        <img
                            className="me-2"
                            src={base_assets + 'images/icons/adult-icon.png'}
                            alt=""
                        />
                        <span className="me-auto">{t("Adult")}</span>
                        <span>{lifestagedata?.Adult ? parseFloat(lifestagedata?.Adult).toFixed(2) : 0}%</span>
                    </div>
                    <div className="d-flex align-items-center my-4">
                        <img
                            className="me-2"
                            src={base_assets + 'images/icons/Elderly_old.png'}
                            alt=""
                        />
                        <span className="me-auto">{t("Retired")}</span>
                        <span>{lifestagedata?.Retired ? parseFloat(lifestagedata?.Retired).toFixed(2) : 0}%</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LifeStage
