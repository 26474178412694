import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import DateTime from '../../common/DateTime'
import { _Api } from '../../api/_call'
import { Tablehead } from '../../common/modules/Tablehead'
import TableColumnModal from '../../common/modules/tableColumn'
import { SetdataTable } from '../../api/setdatatable'
import { posordertableHead } from '../../common/Tablehead'
import { DateRange } from '../../common/helpers/daterange'
import { useTranslation } from 'react-i18next';
import Select from "react-select"
const POSOrderlist = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation()
  const dataFetched = useRef();
  const { locationid, posid, posorder_ids, po_status } = state
  const navigate = useNavigate()
  const [limit, setLimit] = useState(100)
  const [datefilter, setDatefilter] = useState([])
  const [filtername, setFiltername] = useState('')
  const [posorderlist, setPosorderlist] = useState([])
  const [addposorderdata, setAddposorderdata] = useState([])
  const [selectAllStatus, setSelectAllStatus] = useState(false)
  const [newcolumndata, setnewcolumndata] = useState(posordertableHead)
  const [datalodaer, setDatsLodaer] = useState(false)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  let selectAllStatus_arr = []

  const filterbyname = () => {
    if (filtername) {
      po_posorder()
    } else {
      po_posorder()
    }
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const po_posorder = useCallback(async (firstlimit) => {
    setDatsLodaer(true)
    try {
      const postdata = {
        search: filtername,
        limit: firstlimit ? firstlimit : limit,
        skip: 0,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let resPoPOsOrders = await _Api(
        postdata,
        `api/v1/Inventory/po/getPOSList`,
      )
      if (resPoPOsOrders?.code === 200) {
        SetdataTable('po_pos_order_table', 6, newcolumndata, 0)
        setPosorderlist(resPoPOsOrders?.data)
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable('po_pos_order_table', 6, newcolumndata, 0)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [filtername, limit, datefilter, newcolumndata]);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_po_posorderlist',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const addposorder = (event, posid) => {
    const posorderlist_arr = [...posorderlist]
    if (event.target.checked) {
      const addposorderdata_a = [...addposorderdata]
      addposorderdata_a.push(event.target.value)
      setAddposorderdata(addposorderdata_a)
      let data_ind = event.target.getAttribute('data-index')
      posorderlist_arr[data_ind].checkBoxSel = true
    } else {
      setAddposorderdata((data) =>
        data.filter((ids) => ids !== event.target.value),
      )
      let data_ind = event.target.getAttribute('data-index')
      posorderlist_arr[data_ind].checkBoxSel = false
    }
    setPosorderlist(posorderlist_arr)
  }
  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    po_posorder()
  }, [po_posorder])
  useEffect(() => {
    if (posorder_ids !== undefined) {
      setAddposorderdata(posorder_ids)
    }
  }, [posorder_ids])
  useEffect(() => {
    var allCheckbox = document.querySelectorAll('.posordertableHead')
    if (selectAllStatus === true) {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = true
        selectAllStatus_arr.push(checkBox_selectAble.value)
      })
    } else {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = false
      })
    }
    setAddposorderdata(selectAllStatus_arr)
    // eslint-disable-next-line
  }, [selectAllStatus])
  return (
    <React.Fragment key={1}>
      <div>
        <div className="main-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="main-body-current-day-time">
                  <DateTime></DateTime>
                </span>
                <div className="main-body-top-tab-bar create-purchase-top-bar">
                  <div className="page-back-heading">
                    <div className="page-back-btn">
                      <span onClick={() => navigate(-1)} className="page-back-btn">
                        <img
                          src={base_assets + 'images/icons/arrow-left.png'}
                          alt=""
                        />
                      </span>
                      <div
                        className="main-body-top-tab-bar-left-col nav nav-pills"
                        role="tablist"
                      >
                        <span
                          onClick={() => navigate(-1)}
                          className="main-body-top-tab-bar-left-col-a nav-link active"
                        >
                          <span className="main-body-top-tab-bar-left-col-a-text">
                            {t('POS Order')}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="main-body-top-tab-bar-right-col">
                    <Link
                      to="/app/inventory/edit-po-pos"
                      state={{
                        posorder: addposorderdata,
                        posid: posid,
                        po_status: po_status,
                      }}
                      className="main-body-top-tab-bar-right-col-a"
                    >
                      <img
                        className="link-img"
                        src={base_assets + 'images/icons/plus-circle-white.png'}
                        alt=""
                      />
                      <span className="main-body-top-tab-bar-right-col-a-text">
                        {t('Add to po')}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="main-body-top-status-bar">
                  <Select
                    className="select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                    options={selectOptions}
                    value={{ label: limit, value: limit }}
                    onChange={(e) => {
                      setLimit(e.value)
                      po_posorder(e.value)
                    }}
                    isSearchable={false}
                  />
                  <div className="main-body-top-status-bar-filter">
                    <input
                      type="text"
                      value={filtername}
                      className="main-body-top-status-bar-filter-input"
                      placeholder={t('TF No./StockID/SKU/Metal/Stone')}
                      onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                      onChange={(e) => setFiltername(e.target.value)}
                    />
                    {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => setFiltername("")} alt="" /> : ""}
                    <button
                      onClick={filterbyname}
                      className="main-body-top-status-bar-filter-search"
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="main-body-top-status-bar-multi-date-picker">
                    <button className="print-export-dropdown-selected-icon d-none">
                      <img
                        src={base_assets + 'images/icons/printer-icon.png'}
                        alt=""
                      />
                    </button>
                    <DateRange setDatefilter={setDatefilter} />
                    <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            base_assets + 'images/icons/ellipsis-circular.png'
                          }
                          alt=""
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li className="dropdown-item print-dropdown-item">
                          <div className="dropdown-item-img">
                            <img
                              src={
                                base_assets + 'images/icons/printer-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          <span>{t('print')}</span>
                        </li>
                        <li className="dropdown-item">
                          <div className="dropdown-item-img">
                            <img
                              src={
                                base_assets + 'images/icons/export-x-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          <span>{t('export')}</span>
                        </li>
                        <li className="dropdown-item">
                          <div className="dropdown-item-img">
                            <img
                              src={base_assets + 'images/icons/share-icon.png'}
                              alt=""
                            />
                          </div>
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#socialModal"
                          >
                            {t('share')}
                          </span>
                        </li>
                        <li
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#columnModal"
                        >
                          <div className="dropdown-item-img">
                            <img
                              src={base_assets + 'images/icons/column-icon.png'}
                              alt=""
                            />
                          </div>
                          <span>{t('column')}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="common-sub-table-wrap add-row">
                  <div className="main-body-main-table-wrap position-relative">
                    <table
                      id="po_pos_order_table"
                      className="common-table product-base-table table-striped w-100"
                    >

                      <Tablehead
                        tablehead={posordertableHead}
                        tblName={'tbl_po_posorderlist'}
                        setfilterby={""}
                        setShowoption={setSelectAllStatus}
                      />
                      <tbody>
                        {posorderlist
                          ? posorderlist.map((result, key) => {
                            if (addposorderdata.indexOf(result?.id) !== -1) {
                              result.checkBoxSel = true
                            } else {
                              result.checkBoxSel = false
                            }
                            return (
                              <React.Fragment key={result?.id}>
                                <tr
                                  className={
                                    result?.CustomLocation_id === locationid
                                      ? ''
                                      : 'deactive'
                                  }
                                >
                                  <td key={key}>
                                    <div className="com-check">
                                      <input
                                        className="posordertableHead"
                                        checked={
                                          result.checkBoxSel
                                            ? result.checkBoxSel
                                            : ''
                                        }
                                        data-index={key}
                                        value={result?.id}
                                        id={'poscheck' + key}
                                        onChange={(e) => addposorder(e)}
                                        type="checkbox"
                                      />
                                      <label
                                        htmlFor={'poscheck' + key}
                                        className="com-check-label"
                                      />
                                    </div>
                                  </td>
                                  <td>{key + 1}</td>
                                  <td>
                                    <div className="table-pro-img">
                                      <img
                                        src={
                                          result?.product_details?.main_image
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                  <td className="po-no cursor-pointer">
                                    {result?.product_details?.SKU}
                                  </td>
                                  <td>{result?.createdAt}</td>
                                  <td className="po-no cursor-pointer">
                                    {result.So_No}
                                  </td>
                                  <td className="col-highlight">
                                    {result?.stock_id}
                                  </td>
                                  <td className="col-highlight">
                                    {result.product_details.metal_name}
                                  </td>
                                  <td className="col-highlight">
                                    {result.product_details.stone_name}
                                  </td>
                                  <td className="col-highlight">
                                    {result.product_details.size_name}
                                  </td>
                                  <td className="col-highlight">
                                    {result?.Qty}
                                  </td>
                                  <td>
                                    {result?.product_details?.Status === 1 ? (
                                      <label className="status-tab gray-tab">
                                        {t('open order')}
                                      </label>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td>
                                    {result?.priority === 0 ? (
                                      <label className="status-tab low-priority">
                                        {t('Low')}
                                      </label>
                                    ) : result?.priority === 1 ? (
                                      <label className="status-tab medium-priority">
                                        {t('Medium')}{' '}
                                      </label>
                                    ) : result?.priority === 2 ? (
                                      <label className="status-tab high-priority">
                                        {t('High')}{' '}
                                      </label>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                  <td className="danger-text">
                                    {result?.location_name}
                                  </td>
                                  <td className="danger-text">
                                    {result?.customer_name}
                                  </td>
                                  <td className="danger-text">
                                    {result?.delivery_date}
                                  </td>
                                  <td className="danger-text">
                                    {result?.due_days}
                                  </td>
                                  <td>{result?.remark}</td>
                                  {result?.Custom_design === 1 ? (
                                    <td className="text-center">
                                      <img
                                        className="pdf-image"
                                        src={
                                          base_assets +
                                          'images/icons/pdf-icon.png'
                                        }
                                        alt=""
                                      />
                                    </td>
                                  ) : (
                                    ''
                                  )}
                                </tr>
                              </React.Fragment>
                            )
                          })
                          : ''}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            {posorderlist
                              ? posorderlist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val.Qty)),
                                0,
                              )
                              : ''}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tfoot>
                    </table>
                    <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                      <div className="dots">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={posordertableHead}
        tblName={'tbl_po_posorderlist'}
        calldatatable={po_posorder}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
    </React.Fragment>
  )
}

export default POSOrderlist
