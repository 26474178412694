import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Dashboard from "../icons/sidebar/Dashboard.js";
import Organization from "../icons/sidebar/Organization.js";
import User from "../icons/sidebar/User.js";
import Location from "../icons/sidebar/Location.js";
import Product from "../icons/sidebar/Product.js";
import Inventory from "../icons/sidebar/Inventory.js";
import Pos from "../icons/sidebar/Pos.js";
import Master from "../icons/sidebar/Master.js";
import Customer from "../icons/sidebar/Customer.js";
import Setup from "../icons/sidebar/Setup.js";
import EcommerceIcon from "../icons/sidebar/EcommerceIcon.js";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import "../../styleV3.scss";
import "./sidebar.scss";
import Calendar from '../icons/sidebar/Calendar.js';
const Sidebar = (props) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const moduleparmissiondatashow = useSelector((state) => state.app.ModulePermission);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const sidebarOverflows = document.querySelectorAll('.main-side-bar-menu-single.dropdown > .dropdown-toggle');
  const sidebarMainDiv = document.querySelector('.main-side-bar-menu');
  if (sidebarOverflows) {
    sidebarOverflows.forEach((sidebarOverflow) => {
      sidebarOverflow.addEventListener('click', () => {
        sidebarMainDiv.style.overflow = 'visible';
      });
    });

  }

  useEffect(() => {
    const sidebarOverflow = document.querySelector('.main-side-bar-menu-single.dropdown');
    const sidebarMainDiv = document.querySelector('.main-side-bar-menu');

    const handleClick = () => {
      sidebarMainDiv.style.display = 'none';
    };

    if (sidebarOverflow) {
      sidebarOverflow.addEventListener('click', handleClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (sidebarOverflow) {
        sidebarOverflow.removeEventListener('click', handleClick);
      }
    };
  }, []);
  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);


  const handleSecondDropdownClick = () => {
    setIsFirstDropdownOpen(false);
    setIsSecondDropdownOpen(false);
  };

  const [isSetupFirstDropdownOpen, setSetupIsFirstDropdownOpen] = useState(false);
  const [isSetupSecondDropdownOpen, setSetupIsSecondDropdownOpen] = useState(false);


  const handleSetupSecondDropdownClick = () => {
    setSetupIsFirstDropdownOpen(false);
    setSetupIsSecondDropdownOpen(false);
  };


  return (
    <>
      <aside className={sidebarToggle ? 'main-side-bar flex-shrink-0' : 'main-side-bar flex-shrink-0 resize'}>
        <img className='main-side-bar-toggle-btn' src={base_assets + "images/admin/icons/expand-icon.svg"} onClick={() => setSidebarToggle(current => !current)} alt="" />
        <div className='main-side-bar-logo'>
          <Link className='main-side-bar-logo-a'>
            <img src={base_assets + "images/header-images/gis-logo-white.png"} alt="" />
          </Link>
        </div>

        <ul className='main-side-bar-menu d-block list-unstyled px-0'>
          {moduleparmissiondatashow?.Dashboard ?
            <li className='main-side-bar-menu-single'>
              <Link to="/app/admin/dashboard" className='main-side-bar-menu-single-a'> <Dashboard /> <span className='main-side-bar-menu-single-a-text'>{t("Dashboard")}</span></Link>
            </li> : ""}
          <li className='main-side-bar-menu-single'>
            <Link to="/app/admin/calendar" className='main-side-bar-menu-single-a'>  <Calendar width="20px" height="20px" /> <span className='main-side-bar-menu-single-a-text mt-1'>{t("Calandar")}</span></Link>
          </li>
          {moduleparmissiondatashow?.Organization ?
            <li className='main-side-bar-menu-single'>
              <Link className='main-side-bar-menu-single-a'
                to="/app/admin/organization" > <Organization /> <span className='main-side-bar-menu-single-a-text'>{t("Organization")}</span></Link>
            </li> : ""}
          {moduleparmissiondatashow?.User ?
            <li className='main-side-bar-menu-single dropdown'>
              <Link className='main-side-bar-menu-single-a dropdown-toggle after-none' id="subMenuUser" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside"> <User /> <span className='main-side-bar-menu-single-a-text'> {t("User")} </span> <img className='ms-auto' src={base_assets + "images/admin/icons/right-chevron.png"} alt="" />
              </Link>
              <ul className='sub-menu list-unstyled shadow dropdown-menu p-0 border-0' aria-labelledby="subMenuUser">
                {parmissiondatashow?.User?.View ?
                  <li className='sub-menu-li'>
                    <Link className='sub-menu-li-a'
                      to="/app/users">{t("Users")}</Link>
                  </li> : ""}
                {parmissiondatashow?.user_roles?.View ?
                  <li className='sub-menu-li'>
                    <Link className='sub-menu-li-a'
                      to="/app/users/roles">{t("Roles")}</Link>
                  </li> : ""}
                {parmissiondatashow?.user_reports?.View ?
                  <li className='sub-menu-li'>
                    <Link className='sub-menu-li-a dropdown-toggle after-none' id="subMenuReport" data-bs-toggle="dropdown" aria-expanded="false">{t("Report")}
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.5 16.5L14.5 11.5L9.5 6.5" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Link>
                    <ul className='sub-menu list-unstyled shadow dropdown-menu p-0 border-0' aria-labelledby="subMenuReport">
                      <li className='sub-menu-li'>
                        <Link className='sub-menu-li-a'
                          to="/app/users/UserLogReport">{t("Users Log")}</Link>
                      </li>
                      <li className='sub-menu-li'>
                        <Link className='sub-menu-li-a'
                          to="/app/users/OperationLogReport">{t("Operation Log")}</Link>
                      </li>
                    </ul>
                  </li> : ""}
              </ul>
            </li> : ""}
          {moduleparmissiondatashow?.Location ?
            <li className='main-side-bar-menu-single'>
              <Link to="/app/admin/location" className='main-side-bar-menu-single-a'> <Location /> <span className='main-side-bar-menu-single-a-text'>{t("Location")}</span></Link>
            </li> : ""}
          {moduleparmissiondatashow?.Master ?
            <li className='main-side-bar-menu-single dropdown'>
              <Link
                className='main-side-bar-menu-single-a dropdown-toggle after-none'
                id="subMenuMaster"
                data-bs-toggle="dropdown"
                aria-expanded={isFirstDropdownOpen}
                data-bs-auto-close="outside"
                onClick={() => setIsFirstDropdownOpen(!isFirstDropdownOpen)}
              >
                <Master /><span className='main-side-bar-menu-single-a-text'> {t("Master")}</span> <img className='ms-auto' src={base_assets + "images/admin/icons/right-chevron.png"} alt="" />
              </Link>
              <ul className={`sub-menu list-unstyled shadow dropdown-menu p-0 border-0 top-135 ${isFirstDropdownOpen ? 'show' : ''}`} aria-labelledby="subMenuMaster">
                <li className='sub-menu-li'>
                  <Link
                    className='sub-menu-li-a dropdown-toggle after-none'
                    id="subMenuItemMaster"
                    data-bs-toggle="dropdown"
                    aria-expanded={isSecondDropdownOpen}
                  >
                    {t("Item Master")}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M9.5 16.5L14.5 11.5L9.5 6.5" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Link>
                  <ul className={`sub-menu list-unstyled shadow dropdown-menu p-0 border-0 ${isSecondDropdownOpen ? 'show' : ''}`} aria-labelledby="subMenuItemMaster"
                    onClick={handleSecondDropdownClick}>
                    <li className='sub-menu-li' aria-labelledby="subMenuMaster">
                      <Link to="/app/admin/master/itemmaster/item" className='sub-menu-li-a'>{t("Item")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/itemmaster/size" className='sub-menu-li-a'>{t("Size")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/itemmaster/collection" className='sub-menu-li-a'>{t("Collection")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/itemmaster/style" className='sub-menu-li-a'>{t("Style")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/itemmaster/ecommerce-cat" className='sub-menu-li-a'>{t("eCommerce Category")}</Link>
                    </li>
                  </ul>
                </li>
                <li className='sub-menu-li'>
                  <Link className='sub-menu-li-a dropdown-toggle after-none' id="subMenuMetalMaster" data-bs-toggle="dropdown" aria-expanded={isSecondDropdownOpen}>{t("Metal Master")}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M9.5 16.5L14.5 11.5L9.5 6.5" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Link>
                  <ul className='sub-menu list-unstyled shadow dropdown-menu p-0 border-0' aria-labelledby="subMenuMetalMaster"
                    onClick={handleSecondDropdownClick}>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/metalmaster/metal" className='sub-menu-li-a'>{t("Metal")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/metalmaster/color" className='sub-menu-li-a'>{t("Color")}</Link>
                    </li>
                  </ul>
                </li>
                <li className='sub-menu-li'>
                  <Link className='sub-menu-li-a dropdown-toggle after-none' id="subMenuStoneMaster" data-bs-toggle="dropdown" aria-expanded={isSecondDropdownOpen}>{t("Stone Master")}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M9.5 16.5L14.5 11.5L9.5 6.5" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Link>
                  <ul className='sub-menu list-unstyled shadow dropdown-menu p-0 border-0' aria-labelledby="subMenuStoneMaster"
                    onClick={handleSecondDropdownClick}>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/stonegroup" className='sub-menu-li-a'>{t("Stone Group")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/stone" className='sub-menu-li-a'>{t("Stone")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/color" className='sub-menu-li-a'>{t("Color")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/size" className='sub-menu-li-a'>{t("Size")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/shape" className='sub-menu-li-a'>{t("Shape")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/cut" className='sub-menu-li-a'>{t("Cut")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/clarity" className='sub-menu-li-a'>{t("Clarity")}</Link>
                    </li>
                    <li className='sub-menu-li'>
                      <Link to="/app/admin/master/stonemaster/settingtype" className='sub-menu-li-a'>{t("Setting Type")}</Link>
                    </li>
                  </ul>
                </li>
                <li className='sub-menu-li'
                  onClick={handleSecondDropdownClick}>
                  <Link to="/app/admin/master/servicelabour" className='sub-menu-li-a'>{t("Service Labour")}</Link>
                </li>
                <li className='sub-menu-li'
                  onClick={handleSecondDropdownClick}>
                  <Link to="/app/admin/master/currency" className='sub-menu-li-a'>{t("Currency")}</Link>
                </li>
                <li className='sub-menu-li'
                  onClick={handleSecondDropdownClick}>
                  <Link to="/app/admin/master/vouchertype" className='sub-menu-li-a'>{t("Voucher Type")}</Link>
                </li>
                <li className='sub-menu-li'
                  onClick={handleSecondDropdownClick}>
                  <Link to="/app/admin/master/salesperson" className='sub-menu-li-a'>{t("Sales Person")}</Link>
                </li>
                <li className='sub-menu-li'
                  onClick={handleSecondDropdownClick}>
                  <Link to="/app/admin/master/vendor" className='sub-menu-li-a'>{t("Vendor")}</Link>
                </li>
              </ul>
            </li> : ""}
          {moduleparmissiondatashow?.Product ?
            <li className='main-side-bar-menu-single'>
              <Link className='main-side-bar-menu-single-a'
                to="/app/product/main"> <Product /> <span className='main-side-bar-menu-single-a-text'>{t("Product")}</span> </Link>
            </li> : ""}
          {moduleparmissiondatashow?.Inventory ?
            <li className='main-side-bar-menu-single'>
              <Link className='main-side-bar-menu-single-a'
                to="/app/inventory"> <Inventory /> <span className='main-side-bar-menu-single-a-text'>{t("Inventory")}</span> </Link>
            </li> : ""}
          {moduleparmissiondatashow?.Point_of_sale ?
            <li className='main-side-bar-menu-single'>
              <a className='main-side-bar-menu-single-a'
                href="/app/pos/"> <Pos /> <span className='main-side-bar-menu-single-a-text'>{t("Point of Sale")}</span></a>
            </li> : ""}
          <li className='main-side-bar-menu-single dropdown'>
            <Link className='main-side-bar-menu-single-a dropdown-toggle after-none' id="subMenuCustomer" data-bs-toggle="dropdown" aria-expanded="false"> <Customer /> <span className='main-side-bar-menu-single-a-text'>{t("Customer")}</span> <img className='ms-auto' src={base_assets + "images/admin/icons/right-chevron.png"} alt="" />
            </Link>
            <ul className='sub-menu list-unstyled shadow dropdown-menu p-0 border-0' aria-labelledby="subMenuCustomer">
              <li className='sub-menu-li'>
                <Link to="/app/admin/customers" className='sub-menu-li-a'>{t("Customers")}</Link>
              </li>
              <li className='sub-menu-li'>
                <Link to="/app/admin/customers/customersreport" className='sub-menu-li-a'>{t("Report")}</Link>
              </li>
            </ul>
          </li>
          <li className={moduleparmissiondatashow?.GIS_setup ? 'main-side-bar-menu-single dropdown' : "d-none"}>
            <Link className='main-side-bar-menu-single-a dropdown-toggle after-none'
              id="subMenuSetup"
              data-bs-toggle="dropdown"
              aria-expanded={isSetupFirstDropdownOpen}
              data-bs-auto-close="outside"
              onClick={() => setSetupIsFirstDropdownOpen(!isSetupFirstDropdownOpen)}
            >
              <i className="fa fa-sliders" aria-hidden="true"></i>
              <span className='main-side-bar-menu-single-a-text'> {t("Setup")}</span>
              <img className='ms-auto' src={base_assets + "images/admin/icons/right-chevron.png"} alt="" />
            </Link>
            <ul className={`sub-menu list-unstyled shadow dropdown-menu p-0 border-0 bottom-0 ${isSetupFirstDropdownOpen ? 'show' : ''}`} aria-labelledby="subMenuSetup">
              <li className='sub-menu-li'>
                <Link to="/app/admin/setup/quickstoresetup" className='sub-menu-li-a'>{t("Quick Store Setup")}</Link>
              </li>
              {moduleparmissiondatashow?.Device ?
                <li className='sub-menu-li'>

                  <Link to="/app/admin/setup/device" className='sub-menu-li-a'>{t("Device")}</Link>
                </li> : ""
              }
              {parmissiondatashow?.gis_setup_general?.View ?
                <li className='sub-menu-li'>
                  <Link to="/app/admin/setup/general" className='sub-menu-li-a'>{t("General")}</Link>
                </li> : ""
              }
              {parmissiondatashow?.gis_setup_payment_method?.View ?
                <li className='sub-menu-li'>
                  <Link to="/app/admin/setup/paymentmethod" className='sub-menu-li-a'>{t("Payment Method")}</Link>
                </li>
                : ""
              }
              {parmissiondatashow?.gis_setup_tax_rules?.View ?
                <li className='sub-menu-li'>
                  <Link to="/app/admin/setup/taxrules" className='sub-menu-li-a'>{t("Tax Rules")}</Link>
                </li>
                : ""
              }
              <li className='sub-menu-li'>
                <Link to="/app/admin/setup/email" className='sub-menu-li-a'>{t("E-mail")}</Link>
              </li>
              <li className='sub-menu-li'>
                <Link to="/app/admin/setup/applicationkeys" className='sub-menu-li-a'>{t("Application Key's")}</Link>
              </li>
              <li className='sub-menu-li'>
                <Link to="/app/admin/setup/domain" className='sub-menu-li-a'>{t("Domains")}</Link>
              </li>

              {moduleparmissiondatashow?.Device ?
                <li className='sub-menu-li'>
                  <Link
                    className='sub-menu-li-a dropdown-toggle after-none'
                    id="subMenuBarcodeMaster"
                    data-bs-toggle="dropdown"
                    aria-expanded={isSetupSecondDropdownOpen}
                  >
                    {t("Barcode")}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M9.5 16.5L14.5 11.5L9.5 6.5" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Link>
                  <ul className={`sub-menu list-unstyled shadow dropdown-menu p-0 border-0 ${isSetupSecondDropdownOpen ? 'show' : ''}`} aria-labelledby="subMenuBarcodeMaster"
                    onClick={handleSetupSecondDropdownClick}>

                    <li className='sub-menu-li' aria-labelledby="subMenuMaster">
                      <Link to="/app/admin/setup/barcode" className='sub-menu-li-a'>{t("Barcode")}</Link>
                    </li>
                    <li className='sub-menu-li' aria-labelledby="subMenuMaster">
                      <Link to="/app/admin/setup/barcode/log" className='sub-menu-li-a'>{t("Log")}</Link>
                    </li>

                    <li className='sub-menu-li' aria-labelledby="subMenuMaster">
                      <Link to="/app/admin/setup/barcode/s" className='sub-menu-li-a'>{t("Summary")}</Link>
                    </li>

                  </ul>
                </li> : ""
              }
            </ul>
          </li>
          <li className='main-side-bar-menu-single dropdown'>
            <Link className='main-side-bar-menu-single-a dropdown-toggle after-none' to="/app/admin/settings"> <Setup /> <span className='main-side-bar-menu-single-a-text'>{t("Settings")}</span>
            </Link>
          </li>
          {/* <li className='main-side-bar-menu-single dropdown'>
            <Link className='main-side-bar-menu-single-a dropdown-toggle after-none' to="
            "> <EcommerceIcon /> <span className='main-side-bar-menu-single-a-text'>{t("CMS")}</span>
            </Link>
          </li> */}
        </ul>
      </aside>
    </>
  )
}

export default Sidebar;