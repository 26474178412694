import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.js";
import { loadStripe } from "@stripe/stripe-js";
import { _Api } from "../../../../common/api.js";
const StripeCheckout = (props) => {
    const { paymentdatashow, payamount, setCreditpaymentdata, creditpaymentdata, userdata } = props;

    const stripe = loadStripe(paymentdatashow?.clientKey);
    const [clientSecret, setClientSecret] = useState("");
    const [successMessage,] = useState("Payment Successfull");
    const [, setIsLoading] = useState(false);

    const createPaymentIntent = async (amount) => {
        setIsLoading(true);
        try {
            if (!clientSecret) {
                let ObjectPost = {
                    amount: payamount,
                    name: userdata?.billingaddress?.name,
                    slug: paymentdatashow?.payment_slag,
                    email: userdata?.billingaddress?.emails?.[0],
                    PaymentMethod: paymentdatashow?.PaymentMethod
                }
                console.log(ObjectPost, userdata, "lets see the object")
                let res = await _Api(ObjectPost, `checkout/payment-token`)
                if (res?.statusCode === 200) {
                    setClientSecret(res?.data?.clientSecret);
                    setIsLoading(false);
                    setCreditpaymentdata((state) => ({
                        ...state,
                        message: "",
                        payment: false,
                        submitdata: false,
                        stripekey: res?.data?.pid,
                        Paymentmethod_type: paymentdatashow?.Paymentmethod_type
                    }))

                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const onPaymentSuccess = (data) => {
    }

    useEffect(() => {
        if (userdata?.billingaddress?.emails?.[0]) {
            createPaymentIntent(payamount);
        }
        // eslint-disable-next-line
    }, [userdata]);

    return (
        <div className="rs">
            {clientSecret && (
                <Elements
                    options={{
                        clientSecret,
                        appearance: {

                            theme: "stripe",
                            variables: {
                                colorPrimary: '#5CC7C0',
                                fontLineHeight: '17.29px',
                                colorBackground: '#ffffff',
                                colorText: '#717171',
                                fontFamily: 'Segoe UI',
                                fontSizeBase: '14px',
                                colorTextPlaceholder: '#C0C0C0'

                            },
                            rules: {
                                '.Input::placeholder': {
                                    color: '#C0C0C0',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '16px',
                                    lineHeight: '27.69px'
                                },
                                '.Input': {
                                    border: '1px solid #ced4da',
                                    borderRadius: '0.375rem',
                                    marginTop: '8px'
                                },
                                '.Input:focus': {
                                    border: '1px solid #5CC7C0',
                                    boxShadow: 'none'
                                },



                            }


                        },
                    }}
                    stripe={stripe}
                >
                    <CheckoutForm onPaymentSuccess={onPaymentSuccess} successMessage={successMessage}
                        creditpaymentdata={creditpaymentdata} setCreditpaymentdata={setCreditpaymentdata} paymentdatashow={paymentdatashow} />
                </Elements>
            )}
        </div>
    );

}

export default StripeCheckout;