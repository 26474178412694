import React from 'react'

const PrintIcon = (props) => {
    const { width, height } = props;
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3299_205580)">
        <path d="M20 8H18V2H6V8H4C2.9 8 2 8.9 2 10V16C2 17.1 2.9 18 4 18H6V22H18V18H20C21.1 18 22 17.1 22 16V10C22 8.9 21.1 8 20 8ZM7 3H17V8H7V3ZM7 21V13H17V21H7ZM21 16C21 16.5515 20.5515 17 20 17H18V12H6V17H4C3.4485 17 3 16.5515 3 16V10C3 9.4485 3.4485 9 4 9H20C20.5515 9 21 9.4485 21 10V16Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0_3299_205580">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>

    )
}

export default PrintIcon