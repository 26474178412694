import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Gallery from "../common/gallery/gallery";
import ProductMenu from "./productMenu";
import { useForm } from "react-hook-form";
import { _Api } from "../api/_call";
import { loading, Updateloading } from "../common/helpers/toastify";
import { toFormatPrice, toUnformatPrice } from "../common/helpers/function"
import {
  MetalsList,
  ColorsList,
  SizesList,
  StonegroupsList,
  StonesList,
  ShapesList,
  CutsList,
  ClaritysList,
  StonecolorsList,
  LocationsListPricing,
  LocationsListOtherDetails,
  CertificateTypelist,
  TaxsList,
  StoneVarientType,
  StonesettingType,
  StoneSizeList,
  UnitSelect,
  StoneUnitselect,
  GetPointer,
  GetShapeToStoneSize
} from "../product/masters";
import "./product.scss";
import { useTranslation } from 'react-i18next';
const ProductAdvanced = (props) => {
  const { t } = useTranslation();
  const [call, setcall] = useState("");
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const {
    register,
    handleSubmit,
    reset,
    setValue
  } = useForm();

  const pricing_obj = {};
  const { productId_url } = useParams();
  const [IsDisable, setIs_disable] = useState(false);
  const Stone_obj = {
    StoneGroup: "",
    Stone: "",
    StoneVariant: "",
    StoneShape: "",
    StoneCut: "",
    StoneClarity: "",
    StoneColor: "",
    stoneSize: "",
    certificate: "",
    Certificatetype: "",
    certificateNo: "",
    Weight: "",
    WeightType: "",
    Pcs: "",
    setting_type: "",
    stonePrice: "",
  };
  const otherDetails_obj = {

  };
  var setected_opt_ = {
    generalInfo: "",
    advanced: "active",
    variant: "",
    choice: "",
  };
  const [setected_opt] = useState(setected_opt_);
  const [possi_css, setPossi_css] = useState([]);
  const [formValues_stone, setFormValues_stone] = useState([{}]);
  const [formValues_otherDetails, setFormValues_otherDetails] = useState([
    otherDetails_obj,
  ]);
  const [formValues_pricing, setFormValues_pricing] = useState([pricing_obj]);
  //master values props 
  const [additional_image_form, setImages_product] = useState([]);
  const [formstoneGroup, set_stoneGroup] = useState("");
  const [selectedButton, setButton] = useState("");
  const [formValues_metal, setFormValues_metal] = useState("");
  const [formValues_color, setFormValues_color] = useState("");
  const [formValues_size, setFormValues_size] = useState("");
  const [, setPriceLocation] = useState("");
  const [formDesignValue, setDesignValue] = useState([]);
  const [formShape_ids, setShape_ids] = useState([]);
  const [, setVarientType] = useState([]);
  const [formPricetax, setPricetax] = useState("");
  const [productsImages, setproductsImages] = useState("");
  const [OrgCurrency, setOrgCurrency] = useState("");

  const [formUnit, setUnit] = useState("");

  const [advanceProductData, setAdvanceProductData] = useState([
    { Size: "", Metal: "", Color: "" },
  ]);

  const [product_value_arr_advance, setproduct_value_arr_advance] = useState([
    {},
  ]);
  const [varient_image, setVarient_image] = useState("");
  const [, setOtherDetailsLocation] = useState("");
  const [product_value_arr, setproduct_value_arr_val] = useState([{ product_id: "", name: "", varient_type: "" }]);
  const getProductUrl_details = useCallback(async () => {
    if (productId_url !== undefined) {
      try {
        const postdata = {
          id: productId_url,
        };
        let resPoOrders = await _Api(postdata, `api/v1/APP/product/getProductType`);
        if (resPoOrders.code === 200) {
          setproduct_value_arr_val(resPoOrders.data);
        } else {
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  }, [productId_url]);

  let pricing_map_change = (index, key, value) => {
    if (value !== undefined) {
      var formValues_pricing_arr = [...formValues_pricing];
      formValues_pricing_arr[index][key] = value;
      setFormValues_pricing(formValues_pricing_arr);
    }
    let index_stone = index;
    var setstatusSelectedOptions = [...formValues_pricing];

    var taxType = setstatusSelectedOptions[index_stone]["taxType"];
    var taxValue = 0;
    var retailValue = toUnformatPrice(setstatusSelectedOptions[index_stone]["retailprice_Ex"]);
    var retailPlusTaxValue = toUnformatPrice(setstatusSelectedOptions[index_stone]["retailprice_Inc"]);
    var InvValue = toUnformatPrice(setstatusSelectedOptions[index_stone]["inv_retailprice_Ex"]);
    var InvPlusTaxValue = toUnformatPrice(setstatusSelectedOptions[index_stone]["inv_retailprice_Inc"]);
    var taxRate = parseFloat(setstatusSelectedOptions[index_stone]["tax_rate_val"]);
    if (key === 'retailprice_Ex') {
      taxValue = taxType === 'Inclusive' ? (retailValue * taxRate) / (100) : 0;
      setstatusSelectedOptions[index_stone]["retailprice_Inc"] = toFormatPrice(taxValue + retailValue);
      setstatusSelectedOptions[index_stone]["tax"] = toFormatPrice(taxValue);

    }
    else if (key === 'rate') {
      if (retailPlusTaxValue) {
        taxValue = taxType === 'Inclusive' ? (retailPlusTaxValue * taxRate) / (taxRate + 100) : 0;
        setstatusSelectedOptions[index_stone]["retailprice_Ex"] = toFormatPrice(retailPlusTaxValue - taxValue);

      } else {
        taxValue = taxType === 'Inclusive' ? (retailValue * taxRate) / (100 + taxRate) : 0;
        setstatusSelectedOptions[index_stone]["retailprice_Inc"] = toFormatPrice(taxValue + retailValue);
      }
      setstatusSelectedOptions[index_stone]["tax"] = toFormatPrice(taxValue);
    }
    else if (key === 'retailprice_Inc') {
      taxValue = taxType === 'Inclusive' ? (retailPlusTaxValue * taxRate) / (taxRate + 100) : 0;
      setstatusSelectedOptions[index_stone]["retailprice_Ex"] = toFormatPrice(retailPlusTaxValue - taxValue);
      setstatusSelectedOptions[index_stone]["tax"] = toFormatPrice(taxValue);
    }
    if (key === 'inv_retailprice_Ex') {
      taxValue = taxType === 'Inclusive' ? (InvValue * taxRate) / (100) : 0;
      setstatusSelectedOptions[index_stone]["inv_retailprice_Inc"] = toFormatPrice(taxValue + InvValue);
      setstatusSelectedOptions[index_stone]["tax"] = toFormatPrice(taxValue);

    }
    else if (key === 'rate') {
      if (InvPlusTaxValue) {
        taxValue = taxType === 'Inclusive' ? (InvPlusTaxValue * taxRate) / (taxRate + 100) : 0;
        setstatusSelectedOptions[index_stone]["inv_retailprice_Ex"] = toFormatPrice(InvPlusTaxValue - taxValue);

      } else {
        taxValue = taxType === 'Inclusive' ? (InvValue * taxRate) / (100 + taxRate) : 0;
        setstatusSelectedOptions[index_stone]["inv_retailprice_Inc"] = toFormatPrice(taxValue + InvValue);
      }
      setstatusSelectedOptions[index_stone]["tax"] = toFormatPrice(taxValue);
    }
    else if (key === 'inv_retailprice_Inc') {
      taxValue = taxType === 'Inclusive' ? (InvPlusTaxValue * taxRate) / (taxRate + 100) : 0;
      setstatusSelectedOptions[index_stone]["inv_retailprice_Ex"] = toFormatPrice(InvPlusTaxValue - taxValue);
    }
    document.querySelector("#retailprice_Inc_" + index_stone).value = toFormatPrice(
      setstatusSelectedOptions[index_stone]["retailprice_Inc"]);
    document.querySelector("#retailprice_Ex_" + index_stone).value = toFormatPrice(
      setstatusSelectedOptions[index_stone]["retailprice_Ex"]);
    setFormValues_pricing(setstatusSelectedOptions);
  };

  let map_otherDetails = (index, key, value) => {
    var formValues_otherDetails_arr = [...formValues_otherDetails];

    formValues_otherDetails_arr[index][key] = parseInt(value);
    setFormValues_otherDetails(formValues_otherDetails_arr);
  };
  //end
  let addFormFields_stone = () => {
    setFormValues_stone([...formValues_stone, Stone_obj]);
    setPossi_css_call();
  };
  let removeFormFields_stone = (i) => {
    let newFormValues = [...formValues_stone];
    newFormValues.splice(i, 1);
    setFormValues_stone(newFormValues);
  };

  let addFormFields_otherDetails = () => {
    setFormValues_otherDetails([...formValues_otherDetails, otherDetails_obj]);
  };
  let removeFormFields_otherDetails = (i) => {
    let newFormValues = [...formValues_otherDetails];
    newFormValues.splice(i, 1);
    setFormValues_otherDetails(newFormValues);
  };

  let addFormFields_pricing = () => {
    setFormValues_pricing([...formValues_pricing, pricing_obj]);
  };
  let removeFormFields_pricing = (i) => {
    let newFormValues = [...formValues_pricing];
    newFormValues.splice(i, 1);
    setFormValues_pricing(newFormValues);
  };

  const arrayColumn = (arr_, index) => {
    if (JSON.stringify(arr_[0]) !== '{}') {
      return arr_?.map((val) => val[index]);
    } else {
      return [];
    }
  }
  const callcertificatenumber = (value, index, name) => {
    let stones = [...formValues_stone];
    stones[index] = {
      ...stones[index],
      [name]: value
    }
    setFormValues_stone(stones);
  }
  const onSubmit = async (data) => {
    let GetloadingID = loading();
    setButton("pe-none ct-disable");
    data.Metal = formValues_metal;
    data.Color = formValues_color;
    data.Size = formValues_size;
    data.PriceDetails = formValues_pricing ? formValues_pricing : [];
    data.stones = formValues_stone;
    data.OtherDetails = formValues_otherDetails ? formValues_otherDetails : [];
    data.product_id = product_value_arr[0].product_id;
    data.Unit = formUnit;
    data.additional_product_image = arrayColumn(
      additional_image_form,
      "data_url"
    );

    try {
      const postdata = data;
      let resPoOrders = await _Api(postdata, `api/v1/APP/product/saveAdvance`);
      if (resPoOrders.code === 200) {
        Updateloading(t, GetloadingID, t(resPoOrders.message));
        setButton("pe-none ct-disable");
      } else {
        setButton("ErrorShowBtn");
        Updateloading(t, GetloadingID, resPoOrders?.errors, 'error');
      }
    } catch (err) {
      Updateloading(t, GetloadingID, t("something went wrong"), 'error');
      setButton("ErrorShowBtn");
    }
  };

  let taxValSet_data = (index_stone) => {
    pricing_map_change(index_stone, 'rate', 0);
    setPricetax("");
  };

  useEffect(() => {
    if (product_value_arr) {
      setproduct_value_arr_advance(product_value_arr);
      getAdvance();
      let formValues_stone_val = [...formValues_stone];
      setFormValues_stone(formValues_stone_val);
      setVarientType(product_value_arr[0]?.varient_type);
      getVarientImage(product_value_arr[0].varient_type);
    }
    // eslint-disable-next-line
  }, [product_value_arr]);


  useEffect(() => {
    if (formPricetax) {
      taxValSet_data(formPricetax - 1);
    }
    // eslint-disable-next-line
  }, [formPricetax]);
  useEffect(() => {

    if (product_value_arr[0]?.product_id) {
      setIs_disable('pe-none  ct-disable');
    }
  }, [product_value_arr]);
  const getAdvance = async () => {
    try {
      const postdata = {
        id: product_value_arr[0].product_id,
      };
      let resPoOrders = await _Api(postdata, `api/v1/APP/product/getAdvance`);
      if (resPoOrders.code === 200) {

        var response = resPoOrders?.data ? resPoOrders?.data : [];
        var responseImages = resPoOrders?.images ? resPoOrders?.images : [];
        var responseCurrency = resPoOrders?.currency ? resPoOrders?.currency : [];
        setproductsImages(responseImages);
        setOrgCurrency(responseCurrency);
        var StoneGroup = response.StoneGroup ? response?.StoneGroup[0] : [];
        if (StoneGroup?.length) {
          setFormValues_stone([]);
        }
        var data_stone = [];
        resPoOrders?.data?.Stones?.forEach((element, index) => {
          data_stone.push({
            StoneGroup: element?.stone_group,
            Stone: element?.stone,
            StoneVariant: element?.Variant,
            Stone_Variant: element?.Variant,
            StoneShape: element?.shape,
            StoneCut: element?.Cut,
            StoneClarity: element?.clarity,
            StoneColor: element?.color,
            stoneSize: element?.Size,
            certificate: element?.certificate.type,
            Certificatetype: element?.certificate.type,
            certificateNo: element?.certificate.number,
            Weight: element?.Cts,
            WeightType: element?.Unit,
            Pcs: element?.Pcs,
            setting_type: element?.setting_type,
            stonePointer: element?.Pointer,
            stonePrice: element?.Price,
          });
        });

        if (data_stone?.length) {
          setFormValues_stone(data_stone);
        }


        setAdvanceProductData(resPoOrders.data);
        setFormValues_size(response.Size);
        setFormValues_color(response.Color);
        setFormValues_metal(response.Metal);
        setUnit(response.Unit)
        if (resPoOrders.data.PriceDetails) {
          setFormValues_pricing(resPoOrders.data.PriceDetails);
        }
        if (resPoOrders.data.OtherDetails) {
          setFormValues_otherDetails(resPoOrders.data.OtherDetails);
        }
      } else {
      }
    } catch (err) {
      console.log(err, "err1");
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setcall(1);
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    reset(advanceProductData);
    // eslint-disable-next-line
  }, [advanceProductData]);
  const getVarientImage = (type) => {
    var image = "";
    if (type === 1) {
      image = base_assets + "images/icons/variant-sm.png";
    } else if (type === 2) {
      image = base_assets + "images/pro-ring-img.png";
    }
    setVarient_image(image);
  };
  const setPossi_css_call = () => {
    let product_stone_posi = [];
    const timer = setTimeout(() => {
      for (let i = 0; i < 10; i++) {
        let dataStone_ = document.querySelector("#dataStone_" + i);
        if (dataStone_) {
          let dataStone_O = dataStone_.offsetTop;
          product_stone_posi.push(dataStone_O);
        }

      }
      setPossi_css(product_stone_posi);
    }, 5000);
    return () => clearTimeout(timer);
  }
  useEffect(() => {
    getProductUrl_details();
    setPossi_css_call();

  }, [getProductUrl_details]);


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment key="1">
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <ProductMenu
                  product_value_arr={product_value_arr_advance}
                  setected_opt={setected_opt}
                  selectedButton_={selectedButton}
                  setIs_disable={setIs_disable}
                  productId_url={productId_url}

                />
                <div className={"product-ct-wrap product-pt " + IsDisable}>
                  <div className="product-ct-wrap-col-left col-left-wrap">
                    <div className="product-com-box-layout">
                      <div className="product-ct-wrap-col-left-name">
                        <h1 className="product-ct-wrap-col-left-name-text">
                          # {product_value_arr_advance[0]?.name}
                        </h1>
                        <h1 className="product-ct-wrap-col-left-name-text ms-auto">{product_value_arr[0]?.sku}</h1>

                        <img
                          className="product-ct-wrap-col-left-name-img"
                          src={varient_image} alt=""
                        />
                      </div>
                      <div className="row product-ct-wrap-col-left-input-select-wrap mb-5">
                        <h4 className="pro-sub-heading">{t("Metal")}</h4>
                        <div className="col-xl-7 col-md-12 product-ct-wrap-col-left-input-select-wrap-input">
                          <div className="row gx-2">
                            <div className="col-md-4">
                              <div className="placeholder-up-input w-100">
                                {call ? (
                                  <MetalsList
                                    setstatusSelectedOptions={
                                      setFormValues_metal
                                    }
                                    setFormValues_color={
                                      setFormValues_color
                                    }
                                    selected_id={formValues_metal}
                                  ></MetalsList>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="placeholder-up-input w-100">
                                {call ? (
                                  <ColorsList
                                    setstatusSelectedOptions={
                                      setFormValues_color
                                    }
                                    selected_id={formValues_color}
                                  ></ColorsList>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="placeholder-up-input w-100">
                                {call ? (
                                  <SizesList
                                    setstatusSelectedOptions={
                                      setFormValues_size
                                    }
                                    prodoct_id={productId_url}
                                    selected_id={formValues_size}
                                  ></SizesList>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {call
                      ? formValues_stone?.map((element, index) => {
                        return (<div className="product-com-box-layout" key={index}>
                          <div className="row product-ct-wrap-col-left-input-select-wrap mb-5">
                            <h4 className="pro-sub-heading" id={'dataStone_' + index}>{t("Stone")}</h4>
                            <div className="col-xl-7 col-md-12">
                              <div className="row gx-2 mb-4 bg-box-input">
                                <div className="col-md-4">
                                  <div className="placeholder-up-input w-100">
                                    <StonegroupsList
                                      setstatusSelectedOptions={
                                        formValues_stone
                                      }
                                      index_stone={index}
                                      selected_id={
                                        element.StoneGroup
                                          ? element.StoneGroup
                                          : ""
                                      }
                                      setFormValues_stone={setFormValues_stone}
                                      formstoneGroup={formstoneGroup}
                                      set_stoneGroup={set_stoneGroup}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="placeholder-up-input w-100">
                                    <StonesList
                                      setstatusSelectedOptions={
                                        formValues_stone
                                      }
                                      index_stone={index}
                                      selected_id={
                                        element.Stone ? element.Stone : ""
                                      }
                                      setFormValues_stone={setFormValues_stone}
                                      formstoneGroup={formstoneGroup}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div
                                    className={
                                      "placeholder-up-input w-100 " +
                                      (product_value_arr[0]
                                        ?.varient_type === 1 ||
                                        product_value_arr[0]
                                          ?.varient_type === 2
                                        ? "yellow-input"
                                        : "")
                                    }
                                  >
                                    <StoneVarientType
                                      setstatusSelectedOptions={
                                        formValues_stone
                                      }
                                      index_stone={index}
                                      selected_id={
                                        element.StoneVariant
                                      }
                                      setFormValues_stone={setFormValues_stone}
                                      formDesignValue={formDesignValue}
                                      setDesignValue={setDesignValue}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row gx-2">
                                <div className="col-md-3">
                                  <div className="placeholder-up-input w-100">
                                    <ShapesList
                                      setstatusSelectedOptions={
                                        formValues_stone
                                      }
                                      index_stone={index}
                                      selected_id={
                                        element.StoneShape
                                          ? element.StoneShape
                                          : "value"
                                      }
                                      formShape_ids={formShape_ids}
                                      setShape_ids={setShape_ids}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="placeholder-up-input w-100">
                                    <CutsList
                                      setstatusSelectedOptions={
                                        formValues_stone
                                      }
                                      setFormValues_stone={setFormValues_stone}
                                      index_stone={index}
                                      selected_id={
                                        element.StoneCut
                                          ? element.StoneCut
                                          : "value"
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="placeholder-up-input w-100">
                                    <ClaritysList
                                      setstatusSelectedOptions={
                                        formValues_stone
                                      }
                                      setFormValues_stone={setFormValues_stone}
                                      index_stone={index}
                                      selected_id={
                                        element.StoneClarity
                                          ? element.StoneClarity
                                          : ""
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="placeholder-up-input w-100">
                                    <StonecolorsList
                                      setstatusSelectedOptions={
                                        formValues_stone
                                      }
                                      setFormValues_stone={setFormValues_stone}
                                      index_stone={index}
                                      selected_id={
                                        element.StoneColor
                                          ? element.StoneColor
                                          : ""
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="product-ct-wrap-col-left-ecommerce-cat-box product-ct-wrap-col-left-additional-info-box">
                            <h4 className="product-ct-wrap-col-left-additional-info-box-heading">
                              {t("Additional Information")}
                              <span className="question-mark">{t("?")}</span>
                            </h4>
                            <div className="row">
                              <div className="col-md-7">
                                <div className="row gx-2">
                                  <div className="col-md-4">
                                    <div className="placeholder-up-input w-100">
                                      <CertificateTypelist
                                        setstatusSelectedOptions={
                                          formValues_stone
                                        }
                                        setFormValues_stone={setFormValues_stone}
                                        selected_id={
                                          element.Certificatetype
                                            ? element.Certificatetype
                                            : "value"
                                        }
                                        index_stone={index}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="placeholder-up-input form-floating w-100">
                                      <input
                                        onChange={(e) => callcertificatenumber(e.target.value, index, "certificateNo")}
                                        className="placeholder-up-input-field form-control"
                                        defaultValue={element.certificateNo} placeholder={t("Certificate No.")}
                                        onKeyPress={handleKeyPress}
                                      />
                                      <label className="placeholder-up-input-label">
                                        {t("Certificate No.")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5">
                                {!index ? (
                                  <button
                                    type="button"
                                    onClick={addFormFields_stone}
                                    className="add-more-pro ct-disable-btn"
                                  >
                                    <img
                                      src={
                                        base_assets +
                                        "images/icons/add-detail.png"
                                      } alt=""
                                    />
                                    {t("Add more stones")}
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      removeFormFields_stone(index)
                                    }
                                    className="add-more-pro danger-text ct-disable-btn"
                                  >
                                    <img
                                      src={
                                        base_assets +
                                        "images/icons/minus-detail.png"
                                      } alt=""
                                    />
                                    {t("Remove")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>)
                      })
                      : ""}

                    <div className="product-com-box-layout tablet">
                      {/* <div className="row  mb-5">
                        <h4 className="pro-sub-heading">
                          {t("Additional product image")}
                        </h4>
                        <div className="col-md-12">
                          <div className="gallery-upload-area"> */}
                      <Gallery setImages_product={setImages_product} setproductsImages={productsImages} />
                      {/* </div>
                        </div>
                      </div> */}

                      <div className="row  mb-5">
                        <h4 className="pro-sub-heading">{t("other details")}</h4>
                        <div className="col-md-12">
                          <table className="com-product-table">
                            <thead>
                              <tr>
                                <th>{t("Location")}</th>
                                <th>{t("Current Stock")}</th>
                                <th>{t("Available Stock")}</th>
                                <th>{t("Reorder Point")}</th>
                                <th>{t("Reorder Quantity")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {call
                                  ? formValues_otherDetails.map(
                                    (element, index) => (

                                      <tr key={index}>

                                        <td>
                                          <LocationsListOtherDetails
                                            setOtherDetailsLocation={
                                              setOtherDetailsLocation
                                            }
                                            setstatusSelectedOptions={
                                              formValues_otherDetails
                                            }
                                            index_stone={index}
                                            selected_id={element.location}
                                          />
                                        </td>

                                        <td>
                                          <div className="placeholder-up-input w-100">
                                            <input
                                              onChange={(e) =>
                                                map_otherDetails(
                                                  index,
                                                  "currentstock",
                                                  e.target.value ? e.target.value : 0
                                                )
                                              }
                                              className="h-100"
                                              value={
                                                element?.currentstock
                                                  ? parseInt(element.currentstock)
                                                  : 0
                                              }
                                              onKeyPress={handleKeyPress}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="placeholder-up-input w-100">
                                            <input
                                              onChange={(e) =>
                                                map_otherDetails(
                                                  index,
                                                  "availablestock",
                                                  e.target.value ? e.target.value : 0
                                                )
                                              }
                                              className="h-100"
                                              value={
                                                element?.availablestock
                                                  ? parseInt(element.availablestock)
                                                  : 0
                                              }
                                              onKeyPress={handleKeyPress}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="placeholder-up-input w-100">
                                            <input
                                              onChange={(e) =>
                                                map_otherDetails(
                                                  index,
                                                  "reorderpoint",
                                                  e.target.value
                                                )
                                              }
                                              className="h-100"
                                              value={
                                                element?.reorderpoint
                                                  ? parseInt(element.reorderpoint)
                                                  : 0
                                              }
                                              onKeyPress={handleKeyPress}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="placeholder-up-input w-100">
                                            <input
                                              value={
                                                element?.reorderquantity
                                                  ? parseInt(element.reorderquantity)
                                                  : 0
                                              }
                                              onChange={(e) =>
                                                map_otherDetails(
                                                  index,
                                                  "reorderquantity",
                                                  e.target.value
                                                )
                                              }
                                              className="h-100"
                                              onKeyPress={handleKeyPress}
                                            />
                                          </div>
                                        </td>

                                        <td>
                                          {!index ? (
                                            <button
                                              type="button"
                                              onClick={
                                                addFormFields_otherDetails
                                              }
                                              className="row-btn ct-disable-btn"
                                            >

                                              <img
                                                src={
                                                  base_assets +
                                                  "images/icons/add-detail.png"
                                                } alt=""
                                              />
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                removeFormFields_otherDetails(
                                                  index
                                                )
                                              }
                                              className="row-btn ct-disable-btn"
                                            >

                                              <img
                                                src={
                                                  base_assets +
                                                  "images/icons/minus-detail.png"
                                                } alt=""
                                              />
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )
                                  : ""}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="row  mb-5">
                        <h4 className="pro-sub-heading">{t("Pricing")}</h4>
                        <div className="col-md-12">
                          <table className="com-product-table">
                            <thead>
                              <tr>
                                <th>{t("Location")}</th>
                                <th className="w-12">{t("Currency")}</th>
                                <th>{t("Retail price (Ex. Tax)")} </th>
                                <th className="w-12">{t("Tax Rate")}</th>
                                <th className="w-12">{t("Tax")}</th>
                                <th>{t("Retail price (Inc. Tax)")}</th>
                                <th>{t("Inventory price (Inc. Tax)")}</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {call
                                  ? formValues_pricing?.length ? formValues_pricing.map((element, index) => (
                                    <tr key={index}>
                                      <td>
                                        <LocationsListPricing
                                          setPriceLocation={setPriceLocation}
                                          setPricetax={setPricetax}
                                          setstatusSelectedOptions={
                                            formValues_pricing
                                          }
                                          index_stone={index}
                                          selected_id={
                                            element?.location
                                              ? element?.location
                                              : "value"
                                          }
                                        />
                                      </td>
                                      <td>
                                        <div className="placeholder-up-input w-100">
                                          <input
                                            value={element.currency}
                                            className="h-100"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="placeholder-up-input w-100">
                                          <input
                                            onChange={(e) =>
                                              pricing_map_change(
                                                index,
                                                "retailprice_Ex",
                                                e.target.value
                                              )
                                            }
                                            value={
                                              element.retailprice_Ex
                                            }
                                            id={"retailprice_Ex_" + index}
                                            className="h-100"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <TaxsList
                                          setPricetax={setPricetax}
                                          setstatusSelectedOptions={
                                            formValues_pricing
                                          }
                                          index_stone={index}
                                          selected_id={
                                            element?.taxrate
                                              ? element?.taxrate
                                              : "value"
                                          }
                                        />
                                      </td>
                                      <td>
                                        <div className="placeholder-up-input w-100">
                                          <input
                                            value={element?.tax}
                                            className="h-100"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="placeholder-up-input w-100">
                                          <input
                                            onChange={(e) =>
                                              pricing_map_change(
                                                index,
                                                "retailprice_Inc",
                                                e.target.value
                                              )
                                            }
                                            value={
                                              element.retailprice_Inc
                                            }
                                            id={"retailprice_Inc_" + index}
                                            className="h-100"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="placeholder-up-input w-100">
                                          <input
                                            onChange={(e) =>
                                              pricing_map_change(
                                                index,
                                                "inv_retailprice_Inc",
                                                e.target.value
                                              )
                                            }
                                            value={
                                              element.inv_retailprice_Inc
                                            }
                                            id={"inv_retailprice_Inc" + index}
                                            className="h-100"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {!index ? (
                                          <button
                                            type="button"
                                            onClick={() =>
                                              addFormFields_pricing()
                                            }
                                            className="row-btn ct-disable-btn"
                                          >

                                            <img
                                              src={
                                                base_assets +
                                                "images/icons/add-detail.png"
                                              } alt=""
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            onClick={() =>
                                              removeFormFields_pricing(index)
                                            }
                                            className="row-btn ct-disable-btn"
                                          >

                                            <img
                                              src={
                                                base_assets +
                                                "images/icons/minus-detail.png"
                                              } alt=""
                                            />
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))
                                    : (
                                      <tr>
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td colSpan="50" >
                                          <button
                                            type="button"
                                            onClick={() =>
                                              addFormFields_pricing()
                                            }
                                            className="row-btn ct-disable-btn w-100 d-flex align-content-center justify-content-center gap-2"
                                          >
                                            Add
                                            <img
                                              src={
                                                base_assets +
                                                "images/icons/add-detail.png"
                                              } alt=""
                                            />
                                          </button>
                                        </td>
                                      </tr>


                                    ) : ""}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="product-ct-wrap-col-right">
                    <div className="product-sidebar-box box-bg box-input-40">
                      <div className="product-sidebar-box-form">

                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <label>{t("Gross Wt")}</label>
                              </td>
                              <td>
                                <div className="placeholder-up-input w-100">
                                  <input
                                    {...register("GrossWt", {
                                      onBlur: (e) => { setValue('GrossWt', toFormatPrice(e.target.value ? e.target.value : 0)) },
                                    })}
                                    className="box-input"
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>{t("Net Wt")}</label>
                              </td>
                              <td>
                                <div className="placeholder-up-input w-100">
                                  <input
                                    {...register("NetWt", {
                                      onBlur: (e) => { setValue('NetWt', toFormatPrice(e.target.value ? e.target.value : 0)) },
                                    })}

                                    className="box-input"
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>{t("Labor")} ({OrgCurrency})</label>
                              </td>
                              <td>
                                <div className="placeholder-up-input w-100">
                                  <input
                                    {...register("labor", {
                                      onBlur: (e) => { setValue('labor', toFormatPrice(e.target.value ? e.target.value : 0)) },
                                    })}
                                    className="box-input"
                                    onKeyPress={handleKeyPress}
                                  />

                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>{t("Cost Price")} ({OrgCurrency})</label>
                              </td>
                              <td>
                                <div className="placeholder-up-input w-100">
                                  <input
                                    {...register("CostPrice", {
                                      onBlur: (e) => { setValue('CostPrice', toFormatPrice(e.target.value ? e.target.value : 0)) },
                                    })}

                                    className="box-input"
                                    onKeyPress={handleKeyPress}
                                  />

                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label>{t("Unit")}</label>
                              </td>
                              <td>
                                <div className="placeholder-up-input w-100">
                                  <UnitSelect
                                    setUnit={setUnit}
                                    selected_id={formUnit}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {call
                      ? formValues_stone?.map((element, index) => {
                        let poss = '';
                        if (possi_css[index] !== undefined && possi_css[index]) {
                          poss = possi_css[index] - 200;
                        }

                        if (Object.keys(element).length !== 0) {
                          return (

                            <div
                              className="product-sidebar-box box-bg box-input-40 position-absolute"
                              key={index}
                              style={{ top: poss }}
                            >

                              <table>
                                <thead></thead>
                                <tbody>
                                  <>
                                    {element?.StoneVariant === "2" &&
                                      advanceProductData.product_variants_enable === "2" ? (
                                      <tr>
                                        <td>
                                          <label>{t("Pointer")}</label>
                                        </td>
                                        <td>
                                          <GetPointer
                                            setstatusSelectedOptions={
                                              formValues_stone
                                            }
                                            index_stone={index}
                                            setFormValues_stone={setFormValues_stone}
                                            selected_id={
                                              element?.stonePointer
                                                ? element?.stonePointer
                                                : ""
                                            }
                                            formShape_ids={formShape_ids}
                                          />
                                        </td>
                                      </tr>
                                    ) : (
                                      ""
                                    )}
                                    <tr>
                                      <td>
                                        <label>{t("Size")}</label>
                                      </td>
                                      <td>
                                        <StoneSizeList
                                          setstatusSelectedOptions={
                                            formValues_stone
                                          }
                                          index_stone={index}
                                          setFormValues_stone={setFormValues_stone}
                                          selected_id={
                                            element?.stoneSize
                                              ? element?.stoneSize
                                              : "value"
                                          }
                                        />
                                        <div className="d-none">{element?.StoneShape}</div>

                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label>{t("Pcs")}</label>
                                      </td>
                                      <td>
                                        <div className="placeholder-up-input w-100">
                                          <input
                                            onChange={(e) => callcertificatenumber(e.target.value, index, "Pcs")}
                                            defaultValue={element.Pcs}
                                            className="box-input handleAmount"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label>{t("Weight")} </label>
                                      </td>
                                      <td className="d-flex flex-nowrap">
                                        <div className="placeholder-up-input w-50" style={{ "padding": "1px" }}>
                                          <input

                                            onChange={(e) => { setValue('Weight.' + index, toFormatPrice(e.target.value ? e.target.value : 0)); callcertificatenumber(e.target.value, index, "Weight") }}

                                            defaultValue={element.Weight}

                                            className="box-input"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                        <div className="placeholder-up-input w-50" style={{ "padding": "1px" }}>
                                          <StoneUnitselect
                                            setstatusSelectedOptions={
                                              formValues_stone
                                            }
                                            index_stone={index}
                                            setFormValues_stone={setFormValues_stone}
                                            selected_id={
                                              element?.WeightType
                                                ? element?.WeightType
                                                : "G"
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label>{t("Price")}</label>
                                      </td>
                                      <td>
                                        <div className="placeholder-up-input w-100">

                                          <input
                                            onChange={(e) => { setValue('stonePrice.' + index, toFormatPrice(e.target.value ? e.target.value : 0)); callcertificatenumber(e.target.value, index, "stonePrice") }}
                                            defaultValue={element.stonePrice}

                                            className="box-input"
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label>{t("Setting")}</label>
                                      </td>
                                      <td>
                                        <StonesettingType
                                          setstatusSelectedOptions={
                                            formValues_stone
                                          }
                                          index_stone={index}
                                          setFormValues_stone={setFormValues_stone}
                                          selected_id={
                                            element?.setting_type
                                              ? element?.setting_type
                                              : "value"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                </tbody>
                              </table>
                            </div>
                          )
                        } else {

                          return (

                            <div
                              className="product-sidebar-box box-bg box-input-40 position-absolute"
                              key={index}
                              style={{ top: poss }}
                            >

                              <table>
                                <thead></thead>
                                <tbody>
                                  {element?.StoneVariant === 2 &&
                                    advanceProductData.product_variants_enable === 2 ? (
                                    <tr>
                                      <td>
                                        <label>{t("Pointer")}</label>
                                      </td>
                                      <td>
                                        <GetPointer
                                          setstatusSelectedOptions={
                                            formValues_stone
                                          }
                                          index_stone={index}
                                          setFormValues_stone={setFormValues_stone}
                                          selected_id={
                                            element?.stonePointer
                                              ? element?.stonePointer
                                              : ""
                                          }
                                          formShape_ids={formShape_ids}
                                        />
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  <tr>
                                    <td>
                                      <label>{t("Size")}</label>
                                    </td>
                                    <td>
                                      <GetShapeToStoneSize
                                        setstatusSelectedOptions={
                                          formValues_stone
                                        }
                                        index_stone={index}
                                        setFormValues_stone={setFormValues_stone}
                                        selected_id={
                                          element?.stoneSize
                                            ? element?.stoneSize
                                            : ""
                                        }
                                        formShape_ids={formShape_ids}
                                        send_StoneVarint={element?.StoneVariant}
                                        StoneShape_id={element?.StoneShape}
                                      />

                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>{t("Pcs")}</label>
                                    </td>
                                    <td>
                                      <div className="placeholder-up-input w-100">
                                        <input
                                          {...register("Pcs." + index)}
                                          className="box-input handleAmount"
                                          onKeyPress={handleKeyPress}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>{t("Weight")} </label>
                                    </td>
                                    <td>
                                      <div className="placeholder-up-input w-100">
                                        <input
                                          {...register("Weight." + index, {
                                            onBlur: (e) => { setValue('Weight.' + index, toFormatPrice(e.target.value ? e.target.value : 0)) },
                                          })}
                                          className="box-input"
                                          onKeyPress={handleKeyPress}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>{t("Price")}</label>
                                    </td>
                                    <td>
                                      <div className="placeholder-up-input w-100">
                                        <input
                                          {...register("stonePrice." + index, {
                                            onBlur: (e) => { setValue('stonePrice.' + index, toFormatPrice(e.target.value ? e.target.value : 0)) },
                                          })}
                                          className="box-input"
                                          onKeyPress={handleKeyPress}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <label>{t("Setting")}</label>
                                    </td>
                                    <td>
                                      <StonesettingType
                                        setstatusSelectedOptions={
                                          formValues_stone
                                        }
                                        index_stone={index}
                                        setFormValues_stone={setFormValues_stone}
                                        selected_id={
                                          element?.setting_type
                                            ? element?.setting_type
                                            : "value"
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )
                        }
                      })
                      : ""}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductAdvanced;
