import React, { useState, useEffect } from "react"
import { _Api } from "../api/_call";
import TableColumnModal from "../common/modules/tableColumn";
import { Tablehead } from "../common/modules/Tablehead";
import DateTime from "../common/DateTime";
import { reportstockreceivelisthead } from "../common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../api/setdatatable";
import "datatables.net-fixedcolumns";
import { ReportMenuModal } from "../common/modules/reportMenu";
import { DateRange } from "../common/helpers/daterange";
import SocialModal from "../common/modules/SocialModal/SocialModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { toFormatPrice, toUnformatPrice } from "../common/helpers/function";
import ProductModal from "../common/modules/ProductModal";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import Select from "react-select";
const POreport = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [filtername, setFiltername] = useState('');
  const [socialurl, setSocialurl] = useState();
  const [datefilter, setDatefilter] = useState([]);
  const [limit, setLimit] = useState(100);
  const [Reportstockreceive, setReportstockreceive] = useState();
  const [reportstockreceivecounts, setReportstockreceivecounts] = useState([]);
  const [rowlength, setRowlength] = useState(0);
  const [totalresult, setTotalresult] = useState();
  const [newcolumndata, setnewcolumndata] = useState(reportstockreceivelisthead);
  const [getoneproduct, setOneproduct] = useState();
  const [shareloader, setShareloader] = useState(false);
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const filterbyname = (search) => {
    if (filtername) {
      Getreportstockreceive(limit, search);
    } else {
      Getreportstockreceive(limit, search);
    }
  }
  useEffect(() => {
    callcolumns();
  }, []);
  const getsharestockreceive = async () => {
    setShareloader(true);
    try {
      const postdata = {
        "search": filtername,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/stockreceive`);

      if (res.code === 200) {
        setSocialurl(res?.link);
        setShareloader(false);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }

  }
  const getprintstockreceive = async () => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "search": filtername,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/stockreceive`);
      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a');
          exportLinkElement.hidden = true;
          exportLinkElement.download = res?.name ? res?.name : "report.pdf";
          exportLinkElement.href = res?.link;
          exportLinkElement.text = "downloading...";
          exportLinkElement.setAttribute('target', '_blank');
          document.body.appendChild(exportLinkElement);
          exportLinkElement.click();
          exportLinkElement.remove();
          Updateloading(t, GetloadingID, t("download successfully"));

        }, 50)
        setSocialurl(res?.link);
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, t('error'));
    }

  }
  const Getreportstockreceive = async (firstlimit, search) => {
    UnSetdataTable("report_stockreceive");
    setDatsLodaer(true)
    try {
      const postdata = {
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/report/getReportStockReceive`);
      if (res.code === 200) {
        setReportstockreceive(res?.data);
        setRowlength(0);
        setTimeout(() => {
          SetdataTable("report_stockreceive", 5, newcolumndata, [0]);
        }, 50)
        setReportstockreceivecounts(res?.count);
        setTotalresult(res?.total);
        setDatsLodaer(false)
      }
    }
    catch (err) {
      SetdataTable("report_stockreceive", 5, newcolumndata, [0]);
      console.log(err, 'err');
    }
  }
  const fetchMoreData = () => {
    if (totalresult !== Reportstockreceive?.length) {
      var len = rowlength + parseInt(limit);
      setRowlength(len);
      UnSetdataTable("report_stockreceive");
      setTimeout(async () => {
        try {
          const posdata = {
            search: filtername,
            "limit": limit,
            "skip": len,
            "start_date": datefilter ? datefilter[0] : "",
            "end_date": datefilter ? datefilter[1] : "",
          }
          let res = await _Api(posdata, `api/v1/Inventory/report/getReportStockReceive`);
          if (res.code === 200) {
            setTimeout(() => {
              SetdataTable("report_stockreceive", 5, newcolumndata, [0]);
            }, 50)
            setReportstockreceive(Reportstockreceive.concat(res.data));
          }
        } catch (err) {
          console.log(err, 'err');
        }
      }, 1000);
    } else {
      UnSetdataTable("report_stockreceive");
      setTimeout(() => {
        SetdataTable("report_stockreceive", 5, newcolumndata, [0]);
      }, 50)

    }

  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_report_stockreceive",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Getreportstockreceive();
    }
    // eslint-disable-next-line
  }, [datefilter]);
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    reportstockreceivelisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (Reportstockreceive.length) {
      Reportstockreceive.forEach((result, key) => {
        bodyarray.push([key + 1, result?.createdAt, result?.receive_date, result?.tf_no, result?.SKU, result?.stock_id, result?.metal_name, result?.stone_name, result?.size_name, result?.po_QTY, result?.price, result?.transfer_from_loc_name, result?.transfer_to_location_name, result?.delivery_date, result?.status === 3 ?
          "Transit" :
          result?.status === 4 ?
            "Received"
            : "Canceled",
        result?.user_actions?.created_by_name, result?.user_actions?.updated_by_name])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "report_stockreceive");
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <React.Fragment>
      <div className="main-body report-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h2 className="main-body-heading"><span>{t("report")} {">"}</span>&nbsp;&nbsp;{t("Stock Receive")}</h2>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/free-stock.png"}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Total Qty")}</span>
                      <h2 className="inventory-value stock">{reportstockreceivecounts?.total ? reportstockreceivecounts?.total : '0'}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/sku.png"}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Sku")}</span>
                      <h2 className="inventory-value sku">{reportstockreceivecounts?.SKU ? reportstockreceivecounts?.SKU : '0'}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/location.png"}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Location's From")}</span>
                      <h2 className="inventory-value location">{reportstockreceivecounts?.transfer_from_loc ? reportstockreceivecounts?.transfer_from_loc : '0'}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/loacation_on_green.png"}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Location's To")}</span>
                      <h2 className="inventory-value value">{reportstockreceivecounts?.transfer_to_location ? reportstockreceivecounts?.transfer_to_location : '0'}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <ReportMenuModal />
                <Select
                  className="limit-select short z-10"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); Getreportstockreceive(e.value); setRowlength(0) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    value={filtername}
                    type="text"
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("Product name/Stock Id/SKU/Customer/Barcode")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className={parmissiondatashow?.inventory_reports?.Print ? "dropdown-item print-dropdown-item" : "d-none"} onClick={(e) => { getprintstockreceive(e.target.value); }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>
                      <li className="dropdown-item" onClick={(e) => { downloadexclsheet(e.target.value); }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>

                      <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#socialModal" onClick={() => getsharestockreceive()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/share-icon.png"}
                            alt=""
                          />
                        </div>
                        <span >{t("share")}</span>
                      </li>

                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <InfiniteScroll
                  dataLength={Reportstockreceive ? Reportstockreceive?.length : ''}
                  next={totalresult > rowlength ? fetchMoreData : ''}
                  hasMore={totalresult > rowlength ? true : false}
                  loader={Reportstockreceive?.length !== totalresult ? totalresult > rowlength ? <h6>{t("Loading more items...")}</h6> : '' : ''}
                >
                  <table
                    id="report_stockreceive"
                    className="stripe row-border order-column common-table table-striped main-table w-100"
                  >
                    <Tablehead
                      tablehead={reportstockreceivelisthead}
                      tblName={"tbl_report_stockreceive"}
                      setfilterby={""}
                    />
                    <tbody>
                      <>
                        {Reportstockreceive ? Reportstockreceive.map((result, key) => {
                          return (
                            <tr className="" key={key}>
                              <td className="fixed-side">{key + 1}</td>
                              <td className="fixed-side">{result?.createdAt}</td>
                              <td className="fixed-side">{result?.receive_date}</td>
                              <td className="fixed-side link-color">{result?.tf_no}</td>
                              <td className="fixed-side link-color" onClick={() => setOneproduct(result)} data-bs-toggle="modal" data-bs-target="#ProductInfoModal">{result?.SKU}</td>
                              <td className="fixed-side">{result?.stock_id}</td>
                              <td className="fixed-side">{result?.metal_name}</td>
                              <td className="fixed-side">{result?.stone_name}</td>
                              <td className="fixed-side">{result?.size_name}</td>
                              <td className="fixed-side">{result?.po_QTY}</td>
                              <td className="fixed-side">{result?.price}</td>
                              <td className="fixed-side">{result?.transfer_from_loc_name}</td>
                              <td className="fixed-side">{result?.transfer_to_location_name}</td>
                              <td className="fixed-side">{result?.delivery_date}</td>
                              <td className="fixed-side">
                                {
                                  result?.status === 3 ?
                                    <label className="light-blue-text">{t("Transit")}</label> :
                                    result?.status === 4 ?
                                      <label className="status-green-text">{t("Received")}</label>
                                      : <label className="danger-text">{t("Canceled")}</label>
                                }
                              </td>
                              <td className="fixed-side">{result?.user_actions?.created_by_name}</td>
                              <td className="fixed-side">{result?.user_actions?.updated_by_name}</td>
                            </tr>
                          );
                        }) : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td >{Reportstockreceive
                          ? Reportstockreceive?.reduce(
                            (interation, val) =>
                            (interation =
                              interation + parseInt(val.po_QTY)),
                            0
                          )
                          : ""}</td>
                        <td >{
                          toFormatPrice(Reportstockreceive
                            ? Reportstockreceive?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.price)),
                              0
                            )
                            : "", { addSymbol: true })
                        }</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />

                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "mt-4" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit='' />
      </div>
      <TableColumnModal tablehead={reportstockreceivelisthead} tblName={"tbl_report_stockreceive"} setnewcolumndata={setnewcolumndata}
        calldatatable={Getreportstockreceive} ></TableColumnModal>
    </React.Fragment>
  );
};
export default POreport;
