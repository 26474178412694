import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { _Api } from '../../common/api/_call'
import './DiamondDetail.scss'
import CheckoutBlank from '../../checkout/checkoutBlank'
import Loading from '../../common/module/Loading/Loading'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const DiamondDetail = (props) => {
  const { t } = useTranslation()
  const [Loadingstatus, setLoadingstatus] = useState(true)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { state } = useLocation()
  console.log(state, "state in d")
  const [details, setDetails] = useState()
  const [items, setItem] = useState()
  const getdetails = async () => {
    try {
      const posdata = {
        id: state?.daimondid ? state?.daimondid : state?.daimond_id,
      }
      let res = await _Api(posdata, `api/v1/Inventory/diamond/getDetails`)
      if (res.code === 200) {
        setLoadingstatus(false)
        setDetails(res.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getitem = async () => {
    try {
      const posdata = { type: 'mixAndMatch' }
      let res = await _Api(posdata, `api/v1/POS/home/itemList`)
      if (res.code === 200) {
        setItem(res.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (state?.daimondid || state?.daimond_id) {
      getdetails()
      getitem()
    }
    // eslint-disable-next-line
  }, [])
  const [parmission, setParmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.pos_mix_and_match?.View) {
      setParmission(parmissiondatashow?.pos_mix_and_match?.Add);
    }
  }, [parmissiondatashow]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area bg-white page-menu-block">
              <div className="common-navigation-header diamond-detail-header">
                {!state?.order_edit ?
                  <div className="common-navigation-header-left">
                    <Link
                      to="/app/pos/custom/mixAndMatch"
                      state={{
                        itemchanges: state?.itemchanges,
                        items: state?.items,
                        daimond_id: state?.editproduct ? state?.daimondid : "",
                        byitemsorder: true,
                        optiondetailid: state?.optiondetailid,
                        product_id: state?.product_id,
                        Engraving: state?.Engraving,
                        editproduct: state?.editproduct,
                        customcart: state?.customcart,
                        scrvicelist: state?.scrvicelist
                          ? state?.scrvicelist
                          : [],
                        scrviceremark: state?.scrviceremark,
                        remark: state?.remark ? state?.remark : '',
                      }}
                      className="page-back"
                    >
                      <img
                        className="page-back-img"
                        src={base_assets + 'images/pos/icons/arrow-gray-left.png'}
                        alt=""
                      />
                    </Link>
                  </div> : ""}
                <div className="common-navigation-header-right">
                  <div className="com-search-area">
                    <button className="com-search-area-left-img scan-img">
                      <img
                        src={base_assets + 'images/pos/icons/scan-icon.png'}
                        alt=""
                      />
                    </button>
                    <input type="text" placeholder="Scan barcode, Search SKU" />
                    <img
                      className="com-search-area-img"
                      src={
                        base_assets + 'images/pos/icons/magnifying-glass.png'
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="position-relative h-100 overflow-auto">
                {Loadingstatus ? <Loading /> : ''}
                <div className="diamond-detail-section">
                  <div className="diamond-detail-section-row">
                    <div className="diamond-detail-section-row-col">
                      <div className="diamond-detail-section-row-col-pro-img">
                        <img
                          src={
                            details?.image[0]
                              ? details?.image[0]
                              : base_assets + 'images/pos/ring-4.png'
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="diamond-detail-section-row-col">
                      <div className="diamond-detail-section-row-col-detail">
                        <h1 className="diamond-detail-section-row-col-detail-heading">
                          {details?.Carat} {t('Carats')} {details?.Shape} -
                          {details?.Colour} / {details?.Clarity}
                        </h1>
                        <span className="diamond-detail-section-row-col-detail-heading-num">
                          {details?.StockID}
                        </span>
                        <p className="diamond-detail-section-row-col-detail-heading-sub">
                          {details?.Carat} {t('Carats')}, {details?.Colour}{' '}
                          {t('Colour')},{details?.Clarity} {t('Clarity')}{' '}
                          {details?.Shape} ,{details?.Cut} {t('Cut')}
                        </p>
                        <div className="diamond-detail-section-row-col-detail-gia-report">
                          <img
                            className="diamond-detail-section-row-col-detail-gia-report-img"
                            src={base_assets + 'images/icons/gia.png'}
                            alt=""
                          />
                          <span className="diamond-detail-section-row-col-detail-gia-report-title">
                            {details?.GradedBy} {t('Report')}
                          </span>
                          <a
                            className="diamond-detail-section-row-col-detail-gia-report-sku link-color text-decoration-none"
                            href={details?.CertificationUrl}
                          >
                            {details?.Certification}
                          </a>
                          <img
                            className="diamond-detail-section-row-col-detail-gia-report-graph-img"
                            src={base_assets + 'images/icons/exp-view.png'}
                            alt=""
                          />
                        </div>
                        <span className="diamond-detail-section-row-col-detail-price">
                          {details?.Price}
                        </span>
                        {details?.Status === 2 ? (
                          <div className="border-inner-btn hover-effect overflow-visible common-dropdown w-100">
                            <button className="add-to-cart">
                              {t('Reserved')}
                            </button>
                          </div>
                        ) : (
                          <div className="border-inner-btn hover-effect overflow-visible common-dropdown green-hover-dropdown w-100">
                            {state?.items?.id ? (
                              state?.bychangeorder ? (
                                <Link
                                  to="/app/pos/custom/mixAndMatchProduct"
                                  state={{
                                    daimond_id: state?.daimondid,
                                    items: state?.items,
                                    byitemsorder: true,
                                    itemchanges: state?.itemchanges,
                                    product_id: state?.product_id,
                                    optiondetailid: state?.optiondetailid,
                                    Engraving: state?.Engraving,
                                    editproduct: state?.editproduct,
                                    customcart: state?.customcart,
                                    scrvicelist: state?.scrvicelist,
                                    scrviceremark: state?.scrviceremark,
                                    remark: state?.remark
                                  }}
                                >
                                  {t('Select This')}
                                </Link>
                              ) : (
                                <Link
                                  to="/app/pos/custom/mixAndMatchProduct"
                                  state={{
                                    daimond_id: state?.daimondid,
                                    items: state?.items,
                                    fromdaimond: true,
                                    itemchanges: state?.itemchanges,
                                    product_id: state?.product_id,
                                    optiondetailid: state?.optiondetailid,
                                    Engraving: state?.Engraving,
                                    editproduct: state?.editproduct,
                                    customcart: state?.customcart,
                                    scrvicelist: state?.scrvicelist,
                                    scrviceremark: state?.scrviceremark,
                                    remark: state?.remark
                                  }}
                                >
                                  {t('Select This')}
                                </Link>
                              )
                            ) : (
                              <button
                                className="add-to-cart dropdown-toggle"
                                id={parmission ? 'dropdownMenuButton1' : ''}
                                data-bs-toggle={parmission ? 'dropdown' : ''}
                                aria-expanded="false"
                              >
                                {t('ADD TO CART')}
                              </button>
                            )}
                            <ul
                              className="dropdown-menu detail-paga-dropdown"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              {items?.length
                                ? items.map((result, i) => {
                                  return (
                                    <li key={i}>
                                      <Link
                                        className="dropdown-item justify-content-start"
                                        to="/app/pos/custom/mixAndMatchProduct"
                                        state={{
                                          daimond_id: state?.daimondid
                                            ? state?.daimondid
                                            : state?.daimond_id,
                                          items: {
                                            id: result?._id,
                                            name: result?.name,
                                          },
                                          order_edit: state?.order_edit,
                                          order_id: state?.order_id,
                                          order_type: state?.order_type,
                                          mixmatchedit: state?.mixmatchedit,
                                          product_id: state?.product_id,
                                          editproduct: state?.editproduct,
                                          customcart: state?.customcart,
                                          scrvicelist: state?.scrvicelist,
                                          scrviceremark: state?.scrviceremark,
                                          remark: state?.remark,
                                          showpage: state?.showpage,
                                          Engraving: state?.Engraving,
                                        }}
                                      >
                                        <span className="dropdown-item-img">
                                          <img
                                            src={
                                              result?.logo ? result?.logo : ''
                                            }
                                            alt=""
                                          />
                                        </span>
                                        {result?.name}
                                      </Link>
                                    </li>
                                  )
                                })
                                : ''}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="diamond-detail-section-diamond-detail">
                    <div className="diamond-detail-section-diamond-detail-fieldset">
                      <div className="diamond-detail-section-diamond-detail-fieldset-legend">
                        {t('Diamond Details')}
                      </div>
                      <ul className="diamond-detail-section-diamond-detail-fieldset-ul">
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Stock ID')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.StockID}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Shape')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Shape}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Carat')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Carat}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Colour')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Colour}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Clarity')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Clarity}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Cut')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Cut}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <ul className="diamond-detail-section-diamond-detail-fieldset-ul">
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Polish')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Polish}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Symmetry')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Symmetry}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Fluorescence')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Fluoresence}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Depth')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Depth}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Table')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Table}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Girdle')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Gridle}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <ul className="diamond-detail-section-diamond-detail-fieldset-ul">
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Culet')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Culet}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Measurements')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Measurement}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Graded By')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.GradedBy}
                            </span>
                          </div>
                        </li>
                        <li className="diamond-detail-section-diamond-detail-fieldset-ul-list">
                          <div className="diamond-detail-section-diamond-detail-fieldset-ul-list-single">
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-heading">
                              {t('Certification')}
                            </span>
                            <span className="diamond-detail-section-diamond-detail-fieldset-ul-list-single-data">
                              {details?.Certification}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )
}

export default DiamondDetail
