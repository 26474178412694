import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { _Api } from '../../api/_call'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import DateTime from '../../common/DateTime'
import { Tablehead } from '../../common/modules/Tablehead'
import TableColumnModal from '../../common/modules/tableColumn'
import { VoucherList } from '../../common/graphql/commonlayouts'
import Advancedfiltermodel from '../../common/modules/advanced-filter-model'
import { formatDate } from '../../common/helpers/formatDate'
import { createtransferinventoryhead, createtransferinventoryheadcheck } from '../../common/Tablehead'
import { error } from '../../common/helpers/toastify'
import { toUnformatPrice, toFormatPrice } from '../../common/helpers/function'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from "react-infinite-scroll-component";
import Filtermodal from '../../common/modules/Filtermodal'
import Select from "react-select"
import TableLoader from '../../../admin/common/TableLoader'
import Stockidmodal from "../../common/modules/Stockidmodal";

const CreateTransferInventory = (props) => {
  const { t } = useTranslation()

  const dataFetched = useRef()

  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [datalodaer, setDatsLodaer] = useState(false)
  const [limit, setLimit] = useState(100)
  const [filtername, setFiltername] = useState('')
  const [transferinventorylist, setTransferinventorylist] = useState([])
  const [createlist, setCreatelist] = useState([])
  const [selectedlocation, setSelectedlocation] = useState()
  const [selectedwarehouse, setSelectedwarehouse] = useState('')
  const [SelectAllStatus, setSelectAllStatus] = useState()
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [commanSelected_vouc, setcommanSelected_vouc] = useState(null)
  const [allcheck, setallCheck] = useState(false)
  const [rowlength, setRowlength] = useState(0);
  const [totalresult, setTotalresult] = useState();
  const [getSkipNumbers, setSkipNumbers] = useState(0)
  const [payload, setPayload] = useState()
  const filterbyname = (search) => {
    Gettransferinventory(currentPage, limit, payload, search)
    setstate({ ...state, currentPage: 1 })
  }
  const getlimit = (limit) => {
    setLimit(limit)
    Gettransferinventory(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })

  const { currentPage } = state

  const Gettransferinventory = useCallback(
    async (current, firstlimit, filter, search, stock_id) => {
      UnSetdataTable('tbl_createTransfer_list')
      setDatsLodaer(true)
      try {
        var skipNumber = current ? (current - 1) * limit : 0
        const postdata = {
          item: filter?.item ? filter?.item : [],
          collection: filter?.collection ? filter?.collection : [],
          metal: filter?.metal ? filter?.metal : [],
          size: filter?.size ? filter?.size : [],
          stone: filter?.stone ? filter?.stone : [],
          productStatus: filter?.status ? filter?.status : filter?.productStatus ? filter?.productStatus : [],
          location: filter?.location ? filter?.location : [],
          price: filter?.price ? filter?.price : [],
          search: search ? search : "",
          "limit": firstlimit ? firstlimit : limit,
          skip: rowlength,
          stock_ids: stock_id?.length ? stock_id : []
        }
        let restransferinventory = await _Api(
          postdata,
          `api/v1/Inventory/stocktransfer/getInventoryList`,
        )
        if (restransferinventory.code === 200) {
          setPayload(postdata)
          setTotalresult(restransferinventory.count);
          setallCheck(restransferinventory?.checklocations)
          let updatedata = restransferinventory.data.map(item => ({ ...item, rowclass: '' }));
          setTransferinventorylist(updatedata)
          setDatsLodaer(false)
          setSkipNumbers(skipNumber)
          setRowlength(skipNumber);
          SetdataTable('tbl_createTransfer_list', '5', restransferinventory?.checklocations ? createtransferinventoryheadcheck : createtransferinventoryhead, [
            1,
            4,
            9,
            10,
          ])
          dataFetched.current = true

        } else {
          SetdataTable('tbl_createTransfer_list', '5', createtransferinventoryhead)
        }
      } catch (err) {
        console.log(err, 'err')
      }
    },
    // eslint-disable-next-line
    [filtername, limit, state],
  )
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const fetchMoreData = () => {
    UnSetdataTable('tbl_createTransfer_list')
    if (totalresult !== transferinventorylist?.length) {
      var len = rowlength + parseInt(limit);
      setDatsLodaer(true)
      setTimeout(async () => {
        try {
          const posdata = {
            item: payload?.item ? payload?.item : [],
            collection: payload?.collection ? payload?.collection : [],
            metal: payload?.metal ? payload?.metal : [],
            size: payload?.size ? payload?.size : [],
            productStatus: payload?.productStatus ? payload?.productStatus : [],
            location: payload?.location ? payload?.location : [],
            stone: payload?.stone ? payload?.stone : [],
            price: payload?.price ? payload?.price : [],
            search: filtername,
            limit: limit,
            skip: len,
          }
          let res = await _Api(posdata, `api/v1/Inventory/stocktransfer/getInventoryList`);
          if (res.code === 200) {
            setRowlength(len);
            let updatedata = res.data.map(item => ({ ...item, rowclass: '' }));
            let finaldata = updatedata.concat(transferinventorylist);
            const firstLocationId = finaldata[0]?.location_id;
            const filteredData = finaldata.filter(item => item.location_id === firstLocationId);
            let checktable = finaldata?.length > filteredData?.length ? false : true
            setallCheck(checktable)
            setTimeout(async () => {
              setTransferinventorylist(prevList => [...prevList, ...updatedata]);
              SetdataTable('tbl_createTransfer_list', '5', checktable ? createtransferinventoryheadcheck : createtransferinventoryhead, [
                1,
                4,
                9,
                10,
              ])
            }, 2000);
            setDatsLodaer(false)
          }

        } catch (err) {
          console.log(err, 'err');
        }
      }, 1000);


    }
  };
  const submitlist = (event) => {
    const array = [...createlist]
    const mainarray = [...transferinventorylist]
    if (event.target.checked) {
      const currentid = mainarray.filter((all) => {
        return all.id === event.target.value
      })

      if (!selectedwarehouse) {
        setSelectedlocation(currentid[0]?.location_id)
        setSelectedwarehouse(
          currentid[0]?.warehouse_id ? currentid[0]?.warehouse_id : 'null',
        )
      }

      mainarray.forEach((result, key) => {
        if (
          result?.location_id !==
          (selectedlocation ? selectedlocation : currentid[0]?.location_id)
        ) {
          mainarray[key] = {
            ...mainarray[key],
            rowclass: 'row-inactive',
          }
        } else {
          if (currentid[0]?.warehouse_id === '') {
            if (selectedwarehouse === '' || selectedwarehouse === 'null') {
              mainarray[key] = {
                ...mainarray[key],
                rowclass: '',
              }
            } else {
              if (
                result?.warehouse_id !==
                (selectedwarehouse
                  ? selectedwarehouse
                  : currentid[0]?.warehouse_id
                    ? currentid[0]?.warehouse_id
                    : 'null')
              ) {
                if (
                  result?.warehouse_id === '' ||
                  (selectedwarehouse
                    ? selectedwarehouse
                    : currentid[0]?.warehouse_id
                      ? currentid[0]?.warehouse_id
                      : 'null') === ''
                ) {
                  mainarray[key] = {
                    ...mainarray[key],
                    rowclass: '',
                  }
                } else {
                  mainarray[key] = {
                    ...mainarray[key],
                    rowclass: 'row-inactive',
                  }
                }
              } else {
                mainarray[key] = {
                  ...mainarray[key],
                  rowclass: '',
                }
              }
            }
          } else {
            if (selectedwarehouse === 'null') {
              setSelectedwarehouse(currentid[0]?.warehouse_id)
              if (result?.warehouse_id !== currentid[0]?.warehouse_id) {
                if (result?.warehouse_id === '') {
                  mainarray[key] = {
                    ...mainarray[key],
                    rowclass: '',
                  }
                } else {
                  mainarray[key] = {
                    ...mainarray[key],
                    rowclass: 'row-inactive',
                  }
                }
              } else {
                mainarray[key] = {
                  ...mainarray[key],
                  rowclass: '',
                }
              }
            } else {
              if (
                result?.warehouse_id !==
                (selectedwarehouse
                  ? selectedwarehouse
                  : currentid[0]?.warehouse_id
                    ? currentid[0]?.warehouse_id
                    : 'null')
              ) {
                if (
                  result?.warehouse_id === '' ||
                  (selectedwarehouse
                    ? selectedwarehouse
                    : currentid[0]?.warehouse_id
                      ? currentid[0]?.warehouse_id
                      : 'null') === ''
                ) {
                  mainarray[key] = {
                    ...mainarray[key],
                    rowclass: '',
                  }
                } else {
                  mainarray[key] = {
                    ...mainarray[key],
                    rowclass: 'row-inactive',
                  }
                }
              } else {
                mainarray[key] = {
                  ...mainarray[key],
                  rowclass: '',
                }
              }
            }
          }
        }
      })
      setTransferinventorylist(mainarray)
      array.push(event.target.value)
      setCreatelist(array)
    } else {
      if (array.length === 1) {
        setCreatelist((current) =>
          current.filter((item) => item !== event.target.value),
        )
        mainarray.forEach((result, key) => {
          mainarray[key] = {
            ...mainarray[key],
            rowclass: '',
          }
          setTransferinventorylist(mainarray)
        })
        setSelectedlocation('')
        setSelectedwarehouse('')
      } else {
        setCreatelist((current) =>
          current.filter((item) => item !== event.target.value),
        )
      }
    }
  }
  useEffect(() => {
    if (dataFetched.current) return
    dataFetched.current = true
    Gettransferinventory()
  }, [Gettransferinventory])

  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll('.createtransferinventory')
      let arrya = []
      let location_id = []
      let warehouse_id = []
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id')
          let location = selected[i].getAttribute('location_id')
          let warehouse = selected[i].getAttribute('warehouse_id')
          arrya.push(product_id)
          location_id.push(location)
          warehouse_id.push(warehouse)
        }
        setSelectedlocation(location_id[0])
        if (warehouse_id[0]) {
          setSelectedwarehouse(warehouse_id[0])
        } else {
          setSelectedwarehouse('null')
        }

      }
      setCreatelist(arrya)
    } else {
      setCreatelist([])
    }
  }, [SelectAllStatus])
  const selectedproduct = (event) => {
    if (event.target.checked) {
      const mainselected = [...createlist, event.target.value]
      setCreatelist(mainselected)
    } else {
      const result = createlist.filter((element) => {
        return element !== event.target.value
      })
      setCreatelist(result)
    }
  }
  const countstockid = (stiockidindex) => {
    if (stiockidindex) {
      const numbersArray = stiockidindex.split(/\D+/)
      if (numbersArray?.length) {
        Gettransferinventory(currentPage, 'ALL', payload, filtername, numbersArray)
      } else {
        error("Somthing Went Wrong");
      }
    } else {
      Gettransferinventory(currentPage, limit, payload, filtername, [])
    }
  };
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link
                    to="/app/inventory/stock_transfer"
                    className="page-back-btn"
                  >
                    <img
                      src={base_url_assets + 'images/icons/arrow-left.png'}
                      alt=""
                    />
                  </Link>
                  <img
                    src={base_url_assets + 'images/icons/home-icon.png'}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={'Stock_Transfer'}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">
                  {t('Create transfer')}
                </h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills">
                  <Link className="main-body-top-tab-bar-left-col-a nav-link active table-refresh">
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        src={
                          base_url_assets + 'images/icons/inventory-icon.png'
                        }
                        alt=""
                      />
                      {t('Inventory List')}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="main-body-top-status-bar justify-content-between">
                <div className='d-flex'>
                  <Select
                    className="limit-select short mx-3 z-10"
                    options={selectOptions}
                    value={{ label: limit, value: limit }}
                    onChange={(e) => {
                      setLimit(e.value)
                      getlimit(e.value)
                      setRowlength(0)
                    }}
                  />
                  <div className="main-body-top-status-bar-filter">
                    <button
                      className="main-body-top-status-bar-filter-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#filterinventory"
                    >
                      <img
                        className="d-block"
                        src={base_url_assets + '/images/icons/search-outline.png'}
                        alt=""
                      />
                    </button>
                    <input
                      type="text"
                      value={filtername}
                      className="main-body-top-status-bar-filter-input"
                      placeholder={t('PO No./Supplier/Ship to ...')}
                      onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                      onChange={(e) => setFiltername(e.target.value)}
                    />
                    {filtername ? (
                      <img
                        className="main-body-top-status-bar-filter-clear"
                        src={base_assets + 'images/icons/False.png'}
                        onClick={(e) => { setFiltername(''); filterbyname(e.target.value) }}
                        alt=""
                      />
                    ) : (
                      ''
                    )}
                    <button
                      onClick={() => filterbyname(filtername)}
                      className="main-body-top-status-bar-filter-search"
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className='d-flex gap-3'>
                  <button className="print-export-dropdown-selected-icon ms-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#Stockidmodal">
                    <img
                      className={"deletebtn d-block"}
                      src={base_assets + "images/icons/clone.svg"}
                      alt=""
                    />
                  </button>
                  {createlist?.length ? (
                    <Link
                      className="ms-auto com-btn"
                      to="/app/inventory/createTransfer"
                      state={{
                        createlist: createlist,
                        Voucher_id: commanSelected_vouc,
                        hidelocationid: selectedlocation,
                        showwarehouseid: selectedwarehouse,
                        myinventory: false
                      }}
                    >
                      {t('Submit')}
                    </Link>
                  ) : (
                    <button
                      className="ms-auto com-btn"
                      onClick={() => error(t('No item Selected!!'))}
                    >
                      {t('Submit')}
                    </button>
                  )}
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap">
                  <InfiniteScroll
                    dataLength={transferinventorylist ? transferinventorylist?.length : 0}
                    next={() => totalresult > rowlength ? fetchMoreData() : ""}
                    hasMore={totalresult > transferinventorylist.length ? true : false}
                    loader={
                      totalresult > transferinventorylist.length ? (
                        <h6>{t('Loading more Data...')}</h6>
                      ) : null
                    }
                  >
                    <table
                      id="tbl_createTransfer_list"
                      className="common-table first-row-gray w-100"

                    >

                      <Tablehead
                        tablehead={allcheck ? createtransferinventoryheadcheck : createtransferinventoryhead}
                        tblName={'tbl_inventory_Createstocktransfer'}
                        setfilterby={''}
                        setShowoption={setSelectAllStatus}
                      />
                      <tbody id="allsamedata">
                        {transferinventorylist
                          ? transferinventorylist.map((result, key) => {
                            return (
                              <tr
                                id={`common-main-table-wrap-${result?.id}`}
                                className={
                                  result?.rowclass ? result?.rowclass : ''
                                }
                                key={key}
                              >
                                <td >
                                  <div className={
                                    result.status_type === 'reserve'
                                      ? 'com-check checkbox-disabled'
                                      : 'com-check'
                                  }>
                                    <input
                                      id={result?.id}
                                      data-id={result?.id}
                                      value={result?.id}
                                      location_id={result?.location_id}
                                      warehouse_id={result?.warehouse_id}
                                      className={"createtransferinventory createtransferinventoryhead"}
                                      onChange={selectedproduct}
                                      onClick={submitlist}
                                      type="checkbox"
                                    />
                                    <label htmlFor={result?.id} className="com-check-label" />
                                  </div>
                                </td>
                                <td>{parseInt(key + getSkipNumbers + 1)}</td>
                                <td className="table-pro-img">
                                  <img
                                    src={
                                      result?.main_image
                                        ? result?.main_image
                                        : base_url_assets +
                                        'images/icon/camera_profile.png'
                                    }
                                    alt=""
                                  />
                                </td>
                                <td className="po-no">
                                  {result?.SKU ? result?.SKU : ''}
                                </td>
                                <td>
                                  {result?.item_name ? result?.item_name : ''}
                                </td>
                                <td>
                                  {result?.collection_name
                                    ? result?.collection_name
                                    : ''}
                                </td>
                                <td>
                                  {result?.metal_name ? result?.metal_name : ''}
                                </td>
                                <td>
                                  {result?.stone_name ? result?.stone_name : ''}
                                </td>
                                <td>
                                  {result?.size_name ? result?.size_name : ''}
                                </td>
                                {result.status_stock_id === 'stock' ? (
                                  <td className="light-blue-text" data-order={result?.stock_id ? result?.stock_id : ''}>
                                    <div className="tooltip-area-up">
                                      {result?.stock_id ? result?.stock_id : ''}
                                      <span className="tooltip-area-up-text">
                                        {formatDate(result?.delivery_date)}
                                      </span>
                                    </div>
                                  </td>
                                ) : result.status_stock_id === 'transit' ? (
                                  <td className="status-green-text" data-order={result?.stock_id ? result?.stock_id : ''}>
                                    <div className="tooltip-area-up">
                                      {result?.stock_id ? result?.stock_id : ''}
                                      <span className="tooltip-area-up-text">
                                        {formatDate(result?.delivery_date)}
                                      </span>
                                    </div>
                                  </td>
                                ) : result.status_stock_id === 'warehouse' ? (
                                  <td className="status-gray-text" data-order={result?.stock_id ? result?.stock_id : ''}>
                                    <div className="tooltip-area-up">
                                      {result?.stock_id ? result?.stock_id : ''}
                                      <span className="tooltip-area-up-text">
                                        {formatDate(result?.delivery_date)}
                                      </span>
                                    </div>
                                  </td>
                                ) : result.status_stock_id === 'reserve' ? (
                                  <td className="yellow-text" data-order={result?.stock_id ? result?.stock_id : ''}>
                                    <div className="tooltip-area-up">
                                      {result?.stock_id ? result?.stock_id : ''}
                                      <span className="tooltip-area-up-text">
                                        {result?.customer_name}{' '}
                                        {result?.customer_name ? <br /> : ''}{' '}
                                        {formatDate(result?.delivery_date)}
                                      </span>
                                    </div>
                                  </td>
                                ) : result.status_stock_id ===
                                  'repair_order' ? (
                                  <td className="purple-text" data-order={result?.stock_id ? result?.stock_id : ''}>
                                    <div className="tooltip-area-up">
                                      {result?.stock_id ? result?.stock_id : ''}
                                      <span className="tooltip-area-up-text">
                                        {result?.customer_name}{' '}
                                        {result?.customer_name ? <br /> : ''}{' '}
                                        {result?.POS_Order} <br />{' '}
                                        {formatDate(result?.delivery_date)}
                                      </span>
                                    </div>
                                  </td>
                                ) : result.status_stock_id ===
                                  'custom_order' ? (
                                  <td className="danger-text" data-order={result?.stock_id ? result?.stock_id : ''}>
                                    <div className="tooltip-area-up">
                                      {result?.stock_id ? result?.stock_id : ''}
                                      <span className="tooltip-area-up-text">
                                        {result?.customer_name}{' '}
                                        {result?.customer_name ? <br /> : ''}{' '}
                                        {result?.POS_Order} <br />{' '}
                                        {formatDate(result?.delivery_date)}
                                      </span>
                                    </div>
                                  </td>
                                ) : (
                                  ''
                                )}
                                <td className="sku-col table_btn_expand_col">
                                  <div className="td-icons-wrap">
                                    {result?.allocatable ? (
                                      <div className="td-icons-wrap-link">
                                        <img
                                          className="img"
                                          src={
                                            base_url_assets +
                                            'images/icons/link-icon.svg'
                                          }
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div className="td-icons-wrap-user">
                                    <div
                                      className="tooltip-area-up"
                                    >
                                      {result?.pos_Qty !== 0 ? (
                                        <div className="td-icons-wrap-user">
                                          <img
                                            className="img"
                                            src={
                                              base_url_assets +
                                              'images/icons/user-red.png'
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      <span className="tooltip-area-up-text">
                                        {result?.customer_name}
                                        {result?.customer_name ? <br /> : ''}
                                        {result?.POS_Order}
                                        <br />
                                        {formatDate(result?.delivery_date)}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>{result?.po_QTY ? result?.po_QTY : ''}</td>
                                <td> {result?.price ? result?.price : ''}</td>
                                <td className="text-end">
                                  {result?.aging ? result?.aging : '0'}
                                </td>
                                <td
                                  className="location-td"
                                  data-location={
                                    result?.location_name
                                      ? result?.location_name
                                      : ''
                                  }
                                >
                                  <span className="link-color">
                                    {' '}
                                    {result?.location_name
                                      ? result?.location_name
                                      : ''}{' '}
                                  </span>{' '}
                                  <span className="text-center location-td-sub">
                                    {result?.warehouse_location_name
                                      ? result?.warehouse_location_name
                                      : ''}
                                  </span>
                                </td>
                                <td>
                                  {result.status_type === 'stock' ? (
                                    <div className="status-dot light-blue-bg  tooltip-area-up">
                                      <span className="tooltip-area-up-text">
                                        {t('Stock')}
                                      </span>
                                    </div>
                                  ) : result.status_type === 'transit' ? (
                                    <div className="status-dot green-bg  tooltip-area-up">
                                      <span className="tooltip-area-up-text">
                                        {t('Transit')}
                                      </span>
                                    </div>
                                  ) : result.status_type === 'warehouse' &&
                                    result.status_stock_id !==
                                    'custom_order' ? (
                                    <div className="status-dot status-gray-bg tooltip-area-up">
                                      <span className="tooltip-area-up-text">
                                        {t('Warehouse')}
                                      </span>
                                    </div>
                                  ) : result.status_type === 'reserve' ? (
                                    <div className="status-dot yellow-bg tooltip-area-up">
                                      <span className="tooltip-area-up-text">
                                        {t('Reserve')}
                                      </span>
                                    </div>
                                  ) : result.status_type === 'repair_order' ? (
                                    <div className="status-dot purple-bg tooltip-area-up">
                                      <span className="tooltip-area-up-text">
                                        {t('Repair Order')}
                                      </span>
                                    </div>
                                  ) : result.status_type === 'warehouse' &&
                                    result.status_stock_id ===
                                    'custom_order' ? (
                                    <div className="status-dot danger-bg tooltip-area-up">
                                      <span className="tooltip-area-up-text">
                                        {t('Custom Order')}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="status-dot danger-bg tooltip-area-up">
                                      <span className="tooltip-area-up-text">
                                        {t('Custom Order')}
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td className="text-danger">
                                  {result?.POS_Order ? result?.POS_Order : ''}
                                </td>
                                <td className="text-danger">
                                  {result?.customer_name
                                    ? result?.customer_name
                                    : ''}
                                </td>
                                <td className="text-danger">
                                  {result?.delivery_date
                                    ? formatDate(result?.delivery_date)
                                    : ''}
                                </td>
                                <td className="text-danger">
                                  {result?.due_days ? result?.due_days : ''}
                                </td>
                                <td>{result?.remark ? result?.remark : '-'}</td>
                                <td className="text-center">
                                  {result?.pos?.url ? (
                                    <a
                                      href={result?.pos?.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        className="pdf-image"
                                        src={
                                          base_url_assets +
                                          'images/icons/pdf-icon.png'
                                        }
                                        alt=""
                                      />
                                    </a>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                              </tr>
                            )
                          })
                          : ''}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            {transferinventorylist
                              ? transferinventorylist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val.po_QTY)),
                                0,
                              )
                              : ''}
                          </td>
                          <td>
                            {
                              toFormatPrice(transferinventorylist
                                ? transferinventorylist?.reduce(
                                  (interation, val) =>
                                  (interation =
                                    interation +
                                    toUnformatPrice(val?.price)),
                                  0
                                )
                                : "", { addSymbol: true })
                            }
                            {' '}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tfoot>
                    </table>
                    {datalodaer && <TableLoader />}

                  </InfiniteScroll>
                </div>
              </div>
            </div>
            <Filtermodal submitfiltter={Gettransferinventory} limit={limit} custom_filter_enable={true} />
          </div>
        </div>
      </div>
      <Stockidmodal
        countstockid={countstockid}
      />
      <TableColumnModal
        tablehead={allcheck ? createtransferinventoryheadcheck : createtransferinventoryhead}
        tblName={'tbl_inventory_Createstocktransfer'}
        calldatatable={Gettransferinventory}
      ></TableColumnModal>
      <Advancedfiltermodel></Advancedfiltermodel>
    </React.Fragment >
  )
}

export default CreateTransferInventory
