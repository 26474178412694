import CloseIcon from 'admin/common/icons/CloseIcon'
import PageWithPencile from 'admin/common/icons/PageWithPencile'
import PlusIcon from 'admin/common/icons/PlusIcon'
import PrintIcon from 'admin/common/icons/PrintIcon'
import RemarkMessageIcon from 'admin/common/icons/RemarkMessageIcon'
import RightArrow from 'admin/common/icons/right-arrow'
import SaveButtonIcon from 'admin/common/icons/SaveButtonIcon'
import SearchIcon from 'admin/common/icons/SearchIcon'
import TableLoader from 'admin/common/TableLoader'
import { MultiSalePersons, ZoneList, ZoneLocation } from 'components/common/graphql/commonlayouts'
import { Tablehead } from 'components/common/modules/Tablehead'
import { zonEditCreateHeader } from 'components/common/Tablehead'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Select from "react-select";
import DatePicker from 'react-datepicker';
import EditIcon from 'admin/common/icons/EditIcon'
import Calendar from 'admin/common/icons/sidebar/Calendar'
import ZoneSidebarPopup from './ZoneSidebarPopup'
import { Pagination } from 'components/common/Pagination'
import ZoneModal from './ZoneModal'
import DeleteIcon from 'admin/common/icons/DeleteIcon'

export default function ZoneCreateAndEdit() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const {create, zoneData} = state;
    console.log(create, zoneData, "Check create")
    const [limit, setLimit] = useState();
    const [filtername, setFiltername] = useState("");
    const [datefilter, setDatefilter] = useState([]);
    const [datalodaer, setDatsLodaer] = useState(false)
    const [activeStockID, setActiveSidebar] = useState('');
    const [showSidebar, setShowSidebar] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [popupContent, setPopupContent] = useState({header: '', subheader: '', withicon: false});
    const [pages, setPages] = useState({totalPages: 20,currentPage: 1,});
    const { totalPages, currentPage } = pages;
    const selectOptions = [
      { label: 100, value: 100 },
      { label: 200, value: 200 },
      { label: 300, value: 300 }
    ]
    const getlimit = (limit) => {
      setLimit(limit);
    };

    const handlePaginations = (current) => {
      setPages({ ...pages, currentPage: current });
      // getlowstocklist(current, "");
  
    };

    const [showReason, setShowReason] = useState(false)

    const togglePopup = (e, header, subheader, withicon) =>{
      if(e.target.checked){
      setPopupContent((state)=>({ ...state, header: header, subheader: subheader, withicon: withicon}))
      setOpenModal(true)
      }
      console.log(e, header, subheader, withicon, "check toggle")
    }

    const zoneEditData = [
        {id: 'asfadgf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asfdfgasf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asfajsf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asfdxfgasf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asgfasf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asmfasf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asfasaf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'sasfasf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asdasf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asfasbf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'},
        {id: 'asfafsf', input: true, image: "", sku: '34hh', item: 'Ring', stockid: '1111111', issue: 1, sold: 3, return: 0, balance: 1, reason: 'sdfkjfkd'}
    ]

  return (
    <>
    <div className="main-body zone_module">
    <div className="container">
      <div className="row">
      <div className="col-lg-12 d-flex justify-content-between">
            <h1 className="main-body-heading"> 
              <span>
                {t('Inventory')} <span className='ms-2'><RightArrow/></span>
              </span>
              <span>
                {t('Inventory')} <span className='ms-2'><RightArrow/></span>
              </span>
              <span>{t("Zone")}</span> <span className='ms-2'><RightArrow/></span>
              {t("Create Issue")}
            </h1>
            <div className='d-flex gap-2 items-center'>
                {create ? 
                <>
                <Link to={-1} className='border px-2 d-flex align-items-center justify-content-center'><CloseIcon/></Link>
                <Link
                   
                    className={"main-body-top-tab-bar-right-col-a"}
                  >
                    <SaveButtonIcon color="#fff"/>
                  </Link>
                  </>
                  :
                  <div className='cursor-pointer p-2 bg-white' onClick={()=>create = false}><EditIcon colors="#A4A4A4"/></div>
                }
            </div>
      </div>
      <div className='zone_module-inner'>
        <div className='zone_module-inner-firstbar zone_module-inner-header'>
            <div className='zone_module-inner-header-block issue'>
                <label>{t("Issue")}</label>
                <div className='zone_module-inner-header-block-data'>0</div>
            </div>
            <div className='zone_module-inner-header-block sold'>
                <label>{t("Sold")}</label>
                <div className='zone_module-inner-header-block-data'>0</div>
            </div>
            <div className='zone_module-inner-header-block return'>
                <label>{t("Return")}</label>
                <div className='zone_module-inner-header-block-data'>0</div>
            </div>
            <div className='zone_module-inner-header-block balance'>
                <label>{t("Balance")}</label>
                <div className='zone_module-inner-header-block-data'>0</div>
            </div>
        </div>
        <div className='main-body-top-status-bar'>
        <div className='single-inputHolder'> 
                  <input className='single-inputHolder-input' type='text' value={state?.zoneData?.refno}/>
                  <label className={`single-inputHolder-label ${state?.zoneData?.refno ? 'active' : ''}`}>{t("Ref No")}</label>
                </div>
            <div className='zone-datepicker'>
            {state?.zoneData?.issuedate ? <span className='zone-datepicker-icon'><Calendar/></span> : null}
            <DatePicker
                      selectsRange={false}
                    //   onChange={(update) => {
                    //     setStartdate(update[0])
                    //     setEnddate(update[1])
                    //     setDatelable('Custom Range')
                    //   }}
                    value={state?.zoneData?.issuedate ? state?.zoneData?.issuedate  : new Date()}
                    placeholder="Date"
                    classNamePrefix="common-select"
                    dateFormat={state?.zoneData?.issuedate ? 'dd-MM-yyyy' : "yyyy-MM-dd"}
            />
            <label className={`zone-datepicker-label ${state?.zoneData?.issuedate ? 'active' : ''}`}>{t("Date")}</label>
            </div>
            <ZoneLocation/>
            <ZoneList/>
            <div className="zonemultiselect">
                <MultiSalePersons
                setMultisetcommanSelected_loc={
                    [{value: 'Born Sha', label: 'Born Sha'},{value: 'Deepak Kumar', label: 'Deepak Kumar'}]
                }
                />
            </div>
        </div>
        <div className="zone_module-inner-secondbar main-body-top-status-bar">
               
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />
               <div className='zone_module-inner-middlebar'>
                <div className='zone_module-inner-middlebar-radiobtns'>
                <div className='radiobutton'>
                    <label class="zonetype-radiobtn">
                        <input type="radio" name="radio"/>
                    </label>
                    <span>{t("Issue")}</span>
                </div>
                <div className='radiobutton'>
                    <label class="zonetype-radiobtn">
                        <input type="radio" name="radio"/>
                    </label>
                    <span>{t("Return")}</span>
                </div>
                </div>
                <div className='search'>
                    <input type='text' className='' placeholder='Search/Scan by SKU or stock ID' onClick={()=>setShowReason(!showReason)}/>
                </div>
                <div className='stockidSidebar tooltip-area-up' onClick={()=>{setShowSidebar(!showSidebar); setActiveSidebar('stockid')}}>
                    <PageWithPencile/>
                    <span className='tooltip-area-up-text whitenblack'>{t("Add Stock ID")}</span>
                </div>
                <div className='inventorubutton' onClick={()=>navigate('addinventory')}>
                    <PlusIcon type='doublelayered'/>
                    <span>{t("Inventory")}</span>
                </div>
                </div>
                <div className='last-section'>
                  {selectedRows?.length ? 
                   <div className='cursor-pointer' 
                   onClick={()=> {
                    setPopupContent({header:"Are you sure you want to delete this?", subheader: 'Are you sure you want to remove this item ?', subline2: " Yes, I want to delete it.", withicon:false, deleteicon: true});
                    setOpenModal(true);
                   }}
                   >
                    <DeleteIcon type="curved"/>
                   </div>
                   : null }
                    <div className='last-section-toggle'>
                        <div className="form-check form-switch">
                            <input type="checkbox" id="togglebtn1" className="form-check-input float-none" 
                            // onChange={(e)=>togglePopup(e,'The missing return/sold!', 'Changing the status to close with the missing return/sold. The closed status can not be changed later.', false)}
                            onChange={(e)=> e.target.checked === false? (setPopupContent({header:'The missing return/sold!', subheader: 'Changing the status to close with the missing return/sold. The closed status can not be changed later.', withicon: true}), setOpenModal(true)) : null}
                            />
                            <label htmlFor="togglebtn1" className="form-check-label"></label>
                        </div>
                        <span className="last-section-toggle-tag">{t("Status Open")}</span>
                    </div>  
                    <div className='cursor-pointer tooltip-area-up' onClick={()=>{setShowSidebar(!showSidebar); setActiveSidebar('remark')}}>
                        <RemarkMessageIcon />
                        <span className='tooltip-area-up-text whitenblack'>{t("Remark")}</span>
                    </div>
                </div>
                
        </div>
        <div className={`zone_module-inner-table ${showReason ? 'withReason' : ''}`}>
        <div
                className="main-body-main-table-wrap position-relative"
                style={{ maxWidth: '100%', overflowX: 'auto' }}
              >
                <table
                  id="my_inventory"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead tablehead={zonEditCreateHeader} tblName={'zoneEditcreate_table'}  />
                  <tbody>
                    <>

                      {zoneEditData?.length ? zoneEditData.map((result, key) => {
                        return (
                          <tr key={key} className="">
                            <td>
                              <div className={'com-check'}>
                                <input key={key}
                                  className='inventorytablecheck inventorytableHead gisClassTh'
                                  id={result?.id}
                                  type="checkbox"
                                />
                                <label htmlFor={result?.id} className="com-check-label" />
                              </div>
                            </td>
                            <td className="ref-no cursor-pointer">{key+1}</td>
                            <td className="table-pro-img" >
                              <img src='https://www.figma.com/file/Q1DYtqeDh9a6U0axVOhrhp/image/8898bd31c4c4c238fcbad26c8df744b0d47f95b1'/>
                            </td>
                            <td className="">
                              {result?.sku ? result?.sku : "-"}
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.item ? result?.item : "-"}</div>
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.stockid ? result?.stockid : "-"}</div>
                            </td>
                            <td className="">{result?.issue ? result?.issue : "-"}</td>
                            <td className="">{result?.sold ? result?.sold : "-"}</td>
                            <td className="">{result?.return ? result?.return : "-"}</td>
                            <td className="">{result?.balance ? result?.balance : "-"}</td>
                            {showReason ? 
                            <td className="text-danger">
                                <input className='bg-transperant border-none h-20 w-full border-1' value={result?.reason}/>
                            </td>
                            : null }
                          </tr>
                        );
                      }) : ''}
                    </>
                  </tbody>
                </table>
                {datalodaer && <TableLoader />}
              </div>
              <div className='zone_module-inner-bottom'>
            <div>
                {t("Showing")} 1 {t("to")} 10 {t("of")} 10 {t("Entries")}
            </div>
            {zoneEditData.length > 0 ? (
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePaginations(crPage)}
                  />
                ) : (
                  ""
                )}
        </div>
        </div>
      </div>
    </div>
    </div>
   
    </div>
     {showSidebar ? <ZoneSidebarPopup setShowSidebar={setShowSidebar} activeStockID={activeStockID}/> : null }
     {openModal && <ZoneModal setOpenModal={setOpenModal} popupContent={popupContent}/>} 
     </>
  )
}
