import React, { useRef, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { _Api } from "../../api/_call";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import DateTime from "../../common/DateTime";
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { Tablehead } from "../../common/modules/Tablehead";
import { stockrecevietablehead } from '../../common/Tablehead';
import { DateRange } from '../../common/helpers/daterange'
import { toFormatPrice, toUnformatPrice, transactionTotals } from "../../common/helpers/function";
import Select from "react-select";
import { success, error } from "../../common/helpers/toastify";
import { status_receive_class } from "../../common/helpers/status_class";
import { useSelector } from 'react-redux';
import AgentModel from "../../common/modules/agentmodal";
import { Stocktransferdetails } from "../../transaction/subTables/StockTransfer/stocktransferdetails";
import ProductModal from "../../common/modules/ProductModal";
import ProductImageSliderModal from "../../common/modules/productImageslider";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import { Pagination } from "../../common/Pagination";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { formatDate } from "../../common/helpers/formatDate";
const TransactionStockReceive = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const [limit, setLimit] = useState(100);
  const generalsettings = useSelector((state) => state.app.generalsettings);
  const [filtername, setFiltername] = useState('');
  const [datefilter, setDatefilter] = useState([]);
  const [stockreceivelist, setStockreceivelist] = useState([]);
  const [newcolumndata, setnewcolumndata] = useState(stockrecevietablehead);
  const [transfer_sataus, settransfer_sataus] = useState([]);
  const [statement, setStatement] = useState("");
  const [setAgentvalue] = useState();
  const [setAWBvalue] = useState();
  const [imageid, setImageid] = useState();
  const [Productmodaldata, setProductmodaldata] = useState([]);
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const totalcountsnumber = useSelector((state) => state.app.totalcountsnumber);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [datalodaer, setDatsLodaer] = useState(false)
  const [excuteTable, setexcuteTable] = useState(false);
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    Getstockreceive(current, "", "");

  };
  const filterbyname = (search) => {
    setstate({ ...state, currentPage: 1 });
    Getstockreceive(currentPage, limit, search);

  };
  const getlimit = (limit) => {
    setLimit(limit);
    Getstockreceive(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const Getstockreceive = useCallback(async (current, firstlimit, search) => {
    setexcuteTable(false);
    var expand_col = document.querySelector(".table_btn_expand_col");
    if (expand_col) { expand_col.classList.remove("rotate-90"); }
    try {
      setDatsLodaer(true)
      setTimeout(async function () {
        UnSetdataTable("transaction_subtable4");
        const postdata = {
          "search": search ? search : "",
          "limit": firstlimit ? firstlimit : limit,
          "skip": current ? (current - 1) * limit : 0,
          "start_date": datefilter ? datefilter[0] : "",
          "end_date": datefilter ? datefilter[1] : ""
        };
        let resstockreceive = await _Api(postdata, `api/v1/Inventory/stockreceive/getStockReceiveList`);
        if (resstockreceive?.code === 200) {
          transactionTotals();
          setexcuteTable(true);
          SetdataTable("transaction_subtable4", 5, newcolumndata);
          setStockreceivelist(resstockreceive?.data);
          setstate({
            ...state,
            totalPages: Math.ceil(resstockreceive?.count / postdata.limit),
            currentPage: current ? current : 1
          })
          setDatsLodaer(false)
          dataFetched.current = false;
        } else {
          setDatsLodaer(false)
          SetdataTable("transaction_subtable4", '', newcolumndata);
        }
      }, 100)
    }
    catch (err) {
      console.log(err, 'err');
    }
    // eslint-disable-next-line
  }, [datefilter, filtername, limit, state]);
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color
      };
    }
  };
  const select_transfer_status = (index, value, transfer_id) => {
    setStatement("Change Status");
    settransfer_sataus([{ index: index, status: value, transfer_id: transfer_id }]);
    if (value?.value === 3 && generalsettings?.data?.generalsetup?.logistic_status === 1) {
      document.querySelector("#statustransit_query_select").click();
    } else {
      document.querySelector("#status_query_select").click();
    }
  };

  const Getstocktransferstatus = async () => {
    try {
      const postdata = {
        "status": transfer_sataus[0].status?.value,
        "stock_transfer_id": transfer_sataus[0].transfer_id
      };
      let resstocktransferstatus = await _Api(postdata, `api/v1/Inventory/stockreceive/editStockReceiveStatus`);
      if (resstocktransferstatus.code === 200) {
        success(t("Successfully Status Updated"));
        Getstockreceive();
      } else {
        error(t("Something Went Wrong!!"));
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const setSubTable = async (id) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector('#common-main-table-wrap-' + id);

    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...stockreceivelist];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTable = null
      });
      setStockreceivelist(alllist_data);
    } else {
      classList_.add("rotate-90");
      let alllist_data = [...stockreceivelist];
      const postdata = {
        st_order_id: id,
      };
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/stocktransfer/getSTDetails`
      );
      if (prioritydata?.code === 200) {
        var html_ = Stocktransferdetails(prioritydata, setImageid, setProductmodaldata, t);
        alllist_data.filter(item => item.id === id).forEach((result) => {
          result.subTable = html_
        })
      }
      setStockreceivelist(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-table-sub-row' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Getstockreceive();
    }
    // eslint-disable-next-line
  }, [newcolumndata, datefilter]);

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    stockrecevietablehead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (stockreceivelist.length) {
      stockreceivelist.forEach((result, key) => {
        bodyarray.push([result?.createdAt, result?.updatedAt, result?.st_no, result?.transfer_from_loc_name, result?.transfer_to_location_name, result?.sku, result?.total_quantity, result?.total_amount,
        result.status.st_status === 3 ? "transit" :
          result.status.st_status === 4 ? "received" :
            result.status.st_status === 5 ? "canceled" : "",
        result?.note, result?.user_actions?.created_by_name, result?.user_actions?.updated_by_name

        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "stock_receive");
  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading">{t("Transaction")}</h1>
              <div className="main-body-top-tab-bar">
                <div
                  className="main-body-top-tab-bar-left-col nav nav-pills"
                  role="tablist"
                >
                  <Link
                    className={parmissiondatashow?.purchase_order?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Purchase Order")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.PO?.total ? totalcountsnumber?.PO?.total : "0"}</span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_purchase?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/transactionPurchase"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Purchase")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.PU?.total ? totalcountsnumber?.PU?.total : "0"}</span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_stock_transfer?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/stock_transfer"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Stock Transfer")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.ST?.total ? totalcountsnumber?.ST?.total : "0"}</span>
                  </Link>
                  <Link
                    className="main-body-top-tab-bar-left-col-a nav-link active"
                    to="/app/inventory/transactionStockReceive"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Stock Receive")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.SR ? totalcountsnumber?.SR : "0"}</span>
                  </Link>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("TF No./From/Ship to...")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_url_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                  <div
                    className="modal fade filter-modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            {t("Advanced filters")}
                          </h5>
                          <button
                            type="button"
                            className="filter-modal-clear-all"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            {t("Clear All")}
                          </button>
                          <button className="filter-modal-apply-btn">{t("apply")}</button>
                        </div>
                        <div className="modal-body">
                          <div className="filter-modal-row">
                            <div className="filter-modal-row-col-left">{t("Where")}</div>
                            <div className="filter-modal-row-col-right">
                              <div className="filter-modal-row-col-right-row">
                                <select defaultValue="Column1" className="filter-modal-row-col-right-row-column">
                                  <option>Column</option>
                                  <option value="Column1" >Column1</option>
                                  <option>Column2</option>
                                </select>
                                <select defaultValue="Condition1" className="filter-modal-row-col-right-row-column">
                                  <option>Condition</option>
                                  <option value="Condition1" >Condition1</option>
                                  <option>Condition2</option>
                                </select>
                                <input
                                  className="filter-modal-row-col-right-row-column filter-modal-row-col-right-row-value"
                                  placeholder={t("Value")}
                                  type="text"
                                />
                                <button
                                  className="btn remove"
                                  id="remove-filter-row"
                                >
                                  <svg
                                    width={16}
                                    height={17}
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.3886 0.477295L8 6.86587L1.61143 0.477295L0 2.08872L6.38857 8.47729L0 14.8659L1.61143 16.4773L8 10.0887L14.3886 16.4773L16 14.8659L9.61143 8.47729L16 2.08872L14.3886 0.477295Z"
                                      fill="#2A2A2A"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <span
                            className="filter-modal-add-filter"
                            id="add-filter-row"
                          >
                            <img
                              src={base_url_assets + "images/icons/add_ww.png"}
                              alt=""
                            />
                            {t("Add new filter")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img src={base_url_assets + "images/icons/printer-icon.png"} alt="" />
                  </button>
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >

                      <img
                        src={base_url_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_url_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_url_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
                id="table-scroll"
              >
                <table
                  id="transaction_subtable4"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead tablehead={stockrecevietablehead} setfilterby={""}
                    tblName={"tbl_inventory_stocktransferRecived"}
                  />
                  <tbody>
                    <>
                      {stockreceivelist ? stockreceivelist.map((result, key) => {
                        var status_class_val = status_receive_class(
                          result.status.st_status
                        );
                        var valueofstatus = [];
                        var colorsArray = [];
                        if (result.status.st_status === 3) {
                          valueofstatus = [{ label: "transit", value: result.status.st_status, color: "#68B8F2" }]
                          colorsArray = [
                            { label: "received", value: 4, color: "#0ECB81" },
                            { label: "canceled", value: 5, color: "#FF5757" }
                          ]
                        } else if (result.status.st_status === 4) {
                          valueofstatus = [{ label: "received", value: result.status.st_status, color: "#0ECB81" }]

                        } else if (result.status.st_status === 5) {
                          valueofstatus = [{ label: "canceled", value: result.status.st_status, color: "#FF5757" }]
                        }
                        return (
                          <React.Fragment key={key}>
                            <tr id={`common-main-table-wrap-${result?.id}`} key={key}>
                              <td className="fixed-side">{result?.createdAt ? result?.createdAt : ""}</td>
                              <td className="fixed-side">{result.status.st_status !== 3 ? result?.updatedAt : '-'}</td>
                              <td className="po-no">{result?.st_no ? result?.st_no : ""}</td>
                              <td className="danger-text">{result?.transfer_from_loc_name ? result?.transfer_from_loc_name : ""}</td>
                              <td>{result?.transfer_to_location_name ? result?.transfer_to_location_name : ""}</td>
                              <td className="sku-col table_btn_expand_col col-highlight" id={"table-btn_" + result.id}
                                onClick={() => setSubTable(result.id)}>
                                <div className="td-icons-wrap">
                                  <div>
                                    <img
                                      className="sku-col-arrow cursor-pointer"
                                      src={
                                        base_url_assets +
                                        "images/icons/right_green_button.png"
                                      }
                                      alt="" />
                                    <span>{result?.sku ? result?.sku : "0"}</span>
                                  </div>
                                  {result.allocatable === true ? <div className="td-icons-wrap-link">
                                    <img
                                      className="img "
                                      src={
                                        base_url_assets +
                                        'images/icons/link-icon.svg'
                                      }
                                      alt="" />
                                  </div> : ""}
                                  <div className='tooltip-area-up'>
                                    {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user"><img
                                      className="img"
                                      src={
                                        base_url_assets +
                                        'images/icons/gray-user.png'
                                      }
                                      alt=""
                                    /> <span>{result?.pos_Qty}</span>
                                    </div> : ""}
                                    <span className="tooltip-area-up-text">{result?.pos?.customer_name}{result?.pos?.customer_name ? <br /> : ''}{result?.pos?.ref}<br />{formatDate(result?.pos?.delivery_date)}</span>
                                  </div>
                                </div>
                              </td>
                              <td className="col-highlight">{result?.total_quantity ? result?.total_quantity : "0"}</td>
                              <td className="col-highlight"> {result?.total_amount ? toFormatPrice(result?.total_amount, { addSymbol: true }) : ""}</td>
                              <td className="col-highlight">
                                <div className={"dropdown status-dropdown status-dropdown-select " + status_class_val}>
                                  <Select
                                    options={parmissiondatashow.inventory_transaction_stock_Receive?.Status ? colorsArray : []}
                                    isDisabled={result.status.st_status === 4 || result.status.st_status === 5 ? true : false}
                                    value={valueofstatus}
                                    onChange={(event) =>
                                      select_transfer_status(key, event, result.id)
                                    }
                                    classNamePrefix="status-dropdown-select"
                                    styles={colorStyles}
                                    getOptionLabel={(e) => (
                                      <div className="">
                                        {e.label}
                                      </div>
                                    )}
                                    isSearchable={false} />
                                </div>
                              </td>
                              <td className="col-highlight">{result?.note ? result?.note : ""}</td>
                              <td className="col-highlight">{result?.user_actions?.created_by_name ? result?.user_actions?.created_by_name : ""}</td>
                              <td className="col-highlight">{result?.user_actions?.updated_by_name ? result?.user_actions?.updated_by_name : ""}</td>
                            </tr>
                            {
                              excuteTable ? (
                                <>
                                  {typeof result.subTable == "undefined" ? (result.subTable = "") : result.subTable}
                                </>
                              ) : null
                            }
                          </React.Fragment>
                        );
                      }) : ''}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td> {stockreceivelist
                        ? stockreceivelist?.reduce(
                          (interation, val) =>
                          (interation =
                            interation + parseInt(val.total_quantity)),
                          0
                        )
                        : ""}</td>
                      <td>
                        {toFormatPrice(
                          stockreceivelist
                            ? stockreceivelist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.total_amount)),
                              0
                            )
                            : "", { addSymbol: true }
                        )}</td>
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                  <div className="dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            {stockreceivelist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>

          <ProductModal data_product={Productmodaldata} setOneproduct={setProductmodaldata} editproduct_submit={''} />
          <ProductImageSliderModal
            main_id={imageid}
          ></ProductImageSliderModal>
        </div>
        <SocialModal />
        <TableColumnModal
          tablehead={stockrecevietablehead}
          tblName={"tbl_inventory_stocktransferRecived"}
          setnewcolumndata={setnewcolumndata}
          calldatatable={Getstockreceive}
        ></TableColumnModal>
      </div>
      <ConfirmationModel
        statement={statement}
        handler_submit={Getstocktransferstatus}
        handler_dismiss={() => { Getstockreceive(); setexcuteTable(false); }}
      ></ConfirmationModel>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#createPurchaseModal"
        id="status_query_select"
      ></button>
      <AgentModel
        handler_submit={Getstocktransferstatus}
        handler_dismiss={Getstockreceive}
        setAgentvalue={setAgentvalue}
        setAWBvalue={setAWBvalue}
      ></AgentModel>
    </React.Fragment>
  );
}

export default TransactionStockReceive;