import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
const ConfModal = props => {
  const { t } = useTranslation();
  const { handler_submit, statement } = props;
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;
  useEffect(() => {

  }, []);

  return (
    <div
      className="modal fade common-modal"
      id="createPurchaseModal"
      tabIndex={-1}
      aria-labelledby="createPurchaseModalLabel"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <img className="common-modal-exclamation-img"
              src={base_url + "images/icons/exclamation-mark-green.png"}
              alt=""
            />
            <p className="modal-content-text">
              {t("Are you sure you want to")} {t(statement)} ?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn modal-content-no"
              data-bs-dismiss="modal"
            >
              {t("No")}
            </button>
            <button type="button" data-bs-dismiss="modal" onClick={() => handler_submit()} className="btn modal-content-yes">
              {t("yes")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ConfModal;
