import React, { useState, useEffect } from "react";
import "../master.scss"
import { _Api } from "../../api/_call";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const Editcolorstone = (props) => {
    const { setEditItem, dataid, setShowedit, create, type, setCreate, setDataid } = props
    const { t } = useTranslation();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [Status, SetStatus] = useState(true)
    const stonegroupview = async () => {
        if (create) {
            setName("")
            setCode("")
            SetStatus(true)
        } else if (dataid) {
            try {
                let postdata = {
                    "id": dataid,
                    "type": type
                }
                let res = await _Api(postdata, `api/v1/App/master/stoneMasterview`)
                if (res?.code === 200) {
                    setName(res?.data?.name)
                    setCode(res?.data?.code)
                    if (res?.data?.Status === "1") {
                        SetStatus(true)
                    } else {
                        SetStatus(false)
                    }

                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }
    }
    useEffect(() => {
        if (dataid || create) {
            stonegroupview()
        }
        // eslint-disable-next-line
    }, [dataid, create])

    const Editstonegropdata = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            let checkspace = code.includes(' ');
            if (checkspace) {
                error(t('Please remove space in code'));
                return;
            }
        }
        setShowedit(false)
        let GetloadingID = loading();
        try {
            let postdata = {
                "id": dataid,
                "type": type,
                "data": {
                    name: name,
                    code: code,
                    Status: Status ? "1" : "0"
                }
            }
            let res = await _Api(postdata, `api/v1/App/master/stoneMasterEdit`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setEditItem(false)
                setShowedit(true)
                setDataid("")
                setCreate(false)
            } else {
                Updateloading(t, GetloadingID, (res.errors), 'error');
            }
        }

        catch (err) {
            console.log(err, "err")
        }
    }
    const cretecolormaster = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            let checkspace = code.includes(' ');
            if (checkspace) {
                error(t('Please remove space in code'));
                return;
            }
        }
        setShowedit(false)
        let GetloadingID = loading();
        try {
            let postdata = {
                "type": type,
                "data": {
                    name: name,
                    code: code,
                    Status: Status ? "1" : "0"
                }
            }
            let res = await _Api(postdata, `api/v1/App/master/stoneMasterCreate`)
            if (res?.code === 200) {
                Updateloading(t, GetloadingID, (res?.message));
                setEditItem(false)
                setShowedit(true)
                setDataid("")
                setCreate(false)
            } else {
                Updateloading(t, GetloadingID, (res.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    return (
        <div className="edit-item-area bg-white">
            <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                <div className="me-auto">
                    <label className="d-block">{t("Name")}: {name ? name : ""}</label>
                    <label className="d-block">{t("Code")}: {code ? code : ""}</label>
                </div>
                <button className="square-btn square-btn-white btn-close focus-none" onClick={() => {
                    setEditItem(false);
                    setDataid("");
                    setCreate(false)
                }}></button>
                <button className="square-btn " onClick={() => dataid ? Editstonegropdata() : cretecolormaster()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
            </div>
            <div className="edit-item-area-body">
                <div className="row mx-0">
                    <div className="col-md-8 ps-0">
                        <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                            <input className="v3-input-group-input w-100"
                                value={name || ''}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Code")} <sup>*</sup></label>
                            <input className="v3-input-group-input w-100"
                                value={code || ''}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 offset-md-1 pe-0 text-end">
                        <div className="form-check form-switch ps-0">
                            <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                            <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                checked={Status ? true : false}
                                onChange={(e) => SetStatus(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Editcolorstone;