import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { _Api, _Apiauth } from "../../../common/api";
import CrossIcon from '../../assets/icons/CrossIcon'
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from "../../assets/images/calender.png"
import Spinner from "../../../common/Spinner";
const Appointmentmodal = (props) => {
    const [loadeing, setloading] = useState(false)
    const modalRef = useRef(null);
    const { setAllfromdata, allfromdata } = props
    const navigate = useNavigate();
    const [selectdata, setSelectdata] = useState([])
    const { t } = useTranslation();
    const [validation, setValidation] = useState()
    let cartdata = JSON.parse(localStorage.getItem('CartItems'))
    const setalldata = (keyname, value) => {
        setAllfromdata((state) => ({
            ...state,
            [keyname]: value
        }))
    }
    const addappointment = async () => {
        const validfrom = () => {
            let fromisvalid = true
            if (!allfromdata?.appointment_date) {
                setValidation((state) => ({
                    ...state,
                    appointment_date: "Date is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    appointment_date: ""
                }))
            }
            if (!allfromdata?.name) {
                setValidation((state) => ({
                    ...state,
                    name: "Name is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    name: ""
                }))
            }
            if (!allfromdata?.email) {
                setValidation((state) => ({
                    ...state,
                    email: "Email is required"
                }))
                fromisvalid = false
            } else {
                const isEmail = (email) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                if (!isEmail(allfromdata?.email)) {
                    setValidation(prevState => ({
                        ...prevState,
                        email: "Invalid Email"
                    }));
                    fromisvalid = false
                } else {
                    setValidation((state) => ({
                        ...state,
                        email: ""
                    }))
                }
            }
            if (!allfromdata?.message) {
                setValidation((state) => ({
                    ...state,
                    message: "Message is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    message: ""
                }))
            }
            if (!allfromdata?.mobile || !allfromdata?.phonecode) {
                setValidation((state) => ({
                    ...state,
                    mobile: "Mobile number is required"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    mobile: ""
                }))
            }
            return fromisvalid;
        }
        if (validfrom()) {
            // setloading(true)
            try {
                let obj = { ...allfromdata }
                obj.mobile = "+" + allfromdata?.phonecode + "-" + allfromdata?.mobile
                obj?.product.forEach((result) => {
                    delete result?.price
                })
                delete obj?.phonecode
                obj.appointment_date = new Date(allfromdata?.appointment_date.setHours(12, 10, 10, 999))

                let postdata = obj
                let res = await _Api(postdata, `Appointment/create-Appointment`)
                setloading(false)
                if (res?.statusCode === 200) {
                    if (modalRef.current) {
                        modalRef.current.hide();
                    }
                    navigate("/appointmentconfirm", { state: res?.data })
                    let setupdatearray = [...postdata?.product]
                    const newArray1 = cartdata.filter((obj1) => !setupdatearray.some((obj2) => obj2.id === obj1.id));
                    localStorage.setItem('CartItems', JSON.stringify(newArray1));
                } else {
                    setValidation(prevState => ({
                        ...prevState,
                        "error": res?.message
                    }));
                }
            }
            catch (err) {
                console.log(err, "err")

                if (err.response.status) {
                    setValidation(prevState => ({
                        ...prevState,
                        "error": err.response.data.message
                    }));
                }
            }
        }

    }
    const getcountry = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `redis/countryList`)
            if (res?.statusCode === 200) {
                let phonecode = []
                res?.data.forEach((result) => {
                    phonecode.push({
                        label: result?.phoneCode,
                        value: result?.phoneCode
                    })
                })
                setSelectdata(phonecode)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getcountry()
    }, [])
    useEffect(() => {
        modalRef.current = new window.bootstrap.Modal(document.getElementById('Appointmentmodal'));
    }, []);
    return (
        <div
            className="modal fade common-modal appointmentModal"
            id="Appointmentmodal"
            tabIndex={-1}
            aria-labelledby="AppointmentmodalLabel"
            data-bs-backdrop="static"
        >
            <div className="modal-dialog">
                <div className="modal-content appointmentModal-contentBox ">
                    <div className="modal-header appointmentModal-contentBox-headerBox">
                        <h3 >{t("Make an Appointment")}</h3>
                        <button className="modalCross-btn" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => { setValidation(); setAllfromdata() }}><CrossIcon width={17.41} height={17.41} /></button>
                    </div>
                    <div className="modal-body  appointmentModal-contentBox-bodyBox">
                        <div className="appointmentModal-contentBox-bodyBox-block gap-4">
                            <div className="com-formInputBox">
                                <label className=''>{t("Date")} <span className='com-starShow'>*</span></label>
                                <div className={validation?.appointment_date ? "form-control position-relative appointmentdatepicker border-danger" : "form-control position-relative appointmentdatepicker"}>
                                    <DatePicker
                                        selected={allfromdata?.appointment_date}
                                        onChange={(date) => setalldata("appointment_date", date)}
                                        dateFormat="dd/MM/yyyy"
                                        className='datepickerappointment w-100 h-100 border-0 ps-2'
                                        style={{ outline: 'none' }}
                                        minDate={new Date()}
                                        autoComplete='none'
                                    />
                                    <span className="calendarApppoinyBox d-flex position-absolute"><img src={CalendarIcon} alt="" /></span>
                                </div>
                                {validation?.appointment_date ? <div className="text-danger">{validation?.appointment_date}</div> : ""}
                            </div>
                            <div className="com-formInputBox">
                                <label htmlFor="lastname text-dark" className="form-label text-sm-start">{t("Full Name")}<span className="com-starShow">*</span></label>
                                <input
                                    type="text"
                                    className={validation?.name ? "form-control border-danger" : "form-control"}
                                    id="lastname"
                                    value={allfromdata?.name}
                                    onChange={(e) => setalldata("name", e.target.value)}
                                />
                                {validation?.name ? <div className="text-danger">{validation?.name}</div> : ""}
                            </div>
                        </div>
                        <div className="appointmentModal-contentBox-bodyBox-block gap-4">
                            <div className="com-formInputBox">
                                <label htmlFor="phone" className="form-label text-sm-start text-dark">{t("Mobile")} <span className="com-starShow">*</span></label>
                                <div className={validation?.mobile ? "d-flex border border-danger rounded applyborder" : "d-flex applyborder"}>
                                    <div className="w-50">
                                        <Select
                                            options={selectdata}
                                            className="ecommerce-dropdown noborder border-0"
                                            classNamePrefix="common-select"
                                            value={{ label: allfromdata?.phonecode, value: allfromdata?.phonecode }}
                                            onChange={(e) => setalldata("phonecode", e.value)}
                                        />
                                    </div>
                                    <input
                                        type="number"
                                        className={"form-control col-md-4 w-50 border-0"}
                                        id="phone"
                                        autoComplete="off"
                                        value={allfromdata?.mobile}
                                        onChange={(e) => setalldata("mobile", e.target.value)}
                                    />
                                </div>
                                {validation?.mobile ? <div className="text-danger">{validation?.mobile}</div> : ""}
                            </div>
                            <div className="com-formInputBox">
                                <label htmlFor="email" className="form-label text-sm-start text-dark">{t("Email")} <span className="com-starShow">*</span></label>
                                <input
                                    type="text"
                                    className={validation?.email ? "form-control border-danger" : "form-control"}
                                    id="Email"
                                    value={allfromdata?.email}
                                    onChange={(e) => setalldata("email", e.target.value)}
                                />
                                {validation?.email ? <div className="text-danger">{validation?.email}</div> : ""}
                            </div>
                        </div>
                        <div className="appointmentModal-contentBox-bodyBox-block">
                            <div className="com-formInputBox">

                                <label htmlFor="Message" className="form-label text-sm-start text-dark">{t("Message")} <span className="com-starShow">*</span></label>

                                <textarea
                                    type="text"
                                    className={validation?.message ? "form-control border-danger" : "form-control"}
                                    id="Message"
                                    value={allfromdata?.message}
                                    onChange={(e) => setalldata("message", e.target.value)}
                                />
                                {validation?.message ? <div className="text-danger">{t(`${validation?.message}`)}</div> : ""}
                            </div>
                        </div>
                        {validation?.error ?
                            <div className="text-end text-danger">{t(`${validation?.error}`)}</div> :
                            ""}
                    </div>
                    {loadeing ?
                        (<div className=" w-100 d-flex justify-content-center align-items-center  ">
                            <Spinner />
                        </div>) :
                        <div className=" appointmentModal-contentBox-footerBox mt-3" >
                            <button className="com-ecomCancelBtn" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => { setValidation() }}>{t("Cancel")}</button>
                            <button className="com-ecomSendBtn" onClick={() => addappointment()}>{t("Submit")}</button>
                        </div>}

                </div>
            </div>
        </div >
    )
};

export default Appointmentmodal;
