import React, { useState } from 'react'
import WatchIcon from '../../assets/images/watchIcon.png';
import Barcode from '../../assets/images/Barcode.png';
import ShareIcon from '../../assets/icons/ShareIcon';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import PrintIcon from '../../assets/icons/PrintIcon';
import CartIcon from '../../assets/icons/CopyIcon';
import { Link, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import RightArrow from '../../../../../admin/common/icons/right-arrow';
import Location from '../../../../../admin/common/icons/sidebar/Location';
const AppointmentConfirm = () => {
    const { state } = useLocation()
    const { t } = useTranslation();
    const [showstatus, setShowstatus] = useState(false)
    const [appoinmentconfirm, setappoinmentconfirm] = useState(true)
    const handleCopyClick = async (cupyinput) => {
        try {
            await navigator.clipboard.writeText(cupyinput);
            setShowstatus(true)
            setTimeout(() => {
                setShowstatus(false);
            }, 2000);
        } catch (err) {
            console.log(err, "err");
        }
    };
    const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('appoinmentdata');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL(`image/png`);
            const anchor = document.createElement('a');
            anchor.download = `${state?.appointment_id}.png`;
            anchor.href = imageURL;
            anchor.click();
        });
    };
    const handlePrint = () => {
        window.print();
    };
    return (
        <div className='appointmentPage' id='appoinmentdata'>
            <div className='appointmentPage-header'>
                <span className='com-path-show-currectPath'>{t("Categories")}</span> 
                <span className='SlashPath'><RightArrow/></span>
                <span className='com-path-show-startPath'>{t("Appointment")}</span>
            </div>
            <div className='com-confirmPurchasePage-main mx-auto'>
            <div className='com-confirmPurchasePage-main-containerBox-getDocsIconBox'>
                        <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan'><ShareIcon /></span>
                        <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan' onClick={() => downloadPageAsPNG()}><DownloadIcon /></span>
                        <span className='com-confirmPurchasePage-main-containerBox-getDocsIconBox-Iconspan'
                            onClick={() => handlePrint()}><PrintIcon /></span>
                    </div>
                <div className='com-confirmPurchasePage-main-headerBox'>
                    <h3 className='com-confirmPurchasePage-main-headerBox-thankYouText'>{t("Thank you for your appointment!")}</h3>
                    <span className='com-confirmPurchasePage-main-headerBox-doneIconBox'>
                        <img src={WatchIcon} alt='' />
                    </span>
                </div>
                <div className='com-confirmPurchasePage-main-containerBox'>
                    
                    <div className='com-confirmPurchasePage-main-containerBox-middleContentBox'>
                        <div className='com-confirmPurchasePage-main-containerBox-middleContentBox-purchaseIdBox'><span className='idShows'>{t("Appointment ID")} : #{state?.appointment_id}</span> <span onClick={() => handleCopyClick(state?.appointment_id)}><CartIcon widt={'19px'} height={'19px'} /></span> </div>
                        <span className='text-center'>{showstatus ? t("Copy Successfully") : ""} </span>
                        <div className='com-confirmPurchasePage-main-containerBox-middleContentBox-barbox'><img src={state?.appointmentDetails?.Barcode ? state?.appointmentDetails?.Barcode : Barcode }/></div>
                        <div className='com-confirmPurchasePage-main-containerBox-middleContentBox-thanksParaBox text-danger'>{t("Please arrive at the location during business hours for expedited service")}.</div>
                    </div>
                    <div className='com-confirmPurchasePage-main-containerBox-allshippingAddrShow'>
                        <div className="com-addressShowBox com-confirmPurchasePage-main-containerBox-allshippingAddrShow-singleShippAddr">
                            <div className="com-addressShowBox-headerBox d-flex justify-space-between m-0 w-0">
                                <h2 className='shippingaddrHeader'>{t("Appointment Details")}</h2>
                                <h3 className="shippingaddrHeaderright">
                                    {t("Date")} : {state?.appointment_date}
                                </h3>
                            </div>
                            <div className='com-addressShowBox-holder'>
                                <h3 className="com-addressShowBox-AddrparaBox fw-normal">
                                   <div className='com-addressShowBox-AddrparaBox-title'>{t("Location")}</div> 
                                   <div className='com-addressShowBox-AddrparaBox-content'>{state?.branchDetails?.name}<br/> 
                                   <span><Location color="#767676" width="11px" height="15px"/>{state?.branchDetails?.store_Address}</span>
                                   </div>
                                </h3>
                                <p className="com-addressShowBox-AddrparaBox w-100 fw-normal">
                                    <div className='com-addressShowBox-AddrparaBox-title'>{t("Customer")}</div> 
                                    <div className='com-addressShowBox-AddrparaBox-content'>{state?.appointmentDetails?.name}</div>
                                </p>
                                <p className="com-addressShowBox-AddrparaBox w-100 fw-normal">
                                    <div className='com-addressShowBox-AddrparaBox-title'>{t("Phone")}</div> 
                                    <div className='com-addressShowBox-AddrparaBox-content'>{state?.appointmentDetails?.contact}</div>
                                </p>
                                <p className="com-addressShowBox-AddrparaBox">
                                    <div className='com-addressShowBox-AddrparaBox-title'>{t("Email")}</div> 
                                    <div className='com-addressShowBox-AddrparaBox-content'>{state?.appointmentDetails?.email}</div> 
                                </p>
                                <p className="com-addressShowBox-AddrparaBox">
                                    <div className='com-addressShowBox-AddrparaBox-title'>{t("Status")}</div> 
                                    <div className='com-addressShowBox-AddrparaBox-content'>{state?.appointmentDetails?.contact}</div>
                                </p>
                            </div>
                        </div>
                        {/* <div className="com-addressShowBox com-confirmPurchasePage-main-containerBox-allshippingAddrShow-singleShippAddr">
                            <div className="com-addressShowBox-headerBox">
                                <h2 className='shippingaddrHeader'>{t("Appointment branch")}</h2>
                            </div>
                            <div className='d-flex flex-column'>
                                <h3 className="com-addressShowBox-AddrparaBox ">
                                    
                                </h3>
                                <p className="com-addressShowBox-AddrparaBox w-100 ">
                                    {state?.branchDetails?.store_Address}
                                </p>
                                <p className="com-addressShowBox-AddrparaBox w-100">
                                    {state?.branchDetails?.email}
                                </p>
                                <p className="com-addressShowBox-phonePara mt-1">
                                    
                                </p>
                            </div>
                            <div className='mt-1 d-flex flex-column gap-2 w-100'>
                                <p className="com-addressShowBox-AddrparaBox" >{t("Store Hours")}</p>
                                <div className='w-100 justify-content-start com-addressShowBox-daytimeSet'>
                                    {Object.keys(state?.branchDetails?.store_timeSchedule || {}).map((key) => (
                                        <div className='com-addressShowBox-daytimeSet-singleDay' key={key}>
                                            <p className='com-addressShowBox-phonePara com-addressShowBox-daytimeSet-singleDay-day'>{key}</p>
                                            <p className='com-addressShowBox-phonePara com-addressShowBox-daytimeSet-singleDay-time'>{state?.branchDetails?.store_timeSchedule[key]}</p>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div> */}
                    </div>
                </div>
                {appoinmentconfirm ?
                    <div className='com-confirmPurchasePage-main-footerBox'>
                        <Link className='com-confirmPurchasePage-main-footerBox-continueShopBtn d-flex justify-content-center align-items-center text-decoration-none' to="/">{t("Continue Shopping")}</Link>
                        <button className='com-confirmPurchasePage-main-footerBox-completeBtn' onClick={() => setappoinmentconfirm(false)}>{t("OK")}</button>
                    </div> : ""}
            </div>
        </div>
    )
}

export default AppointmentConfirm