import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { success, error } from "../../common/helpers/toastify";
import "../../catalog/catalog.scss";
import CheckoutBlank from '../../checkout/checkoutBlank';
import { _Api } from "../../common/api/_call";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { toFormatPrice } from '../../common/helpers/function';
import { useSelector } from 'react-redux';
import Loading from '../../common/module/Loading/Loading';
import { useTranslation } from 'react-i18next';
const CreateYourOwnDetails = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { state } = useLocation();
  if (state?.product_id) {
    let stringify = JSON.stringify(state)
    localStorage.setItem('productcustomrdata', stringify);
  }
  const itemdaata = localStorage.getItem("productcustomrdata")
  const parsedObject = JSON.parse(itemdaata);
  let navigate = useNavigate();
  const saleperson = useSelector((state) => state.app.saleperson);
  const customer = useSelector((state) => state.app.customer);
  const [addtocartstatus, setaddtocartstatus] = useState(1);
  const [productdetails, setProductdetails] = useState();
  const [Images, setImages] = useState([]);
  const [Loadingstatus, setLoadingstatus] = useState(false);
  const [MetalOption, setMetalOption] = useState([]);
  const [StoneOption, setStoneOption] = useState([]);
  const [SizeOption, setSizeOption] = useState([]);
  const [allchangeitems, setAllchangeitems] = useState({ 'metal': '', 'stone': '', 'size': '' });
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.pos_custom_order?.View) {
      setaddtocartstatus(parmissiondatashow?.pos_custom_order?.Add);
    }
  }, [parmissiondatashow]);
  const getproductdetails = async () => {
    setLoadingstatus(true);
    try {
      const postdata = {
        "product_id": state?.product_id ? state?.product_id : parsedObject?.product_id,
        "Metal": allchangeitems?.metal,
        "Size": allchangeitems?.size,
        "Stone": allchangeitems?.stone,
        'type': state?.filter || parsedObject?.filter ? 'catalog' : 'inventory'
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/getProductDetail_Variants`);
      if (res?.code === 200) {
        setLoadingstatus(false);
        let iarray = []; let marray = []; let starray = []; let sarray = [];
        setProductdetails(res?.data);
        res?.data?.main_image_Arr.forEach((result) => {
          iarray.push({
            original: result?.image,
            thumbnail: result?.thumb,
          });
        })

        if (res?.data?.metal_value?.length) {
          res?.data?.metal_value.forEach((result) => {
            marray.push({ value: result?._id, label: result?.name })
          })
        }
        if (res?.data?.stone_value?.length) {
          res?.data?.stone_value.forEach((result) => {
            starray.push({ value: result?._id, label: result?.name })
          })
        }
        if (res?.data?.size_value?.length) {
          res?.data?.size_value.forEach((result) => {

            sarray.push({ value: result?._id, label: result?.name })
          })
        }
        if (allchangeitems?.metal === '') {
          setAllchangeitems(previousInputs => ({ ...previousInputs, metal: res?.data?.metal_value[0]?._id }))
        }
        if (allchangeitems?.size === '') {
          setAllchangeitems(previousInputs => ({ ...previousInputs, size: res?.data?.size_value[0]?._id }))
        }
        if (allchangeitems?.stone === '') {
          setAllchangeitems(previousInputs => ({ ...previousInputs, stone: res?.data?.stone_value[0]?._id }))
        }
        if (allchangeitems?.metal_name === '') {
          setAllchangeitems(previousInputs => ({ ...previousInputs, metal_name: res?.data?.metal_value[0]?.name }))
        }
        if (allchangeitems?.size_name === '') {
          setAllchangeitems(previousInputs => ({ ...previousInputs, size_name: res?.data?.size_value[0]?.name }))
        }
        setImages(iarray);
        setMetalOption(marray);
        setStoneOption(starray);
        setSizeOption(sarray);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const AddtoCart = async (product_id) => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    try {
      setaddtocartstatus(0);
      const postdata = {
        "product_id": product_id,
        'type': state?.filter || parsedObject?.filter ? 'catalog' : 'inventory',
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "order_type": "custom_order"
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`);
      if (res.code === 200) {
        setaddtocartstatus(1);
        if (parsedObject?.order_id && parsedObject?.order_edit && parsedObject?.order_type) {
          navigate('/app/pos/custom/createYourOwnSetting', {
            state: { cartid: res?.data, filtertype: parsedObject?.filter, order_id: parsedObject?.order_id, order_edit: parsedObject?.order_edit, order_type: parsedObject?.order_type },
          })
        } else {
          navigate('/app/pos/custom/createYourOwnSetting', {
            state: { cartid: res?.data, filtertype: parsedObject?.filter },
          })
        }
        success(t("Successfully Add to Cart!!"));
      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    getproductdetails()
    // eslint-disable-next-line
  }, [allchangeitems?.metal, allchangeitems?.stone, allchangeitems?.size])
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area">
              <div className='catalog-area-header-area detail-area-header'>
                <div className='catalog-area-header-area-control'>
                  <div className='catalog-area-header-area-control-left'>
                    <Link to="/app/pos/custom/createYourOwn" state={{ optiondetailid: parsedObject?.filter, changecolor: parsedObject?.changecolor }} className='page-back'>
                      <img className='page-back-img' src={base_assets + "images/pos/icons/arrow-gray-left.png"} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="catalog-area-ct bg-white">
                {
                  Loadingstatus ? <Loading /> : ''
                }
                <div className='products-details'>
                  <div className='products-details-row'>
                    <div className='products-details-row-col products-details-row-col-left'>
                      <div className='products-details-row-col-left-slider'>
                        <ImageGallery
                          items={Images}
                          showPlayButton={false}
                          showFullscreenButton={false}
                          showNav={false}
                          infinite={true}
                          disableSwipe={false}
                          disableThumbnailSwipe={false}
                          additionalClass="img-view-slider"
                        />
                      </div>
                      <div className={productdetails?.inventory_statistics?.total ? 'products-details-row-col-left-location' : "d-none"}>
                        <div className="dropdown">
                          <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img className='products-details-row-col-left-location-img' src={base_assets + "images/pos/icons/location-icon.png"} alt="" />
                            <span>{productdetails?.inventory_statistics?.total}</span>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {productdetails?.inventory_statistics?.response?.length ? productdetails?.inventory_statistics?.response.map((result, lockey) => {
                              return (
                                <li key={lockey}>
                                  <Link className="dropdown-item" >
                                    {result?.currency ?
                                      <img src={base_assets + "images/flags/" + result?.currency.toLowerCase() + ".png"} alt="" />
                                      : ''
                                    }
                                    <span className='fw-light'>{result?.location_name}</span>
                                    <span className='ms-auto fw-light'>{result?.qty}</span>
                                  </Link>
                                </li>
                              );
                            }) : ''}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='products-details-row-col products-details-row-col-right'>
                      <h1 className='main-heading'>
                        {productdetails?.name}
                      </h1>
                      <h2 className="products-details-row-col-right-sku-heading">
                        {productdetails?.SKU ? productdetails?.SKU : ""}
                      </h2>
                      <p className='products-details-row-col-right-short-desc'>{productdetails?.Matatag}</p>
                      <div className='products-details-row-col-right-select'>
                        <div className='products-details-row-col-right-select-single'>
                          <label className='products-details-row-col-right-select-single-label'>{t("Metal")} :</label>
                          <div className="common-select">
                            {MetalOption?.length ?
                              <Select
                                onChange={(input) => setAllchangeitems({ ...allchangeitems, metal: input?.value })}
                                options={MetalOption}
                                classNamePrefix="common-select"
                                defaultValue={{ value: MetalOption[0]?.value, label: MetalOption[0]?.label }}
                                isSearchable={false}
                              />
                              : <span className='not_available'>{t("Not Available")}</span>}
                          </div>
                        </div>
                        <div className='products-details-row-col-right-select-single'>
                          <label className='products-details-row-col-right-select-single-label'>{t("Stone")} :</label>
                          <div className="common-select">
                            {
                              StoneOption?.length ?
                                <Select
                                  onChange={(input) => setAllchangeitems({ ...allchangeitems, stone: input?.value })}
                                  options={StoneOption}
                                  classNamePrefix="common-select"
                                  defaultValue={{ value: StoneOption[0]?.value, label: StoneOption[0]?.label }}
                                  isSearchable={false}
                                />
                                : <span className='not_available'>{t("Not Available")}</span>
                            }
                          </div>
                        </div>
                        <div className='products-details-row-col-right-select-single'>
                          <label className='products-details-row-col-right-select-single-label'>{t("Size")} :</label>
                          <div className="common-select">
                            {
                              SizeOption?.length ?
                                <Select
                                  onChange={(input) => setAllchangeitems({ ...allchangeitems, size: input?.value })}
                                  options={SizeOption}
                                  classNamePrefix="common-select"
                                  defaultValue={{ value: SizeOption[0]?.value, label: SizeOption[0]?.label }}
                                  isSearchable={false}
                                />
                                : <span className='not_available'>{t("Not Available")}</span>
                            }

                          </div>
                        </div>
                      </div>
                      <p className='products-details-row-col-right-price'>
                        {productdetails?.retailprice_Inc ? toFormatPrice(productdetails?.retailprice_Inc, { addSymbol: true }) : ""}
                      </p>
                      <div className='products-details-row-col-right-btn-wrap'>
                        {addtocartstatus ?
                          <div className='border-inner-btn hover-effect'>
                            <button onClick={() => AddtoCart(productdetails?.product_id)} className='add-to-cart' >{t("ADD TO CART")}</button>
                          </div>
                          :
                          <div className='border-inner-btn hover-effect disabled-hover-btn'>
                            <button className='btn-disabled add-to-cart'>{t("ADD TO CART")}</button>
                          </div>
                        }

                      </div>
                      <div className='products-details-row-col-right-tabs-area'>
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#Description">{t("Description")}</a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div className="tab-pane container active" id="Description">
                            {productdetails?.Description ? productdetails?.Description : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )

}

export default CreateYourOwnDetails;