import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { DateRange } from "../../common/helpers/daterange";
import { useTranslation } from 'react-i18next';
import { _Api } from "../../api/_call";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import RightArrow from "../../common/icons/right-arrow";
import "../customers.scss";
import { toFormatPrice } from '../../common/helpers/function';
import Select from "react-select"
import CustomerInformation from "../customerInformation/CustomerInformation";
import TableLoader from "../../common/TableLoader";

const CustomerReportDetail = () => {
  const { t } = useTranslation();
  const { state } = useLocation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [datefilter, setDatefilter] = useState([]);
  const [type, setType] = useState("ALL")
  const [customerdetailslist, setCustomerdetailslist] = useState([])
  const [limit, setLimit] = useState(100)
  const [customercount, setCustomercount] = useState()
  const [customerdetails, setCustomerdetails] = useState()
  const [cusid, setCusid] = useState("")
  const [showloader, setShowloader] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const getBgClassName = (OrderType) => {
    switch (OrderType) {
      case 'custom_order':
        return 'report-pink-bg';
      case 'mix_and_match':
        return 'report-yellow-bg';
      case 'pos_order':
        return 'report-green-bg';
      case 'repair_order':
        return 'report-purple-bg';
      case 'exchange_order':
        return 'report-blue-bg';
      case 'refund_order':
        return 'report-sky-bg';
      case 'WishList':
        return 'report-gray-bg';
      default:
        return 'report-pink-bg'; // Fallback if no matching case
    }
  };
  const getcustomerreportdetails = async (firstlimit) => {
    setShowloader(true)
    try {
      const postdata = {
        "customer_id": state?.customer_id,
        "type": type ? type : "ALL",
        "limit": firstlimit ? firstlimit : limit,
        "start_date": datefilter ? datefilter[0] : '',
        "end_date": datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/Customer/customerReport/orderDetail`)
      if (res?.code === 200) {
        setCustomerdetailslist(res?.data)
        setCustomercount(res?.total)
        setShowloader(false)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const getcustomerdeteails = async () => {
    try {
      let postdata = {
        "customer_id": state?.customer_id,

      }
      let res = await _Api(postdata, `api/v1/Customer/customer/customerData`)
      if (res?.code === 200) {
        setCustomerdetails(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }

  }

  useEffect(() => {
    getcustomerreportdetails()
    // eslint-disable-next-line 
  }, [type, datefilter])

  useEffect(() => {
    getcustomerdeteails()
    // eslint-disable-next-line 
  }, [])

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    if (type === "ALL") {
      header_values.push("#", "Date Time", "Transaction", "Ref No.", "Location", "SKU", "Stock ID", "Metal", "Size", "Stone", "Price"
        , "Qty", "	Amount")
    } else if (type === "pos_order") {
      header_values.push("#", "Date Time", "Ref No.", "Location", "SKU", "Stock ID", "Metal", "Size", "Stone", "Price", "Qty", "	Amount"
        , "Service Labour")
    } else if (type === "custom_order") {
      header_values.push("#", "Date Time", "Ref No.", "Location", "SKU", "Metal", "Size", "Stone", "Price", "Qty", "	Amount", "Concept"
        , "Sketch", "Picture", "Engraving (Text)", "Engraving (Logo)", "Service Labour", "Delivery Date", "Remark")
    } else if (type === "mix_and_match") {
      header_values.push("#", "Date Time", "Ref No.", "Location", "SKU", "Metal", "Size", "Diamond ID", "Shape", "Pointer", "	DPrice"
        , "Price", "Qty", "Amount", "Engraving (Text)", "Engraving (Logo)", "Service Labour", "Delivery Date", "Remark")
    } else if (type === "repair_order") {
      header_values.push("#", "Date Time", "Ref No.", "Location", "SKU", "Stock ID", "Metal", "Size", "Stone", "Price", "	Qty", "Amount"
        , "Process", "Picture", "Service Labour", "Delivery Date", "Remark")
    } else if (type === "exchange_order") {
      header_values.push("#", "Date Time", "Ref No.", "Location", "SKU", "Stock ID", "Metal", "Size", "Stone", "Price", "	Qty", "Amount"
        , "Remark")
    } else if (type === "refund_order") {
      header_values.push("#", "Date Time", "Ref No.", "Location", "SKU", "Stock ID", "Metal", "Size", "Stone", "Price", "	Qty", "Amount"
        , "Remark")
    } else if (type === "WishList") {
      header_values.push("#", "Date Time", "image", "SKU", "Product Name", "Metal", "Size", "Stone", "Price", "Location")
    } else {
      header_values.push([])
    }
    if (customerdetailslist.length) {
      customerdetailslist.forEach((result, key) => {
        if (type === "ALL") {
          bodyarray.push([key + 1, result?.date, result?.transection, result?.ref_no, result?.location, result?.SKU
            , result?.stock_id, result?.metal, result?.size, result?.stone_name, result?.price, result?.Qty, result?.amount])
        } else if (type === "pos_order") {
          bodyarray.push([key + 1, result?.date, result?.ref_no, result?.location, result?.SKU
            , result?.stock_id, result?.metal, result?.size, result?.stone_name, result?.price, result?.Qty, result?.amount,
          result?.service_labour])
        } else if (type === "custom_order") {
          bodyarray.push([key + 1, result?.date, result?.ref_no, result?.location, result?.SKU
            , result?.metal, result?.size, result?.stone_name, result?.price, result?.Qty, result?.amount, result?.Concept, result?.Sketch
            , result?.Picture, result?.EngravingText, result?.EngravingLogo, result?.service_labour, result?.delivery_date, result?.remark])
        } else if (type === "mix_and_match") {
          bodyarray.push([key + 1, result?.date, result?.ref_no, result?.location, result?.SKU
            , result?.metal, result?.size, result?.diamond_id, result?.shape, result?.pointer, result?.diamond_price, result?.price
            , result?.Qty, result?.amount, result?.EngravingText, result?.EngravingLogo, result?.service_labour, result?.delivery_date
            , result?.remark])
        } else if (type === "repair_order") {
          bodyarray.push([key + 1, result?.date, result?.ref_no, result?.location, result?.SKU, result?.stock_id
            , result?.metal, result?.size, result?.stone_name, result?.price, result?.Qty, result?.amount, result?.process
            , result?.image, result?.service_labour, result?.delivery_date, result?.remark])
        } else if (type === "exchange_order") {
          bodyarray.push([key + 1, result?.date, result?.ref_no, result?.location, result?.SKU, result?.stock_id
            , result?.metal, result?.size, result?.stone_name, result?.price, result?.Qty, result?.amount, result?.remark])
        } else if (type === "refund_order") {
          bodyarray.push([key + 1, result?.date, result?.ref_no, result?.location, result?.SKU, result?.stock_id
            , result?.metal, result?.size, result?.stone_name, result?.price, result?.Qty, result?.amount, result?.remark])
        } else if (type === "WishList") {
          bodyarray.push([key + 1, result?.date, result?.main_image, result?.SKU, result?.name, result?.metal, result?.size
            , result?.stone_name, result?.price, result?.location])
        } else {
          bodyarray.push([])
        }
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "Customer Details");

  }
  return (
    <React.Fragment>
      <div className="main-body-top-inventory main-body main-content-wrapper-body customer-report-detail">

        <div className="row">
          <div className="col-lg-12">
            <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
              <div className="main-content-wrapper-body-top-bar-left">
                <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                  {t("Customer")} <RightArrow /><Link className="text-decoration-none" to="/app/admin/customers/customersreport">{t("Report")} </Link> <RightArrow /> <span> {customerdetails?.name}{customerdetails?.name ? "’s Report" : ""}</span>
                </h1>
              </div>
            </div>
            <ul className="customer-history-tabs nav nav-tabs">
              <li className="nav-item">
                <Link className="active all customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#all"
                  onClick={() => setType("ALL")}>{t("All")} <span clasName="gray-text">({customercount?.all ? customercount?.all : 0})</span> </Link>
              </li>
              <li className="nav-item">
                <Link className="sales customer-history-tabs-single gray-text d-inline-block py-2 mx-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#sale"
                  onClick={() => setType("pos_order")}>{t("Sales")} <span clasName="gray-text">
                    ({customercount?.pos_order ? customercount?.pos_order : 0})</span> </Link>
              </li>
              <li className="nav-item">
                <Link className="custom-order customer-history-tabs-single gray-text d-inline-block py-2 mx-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#custom-order"
                  onClick={() => setType("custom_order")}>{t("Custom Order")} <span clasName="gray-text">
                    ({customercount?.custom_order ? customercount?.custom_order : 0})</span> </Link>
              </li>
              <li className="nav-item">
                <Link className="mix-match customer-history-tabs-single gray-text d-inline-block py-2 mx-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#mix-match"
                  onClick={() => setType("mix_and_match")}>{t("Mix & Match")} <span clasName="gray-text">
                    ({customercount?.mix_and_match ? customercount?.mix_and_match : 0})</span> </Link>
              </li>
              <li className="nav-item">
                <Link className="repair-order customer-history-tabs-single gray-text d-inline-block py-2 mx-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#repair-order"
                  onClick={() => setType("repair_order")}>{t("Repair Order")} <span clasName="gray-text">
                    ({customercount?.repair_order ? customercount?.repair_order : 0})</span> </Link>
              </li>
              <li className="nav-item">
                <Link className="exchange customer-history-tabs-single gray-text d-inline-block py-2 mx-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#exchange"
                  onClick={() => setType("exchange_order")}>{t("Exchange")} <span clasName="gray-text">
                    ({customercount?.exchange_order ? customercount?.exchange_order : 0})</span> </Link>
              </li>
              <li className="nav-item">
                <Link className="refund customer-history-tabs-single gray-text d-inline-block py-2 mx-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#refund"
                  onClick={() => setType("refund_order")}>{t("Refund")} <span clasName="gray-text">
                    ({customercount?.refund_order ? customercount?.refund_order : 0})</span> </Link>
              </li>
              <li className="nav-item">
                <Link className="wishlist customer-history-tabs-single gray-text d-inline-block py-2 mx-2 fw-semibold text-decoration-none me-2" data-bs-toggle="tab" to="#wishlist"
                  onClick={() => setType("WishList")}>{t("Wishlist")} <span clasName="gray-text">
                    ({customercount?.wishlist ? customercount?.wishlist : 0})</span> </Link>
              </li>
            </ul>
            <div className="customer-history-inner-ct bg-white pb-4">
              <div className="customer-history-inner-ct-top-bar d-flex align-items-center px-4">
                {customerdetails?.profile ?
                  <img className="me-3 customer-history-inner-ct-top-bar-img"
                    src={customerdetails?.profile} alt="" /> :
                  <img className="me-3 customer-history-inner-ct-top-bar-img"
                    src={base_assets + 'images/icons/customer-card-icon.png'} alt="" />
                }
                <span
                  onClick={() => setCusid(state?.customer_id)}
                  className="customer-history-inner-ct-top-bar-name bg-transparent border-0 p-0 link-color cursor-pointer"
                  data-bs-toggle="modal" data-bs-target="#customerAddModal">
                  {customerdetails?.name ? customerdetails?.name : "-"}
                </span>
                {
                  customerdetails?.group === "Platinum" ?
                    <img className="customer-history-inner-ct-top-bar-medal ms-3"
                      src={base_assets + "images/icons/platinum-medal.png"} alt="" /> :
                    customerdetails?.group === "Gold" ?
                      <img className="customer-history-inner-ct-top-bar-medal ms-3"
                        src={base_assets + "images/icons/gold-medal.png"} alt="" /> :
                      customerdetails?.group === "Silver" ?
                        <img className="customer-history-inner-ct-top-bar-medal ms-3"
                          src={base_assets + "images/icons/silver-medal.png"} alt="" /> :
                        customerdetails?.group === "Diamond" ?
                          <img className="customer-history-inner-ct-top-bar-medal ms-3"
                            src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : ""
                }
                <i className="fa fa-smile-o mx-3 fs-5 lighgreencolor" aria-hidden="true"></i>
                <span className="d-flex align-items-center"><img src={base_assets + "images/icons/gray-location.png"} alt="" />
                  {" - "}  {customerdetails?.location ? customerdetails?.location : 0}</span>
                <span className="ms-auto gray-mid">{customerdetails?.city ? customerdetails?.city : ""}{customerdetails?.city ? ", " : ""} {customerdetails?.country ? customerdetails?.country : ""}</span>
              </div>
              <div className="main-body-top-status-bar mb-3 mt-4 px-4 main-content-wrapper-body-top-status-bar main-content-wrapper-body-top-bar-right">

                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getcustomerreportdetails(e.value) }}
                  isSearchable={false}
                />
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 justify-content-center bg-white toggle-btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {/* <li className="dropdown-item print-dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li> */}
                      <li className="dropdown-item"
                        onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane active" id="all">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Transaction")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Ref No.")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Location")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /> </th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stock ID")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stone")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Qty")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Amount")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>

                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist?.length ? customerdetailslist.map((result, key) => {
                            const bgClassName = getBgClassName(result?.transection);
                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"} </td>
                                <td>{result?.transection ? result?.transection : "-"}</td>
                                <td>
                                  <div className="tooltip-area">{result?.ref_no ? result?.ref_no : "-"}
                                    <span className={`tooltip-area-text ${bgClassName} rounded-1 shadow-none fs-6 p-3 text-start lh-base top-100`} style={{ bottom: 'auto' }}>{result?.ref_no} <br /> ({(result?.location)})<br />{result?.stock_id}</span>
                                  </div>
                                </td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td>
                                  <button className="border-0 p-0 bg-transparent link-color">{result?.SKU ? result?.SKU : "-"}</button>
                                </td>
                                <td>{result?.stock_id ? result?.stock_id : "-"}</td>
                                <td>{result?.metal ? result?.metal : "-"}</td>
                                <td>{result?.size ? result?.size : "-"}</td>
                                <td>{result?.stone_name ? result?.stone_name : "-"}</td>
                                <td>{toFormatPrice(result?.price, { addSymbol: true })}</td>
                                <td>{result?.Qty ? result?.Qty : "-"}</td>
                                <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>

                              </tr>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                      {showloader && <TableLoader />}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="sale">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Location")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /> </th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stock ID")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stone")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Qty")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Amount")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{("Service Labour")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist?.length ? customerdetailslist.map((result, key) => {
                            const bgClassName = getBgClassName(result?.transection);

                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"} </td>
                                <td>
                                  <div className="tooltip-area">{result?.ref_no ? result?.ref_no : "-"}
                                    <span className={`tooltip-area-text ${bgClassName} rounded-1 shadow-none fs-6 p-3 text-start lh-base top-100`} style={{ bottom: 'auto' }}>{result?.ref_no} <br /> ({(result?.location)})<br />{result?.stock_id}</span>
                                  </div>
                                </td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td className="link-color">{result?.SKU ? result?.SKU : "-"}</td>
                                <td>{result?.stock_id ? result?.stock_id : "-"}</td>
                                <td>{result?.metal ? result?.metal : "-"}</td>
                                <td>{result?.size ? result?.size : "-"}</td>
                                <td>{result?.stone_name ? result?.stone_name : "-"}</td>
                                <td>{result?.price ? result?.price : "-"}</td>
                                <td>{result?.Qty ? result?.Qty : "-"}</td>
                                <td>{result?.amount ? result?.amount : "-"}</td>
                                <td>{result?.service_labour ? result?.service_labour : "-"}</td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>

                              </tr>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="custom-order">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Location")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /> </th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stone")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Qty")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Amount")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{("Concept")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Sketch")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Picture")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Engraving")} (Text) <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Engraving")} (Logo) <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{("Service Labour")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Delivery Date")} </th>
                            <th style={{ minWidth: '180px' }}> {t("Remark")} </th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist.length ? customerdetailslist.map((result, key) => {
                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"}</td>
                                <td>{result?.ref_no ? result?.ref_no : "-"}</td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td className="link-color">{result?.SKU ? result?.SKU : "-"}</td>
                                <td>{result?.metal ? result?.metal : "-"}</td>
                                <td>{result?.size ? result?.size : "-"}</td>
                                <td>{result?.stone_name ? result?.stone_name : "-"}</td>
                                <td>{result?.price ? result?.price : "-"}</td>
                                <td>{result?.Qty ? result?.Qty : "-"}</td>
                                <td>{result?.amount ? result?.amount : "-"}</td>
                                <td className="text-center">
                                  {result?.Concept ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : "-"}
                                </td>
                                <td className="text-center"> {result?.Sketch ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : "-"}</td>
                                <td className="text-center"> {result?.Picture ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : "-"}</td>
                                <td className="text-center"> {result?.EngravingText ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : "-"}</td>
                                <td className="text-center"> {result?.EngravingLogo ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : "-"}</td>
                                <td> {result?.service_labour ? result?.service_labour : "-"}</td>
                                <td>{result?.delivery_date ? result?.delivery_date : "-"}</td>
                                <td>{result?.remark ? result?.remark : "-"}</td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>
                              </tr>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="mix-match">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Location")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /> </th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Diamond ID")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Shape")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Pointer")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("DPrice")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Qty")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Amount")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Engraving")} (Text) <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Engraving")} (Logo) <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{("Service Labour")}<img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Delivery Date")} </th>
                            <th style={{ minWidth: '180px' }}> {t("Remark")} </th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist?.length ? customerdetailslist?.map((result, key) => {
                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"}</td>
                                <td>{result?.ref_no ? result?.ref_no : "-"}</td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td className="link-color">{result?.SKU ? result?.SKU : "-"}</td>
                                <td>{result?.metal ? result?.metal : "-"}</td>
                                <td>{result?.size ? result?.size : "-"}</td>
                                <td>{result?.diamond_id ? result?.diamond_id : "-"}</td>
                                <td>{result?.shape ? result?.shape : "-"}</td>
                                <td>{result?.pointer ? result?.pointer : "-"}</td>
                                <td>{result?.diamond_price ? result?.diamond_price : "-"}</td>
                                <td>{result?.price ? result?.price : "-"}</td>
                                <td>{result?.Qty ? result?.Qty : "-"}</td>
                                <td>{result?.amount ? result?.amount : "-"}</td>
                                <td className="text-center"> {result?.EngravingText ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : "-"}</td>
                                <td className="text-center">{result?.EngravingLogo ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : "-"}</td>
                                <td>{result?.service_labour ? result?.service_labour : "-"}</td>
                                <td>{result?.delivery_date ? result?.delivery_date : "-"}</td>
                                <td>{result?.remark ? result?.remark : "-"}</td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>
                              </tr>
                            )

                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="repair-order">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Location")}<img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stock ID")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stone")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Qty")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Amount")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Process")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Picture")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{("Service Labour")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Delivery Date")}  </th>
                            <th style={{ minWidth: '180px' }}> {t("Remark")}  </th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist?.length ? customerdetailslist?.map((result, key) => {
                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"}</td>
                                <td>{result?.ref_no ? result?.ref_no : "-"}</td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td className="link-color">{result?.SKU ? result?.SKU : "-"}</td>
                                <td>{result?.stock_id ? result?.stock_id : "-"}</td>
                                <td>{result?.metal ? result?.metal : "-"}</td>
                                <td>{result?.size ? result?.size : "-"}</td>
                                <td>{result?.stone_name ? result?.stone_name : "-"}</td>
                                <td>{result?.price ? result?.price : "-"}</td>
                                <td>{result?.Qty ? result?.Qty : "-"}</td>
                                <td>{result?.amount ? result?.amount : "-"}</td>
                                <td>{result?.process ? result?.process : "-"}</td>
                                <td className="text-center">{result?.image ? <img src={base_assets + 'images/icons/True.png'} alt="" /> : ""}</td>
                                <td>{result?.service_labour ? result?.service_labour : "-"}</td>
                                <td>{result?.delivery_date ? result?.delivery_date : "-"}</td>
                                <td>{result?.remark ? result?.remark : "-"}</td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>
                              </tr>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="exchange">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Location")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /> </th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stock ID")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stone")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Qty")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Amount")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th style={{ minWidth: '180px' }}> {t("Remark")}  </th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist?.length ? customerdetailslist.map((result, key) => {
                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"} </td>
                                <td>{result?.ref_no ? result?.ref_no : "-"}</td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td className="link-color">{result?.SKU ? result?.SKU : "-"}</td>
                                <td>{result?.stock_id ? result?.stock_id : "-"}</td>
                                <td>{result?.metal ? result?.metal : "-"}</td>
                                <td>{result?.size ? result?.size : "-"}</td>
                                <td>{result?.stone_name ? result?.stone_name : "-"}</td>
                                <td>{result?.price ? result?.price : "-"}</td>
                                <td>{result?.Qty ? result?.Qty : "-"}</td>
                                <td>{result?.amount ? result?.amount : "-"}</td>
                                <td>{result?.remark ? result?.remark : "-"}</td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>
                              </tr>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="refund">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th>{t("Ref No.")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img></th>
                            <th>{t("Location")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /> </th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stock ID")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stone")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Qty")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Amount")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th style={{ minWidth: '180px' }}> {t("Remark")}  </th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist.length ? customerdetailslist?.map((result, key) => {
                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"} </td>
                                <td>{result?.ref_no ? result?.ref_no : "-"} </td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td className="link-color">{result?.SKU ? result?.SKU : "-"} </td>
                                <td>{result?.stock_id ? result?.stock_id : "-"} </td>
                                <td>{result?.metal ? result?.metal : "-"} </td>
                                <td>{result?.size ? result?.size : "-"} </td>
                                <td>{result?.stone_name ? result?.stone_name : "-"} </td>
                                <td>{result?.price ? result?.price : "-"} </td>
                                <td>{result?.Qty ? result?.Qty : "-"} </td>
                                <td>{result?.amount ? result?.amount : "-"} </td>
                                <td>{result?.remark ? result?.remark : "-"} </td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>
                              </tr>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="wishlist">
                  <div className="px-4">
                    <div
                      className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                    >
                      <table
                        id="my_customer"
                        className="com-custom-table-tag table align-middle mb-0">
                        <thead>
                          <tr className="text-nowrap align-middle">
                            <th>#</th>
                            <th>{t("Date Time")}</th>
                            <th></th>
                            <th>{t("SKU")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Product Name")}  <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Metal")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Size")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Stone")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Price")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Location")} <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" /></th>
                            <th>{t("Currency")} </th>
                            <th>{t("PDF")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerdetailslist?.length ? customerdetailslist.map((result, key) => {
                            return (
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"} </td>
                                <td className="table-pro-img">
                                  <img className="ms-2 com-custom-table-tag-filter-img" src={result?.main_image} alt="" />
                                </td>
                                <td className="link-color">{result?.SKU ? result?.SKU : "-"} </td>
                                <td className="text-uppercase">{result?.name ? result?.name : '-'} </td>
                                <td>{result?.metal ? result?.metal : "-"} </td>
                                <td>{result?.size ? result?.size : "-"} </td>
                                <td>{result?.stone_name ? result?.stone_name : "-"} </td>
                                <td>{result?.price ? result?.price : "-"} </td>
                                <td>{result?.location ? result?.location : "-"} </td>
                                <td className="text-center">{result?.currency ? result?.currency : "-"}</td>
                                <td>
                                  {result?.printURL === "" ? "-"
                                    : <div onClick={() => {
                                      if (result?.printURL) {
                                        window.open(result?.printURL, '_blank', 'noreferrer')
                                      }
                                    }}><img
                                        className="pdf-image cursor-pointer"
                                        src={base_assets + "images/icons/pdf-icon.png"}
                                        alt=""
                                      /></div>
                                  }
                                </td>
                              </tr>
                            )
                          }) :
                            <tr>
                              <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomerInformation
          cusid={cusid}
          setCusid={setCusid} />
      </div>
    </React.Fragment>
  );
};

export default CustomerReportDetail;