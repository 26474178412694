import React from 'react'

export default function TasksTick() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.227 6.99523C16.4081 7.64809 16.5 8.32247 16.5 8.99998C16.5 10.6761 15.9385 12.3039 14.9052 13.6236C13.8719 14.9433 12.4263 15.8788 10.7991 16.2809C9.17197 16.683 7.45702 16.5284 5.92797 15.8419C4.39892 15.1553 3.1439 13.9764 2.36322 12.4932C1.58253 11.01 1.32117 9.30803 1.62085 7.65893C1.92053 6.00984 2.76397 4.50865 4.01656 3.39494C5.26916 2.28124 6.85872 1.61922 8.53155 1.51453C10.2044 1.40984 11.8641 1.86852 13.2457 2.81736" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.20238 9.07648L7.938 11.8425L16.3155 3.18298" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
