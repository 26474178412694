import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
const NoInternetConnection = (props) => {
    const [isOnline, setOnline] = useState(true);
    const { t } = useTranslation();
    useEffect(() => {
        setOnline(navigator.onLine);
    }, []);

    window.addEventListener("online", () => {
        setOnline(false);
    });

    window.addEventListener("offline", () => {
        setOnline(false);
    });

    if (isOnline) {
        return props.children;
    } else {
        return (
            <div
            className="d-flex w-100 h-100 justify-content-center align-items-center position-absolute text-danger"
            >
                <h1>{t("No Internet Connection. Please try again later.")}</h1>
            </div>
        );
    }
};

export default NoInternetConnection;
