import React, { useEffect, useState } from 'react'
import { graphqlPost } from '../../api/_call'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
const AgentModal = (props) => {
  const { t } = useTranslation()
  const { handler_submit, handler_dismiss, setAgentvalue, setAWBvalue, agentmodalRef } = props

  const getAgent = async () => {
    try {
      const postdata = `{
        logistics {         
            id
            name
        }
    }`
      let res = await graphqlPost(postdata, 'Inventory/graphql')
      let agentarray = []
      res?.data?.logistics.forEach((lnames) => {
        agentarray.push({
          value: lnames?.id,
          label: lnames?.name,
        })
      })
      setAgentarray(agentarray)
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const [agentarray, setAgentarray] = useState([])
  const [agent, setAgent] = useState([])
  const [AWBvalue, setAWB] = useState()
  useEffect(() => {
    getAgent()
  }, [])

  return (
    <div
      className="modal fade common-modal agentModal"
      id="agentModal"
      tabIndex={-1}
      aria-labelledby="agentModalLabel"
      ref={agentmodalRef ? agentmodalRef : null}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          {agentmodalRef ? 
          <div className='modal-head'>
          {t("Enter the data")}
        </div>
        : ""
        }
            
          <div className="modal-body">
            <div className="common-select">
              <Select
                value={agent}
                onChange={(e) => {
                  setAgent(e)
                  setAgentvalue(e)
                }}
                className="select  p-0"
                placeholder={t('Agent')}
                options={agentarray}
                classNamePrefix="common-select"
                getOptionLabel={(e) => <div className="">{e.label}</div>}
                isSearchable={false}
              />
            </div>
            <div className="placeholder-up-input agentModal-input">
              <input
                type="text"
                className="input-set"
                value={AWBvalue ? AWBvalue : ''}
                onChange={(e) => {
                  setAWB(e.target.value)
                  setAWBvalue(e.target.value)
                }}
                placeholder={t('Enter AWB No. is here')}
              />
            </div>
          </div>
          <div className={`modal-footer ${agentmodalRef ? 'angentmodalcalender' : ''}`}>
            <button
              type="button"
              className="btn modal-content-no"
              data-bs-dismiss="modal"
              onClick={() => handler_dismiss()}
            >
              { agentmodalRef ? t("Cancel") : t('No')}
            </button>
            <button
              type="button"
              data-bs-dismiss="modal"
              onClick={() => handler_submit()}
              className="btn modal-content-yes"
            >
              { agentmodalRef ? t("Confirm") : t('yes')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentModal
