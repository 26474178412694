import { useEffect , useState } from "react";
import { _Api } from "../api/_call";
import {  useParams } from 'react-router-dom';
const ProductViewer = () => { 
    const [productDetail,setProductDetail]  = useState({});
const {product_id,dbname} = useParams();

    useEffect(()=>{
        const fetchData = async () => {
            try {
              const postdata = {id: product_id,data:dbname}
              let res = await _Api(postdata, `api/v1/qr-code/getGeneral`);
              console.log(res)
         
                const {data} = await res;
                setProductDetail(data)
             
              
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();

        
    },[])
    return (

     <>

 <div className="container qr_viewer">
          <div className="row">
            <div className="col-sm-12">
                <h1>{productDetail.name}</h1>
                
                <div className="row">
                    <img src={productDetail.main_image} className="w-100"/>
                </div>
                <p>
                     <b>SKU : </b> {productDetail.SKU}<br/>

                    <b>Description : </b> {productDetail.Description}<br/>
                </p>
            </div>
 </div>
</div>
</>   
    )


}

export default ProductViewer;