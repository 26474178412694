import React, { useState } from "react";
import Accordion from "./Accordians";

const AccordianPos = ({ id, data }) => {
  const [isOpen1, setIsOpen4] = useState(10);
  const [isOpen2, setIsOpen5] = useState(13);
  const [isOpen3, setIsOpen6] = useState(16);
  const handleChange1 = (id) => {
    setIsOpen4(id);
  };


  const handleChange2 = (id) => {
    setIsOpen5(id);
  };

  const handleChange3 = (id) => {
    setIsOpen6(id);
  };

  const accordionData1 = [
    {
      id: 10,
      title: "Services",
      content: "Customer transactions/repair order and reserve order",
      imageSrc: "/assets/landingNew/Service2.svg",
      defaultOpen: true,
    },
    {
      id: 11,
      title: "Sale Order",
      content: "Sell jewellery (finished goods) or in a single piece (diamond)",
      imageSrc: "/assets/landingNew/POS.svg",
      defaultOpen: false,
    },
    {
      id: 12,
      title: "Custom Order",
      content: "Able to create your own jewelry style with stone and diamond",
      imageSrc: "/assets/landingNew/Report1.svg",
      defaultOpen: false,
    },
  ];

  const accordionData2 = [
    {
      id: 13,
      title: "E-catelog",
      content: "Capable of controlling material inventory and finished good",
      imageSrc: "/assets/landingNew/catalogue.svg",
      defaultOpen: true,
    },
    {
      id: 14,
      title: "Refund&Exchange",
      content: "Refund & Exchange order",
      imageSrc: "/assets/landingNew/refund1.svg",
      defaultOpen: false,
    },
    {
      id: 15,
      title: "Wishlist",
      content: "Favorite item desired by the customer",
      imageSrc: "/assets/landingNew/favourite1.svg",
      defaultOpen: false,
    },
  ];

  const accordionData3 = [
    {
      id: 16,
      title: "Payment Method",
      content: "Payment options (credit card, cash, and bank transfer) in multi-currency for each store",
      imageSrc: "/assets/landingNew/Payment1.svg",
      defaultOpen: true,
    },
    {
      id: 17,
      title: "Gift card",
      content: "Online vouchers for customers to use",
      imageSrc: "/assets/landingNew/gift-card1.svg",
      defaultOpen: false,
    },
    {
      id: 18,
      title: "Deposit",
      content: "Customer credit",
      imageSrc: "/assets/landingNew/deposit1.svg",
      defaultOpen: false,
    },
  ];

  return (
    <>

      <div className="accordion-container faq-flex-content">
        <div className="accordion-set">
          {accordionData1.map((data) => (
            <Accordion
              key={data.id}
              id={data.id}
              title={data.title}
              content={data.content}
              imageSrc={data.imageSrc}
              isOpen={isOpen1}
              handleChange={handleChange1}
            />
          ))}
        </div>
        <div className="accordion-set">
          {accordionData2.map((data) => (
            <Accordion
              key={data.id}
              id={data.id}
              title={data.title}
              content={data.content}
              imageSrc={data.imageSrc}
              isOpen={isOpen2}
              handleChange={handleChange2}
            />
          ))}
        </div>
        <div className="accordion-set">
          {accordionData3.map((data) => (
            <Accordion
              key={data.id}
              id={data.id}
              title={data.title}
              content={data.content}
              imageSrc={data.imageSrc}
              isOpen={isOpen3}
              handleChange={handleChange3}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AccordianPos;
