import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { loading, Updateloading } from "../../common/helpers/toastify";
import { _Api } from "../../common/api/_call"
import { useTranslation } from 'react-i18next';
const MailModal = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [inputemail, setInputemail] = useState()
  const [showmodal, setShowmodal] = useState(false)
  const { type, email, id, setShoworder } = props
  const sandemail = async () => {
    let GetloadingID = loading();
    try {
      let postdata = {
        "type": type,
        "email": inputemail ? inputemail : email,
        "id": id
      }
      let res = await _Api(postdata, `api/v1/POS/report/invoiceMail`);
      if (res.code === 200) {
        setShoworder(false);

        setTimeout(() => {
          Updateloading(t, GetloadingID, t("Successfully Email Sand"));
          navigate('/app/pos/menu');
          window.location.reload(false);
        }, 100)

      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  return (

    <>
      <div
        className="modal fade common-modal email-modal"
        id="emailmodal"
        tabIndex="-1"
        aria-labelledby="emailmodalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog email-modal-dialog modal-dialog-centered">
          <div className="modal-content pt-0 px-0 pb-2 overflow-hidden">

            <div className="modal-header email-modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {t("Email")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-3 pt-4">
              <label className="email-modal-label d-block pb-2">{t("Email")}</label>
              <div className='placeholder-up-input'>
                <input type="text" className='placeholder-up-input-field'
                  defaultValue={email}
                  onChange={(e) =>
                    setInputemail(e.target.value)
                  }
                />
              </div>
            </div>



            <div className="modal-footer" onClick={(e) => { sandemail(); setShowmodal(true) }} >
              {showmodal ?
                <button type="button" className="com-btn btn-disabled">
                  {t("Send Email")}
                </button> :
                <button type="button" className="com-btn">
                  {t("Send Email")}
                </button>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  )

}

export default MailModal;
