import React, { useEffect, useState } from "react";
import NoImage from "../../assets/images/Noimage.png";
import Select from "react-select";
import MinusIcon from "../../assets/icons/MinusIcon";
import PlusIcom from "../../assets/icons/PlusIcon";
import EditIcon from "../../assets/icons/EditIcon";
import { _Api, _Apiauth } from "../../../common/api";
import { useTranslation } from "react-i18next";
import AccountSidebar from './AccountSidebar';
import { Link } from "react-router-dom";

const MyAccountDetailsPage = () => {
    const { t } = useTranslation();
    const [fileName, setFileName] = useState("");
    const [disableFiled, setDisableFiled] = useState(true);
    const [fromvalidaction, setFromvalidaction] = useState();
    const [accountdata, setAccountdata] = useState({
        contacts: [{ icon: 1, phoneCode: "", number: "" }],
    });
    const [selectdata, setSelectdata] = useState({ country: [], phoneCode: [] });
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    try {
                        const postdata = {
                            image: reader.result,
                        };
                        let res = await _Api(postdata, `redis/uplaod`)
                        if (res.statusCode === 200) {
                            setalldata("profile", res?.url);
                            setFileName(file?.name);
                        }
                    }
                    catch (err) {
                        console.log(err, "err")
                    }
                };
                if (file) {
                    reader?.readAsDataURL(file);
                }
            } else {
                alert("Please select a valid image file.");
            }
        }
    };
    const setalldata = (keyname, value) => {
        setAccountdata((privasestate) => ({
            ...privasestate,
            [keyname]: value,
        }));
    };
    const getaccountdetails = async () => {
        try {
            let postdata = {};
            let res = await _Api(postdata, `account/getDetails`);
            if (res?.statusCode === 200) {
                setAccountdata(res?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    const getcountry = async () => {
        try {
            let postdata = {};
            let res = await _Apiauth(postdata, `redis/countryList`)
            if (res?.statusCode === 200) {
                let countrydata = [];
                let phonecode = [];
                res?.data.forEach((result) => {
                    countrydata.push({
                        label: result?.name,
                        value: result?.id,
                    });
                    phonecode.push({
                        label: result?.phoneCode,
                        value: result?.phoneCode,
                    });
                });
                setSelectdata({ country: countrydata, phoneCode: phonecode });
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        getaccountdetails();
        getcountry();
    }, []);
    const addEventList = () => {
        let newValues_ = { ...accountdata };
        let newValues = [...newValues_?.contacts];
        newValues.push({ icon: 1, phoneCode: "", number: "" });
        newValues_.contacts = newValues;
        setAccountdata(newValues_);
    };
    const handleSelectChange = (index, code, no) => {
        let newValues_ = { ...accountdata };
        let newValues = [...newValues_?.contacts];
        newValues[index] = { icon: 1, phoneCode: code, number: no };
        newValues_.contacts = newValues;
        setAccountdata(newValues_);
    };
    const handleRemoveElement = (indexToRemove) => {
        let newValues_ = { ...accountdata };
        let newValues = [...newValues_?.contacts];
        let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
        newValues_.contacts = updatedArray;
        setAccountdata(newValues_);
    };
    const createaccount = async () => {
        try {
            let objdata = { ...accountdata };
            objdata.country = accountdata?.country?.value;
            if (!objdata.profile) {
                delete objdata.profile;
            }
            const validation = () => {
                let formIsValid = true;
                if (!objdata?.fname) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        namevalid: "First Name is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        namevalid: "",
                    }));
                }

                if (!objdata?.lname) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        lnamevalid: "Last Name is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        lnamevalid: "",
                    }));
                }

                if (!objdata?.address) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        addressvalid: "Address is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        addressvalid: "",
                    }));
                }

                if (!objdata?.country) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        countryvalid: "Country is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        countryvalid: "",
                    }));
                }
                if (!objdata?.gender) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        gendervalid: "Gender is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        gendervalid: "",
                    }));
                }
                if (!objdata?.state) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        statevalid: "State is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        statevalid: "",
                    }));
                }

                if (!objdata?.city) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        cityvalid: "City is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        cityvalid: "",
                    }));
                }

                if (!objdata?.zipcode) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        zipcodevalid: "Invalid Zipcode",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        zipcodevalid: "",
                    }));
                }

                if (!objdata?.email) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        emailvalid: " Email Required",
                    }));
                }
                else {
                    const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
                        ;
                    if (!isEmail(objdata?.email)) {
                        setFromvalidaction((state) => ({
                            ...state,
                            emailvalid: "Invalid Email ",
                        }));
                        formIsValid = false;
                    } else {
                        setFromvalidaction((state) => ({
                            ...state,
                            emailvalid: "",
                        }));
                    }
                }
                if (!objdata?.contacts[0]?.number || !objdata?.contacts[0]?.phoneCode) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        contactsvalid: "contact is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        contactsvalid: "",
                    }));
                }
                return formIsValid;
            };
            if (validation()) {
                if (objdata?.govt_ids?.length) {
                    let govt_ids = []
                    accountdata.govt_ids.forEach((result) => {
                        if (result?.idnumber && result?.idtype) {
                            govt_ids.push(result)
                        }
                    })
                    objdata.govt_ids = govt_ids
                }
                let postdata = objdata;
                let res = await _Api(postdata, `account/updateDetails`);
                if (res?.statusCode === 200) {
                    setDisableFiled(true);
                    getaccountdetails();
                }
            }
        } catch (err) {
            console.log(err, "err");
            setFromvalidaction(prevState => ({
                ...prevState,
                error: err.response.data.message,
            }));
        }
    };
    let gender = ["Male", "Female", "Other"];
    return (
        <div className=" accountDetailMainPage d-flex ecommerce-main-layout-bodyContent">
            <AccountSidebar />
            <div className="account-details">
                <div className="account-details-container">
                    <div className="account-details-container-title">
                        <span className="account-details-container-title-heading">
                            {" "}
                            {t("Account Details")}{" "}
                        </span>
                        <div className="d-flex justify-content-between">
                            <span className="account-details-container-title-subheading">
                                {" "}
                                {t("Manage and protect your account")}.{" "}
                            </span>
                            <span onClick={() => setDisableFiled(false)}>
                                <EditIcon
                                    width={22}
                                    height={22}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="account-details-container-inner">
                        {
                            <div className="account-details-container-inner-titleSection">
                                <span className="account-details-container-inner-titleSection-heading">
                                    {" "}
                                    {t("Avatar")}
                                </span>
                                <div className="account-details-container-inner-titleSection-profile">
                                    <img src={accountdata?.profile ? accountdata?.profile : NoImage} alt="" className="object-fit-cover"/>
                                </div>
                                {disableFiled ? null : (
                                    <div className="account-details-container-inner-titleSection-upload-container">
                                        <span className="instruction">
                                            {t("Please upload square image, size less than 100KB")}
                                        </span>

                                        <div className="account-details-container-inner-titleSection-upload-container-inner">
                                            <label htmlFor="profile-details">{t("Choose File")}</label>
                                            <input
                                                hidden
                                                type="file"
                                                accept="image/*"
                                                id="profile-details"
                                                onChange={handleImageChange}
                                            />
                                            <span>{fileName ? fileName : t("No File Chosen")}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        <div className="account-details-container-inner-profileDetails">
                            <div className="account-details-container-inner-profileDetails-inner">
                                <span className="account-details-container-inner-titleSection-heading">
                                    {t("Profile Details")}
                                </span>
                                <div
                                    className={`account-details-container-inner-profileDetails-contact-fields  ${disableFiled ? "disable" : null
                                        }`}
                                >
                                    <div className="w-100">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label text-sm-start"
                                        >
                                            {t("Name")} <span className='com-starShow'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`${fromvalidaction?.namevalid
                                                ? "border-danger form-control accountdetailComInput"
                                                : "form-control accountdetailComInput"
                                                }`}
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            readOnly={disableFiled ? true : false}
                                            value={accountdata?.fname}
                                            onChange={(e) => setalldata("fname", e.target.value)}
                                        />
                                        {fromvalidaction?.namevalid ? (
                                            <span className="text-danger">
                                                {fromvalidaction?.namevalid}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="w-100">
                                        <label
                                            htmlFor="exampleInputPassword1 "
                                            className="form-label text-sm-start"
                                        >
                                            {t("Last Name")} <span className='com-starShow'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`${fromvalidaction?.lnamevalid
                                                ? "border-danger form-control accountdetailComInput"
                                                : "form-control accountdetailComInput"
                                                }`}
                                            id="exampleInputPassword1"
                                            value={accountdata?.lname}
                                            readOnly={disableFiled ? true : false}
                                            onChange={(e) => setalldata("lname", e.target.value)}
                                        />
                                        {fromvalidaction?.lnamevalid ? (
                                            <span className="text-danger">
                                                {fromvalidaction?.lnamevalid}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="gender-checkBox mt-4 mb-4 addAdressBox-formBody-stepfirst">
                                    <div className="com-formInputBox">
                                        <label className="">
                                            {t("Gender")}<span className="com-starShow">*</span>
                                        </label>
                                    </div>
                                    <div className="gender-checkBox-checkContainer">
                                        {gender.map((result, key) => {
                                            return (
                                                <div
                                                    className="gender-checkBox-checkContainer-singleCheck"
                                                    key={key}
                                                >
                                                    <input
                                                        type="radio"
                                                        id={result}
                                                        name="gender"
                                                        value={result}
                                                        checked={accountdata?.gender === result}
                                                        onChange={(e) =>
                                                            setalldata("gender", e.target.value)
                                                        }
                                                        disabled={disableFiled ? true : false}
                                                    />
                                                    <label htmlFor={result}>{result}</label>
                                                </div>
                                            );
                                        })}
                                        {fromvalidaction?.gendervalid ?
                                            <div className="text-danger">{fromvalidaction?.gendervalid}</div> : ""}
                                    </div>
                                </div>
                            </div>
                            <span className="account-details-container-inner-titleSection-heading">
                                {t(" Contact")}
                            </span>
                            <div
                                className={`account-details-container-inner-profileDetails-contact-fields d-block ${disableFiled ? "disable" : null
                                    }`}
                            >
                                <label htmlFor="address" className="form-label text-sm-start">
                                    {t(" Address")} <span className='com-starShow'>*</span>
                                </label>
                                <input
                                    className={`${fromvalidaction?.addressvalid
                                        ? "border-danger form-control accountdetailComInput"
                                        : "form-control accountdetailComInput"
                                        }`}
                                    id="address"
                                    aria-describedby="emailHelp"
                                    value={accountdata?.address}
                                    readOnly={disableFiled ? true : false}
                                    onChange={(e) => setalldata("address", e.target.value)}
                                />
                                {fromvalidaction?.addressvalid ? (
                                    <span className="text-danger">
                                        {fromvalidaction?.addressvalid}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div
                                className={`account-details-container-inner-profileDetails-contact-fields  ${disableFiled ? "disable" : null
                                    }`}
                            >
                                <div className="account-details-container-inner-profileDetails-contact-fields-input w-100">
                                    <label>{t("Country")} <span className='com-starShow'>*</span></label>
                                    <Select
                                        className={`${fromvalidaction?.countryvalid
                                            ? "border-danger ecommerce-dropdown"
                                            : "ecommerce-dropdown"
                                            }`}
                                        options={selectdata?.country}
                                        value={accountdata?.country}
                                        onChange={(e) => setalldata("country", e)}
                                        classNamePrefix="common-select"
                                        isSearchable={true}
                                        isDisabled={disableFiled ? true : false}
                                    />
                                    {fromvalidaction?.countryvalid ? (
                                        <span className="text-danger">
                                            {fromvalidaction?.countryvalid}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="account-details-container-inner-profileDetails-contact-fields-input w-100">
                                    <label htmlFor="state" className="form-label text-sm-start">
                                        {t("Province/State")} <span className='com-starShow'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className={`${fromvalidaction?.statevalid
                                            ? "border-danger form-control accountdetailComInput"
                                            : "form-control accountdetailComInput"
                                            }`}
                                        id="State"
                                        value={accountdata?.state}
                                        onChange={(e) => setalldata("state", e.target.value)}
                                        readOnly={disableFiled ? true : false}
                                    />
                                    {fromvalidaction?.statevalid ? (
                                        <span className="text-danger">
                                            {fromvalidaction?.statevalid}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div
                                className={`account-details-container-inner-profileDetails-contact-fields  ${disableFiled ? "disable" : null
                                    }`}
                            >
                                <div className="account-details-container-inner-profileDetails-contact-fields-input w-100">
                                    <label htmlFor="city" className="form-label text-sm-start">
                                        {t(" City")} <span className='com-starShow'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className={`${fromvalidaction?.cityvalid
                                            ? "border-danger form-control accountdetailComInput"
                                            : "form-control accountdetailComInput"
                                            }`}
                                        id="City"
                                        value={accountdata?.city}
                                        onChange={(e) => setalldata("city", e.target.value)}
                                        readOnly={disableFiled ? true : false}
                                    />
                                    {fromvalidaction?.cityvalid ? (
                                        <span className="text-danger">
                                            {fromvalidaction?.cityvalid}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="account-details-container-inner-profileDetails-contact-fields-input w-100">
                                    <label htmlFor="zip_code" className="form-label text-sm-start">
                                        {t("Zip Code")} <span className='com-starShow'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className={`${fromvalidaction?.zipcodevalid
                                            ? "border-danger form-control accountdetailComInput"
                                            : "form-control accountdetailComInput"
                                            }`}
                                        id="zip_code"
                                        value={accountdata?.zipcode}
                                        onChange={(e) => setalldata("zipcode", e.target.value)}
                                        readOnly={disableFiled ? true : false}
                                    />
                                    {fromvalidaction?.zipcodevalid ? (
                                        <span className="text-danger">
                                            {fromvalidaction?.zipcodevalid}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div
                                className={`account-details-container-inner-profileDetails-phone-fields  mt-4 ${disableFiled ? "disable" : null
                                    }`}
                            >
                                <span>{t("Phone")} <span className='com-starShow'>*</span></span>
                                <div className="phone-filed-container">
                                    {accountdata?.contacts?.length ? accountdata?.contacts.map((item, key) => (
                                        <div
                                            className="d-flex align-items-center phone-filed-container-inner w-100 gap-1 mb-2"
                                            key={key}
                                        >
                                            <div className="d-flex -flex phone-filed-container-inner-input">
                                                <Select
                                                    className="ecommerce-dropdown"
                                                    options={selectdata?.phoneCode}
                                                    value={{
                                                        label: item?.phoneCode,
                                                        value: item?.phoneCode,
                                                    }}
                                                    onChange={(e) =>
                                                        handleSelectChange(key, e.value, item?.number)
                                                    }
                                                    classNamePrefix="common-select"
                                                    isSearchable={true}
                                                    isDisabled={disableFiled ? true : false}
                                                />
                                                <input
                                                    type="text"
                                                    value={item?.number}
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            key,
                                                            item.phoneCode,
                                                            e.target.value
                                                        )
                                                    }
                                                    readOnly={disableFiled ? true : false}
                                                />
                                            </div>
                                            {key > 0 && !disableFiled ?
                                                <button
                                                    disabled={key > 0 ? false : true}
                                                    className="d-flex justify-content-center align-items-center"
                                                    onClick={() => handleRemoveElement(key)}
                                                >
                                                    <MinusIcon />
                                                </button>
                                                : ""}

                                        </div>
                                    )) : ""}
                                </div>
                                {!disableFiled ?
                                    <div
                                        className="d-flex align-items-start add-field-btn gap-1"
                                        onClick={() => addEventList()}
                                    >
                                        {fromvalidaction?.contactsvalid ? <div className="text-danger">{fromvalidaction?.contactsvalid}</div> : ""}
                                        <PlusIcom width={16} height={16} color={"#1CACAC"} /> Add
                                        {t(" Phone")}
                                    </div> : ""
                                }

                            </div>
                            <div
                                className={`account-details-container-inner-profileDetails-contact-fields  ${disableFiled ? "disable" : null
                                    }`}
                            >
                                <div className="w-100">
                                    <label htmlFor="email" className="form-label text-sm-start">
                                        {t("Email")} <span className='com-starShow'>*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className={`${fromvalidaction?.emailvalid
                                            ? "border-danger form-control accountdetailComInput"
                                            : "form-control accountdetailComInput"
                                            }`}
                                        id="email"
                                        aria-describedby="emailHelp"
                                        value={accountdata?.email}
                                        onChange={(e) => setalldata("email", e.target.value)}
                                        readOnly={disableFiled ? true : false}
                                    />
                                    {fromvalidaction?.emailvalid ? (
                                        <span className="text-danger">
                                            {fromvalidaction?.emailvalid}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="w-100">
                                    <div className="w-100">
                                        <label htmlFor="password " className="form-label text-sm-start">
                                            {t("Password")} <span className='com-starShow'>*</span>
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control accountdetailComInput"
                                            id="password"
                                            value="********"
                                            readOnly
                                        />
                                    </div>
                                    <Link to="/account/changepassword" >
                                        <div className="float-end mt-2 chnage_password active">
                                            {t("Change Password")}
                                        </div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    {fromvalidaction?.error ? <div className="mt-3 text-danger text-end">{t(`${fromvalidaction?.error}`)}</div> : ""}
                    <div className="account-details-container-saveBtn   gap-3">
                        <button disabled={disableFiled ? true : false} onClick={() => { getaccountdetails(); setDisableFiled(true) }} className="btn-white">
                            {t("Cancel")}
                        </button>
                        <button
                            disabled={disableFiled ? true : false}
                            className="btn-primary"
                            onClick={() => createaccount()}
                        >
                            {t("Save")}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default MyAccountDetailsPage;
