import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import { error, success } from '../common/helpers/toastify';
import { Api_form } from '../api/_call';
import { Importmodal } from "../common/modules/Importmodal"
const SettingImport = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [importfile, setImportfile] = useState()
  const [filetype, setFiletype] = useState()
  const readExcel = async () => {
    if (!importfile) {
      error(t('Choose file!'));
      return;
    }
    try {
      const formData = new FormData()
      formData.append(filetype, importfile)
      formData.append('type', 'Item')
      console.log(formData, "formData")
      let res = await Api_form(
        formData,
        `api/v1/settings/import`,
      )
      if (res.code === 200) {
        success(
          t(res?.message),
        )
      } else {
        error(t(res?.message))
      }
    } catch (err) {
      console.log(err, 'err')
    }

  }

  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <Link className="text-decoration-none" to="/app/admin/settings">{t("Setting")}</Link>  <RightArrow /> <span>{t("Import")}</span>
          </h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
          <button className="square-btn"><img src={base_assets + "images/icons/white-save.png"} alt="" /></button>
        </div>
      </div>
      <div className="admin-main-card">
        <div className="row">
          <div className="col-md-12">
            <div className='row'>
              <div className='col-md-6'>
                <div className="v3-input-group">
                  <label className="v3-input-group-label" >{t("Database import")}</label>
                  <input type="text" className="v3-input-group-input w-100" />
                </div>
              </div>
              <div className='col-md-6 v3-input-group d-flex align-items-end'>
                <input className='d-none' id='baseUrlFile' />
                <label className='com-btn cursor-pointer' htmlFor="baseUrlFile" data-bs-toggle="modal"
                  data-bs-target="#ImportModal" onClick={() => setFiletype("databaseFile")}>{t("choose file")}</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className="v3-input-group">
                  <label className="v3-input-group-label">{t("Image ZIP")}</label>
                  <input type="text" className="v3-input-group-input w-100" />
                </div>
              </div>
              <div className='col-md-6 v3-input-group d-flex align-items-end'>
                <input className='d-none' id='baseUrlFile' />
                <label data-bs-toggle="modal"
                  data-bs-target="#ImportModal" className='com-btn cursor-pointer' htmlFor="baseUrlFile"
                  onClick={() => setFiletype("imagefile")}>{t("choose file")}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Importmodal
        setImportfile={setImportfile}
        readExcel={readExcel}
        importfile={importfile}
        hideSample={true} />
    </div>
  )
}

export default SettingImport
