import React from 'react';
import { toFormatPrice, toUnformatPrice } from "../../../common/helpers/function";
import $ from 'jquery';
export const createpurchase = (prioritydata, t, key) => {
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  return (
    <React.Fragment>
      {prioritydata?.code === 200 ?
        <tr className="common-sub-table-wrap add-row remove" id={`common-sub-table-wrap-${prioritydata?.data?.id}`} key={key}>
          <td colSpan="15" >
            <table id={"data_table_set_" + prioritydata?.data?.id} className="common-table common-sub-table">
              <thead>
                <tr>
                  <th><div className="com-check">
                    <input name="mainsub" className="subtablecheck_main" id={"subtable_main" + prioritydata.data.id} type="checkbox" /><label htmlFor={"subtable_main" + prioritydata.data.id} className="com-check-label " /></div>
                  </th>
                  <th className="col-highlight">#</th>
                  <th className="col-highlight"></th>
                  <th className="col-highlight">{t("img.")}</th>
                  <th className="col-highlight">{t("Product Name")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th className="col-highlight">{t("SKU")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Stock ID")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Metal")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Stone")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Size")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Qty")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Price")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Amount")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Status")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Priority")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th className="danger-text">{t("POS No.")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th className="danger-text">{t("Customer")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th className="danger-text">{t("Delivery Date")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th className="danger-text">{t("Due Days")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th className="danger-text">{t("Ship to")}
                    <div className="up-down-arrow-btn">
                      <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_url_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                      <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_url_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                    </div>
                  </th>
                  <th>{t("Design")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(prioritydata?.data?.po_product_data).map((result, key) => {
                  return (
                    <tr key={key}>
                      <td><div className="com-check"><input id={result?._id} data-po_order_id={result?.po_order_id} data-location={prioritydata?.data?.location_id} data-supplier={prioritydata?.data?.supplier_id} data-warehouse={prioritydata?.data?.warehouse_id} className="createPurchaseselect subtablecheck" type="checkbox" /><label htmlFor={result?._id} className="com-check-label" />
                        {result.so_no ?
                          <div className="td-icons-wrap-user"><img style={{ marginLeft: "2rem" }} className="img" src={base_url_assets + "images/icons/gray-user.png"} alt="" /></div>
                          : ''
                        }
                      </div></td>
                      <td className="col-highlight">{key + 1}</td>
                      <td className="col-highlight">
                        <div className="td-icons-wrap">
                          {result?.allocatable ? <div className="td-icons-wrap-link">
                            <img
                              className="img "
                              src={
                                base_url_assets +
                                'images/icons/link-icon.svg'
                              }
                              alt=""
                            />
                          </div> : ""}
                          <div className='tooltip-area-up'>
                            {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user" data-bs-toggle="modal"
                              data-bs-target="#deallcocat-modal"><img
                                className="img cursor-pointer"
                                src={
                                  base_url_assets +
                                  'images/icons/gray-user.png'
                                }
                                alt=""
                              />
                            </div> : ""}
                            <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.pos_no}<br />{result?.delivery_date}</span>
                          </div>
                        </div></td>
                      <td className="col-highlight"><div className="table-pro-img"><img src={result?.main_image} alt="" /></div></td>
                      <td className="po-no col-highlight">{result.name ? result.name : "-"}</td>
                      <td className="po-no col-highlight" >{result.SKU ? result.SKU : "-"}</td>
                      <td className="col-highlight">{result.stock_id ? result.stock_id : result?.pos?.stock_id}</td>
                      <td>{result.metal_name ? result.metal_name : "-"}</td>
                      <td>{result.stone_name ? result.stone_name : "-"}</td>
                      <td>{result.size_name ? result.size_name : "-"}</td>
                      <td>{result.po_QTY ? result.po_QTY : "0"}</td>
                      <td>{result.price ? toFormatPrice(result.price, { addSymbol: true }) : "0"}</td>
                      <td>{result.amount_total ? toFormatPrice(result.amount_total, { addSymbol: true }) : "0.00"}</td>
                      {result.status.po_status === 1 ?
                        <td><label className="status-tab status-completed">{t("Completed")}</label></td>
                        : result.status.po_status === 2 ?
                          <td><label className="status-tab status-pending">{t("Pending")} </label></td>
                          : result.status.po_status === 3 ?
                            <td><label className="status-tab status-approved">{t("Approved")} </label></td>
                            :
                            <td><label className="status-tab status-canceled">{t("Canceled")} </label></td>
                      }
                      {result.status.priority === 0 ?
                        <td><label className="status-tab low-priority">{t("Low")}</label></td>
                        : result.status.priority === 1 ?
                          <td><label className="status-tab medium-priority">{t("Medium")} </label></td>
                          : result.status.priority === 2 ?
                            <td><label className="status-tab high-priority">{t("High")} </label></td>
                            : ''
                      }
                      <td>{result.pos_no ? result.pos_no : ""}</td>
                      <td>{result.customer_name ? result.customer_name : "-"}</td>
                      <td>{result.delivery_date ? result.delivery_date : "-"}</td>
                      <td>{result.due_days ? result.due_days : "-"}</td>
                      <td>{result.location_name ? result.location_name : "-"}</td>
                      <td className="text-center">-</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>  {prioritydata?.data?.po_product_data
                    ? prioritydata?.data?.po_product_data?.reduce(
                      (interation, val) =>
                      (interation =
                        interation + parseInt(val.po_QTY)),
                      0
                    )
                    : ""}</td>
                  <td />
                  <td>
                  </td>
                  <td >
                    {toFormatPrice(
                      prioritydata?.data?.po_product_data
                        ? prioritydata?.data?.po_product_data?.reduce(
                          (interation, val) =>
                          (interation =
                            interation +
                            toUnformatPrice(val?.amount_total)),
                          0
                        )
                        : "", { addSymbol: true }
                    )}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
        : ''
      }
    </React.Fragment>
  );
}
$(document).on("click", ".subtablecheck_main", function () {
  var maincheckbox = document.querySelector(".subtablecheck_main");
  var allcheckbox = document.querySelectorAll(".createPurchaseselect");
  if (maincheckbox.checked === true) {
    allcheckbox.forEach(function (checkBox_selectAble) {
      checkBox_selectAble.checked = true;
    });
  } else {
    allcheckbox.forEach(function (checkBox_selectAble) {
      checkBox_selectAble.checked = false;
    });
  }

});




