import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminSetting from "../admin/setting/Setting.js"
import CloudSetting from "../admin/setting/CloudSetting.js"
import SmtpEmail from "../admin/setting/SmtpEmail.js"
import SettingImport from "../admin/setting/Import.js"
import SettingExport from "../admin/setting/Export.js"
import SettingLanguage from "../admin/setting/Language.js"
import SettingCurrency from "../admin/setting/Currency.js"
import Integratedapplications from '../admin/setting/Integratedapplications.js';
import Cmslocations from '../admin/setting/Cmslocations.js';
export default function SettingRoutes(toploadingbarStart) {
    return (
        <Routes>
            <Route exact path='/' element={<AdminSetting />} > </Route>
            <Route exact path='cloudsetting' element={<CloudSetting />} > </Route>
            <Route exact path='smtpemail' element={<SmtpEmail />} > </Route>
            <Route exact path='import' element={<SettingImport />} > </Route>
            <Route exact path='export' element={<SettingExport />} > </Route>
            <Route exact path='language' element={<SettingLanguage />} > </Route>
            <Route exact path='currency' element={<SettingCurrency />} > </Route>
            <Route exact path='integratedapplications' element={<Integratedapplications />} > </Route>
            <Route exact path='cmslocations' element={<Cmslocations />} > </Route>
        </Routes>
    )
}
