import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Report.scss'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import { Daterangepos } from '../common/Daterangepos'
import { Tablehead } from '../../components/common/modules/Tablehead'
import CustomerInformation from '../../admin/customers/customerInformation/CustomerInformation'
import {
  poscustomerdetailsall_head,
  poscustomerdetailsWishlist_head,
  poscustomerdetailsCustomOrder_head,
  poscustomerdetailsrepair_order_head,
  poscustomerdetailsexchange_refund_head,
  poscustomerdetailssales_head,
} from '../../components/common/Tablehead'
import { _Api } from '../common/api/_call'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
const CustomerDatails = () => {
  const { t } = useTranslation()
  const [datefilter, setDatefilter] = useState([])
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [customerdetails, setCustomerdetails] = useState([])
  const [cusid, setCusid] = useState()
  const { state } = useLocation()
  const [saletype, setSaletype] = useState('ALL')
  const [newcolumndata, setnewcolumndata] = useState(poscustomerdetailsall_head)
  const [printparmission, setPrintparmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission)

  useEffect(() => {
    if (parmissiondatashow?.pos_reports?.View) {
      setPrintparmission(parmissiondatashow?.pos_reports?.Print)
    }
  }, [parmissiondatashow])
  const callcolumns = async () => {
    try {
      const postdata = {
        name:
          saletype === 'ALL'
            ? 'report_customer_details_tbl'
            : saletype === 'wishlist'
              ? 'report_customer_wishlist_tbl'
              : saletype === 'custom_order'
                ? 'report_customer_custom_order_tbl'
                : saletype === 'repair_order'
                  ? 'report_customer_repair_order_tbl'
                  : saletype === 'exchange_refund'
                    ? 'report_customer_exchange_refund_tbl'
                    : saletype === 'sales'
                      ? 'report_customer_sales_tbl'
                      : '',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  useEffect(() => {
    let newColumnData
    switch (saletype) {
      case 'ALL':
        newColumnData = poscustomerdetailsall_head
        break
      case 'wishlist':
        newColumnData = poscustomerdetailsWishlist_head
        break
      case 'custom_order':
        newColumnData = poscustomerdetailsCustomOrder_head
        break
      case 'repair_order':
        newColumnData = poscustomerdetailsrepair_order_head
        break
      case 'exchange_refund':
        newColumnData = poscustomerdetailsexchange_refund_head
        break
      case 'sales':
        newColumnData = poscustomerdetailssales_head
        break
      default:
        newColumnData = []
    }

    setnewcolumndata(newColumnData)
  }, [saletype, newcolumndata])
  useEffect(() => {
    callcolumns()
    // eslint-disable-next-line
  }, [newcolumndata])
  const getdailysaledata = async (current, firstlimit) => {
    try {
      let dataTableId
      switch (saletype) {
        case 'ALL':
          dataTableId = 'com-data-area_table'
          break
        case 'wishlist':
          dataTableId = 'com-data-area_table-wishlist'
          break
        case 'custom_order':
          dataTableId = 'com-data-area_table-custom_order'
          break
        case 'repair_order':
          dataTableId = 'com-data-area_table-repair_order'
          break
        case 'exchange_refund':
          dataTableId = 'com-data-area_table-exchange_refund'
          break
        case 'sales':
          dataTableId = 'com-data-area_table-sales'
          break
        default:
          dataTableId = ''
      }
      UnSetdataTable(dataTableId)
      const postdata = {
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        customer_id: state?.id,
        type: saletype,
      }
      let res = await _Api(postdata, `api/v1/POS/report/customerDetail`)
      if (res?.code === 200) {
        if (res?.code === 200) {
          setCustomerdetails(res?.data)
          setTimeout(() => {
            SetdataTable(dataTableId, 0)
          }, 5000)
        } else {
          setTimeout(() => {
            SetdataTable(dataTableId, 0)
          }, 5000)
        }
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (saletype) {
      getdailysaledata()
    }
    // eslint-disable-next-line
  }, [saletype, datefilter])
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 w-100">
            <div className="catalog-area bg-white page-menu-block Dailysalereport confirmPopupHandler">
              <CustomerInformation
                cusid={cusid}
                setCusid={setCusid} />
              <div className="Dailysalereport-header">
                <div className="Dailysalereport-header-left px-5 py-2 justify-content-between">
                  <div>
                    <h3 className="common-navigation-header-left-heading Dailysalereport-head ">
                      {' '}
                      <Link
                        to="/app/pos/Customerreport"
                        className="gray-mid text-decoration-none Dailysalereport-head-report"
                      >
                        {t('REPORT')}
                      </Link>{' '}
                      <img
                        className="Dailysalereport-head-arrow mx-3"
                        src={base_assets + 'images/pos/report/arrow-right.png'}
                        alt=""
                      />
                      <span className="Dailysalereport-head-pagename">
                        {t('CUSTOMER REPORT')}
                      </span>{' '}
                    </h3>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <input
                        type="text"
                        placeholder={t(
                          'Search by product name, barcode, stock ID, SKU...',
                        )}
                      />
                      <img
                        className="com-search-area-img"
                        src={
                          base_assets + 'images/pos/icons/magnifying-glass.png'
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Dailysalereport-content-area">
                <div className="Dailysalereport-content-area-header">
                  <div
                    className="Dailysalereport-content-area-header-inner cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#customerAddModal"
                    onClick={() => {
                      setCusid(state?.id)
                    }}
                  >
                    {state?.name}
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="date">
                      {' '}
                      <Daterangepos setDatefilter={setDatefilter} />
                    </div>
                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                      <button
                        className="btn-style"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            base_assets + 'images/icons/ellipsis-circular.png'
                          }
                          alt=""
                        />
                      </button>
                      <ul
                        className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li className={printparmission ? '' : 'd-none'}>
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/print-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Print')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/export-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Export')}
                          </Link>
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#socialModal"
                          className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={
                                base_assets + 'images/pos/report/share-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          {t('Share')}
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#columnModal"
                        >
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets + 'images/icons/column-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Column')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="Dailysalereport-content-area-main">
                  <ul
                    className="Dailysalereport-content-area-navbar nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li
                      className="nav-item Dailysalereport-content-area-navbar-item"
                      role="presentation"
                      onClick={(e) => {
                        setSaletype('ALL')
                      }}
                    >
                      <button
                        className="nav-link active Dailysalereport-content-area-navbar-item-link"
                        id="All-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#All"
                        type="button"
                        role="tab"
                        aria-controls="All"
                        aria-selected="true"
                      >
                        {t('All')}
                      </button>
                    </li>
                    <li
                      className="nav-item Dailysalereport-content-area-navbar-item"
                      role="presentation"
                      onClick={(e) => {
                        setSaletype('wishlist')
                      }}
                    >
                      <button
                        className="nav-link Dailysalereport-content-area-navbar-item-link"
                        id="wishlist-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#wishlist"
                        type="button"
                        role="tab"
                        aria-controls="wishlist"
                        aria-selected="false"
                      >
                        {t('Wishlist')}
                      </button>
                    </li>
                    <li
                      className="nav-item Dailysalereport-content-area-navbar-item"
                      role="presentation"
                      onClick={(e) => {
                        setSaletype('custom_order')
                      }}
                    >
                      <button
                        className="nav-link Dailysalereport-content-area-navbar-item-link"
                        id="custom_order-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#custom_order"
                        type="button"
                        role="tab"
                        aria-controls="custom_order"
                        aria-selected="false"
                      >
                        {t('Custom Order')}
                      </button>
                    </li>
                    <li
                      className="nav-item Dailysalereport-content-area-navbar-item"
                      role="presentation"
                      onClick={(e) => {
                        setSaletype('repair_order')
                      }}
                    >
                      <button
                        className="nav-link Dailysalereport-content-area-navbar-item-link"
                        id="repair_order-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#repair_order"
                        type="button"
                        role="tab"
                        aria-controls="repair_order"
                        aria-selected="false"
                      >
                        {t('Repair Order')}
                      </button>
                    </li>
                    <li
                      className="nav-item Dailysalereport-content-area-navbar-item"
                      role="presentation"
                      onClick={(e) => {
                        setSaletype('exchange_refund')
                      }}
                    >
                      <button
                        className="nav-link Dailysalereport-content-area-navbar-item-link"
                        id="exchange_refund-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#exchange_refund"
                        type="button"
                        role="tab"
                        aria-controls="exchange_refund"
                        aria-selected="false"
                      >
                        {t('Exchange / Refund')}
                      </button>
                    </li>
                    <li
                      className="nav-item Dailysalereport-content-area-navbar-item"
                      role="presentation"
                      onClick={(e) => {
                        setSaletype('sales')
                      }}
                    >
                      <button
                        className="nav-link Dailysalereport-content-area-navbar-item-link"
                        id="sales-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#sales"
                        type="button"
                        role="tab"
                        aria-controls="sales"
                        aria-selected="false"
                      >
                        {t('Sales')}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="All"
                      role="tabpanel"
                      aria-labelledby="All-tab"
                    >
                      <div
                        className="main-body-main-table-wrap  Dailysalereport-tabs-table"
                        id="table-scroll"
                      >
                        <table
                          id="com-data-area_table"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poscustomerdetailsall_head}
                            tblname={'report_customer_details_tbl'}
                            setfilterby={''}
                          />
                          <tbody>
                            <>
                              {customerdetails?.length
                                ? customerdetails.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{result?.transaction}</td>
                                      <td>{result?.date} </td>
                                      <td>{result?.invoice}</td>
                                      <td>{result?.SKU}</td>
                                      <td>{result?.StockID}</td>
                                      <td>{result?.Metal}</td>
                                      <td>{result?.Size}</td>
                                      <td>{result?.Stones}</td>
                                      <td> {result?.Qty}</td>
                                      <td>{result?.amount}</td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="wishlist"
                      role="tabpanel"
                      aria-labelledby="wishlist-tab"
                    >
                      <div
                        className="main-body-main-table-wrap Dailysalereport-tabs-table "
                        id="table-scroll"
                      >
                        <table
                          id="com-data-area_table-wishlist"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poscustomerdetailsWishlist_head}
                            tblname={'report_customer_wishlist_tbl'}
                            setfilterby={''}
                          />
                          <tbody>
                            <>
                              {customerdetails?.length
                                ? customerdetails.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        {result?.date ? result?.date : ''}
                                      </td>
                                      <td>
                                        <div className="pro-img">
                                          <img src={result?.image} alt="" />
                                        </div>
                                      </td>
                                      <td>
                                        {result?.SKU ? result?.SKU : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.StockID
                                          ? result?.StockID
                                          : ''}
                                      </td>
                                      <td>
                                        {result?.Metal ? result?.Metal : ''}
                                      </td>
                                      <td>
                                        {result?.Size ? result?.Size : ''}
                                      </td>
                                      <td>
                                        {result?.Stones ? result?.Stones : ''}
                                      </td>
                                      <td>
                                        {result?.price ? result?.price : ''}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="custom_order"
                      role="tabpanel"
                      aria-labelledby="custom_order-tab"
                    >
                      <div
                        className="main-body-main-table-wrap Dailysalereport-tabs-table"
                        id="table-scroll"
                      >
                        <table
                          id="com-data-area_table-custom_order"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poscustomerdetailsCustomOrder_head}
                            tblname={'report_customer_custom_order_tbl'}
                            setfilterby={''}
                          />
                          <tbody>
                            <>
                              {customerdetails?.length
                                ? customerdetails.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        {result?.date ? result?.date : '-'}
                                      </td>
                                      <td>
                                        {result?.invoice
                                          ? result?.invoice
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.SKU ? result?.SKU : '-'}
                                      </td>
                                      <td>
                                        {result?.Metal ? result?.Metal : '-'}
                                      </td>
                                      <td>
                                        {result?.Size ? result?.Size : '-'}
                                      </td>
                                      <td>
                                        {result?.Stones
                                          ? result?.Stones
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.Qty ? result?.Qty : '-'}
                                      </td>
                                      <td>
                                        {result?.delivery_date
                                          ? result?.delivery_date
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.amount
                                          ? result?.amount
                                          : '-'}
                                      </td>
                                      <td className="main-green-text">
                                        {result?.Sketch ? (
                                          <i
                                            className="fa fa-check"
                                            aria-hidden="true"
                                          ></i>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td className="main-green-text">
                                        {' '}
                                        {result?.Picture ? (
                                          <i
                                            className="fa fa-check"
                                            aria-hidden="true"
                                          ></i>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td className="main-green-text">
                                        {' '}
                                        {result?.Engraving_Text ? (
                                          <i
                                            className="fa fa-check"
                                            aria-hidden="true"
                                          ></i>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td className="main-green-text">
                                        {result?.Engraving_Logo ? (
                                          <i
                                            className="fa fa-check"
                                            aria-hidden="true"
                                          ></i>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td className="main-green-text">
                                        {' '}
                                        {result?.Remark ? (
                                          <i
                                            className="fa fa-check"
                                            aria-hidden="true"
                                          ></i>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="repair_order"
                      role="tabpanel"
                      aria-labelledby="repair_order-tab"
                    >
                      <div
                        className="main-body-main-table-wrap Dailysalereport-tabs-table"
                        id="table-scroll"
                      >
                        <table
                          id="com-data-area_table-repair_order"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poscustomerdetailsrepair_order_head}
                            tblname={'report_customer_repair_order_tbl'}
                            setfilterby={''}
                          />
                          <tbody>
                            <>
                              {customerdetails?.length
                                ? customerdetails.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        {result?.date ? result?.date : '-'}
                                      </td>
                                      <td>
                                        {result?.invoice
                                          ? result?.invoice
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.SKU ? result?.SKU : '-'}
                                      </td>
                                      <td>
                                        {result?.Metal ? result?.Metal : '-'}
                                      </td>
                                      <td>
                                        {result?.Size ? result?.Size : '-'}
                                      </td>
                                      <td>
                                        {result?.Stones
                                          ? result?.Stones
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.Qty ? result?.Qty : '-'}
                                      </td>
                                      <td>
                                        {result?.amount
                                          ? result?.amount
                                          : '-'}
                                      </td>
                                      <td className="main-green-text">
                                        {result?.Repair_list ? (
                                          <i
                                            className="fa fa-check"
                                            aria-hidden="true"
                                          ></i>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>
                                        {result?.Remark
                                          ? result?.Remark
                                          : '-'}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="exchange_refund"
                      role="tabpanel"
                      aria-labelledby="exchange_refund-tab"
                    >
                      <div
                        className="main-body-main-table-wrap Dailysalereport-tabs-table"
                        id="table-scroll"
                      >
                        <table
                          id="com-data-area_table-exchange_refund"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poscustomerdetailsexchange_refund_head}
                            tblname={'report_customer_exchange_refund_tbl'}
                            setfilterby={''}
                          />
                          <tbody>
                            <>
                              {customerdetails?.length
                                ? customerdetails.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        {result?.date ? result?.date : '-'}
                                      </td>
                                      <td>
                                        {result?.invoice
                                          ? result?.invoice
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.SKU ? result?.SKU : '-'}
                                      </td>
                                      <td>
                                        {result?.stock_id
                                          ? result?.stock_id
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.Metal ? result?.Metal : '-'}
                                      </td>
                                      <td>
                                        {result?.Size ? result?.Size : '-'}
                                      </td>
                                      <td>
                                        {result?.Stones
                                          ? result?.Stones
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.amount
                                          ? result?.amount
                                          : '-'}
                                      </td>
                                      <td className="main-green-text">
                                        {result?.amount
                                          ? result?.amount
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.Remark
                                          ? result?.Remark
                                          : '-'}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="sales"
                      role="tabpanel"
                      aria-labelledby="sales-tab"
                    >
                      <div
                        className="main-body-main-table-wrap Dailysalereport-tabs-table"
                        id="table-scroll"
                      >
                        <table
                          id="com-data-area_table-sales"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poscustomerdetailssales_head}
                            tblname={'report_customer_sales_tbl'}
                            setfilterby={''}
                          />
                          <tbody>
                            <>
                              {customerdetails?.length
                                ? customerdetails.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        {result?.date ? result?.date : '-'}
                                      </td>
                                      <td>
                                        {result?.invoice
                                          ? result?.invoice
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.SKU ? result?.SKU : '-'}
                                      </td>
                                      <td>
                                        {result?.stock_id
                                          ? result?.stock_id
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.Metal ? result?.Metal : '-'}
                                      </td>
                                      <td>
                                        {result?.Size ? result?.Size : '-'}
                                      </td>
                                      <td>
                                        {result?.Stones
                                          ? result?.Stones
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.amount
                                          ? result?.amount
                                          : '-'}
                                      </td>
                                      <td className="main-green-text">
                                        {result?.amount
                                          ? result?.amount
                                          : '-'}
                                      </td>
                                      <td>
                                        {result?.Remark
                                          ? result?.Remark
                                          : '-'}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default CustomerDatails
