import React from 'react';
export const Creditnotesubtable = (prioritydata, id, t) => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    return (
        <React.Fragment>
            {prioritydata.code === 200 ?

                <tr className="common-sub-table-wrap add-row remove" id={`common-sub-table-wrap-${id}`}>
                    <td colSpan="14">
                        <table id={'data_subtable_customorder' + id} className="common-table common-sub-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t("Date")}</th>
                                    <th>{t("VR Type")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Ref No.")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Currency")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Opening")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Redeemed")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Balance")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Dr/Cr")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th>{t("Remark")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(prioritydata.data).map((result, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{result?.date ? result?.date : ""}</td>
                                            <td>{result?.voucher_no ? result?.voucher_no : ""}</td>
                                            <td>{result?.order_no ? result?.order_no : ""}</td>
                                            <td>{result?.currency ? result?.currency : ""}</td>
                                            <td>{result?.formated_opening_balance ? result?.formated_opening_balance : ""}</td>
                                            <td>{result?.formated_redeem ? result?.formated_redeem : ""}</td>
                                            <td>{result?.formated_remaining ? result?.formated_remaining : ""}</td>
                                            <td>{result?.debAndcr ? result?.debAndcr : ""}</td>
                                            <td>{result?.remark ? result?.remark : ""}</td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
                : ""}
        </React.Fragment>
    )
}
export default Creditnotesubtable;