import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { _Api } from "../common/api/_call";
import Select, { components } from "react-select";
import { Saleperson } from "../common/graphql/commonlayouts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomerSearchModal from "../common/module/customerSearch";
import "../catalog/catalog.scss";
import "./checkout.scss";
import { error } from "../common/helpers/toastify";
import SelectBox from "../common/selectBox/SelectBox";
import { useSelector } from 'react-redux';
import { toFormatPrice } from '../common/helpers/function';
import EmailModal from "../common/Modals/EmailModal"
import { useTranslation } from 'react-i18next';
import PaymentLayby from "../common/Modals/PaymentLaybyModal"
import PaymentInstall from "../common/Modals/PaymentInstallModal"
import Savelaybymodal from "../common/Modals/Savelaybymodal"
import Saveinstallmentmodal from "../common/Modals/Saveinstallmentmodal"
import AddCustomerAddressPopup from './addCustomerPopup/AddCustomerAddressPopup';
import addressicon from '../../assets/images/pos/icons/script.png'
import CustomerInformation from '../../admin/customers/customerInformation/CustomerInformation';
import notaddressicon from '../../assets/images/pos/icons/scriptnot.png'
import { store } from '../../source/index'
const CheckoutPayment = (props) => {
  const { t } = useTranslation();
  const location = useLocation()
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const {
    UpdatedCart,
    setShowcheckout,
    order_id,
    quotationid,
    laybyid,
    order_edit,
    order_type,
    by_custom_order,
    custom_order_id,
    by_repair_order,
    order_type_reserve,
    reserve_sell,
    laybylist,
    greandtotal
  } = props;
  console.log(UpdatedCart, "UpdatedCart DEEEPAK");
  const [toggleAddressCustomer, setToggleAddressCustomer] = useState(false);
  const posloc = JSON.parse(localStorage.getItem("pos-loc"));
  let calnumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '00', '.'];
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [cusid, setCusid] = useState();
  const [paystatement, setPaystatement] = useState('Full_Payment');
  const [loader, setLoader] = useState(false);
  const [Rechangekey, setRechangekey] = useState();
  const [installementdata, setInstallementdata] = useState([]);
  const [Currencyoption, setCurrencyoption] = useState([]);
  const [transcationcounts, setTranscationcounts] = useState({ 'cash': [], 'credit_card': 0 });
  const [mode, setMode] = useState('cash');
  const [debitedamount, setDebitedamount] = useState({ 'cash': 0, 'bank': 0, "credit_card": 0, "credit_notes": 0 });
  const [balancedue, setBalancedue] = useState(UpdatedCart?.totalamount);
  const [calnumbers, setCalnumbers] = useState('');
  const [checkcreaditamount, setCheckcreaditamount] = useState([]);
  const [exchange, setExchange] = useState();
  const [installcof, setinstallcof] = useState(false);
  const [repaymentids, setrepaymentids] = useState();
  const [showorder, setShoworder] = useState(false);
  const [chnagecurrencies, setChnagecurrencies] = useState(posloc?.curr);
  const [checkoutdetails, setcheckoutdetails] = useState();
  const [otpnumber, setOtpnumber] = useState([]);
  const [credit_noteoption, setCredit_noteoption] = useState(UpdatedCart?.credit_note_options);
  const [credit_noteids, setcredit_noteids] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [banknamearray, setBanknamearray] = useState([]);
  const [installdivtim, setinstalldivtim] = useState({ "installment": { label: '', value: '' }, "term": { label: "1 month", value: 1 }, 'receive': [] });
  const [creditCardDetails, setcreditCardDetails] = useState({ 'name': '', 'logo': '', 'card_name': '', 'card_number': '', 'payment_method_id': '', 'amount': '', 'Paymentmethod_type': '' });
  const [bankDetails, setBankDetails] = useState({ 'transcation_date': new Date(), 'payment_method_id': '', 'ac_no': '', 'ac_name': '', 'ref_no': '', 'inst_no': '', 'amount': '', "Paymentmethod_type": "Bank" });
  const [pay_data, setPay_data] = useState({ 'cash': "", 'bank': [], 'IB': [], 'credit_card': [], 'credit_note': [], 'gift_card': "" });
  const [pay_layby_data, setPay_layby_data] = useState([]);
  const customer = useSelector((state) => state.app.customer);
  const [laybyshow, setLaybyshow] = useState(false)
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const saleperson = useSelector((state) => state.app.saleperson);
  const [TransactionDate, setTransactionDate] = useState(new Date());
  const MainDate = new Date();
  const [TransactionlaybyDate, setTransactionlaybyDate] = useState(new Date(MainDate.setDate(MainDate.getDate() + 7)))
  const [banktransactionDate, setBanktransactionDate] = useState(new Date());
  const itemdaata = localStorage.getItem("addcustomerdtaa")
  const parsedObject = JSON.parse(itemdaata);
  const parts = location.pathname.split('/');
  const { ValueContainer, Placeholder } = components;
  const installdivide = [{
    label: 10,
    value: 10
  },
  {
    label: 20,
    value: 20
  },
  {
    label: 30,
    value: 30
  }
  ]
  const installtime = [{
    label: "1 month",
    value: 1
  },
  {
    label: "2 month ",
    value: 2
  },
  {
    label: "3 month",
    value: 3
  }
  ]
  const [printparmission, setPrintparmission] = useState(0);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.pos_custom_order?.View ||
      parmissiondatashow?.pos_refund?.View ||
      parmissiondatashow?.pos_deposit?.View ||
      parmissiondatashow?.pos_coupon?.View ||
      parmissiondatashow?.pos_reserve?.View ||
      parmissiondatashow?.pos_repair_order?.View ||
      parmissiondatashow?.pos_mix_and_match?.View)
      setPrintparmission(1)

  }, [parmissiondatashow]);
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const callinstallment = async () => {
    try {
      const postdata = {
        'installment': installdivtim?.installment?.value,
        'term': installdivtim?.term?.value,
        'status_type': UpdatedCart?.sell_info?.status_type,
        'amount': balancedue ? balancedue : UpdatedCart?.totalamount
      };
      let res = await _Api(postdata, `api/v1/POS/receive/calculateInstallments`);
      if (res?.code === 200) {
        setInstallementdata(res?.data);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const removeidsoninstrecieve = (i) => {
    let instarray = [...installdivtim?.receive];
    let array = [];
    instarray?.forEach((result) => {
      if (result?.id !== 'inst_' + i) {
        array.push(result);
      }
    })
    setinstalldivtim(existing => ({ ...existing, receive: array }));
  }
  const creditCard = event => {
    setIsActive(current => !current);
  };
  const callnumber = (number) => {
    let actual = '';
    if (number === 'Backspace') {
      actual = calnumbers.slice(0, -1);
      setCalnumbers(actual);
    } else {
      const re = /^[0-9]*\.?[0-9]*$/;
      let valid = re.test(number);
      if (valid) {
        actual = calnumbers + number;
        setCalnumbers(actual);
      }
    }
  }
  const changecreditnoteamount = (key, value, id, oldamount) => {
    let array = [...credit_noteoption];
    let credit_idsarray = [...credit_noteids];
    let checkamt = [...checkcreaditamount];
    if (checkamt[key]?.id === id) {
      if (value <= oldamount) {
        let subamt = parseFloat(parseFloat(oldamount) - parseFloat(checkamt[key]?.amount))
        if (subamt >= value) {
          array[key] = {
            ...array[key],
            'amount': parseFloat(value)
          }
          credit_idsarray.forEach((creditid) => {
            if (creditid?.id === id) {
              creditid['amount'] = parseFloat(value);
            }
          })
          setcredit_noteids(credit_idsarray);
          setCredit_noteoption(array);
        } else {
          error(subamt === 0 ? t("No amount left in this credit note!!") : t("Amount must be lesser than") + ' ' + subamt);
        }
      } else {
        error(t("Amount must be lesser than") + ' ' + oldamount);
      }
    } else {
      if (value <= oldamount) {
        array[key] = {
          ...array[key],
          'amount': parseFloat(value)
        }
        credit_idsarray.forEach((creditid) => {
          if (creditid?.id === id) {
            creditid['amount'] = parseFloat(value);
          }
        })
        setcredit_noteids(credit_idsarray);
        setCredit_noteoption(array);
      } else {
        error(t("Amount must be lesser than") + ' ' + oldamount);
      }
    }

  }
  const getcredit_noteids = (e, result, key) => {
    let array = [...credit_noteoption];
    if (e.target.checked) {
      setcredit_noteids([...credit_noteids, result]);
      array[key] = {
        ...array[key],
        'classadd': true
      }
      setCredit_noteoption(array);
    } else {
      setcredit_noteids((current) =>
        current.filter((item) => item?.id !== e.target.id)
      );
      array[key] = {
        ...array[key],
        'classadd': false
      }
      setCredit_noteoption(array);
    }
  }

  const debitedprice = () => {
    if (mode === 'cash') {
      if (UpdatedCart?.deposit_details?.cash !== 0 || UpdatedCart?.deposit_details?.credit_card ||
        UpdatedCart?.deposit_details?.bank) {
        if (calnumbers > UpdatedCart?.deposit_details?.cash || debitedamount?.cash >= UpdatedCart?.deposit_details?.cash) {
          error(t("Amount is less than " + (parseFloat(UpdatedCart?.deposit_details?.cash) - parseFloat(debitedamount?.cash))));
          return;
        }
      }
      let rate = 0;
      if (exchange) {
        rate = (parseFloat(exchange) * parseFloat(calnumbers));
      } else {
        rate = parseFloat(calnumbers);
      }
      if (parseFloat(rate) <= parseFloat(balancedue)) {
        setTranscationcounts(existingpay => ({ ...existingpay, cash: [...existingpay?.cash, { 'exchange': exchange, 'calnumber': calnumbers, 'currency': chnagecurrencies }] }));
        setBalancedue(parseFloat(balancedue).toFixed(2) - parseFloat(rate).toFixed(2));
        setDebitedamount(existingValues => ({ ...existingValues, cash: (debitedamount?.cash + parseFloat(rate)) }))
        setExchange('');
        setCalnumbers('');
        setExchange('');
        setChnagecurrencies(posloc?.curr);
        setPay_data(existingIds => ({
          ...existingIds, cash: {
            "payment_method_id": UpdatedCart?.payment_Options?.Cash?.length ? UpdatedCart?.payment_Options?.Cash[0]?.id : '',
            "Paymentmethod_type": "cash",
            "amount": parseFloat(debitedamount?.cash) + parseFloat(rate)
          }
        })

        );
      } else {
        if (balancedue === 0) {
          if (calnumbers) {
            setBalancedue(parseFloat(debitedamount?.cash) - parseFloat(rate));
            setDebitedamount(existingValues => ({ ...existingValues, cash: (parseFloat(rate)) }))
            setExchange('');
            setCalnumbers('');
            setExchange('');
            setChnagecurrencies(posloc?.curr);
            setPay_data(existingIds => ({
              ...existingIds, cash: {
                "payment_method_id": UpdatedCart?.payment_Options?.Cash?.length ? UpdatedCart?.payment_Options?.Cash[0]?.id : '',
                "Paymentmethod_type": "cash",
                "amount": parseFloat(rate)
              }
            })
            );
          }
        } else {
          error("cash Amount is Not Valid")
        }
      }
    }
    if (mode === 'credit-card') {

      if (UpdatedCart?.deposit_details?.credit_card !== 0 || UpdatedCart?.deposit_details?.cash ||
        UpdatedCart?.deposit_details?.bank) {
        if (calnumbers > UpdatedCart?.deposit_details?.credit_card || debitedamount?.credit_card >= UpdatedCart?.deposit_details?.credit_card) {
          error(t("Amount is less than " + (parseFloat(UpdatedCart?.deposit_details?.credit_card) -
            (parseFloat(debitedamount?.credit_card)))));
          return;
        }
      }
      if (parseFloat(calnumbers) <= parseFloat(balancedue)) {
        let onecredit = { ...creditCardDetails };
        setBalancedue(parseFloat(balancedue).toFixed(2) - parseFloat(calnumbers).toFixed(2));
        setTranscationcounts(existingValues => ({ ...existingValues, credit_card: transcationcounts?.credit_card + 1 }));
        setDebitedamount(existingValues => ({ ...existingValues, credit_card: ((debitedamount?.credit_card) + parseFloat(calnumbers)) }))
        setCalnumbers('');
        onecredit = { ...onecredit, amount: calnumbers }
        pay_data?.credit_card?.push(onecredit)
        setcreditCardDetails({ 'name': '', 'logo': '', 'card_name': '', 'card_number': '', 'payment_method_id': '', 'amount': '', 'Paymentmethod_type': '' });
        setIsActive(current => !current);
      } else {
        error(t("Amount is not Valid")); return;
      }
    }
    if (mode === 'bankcheque') {
      if (UpdatedCart?.deposit_details?.bank !== 0 || UpdatedCart?.deposit_details?.cash ||
        UpdatedCart?.deposit_details?.credit_card) {
        if (calnumbers > UpdatedCart?.deposit_details?.bank || debitedamount?.bank >= UpdatedCart?.deposit_details?.bank) {
          error(t("Amount is less than " + (parseFloat(UpdatedCart?.deposit_details?.bank) - parseFloat(debitedamount?.bank))));
          return;
        }
      }
      if (parseFloat(calnumbers) <= parseFloat(balancedue)) {
        let onebank = { ...bankDetails };
        setBalancedue(parseFloat(balancedue).toFixed(2) - parseFloat(calnumbers).toFixed(2));
        setDebitedamount(existingValues => ({ ...existingValues, bank: (parseFloat(debitedamount?.bank) + parseFloat(calnumbers)) }));
        setCalnumbers('');
        onebank = { ...onebank, amount: calnumbers }
        pay_data?.bank?.push(onebank)
        setBankDetails({ 'transcation_date': new Date(), 'payment_method_id': '', 'ac_no': '', 'ac_name': '', 'ref_no': '', 'inst_no': '', 'amount': '', 'Paymentmethod_type': '' });
      } else {
        error(t("Amount is not Valid")); return;
      }
    }
  }

  const change_creditnote = () => {
    if (mode === 'credit-note') {

      let getarray = [...checkcreaditamount]
      if (credit_noteids?.length) {
        credit_noteids.forEach((result) => {
          let array = checkcreaditamount.filter(item => item?.id === result?.id);
          if (array?.length) {
            if (result?.old_amount >= array[0]?.amount) {
              array[0]['amount'] = parseFloat(array[0]?.amount) + parseFloat(result?.amount);
              getarray.map(obj => array.find(o => o.id === obj.id) || obj);
              setCheckcreaditamount(getarray);
            }

          } else {
            setCheckcreaditamount([...checkcreaditamount, { id: result?.id, amount: parseFloat(result?.amount) }]);
          }

        })
      }
      if (parseFloat(balancedue) >= parseFloat(credit_noteids?.length ? credit_noteids?.reduce((interation, val) => (interation = interation + (parseFloat(val?.amount))), 0) : 0)) {
        let credit_notearray = [];
        let credit_noteamount = credit_noteids?.length ? credit_noteids?.reduce((interation, val) => (interation = interation +
          val?.amount), 0) : 0;
        if (credit_noteamount <= debitedamount?.credit_notes) {
          error(t(`Valid Amount is less than ${credit_noteamount}`))
          return;
        } else {
          let duebalance = 0
          if (credit_noteids?.length) {
            for (const interation of credit_noteids) {
              duebalance = interation?.amount;
            }
          } else {
            duebalance = 0;
          }
          setBalancedue(parseFloat(balancedue).toFixed(2) - parseFloat(duebalance).toFixed(2));
          setDebitedamount(existingValues => ({ ...existingValues, credit_notes: (parseFloat(credit_noteamount)) }))
          if (credit_noteids?.length) {
            credit_noteids.forEach((result) => {
              delete result?.Ref_No;
              delete result?.currency;
              delete result?.date;
              delete result?.type;
              credit_notearray.push({ payment_method_id: result?.id, amount: result?.amount });

            })
          }
          setPay_data(existingIds => ({ ...existingIds, credit_note: { 'ids': credit_notearray, 'amount': credit_noteamount, Paymentmethod_type: "CreditNote" } })
          );
        }
      }
      else {
        error(t("Amount is not Valid")); return;
      }

    }
  }
  const reCashPayement = (key, label) => {
    if (label === 'cashpayment') {
      if ((exchange * calnumbers) <= balancedue) {
        let array = { ...transcationcounts };
        let pay_data_array = { ...pay_data };
        array['cash'][Rechangekey] = { 'exchange': chnagecurrencies === posloc?.curr ? '' : exchange, 'calnumber': calnumbers, 'currency': chnagecurrencies };
        let subtotal = array?.cash.reduce((interation, val) => (interation = interation + (parseFloat(val?.exchange ? val?.exchange : 1) * parseFloat(val?.calnumber))), 0)
        let totalamount = parseFloat(pay_data_array?.cash?.amount) + parseFloat(balancedue) - parseFloat(subtotal);
        setBalancedue(totalamount);
        setDebitedamount(existingValues => ({ ...existingValues, cash: subtotal }))
        pay_data_array['cash']['amount'] = subtotal;
        setPay_data(pay_data_array);
        setTranscationcounts(array);
        setCalnumbers('');
        setExchange('');
        setrepaymentids('');
        setChnagecurrencies(posloc?.curr);
        setRechangekey('');
      } else {
        error(t("Amount is not Valid"));
      }
    } else {
      setrepaymentids('cashid');
      let array = { ...transcationcounts };
      setExchange(array?.cash[key]?.exchange);
      setCalnumbers(array?.cash[key]?.calnumber);
      setChnagecurrencies(array?.cash[key]?.currency);
      setRechangekey(key);
    }
  }
  const reCreditCardPayment = (key) => {
    let array = { ...pay_data };
    setrepaymentids('creditcardview');
    setRechangekey(key);
    setIsActive(current => !current);
    setcreditCardDetails(array['credit_card'][key]);
    setCalnumbers(array['credit_card'][key]['amount']);
  }
  const checkoutorder = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    };
    if (parsedObject === null || (Object.keys(parsedObject?.shipping_address)?.length === 0 || Object.keys(parsedObject?.billing_address).length === 0)) {
      error(t("Select Your Billing and Shipping Address"));
      return;
    }
    setLoader(true);
    try {
      let postdata = {
        "sell_info": UpdatedCart?.sell_info,
        "payment_info": {
          "debited_amount": debitedamount,
          "pay_data": pay_data,
          "balance_due": (UpdatedCart?.sell_info?.totalamount - UpdatedCart?.totalamount),
          "balance_deposit": UpdatedCart?.totalamount
        },
        "transaction_date": TransactionDate,
        "layby_due_date": laybylist ? TransactionlaybyDate : paystatement === "LayBy" ? TransactionlaybyDate : "",
        "pay_layby_data": laybylist ? pay_layby_data : paystatement === "LayBy" ? pay_layby_data : [],
        "paymentMathod": installdivtim?.receive?.length && installcof === true ? 'Installment' : paystatement,
        'installment': installdivtim?.receive,
        'fullinstallentlist': installementdata ? installementdata : [],
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "order_type": order_type ? order_type : order_type_reserve ? order_type_reserve : UpdatedCart?.ordertype ? UpdatedCart?.ordertype : UpdatedCart?.sell_info?.status_type,
        "order_id": order_id ? order_id : '',
        "quatation_id": quotationid ? quotationid : "",
        "layby_id": laybyid ? laybyid : "",
        "by_custom_order": by_custom_order ? by_custom_order : "",
        "custom_order_id": custom_order_id ? custom_order_id : "",
        "by_repair_order": by_repair_order ? by_repair_order : "",
        "reserve_sell": reserve_sell ? reserve_sell : "",
        "service_enable": UpdatedCart?.service_enable,
        "shippingInfo":
          parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive"
            && parts[3] !== "reserveproduct" ? parsedObject : {}

      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/saveCustomOrder`);
      if (res.code === 200) {
        setLoader(false);
        setcheckoutdetails(res?.data);
        setShoworder(true);
        localStorage.removeItem("showcheckout")
      } else {
        setLoader(false);
        error((res?.message));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const editcheckoutorder = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (parsedObject === null || (Object.keys(parsedObject?.shipping_address).length === 0 || Object.keys(parsedObject?.billing_address)?.length === 0)) {
      error(t("Select Your Billing and Shipping Address"));
      return;
    }
    setLoader(true);
    setLoader(true);
    try {
      const postdata = {
        "sell_info": UpdatedCart?.sell_info,
        "payment_info": {
          "debited_amount": debitedamount,
          "pay_data": pay_data,
          "balance_due": (UpdatedCart?.sell_info?.totalamount - UpdatedCart?.totalamount),
          "balance_deposit": UpdatedCart?.totalamount
        },
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "pay_layby_data": laybylist ? pay_layby_data : paystatement === "LayBy" ? pay_layby_data : [],
        "paymentMathod": installdivtim?.receive?.length && installcof === true ? 'Installment' : paystatement,
        "transaction_date": TransactionDate,
        "order_type": UpdatedCart?.ordertype ? UpdatedCart?.ordertype : UpdatedCart?.sell_info?.status_type ? UpdatedCart?.sell_info?.status_type : order_type,
        "order_id": order_id ? order_id : '',
        "order_edit": order_edit,
        "shippingInfo": parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive" && parts[3] !== "reserveproduct"
          ? parsedObject : {}
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/EditCustomOrder`);
      if (res.code === 200) {
        setLoader(false);
        setcheckoutdetails(res?.data);
        setShoworder(true);
        localStorage.removeItem("showcheckout")
      } else {
        error(t("Please Try Again!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }

  const searchrefnumber = (value) => {
    let array = [...UpdatedCart?.oldcredit_note_options];
    if (value) {
      let searcharray = array.filter(item => item?.Ref_No === value);
      setCredit_noteoption(searcharray);
    } else {
      setCredit_noteoption(array);
    }

  }

  const verfypin = async () => {
    try {
      const postdata = {
        "verfypin": otpnumber.join().replace(/,/g, '')
      };
      let res = await _Api(postdata, `api/v1/POS/common/loginPINVerfy`);
      if (res.code === 200) {
        if (order_edit) {
          editcheckoutorder()
        } else {
          checkoutorder();

        }

      } else {
        error(t("Please Try Again!!"));
        setOtpnumber([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const getcurrency = async () => {
    try {
      const postdata = {};
      let res = await _Api(postdata, `api/v1/POS/common/exchange-rates`);
      if (res.code === 200) {
        setCurrencyoption(res?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    let array = []
    if (UpdatedCart?.payment_Options?.Bank?.length) {
      UpdatedCart?.payment_Options?.Bank.forEach((result) => {
        array.push({
          label: result?.name,
          value: result?.id
        })
      })
    }
    setBanknamearray(array);
  }, [UpdatedCart?.payment_Options?.Bank]);
  useEffect(() => {
    if (generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '1') {
      if (otpnumber?.length === 6) {
        verfypin();
      }
    }
    // eslint-disable-next-line 
  }, [otpnumber, generalsettings]);
  useEffect(() => {
    getcurrency();
  }, []);
  useEffect(() => {
    setCalnumbers('');
    setExchange('');
    setrepaymentids('');
    setChnagecurrencies(posloc?.curr);
    setRechangekey('');
  }, [mode, posloc?.curr]);
  useEffect(() => {
    if (installdivtim?.installment?.value && installdivtim?.term?.value) {
      callinstallment()
    }
    // eslint-disable-next-line 
  }, [installdivtim?.installment?.value, installdivtim?.term?.value]);
  return (
    <>
      <div className="main-body-row-right-col-inner">
        <div className="main-body-row-right-col-inner-ct-top">
          <div className="main-body-row-right-col-inner-ct-top-inner">
            <div className="main-body-row-right-col-inner-ct-top-inner-date-area">
              <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner">
                <span>{UpdatedCart?.sell_info?.status_type === 'custom_order' || UpdatedCart?.sell_info?.status_type === 'mix_and_match' ? 'CO-XXX' : UpdatedCart?.sell_info?.status_type === 'pos_order' ? 'POS-XXX' : 'XXXX'}</span> :
                <div className="main-body-row-right-col-inner-ct-top-inner-date-area-inner-input date">
                  <DatePicker
                    className="date-field"
                    selected={TransactionDate}
                    onChange={(input) => setTransactionDate(input)}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    autoComplete='none'
                    popperPlacement='top-start'
                  />
                  <img className="date-img" src={base_assets + "images/icons/calendar-green.svg"} alt="" />
                </div>
              </div>
              <div className="main-body-row-right-col-inner-ct-top-inner-date-area-select">
                <Saleperson />
              </div>
            </div>
            <div className="main-body-row-right-col-inner-ct-top-inner-search">
              <CustomerSearchModal setCusid={setCusid}></CustomerSearchModal>
              {parmissiondatashow?.pos_customer_reports?.Add ?
                <span className="main-body-row-right-col-inner-ct-top-inner-search-user">
                  <img data-bs-toggle="modal" data-bs-target="#customerAddModal" src={base_assets + "images/pos/icons/add-user.png"} alt="" />
                </span>
                : ""}
              {customer?.id ?
                <span
                  className={
                    parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive" && parts[3] !== "reserveproduct"
                      ? "main-body-row-right-col-inner-ct-top-inner-search-user"
                      : "d-none"
                  }
                  onClick={() => { setToggleAddressCustomer(!toggleAddressCustomer) }}

                >
                  {customer?.existsInAddress ?
                    <img
                      src={addressicon}
                      alt=""
                    />
                    :
                    <img
                      className='iconwithnotification'
                      src={notaddressicon}
                      alt=""
                    />}
                </span> :
                <span
                  className={
                    parts[3] !== "exchange" && parts[3] !== "refund" && parts[3] !== "reserve" && parts[3] !== "receive" && parts[3] !== "reserveproduct"
                      ? "main-body-row-right-col-inner-ct-top-inner-search-user"
                      : "d-none"
                  }
                  onClick={() => { error(t("Customer is Requried!!")) }}

                >
                  <img
                    src={addressicon}
                    alt=""
                  />
                </span>}
            </div>
          </div>
        </div>
        {toggleAddressCustomer && customer?.id ?
          <AddCustomerAddressPopup /> :
          <div className="main-body-row-right-col-inner-progress">
            <div className="main-body-row-right-col-inner-progress-line">
              <div className="main-body-row-right-col-inner-progress-line-single col active"></div>
              <div className="main-body-row-right-col-inner-progress-line-single col active"></div>
              <div className="main-body-row-right-col-inner-progress-line-single col"></div>
              <div className="main-body-row-right-col-inner-progress-line-single col"></div>
            </div>

            <div className="main-body-row-right-col-inner-progress-step">
              {UpdatedCart?.receivestatus === 'receivepay' ?
                <div className="main-body-row-right-col-inner-progress-step-single active">
                  <div className="main-body-row-right-col-inner-progress-step-single-circle">
                    <img src={base_assets + "images/pos/icons/right-icon.png"} alt="" />
                  </div>
                  <span className="main-body-row-right-col-inner-progress-step-single-text">{t("sell")}</span>
                </div>
                :
                <div className="main-body-row-right-col-inner-progress-step-single active" onClick={() => setShowcheckout('sell')}>
                  <div className="main-body-row-right-col-inner-progress-step-single-circle">
                    <img src={base_assets + "images/pos/icons/right-icon.png"} alt="" />
                  </div>
                  <span className="main-body-row-right-col-inner-progress-step-single-text">{t("sell")}</span>
                </div>
              }

              <div className="main-body-row-right-col-inner-progress-step-single second active" >
                <div className="main-body-row-right-col-inner-progress-step-single-circle">

                  {showorder ? <img src={base_assets + "images/pos/icons/right-icon.png"} alt="" /> :
                    <span className="main-body-row-right-col-inner-progress-step-single-circle-dot"></span>}

                </div>
                <span className="main-body-row-right-col-inner-progress-step-single-text">{t("Payment")}</span>
              </div>
              <div className={showorder ? "main-body-row-right-col-inner-progress-step-single active" : "main-body-row-right-col-inner-progress-step-single"}>
                <div className="main-body-row-right-col-inner-progress-step-single-circle">
                  {showorder ? <img src={base_assets + "images/pos/icons/right-icon.png"} alt="" /> : ''}
                </div>
                <span className="main-body-row-right-col-inner-progress-step-single-text">{t("done")}</span>
              </div>
            </div>
          </div>}


        <div className='main-body-row-right-col-inner-payment-balance'>
          <div className='main-body-row-right-col-inner-payment-balance-box total'>
            <span className='main-body-row-right-col-inner-payment-balance-box-text'>{UpdatedCart?.sell_info?.status_type === 'pos_order' || UpdatedCart?.sell_info?.status_type === 'gift_card_order' ? t('Grand Total') : t("Deposit Amount")} :</span>
            <span className='main-body-row-right-col-inner-payment-balance-box-price'>{toFormatPrice(UpdatedCart?.totalamount, { addSymbol: true })}</span>
          </div>
          <div className='main-body-row-right-col-inner-payment-balance-box danger-text'>
            <span className='main-body-row-right-col-inner-payment-balance-box-text'>{t("Balance due")} :</span>
            <span className='main-body-row-right-col-inner-payment-balance-box-price'>{toFormatPrice(balancedue, { addSymbol: true })}</span>

          </div>
        </div>
        {UpdatedCart?.ordertype === 'pos_order' ?
          < ul className={UpdatedCart?.ordertype === 'refund_order' ? 'main-body-row-right-col-inner-payment-layby-area nav nav-tabs pe-none' : 'main-body-row-right-col-inner-payment-layby-area nav nav-tabs'}>
            <li className='nav-item main-body-row-right-col-inner-payment-layby-area-tab active ' onClick={() => setPaystatement('Full_Payment')}>
              <span className='nav-link active' data-bs-toggle="tab">{t("Full Payment")}</span>
            </li>
            <li className={generalsettings?.data?.generalsetup?.POS_partial_payment === '1' ? 'nav-item main-body-row-right-col-inner-payment-layby-area-tab ' : 'd-none'} onClick={() => setPaystatement('LayBy')}>
              <span className='nav-link' data-bs-toggle="tab">{t("Layby")}</span>
            </li>
            <li onClick={() => { UpdatedCart?.installmentarray?.length ? setInstallementdata(UpdatedCart?.installmentarray) : setinstalldivtim(existing => ({ ...existing, installment: { label: 10, value: 10 } })) }}
              className={generalsettings?.data?.generalsetup?.POS_partial_payment === '1' ? 'nav-item main-body-row-right-col-inner-payment-layby-area-tab' : "d-none"} data-bs-toggle="modal" data-bs-target="#installmentModal">
              <span className='nav-link' data-bs-toggle="tab">{t("Installment")}</span>
            </li>
          </ul>
          : UpdatedCart?.installmentarray?.length ?
            <ul className='main-body-row-right-col-inner-payment-layby-area nav nav-tabs'>
              <li onClick={() => { UpdatedCart?.installmentarray?.length ? setInstallementdata(UpdatedCart?.installmentarray) : callinstallment() }} className='nav-item main-body-row-right-col-inner-payment-layby-area-tab' data-bs-toggle="modal" data-bs-target="#installmentModal">
                <span className='nav-link' data-bs-toggle="tab">{t("Installment")}</span>
              </li>
            </ul>
            : UpdatedCart?.sell_info?.status_type === 'lay_by' ?
              <ul className='main-body-row-right-col-inner-payment-layby-area nav nav-tabs'>
                <li className={generalsettings?.data?.generalsetup?.POS_partial_payment === '1' ? 'nav-item main-body-row-right-col-inner-payment-layby-area-tab ' : 'd-none'} onClick={() => setPaystatement('LayBy')}>
                  <span className='nav-link' data-bs-toggle="tab">{t("Layby")}</span>
                </li>
              </ul> : ""
        }
        <div className="main-body-row-right-col-inner-payment box-3">
          <div className='tab-calc-wrap d-flex flex flex-column justify-content-between h-100'>
            <div className="main-body-row-right-col-inner-payment-tabs">
              <ul className={UpdatedCart?.ordertype === 'refund_order' ? "nav nav-tabs event-none" : "nav nav-tabs"}>
                <li className="nav-item cashtab">
                  <a className="nav-link active cash" onClick={() => setMode('cash')} data-bs-toggle="tab" href="#cash">
                    <span className='nav-link-img'>
                      <img className='not-active' src={base_assets + "images/pos/icons/cash-icon.png"} alt="" />
                      <img className='active' src={base_assets + "images/pos/icons/cash-icon-g.png"} alt="" />
                    </span>
                    {t("Cash")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link card" data-bs-toggle="tab" onClick={() => setMode('credit-card')} href="#credit-card">
                    <span className='nav-link-img'>
                      <img className='not-active' src={base_assets + "images/pos/icons/card.png"} alt="" />
                      <img className='active' src={base_assets + "images/pos/icons/card-g.png"} alt="" />
                    </span>
                    {t("Credit Card")}
                  </a>
                </li>
                <li className="nav-item bank-tabs">
                  <a className="nav-link bank" data-bs-toggle="tab" href="#bank" onClick={() => setMode('bankib')}>
                    <span className='nav-link-img'>
                      <img className='not-active' src={base_assets + "images/pos/icons/bank.png"} alt="" />
                      <img className='active' src={base_assets + "images/pos/icons/bank-g.png"} alt="" />
                    </span>
                    {t("Bank")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link note" data-bs-toggle="tab" href="#credit-note" onClick={() => setMode('credit-note')}>
                    <span className='nav-link-img'>
                      <img className='not-active' src={base_assets + "images/pos/icons/credit-note.png"} alt="" />
                      <img className='active' src={base_assets + "images/pos/icons/credit-note-g.png"} alt="" />
                    </span>
                    {t("Credit Note")}
                  </a>
                  {credit_noteoption?.length ? <img className='comment-icon' src={base_assets + "images/pos/icons/comment-icon.png"} alt="" /> : ''}
                </li>
              </ul>
              {!toggleAddressCustomer &&
                <div className="tab-content">
                  <div className="tab-pane container active main-body-row-right-col-inner-payment-tabs-cash" id="cash">
                    <div className='main-body-row-right-col-inner-payment-tabs-cash-ul'>
                      <div className='dropdown common-dropdown'>
                        <div className='main-body-row-right-col-inner-payment-tabs-cash-ul-li' data-bs-toggle="dropdown" aria-expanded="false">
                          <span className='main-body-row-right-col-inner-payment-tabs-cash-ul-li-img'>
                            {chnagecurrencies ? <img className='active' src={base_assets + "images/flags/" + chnagecurrencies.toLowerCase() + ".png"} alt="" /> : <img className='active' src={base_assets + "images/flags/" + posloc?.curr.toLowerCase() + ".png"} alt="" />}

                          </span>
                          <label>{chnagecurrencies ? chnagecurrencies : posloc?.curr ? posloc?.curr : ""}</label>
                        </div>
                        <ul className="dropdown-menu">
                          {
                            Currencyoption?.length ? Currencyoption.map((result, curkey) => {
                              return (
                                <li key={curkey} >
                                  <span className="dropdown-item" onClick={() => {
                                    setChnagecurrencies(result?.currency);
                                    setExchange(result?.rate);
                                  }}>
                                    {result?.currency ? <img className='active' src={base_assets + "images/flags/" + result?.flag} alt="" /> : ""}
                                    {result?.currency}
                                  </span>
                                </li>
                              );
                            }) : ''
                          }
                        </ul>
                      </div>
                      {chnagecurrencies !== posloc?.curr && chnagecurrencies !== undefined ?
                        <div className='placeholder-up-input'>
                          <span>{t("Exchange rate")}</span>
                          <input type="text" className="placeholder-up-input-field" value={exchange} onChange={(e) => setExchange(e.target.value)} />
                        </div>
                        : ''}
                    </div>
                    {chnagecurrencies !== posloc?.curr && chnagecurrencies !== undefined ?
                      <span className='main-body-row-right-col-inner-payment-tabs-cash-exchange-rate'>= {mode === 'cash' && exchange !== '' && calnumbers !== '' ? toFormatPrice(exchange * calnumbers, { addSymbol: true }) : "0.00"}</span>
                      : ''}
                  </div>
                  <div className="tab-pane container fade main-body-row-right-col-inner-payment-tabs-card" id="credit-card">
                    <div className={isActive ? 'd-none' : 'main-body-row-right-col-inner-payment-tabs-card-inner'}>
                      {UpdatedCart?.payment_Options?.Credit_Card?.length ? UpdatedCart?.payment_Options?.Credit_Card.map((result, creditkey) => {
                        return (
                          <div className='main-body-row-right-col-inner-payment-tabs-card-inner-li' key={creditkey} onClick={() => { creditCard(); setcreditCardDetails(existing => ({ ...existing, name: result?.name })); setcreditCardDetails(existing => ({ ...existing, logo: result?.PayMethod_logo })); setcreditCardDetails(existing => ({ ...existing, payment_method_id: result?.id })); setcreditCardDetails(existing => ({ ...existing, Paymentmethod_type: result?.Paymentmethod_type })) }}>
                            <img src={result?.PayMethod_logo} alt="" />
                            <span className='main-body-row-right-col-inner-payment-tabs-card-inner-li-text'>{result?.name}</span>
                          </div>
                        );
                      }) : ''}
                    </div>
                    <div className={isActive ? "main-body-row-right-col-inner-payment-tabs-card-single-detail" : "d-none"}>
                      <div className="main-body-row-right-col-inner-payment-tabs-card-single-detail-head">
                        <button className="back btn-style" onClick={() => { creditCard(); setcreditCardDetails({ 'name': '', 'logo': '', 'card_name': '', 'card_number': '', 'payment_method_id': '', 'amount': '', 'Paymentmethod_type': '' }) }}>
                          <img className="active" src={base_assets + "images/pos/icons/arrow-gray-left.png"} alt="" />
                        </button>
                        <h4 className="main-body-row-right-col-inner-payment-tabs-card-single-detail-head-text"><img src={creditCardDetails?.logo} alt="" /><label>{creditCardDetails?.name}</label></h4>
                      </div>
                      <div className="main-body-row-right-col-inner-payment-tabs-card-single-detail-card-number placeholder-up-input form-floating">
                        <input type="text" className={repaymentids === 'creditcardview' ? "placeholder-up-input-field form-control pointer-none" : "placeholder-up-input-field form-control"} placeholder={t('Card number')} value={creditCardDetails?.card_number} onChange={(e) => setcreditCardDetails(existing => ({ ...existing, card_number: e.target.value }))} />
                        <label className="placeholder-up-input-label">{t("Card number")}</label>
                        <img className="main-body-row-right-col-inner-payment-tabs-card-single-detail-card-number-img" src={base_assets + "images/pos/icons/p1.png"} alt="" />
                      </div>
                      <div className="main-body-row-right-col-inner-payment-tabs-card-single-detail-card-number placeholder-up-input form-floating">
                        <input type="text" className={repaymentids === 'creditcardview' ? "placeholder-up-input-field form-control pointer-none" : "placeholder-up-input-field form-control"} value={creditCardDetails?.card_name} onChange={(e) => setcreditCardDetails(existing => ({ ...existing, card_name: e.target.value }))} placeholder='card name' />
                        <label className="placeholder-up-input-label">{t("Card name")}</label>
                        <img className="main-body-row-right-col-inner-payment-tabs-card-single-detail-card-number-img" src={base_assets + "images/pos/icons/p1.png"} alt="" />
                      </div>
                    </div>
                    {!isActive ?
                      <div className='dots'>
                        {
                          [...Array(transcationcounts?.credit_card)].map((dckry, dckrys) => {
                            return (<span key={dckrys} onClick={() => reCreditCardPayment(dckrys)} className={Rechangekey === dckrys && Rechangekey !== undefined ? 'dots-single active' : 'dots-single'}></span>);
                          })
                        }
                      </div>
                      : ''
                    }
                  </div>
                  <div className="tab-pane container fade main-body-row-right-col-inner-payment-tabs-card-bank" id="bank">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="tab" href="#banking">{t("Internet Banking")}</a>
                      </li>
                      {UpdatedCart?.payment_Options?.Bank?.length ?
                        <li className="nav-item">
                          <a className="nav-link" data-bs-toggle="tab" onClick={() => setMode('bankcheque')} href="#cheque">{t("Cheque")}</a>
                        </li>
                        : ''
                      }
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane container active" id="banking">
                        <SelectBox />
                        <div className='main-body-row-right-col-inner-payment-tabs-card-bank-btn-row'>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-bank-btn-row-btn'>
                            <input type="file"
                              accept="image/*" />
                            <img src={base_assets + "images/pos/icons/camera-icon.png"} alt="" />
                            <span>{t("take a photo")}</span>
                          </div>
                          <span className='or'>{t("or")}</span>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-bank-btn-row-btn'>
                            <img src={base_assets + "images/pos/icons/qr-icon.png"} alt="" />
                            <span>{t("Scan QR Code")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane container fade" id="cheque">
                        <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row'>
                          <div className="main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row-single placeholder-up-input placeholder-up-input-date date">
                            <DatePicker
                              className="placeholder-up-input-field"
                              selected={banktransactionDate}
                              onChange={(update) => { setBanktransactionDate(update); setBankDetails(existing => ({ ...existing, transcation_date: update })) }}
                              name="TransactionDate"
                              dateFormat={OrgSettings?.date_picker}
                              autoComplete='off'
                            />
                            <img
                              className="placeholder-up-input-field-calender"
                              src={base_assets + "images/icons/calendar-green.svg"}
                              alt=""
                            />
                            <label className="placeholder-up-input-label">
                              {t("Transaction Date")}
                            </label>
                          </div>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row-single'>
                            <div className="placeholder-up-input col ps-0">
                              <Select
                                className="Select"
                                placeholder="Bank Name"
                                options={banknamearray}
                                components={{
                                  ValueContainer: CustomValueContainer,
                                }}
                                classNamePrefix="common-select"
                                onChange={(e) => setBankDetails(existing => ({ ...existing, payment_method_id: e?.value }))}
                                getOptionLabel={(e) => <div className=''>{e.label}</div>}
                                isSearchable={false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row'>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row-single'>
                            <div className="placeholder-up-input form-floating">
                              <input type="text" className="placeholder-up-input-field form-control" value={bankDetails?.ac_no}
                                onChange={(e) => setBankDetails(existing => ({ ...existing, ac_no: e.target.value }))}
                                placeholder='A/c No.' />
                              <label className="placeholder-up-input-label">{t("A/c No.")}</label>
                            </div>
                          </div>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row-single'>
                            <div className="placeholder-up-input form-floating">
                              <input type="text" className="placeholder-up-input-field form-control" value={bankDetails?.ac_name} onChange={(e) => setBankDetails(existing => ({ ...existing, ac_name: e.target.value }))} placeholder='A/c Name.' />
                              <label className="placeholder-up-input-label">{t("A/c Name.")}</label>
                            </div>
                          </div>
                        </div>
                        <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row'>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row-single'>
                            <div className="placeholder-up-input form-floating">
                              <input type="text" className="placeholder-up-input-field form-control" value={bankDetails?.ref_no} onChange={(e) => setBankDetails(existing => ({ ...existing, ref_no: e.target.value }))} placeholder='Ref No.' />
                              <label className="placeholder-up-input-label">{t("Ref No.")}</label>
                            </div>
                          </div>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-bank-cheque-row-single'>
                            <div className="placeholder-up-input form-floating">
                              <input type="text" className="placeholder-up-input-field form-control" value={bankDetails?.inst_no} onChange={(e) => setBankDetails(existing => ({ ...existing, inst_no: e.target.value }))} placeholder='Inst No.' />
                              <label className="placeholder-up-input-label">{t("Inst No")}.</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane container fade" id="credit-note">
                    <div className="main-body-row-right-col-inner-payment-tabs-card-credit-search com-search-area">
                      <button className="com-search-area-left-img">
                        <img src={base_assets + "images/pos/icons/gift-hamper-icon.png"} alt="" />
                      </button>
                      <input type="text" placeholder="Gift Card number" onChange={(e) => searchrefnumber(e.target.value)} />
                      <button className="btn-style lh-0">
                        <img className="com-search-area-img" src={base_assets + "images/pos/icons/scan-icon.png"} alt="" />
                      </button>
                    </div>
                    {credit_noteoption?.length ?
                      <>
                        <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box'>
                          {credit_noteoption?.length ? credit_noteoption.map((result, creditnotekey) => {
                            return (
                              <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row ' key={creditnotekey}>
                                <div className="com-check">
                                  <input id={result?.id} onClick={(e) => getcredit_noteids(e, result, creditnotekey)} type="checkbox" />
                                  <label htmlFor={result?.id} className="com-check-label"></label>
                                </div>
                                <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right'>
                                  <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-name'>
                                    <h4 className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-name-heading'>{result?.type}</h4>
                                    <span className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-name-ref'>{t("Ref No.")} {result?.Ref_No} </span>
                                  </div>
                                  <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-input'>
                                    <span className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-input-date'>{result?.date}</span>
                                    <div className={result?.classadd ? 'main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-input-field' : 'pe-none border-white'}>
                                      <span className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-input-field-span'>{result?.currency}</span>
                                      <input
                                        disabled={UpdatedCart?.ordertype === "pos_order" ? false : true}
                                        className={result?.classadd ? 'main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-input-field-text arrow-none' : 'main-body-row-right-col-inner-payment-tabs-card-credit-box-row-right-input-field-text main-green-text arrow-none'}
                                        onChange={(e) => changecreditnoteamount(creditnotekey, parseFloat(e.target.value), result?.id, result?.old_amount)}
                                        value={parseFloat(result?.amount)}
                                        type="number"
                                        placeholder=''
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }) : ''}
                          <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row p-0 bg-white'>
                            <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-total'>
                              {t("Total")}
                            </div>
                            <div className='main-body-row-right-col-inner-payment-tabs-card-credit-box-row-price'>
                              {toFormatPrice(credit_noteoption?.length ? credit_noteoption?.reduce((interation, val) => (interation = interation + val.amount), 0) : '', { addSymbol: true })}
                            </div>
                          </div>
                        </div>
                        <div className='main-body-row-right-col-inner-payment-tabs-card-credit-submit'>
                          <div className='main-body-row-right-col-inner-payment-tabs-card-credit-submit-value'>
                            <input className='main-body-row-right-col-inner-payment-tabs-card-credit-submit-value-text'
                              value={
                                credit_noteids?.length ? credit_noteids?.reduce((interation, val) =>
                                  interation = parseFloat(interation + val.amount), 0) : 0}
                              type="text" placeholder='' />
                          </div>
                          <button className={UpdatedCart?.ordertype === "pos_order" ? 'btn-style' : "btn-style btn-disabled"} onClick={() => change_creditnote()} >{t("Submit")}</button>
                        </div>
                      </>
                      : ''}
                  </div>
                </div>
              }
            </div>
            {!toggleAddressCustomer && <div className={mode === 'credit-note' || (mode === 'credit-card' && isActive === false) ? "d-none" : "main-body-row-right-col-inner-payment-calculator"}>
              <div className="main-body-row-right-col-inner-payment-calculator-value">
                <input className={(mode === 'credit-card' && repaymentids === 'creditcardview') ? "main-body-row-right-col-inner-payment-calculator-value-input pointer-none" : 'main-body-row-right-col-inner-payment-calculator-value-input'}
                  pattern="[0.0-9]*"
                  onKeyPress={(e) => e.key === 'Enter' && debitedprice()}
                  value={calnumbers} onKeyDown={(e) => callnumber(e.key)} placeholder={t('enter amount')}
                  readOnly />
                <button className="main-body-row-right-col-inner-payment-calculator-value-clear btn" onClick={() => setCalnumbers(calnumbers.slice(0, -1))}>
                  <img className='active' src={base_assets + "images/pos/icons/clear-btn.png"} alt="" />
                </button>
              </div>
              <div className="main-body-row-right-col-inner-payment-calculator-buttons">
                <div className="main-body-row-right-col-inner-payment-calculator-buttons-num">
                  {calnumber.map((result, key) => {
                    return (
                      <button className='main-body-row-right-col-inner-payment-calculator-buttons-num-btn' onClick={() => callnumber(result)} key={key}>{result}</button>
                    )
                  })}
                </div>
                <div className="main-body-row-right-col-inner-payment-calculator-buttons-calculate">
                  <button className='btn clear' onClick={() => setCalnumbers('')}>{t("clear")}</button>
                  {(mode === 'credit-card' && repaymentids === 'creditcardview') ? <button className='btn sub' onClick={() => error(t("Not Editable!"))} >{t("sub")}</button> : <button className='btn sub' onClick={() => { UpdatedCart?.installmentarray?.length && installdivtim?.receive?.length === 0 ? error(t('Please Select Installment!')) : repaymentids === 'cashid' ? reCashPayement('', 'cashpayment') : debitedprice() }} >{t("sub")}</button>}
                </div>
              </div>
              {!toggleAddressCustomer &&
                <div className="main-body-row-right-col-inner-payment-price-detail">
                  <div className="main-body-row-right-col-inner-payment-price-detail-single">
                    <label className='main-body-row-right-col-inner-payment-price-detail-single-text'>{t("Cash")}</label>
                    <span className='main-body-row-right-col-inner-payment-price-detail-single-price'>{toFormatPrice(debitedamount?.cash ? (debitedamount?.cash) : 0, { addSymbol: true })}</span>
                  </div>
                  <div className="main-body-row-right-col-inner-payment-price-detail-single">
                    <label className='main-body-row-right-col-inner-payment-price-detail-single-text'>{t("Credit card")}</label>
                    <span className='main-body-row-right-col-inner-payment-price-detail-single-price'>{toFormatPrice(debitedamount?.credit_card ? debitedamount?.credit_card : 0, { addSymbol: true })}</span>
                  </div>
                  <div className="main-body-row-right-col-inner-payment-price-detail-single">
                    <label className='main-body-row-right-col-inner-payment-price-detail-single-text'>{t("Bank")}</label>
                    <span className='main-body-row-right-col-inner-payment-price-detail-single-price'>{toFormatPrice(debitedamount?.bank ? debitedamount?.bank : 0, { addSymbol: true })}</span>
                  </div>
                  <div className="main-body-row-right-col-inner-payment-price-detail-single">
                    <label className='main-body-row-right-col-inner-payment-price-detail-single-text'>{t("Credit Note")}</label>
                    <span className='main-body-row-right-col-inner-payment-price-detail-single-price'>{toFormatPrice(debitedamount?.credit_notes ? debitedamount?.credit_notes : 0, { addSymbol: true })}</span>
                  </div>
                </div>}
            </div>}

          </div>


        </div>
        <div className="main-body-row-right-col-inner-footer">
          {!toggleAddressCustomer ?
            <div className="main-body-row-right-col-inner-footer-row">
              {
                (UpdatedCart?.receivestatus === 'receivepay' && UpdatedCart?.sell_info?.status_type === 'lay_by') ||
                  paystatement === 'LayBy' ?
                  <button className={debitedamount?.cash || debitedamount?.bank || debitedamount?.credit_card || debitedamount?.credit_notes ? "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" : "pointer-none btn checkout main-body-row-right-col-inner-footer-row-btn w-100"} data-bs-toggle="modal" data-bs-target="#PayLaybyModal" onClick={() => setLaybyshow(true)}>{t("Layby")}</button> :
                  (installdivtim?.receive?.length || UpdatedCart?.sell_info?.status_type === 'Installment') ?
                    <button className={balancedue === 0 ? "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" : "pointer-none btn checkout main-body-row-right-col-inner-footer-row-btn w-100"} data-bs-toggle="modal" data-bs-target="#PayInstallModal">{t("Installment")}</button>
                    :
                    customer?.id ?
                      parsedObject === null || (Object.keys(parsedObject?.shipping_address)?.length === 0 || Object.keys(parsedObject?.billing_address)?.length === 0) ?
                        <button onClick={() => error(t("Select Your Billing and Shipping Address"))} className={balancedue === 0 ? "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" : "pointer-none btn checkout main-body-row-right-col-inner-footer-row-btn w-100"}>{t("check out")}</button> :
                        UpdatedCart?.ordertype === 'refund_order' ?
                          <button className="btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" data-bs-toggle="modal" data-bs-target="#checkoutModal">{t("check out")}</button> :
                          <button className={balancedue === 0 ? "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" : "pointer-none btn checkout main-body-row-right-col-inner-footer-row-btn w-100"} data-bs-toggle="modal" data-bs-target="#checkoutModal">{t("check out")}</button>
                      : <button onClick={() => error(t("Customer is Requried!!"))} className={balancedue === 0 ? "btn checkout main-body-row-right-col-inner-footer-row-btn linear-bg w-100" : "pointer-none btn checkout main-body-row-right-col-inner-footer-row-btn w-100"}>{t("check out")}</button>
              }
            </div>
            : ""}
          <p className="footer">{t("© 2022. All rights reserved.")}</p>
        </div>
      </div >
      <div className="modal fade common-modal" id="checkoutModal" tabIndex="-1" aria-labelledby="checkoutModalLabel">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <img className='modal-body-img common-modal-exclamation-img' src={base_assets + "images/icons/exclamation-mark-green.png"} alt="" />
              <p className="modal-content-text">{UpdatedCart?.ordertype === 'refund_order' ? t("Do you want to Refund?") : t("Do you want to check out ?")}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn modal-content-no"
                data-bs-dismiss="modal"
              >{t("No")}</button>
              {
                customer?.id ?
                  generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '1' ?
                    <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal">{t("yes")}</button>
                    :
                    <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal" onClick=
                      {order_edit ? editcheckoutorder : checkoutorder}>{t("yes")}</button>
                  : <button type="button" className="btn modal-content-yes" onClick={() => error(t("Customer is Requried!!"))}>{t("yes")}</button>
              }
            </div>
          </div>
        </div>
      </div >
      <div className={generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '0' && showorder === true ? "modal fade common-modal pin-modal show" : "modal fade common-modal pin-modal "} data-bs-backdrop="false" id="pinModal" tabIndex="-1" aria-labelledby="pinModalLabel">
        {showorder ?
          paystatement === "LayBy" || checkoutdetails?.order_type === "lay_by" ? <Savelaybymodal checkoutdetails={checkoutdetails} /> :
            installdivtim.receive.length ? <Saveinstallmentmodal checkoutdetails={checkoutdetails} installementdata={installementdata} /> :
              <div className="modal common-modal order-successful-modal d-block" id="orderSuccessfulModal">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <img className='order-successful-modal-img' src={base_assets + "images/pos/icons/check-success.png"} alt="" />
                      <h5 className='order-successful-modal-heading'>{t("Order Successful")}</h5>
                      <table className='order-successful-modal-table'>
                        <tbody>
                          <tr>
                            <td>{t("Order Summary")}</td>
                            <td>{checkoutdetails?.count} {t("Item")}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td> {t("Grand Total")}</td>
                            <td><span>{toFormatPrice(parseFloat(checkoutdetails?.totalAmt), { addSymbol: true })}</span></td>
                          </tr>

                          <tr>
                            <td>{t("Deposit")} ({UpdatedCart?.sell_info?.summary_order?.deposit}%)</td>
                            <td><span>{toFormatPrice(checkoutdetails?.deposit_amount, { addSymbol: true })} </span></td>
                          </tr>
                          <tr className='danger-text'>
                            <td className="fw-normal">{t("Outstanding Balance")}</td>
                            <td className="fw-normal"><span>{toFormatPrice(checkoutdetails?.outst_bal, { addSymbol: true })}</span></td>
                          </tr>
                        </tfoot>
                      </table>
                      <div className='order-successful-modal-icons'>
                        {checkoutdetails?.url ?
                          <a className={printparmission ? 'btn-style' : "d-none"} href={checkoutdetails?.url} target={'_blank'} rel="noreferrer">
                            <img src={base_assets + "images/pos/icons/g-printer.png"} alt="" />
                          </a> : ""}
                        <button className='btn-style' data-bs-toggle="modal" data-bs-target="#emailmodal">
                          <img src={base_assets + "images/pos/icons/g-msg.png"} alt="" />
                        </button>
                        {/* <button className='btn-style'>
                          <img src={base_assets + "images/pos/icons/g-upload.png"} alt="" />
                        </button> */}
                      </div>
                      <Link to="/app/pos/menu" className="btn linear-bg order-successful-modal-btn">{t("BACK TO POS")}</Link>
                    </div>
                  </div>
                </div>
              </div>
          : generalsettings?.data?.generalsetup?.Users_to_enter_PIN_for_every_sale === '1' ?
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-style close" data-bs-dismiss="modal" onClick={() => setOtpnumber([])}>
                  <img src={base_assets + "images/pos/icons/close-green.png"} alt="" />
                </button>
                <div className="modal-body">
                  <h5 className='pin-modal-heading'>{t("Enter PIN")}</h5>
                  <div className={'pin-modal-password active-' + otpnumber?.length + ''}>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                    <span className='pin-modal-password-inner'>
                      <span className='pin-modal-password-inner-dot'></span>
                    </span>
                  </div>
                  {/* <button className='pin-modal-forgot btn-style'>{t("Forgot PIN")}</button> */}
                  <div className='pin-modal-keys'>
                    <div className='pin-modal-keys-row'>
                      {[...new Array(10)].map((each, index) => {
                        return (
                          otpnumber?.length < 6 ?
                            <button className='btn-style pin-modal-keys-row-single' onClick={() => { setOtpnumber([...otpnumber, (index === 9 ? 0 : index + 1)]) }} key={index}>{index === 9 ? 0 : index + 1}</button>
                            :
                            <button className='btn-style pin-modal-keys-row-single' key={index}>{index === 9 ? 0 : index + 1}</button>
                        );
                      })}
                    </div>
                    <button className='btn-style clear' onClick={() => setOtpnumber(otpnumber.slice(0, -1))}>
                      <img src={base_assets + "images/pos/icons/clear-btn.png"} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            : ''
        }
      </div>
      {
        loader ?
          <div className="dot-loader-wrap">
            <div className="dot-loader">
              <div className='dot-loader-dot dot1'></div>
              <div className='dot-loader-dot dot2'></div>
              <div className='dot-loader-dot dot3'></div>
              <div className='dot-loader-dot dot4'></div>
              <div className='dot-loader-dot dot5'></div>
              <div className='dot-loader-dot dot6'></div>
              <div className='dot-loader-dot dot7'></div>
              <div className='dot-loader-dot dot8'></div>
            </div>
          </div>
          : ''
      }
      <div className='confirmPopupHandler'>
        <CustomerInformation
          cusid={cusid}
          setCusid={setCusid} />
      </div>
      <div className="modal fade installment-modal common-modal" id="installmentModal" tabIndex="-1" aria-labelledby="installmentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{t("Installment")}</h5>
              <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setinstallcof(false)}>
                <img className="date-img" src={base_assets + "images/pos/icons/close-green.png"} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className='installment-modal-grand-total installment-modal-price-area'>
                <span>{t("Grand Total")}</span>
                <span>{toFormatPrice(UpdatedCart?.totalamount, { addSymbol: true })}</span>
              </div>
              <div className='installment-modal-select-row d-flex justify-content-between'>
                <div className='d-flex align-items-center'>{t("Installment")}
                  <div className="placeholder-up-input col">
                    <Select
                      className="Select"
                      placeholder={t("Installment")}
                      options={installdivide}
                      value={installdivtim?.installment}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      classNamePrefix="common-select"
                      onChange={(e) => setinstalldivtim(existing => ({ ...existing, installment: { label: e?.label, value: e?.value } }))}
                      getOptionLabel={(e) => <div className=''>{e.label}</div>}
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div className='d-flex align-items-center'>Term
                  <div className="placeholder-up-input col ">
                    <Select
                      className="Select"
                      placeholder="Term"
                      value={installdivtim?.term}
                      options={installtime}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      classNamePrefix="common-select"
                      onChange={(e) => setinstalldivtim(existing => ({ ...existing, term: { label: e?.label, value: e?.value } }))}
                      getOptionLabel={(e) => <div className=''>{e.label}</div>}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
              <div className='installment-modal-detail'>
                <table className='w-100 text-nowrap'>
                  <tbody>
                    <>
                      {installementdata?.length > 0 && installementdata?.map((ins, i) => (
                        <tr
                          className={
                            installdivtim?.receive?.filter(obj => obj.id === 'inst_' + i)?.length
                              ? 'active'
                              : ins?.paid === 0
                                ? ''
                                : 'active'
                          }
                          onClick={() => {
                            if (ins?.paid === 0) {
                              if (installdivtim?.receive.filter(obj => obj.id === 'inst_' + i)?.length) {
                                removeidsoninstrecieve(i);
                              } else {
                                setinstalldivtim(existing => ({
                                  ...existing,
                                  receive: [...existing?.receive, { 'amount': ins?.amount, 'id': ins?.id, 'paid': 1, 'date': ins?.date, 'formatted_date': ins?.formatted_date, "key": i + 1 }],
                                }));
                              }
                            }
                          }}
                          key={i}
                        >
                          <td>
                            {ins?.paid === 0 ? (
                              <svg className='installment-modal-detail-tick' width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.99844 20.0984C12.5445 20.0984 14.9863 19.087 16.7867 17.2867C18.587 15.4863 19.5984 13.0445 19.5984 10.4984C19.5984 7.95236 18.587 5.51056 16.7867 3.71021C14.9863 1.90986 12.5445 0.898438 9.99844 0.898438C7.45236 0.898438 5.01056 1.90986 3.21021 3.71021C1.40986 5.51056 0.398438 7.95236 0.398438 10.4984C0.398438 13.0445 1.40986 15.4863 3.21021 17.2867C5.01056 19.087 7.45236 20.0984 9.99844 20.0984ZM14.4468 8.94684C14.6654 8.72051 14.7864 8.41739 14.7836 8.10276C14.7809 7.78812 14.6547 7.48715 14.4322 7.26466C14.2097 7.04217 13.9088 6.91596 13.5941 6.91323C13.2795 6.91049 12.9764 7.03145 12.75 7.25004L8.79844 11.2016L7.24684 9.65004C7.02051 9.43145 6.71739 9.3105 6.40276 9.31323C6.08812 9.31596 5.78715 9.44217 5.56466 9.66466C5.34217 9.88715 5.21596 10.1881 5.21323 10.5028C5.21049 10.8174 5.33145 11.1205 5.55004 11.3468L7.95004 13.7468C8.17507 13.9718 8.48024 14.0982 8.79844 14.0982C9.11664 14.0982 9.4218 13.9718 9.64684 13.7468L14.4468 8.94684Z" fill="#D9D9D9" />
                              </svg>
                            ) : (
                              <div className='installment-modal-detail-paid'>{t("PAID")}</div>
                            )}
                          </td>
                          <td className='installment-modal-detail-s-no'>{i + 1}</td>
                          <td className='w-100'>{ins?.date}</td>
                          <td className='ms-auto text-end'>{toFormatPrice(ins?.amount, { addSymbol: true })}</td>
                        </tr>
                      ))}

                    </>
                  </tbody>
                </table>
              </div>
              <div className='installment-modal-price-area'>
                <span>{t("Receive")}</span>
                <span>{toFormatPrice(installdivtim?.receive?.reduce(
                  (interation, val) => (interation = interation + parseFloat(val.amount)),
                  0
                ), { addSymbol: true })}</span>
              </div>
              <div className='installment-modal-price-area delivered-text'>
                <span>{t("Outstanding Balance")} </span>
                <span>{toFormatPrice((balancedue), { addSymbol: true })}</span>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="com-btn com-btn-outline" data-bs-dismiss="modal" onClick={() => setinstallcof(false)}>Cancel</button>
              <button type="button" data-bs-dismiss="modal" className="com-btn" onClick={() => {
                setBalancedue(installdivtim?.receive?.reduce(
                  (interation, val) => (interation = interation + parseFloat(val.amount)),
                  0
                )); setinstallcof(true)
              }}>{t("Confirm")}</button>
            </div>
          </div>
        </div>
      </div>
      <EmailModal type={UpdatedCart?.sell_info?.status_type} email={checkoutdetails?.email} id={checkoutdetails?.id} setShoworder={setShoworder} />
      <PaymentLayby balancedue={balancedue} debitedamount={debitedamount} Grand_Total={greandtotal ? greandtotal : UpdatedCart?.totalamount} setTransactionlaybyDate={setTransactionlaybyDate} TransactionlaybyDate={TransactionlaybyDate} setPay_layby_data={setPay_layby_data} pay_layby_data={pay_layby_data}
        laybyshow={laybyshow} laybylist={laybylist} />
      < PaymentInstall installment={installdivtim?.receive} Grand_Total={UpdatedCart?.totalamount} installdivtim={installdivtim} installementdata={installementdata} />

    </>
  )

}

export default CheckoutPayment;