import React, { useState, useEffect } from "react";
import "../master.scss"
import { _Api, graphqlPost } from "../../api/_call";
import Select from "react-select";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const EditItemArea = (props) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { setEditItem, showedit, shape, activeshow, dataid, type, setShowedit, create, setCreate, setDataid } = props
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [description, setDescription] = useState()
  const [mixmatch, setMixMatch] = useState(0)
  const [active, setActive] = useState(true)
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [shapegrp, setShapegrp] = useState()
  const [shapearrayList, setShapearrayList] = useState([])
  const edititemarea = async () => {
    if (!name) {
      error(t("Name is required."))
      return
    }
    if (!code) {
      error(t("Code is required."))
      return
    } else {
      let checkspace = code.includes(' ');
      if (checkspace) {
        error(t('Please remove space in code'));
        return;
      }
    }
    setShowedit(false)
    let GetloadingID = loading();
    const postdata = {
      "id": dataid ? dataid : "",
      "type": type ? type : "",
      "name": name ? name : "",
      "code": code ? code : "",
      "Status": active ? "1" : "0",
      "mixMatch": mixmatch ? mixmatch : 0,
      "edit": dataid ? true : false,
      "image": file ? file : ""
    }
    let res = await _Api(postdata, `api/v1/App/master/itemMasteredit`)
    if (res?.code === 200) {
      setEditItem(false)
      setShowedit(true)
      Updateloading(t, GetloadingID, (res?.message));
      setCreate(false)
      setDataid("")
    } else {
      Updateloading(t, GetloadingID, (res.errors), 'error');
    }
  }
  const viewmaster = async () => {
    if (create) {
      setFile("")
      setName("")
      setCode("")
      setMixMatch("")
      setActive(true)
      setDescription("")
    } else if (dataid && type) {
      try {
        const postdata = {
          type: type,
          id: dataid
        }
        const res = await _Api(postdata, `api/v1/App/master/itemMasterViewById`)
        if (res?.code === 200) {
          setFile(res?.data?.logo)
          setName(res?.data?.name)
          setCode(res?.data?.code)
          setMixMatch(res?.data?.mixMatch)
          if (res?.data?.Status === "1") {
            setActive(true)
          } else {
            setActive(false)
          }
          setDescription(res?.data?.description)
        }
      } catch (err) {
        console.log(err, "err")
      }
    }


  }
  const onImageChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`);
        if (resfront.code === 200) {
          setFile(resfront?.data?.images?.url);
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (type && (dataid || create)) {
      viewmaster();
    }
    // eslint-disable-next-line
  }, [dataid, type, create]);

  const getShapesList = async () => {
    try {
      const postdata = `{
        shapes {
            id
            name 
        }
       }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let shapearray = [];
      if (responce?.data?.shapes) {
        responce.data?.shapes?.forEach((result) => {
          shapearray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      };
      setShapearrayList(shapearray);

    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getShapesList();
  }, []);
  return (
    <>
      <div className="edit-item-area bg-white">
        <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
          <div className="me-auto">
            <label className="d-block">{t("Name")}: {name ? name : ""}</label>
            <label className="d-block">{t("Code")}: {code ? code : ""}</label>
          </div>
          <button className="square-btn square-btn-white btn-close focus-none" onClick={() => {
            setEditItem(false);
            setDataid("");
            setCreate(false);
          }}></button>
          <button className="square-btn " onClick={() => edititemarea()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
        </div>
        <div className="edit-item-area-body">
          <div className="row mx-0">
            <div className="col-md-3 ps-0">
              <div className="edit-item-area-body-img p-2 d-flex align-items-center justify-content-center">
                <img className="position-absolute" src={base_assets + 'images/icons/add-img-circle.png'} alt="" />
                <label className=" d-block h-100 w-100 cursor-pointer" style={{ zIndex: "1" }} htmlFor="itemImgChoose">
                  <img className={file ? "w-100 h-100 bg-white object-fit-cover" : "d-none"} src={file ? file : base_assets + 'images/icons/add-img-circle.png'} alt="" />

                </label>
                <input type="file" id="itemImgChoose" className="d-none"
                  onChange={onImageChange} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                <input className="v3-input-group-input w-100"
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Code")} <sup>*</sup></label>
                <input className="v3-input-group-input w-100"
                  value={code || ''}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>

              {showedit ?
                <div className="v3-input-group mb-0">
                  <label className="v3-input-group-label">{t("Description")}</label>
                  <textarea rows="3" className="v3-input-group-input w-100 h-auto"
                    value={description || ''}
                    onChange={(e) => setDescription(e.target.value)} />
                </div> : ""}
              {shape ?
                <div className="v3-input-group mb-0">
                  <label className="v3-input-group-label">{t("Shape")}</label>
                  <Select
                    className="select-set select  p-0"
                    placeholder=""
                    options={shapearrayList}
                    value={shapegrp}
                    onChange={(e) => setShapegrp(e)}
                    classNamePrefix="common-select"
                    isSearchable={false}
                  />
                </div> : ""}
            </div>
            {
              activeshow ?
                <div className="col-md-3 pe-0 text-end">
                  <div className="form-check form-switch ps-0">
                    <label className="form-check-label" htmlFor="mixmatch">{t("Active")}</label>
                    <input className="form-check-input float-none ms-2" type="checkbox" id="mixmatch"
                      checked={active ? true : false}
                      onChange={(e) => setActive(e.target.checked)} />
                  </div>
                </div> :
                <div className="col-md-3 pe-0 text-end">
                  <div className="form-check form-switch ps-0">
                    <label className="form-check-label" htmlFor="mixmatch">{t("Mix & Match")}</label>
                    <input className="form-check-input float-none ms-2" type="checkbox" id="mixmatch"
                      checked={mixmatch ? true : ""}
                      onChange={(e) => setMixMatch(e.target.checked ? 1 : 0)} />
                  </div>
                  <div className="form-check form-switch ps-0">
                    <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                    <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                      checked={active ? true : false}
                      onChange={(e) => setActive(e.target.checked)} />
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default EditItemArea;