import React from "react";
import { store } from "../../../source/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
const TransAndDeliverDate = props => {
  const { t } = useTranslation();
  var state = store.getState();
  var OrgSettings = state?.app?.organisation_settings;

  const { TransactionDate, DeliveryDate, setTransactionDate, setDeliveryDate } = props;
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;

  const date_format_change = (date_format) => {
    var response;
    if (date_format === "DD/MM/YYYY") {
      response = "dd/MM/yyyy";
    } else if (date_format === "YYYY/MM/DD") {
      response = 'yyyy/MM/dd';
    } else if (date_format === "MM/DD/YYYY") {
      response = 'MM/dd/yyyy';
    } else if (date_format === "MM/YYYY/DD") {
      response = 'MM/yyyy/dd';
    }
    return response;
  }
  return (
    <>
      <div className="placeholder-up-input placeholder-up-input-date">
        <DatePicker
          className="placeholder-up-input-field"
          selected={TransactionDate}
          onChange={setTransactionDate}
          name="TransactionDate"
          dateFormat={date_format_change(OrgSettings?.date_format)}
          placeholderText={'DD/MM/YYYY'}
          autoComplete='off'
        />
        <img
          className="placeholder-up-input-field-calender"
          src={base_url + "images/icons/calendar-green.svg"}
          alt=""
        />
        <label className="placeholder-up-input-label">
          {t("Transaction Date")}
        </label>
      </div>
      <div className="placeholder-up-input placeholder-up-input-date">
        <DatePicker
          className="placeholder-up-input-field"
          selected={DeliveryDate}
          onChange={setDeliveryDate}
          name="DeliveryDate"
          dateFormat={date_format_change(OrgSettings?.date_format)}
          placeholderText={'DD/MM/YYYY'}
          autoComplete='off'
        />
        <img
          className="placeholder-up-input-field-calender"
          src={base_url + "images/icons/calendar-green.svg"}
          alt=""
        />
        <label className="placeholder-up-input-label">
          {t("Delivery Date")}
        </label>
      </div>
    </>
  )
};

export default TransAndDeliverDate;
