import React, { useEffect, useState, useRef } from "react";
import CrossIcon from "../assets/icons/CrossIcon";
import MinusIcon from "../assets/icons/MinusIcon";
import PlusIcon from "../assets/icons/PlusIcon";
import DeleteIcon from "../assets/images/deleteIcon.svg";
import { _Api } from "../../common/api";
import CartBag from "../assets/images/cartBag.png"
import DeleteIconGrey from "../assets/images/deleteicon-grey.png";
import { Link } from "react-router-dom";
import Spinner from "../../common/Spinner";
import { useTranslation } from "react-i18next";
import { toFormatPrice } from '../../common/Formateprice';
const AddToCart = (props) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const { isopencart, setisopencart } = props;
  const [cartitems, setCartitems] = useState([]);
  const [checkoutItems, setCheckoutItems] = useState([]);
  const [, setCurrency] = useState();
  const [loading, setLoading] = useState(false)
  let localitem = localStorage.getItem('CartItems') ? JSON.parse(localStorage.getItem('CartItems')) : [];
  
  const getcartItems = async () => {
    setLoading(true)
    let inputs = document.querySelectorAll(".itemscheckbox");
    if (document.getElementById("selectallcheck")) {
      document.getElementById("selectallcheck").checked = false;
    }
    if (inputs?.length) {
      for (let i = 0; i < inputs?.length; i++) {
        inputs[i].checked = false;
      }
    }
    setCheckoutItems([])
    let cartids = [];
    if (localitem?.length) { localitem?.map((result) => cartids.push(result?.id)) }
    try {
      let postdata = {
        cart_ids: cartids
      }
      let res = await _Api(postdata, `order/getCartDetails`)
      if (res?.statusCode === 200) {
        if (res?.data) {
          let array = [...res?.data];
          array?.forEach((result) => {
            result.qty = parseInt(localitem.filter(e => e?.id === result?.id)[0]['qty']);
          })
          setCartitems(array);
          setCurrency(res?.CURRENCY);
          if (res?.deleted_ids?.length) {
            localitem.forEach((result) => {
              if (!res?.deleted_ids.includes(result?.id)) {
                let data = []
                data.push(result)
                localStorage.setItem('CartItems', JSON.stringify(data));
              }
            })
          }
        }
        setLoading(false)
      }

    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const changeqty = (label, id) => {
    let array = [...cartitems];
    let updatedLocalItems = [...localitem];

    array = array.map(e => {
      if (e.id === id) {
        if (label === 'minus' && e.qty > 1) {
          e.qty -= 1;
        } else if (label !== 'minus') {
          e.qty += 1;
        }
      }
      return e;
    });

    updatedLocalItems = updatedLocalItems.map(e => {
      if (e.id === id) {
        if (label === 'minus' && e.qty > 1) {
          e.qty -= 1;
        } else if (label !== 'minus') {
          e.qty += 1;
        }
      }
      return e;
    });

    localStorage.setItem('CartItems', JSON.stringify(updatedLocalItems));
    setCartitems(array);
  };

  const selectAllcheck = (e) => {
    let inputs = document.querySelectorAll(".itemscheckbox");
    if (e.target.checked) {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].checked = true;
      }
      let array = [...cartitems];
      let cartitemsarray = [];
      array.map((result) => cartitemsarray.push({ id: result?.id, qty: result?.qty, price: result?.UnFormatPrice }));
      setCheckoutItems(cartitemsarray);
    } else {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
      setCheckoutItems([]);
    }
  }

  const inputcheck = (e, id, qty, price) => {
    let checkoutarray = [...checkoutItems];
    if (e.target.checked) {
      checkoutarray.push({ 'id': id, 'qty': qty, 'price': price });
      if (document.querySelectorAll(".itemscheckbox:checked").length === document.querySelectorAll(".itemscheckbox").length) {
        document.getElementById("selectallcheck").checked = true;
      }
    } else {
      checkoutarray = checkoutarray.filter(e => e?.id !== id);
      document.getElementById("selectallcheck").checked = false;
    }
    setCheckoutItems(checkoutarray);

  }

  const deleteItems = () => {
    let checkoutarray = [...checkoutItems];
    checkoutarray.forEach((result) => {
      localitem = localitem.filter(e => e?.id !== result?.id);
    })
    localStorage.setItem('CartItems', JSON.stringify(localitem));
    setCheckoutItems([]);
    getcartItems();

  }

  useEffect(() => {
    if (localStorage.getItem('CartItems') && isopencart) {
      getcartItems();
    }
    // eslint-disable-next-line
  }, [isopencart])
  const hidemodal = () => {
    if (modalRef.current) {
      modalRef.current.hide();
    }
  }
  useEffect(() => {
    modalRef.current = new window.bootstrap.Modal(document.getElementById('AddToCartModal'));

  }, []);
  return (
    <div className="modal right cart-modalRight fade" id="AddToCartModal" role="dialog">
      <div className="modal-dialog modal-dialog-right">
        <div className="modal-content">
          <div className="modal-header cart-modalRight-headerBox">

            <button type="button" className="cart-modalRight-headerBox-crossbtn" onClick={() => setisopencart(false)} data-bs-dismiss="modal" aria-label="Close" >
              <CrossIcon width={18} height={18} />
            </button>
            <h4 className="cart-modalRight-headerBox-modalTitle">{t("Shopping Cart")}</h4>
            <span className="cart-modalRight-headerBox-cartBag"><img src={CartBag} alt="" /></span>
          </div>
          {loading ? (<div className="h-100 d-flex justify-content-center align-items-center">

            <Spinner />

          </div>) : (
            <>
              {cartitems?.length ? (<>
                <div className="modal-body">
                  <div className="modal-header d-block">
                    <div className="cart-modalRight-bodyBox-productCount">{t("My Cart")} ({cartitems?.length} {t("Items")})</div>
                    <div className="cart-modalRight-bodyBox-selectAllBox">
                      <div className="com-ecomCheckBox">
                        <input type="checkbox" onChange={(e) => selectAllcheck(e)} className=" com-ecomCheckBox-inputcheck me-2 cursor-pointer" id="selectallcheck" />
                        <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor="selectallcheck">{t("Select All")}</label>
                      </div>
                      <div className="cart-modalRight-bodyBox-selectAllBox-DeleteBox">
                        {!checkoutItems?.length ?
                          <span className="cart-modalRight-bodyBox-selectAllBox-DeleteBox-iconBox"><img src={DeleteIconGrey} alt="" /></span>
                          : <><span onClick={deleteItems} className="cart-modalRight-bodyBox-selectAllBox-DeleteBox-iconBox"><img src={DeleteIcon} alt="" /></span>
                            <span className="deleteSelect-Show">{checkoutItems?.length ? checkoutItems?.length + t("Selected") : ''}</span></>}
                      </div>
                    </div>
                  </div>
                  <div className="cart-modalRight-bodyBox">
                    {/* cart items */}

                    {cartitems.map((result, key) => {

                      return (

                        <div key={key} className=" com-ecomCartProductContShow">
                          <div className="com-ecomCheckBox">
                            <input type="checkbox" onChange={(e) => inputcheck(e, result?.id, result?.qty, result?.UnFormatPrice)} className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer" id={result?.id} />
                            <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor={result?.id}></label>
                          </div>
                          <div className="com-ecomCartProductContShow-imageBox">
                            <span className="product-img-span">
                              <img src={result?.main_image} alt="" />
                            </span>
                          </div>
                          <div className=" com-ecomCartProductContShow-detailsBox">
                            <div className="com-ecomCartProductContShow-detailsBox-firstBox">
                              <p className="com-ecomCartProductContShow-detailsBox-firstBox-productName">
                                <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                  state={{ id: result?.motherProduct?._id }}
                                  onClick={() => modalRef.current.hide()} className="com-ecomCartProductContShow-detailsBox-firstBox-productName text-decoration-none">{result?.name}</Link>
                                <span className='com-ecomCartProductContShow-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.price}</span>
                              </p>
                              <div className="com-ecomCartProductContShow-detailsBox-firstBox-content">
                                <p className="contentPara">{result?.SKU}</p>
                                <p className="contentPara">{result?.Metal}</p>
                                <p className="contentPara">
                                  <span>{t("Size")}: {result?.Size}</span>
                                </p>
                              </div>
                            </div>
                            <div className='com-ecomCartProductContShow-detailsBox-secondCountBox'>
                              <div className='setCountShow'>
                                <button onClick={() => changeqty('minus', result?.id)} className='setCountShow-minusIcon'><MinusIcon width={14} height={14} color={'#1D1D1F'} /></button><span className='setCountShow-count'>{result?.qty}</span><button onClick={() => changeqty('plus', result?.id)} className='setCountShow-plusIcon'><PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                </button>
                              </div>
                              <div className='com-ecomCartProductContShow-detailsBox-secondCountBox-priceShow desktop-productPriceShow'>{result?.price}</div>
                            </div>
                          </div>

                        </div>
                      )
                    })}
                  </div>

                </div>
                <div className="modal-footer cart-modalRight-footerBox">
                  <div className="cart-modalRight-footerBox-grandTotalBox w-100 d-flex justify-content-between">
                    <p>{t("Grand Total")}</p>
                    <span>{toFormatPrice(checkoutItems?.length ? checkoutItems?.reduce(
                      (a, v) => (a = a + (parseInt(v.qty) * (v.price))),
                      0
                    ) : cartitems?.reduce(
                      (a, v) => (a = a + (parseInt(v.qty) * (v.UnFormatPrice))),
                      0
                    ), { addSymbol: true })}</span>
                  </div>
                  {checkoutItems?.length ?
                    <Link to="/shipping" state={{ checkoutItems: checkoutItems }} className="com-ecomSendBtn w-100 text-center text-decoration-none" onClick={() => hidemodal()}>
                      {t("Checkout")}
                    </Link> :
                    <button disabled={checkoutItems?.length ? false : true} className=' com-DisableecomSendBtn w-100'>
                      {t("Checkout")}
                    </button>}

                  {checkoutItems?.length ?
                    <Link to="/appointment" state={{ checkoutItems: checkoutItems }}
                      className="w-100 text-center text-decoration-none"
                      onClick={() => modalRef.current.hide()}>
                      <button disabled={checkoutItems?.length ? false : true} className='com-ecomCancelBtn w-100 bg-white'>{t("Appointment")}</button>
                    </Link> :
                    <button disabled={checkoutItems?.length ? false : true} className=' com-DisableecomSendBtn w-100'>{t("Appointment")}</button>}
                </div>
              </>)
                : (<div className="empltycartBoc">
                  <h1 className="empltycartBoc-head" >{t("Your Shopping Cart is empty")}</h1>
                  <p className="empltycartBoc-para">{("Still don't have something you want? Try checking out the new products in case you have something you like to add to your cart")}</p>
                  <Link to="/" onClick={() => hidemodal()} className="com-ecomCancelBtn text-decoration-none">{t("Continue Shopping")}</Link>
                </div>)
              }

            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default AddToCart;

