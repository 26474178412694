import React, { useState, useEffect, useCallback } from 'react'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const AllLocation = (props) => {
  const { setDashboardhideshow, setDefaultset } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const [locationlist, setLocationlist] = useState([])
  const [locationliststore, setLocationliststore] = useState([])
  const [showAll, setShowAll] = useState(false);
  const [locationtype, setLocationtype] = useState("Store")
  const { t } = useTranslation();
  const getallLocation = useCallback(async () => {
    try {
      let postdata = {
        type: locationtype
      }
      let res = await _Api(postdata, `api/v1/dashboard/locationStatics`)
      if (res?.code === 200) {
        if (postdata?.type === "Store") {
          setLocationliststore(res?.statics)
          let array = []
          if (res?.statics?.data?.length) {
            res?.statics?.data.forEach((result) => {
              result?.countryWiseStoreSales.forEach((element) => {
                array.push(element)
              })
            })
          }
          setLocationliststore(array)
          setLocationlist([])
        } else if (postdata?.type === "Country") {
          setLocationlist(res?.statics)
          setLocationliststore([])
        }
      }
    } catch (err) {
      console.log(err, "err")
    }
  }, [locationtype]);
  useEffect(() => {
    getallLocation()
  }, [locationtype, getallLocation])
  let type = ["Store", "Country"]
  const handleShowMore = () => {
    setShowAll(true);
  };
  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("All Locations")}
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t(`${locationtype}`)}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              {type?.length ? type.map((result, key) => {
                return (
                  <li onClick={() => setLocationtype(result)} key={key}>
                    <span className="dropdown-item">{t(`${result}`)}</span>
                  </li>
                )
              }) : ""}

            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li
                onClick={() => {
                  setDashboardhideshow(prevState => ({
                    ...prevState,
                    AllLocations: 0
                  }));
                  setDefaultset(true)
                }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area dashboard-area-cards-single-chart-area-location py-3">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <i className="fa fa-globe fs-3" aria-hidden="true"></i>
                </div>
                <div className="col-md-9">
                  <span>{t("Countries")}</span>{' '}
                  <span className="d-block fs-3 fw-semibold success-color">
                    {locationlist?.location_country_count ? locationlist?.location_country_count :
                      locationliststore?.location_country_count}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <i className="fa fa-map-marker fs-3" aria-hidden="true"></i>
                </div>
                <div className="col-md-9">
                  <span>{t("Store")}</span>{' '}
                  <span className="d-block fs-3 fw-semibold success-color">
                    {locationlist?.store_count ? locationlist?.store_count : locationliststore?.store_count}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <table className="w-100">
            <tbody>
              {locationlist?.data?.length ? (
                locationlist?.data.map((result, key) => (
                  <tr key={key}>
                    <td className="py-2">
                      <img
                        className="me-2"
                        style={{ width: '20px' }}
                        src={base_assets + 'images/flags/' + result?.flag}
                        alt=""
                      />
                      {t(`${result?.name}`)}
                    </td>
                    <td className="text-end py-2">{result?.storeCountry_percentage}</td>
                  </tr>
                ))
              ) : (
                locationliststore?.length ? (
                  locationliststore.slice(0, showAll ? locationliststore.length : 4).map((element, index) => (
                    <tr key={index}>
                      <td className="py-2">
                        <img
                          className="me-2"
                          style={{ width: '20px' }}
                          src={base_assets + 'images/flags/' + element?.flag}
                          alt=""
                        />
                        {element?.store_name}
                      </td>
                      <td className="text-end py-2">{element?.saleAmount}</td>
                    </tr>
                  ))
                ) : (
                  null
                )
              )}
            </tbody>
          </table>
          {!showAll && locationliststore?.length && (
            <div className='text-center cursor-pointer' onClick={handleShowMore}>{t("Show more")}</div>
          )}
        </div >
      </div>
    </div>
  )
}
export default AllLocation
