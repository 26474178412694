import React from 'react'

export default function TableLoader() {
  return (
    <>
      <div id="table_loader" className="mt-4">
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  )
}


// export default function TableLoader(props) {
//   let { colummnlength } = props
//   let Array = []
//   if (colummnlength) {
//     for (let i = 0; i < colummnlength; i++) {
//       Array.push(i)
//     }
//   }

//   return (
//     <tr>
//       {Array?.length ? Array.map((result, key) => {
//         return (
//           <td key={key}></td>
//         )
//       }) : ""}
//       <td>
//         <div id="table_loader" className="mt-4">
//           <div className="dots">
//             <span></span>
//             <span></span>
//             <span></span>
//           </div>
//         </div>
//       </td>
//     </tr>

//   )
// }
