import React, { useRef, useState, useEffect, useCallback } from 'react'
import Select from 'react-select'
import { Link, useLocation } from 'react-router-dom'
import RightArrow from '../../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import { _Api } from '../../api/_call'
import { store } from '../../../source/index'
import { loading, Updateloading } from '../../common/helpers/toastify'
const VoucherTypeDetail = () => {
  const dataFetched = useRef();
  const { t } = useTranslation()
  const { state } = useLocation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [voucherdata, setVoucherdata] = useState([])
  const [allvoucherdata, setAllvoucherdata] = useState()
  const Locationdetails = useCallback(async () => {
    try {
      let postdata = {
        "location_id": state?.location_id,
        "type": state?.type
      }
      let res = await _Api(postdata, `api/v1/App/quicksetup/get-vouchertype`)
      if (res?.code === 200) {
        if (res?.data?.inventory?.length) {
          let finaldata = [...res?.data?.inventory]
          finaldata.forEach(item => {
            if (item.Configuration.length === 0) {
              item.Configuration.push({
                conf_date: new Date()
              });
            }
            if (item?.templatOps?.length) {
              const printTemplateUrl = item?.print_template;
              const fileName = printTemplateUrl.split("/").pop();
              let templatearr = item?.templatOps.filter((item) => item.label === fileName)
              item.print_template = templatearr[0]
            }
          });
          setVoucherdata(finaldata);
        } else {
          let finaldata = [...res?.data?.POS]
          finaldata.forEach(item => {
            if (item.Configuration.length === 0) {
              item.Configuration.push({
                conf_date: new Date()
              });
            }
            if (item?.templatOps?.length) {
              const printTemplateUrl = item?.print_template;
              const fileName = printTemplateUrl.split("/").pop();
              let templatearr = item?.templatOps.filter((item) => item.label === fileName)
              item.print_template = templatearr[0]
            }

          });
          setVoucherdata(finaldata);
        }
        setAllvoucherdata(res?.data)
        dataFetched.current = false;
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [state]);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    Locationdetails()
  }, [Locationdetails])
  var date_format = store.getState()
  var OrgSettings = date_format?.app?.organisation_settings
  const date_format_change = (date_format) => {
    var response
    if (date_format === 'DD/MM/YYYY') {
      response = 'dd/MM/yyyy'
    } else if (date_format === 'YYYY/MM/DD') {
      response = 'yyyy/MM/dd'
    } else if (date_format === 'MM/DD/YYYY') {
      response = 'MM/dd/yyyy'
    } else if (date_format === 'MM/YYYY/DD') {
      response = 'MM/yyyy/dd'
    }
    return response
  }
  const Configurationchange = (key, value, keyName) => {
    let array = [...voucherdata]
    if (keyName === "name") {
      array[key].name = value
    } else if (keyName === "conf_date") {
      const dateString = value;
      const dateObject = new Date(dateString);
      const mongodbDateFormat = dateObject.toISOString();
      array[key].Configuration[0].conf_date = mongodbDateFormat
    } else if (keyName === "conf_prefix") {
      array[key].Configuration[0].conf_prefix = value
    } else if (keyName === "conf_startno") {
      array[key].Configuration[0].conf_startno = value
    } else if (keyName === "conf_suffix") {
      array[key].Configuration[0].conf_suffix = value
    } else if (keyName === "Status") {
      array[key].Status = value ? "1" : "0"
    } else if (keyName === "print_template") {
      array[key].print_template = value
    }
    setVoucherdata(array)
  }
  const savevoucher = async () => {
    let array = voucherdata.map(obj => ({ ...obj, location_id: state?.location_id }));
    let finaldata = { voucher_data: [...array] }
    finaldata.voucher_data.forEach((result) => {
      if (result?.Status === "0" && !result?._id) {
        result.Configuration = []
      }
      result.print_template =
        result?.print_template?.value ? result?.print_template?.value : result?.templatOps?.length ? result?.templatOps[0]?.value : ""
      delete result?.templatOps
      let updatetitle = result?.title.replace(/\s/g, '_');
      result.title = updatetitle
      if (result.Configuration?.length) {
        result.Configuration.forEach((element) => {
          if (!element?.conf_updated_no || !element?.conf_prefix || !element?.conf_startno || !element?.conf_startnoconf_suffix) {
            element.conf_updated_no = element.conf_updated_no ? element.conf_updated_no : 0
            element.conf_startno = element.conf_startno ? element.conf_startno : ""
            element.conf_prefix = element.conf_prefix ? element.conf_prefix : ""
            element.conf_startnoconf_suffix = element.conf_startnoconf_suffix ? element.conf_startnoconf_suffix : ""
          }
        })
      }
    })
    let GetloadingID = loading()
    try {
      let postdata = finaldata
      let res = await _Api(postdata, `api/v1/App/quicksetup/save-vouchertype`)
      if (res?.code === 200) {
        Locationdetails()
        Updateloading(t, GetloadingID, (res?.message))
        setVoucherdata([])
      } else {
        Updateloading(t, GetloadingID, res?.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          {state?.locationkey ?
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              <Link
                className="text-decoration-none"
                to="/app/admin/location"
              >
                {t('Location')}
              </Link>
              <RightArrow />
              <Link
                className="text-decoration-none"
                to={`/app/admin/location/detail/${state?.location_id}`}
                state={{ dataid: state?.location_id }}
              >
                {t('Location Details')}
              </Link>
              <RightArrow />
              <span className="">{t('Voucher Type')}</span>
            </h1> :
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              <Link
                className="text-decoration-none"
                to="/app/admin/setup/quickstoresetup"
              >
                {t('Quick Store')}
              </Link>
              <RightArrow />
              <Link
                className="text-decoration-none"
                to="/app/admin/setup/quickstoresetup/vouchertype"
                state={{ location_id: state?.location_id }}
              >
                {t('Voucher Type')}
              </Link>
              <RightArrow />
              <span className="">{t('Voucher Type')}</span>
            </h1>}
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center"
          onClick={() => savevoucher()}>
          <button className="square-btn">
            <img src={base_assets + 'images/icons/white-save.png'} alt="" />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="bg-white p-4 rounded-3">
            <div className="row">
              <div className="col-md-3">
                <h5 className="fw-semibold main-green-text">
                  {state?.type === "inventory" ? t('INVENTORY') : t("POS")}
                </h5>
              </div>
              <div className="col-md-9">
                <h6 className="mb-5 text-end">
                  {t('Locations')}{' -: '}
                  <Link
                    className="main-green-color text-decoration-none"
                  >
                    {allvoucherdata?.locations_name}
                  </Link>
                </h6>
              </div>
            </div>
            <div className="p-2 px-3 rounded-3 box-bg mb-5 fw-semibold">
              {t('Group')}
            </div>



            {voucherdata?.length ?
              voucherdata.map((result, key) => {
                return (
                  <div className="row mb-5" key={key}>
                    <div className="col-md-3">
                      <input
                        className="form-check-input fill-com-check me-3 cursor-pointer"
                        type="checkbox"
                        id={"voucher" + key}
                        checked={result?.Status === "1" ? true : false}
                        onChange={(e) => Configurationchange(key, e.target.checked, "Status")}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor={"voucher" + key}
                      >
                        {t(result?.title)}
                      </label>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-3">
                          <label className="v3-input-group-label">{t('Name')}</label>
                        </div>
                        <div className="col-md-9 v3-input-group">
                          <input
                            type="text"
                            className="v3-input-group-input w-100"
                            value={result?.name}
                            onChange={(e) => Configurationchange(key, e.target.value, "name")}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-3">
                          <label className="v3-input-group-label">
                            {t('Configuration')}
                          </label>
                        </div>
                        <div className="col-md-9">
                          <table className="mb-2 com-custom-table-tag table align-middle w-100">
                            <thead>
                              <tr className="align-middle">
                                <th>{t('Date')}</th>
                                <th className="text-center">{t('Start No.')}</th>
                                <th className="text-center">{t('Prefix')}</th>
                                <th className="text-center">{t('Suffix')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {result?.Configuration?.length ?
                                result?.Configuration.map((value, index) => {
                                  const mongodbDateString = value?.conf_date;
                                  const utcDate = new Date(mongodbDateString);
                                  return (
                                    <tr key={index}>
                                      <td className="v3-input-group mb-0 px-0">
                                        <DatePicker
                                          className="placeholder-up-input-field v3-input-group-input w-100"
                                          selected={utcDate}
                                          onChange={(e) => Configurationchange(key, e, "conf_date")}
                                          name=""
                                          dateFormat={date_format_change(
                                            OrgSettings?.date_format,
                                          )}
                                          placeholderText={'DD/MM/YYYY'}
                                          autoComplete="off"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="border-0 text-center w-100 box-bg"
                                          type="text"
                                          value={value?.conf_startno}
                                          onChange={(e) => Configurationchange(key, e.target.value, "conf_startno")}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="border-0 text-center w-100 box-bg"
                                          type="text"
                                          value={value?.conf_prefix}
                                          onChange={(e) => Configurationchange(key, e.target.value, "conf_prefix")}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="border-0 text-center w-100 box-bg"
                                          type="text"
                                          value={value?.conf_suffix}
                                          onChange={(e) => Configurationchange(key, e.target.value, "conf_suffix")}
                                        />
                                      </td>
                                    </tr>
                                  )
                                }) :
                                 ""}
                            </tbody>
                          </table>
                          
                          <div>
                            {t('Your PO reference no. will show:')} <b>
                              {result?.Configuration[0]?.conf_prefix ? result?.Configuration[0]?.conf_prefix : ""}
                              {result?.Configuration[0]?.conf_startno ? result?.Configuration[0]?.conf_startno : ""}
                              {result?.Configuration[0]?.conf_suffix ? result?.Configuration[0]?.conf_suffix : ""}
                            </b>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <label className="v3-input-group-label">
                            {t('Print Template')}
                          </label>
                        </div>
                        <div className="col-md-9 v3-input-group">
                          <Select
                            className="select-set select w-100 p-0"
                            placeholder=""
                            options={result?.templatOps}
                            value={result?.print_template ? result?.print_template : result?.templatOps[0]}
                            onChange={(e) => Configurationchange(key, e, "print_template")}
                            classNamePrefix="common-select"
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div className="common-select-icon">
                                {e.icon} <span>{e.label}</span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              : ""}

          </div>
        </div>
      </div>
    </div>
  )
}

export default VoucherTypeDetail
