import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Report.scss"
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import SocialModal from "../../components/common/modules/SocialModal/SocialModal";
import TableModal from "../common/Modals/tableColumnPos";
import { Daterangepos } from "../common/Daterangepos";
import { Tablehead } from '../../components/common/modules/Tablehead'
import { useTranslation } from 'react-i18next';
import { pospaymentdetails_head } from '../../components/common/Tablehead'
import { loading, Updateloading } from "../common/helpers/toastify";
import { _Api } from "../common/api/_call"
import { toFormatPrice } from '../common/helpers/function';
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import Select from "react-select";
import * as XLSX from 'xlsx';
const Paymentreportdetails = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [newcolumndata, setnewcolumndata] = useState(pospaymentdetails_head)
    const [shareloader, setShareloader] = useState(false);
    const [socialurl, setSocialurl] = useState();
    const [datefilter, setDatefilter] = useState([]);
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [paymentdata, setPaymentdata] = useState([])
    const [, setfilterby] = useState('');
    const [openingBalance_data, setOpeningBalance_data] = useState()
    const [limit, setLimit] = useState(100)
    const [lowercount, setLowercount] = useState()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const [printparmission, setPrintparmission] = useState(0);
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
    useEffect(() => {
        if (parmissiondatashow?.pos_reports?.View)
            setPrintparmission(parmissiondatashow?.pos_reports?.Print);
    }, [parmissiondatashow]);
    const getsharepaymentreportdetails = async () => {
        setShareloader(true);
        try {
            const postdata = {
                "id": state?.id,
                "type": state?.type,
                "search": "",
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/reports/print/paymentDetails`);

            if (res.code === 200) {
                setSocialurl(res?.link);
                setShareloader(false);
            }
        }
        catch (err) {
            console.log(err, 'err');
        }

    }
    const getprintpaymentdetailsreport = async () => {
        let GetloadingID = loading();
        try {
            const postdata = {
                "id": state?.id,
                "type": state?.type,
                "search": "",
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/reports/print/paymentDetails`);

            if (res.code === 200) {
                setTimeout(() => {
                    const exportLinkElement = document.createElement('a');
                    exportLinkElement.hidden = true;
                    exportLinkElement.download = res?.name ? res?.name : "report.pdf";
                    exportLinkElement.href = res?.link;
                    exportLinkElement.text = "downloading...";
                    exportLinkElement.setAttribute('target', '_blank');
                    document.body.appendChild(exportLinkElement);
                    exportLinkElement.click();
                    exportLinkElement.remove();
                    Updateloading(t, GetloadingID, t("download successfully"));

                }, 50)
                setSocialurl(res?.link);
            } else {
                Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, 'err');
            Updateloading(t, GetloadingID, err, t('error'));
        }

    }
    const callcolumns = async () => {
        try {
            const postdata = {
                name: 'report_paymentdetails_tbl',
            }
            let columnhead = await _Api(
                postdata,
                `api/v1/Inventory/common/getColumnHeads`,
            )
            if (columnhead.code === 200) {
                setnewcolumndata(columnhead?.data?.data)
            }
        } catch (err) {
            console.log(err, 'err')
        }
    }
    useEffect(() => {
        callcolumns()
    }, [])

    const getpaymentdetails = async (firstlimit) => {
        UnSetdataTable("payment_details");
        try {
            let postdata = {
                "id": state?.id,
                "type": state?.type,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : "",
                "limit": firstlimit ? firstlimit : limit,
                "skip": 0,
            }
            let listdata = await _Api(postdata, `api/v1/POS/report/paymentDetails`);
            if (listdata.code === 200) {
                setPaymentdata(listdata?.data);
                setOpeningBalance_data(listdata?.openingBalance_data)
                setTimeout(() => {
                    SetdataTable("payment_details", 0, newcolumndata, [0]);
                }, 50)
                setLowercount(listdata?.lowercount);
            } else {
                SetdataTable("payment_details", 0, newcolumndata, [0]);
            }

        }
        catch (err) {
            console.log(err, 'err')
        }
    }
    useEffect(() => {
        if (datefilter[0] && datefilter[1]) {
            getpaymentdetails();
        }
        // eslint-disable-next-line
    }, [newcolumndata, datefilter]);
    const downloadexclsheet = async () => {
        let header_values = [];
        let bodyarray = [];
        pospaymentdetails_head.map(async (r, k) => {
            header_values.push(r?.lable)
        })
        if (paymentdata.length) {
            paymentdata.forEach((result, key) => {
                bodyarray.push([key + 1, result?.op_date, result?.time, result?.Ref, result?.Type, openingBalance_data?.description, result?.withdraw, result?.deposit, result?.netBalance, result?.channel, result?.user])
            })
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
        const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([fileContent], { type: fileType });
        FileSaver.saveAs(data, "payment_report");

    }
    const selectOptions = [
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 }
    ]
    return (<>

        <div className="main-body">
            <div className="main-body-row">
                <div className="main-body-row-left-col p-0 w-100">
                    <div className="catalog-area bg-white page-menu-block Dailysalereport paymentreportdetails">
                        <div className="Dailysalereport-header paymentreportdetails-header">
                            <div className="Dailysalereport-header-left">
                                <h3 className='common-navigation-header-left-heading Dailysalereport-head paymentreportdetails-header'> <Link to="/app/pos/Paymentreport"> <img className="paymentreportdetails-header-backbtn" src={base_assets + "images/pos/report/backbtn.png"} alt=""></img></Link><Link to="/app/pos/Paymentreport" className='ms-5 gray-mid text-decoration-none Dailysalereport-head-report'>{t("REPORT")}</Link><img className="Dailysalereport-head-arrow mx-3" src={base_assets + "images/pos/report/arrow-right.png"} alt="" /> <span className="Dailysalereport-head-pagename">{t("Payment Report")}</span></h3>
                            </div>
                        </div>
                        <div className="Dailysalereport-content-area">
                            <div className="Dailysalereport-content-area-header">
                                <div className="Dailysalereport-content-area-header-inner">
                                    <span className="paymentreport-heading">{state?.name}</span>
                                </div>
                                <div className="catalog-area-header-area-control-right">
                                    <div className="me-2">
                                        <Select
                                            className="limit-select short mx-3"
                                            options={selectOptions}
                                            value={{ label: limit, value: limit }}
                                            onChange={(e) => { setLimit(e.value); getpaymentdetails(e.value) }}
                                        />
                                    </div>
                                    <div className="date"> <Daterangepos setDatefilter={setDatefilter} /></div>
                                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                                        <button className='btn-style' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={base_assets + 'images/icons/ellipsis-circular.png'} alt="" /></button>
                                        <ul className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li onClick={() => getprintpaymentdetailsreport()} className={printparmission ? "" : "d-noene"}>
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/print-icon.png"} alt="" />
                                                    </div>
                                                    {t("Print")}</Link>
                                            </li>
                                            <li onClick={() => downloadexclsheet()}>
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/export-icon.png"} alt="" />
                                                    </div>
                                                    {t("Export")}</Link>
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target="#socialModal" className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" onClick={() => getsharepaymentreportdetails()}>
                                                <div className="dropdown-menu-img">
                                                    <img src={base_assets + "images/pos/report/share-icon.png"} alt="" />
                                                </div>
                                                {t("Share")}
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target="#columnModal">
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/icons/column-icon.png"} alt="" />
                                                    </div>
                                                    {t("Column")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="Dailysalereport-content-area-main">
                                <div className="main-body-main-table-wrap Dailysalereport-table" id="table-scroll">
                                    <table id='payment_details' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                        <Tablehead
                                            tablehead={pospaymentdetails_head}
                                            tblname={'report_paymentdetails_tbl'}
                                            setfilterby={setfilterby}
                                        />
                                        <tbody>
                                            <>
                                                <tr>
                                                    <td></td>
                                                    <td>{openingBalance_data?.op_date}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{openingBalance_data?.description}</td>
                                                    <td>{toFormatPrice(openingBalance_data?.withdraw, { addSymbol: true })}</td>
                                                    <td>{toFormatPrice(openingBalance_data?.deposit, { addSymbol: true })}</td>
                                                    <td>{toFormatPrice(openingBalance_data?.netBalance, { addSymbol: true })}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                {paymentdata.length ? paymentdata.map((result, key) => {
                                                    return (
                                                        <>
                                                            <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{result?.op_date ? result?.op_date : "-"}</td>
                                                                <td>{result?.time ? result?.time : "-"}</td>
                                                                <td>{result?.Ref ? result?.Ref : "-"}</td>
                                                                <td>{result?.Type ? result?.Type : "-"}</td>
                                                                <td>{result?.description ? result?.description : ""}</td>
                                                                <td className="Dailysalereport-table-main-innerdata">{result?.withdraw ? result?.withdraw : "-"}</td>
                                                                <td className="Dailysalereport-table-main-innerdata">{result?.deposit ? result?.deposit : "-"}</td>
                                                                <td className="Dailysalereport-table-main-innerdata">{result?.netBalance ? result?.netBalance : "-"}</td>
                                                                <td>{result?.channel ? result?.channel : "-"}</td>
                                                                <td>{result?.user ? result?.user : "-"}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                }) : ""}
                                            </>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="Dailysalereport-table-main-footer-data">
                                                    {toFormatPrice(lowercount?.withdraw, { addSymbol: true })}
                                                </td>
                                                <td className="Dailysalereport-table-main-footer-data">
                                                    {toFormatPrice(lowercount?.deposit, { addSymbol: true })}
                                                </td>
                                                <td className="Dailysalereport-table-main-footer-data">
                                                    {toFormatPrice(lowercount?.netBalance, { addSymbol: true })}
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SocialModal shareUrl={socialurl} shareloader={shareloader} />
            <TableModal tablehead={pospaymentdetails_head} tblName={"report_paymentdetails_tbl"} setnewcolumndata={setnewcolumndata} />
        </div>

    </>)
}
export default Paymentreportdetails;

































