
// let moment = require('moment'); // require
import moment from 'moment';
import { store } from "../../../source/index";
import { graphqlPost } from '../../api/_call';

export const formatDate = (date) => {
    var state = store.getState();
    var OrgSettings = state?.app?.organisation_settings;
    let response = moment(date).utc(true).format(OrgSettings?.date_format);
    return response;
}

const date_format_change = (date_format) => {
    var response;
    if (date_format === "d/m/Y") {
        response = "DD/MM/YYYY";
    } else if (date_format === 'Y/m/d') {
        response = "YYYY/MM/DD";
    } else if (date_format === 'm/d/Y') {
        response = "MM/DD/YYYY";
    } else if (date_format === 'm/Y/d') {
        response = "MM/YYYY/DD";
    }
    return response;
}
export const handleContractDateChange = () => {
    const formattedDate = moment("30/08/2022", date_format_change('d/m/Y'));
    // Use formattedDate
    return formattedDate;

}
export const calDuedate = (date) => {
    return new Promise(async (resolve, reject) => {
        const postdata = `{
              generalsetup {
              purchaseOrder_delivery      
          }
      }`;
        let res = await graphqlPost(postdata, "App/graphql");
        if (res?.data?.generalsetup?.purchaseOrder_delivery?.status === "1") {
            const duedate = res?.data?.generalsetup?.purchaseOrder_delivery?.weeks;
            const caldays = 7 * parseInt(duedate);
            let result = new Date(date.setDate(date.getDate() + caldays));
            resolve(result);
        } else {
            let result = new Date(date.setDate(date.getDate() + 7));
            resolve(result);
        }

    });
}
export const reserveDuedate = (date) => {
    return new Promise(async (resolve, reject) => {
        const postdata = `{
              generalsetup {
                reserve_delivery      
          }
      }`;
        let res = await graphqlPost(postdata, "App/graphql");
        if (res?.data?.generalsetup?.reserve_delivery?.status === "1") {
            const duedate = res?.data?.generalsetup?.reserve_delivery?.weeks;
            const caldays = parseInt(duedate);
            let result = new Date(date.setDate(date.getDate() + caldays));
            resolve(result);
        } else {
            let result = new Date(date.setDate(date.getDate() + 1));
            resolve(result);
        }

    });
}