import React, { useState } from 'react'
import './ZoneModal.scss'
import { useTranslation } from 'react-i18next'

export default function ZoneFilter({setToggleFilter}) {
    const {t} = useTranslation();
    const [selectedFilters, setSelectedFilters] = useState([]);
    const filterData = [
        {title: "item", data: ["Ring", "Earring", "Bracelete"]},
        {title: "Collection", data: ["Earring jacket", "Band Ring", "Classic Ring", "Leather Series", "Accessories", "Infini Collection"]},
        {title: "Metal", data: ["18KBK", "18KRB", "18KWG", "18KYG", "9KBK"]},
        {title: "Stone", data: ["Diamong", "None", "Melee Diamong", "Mele Diamond 1 pts.", "9KBK"]},
        {title: "Size", data: ["41", "42", "43", "44", "45", "46,",  "47", "None", "s"]},
        {title: "Location", data: ["Central World", "Head Quarter",]},
    ]
  return (
    <div className='zonefilter'>
        <div className='zonefilter-inner'>
            <div className='zonefilter-inner-heading'>
                <div className='head'>{t("Filter")}</div>
                <div className='clear' onClick={()=>setToggleFilter(false)}>{t("Clear Filters")}</div>
            </div>
            <div className='zonefilter-inner-container'>
                { filterData.length && filterData.map((details, i)=>{
                    return(
                        <div className='zonefilter-inner-container-filter' key={i}>
                            <div className='zonefilter-inner-container-filter-header'>
                                <span className='title'>{details?.title}</span>
                                <span className='select'>{t("Select All")}</span>
                            </div>
                            <div className='zonefilter-inner-container-filter-data'>
                                { details?.data?.length ? 

                                details.data.map((item, index)=>{
                                    console.log(item, "chekc name")
                                    return(
                                        <div className='zonefilter-inner-container-filter-data-card' key={index}>{item}</div>
                                    )
                                })
                                : null
                                }
                            </div>
                        </div>
                    )
                })

                }
            </div>
            <div className='zonefilter-inner-bottom'>
                <button>{t("Apply")}</button>
            </div>
        </div>
    </div>
  )
}
