import React from 'react'

export default function DiamondIcon() {
  return (
    <svg width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.8569 12.8583L39.2854 1.42969H10.7397L2.14258 12.8583M47.8569 12.8583L24.9997 41.4297M47.8569 12.8583H2.14258M24.9997 41.4297L2.14258 12.8583M24.9997 41.4297L16.4283 12.8583M24.9997 41.4297L33.5712 12.8583" stroke="#4F4A3E" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}
