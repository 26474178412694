import React, { useState, useEffect } from 'react'
import RightArrow from '../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { _Api } from '../api/_call'
import { loading, Updateloading } from '../common/helpers/toastify'
import { LocationList } from '../common/Commongraphql'
import Confirmationmodel from "../common/modules/confirmationmodel"
import { useSelector } from 'react-redux';
import TableLoader from '../common/TableLoader'
const PaymentMethod = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [editItem, setEditItem] = useState(false)
  const [paymentlist, setPaymentlist] = useState([])
  const [paymentMethodOption, setPaymentMethodOption] = useState([])
  const [completeDataObject, setCompliteDataObject] = useState()
  const [locationarrayList, setLocationarrayList] = useState([])
  const [deleteid, setDeleteid] = useState([])
  const [showloader, setShowloader] = useState(false);
  const [filtername, setFiltername] = useState('');
  const [params, setParams] = useState()
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject((prevState) => ({
      ...prevState,
      [keyName]: value,
    }))
  }
  const paymentTypeOption = [
    {
      value: 'Credit Card',
      label: 'Credit Card',
    },
    {
      value: 'Bank',
      label: 'Bank',
    },
  ]
  const bankPaymentOption = [
    {
      value: 'Cheque',
      label: 'Cheque',
    },
    {
      value: 'IB',
      label: 'Internet Banking',
    },
  ]
  const filterbyname = (search) => {
    getpaymentmethod(search);
  };
  const getpaymentmethod = async (search) => {
    setShowloader(true)
    try {
      let postdata = {
        "search": search ? search : "",
      }
      let res = await _Api(postdata, `api/v1/App/paymentmethod/get-list`)
      if (res?.code === 200) {
        setPaymentlist(res?.data)
        setShowloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getpaymentactive = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/paymentmethod/get-active`)
      if (res?.code === 200) {
        let finalarr = []
        if (res?.data?.length) {
          res?.data.forEach((result) => {
            let arr = []
            if (result?.params?.length) {
              result?.params.forEach((element) => {
                arr.push({
                  label: element,
                  value: '',
                })
              })
            }
            finalarr.push({
              name: result?.name,
              params: arr,
              _id: result?._id
            })
          })
        }
        setPaymentMethodOption(finalarr)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getpaymentmethod()
    getpaymentactive()
  }, [])

  const onImageupload = (e) => {
    const [file] = e.target.files
    const reader = new FileReader(file)
    reader.onloadend = async () => {
      let GetloadingID = loading()
      try {
        const postdata = {
          image: reader.result,
          name: 'profile',
        }
        let resfront = await _Api(postdata, `api/v1/App/User/uploadimage`)
        if (resfront.code === 200) {
          Updateloading(t, GetloadingID, t('Successfully Upload Image'))
          onChangeValueUpdate('logo', resfront?.data?.images?.url)
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
    if (file) {
      reader?.readAsDataURL(file);
    }
  }
  const viewpayment = async (id) => {
    try {
      let postdata = {
        id: id,
      }
      let res = await _Api(postdata, `api/v1/App/paymentmethod/view`)
      if (res?.code === 200) {
        setCompliteDataObject((prevState) => ({
          ...prevState,
          Status: res?.data?.Status,
          logo: res?.data?.logo,
          Paymentmethod_type: res?.data?.Paymentmethod_type,
          BankPaymenttype: res?.data?.BankPaymenttype,
          Payment_method: {
            params: res?.data?.keys,
            name: res?.data?.payment_method_name?.label,
            _id: res?.data?.payment_method_name?.value
          },
          Locations: {
            id: res?.data?.Locations,
            name: res?.data?.location_name,
          },
          name: res?.data?.name,
          _id: res?.data?._id
        }))
        let obj = { ...params }
        obj.params = res?.data?.keys
        obj.name = res?.data?.payment_method_name?.label
        obj._id = res?.data?.payment_method_name?.value
        setParams(obj)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const addpaymentmethod = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        logo: completeDataObject?.logo,
        Paymentmethod_type: completeDataObject?.Paymentmethod_type,
        BankPaymenttype: completeDataObject?.BankPaymenttype,
        Payment_method_id: params?._id,
        name: completeDataObject?.name,
        keys: params?.params,
        Locations: completeDataObject?.Locations?.id,
        Status: completeDataObject?.Status ? completeDataObject?.Status : "0",
      }
      let res = await _Api(postdata, `api/v1/App/paymentmethod/add`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getpaymentmethod()
        setEditItem(false)
      } else {
        Updateloading(t, GetloadingID, res.errors ? res.errors : res.message, 'error');
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const editpaymentmethod = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        "_id": completeDataObject?._id,
        "logo": completeDataObject?.logo,
        "Paymentmethod_type": completeDataObject?.Paymentmethod_type,
        "BankPaymenttype": completeDataObject?.BankPaymenttype,
        "Payment_method_id": params?._id,
        "name": completeDataObject?.name,
        "keys": params?.params,
        "Locations": completeDataObject?.Locations?.id,
        "Status": completeDataObject?.Status
      }

      let res = await _Api(postdata, `api/v1/App/paymentmethod/edit`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getpaymentmethod()
        setEditItem(false)
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }

    catch (err) {
      console.log(err, "err")
    }
  }

  const hendlemethodchange = (index, event, params) => {
    const arr = { ...params }
    arr.params[index].value = event
    setParams(arr)
  }

  const deletepayment = async () => {
    let GetloadingID = loading()
    try {
      let postdata = { "ids": deleteid }
      let res = await _Api(postdata, `api/v1/App/paymentmethod/delete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getpaymentmethod()
        setDeleteid([])
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const handleCheckAllChange = (event) => {
    setDeleteid(
      event.target.checked ? paymentlist.map((c) => c?._id) : []
    );
  };
  const handleFlterchange = (event, data) => {
    setDeleteid((prevChecked) =>
      event.target.checked
        ? [...prevChecked, data]
        : prevChecked.filter((item) => item !== data)
    );
  };
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              {t("Setup")} <RightArrow /> <span className="">{t("PAYMENT RULES")}</span>
            </h1>
          </div>

          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            {deleteid?.length ?
              <img
                data-bs-toggle="modal" data-bs-target="#createPurchaseModal"
                className="cursor-pointer"
                src={
                  base_assets + 'images/admin/icons/Delete.png'
                }
                alt=""
              /> : ""}

            <div className="com-search-bar position-relative">
              <img
                onClick={filterbyname}
                className="com-search-bar-search-img"
                src={base_assets + 'images/admin/icons/gray-search.png'}
                alt=""
              />
              <input
                placeholder="Search"
                value={filtername || ''}
                className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                onChange={(e) => setFiltername(e.target.value)}
              />
              {filtername ? <img className="search-bar-clear" onClick={(e) => {
                setFiltername("")
                getpaymentmethod("")
              }} src={base_assets + 'images/icons/False.png'} alt="" /> : ""}
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={base_assets + 'images/admin/icons/gray-ellipsis.png'}
                  alt=""
                />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </div>
                  <span>{t('print')}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + 'images/icons/export-x-icon.png'}
                      alt=""
                    />
                  </div>
                  <span>{t('export')}</span>
                </li>
                <li
                  className="dropdown-item"
                >
                  <div className="dropdown-item-img">
                    <img src={base_assets + 'images/icons/export.png'} alt="" />
                  </div>
                  <span>{t('Import')}</span>
                </li>
              </ul>
            </div>
            <button
              className={parmissiondatashow?.gis_setup_payment_method?.Add ? "com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3" : "d-none"}
              onClick={() => { setEditItem(true); setCompliteDataObject(); setParams() }}
            >
              <img
                className="me-2"
                src={base_assets + 'images/admin/icons/add-icon-white.png'}
                alt=""
              />
              {t("Add New")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className={editItem ? 'col-md-6' : 'col-md-12'}>
            <div className="main-body-main-table-wrap com-custom-table table-responsive position-relative">
              <table id="my_customer" className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th className="pe-0">
                      <div className="com-check">
                        <input
                          id="check1"
                          type="checkbox"
                          onChange={(e) => handleCheckAllChange(e)}
                          checked={deleteid.length === paymentlist.length}
                        />
                        <label htmlFor="check1" className="com-check-label" />
                      </div>
                    </th>
                    <th>#</th>
                    <th>{t("Image")}</th>
                    <th>{t("Payment type")}</th>
                    <th>{t("Payment Method")}</th>
                    <th>{t("Label")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Location/ Outlet")}</th>
                    <th>{t("Last Modified Date")}</th>
                    <th>{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentlist?.length > 0 ? (
                    paymentlist.map((result, key) => (
                      <tr className="text-nowrap" key={key}>
                        <td className="pe-0">
                          <div className="com-check">
                            <input
                              id={result?._id}
                              type="checkbox"
                              onChange={(e) => handleFlterchange(e, result?._id)}
                              checked={deleteid ? deleteid.includes(result?._id) : ""}
                            />
                            <label htmlFor={result?._id} className="com-check-label" />
                          </div>
                        </td>
                        <td>{key + 1}</td>
                        <td className="px-2 text-center">
                          <img
                            className="table-user-img rounded-circle"
                            src={result?.logo}
                            alt=""
                          />
                        </td>
                        {parmissiondatashow?.gis_setup_payment_method?.Edit ?
                          <td className="link-color cursor-pointer" onClick={() => { setEditItem(true); viewpayment(result?._id); }}>
                            {result?.Paymentmethod_type || ""}
                          </td> :
                          <td>
                            {result?.Paymentmethod_type || ""}
                          </td>}

                        <td>{result?.payment_method_name || ""}</td>
                        <td>{result?.name || ""}</td>
                        <td>
                          {result?.Status === "1" ? (
                            <span className="active-badge rounded-pill px-2 py-1 fw-semibold">
                              <span className="active-badge-dot me-2" />
                              {t("Active")}
                            </span>
                          ) : (
                            <span className="inactive-badge rounded-pill px-2 py-1 fw-semibold">
                              <span className="inactive-badge-dot me-2" />
                              {t("InActive")}
                            </span>
                          )}
                        </td>
                        <td>{result?.location_name || ""}</td>
                        <td>{result?.updatedAt || ""}</td>
                        <td className="text-center">
                          <img
                            className={parmissiondatashow?.gis_setup_payment_method?.Edit ? "cursor-pointer me-4" : "d-none"}
                            onClick={() => {
                              setEditItem(true);
                              viewpayment(result?._id);
                            }}
                            src={base_assets + "images/admin/icons/Edit.png"}
                            alt=""
                          />
                          <img
                            data-bs-toggle="modal"
                            data-bs-target="#createPurchaseModal"
                            onClick={() => setDeleteid([result?._id])}
                            className={parmissiondatashow?.gis_setup_payment_method?.Delete ? "cursor-pointer" : "d-none"}
                            src={base_assets + "images/admin/icons/Delete.png"}
                            alt=""
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="50" className='text-center'>{t("No Data Available In Table")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
          </div>
          <div className={editItem ? 'col-md-6 d-block' : 'd-none'}>
            <div className="edit-item-area bg-white row">
              <div className="col-md-9 px-0">
                <div className="edit-item-area-body">
                  <div className="edit-item-area-body-circle-img text-center mb-4">
                    <div className="edit-item-area-body-circle-img-choose">
                      <input
                        id="chooseImg"
                        type="file"
                        className="d-none"
                        onChange={onImageupload}
                        accept="image/*"
                      />
                      <label
                        htmlFor="chooseImg"
                        className="rounded-circle overflow-hidden"
                      >
                        <img
                          className=""
                          src={
                            completeDataObject?.logo
                              ? completeDataObject?.logo
                              : base_assets + 'images/blank-img-2.png'
                          }
                          alt=""
                        />
                      </label>
                    </div>
                    <label>{t("Height and Width must not exceed 60px")}</label>
                  </div>
                  <div className="row">
                    <div className="col-md-12 ps-0">
                      <div className="row mx-0">
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">
                              {t("Payment Type")} <sup>*</sup>
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={paymentTypeOption}
                              value={{
                                label: completeDataObject?.Paymentmethod_type,
                                value: completeDataObject?.Paymentmethod_type,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate(
                                  'Paymentmethod_type',
                                  e.value,
                                )
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                        </div>
                        {completeDataObject?.Paymentmethod_type === 'Bank' ? (
                          <div className="col-md-12">
                            <div className="v3-input-group">
                              <label className="v3-input-group-label">
                                {t("Bank Payment Type")} <sup>*</sup>
                              </label>
                              <Select
                                className="select-set select w-100 p-0"
                                placeholder=""
                                options={bankPaymentOption}
                                value={{
                                  label: completeDataObject?.BankPaymenttype,
                                  value: completeDataObject?.BankPaymenttype,
                                }}
                                onChange={(e) =>
                                  onChangeValueUpdate(
                                    'BankPaymenttype',
                                    e.value,
                                  )
                                }
                                classNamePrefix="common-select"
                                isSearchable={false}
                              />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">
                              {t("Payment Method")} <sup>*</sup>
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={paymentMethodOption}
                              value={completeDataObject?.Payment_method}
                              onChange={(e) => setParams(e)}
                              classNamePrefix="common-select"
                              isSearchable={false}
                              getOptionLabel={(e) => (
                                <div className="common-select-icon">
                                  <span>{e.name}</span>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">
                              {t("Location")} <sup>*</sup>
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={locationarrayList}
                              value={completeDataObject?.Locations}
                              onChange={(e) =>
                                onChangeValueUpdate('Locations', e)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                              getOptionLabel={(e) => (
                                <div className="common-select-icon">
                                  {' '}
                                  <span>{e.name}</span>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">
                              {t("Label")} <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="v3-input-group-input w-100"
                              placeholder="Credit/Debit Card"
                              value={completeDataObject?.name || ''}
                              onChange={(e) =>
                                onChangeValueUpdate('name', e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {completeDataObject?.BankPaymenttype === 'Cheque' && completeDataObject?.Paymentmethod_type === 'Bank'
                          ? ''
                          :
                          params?.params?.length
                            ? params?.params.map((element, index) => {
                              return (
                                <div className="col-md-12" key={index}>
                                  <div className="v3-input-group">
                                    <label className="v3-input-group-label">
                                      {element?.label} <sup>*</sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="v3-input-group-input w-100"
                                      placeholder={element?.label}
                                      value={element?.value || ''}
                                      onChange={(e) =>
                                        hendlemethodchange(
                                          index,
                                          e.target.value,
                                          params,
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            })
                            : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 pe-0">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                  <button
                    className="square-btn square-btn-white btn-close focus-none"
                    onClick={() => setEditItem(false)}
                  ></button>
                  <button
                    className="square-btn"
                    onClick={() => { completeDataObject?._id ? editpaymentmethod() : addpaymentmethod() }}
                  >
                    <img
                      src={base_assets + 'images/icons/white-save.png'}
                      alt=""
                    />
                  </button>
                </div>
                <div className="form-check form-switch ps-0 ms-2">
                  <label className="form-check-label" htmlFor="mixmatch">
                    {t("Status")}
                  </label>
                  <input
                    className="form-check-input float-none ms-2"
                    type="checkbox"
                    id="mixmatch"
                    onChange={(e) =>
                      onChangeValueUpdate(
                        'Status',
                        e.target.checked ? "1" : "0",
                      )
                    }
                    checked={completeDataObject?.Status === "1" ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={deletepayment} ></Confirmationmodel>
        <LocationList setLocationarrayList={setLocationarrayList} />
      </div>
    </>
  )
}

export default PaymentMethod
