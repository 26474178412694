import React from 'react';
import { toFormatPrice } from '../../common/helpers/function';
export const PartialHistorysubtable = (prioritydata, layby_id, t) => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    return (
        <React.Fragment>
            {prioritydata.code === 200 ?
                <tr className="common-sub-table-wrap add-row remove" id={`common-sub-table-wrap-${layby_id}`}>
                    <td colSpan="14">
                        <table id={'data_subtable_customorder' + layby_id} className="common-table common-sub-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t("Duedate")}</th>
                                    <th>{t("Receive")}</th>
                                    <th>{t("Payment")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                    <th className='delivered-text'>{t("Outstanding")}
                                        <div className="up-down-arrow-btn">
                                            <img className="up-down-arrow-img up-down-arrow-img-forward active" src={base_assets + "images/icons/up-down-arrow-green.png"} alt="" />
                                            <img className="up-down-arrow-img up-down-arrow-img-reverse" src={base_assets + "images/icons/up-down-arrow-green-reverse.png"} alt="" />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(prioritydata?.data).map((result, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{result?.sr_no ? result?.sr_no : ""}</td>
                                            <td>{result?.due_date ? result?.due_date : ""}</td>
                                            <td>{result.receive_date ? result.receive_date : ""}</td>
                                            <td>{toFormatPrice(result?.payment_receive, { addSymbol: true })}</td>
                                            <td className='delivered-text'>{toFormatPrice(result?.outstanding, { addSymbol: true })} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
                : ""}
        </React.Fragment>
    )
}