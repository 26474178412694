import React from 'react'
import { Link } from "react-router-dom";
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import { Dashboard } from "@uppy/react";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { useTranslation } from 'react-i18next';
const ProductImage = () => {
  const { t } = useTranslation();
  const uppy = new Uppy({
    autoProceed: false,
    width: '100%',
    inline: true,
    allowMultipleUploads: false,
    restrictions: {
      maxFileSize: 300000,
      maxNumberOfFiles: 100,
      minNumberOfFiles: null,
      allowedFileTypes: [
        'image/*', '.jpg', '.jpeg', '.png']
    }
  }).use(XHRUpload, {
    headers: {
      'authorization': localStorage.getItem("UserToken")
    },
    endpoint: process.env.REACT_APP_API_URL + 'api/v1/App/product/saveImage',
    formData: true,
    fieldName: 'import',
  })
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  return (
    <div className='main-layout'>
      <div className='main-body'>

        <div className='container'>
          <div className='position-relative mb-3'>
            <div className='page-back-heading'>
              <Link className="page-back-btn position-static" to="/app/product/main"><img src={base_assets + "/images/icons/arrow-left.png"} alt="" />
                <span className='head-name'>{t("Product")}</span></Link>
            </div>
          </div>
          <div className="App"  >
            <Dashboard
              uppy={uppy}
              width={1665}
              height={660}
              note={t('Images  jpg,.png only, 300kb or less  AND  Max Number Of Files 100')}
              proudlyDisplayPoweredByUppy={false}
              showProgressDetails={true}
            />
          </div>
        </div>
      </div>
    </div>

  );

}
export default ProductImage;