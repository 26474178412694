import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DateTime from '../../common/DateTime'
import { AllocationtableHead } from '../../common/Tablehead'
import TableColumnModal from '../../common/modules/tableColumn'
import ConfirmationModel from '../../common/modules/confirmationmodel'
import { Tablehead } from '../../common/modules/Tablehead'
import { _Api } from '../../api/_call'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import { subtableallocationpo } from '../../transaction/subTables/po/allocationpo'
import SelectSearch from 'react-select-search'
import { status_priority } from '../../common/helpers/status_class'
import SocialModal from '../../common/modules/SocialModal/SocialModal'
import { DateRange } from '../../common/helpers/daterange'
import {
  success,
  error,
  loading,
  Updateloading,
} from '../../common/helpers/toastify'
import ProductImageSliderModal from '../../common/modules/productImageslider'
import $ from 'jquery'
import { Pagination } from '../../common/Pagination'
import { useTranslation } from 'react-i18next'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import Select from "react-select";
const AllocationPO = (props) => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const navigate = useNavigate()
  const [filtername, setFiltername] = useState('')
  const [allocationpo, setAllocationpo] = useState([])
  const [datefilter, setDatefilter] = useState([])
  const [limit, setLimit] = useState(100)
  const [imageid, setImageid] = useState()
  const [newcolumndata, setnewcolumndata] = useState(AllocationtableHead)
  const [po_priority, setpo_priority] = useState([])
  const [statement, setStatement] = useState()
  const [ShowSubmitbutton, setShowSubmitbutton] = useState(true)
  const [datalodaer, setDatsLodaer] = useState(false)
  const [productid, setProductid] = useState()
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const optionsPriority = [
    { name: t('Low'), value: '0' },
    { name: t('Medium'), value: '1' },
    { name: t('High'), value: '2' },
  ]
  const Changepriority = (index, value, ptoduct_id) => {
    setStatement(t('Change Priority'))
    setpo_priority([{ index: index, status: value, ptoduct_id: ptoduct_id }])
    document.querySelector('#status_query_select').click()
  }
  const Getpoallocation = async (current, firstlimit, search) => {
    setDatsLodaer(true)
    UnSetdataTable('tbl_po_allocationpo')
    try {
      const postdata = {
        search: search ? search : "",
        skip: current ? (current - 1) * limit : 0,
        limit: firstlimit ? firstlimit : limit,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let resPoallocation = await _Api(
        postdata,
        `api/v1/Inventory/po/allocationList`,
      )
      if (resPoallocation?.code === 200) {
        setTimeout(() => {
          SetdataTable('tbl_po_allocationpo', 7, newcolumndata, 0)
        }, 50)
        setAllocationpo(resPoallocation?.data)
        setstate({
          ...state,
          totalPages: Math.ceil(resPoallocation?.total / postdata.limit),
          currentPage: current ? current : 1,
        })
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        setTimeout(() => {
          SetdataTable('tbl_po_allocationpo', 7, newcolumndata, 0)
        }, 50)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const filterbyname = (search) => {
    Getpoallocation(currentPage, limit, search)
    setstate({ ...state, currentPage: 1 })

    //}
  }
  const getlimit = (limit) => {
    setLimit(limit)
    Getpoallocation(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    Getpoallocation(current, '')
  }
  const setSubTable = async (event, key, id, Qty) => {
    var row = document.querySelector('#common-main-table-wrap-' + id)
    if (event.target.checked) {
      let allocationsubdata = [...allocationpo]
      const postdata = {
        id: id,
      }
      let data = await _Api(postdata, `api/v1/Inventory/po/allocationConnect`)
      var all = document.querySelectorAll('.allocationcheck')
      all.forEach(async function (eve, k) {
        if (k !== key) {
          allocationsubdata[k].subTable = null
          setAllocationpo(allocationsubdata)
          eve.checked = false
        } else {
          eve.checked = true
          var html_ = subtableallocationpo(data, t, key, Qty)
          allocationsubdata[k].subTable = html_
          setAllocationpo(allocationsubdata)
          var checkRowSub = setInterval(function () {
            var rowSub = document.querySelector('#common-sub-table-wrap-' + id)
            if (rowSub) {
              $(row).after(rowSub)
              clearInterval(checkRowSub)
            }
          }, 100)
        }
      })
    } else {
      let allocationsubdata = [...allocationpo]
      allocationsubdata[key].subTable = null
      setAllocationpo(allocationsubdata)
    }
  }
  const handler_priority = async () => {
    try {
      const postdata = {
        po_product_id: po_priority[0].ptoduct_id,
        status: po_priority[0]?.status,
      }
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/po/editAllocationPriority`,
      )
      if (prioritydata?.code === 200) {
        success(t('Successfully Priority Updated!!'))
        Getpoallocation()
      } else {
        error(t('Something Went Wrong!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const submit_po = async () => {
    var getcheckbox = document.querySelectorAll('.allocationselect')
    const selected_id = []
    for (var i = 0; i < getcheckbox.length; i++) {
      if (getcheckbox[i].checked) {
        selected_id.push(getcheckbox[i].id)
      }
    }
    const handleValidation = (e) => {
      let formIsValid = true
      if (selected_id.length === 0) {
        formIsValid = false
        error(t('No item Selected!!'))
      }
      return formIsValid
    }
    if (handleValidation()) {
      setShowSubmitbutton(false)
      let GetloadingID = loading()
      try {
        const postdata = {
          po_product_id: productid,
          selected_id: selected_id,
        }
        let res = await _Api(
          postdata,
          `api/v1/Inventory/po/allocationConnectSave`,
        )
        if (res.code === 200) {
          setShowSubmitbutton(true)
          Updateloading(t, GetloadingID, t('Allocation connection susses!!'))
          const timer = setTimeout(() => {
            navigate('/app/inventory/allocatedlist')
          }, 100)
          return () => clearTimeout(timer)
        } else {
          setShowSubmitbutton(true)
          Updateloading(t, GetloadingID, (res?.message), 'error')
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }

  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_po_allocationpo',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Getpoallocation()
    }
    // eslint-disable-next-line
  }, [datefilter])

  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    AllocationtableHead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (allocationpo.length) {
      allocationpo.map((result, key) => {
        return (
          bodyarray.push([
            '',
            key + 1,
            result?.maain_image,
            result?.SKU,
            result?.createdAt,
            result?.po_no,
            '',
            result?.stock_id,
            result?.metal_name,
            result?.stone_name,
            result?.size_name,
            result?.po_QTY,
            result.priority === 0
              ? 'Low'
              : result.priority === 1
                ? 'Medium'
                : 'High',
            result?.location_name,
            result?.delivery_date,
            result?.due_days,
          ])
        )

      })
    }
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'allocationpo')
  }
  return (
    <>
      <div>
        <div className="main-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="main-body-current-day-time">
                  <DateTime></DateTime>
                </span>
                <div className="top-heading-area">
                  <div className="top-heading-area-left">
                    <Link to="/app/inventory" className="page-back-btn">
                      <img
                        src={base_assets + 'images/icons/arrow-left.png'}
                        alt=""
                      />
                    </Link>
                  </div>
                  <h1 className="main-heading text-center">
                    {t('Allocation')}
                  </h1>
                </div>
                <div className="main-body-top-tab-bar create-purchase-top-bar">
                  <div
                    className="main-body-top-tab-bar-left-col nav nav-pills"
                    role="tablist"
                  >
                    <Link
                      to="/app/inventory/allocationpo"
                      className="main-body-top-tab-bar-left-col-a nav-link active"
                    >
                      <span className="main-body-top-tab-bar-left-col-a-text">
                        {' '}
                        <img
                          className="linktwo"
                          src={base_assets + 'images/icons/Link-black.png'}
                          alt=""
                        />
                        <img
                          className="linkone"
                          src={base_assets + 'images/icons/Link.png'}
                          alt=""
                        />{' '}
                        {t('Allocation PO')}
                      </span>
                    </Link>
                    <Link
                      to="/app/inventory/allocatedlist"
                      className="main-body-top-tab-bar-left-col-a nav-link"
                    >
                      <span className="main-body-top-tab-bar-left-col-a-text">
                        {' '}
                        <img
                          className="usgreen"
                          src={base_assets + 'images/icons/user-green.png'}
                          alt=""
                        />
                        <img
                          className="usblack"
                          src={base_assets + 'images/icons/user.png'}
                          alt=""
                        />{' '}
                        {t('Allocated List')}
                      </span>
                    </Link>
                  </div>
                  <div className="main-body-top-inputs-bar-col-right">
                    <button
                      className={
                        ShowSubmitbutton
                          ? 'main-body-top-inputs-bar-col-right-submit-btn com-btn '
                          : 'main-body-top-inputs-bar-col-right-submit-btn com-btn d-block  deactive'
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#createPurchaseModal"
                      onClick={() => setStatement(t('connect Allocation'))}
                    >
                      {t('Submit')}
                    </button>
                  </div>
                </div>
                <div className="tab-content">
                  <div id="pos_order" className="tab-pane active">
                    <div className="main-body-top-status-bar">
                      <Select
                        className="limit-select short mx-3"
                        options={selectOptions}
                        value={{ label: limit, value: limit }}
                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                      />
                      <div className="main-body-top-status-bar-filter">
                        <input
                          type="text"
                          value={filtername}
                          className="main-body-top-status-bar-filter-input"
                          placeholder={t('POS No./StockID/Metal ...')}
                          onKeyPress={(e) =>
                            e.key === 'Enter' && filterbyname(e.target.value)
                          }
                          onChange={(e) => setFiltername(e.target.value)}
                        />
                        {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                        <button
                          onClick={(e) => filterbyname(filtername)}
                          className="main-body-top-status-bar-filter-search"
                        >
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="main-body-top-status-bar-multi-date-picker">
                        <DateRange setDatefilter={setDatefilter} />
                        <button className="print-export-dropdown-selected-icon d-none">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </button>

                        <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                          <button
                            className="btn dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {' '}
                            <img
                              src={
                                base_assets +
                                'images/icons/ellipsis-circular.png'
                              }
                              alt=""
                            />{' '}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li
                              className="dropdown-item"
                              onClick={() => downloadexclsheet()}
                            >
                              <div className="dropdown-item-img">
                                <img
                                  src={
                                    base_assets +
                                    'images/icons/export-x-icon.png'
                                  }
                                  alt=""
                                />
                              </div>
                              <span>{t('export')}</span>
                            </li>
                            <li
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#columnModal"
                            >
                              <div className="dropdown-item-img">
                                <img
                                  src={
                                    base_assets + 'images/icons/column-icon.png'
                                  }
                                  alt=""
                                />
                              </div>
                              <span>{t('column')}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="common-sub-table-wrap add-row">
                      <div className="main-body-main-table-wrap position-relative">
                        <table
                          id="tbl_po_allocationpo"
                          className="common-table product-base-table w-100"
                        >

                          <Tablehead
                            tablehead={AllocationtableHead}
                            tblName={'tbl_po_allocationpo'}
                            setfilterby={""}
                          />
                          <tbody>
                            <>
                              {allocationpo?.length
                                ? allocationpo.map((result, key) => {
                                  var po_status_class_val = status_priority(
                                    result?.priority,
                                  )
                                  return (
                                    <React.Fragment key={key}>
                                      <tr
                                        id={`common-main-table-wrap-${result?._id}`}
                                        key={key}
                                      >
                                        <td className="row-check">
                                          <div className="com-check radio">
                                            <input
                                              type="checkbox"
                                              className="allocationcheck"
                                              id={result?._id}
                                              onClick={(e) => {
                                                setSubTable(
                                                  e,
                                                  key,
                                                  result._id,
                                                  result?.Qty
                                                )
                                                setProductid(result._id)
                                              }}
                                            />
                                            <label
                                              htmlFor={result._id}
                                              className="com-check-label"
                                            />
                                          </div>
                                        </td>
                                        <td>{key + 1}</td>
                                        <td>
                                          <div
                                            className="table-pro-img"
                                            onClick={() =>
                                              setImageid(result?.product_id)
                                            }
                                            data-bs-toggle="modal"
                                            data-bs-target="#ProductViewModal"
                                          >
                                            <img
                                              src={result?.maain_image}
                                              alt=""
                                            />
                                          </div>
                                        </td>
                                        <td className="po-no col-highlight">
                                          {result?.SKU ? result?.SKU : ''}
                                        </td>
                                        <td className="col-highlight">
                                          {' '}
                                          {result?.createdAt
                                            ? result?.createdAt
                                            : ''}
                                        </td>
                                        <td className="po-no col-highlight">
                                          {result?.po_no
                                            ? result?.po_no
                                            : ''}
                                        </td>
                                        <td className="col-highlight">
                                          <div className="td-icons-wrap">
                                            {' '}
                                            <span className="td-icons-wrap-link">
                                              {result?.allocatable ===
                                                true ? (
                                                <img
                                                  className="img "
                                                  src={
                                                    base_assets +
                                                    'images/icons/Link-black.png'
                                                  }
                                                  alt=""
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </span>
                                          </div>
                                        </td>
                                        <td className="col-highlight">
                                          {' '}
                                          {result.stock_id}
                                        </td>
                                        <td className="col-highlight">
                                          {result?.metal_name
                                            ? result?.metal_name
                                            : ''}
                                        </td>
                                        <td className="col-highlight">
                                          {result?.stone_name
                                            ? result?.stone_name
                                            : ''}
                                        </td>
                                        <td className="col-highlight">
                                          {result?.size_name
                                            ? result?.size_name
                                            : ''}
                                        </td>
                                        <td className="col-highlight">
                                          {result?.po_QTY
                                            ? result?.po_QTY
                                            : ''}
                                        </td>
                                        <td className="col-highlight">
                                          <div
                                            className={
                                              'dropdown status-dropdown ' +
                                              po_status_class_val
                                            }
                                          >
                                            <SelectSearch
                                              options={optionsPriority}
                                              onChange={(event) =>
                                                Changepriority(
                                                  key,
                                                  event,
                                                  result._id,
                                                )
                                              }
                                              name="language"
                                              value={result.priority}
                                            />
                                          </div>
                                        </td>
                                        <td className="danger-text col-highlight">
                                          {result?.location_name
                                            ? result?.location_name
                                            : ''}
                                        </td>
                                        <td className="danger-text col-highlight">
                                          {result?.delivery_date
                                            ? result?.delivery_date
                                            : ''}
                                        </td>
                                        <td className="danger-text col-highlight text-end">
                                          {result?.due_days}
                                        </td>
                                      </tr>
                                      {typeof result.subTable == 'undefined'
                                        ? (result.subTable = '')
                                        : result.subTable}
                                    </React.Fragment>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td>
                                {allocationpo?.reduce(
                                  (interation, val) =>
                                  (interation =
                                    interation + parseInt(val.po_QTY)),
                                  0,
                                )}
                              </td>
                              <td />
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tfoot>
                        </table>
                        <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                          <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {allocationpo.length > 0 ? (
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        pagination={(crPage) => handlePaginations(crPage)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SocialModal />
        </div>
        <div
          className="modal fade common-modal common-modal-danger"
          id="allocation-listedModal"
          tabIndex={-1}
          aria-labelledby="allocation-listedModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img
                  className="common-modal-exclamation-img"
                  src={base_assets + 'images/icons/exclamation-mark-red.png'}
                  alt=""
                />
                <span className="modal-content-text">
                  {t('Are you sure you want to Free this item ?')}
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn modal-content-no"
                  data-bs-dismiss="modal"
                >
                  {t('Order Close')}
                </button>
                <button type="button" className="btn modal-content-yes">
                  {t('Deallocate')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={AllocationtableHead}
        tblName={'tbl_po_allocationpo'}
        calldatatable={Getpoallocation}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <ProductImageSliderModal main_id={imageid}></ProductImageSliderModal>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#createPurchaseModal"
        id="status_query_select"
      ></button>
      <ConfirmationModel
        statement={statement}
        handler_submit={
          statement === 'Change Priority' ? handler_priority : submit_po
        }
        handler_dismiss={Getpoallocation}
      ></ConfirmationModel>
    </>
  )
}
export default AllocationPO
