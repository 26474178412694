import React, { useState } from "react";
import './select.scss';
import Select, { components } from "react-select";
const SelectBox = () => {
  const [statusselectedOptions, setstatusSelectedOptions] = useState();

  const status = [
    { value: "s1", label: <label className="">active</label> },
    { value: "s2", label: <label className="">inactive</label> }
  ];

  

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <div className="common-select">
      <Select
        placeholder="Select"
        options={status}
        value={statusselectedOptions}
        onChange={(opt) => setstatusSelectedOptions(opt)}
        classNamePrefix="common-select"
        components={{
          ValueContainer: CustomValueContainer
        }}
        getOptionLabel={e => (
          <div className="">
            {e.label}
          </div>
        )}
        isSearchable={false}
      />
    </div>

  )
}

export default SelectBox;