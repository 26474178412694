import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { _Api } from '../../api/_call'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import TableColumnModal from '../../common/modules/tableColumn'
import DateTime from '../../common/DateTime'
import { Tablehead } from '../../common/modules/Tablehead'
import { VoucherList } from '../../common/graphql/commonlayouts'
import Advancedfiltermodel from '../../common/modules/advanced-filter-model'
import { formatDate } from '../../common/helpers/formatDate'
import { transferinventorylisthead } from '../../common/Tablehead'
import { toUnformatPrice } from '../../common/helpers/function'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
const Inventorylist = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  let navigate = useNavigate()
  const [limit, setLimit] = useState(100)
  const { state } = useLocation()
  const [filtername, setFiltername] = useState('')
  const [setfilterby] = useState('')
  const [transferinventorylist, setTransferinventorylist] = useState([])
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [datalodaer, setDatsLodaer] = useState(false)
  const [setcommanSelected_vouc] = useState(null)
  const [newcolumndata, setnewcolumndata] = useState(transferinventorylisthead)
  const filterbyname = (search) => {
    Gettransferinventory(limit, search)
  }
  const Gettransferinventory = async (firstlimit, search) => {
    UnSetdataTable('tbl_inventoryTransfer_list')
    setDatsLodaer(true)
    try {
      const postdata = {
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        skip: 0,
        location_id: [
          state?.shipfromid ? state?.shipfromid : '',
          state?.location_id ? state?.location_id : '',
        ],
      }
      let restransferinventory = await _Api(
        postdata,
        `api/v1/Inventory/stocktransfer/editStockTransferInventoryList`,
      )
      if (restransferinventory.code === 200) {
        setDatsLodaer(false)
        setTransferinventorylist(restransferinventory.data)
        SetdataTable('tbl_inventoryTransfer_list', '5', newcolumndata, [
          1,
          4,
          9,
          10,
        ])
      } else {
        setDatsLodaer(false)
        SetdataTable('tbl_inventoryTransfer_list', '5', newcolumndata)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_inventory_addstocktransfer',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])

  useEffect(() => {
    Gettransferinventory()
    // eslint-disable-next-line
  }, [])

  const addlists = () => {
    let selected = document.querySelectorAll('.inventorystocklist')
    let listarray = []
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let id = selected[i].getAttribute('data-id')
        transferinventorylist
          .filter((item) => item?.id === id)
          .forEach((result) => {
            listarray.push({
              main_image: result?.main_image,
              name: result?.name,
              SKU: result?.SKU,
              stock_id: result?.stock_id,
              metal_name: result?.metal_name,
              size_name: result?.size_name,
              stone_name: result?.stone_name,
              po_QTY: result?.po_QTY,
              price: result?.price,
              amount_total: result?.amount_total,
              warehouse_location_name: result?.warehouse_location_name,
              location_name: result?.location_name,
              id: result?.id,
            })
          })
      }
    }
    navigate('/app/inventory/edit-stocktransfer', {
      state: {
        skulist: listarray,
        shipfromid: state?.shipfromid ? state?.shipfromid : '',
        voucher_id: state?.voucher_id ? state?.voucher_id : '',
        transitiondate: state?.transitiondate,
        deliverydate: state?.deliverydate,
        tf_no: state?.tf_no,
        location_id: state?.location_id,
        stock_status: state?.stock_status,
        stockid: state?.stockid,
      },
    })
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link
                    to="/app/inventory/edit-stocktransfer"
                    className="page-back-btn"
                    state={{
                      shipfromid: state?.shipfromid ? state?.shipfromid : '',
                      voucher_id: state?.voucher_id ? state?.voucher_id : '',
                      transitiondate: state?.transitiondate,
                      deliverydate: state?.deliverydate,
                      tf_no: state?.tf_no,
                      location_id: state?.location_id,
                      stock_status: state?.stock_status,
                    }}
                  >
                    <img
                      src={base_url_assets + 'images/icons/arrow-left.png'}
                      alt=""
                    />
                  </Link>
                  <img
                    src={base_url_assets + 'images/icons/home-icon.png'}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={'Stock_Transfer'}
                    voucherid={state?.voucher_id ? state?.voucher_id : ''}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">
                  {t('Edit transfer')}
                </h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills">
                  <Link className="main-body-top-tab-bar-left-col-a nav-link active table-refresh">
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        src={
                          base_url_assets + 'images/icons/inventory-icon.png'
                        }
                        alt=""
                      />
                      {t('Inventory List')}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3 z-10"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); Gettransferinventory(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('SKU/Supplier/Ship to ...')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                {
                  <button className="ms-auto com-btn" onClick={addlists}>
                    {t('Add')}
                  </button>
                }
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="tbl_inventoryTransfer_list"
                    className="common-table first-row-gray w-100"

                  >
                    <Tablehead
                      tablehead={transferinventorylisthead}
                      tblName={'tbl_inventory_addstocktransfer'}
                      setfilterby={setfilterby}
                    />
                    <tbody id="allsamedata">
                      {transferinventorylist
                        ? transferinventorylist.map((result, key) => {
                          return (
                            <tr
                              id={`common-main-table-wrap-${result?.id}`}
                              className={
                                result?.rowclass ? result?.rowclass : ''
                              }
                              key={key}
                            >
                              <td>
                                <div className="com-check">
                                  <input
                                    id={result?.id}
                                    data-id={result?.id}
                                    className="inventorystocklist"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor={result?.id}
                                    className="com-check-label"
                                  />
                                </div>
                              </td>
                              <td>{key + 1}</td>
                              <td className="table-pro-img">
                                <img
                                  src={
                                    result?.main_image
                                      ? result?.main_image
                                      : base_url_assets +
                                      'images/icon/camera_profile.png'
                                  }
                                  alt=""
                                />
                              </td>
                              <td className="po-no">
                                {result?.SKU ? result?.SKU : ''}
                              </td>
                              <td>
                                {result?.item_name ? result?.item_name : ''}
                              </td>
                              <td>
                                {result?.collection_name
                                  ? result?.collection_name
                                  : ''}
                              </td>
                              <td>
                                {result?.metal_name ? result?.metal_name : ''}
                              </td>
                              <td>
                                {result?.stone_name ? result?.stone_name : ''}
                              </td>
                              <td>
                                {result?.size_name ? result?.size_name : ''}
                              </td>

                              {result.status_type === 'stock' ? (
                                <td className="light-blue-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'transit' ? (
                                <td className="status-green-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'warehouse' ? (
                                <td className="status-gray-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'reserve' ? (
                                <td className="yellow-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'repair_order' ? (
                                <td className="purple-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : (
                                <td className="danger-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              )}

                              <td>{result?.po_QTY ? result?.po_QTY : ''}</td>
                              <td> {result?.price ? result?.price : ''}</td>
                              <td className="text-end">
                                {result?.aging ? result?.aging : '0'}
                              </td>
                              <td
                                className="location-td"
                                data-location={
                                  result?.location_name
                                    ? result?.location_name
                                    : ''
                                }
                              >
                                <span className="link-color">
                                  {' '}
                                  {result?.location_name
                                    ? result?.location_name
                                    : ''}{' '}
                                </span>{' '}
                                <span className="text-center location-td-sub">
                                  {result?.warehouse_location_name
                                    ? result?.warehouse_location_name
                                    : ''}
                                </span>
                              </td>
                              <td>
                                {result.status_type === 'stock' ? (
                                  <div className="status-dot light-blue-bg  tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Stock')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'transit' ? (
                                  <div className="status-dot green-bg  tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Transit')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'warehouse' ? (
                                  <div className="status-dot status-gray-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Warehouse')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'reserve' ? (
                                  <div className="status-dot yellow-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Reserve')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'repair_order' ? (
                                  <div className="status-dot purple-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {'Repair Order'}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="status-dot danger-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {'Sales Order'}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td>
                                {result?.POS_Order ? result?.POS_Order : ''}
                              </td>
                              <td className="text-danger">
                                {result?.customer_name
                                  ? result?.customer_name
                                  : ''}
                              </td>
                              <td className="text-danger">
                                {result?.delivery_date
                                  ? formatDate(result?.delivery_date)
                                  : ''}
                              </td>
                              <td className="text-danger">
                                {result?.due_days ? result?.due_days : ''}
                              </td>
                              <td>{result?.remark ? result?.remark : '-'}</td>
                              <td className="text-center">
                                {result?.design === 1 ? (
                                  <img
                                    className="pdf-image"
                                    src={
                                      base_url_assets +
                                      'images/icons/pdf-icon.png'
                                    }
                                    alt=""
                                  />
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                          )
                        })
                        : ''}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>
                          {transferinventorylist
                            ? transferinventorylist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation + parseInt(val.po_QTY)),
                              0,
                            )
                            : ''}
                        </td>
                        <td>
                          {transferinventorylist?.reduce(
                            (interation, val) =>
                            (interation =
                              interation +
                              parseFloat(toUnformatPrice(val.price))),
                            0,
                          )}{' '}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={transferinventorylisthead}
        tblName={'tbl_inventory_addstocktransfer'}
        calldatatable={Gettransferinventory}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <Advancedfiltermodel></Advancedfiltermodel>
    </React.Fragment>
  )
}

export default Inventorylist
