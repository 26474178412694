import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { _ApiStore } from "../api/_call";
import Cashregistermodal from "../../common/Modals/cashregistermodals/Cashregistermodal";
import Closeregistermodal from "../../common/Modals/cashregistermodals/Closeregistermodal";
import { _posApi } from '../../../pos/common/api/_call';
import { posOrganisationSettings, posstatics } from '../../../source/app/appSlice';
import { useDispatch } from 'react-redux';
import TableLoader from "../../../admin/common/TableLoader";
const StoreModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataloder, setDataloder] = useState(true)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [getStorelist, setGetStorelist] = useState();
  const [closestore, setClosestore] = useState(false);
  const [cashstatus, setCsahstatus] = useState()
  const [closure_id, setClosure_id] = useState()
  const FormatPosPrice = useCallback(async () => {

    try {
      const postdata = {};
      let countdata = await _posApi(postdata, `api/v1/POS/common/getPOSSettings`);
      if (countdata.code === 200) {
        dispatch(posOrganisationSettings(countdata?.data));
        let data = JSON.stringify(countdata.data)
        localStorage.setItem('getPOSSettings', data);

      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [dispatch])
  const getAuth = useCallback(async (loc, voc, curr, data, Closure, Closure_id) => {
    try {
      const postdata = {
        "currency": curr,
        "voucher_id": voc,
        "location_id": loc
      };
      let listdata = await _ApiStore(postdata, `api/v1/POS/auth/generatePOSAuthToken`);
      if (listdata.code === 200) {
        localStorage.setItem('Pos-Authorization', listdata?.data?.token);
        FormatPosPrice()
        if (data === "onedata") {
          // navigate("/app/pos/menu");
          setClosestore(true);
          setCsahstatus(Closure);
          setClosure_id(Closure_id)
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line
  }, [FormatPosPrice])

  const getStores = useCallback(async () => {
    setDataloder(true)
    try {
      const postdata = {};
      let listdata = await _ApiStore(postdata, `api/v1/POS/getVoucherList`);
      if (listdata.code === 200) {
        setDataloder(false)
        if (listdata?.data?.length) {
          if (listdata?.data?.length > 1) {
            setGetStorelist(listdata?.data);
          } else {
            setGetStorelist(listdata?.data);
            getAuth(listdata?.data[0]?.location_id, listdata?.data[0]?.voucher_id, listdata?.data[0]?.currency, "onedata", listdata?.data[0]?.Closure, listdata?.data[0]?.Closure_id);
            localStorage.setItem('pos-loc', JSON.stringify({
              "loc": listdata?.data[0]?.location_id,
              "voc": listdata?.data[0]?.voucher_name,
              "curr": listdata?.data[0]?.currency,
              "symbol": listdata?.data[0]?.symbol
            }));
            dispatch(posstatics({
              "loc": listdata?.data[0]?.location_id,
              "voc": listdata?.data[0]?.voucher_name,
              "curr": listdata?.data[0]?.currency,
              "symbol": listdata?.data[0]?.symbol
            }));
          }
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line
  }, [getAuth, navigate]);

  useEffect(() => {
    getStores();
  }, [getStores]);
  const setposstatics = (location_id, voucher_id, currency, voucher_name, symbol) => {
    getAuth(location_id, voucher_id, currency);
    localStorage.setItem('pos-loc', JSON.stringify({ "loc": location_id, "voc": voucher_name, "curr": currency, "symbol": symbol }));
    dispatch(posstatics({ "loc": location_id, "voc": voucher_name, "curr": currency, "symbol": symbol }));
  }
  return (
    <>
      {getStorelist?.length > 1 ?

        <div
          className={closestore ? "store-modal d-none" : "store-modal"}>
          <div className="store-modal-body">
            <a href="/app/admin/dashboard" className="store-modal-close btn-style">
              <img src={base_assets + "/images/pos/close-btn.png"} alt="" />
            </a>
            <div className="store-modal-body-header">
              {t("Select Store")}
            </div>
            <div className="store-modal-body-ct">
              {getStorelist ? getStorelist.map((result, key) => {
                return (
                  <div className="store-modal-body-ct-row" onClick={() => {
                    setposstatics(result?.location_id, result?.voucher_id, result?.currency, result?.voucher_name, result?.symbol)
                    setClosestore(true); setCsahstatus(result?.Closure); setClosure_id(result?.Closure_id)
                  }} key={key}>
                    <span className="store-modal-body-ct-row-location">{result?.voucher_name ? result?.voucher_name : ''}</span>
                    <span className="store-modal-body-ct-row-img">
                      <img src={base_assets + "images/flags/" + result?.currency.toLowerCase() + ".png"} alt="" />
                    </span>
                  </div>
                );
              }) : ""}
            </div>
          </div>
        </div>

        :

        <div className="store-modal">
          <div className="store-modal-body">
            <button className="store-modal-close btn-style" >
              <img src={base_assets + "/images/pos/close-btn.png"} alt="" />
            </button>
            <div className="store-modal-body-ct">
              {dataloder ? <TableLoader /> :
                <p className="voucher-text">{t("NO voucher Add!!")}</p>}

            </div>
          </div>
        </div>
      }

      {cashstatus === 0 ? <Cashregistermodal /> : cashstatus === 2 ? <Closeregistermodal Closure_id={closure_id} /> : cashstatus === 1 ? navigate("/app/pos/menu") : ''}


    </>
  )
}

export default StoreModal;