import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import CheckoutBlank from '../checkout/checkoutBlank'
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function'
import TableColumnModal from '../../components/common/modules/tableColumn'
import { Tablehead } from '../../components/common/modules/Tablehead'
import { posreservedaimondorder_head } from '../../components/common/Tablehead'
import { _Api } from '../common/api/_call'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import CustomerSearchModal from '../common/module/customerSearch'
import './ReserveOrder.scss'
import { store } from '../../source/index'
const ReserveListDiamond = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation()
  let navigate = useNavigate()
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const [TransactionDate, setTransactionDate] = useState()
  const [orderid, setOrderid] = useState('')
  const [newcolumndata, setnewcolumndata] = useState(
    posreservedaimondorder_head,
  )
  const customer = useSelector((state) => state.app.customer)
  const [, setfilterby] = useState('')
  const [searchproductstatus, setsearchproductstatus] = useState(false)
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [showpayment, setShowpayment] = useState(false)
  const [orderlist, setOrderlist] = useState()
  const [UpdatedCart, setUpdatedCart] = useState()
  const selectTransactionDate = (input) => {
    setTransactionDate(input)
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'reserveorderdaimond_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const GetCartdaimondorder = async () => {
    UnSetdataTable('com-data-area_table')
    try {
      const postdata = {
        order_type: 'reserve_diamond',
        customer_id: customer?.id,
        date: TransactionDate,
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/getdimondOrderList`,
      )
      if (res.code === 200) {
        SetdataTable('com-data-area_table', '5', newcolumndata, [1])
        setOrderlist(res?.data)
      } else {
        SetdataTable('com-data-area_table', '5', newcolumndata, [1])
        navigate('/app/pos/reserve/ReserveListDiamond')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const GetCartdetailorder = async () => {
    try {
      const postdata = {
        order_id: orderid,
        order_type: 'reserve_diamond',
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data)
        setShowcheckout('sell')
      } else {
        navigate('/app/pos/reserve/ReserveListDiamond')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (customer?.id) {
      GetCartdaimondorder()
    } else {
      GetCartdaimondorder()
    }
    // eslint-disable-next-line
  }, [customer?.id, TransactionDate])

  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="com-data-area">
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left reserveorder-header">
                    <Link to="/app/pos/reserve/diamondreserve">
                      <img
                        className="reserveorder-header-backbtn"
                        src={base_assets + 'images/pos/backbtn.png'}
                        alt=""
                      ></img>
                    </Link>
                    <h1 className="heading reserveorder-header-heading">
                      {t('Reserve Diamond')}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="com-data-area-header delivered-header">
                <div className="delivered-header-left custom-order-search">
                  <CustomerSearchModal></CustomerSearchModal>
                </div>
                <div className="customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date">
                  <DatePicker
                    className="placeholder-up-input-field"
                    selected={TransactionDate}
                    onChange={selectTransactionDate}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    placeholderText="MM/DD/YYYY"
                    autoComplete='none'
                  />
                  <img
                    className="placeholder-up-input-field-calender"
                    src={base_assets + 'images/icons/calendar-green.svg'}
                    alt=""
                  />
                </div>
              </div>
              <div className="com-data-area-inner">
                <div
                  className="main-body-main-table-wrap reserveorder-table vertical-scroll-table mt-3"
                  id="table-scroll"
                >
                  <table
                    id="com-data-area_table"
                    className="common-table first-row-gray w-100 reserveorder-table-main"
                  >
                    <Tablehead
                      tablehead={posreservedaimondorder_head}
                      tblname={'reserveorderdaimond_tbl'}
                      setfilterby={setfilterby}
                    />
                    <tbody>
                      <>
                        {orderlist?.length
                          ? orderlist.map((result, key) => {
                            return (
                              <>
                                <tr
                                  onClick={() => setOrderid(result?.order_id)}
                                >
                                  <td className="row-check">
                                    <div className="com-check radio">
                                      <input
                                        type="radio"
                                        id={result?.order_id}
                                      />
                                      <label
                                        htmlFor={result?.order_id}
                                        className="com-check-label"
                                      />
                                    </div>
                                  </td>
                                  <td>{key + 1}</td>
                                  <td>{result?.transaction_date}</td>
                                  <td className="link-color">
                                    {result?.order_no}
                                  </td>
                                  <td>
                                    <div className="pro-img mx-auto">
                                      <img src={result?.shape} alt="" />
                                    </div>
                                  </td>
                                  <td>{result?.stock_id}</td>
                                  <td className="link-color">
                                    {result?.Certification}
                                  </td>
                                  <td>
                                    {toFormatPrice(result?.amount, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td className="main-green-text">
                                    {toFormatPrice(result?.payment, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td className="danger-text">
                                    {toFormatPrice(result?.balance_due, {
                                      addSymbol: true,
                                    })}
                                  </td>
                                  <td className="danger-text">
                                    {result?.delivery_date}
                                  </td>
                                  <td>{result?.location_name}</td>
                                  <td>
                                    {result?.status === 5 ? (
                                      <label className="status-tab production-col">
                                        Reserve
                                      </label>
                                    ) : (
                                      <label className="status-tab gray-tab">
                                        Sold Out
                                      </label>
                                    )}
                                  </td>
                                </tr>
                              </>
                            )
                          })
                          : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {toFormatPrice(
                            orderlist
                              ? orderlist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.amount)),
                                0,
                              )
                              : '',
                            { addSymbol: true },
                          )}
                        </td>
                        <td className="main-green-text">
                          {toFormatPrice(
                            orderlist
                              ? orderlist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.payment)),
                                0,
                              )
                              : '',
                            { addSymbol: true },
                          )}
                        </td>
                        <td className="danger-text">
                          {toFormatPrice(
                            orderlist
                              ? orderlist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.balance_due)),
                                0,
                              )
                              : '',
                            { addSymbol: true },
                          )}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="com-data-area-footer-btn">
                  <button
                    onClick={() => GetCartdetailorder()}
                    className={
                      orderid !== '' && showcheckout === 'blank'
                        ? 'com-btn ms-auto'
                        : 'com-btn ms-auto btn-disabled'
                    }
                  >
                    {t('Submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                order_id={orderid ? orderid : ''}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={posreservedaimondorder_head}
        tblname={'reserveorderdaimond_tbl'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={GetCartdaimondorder}
      ></TableColumnModal>
    </>
  )
}
export default ReserveListDiamond
