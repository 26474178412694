import React, { useState, useEffect, useCallback } from 'react'
import Chart from 'react-apexcharts'
import { LocationList } from "../../common/Commongraphql"
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const TotalSales = (props) => {
  const { setDashboardhideshow, setDefaultset } = props
  const [fixed, setFixed] = useState(false)
  const { t } = useTranslation();
  const [locationarrayList, setLocationarrayList] = useState([])
  const [completeDataObject, setCompliteDataObject] = useState({
    "location": {
      name: "All",
      id: ""
    },
    "timeset": "Monthly",
    "graphtype": "Chart"
  })
  const [totalsalesdata, setTotalsalesdata] = useState()
  const [tabledata, setTabledata] = useState()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const totalsalesgraph = useCallback(async () => {
    try {
      let postdata = {
        "graphtype": completeDataObject?.graphtype,
        "duration": completeDataObject?.timeset,
        "location": completeDataObject?.location?.id
      }
      let res = await _Api(postdata, `api/v1/dashboard/totalsalesStatics`)
      if (res?.code === 200) {
        if (postdata?.graphtype === "Chart") {
          setTotalsalesdata(res?.data)
        } else if (postdata?.graphtype === "Table") {
          setTabledata(res?.statics)
        }
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [completeDataObject]);
  useEffect(() => {
    totalsalesgraph()
  }, [completeDataObject, totalsalesgraph])
  const totalSalesChart = {
    series: totalsalesdata?.data?.length ? totalsalesdata?.data : [],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        categories: totalsalesdata?.xaxis
      },
      yaxis: {
        categories: [0, 1000, 2000, 3000, 4000],
      },
      colors: totalsalesdata?.color,
    },
  }
  let time_periods = ["Daily", "Weekly", "Monthly", "Yearly"]
  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100 ' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Total Sales")}
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-right-radius-0 fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {completeDataObject?.location?.name ? completeDataObject?.location?.name : "Source Type"}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    "location": {
                      name: "All",
                      id: ""
                    }
                  }))}>
                <span className="dropdown-item">{t("All")}</span>
              </li>
              {/* location dropdown */}
              {
                locationarrayList?.length ? locationarrayList.map((result, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() =>
                        setCompliteDataObject(prevState => ({
                          ...prevState,
                          "location": result
                        }))}>
                      <span className="dropdown-item">{t(`${result?.name ? result?.name : ""}`)}</span>
                    </li>
                  )
                }) : ""
              }
            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-left-radius-0 fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t(`${completeDataObject?.timeset ? completeDataObject?.timeset : ""}`)}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              {time_periods?.length ? time_periods.map((result, key) => {
                return (
                  <li key={key}
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        timeset: result
                      }))}>
                    <span className="dropdown-item">{t(`${result}`)}</span>
                  </li>
                )
              }) : ""}
            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    graphtype: "Chart"
                  }))}>
                <span className="dropdown-item">
                  <i className="fa fa-line-chart me-2" aria-hidden="true"></i>{' '}
                  {t("Chart")}
                </span>
              </li>
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    graphtype: "Table"
                  }))}>
                <span className="dropdown-item">
                  <i className="fa fa-table me-2" aria-hidden="true"></i> {t("Table")}
                </span>
              </li>
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li onClick={() => {
                setDashboardhideshow(prevState => ({
                  ...prevState,
                  TotalSales: 0
                }));
                setDefaultset(true)
              }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area py-3">
          {completeDataObject?.graphtype === "Chart" ?
            <div className='dashboard-area-cards-single-chart-area-inner' id="totalSalesChart">
              <Chart
                options={totalSalesChart.options}
                series={totalSalesChart.series}
                type="line"
                height={350}
              />
            </div> : completeDataObject?.graphtype === "Table" ?
              <div className='dashboard-area-cards-single-chart-area-table'>
                <table className='w-100'>
                  <thead>
                    <tr>
                      <th className='text-center'>{t("Refund")} ({tabledata?.currency})</th>
                      <th className='text-center'>{t("Exchange")}({tabledata?.currency})</th>
                      <th className='text-center'>{t("Sales")} ({tabledata?.currency})</th>
                      <th className='text-center'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabledata?.statics?.length ? tabledata?.statics.map((result, key) => {
                      return (
                        <tr key={key}>
                          <td className='text-center'>{result?.refund ? result?.refund : 0}</td>
                          <td className='text-center'>{result?.exchange ? result?.exchange : 0}</td>
                          <td className='text-center'>{result?.posSales ? result?.posSales : ""}</td>
                          <td className='text-center'>{result?.label ? result?.label : ""}</td>
                        </tr>
                      )
                    }) : ""}

                  </tbody>
                </table>
              </div> : ""}
        </div>
      </div>
      <LocationList setLocationarrayList={setLocationarrayList} />
    </div>
  )
}

export default TotalSales
