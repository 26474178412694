import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import "./catalog.scss";
import { UnSetdataTable } from "../common/api/setdatatable";
import CheckoutSell from '../checkout/checkoutSell';
import CheckoutBlank from '../checkout/checkoutBlank';
import ProductListGrid from '../common/productList/productListGrid';
import ProductListTable from '../common/productList/productListTable';
import { _Api } from "../common/api/_call";
import CatalogNavbar from "./CatalogNavbar"
import { useSelector } from 'react-redux';
import Loading from '../common/module/Loading/Loading';
import CheckoutPayment from '../checkout/checkoutPayment';
import NoOrdersYet from '../common/module/NoOrdersYet/NoOrdersYet';
import { useTranslation } from 'react-i18next';
const Products = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const navigate = useNavigate();
  const [pNavid, setproductNavid] = useState();
  const [isviewOption, setIsviewOption] = useState(false);
  const { state } = useLocation();
  const [searchsku, setSearchsku] = useState();
  const [skip, setSkip] = useState(0);
  const [, setdeletestatus] = useState(false)
  const [Totalproduct, setTotalproduct] = useState(0);
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [showpayment, setShowpayment] = useState(false);
  const [UpdatedCart, setUpdatedCart] = useState();
  let checksale = localStorage.getItem('showcheckout') === "sell" ? true : false
  const [searchproductstatus, setsearchproductstatus] = useState(checksale || false);
  const [Sale, setSale] = useState('top_sale');
  const [productlist, setProductlist] = useState()
  const [isCrown, setCrown] = useState(state?.Optiondetails === true ? true : false);
  const [inv_orderid, setInv_orderid] = useState()
  const [inv_ordertype, setInv_ordertype] = useState()
  const [by_repair_order, setBy_repair_order] = useState()
  const [by_customorder, setBy_customorder] = useState()
  const customer = useSelector((state) => state.app.customer);
  const [Loadingstatus, setLoadingstatus] = useState(false);
  const [shoremorelodar, setShowmorelodar] = useState(false)
  const [addparmission, setAddparmission] = useState(0)
  const [custom_data, setCustom_data] = useState()
  const [finalprodata, setFinalprodata] = useState([])
  const [cartstockid, setCartstockid] = useState()
  const [uuid, setUuid] = useState('');
  const [stockid, setStockid] = useState('');
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const itemdaata = localStorage.getItem("itemdata")
  const parsedObject = JSON.parse(itemdaata);

  useEffect(() => {
    if (parmissiondatashow?.POS?.View || parmissiondatashow?.WishList?.View)
      setAddparmission(parmissiondatashow?.POS?.Add)
  }, [parmissiondatashow]);
  const crownClick = event => {

    setCrown(current => !current);


  };
  const generateUuid = () => {
    const newUuid = crypto.randomUUID();
    setUuid(newUuid);
  };
  const getproducts = async () => {
    UnSetdataTable("pos_order_table")
    if (shoremorelodar) {
      setShowmorelodar(true)
    } else {
      setLoadingstatus(true);
    }
    try {
      const postdata = {
        "Item_id": state?.itemid ? state?.itemid : parsedObject?.Item_id,
        "type": isCrown === true ? 'catalog' : 'inventory',
        "limit": 20,
        "skip": skip,
        "sale": Sale,
        "search": searchsku ? searchsku : state?.search,
        "category_id": state?.NavId ? state?.NavId : parsedObject?.category_id,
        "customer_id": customer?.id ? customer?.id : ""
      };
      let data = JSON.stringify(postdata)
      localStorage.setItem('itemdata', data);
      let res = await _Api(postdata, `api/v1/POS/home/itemData`);
      if (res.code === 200) {
        generateUuid()
        if (res?.data?.length || res?.soldOutOrder?.length) {
          if (skip === 0) {
            let finalData = []
            setCustom_data(res?.soldOutOrder)
            finalData = res?.soldOutOrder.concat(res?.data);
            setFinalprodata(finalData)
            setProductlist(res?.data);

          } else {
            let data = []
            data = productlist.concat(res?.data)
            setCustom_data(custom_data.concat(res?.soldOutOrder))
            setProductlist(productlist.concat(res?.data));
            setFinalprodata(data.concat(res?.soldOutOrder))
          }
        } else {
          setProductlist([])
          setFinalprodata([])
          setCustom_data([])
        }
        setTotalproduct(res?.total);
        if (shoremorelodar) {
          setShowmorelodar(false)
        } else {
          setLoadingstatus(false);
        }

      } else {
        setIsviewOption(false)
        setTotalproduct(res?.total);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const filterbyname = () => {
    if (searchsku) {
      setSkip(0)
      getproducts();
    } else {
      setSkip(0)
      getproducts();
    }
  };
  const GetCartdetailpark = async () => {
    try {
      let postdata;
      if (state?.parkid || state?.order_id) {
        postdata = {
          "park_id": state?.parkid,
          "order_id": state?.order_id
        }
      } else {
        postdata = {}
      }

      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setShowcheckout('sell');
      } else {
        navigate('/app/pos/catalog/CollectionList');
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getproducts();
    // eslint-disable-next-line
  }, [Sale, isCrown, skip, customer?.id]);

  useEffect(() => {
    if (pNavid) {
      navigate('/app/pos/catalog/CollectionList', {
        state: { navids: pNavid, filtertype: isCrown },
      })
    }
    else if (state?.parkid || state?.order_id) {

      GetCartdetailpark();
    }
    // eslint-disable-next-line
  }, [pNavid, state?.parkid]);
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area">
              <div className='catalog-area-header-area'>
                <CatalogNavbar setproductNavid={setproductNavid} navid={state?.NavId} location={"/app/pos/products"} pNavid={pNavid} />
                <div className='catalog-area-header-area-control'>
                  <div className='catalog-area-header-area-control-left'>
                    <button className={isCrown ? 'catalog-area-header-area-control-left-btn warehouse' : 'catalog-area-header-area-control-left-btn'} onClick={() => { crownClick(); setSkip(0) }} >
                      <img src={isCrown === false ? base_assets + "images/pos/icons/warehouse.png" : base_assets + "images/pos/icons/crown-outline.png"} alt="" />
                    </button>
                  </div>
                  <div className='catalog-area-header-area-control-right'>
                    <div className="com-search-area">
                      <button className='com-search-area-left-img'>
                        <img src={base_assets + "images/pos/icons/scan-icon.png"} alt="" />
                      </button>
                      <input type="text" value={searchsku} onKeyPress={(e) => e.key === "Enter" && filterbyname()}
                        onChange={(e) => setSearchsku(e.target.value)} placeholder={t("Scan barcode, Search SKU")} />
                      <img className='com-search-area-img' onClick={() => { filterbyname(); setSkip(0) }} src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                    </div>
                    <div className='catalog-area-header-area-control-right-view dropdown'>
                      <button className=" dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img className='catalog-area-header-area-control-right-view-img' src={isviewOption ? base_assets + "images/pos/icons/list-view-icon.png" : base_assets + "images/pos/icons/grid-icon.png"} alt="" />
                        <div className='catalog-area-header-area-control-right-view-arrow'>
                          <div className='catalog-area-header-area-control-right-view-arrow-img'>
                            <img src={base_assets + "images/pos/icons/arrow-up.png"} alt="" />
                          </div>
                          <div className='catalog-area-header-area-control-right-view-arrow-img'>
                            <img src={base_assets + "images/pos/icons/arrow-down.png"} alt="" />
                          </div>
                        </div>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li className='hover-effect'>
                          <Link className="dropdown-item hover-effect-inner hover-effect-inner" onClick={() => setIsviewOption(false)}>
                            <img className='catalog-area-header-area-control-right-view-img hover-effect-inner-img hover-effect-inner-img-g' src={base_assets + "images/pos/icons/grid-icon.png"} alt="" />
                            <img className='catalog-area-header-area-control-right-view-img hover-effect-inner-img hover-effect-inner-img-w' src={base_assets + "images/pos/icons/grid-icon-gray.png"} alt="" />
                          </Link>
                        </li>
                        <li className='hover-effect'>
                          <Link className="dropdown-item hover-effect-inner hover-effect-inner" onClick={() => setIsviewOption(true)}>
                            <img className='catalog-area-header-area-control-right-view-img hover-effect-inner-img hover-effect-inner-img-g' src={base_assets + "images/pos/icons/list-view-icon.png"} alt="" />
                            <img className='catalog-area-header-area-control-right-view-img hover-effect-inner-img hover-effect-inner-img-w' src={base_assets + "images/pos/icons/list-view-icon-gray.png"} alt="" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="catalog-area-ct bg-white">
                <div className='product-filter-bar'>
                  <div className='product-filter-bar-left common-select'>
                    <label className='product-filter-bar-left-label'>{state?.itemname}</label>
                    <div className="dropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="graph" data-bs-toggle="dropdown" aria-expanded="false">
                        <img className='' src={base_assets + "images/pos/icons/graph-icon.png"} alt="" />
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="graph">
                        <li className="dropdown-item" onClick={() => setSale('top_sale')}>
                          <img className='' src={base_assets + "images/pos/icons/top-sale.png"} alt="" /> {t("Top sales")}
                        </li>
                        <li className="dropdown-item" onClick={() => setSale('lowest_sale')}>
                          <img className='' src={base_assets + "images/pos/icons/up-sale.png"} alt="" /> {t("lowest sales")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {
                  (custom_data?.length || (productlist?.length && Loadingstatus === false)) ? isviewOption ?
                    <ProductListTable
                      ProductListTable={productlist}
                      filter={isCrown}
                      setProductListTable={setProductlist}
                      setShowcheckout={setShowcheckout}
                      itemid={state?.itemid}
                      NavId={state?.NavId}
                      setsearchproductstatus={setsearchproductstatus}
                      setUpdatedCart={setUpdatedCart}
                      parkid={state?.parkid}
                      order_id={state?.order_id}
                      order_edit={state?.order_edit}
                      order_type={state?.order_type}
                      customer_id={customer?.id}
                      setInv_orderid={setInv_orderid}
                      inv_orderid={inv_orderid}
                      setInv_ordertype={setInv_ordertype}
                      inv_ordertype={inv_ordertype}
                      setBy_customorder={setBy_customorder}
                      by_customorder={by_customorder}
                      setBy_repair_order={setBy_repair_order}
                      by_repair_order={by_repair_order}
                      addparmission={addparmission}
                      finalprodata={finalprodata}
                      setUuid={setUuid}
                      uuid={uuid}
                      cartstockid={cartstockid}
                      setStockid={setStockid}
                    />
                    :
                    <ProductListGrid
                      ProductListGrid={productlist}
                      setProductListGrid={setProductlist}
                      filter={isCrown}
                      setShowcheckout={setShowcheckout}
                      itemid={state?.itemid}
                      NavId={state?.NavId}
                      itemname={state?.itemname}
                      setsearchproductstatus={setsearchproductstatus}
                      parkid={state?.parkid}
                      order_id={state?.order_id}
                      setUpdatedCart={setUpdatedCart}
                      order_edit={state?.order_edit}
                      order_type={state?.order_type}
                      setInv_orderid={setInv_orderid}
                      inv_orderid={inv_orderid}
                      setInv_ordertype={setInv_ordertype}
                      setBy_repair_order={setBy_repair_order}
                      inv_ordertype={inv_ordertype}
                      by_repair_order={by_repair_order}
                      by_customorder={by_customorder}
                      setBy_customorder={setBy_customorder}
                      addparmission={addparmission}
                      custom_data={custom_data}
                      finalprodata={finalprodata}
                      setUuid={setUuid}
                      uuid={uuid}
                      cartstockid={cartstockid}
                      setStockid={setStockid} />
                    : productlist?.length === 0 ? <NoOrdersYet /> : <Loading />
                }
              </div>
              {shoremorelodar ?
                <div className="border-bottom-0 d-block main-green-text mx-auto my-4 spinner-border flex-shrink-0" role="status"></div> : ""}

              {
                finalprodata?.length !== 0 && finalprodata?.length !== Totalproduct ?
                  skip <= Totalproduct ?
                    <div className="show-more">
                      <span className='show-more-displaying'>{t("Displaying")} {finalprodata?.length ? finalprodata?.length : 0} {t("of")} {Totalproduct} {t("items")}</span>
                      <button className={shoremorelodar ? "btn show-more-btn disabled" : "btn show-more-btn"} onClick={() => {
                        setSkip(skip + 20); setShowmorelodar(true)
                      }}>
                        {t("Show more")} <img src={base_assets + "images/icons/add-icon.png"} alt="" />
                      </button>
                    </div> : ""
                  : ''
              }
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                setdeletestatus={setdeletestatus}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
                parkid={state?.parkid}
                order_id={state?.order_id}
                ordertype={'pos_order'}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
                inv_orderid={inv_orderid}
                inv_ordertype={inv_ordertype}
                by_repair_order={by_repair_order}
                by_customorder={by_customorder}
                getproducts={getproducts}
                uuid={uuid}
                setCartstockid={setCartstockid}
                stock_id={stockid}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                order_edit={state?.order_edit}
                order_id={inv_orderid ? inv_orderid : state?.order_id}
                order_type={inv_ordertype === "custom_order" ? "pos_order" : inv_ordertype ? inv_ordertype : state?.order_type}
                by_custom_order={by_customorder}
                by_repair_order={by_repair_order}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div >
    </>

  )

}

export default Products;