import { useEffect, useRef } from "react";
import { _Api } from "../../common/api";
import { useNavigate } from "react-router-dom";
const LogOut = (props) => {
    const navigate = useNavigate();
    const dataFetched = useRef();
    const handleLogout = async () => {
        try {
            let postdata = {}
            _Api(postdata, `auth/logout`)
            localStorage.removeItem('ecomaccesstoken');
            navigate("/");
            window.location.reload()
        }
        catch (err) {
            console.log(err, "err")
        }


    };
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true
        handleLogout();
    });

};
export default LogOut;