import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./menu.scss";
import { _Api } from "../common/api/_call";
import CheckoutBlank from "../checkout/checkoutBlank";
import { useTranslation } from 'react-i18next';
import calendersvgw from "../../assets/images/pos/icons/calendersvgw.svg"
import calendarsvg from "../../assets/images/pos/icons/calendarsvg.svg"
const Menu = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const { t } = useTranslation();
  const getCartClear = async () => {
    localStorage.removeItem("showcheckout")
    try {
      const postdata = {
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/cartClear`);
      if (res?.code === 200) {
        localStorage.removeItem("showcheckout")

      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    getCartClear();
  }, [])
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="menu-block">
              <h1 className="main-heading">{t("MENU")}</h1>
              <div className="menu-block-row">
                <div className={parmissiondatashow?.pos_mix_and_match?.View || parmissiondatashow?.pos_custom_order?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/custom/custom"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/custom.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/custom-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Custom")}</span>
                  </Link>
                </div>

                <div className={parmissiondatashow?.pos_repair_order?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/repair"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/repair.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/repair-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Repair")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.pos_exchange?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/exchange"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/exchange.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/exchange-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Exchange")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.pos_refund?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/refund"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/refund.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/refund-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Refund")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.pos_customer_reports?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/customer/customer"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/user-big.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/user-big-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Customer")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.pos_reserve?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/reserve"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/reserve.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/reserve-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Reserve")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.pos_coupon?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/giftCard"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/gift-card.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/gift-card-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">
                      {t("gift-card")}
                    </span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.Receive?.View || parmissiondatashow?.pos_deposit?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/receive"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/receive.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/receive-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Receive")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.WishList?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/wishlist"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/heart-big.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/heart-big-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Wishlist")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.POS?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/catalog/CollectionList"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/catalog-big.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/catalog-big-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Catalog")}</span>
                  </Link>
                </div>
                <div className={parmissiondatashow?.Quotation?.View &&
                  generalsettings?.data?.generalsetup?.isQuotation === '1' ? "menu-block-row-col hover-effect" : "d-none"}>
                  <Link
                    to="/app/pos/Quotation"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={base_assets + "images/pos/icons/quotation.png"}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={base_assets + "images/pos/icons/quotation-w.png"}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Quotation")}</span>
                  </Link>
                </div>
                <div className="menu-block-row-col hover-effect" >
                  <Link
                    to="/app/pos/appointment"
                    className="menu-block-row-col-a hover-effect-inner"
                  >
                    <span className="menu-block-row-col-a-img position-relative">
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-g"
                        src={calendarsvg}
                        alt=""
                      />
                      <img
                        className="hover-effect-inner-img hover-effect-inner-img-w"
                        src={calendersvgw}
                        alt=""
                      />
                    </span>
                    <span className="menu-block-row-col-a-title">{t("Appointment")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
