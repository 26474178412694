import React, { useEffect } from 'react';
import { Route, Outlet } from 'react-router-dom';
import '../i18n.js'
import { useTranslation } from 'react-i18next';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AdminHeader from "../admin/common/header/Header"
import AdminSidebar from "../admin/common/sidebar/Sidebar";
import MasterRoutes from './MasterRoutes.js';
import OrganizationRoutes from './OrganizationRoutes.js'
import LocationRoutes from './LocationRoutes.js'
import SettingRoutes from './SettingRoutes.js'
import SetupRoutes from './SetupRoutes.js'
import CustomerRoutes from './CustomerRoutes.js'
import AdminProfile from "../admin/Profile.js"
import AdminDashboard from "../admin/dashboard/AdminDashboard.js"
import CustomSwitch from '../CustomSwitch.js';
import PageNotFound from '../components/common/404.js';
import SessionModal from "../admin/setup/email/SessionModal.js"
import { useSelector } from 'react-redux';
import CalendarRoutes from './CalendarRoutes.js';
import UpdateTitle from '../source/UpdateTitle.js';

const AdminReactActive = function () {
    const { i18n } = useTranslation();
    const Fullhandle = useFullScreenHandle();
    localStorage.setItem('middlewareLocations', '');

    return (
        <FullScreen handle={Fullhandle}>
            <div className='main-layout-apps'>
                <div className="main-content-wrapper">
                    <AdminSidebar />
                    <div className='main-content-wrapper-right'>
                        <AdminHeader Fullhandle={Fullhandle} i18n={i18n} />
                        <Outlet />
                    </div>
                </div>
            </div>
        </FullScreen>
    );
};

export default function AdminRoutes() {
    const ModulePermission = useSelector((state) => state.app.ModulePermission);
    useEffect(() => {
        localStorage.removeItem("customerdetails")
        localStorage.removeItem("salesdetails")
    }, [])
    return (

        <CustomSwitch>
            <Route element={<AdminReactActive />}>
                {ModulePermission?.Dashboard &&
                    <Route path="dashboard" element={<> <UpdateTitle title="Dashboard" /> <AdminDashboard></AdminDashboard></>} />}
                {ModulePermission?.Organization &&
                    <Route path="organization/*" element={<> <UpdateTitle title="Organization" /><OrganizationRoutes /></>} />}
                {ModulePermission?.Master &&
                    < Route path="master/*" element={<> <UpdateTitle title="Masters" /><MasterRoutes /></>} />}
                {ModulePermission?.Location &&
                    <Route path="location/*" element={<> <UpdateTitle title="Locations" /><LocationRoutes /></>} />}
                {ModulePermission?.GIS_setup &&
                    <Route path="setup/*" element={<> <UpdateTitle title="Setup" /><SetupRoutes /></>} />}
                <Route path="calendar/*" element={<> <UpdateTitle title="Calendar" /><CalendarRoutes /></>} />
                <Route path="customers/*" element={<> <UpdateTitle title="Customer" /><CustomerRoutes /></>} />
                <Route path='profile' element={<> <UpdateTitle title="Profile" /><AdminProfile /></>} > </Route>
                <Route path="/deepaks" element={<SessionModal />} />
                <Route path="settings/*" element={<> <UpdateTitle title="Settings" /><SettingRoutes /></>} />
                <Route path='*' element={<> <UpdateTitle title="Page Not Found" /><PageNotFound /></>} > </Route>

            </Route>
        </CustomSwitch>

    );
}
