import React, { useState, useEffect } from 'react'
import RightArrow from '../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Confirmationmodel from '../common/modules/confirmationmodel'
import { error, loading, Updateloading } from '../common/helpers/toastify'
import { LocationList } from '../common/Commongraphql'
import { _Api } from '../api/_call'
import { Pagination } from '../common/Pagination'
import { useSelector } from 'react-redux';
import TableLoader from '../common/TableLoader'
const SetupDevice = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation()
  const [editItem, setEditItem] = useState(false)
  const [devicelist, setDevicelist] = useState([])
  const [LocationarrayList, setLocationarrayList] = useState([])
  const [dataid, setDtataid] = useState('')
  const [filtername, setFiltername] = useState('')
  const [limit, setLimit] = useState(100)
  const [showloader, setShowloader] = useState(false);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [completeDataObject, setCompliteDataObject] = useState({
    name: '',
    deviceType: '',
    client: '',
    description: '',
    blindKey: '',
    blindTimeStamp: '',
    status: '',
    location: [],
  })
  const deviceOption = [
    {
      value: 'RFID Scanner',
      label: 'RFID Scanner',
      icon: (
        <img src={base_assets + 'images/admin/icons/scaner-icon.png'} alt="" />
      ),
    },
    {
      value: 'Tablet',
      label: 'Tablet',
      icon: (
        <img src={base_assets + 'images/admin/icons/tablet-icon.png'} alt="" />
      ),
    },
    {
      value: 'Mobile',
      label: 'Mobile',
      icon: (
        <img
          src={base_assets + 'images/admin/icons/cell-phone-icon.png'}
          alt=""
        />
      ),
    },
  ]
  const onImageupload = (e) => {
    const [file] = e.target.files
    const reader = new FileReader(file)
    reader.onloadend = async () => {
      let GetloadingID = loading()
      try {
        const postdata = {
          image: reader.result,
          name: 'profile',
        }
        let resfront = await _Api(postdata, `api/v1/App/User/uploadimage`)
        if (resfront.code === 200) {
          Updateloading(t, GetloadingID, t('Successfully Upload Image'))
          onChangeValueUpdate('devicelogo', resfront?.data?.images?.url)
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
    if (file) {
      reader?.readAsDataURL(file);
    }
  }
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject((prevState) => ({
      ...prevState,
      [keyName]: value,
    }))
  }
  const getlimit = (limit) => {
    setLimit(limit)
    getdevice(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const filterbyname = () => {
    getdevice(currentPage)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })
  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    getdevice(current)
  }
  const getdevice = async (current, firstlimit) => {
    setShowloader(true);
    try {
      var skipNumber = current ? (current - 1) * limit : 0
      let postdata = {
        search: filtername,
        limit: firstlimit ? firstlimit : limit,
        skip: skipNumber,
      }
      let res = await _Api(postdata, `api/v1/App/device/get-list`)
      if (res?.code === 200) {
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1,
        })
        setDevicelist(res?.data)
        setShowloader(false);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const adddevice = async () => {
    if (completeDataObject?.status === '0') {
      completeDataObject.status = '0'
    } else {
      completeDataObject.status = '1'
    }
    if (!completeDataObject?.name) {
      error(t('Name field is required'))
      return
    }
    if (!completeDataObject?.deviceType) {
      error(t('Device field is required'))
      return
    }
    if (!completeDataObject?.client) {
      error(t('Client field is required'))
      return
    }
    if (!completeDataObject?.description) {
      error(t('Description field is required'))
      return
    }
    if (!completeDataObject?.blindKey) {
      error(t('BlindKey field is required'))
      return
    }
    if (!completeDataObject?.blindTimeStamp) {
      error(t('BlindTimeStamp field is required'))
      return
    }
    if (!completeDataObject?.location?.length) {
      error(t('location field is required'))
      return
    }
    if (!completeDataObject?.status) {
      error(t('Status field is required'))
      return
    }
    let GetloadingID = loading()
    try {
      let postdata = completeDataObject
      let res
      if (completeDataObject?.id) {
        res = await _Api(postdata, `api/v1/App/device/edit`)
      } else {
        res = await _Api(postdata, `api/v1/App/device/add`)
      }
      if (res.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getdevice()
        setEditItem(false)
        setCompliteDataObject({
          name: '',
          deviceType: '',
          client: '',
          description: '',
          blindKey: '',
          blindTimeStamp: '',
          status: '',
          location: [],
        })
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const deletedevice = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        id: dataid,
      }
      let res = await _Api(postdata, `api/v1/App/device/delete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        getdevice()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const viewdevice = async (viewdataid) => {
    try {
      let postdata = {
        id: viewdataid,
      }
      let res = await _Api(postdata, `api/v1/App/device/view`)
      if (res?.code === 200) {
        setCompliteDataObject(res?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const handleCountryChange = (e, c) => {
    let arr = { ...completeDataObject }
    if (!arr.location) {
      arr.location = []
    }
    if (e.target.checked) {
      let location = arr.location
      location.push(c.id)

      setCompliteDataObject(arr)
    } else {
      if (arr.location) {
        arr.location = arr.location.filter((item) => item !== c.id)
        setCompliteDataObject(arr)
      }
    }
  }
  useEffect(() => {
    getdevice()
    // eslint-disable-next-line
  }, [])
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            {t("Setup")} <RightArrow /> <span className="">{t("Device")}</span>
          </h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
          <Select
            className="limit-select mx-3"
            options={selectOptions}
            value={{ label: limit, value: limit }}
            onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
          />
          <div className="com-search-bar position-relative">
            <img
              onClick={filterbyname}
              className="com-search-bar-search-img"
              src={base_assets + 'images/admin/icons/gray-search.png'}
              alt=""
            />
            <input
              placeholder="Search Device"
              className="h-100 border-0 bg-transparent"
              onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
              onChange={(e) => setFiltername(e.target.value)}
            />
          </div>
          <div className="print-export-dropdown dropdown">
            <button
              className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={base_assets + 'images/admin/icons/gray-ellipsis.png'}
                alt=""
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li className={parmissiondatashow?.Device?.Print ? "dropdown-item print-dropdown-item" : "d-none"}>
                <div className="dropdown-item-img">
                  <img
                    src={base_assets + 'images/icons/printer-icon.png'}
                    alt=""
                  />
                </div>
                <span>{t('print')}</span>
              </li>
              <li className="dropdown-item">
                <div className="dropdown-item-img">
                  <img
                    src={base_assets + 'images/icons/export-x-icon.png'}
                    alt=""
                  />
                </div>
                <span>{t('export')}</span>
              </li>
              <li
                className="dropdown-item"
              >
                <div className="dropdown-item-img">
                  <img src={base_assets + 'images/icons/export.png'} alt="" />
                </div>
                <span>{t('Import')}</span>
              </li>
            </ul>
          </div>
          <button
            className={parmissiondatashow?.Device?.Add ? "com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3" : "d-none"}
            onClick={() => {
              setEditItem(true)
              setCompliteDataObject({
                name: '',
                deviceType: '',
                client: '',
                description: '',
                blindKey: '',
                blindTimeStamp: '',
                status: '',
                location: [],
              })
            }}
          >
            <img
              className="me-2"
              src={base_assets + 'images/admin/icons/add-icon-white.png'}
              alt=""
            />
            {t('Create')}
          </button>
        </div>
      </div>
      <div className="row">
        <div className={editItem ? 'col-md-6' : 'col-md-12'}>
          <div
            className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
          >
            <table
              id="my_customer"
              className="com-custom-table-tag table align-middle"
            >
              <thead>
                <tr className="text-nowrap align-middle">
                  <th>#</th>
                  <th>{t("Image")}</th>
                  <th>{t("Name")}</th>
                  <th>{t("Device Type")} </th>
                  <th>{t("Client")}</th>
                  <th>{t("Description")}</th>
                  <th>{t("Location")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {devicelist?.map((result, key) => {
                  return (
                    <tr className="text-nowrap" key={key}>
                      <td>{key + 1}</td>
                      <td className="px-2 text-center">
                        <img
                          className="table-user-img rounded-circle"
                          src={result?.device_logo}
                          alt=""
                        />
                      </td>
                      <td>{result?.name ? result?.name : '-'}</td>
                      <td>{result?.type ? result?.type : '-'}</td>
                      <td>{result?.client ? result?.client : '-'}</td>
                      <td>
                        {result?.description ? result?.description : '-'}
                      </td>
                      <td>
                        {result?.locationName ? result?.locationName : '-'}
                      </td>
                      <td>
                        {result?.status === '1' ? (
                          <span className="active-badge  rounded-pill px-2 py-1 fw-semibold">
                            {' '}
                            <span className="active-badge-dot me-2"></span>
                            {t('Active')}
                          </span>
                        ) : (
                          <span className="inactive-badge  rounded-pill px-2 py-1 fw-semibold">
                            {' '}
                            <span className="inactive-badge-dot me-2"></span>
                            {t('InActive')}
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        <img
                          className={parmissiondatashow?.Device?.Edit ? "cursor-pointer me-4" : "d-none"}
                          onClick={() => {
                            setEditItem(true)
                            viewdevice(result?._id)
                          }}
                          src={base_assets + 'images/admin/icons/Edit.png'}
                          alt=""
                        />
                        <img
                          className={parmissiondatashow?.Device?.Delete ? "cursor-pointer" : "d-none"}
                          data-bs-toggle="modal"
                          data-bs-target="#createPurchaseModal"
                          onClick={() => setDtataid(result?._id)}
                          src={
                            base_assets + 'images/admin/icons/Delete.png'
                          }
                          alt=""
                        />
                      </td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
            {showloader && <TableLoader />}
          </div>
          {devicelist.length > 0 ? (
            <Pagination
              total={totalPages}
              current={currentPage}
              pagination={(crPage) => handlePaginations(crPage)}
            />
          ) : (
            ''
          )}
        </div>
        <div className={editItem ? 'col-md-6 d-block' : 'd-none'}>
          <div className="edit-item-area bg-white row">
            <div className="col-md-9 px-0">
              <div className="edit-item-area-body">
                <div className="edit-item-area-body-circle-img text-center mb-4">
                  <div className="edit-item-area-body-circle-img-choose">
                    <input
                      id="chooseImg"
                      type="file"
                      className="d-none"
                      onChange={onImageupload}
                      accept="image/*"
                    />
                    <label
                      htmlFor="chooseImg"
                      className="rounded-circle overflow-hidden"
                    >
                      <img
                        className=""
                        src={
                          completeDataObject?.devicelogo
                            ? completeDataObject?.devicelogo
                            : base_assets + 'images/blank-img-2.png'
                        }
                        alt=""
                      />
                    </label>
                  </div>
                  <label>
                    {t('File: PNG, JPG, JPEG')} <br /> {t('Maximum size 200KB')}
                  </label>
                </div>
                <div className="row">
                  <div className="col-md-12 ps-0">
                    <div className="row mx-0">
                      <div className="col-md-12">
                        <div className="v3-input-group">
                          <label className="v3-input-group-label">
                            {t('Name')} <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="v3-input-group-input w-100"
                            value={completeDataObject?.name}
                            onChange={(e) =>
                              onChangeValueUpdate('name', e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="v3-input-group">
                          <label className="v3-input-group-label">
                            {t("Device")} <sup>*</sup>
                          </label>
                          <Select
                            className="select-set select w-100 p-0"
                            placeholder=""
                            options={deviceOption}
                            value={{
                              value: completeDataObject?.deviceType,
                              label: completeDataObject?.deviceType,
                            }}
                            onChange={(e) =>
                              onChangeValueUpdate('deviceType', e.value)
                            }
                            classNamePrefix="common-select"
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div className="common-select-icon">
                                {e.icon} <span>{e.label}</span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="v3-input-group">
                          <label className="v3-input-group-label">
                            {t('Client')} <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="v3-input-group-input w-100"
                            value={completeDataObject?.client}
                            onChange={(e) =>
                              onChangeValueUpdate('client', e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="v3-input-group">
                          <label className="v3-input-group-label">
                            {t('Description')} <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="v3-input-group-input w-100"
                            value={completeDataObject?.description}
                            onChange={(e) =>
                              onChangeValueUpdate('description', e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="v3-input-group">
                          <label className="v3-input-group-label">
                            {t("Blind Key")} <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="v3-input-group-input w-100"
                            value={completeDataObject?.blindKey}
                            onChange={(e) =>
                              onChangeValueUpdate('blindKey', e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="v3-input-group">
                          <label className="v3-input-group-label">
                            {t("Blind Time Stamp")} <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="v3-input-group-input w-100"
                            value={completeDataObject?.blindTimeStamp}
                            onChange={(e) =>
                              onChangeValueUpdate(
                                'blindTimeStamp',
                                e.target.value,
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="v3-input-group">
                          <label className="v3-input-group-heading">
                            {t("LOCATIONS / OUTLETS")}
                          </label>
                        </div>
                        <div className="d-flex gap-5">
                          <div className="d-flex flex-wrap">
                            {LocationarrayList?.length
                              ? LocationarrayList.map((element, index) => {
                                return (
                                  <div className="mb-4 me-4" key={index}>
                                    <input
                                      className="form-check-input fill-com-check me-3 cursor-pointer"
                                      type="checkbox"
                                      id={element?.id}
                                      checked={completeDataObject.location.includes(
                                        element?.id,
                                      )}
                                      onChange={(e) =>
                                        handleCountryChange(e, element)
                                      }
                                    />
                                    <label
                                      className="form-check-label cursor-pointer"
                                      htmlFor={element?.id}
                                    >
                                      {element?.name}
                                    </label>
                                  </div>
                                )
                              })
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 pe-0">
              <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-3">
                <button
                  className="square-btn square-btn-white btn-close focus-none"
                  onClick={() => setEditItem(false)}
                ></button>
                <button className="square-btn" onClick={() => adddevice()}>
                  <img
                    src={base_assets + 'images/icons/white-save.png'}
                    alt=""
                  />
                </button>
              </div>
              <div className="form-check form-switch ps-0 ms-2">
                <label className="form-check-label" htmlFor="mixmatch">
                  {t("Status")}
                </label>
                <input
                  className="form-check-input float-none ms-2"
                  type="checkbox"
                  id="mixmatch"
                  onChange={(e) =>
                    onChangeValueUpdate('status', e.target.checked ? '1' : '0')
                  }
                  checked={completeDataObject?.status === '1' ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmationmodel
        statement={'Delete'}
        handler_submit={deletedevice}
      ></Confirmationmodel>
      <LocationList setLocationarrayList={setLocationarrayList} />
    </div>
  )
}

export default SetupDevice
