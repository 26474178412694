import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { _Api } from '../../api/_call'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import {
  error,
  loading,
  Updateloading,
} from '../../common/helpers/toastify'
import DateTime from '../../common/DateTime'
import TableColumnModal from '../../common/modules/tableColumn'
import { Tablehead } from '../../common/modules/Tablehead'
import { purchaseorderoutstandinghead } from '../../common/Tablehead'
import { createpurchase } from '../../transaction/subTables/purchase/createpurchase'
import { toFormatPrice, toUnformatPrice } from '../../common/helpers/function'
import { PODateRange } from '../../common/helpers/podaterange'
import SocialModal from '../../common/modules/SocialModal/SocialModal'
import Filtermodal from "../../common/modules/Filtermodal";
import { Pagination } from "../../common/Pagination";
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from "react-select";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import TableLoader from '../../../admin/common/TableLoader'
const PurchaseOrderOutstanding = (props) => {
  const { t } = useTranslation();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const dataFetched = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  let total_qty = 0
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL
  let navigate = useNavigate()
  const [datefilter, setDatefilter] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(
    purchaseorderoutstandinghead,
  )
  const [filtername, setFiltername] = useState('')
  const [check, setCheck] = useState('')
  const [poutstandinglist, setPoutstandinglist] = useState('')
  const [limit, setLimit] = useState(100)
  const [socialurl, setSocialurl] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [SelectAllStatus, setSelectAllStatus] = useState()
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [datalodaer, setDatsLodaer] = useState(false)
  const [filterdata, setFilterdata] = useState()
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_purchaseorder_outstanding',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const getsharepurchaseoutstanding = async () => {
    setShareloader(true)
    try {
      const postdata = {
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/inventory/print/POoutstanding`,
      )

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getprintpurchaseoutstanding = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/inventory/print/POoutstanding`,
      )

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }

  const setSubTable = async (event, id, locationid, key) => {

    var e = document.querySelector('#table-btn_' + id)
    var classList_ = e.classList
    if (event.target.checked || classList_ === 'rotate-90') {
      setCheck(locationid)
      let pooutstandingdata = [...poutstandinglist]
      const postdata = {
        po_order_id: id,
      }
      let data = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPUOutStandingListDetails`,
      )
      var html_ = createpurchase(data, t, key)
      pooutstandingdata
        .filter((item) => item.id === id)
        .forEach((result) => {
          result.subTable = html_
        })
      setPoutstandinglist(pooutstandingdata)
    } else {
      classList_.remove('rotate-90')
      setCheck('')
      let pooutstandingdata = [...poutstandinglist]
      pooutstandingdata
        .filter((item) => item.id === id)
        .forEach((result) => {
          result.subTable = null
        })
      setPoutstandinglist(pooutstandingdata)
    }
  }
  const filterbyname = (search) => {
    Getpurchaseoutstanding(currentPage, limit, filterdata, search);
    setstate({ ...state, currentPage: 1 });
  };
  const getlimit = (limit) => {
    setLimit(limit);
    Getpurchaseoutstanding(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    Getpurchaseoutstanding(current, "");

  };
  const [excuteTable, setexcuteTable] = useState(false)
  const Getpurchaseoutstanding = useCallback(async (current, firstlimit, filter, search) => {
    setexcuteTable(false)
    setFilterdata(filter)
    var expand_col = document.querySelector('.table_btn_expand_col')
    if (expand_col) {
      expand_col.classList.remove('rotate-90')
    }
    try {
      setDatsLodaer(true)
      UnSetdataTable('tbl_purchaseOrderOutstanding_list')
      const postdata = {
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
        item: filter?.item ? filter?.item : [],
        stone: filter?.stone ? filter?.stone : [],
        collection: filter?.collection ? filter?.collection : [],
        metal: filter?.metal ? filter?.metal : [],
        location: filter?.location ? filter?.location : [],
      }
      let resoutstanding = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPuOutStandingList`,
      )
      if (resoutstanding?.code === 200) {
        setexcuteTable(true)
        SetdataTable('tbl_purchaseOrderOutstanding_list', '4', newcolumndata, 0)
        setstate({
          ...state,
          totalPages: Math.ceil(resoutstanding?.count / postdata.limit),
          currentPage: current ? current : 1
        });
        setPoutstandinglist(resoutstanding?.data)
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable('tbl_purchaseOrderOutstanding_list', '4', newcolumndata, 0)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }, [newcolumndata, limit, state,]);
  const setSubTableforsku = async (id, locationid) => {
    var row = document.querySelector('#common-main-table-wrap-' + id)
    var e = document.querySelector('#table-btn_' + id)
    var classList_ = e.classList

    if (classList_.contains('rotate-90')) {
      classList_.remove('rotate-90')
      setCheck('')
      let pooutstandingdata = [...poutstandinglist]
      pooutstandingdata
        .filter((item) => item.id === id)
        .forEach((result) => {
          result.subTable = null
        })
      setPoutstandinglist(pooutstandingdata)
    } else {
      classList_.add('rotate-90')
      setCheck(locationid)
      let pooutstandingdata = [...poutstandinglist]
      const postdata = {
        po_order_id: id,
      }
      let data = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPUOutStandingListDetails`,
      )
      if (data?.code === 200) {
        var html_ = createpurchase(data, t)
        pooutstandingdata
          .filter((item) => item.id === id)
          .forEach((result) => {
            result.subTable = html_
          })
        setPoutstandinglist(pooutstandingdata)
        var checkRowSub = setInterval(function () {
          var rowSub = document.querySelector('#common-sub-table-wrap-' + id)
          if (rowSub) {
            $(row).after(rowSub)
            clearInterval(checkRowSub)
          }
        }, 100)
      }
    }
  }

  const callsubmit = () => {
    var getcheckbox = document.querySelectorAll('.createPurchaseselect')
    let po_order_id = ''
    let location_id = ''
    let supplier_id = ''
    let warehouse_id = ''
    const array = []
    
    for (var i = 0; i < getcheckbox.length; i++) {
     
      if (getcheckbox[i].checked) {
        console.log(getcheckbox[i].id, getcheckbox[i].getAttribute('data-supplier'), getcheckbox[i].getAttribute('data-warehouse'),  "hryvhrvkon")
        array.push(getcheckbox[i].id)
        po_order_id = getcheckbox[i].getAttribute('data-po_order_id')
        location_id = getcheckbox[i].getAttribute('data-location')
        supplier_id = getcheckbox[i].getAttribute('data-supplier')
        warehouse_id = getcheckbox[i].getAttribute('data-warehouse')
      }
    }
    if (array.length) {
      navigate('/app/inventory/purchaseOrdersOutstanding', {
        state: {
          purchaseoustandingids: array,
          po_order_id: po_order_id,
          location_id: location_id,
          supplier_id: supplier_id,
          warehouse_id: warehouse_id,
        },
      })
    } else {
      error(t('No item Selected!!'))
    }
  }
  const addrow = async (id, locationid) => {
    var row = document.querySelector('#common-main-table-wrap-' + id)
    var e = document.querySelector('#table-btn_' + id)
    var classList_ = e.classList
    classList_.add('rotate-90')
    setCheck(locationid)
    var pooutstandingdata = [...poutstandinglist]
    const postdata = {
      po_order_id: id,
    }
    let data = await _Api(
      postdata,
      `api/v1/Inventory/purchase/getPUOutStandingListDetails`,
    )
    if (data?.code === 200) {
      var html_ = createpurchase(data)
      pooutstandingdata
        .filter((item) => item.id === id)
        .forEach((result) => {
          result.subTable = html_
        })
      setPoutstandinglist(pooutstandingdata)
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id)
        if (rowSub) {
          $(row).after(rowSub)
          clearInterval(checkRowSub)
        }
      }, 100)
    }
  }

  const removerow = () => {
    let AllRows = document.querySelectorAll('.subtabletest')
    setCheck('')
    for (let j = 0; j < AllRows.length; j++) {
      let id = AllRows[j].getAttribute('data-id')
      var e = document.querySelector('#table-btn_' + id)
      var classList_ = e.classList
      classList_.remove('rotate-90')
    }
  }
  useEffect(() => {
    let AllRows = document.querySelectorAll('.subtabletest')
    if (SelectAllStatus) {
      for (let i = 0; i < AllRows.length; i++) {
        let id = AllRows[i].getAttribute('data-id')
        let locationid = AllRows[i].getAttribute('data-locationid')
        addrow(id, locationid)
      }
    } else {
      removerow()
    }
    // eslint-disable-next-line
  }, [SelectAllStatus])

  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    Getpurchaseoutstanding()
  }, [Getpurchaseoutstanding])

  const puimport = async () => {
    const select = document.querySelectorAll(".createPurchaseselect");
    const mainselected = []
    for (let i = 0; i < select.length; i++) {
      if (select[i].checked) {
        let puid = select[i].getAttribute("data-po_order_id");
        mainselected.push(puid);
      }
    }
    const handleValidation = (e) => {
      let formIsValid = true
      if (!mainselected.length) {

        formIsValid = false
        error(t('No item Selected!!'))
      }
      return formIsValid
    }
    if (handleValidation()) {
      let GetloadingID = loading();
      try {
        let postdata = {
          po_order_id: mainselected
        }
        let res = await _Api(postdata, "api/v1/Inventory/purchase/getPUOutStandingListExport")
        if (res?.code === 200) {
          if (res?.data?.length) {
            downloadexclsheet(res?.data)
            Updateloading(t, GetloadingID, t("Excel Export Successfully!!"));
          }
        }
      }
      catch (err) {
        console.log(err, "Err")
      }
    }
  }
  const downloadexclsheet = async (puexportdata) => {
    let header_values = [];
    let bodyarray = [];
    header_values.push("Product ID", "SKU", "StockID", "Metal", "Stone", "Size", "Location", "QTY", "Price", "PO_NO", "POS_NO", "Remark", 'Gwt', "Nwt", "Stone", "Shape", "Cut", "Clarity", "Color", "Pointer", "Size", "Pcs", "Weight", "Setting_Type", "C_Type", "C_No"
    )

    if (puexportdata.length) {
      puexportdata.forEach((result, key) => {

        if (result?.Stones?.length) {
          bodyarray.push([result?.ID, result?.SKU, result?.stock_id, result?.metal_name, result?.stone_name, result?.size_name,
          result?.location_name, result?.po_QTY, result?.price, result?.po_no, result?.pos_no, result?.remark, result?.GrossWt,
          result?.NetWt, result?.Stones[0]?.stone_name, result?.Stones[0]?.shape_name, result?.Stones[0]?.cut_name, result?.Stones[0]?.clarity_name, result?.Stones[0]?.color_name, result?.Stones[0]?.Pointer, result?.Stones[0]?.size_name, result?.Stones[0]?.Pcs, result?.Stones[0]?.Cts, result?.Stones[0]?.setting_name, result?.Stones[0]?.certificate?.type,
          result?.Stones[0]?.certificate?.number]
          )
        } else {
          bodyarray.push([result?.ID, result?.SKU, "", result?.metal_name, result?.stone_name, result?.size_name, "",
          result?.order_qty, result?.price, result?.pos, result?.remark, result?.GrossWt, result?.NetWt])
        }
        if (result?.Stones?.length) {
          result?.Stones.forEach((res, key) => {
            if (key >= 1) {
              bodyarray.push(["", "", "", "", "", "", "", "", "", "", "", "", "", "", res?.stone_name, res?.shape_name, res?.cut_name,
                res?.clarity_name, res?.color_name, res?.Pointer, res?.size_name, res?.Pcs, res?.Cts, res?.setting_name,
                res?.certificate?.type, res?.certificate?.number]
              )
            }
          })
        }
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'PU IMPORT');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "create_purchase");
  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="top-heading-area-left">
                  <Link
                    to="/app/inventory/transactionPurchase"
                    className="page-back-btn"
                  >
                    <img
                      src={base_assets + 'images/icons/arrow-left.png'}
                      alt=""
                    />
                  </Link>
                  <div
                    className="main-body-top-tab-bar-left-col nav nav-pills"
                    role="tablist"
                  >
                    <button className="main-body-top-tab-bar-left-col-a nav-link active">
                      <span className="main-body-top-tab-bar-left-col-a-text">
                        {t('Purchase Order Outstanding')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className={parmissiondatashow?.inventory_transaction_purchase?.Add ? "main-body-top-tab-bar-right-col" : "d-none"}>
                  <button
                    className="main-body-top-tab-bar-right-col-a border-0"
                    onClick={callsubmit}
                  >
                    <img
                      src={base_assets + 'images/icons/plus-circle-white.png'}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t('Create PU')}
                    </span>
                  </button>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <button
                    className="main-body-top-status-bar-filter-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#filterinventory"
                  >
                    <img
                      src={base_assets + 'images/icons/search-outline.png'}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("PO No./StockID/SKU/Metal/Stone")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_url_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <PODateRange setDatefilter={setDatefilter} />
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          base_url_assets + 'images/icons/ellipsis-circular.png'
                        }
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className="dropdown-item print-dropdown-item"
                        onClick={(e) => {
                          getprintpurchaseoutstanding(e.target.value)
                        }}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + 'images/icons/printer-icon.png'
                            }
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li className="dropdown-item" onClick={() => puimport()}>
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + 'images/icons/export-x-icon.png'
                            }
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#socialModal"
                        onClick={() => getsharepurchaseoutstanding()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + 'images/icons/share-icon.png'
                            }
                            alt=""
                          />
                        </div>
                        <span>{t('share')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + 'images/icons/column-icon.png'
                            }
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap">
                  <table
                    id="tbl_purchaseOrderOutstanding_list"
                    className="common-table product-base-table table-striped w-100"

                  >

                    <Tablehead
                      tablehead={purchaseorderoutstandinghead}
                      tblName={'tbl_purchaseorder_outstanding'}
                      setfilterby={""}
                      setShowoption={setSelectAllStatus}
                    />
                    <tbody>
                      <>
                        {poutstandinglist
                          ? poutstandinglist.map((result, key) => {
                            total_qty += result?.quantity ? result?.quantity : 0
                            return (
                              <React.Fragment key={key}>
                                <tr
                                  id={`common-main-table-wrap-${result?.id}`}
                                  key={key}
                                  className={
                                    check
                                      ? check === result?.location_id
                                        ? 'subtabletest'
                                        : 'row-inactive subtabletest'
                                      : 'subtabletest'
                                  }
                                  data-id={result.id}
                                  data-locationid={result?.location_id}
                                >
                                  <td>
                                    <div className="com-check">
                                      <input
                                        id={key}
                                        type="checkbox"
                                        className="po_Outst_checkbox"
                                        value={result?.id}
                                        onChange={(e) =>
                                          setSubTable(
                                            e,
                                            result.id,
                                            result?.location_id,
                                            key
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={key}
                                        className="com-check-label"
                                      />
                                    </div>
                                  </td>
                                  <td>{key + 1}</td>
                                  <td>
                                    {result?.createdAt ? result?.createdAt : '-'}
                                  </td>
                                  <td className="po-no sku-col">
                                    {result?.po_no ? result?.po_no : '-'}
                                  </td>
                                  <td className="danger-text">
                                    {result?.supplier_name ? result?.supplier_name : '-'}
                                  </td>
                                  <td className="danger-text">
                                    {result?.location_name ? result?.location_name : '-'}
                                  </td>
                                  <td
                                    className="sku-col table_btn_expand_col"
                                    onClick={() =>
                                      setSubTableforsku(
                                        result.id,
                                        result?.location_id,
                                      )
                                    }
                                    id={'table-btn_' + result.id}
                                  >
                                    <div className="td-icons-wrap">
                                      <div>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            'images/icons/right_green_button.png'
                                          }
                                          alt=""
                                        />
                                        <span>
                                          {result?.sku ? result?.sku : '-'}
                                        </span>
                                      </div>
                                      {result.allocatable ? <div className="td-icons-wrap-link">
                                        <img
                                          className="img "
                                          src={
                                            base_assets +
                                            'images/icons/link-icon.svg'
                                          }
                                          alt=""
                                        />
                                      </div> : ""}
                                      <div className='tooltip-area-up'>
                                        {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user"><img
                                          className="img"
                                          src={
                                            base_assets +
                                            'images/icons/gray-user.png'
                                          }
                                          alt=""
                                        /> <span>{result?.pos_Qty}</span>
                                        </div> : ""}
                                        <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.pos_no}<br />{result?.delivery_date}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="col-highlight">
                                    {result?.quantity ? result?.quantity : '-'}
                                  </td>
                                  <td className="danger-text text-center">
                                    {result?.delivery_date ? result?.delivery_date : '-'}
                                  </td>
                                  <td className="text-end">
                                    {result?.due_days ? result?.due_days : '-'}
                                  </td>
                                  <td>
                                    {result?.total_price
                                      ? toFormatPrice(result?.total_price, { addSymbol: true })
                                      : '-'}
                                  </td>
                                  <td> {result.status.priority === 0 ?
                                    <label className="status-tab low-priority">{t("Low")}</label>
                                    : result.status.priority === 1 ?
                                      <label className="status-tab medium-priority">{t("Medium")}</label>
                                      : result.status.priority === 2 ?
                                        <label className="status-tab high-priority">{t("High")}</label>
                                        : ''
                                  }</td>
                                </tr>
                                {excuteTable ? (
                                  <>
                                    {typeof result.subTable == 'undefined' ? (result.subTable = '') : result.subTable}
                                  </>
                                ) : null}
                              </React.Fragment>
                            )
                          })
                          : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{total_qty}</td>
                        <td />
                        <td />
                        <td>
                          {toFormatPrice(
                            poutstandinglist
                              ? poutstandinglist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.total_price)),
                                0,
                              )
                              : '', { addSymbol: true }
                          )}
                        </td>
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  {datalodaer && <TableLoader />}
                </div>
              </div>
              {poutstandinglist.length > 0 ? (
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  pagination={(crPage) => handlePaginations(crPage)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <SocialModal shareUrl={socialurl} shareloader={shareloader} />
      <TableColumnModal
        tablehead={purchaseorderoutstandinghead}
        tblName={'tbl_purchaseorder_outstanding'}
        calldatatable={Getpurchaseoutstanding}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <Filtermodal submitfiltter={Getpurchaseoutstanding} limit={limit} custom_filter_enable={false} />
    </React.Fragment>
  )
}

export default PurchaseOrderOutstanding;
