import React from 'react'
import { useState } from 'react';
import theTap from '../../default/assets/images/the-tape-method 1.png'
import ScaleImg from '../../default/assets/images/disegno-misura-anello-1-1024x615 2.png'
import { useTranslation } from "react-i18next";
const SizeGuideModal = () => {
    const [activeTab, setActiveTab] = useState('tab1');

const { t } = useTranslation();
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    return (
        <div>
            <div className="modal fade right cart-modalRight" id="sizeGuideBack" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-right">
                    <div className="modal-content SizeGuied">
                        <div className="modal-header cart-modalRight-headerBox">
                            <h5 className="SizeGuied-headtitle" id="sizeGuideBackLabel">{t("Size Guide")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="m-32 w-100 d-flex justify-content-center">
                                <table className="w-100">
                                    <thead>
                                        <tr className="align-middle ">
                                            <th className="SizeGuied-headtitle text-center pt-24 pb-24 ">{t("US")}</th>
                                            <th className="SizeGuied-headtitle text-center pt-24 pb-24">{t("EU")}</th>
                                            <th className="SizeGuied-headtitle text-center pt-24 pb-24">{t("Diameter")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center pb-32">5</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">49</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">15.6 mm</td>
                                        </tr>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center pb-32">5.5</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">50</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">15.9 mm</td>
                                        </tr>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center pb-32">6</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">52</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">16.6 mm</td>
                                        </tr>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center pb-32">6.5</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">53</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">16.9 mm</td>
                                        </tr>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center pb-32">7</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">54</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">17.2 mm</td>
                                        </tr>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center pb-32">7.5</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">55</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">17.6 mm</td>
                                        </tr>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center  pb-32">8</td>
                                            <td className="SizeGuied-subtitle text-center  pb-32">57</td>
                                            <td className="SizeGuied-subtitle text-center  pb-32">18.1 mm</td>
                                        </tr>
                                        <tr className="text-nowrap align-middle ">
                                            <td className="SizeGuied-subtitle text-center pb-32">8.5</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">58</td>
                                            <td className="SizeGuied-subtitle text-center pb-32">18.5 mm</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='w-full   ' >
                                <h1 className='SizeGuied-headtitle mb-24 text-center' >{t("How to Measure")}</h1>
                                <div className="d-flex justify-content-center flex-column">
                                    <div className=' d-flex justify-content-center gap-4  ' >


                                        <button
                                            className={activeTab === 'tab1' ? 'SizeGuied-activeSizeButton SizeGuied-subtitle  ' : 'SizeGuied-notactiveSizeButton SizeGuied-subtitle '}
                                            onClick={() => handleTabClick('tab1')}
                                        >
                                           {t(" The Hands-on Method")}
                                        </button>
                                        <button
                                            className={activeTab === 'tab2' ? 'SizeGuied-activeSizeButton SizeGuied-subtitle' : 'SizeGuied-notactiveSizeButton SizeGuied-subtitle'}
                                            onClick={() => handleTabClick('tab2')}
                                        >
                                            {t("Measure on Existing Ring")}
                                        </button>
                                    </div>

                                    <div id="tab1" className={activeTab === 'tab1' ? 'd-flex justify-content-center w-100  ' : 'd-none'}>
                                        <div style={{ padding: '0px 32px', }} className='d-flex justify-content-center flex-column' >
                                            <img style={{ marginTop: '20px' , marginBottom:'10px' }} src={theTap} alt='The Tap' />
                                            <p className='SizeGuied-subtitle  ' >1.{t(" Grab a tape measure, length of string or strip of paper")}.</p>
                                            <p className='SizeGuied-subtitle' >2. {t("Wrap it around the finger you’ll wear your ring on - if you’re creating a ring stack")}, {t("consider where on your finger the ring will sit and mask the place where it joins")}.</p>
                                        </div>

                                    </div>
                                    <div id="tab2" className={activeTab === 'tab2' ? 'd-flex justify-content-center w-100' : 'd-none'}>
                                        <div style={{ padding: '0px 32px', }} className='d-flex justify-content-center flex-column gap-4  ' >
                                            <img src={ScaleImg} alt='The Tap' />
                                            <p className='SizeGuied-subtitle  ' >1.  {t("Choose a ring that already fits your finger well")}.</p>
                                            <p className='SizeGuied-subtitle' >2.  {t("Measure the interior diameter of the ring in millimeters(mm)")}.</p>
                                            <p className='SizeGuied-subtitle' >3. {t("Use the measurement chart above to match the size of your ring to the closet size in mm")}.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SizeGuideModal