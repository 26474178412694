import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../common/helpers/formatDate";


export const PODateRange = (props) => {

    const { setDatefilter } = props
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(new Date());
    const datePickerRef = useRef(null);
    const changedate = (update) => {
        setStartDate(update[0]);
        setEndDate(update[1]);
        setDatefilter([update[0], update[1]]);
    }
    useEffect(() => {
        const prevMonth = new Date();
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setStartDate(prevMonth);
    }, []);
    return (
        <React.Fragment>


            <div className="dateranger position-relative" >
                <div className=" main-body-top-status-bar-multi-date-picker-wrap " onClick={() => datePickerRef.current.setOpen(true)}>
                    <input type="text" value={startDate ? formatDate(startDate) : "DD/MM/YYYY"} readOnly />
                    <span className="date-arrow">
                        <img
                            src={base_assets + "images/icons/calendar_arrow.svg"}
                            className="calendar_arrow" alt=""
                        />
                    </span>
                    <input type="text" value={endDate ? formatDate(endDate) : "DD/MM/YYYY"} readOnly />
                    <span className="main-body-top-status-bar-multi-date-picker-wrap-calender">
                        <img
                            src={base_assets + "images/icons/calendar-green.svg"}
                            alt=""
                        />
                    </span>
                </div>
                <DatePicker
                    selectsRange={true}
                    monthsShown={2}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        changedate(update);
                    }}
                    className='d-flex'
                    dateFormat="dd-mm-yyyy"
                    ref={datePickerRef}
                    autoComplete='none'
                />
            </div>

        </React.Fragment>
    );

}
// export default DateRange;