import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function BacklogFilter({handleShowFilter, backlogFilters, setBacklogFilters, type, location}) {
    const [filters, setFilters] = useState(backlogFilters);
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [valueAlreadyExist, setValueAlradyExist] = useState([]);
    const { t } = useTranslation();
    let OrderKanban = [
        "POS Order",
        "Replenishment Order"
    ]
    let CreateDate = [
        "Oldest",
        "Newest"
    ]

    // useEffect(() => {
    //   if(backlogFilters?.location?.length === 0 && location){
    //     setFilters(state => 
    //         ({...state, location: location })
    //     )
    //   }
    // }, [])
    

    const handleApplyFilterButton = () =>{
        handleShowFilter();
        setBacklogFilters(prevState => ({
            ...prevState,  // Spread the previous state
            ...filters     // Spread the new filters object
          }));
    }

        const onFilterCLick = (label, value) => {
        let updatedArray = filters[label];
        if (updatedArray.includes(value)) {
            updatedArray = updatedArray.filter(item => item !== value);
        } else {
            if(label === 'create_at'){
                updatedArray = [value];
            }else{
            updatedArray.push(value);
            
            }
            if(alreadyExist){
                setValueAlradyExist(prev => ([...prev,{key: label, value: value}]))
            }
        }
        setFilters(state => 
            ({...state, [label]: updatedArray })
        )
        
    }

    const handleClearAllLocation = () =>{
        
        if(alreadyExist){
            setValueAlradyExist(prev => ([...prev,{key: location, value: []}]))
        }
        setFilters(state => 
            ({...state, location: [] })
        )
    }

        const handleFilterCloseButton = () =>{
        
        setFilters(prevState => ({
            ...prevState,
            OrderType: [],
            Status: [],
            start_date: "",
            end_date: "",
            activedates:"",
            location:[],
            create_at:[]
          }));
          if(alreadyExist){
            const updatedApplyFilter = { ...backlogFilters };

            valueAlreadyExist.forEach(obj => {
              const { key, value } = obj;
              if (updatedApplyFilter[key]) {
                updatedApplyFilter[key] = updatedApplyFilter[key].filter(item => item !== value);
              }
            });
            setBacklogFilters(updatedApplyFilter)
          }
          handleShowFilter(); 
    }


  return (
    <div className='backlogFilter'>
            <div className='backlogFilter-inner'>
                <div className='backlogFilter-inner-title'>
                    <div>{t("Filters")}</div>
                    <div className='cursor-pointer backlogFilter-inner-title-closeicon'
                        onClick={() => handleFilterCloseButton()}
                    > <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#767676" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                   </div>
                </div>
                <div className='backlogFilter-inner-content'>
                        <>
                        {type === 'po' ? 
                        <div className='backlogFilter-inner-content-data'>
                        <div className='backlogFilter-inner-content-data-header'><span className='backlogFilter-inner-content-data-header-title'>{t("Order Types")}</span></div>
                        <div className='backlogFilter-inner-content-data-filter'>
                            {OrderKanban?.length ? OrderKanban?.map((result, key) => {
                                return (
                                    <div className=
                                        {`backlogFilter-inner-content-data-filter-item ${filters?.order_type?.includes(result) ? "active" : ""}`}
                                        key={key}
                                        onClick={() => onFilterCLick("order_type", result)}>
                                        {t(result)}
                                    </div>
                                )
                            }) : ""}
                        </div>
                        </div>
                        : ""
                        }
                       
                        <div className='backlogFilter-inner-content-data'>
                            <div className='backlogFilter-inner-content-data-header'><span className='backlogFilter-inner-content-data-header-title'>{t("Create Date")}</span></div>
                            <div className='backlogFilter-inner-content-data-filter'>
                            {CreateDate?.length ? CreateDate.map((result, key) => {
                                return (
                                    <div className={`backlogFilter-inner-content-data-filter-item me-2 mb-2 
                                        ${filters?.create_at?.includes(result)  ? "active" : ""}`} key={key}
                                        onClick={() => onFilterCLick("create_at", result)}>
                                        {t(result)}</div>
                                )
                            }) : ""}
                        </div>
                        </div>
                        
                        { type !== 'stock' ? 
                        <div className='backlogFilter-inner-content-data'>
                            <div className='backlogFilter-inner-content-data-header'><span className='backlogFilter-inner-content-data-header-title'>{t("Location")}</span><span className='backlogFilter-inner-content-data-header-close' onClick={handleClearAllLocation}>{t("Clear all")}</span></div>
                            <div className='backlogFilter-inner-content-data-filter'>
                            {location?.length ? location?.map((result, key) => {
                                return (
                                    <div className=
                                        {`backlogFilter-inner-content-data-filter-item ${filters?.location?.includes(result) ? "active" : ""}`}
                                        key={key}
                                        onClick={() => onFilterCLick("location", result)}>
                                        {t(result)}
                                    </div>
                                )
                            }) : ""}
                            </div>
                        </div>
                        : ""
                    }
                        </>
                </div>
            </div>
            <div className='backlogFilter-inner-bottom'
                    onClick={() => {handleApplyFilterButton()}}>
                    <button>
                        {t("Apply Filters")}
                    </button>
                </div>
    </div>
  )
}
