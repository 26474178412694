import React, { useEffect, useRef, useState } from 'react'
import RightIcon from '../common/icons/sidebar/RightIcon'
import LeftIcon from '../common/icons/sidebar/LeftIcon'
import Location from '../common/icons/sidebar/Location'
import CellPhoneGreen from '../common/icons/sidebar/CellPhoneGreen'
import Messegeboxblue from '../common/icons/sidebar/Messegeboxblue'
import CircledTick from '../common/icons/sidebar/CircledTick'
import { useTranslation } from 'react-i18next';
import CalendarIcon from '../common/icons/sidebar/CalendarIcon'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Calendar from '../common/icons/sidebar/Calendar'
import { _Api } from '../api/_call'
import Lodingspinner from '../../components/common/Lodingspinner'
import { Link } from 'react-router-dom'
import { store } from '../../source/index'
import ProductImageSliderModal from '../../components/common/modules/productImageslider'

export default function CalendarModal({ currentEvents, dataid, getcalenderlist, gettaskbar, kanban }) {
  const { t } = useTranslation();
  const calendardrop = useRef(null);
  var date_format = store.getState()
  var OrgSettings = date_format?.app?.organisation_settings
  const [showmore, setShowmore] = useState(false);
  const [editmodel, setEditModel] = useState(false);
  const [selectCalender, setSelectCalendar] = useState(false)
  const [currentEventId, setCurrentEventId] = useState(dataid?.index)
  const [modaldata, setModaldata] = useState()
  const [lodingdata, setLodingdata] = useState(false)
  const [savelodaer, setSaveloader] = useState(false)
  const [skuimage, setSkuimage] = useState();
  const [editted, setEditted] = useState(false);
  const [imageid, setImageid] = useState();

  const date_format_change = (date_format) => {
    var response
    if (date_format === 'DD/MM/YYYY') {
      response = 'dd/MM/yyyy'
    } else if (date_format === 'YYYY/MM/DD') {
      response = 'yyyy/MM/dd'
    } else if (date_format === 'MM/DD/YYYY') {
      response = 'MM/dd/yyyy'
    } else if (date_format === 'MM/YYYY/DD') {
      response = 'MM/yyyy/dd'
    }
    return response
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendardrop.current && !calendardrop.current.contains(event.target)) {
        setSelectCalendar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePreviours = () => {
    if (currentEvents?.length !== 1) {
      if (currentEventId === 1) {
        const lastIndex = currentEvents?.length - 1;
        setCurrentEventId(currentEvents?.length);
        getmodaldata(currentEvents[lastIndex]?._id);
      } else {
        setCurrentEventId(currentEventId - 1);
        getmodaldata(currentEvents[currentEventId - 2]?._id);
      }
    }
  }

  const handleNext = () => {
    if (currentEvents?.length !== 1) {
      if (currentEventId === currentEvents?.length) {
        setCurrentEventId(1);
        getmodaldata(currentEvents[0]?._id);
      } else {
        setCurrentEventId(currentEventId + 1);
        getmodaldata(currentEvents[currentEventId]?._id);
      }
    }
  }
  const getmodaldata = async (id) => {
    setLodingdata(true)
    try {
      let postdata = {
        "id": id
      }
      let res = await _Api(postdata, `api/v1/App/calender/getCalender-details-info`)
      if (res?.code === 200) {
        let data = { ...res?.data }
        const isDate = !isNaN(Date.parse(data.CompletedDate));
        data.type = isDate ? 'date' : 'description'
        setModaldata(data)
        setSkuimage({ image: data?.skuList?.[0]?.image, product_id: data?.skuList?.[0]?.product_id })
        setLodingdata(false)
      } else {
        setLodingdata(false)
      }

    }
    catch (err) {
      console.log(err, "Err")
    }
  }
  useEffect(() => {
    if (dataid?._id) {
      getmodaldata(dataid?._id)
      setCurrentEventId(dataid?.index)
    }
  }, [dataid])

  const updateduedate = (dateString, format) => {
    const date = new Date(dateString);
    setEditted(true)
    let formattedDate = '';
    switch (format) {
      case 'DD/MM/YYYY':
        formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
        break;
      case 'YYYY/MM/DD':
        formattedDate = `${date.getFullYear()}/${pad(date.getMonth() + 1)}/${pad(date.getDate())}`;
        break;
      case 'MM/DD/YYYY':
        formattedDate = `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${date.getFullYear()}`;
        break;
      case 'MM/YYYY/DD':
        formattedDate = `${pad(date.getMonth() + 1)}/${date.getFullYear()}/${pad(date.getDate())}`;
        break;
      default:
        throw new Error(`Unsupported date format: ${format}`);
    }
    setModaldata((state) => ({
      ...state,
      DelieveryDate: formattedDate,
      start: `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
    }));

  };


  const handleParsedDate = (modaldate, format) => {
    const dateString = modaldate;
    const dateParts = dateString.split('/');
    let formattedDate = '';
    switch (format) {
      case 'DD/MM/YYYY':

        formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        break;
      case 'YYYY/MM/DD':
        formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        break;
      case 'MM/DD/YYYY':
        formattedDate = new Date(dateParts[1] - 1, dateParts[2], dateParts[0]);
        break;
      case 'MM/YYYY/DD':
        formattedDate = new Date(dateParts[1] - 1, dateParts[0], dateParts[2]);;
        break;
      default:
        throw new Error(`Unsupported date format: ${format}`);
    }
    return formattedDate;
  }
  const pad = (value) => {
    return value < 10 ? `0${value}` : value;
  };
  const editcalanderdats = async () => {
    if (editted) {
      setSaveloader(true)
      try {
        let postdata = {
          "start": modaldata?.start,
          "title": modaldata?.Activity,
          "id": modaldata?._id,
        }
        let res = await _Api(postdata, `api/v1/App/calender/update-date-drag`)
        if (res?.code === 200) {
          getmodaldata(modaldata?._id)
          setSaveloader(false)
          getcalenderlist()
          gettaskbar()
        }
      }
      catch (err) {
        console.log(err, "Err")
      }
      setEditted(false);
      setEditModel(false);
      setSaveloader(false);
    }
  }

  return (
    <>
      <div
        className="modal fade taskDropdown"
        id="showtaskmodel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="showTaskModel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content ${selectCalender ? 'long' : ''}`}>
            {lodingdata ?
              <div className='position-absolute top-50 start-50 translate-middle'><Lodingspinner /></div> :
              <div>
                <div className="modal-header border-0 taskDropdown-header">
                  <div className="modal-title">
                    <span>{modaldata?.Activity ? modaldata?.Activity : ""} :{" "}
                      {modaldata?.OrderNumber ? modaldata?.OrderNumber : ""}
                    </span>
                    {modaldata?.TransactionDate && (
                      <span className="taskDropdown-header-small text-gray ps-2">
                        {modaldata.TransactionDate}
                      </span>
                    )}
                  </div>
                  <div className='d-flex align-items-center'>
                    {modaldata?.order_status === 0 ? (
                      <div
                        onClick={() => setEditModel(!editmodel)}
                        className='taskDropdown-header-editicon cursor-pointer'
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.32379 1.85277H3.05892C1.64567 1.85277 0.5 2.9984 0.5 4.41161V12.9412C0.5 14.3544 1.64567 15.5 3.05892 15.5H11.5887C13.0019 15.5 14.1476 14.3544 14.1476 12.9412L14.1476 8.67638M4.76487 11.2352L7.86826 10.6099C8.03301 10.5767 8.18429 10.4956 8.30309 10.3767L15.2503 3.42587C15.5834 3.09262 15.5832 2.55242 15.2498 2.21945L13.7782 0.749481C13.4449 0.416642 12.905 0.416869 12.572 0.749988L5.62407 7.70154C5.5055 7.82018 5.42454 7.97114 5.39131 8.13554L4.76487 11.2352Z" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    ) : ""}
                    <button
                      type="button"
                      className="shadow-none border-0 bg-transparent"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                    >
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 13L7 7M7 7L1 1M7 7L13 1M7 7L1 13" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="modal-body" >
                  <div className='taskDropdown-content'>
                    <div className='taskDropdown-content-row w-padding w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title d-flex align-items-start'>{t("Due Date")}</div>
                      <div className='taskDropdown-content-row-data align-items-center'>
                        {editmodel ?
                          <div className='taskDropdown-content-row-data-datepicker' ref={calendardrop}>
                            <div className='taskDropdown-content-row-data-datepicker-tag cursor-pointer'
                              onClick={() => setSelectCalendar(!selectCalender)}>
                              <span>
                                <Calendar withclock={true} color={"#A4A4A4"} width={'14px'} height={'14px'} />
                              </span>
                              {modaldata?.DelieveryDate && (
                                <span className='px-2'>
                                  {modaldata.DelieveryDate}
                                </span>
                              )}
                            </div>
                            {selectCalender ?
                              <div className="taskDropdown-content-row-data-datepicker-dropdown">
                                <div className='d-flex justify-content-center p-2'>
                                  <div className={`taskDropdown-content-row-data-datepicker-dropdown-date`}>
                                    <Calendar color="#A0A0A0" />
                                    <DatePicker
                                      selected={new Date()}
                                      shouldCloseOnSelect={true}
                                      className={`border taskDropdown-content-row-data-datepicker-dropdown-date-calendar`}
                                      autoComplete='none'
                                      dateFormat={date_format_change(
                                        OrgSettings?.date_format,
                                      )}
                                      open={false}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className={`taskDropdown-content-row-data-datepicker-dropdown-date secondone`}>
                                    <Calendar color="#A0A0A0" />
                                    <DatePicker
                                      onChange={(date) => updateduedate(date, OrgSettings?.date_format)}
                                      className={`border taskDropdown-content-row-data-datepicker-dropdown-date-calendar active`}
                                      autoComplete='none'
                                      dateFormat={date_format_change(
                                        OrgSettings?.date_format,
                                      )}
                                      open={true}
                                      selected={handleParsedDate(modaldata?.DelieveryDate, OrgSettings?.date_format)}
                                    />
                                  </div>
                                </div>
                              </div>
                              : ""}
                          </div> :
                          <div className='taskDropdown-content-row-data-fixdate'>
                            <span >
                              <CalendarIcon color={"#A4A4A4"} width={'24px'} height={'24px'} />
                            </span>
                            {modaldata?.DelieveryDate && (
                              <span className='px-2'>
                                {modaldata.DelieveryDate}
                              </span>
                            )}
                          </div>
                        }
                      </div>
                    </div>
                    <div className='taskDropdown-content-row w-padding'>
                      <div className='taskDropdown-content-row-title'>{t("Completed Date")}</div>
                      <div className='taskDropdown-content-row-data d-flex align-items-center'>
                        {modaldata?.type === "date" ?
                          <>
                            <CircledTick width={'18px'} height={'18px'} color="#52CBC4" /> <span className='taskDropdown-content-row-data-success mx-2'>{modaldata?.CompletedDate}</span>
                          </> :
                          <>
                            <CircledTick width={'18px'} height={'18px'} color="#FF5757" /> <span className='taskDropdown-content-row-data-danger mx-2'>{modaldata?.CompletedDate}</span>
                          </>
                        }
                      </div>
                    </div>
                    {modaldata?.skuList?.length ?
                      <div className='taskDropdown-content-row extrapadding'>
                        <div className='taskDropdown-content-row-title skutitle cursor-pointer' >
                          {t("SKU")}<img
                            src={skuimage?.image ? skuimage?.image : modaldata?.skuList[0]?.image}
                            alt='img'
                            onClick={() => setImageid(skuimage?.product_id ? skuimage?.product_id : modaldata?.skuList[0]?.product_id)} data-bs-toggle="modal" data-bs-target="#ProductViewModal"
                          />
                        </div>
                        <div className='taskDropdown-content-row-data skuleft'>
                          <div className={`taskDropdown-content-row-data-list ${showmore ? 'edit' : ""}`}>
                            {modaldata?.skuList.map((result, key) => {
                              return (
                                <div className='sku' key={key}>
                                  <div className={`sku-first cursor-pointer ${result?.image === (skuimage?.image ? skuimage?.image : modaldata?.skuList[0]?.image) && "active"}`} onClick={() => setSkuimage({ image: result?.image, product_id: result?.product_id })}>
                                    <img src={result?.image ? result?.image : ""} alt='img' />
                                    <span>{result?.SKU ? result?.SKU : ""}</span>
                                  </div>

                                  <div className='sku-second d-flex align-items-center'>
                                    {result?.status ?
                                      <>
                                        <div
                                          className={`taskDropdown-content-row-data-list-type 
                                      ${modaldata?.Activity} pe-2`}>
                                        </div>
                                        <span className='ps-2'>IN Stock</span>
                                      </>
                                      : ""
                                    }
                                  </div>

                                </div>
                              )
                            })}
                          </div>
                          {modaldata?.skuList?.length > 3 ?
                            <div
                              className={`taskDropdown-content-row-data-list-showmore ${showmore ? 'active' : ''} cursor-pointer`}
                            >
                              <div className='d-flex align-items-center' onClick={() => setShowmore(!showmore)}>
                                <div>
                                  <RightIcon color="#A0A0A0" />
                                </div>
                                <div className='text-ao'>
                                  {showmore ? t('Show Less') : t('Show More')}
                                </div>
                              </div>
                            </div>
                            : ""}
                        </div>
                      </div> : ""}
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("Qty")}</div>
                      <div className='taskDropdown-content-row-data'>{modaldata?.TotalQty ? modaldata?.TotalQty : ""}</div>
                    </div>
                    {modaldata?.customer_name ?
                      <div className='taskDropdown-content-row w-padding extrapadding'>
                        <div className='taskDropdown-content-row-title'>{t("Customer")}</div>
                        <div className='taskDropdown-content-row-data d-flex align-items-center'>
                          <div className='saleperson'>
                            <img src={modaldata?.customer_profile} alt='' />
                            <span className='ms-2'>
                              {modaldata?.customer_name ? modaldata?.customer_name : ""}</span>
                          </div>
                          {modaldata?.customer_phone ? <Link to={`tel:${modaldata?.customer_phone}`}><CellPhoneGreen /></Link> : ""}
                          <Link to={`mailto:${modaldata?.customer_email}`}><Messegeboxblue /></Link>
                        </div>
                      </div> : ""}

                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("Location")}</div>
                      <div className='taskDropdown-content-row-data'><Location forcalendar={true} color={"#A4A4A4"} />{" "}
                        {modaldata?.locationName ? modaldata?.locationName : ""}
                      </div>
                    </div>
                    {modaldata?.SalesPerson ?
                      <div className='taskDropdown-content-row w-padding extrapadding'>
                        <div className='taskDropdown-content-row-title'>{t("Sale Person")}</div>
                        <div className='taskDropdown-content-row-data'>
                          <div
                            className='saleperson'>
                            <img
                              src={modaldata?.SalesPerson_image ? modaldata?.SalesPerson_image : ""}
                              alt=""
                            />
                            <span className='ms-2'>
                              {modaldata?.SalesPerson ? modaldata?.SalesPerson : ""}
                            </span></div></div>
                      </div>
                      : ""}
                    <div className='taskDropdown-content-row w-padding extrapadding'>
                      <div className='taskDropdown-content-row-title'>{t("Remark")}</div>
                      <div className='taskDropdown-content-row-data'><div>
                        {modaldata?.Remark ? modaldata?.Remark : ""}</div></div>
                    </div>
                  </div>
                </div>
                {
                  !kanban ?
                    <div className="taskDropdown-footer modal-footer border-0 d-flex justify-content-center">
                      {editmodel ?
                        <div className='taskDropdown-footer-inner'>
                          <button className='taskDropdown-footer-inner-cancelbtn' onClick={() => { setEditModel(false); setEditted(false) }}>{t("Cancel")}</button>

                          <button className={`taskDropdown-footer-inner-savebtn ${editted ? 'active' : 'disable'}`} onClick={() => editcalanderdats()}>
                            {t("Save")}
                            {savelodaer && editted ?
                              <div className="spinner-border text-light ms-2" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              : null}
                          </button>
                          {/* <button className={`taskDropdown-footer-inner-savebtn disable`} onClick={() => editcalanderdats()}>
                        {t("Save")}
                      </button> */}

                        </div>
                        :
                        <>
                          <span
                            className='cursor-pointer lr-icons'
                            onClick={() => handlePreviours()}>
                            <LeftIcon />
                          </span><span> {currentEventId}</span> of <span>{currentEvents?.length}</span>
                          <span
                            className='cursor-pointer lr-icons'
                            onClick={() => handleNext()}>
                            <RightIcon />
                          </span>
                        </>
                      }
                    </div>
                    : ""
                }

              </div>
            }
          </div>

        </div>
      </div >
      <ProductImageSliderModal
        main_id={imageid}
      ></ProductImageSliderModal>
    </>
  )
}
