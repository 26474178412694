import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { _Api } from "../../../common/api/_call"
import { error } from "../../../common/helpers/toastify";

const Cashinmodal = props => {
    const { cashtype, setInoutStatus } = props
    const { t } = useTranslation();
    const [amount, setAmount] = useState();
    const [remark, setRemark] = useState()
    const createcashflow = async () => {
        if (!amount) {
            error(t("Amount is required!"));
            return;
        }
        if (!remark) {
            error(t("Remark is required!"));
            return;
        }
        try {
            let postdata = {
                "type": cashtype,
                "hod": 0,
                "amount": parseInt(amount),
                "remark": remark
            }
            let res = await _Api(postdata, `api/v1/POS/cashRegister/createCashflow`);
            if (res?.code === 200) {
                setInoutStatus(true);
                setAmount('');
                setRemark('');
            }
        }
        catch (err) {
            console.log(err, 'err')
        }
    }

    return (
        <>
            <div
                className="modal fade common-modal cashin-modal"
                id="cashinmodal"
                tabIndex="-1"
                aria-labelledby="cashregister-in-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog cashin-modal-dialog modal-dialog-centered">
                    <div className="modal-content pt-0 px-0 pb-2 overflow-hidden">
                        <div className="modal-header cashin-modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                {t("Cash in")}
                            </h5>
                            <button
                                type="button"
                                className="btn-close shadow-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body px-3 pt-3">
                            <div className="cashin-modal-body">
                                <div className="cashin-modal-body-amount">
                                    <div className="cashin-modal-body-amount-heading">
                                        <span>{t("Amount")}</span>
                                        <input type="text" value={amount} className="cashin-modal-body-amount-input"
                                            onChange={(e) => setAmount(e.target.value)} />
                                    </div>
                                </div>
                                <div className='placeholder-up-input cashin-modal-body-notes'>
                                    <textarea type="text" value={remark} className='placeholder-up-input-field input-field'
                                        placeholder={t("Notes")}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer" onClick={(e) => createcashflow(e.target.value)} data-bs-dismiss="modal"
                                aria-label="Close">
                                <button type="button" className="com-btn">
                                    {t("Submit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Cashinmodal;