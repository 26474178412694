import React from 'react'
// import UserIcon from "../../assets/icons/UserIcon"
// import AppoinmentIcon from "../../assets/icons/AppoinmentIcon"
// import CartIcon from "../../assets/icons/CartIcon"
// import ChangePasswordIcon from "../../assets/icons/ChangePasswordIcon"
// import RightArrowIcon from "../../assets/icons/RightSideArrow"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserIcon from 'admin/common/icons/UserIcon';
import AppoinmentIcon from 'Ecommerce/template_three/assets/icons/AppoinmentIcon';
import CartIcon from 'Ecommerce/template_three/assets/icons/CartIcon';
import RightArrowIcon from 'Ecommerce/template_three/assets/icons/RightArrowIcon';
import RightArrow from 'admin/common/icons/right-arrow';
import PurchaseIcon from 'admin/common/icons/PurchaseIcon';
import BillingShippingIcon from 'admin/common/icons/BillingShippingIcon';
import ChangePasswordIcon from 'admin/common/icons/ChangePasswordIcon';
import EcomLogoutIcon from 'admin/common/icons/EcomLogoutIcon';

export default function Sidebar() {
  const location = useLocation()
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
   
      <>
        <div className="accountsidebar">
        <div className="accountsidebar-title">
            {t("My Account")}
        </div>
        <div className="accountsidebar-inner">
            <div className='accountsidebar-inner-account-tabs'>
                <Link to={"/account"} className={location.pathname === '/account' ? `text-decoration-none accountsidebar-inner-tab active` : 'text-decoration-none accountsidebar-inner-tab'}>
                    <span className='fill'><UserIcon type="withbgfilled" /></span> {t("My Account")}
                </Link>
                <Link className='text-decoration-none' to="/account/details">
                    <div className={location.pathname === '/account/details' ? `accountsidebar-inner-account-insideTab active` : 'accountsidebar-inner-account-insideTab'}>
                        {t("Account Details")} <RightArrow />
                    </div>
                </Link>
                {/* <Link className='text-decoration-none' to="/account/address">
                    <div className={location.pathname === '/account/address' ? `accountsidebar-inner-account-insideTab active` : 'accountsidebar-inner-account-insideTab'} >
                        {t("My Addresses")} <RightArrowIcon />
                    </div>
                </Link> */}
            </div>
            <Link className='text-decoration-none' to="/account/address">
                <div className={location.pathname === `/account/address` || location.pathname === `/account/addressdetails` ? `accountsidebar-inner-tab active` : 'accountsidebar-inner-tab'}>
                <span className='fill'><BillingShippingIcon /></span>{t("Billing & Shipping")}
                </div>
            </Link>
            <Link className='text-decoration-none' to='/account/appointmentdata'>
                <div className={location.pathname === '/account/appointmentdata' ? `accountsidebar-inner-tab active` : 'accountsidebar-inner-tab'}>
                   <span className='fill'><AppoinmentIcon type="withStar"/></span> {t("My Appointment")}
                </div>
            </Link>
            <Link className='text-decoration-none' to="/account/purchasehistory">
                <div className={location.pathname === `/account/purchasehistory` ? `accountsidebar-inner-tab active` : 'accountsidebar-inner-tab'}>
                <span className='fill'><PurchaseIcon /></span>{t("Purchase History")}
                </div>
            </Link>
            
            <Link className='text-decoration-none' to="/account/changepassword">
                <div className={location.pathname === '/account/changepassword' ? `accountsidebar-inner-tab active` : 'accountsidebar-inner-tab'}>
                    <ChangePasswordIcon type="dark"/>  {t("Change Password")}
                </div>
            </Link>

        </div>
        <div className='accountsidebar-bottom' onClick={()=>navigate('/logout')}>
            <span><EcomLogoutIcon/></span>
            <span>{t("Log out")}</span>
        </div>
    </div>
    {/* <div className='accountTabMenu'>
        <div className='accountTabMenu-container'>
            <Link className='text-decoration-none' to="/account">
                <div className={`accountTabMenu-container-singleMenu`}>
                    {t("Account Details")}
                </div>
            </Link>
            <Link className='text-decoration-none' to="/account/address">
                <div className={`accountTabMenu-container-singleMenu`}>
                    {t("My Addresses")}
                </div>
            </Link>
            <Link className='text-decoration-none' to="/account/purchasehistory">
                <div className={`accountTabMenu-container-singleMenu`}>
                    {t("Purchase History")}
                </div>
            </Link>
            <Link className='text-decoration-none' to="/account/appointmentdata">
                <div className={`accountTabMenu-container-singleMenu`}>
                    {t("Appointment")}
                </div>
            </Link>
            <Link className='text-decoration-none' to="/account/changepassword">
                <div className={`accountTabMenu-container-singleMenu`}>
                    {t("Change Password")}
                </div>
            </Link>
        </div>
    </div> */}
    </>
  )
}
