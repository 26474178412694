import React from 'react'

export default function EcomLogoutIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.945 1.25C13.578 1.25 12.475 1.25 11.608 1.367C10.708 1.487 9.94996 1.747 9.34796 2.348C8.82396 2.873 8.55796 3.518 8.41896 4.276C8.28396 5.013 8.25796 5.914 8.25196 6.996C8.2509 7.19491 8.3289 7.3861 8.46881 7.5275C8.60871 7.6689 8.79905 7.74894 8.99796 7.75C9.19688 7.75106 9.38806 7.67306 9.52947 7.53316C9.67087 7.39326 9.7509 7.20291 9.75196 7.004C9.75796 5.911 9.78596 5.136 9.89396 4.547C9.99896 3.981 10.166 3.652 10.409 3.409C10.686 3.132 11.075 2.952 11.809 2.853C12.564 2.752 13.565 2.75 15 2.75H16C17.436 2.75 18.437 2.752 19.192 2.853C19.926 2.952 20.314 3.133 20.592 3.409C20.868 3.686 21.048 4.074 21.147 4.809C21.249 5.563 21.25 6.565 21.25 8V16C21.25 17.435 21.249 18.436 21.147 19.192C21.048 19.926 20.868 20.314 20.591 20.591C20.314 20.868 19.926 21.048 19.192 21.147C18.437 21.248 17.436 21.25 16 21.25H15C13.565 21.25 12.564 21.248 11.808 21.147C11.075 21.048 10.686 20.867 10.409 20.591C10.166 20.347 9.99896 20.019 9.89396 19.453C9.78596 18.864 9.75796 18.089 9.75196 16.996C9.75144 16.8975 9.73152 16.8001 9.69334 16.7093C9.65517 16.6185 9.59948 16.5361 9.52947 16.4668C9.45945 16.3976 9.37648 16.3428 9.28528 16.3056C9.19409 16.2684 9.09646 16.2495 8.99796 16.25C8.89947 16.2505 8.80205 16.2704 8.71126 16.3086C8.62046 16.3468 8.53808 16.4025 8.46881 16.4725C8.39953 16.5425 8.34473 16.6255 8.30752 16.7167C8.27032 16.8079 8.25144 16.9055 8.25196 17.004C8.25796 18.086 8.28396 18.987 8.41896 19.724C8.55896 20.482 8.82396 21.127 9.34896 21.652C9.94996 22.254 10.709 22.512 11.609 22.634C12.475 22.75 13.578 22.75 14.945 22.75H16.055C17.423 22.75 18.525 22.75 19.392 22.634C20.292 22.512 21.05 22.254 21.652 21.652C22.254 21.05 22.512 20.292 22.634 19.392C22.75 18.525 22.75 17.422 22.75 16.055V7.945C22.75 6.578 22.75 5.475 22.634 4.608C22.513 3.708 22.254 2.95 21.652 2.348C21.05 1.746 20.292 1.488 19.392 1.367C18.525 1.25 17.422 1.25 16.055 1.25H14.945Z" fill="#EE0004"/>
        <path d="M15.0001 11.2499C15.199 11.2499 15.3897 11.3289 15.5304 11.4695C15.6711 11.6102 15.7501 11.801 15.7501 11.9999C15.7501 12.1988 15.6711 12.3896 15.5304 12.5302C15.3897 12.6709 15.199 12.7499 15.0001 12.7499H4.02707L5.98807 14.4299C6.13924 14.5593 6.23281 14.7435 6.24819 14.9419C6.26357 15.1403 6.19949 15.3367 6.07007 15.4879C5.94064 15.639 5.75647 15.7326 5.55805 15.748C5.35964 15.7634 5.16324 15.6993 5.01207 15.5699L1.51207 12.5699C1.42973 12.4995 1.36363 12.4121 1.31831 12.3137C1.27298 12.2153 1.24951 12.1082 1.24951 11.9999C1.24951 11.8915 1.27298 11.7845 1.31831 11.6861C1.36363 11.5877 1.42973 11.5003 1.51207 11.4299L5.01207 8.42987C5.08692 8.36579 5.17367 8.31708 5.26735 8.28651C5.36103 8.25595 5.45981 8.24414 5.55805 8.25176C5.6563 8.25937 5.75208 8.28626 5.83993 8.33089C5.92778 8.37552 6.00598 8.43702 6.07007 8.51187C6.13415 8.58673 6.18287 8.67347 6.21343 8.76715C6.24399 8.86083 6.2558 8.95961 6.24819 9.05786C6.24057 9.1561 6.21368 9.25188 6.16905 9.33974C6.12442 9.42759 6.06292 9.50579 5.98807 9.56987L4.02807 11.2499H15.0001Z" fill="#EE0004"/>
    </svg>
  )
}
