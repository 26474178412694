import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { success, error } from "../common/helpers/toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import TableColumnModal from "../../components/common/modules/tableColumn";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { posrepairorder_head } from "../../components/common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { _Api } from "../common/api/_call";
import { useTranslation } from 'react-i18next';
import CheckoutBlank from "../checkout/checkoutBlank";
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function';
import TableLoader from '../../admin/common/TableLoader';
import { store } from '../../source/index'
const Repair = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { state } = useLocation()
  const saleperson = useSelector((state) => state.app.saleperson);
  const customer = useSelector((state) => state.app.customer);
  const [newcolumndata, setnewcolumndata] = useState(posrepairorder_head);
  const [, setfilterby] = useState("");
  const [selecteditem, setSelecteditem] = useState([]);
  const [repairdata, setRepairdata] = useState()
  const [TransactionDate, setTransactionDate] = useState();
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [parmission, setParmission] = useState(0);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [dataloder, setDataloder] = useState(false);

  useEffect(() => {
    if (parmissiondatashow?.pos_repair_order?.View) {
      setParmission(parmissiondatashow?.pos_repair_order?.Add);
    }
  }, [parmissiondatashow]);
  const selectTransactionDate = (input) => {
    setTransactionDate(input);
  };
  useEffect(() => {
    callcolumns();
  }, []);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_pos_repairorder",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const addtorepaircart = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }

    try {
      const postdata = {
        "cart_ids": selecteditem,
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "order_type": "repair_order"
      };
      let res = await _Api(postdata, `api/v1/POS/repair/addItemsToCart`)
      if (res.code === 200) {
        success(t("Successfully Add to Cart!!"));
        navigate("/app/pos/repair/repairOrderProcess", {
          state: state
        });
      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const getrepairlist = async () => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      SetdataTable("com-data-area_table", 4, newcolumndata, 0);
      return;
    }
    try {
      setDataloder(true);
      const postdata = {
        date: TransactionDate,
        customer_id: customer?.id
      };
      let res = await _Api(postdata, `api/v1/POS/repair/getSalesOrderList`);
      if (res?.code === 200) {
        setDataloder(false);
        setRepairdata(res?.data);
        UnSetdataTable("com-data-area_table");
        SetdataTable("com-data-area_table", 4, newcolumndata, 0);
      } else {
        setDataloder(false);
        UnSetdataTable("com-data-area_table");
        SetdataTable("com-data-area_table", 4, newcolumndata, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const selectedproduct = (i, event) => {
    if (event.target.checked) {
      const mainselected = [...repairdata];
      // let selectdata = [...selecteditem]
      let selectdata = []
      if (mainselected[i]?.cart_id === event.target.value) {
        mainselected[i] = {
          ...mainselected[i],
          'Qty': 1,
        }
        selectdata.push(mainselected[i])
        setSelecteditem(selectdata);
      }
    } else {
      const result = selecteditem.filter((element) => {
        return element?.cart_id !== event.target.value;
      });
      setSelecteditem(result);
    }
  };
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".repair_item");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute("data-id");
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);
  useEffect(() => {
    if (customer?.id) {
      getrepairlist();
    }
    // eslint-disable-next-line
  }, [customer?.id, TransactionDate])

  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className='com-data-area'>
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left">
                    <h1 className="heading"> <Link to="/app/pos/menu" className='gray-text text-decoration-none'>{t("REPAIR ORDER")}</Link></h1>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="custom-tooltip">
                      <Link to="/app/pos/repair/repairOrderDelivered">
                        <img className="custom-order-img" src={base_assets + "images/pos/icons/custom-order-icon.png"} alt="" />
                        <span className="com-btn custom-tooltip-text">{t("Repair Order")}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='selection-process'>
                <div className='selection-process-single'>
                  <img className='selection-process-single-img' src={base_assets + "images/pos/steps/s1.png"} alt="" />
                  <span className='selection-process-single-name'>{t("Select Product")}</span>
                </div>
                <span className='selection-process-line'></span>
                <div className='selection-process-single'>
                  <img className='selection-process-single-img' src={base_assets + "images/pos/steps/s9.png"} alt="" />
                  <span className='selection-process-single-name'>{t("Repair Process")}</span>
                </div>
              </div>
              <div className='com-data-area-header'>
                <h2 className='com-data-area-header-heading'>{t("PURCHASE HISTORY")}</h2>
                <div className='customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date'>
                  <DatePicker
                    className="placeholder-up-input-field"
                    placeholderText='MM-DD-YYYY'
                    selected={TransactionDate}
                    onChange={selectTransactionDate}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    autoComplete='none'
                  />
                  <img
                    className="placeholder-up-input-field-calender"
                    src={base_assets + "images/icons/calendar-green.svg"}
                    alt=""
                  />
                </div>
              </div>
              <div className="com-data-area-inner overflow-auto">
                <div className='main-body-main-table-wrap overflow-auto'>
                  <div className="vertical-scroll-table position-relative" id="table-scroll" >
                    <table id='com-data-area_table' className="common-table first-row-gray w-100">
                      <Tablehead setShowoption={setSelectAllStatus} tablehead={posrepairorder_head} tblName={"tbl_pos_repairorder"} setfilterby={setfilterby} />
                      <tbody>
                        <>
                          {repairdata?.length ? repairdata.map((result, repairkey) => {
                            return (
                              <tr key={result?.cart_id}>
                                <td>
                                  <div className="com-check radio">
                                    <input id={result?.cart_id} data-id={result?.cart_id} value={result?.cart_id} order_id={result?.order_id} type="radio" name='customchack' className='repair_item pos_repairorderhead'
                                      onChange={(e) => selectedproduct(repairkey, e)} />
                                    <label htmlFor={result?.cart_id} className="com-check-label"></label>
                                  </div>
                                </td>
                                <td>{repairkey + 1}</td>
                                <td>
                                  <div className="pro-img">
                                    <img src={result?.image} alt="" />
                                  </div>
                                </td>
                                <td>{result?.date}</td>
                                <td className='link-color'>{result?.ref_no}</td>
                                <td className='link-color'>{result?.SKU}</td>
                                <td>{result?.metal_name}</td>
                                <td>{result?.stone_name}</td>
                                <td>{result?.size_name}</td>
                                <td>{result?.stock_id}</td>
                                <td>{result?.location}</td>
                                <td>{result?.Qty}</td>
                                <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                              </tr>
                            );
                          }) : ""}
                        </>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{
                            repairdata?.length
                              ? repairdata?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  val?.Qty),
                                0
                              )
                              : ""
                          }</td>
                          <td>{
                            toFormatPrice(
                              repairdata?.length
                                ? repairdata?.reduce(
                                  (interation, val) =>
                                  (interation =
                                    interation +
                                    toUnformatPrice(val?.amount)),
                                  0
                                )
                                : "",
                              { addSymbol: true })
                          }</td>
                        </tr>
                      </tfoot>
                    </table>
                    {dataloder ? <TableLoader /> : ""}
                  </div>

                </div>
                <div className='com-data-area-footer-btn'>
                  <button className='com-btn disabled-hover-btn'>
                    {/* {selecteditem.length > 0 ? (
                    <span> {selecteditem.length} {t("item selected")}</span>
                  ) : ''} */}
                  </button>
                  {selecteditem?.length && parmission ?
                    <button onClick={addtorepaircart} className={'com-btn'}>{t("Submit")}</button>
                    : <button className='com-btn btn-disabled'>{t("Submit")}</button>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
      <TableColumnModal tablehead={posrepairorder_head} tblName={"tbl_pos_repairorder"} setnewcolumndata={setnewcolumndata}
        calldatatable={getrepairlist}></TableColumnModal>

    </>
  )
}

export default Repair;