import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Report.scss'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import { Reportmenupos } from './Reportmenupos'
import { Daterangepos } from '../common/Daterangepos'
import SocialModal from '../../components/common/modules/SocialModal/SocialModal'
import TableModal from '../common/Modals/tableColumnPos'
import { _Api } from '../common/api/_call'
import { Tablehead } from '../../components/common/modules/Tablehead'
import { loading, Updateloading } from '../common/helpers/toastify'
import {
  poswishlistJewelryreport_head,
  poswishlistdiamondreport_head,
} from '../../components/common/Tablehead'
import { useTranslation } from 'react-i18next'
import { Pagination } from '../common/Pagination'
import { toFormatPrice } from '../common/helpers/function'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import Select from "react-select";
import { useSelector } from 'react-redux'
const Wishlistreport = () => {
  const { t } = useTranslation()
  const [newcolumndata, setnewcolumndata] = useState()
  const [socialurl, setSocialurl] = useState()
  const [datefilter, setDatefilter] = useState([])
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [filtername, setFiltername] = useState('')
  const [, setfilterby] = useState('')
  const [shareloader, setShareloader] = useState(false)
  const [limit, setLimit] = useState(100)
  const [wishlist, setWishlist] = useState([])
  const [uppercount, setUppercount] = useState()
  const [type, setType] = useState()
  const [diamondlist, setDiamondlist] = useState([])
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

  const [printparmission, setPrintparmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission)

  useEffect(() => {
    if (parmissiondatashow?.pos_reports?.View)
      setPrintparmission(parmissiondatashow?.pos_reports?.Print)
  }, [parmissiondatashow])
  const callcolumns = async (event) => {
    try {
      const postdata = {
        name: 'report_wishlistJewelryreport_tbl'
          ? 'report_wishlistJewelryreport_tbl'
          : 'report_wishlistsiamondreport_tbl',
      }

      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const getprintwishlistreport = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/jewelryWishList`)

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const getsharerewishlistreport = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/jewelryWishList`)

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getprintwishlistdiamondreport = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/diamondWishList`)

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const getsharerewishlistdiamondreport = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/diamondWishList`)

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const filterbyname = (search) => {
    type === 'Diamond'
      ? wishlistdiamond(currentPage, limit, search)
      : getwishlistdata(currentPage, limit, search)
    setstate({ ...state, currentPage: 1 })
  }
  const getlimit = (limit) => {
    setLimit(limit)
    if (type === 'Diamond') {
      wishlistdiamond(currentPage, limit)
    } else {
      getwishlistdata(currentPage, limit)
    }
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })

  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    type === 'Diamond'
      ? wishlistdiamond(currentPage, limit)
      : getwishlistdata(currentPage, limit)
  }
  const getwishlistdata = async (current, firstlimit, search) => {
    UnSetdataTable('wishlist_jewelry_tbl')

    try {
      const postdata = {
        search: search ? search : "",
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
      }

      let listdata = await _Api(postdata, `api/v1/POS/report/wishlist`)
      if (listdata.code === 200) {
        setWishlist(listdata?.data)
        setUppercount(listdata?.count)
        setTimeout(() => {
          SetdataTable('wishlist_jewelry_tbl', 0, newcolumndata)
        }, 50)
        setstate({
          ...state,
          totalPages: Math.ceil(listdata?.total / postdata.limit),
          currentPage: current ? current : 1,
        })
      } else {
        SetdataTable('wishlist_jewelry_tbl', 0, newcolumndata)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const wishlistdiamond = async (current, firstlimit, search) => {
    UnSetdataTable('wishlist_diamond_tbl')
    try {
      const postdata = {
        search: search ? search : "",
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
      }
      let listdata = await _Api(postdata, `api/v1/POS/report/diamondwishlist`)
      if (listdata?.code === 200) {
        setDiamondlist(listdata?.data)
        setTimeout(() => {
          SetdataTable('wishlist_diamond_tbl', 0, newcolumndata, [])
        }, 50)
        setstate({
          ...state,
          totalPages: Math.ceil(listdata?.total / postdata.limit),
          currentPage: current ? current : 1,
        })
      } else {
        SetdataTable('wishlist_diamond_tbl', 0, newcolumndata, [])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      wishlistdiamond()
      getwishlistdata()
    }
    // eslint-disable-next-line
  }, [newcolumndata, datefilter])
  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    poswishlistJewelryreport_head.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (wishlist.length) {
      wishlist.forEach((result, key) => {
        bodyarray.push([
          key + 1,
          result?.date,
          result?.SKU,
          result?.stock_id,
          result?.Item,
          result?.Collection,
          result?.Metal,
          result?.Stones,
          result?.Size,
          result?.formated_amount,
          result?.location,
          result?.customer_name,
        ])
      })
    }

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'wushlist')
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 w-100">
            <div className="catalog-area bg-white page-menu-block Dailysalereport">
              <div className="Dailysalereport-header">
                <div className="Dailysalereport-header-left">
                  <h3 className="common-navigation-header-left-heading Dailysalereport-head">
                    {' '}
                    <Link
                      to=""
                      className="gray-mid text-decoration-none Dailysalereport-head-report"
                    >
                      {t('REPORT')}
                    </Link>{' '}
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'}
                      alt=""
                    />
                    <span className="Dailysalereport-head-pagename">
                      {t('Wishlist Report')}
                    </span>{' '}
                  </h3>
                </div>
                <div className="Dailysalereport-header-reportdetail">
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report/stock-img.png'}
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('Total Qty')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data lightblue">
                        {uppercount?.total}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report/sku-img.png'}
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('SKU')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data yellow-text">
                        {uppercount?.SKU}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={
                          base_assets + 'images/pos/report/customer-green.png'
                        }
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('Total Customer')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data maingreen">
                        {uppercount?.customer}
                      </span>
                    </div>
                  </div>
                  <div className="Dailysalereport-header-reportdetail-box">
                    <div className="Dailysalereport-header-reportdetail-box-inner">
                      <img
                        className="Dailysalereport-header-reportdetail-box-inner-img"
                        src={base_assets + 'images/pos/report//loacation.png'}
                        alt=""
                      ></img>
                    </div>
                    <div className="Dailysalereport-header-reportdetail-box-text">
                      <span className="Dailysalereport-header-reportdetail-box-text-inner">
                        {t('Total Location')}
                      </span>
                      <span className="Dailysalereport-header-reportdetail-box-text-data lightgray">
                        {uppercount?.location}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Dailysalereport-content-area">
                <div className="Dailysalereport-content-area-header">
                  <div className="Dailysalereport-content-area-header-inner">
                    <Reportmenupos
                      Reportname={"Wishlist Report"} />
                    <Select
                      className="limit-select short mx-3"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                    />
                    <div className="date">
                      {' '}
                      <Daterangepos setDatefilter={setDatefilter} />
                    </div>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <input
                        type="text"
                        value={filtername}
                        placeholder={t('Scan barcode, Search SKU')}
                        onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                        onChange={(e) => setFiltername(e.target.value)}
                      />
                      {filtername ? (
                        <img
                          className="com-search-area-clear"
                          src={base_assets + 'images/icons/False.png'}
                          onClick={(e) => { setFiltername(''); filterbyname("") }}
                          alt=""
                        />
                      ) : (
                        ''
                      )}
                      <img
                        onClick={() => filterbyname(filtername)}
                        className="com-search-area-img"
                        src={
                          base_assets + 'images/pos/icons/magnifying-glass.png'
                        }
                        alt=""
                      />
                    </div>
                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                      <button
                        className="btn-style"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            base_assets + 'images/icons/ellipsis-circular.png'
                          }
                          alt=""
                        />
                      </button>
                      <ul
                        className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          onClick={() => {
                            type === 'Diamond'
                              ? getprintwishlistdiamondreport()
                              : getprintwishlistreport()
                          }}
                          className={printparmission ? '' : 'd-none'}
                        >
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/print-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Print')}
                          </Link>
                        </li>
                        <li onClick={() => downloadexclsheet()}>
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets +
                                  'images/pos/report/export-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Export')}
                          </Link>
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#socialModal"
                          className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                          onClick={() => {
                            type === 'Diamond'
                              ? getsharerewishlistdiamondreport()
                              : getsharerewishlistreport()
                          }}
                        >
                          <div className="dropdown-menu-img">
                            <img
                              src={
                                base_assets + 'images/pos/report/share-icon.png'
                              }
                              alt=""
                            />
                          </div>
                          {t('Share')}
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#columnModal"
                        >
                          <Link
                            className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item"
                            to="#"
                          >
                            <div className="dropdown-menu-img">
                              <img
                                src={
                                  base_assets + 'images/icons/column-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            {t('Column')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="Dailysalereport-content-area-main">
                  <ul
                    className="Dailysalereport-content-area-navbar wishlist-navbar nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li
                      onClick={(e) => {
                        setType('Jewelry')
                      }}
                      className="nav-item Dailysalereport-content-area-navbar-item wishlist-navbar-item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active Dailysalereport-content-area-navbar-item-link wishlist-navbar-item-link"
                        id="Sale-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Sale"
                        type="button"
                        role="tab"
                        aria-controls="Sale"
                        aria-selected="true"
                      >
                        {t('Jewelry')}
                      </button>
                    </li>
                    <li
                      onClick={(e) => {
                        setType('Diamond')
                      }}
                      className="nav-item Dailysalereport-content-area-navbar-item wishlist-navbar-item"
                      role="presentation"
                    >
                      <button
                        className="nav-link Dailysalereport-content-area-navbar-item-link wishlist-navbar-item-link"
                        id="Exchange-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Exchange"
                        type="button"
                        role="tab"
                        aria-controls="Exchange"
                        aria-selected="false"
                      >
                        {t('Diamond')}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="Sale"
                      role="tabpanel"
                      aria-labelledby="Sale-tab"
                    >
                      <div
                        className="main-body-main-table-wrap Dailysalereport-table"
                        id="table-scroll"
                      >
                        <table
                          id="wishlist_jewelry_tbl"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poswishlistJewelryreport_head}
                            tblname={'report_wishlistJewelryreport_tbl'}
                            setfilterby={setfilterby}
                          />
                          <tbody>
                            <>
                              {wishlist.length
                                ? wishlist.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>
                                        {' '}
                                        {result?.date ? result?.date : ''}
                                      </td>
                                      <td className="link-color">
                                        {result?.SKU ? result?.SKU : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.stock_id
                                          ? result?.stock_id
                                          : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.Item ? result?.Item : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.Collection
                                          ? result?.Collection
                                          : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.Metal ? result?.Metal : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.Stones ? result?.Stones : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.Size ? result?.Size : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {toFormatPrice(result?.price, {
                                          addSymbol: true,
                                        })}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.location
                                          ? result?.location
                                          : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.customer_name
                                          ? result?.customer_name
                                          : ''}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                        </table>
                      </div>
                      {wishlist.length > 0 ? (
                        <Pagination
                          total={totalPages}
                          current={currentPage}
                          pagination={(crPage) => handlePaginations(crPage)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Exchange"
                      role="tabpanel"
                      aria-labelledby="Exchange-tab"
                    >
                      <div
                        className="main-body-main-table-wrap Dailysalereport-table"
                        id="table-scroll"
                      >
                        <table
                          id="wishlist_diamond_tbl"
                          className="common-table first-row-gray w-100 Dailysalereport-table-main"
                        >
                          <Tablehead
                            tablehead={poswishlistdiamondreport_head}
                            tblname={'report_wishlistsiamondreport_tbl'}
                            setfilterby={setfilterby}
                          />
                          <tbody>
                            <>
                              {diamondlist.length
                                ? diamondlist.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>
                                        {result?.date ? result?.date : ''}
                                      </td>
                                      <td>
                                        {' '}
                                        {result?.StockID
                                          ? result?.StockID
                                          : ''}
                                      </td>
                                      <td>
                                        {result?.Shape ? result?.Shape : ''}
                                      </td>
                                      <td className="link-color">
                                        {result?.Carat ? result?.Carat : ''}
                                      </td>
                                      <td>
                                        {result?.Colour ? result?.Colour : ''}
                                      </td>
                                      <td>
                                        {result?.Clarity
                                          ? result?.Clarity
                                          : ''}
                                      </td>
                                      <td>
                                        {result?.Cut ? result?.Cut : ''}
                                      </td>
                                      <td>
                                        {result?.Polish ? result?.Polish : ''}
                                      </td>
                                      <td>
                                        {result?.Symmetry
                                          ? result?.Symmetry
                                          : ''}
                                      </td>
                                      <td className="link-color">
                                        {result?.Certification
                                          ? result?.Certification
                                          : ''}
                                      </td>
                                      <td>
                                        {toFormatPrice(result?.TagPrice, {
                                          addSymbol: true,
                                        })}
                                      </td>
                                      <td>
                                        {result?.location
                                          ? result?.location
                                          : ''}
                                      </td>
                                      <td>
                                        {result?.customer_name
                                          ? result?.customer_name
                                          : ''}
                                      </td>
                                    </tr>
                                  )
                                })
                                : ''}
                            </>
                          </tbody>
                        </table>
                      </div>
                      {diamondlist.length > 0 ? (
                        <Pagination
                          total={totalPages}
                          current={currentPage}
                          pagination={(crPage) => handlePaginations(crPage)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <TableModal
          tablehead={
            type === 'Diamond'
              ? poswishlistdiamondreport_head
              : poswishlistJewelryreport_head
          }
          setnewcolumndata={setnewcolumndata}
        />
      </div>
    </>
  )
}
export default Wishlistreport
