import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
const ConfirmDragDropdown = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { dragupdatedata } = props
  useImperativeHandle(ref, () => ({
    openModal: () => setShow(true),
    closeModal: () => setShow(false),
  }));
  const handleCloseModal = () => {
    if (ref.current) {
      ref.current.closeModal();
    }
  };
  return (
    <div className={`modal fade ${show ? 'show d-block' : 'd-none'}`} tabIndex="-1" role="dialog" aria-labelledby="confirmmodel" aria-hidden={!show}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0">
            <h5 className="modal-title confirmpopup-header">
              {t("Confirm Change Drag Step")}
            </h5>
          </div>
          <div className='modal-body pt-1 pb-0'>
            <p className='text-muted'>{t("You are about to change the drag step behavior to discard the original dragging path and use a calculated path to a new target element. ( this can not be undone )")}  </p>
          </div>
          <div className='d-flex confirmpopup-footer align-items-center'>
            <button type="button" className='confirmpopup-footer-buttons border white'
              onClick={() => { handleCloseModal(); setShow(false) }}>{t("Cancel")}</button>
            <button className='confirmpopup-footer-buttons green' onClick={() => dragupdatedata()}>{t("OK")}</button>
          </div>
        </div>
      </div>
    </div>
  )
});

export default ConfirmDragDropdown;
