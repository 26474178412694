import React from 'react';
import { useTranslation } from 'react-i18next';
export const ImportExcelModal = (props) => {
  const { t } = useTranslation();
  const { setImportfile, readExcel, SampleExcel, importfile } = props
 
  return (
    <div
      className="modal fade import-modal"
      id="ImportModal"
      tabIndex={-1}
      aria-labelledby="ImportModalLabel"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="import-modal-choose-file">
              {t("choose file")}
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0]
                  setImportfile(file)
                }}
                name="files"
              />
            </div>
            <div className="import-modal-file  w-100">
              {importfile?.name}
            </div>
          </div>
          <div id="clickonme" className="modal-footer">

            <button
              id="download"
              type="button"
              onClick={SampleExcel}
              className="btn modal-content-yes"
            >
              {t("Download Sample")}
            </button>
            <button
              type="button"
              onClick={readExcel}
              data-bs-dismiss="modal"
              className="btn modal-content-yes"
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};
