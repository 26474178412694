import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { _Api } from "../common/api/_call";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const CatalogNavbar = (props) => {
    const { t } = useTranslation();
    const generalsettings = useSelector((state) => state.app.generalsettings)
    const { setNavid, navid, location, setproductNavid, pNavid } = props;
    const [collectionmenu, setCollectionmenu] = useState();
    const [defaultfirst, setDefaultfirst] = useState();
    const [active, setActive] = useState(false)
    const collectionmanu = useCallback(async () => {
        try {
            const postdata = {}
            let res = await _Api(postdata, `api/v1/POS/common/catelogMenu`);
            if (res.code === 200) {
                setCollectionmenu(res.data);
                if (!navid) {
                    if (!location) {
                        setNavid(res.data?.length ? res.data[0]?.id : '');
                    }
                    setDefaultfirst(res.data?.length ? res.data[0]?.id : '');
                }
            }
        }
        catch (err) {
            console.log(err, "err");
        }
        // eslint-disable-next-line
    }, [navid, setNavid])
    useEffect(() => {
        collectionmanu();
    }, [collectionmanu]);

    return (
        <>
            <ul className='catalog-area-header-area-tabs' role="tablist">
                {collectionmenu ? collectionmenu.map((result, key) => {
                    return (
                        <li className='catalog-area-header-area-tabs-single' key={key} >
                            {
                                location === '/app/pos/products' || location === "/app/pos/CatalogDiamond" ?
                                    <button onClick={() => setproductNavid(result?.id)} className={active ? "nav-link" : (pNavid || navid || defaultfirst) === result?.id ? "nav-link active" : "nav-link"} data-bs-toggle="tab" data-bs-target="#jalwary" type="button" role="tab" >{t(result.name)}</button>
                                    :
                                    <button onClick={() => setNavid(result?.id)} className={(navid || defaultfirst) === result?.id ? "nav-link active" : "nav-link"} data-bs-toggle="tab" data-bs-target="#jalwary" type="button" role="tab" >{t(result.name)}</button>
                            }
                        </li>
                    )
                }) : ""}
                <li className={generalsettings?.data?.generalsetup?.productChoice === '1' ? 'catalog-area-header-area-tabs-single' : "d-none"} onClick={() => { setActive(true) }}>
                    <Link to="/app/pos/products/CatalogDiamond" className={active ? "nav-link active" : "nav-link"} type="button" role="tab" >{t("Diamond")}</Link>
                </li>

            </ul>
        </>
    )
}
export default CatalogNavbar;