import React, { useState, useEffect } from "react";
import { toFormatPrice, toUnformatPrice } from "../helpers/function";
import { useTranslation } from 'react-i18next';
const NotesModalAccounting = (props) => {
  const { t } = useTranslation();
  const { setForm_note, settotalamount, setShipping, setTax, data_product } = props;
  let handleChange = (e) => {
    setForm_note(e.target.value);
  };
  const [taxs, setTaxs] = useState();
  const [shippings, setShippings] = useState();
  const [note, setNote] = useState();
  useEffect(() => {
    if (data_product) {
      setTaxs(data_product?.tax_amount === 0 ? 0 : data_product?.tax_amount);
      setShippings(data_product?.shipping_handling_amount === 0 ? 0 : data_product?.shipping_handling_amount);
      setNote(data_product?.note);
    }
  }, [data_product]);
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;
  return (
    <div
      className="modal notes-modal notes-modal-account"
      id="notesModal"
      tabIndex={-1}
      aria-labelledby="notesModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="notesModalLabel">
              <img
                src={base_url + "images/icons/comment-icon-green.png"}
                alt=""
              />
              {t("notes")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <textarea
              onChange={(e) => handleChange(e)}
              className="notes-modal-text"
              placeholder="Type your note here."
              defaultValue={note}
            />

            <table className="notes-modal-account">
              <thead>
                <tr>
                  <th colSpan="2">
                    <div className="d-flex align-items-center">
                      <img className="me-2" src={base_url + "images/icons/calc-icon.png"} alt="" /> {t("Account")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("Total")}</td>
                  <td className="text-end"><span></span>{settotalamount}</td>
                </tr>
                <tr>
                  <td>Tax %</td>
                  <td className="text-end">
                    <div className="placeholder-up-input w-100"><input className="" value={taxs ? taxs : ""} onChange={(e) => { setTaxs(e.target.value); setTax(e.target.value) }} /></div>
                  </td>
                </tr>
                <tr>
                  <td>{t("Shipping and Heading")}</td>
                  <td className="text-end">
                    <div className="placeholder-up-input w-100"><input className="" value={shippings ? shippings : ""} onChange={(e) => { setShipping(e.target.value); setShippings(e.target.value) }} /></div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td> {t("Total Account")}</td>
                  <td className="text-end "> <span></span><span className="total_purchase_amount">
                    {toFormatPrice(toUnformatPrice(settotalamount) + parseInt(taxs ? taxs : 0) + parseInt(shippings ? shippings : 0), { addSymbol: true })}</span> </td>
                </tr>
              </tfoot>

            </table>

          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesModalAccounting;
