import React, { useEffect, useRef, useState } from "react";
// import RecentView from "../../components/RecentView";
// import CollectionSlider from "../../components/CollectionSlider"
import ImageGallery from 'react-image-gallery';
// import HeartIcon from "../../assets/icons/HeartIcon";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import MinusIcon from "../../assets/icons/MinusIcon";
// import PlusIcon from "../../assets/icons/PlusIcon";
// import { _Api } from "../../../common/api";
// import AddToCartModal from "../../components/AddToCart";
// import CartNotification from "../../components/CartNotification"
// import ArroeIcontop from '../../assets/images/arrowIcontop.png'
// import SizeGuideModal from "../../components/SizeGuide";
// import Spinner from "../../../common/Spinner";
// import { templateTwoActiveTab, updateTemplateTwoTabsData, updateTemplateTwodata } from "../../../../../source/app/appSlice";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HeartIcon from "admin/common/icons/HeartIcon";
import CollectionSlider from "Ecommerce/template_three/include/Common/CollectionSlider";
import RecentViewProducts from "Ecommerce/template_three/components/RecentViewProducts";
import AddCartModal from "Ecommerce/template_three/components/Cart/AddCartModal";
import CartItemsNotify from "Ecommerce/template_three/components/Cart/CartItemsNotify";
import SizeGuildeModal from "Ecommerce/template_three/components/SizeGuilde/SizeGuildeModal";
import Spinner from "Ecommerce/common/Spinner";
import RightArrow from "admin/common/icons/right-arrow";
import Ring from "admin/common/icons/Ring";
import CircledTick from "admin/common/icons/sidebar/CircledTick";
import Diamond from "admin/common/icons/Diamond";
import PlusIcon from "Ecommerce/common/icons/PlusIcon";
import MinusIcon from "Ecommerce/common/icons/MinusIcon";
import { _Api } from "Ecommerce/common/api";
import LeftArrow from "Ecommerce/common/icons/LeftArrow";
// import RightArrow from "../../../../../admin/common/icons/right-arrow";
// import Ring from "../../../../../admin/common/icons/Ring";
// import CircledTick from "../../../../../admin/common/icons/sidebar/CircledTick";
// import Diamond from "../../../../../admin/common/icons/Diamond";
// import Engraving from "./Engraving/Engraving";

export default function DiamondProductPage({productDetail, getDiamondFilters, setGetDiamondFilters, setShowEngravingPage, diamondProduct, setDiamondProduct, setActiveTab, flowMode, setShowDiamondProduct, diamondFilters, engravingData, setEngravingData}) {
    const [diamonddetails, setDiamondDetails] = useState(diamondProduct? diamondProduct : []);
const dispatch = useDispatch();
    const { state } = useLocation()
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(1);
    const [showdropDown, setShowDropdown] = useState(1)
    const [productdetails, setProductdetails] = useState()
    const [variantcombo, setVariantcombo] = useState({ metal: '', stone: '', size: '' });
    const [isopennotifycart, setIsopennotifycart] = useState(false)
    const [isopencart, setisopencart] = useState(false)
    const [loading, setloading] = useState(true);
    // const [activeTab, setActiveTab] = useState(1);
    const fullPath = useLocation();
    const parts = fullPath.pathname.split('/');
    const params = new URLSearchParams(fullPath.search);
    const vValue = params.get('v');
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    let navigate = useNavigate();
    const [variantdata, setVarientdata] = useState([])
    const [varientloading, setVarientloading] = useState(false)
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
    const [mobileTab, setMobileTab] = useState();
    const [diamondNote, setDiamondNote] = useState(diamonddetails?.note ? diamonddetails?.note : "")
    const diamondproductPageref = useRef();
    const [recentviewddata, setRecentvieweddata] = useState([]);
    let localRecentViewed = localStorage.getItem('RecentViewedDiamonds') ? JSON.parse(localStorage.getItem('RecentViewedDiamonds')) : [];
    const location = useLocation();
    const accessToken = localStorage.getItem("ecomaccesstoken");
    useEffect(() => {
        if (diamondproductPageref.current) {
            diamondproductPageref.current.scrollIntoView({ behavior: 'smooth' });
          }
          if(diamondProduct){
            setloading(false)
          }
          console.log(diamondProduct, "diamond product from diamond page")
    }, [])

    useEffect(()=>{
        console.log(diamondProduct, "check diamond product on change")
        setDiamondDetails(diamondProduct)
    },[diamondProduct])
    
    const handleSaveDataInStore = () =>{
       setGetDiamondFilters(diamondFilters);
       if(diamondNote !== undefined && diamondNote !== ""){
       setDiamondProduct((state)=>({...state, "note" : diamondNote}))
       }
       if(productDetail !== undefined){
        setActiveTab('Final')
       }else{
        setActiveTab('Product')
       }
    }
    const handleEngravingClick = () =>{
        if(diamondNote !== undefined && diamondNote !== ""){
            setDiamondProduct((state)=>({...state, "note" : diamondNote}))
        }
        setGetDiamondFilters(diamondFilters);
        setShowDiamondProduct(false);
        setShowEngravingPage(true);
    }

    const handlePlaceComplete = () =>{
        setActiveTab("Final")
    }

    const getrecentviewed = async () => {
        try {
            let postdata = {
                "id": '',
                "diamondIds": localRecentViewed,
                "wishlistproduct": localmywishlist,
                "wishlistremove": localmywishlistremove,
            }
            let res = await _Api(postdata, `checkout/recentView`);
            if (res?.statusCode === 200) {
                setRecentvieweddata(res?.data);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    const handleDiamondItem = async(id) =>{
            setTimeout(() => {
                if (!localRecentViewed.filter(e => e === id).length) {
                    localRecentViewed.push(id);
                    localStorage.setItem('RecentViewedDiamonds', JSON.stringify(localRecentViewed));
                }
            }, 15000)
        try {
          let  prams = {
            "id": id
        }
        
          let res = await _Api(prams, `mixMatch/get-diamond-details`)
          if (res?.code === 200) {
            setDiamondProduct(res?.data)
             console.log(res?.data, "diamond product from recent view");
             handleScrollToTop();
          }
      }
      catch (err) {
          console.log(err, "err")
      }
      }

      const handleScrollToTop = () => {
        // Use scrollIntoView to scroll the element into view
        diamondproductPageref.current?.scrollIntoView({ behavior: 'smooth' });
      };

      const addrecentviewed = async () => {
        try {
            let postdata = {
                diamondIds: localRecentViewed
            }
            await _Api(postdata, `checkout/addProductToView`);
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    useEffect(() => {
        if (localRecentViewed?.length && accessToken) {
            if (location.pathname.includes("/product")) {
                addrecentviewed();
            }
        }
        // eslint-disable-next-line
    }, [localRecentViewed?.length, accessToken])

    useEffect(() => {
        getrecentviewed();
        // eslint-disable-next-line
    }, [])
  return (
    <div ref={diamondproductPageref}>
    {
                  loading ?
                      (<div className=" w-100 vh-100 d-flex justify-content-center align-items-center" >
                          <Spinner />
                      </div >) : (
                          <>
                      <div className="diamond_product">
                                  <div className="diamond_product-image-section">
                                      <img className="diamondimae" src={diamonddetails?.image} alt='diamond'/> 
                                  </div>
                                  <div className="diamond_product-section">
                                      <div className="diamond_product-section-product-details">
                                          <div className={`diamond_product-section-product-details-name ${diamonddetails?.isWishlist ? "likeHeartBtn" : ""}`}>
                                          
                                           <span>{diamonddetails?.Cut} {diamonddetails?.Carat} {t("Carat")} {diamonddetails?.Colour}-{diamonddetails?.Clarity} {diamonddetails?.Shape} {t("Diamond")}</span>
                                              {/* <div className="d-inline-block" >
                                                  <HeartIcon width="26px" height="24px"/>
                                              </div> */}
                                          </div>
                                          <span className="diamond_product-section-product-details-serialNumber">
                                         
                                          {t("Color")} : {diamonddetails?.Colour} / {t("Certified By")} : {diamonddetails?.GradedBy} / {t("Cut")} : {diamonddetails?.Cut}
                                          </span>
                                      </div>
                                      <span className="diamond_product-section-product-price " id="variantprice" data-caritemid={diamonddetails?._id} >
                                      {diamonddetails?.FormattedPrice}
                                      </span>
                                      
                                      <div className="diamond_product-section-buttons">
                                       <div className="diamond_product-section-buttons-backtosetting full" onClick={()=>setShowDiamondProduct(false)}>
                                         <span className="diamond_product-section-buttons-backtosetting-img">
                                           <LeftArrow/>
                                        </span> <span className="diamond_product-section-buttons-backtosetting-title">{t("Back to Settings")}</span>
                                      </div>
                                      { flowMode === 'fromDiamond' && 
                                      <div  
                                      onClick={handleSaveDataInStore} 
                                      disabled={!productdetails?.size?.length ? (!variantcombo?.size ? false : true) : (!variantcombo?.size ? true : false)}
                                          // onClick={addtocart} 
                                          className="diamond_product-section-buttons-addToCartBtn">
                                          {t("Add to a ring")}
                                      </div>
                                      }   
                                      {flowMode === 'fromProduct' ? engravingData ? 
                                      
                                      <div  
                                      onClick={handlePlaceComplete} 
                                          className="diamond_product-section-buttons-addToCartBtn">
                                          {t("Place Complete")}
                                      </div>
                                              :
                                      <button className="diamond_product-section-engravingbtn" onClick={()=>handleEngravingClick()}>
                                          {t("Engraving")}
                                      </button>
                                      : null
                                      }
                                      </div>
                                      <div className={`diamond_product-section-product-productdetails border-line`}>
                                          <div className="d-flex justify-content-between align-items-center title">
                                              <label>{t("Descriptions")}</label>
                                              {/* {showdropDown === 2 ? <MinusIcon width={20} height={20} /> :
                                                  <PlusIcon width={20} height={20} />} */}
                                          </div>
                                         
                                          <div className="diamond_product-section-product-productdetails-inner">
                                             {diamonddetails?.des}
                                          </div>
                                      </div> 
                                      
                                  </div>
                          {/* similar diamond section */}
                  
                              </div>  
                                <div className="diamond_product-bottom">
                                    <div className="diamond_product-bottom-tabs">
                                        <div className={`diamond_product-bottom-tabs-individual ${showdropDown === 1 ? 'active' : ""}`} onClick={() => setShowDropdown(1)}>{t("Diamond Details")}</div>
                                        <div className={`diamond_product-bottom-tabs-individual ${showdropDown === 2 ? 'active' : ""}`} onClick={() => setShowDropdown(2)}>{t("Shipping Information")}</div>
                                        <div className={`diamond_product-bottom-tabs-individual ${showdropDown === 3 ? 'active' : ""}`} onClick={() => setShowDropdown(3)}>{t("Note")}</div>
                                    </div>

                                        <div className={`diamond_product-section-product-productdetails ${showdropDown === 1 ? "border-line" : ""} `}>
                                          {/* <div className="d-flex justify-content-between align-items-center title" onClick={() => {showdropDown === 1 ? setShowDropdown(null) : setShowDropdown(1)}}>
                                              <label>{t("Diamond Details")}</label>
                                              {showdropDown === 1 ? <MinusIcon width={20} height={20} /> :
                                                  <PlusIcon width={20} height={20} />}
                                          </div> */}
                                          {showdropDown === 1 ? 
                                          <div className="wrapper">
                                          <div className="diamond_product-section-product-productdetails-inner">
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Stock ID")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.StockID}</span>
                                              </div>              
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Shape")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Shape   }</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Carat")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Carat}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Colour")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Colour}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Clarity")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Clarity}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Cut")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Cut}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Polish")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Polish}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Symmetry")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Symmetry}</span>
                                              </div>
                                          </div> 
                                          <div className="diamond_product-section-product-productdetails-inner">
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Flourescence")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Fluoresence}</span>
                                              </div>              
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Depth")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Depth}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Table")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Table}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Gridle")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Gridle}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Culet")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Culet}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Measurements")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Measurement}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Graded By")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.GradedBy}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Certification")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{diamonddetails?.Certification}</span>
                                              </div>
  
                                          </div> 
                                          </div>
                                          : null}
                                           {showdropDown === 2 ? 
                                          <div className="diamond_product-section-product-productdetails-inner">
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Shipping By")}</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{t("Order by 4 pm today and received on Friday, 23 August")}</span>
                                              </div>              
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Estimated ship date")}:</span>
                                                  <span className="diamond_product-section-product-productdetails-inner-item-data">{t("1st Class Insured Shipping Service")}</span>
                                              </div>
                                              <div className="diamond_product-section-product-productdetails-inner-item">
                                                  <span className="diamond_product-section-product-productdetails-inner-item-product">{t("Free shipping for all thailand orders")}</span>
                                                  
                                              </div>
                                              <span className="diamond_product-section-product-productdetails-inner-item-data">{t("For international customers, shipping and insurance is SGD $150 + 3% of total order amount. International customers will be responsible for any duties, GST, VAT, taxes or surcharges levied or charged by their country. Please check with local authorities prior to placing your order to confirm taxes and duties.")}</span>
                                          </div>
                                          : null}
                                           {showdropDown === 3? 
                                          <div className="diamond_product-section-product-productdetails-note">
                                              <div className="diamond_product-section-product-productdetails-note-title">{t("Please Note")}:</div>
                                              <ul>
                                                  <li>Prices and availability are subject to change without notice. For more details, please view our Terms of Use.</li>
                                                  <li>The image displayed above is a sample image of the diamond.</li>
                                                  <li>{t("To request for the real photo of the diamond")}<Link>,click here.</Link></li>
                                              </ul>
                                              <div>
                                                  <textarea placeholder="Type something" value={diamondNote} onChange={(e)=>setDiamondNote(e.target.value)}/>
                                              </div>
                                          </div>
                                          : null}
                                      </div> 
                                     
                                </div>
                              <div className="ecommerce-main-layout-collection">
                                      <CollectionSlider />
                                  </div> 
  
  
                              {
                                  (recentviewddata?.length) ?
                                  <div className='ecommerce-recent_view-container'>
                                      <div className='ecommerce-recent_view-container-title'>
                                          {t("See Similar Diamonds")}
                                      </div>
                                      <div className='ecommerce-recent_view-container-inner'>
                                          {
                                              recentviewddata.map((item, key) => {
                                                  return (
  
                                                      <div 
                                                      // to={`/product/${item?.Slug}`} state={{ id: item?._id }} 
                                                      onClick={()=>handleDiamondItem(item?._id)}
                                                      className='ecommerce-recent_view-container-inner-product' key={key}
                                                      >
                                                          <div className='ecommerce-recent_view-container-inner-product-image'>
                                                              <div className="ecommerce-recent_view-container-inner-product-image-imgBoxShow"><img src={item?.main_image} alt='product' /></div>
                                                              
                                                          </div>
                                                          <div className="ecommerce-recent_view-container-inner-product-details text-decoration-none ">
                                                              <div className='ecommerce-recent_view-container-inner-product-details-wrapper'>
                                                                  <div className="ecommerce-recent_view-container-inner-product-details-wrapper-productName">
                                                                      {item?.name}
                                                                  </div>
                                                                  <span>{item?.SKU} </span>
                                                              </div>
                                                              <div className='ecommerce-recent_view-container-inner-product-price'>
                                                                  {item?.price}
                                                              </div>
                                                          </div>
                                                      </div>
  
                                                  )
                                              })
                                          }
                                      </div>
                                  </div >
                                  : ''
                              }
  </>
                      )}  
  
    </div>
  )
}
