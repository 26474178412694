import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { _Api } from '../../api/_call'
import { SetdataTable } from '../../api/setdatatable'
import { error } from '../../common/helpers/toastify'
import DateTime from '../../common/DateTime'
import TableColumnModal from '../../common/modules/tableColumn'
import { Tablehead } from '../../common/modules/Tablehead'
import Advancedfiltermodel from '../../common/modules/advanced-filter-model'
import { editpurchaseorderoutstandinghead } from '../../common/Tablehead'
import { createpurchase } from '../../transaction/subTables/purchase/createpurchase'
import { toFormatPrice, toUnformatPrice } from '../../common/helpers/function'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
const Editpurchaseoutstanding = (props) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const {
    transactiondate,
    location_id,
    supplier_id,
    warehouse_id,
    Voucher_id,
    deliverydate,
    pu_no,
    purchasestatus,
    product_id,
  } = state
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  let total_qty = 0
  let navigate = useNavigate()
  const [newcolumndata, setnewcolumndata] = useState(
    editpurchaseorderoutstandinghead,
  )
  const [datalodaer, setDatsLodaer] = useState(false)
  const [filtername, setFiltername] = useState('')
  let check = ""
  const [poutstandinglist, setPoutstandinglist] = useState('')
  const [limit, setLimit] = useState(100)

  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_editpurchaseorder_outstanding',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const setSubTable = async (event, key, id, locationid) => {
    if (event.target.checked) {
      let pooutstandingdata = [...poutstandinglist]
      const postdata = {
        po_order_id: id,
      }
      let data = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPUOutStandingListDetails`,
      )
      var html_ = createpurchase(data, t)
      pooutstandingdata[key].subTable = html_
      setPoutstandinglist(pooutstandingdata)
    } else {
      let pooutstandingdata = [...poutstandinglist]
      pooutstandingdata[key].subTable = null
      setPoutstandinglist(pooutstandingdata)
    }
  }

  const [excuteTable, setexcuteTable] = useState(false)
  const filterbyname = (search) => {
    Getpurchaseoutstanding(currentPage, limit, search);
    setstate({ ...statepage, currentPage: 1 });
  };
  const getlimit = (limit) => {
    setLimit(limit);
    Getpurchaseoutstanding(1, limit);
    setstate({ ...statepage, currentPage: 1 });

  };
  const [statepage, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { currentPage } = statepage;
  const Getpurchaseoutstanding = async (current, firstlimit, search) => {
    setexcuteTable(false)
    var expand_col = document.querySelector('.table_btn_expand_col')
    if (expand_col) {
      expand_col.classList.remove('rotate-90')
    }
    setDatsLodaer(true)
    try {
      const postdata = {
        search: search ? search : "",
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
        location_id: location_id,
      }
      let resoutstanding = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPuOutStandingList`,
      )
      if (resoutstanding?.code === 200) {
        setexcuteTable(true)
        SetdataTable('tbl_purchaseOrderOutstanding_list', '', newcolumndata)
        setstate({
          ...statepage,
          totalPages: Math.ceil(resoutstanding?.count / postdata.limit),
          currentPage: current ? current : 1
        });
        setPoutstandinglist(resoutstanding?.data)
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable('tbl_purchaseOrderOutstanding_list', '', newcolumndata)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const setSubTableforsku = async (key, id, locationid) => {
    var e = document.querySelector('#table-btn_' + key)
    var classList_ = e.classList
    if (classList_.contains('rotate-90')) {
      classList_.remove('rotate-90')
      let pooutstandingdata = [...poutstandinglist]
      pooutstandingdata[key].subTable = null
      setPoutstandinglist(pooutstandingdata)
    } else {
      classList_.add('rotate-90')
      let pooutstandingdata = [...poutstandinglist]
      const postdata = {
        po_order_id: id,
      }
      let data = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPUOutStandingListDetails`,
      )
      var html_ = createpurchase(data, t)
      pooutstandingdata[key].subTable = html_
      setPoutstandinglist(pooutstandingdata)
    }
  }
  const callsubmit = () => {
    var getcheckbox = document.querySelectorAll('.createPurchaseselect')
    const array = []
    for (var i = 0; i < getcheckbox.length; i++) {
      if (getcheckbox[i].checked) {
        array.push(getcheckbox[i].id)
      }
    }
    if (array.length) {
      navigate('/app/inventory/editPurchase', {
        state: {
          editpurchaseoustandingids: array,
          product_id: product_id,
          location_id: location_id,
          supplier_id: supplier_id,
          warehouse_id: warehouse_id,
          pu_no: pu_no,
          Voucher_id: Voucher_id,
          transactiondate: transactiondate,
          deliverydate: deliverydate,
          purchasestatus: purchasestatus,
        },
      })
    } else {
      error(t("No item Selected!!"))
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])

  useEffect(() => {
    Getpurchaseoutstanding()
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="top-heading-area-left">
                  <Link
                    to="/app/inventory/editPurchase"
                    state={{
                      location_id: location_id,
                      product_id: product_id,
                      supplier_id: supplier_id,
                      warehouse_id: warehouse_id,
                      Voucher_id: Voucher_id,
                      transactiondate: transactiondate,
                      deliverydate: deliverydate,
                      pu_no: pu_no,
                      purchasestatus: purchasestatus,
                    }}
                    className="page-back-btn"
                  >
                    <img
                      src={base_assets + 'images/icons/arrow-left.png'}
                      alt=""
                    />
                  </Link>
                  <div
                    className="main-body-top-tab-bar-left-col nav nav-pills"
                    role="tablist"
                  >
                    <span className="main-body-top-tab-bar-left-col-a nav-link active">
                      <span className="main-body-top-tab-bar-left-col-a-text">
                        {t('purchase order outstanding')}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="main-body-top-tab-bar-right-col">
                  <button
                    className="main-body-top-tab-bar-right-col-a border-0"
                    onClick={callsubmit}
                  >
                    <img
                      src={base_assets + 'images/icons/plus-circle-white.png'}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t('Add to PU')}
                    </span>
                  </button>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <button
                    className="main-body-top-status-bar-filter-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <img
                      src={base_assets + 'images/icons/search-outline.png'}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('TF No./StockID/SKU/Metal/Stone')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                  <Advancedfiltermodel></Advancedfiltermodel>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="tbl_purchaseOrderOutstanding_list"
                    className="common-table product-base-table table-striped w-100"

                  >

                    <Tablehead
                      tablehead={editpurchaseorderoutstandinghead}
                      tblName={'tbl_editpurchaseorder_outstanding'}
                      setfilterby={""}
                    />
                    <tbody>
                      <>
                        {poutstandinglist
                          ? poutstandinglist.map((result, key) => {
                            total_qty += result?.quantity ? result?.quantity : 0
                            return (
                              <React.Fragment key={key}>
                                <tr
                                  key={key}
                                  className={
                                    check
                                      ? check === result?.location_id
                                        ? ''
                                        : 'deactive-row'
                                      : ''
                                  }
                                >
                                  <td>
                                    <div className="com-check">
                                      <input
                                        id={key}
                                        type="checkbox"
                                        className="editpo_Outst_checkbox"
                                        onChange={(e) =>
                                          setSubTable(
                                            e,
                                            key,
                                            result.id,
                                            result?.location_id,
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={key}
                                        className="com-check-label"
                                      />
                                    </div>
                                  </td>
                                  <td>{key + 1}</td>
                                  <td>

                                    {result?.createdAt
                                      ? result?.createdAt
                                      : '-'}
                                  </td>
                                  <td className="po-no sku-col">
                                    {result?.po_no ? result?.po_no : '-'}
                                  </td>
                                  <td className="danger-text">
                                    {result?.supplier_name
                                      ? result?.supplier_name
                                      : '-'}
                                  </td>
                                  <td className="danger-text">
                                    {result?.location_name
                                      ? result?.location_name
                                      : '-'}
                                  </td>
                                  <td
                                    className="sku-col table_btn_expand_col"
                                    onClick={() =>
                                      setSubTableforsku(
                                        key,
                                        result.id,
                                        result?.location_id,
                                      )
                                    }
                                    id={'table-btn_' + key}
                                  >
                                    <div className="td-icons-wrap">
                                      <div>
                                        <img
                                          className="sku-col-arrow cursor-pointer"
                                          src={
                                            base_assets +
                                            'images/icons/right_green_button.png'
                                          }
                                          alt=""
                                        />
                                        <span>
                                          {result?.sku ? result?.sku : '-'}
                                        </span>
                                      </div>
                                      {result?.allocatable ?
                                        <div className="td-icons-wrap-link">
                                          <img
                                            className="img "
                                            src={
                                              base_assets +
                                              'images/icons/link-icon.svg'
                                            }
                                            alt=""
                                          />
                                        </div> : ""}
                                      <div className='tooltip-area-up'>
                                        {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user"><img
                                          className="img"
                                          src={
                                            base_assets +
                                            'images/icons/gray-user.png'
                                          }
                                          alt=""
                                        /> <span>{result?.pos_Qty}</span>
                                        </div> : ""}
                                        <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.pos_no}<br />{result?.delivery_date}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="col-highlight">
                                    {result?.quantity ? result?.quantity : '-'}
                                  </td>
                                  <td className="danger-text text-center">
                                    {result?.delivery_date
                                      ? result?.delivery_date
                                      : '-'}
                                  </td>
                                  <td className="text-end">
                                    {result?.due_days ? result?.due_days : '-'}
                                  </td>
                                  <td>
                                    {result?.total_price
                                      ? toFormatPrice(result?.total_price, { addSymbol: true })
                                      : '-'}
                                  </td>
                                  {result.status.priority === 0 ? (
                                    <td>
                                      <label className="status-tab low-priority">
                                        {t('Low')}
                                      </label>
                                    </td>
                                  ) : result.status.priority === 1 ? (
                                    <td>
                                      <label className="status-tab medium-priority">
                                        {t('Medium')}
                                      </label>
                                    </td>
                                  ) : result.status.priority === 2 ? (
                                    <td>
                                      <label className="status-tab high-priority">
                                        {t('High')}
                                      </label>
                                    </td>
                                  ) : (
                                    ''
                                  )}
                                </tr>
                                {excuteTable ? (
                                  <>
                                    {typeof result.subTable == 'undefined'
                                      ? (result.subTable = '')
                                      : result.subTable}
                                  </>
                                ) : null}
                              </React.Fragment>
                            )
                          })
                          : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{total_qty}</td>
                        <td />
                        <td />
                        <td>
                          {toFormatPrice(
                            poutstandinglist
                              ? poutstandinglist?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.total_price)),
                                0,
                              )
                              : '',
                          )}
                        </td>
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={editpurchaseorderoutstandinghead}
        tblName={'tbl_editpurchaseorder_outstanding'}
        calldatatable={Getpurchaseoutstanding}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
    </React.Fragment>
  );
};

export default Editpurchaseoutstanding;
