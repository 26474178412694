import React, { useEffect, useState } from "react";
import { _Api } from "../api/_call";
const CustomerSearchDetailModal = (props) => {
  const { setCusid, setnewcustomersid } = props;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [filter, setFilter] = useState(false);
  const [KeyCount, setKeyCount] = useState(0);
  const [customerdetails, setCustomerdetails] = useState();
  const [searchVal, setSearchVal] = useState("");
  const [country, setCountry] = useState([])
  const [Namedata, setNamedata] = useState([]);
  const [cussearchkey, setCussearchkey] = useState("");
  const [city, setCity] = useState([])
  const [countrydata, setCountrydata] = useState([]);
  const [citydata, setCitydata] = useState([]);
  const alphabates = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  const GetcustomersDetails = async () => {
    try {
      const postdata = {
        name: Namedata,
        search: cussearchkey,
        "country": countrydata,
        "city": citydata,
      };
      let res = await _Api(postdata, `api/v1/Customer/customer/getList`);
      if (res.code === 200) {
        setKeyCount(0);
        setCustomerdetails(res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const clearall = () => {
    let get = document.getElementsByName("clear");
    for (let i = 0; i < get.length; i++) {
      get[i].checked = false;
    }
    setNamedata([]);
    setCountrydata([])
    setCitydata([])
  };
  const checkallName = () => {
    let allletter = document.querySelectorAll(".namescheck");
    for (let i = 0; i < allletter.length; i++) {
      allletter[i].checked = true;
    }
  };
  const callfilters = (event, label) => {
    if (label === "name") {
      const namedata = [...Namedata];
      if (event.target.checked) {
        if (namedata.filter((e) => e === event.target.value).length === 0) {
          namedata.push(event.target.value);
          setNamedata(namedata);
        }
      } else {
        setNamedata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    } else if (label === "country") {
      const countryarr = [...countrydata];
      if (event.target.checked) {
        if (countryarr.filter(e => e === event.target.value).length === 0) {
          countryarr.push(event.target.value);
          setCountrydata(countryarr);
        }
      } else {
        setNamedata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }

    } else if (label === "city") {
      const cityarr = [...citydata];

      if (event.target.checked) {
        if (cityarr.filter(e => e === event.target.value).length === 0) {
          cityarr.push(event.target.value);
          setCitydata(cityarr);
        }
      } else {
        setNamedata((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    }
  };
  useEffect(() => {
    if (KeyCount === 3 || !cussearchkey) {
      GetcustomersDetails();
    }
    // eslint-disable-next-line
  }, [KeyCount, cussearchkey]);
  const getcustomerfiltter = async () => {

    try {
      const postdata = {}
      let res = await _Api(postdata, `api/v1/App/customer/customerFilter`);
      if (res.code === 200) {
        setCountry(res?.data?.country)
        setCity(res?.data?.city)
      }
    }

    catch (err) {
      console.log(err, 'err');
    }
  }
  useEffect(() => {
    if (filter) {
      getcustomerfiltter();
    }
  }, [filter]);
  const getscarchvalue = async (event, lable) => {
    if (lable === "country") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...country];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setCountry(arr)

        });
      } else {
        getcustomerfiltter()
      }
    }
    else if (lable === "city") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...city];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setCity(arr)

        });
      } else {
        getcustomerfiltter()
      }
    }



  }
  return (
    <>
      <div
        className="modal  customer-search-modal hello"
        id="customerSearchDetailModal"
        tabIndex="-1"
        aria-labelledby="customerSearchDetailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="customerSearchDetailModalLabel">
                Customer search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="customer-search-modal-search-bar">
                <div className="customer-search-modal-search-bar-input">
                  <button className="btn-style customer-search-modal-search-bar-input-btn">
                    <img
                      className="link-img"
                      src={base_assets + "images/icons/search-icon-2.png"}
                      alt=""
                    />
                  </button>
                  <div className="placeholder-up-input form-floating customer-search-modal-search-bar-input-area">
                    <input
                      type="text"
                      className="placeholder-up-input-field form-control bg-white"
                      onKeyPress={(e) =>
                        (e.key === "Enter" && GetcustomersDetails()) ||
                        setKeyCount(KeyCount + 1)
                      }
                      value={cussearchkey ? cussearchkey : ""}
                      onChange={(e) => setCussearchkey(e.target.value)}
                      placeholder="Customer"
                    />
                    <label className="placeholder-up-input-label">
                      Customer
                    </label>
                  </div>
                </div>
                <button
                  className="customer-search-modal-search-bar-filter-btn"
                  onClick={() => setFilter(!filter)}
                >
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </button>
              </div>

              <div className="customer-search-modal-table-wrap">
                <table className="customer-search-modal-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>City</th>
                      <th>Country</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {customerdetails
                        ? customerdetails.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td><div className="user-img">
                                <img src={result?.profile} alt="" />
                              </div></td>
                              <td><button
                                className="btn-style link-color"
                                data-bs-dismiss="modal"
                                onClick={() =>
                                  setnewcustomersid({
                                    id: result?._id,
                                    name: result?.name,
                                  })
                                }
                              >
                                {result?.name ? result?.name : ""}
                              </button></td>
                              <td>{result?.city ? result?.city : ""}</td>
                              <td>{result?.country ? result?.country : ""}</td>
                              <td>{result?.email ? result?.email : ""}</td>
                              <td>{result?.phone ? result?.phone : ""}</td>
                              <td><button
                                className="edit-img btn-style"
                                onClick={() => {
                                  setCusid(result?._id);
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#customerAddModal"
                              >
                                <img
                                  src={
                                    base_assets + "images/icons/edit-icon.png"
                                  }
                                  alt=""
                                />
                              </button></td>
                            </tr>
                          );
                        })
                        : ""}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          filter
            ? "customer-search-filter-modal d-flex"
            : "customer-search-filter-modal"
        }
      >
        <div
          className="modal fade d-block"
          id="customerSearchFilterModal"
          aria-labelledby="customerSearchFilterModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="clear-btn btn-style link-color"
                  onClick={clearall}
                >
                  Clear All
                </button>
                <h5 className="modal-title" id="customerSearchFilterModalLabel">
                  Filter
                </h5>
                <button
                  type="button"
                  className="btn-close focus-none shadow-none"
                  onClick={() => setFilter(!filter)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="customer-search-filter-modal-row">
                  <div className="customer-search-filter-modal-row-col col">
                    <div className="customer-search-filter-modal-row-col-header">
                      <label className="customer-search-filter-modal-row-col-header-label">
                        Name
                      </label>
                      <button
                        className="customer-search-filter-modal-row-col-header-btn btn-style"
                        onClick={checkallName}
                      >
                        Select All
                      </button>
                    </div>
                    <div className="customer-search-filter-modal-row-col-body alphabates-box">
                      <div className="customer-search-filter-modal-row-col-body-check-data">
                        {alphabates.map((result, key) => {
                          return (
                            <div className="customer-search-filter-modal-row-col-body-check-data-single" key={key}>
                              <div className="com-check">
                                <input
                                  id={"n" + key}
                                  name="clear"
                                  className="namescheck"
                                  type="checkbox"
                                  value={result ? result : ""}
                                  onClick={(e) => callfilters(e, "name")}
                                />
                                <label
                                  htmlFor={"n" + key}
                                  className="com-check-label"
                                ></label>
                              </div>
                              <label htmlFor={"n" + key}>{result}</label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="customer-search-filter-modal-row-col col">
                    <div className="customer-search-filter-modal-row-col-header">
                      <label className="customer-search-filter-modal-row-col-header-label">City</label>
                      <button className="customer-search-filter-modal-row-col-header-btn btn-style">Select All</button>
                    </div>
                    <div className="customer-search-filter-modal-row-col-body">
                      <div className="customer-search-filter-modal-row-col-body-search">
                        <img onClick={(e) => getscarchvalue(e, "city")} className="" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                        <input type="text" placeholder="Search"
                          onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "city")}
                          onChange={(e) => setSearchVal(e.target.value)} />
                      </div>
                      <div className="customer-search-filter-modal-row-col-body-check-data">
                        {city?.length ? city.map((result, key) => {
                          return (
                            <div className="customer-search-filter-modal-row-col-body-check-data-single" key={key}>
                              <div className="com-check">
                                <input id={"city" + key} type="checkbox" value={result} name="clear" onClick={(e) => callfilters(e, "city")} />
                                <label htmlFor={"city" + key} className="com-check-label"></label>
                              </div>
                              <label htmlFor={"city" + key}>{result}</label>
                            </div>
                          )

                        })
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="customer-search-filter-modal-row-col col">
                    <div className="customer-search-filter-modal-row-col-header">
                      <label className="customer-search-filter-modal-row-col-header-label">Country</label>
                      <button className="customer-search-filter-modal-row-col-header-btn btn-style">Select All</button>
                    </div>
                    <div className="customer-search-filter-modal-row-col-body">
                      <div className="customer-search-filter-modal-row-col-body-search">
                        <img onClick={(e) => getscarchvalue(e, "country")} className="" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                        <input type="text" placeholder="Search" onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "country")}
                          onChange={(e) => setSearchVal(e.target.value)} />
                      </div>
                      <div className="customer-search-filter-modal-row-col-body-check-data">
                        {country?.length ? country.map((result, key) => {
                          return (
                            <div className="customer-search-filter-modal-row-col-body-check-data-single" key={key}>
                              <div className="com-check">
                                <input id={result?._id} type="checkbox" value={result?.id} name="clear" onClick={(e) => callfilters(e, "country")} />
                                <label htmlFor={result?._id} className="com-check-label"></label>
                              </div>
                              <label htmlFor={result?._id}>{result?.name ? result?.name : ''}</label>
                            </div>
                          )
                        }) : ""

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-style"
                  onClick={() => {
                    setFilter(!filter);
                    GetcustomersDetails();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerSearchDetailModal;
