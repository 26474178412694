import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toFormatPrice } from '../../common/helpers/function'
import { store } from '../../../source/index'
const PaymentLayby = (props) => {
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const { balancedue, debitedamount, Grand_Total, TransactionlaybyDate, setTransactionlaybyDate, setPay_layby_data, laybyshow, pay_layby_data, laybylist } = props
  useEffect(() => {
    if (laybyshow || laybylist) {
      let upadetlist = []
      let pay_layby_data1 = []
      let finaldata = []
      pay_layby_data1.push({
        "Received": "",
        'amount': parseInt(debitedamount?.cash + debitedamount?.bank + debitedamount?.credit_card + debitedamount?.credit_notes)
      })
      finaldata.push({
        "Received": `${(laybylist ? laybylist.length : 0) + pay_layby_data1?.length} Received`,
        'amount': parseInt(debitedamount?.cash + debitedamount?.bank + debitedamount?.credit_card + debitedamount?.credit_notes)
      });
      if (laybylist) {
        upadetlist = laybylist.concat(finaldata);
      }

      if (laybylist) {
        setPay_layby_data(upadetlist)
      } else {
        setPay_layby_data(finaldata)
      }
    }
    // eslint-disable-next-line
  }, [laybyshow, laybylist]);
  const selectTransactionDate = (input) => {
    setTransactionlaybyDate(input)
  }
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { t } = useTranslation()
  return (
    <>
      <div
        className="modal fade common-modal payconf-modal"
        id="PayLaybyModal"
        tabIndex={-1}
        aria-labelledby="PayLaybyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <img
                className="order-successful-modal-img mb-2"
                src={base_assets + 'images/icons/exclamation-mark-green.png'}
                alt='payment'
              />
              <h5 className="order-successful-modal-heading">
                {t("Payment Confirmation")}
              </h5>

              <table className="order-successful-modal-table">
                <tbody>
                  <tr>
                    <td>{t("Grand Total")}</td>
                    <td><b>{toFormatPrice(Grand_Total, { addSymbol: true })}</b></td>
                  </tr>
                  {pay_layby_data.length ? pay_layby_data.map((result, key) => {
                    return (
                      <tr key={key}>
                        <td><b>{result?.Received}</b></td>
                        <td><b>{toFormatPrice(result?.amount, { addSymbol: true })}</b></td>
                      </tr>
                    )
                  }) : ""}
                </tbody>
                <tfoot>
                  <tr>
                    <td> {t("Outstanding Balance")}</td>
                    <td className='danger-text'>
                      <span> <b>{toFormatPrice(balancedue, { addSymbol: true })}</b></span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Due Date")}</td>
                    <td>
                      <div className="date text-start position-relative ">
                        <img
                          className="date-img"
                          src={base_assets + 'images/pos/icons/calendar-red.png'}
                          alt=""
                        />
                        <DatePicker
                          className="date-field danger-text"
                          selected={TransactionlaybyDate}
                          onChange={selectTransactionDate}
                          name="TransactionDate"
                          dateFormat={OrgSettings?.date_picker}
                          autoComplete='none'
                        />
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="modal-footer mt-0">
              <button
                type="button"
                className="btn modal-content-no"
                data-bs-dismiss="modal"
              >
                {t('No')}
              </button>

              <button type="button" className="btn modal-content-yes" data-bs-toggle="modal" data-bs-target="#pinModal">{t("yes")}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentLayby;
