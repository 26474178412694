import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
const Domainchngemodal = (props) => {
    const { dataid } = props
    const { t } = useTranslation();
    const [domaintype, setDomaintype] = useState(dataid?.type)

    return (
        <div className="modal fade com-modal" id="Domainchngemodal" tabIndex="-1" aria-labelledby="DonainchngemodalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ "width": "670px", "maxWidth": "90%" }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title fw-semibold" id="DonainchngemodalLabel">{t("Change Domain For")}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body fs-14">
                        <div className="d-flex gap-3 mb-4">
                            <input type="radio" name="domainradio" className="input-accent-green"
                                onChange={(e) => setDomaintype(1)}
                                checked={domaintype === 1 ? true : false} />
                            <div>
                                <span className="fw-semibold d-block">{t("Primary domain")}</span>
                                <span>{t("Displayed in the address bar when visitors are browsing Online Store")}. </span>
                            </div>
                        </div>
                        <div className="d-flex gap-3 mb-4">
                            <input type="radio" name="domainradio" className="input-accent-green"
                                onChange={(e) => setDomaintype(2)}
                                checked={domaintype === 2 ? true : false} />
                            <div>
                                <span className="fw-semibold d-block">{t("Redirecting domain")} ({t("Current")})</span>
                                <span>{t("Directs users to the primary domain for Online Store")}.</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn text-white border main-green-text" data-bs-dismiss="modal"
                            onClick={() => setDomaintype(dataid?.type)}>{t("Cancel")}</button>
                        <button type="button" className="btn text-white main-green-bg borde-0" data-bs-dismiss="modal" >{t("Change Domain Type")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Domainchngemodal;