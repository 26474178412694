import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import DateTime from "../../common/DateTime";
import { _Api, Api_form } from "../../api/_call";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { Tablehead } from "../../common/modules/Tablehead";
import NotesModalAccounting from "../../common/modules/NotesModalAccounting";
import TableColumnModal from "../../common/modules/tableColumn";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import {
  LocationList,
  SupplierList,
  VoucherList,
  WarehouseList,
} from "../../common/graphql/commonlayouts";
import { puchaseoutstandingorderstablehead } from "../../common/Tablehead";
import { toFormatPrice, toUnformatPrice } from "../../common/helpers/function";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import ProductModal from "../../common/modules/ProductModal";
import TransAndDeliverDate from "../../common/modules/TransAndDeliverDate";
import { ImportExcelModal } from '../../common/modules/importExcel'
import { useTranslation } from 'react-i18next';
import Stockidmodal from "../../common/modules/Stockidmodal";
import { useSelector } from "react-redux";
import TableLoader from "../../../admin/common/TableLoader";
const OutstandingPurchaseOrder = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const generalsettings = useSelector((state) => state.app.generalsettings);
  const {
    purchaseoustandingids,
    type,
    location_id,
    supplier_id,
    warehouse_id,
    Voucher_id,
    transactiondate,
    deliverydate,
    po_order_id,
    importarray,
    excelloges1
  } = state;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const navigate = useNavigate();
  const [limit] = useState(100);
  const [filtername, setFiltername] = useState("");
  const [commanSelected_warehouse, setcommanSelected_warehouse] = useState();
  const [importfile, setImportfile] = useState()
  const [TransactionDate, setTransactionDate] = useState(transactiondate ? transactiondate : new Date());
  const [DeliveryDate, setDeliveryDate] = useState(deliverydate ? deliverydate : new Date());
  const [selecteditem, setSelecteditem] = useState([]);
  const [purchaseoutstanding, setPurchaseoutstanding] = useState([]);
  const [qty, setQty] = useState()
  const [excelloges, setExcelloges] = useState([])
  const [po_id, setPo_id] = useState([])
  const [oldpurchaseoutstanding, setOldpurchaseoutstanding] = useState([]);
  const [commanSelected_vouc, setcommanSelected_vouc] = useState(Voucher_id);
  const [newcolumndata, setnewcolumndata] = useState(
    puchaseoutstandingorderstablehead
  );
  const [datalodaer, setDatsLodaer] = useState(false)
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [commanSelected_loc, setcommanSelected_loc] = useState();
  const [commanSelected_sup, setcommanSelected_sup] = useState();
  const [submitbutton, setSubmitbutton] = useState(true);
  const [Shipping, setShipping] = useState();
  const [Form_note, setForm_note] = useState();
  const [tax, setTax] = useState();
  const [getoneproduct, setOneproduct] = useState();
  const [oneproductindex, setoneproductindex] = useState();
  const [sku, setSku] = useState()
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;

  const filterbyname = (search) => {
    // console.log(search, "valuesss", purchaseoutstanding, oldpurchaseoutstanding)
    // if(search !== ""){
    // const cloneArray = [...purchaseoutstanding];
    // let filteredArray = cloneArray.filter((item)=> (item.SKU).toLowerCase().includes(search.toLowerCase()) || (item.location_name).toLowerCase().includes(search.toLowerCase()) || (item.name).toLowerCase().includes(search.toLowerCase()))
   
    //   setPurchaseoutstanding(filteredArray)
    //   if(filteredArray.length <=0){
    //     UnSetdataTable('tbl_createPurchase_list');
    // }
    // }else{
    //   Getoutstanding();
    // }
  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_outstandingorders_purchase",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const Getoutstanding = async (firstlimit, search) => {
    setDatsLodaer(true)
    try {
      const postdata = {
        po_product_id: purchaseoustandingids,
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
      };
      let resoutstanding = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getCreatePurchaseList`
      );
      if (resoutstanding?.code === 200) {
        SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);
        setPurchaseoutstanding(resoutstanding?.data);
        setOldpurchaseoutstanding(resoutstanding?.data);
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable("tbl_createPurchase_list", "6", newcolumndata);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const deleteIcon = (event) => {
    if (event.target.checked) {
      const values = [...selecteditem, event.target.value];
      setSelecteditem(values);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  };
  const deleteitem = () => {
    const selected = document.querySelectorAll(".createPurchase_select");
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let product_id = selected[i].getAttribute('data-id');
        setPurchaseoutstanding((current) =>
          current.filter((item) => item?.id !== product_id)
        );
        selected[i].checked = false;
      }
    }
    setSelecteditem([]);
    UnSetdataTable('tbl_createPurchase_list');
    setTimeout(() => {
      SetdataTable("tbl_createPurchase_list", "6", newcolumndata);
    }, 100);
  };
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".createPurchase_select");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id');
          let type = selected[i].getAttribute("type");
          if (type === "checkbox") {
            arrya.push(product_id);
          }
        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);
  const calculation_purchase_tax = (event, i) => {
    const { value, name } = event.target;
    const newState = [...purchaseoutstanding];
    let oldmain = [...oldpurchaseoutstanding];

    if (name === 'po_QTY') {
      if (value <= oldmain[i].po_QTY) {
        console.log(value, "in if")
        newState[i] = {
          ...newState[i],
          [name]: value ? value : 0,
        };
      } else {
        error("Enter Quantity less than" + oldmain[i].po_QTY);
        return;
      }
    } else {
      newState[i] = {
        ...newState[i],
        [name]: value,
      };
    }
    var tax_value = parseInt(newState[i]?.tax);
    if (!tax_value) {
      tax_value = 0;
    }
    var qty_value = parseInt(newState[i]?.po_QTY);
    var price_value = newState[i]?.price;
    var amount = qty_value * price_value;
    var tax_amount = (amount * tax_value) / 100;
    var amount_with_tax = amount + tax_amount;
    newState[i] = {
      ...newState[i],
      "amount_total": parseFloat(amount_with_tax),
      "tax": tax_value,
      "price": price_value
    };
    setPurchaseoutstanding(newState);
  };
  const checkstock_id = async (event, key, setqty, setsku) => {
    if (event.target.value) {
      try {
        const postdata = {
          stock_id: event.target.value,
          qty: qty ? qty : setqty,
          SKU: sku ? sku : setsku
        };
        let resstockid = await _Api(
          postdata,
          `api/v1/Inventory/purchase/checkStockID`
        );
        var main = [...purchaseoutstanding];
        if (resstockid?.code === 200) {
          main[key] = {
            ...main[key],
            'stock_id': event.target.value,
          };
          purchaseoutstanding?.forEach((result) => {
            if (purchaseoutstanding[key]?.stock_id !== main[key]?.stock_id) {
              if (result?.stock_id === main[key]?.stock_id) {
                if (main[key]?.stock_id) {
                  main[key] = {
                    ...main[key],
                    'stock_id': "",
                  };
                  error(t(`StockID ${event.target.value} is Dublicate`));
                }
              }
            }
          })

        }
        else {
          error(t(`StockID ${event.target.value} already exists !!`));
          main[key] = {
            ...main[key],
            'stock_id': "",
          };
        }
        setPurchaseoutstanding(main);
      } catch (err) {
        console.log(err, "err");
      }
    }
  };

  const splitrow = (key, id) => {
    var main_data = [...purchaseoutstanding];

    for (let i = 0; i < main_data[key]?.po_QTY - 1; i++) {
      main_data = [...main_data, main_data[key]];
    }
    main_data.sort((a, b) => (a.id > b.id) ? 1 : -1)

    for (let i = 0; i < main_data.length; i++) {
      if (main_data[i]?.id === id) {
        main_data[i] = {
          ...main_data[i],
          "po_QTY": 1,
          "is_split": 1,
          'stock_id': "",
          "class": "input-none",
          "id": main_data[i].id + "_" + i,
          "main_product_id": main_data[i].id,
          'amount_total': main_data[i].price * 1
        };

      }


    }
    UnSetdataTable('tbl_createPurchase_list');
    setPurchaseoutstanding(main_data);
    const timer = setTimeout(() => {
      SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);

    }, 200);
    return () => clearTimeout(timer);
  };
  const countstockid = (stiockidindex) => {
    let main = [...purchaseoutstanding];

    if (stiockidindex) {
      const numbersArray = stiockidindex.split(/\D+/)
      if (numbersArray?.length) {
        main.forEach((element, index) => {
          if (element?.order_type === "repair_order") { index = index - 1; } else {
            let newStockidGroup = '';
            if (numbersArray?.[index]) {
              newStockidGroup = numbersArray?.[index];
            }

            setPurchaseoutstanding((prev) => {
              const updatedArray = [...prev];
              updatedArray[index] = {
                ...updatedArray[index],
                stock_id: newStockidGroup.toString(),
              };
              return updatedArray;
            });
          }
        });
      } else {
        error("Somthing Went Wrong");
      }
    } else {
      error("Please Entre Stock id");
    }
  };
  const handleTextareaChange = (e, index) => {
    const { value } = e.target;

    setPurchaseoutstanding((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = {
        ...updatedArray[index],
        stock_id: value,
      };
      return updatedArray;
    });
  };
  const splitAllRows = () => {
    const purchaseOutstandingCopy = [];
    purchaseoutstanding.forEach((row) => {
      if (row.po_QTY > 1) {
        for (let i = 0; i < row.po_QTY; i++) {
          const newRow = {
            ...row,
            "po_QTY": 1,
            "is_split": 1,
            'stock_id': "",
            "class": "input-none",
            "id": `${row.id}_${i}`,
            "main_product_id": row.id,
            'amount_total': row.price * 1
          };
          purchaseOutstandingCopy.push(newRow);
        }
      } else {
        purchaseOutstandingCopy.push(row);
      }
    });
    purchaseOutstandingCopy.sort((a, b) => (a.id > b.id) ? 1 : -1);
    UnSetdataTable('tbl_createPurchase_list');
    setPurchaseoutstanding(purchaseOutstandingCopy);
    const timer = setTimeout(() => {
      SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);
    }, 200);

    return () => clearTimeout(timer);
  };


  const finalstockidcheck = async () => {
    let data = [...purchaseoutstanding]
    let finalarray = []
    if (data?.length) {
      data.forEach((element, key) => {
        finalarray.push({
          qty: element?.po_QTY,
          SKU: element?.SKU,
          stockid: element?.stock_id
        })
      })
      try {
        if (!commanSelected_loc) {
          error(t("Please select location"));
          return
        }
        if (!commanSelected_sup) {
          error(t("Supplier is Requried!"));
          return
        }
        if (generalsettings?.data?.generalsetup?.allowWarehouse === '1') {
          if (!commanSelected_warehouse) {
            error(t("Please select Warehouse!!"));
            return
          }
        }
        if (!DeliveryDate) {
          error(t("Please select Delivery Date"));
          return
        }
        if (!TransactionDate) {
          error(t("Please select Transaction Date"));
          return
        }
        let postdata = {
          stockIds: finalarray
        }
        let res = await _Api(postdata, `api/v1/Inventory/purchase/checkBulkStockID`)
        if (res?.code === 200) {
          handler_submit()
        } else {
          error(res?.errors)

        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  }
  const handler_submit = async () => {
    const handleValidation = (e) => {
      let formIsValid = true;
      if (!commanSelected_loc) {
        formIsValid = false;
        error(t("Please select location"));
      }
      if (!commanSelected_sup) {
        formIsValid = false;
        error(t("Supplier is Requried!"));
      }
      if (generalsettings?.data?.generalsetup?.allowWarehouse === '1') {
        if (!commanSelected_warehouse) {
          formIsValid = false;
          error(t("Please select Warehouse!!"));
        }
      }
      if (!DeliveryDate) {
        formIsValid = false;
        error(t("Please select Delivery Date"));
      }
      if (!TransactionDate) {
        formIsValid = false;
        error(t("Please select Transaction Date"));
      }

      var allcheck = document.querySelectorAll(".createPurchase_select");
      allcheck.forEach(check => {
        const matchingResult = purchaseoutstanding.find(result => result.id === check.id);
        if (matchingResult && !matchingResult?.pos?.stock_id && !matchingResult.stock_id) {
          formIsValid = false;
          const stockcheck = document.querySelectorAll(".changestockids");
          stockcheck[allcheck.indexOf(check)].style.border = "1px solid red";
        }
      });
      for (var j = 0; j < allcheck.length; j++) {
        let qty = allcheck[j].getAttribute('data-qty');
        if (parseInt(qty) === 0) {
          let qtycheck = document.querySelectorAll(".purchaseqty");
          qtycheck[j].style.border = "1px solid red ";
          formIsValid = false;
        } else {
          let qtycheck = document.querySelectorAll(".purchaseqty");
          qtycheck[j].style.border = "1px solid #f4f4f4 ";
        }
      }
      return formIsValid;
    };

    if (handleValidation()) {
      setSubmitbutton(false);
      let GetloadingID = loading();
      var allcheck = document.querySelectorAll(".createPurchase_select");
      var pudetails = [];
      var array = {
        purchase_data: [],
        subtotal: "",
        purchasetotal: "",
      };
      for (let i = 0; i < allcheck.length; i++) {
        purchaseoutstanding.forEach((result) => {
          if (result.id === allcheck[i].id) {
            pudetails.push(result);
          }
        });
      }
      array["subtotal"] = toFormatPrice(
        purchaseoutstanding
          ? purchaseoutstanding?.reduce(
            (interation, val) =>
              (interation = interation + toUnformatPrice(val?.amount_total)),
            0
          )
          : ""
      );
      var total = document.querySelector(".total_purchase_amount");
      array["purchasetotal"] = total.innerText;
      for (var keys = 0; keys < pudetails.length; keys++) {
        array["purchase_data"].push({
          stock_id: pudetails[keys].stock_id,
          quantity: pudetails[keys].po_QTY,
          tax_percentage: pudetails[keys].tax ? pudetails[keys].tax : 0,
          price: pudetails[keys].price,
          is_split: pudetails[keys].is_split ? pudetails[keys].is_split : 0,
          main_po_product_id: pudetails[keys].is_split
            ? pudetails[keys].main_product_id
            : pudetails[keys].id,
          Stones: pudetails[keys].Stones,
          PriceDetails: pudetails[keys].PriceDetails,
          GrossWt: pudetails[keys].GrossWt,
          NetWt: pudetails[keys].NetWt,
          extra_po_information: pudetails[keys].extra_po_information,


        });
      }
      try {
        const postdata = {
          voucher_id: commanSelected_vouc,
          location_id: commanSelected_loc,
          supplier_id: commanSelected_sup,
          warehouse_id: commanSelected_warehouse,
          delivery_date: DeliveryDate,
          po_order_id: importarray?.length ? importarray[0]?.po_order_id : po_order_id,
          transaction_date: TransactionDate,
          shipping_handling_amount: Shipping ? Shipping : 0,
          note: Form_note,
          tax_amount: tax ? tax : 0,
          purchase_data: array["purchase_data"],
          subtotal_amount: array["subtotal"],
          total_amount: array["purchasetotal"],
        };
        let res = await _Api(
          postdata,
          `api/v1/Inventory/purchase/createPU`
        );
        if (res?.code === 200) {
          setSubmitbutton(true);
          Updateloading(t, GetloadingID, t("Successfully Created!!"));
          const timer = setTimeout(() => {
            navigate("/app/inventory/transactionPurchase")
          }, 100);
          return () => clearTimeout(timer);

        } else {
          setSubmitbutton(true);
          Updateloading(t, GetloadingID, res.errors ? (res.errors) : (res?.message), 'error');

        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };

  useEffect(() => {
    callcolumns();
  }, []);
  useEffect(() => {
    if (importarray) {
      SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);
      setPurchaseoutstanding(importarray);
    } else {
      Getoutstanding();
    }
    // eslint-disable-next-line
  }, []);
  const editproduct_submit = () => {
    var mainarray = [...purchaseoutstanding];
    mainarray[oneproductindex] = getoneproduct;
    setPurchaseoutstanding(mainarray);
  }
  const SampleExcel = async () => {
    const exportLinkElement = document.createElement('a')
    exportLinkElement.hidden = true
    exportLinkElement.download = 'ReplenishOrder.xlsx'
    exportLinkElement.href = process.env.REACT_APP_BASE_ASSETS_URL + 'files/GISpurchaseImportnew.xlsx'
    exportLinkElement.text = 'downloading...'
    document.body.appendChild(exportLinkElement)
    exportLinkElement.click()
    exportLinkElement.remove()
  }

  const readExcel = async () => {
    const handleValidation = (e) => {
      let formIsValid = true
      if (!importfile) {
        formIsValid = false
        error('Choose file!')
      }
      return formIsValid
    }

    let GetloadingID = loading();
    if (handleValidation()) {
      try {
        const formData = new FormData()
        formData.append('file', importfile)
        let res = await Api_form(
          formData,
          `api/v1/Inventory/purchase/uploadPUExcel`,
        )
        if (res.code === 200) {
          setExcelloges(res?.logs)
          setPurchaseoutstanding(res?.data)
          Updateloading(t, GetloadingID, (res?.message));
          setTimeout(() => {
            navigate('/app/inventory/purchaseOrdersOutstanding', {
              state: {
                importarray: res.data,
                type: 'pu_Outstanding_main',
                location_id: commanSelected_loc,
                supplier_id: commanSelected_sup,
                warehouse_id: commanSelected_warehouse,
                Voucher_id: commanSelected_vouc,
                transactiondate: TransactionDate,
                deliverydate: DeliveryDate,
              },
            })
          }, 500)
        } else {
          Updateloading(t, GetloadingID, (res?.message), 'error');
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }

  const selectedproductitem = async (event) => {
    if (event.target.checked) {
      const select = document.querySelectorAll(".puitems");
      const mainselected = []
      for (let i = 0; i < select.length; i++) {
        if (select[i].checked) {
          let puid = select[i].getAttribute("product-id");
          mainselected.push(puid);
        }
      }
      setPo_id(mainselected)
    } else {
      const result = po_id.filter((element) => {
        return element !== event.target.value;
      });
      setPo_id(result);
    }
  }
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".puitems");

      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute("product-id");
          let type = selected[i].getAttribute("type");
          if (type === "checkbox") {
            arrya.push(product_id);
          }

        }
      }
      setPo_id(arrya);
    } else {
      setPo_id([]);
    }
  }, [SelectAllStatus]);

  const downloadexclsheet = async () => {
    let GetloadingID = loading();
    try {
      let postdata = {
        po_product_id: selecteditem.length ? selecteditem : []
      }
      let res = await _Api(postdata, "api/v1/Inventory/purchase/puProductExport")
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }

    }
    catch (err) {
      console.log(err, "err")
    }
  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link
                    to={
                      type === "pu_Outstanding_main"
                        ? "/app/inventory/createPurchase"
                        : "/app/inventory/purchaseOrderOutstanding"
                    }
                    className="page-back-btn"
                  >
                    <img
                      src={base_assets + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </Link>
                  <img
                    src={base_assets + "images/icons/home-icon.png"}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Purchase"}
                    voucherid={Voucher_id}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">{t("Create Purchase")}</h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills">
                  <button className="main-body-top-tab-bar-left-col-a nav-link active table-refresh">
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        src={
                          base_assets +
                          "images/icons/outstanding-green-icon.png"
                        }
                        alt=""
                      />
                      {t("Purchase Order Outstanding")}
                    </span>
                  </button>
                </div>
              </div>
              <div className="main-body-top-inputs-bar">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">{t("pu-002")}</label>
                  <LocationList
                    setcommanSelected_loc={setcommanSelected_loc}
                    locationid={location_id}
                    addclass='row-inactive'
                  />
                  <SupplierList
                    setcommanSelected_sup={setcommanSelected_sup}
                    supplierid={supplier_id}
                    addclass='row-inactive'
                  />
                  {generalsettings?.data?.generalsetup?.allowWarehouse === '1' && (
                    <WarehouseList
                      setcommanSelected_warehouse={setcommanSelected_warehouse}
                      warehouseid={warehouse_id}
                    />
                  )}
                  <TransAndDeliverDate TransactionDate={TransactionDate} DeliveryDate={DeliveryDate} setTransactionDate={setTransactionDate} setDeliveryDate={setDeliveryDate} />
                </div>
                <div className="main-body-top-inputs-bar-col-right">
                  {excelloges?.length || excelloges1?.length ?
                    <div className="dropdown user-log-dropdown d-flex align-items-center">
                      <button className="btn-style stock-take-inner-row-col-mid-header-right-btn mx-1 dropdown-toggle"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                        <img className="note" src={base_assets + "images/icons/note-board-icon-dark.png"} alt="" />
                      </button>

                      <ul className="dropdown-menu">
                        <ul className="nav nav-tabs user-log-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="user-tab" data-bs-toggle="tab" data-bs-target="#user-tab-pane" type="button" role="tab" aria-controls="user-tab-pane" aria-selected="true">{t("Excel Impoet Loges")}</button>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="user-tab-pane" role="tabpanel" aria-labelledby="user-tab" tabIndex="0">
                            <table>
                              <tbody>
                                {excelloges?.length ? excelloges.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        <b className="fw-semibold">{result?.SKU ? result?.SKU : ""}</b><br />
                                        <span>{result?.message ? result?.message : ""}</span><br />
                                      </td>

                                    </tr>
                                  )
                                })
                                  :
                                  excelloges1?.length ? excelloges1.map((result, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>
                                          <b className="fw-semibold">{result?.SKU ? result?.SKU : ""}</b><br />
                                          <span>{result?.message ? result?.message : ""}</span><br />
                                        </td>

                                      </tr>
                                    )
                                  }) : ""}

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </ul>
                    </div> : ""}
                  <button
                    className={
                      submitbutton
                        ? "main-body-top-inputs-bar-col-right-submit-btn com-btn "
                        : "main-body-top-inputs-bar-col-right-submit-btn com-btn d-block  deactive"
                    }
                    data-bs-toggle="modal"
                    data-bs-target="#createPurchaseModal"
                  >
                    {t("Submit")}
                  </button>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                {importarray?.length ?
                  '' :
                  <>
                    {/* <Select
                      className="limit-select short mx-3"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); Getoutstanding(e.value) }}
                    /> */}
                    <div className="main-body-top-status-bar-filter">
                      <input
                        type="text"
                        value={filtername}
                        className="main-body-top-status-bar-filter-input"
                        placeholder={t("PO No./Supplier/Ship to ...")}
                        onKeyPress={(e) => e.key === "Enter" && (setFiltername(e.target.value),filterbyname(e.target.value))}
                        onChange={(e) => setFiltername(e.target.value)}
                      />
                      {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => {setFiltername('');Getoutstanding()}} alt="" /> : ""}
                      <button
                        onClick={() => filterbyname(filtername)}
                        className="main-body-top-status-bar-filter-search"
                      >
                        <i className="fa fa-search" aria-hidden="true" />
                      </button>
                    </div>
                  </>
                }
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <button className="print-export-dropdown-selected-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#Stockidmodal">
                    <img
                      className={"deletebtn d-block"}
                      src={base_assets + "images/icons/clone.svg"}
                      alt=""
                    />
                  </button>
                  <button className="print-export-dropdown-selected-icon">
                    <img
                      className={
                        selecteditem.length > 0
                          ? "deletebtn d-block"
                          : "deletebtn d-none"
                      }
                      src={base_assets + "images/icons/delete-icon.png"}
                      onClick={deleteitem}
                      alt=""
                    />
                  </button>
                  <button className="print-export-dropdown-selected-icon">
                    <img
                      src={base_assets + "images/icons/split.png"}
                      alt=""
                      onClick={() => splitAllRows()}
                    />
                  </button>
                  <div
                    className="main-body-top-status-bar-note-button"
                    data-bs-toggle="modal"
                    data-bs-target="#notesModal"
                  >
                    <img
                      src={base_url + "images/icons/account-icon.png"}
                      alt=""
                    />
                    {t("Accounting")}
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item print-dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>
                      <li className="dropdown-item"
                        onClick={() => { downloadexclsheet() }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#ImportModal" >
                        <div className="dropdown-item-img">
                          <img src={base_assets + "images/icons/import-icon.png"} alt="" />
                        </div>
                        <span>{t("import")}</span>
                      </li>
                      <li className="dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/share-icon.png"}
                            alt=""
                          />
                        </div>
                        <span data-bs-toggle="modal" data-bs-target="#socialModal">{t("share")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="tbl_createPurchase_list"
                    className="common-table first-row-gray w-100 position-relative"
                  >
                    <Tablehead
                      tablehead={puchaseoutstandingorderstablehead}
                      tblName={"tbl_outstandingorders_purchase"}
                      setfilterby={""}
                      setShowoption={setSelectAllStatus}
                    />
                    <tbody>
                      {purchaseoutstanding
                        ? purchaseoutstanding.map((result, key) => {
                          let locationwithprice = result?.PriceDetails.filter((item) => item.location === location_id);
                          return (
                            <tr key={key} >
                              {result?.pos?.order_type === "repair_order" && result?.pos?.stock_id ?
                                <td className="col-highlight">
                                  <div className={"com-check"}>
                                    <input
                                      value={result?.pos?.stock_id || ''}
                                      className="createPurchase_select purchaseoutstandingorder puitems purchaseqty"
                                      id={result?.id}
                                      data-qty={result?.po_QTY}
                                      product-id={result?.po_order_id}
                                      data-id={result?.id}
                                      onClick={(e) => { checkstock_id(e, key, result?.po_QTY, result?.SKU); selectedproductitem(e); setQty(result?.po_QTY); setSku(result?.SKU) }}
                                      type="checkbox"
                                    />
                                    <label
                                      htmlFor={result?.id}
                                      className="com-check-label"
                                    />
                                  </div>
                                </td>
                                :
                                <td className="col-highlight">
                                  {result?.stock_id === "" ? <div className={"com-check"}>
                                    <input
                                      value={result?.id || ''}
                                      className="createPurchase_select purchaseoutstandingorder puitems"
                                      id={result?.id}
                                      data-qty={result?.po_QTY}
                                      product-id={result?.po_order_id}
                                      data-id={result?.id}
                                      type={result?.stock_id === "" ? "" : "checkbox"}
                                      onClick={(e) => { error("Enter stock id"); selectedproductitem(e) }}
                                    />
                                    <label
                                      htmlFor={result?.id}
                                      className="com-check-label"
                                    />
                                  </div> :
                                    <div className="com-check">
                                      <input
                                        value={result?.id || ''}
                                        className="createPurchase_select purchaseoutstandingorder puitems"
                                        id={result?.id}
                                        data-qty={result?.po_QTY}
                                        data-id={result?.id}
                                        product-id={result?.po_order_id}
                                        type="checkbox"
                                        onClick={(e) => { deleteIcon(e); selectedproductitem(e) }}
                                        readOnly
                                      />
                                      <label
                                        htmlFor={result?.id}
                                        className="com-check-label"
                                      />
                                    </div>}
                                </td>}

                              <td className="col-highlight">
                                <div className="td-icons-wrap">
                                  {result?.allocatable ? <div className="td-icons-wrap-link">
                                    <img

                                      className="img"
                                      src={
                                        base_assets +
                                        'images/icons/link-icon.svg'
                                      }
                                      alt=""
                                    />
                                  </div> : ""}
                                  <div className='tooltip-area-up'>
                                    {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user"><img
                                      className="img cursor-pointer"
                                      src={
                                        base_assets +
                                        'images/icons/gray-user.png'
                                      }
                                      alt=""
                                    />
                                    </div> : ""}
                                    <span className="tooltip-area-up-text">{result?.customer_name}{result?.customer_name ? <br /> : ''}{result?.pos_no}<br />{result?.delivery_date}</span>
                                  </div>
                                </div>
                              </td>
                              <td className="col-highlight" style={{ zIndex: 0 }}>{key + 1}</td>
                              <td className="col-highlight">
                                <div className="table-pro-img">
                                  <img
                                    src={
                                      result?.main_image
                                        ? result?.main_image
                                        : ""
                                    }
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td className="po-no col-highlight">
                                {result?.name ? result?.name : "-"}
                              </td>
                              <td className="col-highlight link-color cursor-pointer" onClick={() => { setOneproduct(result); setoneproductindex(key) }} data-bs-toggle="modal" data-bs-target="#ProductInfoModal">
                                {result?.SKU ? result?.SKU : ""}
                              </td>
                              {result?.pos?.order_type === "repair_order" && result?.pos?.stock_id ?
                                <td className="col-highlight"

                                >{result?.pos?.stock_id}</td>
                                :
                                <td>
                                  <div className="col-input ">
                                    {result?.is_split === 1 ?
                                      <textarea
                                        value={result?.stock_id || ''}
                                        key={key}
                                        id="stockidcheck"
                                        className="changestockids stock-field"
                                        type="text"
                                        name="stockid"
                                        onBlur={(e) => checkstock_id(e, key, result?.po_QTY, result?.SKU)}
                                        onChange={(e) => handleTextareaChange(e, key)}
                                      >
                                      </textarea> :
                                      <input
                                        value={result?.stock_id || ''}
                                        key={key}
                                        id="stockidcheck"
                                        className="changestockids stock-field"
                                        type="text"
                                        name="stockid"
                                        onBlur={(e) => checkstock_id(e, key, result?.po_QTY, result?.SKU)}
                                        onChange={(e) => handleTextareaChange(e, key)}

                                      />
                                    }
                                  </div>
                                </td>}

                              <td>
                                {result?.metal_name
                                  ? result?.metal_name
                                  : "-"}
                              </td>
                              <td>
                                {result?.stone_name
                                  ? result?.stone_name
                                  : "-"}
                              </td>
                              <td>
                                {result?.size_name ? result?.size_name : "-"}
                              </td>
                              <td>
                                {" "}
                                <div className="col-input" >
                                  {
                                    result?.is_split === 1 ?
                                      <input
                                        type="text"
                                        name="price"
                                        onChange={(e) =>
                                          calculation_purchase_tax(e, key)
                                        }
                                        defaultValue={result?.price}
                                      />
                                      : <input
                                        type="text"
                                        name="price"
                                        onChange={(e) =>
                                          calculation_purchase_tax(e, key)
                                        }
                                        defaultValue={result?.price}
                                      />}
                                </div>
                              </td>
                              <td>
                                <img
                                  className={
                                    result?.po_QTY <= 1 ? "d-none" : "d-block"
                                  }
                                  src={base_assets + "images/icons/split.png"}
                                  alt=""
                                  onClick={() => splitrow(key, result?.id)}
                                />
                              </td>
                              {result?.pos?.order_type === "repair_order" && result?.po_QTY ?
                                <td>{result?.po_QTY}</td>
                                :
                                <td>
                                  <div className="col-input">
                                    <input
                                      type="text"
                                      className={
                                        result?.class ? "purchaseqty input-none" : "purchaseqty"
                                      }
                                      id="qtycheck"
                                      name="po_QTY"
                                      value={result?.po_QTY || ''}
                                      onChange={(e) => { calculation_purchase_tax(e, key); setQty(result?.po_QTY); }}
                                    />
                                  </div>
                                </td>
                              }

                              <td>
                                <div className="col-input">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      calculation_purchase_tax(e, key)
                                    }
                                    name="tax"
                                    defaultValue="0"
                                  />
                                </div>
                              </td>
                              {

                              }
                              <td>{locationwithprice.length ? toFormatPrice(locationwithprice[0].retailprice_Inc, { addSymbol: true }, locationwithprice[0].currency) : ''}</td>
                              <td>
                                {result?.amount_total
                                  ? toFormatPrice(result?.amount_total, { addSymbol: true })
                                  : "-"}
                              </td>
                              <td>{result?.pos_no ? result?.pos_no : "-"}</td>
                              <td>
                                {result?.customer_name
                                  ? result?.customer_name
                                  : "-"}
                              </td>
                              <td>
                                {result?.delivery_date
                                  ? result?.delivery_date
                                  : "-"}
                              </td>
                              <td>
                                {result?.due_days ? result?.due_days : "-"}
                              </td>
                              <td>
                                {result?.location_name
                                  ? result?.location_name
                                  : "-"}
                              </td>
                              <td>{result?.remark ? result?.remark : "-"}</td>
                              <td>
                                {result?.pos?.url ? <div onClick={() => {
                                  if (result?.pos?.url) {
                                    window.open(result?.pos?.url, '_blank', 'noreferrer')
                                  }
                                }}><img
                                    className="pdf-image"
                                    src={base_assets + "images/icons/pdf-icon.png"}
                                    alt=""
                                  /></div>
                                  : "-"
                                }
                              </td>
                            </tr>
                          );
                        })
                        : ""}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="main-green-text left-0 text-start " colSpan={4}>
                          {selecteditem.length > 0 ? (
                            <span> {selecteditem.length} {t("item selected")}</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>
                          {purchaseoutstanding
                            ? purchaseoutstanding?.reduce(
                              (interation, val) =>
                              (interation =
                                interation + parseInt(val.po_QTY)),
                              0
                            )
                            : ""}
                        </td>
                        <td />
                        <td />
                        <td>
                          {toFormatPrice(
                            purchaseoutstanding
                              ? purchaseoutstanding?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.amount_total)),
                                0
                              )
                              : ""
                            , { addSymbol: true })}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  {datalodaer && <TableLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit={editproduct_submit} />
      </div>
      <ImportExcelModal
        setImportfile={setImportfile}
        readExcel={readExcel}
        SampleExcel={SampleExcel}
        importfile={importfile}
      />
      <Stockidmodal
        countstockid={countstockid} />
      <TableColumnModal
        tablehead={puchaseoutstandingorderstablehead}
        tblName={"tbl_outstandingorders_purchase"}
        calldatatable={Getoutstanding}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <NotesModalAccounting
        setForm_note={setForm_note}
        setTax={setTax}
        setShipping={setShipping}
        settotalamount={toFormatPrice(
          purchaseoutstanding
            ? purchaseoutstanding?.reduce(
              (interation, val) =>
              (interation =
                interation + toUnformatPrice(val?.amount_total)),
              0
            )
            : ""
        )}
      ></NotesModalAccounting>
      <ConfirmationModel
        handler_submit={finalstockidcheck}
        statement={"Create purchase"}
      ></ConfirmationModel>
    </React.Fragment>
  );
};

export default OutstandingPurchaseOrder;
