import axios from 'axios';
const Apiauth = async function (querystring = "", Url = "") {
    try {
        var data = querystring;
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + 'api/v1/' + Url,
            headers: {
                'authorization': localStorage.getItem("UserToken"),
                'Content-Type': 'application/json'
            },
            data: data
        };
        const response = await axios(config);
        if (response.status === 403) {
            localStorage.removeItem("UserToken");
            localStorage.removeItem('updateModulePermission');
            localStorage.removeItem('updateUserPermission');
            localStorage.removeItem("LOGINWITHPIN");
            var currentPath = window.location.pathname;
            window.location.href = "/login?msg=sessionexpired&history=" + encodeURIComponent(currentPath);
        } else {
            return response.data;
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            console.error('Status code:', error.response.status);
        } else if (error.request) {
            console.error('No response received:', error.request);
        } else {
            console.error('Error during request setup:', error.message);
        }
    }
};

export default Apiauth;
