import React, { useEffect, useState } from 'react'
import "./common2.scss"
import SideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { _Apiauth } from '../../common/api'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
const Register = (props) => {
    const { setOpenmodal, setEmailVerifydata } = props
    const { t } = useTranslation()
    const [signupdata, setSignupdata] = useState()
    const [validation, setValidation] = useState()
    const [selectdata, setSelectdata] = useState({ country: [] });
    const [lodaer, setLodaer] = useState(false)
    const allsignupdata = (keyName, value) => {
        setSignupdata((prevState) => ({
            ...prevState,
            [keyName]: value,
        }))
    }
    // eslint-disable-next-line
    const webregister = async () => {
        let valid = () => {
            let fromisvalid = true
            if (!signupdata?.fname) {
                setValidation(prevState => ({
                    ...prevState,
                    namevalid: {
                        message: 'First Name is required',
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    namevalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!signupdata?.lname) {
                setValidation(prevState => ({
                    ...prevState,
                    lnamevalid: {
                        message: "Last Name is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    lnamevalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!signupdata?.country?.value) {
                setValidation(prevState => ({
                    ...prevState,
                    countryvalid: {
                        message: "Country is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    countryvalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            if (!signupdata?.email) {
                setValidation(prevState => ({
                    ...prevState,
                    emailvalid: {
                        message: "Email is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                const isEmail = (email) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
                if (!isEmail(signupdata?.email)) {
                    setValidation(prevState => ({
                        ...prevState,
                        emailvalid: {
                            message: "Invalid Email",
                            status: true
                        }
                    }));
                    fromisvalid = false
                } else {
                    setValidation(prevState => ({
                        ...prevState,
                        emailvalid: {
                            message: "",
                            status: false
                        }
                    }));
                }
            }
            if (!signupdata?.password) {
                setValidation(prevState => ({
                    ...prevState,
                    passwordvalid: {
                        message: "Password is required",
                        status: true
                    }
                }));
                fromisvalid = false
            } else {
                setValidation(prevState => ({
                    ...prevState,
                    passwordvalid: {
                        message: "",
                        status: false
                    }
                }));
            }
            return fromisvalid;
        }

        if (valid()) {
            setLodaer(true)
            try {
                let signupobj = { ...signupdata }
                signupobj.country = signupdata?.country?.value
                let postdata = signupobj
                var res = await _Apiauth(postdata, `auth/register`)
                if (res?.statusCode === 200) {
                    setLodaer(false)
                    if (res?.isVarified === 1) {
                        setEmailVerifydata({ accessToken: res?.accessToken, email: signupdata?.email })
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: true,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }))
                    } else {
                        setLodaer(false)
                        localStorage.setItem('ecomaccesstoken', (res?.accessToken));
                        setValidation(prevState => ({
                            ...prevState,
                            sussess: {
                                message: "Register Successfully",
                                status: true
                            }
                        }));
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: false,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }));
                    }
                } else {
                    setLodaer(false)
                    setValidation(prevState => ({
                        ...prevState,
                        sussess: {
                            message: res?.message,
                            status: true
                        }
                    }));
                }
            }
            catch (err) {
                setLodaer(false)
                if (err.response.status) {
                    setValidation(prevState => ({
                        ...prevState,
                        sussess: {
                            message: err.response.data.message,
                            status: true
                        }
                    }));
                }
                console.log(err, 'err')
            }
        }

    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                webregister();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [webregister]);
    const getcountry = async () => {
        try {
            let postdata = {};
            let res = await _Apiauth(postdata, `redis/countryList`)
            if (res?.statusCode === 200) {
                let countrydata = [];
                res?.data.forEach((result) => {
                    countrydata.push({
                        label: result?.name,
                        value: result?.id,
                    });
                });
                setSelectdata({ country: countrydata });
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        getcountry();
    }, []);
    return (
        <div className='ecommerce-login-overlay'>
            <div className='ecommerce-login'>
                <span className='Cross_icon'
                    onClick={() => {
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: false,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }));
                    }} >
                    <CrossIcon width={30} height={30} />
                </span>
                <div className='ecommerce-login-inner registerpage'>
                    <div className='formdata'>
                    <img className='ecommerce-login-inner-logo' src={SideBanner} alt='side_banner' />
                    <div className='ecommerce-login-inner-title'>
                        <div className='ecommerce-login-inner-title-heading'>{t("Create Account")}</div>
                        <div className='ecommerce-login-inner-title-sub-title'> {t("Enter your information below to proceed.If you already have an account, please log in instead")}</div>
                    </div >
                    <div className='ecommerce-login-inner-input_section'>
                        <div className={`ecommerce-login-inner-input_section-inner ${validation?.namevalid?.status && "required"}`}>
                            {/* <label>
                                {t("First Name")}
                            </label> */}
                            <input className={`ecommerce-login-inner-input_section-inner-input ${validation?.namevalid?.status && "border-danger"}`}
                                onChange={(e) => allsignupdata("fname", e.target.value)} placeholder='First Name'/>
                            {validation?.namevalid?.status ?
                                <div className='text-danger validation-message'>
                                    {t(`${validation?.namevalid?.message}`)}
                                </div>
                                : ""}
                        </div>


                        <div className={`ecommerce-login-inner-input_section-inner ${validation?.lnamevalid?.status && "required"}`}>
                            {/* <label>
                                {t("Last Name")}
                            </label> */}
                            <input className={`ecommerce-login-inner-input_section-inner-input ${validation?.lnamevalid?.status && "border-danger"}`}
                                onChange={(e) => allsignupdata("lname", e.target.value)} placeholder='Last Name'/>
                            {validation?.lnamevalid?.status ?
                                <div className='text-danger validation-message'>
                                    {t(`${validation?.lnamevalid?.message}`)}
                                </div>
                                : ""}
                        </div>
                        <div className={validation?.countryvalid?.status ? "ecommerce-login-inner-input_section-inner w-100 border-danger" : "ecommerce-login-inner-input_section-inner w-100"}>
                            {/* <label>{t("Country")}</label> */}
                            <Select
                                className={"ecommerce-dropdown"}
                                options={selectdata?.country}
                                value={signupdata?.country}
                                onChange={(e) => allsignupdata("country", e)}
                                classNamePrefix="common-select"
                                isSearchable={true}
                                placeholder="Country"
                            />
                            {validation?.countryvalid?.status ?
                                <div className='text-danger validation-message'>
                                    {t(`${validation?.countryvalid?.message}`)}
                                </div>
                                : ""}
                        </div>
                        <div className={`ecommerce-login-inner-input_section-inner ${validation?.emailvalid?.status && "required"}`}>
                            {/* <label>
                                {t("Email")}
                            </label> */}
                            <input className={`ecommerce-login-inner-input_section-inner-input ${validation?.emailvalid?.status && "border-danger"}`}
                                onChange={(e) => allsignupdata("email", e.target.value)} placeholder='Email'/>
                            {validation?.emailvalid?.status ?
                                <div className='text-danger validation-message'>
                                    {t(`${validation?.emailvalid?.message}`)}
                                </div>
                                : ""}
                        </div>
                        <div className={`ecommerce-login-inner-input_section-inner ${validation?.passwordvalid?.status && "required"}`}>
                            {/* <label>
                                {t("Password")}
                            </label> */}
                            <input className={`ecommerce-login-inner-input_section-inner-input ${validation?.passwordvalid?.status && "border-danger"}`}
                                type='password'
                                onChange={(e) => allsignupdata("password", e.target.value)} placeholder='Password'/>
                            {validation?.passwordvalid?.status ?
                                <div className='text-danger validation-message'>
                                    {t(`${validation?.passwordvalid?.message}`)}
                                </div>
                                : ""}
                        </div>
                        {validation?.sussess?.status ?
                            <div className='text-danger validation-message'>{t(validation?.sussess?.message)}</div> : ""
                        }
                        {lodaer ?
                            <button className='ecommerce-login-inner-input_section-btn'>
                                <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div>
                            </button> :
                            <button className='ecommerce-login-inner-input_section-btn' onClick={() => webregister()}>{t("Sign Up")}</button>}

                    </div>
                    <div className='ecommerce-login-inner-create-account'>
                        <div className='ecommerce-login-inner-create-account-register_btn'>
                            {t("Already have an account")}?
                            <span onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: true,
                                    showRegisterModel: false,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false
                                }));
                            }}>
                                {t("LOGIN")}
                            </span>
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice'>
                            {t("By continuing, I confirm that I have read and agree to the")}
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice no_padding'>
                          {t("Privacy Notice")} {t("and Term and Conditions")}
                        </div>
                    </div>
                    </div>
                </div >
                <div className='ecommerce-login-sidebanner'>
                    <div className='ecommerce-login-sidebanner-holder'>
                        <img src={SideBanner} alt='side_banner' />
                    </div>
                </div >
            </div >
        </div>

    )
}

export default Register