import React, { useState, useEffect, useCallback } from "react"
import { Link, useNavigate } from "react-router-dom";
import { _Api } from "../api/_call";
import { SetdataTable, UnSetdataTable } from "../api/setdatatable";
import { Tablehead } from "../common/modules/Tablehead";
import { productmaintablehead } from "../common/Tablehead";
import TableColumnModal from "../common/modules/tableColumn";
import Productfiltermodal from "../common/modules/productfilter";
import ProductImportExportModel from "./productImportExportModel";
import { Pagination } from "../common/Pagination";
import DeleteModal from "../common/modules/deletemodal";
import { success, error, loading, Updateloading } from "../common/helpers/toastify";
import ProductImageSliderModal from "../common/modules/productImageslider";
import SocialModal from "../common/modules/SocialModal/SocialModal";
import { useTranslation } from 'react-i18next';
import Select from "react-select"
import { useSelector } from 'react-redux';
import giftimage from '../../assets/images/icons/Packaging.svg'
import TableLoader from "../../admin/common/TableLoader";
const MainList = (props) => {
  const { t } = useTranslation();

  let navigate = useNavigate();
  const [filtername, setFiltername] = useState("");
  const [selecteditem, setSelecteditem] = useState([]);
  const [productlist, setProductlist] = useState([]);
  const [collections, setCollections] = useState(0);
  const [items, setItems] = useState(0);
  const [collectionsFilter, setCollectionsFilter] = useState([]);
  const [itemsFilter, setItemsFilter] = useState([]);
  const [filterApply, setFilterApply] = useState(false);
  const [variant, setVariant] = useState(0);
  const [productcount, setProductcount] = useState(0);
  const [productfilterdetails, setProductfilterdetails] = useState();
  const [newcolumndata, setnewcolumndata] = useState(productmaintablehead);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [isActive, setIsActive] = useState(false);
  const [limit, setLimits] = useState(100);
  const [imageid, setImageid] = useState();
  const [socialurl, setSocialurl] = useState();
  const [shareloader, setShareloader] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const [addparmission, setAddparmission] = useState(0)
  const [editparmission, setEditparmission] = useState(0)
  const [deleteparmission, setDeleteparmission] = useState(0)
  const [printparmission, setPrintparmission] = useState(0)
  const [getSkipNumbers, setSkipNumbers] = useState(0)
  const [getProductExportList,setGetProductExportList] = useState(false)

  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.Product?.View)
      setAddparmission(parmissiondatashow?.Product?.Add)
    setEditparmission(parmissiondatashow?.Product?.Edit)
    setDeleteparmission(parmissiondatashow?.Product?.Delete)
    setPrintparmission(parmissiondatashow?.Product?.Print)
  }, [parmissiondatashow]);
  const [product_value_arr_, setProduct_value_arr] = useState([
    { product_id: "", name: "", varient_type: "" },
  ]);
  let selectAllStatus_arr = [];

  useEffect(() => {
    callcolumns();
  }, []);

  const getshareproductmaster = async () => {
    setShareloader(true);
    try {
      const postdata = {
        "search": filtername,
        Item: itemsFilter,
        Collection: collectionsFilter

      };
      let res = await _Api(postdata, `api/v1/Inventory/inventory/print/productMaster`);

      if (res.code === 200) {
        setSocialurl(res?.link);
        setShareloader(false);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }

  }
  const getprintproductmaster = async () => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "search": filtername,
        Item: itemsFilter,
        Collection: collectionsFilter
      };
      let res = await _Api(postdata, `api/v1/Inventory/inventory/print/productMaster`);

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a');
          exportLinkElement.hidden = true;
          exportLinkElement.download = res?.name ? res?.name : "report.pdf";
          exportLinkElement.href = res?.link;
          exportLinkElement.text = "downloading...";
          exportLinkElement.setAttribute('target', '_blank');
          document.body.appendChild(exportLinkElement);
          exportLinkElement.click();
          exportLinkElement.remove();
          Updateloading(t, GetloadingID, t("download successfully"));

        }, 50)
        setSocialurl(res?.link);
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, t('error'));
    }

  }
  const handleFilter = (cls = false) => {
    if (cls) {
      Getproduct(currentPage, limit, "");
    } else {
      Getproduct(currentPage, limit, filtername);
    }
    setstate({ ...state, currentPage: 1 });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleFilter();
    }
  };
  const handleButtonClickClear = () => {
    setFiltername("");
    handleFilter(true)
  };

  const handleButtonClick = () => {
    handleFilter();
  };

  const getlimit = (limit) => {
    setLimits(limit);
    Getproduct(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    Getproduct(current, "");

  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_product_main",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getCardCounts = useCallback(async () => {
    try {
      const postdata = {
        search: filtername,
        Item: itemsFilter,
        Collection: collectionsFilter,
      };
      let resproduct = await _Api(
        postdata,
        `api/v1/App/product/getproductListCount`
      );
      if (resproduct?.code === 200) {
        var resproduct_data = resproduct?.data;
        setCollections(resproduct_data?.Collection);
        setItems(resproduct_data?.Item);
        setVariant(resproduct_data?.variant);
        setProductcount(resproduct_data?.sku);

      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [filtername, itemsFilter, collectionsFilter]);
  const Getproduct = async (current, limits, search) => {
    UnSetdataTable("product_list");
    setFilterApply(false);
    setIsActive(true);
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      const postdata = {
        search: search ? search : "",
        limit: limits ? limits : limit,
        skip: search ? 0 : skipNumber,
        Item: itemsFilter,
        Collection: collectionsFilter,
      };
      let resproduct = await _Api(
        postdata,
        `api/v1/App/product/getproductList`
      );
      if (resproduct?.code === 200) {
        setSkipNumbers(skipNumber);
        setProductlist(resproduct?.data);
        getCardCounts();
        SetdataTable("product_list", 3, newcolumndata, [1, 4, 10, 11]);
        setIsActive(false);
        setstate({
          ...state,
          totalPages: Math.ceil(resproduct?.count / postdata.limit),
          currentPage: current ? current : 1
        });


      } else {
        SetdataTable("product_list", 3, newcolumndata, 0);
        setIsActive(false);
      }
    } catch (err) {
      console.log(err, "err");
      setIsActive(false);
    }
  };
  useEffect(() => {
    Getproduct();
    // eslint-disable-next-line 
  }, []);
  useEffect(() => {
    if (filterApply) {
      Getproduct();
    }
    // eslint-disable-next-line 
  }, [filterApply]);
  const deleteIcon = (event) => {
    if (event.target.checked) {
      const mainselected = [...selecteditem, event.target.value];
      setSelecteditem(mainselected);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  };
  const handler_delete = async () => {
    try {
      const postdata = {
        id: selecteditem,
      };
      let resproductdelete = await _Api(
        postdata,
        `api/v1/App/product/delproduct`
      );
      if (resproductdelete?.code === 200) {
        setSelecteditem([]);
        let checkboxs = document.querySelectorAll(".productList_checkBox");
        for (let i = 0; i < checkboxs.length; i++) {
          checkboxs[i].checked = false;
        }
        success(t(resproductdelete?.message));
        Getproduct();
      } else {
        error(t(resproductdelete?.message));
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const filteringproducts = async () => {
    try {
      const postdata = {};
      let resproductfilter = await _Api(
        postdata,
        `api/v1/App/product/getMasterFliters`
      );
      if (resproductfilter?.code === 200) {
        setProductfilterdetails(resproductfilter?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const callimportimage = () => {
    navigate("/app/product/importproductimage");
  };
  const setProductValue = (product_id, name, varient_type) => {
    setProduct_value_arr([
      { product_id: product_id, name: name, varient_type: varient_type },
    ]);
  };

  useEffect(() => {
    var allCheckbox = document.querySelectorAll('.productList_checkBox');
    if (selectAllStatus === true) {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = true;
        selectAllStatus_arr.push(checkBox_selectAble.value);
      });
      let arrya = [];
      for (let i = 0; i < allCheckbox.length; i++) {
        if (allCheckbox[i].checked) {
          let product_id = allCheckbox[i].getAttribute('data-id');
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = false;
      });
      setSelecteditem([]);
    }
    setSelecteditem(selectAllStatus_arr);
    // eslint-disable-next-line
  }, [selectAllStatus]);

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  return (
    <React.Fragment>
      <div className="main-body product-page-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="main-body-heading">{t("Products")}</h1>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/sku.png"}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t("SKU")}</p>
                      <h2 className="inventory-value sku">{productcount}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/pro-cart.png"}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t("Item")}</p>
                      <h2 className="inventory-value item">{items}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/customer-pro.png"}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t("Collection")}</p>
                      <h2 className="inventory-value value">{collections}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/Variants.png"}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t("Variants")}</p>
                      <h2 className="inventory-value variant">{variant}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { getlimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <button
                    className="main-body-top-status-bar-filter-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#productfiltermodal"
                    onClick={filteringproducts}
                  >
                    <img
                      src={base_assets + "images/icons/search-outline.png"}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("Product Name, SKU...")}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? (
                    <img
                      className="main-body-top-status-bar-filter-clear"
                      src={base_assets + 'images/icons/False.png'}
                      onClick={handleButtonClickClear}
                      alt=""
                    />
                  ) : ''}
                  <button
                    onClick={handleButtonClick}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className={deleteparmission ? "print-export-dropdown-selected-icon" : "d-none"}>
                    <img
                      className={
                        selecteditem.length > 0
                          ? "deletebtn d-block"
                          : "deletebtn d-none"
                      }
                      src={base_assets + "images/icons/delete-icon.png"}
                      alt=""
                      data-bs-toggle="modal"
                      data-bs-target="#delete-listedModal"
                    />
                  </button>

                  <Link
                    to={
                      selecteditem.length === 1
                        ? "/app/product/clone/" + selecteditem[0]
                        : "deletebtn d-none"
                    }

                  >
                    <img
                      className={
                        selecteditem.length === 1
                          ? "deletebtn d-block"
                          : "deletebtn d-none"
                      }
                      src={base_assets + "images/icons/clone.svg"}
                      alt=""
                    />
                  </Link>
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <div className={addparmission ? "product-main-create" : "d-none"}>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#productImportExportModel_data"
                      className="pro-img me-0 import-export-btn"
                      onClick={()=>setGetProductExportList(true)}
                    >
                      <img
                        className="m-auto d-block"
                        src={base_assets + "images/icons/exit_icon.png"} alt=""
                      />
                    </button>
                    <button onClick={callimportimage} className="pro-img">
                      <img
                        className=""
                        src={base_assets + "images/icons/image.png"} alt=""
                      />
                      {t("Product Image")}
                    </button>

                    <Link
                      to="/app/product/add-new"
                      state={{ product_value_arr: product_value_arr_ }}
                      className="text-decoration-none"
                    >
                      <button className="create">
                        <img
                          className=""
                          src={
                            base_assets + "images/icons/plus-circle-white.png"
                          } alt=""
                        />
                        {t("Create")}
                      </button>
                    </Link>
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className={printparmission ? "dropdown-item print-dropdown-item" : "d-none"}
                        onClick={(e) => { getprintproductmaster(e.target.value); }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>

                      <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#socialModal" onClick={() => getshareproductmaster()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/share-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("share")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  id="product_list"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead
                    tablehead={productmaintablehead}
                    tblName={"tbl_product_main"}
                    setShowoption={setSelectAllStatus}
                  />
                  <tbody>
                    {productlist
                      ? productlist.map((result, key) => {
                        return (
                          <tr key={key + getSkipNumbers} className="">
                            <td>
                              <div className="com-check" id="">
                                <input
                                  className="gisClassTh productList_checkBox"
                                  value={result?._id}
                                  data-id={result?._id}
                                  type="checkbox"
                                  id={"product_" + result?._id}
                                  onClick={deleteIcon}
                                  data-name={result?.name ? result?.name : ""}
                                  data-variant={
                                    result?.product_variants_enable
                                  }
                                  name="master_product_checkbox"
                                />
                                <label
                                  htmlFor={"product_" + result?._id}
                                  className="com-check-label"
                                />
                              </div>
                            </td>
                            <td className="fixed-side">
                              {parseInt(key + getSkipNumbers + 1)}
                            </td>
                            <td className="fixed-side" onClick={() => setImageid(result?._id)} data-bs-toggle="modal" data-bs-target="#ProductViewModal">
                              <div className="table-pro-img">
                                <img
                                  src={
                                    result?.main_image
                                      ? result?.main_image
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                            </td>
                            <td className="fixed-side">
                              {result?.name ? result?.name : ""}
                            </td>
                            <td className="fixed-side ">
                              {result?.ID ? result?.ID : ""}
                            </td>

                            <td className="fixed-side yellow-text pro-type-img">
                              {result?.product_variants_enable === "1" ? (
                                <img
                                  className=""
                                  src={
                                    base_assets +
                                    "images/icons/variant-sm.png"
                                  }
                                  alt=""
                                />
                              ) : result?.product_variants_enable === "2" ? (
                                <img
                                  className=""
                                  src={
                                    base_assets + "images/pro-ring-img.png"
                                  }
                                  alt=""
                                />
                              ) : result?.product_variants_enable === "3" ? (
                                <img
                                  className=""
                                  src={
                                    giftimage
                                  }
                                  alt=""
                                />
                              ) :
                                ""
                              }
                            </td>
                            <td className="fixed-side">
                              <Link
                                className="link-color text-decoration-none"
                                onClick={() =>
                                  setProductValue(
                                    result?._id,
                                    result?.name,
                                    result?.product_variants_enable
                                  )
                                }
                                state={{
                                  product_value_arr: [
                                    {
                                      product_id: result?._id,
                                      name: result?.name,
                                      varient_type:
                                        result?.product_variants_enable,
                                    },
                                  ],
                                }}
                                to={editparmission ? "/app/product/add-new/" + result?._id : ""}
                              >
                                {result?.SKU ? result?.SKU : ""}
                              </Link>
                            </td>
                            <td className="fixed-side">
                              {result?.item_name ? result?.item_name : ""}
                            </td>
                            <td className="sku-col">
                              {result?.collection_name
                                ? result?.collection_name
                                : ""}
                            </td>
                            <td className="fixed-side">
                              {result?.VariantsCount
                                ? result?.VariantsCount
                                : "0"}
                            </td>
                            <td className="fixed-side text-end">
                              {result?.ReorderPoints
                                ? result?.ReorderPoints
                                : ""}
                            </td>
                            <td className="fixed-side text-end">
                              {result?.ReorderQuantitys
                                ? result?.ReorderQuantitys
                                : "0"}
                            </td>
                            {result?.Status === "Active" ? (
                              <td className="fixed-side link-color text-center">
                                {t("Active")}
                              </td>
                            ) : (
                              <td className="fixed-side danger-text">
                                {" "}
                                {t("Inactive")}
                              </td>
                            )}
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="main-green-text left-0">
                        {selecteditem.length > 0 ? (
                          <span> {selecteditem.length} {t("item selected")}</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
                {isActive && <TableLoader />}
              </div>
            </div>
            {productlist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />


        <TableColumnModal
          tablehead={productmaintablehead}
          tblName={"tbl_product_main"}
          setnewcolumndata={setnewcolumndata}
          calldatatable={Getproduct}
        ></TableColumnModal>



      </div>
      <div
        className="modal fade common-modal"
        id="inventoryreverse"
        tabIndex={-1}
        aria-labelledby="inventoryreverseLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <img className="common-modal-exclamation-img"
                src={base_assets + "images/icons/exclamation-mark-red.png"}
                alt=""
              />
              <p className="modal-content-text">
                {t("Are you sure you want to delete selected product from reserve list ?")}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn modal-content-no"
                data-bs-dismiss="modal"
              >
                {t("No")}
              </button>
              <button type="button" className="btn modal-content-yes-red">
                {t("yes")}
              </button>
            </div>
          </div>
        </div>
      </div>


      <DeleteModal
        statement={t("Product")}
        handler_delete={handler_delete}
      ></DeleteModal>
      <Productfiltermodal
        productfilterdetails={productfilterdetails}
        setCollectionsFilter={setCollectionsFilter}
        setItemsFilter={setItemsFilter}
        setFilterApply={setFilterApply}
      ></Productfiltermodal>
      <ProductImportExportModel
        master_product_checkbox_value={selecteditem}
        setGetProductExportList={setGetProductExportList}
        getProductExportList={getProductExportList}
      ></ProductImportExportModel>
      <ProductImageSliderModal
        main_id={imageid}
      ></ProductImageSliderModal>

    </React.Fragment>
  );
};

export default MainList;
