import React, { useState, useEffect, useCallback } from "react";
import "./login-signup.scss";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { _Apiauth } from '../../api/_call';
const ForgotPin = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [updatepin, setUpdatepin] = useState("")
  const [confirmpin, setConfirmpin] = useState("");
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [validation, setValidation] = useState()
  console.log(validation, "validation")
  const [showpage, setshowpage] = useState(false)
  const checkauth = async (authToken) => {
    try {
      let postdata = {
        authToken: authToken
      }
      let res = await _Apiauth(postdata, `Auth/Web/reset-password-check`)
      if (res?.code === 200) {
        setshowpage(true)
        setValidation(prevState => ({
          ...prevState,
          validlink: {
            message: res?.message,
            status: res?.status
          }
        }));
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (token) {
      checkauth(token)
    }
  }, [token]);
  const setforgotpin = useCallback(async () => {
    const handleValidation = (e) => {
      let formIsValid = true;
      if (confirmpin?.length < 6) {
        formIsValid = false;
        setValidation(prevState => ({
          ...prevState,
          errormessage: {
            message: "PIN should have 6 digits",
            status: true
          },
          loader: {
            status: false
          }
        }));
      }
      if (!confirmpin) {
        formIsValid = false;
        setValidation(prevState => ({
          ...prevState,
          errormessage: {
            message: "Pleace enter PIN",
            status: true
          },
          loader: {
            status: false
          }
        }));
      }
      return formIsValid;
    }
    if (updatepin === confirmpin) {
      setValidation(prevState => ({
        ...prevState,
        loader: {
          status: true
        }
      }));
      if (handleValidation()) {
        try {
          let postdata = {
            authToken: token,
            loginPIN: confirmpin,
          }
          let res = await _Apiauth(postdata, `Auth/Web/reset-pin`)
          if (res.code === 200) {
            setValidation(prevState => ({
              ...prevState,
              errormessage: {
                message: "",
                status: false
              }
            }));
            setValidation(prevState => ({
              ...prevState,
              success: {
                message: res?.message,
                status: true
              }
            }));
            setValidation(prevState => ({
              ...prevState,
              loader: {
                status: false
              }
            }));
            setValidation(prevState => ({
              ...prevState,
              validlink: {
                message: "",
                status: false
              }
            }));
          } else {
            setValidation(prevState => ({
              ...prevState,
              errormessage: {
                message: res?.message,
                status: false
              }
            }));
            setValidation(prevState => ({
              ...prevState,
              loader: {
                status: false
              }
            }));
          }
        }
        catch (err) {
          console.log(err, "err")
        }
      }
    } else {
      setValidation(prevState => ({
        ...prevState,
        errormessage: {
          message: "PIN is not match",
          status: true
        }
      }));
    }
    // eslint-disable-next-line
  }, [token, confirmpin, updatepin, validation])
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        setforgotpin();
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [setforgotpin]);

  return (

    <div className="main-body bg-white p-0">
      {showpage ?
        <div className="login-page position-relative d-flex align-items-center justify-content-center">
          <img className="login-page-bg position-absolute end-0 bottom-0 w-75" src={base_assets + "images/login-bg.png"} alt="" />
          <div className="login-page-row d-flex justify-content-center align-items-center w-100">
            <div className="text-center login-page-left-col">
              <Link to="/">
                <img className="login-page-left-col-img" src={base_assets + "images/gis-xl-logo.png"} alt="" />
              </Link>
              <span className="d-block mt-4 fs-4 fw-semibold login-page-left-col-text">{t("Start your journey with us")} <span className="main-green-text">{t(" today")}</span></span>
            </div>
            {validation?.validlink?.status ?
              <div className="login-page-right-col">
                <h1 className="login-page-right-col-heading fs-2 fw-semibold">{t("Forgot PIN")}</h1>

                <div className="login-page-right-col-form">
                  <div className="col-md-12">
                    <div className={`placeholder-up-input w-100 form-floating mb-4`}>
                      <input
                        maxLength={6}
                        pattern="[0-9]*"
                        type="password"
                        className={`placeholder-up-input-field form-control fs-6 px-3 ${validation?.errormessage?.status ? "border-danger" : ""}`} id="" placeholder="new Pin"
                        onChange={(e) => setUpdatepin(e.target.value)}
                        onKeyDown={(e) => {
                          if (!(e.key === 'Backspace' || /[0-9]/.test(e.key))) {
                            e.preventDefault();
                          }
                        }} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="" >{t("New Pin")}</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className={`placeholder-up-input w-100 form-floating mb-4`}>
                      <input
                        maxLength={6}
                        pattern="[0-9]*"
                        type="password"
                        className={`placeholder-up-input-field form-control fs-6 px-3 ${validation?.errormessage?.status ? "border-danger" : ""}`} id="" placeholder="confirm  Pin" onChange={(e) => setConfirmpin(e.target.value)}
                        onKeyDown={(e) => {
                          if (!(e.key === 'Backspace' || /[0-9]/.test(e.key))) {
                            e.preventDefault();
                          }
                        }} />
                      <label className="placeholder-up-input-label fs-6" htmlFor="" >{t("Confirm Pin")} </label>
                    </div>
                  </div>
                  <span>
                    <p>
                      {validation?.errormessage?.status ? <span className="d-block mb-4 text-danger">{validation?.errormessage?.message ? validation?.errormessage?.message : ""}</span> : ""}
                      {validation?.success?.status ? <span className="d-block mb-4 main-green-text">{validation?.success?.message ? validation?.success?.message : validation?.success?.message}</span> : ""}
                    </p>
                  </span>
                  <button className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold mb-0" onClick={() => setforgotpin()}>
                    {validation?.loader?.status ?
                      <div className="border-bottom-0 border-width-2px d-block text-white mx-auto spinner-border flex-shrink-0" role="status"></div> :
                      <span className="h-100 w-100 justify-content-center align-items-center d-flex">{t("Submit")}</span>
                    }</button>

                </div>

              </div> :
              <>
                <div className={`login-page-right-col  ${validation?.validlink?.message ? "text-danger" : "main-green-text"}`}>
                  {validation?.validlink?.message ? validation?.validlink?.message :
                    validation?.success?.message}
                  <Link to="/login" className="login-page-right-col-form-login-btn main-green-bg text-white text-center w-100 border-0 rounded-1 fs-6 fw-semibold d-flex align-items-center justify-content-center mb-4 text-decoration-none">
                    <span className="h-100 w-100 justify-content-center align-items-center d-flex"> {t("Back to Login")}</span>
                  </Link>
                </div>
                {/* <p>
                  {validation?.success?.status ? <span className="d-block mb-4 main-green-text">{validation?.success?.message ? validation?.success?.message : validation?.success?.message}</span> : ""}
                </p>
                <div className="login-page-right-col text-danger">{validation?.validlink?.message ? validation?.validlink?.message : ""}</div> */}
              </>}
          </div>
        </div>
        : <div className="spinner-border text-info position-fixed start-50 top-50" role="status">
          <span className="visually-hidden">{t("Loading...")}</span> </div>}
    </div>
  )
}

export default ForgotPin;