import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIconRed from "../../assets/images/deleteIcon.svg";
import html2canvas from 'html2canvas';
import Deletedeletepopup from 'Ecommerce/template_three/components/Delete/DeleteConfirmationPopup';
import MinusIcon from 'Ecommerce/template_three/assets/icons/MinusIcon';
import PlusIcon from 'Ecommerce/template_three/assets/icons/PlusIcon';
import { _Api } from 'Ecommerce/common/api';
import Spinner from 'Ecommerce/common/Spinner';
import { toFormatPrice } from 'Ecommerce/common/Formateprice';
import RightArrow from 'admin/common/icons/right-arrow';
import EditIcon from 'Ecommerce/template_three/assets/icons/EditIcon';
import DeleteIcon from 'Ecommerce/template_three/assets/icons/DeleteIcon';
import PrintIcon from 'Ecommerce/template_three/assets/icons/PrintIcon';
import DownloadIcon from 'Ecommerce/template_three/assets/icons/DownloadIcon';
import CalendarIcon from 'admin/common/icons/sidebar/CalendarIcon';
import RightSideArrow from 'Ecommerce/template_three/assets/icons/RightSideArrow';
import LeftArrow from 'Ecommerce/common/icons/LeftArrow';
import './cart.scss'
import Shoppingbag from 'admin/common/icons/Shoppingbag';
import ShareIcon from 'Ecommerce/template_three/assets/icons/ShareIcon';
import EmptyCartImage from '../../../common/images/emptyCart.png'
import BottomBar from 'Ecommerce/template_three/include/Common/BottomBar';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';


export default function ShoppinCart() {

    let shipdata = JSON.parse(localStorage.getItem('CartItems'))
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [cartdata, setCartdata] = useState([])
    const [loading, setLoading] = useState(false)
    const [checkid, setCheckid] = useState([])
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [singleDeleteItem, setSingleDeleteItem] = useState();
    const screenSize = useScreenSize();
    const getQuantity = (index, lable, qty) => {
        let array = [...cartdata]
        if (lable === "plus") {
            array[index].qty = qty + 1
        } else if (lable === "minus") {
            array[index].qty = qty - 1
        }
        setCartdata(array)
        let setupdatearray = []
        array.forEach((result) => {
            setupdatearray.push({
                id: result?.id,
                qty: result?.qty
            })
        })
        // getcarts(setupdatearray)
        localStorage.setItem('CartItems', JSON.stringify(setupdatearray));
    }

    const deleteItems = (removeid) => {
        let array = [...cartdata]
        let update = array.filter(e => e?.id !== removeid);
        setCartdata(update);
        let setupdatearray = []
        update.forEach((result) => {
            setupdatearray.push({
                id: result?.id,
                qty: result?.qty
            })
        })
        localStorage.setItem('CartItems', JSON.stringify(setupdatearray));
        setSingleDeleteItem((prev)=>prev=null);
    }
    const getcarts = async (shipdata) => {
        setLoading(true)
        // if (shipdata?.length) { await shipdata?.map((result) => cartids.push(result?.id)) }        
        let postdata = {}
        const result = shipdata?.map(item => ({
            diamond_id: item?.diamonddetails?.id,
            id: item?.id,
            qty: item?.qty,
            temp_cart_id: item?.temp_cart_id
        }));

        // shipdata?.map((result) => {
        //     if (result?.diamonddetails) {
        //         details.push(
        //             {
        //                 "id": result?.id,
        //                 "qty": result?.qty,
        //                 "diamond_id": result?.diamonddetails?.diamonddata?.id,
        //                 "diamond_note": result?.diamonddetails?.diamonddata?.note,
        //                 "metal": result?.diamonddetails?.productdiamond?.metal?.value,
        //                 "shape": result?.diamonddetails?.productdiamond?.shape?.shapeId,
        //                 "size": result?.diamonddetails?.productdiamond?.size?._id,
        //                 "stone": result?.diamonddetails?.productdiamond?.stone?.value,
        //                 "pointer": result?.diamonddetails?.productdiamond?.pointer?.label,
        //                 "temp_cart_id": result?.temp_cart_id,
        //                 "custom_design": {
        //                     "engraving_text": result?.custom_design?.customizeText,
        //                     "side": result?.custom_design?.inorout,
        //                     "engraving_position": result?.custom_design?.textposition?.label,
        //                     "engraving_logo": result?.custom_design?.imageFile,
        //                     "font": result?.custom_design?.fonts?.label,
        //                     "logo_position": result?.custom_design?.imgPosition?.label
        //                 },
        //             }
        //         )
        //     } else {
        //         details.push(result)
        //     }
        // })
        try {
            postdata = { "diamond_ids": result }

            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                let array = [...res?.data];
                array?.map((result) => {
                    return result.qty = parseInt(shipdata.filter(e => e?.id === result?.id)[0]['qty']);
                })
                setCartdata(array)
                setLoading(false)
                if (res?.deleted_ids?.length) {
                    shipdata.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }


    const checkcart = (event, data) => {
        if (data?.id) {
            setCheckid((prevChecked) =>
                event.target.checked
                    ? data?.diamond ? [...prevChecked, { id: data?.id, qty: data?.qty, temp_cart_id: data?.temp_cart_id, diamond_id: data?.diamond?.id, price: (parseFloat(data?.UnFormatPrice) + parseFloat(data?.diamond?.UnFormatPrice)) }] : [...prevChecked, { id: data?.id, qty: data?.qty, price: data?.UnFormatPrice }]
                    : prevChecked.filter((item) => item?.id !== data?.id)
            )
        } else {
            let fulldata = []
            if (event.target.checked) {
                cartdata?.forEach((result) => {
                    if (result?.diamond) {
                        fulldata.push({ id: result?.id, qty: result?.qty, temp_cart_id: result?.temp_cart_id, diamond_id: result?.diamond?.id, price: (parseFloat(result?.UnFormatPrice) + parseFloat(result?.diamond?.UnFormatPrice)) })
                    } else {
                        fulldata.push({ id: result?.id, qty: result?.qty, price: result?.UnFormatPrice })
                    }
                });

            }
            setCheckid(fulldata)
        }
    }

    const handleClearAll = () => {
        if (checkid.length === cartdata.length) {
            let setupdatearray = [];
            setCartdata((prev) => []);
            setCheckid((prev) => []);
            localStorage.setItem('CartItems', JSON.stringify(setupdatearray));
        }
    }
    const negvigatecheckout = (lable, data) => {
        if (lable === "checkout") {
            navigate("/shipping", { state: { checkoutItems: data } })
        }
        if (lable === "appoinment") {
            navigate("/appointment", { state: { checkoutItems: data } })
        }
    }
    useEffect(() => {
        getcarts(shipdata)
        // eslint-disable-next-line
    }, [])

    const showDeleteConfirmation = () =>{
       
        setIsPopupVisible(true);
      }

      const handleConfirm = (id) => {
        console.log(id, "id handle confirm")
        console.log(singleDeleteItem, "Check Single Delete Item")
        if(checkid?.length === cartdata?.length){
            handleClearAll();
            console.log("all")
        }else{
            deleteItems(singleDeleteItem?.id);
            console.log("one by one")
        }
        setIsPopupVisible(false);
      };
      
      const handleCancel = () => {
        setIsPopupVisible(false);
      };

      const handleBookAppointment = () =>{
        console.log(checkid, "check id items")
        navigate("/appointment", { state: { checkoutItems : checkid }})
      }

      const downloadPageAsPNG = () => {
        const rootElement = document.getElementById('shoppingcart');
        html2canvas(rootElement).then((canvas) => {
            const imageURL = canvas.toDataURL('image/png');
            const anchor = document.createElement('a');
            anchor.download = 'shoppingcart.png';
            anchor.href = imageURL;
            anchor.click();
        });
    };
    const handlePrint = () => {
        window.print();
    };


  return (
    <div className=' ecommerce-main-layout-bodyContent' id="shoppingcart">
    <div className='shopping_cart_page'>
                    <div className='shopping_cart_page-header cursor-pointer' onClick={()=>navigate('/Collection')}>
                        <span className='icon-holder'><span className='arrowicon'><LeftArrow/></span></span> <span>{t("Back to Home")}</span>
                    </div>
        <div className='shopping_cart_page-inner'>
        <div className='shopping_cart_page-inner-headBox'>
            {cartdata?.length ? 
            <span className='shopping_cart_page-inner-headBox-heading'>
                
                <span className='shopping_cart_page-inner-headBox-heading-items'><span className='rightsideicon cursor-pointer' onClick={()=>navigate('/Collection')}><RightSideArrow/></span><Shoppingbag/><span>{t("Cart")}</span></span>
                <span className='shopping_cart_page-inner-headBox-heading-subtitle'>{t("To view items in your cart.")}</span>
            </span> 
            : 
            <span className='shopping_cart_page-inner-headBox-heading'>{t("Your cart is empty")}</span>
            }
            <div className='shopping_cart_page-inner-headBox-icons'>
                
                    <span><ShareIcon/></span>
                    <span onClick={() => downloadPageAsPNG()}><DownloadIcon color={"#767676"}/></span>
                    <span onClick={() => handlePrint()}><PrintIcon color={"#767676"}/></span>
            </div>
        </div>
        
        {loading ? (<div className='h-100 d-flex justify-content-center align-items-center'>

            <Spinner />

        </div>) : (
                <>
                {cartdata?.length ? (
                    <>
                        <div className='shopping_cart_page-inner-cartTableBox' >
                            <div className='w-100'>
                                <div className='shopping_cart_page-inner-cartTableBox-header'>
                                    {/* <div className='shopping_cart_page-inner-cartTableBox-header-wrapper'>
                                        <div className='shopping_cart_page-inner-cartTableBox-header-wrapper-left'>
                                            <div className="com-ecomCheckBox">
                                                <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                    onChange={(e) => checkcart(e, "")}
                                                    checked={checkid?.length === cartdata?.length}
                                                    id='allcartcheck' />
                                                <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor='allcartcheck'> {t("Product")}</label>
                                            </div>
                                        </div>
                                        <div className='shopping_cart_page-inner-cartTableBox-header-right'>
                                            <div className='responsive'>{t("Price")}</div>
                                            <div className='responsive'>{t("Stock")}</div>
                                            <div className='responsive'>{t("Quantity")}</div>
                                        </div>
                                    </div> */}
                                </div>
                                <div>
                                    
                                    {cartdata?.length ? cartdata.map((result, key) => {
                                        return (

                                            <>
                                              <div key={key} className={`ecom_cartproduct ${result?.diamond ? 'mixandmatch' : ""}`}>
                                                <div className="ecom-darkthemed-checkbox">
                                                        <input type="checkbox" 
                                                        className="itemscheckboxs"
                                                        id={result?.id}
                                                        checked={checkid?.some(item => item.id === result?.id)}

                                                        onChange={(e) => checkcart(e, result)}
                                                        />
                                                        <label className="ecom-darkthemed-checkbox-label"
                                                        htmlFor={result?.id}></label>
                                                </div>
                                                <div className="ecom_cartproduct-imageBox">
                                                  <span className="product-img-span">
                                                    <img src={result?.main_image} alt="" />
                                                  </span>
                                                </div>
                                                <div className=" ecom_cartproduct-detailsBox">
                                                  <div className="ecom_cartproduct-detailsBox-firstBox">
                                                    <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                                      <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                        state={{ id: result?.motherProduct?._id }}
                                                        className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.name}</Link>
                                                      <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.price}</span>
                                                    </p>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-instock">{t("In Stock")}</div>
                                                                                  <div className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper">
                                                                                      { (result?.Metal === '18KBK' || result?.Metal.includes('BK')) && <div className={`black`}></div>}
                                                                                      { (result?.Metal === '18KWG' || result?.Metal.includes('WG')) && <><div className={`titanium`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Sliver")}</span></>}
                                                                                      { (result?.Metal === '18KYG' || result?.Metal.includes('YG')) && <><div className={`sunshine`}></div><span className="ecom_cartproduct-detailsBox-firstBox-colorthemewrapper-title">{t("Yellow Gold")}</span></>}
                                                                                      { (result?.Metal === '18KRG' || result?.Metal.includes('RG')) && <div className={`peach`}></div>}
                                                                                  </div>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-content">
                                                    <p className="contentPara">{t("Size")}: {result?.size}</p>
                                                    <div className="ecom_cartproduct-detailsBox-firstBox-price">{result?.price}</div>
                                                    </div>
                                                  </div>
                                                  {/* {result?.diamond ?
                      
                                                  <div key={key} className=" ecom_cartproduct diamonditem">
                                                    <div className="com-ecomCheckBox">
                      
                                                    </div>
                                                    <div className="ecom_cartproduct-imageBox">
                                                      <span className="product-img-span">
                                                        <img src={result?.diamond?.main_image} alt="" />
                                                      </span>
                                                    </div>
                                                    <div className=" ecom_cartproduct-detailsBox">
                                                      <div className="ecom_cartproduct-detailsBox-firstBox">
                                                        <p className="ecom_cartproduct-detailsBox-firstBox-productName">
                                                          <Link to={`/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                            state={{ id: result?.motherProduct?._id }}
                                                            onClick={() => modalRef.current.hide()} className="ecom_cartproduct-detailsBox-firstBox-productName text-decoration-none">{result?.diamond?.name}</Link>
                                                          <span className='ecom_cartproduct-detailsBox-firstBox-productName tab-productPriceBlock'>{result?.diamond?.price}</span>
                                                        </p>
                                                        <div className="ecom_cartproduct-detailsBox-firstBox-content">
                                                          <p className="contentPara">{result?.diamond?.SKU}</p>
                                                        </div>
                                                      </div>
                                                      <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                                                        <div className='setCountShow'>
                                                          <button onClick={!result?.diamond ? () => changeqty('minus', result?.id) : null} className='setCountShow-minusIcon'>
                                                            <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                                          </button>
                                                          <span className='setCountShow-count'>{result?.diamond ? 1 : result?.qty}</span>
                                                          <button onClick={!result?.diamond ? () => changeqty('plus', result?.id) : null} className='setCountShow-plusIcon'>
                                                            <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                                          </button>
                                                        </div>
                                                        <div className='ecom_cartproduct-detailsBox-secondCountBox-priceShow desktop-productPriceShow'>{result?.diamond?.price}</div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  : ""} */}
                                                  <div className='ecom_cartproduct-detailsBox-secondCountBox'>
                                                    <div className='setCountShow'>
                                                      <button onClick={()=>getQuantity(key, "minus", result?.qty)} className='setCountShow-minusIcon'>
                                                        <MinusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                      <span className='setCountShow-count'>{result?.qty}</span>
                                                      <button onClick={() => getQuantity(key, "plus", result?.qty)} className='setCountShow-plusIcon'>
                                                        <PlusIcon width={14} height={14} color={'#1D1D1F'} />
                                                      </button>
                                                    </div>
                                                    <span onClick={() => {showDeleteConfirmation(); setSingleDeleteItem((prev)=>prev = result)}} className='label-icon'>
                                                                        <img src={DeleteIconRed} alt='deleteicon' />
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                                {/* <div className={`shopping_cart_page-inner-cartTableBox-content`} key={key}>
                                                    <div className='shopping_cart_page-inner-cartTableBox-content-left'>
                                                        <div className='shopping_cart_page-inner-cartTableBox-content-left-item'>
                                                            <div className="com-ecomCheckBox">
                                                                <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck cursor-pointer"
                                                                    id={result?.id}
                                                                    checked={checkid?.some(item => item.id === result?.id)}

                                                                    onChange={(e) => checkcart(e, result)} />
                                                                <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold align-items-center"
                                                                    htmlFor={result?.id}
                                                                >
                                                                    <span onClick={() => {showDeleteConfirmation(); setSingleDeleteItem((prev)=>prev = result)}} className='label-icon'>
                                                                        <img src={DeleteIconRed} alt='deleteicon' />
                                                                    </span>
                                                                    <img className='shopping_cart_page-inner-cartTableBox-content-left-item-img' src={result?.main_image} alt='' />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className='shopping_cart_page-inner-cartTableBox-content-left-item-detail'>
                                                            <p className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-name'>{result.name} </p>
                                                            <Link
                                                                to={result?.diamond ? "/mixandmatch" : `/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                                state={result?.diamond ? { temp_cart_id: (shipdata.filter((item) => item.id === result?.id)?.[0]?.temp_cart_id), activeTab: 'Product' } : { id: result?.motherProduct?._id }}
                                                                className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-sku'>
                                                                {result?.SKU ? result?.SKU : "-"}
                                                            </Link>
                                                            <p className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-subname'>{t("Size : ")}{result?.Size ? result?.Size : ""}</p>
                                                            <div className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-responsive'>
                                                                <div className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-responsive-leftside'>
                                                                    <span>{t("In Stock")}</span>
                                                                    <div className={`cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                        <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                            <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                        </button>
                                                                        <div className='digit'>{result?.qty}</div>
                                                                        <button
                                                                            onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-price dark'>{result.price}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right'>
                                                        <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-price dark'>{result.price}</div>
                                                        <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-price'>{t("In Stock")}</div>
                                                        <div className="cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-qty">
                                                            <div className={`cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                    <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                </button>
                                                                <div className='digit'>{result?.qty}</div>
                                                                <button
                                                                    onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                </button>
                                                            </div>
                                                           
                                                        </div>
                                                      
                                                    </div>
                                                </div>
                                                {
                                                    result?.diamond ?
                                                        <div className="shopping_cart_page-inner-cartTableBox-content mixandmatch" key={key + result?.id}>
                                                            <div className='shopping_cart_page-inner-cartTableBox-content-left'>
                                                                <div className='shopping_cart_page-inner-cartTableBox-content-left-item' key={key + result?.id + 1}>
                                                                    <div className="com-ecomCheckBox">
                                                                        <label className="com-ecomCheckBox-labelBox mixandmatch cursor-pointer fw-semibold align-items-center"
                                                                            htmlFor={result?.id}
                                                                        >
                                                                            <img className='shopping_cart_page-inner-cartTableBox-content-left-item-img' src={result?.diamond?.main_image} alt='' />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className='shopping_cart_page-inner-cartTableBox-content-left-item-detail'>
                                                                    <p className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-name'>{result?.diamond?.name} </p>
                                                                    <Link
                                                                        to={result?.diamond ? "/mixandmatch" : `/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                                        state={result?.diamond ? { temp_cart_id: (shipdata.filter((item) => item.id === result?.id)?.[0]?.temp_cart_id), activeTab: 'Diamond' } : { id: result?.motherProduct?._id }}
                                                                        className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-sku'>
                                                                        {result?.diamond?.SKU ? result?.diamond?.SKU : "-"}
                                                                    </Link>
                                                                    <div className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-responsive'>
                                                                        <div className='shopping_cart_page-inner-cartTableBox-content-left-item-detail-responsive-leftside'>
                                                                            <span>{t("In Stock")}</span>
                                                                            <div className={`cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                                <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                                    <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                </button>
                                                                                <div className='digit'>{result?.qty}</div>
                                                                                <button
                                                                                    onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-price dark'>{result?.diamond?.price}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right'>
                                                                <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-price dark'>{result?.diamond?.price}</div>
                                                                <div className='cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-price'>{t("In Stock")}</div>
                                                                <div className="cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-qty">
                                                                    <div className={`cartQuantiyBox shopping_cart_page-inner-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                        <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                            <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                        </button>
                                                                        <div className='digit'>{result?.qty}</div>
                                                                        <button
                                                                            onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""} */}
                                            </>
                                        )
                                    }) : ""}

                                </div>
                            </div>
                            {/* <div className='shopping_cart_page-inner-cartTableBox-right'>
                                <div className='shopping_cart_page-inner-cartTableBox-right-head'>{t("ORDER SUMMARY")}</div>
                                <div className='shopping_cart_page-inner-cartTableBox-right-content'>
                                    <div className='shopping_cart_page-inner-cartTableBox-right-content-one'>
                                        <span>{t("Subtotal")} {checkid?.length ? `(${checkid?.length} ${t("Items")})` : ""}</span>
                                        <span>
                                            {checkid.length > 0 && checkid.length !== cartdata.length ?

                                                toFormatPrice(
                                                    checkid.reduce((accumulator, product) => {
                                                        return accumulator + (product.price * (product.qty));
                                                    }, 0),
                                                    { addSymbol: true }
                                                )

                                                :
                                                toFormatPrice(
                                                    cartdata.reduce((accumulator, product) => {
                                                        // Check if the product has the 'diamond' key
                                                        if (product.diamond) {
                                                            return accumulator + (parseFloat(product?.UnFormatPrice) + parseFloat(product?.diamond?.UnFormatPrice))
                                                        } else {
                                                            // Otherwise, multiply price by qty and add to accumulator
                                                            return accumulator + (product.UnFormatPrice * (product.qty));
                                                        }
                                                    }, 0),
                                                    { addSymbol: true }
                                                )
                                            }
                                        </span>
                                    </div>
                                    <div className='shopping_cart_page-inner-cartTableBox-right-content-two'>
                                        <span>{t("Total")}</span>
                                        <span>
                                            {checkid.length > 0 && checkid.length !== cartdata.length ?

                                                toFormatPrice(
                                                    checkid.reduce((accumulator, product) => {
                                                        return accumulator + (product.price * (product.qty));
                                                    }, 0),
                                                    { addSymbol: true }
                                                )

                                                :
                                                toFormatPrice(
                                                    cartdata.reduce((accumulator, product) => {
                                                        // Check if the product has the 'diamond' key
                                                        if (product.diamond) {
                                                            // If 'diamond' key exists, add the price directly
                                                            return accumulator + (parseFloat(product?.UnFormatPrice) + parseFloat(product?.diamond?.UnFormatPrice))
                                                        } else {
                                                            // Otherwise, multiply price by qty and add to accumulator
                                                            return accumulator + (product.UnFormatPrice * (product.qty));
                                                        }
                                                    }, 0),
                                                    { addSymbol: true }
                                                )
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className='shopping_cart_page-inner-cartTableBox-right-subdata'>{t("Tax included and shipping calculated at checkout")}</div>
                                <button className={`shopping_cart_page-inner-cartTableBox-right-button ${checkid?.length > 0 ? '' : 'disabled'}`} onClick={checkid?.length > 0 ? () => negvigatecheckout("checkout", checkid) : null}>{t("Checkout")}</button>
                            </div> */}
                             
                       
                        </div>
                        <div className='shopping_cart_page-cartFooterbox'>
                            <div className='shopping_cart_page-cartFooterbox-subTotalBox'>
                                <p className=''>{t("SubTotal")}</p>
                                <p> 
                                {checkid.length > 0 && checkid.length !== cartdata.length ?

                                    toFormatPrice(
                                        checkid.reduce((accumulator, product) => {
                                            return accumulator + (product.price * (product.qty));
                                        }, 0),
                                        { addSymbol: true }
                                    )

                                    :
                                    toFormatPrice(
                                        cartdata.reduce((accumulator, product) => {
                                            // Check if the product has the 'diamond' key
                                            if (product.diamond) {
                                                return accumulator + (parseFloat(product?.UnFormatPrice) + parseFloat(product?.diamond?.UnFormatPrice))
                                            } else {
                                                // Otherwise, multiply price by qty and add to accumulator
                                                return accumulator + (product.UnFormatPrice * (product.qty));
                                            }
                                        }, 0),
                                        { addSymbol: true }
                                    )
                                    }
                                </p>
                            </div>
                            <div className='shopping_cart_page-cartFooterbox-buttonWrapper'>
                                <button className={`btnbold ${checkid.length ? "" : 'disablebtn'}`} disabled={checkid.length ? false : true} onClick={() => negvigatecheckout("checkout", checkid)}>{t("Checkout")}</button>
                                <button className={`btnbold ${checkid.length ? "" : 'disablebtn'}`} onClick={() => handleBookAppointment()}>{t("Appointment")}</button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='shopping_cart_page-inner-nodata'>
                            <div className="shopping_cart_page-inner-nodata-block">
                                <img className='shopping_cart_page-inner-nodata-block-image' src={EmptyCartImage} alt='cart'/>
                                <span className='shopping_cart_page-inner-nodata-block-title'>{t("Your Cart is Empty")}</span>
                                <span className='shopping_cart_page-inner-nodata-block-subtitle'>{t("You have no product in your shopping cart yet")}</span>
                                <Link className='shopping_cart_page-inner-nodata-block-button' to="/Collection">{t("Continue to Shopping")}</Link>
                            </div>
                        </div>
                    </>
                )}
                </>
        )}

    </div>
    </div>
    <Deletedeletepopup 
        isVisible={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message="Remove Items"
    />
    <BottomBar/>
</div>
  )
}
