import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
const SessionModal = () => {
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#sessionModal"
      >
        {t("Launch demo modal")}
      </button>
      <div
        className="modal fade session-modal"
        id="sessionModal"
        data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center pt-4 pb-3">
              <div className='emoji'>
                <span></span>
                <span></span>
                <span></span>

              </div>
              <i className="fa fa-frown-o session-modal-icon" aria-hidden="true"></i>
              <div className='session-modal-title fw-semibold fs-4'>{t("Your session has expired")}</div>
              <Link to="" className='session-modal-back text-decoration-none com-btn py-1'>{t("Go back")}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SessionModal
