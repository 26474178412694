import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { _Api } from "../api/_call";
import { formatDate } from "../../common/helpers/formatDate";
import { useSelector } from 'react-redux';
import { error } from '../../common/helpers/toastify';
import { useTranslation } from 'react-i18next';
import TableLoader from '../../../admin/common/TableLoader';
export const DaimondSearchModal = (props) => {
  const dataFetched = useRef();
  const { t } = useTranslation();
  const { state } = useLocation()
  const customer = useSelector((state) => state.app.customer)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [daimondfilter, setDaimondfilters] = useState();
  const [cut, setCut] = useState([]);
  const [clarity, setClarity] = useState([]);
  const [stonecolor, setStonecolors] = useState([]);
  const [polish, setPolish] = useState([]);
  const [symmetry, setSymmetry] = useState([]);
  const [fluorescence, setFluorescence] = useState([]);
  const [lbs, setLbs] = useState([]);
  const [Status, setStatus] = useState([]);
  const [shape, setShape] = useState([]);
  const [comparisiondata, setComparisiondata] = useState('');
  const [comparisionLoader, setComparisionLoader] = useState(true);
  const [items, setItems] = useState([]);
  const [totalresult, setTotalresult] = useState();
  const [rowlength, setRowlength] = useState(0);
  const [val1, setVal1] = useState([47, 900]);
  const [val2, setVal2] = useState([0.10, 4.00]);
  const [val3, setVal3] = useState([45, 86]);
  const [val4, setVal4] = useState([45, 86]);
  const [showLoader, setShowloader] = useState(false);
  let limit = 20;
  const callfilters = (event, label) => {
    if (label === 'cut') {
      let elem = document.getElementById("cut" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setCut((current) =>
          current.filter((item) => item !== event.target.id)
        );
      } else {
        elem.classList.add("active");
        const cutdata = [...cut];
        cutdata.push(event.target.id);
        setCut(cutdata);
      }
    }
    if (label === 'clarity') {
      let elem = document.getElementById("clarity" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setClarity((current) =>
          current.filter((item) => item !== event.target.id)
        );
      } else {
        elem.classList.add("active");
        const claritydata = [...clarity];
        claritydata.push(event.target.id);
        setClarity(claritydata);
      }

    }
    if (label === 'stonecolor') {
      let elem = document.getElementById("stonecolor" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setStonecolors((current) =>
          current.filter((item) => item !== event.target.id)
        );
      } else {
        elem.classList.add("active");
        const stonecolordata = [...stonecolor];
        stonecolordata.push(event.target.id);
        setStonecolors(stonecolordata);
      }
    }

    if (label === 'polish') {
      let elem = document.getElementById("polish" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setPolish((current) =>
          current.filter((item) => item !== event.target.id)
        );
      } else {
        elem.classList.add("active");
        const polishdata = [...polish];
        polishdata.push(event.target.id);
        setPolish(polishdata);
      }
    }
    if (label === 'symmetry') {
      let elem = document.getElementById("symmetry" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setSymmetry((current) =>
          current.filter((item) => item !== event.target.id)
        );
      } else {
        elem.classList.add("active");
        const symmetrydata = [...symmetry];
        symmetrydata.push(event.target.id);
        setSymmetry(symmetrydata);
      }
    }
    if (label === 'fluorescence') {
      let elem = document.getElementById("fluorescence" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setFluorescence((current) =>
          current.filter((item) => item !== event.target.id)
        );
      } else {
        elem.classList.add("active");
        const fluorescencedata = [...fluorescence];
        fluorescencedata.push(event.target.id);
        setFluorescence(fluorescencedata);
      }
    }
    if (label === 'lbs') {
      let elem = document.getElementById("l" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setLbs((current) =>
          current.filter((item) => item !== event.target.value)
        );
      } else {
        elem.classList.add("active");
        const lbsdata = [...lbs];
        lbsdata.push(event.target.value);
        setLbs(lbsdata);
      }
    }
    if (label === 'status') {
      let elem = document.getElementById("status" + event.target.id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setStatus((current) =>
          current.filter((item) => item !== parseInt(event.target.value))
        );
      } else {
        elem.classList.add("active");
        const statusdata = [...Status];
        statusdata.push(parseInt(event.target.value));
        setStatus(statusdata);
      }
    }
    if (label === 'shape') {
      const id = event.currentTarget.dataset.id;
      const shapedata = [...shape];
      let elem = document.getElementById("shape" + id);
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
        setShape(shapedata.filter(n => n !== id))
      } else {
        elem.classList.add("active");
        shapedata.push(id);
        setShape(shapedata);
      }
    }

  }
  const submitfilters = async () => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    const integerArray = Status.map(Number);
    setTotalresult(0)
    try {
      const posdata = {
        length: 0,
        limit: limit,
        skip: 0,
        "CUT": cut,
        "Clarity": clarity,
        "Stonecolor": stonecolor,
        "Polish": polish,
        "Symmetry": symmetry,
        "fluorescence": fluorescence,
        "Lbs": lbs,
        "Status": integerArray,
        "Shape": shape,
        "Price": [{ "min": val1[0], "max": val1[1] }],
        "Carat": [{ "min": val2[0], "max": val2[1] }],
        "Depth": [{ "min": val3[0], "max": val3[1] }],
        "Table": [{ "min": val4[0], "max": val4[1] }],
        "customer_id": customer?.id,
        "type": "mix_match"
      }
      let res = await _Api(posdata, `api/v1/POS/diamond/getList`);
      if (res.code === 200) {
        setItems(res.data);
        setTotalresult(res.totalResult);
        setRowlength(0)
      }
    } catch (err) {
      console.log(err, 'err');
    }
  }
  const fetchMoreData = () => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    var len = rowlength + limit;
    setRowlength(len);
    const integerArray = Status.map(Number);
    setTimeout(async () => {
      try {
        const posdata = {
          length: len,
          limit: limit,
          skip: len,
          "CUT": cut,
          "Clarity": clarity,
          "Stonecolor": stonecolor,
          "Polish": polish,
          "Symmetry": symmetry,
          "fluorescence": fluorescence,
          "Lbs": lbs,
          "Status": integerArray,
          "Shape": shape,
          "Price": [{ "min": val1[0], "max": val1[1] }],
          "Carat": [{ "min": val2[0], "max": val2[1] }],
          "Depth": [{ "min": val3[0], "max": val3[1] }],
          "Table": [{ "min": val4[0], "max": val4[1] }],
          "customer_id": customer?.id,
          "type": "mix_match"
        }
        let res = await _Api(posdata, `api/v1/POS/diamond/getList`);
        if (res.code === 200) {
          setItems(items.concat(res.data));
        }
      } catch (err) {
        console.log(err, 'err');
      }
    }, 1200);
  };

  const callcomparision = (event) => {
    const data = [...comparisiondata];
    var newState = [...items]
    if (event.target.checked) {
      newState.filter((itemdata) => itemdata._id === event.target.id).forEach((result) => {
        data.push(result);
      })
      setComparisiondata(data);
      setComparisionLoader(false);
    } else {
      setComparisiondata(comparisiondata.filter((v) => v._id !== event.target.id));
      setComparisionLoader(false);
    }
  }

  const deletecomparision = (id) => {
    var checkboxitem = document.querySelector('[data-id="d' + id + '"]');
    if (checkboxitem.checked) {
      checkboxitem.checked = false;
    }
    setComparisiondata(comparisiondata.filter((v) => v._id !== id));
    setComparisionLoader(false)
  }
  const daimondfilters = async (lable) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    setShowloader(true);
    try {
      const posdata = {}
      let res = await _Api(posdata, `api/v1/Inventory/diamond/getFilters`);
      if (res.code === 200) {
        let shapes = [], colors = [], cuts = [], polishs = [], claritys = [], symmetrys = [], fluorescences = [], lbss = [], status = [], Price = [], Carat = [], Depth = [], Table = [];
        setCut([]); setShape([]); setStonecolors([]); setClarity([]); setPolish([]); setSymmetry([]); setFluorescence([]); setLbs([]); setStatus([]);
        setDaimondfilters(res.data);
        if (res.data?.Shape) {
          res.data?.Shape.map((result) => {
            setShape(shape => shape.concat(result?._id));
            shapes.push(result?._id);
            return null;
          })
        }

        if (res.data?.StoneColor) {
          res.data?.StoneColor.map((result) => {
            setStonecolors(stonecolor => stonecolor.concat(result?._id));
            colors.push(result?._id);
            return null;
          })
        }
        if (res.data?.Clarity) {
          res.data?.Clarity.map((result) => {
            setClarity(clarity => clarity.concat(result?._id));
            claritys.push(result?._id);
            return null;
          })
        }
        if (res.data?.Cut) {
          res.data?.Cut.map((result) => {
            setCut(cut => cut.concat(result?._id));
            cuts.push(result?._id);
            return null;
          })
        }
        if (res.data?.Polish) {
          res.data?.Polish.map((result) => {
            setPolish(polish => polish.concat(result?._id));
            polishs.push(result?._id);
            return null;
          })
        }
        if (res.data?.Symmetry) {
          res.data?.Symmetry.map((result) => {
            setSymmetry(symmetry => symmetry.concat(result?._id));
            symmetrys.push(result?._id);
            return null;
          })
        }
        if (res.data?.Fluorescence) {
          res.data?.Fluorescence.map((result) => {
            setFluorescence(fluorescence => fluorescence.concat(result?._id));
            fluorescences.push(result?._id);
            return null;
          })
        }
        if (res.data?.Lbs) {
          res.data?.Lbs.map((result) => {
            setLbs(lbs => lbs.concat(result));
            lbss.push(result);
            return null;
          })
        }
        if (res.data?.Status) {
          res.data?.Status.map((result) => {
            setStatus(status => status.concat(result));
            status.push(result);
            return null;
          })
        }
        setVal1([res.data?.Price?.min, res.data?.Price?.max]);
        setVal2([res.data?.Carat?.min, res.data?.Carat?.max]);
        setVal3([res.data?.Depth?.min, res.data?.Depth?.max]);
        setVal4([res.data?.Table?.min, res.data?.Table?.max]);
        Price.push({ "min": res.data?.Price?.min, "max": res.data?.Price?.max });
        Carat.push({ "min": res.data?.Carat?.min, "max": res.data?.Carat?.max });
        Depth.push({ "min": res.data?.Depth?.min, "max": res.data?.Depth?.max });
        Table.push({ "min": res.data?.Table?.min, "max": res.data?.Table?.max });
        if (lable === 'reset') {
          const reshape = document.querySelectorAll('.resetshape');
          for (const x of reshape) {
            x.classList.add('active');
          }
          const recolor = document.querySelectorAll('.resetcolor');
          for (const x of recolor) {
            x.classList.add('active');
          }
          const reclarity = document.querySelectorAll('.resetclarity');
          for (const x of reclarity) {
            x.classList.add('active');
          }
          const recut = document.querySelectorAll('.resetcut');
          for (const x of recut) {
            x.classList.add('active');
          }
          const repolish = document.querySelectorAll('.resetpolish');
          for (const x of repolish) {
            x.classList.add('active');
          }
          const resymmetry = document.querySelectorAll('.resetsymmetry');
          for (const x of resymmetry) {
            x.classList.add('active');
          }
          const refluorescence = document.querySelectorAll('.resetfluorescence');
          for (const x of refluorescence) {
            x.classList.add('active');
          }
          const relab = document.querySelectorAll('.resetlab');
          for (const x of relab) {
            x.classList.add('active');
          }
          const restatus = document.querySelectorAll('.resetstatus');
          for (const x of restatus) {
            x.classList.add('active');
          }
          setRowlength(0);
        }
        const integerArray = status.map(Number);
        setTimeout(async () => {
          try {
            const posdata = {
              length: 0,
              limit: limit,
              skip: 0,
              "CUT": cuts,
              "Clarity": claritys,
              "Stonecolor": colors,
              "Polish": polishs,
              "Symmetry": symmetrys,
              "fluorescence": fluorescences,
              "Lbs": lbss,
              "Status": integerArray,
              "Shape": shapes,
              "Price": Price,
              "Carat": Carat,
              "Depth": Depth,
              "Table": Table,
              "customer_id": customer?.id,
              "type": "mix_match"
            }
            let res = await _Api(posdata, `api/v1/POS/diamond/getList`);
            if (res.code === 200) {
              setShowloader(false);
              setItems(res.data);
              setTotalresult(res.totalResult);
              dataFetched.current = false
            }
          } catch (err) {
            console.log(err, 'err');
          }
        }, 1000);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  }

  useEffect(() => {
    if (customer?.id) {
      dataFetched.current = false
    }
    if (dataFetched.current) return;
    dataFetched.current = true
    daimondfilters();
    // eslint-disable-next-line 
  }, [customer]);
  return (
    <div className="diamond-search-wrap">
      <div className="diamond-search-wrap-width">
        <div
          className="accordion diamond-search-accordian"
          id="accordionDiamond"
        >
          <div className="diamond-search-accordian-tab">
            <button
              className="diamond-search-wrap-row diamond-search-accordian-tab-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#diamondSearchTab"
              aria-expanded="false"
              aria-controls="diamondSearchTab"
            >
              <span>
                {" "}
                <img src={base_assets + "images/icons/down-arrow.png"} alt="" />
                {t("Diamond Search")}
              </span>
            </button>
            <button
              onClick={() => {
                daimondfilters("reset");
              }}
              className="diamond-search-accordian-tab-reset"
            >
              <img src={base_assets + "images/icons/reset.png"} alt="" />
              <span>{t("reset filter")}</span>
            </button>
          </div>
          <div
            id="diamondSearchTab"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionDiamond"
          >
            <div className="diamond-search-wrap-row">
              <div className="diamond-search-wrap-row-col col">
                <h6 className="diamond-search-wrap-row-col-heading">{t("Shape")}</h6>
                <div className="diamond-search-wrap-row-col-diamond-row">
                  {daimondfilter?.Shape
                    ? daimondfilter?.Shape.map((result, key) => {
                      return (
                        <div
                          key={key}
                          id={"shape" + result?._id}
                          data-id={result?._id}
                          onClick={(e) => callfilters(e, "shape")}
                          className="diamond-search-wrap-row-col-diamond-row-single active resetshape"
                        >
                          <div className="diamond-search-wrap-row-col-diamond-row-single-img">
                            <img src={result?.logo} alt="" />
                          </div>
                          <p className="diamond-search-wrap-row-col-diamond-row-single-title">
                            {result?.name}
                          </p>
                        </div>
                      );
                    })
                    : ""}
                </div>
              </div>
              <div className="diamond-search-wrap-row-col col range">
                <div className="diamond-search-wrap-row-col-ct">
                  <h6 className="diamond-search-wrap-row-col-heading">{t("price")}</h6>
                  <div className="range-area">
                    <Slider
                      range
                      min={daimondfilter?.Price?.min}
                      max={daimondfilter?.Price?.max}
                      value={val1}
                      onChange={setVal1}
                      step={0.1}
                    />
                    <div className="range-area-num">
                      <div className="range-area-num-single">
                        <span className='range-area-num-value'>{daimondfilter?.currency}   </span>
                        <span className="range-area-num-unit">{val1[0]}</span>
                      </div>
                      <div className="range-area-num-single">
                        <span className='range-area-num-value'>{daimondfilter?.currency}   </span>
                        <span className="range-area-num-unit">{val1[1]} </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="diamond-search-wrap-row-col-ct">
                  <h6 className="diamond-search-wrap-row-col-heading">{t("Carat")}</h6>
                  <div className="range-area">
                    <Slider
                      range
                      min={daimondfilter?.Carat?.min}
                      max={daimondfilter?.Carat?.max}
                      value={val2}
                      onChange={setVal2}
                      step={0.1}
                    />
                    <div className="range-area-num">
                      <div className="range-area-num-single">
                        <span className="range-area-num-value">
                          {" "}
                          {val2[0]}{" "}
                        </span>
                        <span className="range-area-num-unit">{t("Ct")}</span>
                      </div>
                      <div className="range-area-num-single">
                        <span className="range-area-num-value">
                          {" "}
                          {val2[1]}{" "}
                        </span>
                        <span className="range-area-num-unit">{t("Ct")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="diamond-search-wrap-row-col col">
                <div className="diamond-search-wrap-row-col-ct">
                  <h6 className="diamond-search-wrap-row-col-heading">
                    {t("Colour")}
                  </h6>
                  <div className="diamond-tab-row row m-0">
                    {daimondfilter?.StoneColor
                      ? daimondfilter?.StoneColor.map((result, key) => {
                        return (
                          <React.Fragment key={key}>
                            <input
                              key={key}
                              id={result?._id}
                              value={result?.code}
                              type="checkbox"
                              onClick={(e) => callfilters(e, "stonecolor")}
                            />
                            <label
                              htmlFor={result?._id}
                              id={"stonecolor" + result?._id}
                              className="diamond-tab-row-tab active resetcolor"
                            >
                              {result?.code}
                            </label>
                          </React.Fragment>
                        );
                      })
                      : ""}
                  </div>
                </div>

                <div className="diamond-search-wrap-row-col-ct">
                  <h6 className="diamond-search-wrap-row-col-heading">
                    {t("clarity")}
                  </h6>
                  <div className="diamond-tab-row row m-0">
                    {daimondfilter?.Clarity
                      ? daimondfilter?.Clarity.map((result, key) => {
                        return (
                          <React.Fragment key={key}>
                            <input
                              key={key}
                              id={result?._id}
                              value={result?.code}
                              type="checkbox"
                              onClick={(e) => callfilters(e, "clarity")}
                            />
                            <label
                              htmlFor={result?._id}
                              id={"clarity" + result?._id}
                              className="diamond-tab-row-tab  active resetclarity"
                            >
                              {result?.code}
                            </label>
                          </React.Fragment>
                        );
                      })
                      : ""}
                  </div>
                </div>
                <div className="diamond-search-wrap-row-col-ct">
                  <h6 className="diamond-search-wrap-row-col-heading">{t("cut")}</h6>
                  <div className="diamond-tab-row row m-0">
                    {daimondfilter?.Cut
                      ? daimondfilter?.Cut.map((result, key) => {
                        return (
                          <React.Fragment key={key}>
                            <input
                              key={key}
                              id={result?._id}
                              value={result?.code}
                              type="checkbox"
                              onClick={(e) => callfilters(e, "cut")}
                            />
                            <label
                              htmlFor={result?._id}
                              id={"cut" + result?._id}
                              className="diamond-tab-row-tab col active resetcut"
                            >
                              {result?.code}
                            </label>
                          </React.Fragment>
                        );
                      })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="accordion advanced-options-accordian"
              id="accordionAdvanced"
            >
              <div
                id="advancedOptions"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionAdvanced"
              >
                <div className="diamond-search-wrap-row">
                  <div className="diamond-search-wrap-row-col col">
                    <div className="diamond-search-wrap-row-col-ct">
                      <h6 className="diamond-search-wrap-row-col-heading">
                        {t("Polish")}
                      </h6>
                      <div className="diamond-tab-row row m-0">
                        {daimondfilter?.Polish
                          ? daimondfilter?.Polish.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <input key={key} id={result?._id} value={result?.code} type="checkbox" onClick={(e) => callfilters(e, "polish")} />
                                <label htmlFor={result?._id} id={"polish" + result?._id} className="diamond-tab-row-tab symmetry-btn w-25 active resetpolish">{result?.code}</label>
                              </React.Fragment>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                    <div className="diamond-search-wrap-row-col-ct">
                      <h6 className="diamond-search-wrap-row-col-heading">
                        {t("depth")}
                      </h6>
                      <div className="range-area">
                        <Slider
                          range
                          min={daimondfilter?.Depth?.min}
                          max={daimondfilter?.Depth?.max}
                          value={val3}
                          onChange={setVal3}
                        />
                        <div className="range-area-num">
                          <div className="range-area-num-single">
                            <span className="range-area-num-value">
                              {" "}
                              {val3[0]}{" "}
                            </span>
                            <span className="range-area-num-unit">%</span>
                          </div>
                          <div className="range-area-num-single">
                            <span className="range-area-num-value">
                              {" "}
                              {val3[1]}{" "}
                            </span>
                            <span className="range-area-num-unit">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="diamond-search-wrap-row-col col">
                    <div className="diamond-search-wrap-row-col-ct">
                      <h6 className="diamond-search-wrap-row-col-heading">
                        {t("Symmetry")}
                      </h6>
                      <div className="diamond-tab-row row m-0">
                        {daimondfilter?.Symmetry
                          ? daimondfilter?.Symmetry.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <input key={key} id={result?._id} value={result?.code} type="checkbox" onClick={(e) => callfilters(e, "symmetry")} />
                                <label htmlFor={result?._id} id={"symmetry" + result?._id} className="diamond-tab-row-tab symmetry-btn active resetsymmetry">{result?.code}</label>
                              </React.Fragment>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                    <div className="diamond-search-wrap-row-col-ct">
                      <h6 className="diamond-search-wrap-row-col-heading">
                        {t("Table")}
                      </h6>
                      <div className="range-area">
                        <Slider
                          range
                          min={daimondfilter?.Table?.min}
                          max={daimondfilter?.Table?.max}
                          value={val4}
                          onChange={setVal4}
                        />
                        <div className="range-area-num">
                          <div className="range-area-num-single">
                            <span className="range-area-num-value">
                              {" "}
                              {val4[0]}{" "}
                            </span>
                            <span className="range-area-num-unit">%</span>
                          </div>
                          <div className="range-area-num-single">
                            <span className="range-area-num-value">
                              {" "}
                              {val4[1]}{" "}
                            </span>
                            <span className="range-area-num-unit">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="diamond-search-wrap-row-col col">
                    <div className="diamond-search-wrap-row-col-ct">
                      <h6 className="diamond-search-wrap-row-col-heading">
                        {t("Fluorescence")}
                      </h6>
                      <div className="diamond-tab-row row m-0">
                        {daimondfilter?.Fluorescence
                          ? daimondfilter?.Fluorescence.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <input key={key} id={result?._id} value={result?.code} type="checkbox" onClick={(e) => callfilters(e, "fluorescence")} />
                                <label htmlFor={result?._id} id={"fluorescence" + result?._id} className="diamond-tab-row-tab fluorescence-btn active resetfluorescence">{result?.code}</label>
                              </React.Fragment>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                    <div className="diamond-search-wrap-row-col-ct">
                      <h6 className="diamond-search-wrap-row-col-heading">
                        {t("Lab")}
                      </h6>
                      <div className="diamond-tab-row row m-0">
                        {daimondfilter?.Lbs
                          ? daimondfilter?.Lbs.map((result, key) => {
                            return (
                              <React.Fragment key={key}>
                                <input key={key} id={"Lbs" + key} value={result} type="checkbox" onClick={(e) => callfilters(e, "lbs")} />
                                <label htmlFor={"Lbs" + key} id={"lLbs" + key} className="diamond-tab-row-tab text-uppercase lab-btn active resetlab">{result}</label>
                              </React.Fragment>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                    <div className='diamond-search-wrap-row-col-ct'>
                      <h6 className="diamond-search-wrap-row-col-heading">{t("Status")}</h6>
                      <div className="diamond-tab-row row m-0">
                        {daimondfilter?.Status ? daimondfilter?.Status.sort((a, b) => a - b).map((result, key) => {
                          return (
                            <React.Fragment key={key}>
                              <input key={key} id={result} value={result} type="checkbox" onClick={(e) => callfilters(e, "status")} />

                              <label htmlFor={result} id={"status" + result} className="diamond-tab-row-tab status-btn active resetstatus">
                                {result === "1" ?
                                  "Stock" : result === "2" ?
                                    "Reserve" : result === "3" ?
                                      "Custom Order" : result === "4" ?
                                        "Transits" : ''
                                }</label>
                            </React.Fragment>
                          );
                        }) : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="advanced-options-accordian-button-wrap">
                <div className="row justify-content-center m-0">
                  <div className="col-md-3">
                    <button className=" w-100 com-btn mt-2 mb-2 submit-filter-btn" onClick={submitfilters}>{t("Submit Filter")}</button>
                  </div>
                </div>
                <button
                  className="diamond-search-wrap-row 
                            advanced-options-accordian-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#advancedOptions"
                  aria-expanded="true"
                  aria-controls="advancedOptions"
                >
                  <span className="advanced-text">
                    {t("Advanced Options")}{" "}
                    <img src={base_assets + "images/icons/add-icon.png"} alt="" />
                  </span>
                  <span className="less-text">
                    {t("Less Options")}{" "}
                    <img
                      src={base_assets + "images/icons/minus-icon.png"}
                      alt=""
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="diamond-search-wrap-nav">
          <div
            className="diamond-search-wrap-nav-tab"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              {t("results")}{" "}
              <span className="total-row">
                ({totalresult ? totalresult : ""})
              </span>
            </button>
            <button
              className="nav-link"
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              {t("Comparision")}{" "}
              <span className="total-row">
                ({comparisiondata ? comparisiondata?.length : "0"})
              </span>
            </button>
          </div>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap">
                  <table
                    id="tbl_createTransfer1"
                    className="common-table first-row-gray w-100"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>#</th>
                        <th>{t("Shape")}</th>
                        <th>{t("Carat")}</th>
                        <th>{t("Colour")}</th>
                        <th>{t("Clarity")}</th>
                        <th>{t("Cut")}</th>
                        <th>{t("Polish")}</th>
                        <th>{t("Symmetry")}</th>
                        <th>{t("Certificate")}</th>
                        <th>{t("Price")}</th>
                        <th>{t("Location")}</th>
                        <th className="text-center">{t("Detail")}</th>
                        <th className="text-center">{t("Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {items.length
                          ? items.map((result, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <div className={result?.Status === 2 ? "com-check checkbox-disabled" : "com-check"}>
                                    <input id={result?._id} data-id={"d" + result?._id} onClick={callcomparision} type="checkbox" />
                                    <label htmlFor={result?._id} className="com-check-label" />
                                  </div>
                                </td>
                                <td>{key + 1}</td>
                                <td className="diamond-pro-img">
                                  <img
                                    src={
                                      result?.Shape
                                        ? result?.Shape
                                        : base_assets +
                                        "images/icon/camera_profile.png"
                                    }
                                    alt=""
                                  />
                                </td>
                                <td>

                                  <Link
                                    className="link-color text-decoration-none"
                                    state={{
                                      daimondid: result?._id,
                                      items: state ? state?.items : '',
                                      itemchanges: state?.itemchanges,
                                      product_id: state?.product_id,
                                      bychangeorder: state?.bychangeorder,
                                      optiondetailid: state?.optiondetailid,
                                      Engraving: state?.Engraving,
                                      customcart: state?.customcart,
                                      order_edit: state?.order_edit,
                                      order_id: state?.order_id,
                                      order_type: state?.order_type,
                                      mixmatchedit: state?.mixmatchedit,
                                      editproduct: state?.editproduct,
                                      scrvicelist: state?.scrvicelist,
                                      scrviceremark: state?.scrviceremark,
                                      remark: state?.remark
                                    }}
                                    to="/app/pos/custom/DiamondDetail"
                                  >
                                    {result?.Carat ? result?.Carat : ""}
                                  </Link>
                                </td>
                                <td>
                                  {result?.Colour ? result?.Colour : ""}
                                </td>
                                <td>
                                  {result?.Clarity ? result?.Clarity : ""}
                                </td>
                                <td>{result?.Cut ? result?.Cut : ""}</td>
                                <td>
                                  {result?.Polish ? result?.Polish : ""}
                                </td>
                                <td>
                                  {result?.Symmetry ? result?.Symmetry : ""}
                                </td>
                                <td>
                                  <Link
                                    className="link-color text-decoration-none"
                                    target="_blank"
                                    href={
                                      result?.CertificationUrl
                                        ? result?.CertificationUrl
                                        : ""
                                    }
                                  >
                                    {result?.Certification
                                      ? result?.Certification
                                      : ""}
                                  </Link>
                                </td>
                                <td>
                                  {result?.Price ? result?.Price : ""}
                                </td>
                                <td>
                                  {result?.Location ? result?.Location : ""}
                                </td>
                                <td className="text-center eye-img">
                                  <Link
                                    state={{
                                      daimondid: result?._id,
                                      items: state ? state?.items : '',
                                      itemchanges: state?.itemchanges,
                                      product_id: state?.product_id,
                                      bychangeorder: state?.bychangeorder,
                                      optiondetailid: state?.optiondetailid,
                                      Engraving: state?.Engraving,
                                      customcart: state?.customcart,
                                      order_edit: state?.order_edit,
                                      order_id: state?.order_id,
                                      order_type: state?.order_type,
                                      mixmatchedit: state?.mixmatchedit,
                                      editproduct: state?.editproduct,
                                      scrvicelist: state?.scrvicelist,
                                      scrviceremark: state?.scrviceremark,
                                      remark: state?.remark
                                    }}
                                    to="/app/pos/custom/DiamondDetail"
                                  >
                                    <img
                                      src={
                                        base_assets +
                                        "images/icons/green-eye.png"
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </td>

                                <td className='text-center'>
                                  <div className={result?.Status === 1 ? "status-yellow light-blue tooltip-area-up" : result?.Status === 2 ? "status-yellow light-yellow-bg tooltip-area-up" : result?.Status === 3 ? "status-yellow bg-danger tooltip-area-up" : "status-color bg-main-green tooltip-area-up"}>
                                    {
                                      result?.reserveDate ?
                                        <span className='tooltip-area-up-text'> {result?.customer}<br /> {formatDate(result?.reserveDate)} </span> :
                                        result?.Status === 1 ? <span className='tooltip-area-up-text'>{t("Stock")}</span> : result?.Status === 3 ?
                                          <span className='tooltip-area-up-text'>{t("Custom Order")}</span> : result?.Status === 4 ? <span className='tooltip-area-up-text'>{t("Transit")} </span> : ''
                                    }
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                          :
                          <tr>

                            <td colSpan="50" className="text-center">{showLoader ? <TableLoader /> : t("No Data Available In Table")}</td>
                          </tr>
                        }

                      </>
                    </tbody>
                  </table>
                  {totalresult > limit && totalresult > rowlength ?
                    <div className="show-more" >
                      <span className='show-more-displaying'>{t("Displaying")} {items?.length}  {t("of")} {totalresult} {t("items")}</span>
                      <button onClick={fetchMoreData} className='btn show-more-btn'>
                        {t("Show more")} <img src={base_assets + "images/icons/add-icon.png"} alt="" />
                      </button>
                    </div> : ""}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap">
                  <table
                    id="tbl_createTransfer2"
                    className="common-table first-row-gray w-100"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>#</th>
                        <th>{t("Shape")}</th>
                        <th>{t("Carat")}</th>
                        <th>{t("Colour")}</th>
                        <th>{t("Clarity")}</th>
                        <th>{t("Cut")}</th>
                        <th>{t("Polish")}</th>
                        <th>{t("Symmetry")}</th>
                        <th>{t("Certificate")}</th>
                        <th>{t("Price")}</th>
                        <th>{t("Location")}</th>
                        <th className="text-center">{t("Detail")}</th>
                        <th className="text-center">{t("Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {comparisiondata.length
                          ? comparisiondata.map((result, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <button
                                    className="delete-row-btn"
                                    onClick={() =>
                                      deletecomparision(result?._id)
                                    }
                                  >
                                    <img
                                      src={
                                        base_assets +
                                        "images/icons/delete-icon.png"
                                      }
                                      alt=""
                                    />
                                  </button>
                                </td>
                                <td>{key + 1}</td>
                                <td className="diamond-pro-img">
                                  <img
                                    src={
                                      result?.Shape
                                        ? result?.Shape
                                        : base_assets +
                                        "images/icon/camera_profile.png"
                                    }
                                    alt=""
                                  />
                                </td>
                                <td>
                                  <Link className="link-color text-decoration-none" href="">
                                    {result?.Carat ? result?.Carat : ""}
                                  </Link>
                                </td>
                                <td>{result?.Colour ? result?.Colour : ""}</td>
                                <td>
                                  {result?.Clarity ? result?.Clarity : ""}
                                </td>
                                <td>{result?.Cut ? result?.Cut : ""}</td>
                                <td>{result?.Polish ? result?.Polish : ""}</td>
                                <td>
                                  {result?.Symmetry ? result?.Symmetry : ""}
                                </td>
                                <td>
                                  <Link className="link-color text-decoration-none"
                                    target="_blank"
                                    href={
                                      result?.CertificationUrl
                                        ? result?.CertificationUrl
                                        : ""
                                    }
                                  >
                                    {result?.Certification
                                      ? result?.Certification
                                      : ""}
                                  </Link>
                                </td>
                                <td> {result?.Price ? result?.Price : ""}</td>
                                <td>
                                  {result?.Location ? result?.Location : ""}
                                </td>
                                <td className="text-center eye-img">
                                  <Link
                                    state={{
                                      daimondid: result?._id,
                                      items: state ? state?.items : '',
                                      itemchanges: state?.itemchanges,
                                      product_id: state?.product_id,
                                      bychangeorder: state?.bychangeorder,
                                      optiondetailid: state?.optiondetailid,
                                      Engraving: state?.Engraving,
                                      editproduct: state?.editproduct,
                                      customcart: state?.customcart,
                                      scrvicelist: state?.scrvicelist,
                                      scrviceremark: state?.scrviceremark,
                                      remark: state?.remark
                                    }}
                                    to="/app/pos/custom/DiamondDetail"
                                  >
                                    <img
                                      src={
                                        base_assets +
                                        "images/icons/green-eye.png"
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </td>

                                <td className='text-center'>
                                  <div className={result?.Status === 1 ? "status-yellow light-blue tooltip-area-up" : result?.Status === 2 ? "status-yellow light-yellow-bg tooltip-area-up" : result?.Status === 3 ? "status-yellow bg-danger tooltip-area-up" : "status-color bg-main-green tooltip-area-up"}>
                                    {
                                      result?.reserveDate ?
                                        <span className='tooltip-area-up-text'> {result?.customer} <br /> {formatDate(result?.reserveDate)} </span> :
                                        result?.Status === 1 ? <span className='tooltip-area-up-text'>{t("Stock")}</span> : result?.Status === 3 ?
                                          <span className='tooltip-area-up-text'>{t("Custom Order")}</span> : result?.Status === 4 ? <span className='tooltip-area-up-text'>{t("Transit")} </span> : ''
                                    }
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                          :
                          <tr>

                            <td colSpan="50" className="text-center">{comparisionLoader ? <TableLoader /> : t("No Data Available In Table")}</td>
                          </tr>
                        }

                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
