import React, { useEffect, useState, useRef } from 'react'
import { _Api, graphqlPost } from '../api/_call'
import { useTranslation } from 'react-i18next'
import { store } from "../../../source/index";
const Servicelabourmodal = (props) => {
  const { t } = useTranslation()
  const { setService_enable,
    setScrviceremark,
    setScrvicelist,
    setScrvicecharge,
    scrvicelist,
    scrviceremark,
    cart_id,
    service_saved,
    setService_saved,
    GetCartdetail,
    order_edit,
    order_type,
    order_id,
    product_price,
    setServicelabourlist,
    servicelabourlist,
    setShowmodal,
    edit,
    showmodal } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const dropdownRef = useRef(null);
  const [servicelabour, setServicelabour] = useState([])
  var state = store.getState();
  var OrgSettings = state?.app?.posorganisation_settings;
  var currency = OrgSettings?.symbol;
  const [selecteditem, setSelecteditem] = useState([]);
  const getSalesList = async () => {
    try {
      const postdata = `{
            ServiceLabour{
                   name
                   code
                   id
               }
    }`;

      let responce = await graphqlPost(postdata, "App/graphql");
      let ServiceLabour = [];
      if (responce.data?.ServiceLabour) {
        responce.data?.ServiceLabour?.forEach((result) => {
          ServiceLabour.push(
            {
              id: result?.id,
              code: result?.code,
              name: result?.name
            }
          );
        })
      }
      setServicelabour(ServiceLabour);
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getSalesList();
  }, []);
  useEffect(() => {
    if (scrvicelist?.length) {
      setService_saved(true)
    } else {
      setService_saved(false)
    }
    // eslint-disable-next-line
  }, [scrvicelist?.length]);
  const scrvicelabure = async () => {
    try {
      const postdata = {
        "cart_id": cart_id,
        service_saved: service_saved,
        "service_labour": {
          "service_remark": scrviceremark,
          "service_laburelist": scrvicelist,
          "product_price": product_price
        },

      }

      let res = await _Api(postdata, `api/v1/POS/customOrder/saveServiceLabour`)
      if (res.code === 200) {
        GetCartdetail(order_edit, order_type, order_id)

      } else {

      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const selectedproduct = (event, id, code, name, index) => {

    if (event.target.checked) {
      let arrya = [];
      let list = []
      if (scrvicelist?.length) {
        let data = [...scrvicelist]
        let select = [...selecteditem]
        if (data[index]?.id !== id) {
          data.push({ id: id, name: name, code: code, scrviceamount: 0 })
          select.push(id);
        }
        setServicelabourlist(data);
        setScrvicelist(data)
        setSelecteditem(select)
        setShowmodal(true)
      } else {
        list.push({ id: id, name: name, code: code, scrviceamount: 0 })
        arrya.push(id)
        setServicelabourlist(list);
        setScrvicelist(list)
        setSelecteditem(arrya)
        setShowmodal(true)
      }
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.id;
      });
      const resultlist = servicelabourlist.filter((element) => {
        return element?.id !== event.target.id;
      });
      setSelecteditem(result);
      setServicelabourlist(resultlist);
      setScrvicelist(resultlist)
    }

  };
  const deleteproduct = (id) => {
    setServicelabourlist((current) =>
      current.filter((item) => item?.id !== id)
    );
    setSelecteditem((current) =>
      current.filter((item) => item?.id === id)
    );
    setScrvicelist((current) =>
      current.filter((item) => item?.id !== id)

    );

  }

  const amount = (e, key) => {
    let array = [...scrvicelist]
    array[key] = { ...array[key], scrviceamount: parseInt(e.target.value) }
    setScrvicelist(array)
    setServicelabourlist(array)
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      total = total + array[i].scrviceamount;
    }
    setScrvicecharge(total)
  }
  const [isSwitchActive, setIsSwitchActive] = useState(false);

  const switchActive = event => {
    setIsSwitchActive(current => !current);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSwitchActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div
        className="modal fade common-modal service-modal"
        id="servicelabour"
        tabIndex="-1"
        aria-labelledby="servicelabourLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog service-modal-dialog modal-dialog-centered">
          <div className="modal-content pt-0 px-0 pb-2 overflow-hidden service-modal-content">
            <div className="modal-header service-modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {t('Service Labour')}
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-0 pt-4 service-modal-content-body">
              <div className='switch-select-area text-start'>
                <div className='d-flex'>
                  <label className="service-modal-label pb-2 switch-select-area-label">
                    {t('Service Labour')}
                  </label>

                  {showmodal ? (
                    <span className='ms-auto danger-text cursor-pointer' style={{ fontSize: "0.9vw" }}
                      onClick={() => { setServicelabourlist([]); setScrvicelist([]) }}>{t("Remove All")}</span>
                  ) : ""}
                </div>

                <div className="dropdown common-dropdown service-modal-content-dropdown switch-select-area-dropdown"
                  ref={dropdownRef}>
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownDiamond"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={switchActive}
                  >
                    {t("Service Labour")}
                  </button>
                  <ul
                    className={isSwitchActive ? "dropdown-menu switch-select-area-dropdown-menu active" : "dropdown-menu switch-select-area-dropdown-menu"}
                    aria-labelledby="dropdownDiamond"
                  >
                    {servicelabour?.length ? servicelabour.map((result, key) => {
                      return (
                        <li className="dropdown-item" key={key}>
                          <div className="com-check"
                          >
                            <input
                              checked={servicelabourlist.some(item => item.id === result?.id)}
                              id={result?.id}
                              data-code={result?.code}
                              data-name={result?.name}
                              type="checkbox"
                              className='scrvicecheck'
                              onChange={(e) => selectedproduct(e, result?.id, result?.code, result?.name, key)}
                            />
                            <label
                              htmlFor={result?.id}
                              className="com-check-label"
                            ></label>
                          </div>
                          <label >{result?.code}</label>
                          <label onClick={() => { setShowmodal(true); switchActive() }}>{result?.name}</label>
                        </li>
                      )


                    }) : ""}

                  </ul>
                  {showmodal ? (
                    <ul className="switch-select-area-dropdown-menu switch-select-area-dropdown-menu-2">
                      {servicelabourlist?.length ? servicelabourlist.map((result, key) => {

                        return (
                          <li className="dropdown-item service-modal-content-dropdown-item" key={key}>
                            <div className="" onClick={() => { deleteproduct(result?.id) }}>
                              <img style={{ width: "1.25vw" }}
                                src={base_assets + 'images/pos/icons/red-delete.png'} alt=''
                              />
                            </div>
                            <div>{result?.code}</div>
                            <div className='switch-select-area-dropdown-item-name'>{result?.name}</div>
                            <div className="ms-auto">{currency}
                              <input type="number" className="active-input arrow-none text-end"
                                value={result?.scrviceamount}
                                onChange={(e) => amount(e, key)} />
                            </div>
                          </li>

                        )
                      }) : <li>{t("No Data Available")}</li>}
                    </ul>

                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className='textarea placeholder-up-input text-box form-floating px-0'>
                <textarea
                  className="placeholder-up-input-field"
                  placeholder={t('Remark')}
                  onChange={(e) => servicelabourlist?.length ? setScrviceremark(e.target.value) : ""}
                ></textarea>
                <label className='placeholder-up-input-label'>{t('Remark')}</label>
              </div>

            </div>

            <div className="modal-footer">
              <button type="button" className="com-btn-outline com-btn"
                data-bs-dismiss="modal"
                aria-label="Close">
                {t('cancel')}
              </button>
              {order_edit && order_type && order_id ?
                <button type="button" className="com-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { setService_enable(true); edit(cart_id) }}>
                  {t('Save Edit')}
                </button> :
                <button type="button" className="com-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { setService_enable(true); scrvicelabure() }}>
                  {t('save')}
                </button>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Servicelabourmodal
