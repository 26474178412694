import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import "../common/multi-select/multiSelect.scss";
import { useForm } from "react-hook-form";
import ProductMenu from "./productMenu";
import "./product.scss";
import CopyToAll from "./copy_to_all_model";
import { graphqlPost, _Api } from "../api/_call";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const ProductVariant = (props) => {
  const { t } = useTranslation();
  const { productId_url } = useParams();
  const [selectedButton, setButton] = useState("");
  const [varient_image, setVarient_image] = useState("");
  const [getTimeLoading, setTimeLoading] = useState(true);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  var tr_index = 0;
  var imageBox_index = 0;
  var imageBox_no = 0;
  var img_ind_ = 0;
  const [IsDisable, setIs_disable] = useState(false);
  var selected_size_list = [];
  var selected_stone_list = [];
  var selected_metal_list = [];
  const [productimage, setproduct_image] = useState({ image: "" });

  const {
    handleSubmit,
  } = useForm();
  const status = [
    { value: 1, label: <span className="dot-single"></span> },
    { value: 0, label: <span className="dot-single red"></span> },
  ];
  var setected_opt = {
    generalInfo: "",
    advanced: "",
    variant: "active",
    choice: "",
  };
  const [call, setcall] = useState();
  const [size_load, setSize_load] = useState("");
  const [stone_load, setStone_load] = useState("");
  const [metal_load, setMetal_load] = useState("");
  const [possi_css, setPossi_css] = useState([]);
  const [callviewvarient, setCallviewvarient] = useState({ metal: false, stone: false, size: false })
  const onImageChange = (e, index, sku) => {
    const [file] = e.target.files;
    convertBase64(file, index, sku);
  };
  const convertBase64 = (file, index, sku) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          setproduct_image({ image: fileReader.result, index: index, sku: sku });
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      }

    });
  };
  const [data_size_list, setSize_list] = useState([]);
  const [data_metal_list, setMetal_list] = useState([]);
  const [data_stone_list, setStonesList] = useState([]);
  const [product_value_arr_varient, setproduct_value_arr_varient] = useState([
    { product_id: "", name: "", varient_type: "" },
  ]);

  const [data_currency, setcurrency] = useState([]);
  const [data_varient, setvarient] = useState([]);
  const [data_size, setSize] = useState([]);
  const [data_metal, setMetal] = useState([]);
  const [data_stone, setStone] = useState([]);
  const [stone_selected_ids, setStone_selected_ids] = useState([]);
  const [size_selected_ids, setSize_selected_ids] = useState([]);
  const [metal_selected_ids, setMetal_selected_ids] = useState([]);
  const [data_varient_brack, setVarient_brack] = useState(0);
  const [data_varient_length, setVarient_length] = useState(0);

  const handleSelectSize = (data) => {

    setSize(data);
  };
  const handleSelectMetal = (data) => {
    setMetal(data);
  };
  const handleSelectStone = (data) => {
    setStone(data);
  };


  const [product_value_arr, setproduct_value_arr_val] = useState([{ product_id: "", name: "", varient_type: "" }]);
  const getProductUrl_details = useCallback(async () => {
    if (productId_url && productId_url !== undefined) {
      try {
        const postdata = {
          id: productId_url,
        };
        let resPoOrders = await _Api(postdata, `api/v1/APP/product/getProductType`);
        if (resPoOrders.code === 200) {
          setproduct_value_arr_val(resPoOrders.data);
        } else {
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  }, [productId_url]);
  const getVariantsOptions = useCallback(async () => {
    try {
      const postdata = {
        id: product_value_arr[0].product_id,
      };
      let responce = await _Api(
        postdata,
        `api/v1/APP/product/getVariantsOptions`
      );
      if (responce.code === 200) {
        var responce_data = responce.data;
        if (
          responce_data.variant_Size_value !== undefined &&
          responce_data.variant_Size_value[0] !== undefined
        ) {
          var size_data = responce_data.variant_Size_value[0];
          setChecked_size(size_data.Status);
          setSize_selected_ids(size_data.id);
        }
        if (
          responce_data.variant_Stone_value !== undefined &&
          responce_data.variant_Stone_value[0] !== undefined
        ) {
          var stone_data = responce_data.variant_Stone_value[0];

          setChecked_stone(stone_data.Status);
          setStone_selected_ids(stone_data.id);
        }
        if (
          responce_data.variant_Metal_value !== undefined &&
          responce_data.variant_Metal_value[0] !== undefined
        ) {
          var metal_data = responce_data.variant_Metal_value[0];
          setChecked_metal(metal_data.Status);
          setMetal_selected_ids(metal_data.id);
        }
        setcall(1);
      }
    } catch (err) {
      console.log(err, "err");
    }

    // eslint-disable-next-line 
  }, [product_value_arr]);

  const SizesList = async () => {
    try {
      const postdata = {
        id: product_value_arr[0].product_id,
      };
      let responce = await _Api(postdata, `api/v1/APP/product/getsizes`);
      var size_list = [];
      responce?.data?.forEach((result, index) => {
        size_list.push({
          value: result._id + "_Size",
          text: result.name,
          code: "-" + result.code,
          label: result.name,
          id: result._id,
        });
      });



      size_selected_ids?.map((sizelSelectedID) => {
        var getSizeIndex = size_list.findIndex(obj => obj.id === sizelSelectedID);
        if (getSizeIndex !== -1) {
          var get_Size = size_list[getSizeIndex];
          var id = get_Size.id + "_Size";
          selected_size_list.push({
            value: id,
            label: get_Size.label,
            code: get_Size?.code,
            text: get_Size?.text,
            id: get_Size.id,
            isFixed: true,
          });
        }
        return false;
      });
      setSize_list(size_list);
      setSize(selected_size_list);
      setSize_load(selected_size_list);
      setCallviewvarient((state) => ({
        ...state,
        size: true
      }))
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line
  };

  const metalList = useCallback(async () => {
    try {
      const postdata = `{
                metals {
                    id
                    name 
                    code
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      var metal_list = [];
      responce?.data?.metals.map((result, index) => {
        var id = result.id + "_Metal";
        metal_list.push({
          value: id,
          label: result.name,
          code: result?.code,
          text: result?.name,
          id: result.id,
        });
        return false
      });

      metal_selected_ids.map((metalSelectedID, index) => {
        var getMetalIndex = metal_list.findIndex(obj => obj.id === metalSelectedID);
        if (getMetalIndex !== -1) {
          var metal = metal_list[getMetalIndex];
          var id = metal.id + "_Metal";
          selected_metal_list.push({
            value: id,
            label: metal.label,
            code: metal?.code,
            text: metal?.text,
            id: metal.id,
            isFixed: true,
          });
        }
        return false
      });

      setMetal_list(metal_list);
      setMetal(selected_metal_list);
      setMetal_load(selected_metal_list);
      setCallviewvarient((state) => ({
        ...state,
        metal: true
      }))
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line
  }, [metal_selected_ids]);
  const stoneList = useCallback(async () => {
    try {
      var stone_list = [];
      const postdata = `{
        stones {
            id
            name 
            code
        }
      }`;
      let responce = await graphqlPost(postdata, "App/graphql");

      responce?.data?.stones?.map((result, index) => {
        stone_list.push({
          value: result.id + "_Stone",
          label: result.name,
          code: result?.code,
          text: result?.name,
          id: result.id,
        });
        return false
      });


      stone_selected_ids.map((metalSelectedID, index) => {
        var getMetalIndex = stone_list.findIndex(obj => obj.id === metalSelectedID);
        if (getMetalIndex !== -1) {
          var metal = stone_list[getMetalIndex];
          var id = metal.id + "_Stone";
          selected_stone_list.push({
            value: id,
            label: metal.label,
            code: metal?.code,
            text: metal?.text,
            id: metal.id,
            isFixed: true,
          });
        }
        return false;
      });

      setStonesList(stone_list);
      setStone(selected_stone_list);
      setStone_load(selected_stone_list);
      setCallviewvarient((state) => ({
        ...state,
        stone: true
      }))
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line
  }, [stone_selected_ids]);


  useEffect(() => {
    getProductUrl_details();
  }, [getProductUrl_details]);
  const createVarient = async () => {
    try {
      var postdata = {
        id: product_value_arr_varient[0].product_id,
        variants: [
          {
            title: "Metal",
            status: checked_metal ? 1 : 0,
            values: data_metal,
          },
          {
            title: "Stone",
            status: checked_stone ? 1 : 0,
            values: data_stone,
          },
          {
            title: "Size",
            status: checked_size ? 1 : 0,
            values: data_size,
          },
        ],
      };
      let resPoOrders = await _Api(
        postdata,
        `api/v1/APP/product/createVariants`
      );
      if (resPoOrders.code === 200) {
        setcurrency(resPoOrders?.data?.currency);
        var no_ofMetal = data_metal.length ? data_metal.length : 1;
        var no_ofvarient = resPoOrders?.data?.varients.length;
        var varient_brack = no_ofvarient / no_ofMetal;
        imageBox_no = varient_brack;
        setVarient_brack(varient_brack);
        setVarient_length(no_ofvarient);
        setvarient(resPoOrders?.data?.varients);
        setPossi_css_call();
      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const viewVarients = async () => {
    try {
      setTimeLoading(true)
      var postdata = {
        id: product_value_arr_varient[0].product_id,
        variants: [
          {
            title: "Metal",
            status: checked_metal ? 1 : 0,
            values: data_metal,
          },
          {
            title: "Stone",
            status: checked_stone ? 1 : 0,
            values: data_stone,
          },
          {
            title: "Size",
            status: checked_size ? 1 : 0,
            values: data_size,
          },
        ],
      };
      let resPoOrders = await _Api(
        postdata,
        `api/v1/APP/product/viewVariants`
      );
      if (resPoOrders.code === 200) {
        setcurrency(resPoOrders?.data?.currency);
        if (resPoOrders?.data?.varients?.length) {
          var no_ofMetal = data_metal.length ? data_metal.length : 1;
          var no_ofvarient = resPoOrders?.data?.varients.length;
          var varient_brack = no_ofvarient / no_ofMetal;
          imageBox_no = varient_brack;
          setVarient_brack(varient_brack);
          setVarient_length(no_ofvarient);
          setvarient(resPoOrders?.data?.varients);
          setPossi_css_call();
          onloadCopyHeadrsDataset(resPoOrders?.data?.currency, resPoOrders?.data?.varients);
        }
        setTimeLoading(false)
      } else {
        setTimeLoading(false)
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const [checked_stone, setChecked_stone] = useState(false);
  const [checked_metal, setChecked_metal] = useState(false);
  const [checked_size, setChecked_size] = useState(false);

  const handleClick_stone = () => setChecked_stone(!checked_stone);
  const handleClick_metal = () => setChecked_metal(!checked_metal);
  const handleClick_size = () => setChecked_size(!checked_size);
  const tr_index_inc = () => {
    tr_index++;
  };

  const tr_index_reset = () => {
    tr_index = 0;
  };
  const imageBox_index_inc = () => {
    imageBox_index++;
  };
  const imageBox_index_reset = () => {
    imageBox_index = 0;
  };
  const setImageBox_no = () => {
    imageBox_no += data_varient_brack;
  };
  const varient_product_add = async () => {
    try {
      var optionsvariants = [
        {
          title: "Metal",
          status: checked_metal ? 1 : 0,
          values: data_metal,
        },
        {
          title: "Stone",
          status: checked_stone ? 1 : 0,
          values: data_stone,
        },
        {
          title: "Size",
          status: checked_size ? 1 : 0,
          values: data_size,
        },
      ];
      const postdata = {
        variants: data_varient,
        id: product_value_arr_varient[0].product_id,
        Optionsvariants: optionsvariants,
      };
      let GetloadingID = loading();

      let resPoOrders = await _Api(postdata, `api/v1/APP/product/saveVariants`);
      if (resPoOrders.code === 200) {
        Updateloading(t, GetloadingID, t(resPoOrders.message));
        setButton("pe-none ct-disable");
      } else {
        setButton("ErrorShowBtn");
        Updateloading(t, GetloadingID, t(resPoOrders.errors), 'error');
      }

    } catch (err) {
      setButton("ErrorShowBtn");
    }
  };
  const map_valueSet = useCallback((index, value, input_key) => {
    var data_varient_arr = [...data_varient];
    data_varient_arr[index][input_key] = value;
    setvarient(data_varient_arr);
  }, [data_varient]);

  const onloadCopyHeadrsDataset = (data_currency, data_varient) => {

    var data_currency_ = [...data_currency];
    var data_varient_arr = [...data_varient];
    data_varient_arr?.[0]?.currency.forEach((result, key) => {
      data_currency_[key] = {
        "currency": result?.currency, "location_id": result?.currency, "check_price": false,
        "stock_check": false,
        "price": result?.price ? result?.price : "0",
        "ROP": result?.ROP ? result?.ROP : "0",
        "ROQ": result?.ROQ ? result?.ROQ : "0",
      }
      setcurrency(data_currency_);
    })

  };

  const map_valueSet_sub = (
    index,
    value,
    input_key,
    sub_ind,
    sub_input_key
  ) => {
    var data_varient_arr = [...data_varient];
    if (index === 0) {
      var data_currency_ = [...data_currency];
      data_currency_[sub_ind][sub_input_key] = value;
      setcurrency(data_currency_);
    }
    data_varient_arr[index][input_key][sub_ind][sub_input_key] = value;
    setvarient(data_varient_arr);
  };

  const setImage = useCallback(async () => {
    try {
      var postdata = productimage;
      let resPoOrders = await _Api(
        postdata,
        `api/v1/APP/product/saveVariantsImage`
      );
      if (resPoOrders.code === 200) {
        map_valueSet(productimage.index, resPoOrders.data.image, "image");
      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
    // eslint-disable-next-line
  }, [map_valueSet, productimage]);
  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };
  const getVarientImage = useCallback((type) => {
    var image = "";
    if (type === 1) {
      image = base_assets + "images/icons/variant-sm.png";
    } else if (type === 2) {
      image = base_assets + "images/pro-ring-img.png";
    }
    setVarient_image(image);
  }, [base_assets]);
  useEffect(() => {
    if (product_value_arr[0]?.product_id) {
      setIs_disable('pe-none ct-disable');
    }
  }, [product_value_arr]);





  const setPossi_css_call = () => {
    const timer = setTimeout(() => {
      var product_divide_manage_data = document.querySelectorAll('.product_divide_manage');
      var possi_css_ = [...possi_css];
      product_divide_manage_data?.forEach(function (data, index) {
        var data_id = data.getAttribute('data-index');
        possi_css_[data_id] = data.offsetTop;
      });
      setPossi_css(possi_css_);
    }, 1000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    if (call) {
      SizesList();
      metalList();
      stoneList();
    }
    // eslint-disable-next-line
  }, [call]);
  useEffect(() => {
    if (product_value_arr) {
      getVarientImage(product_value_arr[0].varient_type);
      setproduct_value_arr_varient(product_value_arr);
      if (product_value_arr[0].product_id) {
        getVariantsOptions();
      }
    }
  }, [product_value_arr, getVariantsOptions, getVarientImage]);
  useEffect(() => {
    if (callviewvarient?.metal && callviewvarient?.size && callviewvarient?.stone) {
      if ((size_load || metal_load || stone_load)) {
        setTimeout(async function () {
          viewVarients();
        }, 1000)
      }
    }
    // eslint-disable-next-line
  }, [metal_load, size_load, stone_load]);

  useEffect(() => {
    if (productimage.image) {
      setImage();
    }
    // eslint-disable-next-line
  }, [productimage]);

  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit(varient_product_add)}>
                <ProductMenu
                  product_value_arr={product_value_arr_varient}
                  setected_opt={setected_opt}
                  selectedButton_={selectedButton}
                  setIs_disable={setIs_disable}
                  productId_url={productId_url}

                />
                <div className={"product-ct-wrap product-pt " + IsDisable}>
                  <div className="product-ct-wrap-col-left col-left-wrap w-100">
                    <div className="product-com-box-layout">
                      <div className="product-ct-wrap-col-left-name">
                        <h1 className="product-ct-wrap-col-left-name-text">
                          # {product_value_arr_varient[0]?.name}
                        </h1>
                        <h1 className="product-ct-wrap-col-left-name-text ms-auto">{product_value_arr[0]?.sku}</h1>

                        <img
                          className="product-ct-wrap-col-left-name-img"
                          src={varient_image} alt=""
                        />
                      </div>
                      <div className="row product-ct-wrap-col-left-input-select-wrap">
                        <h4 className="pro-sub-heading">{t("Variants")}</h4>
                        <div className="col-md-12 product-ct-wrap-col-left-input-select-wrap-input">
                          <div className="product-multi-select-checkbox">
                            <div className="com-check">
                              <input
                                type="checkbox"
                                id="check"
                                onChange={() => handleClick_metal()}
                                checked={checked_metal}
                              />
                              <label
                                className="com-check-label"
                                htmlFor="check"
                              />
                            </div>

                            <div className="product-multi-select">
                              <label className="product-multi-select-label">
                                {t("Metal")}
                              </label>
                              <div className="product-multi-select-box">
                                <Select
                                  options={data_metal_list}
                                  placeholder={t("Metal")}
                                  value={data_metal || ''}
                                  components={{ MultiValueRemove }}
                                  onChange={handleSelectMetal}
                                  classNamePrefix="product-multi-select"
                                  getOptionLabel={(e) => (
                                    <div className="product-multi-select__multi-value__label-ct">
                                      {e.icon}
                                      <span>{e.label}</span>
                                    </div>
                                  )}
                                  isSearchable={false}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                          <div className="product-multi-select-checkbox">
                            <div className="com-check">
                              <input
                                type="checkbox"
                                id="check3"
                                onChange={() => handleClick_stone()}
                                checked={checked_stone}
                              />
                              <label
                                className="com-check-label"
                                htmlFor="check3"
                              />
                            </div>

                            <div className="product-multi-select">
                              <label className="product-multi-select-label">
                                {t("Stone")}
                              </label>
                              <div className="product-multi-select-box">
                                <Select
                                  options={data_stone_list}
                                  components={{ MultiValueRemove }}
                                  placeholder={t("Stone")}
                                  useDragHandle
                                  value={data_stone || ''}
                                  onChange={handleSelectStone}
                                  classNamePrefix="product-multi-select"
                                  getOptionLabel={(e) => (
                                    <div className="product-multi-select__multi-value__label-ct">
                                      {e.icon}
                                      <span>{e.label}</span>
                                    </div>
                                  )}
                                  isSearchable={false}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>
                          <div className="product-multi-select-checkbox">
                            <div className="com-check">
                              <input
                                type="checkbox"
                                id="check2"
                                onChange={() => handleClick_size()}
                                checked={checked_size}
                              />
                              <label
                                className="com-check-label"
                                htmlFor="check2"
                              />
                            </div>

                            <div className="product-multi-select">
                              <label className="product-multi-select-label">
                                {t("Size")}
                              </label>
                              <div className="product-multi-select-box">
                                <Select
                                  options={data_size_list}
                                  placeholder={t("Size")}
                                  value={data_size || ''}
                                  onChange={handleSelectSize}
                                  classNamePrefix="product-multi-select"
                                  components={{ MultiValueRemove }}
                                  getOptionLabel={(e) => (
                                    <div className="product-multi-select__multi-value__label-ct">
                                      {e.icon}
                                      <span>{e.label}</span>
                                    </div>
                                  )}
                                  isClearable={false}
                                  isSearchable={false}
                                  isMulti
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="box-create">
                          <span className="box-create-text">
                            {t("Select the box create variant.")}
                          </span>
                          <button
                            type="button"
                            onClick={() => createVarient()}
                            className={IsDisable ? "box-create-btn d-none" : "box-create-btn"}
                          >
                            {t("Create Variants")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className={"product-ct-wrap "}>
                <div className="product-ct-wrap-col-left col-left-wrap">
                  <div className="product-com-box-layout">
                    <button
                      className={IsDisable ? "variant-table-copy d-none" : "variant-table-copy"}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#CopyAllModal"
                    >
                      {t("Copy to all")}
                    </button>
                    <div className="variant-table">
                      <table className={"variant-table-inner " + IsDisable}>
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th>{t("Variant")}</th>
                            <th>{t("Product ID")}</th>
                            {data_currency?.length ? data_currency?.map((element, index) => (
                              <React.Fragment key={index}>
                                <th>
                                  <div className="variant-table-inner-country-th">
                                    <img
                                      className="save-icon"
                                      src={
                                        base_assets +
                                        "images/flags/" +
                                        element?.currency?.toLowerCase() +
                                        ".png"
                                      } alt=""
                                    />
                                    <span>{element.currency}</span>
                                  </div>
                                </th>
                              </React.Fragment>
                            )) : ""
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {data_varient?.length ? data_varient?.map((element, index) => {
                            if (element?.imgType === undefined) {
                              element.imgType = "individual";
                            }

                            if (imageBox_no === 0) {
                              imageBox_no = data_varient_brack;
                            }
                            if (
                              data_varient[imageBox_no - 1]?.imgType ===
                              undefined
                            ) {
                              data_varient[0].imgType =
                                "individual";
                            }
                            var img_type =
                              data_varient[imageBox_no - 1]?.imgType;
                            var sameType_img = data_varient[img_ind_].image;


                            if (img_type === "same") {
                              element.image = sameType_img;
                            }
                            tr_index_inc();
                            if (data_varient_brack === tr_index) {
                              img_ind_ = imageBox_no;
                              setImageBox_no();
                            }
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <div className="variant-table-inner-dot dot-status-dot">
                                      <Select
                                        placeholder=""
                                        defaultValue={status[0]}
                                        options={status}
                                        classNamePrefix="dot-status"
                                        isSearchable={false}
                                        onChange={(opt) =>
                                          map_valueSet(
                                            index,
                                            opt.value,
                                            "status"
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="px-0">
                                    <div className="variant-table-inner-product-img">
                                      <div
                                        className={
                                          element.image
                                            ? "variant-table-inner-product-img-box bg-none"
                                            : "variant-table-inner-product-img-box"
                                        }
                                      >
                                        {element.image ? (
                                          <img src={element.image} alt="" />
                                        ) : null}
                                        <input
                                          type="file"
                                          onChange={(e) =>
                                            onImageChange(e, index, element.sku)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="variant-table-inner-product-name">
                                    <div className="mt-1">{element.sku}</div>
                                  </td>
                                  <td>
                                    <div className="placeholder-up-input w-100">
                                      <input
                                        type="text"
                                        className="placeholder-up-input-field variant-table-inner-input"
                                        required=""
                                        defaultValue={element.id}
                                        onChange={(e) =>
                                          map_valueSet(
                                            index,
                                            e.target.value,
                                            "id"
                                          )
                                        }
                                      />
                                    </div>
                                  </td>

                                  {element?.currency?.map(
                                    (element_sub, index_sub) => {
                                      if (
                                        data_currency[index_sub]?.stock_check ===
                                        true
                                      ) {
                                        element_sub.ROP =
                                          data_currency[index_sub]?.ROP;
                                        element_sub.ROQ =
                                          data_currency[index_sub]?.ROQ;
                                      }
                                      if (
                                        data_currency[index_sub]?.check_price ===
                                        true
                                      ) {
                                        element_sub.price =
                                          data_currency[index_sub]?.price;
                                      }
                                      return (
                                        <React.Fragment key={index_sub}>
                                          <td>
                                            <div className="placeholder-up-input w-100">
                                              <input
                                                type="text"
                                                className={
                                                  "placeholder-up-input-field variant-table-inner-input currency_price" +
                                                  index_sub
                                                }
                                                required=""
                                                value={
                                                  data_currency[index_sub]
                                                    ?.check_price === true
                                                    ? data_currency[index_sub]
                                                      ?.price
                                                    : element_sub?.price
                                                }
                                                onChange={(e) =>
                                                  map_valueSet_sub(
                                                    index,
                                                    e.target.value,
                                                    "currency",
                                                    index_sub,
                                                    "price"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="variant-table-inner-color-input d-flex">
                                              <input
                                                className={
                                                  "variant-table-inner-color-input-single mx-1 currency_ROP" +
                                                  index_sub
                                                }
                                                value={
                                                  data_currency[index_sub]
                                                    ?.stock_check === true
                                                    ? data_currency[index_sub]?.ROP
                                                    : element_sub?.ROP
                                                }
                                                onChange={(e) =>
                                                  map_valueSet_sub(
                                                    index,
                                                    e.target.value,
                                                    "currency",
                                                    index_sub,
                                                    "ROP"
                                                  )
                                                }
                                              />
                                              <input
                                                className={
                                                  "variant-table-inner-color-input-single mx-1 currency_ROQ" +
                                                  index_sub
                                                }
                                                value={
                                                  data_currency[index_sub]
                                                    ?.stock_check === true
                                                    ? data_currency[index_sub]?.ROQ
                                                    : element_sub?.ROQ
                                                }
                                                onChange={(e) =>
                                                  map_valueSet_sub(
                                                    index,
                                                    e.target.value,
                                                    "currency",
                                                    index_sub,
                                                    "ROQ"
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </tr>

                                {data_varient_brack === tr_index &&
                                  data_varient_length !== index + 1 ? (
                                  <tr className="divider-table product_divide_manage" data-index={index + data_varient_brack}>
                                    <td colSpan="100">
                                      <hr />
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {data_varient_brack === tr_index &&
                                  data_varient_length !== index + 1
                                  ? tr_index_reset()
                                  : ""}


                              </React.Fragment>
                            );
                          }) : <tr>

                            <td colSpan="50" className="text-center">
                              {getTimeLoading ? (
                                <div className="spinner">
                                  <div className="loading-spinner">Loading...</div>
                                </div>
                              ) : (
                                <span>{t("No Data Available In Table")}</span>
                              )}
                            </td>
                          </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={"product-ct-wrap-col-right seperate-box " + IsDisable} >

                  {data_varient?.map((element, index) => {
                    var poss = '';
                    if (possi_css[index] !== undefined) {
                      poss = possi_css[index]
                    }

                    return (
                      <React.Fragment key={index}>
                        {imageBox_index_inc()}
                        {data_varient_brack === imageBox_index ? (
                          <div className="product-sidebar-box box-bg" id={'individual_' + index} style={{ top: poss }}>
                            <div className="com-check radio">
                              <input
                                type="radio"
                                className="checked"
                                id={"varient_" + index}
                                checked={element?.imgType === "individual"}
                                name={"varient" + index}
                                onChange={(e) =>
                                  map_valueSet(index, "individual", "imgType")
                                }
                              />
                              <label
                                className="com-check-label"
                                htmlFor={"varient_" + index}
                              ></label>
                              <label
                                className="com-check-label-text"
                                htmlFor={"varient_" + index}
                              >
                                {t("Use individual image")}
                              </label>
                            </div>
                            <div className="com-check radio">
                              <input
                                type="radio"
                                className="checked"
                                id={"varient" + index}
                                name={"varient" + index}
                                checked={element?.imgType === "same"}
                                onChange={(e) =>
                                  map_valueSet(index, "same", "imgType")
                                }
                              />
                              <label
                                className="com-check-label"
                                htmlFor={"varient" + index}
                              ></label>
                              <label
                                className="com-check-label-text"
                                htmlFor={"varient" + index}
                              >
                                {t("Use same image")}
                              </label>
                            </div>
                          </div>



                        ) : (
                          ""
                        )}
                        {data_varient_brack === imageBox_index
                          ? imageBox_index_reset()
                          : ""}
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopyToAll
        data_currency={data_currency}
        setcurrency={setcurrency}
      ></CopyToAll>
    </React.Fragment>
  );
};

export default ProductVariant;
