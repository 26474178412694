import { atom } from "recoil";

// Purpose for recoil at profilePage is helper to set show component 
    // due to issue trigger data at reduecer is not getting live data to anther compoment
    // and recoil library to help this part beacause that easy to use 

// data come from
// State in profile page have 5 state
// 1. list_profile_page  
// 2. add_profile_page 
// 3. edit_profile_page
// 4. print_preview_page
// 5. inventory_import_page


export const pageStatusState = atom({
    key: "pageStatusState",
    default:"list_profile_page"
})

export const pageHeadingBreadCrumbState = atom({
    key: "pageHeadingBreadCrumbState",
    default:{
        heading:"Barcode Label",
        breadCrumbName:"",
    }
})

export const callerFromState = atom({
    key: "callerFromState",
    default:""
})