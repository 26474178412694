import React, { useState } from "react";
import { _Api } from "../../api/_call";
import { error } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const Modal = (props) => {
  const { t } = useTranslation();
  const { tablehead, tblName, setnewcolumndata, calldatatable } = props;
  const [newcolumndata_, setnewcolumndata_] = useState(tablehead);
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;

  const handle_apply = async () => {
    try {
      const postdata = {
        name: tblName,
        data: newcolumndata_,
      };
      let resPoOrders = await _Api(
        postdata,
        `api/v1/Inventory/common/saveColumnHead`
      );
      if (resPoOrders.code === 200) {
        setnewcolumndata(newcolumndata_);
        calldatatable()
      } else {
        error(resPoOrders.errors);
      }
    } catch (err) {
      console.log(err, "err")
    }
  };

  const userSelectedAnswer = (Qindex) => {
    let newcolumndata_data = [...newcolumndata_];
    var check_checked = document.querySelector("#tableth" + Qindex).checked;
    newcolumndata_data[Qindex].status = check_checked;
    setnewcolumndata_(newcolumndata_data);
  };



  return (
    <div>
      <div
        className="modal fade column-modal"
        id="columnModal"
        tabIndex={-1}
        aria-labelledby="columnModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="columnModalLabel">
                <img
                  className="sku-col-arrow"
                  src={base_url + "images/icons/green-column.png"}
                  alt=""
                />
                {t("Column")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="column-modal-search-area">
                <button
                  onClick={handle_apply}
                  data-bs-dismiss="modal"
                  className="ms-auto column-modal-search-area-apply"
                >
                  {t("apply")}
                </button>
              </div>
              <div className="column-modal-checkbox-area">
                {newcolumndata_?.map((result, key) => {
                  return (
                    <div key={key} className="column-modal-checkbox-area-check">
                      <div className="com-check">
                        <input
                          onChange={(e) => userSelectedAnswer(key)}
                          id={"tableth" + key}
                          checked={result?.status}
                          type="checkbox"
                        />
                        <label
                          htmlFor={"tableth" + key}
                          className="com-check-label"
                        />
                      </div>
                      <label htmlFor={"tableth" + key}>
                        {result?.name ? t(result?.name) : "columnname"}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
//
