import React, { useEffect } from 'react';
import { Route, Outlet, useParams, Navigate } from 'react-router-dom';
import '../i18n.js'
import { useTranslation } from 'react-i18next';
import { useFullScreenHandle } from "react-full-screen";
import AdminHeader from "../admin/common/header/Header.js"
import AdminSidebar from "../admin/cms/sidebar/Sidebar.js";
import AdminProfile from "../admin/cms/pages/Profile.js"
import CustomSwitch from '../CustomSwitch.js';
import PageNotFound from '../components/common/404.js';
import { useSelector } from 'react-redux';
import UpdateTitle from '../source/UpdateTitle.js';
import FrontCms from "../admin/cms/settings/frontCms.js"
import CmsBanners from '../admin/cms/pages/CmsBanners.js';
import AddPage from '../admin/cms/pages/AddPage.js';
import CustomisePage from '../admin/cms/pages/CustomisePage.js';

const AdminCmsActive = function () {
    const { i18n } = useTranslation();
    const Fullhandle = useFullScreenHandle();
    const { locationId_url } = useParams();
    console.log(locationId_url, "locationId_url");
    if (!locationId_url) {
        // If locationId is not provided, redirect to a 'not found' page or another fallback route
        return <Navigate to="/app/admin/cms/not-found" />;
    }
    localStorage.setItem('middlewareLocations', '');
    return (
        <>
            <div className='main-layout-apps'>
                <div className="main-content-wrapper">
                    <AdminSidebar />
                    <div className='main-content-wrapper-right'>
                        <AdminHeader Fullhandle={Fullhandle} i18n={i18n} />

                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );

};

export default function AdminCmsRoutes() {
    const ModulePermission = useSelector((state) => state.app.ModulePermission);
    useEffect(() => {
        localStorage.removeItem("customerdetails")
        localStorage.removeItem("salesdetails")
    }, [])
    return (

        <CustomSwitch>
            <Route element={<AdminCmsActive />}>
                {ModulePermission?.GIS_setup &&

                    <><Route path="pages" element={<> <UpdateTitle title="CMS Pages" /><AdminProfile /></>} />
                        <Route path="pages/add" element={<> <UpdateTitle title="Edit  Page" /><AddPage /></>} />
                        <Route path="pages/add/:productId_url" element={<> <UpdateTitle title="Edit  Page" /><AddPage /></>} />
                        <Route path="banners" element={<> <UpdateTitle title="Banners" /><CmsBanners /></>} />
                        <Route path="frontcms" element={<> <UpdateTitle title="CMS Setting" /><FrontCms /></>} />
                        <Route path="/" element={<> <UpdateTitle title="CMS Setting" /><FrontCms /></>} />
                        <Route path="frontcms/:theme" element={<> <UpdateTitle title="CMS Setting" /><CustomisePage /></>} />

                    </>
                }
                <Route path='not-found' element={<PageNotFound />} ></Route>
                <Route path='*' element={<PageNotFound />} ></Route>
            </Route>
        </CustomSwitch>

    );
}
