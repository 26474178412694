import React, { useEffect, useState } from 'react';
import "datatables.net-fixedcolumns";
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import { _Api } from '../../api/_call';


const Filtermodal = (props) => {
    const {
        submitfiltter,
        limit,
        custom_filter_enable
    } = props
    const { t } = useTranslation();
    const [filterdata, setFilterdata] = useState()
    const [inventoryfilterdata, setInventoryfilterdata] = useState({
        "SKU": [],
        "price": [filterdata?.Price?.min, filterdata?.Price?.max],
        "item": [],
        "metal": [],
        "stone": [],
        "size": [],
        "collection": [],
        "location": [],
        "status": []
    })
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const callfilters = (event, label) => {
        let alldata = { ...inventoryfilterdata }
        if (label === "Item") {
            let itemdata = [...alldata?.item];
            if (event.target.checked) {
                itemdata.push(event.target.value);
                alldata.item = itemdata
            } else {
                alldata.item = itemdata.filter((item) => item !== event.target.value)
            }
        } else if (label === "metal") {
            let metaldata = [...alldata?.metal];
            if (event.target.checked) {
                metaldata.push(event.target.value);
                alldata.metal = metaldata
            } else {
                alldata.metal = metaldata.filter((metal) => metal !== event.target.value)
            }
        } else if (label === "stone") {
            let metaldata = [...alldata?.stone];
            if (event.target.checked) {
                metaldata.push(event.target.value);
                alldata.stone = metaldata
            } else {
                alldata.stone = metaldata.filter((stone) => stone !== event.target.value)
            }
        } else if (label === "size") {
            const sizedata = [...alldata.size];
            if (event.target.checked) {
                sizedata.push(event.target.value);
                alldata.size = sizedata
            } else {
                alldata.size = sizedata.filter((size) => size !== event.target.value)
            }
        } else if (label === "status") {
            const statusdata = [...alldata.status];
            if (event.target.checked) {
                statusdata.push(event.target.value);
                alldata.status = statusdata
            } else {
                alldata.status = statusdata.filter((status) => status !== event.target.value)
            }
        } else if (label === "collection") {
            const Collectiondata = [...alldata.collection];
            if (event.target.checked) {
                Collectiondata.push(event.target.value);
                alldata.collection = Collectiondata
            } else {
                alldata.collection = Collectiondata.filter((collection) => collection !== event.target.value)
            }
        } else if (label === "location") {
            const locationdata = [...alldata.location];
            if (event.target.checked) {
                locationdata.push(event.target.value);
                alldata.location = locationdata
            } else {
                alldata.location = locationdata.filter((location) => location !== event.target.value)
            }
        }
        setInventoryfilterdata(alldata);
    }
    const selectallfilter = (labels, selectStatus) => {
        let alldata = { ...inventoryfilterdata };
        const labelMapping = {
            item: '_id',
            metal: '_id',
            stone: '_id',
            size: '_id',
            status: 'value',
            collection: '_id',
            location: '_id'
        };
        const labelKey = labels.toLowerCase();
        const dataKey = labelMapping[labelKey];
        if (selectStatus === 'select') {
            alldata[labelKey] = Object.values(filterdata?.[labelKey]).flat().filter(item => item[dataKey]).map(item => item[dataKey]);
        } else {
            alldata[labelKey] = [];
        }
        setInventoryfilterdata(alldata);
    };

    const GetFilters = async () => {
        try {
            const postdata = {
                custom_filter_enable: custom_filter_enable ? true : false
            };
            let res = await _Api(postdata, `api/v1/Inventory/my/filter`);
            if (res?.code === 200) {
                setFilterdata(res?.data)

                setInventoryfilterdata(prevState => ({
                    ...prevState,
                    price: [res?.data?.Price?.min, res?.data?.Price?.max]
                }));
            }
        }
        catch (err) {
            console.log(err, 'err');
        }
    }
    useEffect(() => {
        GetFilters()
        // eslint-disable-next-line
    }, [])

    const clearall = () => {
        let get = document.getElementsByName('clear');
        for (let i = 0; i < get.length; i++) {
            get[i].checked = false;
        }
        setInventoryfilterdata({
            "SKU": [],
            "price": [filterdata?.Price?.min, filterdata?.Price?.max],
            "item": [],
            "metal": [],
            "stone": [],
            "size": [],
            "collection": [],
            "location": [],
            "status": []
        })
    }
    return (
        <>
            <div className="modal fade filter-customer-modal" id="filterinventory" tabIndex="-1" aria-labelledby="filterinventoryLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img className="d-block" src={base_assets + "images/icons/search-outline.png"} alt="" />
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {filterdata?.item?.length ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Item")}</label>
                                            {filterdata?.item?.length === inventoryfilterdata?.item?.length ?
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("item", "deselect")}>{t("Deselect")}</span> :
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("item", "select")}>{t("Select All")}</span>}

                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseItem" aria-expanded="false" aria-controls="collapseItem"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapseItem">
                                        <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                                            {filterdata?.item?.map((result, key) => {
                                                return (
                                                    <div className='com-label-select' key={key}>
                                                        <input className=''
                                                            type='checkbox'
                                                            id={result?._id}
                                                            value={result?._id}
                                                            checked={inventoryfilterdata.item.includes(result?._id)}
                                                            name="clear"
                                                            onClick={(e) => callfilters(e, "Item")}
                                                            readOnly />
                                                        <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto'
                                                            htmlFor={result?._id}>{result?.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {filterdata?.collection?.length ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Collection")}</label>
                                            {filterdata?.collection?.length === inventoryfilterdata?.collection?.length ?
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("collection", "deselect")}>{t("Deselect")}</span> :
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("collection", "select")}>{t("Select All")}</span>}
                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCollection" aria-expanded="false" aria-controls="collapseCollection"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapseCollection">
                                        <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                                            {filterdata?.collection?.map((result, key) => {
                                                return (
                                                    <div className='com-label-select' key={key}>
                                                        <input className='' type='checkbox' name="clear" id={result?._id}
                                                            value={result?._id}
                                                            onClick={(e) => callfilters(e, "collection")}
                                                            checked={inventoryfilterdata.collection.includes(result?._id)}
                                                            readOnly />
                                                        <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto' htmlFor={result?._id}>{result?.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {filterdata?.metal?.length ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Metal")}</label>
                                            {filterdata?.metal?.length === inventoryfilterdata?.metal?.length ?
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("metal", "deselect")}>{t("Deselect")}</span> :
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("metal", "select")}>{t("Select All")}</span>}
                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMetal" aria-expanded="false" aria-controls="collapseMetal"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapseMetal">
                                        <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                                            {filterdata?.metal?.map((result, key) => {
                                                return (
                                                    <div className='com-label-select' key={key}>
                                                        <input className=''
                                                            type='checkbox'
                                                            id={result?._id}
                                                            value={result?._id}
                                                            name="clear"
                                                            checked={inventoryfilterdata.metal.includes(result?._id)}
                                                            onClick={(e) => callfilters(e, "metal")}
                                                            readOnly />
                                                        <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto'
                                                            htmlFor={result?._id}>{result?.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {filterdata?.stone?.length ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Stone")}</label>
                                            {filterdata?.stone?.length === inventoryfilterdata?.stone?.length ?
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("stone", "deselect")}>{t("Deselect")}</span> :
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("stone", "select")}>{t("Select All")}</span>}
                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseStone" aria-expanded="false" aria-controls="collapseStone"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapseStone">
                                        <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                                            {filterdata?.stone?.map((result, key) => {
                                                return (
                                                    <div className='com-label-select' key={key}>
                                                        <input className=''
                                                            type='checkbox'
                                                            id={result?._id}
                                                            value={result?._id}
                                                            name="clear"
                                                            checked={inventoryfilterdata.stone.includes(result?._id)}
                                                            onClick={(e) => callfilters(e, "stone")}
                                                            readOnly />
                                                        <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto'
                                                            htmlFor={result?._id}>{result?.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {filterdata?.size?.length ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Size")}</label>
                                            {filterdata?.size?.length === inventoryfilterdata?.size?.length ?
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("size", "deselect")}>{t("Deselect")}</span> :
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("size", "select")}>{t("Select All")}</span>}
                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSize" aria-expanded="false" aria-controls="collapseSize"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapseSize">
                                        <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                                            {filterdata?.size?.map((result, key) => {
                                                return (
                                                    <div className='com-label-select' key={key}>
                                                        <input className=''
                                                            type='checkbox'
                                                            id={result?._id}
                                                            name="clear"
                                                            value={result?._id}
                                                            checked={inventoryfilterdata.size.includes(result?._id)}
                                                            onClick={(e) => callfilters(e, "size")}
                                                            readOnly />
                                                        <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto' htmlFor={result?._id}>{result?.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {filterdata?.location?.length ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Location")}</label>
                                            {filterdata?.location?.length === inventoryfilterdata?.location?.length ?
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("location", "deselect")}>{t("Deselect")}</span> :
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("location", "select")}>{t("Select All")}</span>}
                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLocation" aria-expanded="false" aria-controls="collapseLocation"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapseLocation">
                                        <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                                            {filterdata?.location?.map((result, key) => {
                                                return (
                                                    <div className='com-label-select' key={key}>
                                                        <input className='' type='checkbox' name="clear" id={result?._id}
                                                            value={result?._id}
                                                            onClick={(e) => callfilters(e, "location")}
                                                            checked={inventoryfilterdata.location.includes(result?._id)}
                                                            readOnly />
                                                        <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto' htmlFor={result?._id}>{result?.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {filterdata?.status?.length ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Status")}</label>
                                            {filterdata?.status?.length === inventoryfilterdata?.status?.length ?
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("status", "deselect")}>{t("Deselect")}</span> :
                                                <span className='me-3 dis cursor-pointer d-inline-block graythemecolor' style={{ fontSize: "15px" }}
                                                    onClick={() => selectallfilter("status", "select")}>{t("Select All")}</span>}
                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseStatus" aria-expanded="false" aria-controls="collapseStatus"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapseStatus">
                                        <div className='d-flex flex-wrap filter-customer-modal-alpha filter-customer-modal-label-row pb-4'>
                                            {filterdata?.status?.map((result, key) => {
                                                return (
                                                    <div className='com-label-select' key={key}>
                                                        <input className='' type='checkbox' name="clear" id={result?.value}
                                                            value={result?.value}
                                                            onClick={(e) => callfilters(e, "status")}
                                                            checked={inventoryfilterdata.status.includes(result?.value)}
                                                            readOnly />
                                                        <label className='text-center bg-f2 py-2 rounded-5 px-3 w-auto'
                                                            htmlFor={result?.value}>{result?.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {custom_filter_enable ?
                                <div className='filter-customer-modal-single-box'>
                                    <div className='d-flex align-items-center justify-space-between fw-semibold mb-3 mt-3'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <label>{t("Price")}</label>
                                        </div>
                                        <button className="collapsed border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePrice" aria-expanded="false" aria-controls="collapsePrice"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path d="M1 1L6 6L11 1" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg></button>
                                    </div>
                                    <div className="collapse" id="collapsePrice">
                                        <div className='filter-customer-modal-alpha filter-customer-modal-label-row pb-4 pt-2'>
                                            <div className="range-area mt-0">
                                                <Slider
                                                    range
                                                    min={filterdata?.Price?.min ? filterdata?.Price?.min : 0}
                                                    max={filterdata?.Price?.max ? filterdata?.Price?.max : 1000}
                                                    value={inventoryfilterdata?.price}
                                                    onChange={(e) => setInventoryfilterdata(prevState => ({
                                                        ...prevState,
                                                        price: e
                                                    }))}
                                                />
                                                <div className="range-area-num d-flex justify-content-between">
                                                    <div className="range-area-num-single">
                                                        <span className="range-area-num-unit">
                                                            {inventoryfilterdata?.price[0]}
                                                        </span>
                                                    </div>
                                                    <div className="range-area-num-single">
                                                        <span className="range-area-num-unit">
                                                            {inventoryfilterdata?.price[1]}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}

                        </div>
                        <div className="modal-footer py-2">
                            <button type="button" className="p-0 bg-transparent border-0 fs-6 me-auto fw-semibold link-color graythemecolor"
                                onClick={(e) => {
                                    clearall()
                                }}>{t("Clear All")}</button>
                            <button type="button" className="com-btn fs-6 h-auto p-3 py-2 rounded-2 fw-semibold" data-bs-dismiss="modal"
                                onClick={() => {
                                    submitfiltter(1, limit, inventoryfilterdata)
                                }} >{t("Apply")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Filtermodal;