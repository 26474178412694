import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { _Api, Api_form } from "../../api/_call";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTime from "../../common/DateTime";
import {
  LocationList,
  SupplierList,
  VoucherList,
} from "../../common/graphql/commonlayouts";
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { Tablehead } from "../../common/modules/Tablehead";
import Advancedfiltermodel from "../../common/modules/advanced-filter-model";
import NotesModalLabel from "../../common/modules/NotesModalLabel";
import { ImportExcelModal } from "../../common/modules/importExcel";
import { calDuedate } from "../../common/helpers/formatDate";
import { tbl_createreplenishHead } from "../../common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { Pagination } from "../../common/Pagination";
import ProductModal from "../../common/modules/ProductModal";
import ProductImageSliderModal from "../../common/modules/productImageslider";
import TransAndDeliverDate from "../../common/modules/TransAndDeliverDate";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import Select from "react-select";
const CreateReplenishOrder = (props) => {
  const { t } = useTranslation();
  const datePickerRef = useRef(null);
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL

  const [form_note, setForm_note] = useState(null);
  const [commanSelected_loc, setcommanSelected_loc] = useState(null);
  const [commanSelected_sup, setcommanSelected_sup] = useState(null);
  const [commanSelected_vouc, setcommanSelected_vouc] = useState(null);
  const [masterSearchData, setMasterSearchData] = useState([]);
  const [selecteditem, setSelecteditem] = useState([]);
  const [orderType, set_orderType] = useState('Low Stock');
  const [Replenish_OrdersList, setReplenish_OrdersList] = useState([]);
  const [excelloges, setExcelloges] = useState([])
  const [TransactionDate, setTransactionDate] = useState(new Date());
  const [DeliveryDate, setDeliveryDate] = useState();
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState('');
  const [getoneproduct, setOneproduct] = useState();
  const [newcolumndata, setnewcolumndata] = useState(tbl_createreplenishHead);
  const [importfile, setImportfile] = useState();
  const [startdate, setStartdate] = useState();
  const [ShowSubmitbutton, setShowSubmitbutton] = useState(true);
  const [enddate, setEnddate] = useState();
  const [imageid, setImageid] = useState();
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [oneproductindex, setoneproductindex] = useState();
  const [datelable, setDatelable] = useState('Replenish');
  const [cuppyqty, setCuppyqty] = useState()
  const [qty, setQty] = useState()
  const [showination, setShowination] = useState(false)
  const [poexportdata, setPoexportdata] = useState(false)
  const [getqty, setGetqty] = useState();
  const getdeliverydate = async () => {
    setDeliveryDate(await calDuedate(new Date()));
  }
  useEffect(() => {
    getdeliverydate();
  }, []);
  const SampleExcel = async () => {
    const exportLinkElement = document.createElement('a');
    exportLinkElement.hidden = true;
    exportLinkElement.target = '_blank';
    exportLinkElement.download = "ReplenishOrder.xlsx";
    exportLinkElement.href = process.env.REACT_APP_BASE_ASSETS_URL+"files/Replenish-Export.xlsx";
    exportLinkElement.text = "downloading...";
    document.body.appendChild(exportLinkElement);
    exportLinkElement.click();
    exportLinkElement.remove();
   
  };
  const readExcel = async () => {

    const handleValidation = (e) => {
      let formIsValid = true;
      if (!importfile) {
        formIsValid = false;
        error(t("Choose file!"));
      }
      return formIsValid;
    }
    let GetloadingID = loading();
    if (handleValidation()) {
      try {
        const formData = new FormData();
        formData.append('file', importfile);
        let res = await Api_form(formData, `api/v1/Inventory/po/uploadPOExcel`);
        if (res.code === 200) {
          setShowination(true)
          setPoexportdata(true)
          setExcelloges(res?.logs)
          UnSetdataTable('replenish_order_table');
          Updateloading(t, GetloadingID, (res?.message));
          setReplenish_OrdersList(res?.data);
          setTimeout(() => {
            SetdataTable("replenish_order_table", "4", newcolumndata);
          }, 100);
        } else {
          error((res?.message));
        }
      } catch (err) {
        console.log(err, 'err');
      }
    }
  };
  useEffect(() => { }, [DeliveryDate]);
  useEffect(() => {
    callcolumns();
  }, []);

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  useEffect(() => {
    SetdataTable("replenish_order_table", "6", newcolumndata);
  }, [newcolumndata]);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_creatreplenish",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getlimit = (limit) => {
    setLimit(limit);
    getReplenishList(1, limit);
    setstate({ ...state, currentPage: 1 });

  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getReplenishList(current, "");

  };
  const getReplenishList = async (current, firstlimit, start_date, end_date) => {

    try {

      if (!commanSelected_vouc) {
        error(t("Please select voucher"));
        return;
      }
      if (!commanSelected_loc) {
        error(t("Please select location"));
        return;
      }
      if (!commanSelected_sup) {
        error(t("Please select Supplier"));
        return;
      }
      let GetloadingID = loading();
      UnSetdataTable("replenish_order_table");
      const postdata = {
        location: commanSelected_loc,
        supplier: commanSelected_sup,
        skip: current ? (current - 1) * limit : 0,
        "limit": firstlimit ? firstlimit : limit,
        "start_date": start_date ? start_date : startdate ? startdate : new Date(),
        "end_date": end_date ? end_date : enddate ? enddate : new Date()
      };
      let resReplenishList = await _Api(
        postdata,
        `api/v1/Inventory/po/getReplenishList`
      );
      if (resReplenishList.code === 200) {
        setSelecteditem([])
        setSelectAllStatus(false)
        setShowination(false)
        Updateloading(t, GetloadingID, t("Data show successfully!"));
        SetdataTable("replenish_order_table", "6", newcolumndata);
        let limit = 1;
        if (postdata.limit === "ALL") {
          limit = resReplenishList?.count
        } else {
          limit = postdata.limit
        }
        setstate({
          ...state,
          totalPages: Math.ceil(resReplenishList?.count / limit),
          currentPage: current ? current : 1
        });
        if (getqty) {
          let array = [...resReplenishList.data]
          for (var i = 0; i < array.length; i++) {
            array[i] = {
              ...array[i],
              'order_qty': parseInt(getqty)
            }
          }
          setReplenish_OrdersList(array);
        } else {
          setReplenish_OrdersList(resReplenishList.data);
        }

      } else {
        Updateloading(t, GetloadingID, resReplenishList?.message, 'error');
        setReplenish_OrdersList([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const po_create_submit = async () => {
    try {
      if (!commanSelected_vouc) {
        error(t("Please select voucher"));
        return;
      }
      if (!commanSelected_loc) {
        error(t("Please select location"));
        return;
      }
      if (!commanSelected_sup) {
        error(t("Please select Supplier"));
        return;
      }
      if (!TransactionDate) {
        error(t("Please select Transaction Date"));
        return;
      }
      if (!DeliveryDate) {
        error(t("Please select Delivery Date"));
        return;
      }
      var checkedValue = document.querySelectorAll(
        ".allocation_OrdersList:checked"
      );
      var product_data = [];
      checkedValue.forEach(function (result) {
        var i = result.getAttribute("data-key");
        var product_details = Replenish_OrdersList[i];
        product_data.push({
          product_id: product_details["product_id"],
          oldStock_id: "",
          customer_id: "",
          so_no: "",
          po_QTY: parseInt(product_details["order_qty"]),
          price: product_details['price'],
          amount_total: (parseInt(product_details['price']) * parseInt(product_details["order_qty"])),
          sales_order_id: "",
          remark: "",
          Style: product_details["Style"],
          Metal: product_details["Metal"],
          Color: product_details["Color"],
          Size: product_details["Size"],
          Unit: product_details["Unit"],
          GrossWt: product_details["GrossWt"],
          NetWt: product_details["NetWt"],
          Stones: product_details["Stones"]
        });
      });
      if (!product_data.length) {
        error(t("No item Selected!!"));
        return;
      }
      if (qty <= 0) {
        error(t("Quantity should be greater than 0"));
        return;
      }
      var allcheck = document.querySelectorAll(".createreplenish");
      for (var j = 0; j < allcheck.length; j++) {
        if (allcheck[j].checked) {
          let qty = allcheck[j].getAttribute('data-qty');
          if (parseInt(qty) === 0) {
            let qtycheck = document.querySelectorAll(".replenishqty");
            qtycheck[j].style.border = "1px solid red ";
            return;
          } else {
            let qtycheck = document.querySelectorAll(".replenishqty");
            qtycheck[j].style.border = "1px solid #f4f4f4 ";
          }
        }
      }
      setShowSubmitbutton(false);
      let GetloadingID = loading();
      const postdata = {
        voucher_id: commanSelected_vouc,
        location_id: commanSelected_loc,
        supplier_id: commanSelected_sup,
        delivery_date: DeliveryDate,
        transaction_date: TransactionDate,
        note: form_note,
        type: orderType,
        po_data: product_data,
        RefNo: ''
      };
      let resPoOrders = await _Api(postdata, `api/v1/Inventory/po/createPO`);
      if (resPoOrders.code === 200) {
        setShowSubmitbutton(true);
        Updateloading(t, GetloadingID, t("Added successfully!!"));
        const timer = setTimeout(() => {
          navigate("/app/inventory");
        }, 100);
        return () => clearTimeout(timer);
      } else {
        setShowSubmitbutton(true);
        Updateloading(t, GetloadingID, t(resPoOrders?.message), t('error'));
        getReplenishList();
      }
    } catch (err) { }
  };


  const productSearch = useCallback(async () => {
    try {
      const postdata = {
        search: filtername,
      };
      let resp = await _Api(
        postdata,
        `api/v1/Inventory/po/getMasterProductSearch`
      );
      if (resp.code === 200) {
        setMasterSearchData(resp.data);
      } else {
        setMasterSearchData([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [filtername]);
  useEffect(() => {
    if (filtername) {
      productSearch();
    }
  }, [filtername, productSearch]);
  const getMasterProductById = async (product_id) => {
    try {
      const postdata = {
        id: product_id,
      };
      let resp = await _Api(
        postdata,
        `api/v1/Inventory/po/getMasterProductById`
      );
      if (resp.code === 200) {
        setShowination(true)
        setPoexportdata(true)
        UnSetdataTable('replenish_order_table');
        set_orderType("Master Product");
        setReplenish_OrdersList([...Replenish_OrdersList, resp.data]);
        setTimeout(() => {
          SetdataTable("replenish_order_table", "6", newcolumndata);
        }, 100);
      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const getNewMasterProduct = (product_id) => {
    getMasterProductById(product_id);
    setFiltername('');
    setMasterSearchData([]);
  };
  const changeorder_qty = (event, key) => {
    var mainarray = [...Replenish_OrdersList];
    if (event.target.value > 0) {

      mainarray[key] = {
        ...mainarray[key],
        'order_qty': event.target.value ? event.target.value : ""
      }
    } else {
      mainarray[key] = {
        ...mainarray[key],
        'order_qty': ""
      }
    }
    setReplenish_OrdersList(mainarray);

  }

  const changeDates = (lable) => {
    const MainDate = new Date();
    if (lable === 'Today') {
      setStartdate(new Date());
      setEnddate(new Date());
      setDatelable('Today');
      getReplenishList(1, 100, new Date(), new Date())
    } else if (lable === 'Yesterday') {
      const yesterdaydate = new Date(MainDate.setDate(MainDate.getDate() - 1));
      setStartdate(yesterdaydate);
      setEnddate(yesterdaydate);
      setDatelable('Yesterday');
      getReplenishList(1, 100, yesterdaydate, yesterdaydate)
    } else if (lable === '7days') {
      const sevendays = new Date(MainDate.setDate(MainDate.getDate() - 7));
      setStartdate(sevendays);
      setEnddate(new Date());
      setDatelable('7days');
      getReplenishList(1, 100, sevendays, new Date())
    } else if (lable === '30days') {
      const days = new Date(MainDate.setDate(MainDate.getDate() - 30));
      setStartdate(days);
      setEnddate(new Date());
      setDatelable('30days');
      getReplenishList(1, 100, days, new Date())
    } else if (lable === 'ThisMonth') {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 1);
      const lastDay = new Date(MainDate.getFullYear(), MainDate.getMonth() + 1, 0);
      setStartdate(firstDay);
      setEnddate(lastDay);
      setDatelable('ThisMonth');
      getReplenishList(1, 100, firstDay, lastDay)
    } else if (lable === "LastMonth") {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth() - 1, 1);
      const lastDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 0);
      setStartdate(firstDay);
      setEnddate(lastDay);
      getReplenishList(1, 100, firstDay, lastDay)
      setDatelable('LastMonth');
    }

  }
  const editproduct_submit = () => {
    var mainarray = [...Replenish_OrdersList];
    mainarray[oneproductindex] = getoneproduct;
    setReplenish_OrdersList(mainarray);
  }
  const deleteProduct = () => {
    const mainarray = [...Replenish_OrdersList];
    const selected = document.querySelectorAll(".createreplenish");
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let product_id = selected[i].getAttribute('data-id');
        let product_key = selected[i].getAttribute('data-key');
        let multipleorders = mainarray.filter((item) => item?.product_id === product_id);
        if (multipleorders.length > 1) {
          setReplenish_OrdersList((current) =>
            current.filter((item, key) => key !== parseInt(product_key))
          );
        } else {
          setReplenish_OrdersList((current) =>
            current.filter((item) => item?.product_id !== product_id)
          );
        }


        selected[i].checked = false;
      }
    }
    setSelecteditem([]);
    UnSetdataTable('replenish_order_table');
    setTimeout(() => {
      SetdataTable("replenish_order_table", "6", newcolumndata);
    }, 100);
  }
  const cuppyProduct = () => {
    const mainarray = [...Replenish_OrdersList];
    for (var i = 0; i < mainarray.length; i++) {

      mainarray[i] = {
        ...mainarray[i],
        'order_qty': parseInt(cuppyqty)
      }
    }
    setGetqty(mainarray[0]?.order_qty)
    setReplenish_OrdersList(mainarray);
    setSelecteditem([]);
    UnSetdataTable('replenish_order_table');
    setTimeout(() => {
      SetdataTable("replenish_order_table", "6", newcolumndata);
    }, 100);

  }
  const selectedproduct = (e) => {
    const productId = e.target.value;
    const productIndex = parseInt(e.target.dataset.key, 10);
    const isChecked = e.target.checked;

    if (isChecked) {
      if (!selecteditem.some(item => item.product_id === productId && item.index === productIndex)) {
        setSelecteditem([...selecteditem, { product_id: productId, index: productIndex }]);
      }
    } else {
      setSelecteditem(selecteditem.filter(item => !(item.product_id === productId && item.index === productIndex)));
    }
  };
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".createreplenish");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        let product_id = selected[i].getAttribute('data-id');
        arrya.push({ product_id: product_id, index: i });
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);

  const poimport = async () => {
    const handleValidation = (e) => {
      let formIsValid = true
      if (!commanSelected_vouc) {
        formIsValid = false
        error(t("Please select voucher"));
        return;
      }
      if (!commanSelected_loc) {
        formIsValid = false
        error(t("Please select location"));
        return;

      }
      if (poexportdata) {
        if (!selecteditem.length) {
          formIsValid = false
          error(t('No item Selected!!'))
          return;
        }

      }
      return formIsValid
    }
    if (handleValidation()) {
      let GetloadingID = loading();
      try {
        let selectprodutid = [...selecteditem]
        let product_id = []
        if (selectprodutid?.length) {
          selectprodutid.forEach((result) => {
            product_id.push(result?.product_id)
          })
        }
        let postdata = {
          "product_id": selecteditem.length ? product_id : [],
          "start_date": startdate ? startdate : new Date(),
          "end_date": enddate ? enddate : new Date(),
          "location": commanSelected_loc
        }
        let res = await _Api(postdata, "api/v1/Inventory/po/geReplenishListExport")
        if (res?.code === 200) {
          Updateloading(t, GetloadingID, (res?.message));
        } else {
          Updateloading(t, GetloadingID, (res.errors), 'error');
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-body-current-day-time">
                <DateTime></DateTime>
              </div>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <span onClick={() => navigate(-1)} className="page-back-btn">
                    <img
                      src={base_url + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </span>
                  <img src={base_url + "images/icons/home-icon.png"} alt="" />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Purchase_order"}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">
                  {t("Create Purchase Order")}
                </h1>
              </div>

              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div
                  className="main-body-top-tab-bar-left-col nav nav-pills"
                  role="tablist"
                >
                  <Link
                    to="/app/inventory/createpo"
                    className="main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        className="tab-hover-img-1"
                        src={base_url + "images/icons/price-list-icon.png"}
                        alt=""
                      />
                      <img
                        className="tab-hover-img-2"
                        src={
                          base_url + "images/icons/price-list-icon-green.png"
                        }
                        alt=""
                      />
                      {t("POS Order")}
                    </span>
                  </Link>
                  <Link
                    to="/app/inventory/createpoReplenishOrder"
                    className="main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh active"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        className="tab-hover-img-1"
                        src={base_url + "images/icons/replenish-icon.png"}
                        alt=""
                      />
                      <img
                        className="tab-hover-img-2"
                        src={base_url + "images/icons/replenish-icon-green.png"}
                        alt=""
                      />
                      {t("Replenish Order")}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="tab-content">
                <div id="replenish_order" className="tab-pane active">
                  <div className="main-body-top-inputs-bar">
                    <div className="main-body-top-inputs-bar-col-left">
                      <label className="po-num-label  po-num-green">
                        {t("po-XXXX")}
                      </label>
                      <LocationList
                        setcommanSelected_loc={setcommanSelected_loc}
                        setpo={"setpo"}
                      />
                      <SupplierList
                        setcommanSelected_sup={setcommanSelected_sup}
                      />
                      <TransAndDeliverDate TransactionDate={TransactionDate} DeliveryDate={DeliveryDate} setTransactionDate={setTransactionDate} setDeliveryDate={setDeliveryDate} />
                    </div>
                    <div className="main-body-top-inputs-bar-col-right">
                      {excelloges?.length ?
                        <div className="dropdown user-log-dropdown d-flex align-items-center">
                          <button className="btn-style stock-take-inner-row-col-mid-header-right-btn mx-1 dropdown-toggle"
                            data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <img className="note" src={base_url + "images/icons/note-board-icon-dark.png"} alt="" />
                          </button>

                          <ul className="dropdown-menu">
                            <ul className="nav nav-tabs user-log-tabs" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="user-tab" data-bs-toggle="tab" data-bs-target="#user-tab-pane" type="button" role="tab" aria-controls="user-tab-pane" aria-selected="true">{t("Excel Import Loges")}</button>
                              </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                              <div className="tab-pane fade show active" id="user-tab-pane" role="tabpanel" aria-labelledby="user-tab" tabIndex="0">
                                <table>
                                  <tbody>
                                    {excelloges?.length ? excelloges.map((result, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>
                                            <b className="fw-semibold">{result?.SKU ? result?.SKU : ""}</b><br />
                                            <span>{result?.message ? result?.message : ""}</span><br />
                                          </td>

                                        </tr>
                                      )
                                    })
                                      : ""}

                                  </tbody>
                                </table>
                              </div>

                            </div>
                          </ul>
                        </div> : ""}
                      <button
                        className={
                          ShowSubmitbutton
                            ? "main-body-top-inputs-bar-col-right-submit-btn com-btn "
                            : "main-body-top-inputs-bar-col-right-submit-btn com-btn d-block  deactive"
                        }
                        data-bs-toggle="modal"
                        data-bs-target="#createPurchaseModal"
                      >
                        {t("Submit")}
                      </button>
                    </div>
                  </div>
                  <div className="main-body-top-status-bar">
                    <Select
                      className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                      isSearchable={false}
                    />
                    <div className="main-body-top-status-bar-filter">
                      <input
                        type="text"
                        value={filtername}
                        className="main-body-top-status-bar-filter-input"
                        placeholder={t("SKU/Metal/Stone")}
                        onChange={(e) => setFiltername(e.target.value)}
                      />
                      {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => setFiltername("")} alt="" /> : ""}
                      <button className="main-body-top-status-bar-filter-search">
                        <i className="fa fa-search" aria-hidden="true" />
                      </button>
                      {filtername ?
                        <div className="item_list_card_all">
                          <div className="item_list_card">
                            <div className="custom_search_title text-green">{t("Custom Search")}</div>
                            <ul id="item_list_cardul_custom" className="item_list_cardul_custom">
                              <li>{filtername}</li>
                            </ul>
                            <div className="custom_search_title text-green">{t("Product")}</div>
                            <ul id="item_list_cardul" className="item_list_cardul">
                              {masterSearchData?.length ? masterSearchData?.map((result, index) => {
                                return (
                                  <li onClick={() => getNewMasterProduct(result._id)} key={index}>
                                    <span>{result.name}</span>
                                    <br />
                                    <small>{result.SKU}</small>
                                  </li>
                                )
                              }) : ""}
                            </ul>
                          </div>
                        </div>
                        : " "}
                    </div>
                    <div className="main-body-top-status-bar-multi-date-picker">
                      <button className="print-export-dropdown-selected-icon d-none">
                        <img
                          src={base_url + "images/icons/printer-icon.png"}
                          alt=""
                        />
                      </button>
                      <div className="print-export-dropdown-selected-icon">
                        <button className={
                          selecteditem.length > 0
                            ? "deletebtn btn-style d-block"
                            : "d-none"
                        } onClick={deleteProduct}>
                          <img src={base_url + "images/icons/delete-icon.png"} alt="" />
                        </button>
                      </div>
                      <div className="print-export-dropdown-selected-icon">
                        <button className={
                          cuppyqty
                            ? "deletebtn btn-style d-block"
                            : "d-none"
                        } onClick={cuppyProduct}  >
                          <img src={base_url + "images/icons/clone.svg"} alt="" />
                        </button>
                      </div>
                      <div className="main-body-top-status-bar-video-btn">
                        <img
                          onClick={() => {
                            getReplenishList();
                            set_orderType("Low Stock");
                          }}
                          src={base_url + "images/icons/play-icon.png"}
                          alt=""
                        />
                      </div>
                      <div className="main-body-top-status-bar-note-button common-dropdown replenish-date" >
                        <button className="dropdown-toggle btn-style align-items-center d-flex" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <img className="me-2" src={base_url + "images/icons/clock-icon.png"} alt="" />
                          {t(datelable)}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li className={`dropdown-item ${datelable === 'Today' || datelable === "Replenish" ? "active" : ""}`} onClick={() => changeDates('Today')} >{t("Today")}</li>
                          <li className={`dropdown-item ${datelable === 'Yesterday' ? "active" : ""}`} onClick={() => changeDates('Yesterday')}>{t("Yesterday")}</li>
                          <li className={`dropdown-item ${datelable === '7days' ? "active" : ""}`} onClick={() => changeDates('7days')}>{t("Last 7 days")}</li>
                          <li className={`dropdown-item ${datelable === '30days' ? "active" : ""}`} onClick={() => changeDates('30days')}>{t("Last 30 days")}</li>
                          <li className={`dropdown-item ${datelable === 'ThisMonth' ? "active" : ""}`} onClick={() => changeDates('ThisMonth')}>{t("This Month")}</li>
                          <li className={`dropdown-item ${datelable === 'LastMonth' ? "active" : ""}`} onClick={() => changeDates('LastMonth')}>{t("Last Month")}</li>
                          <li className={`dropdown-item ${datelable === 'Custom Range' ? "active" : ""}`}
                            onClick={() => datePickerRef.current.setOpen(true)}>{t("Custom Range")}</li>
                        </ul>
                        <DatePicker
                          selectsRange={true}
                          monthsShown={2}
                          startDate={startdate}
                          endDate={enddate}
                          onChange={(update) => {
                            const startDate = update[0];
                            const endDate = update[1];
                            setStartdate(startDate);
                            setEnddate(endDate);
                            setDatelable("Custom Range");
                            const modifiedStartDate = new Date(startDate);
                            modifiedStartDate.setHours(12, 10, 10, 999);
                            const modifiedEndDate = new Date(endDate);
                            modifiedEndDate.setHours(12, 10, 10, 999);
                            getReplenishList(1, 100, modifiedStartDate, modifiedEndDate);
                          }}
                          dateFormat="yyyy-MM-dd"
                          ref={datePickerRef}
                          autoComplete='none'
                        />
                      </div>
                      <div
                        className="main-body-top-status-bar-note-button"
                        data-bs-toggle="modal"
                        data-bs-target="#notesModal"
                      >
                        <img
                          src={base_url + "images/icons/comment-icon.png"}
                          alt=""
                        />
                        {t("Note")}
                      </div>
                      <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              base_url + "images/icons/ellipsis-circular.png"
                            }
                            alt=""
                          />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className="dropdown-item" onClick={() => poimport()}>
                            <div className="dropdown-item-img">
                              <img
                                src={
                                  base_url + "images/icons/export-x-icon.png"
                                }
                                alt=""
                              />
                            </div>
                            <span>{t("Export")}</span>
                          </li>
                          <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#ImportModal">
                            <div className="dropdown-item-img">
                              <img src={base_url + "images/icons/import-icon.png"} alt="" />
                            </div>
                            <span>{t("import")}</span>
                          </li>
                          <li
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#columnModal"
                          >
                            <div className="dropdown-item-img">
                              <img
                                src={base_url + "images/icons/column-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("column")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="common-sub-table-wrap add-row">
                    <div className="main-body-main-table-wrap position-relative">
                      <table
                        id="replenish_order_table"
                        className="stripe row-border order-column common-table table-striped main-table w-100"
                      >
                        <Tablehead
                          tablehead={tbl_createreplenishHead}
                          tblName={"tbl_creatreplenish"}
                          setfilterby={""}
                          setShowoption={setSelectAllStatus}
                          setSelectAllStatus={SelectAllStatus}
                        />
                        <tbody>
                          {Replenish_OrdersList?.map((result, index) => {
                            return (
                              <tr key={index} className="createrepainshcuppy">
                                <td>
                                  <div className="com-check checkbox-width">
                                    <input
                                      checked={selecteditem.some(item => item.product_id === result?.product_id && item.index === index)}
                                      data-key={index}
                                      className="allocation_OrdersList createreplenish"
                                      type="checkbox"
                                      onChange={selectedproduct}
                                      value={result?.product_id || ''}
                                      onClick={() => setQty(result.order_qty)}
                                      data-id={result?.product_id}
                                      data-qty={result?.order_qty}
                                      id={`${result?.product_id}_${index}`}
                                    />
                                    <label
                                      htmlFor={`${result?.product_id}_${index}`}
                                      className="com-check-label"
                                    />
                                  </div>
                                </td>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="table-pro-img" onClick={() => setImageid(result?.product_id)} data-bs-toggle="modal" data-bs-target="#ProductViewModal">
                                    <img src={result.main_image} alt="" />
                                  </div>
                                </td>
                                <td>
                                  <div className="tooltip-area-up">
                                    <span className="table-pro-tooltip">{result.name}</span>

                                    <span className="tooltip-area-up-text text-white">
                                      {result.name}
                                    </span>
                                  </div>
                                </td>
                                <td className="po-no link-color cursor-pointer col-highlight" onClick={() => { setOneproduct(result); setoneproductindex(index) }} data-bs-toggle="modal" data-bs-target="#ProductInfoModal">
                                  {result.SKU}
                                </td>
                                <td className="col-highlight"> {result.metal_name}</td>
                                <td className="col-highlight">{result.stone_name}</td>
                                <td className="col-highlight">
                                  {result.size_name}
                                </td>
                                <td className="col-highlight text-center">
                                  {result.total_stock ? result.total_stock : 0}
                                </td>
                                <td className="col-highlight text-center danger-text">
                                  {result.SalesOrder ? result.SalesOrder : 0}
                                </td>
                                <td className="col-highlight text-center">
                                  {result.AvailableStock ? result.AvailableStock : 0}
                                </td>
                                <td className="col-highlight text-center">
                                  {result.ReorderPoint_total ? result.ReorderPoint_total : 0}
                                </td>
                                <td className="col-highlight text-center status-green-text">
                                  {result.ReorderQuantity_total ? result.ReorderQuantity_total : 0}
                                </td>
                                <td className="col-highlight">
                                  <div className="col-input">
                                    <input
                                      className="col-width replenishqty"
                                      type="text"
                                      value={result.order_qty || ''}
                                      onChange={(e) => { changeorder_qty(e, index); setCuppyqty(e.target.value); setQty(e.target.value) }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td className="main-green-text left-0">
                              {selecteditem.length > 0 ? (
                                <span> {selecteditem.length} {t("item Selected")}</span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>{t("Total")}</td>
                            <td> {Replenish_OrdersList
                              ? Replenish_OrdersList?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val?.order_qty ? val?.order_qty : 0)),
                                0
                              )
                              : 0}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  {showination ? "" :
                    Replenish_OrdersList.length > 0 ? (
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        pagination={(crPage) => handlePaginations(crPage)}
                      />
                    ) : (
                      ""
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit={editproduct_submit} />
      </div>
      {
        imageid ?
          <ProductImageSliderModal
            main_id={imageid}
          ></ProductImageSliderModal> : ''
      }
      <TableColumnModal
        tablehead={tbl_createreplenishHead}
        tblName={"tbl_creatreplenish"}
        setnewcolumndata={setnewcolumndata}
        calldatatable={getReplenishList}
      ></TableColumnModal>
      <ConfirmationModel statement={"Create Purchase Order"} handler_submit={po_create_submit}></ConfirmationModel>
      <NotesModalLabel setForm_note={setForm_note}></NotesModalLabel>
      <Advancedfiltermodel></Advancedfiltermodel>
      <ImportExcelModal setImportfile={setImportfile} readExcel={readExcel} SampleExcel={SampleExcel} importfile={importfile} />
    </React.Fragment>
  );
};
export default CreateReplenishOrder;
