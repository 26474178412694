import React, { useEffect } from 'react'
import './CookieConsent.scss'
import CrossIcon from '../../assets/icons/CrossIcon'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { giveConsent } from '../../../../source/app/appSlice';


export default function CookieConsent() {
    const { t } = useTranslation();

    const dispatch = useDispatch();
  const consentGiven = useSelector((state) => state.app.consentGiven);

  useEffect(() => {
    const consentStatus = localStorage.getItem('cookieConsent');
    if (consentStatus === 'true') {
      dispatch(giveConsent());
    }
  }, [dispatch]);

  const handleAccept = () => {
    dispatch(giveConsent());
    localStorage.setItem('cookieConsent', 'true');
  };

  const handleReject = () => {
    dispatch(giveConsent());
    localStorage.setItem('cookieConsent', 'false');
  };

  if (consentGiven) return null;
  return (
    <div className='cookieconsent' onCl>
        <div className='cookieconsent-card'>
        <div className='cookieconsent-card-close'><CrossIcon width={"12px"} height={"12px"}/></div>
        <div className='cookieconsent-card-inner'>
            <div className='cookieconsent-card-inner-content'>
                <div className='cookieconsent-card-inner-content-label'>{t("Cookie Consent")}</div>
                <div className='cookieconsent-card-inner-content-subdata'>{t("By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. ")}<Link to="" className='link-color text-underline'>{t("Privacy policy")}</Link></div>
            </div>
            <div className='cookieconsent-card-inner-controls'>
                <button className='btnwithborder' onClick={handleReject}>{t("Reject all")}</button>
                <button className='btnbold' onClick={handleAccept}>{t("Accept all")}</button>
            </div>
        </div>
        </div>
    </div>
  )
}
