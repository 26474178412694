import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { success, error } from "../../common/helpers/toastify";
import DateTime from "../../common/DateTime";
import { _Api } from "../../api/_call";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { Tablehead } from "../../common/modules/Tablehead";
import NotesModalAccounting from "../../common/modules/NotesModalAccounting";
import TableColumnModal from "../../common/modules/tableColumn";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import {
  LocationList,
  SupplierList,
  VoucherList,
  WarehouseList,
} from "../../common/graphql/commonlayouts";
import { purchase_status } from "../../common/helpers/status_class";
import { Editpurchasetablehead } from "../../common/Tablehead";
import { toFormatPrice, toUnformatPrice } from "../../common/helpers/function";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import ProductModal from "../../common/modules/ProductModal";
import TransAndDeliverDate from "../../common/modules/TransAndDeliverDate";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { useSelector } from "react-redux";
const Editpurchase = (props) => {
  const { t } = useTranslation();
  var colorsArray = []
  var valueofstatus = [];
  const { state } = useLocation();
  const {
    editpurchaseoustandingids,
    location_id,
    supplier_id,
    warehouse_id,
    Voucher_id,
    pu_no,
    transactiondate,
    deliverydate,
    purchasestatus,
    product_id,
    printparmission
  } = state;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState("");
  const [qty, setQty] = useState()
  const [sku, setSku] = useState()
  const [commanSelected_warehouse, setcommanSelected_warehouse] =
    useState();
  const [editpusstatus, setEditpusstatus] = useState();
  const [TransactionDate, setTransactionDate] = useState(new Date(transactiondate));
  const [DeliveryDate, setDeliveryDate] = useState(new Date(deliverydate));
  const [selecteditem, setSelecteditem] = useState([]);
  const [Productmodaldata, setProductmodaldata] = useState([]);
  const [editpurchasedata, setEditpurchasedata] = useState([]);
  const [oldeditpurchase, setEditoldpurchase] = useState([]);
  const [purchasestatusdata, setpurchasestatusdata] = useState();
  const [commanSelected_vouc, setcommanSelected_vouc] = useState();
  const [newcolumndata, setnewcolumndata] = useState(Editpurchasetablehead);
  const [deletearray, setDeletearray] = useState([]);
  const [Accountingarray, setAccountingarray] = useState([]);
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [commanSelected_loc, setcommanSelected_loc] = useState();
  const [commanSelected_sup, setcommanSelected_sup] = useState();
  const [Shipping, setShipping] = useState();
  const [printurl, setPrinturl] = useState();
  const [Form_note, setForm_note] = useState();
  const [statement, setStatement] = useState("");
  const [tax, setTax] = useState();
  const [focusstockid, setFocusstockid] = useState();
  const [datalodaer, setDatsLodaer] = useState(false)
  const [oneproductindex, setoneproductindex] = useState();
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color
      };
    }
  };
  const filterbyname = (search) => {
    if (filtername)
      Getpurchaseproduct(limit, search);
  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_edit_purchase",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  var po_status_class_val = purchase_status(
    purchasestatusdata
  );
  if (purchasestatusdata === 1) {
    valueofstatus = [{ label: "approved", value: purchasestatusdata, color: "#0ECB81" }]
  } else if (purchasestatusdata === 2) {
    valueofstatus = [{ label: "pending", value: purchasestatusdata, color: "#F9BC44" }]
    colorsArray = [
      { label: "approved", value: 1, color: "#0ECB81" },
      { label: "canceled", value: 0, color: "#FF5757" }
    ]
  } else if (purchasestatusdata === 0) {
    valueofstatus = [{ label: "canceled", value: purchasestatusdata, color: "#FF5757" }]
  }
  const Getpurchaseproduct = async (firstlimit, search) => {
    setDatsLodaer(true)
    UnSetdataTable('tbl_createPurchase_list');
    try {
      const postdata = {
        po_order_id: product_id,
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
        show_all: false,
      };
      let res = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPuDetails`
      );
      if (res?.code === 200) {
        setAccountingarray({ 'shipping_handling_amount': res?.data?.shipping_handling_amount, 'tax_amount': res?.data?.tax_amount, "note": res?.data?.note })
        res?.data?.po_product_data.map((result, key) => {
          return (
            res.data.po_product_data[key] = { ...res.data.po_product_data[key], "is_stock_disable": true }
          )
        });
        var newdata = [];
        if (editpurchaseoustandingids !== undefined) {
          newdata = await Getoutstanding();
        }
        let Olddata = res?.data?.po_product_data;
        let mix_data = Olddata.concat(newdata);
        SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);
        setpurchasestatusdata(res?.data?.status?.pu_status);
        setPrinturl(res?.data?.printURL);
        setEditpurchasedata(mix_data);
        setEditoldpurchase(mix_data);
        setDeletearray([])
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const Getoutstanding = async () => {
    let newarray = [];
    try {
      const postdata = {
        po_product_id: editpurchaseoustandingids,
      };
      let resoutstanding = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getCreatePurchaseList`
      );
      if (resoutstanding?.code === 200) {
        resoutstanding.data.forEach((result, key) => {
          result.product_new = true;
          newarray.push(result);
        });

      }
    } catch (err) {
      console.log(err, "err");
    }
    return newarray;
  };
  const deleteIcon = (event) => {
    if (event.target.checked) {
      const values = [...selecteditem, event.target.value];
      setSelecteditem(values);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  };
  const deleteitem = () => {
    UnSetdataTable('tbl_createPurchase_list');
    const selected = document.querySelectorAll(".editPurchase_select");
    let deleteArray = [...deletearray];
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let product_id = selected[i].getAttribute('data-id');
        setEditpurchasedata((current) =>
          current.filter((item) => item?.id !== product_id)
        );
        selected[i].checked = false;
        deleteArray.push(product_id);
        setDeletearray(deleteArray);

      }
    }
    setSelecteditem([]);
    const timer = setTimeout(() => {
      SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);
    }, 200);
    return () => clearTimeout(timer);
  }
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".editPurchase_select");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id');
          arrya.push(product_id);

        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);
  const calculation_purchase_tax = (event, i) => {
    const { value, name } = event.target;
    const newState = [...editpurchasedata];
    newState[i] = {
      ...newState[i],
      [name]: value,
    };
    var tax_value = parseInt(newState[i]?.tax);
    if (!tax_value) {
      tax_value = 0;
    }
    var qty_value = parseInt(newState[i]?.po_QTY);
    var price_value = newState[i]?.price;
    var amount = qty_value * price_value;
    var tax_amount = (amount * tax_value) / 100;
    var amount_with_tax = amount + tax_amount;
    newState[i] = {
      ...newState[i],
      "amount_total": "$ " + parseFloat(amount_with_tax),
      "tax": tax_value,
      "price": price_value
    };
    setEditpurchasedata(newState);
  };
  const checkstock_id = async (event, key, setqty, setsku) => {
    if (event.target.value && focusstockid !== event.target.value) {
      let main = [...editpurchasedata];
      main[key] = {
        ...main[key],
        stock_id: event.target.value,
      };
      setEditpurchasedata(main);
      try {
        const postdata = {
          stock_id: event.target.value,
          qty: qty ? qty : setqty,
          SKU: sku ? sku : setsku
        };
        let resstockid = await _Api(
          postdata,
          `api/v1/Inventory/purchase/checkStockID`
        );
        if (resstockid?.code === 200) {
          var inputs = [];
          var getstockids = document.querySelectorAll(".changestockids");
          for (var i = 0; i < getstockids.length; i++) {
            if (getstockids[i].value) {
              getstockids[i].style.border = " none";
              inputs.push(getstockids[i].value);
            }
          }
          var total_stock = inputs.length;
          let unique = [...new Set(inputs)].length;
          if (total_stock !== unique) {
            error(t("Please Check Stock Id !!"));
          } else {
            let main = [...editpurchasedata];
            main[key] = {
              ...main[key],
              'stock_id': event.target.value,
            };
            setEditpurchasedata(main);
          }
        } else {
          error(t(`StockID ${event.target.value} already exists !!`));
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const changeqty = (event, i) => {
    var main = [...editpurchasedata];
    let oldmain = [...oldeditpurchase];
    if (event.target.value <= oldmain[i].po_QTY) {
      const { value, name } = event.target;
      main[i] = {
        ...main[i],
        [name]: value,
      };
      setEditpurchasedata(main);
    } else {
      error(t("Enter Quantity less than") + oldmain[i].po_QTY);
    }
  };
  const splitrow = (key, id) => {
    var main_data = [...editpurchasedata];
    for (let i = 0; i < main_data[key]?.po_QTY - 1; i++) {
      main_data = [...main_data, main_data[key]];
    }
    for (let i = 0; i < main_data.length; i++) {
      if (main_data[i]?.id === id) {
        main_data[i] = {
          ...main_data[i],
          "po_QTY": 1,
          "is_split": 1,
          'stock_id': '',
          "class": "input-none",
          "id": main_data[i].id + "_" + i,
          "main_product_id": main_data[i].id,
          'amount_total': main_data[i].price * 1
        };
      }
    }
    setEditpurchasedata(main_data);
    UnSetdataTable('tbl_createPurchase_list');
    const timer = setTimeout(() => {
      SetdataTable("tbl_createPurchase_list", "6", newcolumndata, [1]);
    }, 200);
    return () => clearTimeout(timer);
  };
  const handler_submit = async () => {
    const handleValidation = (e) => {
      let formIsValid = true;
      if (!commanSelected_loc) {
        formIsValid = false;
        error(t("Location is Requried!"));
      }
      if (!commanSelected_sup) {
        formIsValid = false;
        error(t("Supplier is Requried!"));
      }
      if (!commanSelected_warehouse) {
        formIsValid = false;
        error(t("Warehouse is Requried!"));
      }
      if (!DeliveryDate) {
        formIsValid = false;
        error(t("DeliveryDate is Requried!"));
      }
      if (!TransactionDate) {
        formIsValid = false;
        error(t("TransactionDate is Requried!"));
      }

      var stockcheck = document.querySelectorAll(".changestockids");
      for (var i = 0; i < stockcheck.length; i++) {
        if (!stockcheck[i].value) {
          formIsValid = false;
          stockcheck[i].style.border = "1px solid red ";
        }
      }
      var allcheck = document.querySelectorAll(".editpurchasedata");
      for (var j = 0; j < allcheck.length; j++) {
        let qty = allcheck[j].getAttribute('data-qty');
        if (parseInt(qty) === 0) {
          let qtycheck = document.querySelectorAll(".purchaseqty");
          qtycheck[j].style.border = "1px solid red ";
          formIsValid = false;
        } else {
          let qtycheck = document.querySelectorAll(".purchaseqty");
          qtycheck[j].style.border = "1px solid #f4f4f4 ";
        }
      }
      return formIsValid;
    };
    if (handleValidation()) {
      var array = {
        purchase_data: [],
        subtotal: "",
        purchasetotal: "",
      };
      array["subtotal"] = toFormatPrice(
        editpurchasedata
          ? editpurchasedata?.reduce(
            (interation, val) =>
              (interation = interation + toUnformatPrice(val?.amount_total)),
            0
          )
          : ""
      );
      var total = document.querySelector(".total_purchase_amount");
      array["purchasetotal"] = total.innerText;
      var product_data = [];
      var new_product = [];
      editpurchasedata?.forEach((result, key) => {
        var product_data_obj = {
          stock_id: result?.stock_id,
          quantity: result?.po_QTY,
          tax_percentage: result?.tax ? result?.tax : 0,
          price: result?.price,
          is_split: result?.is_split ? result?.is_split : 0,
          main_po_product_id: result?.is_split
            ? result?.main_product_id
            : result?.id,
          Stones: result?.Stones,
          PriceDetails: result?.PriceDetails,
          GrossWt: result?.GrossWt,
          extra_po_information: result?.extra_po_information,
          NetWt: result?.NetWt
        };
        if (result.product_new === true) {
          new_product.push(product_data_obj);
        } else {
          product_data.push(product_data_obj);
        }

      });
      try {
        const postdata = {
          voucher_id: commanSelected_vouc,
          location_id: commanSelected_loc,
          supplier_id: commanSelected_sup,
          warehouse_id: commanSelected_warehouse,
          delivery_date: DeliveryDate,
          transaction_date: TransactionDate,
          shipping_handling_amount: Shipping ? Shipping : 0,
          note: Form_note,
          pu_order_id: product_id,
          tax_amount: tax ? tax : 0,
          subtotal_amount: array["subtotal"],
          total_amount: array["purchasetotal"],
          purchase_datas: product_data,
          new_purchase_datas: new_product,
          deleted_pu_datas: deletearray,
        };
        let resPoOrders = await _Api(postdata, `api/v1/Inventory/purchase/editPU`);
        if (resPoOrders.code === 200) {
          success(t("Edited successfully!!"));
          Getpurchaseproduct();
        } else {
          error(t("Something Went Wrong"));
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };
  const select_pu_status = (event) => {
    setStatement("Change Status");
    setEditpusstatus(event?.value);
    document.querySelector("#status_query_select").click();
  };
  const handler_status = async () => {
    try {
      const postdata = {
        pu_order_id: product_id,
        status: editpusstatus,
      };
      let statusdata = await _Api(
        postdata,
        `api/v1/Inventory/purchase/editPUStatus`
      );
      if (statusdata?.code === 200) {
        success(t(statusdata?.message));
        Getpurchaseproduct();
        setpurchasestatusdata(editpusstatus);
      } else {
        error(t(statusdata?.message));
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    callcolumns();
  }, []);

  useEffect(() => {
    Getpurchaseproduct();
    // eslint-disable-next-line
  }, []);

  const editproduct_submit = () => {
    var mainarray = [...editpurchasedata];
    mainarray[oneproductindex] = Productmodaldata;
    setEditpurchasedata(mainarray);
  }
  const handleTextareaChange = (e, index) => {
    const { value } = e.target;
    setEditpurchasedata((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = {
        ...updatedArray[index],
        stock_id: value,
      };
      return updatedArray;
    });
  };
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link
                    to={"/app/inventory/transactionPurchase"}
                    className="page-back-btn"
                  >
                    <img
                      src={base_assets + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </Link>
                  <img
                    src={base_assets + "images/icons/home-icon.png"}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Purchase"}
                    voucherid={Voucher_id}
                    addclass="inactive"
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center danger-text">{t("Edit Purchase")}</h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills">
                  <button className="main-body-top-tab-bar-left-col-a nav-link active table-refresh">
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        src={
                          base_assets +
                          "images/icons/outstanding-green-icon.png"
                        }
                        alt=""
                      />
                      {t("Purchase Order Outstanding")}
                    </span>
                  </button>
                </div>
                <div className={"dropdown status-dropdown status-dropdown-select " + po_status_class_val}>
                  <Select
                    options={parmissiondatashow?.inventory_transaction_purchase?.Status ? colorsArray : []}
                    value={valueofstatus}
                    isDisabled={purchasestatusdata === 2 ? false : true}
                    onChange={(e) => select_pu_status(e)}
                    classNamePrefix="status-dropdown-select"
                    styles={colorStyles}
                    getOptionLabel={(e) => (
                      <div className="">
                        {e.label}
                      </div>
                    )}
                    isSearchable={false}
                  />

                </div>
              </div>
              <div className="main-body-top-inputs-bar">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">{pu_no ? pu_no : "PU-XXX"}</label>
                  <LocationList
                    setcommanSelected_loc={setcommanSelected_loc}
                    locationid={location_id}
                    addclass={'row-inactive'}
                  />
                  <SupplierList
                    setcommanSelected_sup={setcommanSelected_sup}
                    supplierid={supplier_id}
                  />

                  <WarehouseList
                    setcommanSelected_warehouse={setcommanSelected_warehouse}
                    warehouseid={warehouse_id}
                  />
                  <TransAndDeliverDate TransactionDate={TransactionDate} DeliveryDate={DeliveryDate} setTransactionDate={setTransactionDate} setDeliveryDate={setDeliveryDate} />
                </div>

                <div className="main-body-top-inputs-bar-col-right">
                  <Link
                    to="/app/inventory/editpurchaseOrdersOutstanding"
                    state={{
                      location_id: location_id, product_id: product_id,
                      supplier_id: supplier_id,
                      transactiondate: transactiondate,
                      deliverydate: deliverydate,
                      warehouse_id: warehouse_id,
                      purchasestatus: purchasestatus,
                      pu_no: pu_no,
                      Voucher_id: Voucher_id


                    }}
                    className="main-body-top-tab-bar-right-col-a puoutstanding-text"
                  >
                    <img
                      src={base_assets + "images/icons/outstanding-icon.png"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("PO Outstanding")}
                    </span>
                  </Link>
                  {
                    purchasestatus === 2 ?
                      <button
                        className="main-body-top-inputs-bar-col-right-submit-btn com-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#createPurchaseModal"
                        onClick={() => setStatement("Save the Edited Item")}
                      >
                        {t("save")}
                      </button>
                      : ''
                  }
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); Getpurchaseproduct(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("PO No./Supplier/Ship to ...")}
                    onKeyPress={(e) => e.key === "Enter" && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <button className="print-export-dropdown-selected-icon">
                    <img
                      className={
                        selecteditem.length > 0
                          ? "deletebtn d-block"
                          : "deletebtn d-none"
                      }
                      src={base_assets + "images/icons/delete-icon.png"}
                      onClick={() => deleteitem()}
                      alt=""
                    />
                  </button>
                  <div
                    className="main-body-top-status-bar-note-button"
                    data-bs-toggle="modal"
                    data-bs-target="#notesModal"
                  >
                    <img
                      src={base_url + "images/icons/account-icon.png"}
                      alt=""
                    />
                    {t("Accounting")}
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li onClick={() => {
                        if (printurl) {
                          window.open(printurl, '_blank', 'noreferrer')
                        }
                      }}
                        className={printparmission ? "dropdown-item print-dropdown-item" : "d-none"}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>
                      <li className="dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li className="dropdown-item" >
                        <div className="dropdown-item-img">
                          <img src={base_assets + "images/icons/import-icon.png"} alt="" />
                        </div>
                        <span>{t("import")}</span>
                      </li>
                      <li className="dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/share-icon.png"}
                            alt=""
                          />
                        </div>
                        <span data-bs-toggle="modal" data-bs-target="#socialModal">{t("share")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="tbl_createPurchase_list"
                    className="common-table first-row-gray w-100"
                  >

                    <Tablehead
                      tablehead={Editpurchasetablehead}
                      tblName={"tbl_edit_purchase"}
                      setfilterby={""}
                      setShowoption={setSelectAllStatus}
                    />

                    <tbody>
                      {editpurchasedata
                        ? editpurchasedata.map((result, key) => {
                          let locationwithprice = result?.PriceDetails.filter((item) => item.location === location_id);

                          return (
                            <tr key={key}>
                              {result?.pos?.order_type === "repair_order" && result?.pos?.stock_id ?
                                <td className="col-highlight">
                                  <div className={"com-check"}>

                                    <input
                                      value={result?.pos?.stock_id}
                                      className="createPurchase_select editpurchasedata puitems purchaseqty editPurchase_select"
                                      id={'pu_edit_' + result.id}
                                      data-qty={result?.po_QTY}
                                      product-id={result?.po_order_id}
                                      data-id={result?.id}
                                      onClick={(e) => { setQty(result?.po_QTY); setSku(result?.SKU) }}
                                      type="checkbox"
                                    />
                                    <label
                                      htmlFor={'pu_edit_' + result.id}
                                      className="com-check-label"
                                    />
                                  </div>
                                </td>
                                :
                                <td className="col-highlight">
                                  {result?.stock_id === "" ? <div className={"com-check"}>
                                    <input
                                      value={result?.id}
                                      className="createPurchase_select editpurchasedata puitems editPurchase_select"
                                      id={'pu_edit_' + result.id}
                                      data-qty={result?.po_QTY}
                                      product-id={result?.po_order_id}
                                      data-id={result?.id}
                                      type={result?.stock_id === "" ? "" : "checkbox"}
                                      onClick={(e) => { error("Enter stock id"); }}
                                    />
                                    <label
                                      htmlFor={'pu_edit_' + result.id}
                                      className="com-check-label"
                                    />
                                  </div> :
                                    <div className="com-check">
                                      <input
                                        value={result?.id}
                                        className="createPurchase_select editpurchasedata puitems editPurchase_select editPurchase_select"
                                        id={'pu_edit_' + result.id}
                                        data-qty={result?.po_QTY}
                                        data-id={result?.id}
                                        product-id={result?.po_order_id}
                                        type="checkbox"
                                        onClick={(e) => { deleteIcon(e); }}
                                        readOnly
                                      />
                                      <label
                                        htmlFor={'pu_edit_' + result.id}
                                        className="com-check-label"
                                      />
                                    </div>}
                                </td>}
                              <td className="col-highlight">
                                {key + 1} </td>
                              <td className="col-highlight">
                                <div className="table-pro-img">
                                  <img
                                    src={
                                      result?.main_image
                                        ? result?.main_image
                                        : ""
                                    }
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td className="po-no col-highlight">
                                {result?.name ? result?.name : "-"}
                              </td>
                              <td className="col-highlight link-color cursor-pointer" data-bs-toggle="modal" onClick={() => { setProductmodaldata(result); setoneproductindex(key) }} data-bs-target="#ProductInfoModal" >
                                {result?.SKU ? result?.SKU : ""}
                              </td>
                              <td className="col-highlight">
                                {result?.pos?.order_type === "repair_order" && result?.pos?.stock_id ?
                                  result?.pos?.stock_id
                                  :
                                  <div className="col-input ">
                                    {result?.is_split === 1 ?
                                      <textarea
                                        id="stockidcheck"
                                        className="changestockids stock-field"
                                        type="text"
                                        name="stockid"
                                        onBlur={(e) => { checkstock_id(e, key) }}
                                        onClick={() => { setQty(result?.po_QTY); setSku(result?.SKU) }}
                                        onFocus={() => setFocusstockid(result?.stock_id)}
                                        value={result?.stock_id}
                                        onChange={(e) => handleTextareaChange(e, key)}
                                      >
                                      </textarea> :
                                      <input
                                        id="stockidcheck"
                                        className="changestockids stock-field"
                                        type="text"
                                        name="stockid"
                                        onBlur={(e) => { checkstock_id(e, key) }}
                                        onClick={() => { setQty(result?.po_QTY); setSku(result?.SKU) }}
                                        onFocus={() => setFocusstockid(result?.stock_id)}
                                        value={result?.stock_id}
                                        onChange={(e) => handleTextareaChange(e, key)}
                                      />
                                    }
                                  </div>
                                }
                              </td>
                              <td>
                                {result?.metal_name
                                  ? result?.metal_name
                                  : "-"}
                              </td>
                              <td>
                                {result?.stone_name
                                  ? result?.stone_name
                                  : "-"}
                              </td>
                              <td>
                                {result?.size_name ? result?.size_name : "-"}
                              </td>
                              <td>
                                {" "}
                                <div className="col-input">
                                  <input
                                    type="text"
                                    name="price"
                                    onKeyUp={(e) =>
                                      calculation_purchase_tax(e, key)
                                    }
                                    defaultValue={result?.price}
                                  />
                                </div>
                              </td>
                              <td>
                                <img
                                  className={
                                    result?.po_QTY <= 1 ? "d-none" : "d-block"
                                  }
                                  src={base_assets + "images/icons/split.png"}
                                  alt=""
                                  onClick={() => splitrow(key, result?.id)}
                                />
                              </td>
                              <td>
                                <div className="col-input">
                                  <input
                                    type="text"
                                    className={
                                      result?.class ? "purchaseqty input-none" : "purchaseqty"
                                    }
                                    id="qtycheck"
                                    name="po_QTY"
                                    value={result?.po_QTY}
                                    onChange={(e) => { changeqty(e, key); setQty(e, key); }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-input">
                                  <input
                                    type="text"
                                    onKeyUp={(e) =>
                                      calculation_purchase_tax(e, key)
                                    }
                                    name="tax"
                                    defaultValue="0"
                                  />
                                </div>
                              </td>
                              <td>{locationwithprice.length ? toFormatPrice(locationwithprice[0].retailprice_Inc, { addSymbol: true }, locationwithprice[0].currency) : ''}</td>
                              <td>
                                {result?.amount_total
                                  ? toFormatPrice(result?.amount_total, { addSymbol: true })
                                  : "0.00"}
                              </td>
                              <td>{result?.pos_no ? result?.pos_no : "-"}</td>

                              <td>
                                {result?.customer_name
                                  ? result?.customer_name
                                  : "-"}
                              </td>
                              <td>
                                {result?.delivery_date
                                  ? result?.delivery_date
                                  : "-"}
                              </td>
                              <td>
                                {result?.due_days ? result?.due_days : "-"}
                              </td>
                              <td>
                                {result?.location_name
                                  ? result?.location_name
                                  : "-"}
                              </td>
                              <td>{result?.remark ? result?.remark : "-"}</td>
                              <td className="text-center col-highlight">
                                {result?.pos?.url ?
                                  <div onClick={() => {
                                    if (result?.pos?.url) {
                                      window.open(result?.pos?.url, '_blank', 'noreferrer')
                                    }
                                  }}><img
                                      className="pdf-image"
                                      src={base_assets + "images/icons/pdf-icon.png"}
                                      alt=""
                                    />
                                  </div>
                                  : "-"
                                }
                              </td>
                            </tr>
                          );
                        })
                        : ""}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="main-green-text left-0">
                          {selecteditem.length > 0 ? (
                            <span> {selecteditem.length} {t("item selected")}</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td></td>
                        <td />
                        <td />
                        <td></td>
                        <td>
                          {editpurchasedata
                            ? editpurchasedata?.reduce(
                              (interation, val) =>
                              (interation =
                                interation + parseInt(val.po_QTY)),
                              0
                            )
                            : ""}
                        </td>
                        <td />
                        <td />
                        <td>
                          {toFormatPrice(
                            editpurchasedata
                              ? editpurchasedata?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  toUnformatPrice(val?.amount_total)),
                                0
                              )
                              : ""
                            , { addSymbol: true })}
                        </td>
                        <td></td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
        <ProductModal data_product={Productmodaldata} setOneproduct={setProductmodaldata} editproduct_submit={editproduct_submit} />
      </div>
      <TableColumnModal
        tablehead={Editpurchasetablehead}
        tblName={"tbl_edit_purchase"}
        calldatatable={Getpurchaseproduct}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <NotesModalAccounting
        setForm_note={setForm_note}
        setTax={setTax}
        setShipping={setShipping}
        data_product={Accountingarray}
        settotalamount={toFormatPrice(
          editpurchasedata
            ? editpurchasedata?.reduce(
              (interation, val) =>
              (interation =
                interation + toUnformatPrice(val?.amount_total)),
              0
            )
            : ""
        )}
      ></NotesModalAccounting>
      <ConfirmationModel
        statement={statement}
        handler_submit={statement === 'Change Status' ? handler_status : handler_submit}
        handler_dismiss={Getpurchaseproduct}
      ></ConfirmationModel>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#createPurchaseModal"
        id="status_query_select"
      ></button>
    </React.Fragment>
  );
};

export default Editpurchase;
