import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { _Api, Api_form } from '../api/_call';
import TableColumnModal from '../common/modules/tableColumn';
import { ImportExcelModal } from '../common/modules/importExcel';
import './diamond.scss';
import Slider from 'rc-slider';
import {
  success,
  error,
  loading,
  Updateloading,
} from '../common/helpers/toastify';
import 'rc-slider/assets/index.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import { formatDate } from '../common/helpers/formatDate';
import SocialModal from '../common/modules/SocialModal/SocialModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const DiamondSearch = (props) => {

  const { t } = useTranslation()
  const [importfile, setImportfile] = useState()
  const datePickerRef = useRef(null)
  const [startdate, setStartdate] = useState()
  const [enddate, setEnddate] = useState()
  const [daimondfilter, setDaimondfilters] = useState()
  const [cut, setCut] = useState([])
  const [clarity, setClarity] = useState([])
  const [stonecolor, setStonecolors] = useState([])
  const [polish, setPolish] = useState([])
  const [symmetry, setSymmetry] = useState([])
  const [fluorescence, setFluorescence] = useState([])
  const [datelable, setDatelable] = useState('Show All')
  const [lbs, setLbs] = useState([])
  const [Status, setStatus] = useState([])
  const [Location, setLocation] = useState([])
  const [shape, setShape] = useState([])
  const [comparisiondata, setComparisiondata] = useState('')
  const [exportdata, setExportdata] = useState('')
  const [items, setItems] = useState([])
  const [totalresult, setTotalresult] = useState()
  const [rowlength, setRowlength] = useState(0)
  const [val1, setVal1] = useState([47, 900])
  const [val2, setVal2] = useState([0.1, 4.0])
  const [val3, setVal3] = useState([45, 86])
  const [val4, setVal4] = useState([45, 86])
  const [FilterSubmitClass, setFilterSubmitClass] = useState('')
  const [socialurl, setSocialurl] = useState()
  const [shareloader, setShareloader] = useState(false)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const readExcel = async () => {
    const handleValidation = (e) => {
      let formIsValid = true
      if (!importfile) {
        formIsValid = false
        error(t('Choose file!'))
      }
      return formIsValid
    }
    if (handleValidation()) {
      try {
        const formData = new FormData()
        formData.append('file', importfile)
        formData.append('type', 'DiamondImport')
        let res = await Api_form(
          formData,
          `api/v1/Inventory/diamond/uploadDiamondxls`,
        )
        if (res.code === 200) {
          success(
            t("File Sucessfully Uploaded and Backend Process have  Started Now,Please wait!!")
          )
          daimondfilters()
        } else {
          error(t('Please Wait or try again later!!'))
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }

  const SampleExcel = async () => {
    try {
      const posdata = {}
      let res = await _Api(posdata, `api/v1/Inventory/diamond/DiamondSamplexls`)
      if (res.code === 200) {
        const exportLinkElement = document.createElement('a')

        exportLinkElement.hidden = true
        exportLinkElement.download = 'Daimond.xlsx'
        exportLinkElement.href = res.data
        exportLinkElement.text = 'downloading...'

        document.body.appendChild(exportLinkElement)
        exportLinkElement.click()
        exportLinkElement.remove()
      } else {
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const callfilters = (event, label) => {
    if (label === 'cut') {
      var elem = document.getElementById('cut' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setCut((current) => current.filter((item) => item !== event.target.id))
      } else {
        elem.classList.add('active')
        const cutdata = [...cut]
        cutdata.push(event.target.id)
        setCut(cutdata)
      }
    }
    if (label === 'clarity') {
      let elem = document.getElementById('clarity' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setClarity((current) =>
          current.filter((item) => item !== event.target.id),
        )
      } else {
        elem.classList.add('active')
        const claritydata = [...clarity]
        claritydata.push(event.target.id)
        setClarity(claritydata)
      }
    }
    if (label === 'stonecolor') {
      let elem = document.getElementById('stonecolor' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setStonecolors((current) =>
          current.filter((item) => item !== event.target.id),
        )
      } else {
        elem.classList.add('active')
        const stonecolordata = [...stonecolor]
        stonecolordata.push(event.target.id)
        setStonecolors(stonecolordata)
      }
    }

    if (label === 'polish') {
      let elem = document.getElementById('polish' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setPolish((current) =>
          current.filter((item) => item !== event.target.id),
        )
      } else {
        elem.classList.add('active')
        const polishdata = [...polish]
        polishdata.push(event.target.id)
        setPolish(polishdata)
      }
    }
    if (label === 'symmetry') {
      let elem = document.getElementById('symmetry' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setSymmetry((current) =>
          current.filter((item) => item !== event.target.id),
        )
      } else {
        elem.classList.add('active')
        const symmetrydata = [...symmetry]
        symmetrydata.push(event.target.id)
        setSymmetry(symmetrydata)
      }
    }
    if (label === 'fluorescence') {
      let elem = document.getElementById('fluorescence' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setFluorescence((current) =>
          current.filter((item) => item !== event.target.id),
        )
      } else {
        elem.classList.add('active')
        const fluorescencedata = [...fluorescence]
        fluorescencedata.push(event.target.id)
        setFluorescence(fluorescencedata)
      }
    }
    if (label === 'lbs') {
      let elem = document.getElementById('l' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setLbs((current) =>
          current.filter((item) => item !== event.target.value),
        )
      } else {
        elem.classList.add('active')
        const lbsdata = [...lbs]
        lbsdata.push(event.target.value)
        setLbs(lbsdata)
      }
    }
    if (label === 'status') {
      let elem = document.getElementById('status' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setStatus((current) =>
          current.filter((item) => item !== event.target.value),
        )
      } else {
        elem.classList.add('active')
        const statusdata = [...Status]
        statusdata.push(event.target.value)
        setStatus(statusdata)
      }
    }
    if (label === 'Location') {
      let elem = document.getElementById('Location' + event.target.id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setLocation((current) =>
          current.filter((item) => item !== event.target.id),
        )
      } else {
        elem.classList.add('active')
        const locationdata = [...Location]
        locationdata.push(event.target.id)
        setLocation(locationdata)
      }
    }
    if (label === 'shape') {
      const id = event.currentTarget.dataset.id
      const shapedata = [...shape]
      let elem = document.getElementById('shape' + id)
      if (elem.classList.contains('active')) {
        elem.classList.remove('active')
        setShape(shapedata.filter((n) => n !== id))
      } else {
        elem.classList.add('active')
        shapedata.push(id)
        setShape(shapedata)
      }
    }
    setFilterSubmitClass('btn-gliter')
  }
  const getsharediamondsearch = async () => {
    setShareloader(true)

    try {
      const postdata = {
        length: rowlength,
        limit: 10,
        skip: rowlength,
        CUT: cut,
        Clarity: clarity,
        Stonecolor: stonecolor,
        Polish: polish,
        Symmetry: symmetry,
        fluorescence: fluorescence,
        Lbs: lbs,
        status: Status,
        Location: Location,
        Shape: shape,
        Price: [{ min: val1[0], max: val1[1] }],
        Carat: [{ min: val2[0], max: val2[1] }],
        Depth: [{ min: val3[0], max: val3[1] }],
        Table: [{ min: val4[0], max: val4[1] }],
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/inventory/print/diamondSearch`,
      )

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getprintdiamondsearch = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        length: rowlength,
        limit: 10,
        skip: rowlength,
        CUT: cut,
        Clarity: clarity,
        Stonecolor: stonecolor,
        Polish: polish,
        Symmetry: symmetry,
        fluorescence: fluorescence,
        Lbs: lbs,
        status: Status,
        Location: Location,
        Shape: shape,
        Price: [{ min: val1[0], max: val1[1] }],
        Carat: [{ min: val2[0], max: val2[1] }],
        Depth: [{ min: val3[0], max: val3[1] }],
        Table: [{ min: val4[0], max: val4[1] }],
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/inventory/print/diamondSearch`,
      )

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const submitfilters = async () => {
    setTotalresult(0)
    const integerArray = Status.map(Number);
    try {
      const posdata = {
        length: 0,
        limit: 20,
        skip: 0,
        CUT: cut,
        Clarity: clarity,
        Stonecolor: stonecolor,
        Polish: polish,
        Symmetry: symmetry,
        fluorescence: fluorescence,
        Lbs: lbs,
        Status: integerArray,
        Location: Location,
        Shape: shape,
        Price: [{ min: val1[0], max: val1[1] }],
        Carat: [{ min: val2[0], max: val2[1] }],
        Depth: [{ min: val3[0], max: val3[1] }],
        Table: [{ min: val4[0], max: val4[1] }],

      }

      let res = await _Api(posdata, `api/v1/Inventory/diamond/getList`)
      if (res.code === 200) {
        setItems(res.data)
        setTotalresult(res.totalResult)
        setRowlength(0)
      }
    } catch (err) {
      console.log(err, 'err')
    }
    setFilterSubmitClass('')
  }
  const changeDates = (lable) => {
    const MainDate = new Date()
    if (lable === 'Today') {
      setStartdate(new Date())
      setEnddate(new Date())
      setDatelable('Today')
    } else if (lable === 'Yesterday') {
      const yesterdaydate = new Date(MainDate.setDate(MainDate.getDate() - 1))
      setStartdate(yesterdaydate)
      setEnddate(yesterdaydate)
      setDatelable('Yesterday')
    } else if (lable === '7days') {
      const sevendays = new Date(MainDate.setDate(MainDate.getDate() - 7))
      setStartdate(sevendays)
      setEnddate(new Date())
      setDatelable('7days')
    } else if (lable === '30days') {
      const days = new Date(MainDate.setDate(MainDate.getDate() - 30))
      setStartdate(days)
      setEnddate(new Date())
      setDatelable('30days')
    } else if (lable === 'ThisMonth') {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 1)
      const lastDay = new Date(
        MainDate.getFullYear(),
        MainDate.getMonth() + 1,
        0,
      )
      setStartdate(firstDay)
      setEnddate(lastDay)
      setDatelable('ThisMonth')
    } else if (lable === 'LastMonth') {
      const firstDay = new Date(MainDate.getFullYear(), MainDate.getMonth() - 1, 1)
      const lastDay = new Date(MainDate.getFullYear(), MainDate.getMonth(), 0)
      setStartdate(firstDay)
      setEnddate(lastDay)
      setDatelable('LastMonth')
    }
  }
  const callexportrequest = async () => {
    try {
      const posdata = { type: 'DiamondExport' }
      let res = await _Api(posdata, `api/v1/Inventory/diamond/downloadDiamondxls`)
      if (res.code === 200) {
        callexportlist()
        success(t('Sucessfully File Exported Request Sent!!'))
      }
    } catch (err) {
      error(t('Please Wait or try again later!!'))
      console.log(err, 'err')
    }
  }
  const callexportlist = async () => {
    try {
      const posdata = { type: 'DiamondExport' }
      let res = await _Api(posdata, `api/v1/Inventory/diamond/getExportList`)
      if (res.code === 200) {
        setExportdata(res.data)
        success(t(res?.message))
      } else {
        error(res?.errors)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const daimondfilters = async (lable) => {
    try {
      const posdata = {}
      let res = await _Api(posdata, `api/v1/Inventory/diamond/getFilters`)
      if (res.code === 200) {
        let shapes = [],
          colors = [],
          cuts = [],
          polishs = [],
          claritys = [],
          symmetrys = [],
          fluorescences = [],
          lbss = [],
          status = [],
          locations = [],
          Price = [],
          Carat = [],
          Depth = [],
          Table = []
        setCut([])
        setShape([])
        setStonecolors([])
        setClarity([])
        setPolish([])
        setSymmetry([])
        setFluorescence([])
        setLbs([])
        setStatus([])
        setLocation([])
        setDaimondfilters(res.data)
        if (res.data?.Shape) {
          res.data?.Shape.forEach((result) => {
            setShape((shape) => shape.concat(result?._id))
            shapes.push(result?._id)
          })
        }
        if (res.data?.StoneColor) {
          res.data?.StoneColor.forEach((result) => {
            setStonecolors((stonecolor) => stonecolor.concat(result?._id))
            colors.push(result?._id)
          })
        }
        if (res.data?.Clarity) {
          res.data?.Clarity.forEach((result) => {
            setClarity((clarity) => clarity.concat(result?._id))
            claritys.push(result?._id)
          })
        }
        if (res.data?.Cut) {
          res.data?.Cut.forEach((result) => {
            setCut((cut) => cut.concat(result?._id))
            cuts.push(result?._id)
          })
        }
        if (res.data?.Polish) {
          res.data?.Polish.forEach((result) => {
            setPolish((polish) => polish.concat(result?._id))
            polishs.push(result?._id)
          })
        }
        if (res.data?.Symmetry) {
          res.data?.Symmetry.forEach((result) => {
            setSymmetry((symmetry) => symmetry.concat(result?._id))
            symmetrys.push(result?._id)
          })
        }
        if (res.data?.Fluorescence) {
          res.data?.Fluorescence.forEach((result) => {
            setFluorescence((fluorescence) =>
              fluorescence.concat(result?._id),
            )
            fluorescences.push(result?._id)
          })
        }
        if (res.data?.Lbs) {
          res.data?.Lbs.forEach((result) => {
            setLbs((lbs) => lbs.concat(result))
            lbss.push(result)
          })
        }
        if (res.data?.Status) {
          res.data?.Status.forEach((result) => {
            setStatus((status) => status.concat(result))
            status.push(result)
          })
        }
        if (res.data?.Location) {
          res.data?.Location.forEach((result) => {
            setLocation((location) => location.concat(result?._id))
            locations.push(result?._id)
          })
        }
        setVal1([res.data?.Price?.min, res.data?.Price?.max])
        setVal2([res.data?.Carat?.min, res.data?.Carat?.max])
        setVal3([res.data?.Depth?.min, res.data?.Depth?.max])
        setVal4([res.data?.Table?.min, res.data?.Table?.max])
        Price.push({ min: res.data?.Price?.min, max: res.data?.Price?.max })
        Carat.push({ min: res.data?.Carat?.min, max: res.data?.Carat?.max })
        Depth.push({ min: res.data?.Depth?.min, max: res.data?.Depth?.max })
        Table.push({ min: res.data?.Table?.min, max: res.data?.Table?.max })
        if (lable === 'reset') {
          const reshape = document.querySelectorAll('.resetshape')
          for (const x of reshape) {
            x.classList.add('active')
          }
          const recolor = document.querySelectorAll('.resetcolor')
          for (const x of recolor) {
            x.classList.add('active')
          }
          const reclarity = document.querySelectorAll('.resetclarity')
          for (const x of reclarity) {
            x.classList.add('active')
          }
          const recut = document.querySelectorAll('.resetcut')
          for (const x of recut) {
            x.classList.add('active')
          }
          const repolish = document.querySelectorAll('.resetpolish')
          for (const x of repolish) {
            x.classList.add('active')
          }
          const resymmetry = document.querySelectorAll('.resetsymmetry')
          for (const x of resymmetry) {
            x.classList.add('active')
          }
          const refluorescence = document.querySelectorAll('.resetfluorescence')
          for (const x of refluorescence) {
            x.classList.add('active')
          }
          const relab = document.querySelectorAll('.resetlab')
          for (const x of relab) {
            x.classList.add('active')
          }
          const restatus = document.querySelectorAll('.resetstatus')
          for (const x of restatus) {
            x.classList.add('active')
          }
          const relocation = document.querySelectorAll('.resetLocation')
          for (const x of relocation) {
            x.classList.add('active')
          }
          setRowlength(0)
          setFilterSubmitClass('')
        }
        setTimeout(async () => {
          try {
            const posdata = {
              length: 0,
              limit: 10,
              skip: 0,
              CUT: cuts,
              Clarity: claritys,
              Stonecolor: colors,
              Polish: polishs,
              Symmetry: symmetrys,
              fluorescence: fluorescences,
              Lbs: lbss,
              Status: status,
              Location: locations,
              Shape: shapes,
              Price: Price,
              Carat: Carat,
              Depth: Depth,
              Table: Table,
              start_date: startdate ? startdate : new Date(),
              end_date: enddate ? enddate : new Date(),
            }
            let res = await _Api(posdata, `api/v1/Inventory/diamond/getList`)
            if (res.code === 200) {
              setItems(res.data)
              setTotalresult(res.totalResult)
              setDatelable('Show All')
            }
          } catch (err) {
            console.log(err, 'err')
          }
        }, 1000)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const fetchMoreData = () => {
    var len = rowlength + 10
    setRowlength(len)
    const integerArray = Status.map(Number);
    setTimeout(async () => {
      try {
        const posdata = {
          length: len,
          limit: 10,
          skip: len,
          CUT: cut,
          Clarity: clarity,
          Stonecolor: stonecolor,
          Polish: polish,
          Symmetry: symmetry,
          fluorescence: fluorescence,
          Lbs: lbs,
          Status: integerArray,
          Location: Location,
          Shape: shape,
          Price: [{ min: val1[0], max: val1[1] }],
          Carat: [{ min: val2[0], max: val2[1] }],
          Depth: [{ min: val3[0], max: val3[1] }],
          Table: [{ min: val4[0], max: val4[1] }],
        }
        let res = await _Api(posdata, `api/v1/Inventory/diamond/getList`)
        if (res.code === 200) {
          setItems(items.concat(res.data))
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }, 1200)
  }

  const callcomparision = (event) => {
    const data = [...comparisiondata]
    var newState = [...items]
    if (event.target.checked) {
      newState
        .filter((itemdata) => itemdata._id === event.target.id)
        .forEach((result) => {
          data.push(result)
        })
      setComparisiondata(data)
    } else {
      setComparisiondata(
        comparisiondata.filter((v) => v._id !== event.target.id),
      )
    }
  }
  const deletecomparision = (id) => {
    var checkboxitem = document.querySelector('[data-id="d' + id + '"]')
    if (checkboxitem.checked) {
      checkboxitem.checked = false
    }
    setComparisiondata(comparisiondata.filter((v) => v._id !== id))
  }
  useEffect(() => {
    daimondfilters()
    // eslint-disable-next-line
  }, [startdate, enddate])

  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="diamond-search-heading">
                <h1 className="main-body-heading">{t('Diamond Search')}</h1>
                <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={base_assets + 'images/icons/ellipsis-circular.png'}
                      alt=""
                    />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li
                      className={parmissiondatashow?.inventory_diamond_search?.Print ? "dropdown-item print-dropdown-item" : "d-none"}
                      onClick={(e) => {
                        getprintdiamondsearch(e.target.value)
                      }}
                    >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/printer-icon.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('print')}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={callexportlist}
                      data-bs-toggle="modal"
                      data-bs-target="#ExportModal"
                    >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/export-x-icon.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('export')}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#ImportModal"
                    >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/import-icon.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('import')}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#socialModal"
                      onClick={() => getsharediamondsearch()}
                    >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/share-icon.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('share')}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#columnModal"
                    >
                      <div className="dropdown-item-img">
                        <img
                          src={base_assets + 'images/icons/column-icon.png'}
                          alt=""
                        />
                      </div>
                      <span>{t('column')}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <ImportExcelModal
                setImportfile={setImportfile}
                readExcel={readExcel}
                SampleExcel={SampleExcel}
                importfile={importfile}
              />

              <div
                className="modal fade export-modal"
                id="ExportModal"
                tabIndex={-1}
                aria-labelledby="ExportModalLabel"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{t('Export')}</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <button
                        type="button"
                        onClick={callexportlist}
                        className="btn float-end"
                      >
                        {t('Run')}
                      </button>
                      <button
                        type="button"
                        onClick={callexportrequest}
                        className="btn float-end"
                      >
                        {t('Export Request')}
                      </button>
                      <div className="main-body-main-table-wrap mt-5">
                        <table
                          className="common-table first-row-gray w-100"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{t('User')}</th>
                              <th>{t('Request Date')}</th>
                              <th>{t('Generate Date')}</th>
                              <th>{t('Action')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {exportdata?.length
                                ? exportdata.map((result, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>
                                        {result?.userName
                                          ? result?.userName
                                          : ''}
                                      </td>
                                      <td>
                                        {result?.createdAt
                                          ? result?.createdAt
                                          : ''}
                                      </td>
                                      <td>
                                        {result?.updatedAt
                                          ? result?.updatedAt
                                          : ''}
                                      </td>
                                      <td>
                                        {result?.status === 1 ? (
                                          <span>{t('Pending')}</span>
                                        ) : result?.status === 2 ? (
                                          <span>{t('Processing')}</span>
                                        ) : (
                                          <a
                                            href={
                                              result?.filekey
                                                ? result?.filekey
                                                : ''
                                            }
                                          >
                                            <img
                                              className="pdf-image"
                                              src={
                                                base_assets +
                                                'images/icons/pdf-icon.png'
                                              }
                                              alt=""
                                            />
                                          </a>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })
                                : <tr>
                                  <td>{'No data Found'}</td></tr>}
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="diamond-search-wrap">
                <div className="diamond-search-wrap-width">
                  <div
                    className="accordion diamond-search-accordian"
                    id="accordionDiamond"
                  >
                    <div className="diamond-search-accordian-tab">
                      <button
                        className="diamond-search-wrap-row diamond-search-accordian-tab-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#diamondSearchTab"
                        aria-expanded="true"
                        aria-controls="diamondSearchTab"
                      >
                        <span>
                          <img
                            src={base_assets + 'images/icons/down-arrow.png'}
                            alt=""
                          />
                          {t('Diamond Search')}
                        </span>
                      </button>


                      <div className='main-body-top-status-bar-note-button common-dropdown replenish-date d-flex'>
                        <button
                          className="dropdown-toggle btn-style align-items-center d-flex diamond-search-accordian-tab-reset"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >

                          {t(datelable)}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li
                            className={`dropdown-item ${datelable === 'Today' || datelable === 'Show All'
                              ? 'active'
                              : ''
                              }`}
                            onClick={() => changeDates('Today')}
                          >
                            {t('Today')}
                          </li>
                          <li
                            className={`dropdown-item ${datelable === 'Yesterday' ? 'active' : ''
                              }`}
                            onClick={() => changeDates('Yesterday')}
                          >
                            {t('Yesterday')}
                          </li>
                          <li
                            className={`dropdown-item ${datelable === '7days' ? 'active' : ''
                              }`}
                            onClick={() => changeDates('7days')}
                          >
                            {t('Last 7 days')}
                          </li>
                          <li
                            className={`dropdown-item ${datelable === '30days' ? 'active' : ''
                              }`}
                            onClick={() => changeDates('30days')}
                          >
                            {t('Last 30 days')}
                          </li>
                          <li
                            className={`dropdown-item ${datelable === 'ThisMonth' ? 'active' : ''
                              }`}
                            onClick={() => changeDates('ThisMonth')}
                          >
                            {t('This Month')}
                          </li>
                          <li
                            className={`dropdown-item ${datelable === 'LastMonth' ? 'active' : ''
                              }`}
                            onClick={() => changeDates('LastMonth')}
                          >
                            {t('Last Month')}
                          </li>
                          <li
                            className={`dropdown-item ${datelable === 'Custom Range' ? 'active' : ''
                              }`}
                            onClick={() => datePickerRef.current.setOpen(true)}
                          >
                            {t('Custom Range')}
                          </li>
                        </ul>
                        <DatePicker
                          selectsRange={true}
                          monthsShown={2}
                          startDate={startdate}
                          endDate={enddate}
                          onChange={(update) => {
                            setStartdate(update[0])
                            setEnddate(update[1])
                            setDatelable('Custom Range')
                          }}
                          dateFormat="yyyy-MM-dd"
                          ref={datePickerRef}
                          autoComplete='none'
                        />

                        <button
                          onClick={() => {
                            daimondfilters('reset')
                          }}
                          className="diamond-search-accordian-tab-reset"
                        >
                          <img
                            src={base_assets + 'images/icons/reset.png'}
                            alt=""
                          />
                          <span>{t('reset filter')}</span>
                        </button>
                      </div>
                    </div>
                    <div
                      id="diamondSearchTab"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionDiamond"
                    >
                      <div className="diamond-search-wrap-row">
                        <div className="diamond-search-wrap-row-col col">
                          <h6 className="diamond-search-wrap-row-col-heading">
                            {t('Shape')}
                          </h6>
                          <div className="diamond-search-wrap-row-col-diamond-row">
                            {daimondfilter?.Shape
                              ? daimondfilter?.Shape.map((result, key) => {
                                return (
                                  <div
                                    key={key}
                                    id={'shape' + result?._id}
                                    data-id={result?._id}
                                    onClick={(e) => callfilters(e, 'shape')}
                                    className="diamond-search-wrap-row-col-diamond-row-single active resetshape"
                                  >
                                    <div className="diamond-search-wrap-row-col-diamond-row-single-img">
                                      <img src={result?.logo} alt="" />
                                    </div>
                                    <p className="diamond-search-wrap-row-col-diamond-row-single-title">
                                      {result?.name}
                                    </p>
                                  </div>
                                )
                              })
                              : ''}
                          </div>
                        </div>
                        <div className="diamond-search-wrap-row-col col">
                          <div className="diamond-search-wrap-row-col-ct">
                            <h6 className="diamond-search-wrap-row-col-heading">
                              {t('Price')}
                            </h6>
                            <div className="range-area">
                              <Slider
                                range
                                min={daimondfilter?.Price?.min}
                                max={daimondfilter?.Price?.max}
                                value={val1}
                                onChange={setVal1}
                                step={0.1}
                              />
                              <div className="range-area-num">
                                <div className="range-area-num-single">
                                  <span className="range-area-num-value">
                                    {daimondfilter?.currency}
                                  </span>
                                  <span className="range-area-num-unit">
                                    {val1[0]}
                                  </span>
                                </div>
                                <div className="range-area-num-single">
                                  <span className="range-area-num-value">
                                    {daimondfilter?.currency}
                                  </span>
                                  <span className="range-area-num-unit">
                                    {val1[1]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="diamond-search-wrap-row-col-ct">
                            <h6 className="diamond-search-wrap-row-col-heading">
                              {t('Carat')}
                            </h6>
                            <div className="range-area">
                              <Slider
                                range
                                min={daimondfilter?.Carat?.min}
                                max={daimondfilter?.Carat?.max}
                                value={val2}
                                onChange={setVal2}
                                step={0.1}
                              />
                              <div className="range-area-num">
                                <div className="range-area-num-single">
                                  <span className="range-area-num-value">

                                    {val2[0]}
                                  </span>
                                  <span className="range-area-num-unit">
                                    {t('Ct')}
                                  </span>
                                </div>
                                <div className="range-area-num-single">
                                  <span className="range-area-num-value">

                                    {val2[1]}
                                  </span>
                                  <span className="range-area-num-unit">
                                    {t('Ct')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="diamond-search-wrap-row-col col">
                          <div className="diamond-search-wrap-row-col-ct">
                            <h6 className="diamond-search-wrap-row-col-heading">
                              {t('Colour')}
                            </h6>
                            <div className="diamond-tab-row row m-0">
                              {daimondfilter?.StoneColor
                                ? daimondfilter?.StoneColor.map(
                                  (result, key) => {
                                    return (
                                      <React.Fragment key={key}>
                                        <input
                                          key={key}
                                          id={result?._id}
                                          value={result?.code}
                                          type="checkbox"
                                          onClick={(e) =>
                                            callfilters(e, 'stonecolor')
                                          }
                                        />
                                        <label
                                          htmlFor={result?._id}
                                          id={'stonecolor' + result?._id}
                                          className="diamond-tab-row-tab  active resetcolor"
                                        >
                                          {result?.code}
                                        </label>
                                      </React.Fragment>
                                    )
                                  },
                                )
                                : ''}
                            </div>
                          </div>
                          <div className="diamond-search-wrap-row-col-ct">
                            <h6 className="diamond-search-wrap-row-col-heading">
                              {t('clarity')}
                            </h6>
                            <div className="diamond-tab-row row m-0">
                              {daimondfilter?.Clarity
                                ? daimondfilter?.Clarity.map((result, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <input
                                        key={key}
                                        id={result?._id}
                                        value={result?.code}
                                        type="checkbox"
                                        onClick={(e) =>
                                          callfilters(e, 'clarity')
                                        }
                                      />
                                      <label
                                        htmlFor={result?._id}
                                        id={'clarity' + result?._id}
                                        className="diamond-tab-row-tab   active resetclarity"
                                      >
                                        {result?.code}
                                      </label>
                                    </React.Fragment>
                                  )
                                })
                                : ''}
                            </div>
                          </div>
                          <div className="diamond-search-wrap-row-col-ct">
                            <h6 className="diamond-search-wrap-row-col-heading">
                              {t('cut')}
                            </h6>
                            <div className="diamond-tab-row row m-0">
                              {daimondfilter?.Cut
                                ? daimondfilter?.Cut.map((result, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <input
                                        key={key}
                                        id={result?._id}
                                        value={result?.code}
                                        type="checkbox"
                                        onClick={(e) => callfilters(e, 'cut')}
                                      />
                                      <label
                                        htmlFor={result?._id}
                                        id={'cut' + result?._id}
                                        className="diamond-tab-row-tab col active resetcut"
                                      >
                                        {result?.code}
                                      </label>
                                    </React.Fragment>
                                  )
                                })
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion advanced-options-accordian"
                        id="accordionAdvanced"
                      >
                        <div
                          id="advancedOptions"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionAdvanced"
                        >
                          <div className="diamond-search-wrap-row">
                            <div className="diamond-search-wrap-row-col col">
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('Polish')}
                                </h6>
                                <div className="diamond-tab-row row m-0">
                                  {daimondfilter?.Polish
                                    ? daimondfilter?.Polish.map(
                                      (result, key) => {
                                        return (
                                          <React.Fragment key={key}>
                                            <input
                                              key={key}
                                              id={result?._id}
                                              value={result?.code}
                                              type="checkbox"
                                              onClick={(e) =>
                                                callfilters(e, 'polish')
                                              }
                                            />
                                            <label
                                              htmlFor={result?._id}
                                              id={'polish' + result?._id}
                                              className="diamond-tab-row-tab symmetry-btn w-25 active resetpolish"
                                            >
                                              {result?.code}
                                            </label>
                                          </React.Fragment>
                                        )
                                      },
                                    )
                                    : ''}
                                </div>
                              </div>
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('depth')}
                                </h6>
                                <div className="range-area">
                                  <Slider
                                    range
                                    min={daimondfilter?.Depth?.min}
                                    max={daimondfilter?.Depth?.max}
                                    value={val3}
                                    onChange={setVal3}
                                  />
                                  <div className="range-area-num">
                                    <div className="range-area-num-single">
                                      <span className="range-area-num-value">

                                        {val3[0]}
                                      </span>
                                      <span className="range-area-num-unit">
                                        %
                                      </span>
                                    </div>
                                    <div className="range-area-num-single">
                                      <span className="range-area-num-value">

                                        {val3[1]}
                                      </span>
                                      <span className="range-area-num-unit">
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('Location')}
                                </h6>
                                <div className="diamond-tab-row row m-0">
                                  {daimondfilter?.Location
                                    ? daimondfilter?.Location.map(
                                      (result, key) => {
                                        return (
                                          <React.Fragment key={key}>
                                            <input
                                              key={key}
                                              id={result?._id}
                                              value={result?.name}
                                              type="checkbox"
                                              onClick={(e) =>
                                                callfilters(e, 'Location')
                                              }
                                            />
                                            <label
                                              htmlFor={result?._id}
                                              id={'Location' + result?._id}
                                              className="diamond-tab-row-tab Location-btn active resetLocation"
                                            >
                                              {result?.name}
                                            </label>
                                          </React.Fragment>
                                        )
                                      },
                                    )
                                    : ''}
                                </div>
                              </div>
                            </div>
                            <div className="diamond-search-wrap-row-col col">
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('Symmetry')}
                                </h6>
                                <div className="diamond-tab-row row m-0">
                                  {daimondfilter?.Symmetry
                                    ? daimondfilter?.Symmetry.map(
                                      (result, key) => {
                                        return (
                                          <React.Fragment key={key}>
                                            <input
                                              key={key}
                                              id={result?._id}
                                              value={result?.code}
                                              type="checkbox"
                                              onClick={(e) =>
                                                callfilters(e, 'symmetry')
                                              }
                                            />
                                            <label
                                              htmlFor={result?._id}
                                              id={'symmetry' + result?._id}
                                              className="diamond-tab-row-tab symmetry-btn active resetsymmetry"
                                            >
                                              {result?.code}
                                            </label>
                                          </React.Fragment>
                                        )
                                      },
                                    )
                                    : ''}
                                </div>
                              </div>
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('Table')}
                                </h6>
                                <div className="range-area">
                                  <Slider
                                    range
                                    min={daimondfilter?.Table?.min}
                                    max={daimondfilter?.Table?.max}
                                    value={val4}
                                    onChange={setVal4}
                                  />
                                  <div className="range-area-num">
                                    <div className="range-area-num-single">
                                      <span className="range-area-num-value">

                                        {val4[0]}
                                      </span>
                                      <span className="range-area-num-unit">
                                        %
                                      </span>
                                    </div>
                                    <div className="range-area-num-single">
                                      <span className="range-area-num-value">

                                        {val4[1]}
                                      </span>
                                      <span className="range-area-num-unit">
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="diamond-search-wrap-row-col col">
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('Fluorescence')}
                                </h6>
                                <div className="diamond-tab-row row m-0">
                                  {daimondfilter?.Fluorescence
                                    ? daimondfilter?.Fluorescence.map(
                                      (result, key) => {
                                        return (
                                          <React.Fragment key={key}>
                                            <input
                                              key={key}
                                              id={result?._id}
                                              value={result?.code}
                                              type="checkbox"
                                              onClick={(e) =>
                                                callfilters(e, 'fluorescence')
                                              }
                                            />
                                            <label
                                              htmlFor={result?._id}
                                              id={
                                                'fluorescence' + result?._id
                                              }
                                              className="diamond-tab-row-tab fluorescence-btn active resetfluorescence"
                                            >
                                              {result?.code}
                                            </label>
                                          </React.Fragment>
                                        )
                                      },
                                    )
                                    : ''}
                                </div>
                              </div>
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('Lab')}
                                </h6>
                                <div className="diamond-tab-row row m-0">
                                  {daimondfilter?.Lbs
                                    ? daimondfilter?.Lbs.map((result, key) => {
                                      return (
                                        <React.Fragment key={key}>
                                          <input
                                            key={key}
                                            id={'Lbs' + key}
                                            value={result}
                                            type="checkbox"
                                            onClick={(e) =>
                                              callfilters(e, 'lbs')
                                            }
                                          />
                                          <label
                                            htmlFor={'Lbs' + key}
                                            id={'lLbs' + key}
                                            className="diamond-tab-row-tab text-uppercase lab-btn active resetlab"
                                          >
                                            {result}
                                          </label>
                                        </React.Fragment>
                                      )
                                    })
                                    : ''}
                                </div>
                              </div>
                              <div className="diamond-search-wrap-row-col-ct">
                                <h6 className="diamond-search-wrap-row-col-heading">
                                  {t('Status')}
                                </h6>
                                <div className="diamond-tab-row row m-0">
                                  {daimondfilter?.Status
                                    ? daimondfilter?.Status.sort(
                                      (a, b) => a - b,
                                    ).map((result, key) => {
                                      return (
                                        <React.Fragment key={key}>
                                          <input
                                            key={key}
                                            id={result}
                                            value={result}
                                            type="checkbox"
                                            onClick={(e) =>
                                              callfilters(e, 'status')
                                            }
                                          />

                                          <label
                                            htmlFor={result}
                                            id={'status' + result}
                                            className="diamond-tab-row-tab status-btn active resetstatus"
                                          >
                                            {result === "1"
                                              ? "Stock"
                                              : result === "2"
                                                ? 'Reserve'
                                                : result === "3"
                                                  ? 'Custom Order'
                                                  : result === "4"
                                                    ? 'Transits'
                                                    : ''}
                                          </label>
                                        </React.Fragment>
                                      )
                                    })
                                    : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="advanced-options-accordian-button-wrap">
                          <div className="row justify-content-center">
                            <div className="col-md-3">
                              <button
                                className={
                                  'w-100 com-btn mt-2 mb-2 ' + FilterSubmitClass
                                }
                                onClick={submitfilters}
                              >
                                {t('Submit Filter')}
                              </button>
                            </div>
                          </div>
                          <button
                            className="diamond-search-wrap-row 
                            advanced-options-accordian-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#advancedOptions"
                            aria-expanded="true"
                            aria-controls="advancedOptions"
                          >
                            <span className="advanced-text">
                              {t('Advanced Options')}
                              <img
                                src={base_assets + 'images/icons/add-icon.png'}
                                alt=""
                              />
                            </span>
                            <span className="less-text">
                              {t('Less Options')}
                              <img
                                src={
                                  base_assets + 'images/icons/minus-icon.png'
                                }
                                alt=""
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="diamond-search-wrap-nav">
                    <div
                      className="diamond-search-wrap-nav-tab"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="nav-link active"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {t('results')}
                        <span className="total-row">
                          ({totalresult ? totalresult : ''})
                        </span>
                      </button>
                      <button
                        className="nav-link"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        {t('Comparision')}
                        <span className="total-row">
                          ({comparisiondata ? comparisiondata?.length : '0'})
                        </span>
                      </button>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <div className="common-sub-table-wrap add-row">
                          <div className="main-body-main-table-wrap">
                            <InfiniteScroll
                              dataLength={items ? items?.length : ''}
                              next={totalresult > 10 ? fetchMoreData : ''}
                              hasMore={totalresult > rowlength ? true : false}
                              loader={
                                totalresult > 10 ? (
                                  <h6>{t('Loading more items...')}</h6>
                                ) : (
                                  ''
                                )
                              }
                            >
                              <table
                                id="tbl_createTransfer1"
                                className="common-table first-row-gray w-100"
                              >
                                <thead>
                                  <tr>
                                    <th />
                                    <th>#</th>
                                    <th>{t('Shape')}</th>
                                    <th>{t('Carat')}</th>
                                    <th>{t('Colour')}</th>
                                    <th>{t('Clarity')}</th>
                                    <th>{t('Cut')}</th>
                                    <th>{t('Polish')}</th>
                                    <th>{t('Symmetry')}</th>
                                    <th>{t('Certificate')}</th>
                                    <th>{t('Price')}</th>
                                    <th>{t('Location')}</th>
                                    <th className="text-center">
                                      {t('Detail')}
                                    </th>
                                    <th className="text-center">
                                      {t('Status')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {items
                                      ? items.map((result, key) => {
                                        return (
                                          <tr key={key}>
                                            <td>
                                              <div className="com-check">
                                                <input
                                                  id={result?._id}
                                                  data-id={'d' + result?._id}
                                                  onClick={callcomparision}
                                                  type="checkbox"
                                                />
                                                <label
                                                  htmlFor={result?._id}
                                                  className="com-check-label"
                                                />
                                              </div>
                                            </td>
                                            <td>{key + 1}</td>
                                            <td className="diamond-pro-img">
                                              <img
                                                src={
                                                  result?.Shape
                                                    ? result?.Shape
                                                    : base_assets +
                                                    'images/icon/camera_profile.png'
                                                }
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <Link
                                                className="link-color text-decoration-none"
                                              >
                                                {result?.Carat
                                                  ? result?.Carat
                                                  : ''}
                                              </Link>
                                            </td>
                                            <td>
                                              {result?.Colour
                                                ? result?.Colour
                                                : ''}
                                            </td>
                                            <td>
                                              {result?.Clarity
                                                ? result?.Clarity
                                                : ''}
                                            </td>
                                            <td>
                                              {result?.Cut ? result?.Cut : ''}
                                            </td>
                                            <td>
                                              {result?.Polish
                                                ? result?.Polish
                                                : ''}
                                            </td>
                                            <td>
                                              {result?.Symmetry
                                                ? result?.Symmetry
                                                : ''}
                                            </td>
                                            <td>
                                              <Link
                                                className="link-color"
                                                target="_blank"
                                                to={
                                                  result?.CertificationUrl
                                                    ? result?.CertificationUrl
                                                    : ''
                                                }
                                              >
                                                {result?.Certification
                                                  ? result?.Certification
                                                  : ''}
                                              </Link>
                                            </td>
                                            <td>
                                              {result?.Price
                                                ? result?.Price
                                                : ''}
                                            </td>
                                            <td>
                                              {result?.Location
                                                ? result?.Location
                                                : ''}
                                            </td>
                                            <td className="text-center eye-img">
                                              <Link
                                                state={{
                                                  daimondid: result?._id,
                                                }}
                                                to="/app/inventory/detailDiamond"
                                              >
                                                <img
                                                  src={
                                                    base_assets +
                                                    'images/icons/green-eye.png'
                                                  }
                                                  alt=""
                                                />
                                              </Link>
                                            </td>

                                            <td className="text-center">
                                              <div
                                                className={
                                                  result?.Status === 1
                                                    ? 'status-yellow light-blue tooltip-area-up'
                                                    : result?.Status === 2
                                                      ? 'status-yellow light-yellow-bg tooltip-area-up'
                                                      : result?.Status === 3
                                                        ? 'status-yellow bg-danger tooltip-area-up'
                                                        : result?.Status === 4
                                                          ? 'status-gray-tab light-gray-bg tooltip-area-up'
                                                          : 'status-color bg-main-green tooltip-area-up'

                                                }
                                              >
                                                {result?.reserveDate ? (
                                                  <span className="tooltip-area-up-text">

                                                    {result?.customer}
                                                    <br />
                                                    {formatDate(
                                                      result?.reserveDate,
                                                    )}
                                                  </span>
                                                ) : result?.Status === 1 ? (
                                                  <span className="tooltip-area-up-text">
                                                    {t('Stock')}
                                                  </span>
                                                ) : result?.Status === 3 ? (
                                                  <span className="tooltip-area-up-text">
                                                    {t('Custom Order')}
                                                  </span>
                                                ) : result?.Status === 4 ? (
                                                  <span className="tooltip-area-up-text">
                                                    {t('Transit')}
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })
                                      : ''}
                                  </>
                                </tbody>
                              </table>
                            </InfiniteScroll>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <div className="common-sub-table-wrap add-row">
                          <div className="main-body-main-table-wrap">
                            <table
                              id="tbl_createTransfer2"
                              className="common-table first-row-gray w-100"
                            >
                              <thead>
                                <tr>
                                  <th />
                                  <th>#</th>
                                  <th>{t('Shape')}</th>
                                  <th>{t('Carat')}</th>
                                  <th>{t('Colour')}</th>
                                  <th>{t('Clarity')}</th>
                                  <th>{t('Cut')}</th>
                                  <th>{t('Polish')}</th>
                                  <th>{t('Symmetry')}</th>
                                  <th>{t('Certificate')}</th>
                                  <th>{t('Price')}</th>
                                  <th>{t('Location')}</th>
                                  <th className="text-center">{t('Detail')}</th>
                                  <th className="text-center">{t('Status')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  {comparisiondata
                                    ? comparisiondata.map((result, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>
                                            <button
                                              className="delete-row-btn"
                                              onClick={() =>
                                                deletecomparision(result?._id)
                                              }
                                            >
                                              <img
                                                src={
                                                  base_assets +
                                                  'images/icons/delete-icon.png'
                                                }
                                                alt=""
                                              />
                                            </button>
                                          </td>
                                          <td>{key + 1}</td>
                                          <td className="diamond-pro-img">
                                            <img
                                              src={
                                                result?.Shape
                                                  ? result?.Shape
                                                  : base_assets +
                                                  'images/icon/camera_profile.png'
                                              }
                                              alt=""
                                            />
                                          </td>
                                          <td>
                                            <Link className="link-color">
                                              {result?.Carat
                                                ? result?.Carat
                                                : ''}
                                            </Link>
                                          </td>
                                          <td>
                                            {result?.Colour
                                              ? result?.Colour
                                              : ''}
                                          </td>
                                          <td>
                                            {result?.Clarity
                                              ? result?.Clarity
                                              : ''}
                                          </td>
                                          <td>
                                            {result?.Cut ? result?.Cut : ''}
                                          </td>
                                          <td>
                                            {result?.Polish
                                              ? result?.Polish
                                              : ''}
                                          </td>
                                          <td>
                                            {result?.Symmetry
                                              ? result?.Symmetry
                                              : ''}
                                          </td>
                                          <td>
                                            <a
                                              className="link-color"
                                              target="_blank"
                                              href={
                                                result?.CertificationUrl
                                                  ? result?.CertificationUrl
                                                  : ''
                                              }
                                              rel="noreferrer"
                                            >
                                              {result?.Certification
                                                ? result?.Certification
                                                : ''}
                                            </a>
                                          </td>
                                          <td>
                                            {result?.Price ? result?.Price : ''}
                                          </td>
                                          <td>
                                            {result?.Location
                                              ? result?.Location
                                              : ''}
                                          </td>
                                          <td className="text-center eye-img">
                                            <Link
                                              state={{ daimondid: result?._id }}
                                              to="/app/inventory/detailDiamond"
                                            >
                                              <img
                                                src={
                                                  base_assets +
                                                  'images/icons/green-eye.png'
                                                }
                                                alt=""
                                              />
                                            </Link>
                                          </td>

                                          <td className="text-center">
                                            <div
                                              className={
                                                result?.Status === 1
                                                  ? 'status-yellow light-blue tooltip-area-up'
                                                  : result?.Status === 2
                                                    ? 'status-yellow light-yellow-bg tooltip-area-up'
                                                    : result?.Status === 3
                                                      ? 'status-yellow bg-danger tooltip-area-up'
                                                      : 'status-color bg-main-green tooltip-area-up'
                                              }
                                            >
                                              {result?.reserveDate ? (
                                                <span className="tooltip-area-up-text">

                                                  {result?.customer} <br />
                                                  {formatDate(
                                                    result?.reserveDate,
                                                  )}
                                                </span>
                                              ) : result?.Status === 1 ? (
                                                <span className="tooltip-area-up-text">
                                                  {t('Stock')}
                                                </span>
                                              ) : result?.Status === 3 ? (
                                                <span className="tooltip-area-up-text">
                                                  {t('Custom Order')}
                                                </span>
                                              ) : result?.Status === 4 ? (
                                                <span className="tooltip-area-up-text">
                                                  {t('Transit')}
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })
                                    : ''}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
      </div>
      <TableColumnModal></TableColumnModal>
    </React.Fragment>
  )
}

export default DiamondSearch;
