import React, { useState } from "react";
import Accordion from "./Accordians";

const AccordianInv = () => {
  const [isOpen1, setIsOpen1] = useState(1);
  const [isOpen2, setIsOpen2] = useState(4);
  const [isOpen3, setIsOpen3] = useState(7);
  const handleChange1 = (id) => {
    setIsOpen1(id);
  };

  const handleChange2 = (id) => {
    setIsOpen2(id);
  };

  const handleChange3 = (id) => {
    setIsOpen3(id);
  };

  const accordionData1 = [
    {
      id: 1,
      title: "Inventory",
      content: "Stored diamonds and jewelry that can track the status and location of each product",
      imageSrc: "/assets/landingNew/inventory-2.svg",
    },
    {
      id: 2,
      title: "Summary",
      content: "Displays the status and total amount of stock in real time",
      imageSrc: "/assets/landingNew/inventory.svg",
    },
    {
      id: 3,
      title: "Product Analysis",
      content: "Evaluating product costs, availability, quality, appearance and other aspects",
      imageSrc: "/assets/landingNew/ProductAnalysis1.svg",
    },
  ];

  const accordionData2 = [
    {
      id: 4,
      title: "Quick View",
      content: "Fill in the SKU number to see product information such as quantity, location, material, and more",
      imageSrc: "/assets/landingNew/QuickViewProduct1.svg",
    },
    {
      id: 5,
      title: "Stock Take",
      content: "Check stocks by using RFID technology",
      imageSrc: "/assets/landingNew/StockTake1.svg",

    },
    {
      id: 6,
      title: "Low Stock Planning",
      content: "Planning to refill stock automatically by calculating the purchased order and minimum stock",
      imageSrc: "/assets/landingNew/LowStockPlaning1.svg",

    },
  ];

  const accordionData3 = [
    {
      id: 7,
      title: "Purchase Order",
      content: "Notification alert when a new order comes in or low stock levels in the inventory",
      imageSrc: "/assets/landingNew/Purchaseorder1.svg",
    },
    {
      id: 8,
      title: "Stock Transfer",
      content: "Easy to transfer and tracking stock",
      imageSrc: "/assets/landingNew/StockTransfer1.svg",

    },
    {
      id: 9,
      title: "Reports",
      content: "Records of overall inventory performance",
      imageSrc: "/assets/landingNew/Report1.svg",

    },
  ];

  return (
    <div className="accordion-container faq-flex-content">
      <div className="accordion-set">
        {accordionData1.map((data) => (
          <Accordion
            key={data.id}
            id={data.id}
            title={data.title}
            content={data.content}
            imageSrc={data.imageSrc}
            isOpen={isOpen1}
            handleChange={handleChange1}
          />
        ))}
      </div>
      <div className="accordion-set">
        {accordionData2.map((data) => (
          <Accordion
            key={data.id}
            id={data.id}
            title={data.title}
            content={data.content}
            imageSrc={data.imageSrc}
            isOpen={isOpen2}
            handleChange={handleChange2}
          />
        ))}
      </div>
      <div className="accordion-set">
        {accordionData3.map((data) => (
          <Accordion
            key={data.id}
            id={data.id}
            title={data.title}
            content={data.content}
            imageSrc={data.imageSrc}
            isOpen={isOpen3}
            handleChange={handleChange3}
          />
        ))}
      </div>
    </div>
  );
};

export default AccordianInv;
