import React from 'react'

export default function Eye() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4987 12.9193C12.1095 12.9193 13.4154 11.6134 13.4154 10.0026C13.4154 8.39177 12.1095 7.08594 10.4987 7.08594C8.88787 7.08594 7.58203 8.39177 7.58203 10.0026C7.58203 11.6134 8.88787 12.9193 10.4987 12.9193Z" stroke="#181818" stroke-width="0.833333"/>
    <path d="M17.3231 9.11167C17.6464 9.505 17.8081 9.70083 17.8081 10C17.8081 10.2992 17.6464 10.495 17.3231 10.8883C16.1397 12.325 13.5297 15 10.4997 15C7.46974 15 4.85974 12.325 3.67641 10.8883C3.35307 10.495 3.19141 10.2992 3.19141 10C3.19141 9.70083 3.35307 9.505 3.67641 9.11167C4.85974 7.675 7.46974 5 10.4997 5C13.5297 5 16.1397 7.675 17.3231 9.11167Z" stroke="#545454" stroke-width="0.833333"/>
    </svg>

  )
}
