import { injectStyle } from "react-toastify/dist/inject-style";
import { toast } from "react-toastify";
// import { useTranslation } from 'react-i18next';
if (typeof window !== "undefined") {
  injectStyle();
}

export const loading = function () {
  return toast.loading("Processing Wait...");
};

export const Updateloading = function (t, Id, querystring = "Successfully!!", type = 'success', position = "top-right") {
  if (typeof querystring == "object") {
    Object.values(querystring)?.forEach((result) => {
      toast.update(Id, {
        render: t(result[0]), type: type, isLoading: false,
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
    });
  } else {
    toast.update(Id, {
      render: t(querystring), type: type, isLoading: false,
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,

    });
  }
};

export const success = function (
  querystring = "Successfully!!",
  position = "top-right"
) {
  if (typeof querystring == "object") {
    Object.values(querystring)?.forEach((result) => {
      toast.success(result[0], {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  } else {
    toast.success(querystring, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const error = function (
  querystring = "samething wroung!! ",
  position = "top-right"
) {

  if (typeof querystring == "object") {

    Object.values(querystring)?.forEach((result) => {
      toast.error(result[0], {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  } else {
    toast.error(querystring, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
