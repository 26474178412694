import React, { useState, useEffect } from 'react'
import RightArrow from '../../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import './domain.scss'
import ExistinfDomainModal from './ExistingDomainModal'
import ChangeDomainModal from './ChangeTypeModal'
import { _Api } from '../../api/_call'
import Confirmationmodel from '../../common/modules/confirmationmodel'
import TableLoader from '../../common/TableLoader'
import { loading, Updateloading } from '../../common/helpers/toastify'
const SetupDomain = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation()
  const [domainlist, setDomainlist] = useState([])
  const [showloader, setShowloader] = useState(false)
  const [filtername, setFiltername] = useState('');
  const [dataid, setDataid] = useState()
  const [deleteid, setDeleteid] = useState("")
  const filterbyname = () => {
    getdomain();
  };
  const getdomain = async () => {
    setShowloader(true)
    try {
      let postdata = {
        "search": filtername,
      }
      let res = await _Api(postdata, `api/v1/App/domain/get-list`)
      if (res?.code === 200) {
        setDeleteid("")
        setDomainlist(res?.data)
        setShowloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getdomain()
    // eslint-disable-next-line
  }, [])
  const deletedomain = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        id: deleteid
      }
      let res = await _Api(postdata, `api/v1/App/domain/delete-domain`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        setDeleteid("")
        getdomain()
      } else {
        Updateloading(t, GetloadingID, res.message ? (res.message) : (res.errors), 'error')
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
              {t('Setup')} <RightArrow />{' '}
              <span className="">{t('Domains')}</span>
            </h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <div className="com-search-bar position-relative">
              <img onClick={filterbyname} className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search Domains" className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                onChange={(e) => setFiltername(e.target.value)} />
            </div>
            {/* <div className="com-search-bar position-relative">
              <img
                className="com-search-bar-search-img"
                src={base_assets + 'images/admin/icons/gray-search.png'}
                alt=""
              />
              <input
                placeholder="Search Domains"
                className="h-100 border-0 bg-transparent"
              />
            </div> */}
            <button
              className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"
              data-bs-toggle="modal"
              data-bs-target="#ExistinfDomainModal"
            >
              {t("Connect Existing Domain")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="bg-white p-4 rounded-3 position-relative">
              <table className="domain-table w-100">
                <thead>
                  <tr>
                    <th className="p-2 pb-4">{t('Domain')}</th>
                    <th className="p-2 pb-4">{t('Target')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {domainlist?.length
                    ?
                    domainlist.map((result, key) => {
                      return (
                        <tr
                          className="border border-start-0 border-end-0"
                          key={key}
                        >
                          <td className="p-2"
                            onClick={() => setDataid(result)}>
                            <div className="fit-content-w">
                              <div
                                className={`align-items-center d-flex gap-4 cursor-pointer ${result?.type === 1 ? "disabled " : ""}`}
                                {...(result?.type === 0 ? { 'data-bs-toggle': 'modal', 'data-bs-target': '#ChangeDomainModal' } : {})}
                              >
                                <div
                                  className={`domain-table-icon d-flex align-items-center justify-content-center p-2 rounded-3  
                              ${result?.type === 1 ? 'active' : '-'}`}
                                >
                                  <div className='tooltip-area-up'>
                                    <img
                                      className="w-100"
                                      src={
                                        base_assets +
                                        'images/admin/icons/domain.svg'
                                      }
                                      alt=""
                                    />
                                    {
                                      result?.type === 0 ?
                                        <span className="tooltip-area-up-text">
                                          {t("Make Default Domain")}</span> :
                                        <span className="tooltip-area-up-text">
                                          {t("Default Domain")}</span>
                                    }
                                  </div>
                                </div>
                                <div>
                                  <span className="fw-semibold d-block">
                                    {result?.name ? result?.name : '-'}
                                  </span>
                                  <span className="d-block">
                                    {result?.type === 1
                                      ? `Primary for ${result?.primary}`
                                      : `Redirecting to ${result?.primary}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="p-2">{t("Online Store")}</td>
                          {result?.is_delete === 1 && result?.type === 0 && (
                            <td className="text-center">
                              <img
                                onClick={() => setDeleteid(result?._id)}
                                className="cursor-pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#createPurchaseModal"
                                src={
                                  base_assets +
                                  'images/admin/icons/Delete.png'
                                }
                                alt=""
                              />
                            </td>
                          )}
                        </tr>
                      )
                    })
                    :
                    <tr>
                      <td colSpan="50" className="text-center">
                        {' '}
                        {t("No Data Available In Table")}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
          </div>
        </div>
      </div>
      <ExistinfDomainModal />
      <ChangeDomainModal
        dataid={dataid} getdomain={getdomain} />
      <Confirmationmodel
        statement={'Delete'}
        handler_submit={deletedomain}
      ></Confirmationmodel>
    </>
  )
}

export default SetupDomain
