import React, { useEffect, useState } from "react";
import PlusIcon from "../assets/icons/PlusIcon"
import CrossIcon from "../assets/icons/CrossIcon";
import MinusIcon from "../assets/icons/MinusIcon";
import Slider from 'rc-slider';
import { useTranslation } from "react-i18next";
const FilterEcommerce = (props) => {
  const { filterdata, setFilters, toggleFilter, filters } = props
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { t } = useTranslation();
  const [filtercheck, setfiltercheck] = useState(filters)
  const [price, setPrice] = useState([filterdata?.price?.min, filterdata?.price?.max])
  const toggleCollapse = (key, status) => {
    setIsCollapsed((privace) => ({
      ...privace,
      [key]: status
    }));
  };
  const reaponcefilter = (event, lable, filter) => {
    let data = { ...filtercheck }
    if (lable === "item") {
      data.item = filter?._id
    }
    if (lable === "size") {
      if (event.target.checked) {
        data.size.push(filter)
      } else {
        data.size = data.size.filter((item) => item?._id !== filter?._id)
      }
    }
    if (lable === "stone") {
      if (event.target.checked) {
        data.stone.push(filter)
      } else {
        data.stone = data.stone.filter((item) => item?._id !== filter?._id)
      }
    }
    if (lable === "metal") {
      if (event.target.checked) {
        data.metal.push(filter)
      } else {
        data.metal = data.metal.filter((item) => item?._id !== filter?._id)
      }
    }
    if (lable === "price") {
      data.price.min = event[0]
      data.price.max = event[1]
    }
    if (lable === "clearfilter") {
      data.item = ""
      data.size = []
      data.stone = []
      data.metal = []
      data.price = { min: filterdata?.price?.min, max: filterdata?.price?.max }
      setPrice([filterdata?.price?.min, filterdata?.price?.max])
    }
    setFilters(data)
    setfiltercheck(data)
  }
  useEffect(() => {
    setfiltercheck((privacestate) => ({
      ...privacestate,
      price: { min: filterdata?.price?.min, max: filterdata?.price?.max }
    }))
  }, [filterdata])
  return (
    <div className="ecommerce-main-layout-products-container-filter">
      <div className="ecommerce-main-layout-products-container-filter-selected_filter">
        <span className="filterMainHeading">{t("All New Jewelry")}</span>
        <span className="filterMainCrossBtn" onClick={() => toggleFilter(true)}><CrossIcon width={16} height={16} /></span>
      </div>
      <div className="ecommerce-main-layout-products-container-filter-inner">
        <div className="ecommerce-main-layout-products-container-filter-inner-title" onClick={() => toggleCollapse("applyfilter", !isCollapsed.applyfilter)} data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
          <div className='d-flex align-items-center justify-space-between w-100'>
            <span className="ecommerce-main-layout-products-container-filter-inner-title-mainhead">{t("Applied Filters")}</span>
            <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button"
            >
              {isCollapsed?.applyfilter ?
                <span ><MinusIcon /></span> :
                <span > < PlusIcon /></span>}
            </button>
          </div>
        </div>
        <div className={`ecommerce-main-layout-products-container-filter-inner-filter_details collapse  ${isCollapsed?.applyfilter ? 'show' : ''}`} id='collapseFilter' >
          <div className="ecommerce-main-layout-products-container-filter-inner-filter_details-appliedFilter-Container">
            <div className="applied-all-filterShow">
              {filtercheck?.metal?.length ? filtercheck?.metal.map((result, key) => {
                return (
                  <div className="applied-all-filterShow-singlefilter" key={key}>
                    <span className="applied-all-filterShow-singlefilter-appliedHead">{t(`${result?.name}`)}</span>
                    <span className="applied-all-filterShow-singlefilter-crossbtn"
                      onClick={(e) => reaponcefilter(e, "metal", result)}><CrossIcon /></span>
                  </div>
                )
              }) : ""}
              {filtercheck?.stone?.length ? filtercheck?.stone.map((result, key) => {
                return (
                  <div className="applied-all-filterShow-singlefilter" key={key}>
                    <span className="applied-all-filterShow-singlefilter-appliedHead">{t(`${result?.name}`)}</span>
                    <span className="applied-all-filterShow-singlefilter-crossbtn"
                      onClick={(e) => reaponcefilter(e, "stone", result)}><CrossIcon /></span>
                  </div>
                )
              }) : ""}
              {filtercheck?.size?.length ? filtercheck?.size.map((result, key) => {
                return (
                  <div className="applied-all-filterShow-singlefilter" key={key}>
                    <span className="applied-all-filterShow-singlefilter-appliedHead">{t(`${result?.name}`)}</span>
                    <span className="applied-all-filterShow-singlefilter-crossbtn"
                      onClick={(e) => reaponcefilter(e, "size", result)}><CrossIcon /></span>
                  </div>
                )
              }) : ""}
            </div>
            <div className="applied-filter-clearbtn cursor-pointer" onClick={() => reaponcefilter("", "clearfilter")}>{t("Clear all filters")}</div>
          </div>
        </div>
      </div >
      {filterdata?.item?.length ?
        <div className="ecommerce-main-layout-products-container-filter-inner" >
          <div className="ecommerce-main-layout-products-container-filter-inner-title" onClick={() => toggleCollapse("item", !isCollapsed.item)} data-bs-toggle="collapse" data-bs-target="#collapseCategories" aria-expanded="false" aria-controls="collapseCategories">
            <div className='d-flex align-items-center justify-space-between w-100'>
              <span className="ecommerce-main-layout-products-container-filter-inner-title-mainhead">{t("Categories")}</span>
              <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button" >
                {isCollapsed?.item ?
                  <span><MinusIcon /></span> :
                  <span> < PlusIcon /></span>}
              </button>
            </div>
          </div>

          <div className={`ecommerce-main-layout-products-container-filter-inner-filter_details collapse  ${isCollapsed?.item ? 'show' : ''}`} id='collapseCategories'  >
            {filterdata?.item?.length ? filterdata?.item.map((result, key) => {
              return (
                <div className="ecommerce-main-layout-products-container-filter-inner-title-container" key={key} onClick={() => toggleFilter(true)}>
                  <span className="ecommerce-main-layout-products-container-filter-inner-title-container-filter_name"
                    onClick={(e) => reaponcefilter(e, "item", result)} >{t(`${result?.name}`)}</span>
                </div>
              )
            }) : ""}
          </div>
        </div>
        : ""}
      {filterdata?.metal?.length ?
        <div className="ecommerce-main-layout-products-container-filter-inner" >
          <div className="ecommerce-main-layout-products-container-filter-inner-title" data-bs-toggle="collapse" data-bs-target="#collapseMetal" aria-expanded="false" aria-controls="collapseMetal" onClick={() => toggleCollapse("Metal", !isCollapsed.Metal)}>
            <div className='d-flex align-items-center justify-space-between w-100'>
              <span className="ecommerce-main-layout-products-container-filter-inner-title-mainhead">{t("Metal")}</span>
              <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button">      {isCollapsed?.Metal ?
                <span><MinusIcon /></span> :
                <span> < PlusIcon /></span>
              }
              </button>
            </div>

          </div>

          <div className={`ecommerce-main-layout-products-container-filter-inner-filter_details collapse  ${isCollapsed?.Metal ? 'show' : ''}`} id='collapseMetal'>
            <div className="ecommerce-main-layout-products-container-filter-inner-filter_details-metalContainer">
              {filterdata?.metal?.length ? filterdata.metal.map((result, key) => (
                <div className="metal-filterbox" key={key}>
                  <div className="metal-filterbox-innerLeftSide">
                    <input
                      className="fill-com-check form-check-input filter-checkbox d-inline-block m-0 cursor-pointer"
                      type="checkbox"
                      id={result.name}
                      onChange={(e) => reaponcefilter(e, "metal", result)}
                      checked={filtercheck?.metal && filtercheck.metal.some(item => item._id === result._id)}
                    />
                    <label htmlFor={result.name} className="metal-filterbox-innerLeftSide-optionHeader">
                      {t(`${result.name}`)}
                    </label>
                  </div>
                </div>
              )) : null}
            </div>
          </div>
        </div>
        : ""}
      {filterdata?.stone?.length ?
        <div className="ecommerce-main-layout-products-container-filter-inner" >
          <div className="ecommerce-main-layout-products-container-filter-inner-title" onClick={() => toggleCollapse("Stone", !isCollapsed.Stone)} data-bs-toggle="collapse" data-bs-target="#collapseStone" aria-expanded="false" aria-controls="collapseStone">
            <div className='d-flex align-items-center justify-space-between w-100'>
              <span className="ecommerce-main-layout-products-container-filter-inner-title-mainhead">{t("Stone")}</span>
              <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button">
                {isCollapsed?.Stone ?
                  <span><MinusIcon /></span> :
                  <span> < PlusIcon /></span>
                }
              </button>
            </div>

          </div>

          <div className={`ecommerce-main-layout-products-container-filter-inner-filter_details collapse  ${isCollapsed?.Stone ? 'show' : ''}`} id='collapseStone'>
            <div className="ecommerce-main-layout-products-container-filter-inner-filter_details-metalContainer">
              {filterdata?.stone?.length ? filterdata?.stone.map((result, key) => {
                return (
                  <div className="metal-filterbox" key={key}>
                    <div className="metal-filterbox-innerLeftSide">
                      <input className="fill-com-check form-check-input filter-checkbox d-inline-block m-0 cursor-pointer" type="checkbox" id="name"
                        value={result?._id}
                        onClick={(e) => reaponcefilter(e, "stone", result)}
                        checked={filtercheck?.stone && filtercheck.stone.some(item => item._id === result._id)}
                      />
                      <span className="metal-filterbox-innerLeftSide-optionHeader">{t(`${result?.name}`)}</span>
                    </div>
                  </div>
                )
              }) : ""}
            </div>
          </div>
        </div>
        : ""}
      {filterdata?.size?.length ?
        <div className="ecommerce-main-layout-products-container-filter-inner">
          <div className="ecommerce-main-layout-products-container-filter-inner-title" onClick={() => toggleCollapse("Size", !isCollapsed.Size)} data-bs-toggle="collapse" data-bs-target="#collapseSize" aria-expanded="false" aria-controls="collapseSize">
            <div className='d-flex align-items-center justify-space-between w-100'>
              <span className="ecommerce-main-layout-products-container-filter-inner-title-mainhead">{t("Size")}</span>
              <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button"  >
                {isCollapsed?.Size ?
                  <span><MinusIcon /></span> :
                  <span> < PlusIcon /></span>
                }
              </button>
            </div>
          </div>
          <div className={`ecommerce-main-layout-products-container-filter-inner-filter_details collapse  ${isCollapsed?.Size ? 'show' : ''}`} id='collapseSize' >
            <div className="ecommerce-main-layout-products-container-filter-inner-filter_details-sizeContainer">
              {filterdata?.size?.length ? filterdata?.size.map((result, key) => {
                return (
                  <div className='com-label-select ' key={key}>
                    <input className='' type='checkbox' id={result?.name} value={result?._id} name="clear"
                      onClick={(e) => { reaponcefilter(e, "size", result) }}
                      checked={filtercheck?.size?.length && filtercheck.size.some(item => item._id === result._id)}
                    />
                    <label className='text-center bg-f2 sizeSelectbox' htmlFor={result?.name}>{t(`${result?.name}`)}</label>
                  </div>
                )
              }) : ""}
            </div>
          </div>
        </div>
        : ""}
      {filterdata?.price?.max ?
        <div className="ecommerce-main-layout-products-container-filter-inner">
          <div className="ecommerce-main-layout-products-container-filter-inner-title" onClick={() => toggleCollapse("Price", !isCollapsed.Price)} data-bs-toggle="collapse" data-bs-target="#collapsePrice" aria-expanded="false" aria-controls="collapsePrice">
            <div className='d-flex align-items-center justify-space-between w-100'>
              <span className="ecommerce-main-layout-products-container-filter-inner-title-mainhead">{t("Price")}</span>
              <button className="border-0 p-0 bg-transparent ms-auto d-flex gap-3 align-items-center fw-semibold" type="button">
                {isCollapsed?.Price ?
                  <span><MinusIcon /></span> :
                  <span> < PlusIcon /></span>
                }
              </button>
            </div>
          </div>
          <div className={`ecommerce-main-layout-products-container-filter-inner-filter_details collapse  ${isCollapsed?.Price ? 'show' : ''}`} id='collapsePrice'>
            <div className="price_FilterContainer">
              <div className="price_FilterContainer-rangeBox mt-0">
                <div className="price_FilterContainer-rangeBox-numShow d-flex justify-content-between">
                  <div className="price_FilterContainer-rangeBox-numShow-single">
                    <span className="Dollar_sign">{filterdata?.currency}</span>
                    <span className="price_FilterContainer-rangeBox-numShow-single-unit">{price[0]}</span>
                  </div>
                  <span className="price-betwn-to">{t("to")}</span>
                  <div className="price_FilterContainer-rangeBox-numShow-single">
                    <span className="Dollar_sign">{filterdata?.currency}</span>
                    <span className="price_FilterContainer-rangeBox-numShow-single-unit">{price[1]}</span>
                  </div>
                </div>
                <Slider
                  range
                  min={filterdata?.price?.min}
                  max={filterdata?.price?.max}
                  value={price}
                  onChange={(e) => setPrice(e)}
                  onChangeComplete={(e) => reaponcefilter(e, "price")}
                />
              </div>
            </div>
          </div>
        </div>
        : ""}
      <button className="ecommerce-main-layout-products-container-filter-applybutton"
        onClick={() => toggleFilter(true)}>
        {t("Apply")}
      </button>

    </div >

  )
}

export default FilterEcommerce