import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabInv from "../../src/landing/Tab-inv ";
import TabPos from "./Tab-pos "
import brochureBtn from "./assets/images/brochure-downloadbtn.png"
import BrochureModel from "./BrochureModel";
const Mobfeature = (props) => {
  const { t } = useTranslation();
  const { countries } = props;
  const [activeTab, setActiveTab] = useState("home");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div>
        <div className="radio-buttons feature-radio-box">
          <div>
            <input
              type="radio"
              id="pills-home"
              name="tab-selector"
              checked={activeTab === "home"}
              onChange={() => handleTabChange("home")}
              className='d-none'
            />
            <label
              htmlFor="pills-home"
              className={`tab-label feature-radio-box-single ${activeTab === "home" ? " active" : ""}`}
              style={{ color: activeTab === "home" ? "black" : "#C0C0C0" }}
            >
              {activeTab === "home" && <span className="circle"></span>}
              {t("Inventory")}
            </label>
          </div>

          <div>
            <input
              type="radio"
              id="pills-profile"
              name="tab-selector"
              checked={activeTab === "profile"}
              onChange={() => handleTabChange("profile")}
              className='d-none'
            />
            <label
              htmlFor="pills-profile"
              className={`tab-label feature-radio-box-single${activeTab === "profile" ? " active" : ""}`}
              style={{ color: activeTab === "profile" ? "black" : "#C0C0C0" }}
            >
              {activeTab === "profile" && <span className="circle"></span>}
              POS
            </label>
          </div>

        </div>

        <div className="feature-radio-box-flex">
          <div className="faq-flex-inner-box">
            <div className="tab-content  featureshow-mob-content" id="pills-mobContent">
              <div className={`tab-pane fade show ${activeTab === "home" ? "active" : ""}`} id="nav-home" role="tabpanel">
                <TabInv />
              </div>

              <div className={`tab-pane fade show ${activeTab === "profile" ? "active" : ""}`} id="nav-profile" role="tabpanel">
                <TabPos />
              </div>
            </div>
          </div>




        </div>

        <div className="brochure-mob">
          <div className="brochure-button">
            <button
              className="brochure-button-inner"
              data-bs-toggle="modal"
              data-bs-target="#brochureDownloadModal"
            >
              <span className="brochure-button-inner-img-box"><img src={brochureBtn} alt='' /></span>
              Download Brochure
            </button>
          </div>
        </div>



      </div>
      <BrochureModel countries={countries} />
    </>
  );
};

export default Mobfeature;
