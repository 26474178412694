import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import 'datatables.net-dt'
import 'datatables.net-fixedcolumns';
const MyInventory = (props) => {

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    useEffect(() => {
        $('#pos_order_table').DataTable({
            scrollY: "300px",
            scrollX: true,
            scrollCollapse: true,
            paging: false,
            bFilter: false,
            bInfo: false,
            autoWidth: true,
            fixedColumns: { left: 3 },
            columns: [
                { className: "d-none" },
                null,
                null,
                { className: "" },
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,

            ]
        });

    }, []);
    return (
        <React.Fragment>
            <div className="main-body">
                <div className="container">
                    <div className="row">
                        <DatePicker
                            selectsRange={true}
                            monthsShown={1}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            withPortal
                            autoComplete='none'
                        />

                        <input value={`${startDate} - ${endDate}`} />

                        <div className="common-sub-table-wrap add-row">

                            <div className="main-body-main-table-wrap">
                                <table
                                    id="pos_order_table"
                                    className="common-table product-base-table w-100"
                                >
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="com-check">
                                                    <input type="checkbox" />
                                                    <label className="com-check-label" />
                                                </div>
                                            </th>
                                            <th>#</th>
                                            <th>img</th>
                                            <th>
                                                SKU
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th className="col-highlight">
                                                date
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th className="danger-text">
                                                POS no
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                Stock ID
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>metal</th>
                                            <th>stone</th>
                                            <th>size</th>
                                            <th>
                                                qty
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                Price
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                Amount
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th className="danger-text">
                                                ship to
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th className="danger-text">
                                                customer
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th className="danger-text">
                                                delivery date
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th className="danger-text">
                                                due days
                                                <div className="up-down-arrow-btn">
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-forward active"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger.png"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="up-down-arrow-img up-down-arrow-img-reverse"
                                                        src="../../assets/v2/images/icons/up-down-arrow-danger-reverse.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>remark</th>
                                            <th>design</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="com-check">
                                                    <input type="checkbox" />
                                                    <label className="com-check-label" />
                                                </div>
                                            </td>
                                            <td>1</td>
                                            <td>
                                                <div className="table-pro-img">
                                                    <img src="../../assets/v2/images/AVEN-R1.png" alt="" />
                                                </div>
                                            </td>
                                            <td className="po-no">
                                                <Link> Avenues Multi-Finger Ring</Link>
                                            </td>
                                            <td> 24/05/2022</td>
                                            <td className="po-no">
                                                <Link> PGRO-004</Link>
                                            </td>
                                            <td className="col-highlight">-</td>
                                            <td className="col-highlight">YG</td>
                                            <td className="col-highlight">BC</td>
                                            <td className="col-highlight">L</td>
                                            <td className="col-highlight">22</td>
                                            <td className="col-highlight">$ 1,600.00</td>
                                            <td className="col-highlight">$ 3,200.0</td>
                                            <td className="danger-text col-highlight">paragon</td>
                                            <td className="danger-text col-highlight">Britney Spears</td>
                                            <td className="danger-text col-highlight">23/06/2022</td>
                                            <td className="danger-text col-highlight">30</td>
                                            <td className="text-center col-highlight">-</td>
                                            <td className="text-center col-highlight">
                                                <img
                                                    className="pdf-image"
                                                    src="../../assets/v2/images/icons/pdf-icon.png"
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="com-check">
                                                    <input type="checkbox" />
                                                    <label className="com-check-label" />
                                                </div>
                                            </td>
                                            <td>2</td>
                                            <td>
                                                <div className="table-pro-img">
                                                    <img src="../../assets/v2/images/AVEN-R2.png" alt="" />
                                                </div>
                                            </td>
                                            <td className="po-no">
                                                <Link> Avenues Multi-Finger Ring</Link>
                                            </td>
                                            <td> 24/05/2022</td>
                                            <td className="po-no">
                                                <Link className="danger-text">
                                                    PGRO-004
                                                </Link>
                                            </td>
                                            <td className="col-highlight">-</td>
                                            <td className="col-highlight">YG</td>
                                            <td className="col-highlight">BC</td>
                                            <td className="col-highlight">L</td>
                                            <td className="col-highlight">25</td>
                                            <td className="col-highlight">$ 1,600.00</td>
                                            <td className="col-highlight">$ 3,200.0</td>
                                            <td className="danger-text col-highlight">paragon</td>
                                            <td className="danger-text col-highlight">Britney Spears</td>
                                            <td className="danger-text col-highlight">23/06/2022</td>
                                            <td className="danger-text col-highlight">30</td>
                                            <td className="text-center col-highlight">-</td>
                                            <td className="text-center col-highlight">
                                                <img
                                                    className="pdf-image"
                                                    src="../../assets/v2/images/icons/pdf-icon.png"
                                                    alt=""
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={4} className="main-green-text left-0">
                                                <span>2</span> item selected
                                            </td>

                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td>7</td>
                                            <td />
                                            <td>$ 6,400.00</td>
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                            <td />
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

        </React.Fragment>
    );
}
export default MyInventory;