import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CheckoutBlank from '../checkout/checkoutBlank'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import './Reserve.scss'
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function'
import { Tablehead } from '../../components/common/modules/Tablehead'
import { posreserve_head } from '../../components/common/Tablehead'
import { _Api } from '../common/api/_call'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Pagination } from "../common/Pagination";

const Reserve = (props) => {
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [newcolumndata, setnewcolumndata] = useState(posreserve_head)
  const [, setfilterby] = useState('')
  const [filtername, setFiltername] = useState('')
  const [reservedata, setReservedata] = useState([])
  const [selecteditem, setSelecteditem] = useState([])
  const [SelectAllStatus, setSelectAllStatus] = useState()
  const [addparmission, setAddparmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.pos_reserve?.View)
      setAddparmission(parmissiondatashow?.pos_reserve?.Add);
  }, [parmissiondatashow]);
  const filterbyname = () => {
    getreservelist(currentPage);
    setstate({ ...state, currentPage: 1 });
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getreservelist(current, "");

  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'reserve_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const getreservelist = async (current) => {
    UnSetdataTable('reserve_pos_table')
    try {
      const postdata = {
        search: filtername,
        limit: 20,
        skip: current ? (current - 1) * 20 : 0
      }
      let res = await _Api(postdata, `api/v1/POS/common/getMyinventoryList`)
      if (res?.code === 200) {
        setstate({
          ...state,
          totalPages: Math.ceil(res?.count / postdata?.limit),
          currentPage: current ? current : 1
        });
        setReservedata(res?.data)
        SetdataTable('reserve_pos_table', 4, newcolumndata, [])
      } else {
        SetdataTable('reserve_pos_table', 4, newcolumndata, [])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getreservelist()
    // eslint-disable-next-line
  }, [newcolumndata])

  const selectedproduct = (event) => {
    if (event.target.checked) {
      const mainselected = [...selecteditem, event.target.value]
      setSelecteditem(mainselected)
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value
      })
      setSelecteditem(result)
    }
  }
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll('.reserve_item')
      let arrya = []
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id')
          arrya.push(product_id)
        }
      }
      setSelecteditem(arrya)
    } else {
      setSelecteditem([])
    }
  }, [SelectAllStatus])

  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0 ">
            <div className="catalog-area bg-white page-menu-block reserve">
              <div className="common-navigation-header">
                <div className="common-navigation-header-left">
                  <h3 className="common-navigation-header-left-heading">
                    {t('RESERVE')}
                  </h3>
                </div>
                <div className="common-navigation-header-right">
                  <div className="custom-tooltip">
                    <Link to="/app/pos/reserve/reserveorder">
                      <img
                        className="custom-order-img"
                        src={base_assets + 'images/pos/work-schedule 1.png'}
                        alt=""
                      />
                      <span className="com-btn custom-tooltip-text">
                        {t('Reserve list')}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="selection-process">
                <div className="dropdown common-dropdown diamond-dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownDiamond"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <img
                        className="reserve-sku_ring_img"
                        src={
                          base_assets + 'images/pos/icons/sku-ring-green.png'
                        }
                        alt=""
                      />
                    </span>{' '}
                    {t('Jewelry')}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownDiamond"
                  >
                    <li>
                      <Link
                        className={
                          generalsettings?.data?.generalsetup?.productChoice ===
                            '1'
                            ? 'dropdown-item'
                            : 'd-none'
                        }
                        to="/app/pos/reserve/diamondreserve"
                      >
                        <span>
                          <img
                            src={
                              base_assets +
                              'images/pos/icons/diamond-icon-green.png'
                            }
                            alt=""
                          />
                        </span>
                        {t('Diamond')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="selection-process-single">
                  <img
                    className="selection-process-single-img"
                    src={base_assets + 'images/pos/steps/s1.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Select Product')}
                  </span>
                </div>
                <span className="selection-process-line"></span>
                <div className="selection-process-single">
                  <img
                    className="selection-process-single-img"
                    src={base_assets + 'images/pos/steps/s11.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Reserve')}
                  </span>
                </div>
              </div>
              <div className="reserve-scarch">
                <div className="com-search-area com-search-area-large ">
                  <button className="com-search-area-left-img scan-img">
                    <img
                      src={base_assets + 'images/pos/icons/scan-icon.png'}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    placeholder={t('Scan barcode, Search SKU')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  <img
                    onClick={filterbyname}
                    className="com-search-area-img"
                    src={base_assets + 'images/pos/icons/magnifying-glass.png'}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="main-body-main-table-wrap reserve-table vertical-scroll-table"
                id="table-scroll"
              >
                <table
                  id="reserve_pos_table"
                  className="common-table first-row-gray w-100"
                >
                  <Tablehead
                    tablehead={posreserve_head}
                    tblname={'reserve_tbl'}
                    setShowoption={setSelectAllStatus}
                    setfilterby={setfilterby}
                  />
                  <tbody>
                    <>
                      {reservedata?.length
                        ? reservedata.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <div
                                  className={
                                    result.status_type === 'reserve' ||
                                      result.status_type === 'transit'
                                      ? 'com-check checkbox-disabled'
                                      : 'com-check'
                                  }
                                >
                                  <input
                                    id={result?.id}
                                    type="checkbox"
                                    onChange={selectedproduct}
                                    className="reserve_item pos_reservetablehead"
                                    data-id={result?.id}
                                    value={result?.id}
                                  />
                                  <label
                                    htmlFor={result?.id}
                                    className="com-check-label"
                                  ></label>
                                </div>
                              </td>
                              <td>{key + 1}</td>
                              <td>
                                <div className="pro-img">
                                  <img
                                    src={
                                      result.main_image
                                        ? result?.main_image
                                        : ''
                                    }
                                    alt=""
                                  ></img>
                                </div>
                              </td>
                              <td className="rlink-color">
                                {result?.SKU ? result?.SKU : 0}
                              </td>
                              <td>
                                {result?.collection_name
                                  ? result?.collection_name
                                  : 0}
                              </td>
                              <td>
                                {result?.metal_name ? result?.metal_name : 0}
                              </td>
                              <td>
                                {result?.stone_name ? result?.stone_name : 0}
                              </td>
                              <td>
                                {result?.size_name ? result?.size_name : 0}
                              </td>
                              <td>
                                {result?.stock_id ? result?.stock_id : 0}
                              </td>
                              <td>{result?.po_QTY ? result?.po_QTY : 0}</td>
                              <td>
                                {toFormatPrice(result?.price, {
                                  addSymbol: true,
                                })}{' '}
                              </td>
                              <td className="location-td">
                                <span className="link-color">
                                  {result?.location_name
                                    ? result?.location_name
                                    : ''}
                                </span>
                                <span className="text-center location-td-sub">
                                  {result?.warehouse_location_name
                                    ? result?.warehouse_location_name
                                    : ''}
                                </span>
                              </td>
                              <td>
                                {result.status_type === 'stock' ? (
                                  <div className="status-dot light-blue-bg  tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Stock')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'transit' ? (
                                  <div className="status-dot green-bg  tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Transit')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'warehouse' ? (
                                  <div className="status-dot status-gray-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Warehouse')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'reserve' ? (
                                  <div className="status-dot yellow-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Reserve')}
                                    </span>
                                  </div>
                                ) : result.status_type === 'repair_order' ? (
                                  <div className="status-dot purple-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Repair Order')}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="status-dot danger-bg tooltip-area-up">
                                    <span className="tooltip-area-up-text">
                                      {t('Sales Order')}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )
                        })
                        : ''}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {reservedata?.reduce(
                          (interation, val) =>
                            (interation = interation + parseInt(val.po_QTY)),
                          0,
                        )}
                      </td>
                      <td>
                        {toFormatPrice(
                          reservedata
                            ? reservedata?.reduce(
                              (interation, val) =>
                              (interation =
                                interation + toUnformatPrice(val?.price)),
                              0,
                            )
                            : '',
                          { addSymbol: true },
                        )}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>

              </div>
              <div style={{ marginBottom: "auto" }}>
                {reservedata?.length > 0 ? (
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePaginations(crPage)}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="com-data-area-footer-btn">
                <button className="com-btn disabled-hover-btn">
                  {' '}
                  {selecteditem.length > 0 ? (
                    <span>
                      {' '}
                      {selecteditem.length} {t('item selected')}
                    </span>
                  ) : (
                    ''
                  )}
                </button>
                {selecteditem?.length && addparmission ? (
                  <Link
                    to="/app/pos/reserveproduct"
                    state={{ ids: selecteditem }}
                  >
                    <button className={'com-btn'}>{t('Submit')}</button>
                  </Link>
                ) : (
                  <button className="com-btn btn-disabled">
                    {t('Submit')}
                  </button>
                )}
              </div>
            </div>

          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )
}
export default Reserve
