import React, { useState, useEffect, useRef } from "react";
import "../master.scss"
import { _Api } from "../../api/_call";
import Select, { components } from "react-select";
import { GetCountrygraph } from "../../common/Commongraphql";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const EditVendor = (peops) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { setEditItem, setEditSales, create, dataid, setDataid, setCreate } = peops
  const [country, setCountry] = useState();
  const [organization, setOrganization] = useState("")
  const [vendor_type, setVendortype] = useState("")
  const [contact_person, setContactperson] = useState("")
  const { t } = useTranslation();
  const [tax_id, setTax_id] = useState("")
  const [address, setAddress] = useState("")
  const [countryarray, setCountryarray] = useState([])
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [email, setEmail] = useState("")
  const [userImage, setUserImage] = useState("")
  const [contactArr, setContactArr] = useState([])
  const [active, setActive] = useState(true);
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const mobileImage = <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />;
  const phoneImage = <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />;
  const phoneOption = [
    {
      value: 1,
      icon: <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />
    },
    {
      value: 2,
      icon: <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />
    }
  ];
  const selectRef = useRef();
  const vendormasterview = async () => {
    if (create) {
      let contactObj = { icon: 1, code: "", no: '' };
      setVendortype("")
      setContactperson("")
      setTax_id("")
      setAddress("")
      setCountry("")
      setProvince("")
      setCity("")
      setZipcode("")
      setEmail("")
      setContactArr([contactObj])
      setActive(true)
      setUserImage("")
      setOrganization("")
    } else if (dataid) {
      try {
        let postdata = {
          "id": dataid ? dataid : ""
        }
        let res = await _Api(postdata, `api/v1/App/master/vendorMasterView`)
        if (res?.code === 200) {
          setOrganization(res?.data?.organisation)
          setVendortype(res?.data?.vendor_type)
          setContactperson(res?.data?.contact_person)
          setTax_id(res?.data?.taxId)
          setAddress(res?.data?.address)
          setCountry(res?.data?.country)
          setProvince(res?.data?.state)
          setCity(res?.data?.city)
          setZipcode(res?.data?.zip)
          setEmail(res?.data?.email)
          setContactArr(res?.data?.contacts)
          if (res?.data?.Status === "1") {
            setActive(true)
          } else {
            setActive(false)
          }

          setUserImage(res?.data?.profile)
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }


  }
  useEffect(() => {
    if (dataid || create) {
      vendormasterview()
    }
    // eslint-disable-next-line
  }, [dataid, create])

  const handleSelectChange = (selectedOption, index, code, no) => {
    const newValues = [...contactArr];
    newValues[index] = { icon: selectedOption, code: code, no: no };
    setContactArr(newValues);
  };

  const vendormasteredit = async () => {
    if (!organization) {
      error(t("Organization is required."))
      return
    }
    if (!vendor_type) {
      error(t("Vendor Type is required."))
      return
    }
    if (!contact_person) {
      error(t("Contact Person is required."))
      return
    }
    if (!address) {
      error(t("Address is required."))
      return
    }
    setEditSales(false)
    let GetloadingID = loading()
    try {
      let postdata = {
        "id": dataid,
        "profile": userImage ? userImage : "",
        "organisation": organization ? organization : "",
        "vendor_type": vendor_type ? vendor_type : "",
        "contact_person": contact_person ? contact_person : "",
        "address": address ? address : "",
        "state": province ? province : "",
        "city": city ? city : "",
        "zip": zipcode ? zipcode : "",
        "taxid": tax_id ? tax_id : "",
        "Email": email ? email : "",
        "country": country?.id ? country?.id : "",
        "Status": active ? "1" : "0",
        "contacts": contactArr,
        "edit": dataid ? true : false
      }

      let res = await _Api(postdata, `api/v1/App/master/vendorMasterEdit`)
      if (res?.code === 200) {
        if (dataid) {
          Updateloading(t, GetloadingID, (res?.message));
        } else {
          Updateloading(t, GetloadingID, (res?.message));
        }
        setEditSales(true)
        setEditItem(false)
        setDataid("")
        setCreate(false)
      } else {
        Updateloading(t, GetloadingID, res?.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const addEventList = () => {
    let newValues = [...contactArr];
    newValues.push({ icon: 2, code: "", no: '' });
    setContactArr(newValues);
  };

  const handleRemoveElement = (indexToRemove) => {
    let newValues = [...contactArr];
    let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
    setContactArr(updatedArray);

  };

  const onImageupload = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      let GetloadingID = loading();
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/App/User/uploadimage`);
        if (resfront.code === 200) {
          Updateloading(t, GetloadingID, t("Successfully Upload Image"));
          setUserImage(resfront?.data?.images?.url);
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="edit-item-area bg-white row">
        <div className="col-md-9 px-0">
          <div className="edit-item-area-body">

            <div className="edit-item-area-body-circle-img text-center mb-4">
              <div className="edit-item-area-body-circle-img-choose">
                <input id="chooseImg" type="file" className="d-none"
                  onChange={onImageupload} />
                <label htmlFor="chooseImg" className="rounded-circle overflow-hidden">
                  <img className="" src={userImage ? userImage : base_assets + "images/blank-img-2.png"} alt="" />
                </label>
              </div>
              <label>{t("File: PNG, JPG, JPEG")} <br /> {t("Maximum size 200KB")}</label>
            </div>
            <div className="row">
              <div className="col-md-12 ps-0">
                <div className="row mx-0">
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Organization")} <sup>*</sup></label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={organization || ''}
                        onChange={(e) => setOrganization(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Vendor Type")} <sup>*</sup></label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={vendor_type || ''}
                        onChange={(e) => setVendortype(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Contact Person")} <sup>*</sup></label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={contact_person || ''}
                        onChange={(e) => setContactperson(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Tax ID")}</label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={tax_id || ''}
                        onChange={(e) => setTax_id(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Address")} <sup>*</sup></label>
                      <textarea rows="3" className="v3-input-group-input w-100 h-auto"
                        value={address || ''}
                        onChange={(e) => setAddress(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Country")} <sup>*</sup></label>
                      <Select
                        className="select-set select w-100 p-0"
                        placeholder=""
                        options={countryarray}
                        value={country}
                        onChange={(e) => {
                          setCountry(e);
                          handleSelectChange(
                            contactArr[0]?.icon,
                            0,
                            e.phoneCode ? e.phoneCode : contactArr[0]?.code,
                            contactArr[0]?.no
                          )
                        }}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        classNamePrefix="common-select"
                        isSearchable={true}
                        getOptionLabel={(opt) => opt.name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Province/State")} <sup>*</sup></label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={province || ''}
                        onChange={(e) => setProvince(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("City")} <sup>*</sup></label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={city}
                        onChange={(e) => setCity(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Zip Code")} <sup>*</sup></label>
                      <input type="email" className="v3-input-group-input w-100"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Email")} </label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="v3-input-group mb-0">
                      <label className="v3-input-group-label">{t("Phone")}</label>
                    </div>
                    {contactArr?.length ? contactArr.map((index, value) => {
                      return (
                        <div className="phone-select-group mb-3"
                          id="othermobile">
                          {value !== 0 ?
                            <button className="border-0 bg-transparent p-0"
                              onClick={() => handleRemoveElement(value)}><img className="=" src={base_assets + "images/icons/minus-detail.png"} alt="" /></button>
                            : ""}
                          <div className="v3-input-group phone-select-group-icon mb-0">
                            <Select
                              ref={selectRef}
                              className="select-set select w-100 p-0"
                              placeholder=""
                              id="othermobilicon"
                              options={phoneOption}
                              value={{ value: index?.icon, icon: index?.icon === 1 ? mobileImage : phoneImage }}
                              onChange={(e) =>
                                handleSelectChange(
                                  e.value ? e.value : index?.icon,
                                  value,
                                  index?.code,
                                  index?.no
                                )}
                              classNamePrefix="common-select"
                              isSearchable={false}
                              getOptionLabel={(e) => (
                                <div className="common-select-icon">{e.icon}</div>
                              )}
                            />
                          </div>
                          <div className="d-flex align-items-center v3-input-group phone-select-group-input-grp mb-0">
                            <Select
                              className="select-set select p-0 flex-shrink-0"
                              id="otherphonecode"
                              placeholder=""
                              options={countryarray}
                              value={
                                { value: index?.code, phoneCode: index?.code }
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  index?.icon,
                                  value,
                                  e.phoneCode ? e.phoneCode : index?.code,
                                  index?.no
                                )}
                              classNamePrefix="common-select"
                              isSearchable={true}
                              getOptionLabel={(opt) => opt.phoneCode}
                            />
                            <input type="text" className="form-control border-start-0 v3-input-group-input"
                              id="othermobilenumber"
                              value={index?.no}
                              onChange={(e) => handleSelectChange(
                                index?.icon,
                                value,
                                index?.code,
                                e.target.value ? e.target.value : index?.no
                              )}
                            />
                          </div>
                        </div>
                      )
                    })
                      : ""}

                  </div>
                  <div className="col-md-12 mt-3">

                    <button className="border-0 bg-transparent p-0 main-green-text"
                      onClick={() => addEventList()}>
                      <img src={base_assets + "images/icons/add-detail.png"} alt="" />
                      <span className="ms-2"> {t("Add Phone")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 pe-0">
          <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
            <button className="square-btn square-btn-white btn-close focus-none" onClick={() => {
              setEditItem(false);
              setDataid("")
              setCreate(false)
            }}></button>
            <button className="square-btn" onClick={() => { vendormasteredit() }}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
          </div>
          <div className="text-end">
            <div className="form-check form-switch ps-0">
              <label className="form-check-label">Active</label>
              <input className="form-check-input float-none ms-2 cursor-pointer" type="checkbox"
                checked={active ? true : false}
                onChange={(e) => setActive(e.target.checked)} />
            </div>
          </div>
        </div>
        <GetCountrygraph setCountrynamearr={setCountryarray} />
      </div>

    </>
  )
}

export default EditVendor;