import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./custom.scss";
import CheckoutBlank from '../checkout/checkoutBlank';
import { _Api } from "../common/api/_call";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const Custom = () => {
  const { t } = useTranslation();
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const getCartClear = async () => {
    try {
      const postdata = {
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/cartClear`);
      if (res?.code === 200) {
        localStorage.removeItem("showcheckout")
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    getCartClear();
  }, [])
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  return (
    <>

      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area bg-white page-menu-block">
              <div className='catalog-area-header-area detail-area-header'>
                <div className='catalog-area-header-area-control'>
                  <div className='catalog-area-header-area-control-left'>
                    <h1 className='heading text-uppercase'>{t("CUSTOM ORDER")}</h1>
                  </div>
                  <div className='catalog-area-header-area-control-right'>
                    <div className={parmissiondatashow?.pos_custom_order?.View ? 'custom-tooltip' : "d-none"}>
                      <Link to="/app/pos/custom/customOrderDelivered">
                        <img className='custom-order-img' src={base_assets + "images/pos/icons/custom-order-icon.png"} alt="" />
                        <span className='com-btn custom-tooltip-text'>{t("Custom Order")}</span>
                      </Link>
                    </div>
                    <div className={parmissiondatashow?.Quotation?.View ? 'custom-tooltip ms-4' : "d-none"}>
                      <Link to="/app/pos/custom/quotationList">
                        <img className='custom-order-img' src={base_assets + "images/pos/icons/quotation-icon.png"} alt="" />
                        <span className='com-btn custom-tooltip-text'>{t("Quotation list")}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='page-menu-block-inner bg-white'>
                <h1 className="main-heading">{t("Select your option")}</h1>
                <div className="menu-block-row">
                  <div className={parmissiondatashow?.pos_custom_order?.View ? "menu-block-row-col hover-effect" : 'd-none'}>
                    <Link to="/app/pos/custom/createYourOwn" className="menu-block-row-col-a hover-effect-inner">
                      <span className="menu-block-row-col-a-img position-relative">
                        <img className="hover-effect-inner-img hover-effect-inner-img-g" src={base_assets + "images/pos/icons/custom.png"} alt="" />
                        <img className="hover-effect-inner-img hover-effect-inner-img-w" src={base_assets + "images/pos/icons/custom-w.png"} alt="" />
                      </span>
                      <span className="menu-block-row-col-a-title">{t("Create your own")}</span>
                    </Link>
                  </div>

                  <div className={generalsettings?.data?.generalsetup?.productChoice === '1' &&
                    parmissiondatashow?.pos_mix_and_match?.View ? "menu-block-row-col hover-effect" : "d-none"}>
                    <Link to="/app/pos/custom/mixAndMatch" className="menu-block-row-col-a hover-effect-inner">
                      <span className="menu-block-row-col-a-img position-relative">
                        <img
                          className="hover-effect-inner-img hover-effect-inner-img-g"
                          src={base_assets + "images/pos/icons/mix-match.png"}
                          alt=""
                        />
                        <img
                          className="hover-effect-inner-img hover-effect-inner-img-w"
                          src={base_assets + "images/pos/icons/mix-match-w.png"}
                          alt=""
                        />
                      </span>
                      <span className="menu-block-row-col-a-title">{t("Mix & Match")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div >
    </>
  )

}

export default Custom;