import React, { useState, useEffect } from 'react'
import RightArrow from "../common/icons/right-arrow";
import { Link } from 'react-router-dom'
import { _Api } from '../api/_call';
import { useTranslation } from 'react-i18next';
const CloudSetting = () => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [completeDataObject, setCompliteDataObject] = useState()
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  const getcloudsetting = async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/profile/get-config`)
      if (res?.code === 200) {
        setCompliteDataObject(res?.data)
      }
    }
    catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getcloudsetting()
  }, [])
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <Link className="text-decoration-none" to="/app/admin/settings">{t("Setting")}</Link>  <RightArrow /> <span className="">{t("Configuration")}</span></h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
          <button className="square-btn"><img src={base_assets + "images/icons/white-save.png"} alt="" /></button>
        </div>
      </div>
      <div className="admin-main-card">
        <div className="row">
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("Base Url")}</label>
              <input type="text" className="v3-input-group-input w-100"
                value={completeDataObject?.Domain || ''}
                onChange={(e) => onChangeValueUpdate("Domain", e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("IP / Host")}</label>
              <input type="text" className="v3-input-group-input w-100"
                value={completeDataObject?.Domain || ''}
                onChange={(e) => onChangeValueUpdate("Domain", e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("Database name")}</label>
              <input type="text" className="v3-input-group-input w-100"
                value={completeDataObject?.database_name || ''}
                onChange={(e) => onChangeValueUpdate("database_name", e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("Database username")}</label>
              <input type="password" className="v3-input-group-input w-100" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("Database password")}</label>
              <input type="password" className="v3-input-group-input w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CloudSetting
