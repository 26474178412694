import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { _Api, graphqlPost } from '../../common/api/_call'
import './createYourOwnSetting.scss'
import Select from 'react-select'
import { success, error } from "../../common/helpers/toastify";
import CheckoutBlank from '../../checkout/checkoutBlank'
import CheckoutSell from '../../checkout/checkoutSell'
import CheckoutPayment from '../../checkout/checkoutPayment'
import ImageUploading from 'react-images-uploading'
import ProductModal from '../../common/module/ProductModal'
import { useSelector } from 'react-redux'
import { ReactSketchCanvas } from 'react-sketch-canvas'
import { useTranslation } from 'react-i18next';
import Loading from '../../common/module/Loading/Loading';
import { store } from "../../../source/index";
const CreateYourOwnSetting = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const dropdownRef = useRef(null);
  let canvas = React.createRef()
  const { state } = useLocation()
  const customer = useSelector((state) => state.app.customer);
  const saleperson = useSelector((state) => state.app.saleperson);
  const [productdetails, setProductdetails] = useState()
  const [productfilterdetails, setProductfilterdetails] = useState()
  const [customCartdetail, setCustomCartdetail] = useState([])
  const [images, setImages] = useState([])
  const [UpdatedCart, setUpdatedCart] = useState()
  const [Loadingstatus, setLoadingstatus] = useState(false);
  const [showseq, setShowseq] = useState(0)
  const [isWarehouse, setIsWarehouse] = useState(state?.filtertype === true ? true : false);
  const [checkeditstatus, setCheckeditstatus] = useState(false);
  const [deletestatus, setdeletestatus] = useState(false)
  const [searchproductstatus, setsearchproductstatus] = useState(true)
  const [showcheckout, setShowcheckout] = useState('sell')
  const [showpayment, setShowpayment] = useState(false);
  const [searchlist, setSearchlist] = useState();
  const [colorCode, setColorCode] = useState('#000')
  const [font, setFont] = useState()
  const [cartid, setCartid] = useState()
  const [cartseq, setCartseq] = useState()
  const [logo, setLogo] = useState()
  const [customcart, setCustomcart] = useState([])
  const [canvasedit, setCanvasedit] = useState(false)
  const [editproduct, setEditproduct] = useState(false)
  const [showmodal, setShowmodal] = useState(false)
  const [servicelabour, setServicelabour] = useState([])
  const [selecteditem, setSelecteditem] = useState([]);
  const [customsecrive, setCustomsecrive] = useState(false)
  const [changeproduct, setChangeproduct] = useState(false)
  var state1 = store.getState();
  var OrgSettings = state1?.app?.posorganisation_settings;
  var currency = OrgSettings?.symbol;
  const [cart, setCart] = useState()
  const selectImage = async (imagedata) => {
    try {
      const postdata = {
        Data: [{ data_url: productdetails?.custom_design?.canvas_design ? productdetails?.custom_design?.canvas_design : imagedata }],
        name: 'custom_order_canvas',
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/uploadDesign`)
      if (res.code === 200) {
        setCanvasedit(false)
        customdesign('canvas_design', res?.data[0]?.url)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const colorPicker = (event) => {
    setColorCode(event.target.value)
  }
  const fontarray = [
    { value: 'Serif', label: 'Serif' },
    { value: 'Sans-serif', label: 'Sans-serif' },
    { value: 'Monospace ', label: 'Monospace ' },
  ]
  const updateimage = async (imageList, label) => {
    if (label === "update") {
      let array = []
      setImages(imageList)
      try {
        const postdata = {
          Data: imageList,
          name: 'custom_order_image',
        }
        let res = await _Api(postdata, `api/v1/POS/customOrder/uploadDesign`)
        if (res.code === 200) {
          res?.data.forEach((result) => {
            array.push(result?.url)
          })

          setProductdetails((state) => ({
            ...state,
            custom_design: {
              ...state?.custom_design,
              image_design: array
            }
          }))
        } else {
          setProductdetails((state) => ({
            ...state,
            custom_design: {
              ...state?.custom_design,
              image_design: []
            }
          }))
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }

  const submitdetails = async () => {
    delete productdetails?.custom_design?.image_design_base64
    delete productdetails?.custom_design_status
    try {
      const postdata = productdetails

      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/editCartItemDetails`,
      )
      if (res.code === 200) {
        setsearchproductstatus(true);
        GetcustomCartdetail()
        productdetail(productdetails?.custom_cart_id)
        setShowseq(0)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const customdesign = (lable, value) => {
    let design = { ...productdetails }
    if (lable === 'remark') {
      design.product_details["remark"] = value
    } else {
      design.custom_design[lable] = value
    }
    setProductdetails(design);
  }

  const productdetail = async (cartid, key) => {
    setLoadingstatus(true)
    try {
      const postdata = {
        custom_cart_id: cartid ? cartid : state ? state?.cartid : '',
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/getCartItemDetails`,
      )
      if (res.code === 200) {
        let imagearray = []
        setProductdetails(res.data);
        setCartid(res?.data?.custom_cart_id);
        if (key || key === 0) {
          setCartseq(key);
        }
        setFont({
          value: res.data?.custom_design?.font,
          label: res.data?.custom_design?.font,
        })
        setLogo(res.data?.custom_design?.engraving_logo)
        if (res.data?.custom_design?.image_design_base64?.length) {
          res.data?.custom_design?.image_design_base64.forEach((result) => {
            imagearray.push({ data_url: result })
          })
        }
        setImages(imagearray)
        if (!state?.order_edit) {
          setProductfilterdetails(res.data?.product_details)
        }
        setLoadingstatus(false)
      } else {
        setLoadingstatus(false)
        navigate('/app/pos/custom/createYourOwn');

      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getproductdetails = async () => {

    try {
      const postdata = {
        "productDetails": {
          "Color": productfilterdetails?.Color,
          "Metal": productfilterdetails?.Metal,
          "Size": productfilterdetails?.Size,
        },
        "cartId": state?.cartid ? state?.cartid : cart
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/editCustomOrderCart`)
      if (res?.code === 200) {
        setCanvasedit(false)
        if (!state?.order_edit) {
          if (state?.cartid) {
            productdetail(state?.cartid)
          }
        }
        GetcustomCartdetail()

      } else if (res?.code === 400) {
        error(res?.message)
        setCanvasedit(false)
        if (state?.cartid) {
          productdetail(state?.cartid)
        }
        setEditproduct(false)

      }
    }

    catch (err) {
      console.log(err, 'err')
    }
  }

  const editproduct_submit = () => {
    var mainarray = { ...productdetails }
    mainarray['product_details'] = productfilterdetails
    setProductdetails(mainarray)
    setCheckeditstatus(true);
    setEditproduct(true)

  }
  const onImageChange = (e) => {
    const [file] = e.target.files
    setLogo(URL.createObjectURL(file))
    const reader = new FileReader(file)
    reader.onloadend = async () => {
      try {
        const postdata = {
          Data: [{ data_url: reader.result }],
          name: 'custom_order_logo',
        }
        let res = await _Api(postdata, `api/v1/POS/customOrder/uploadDesign`)
        if (res.code === 200) {
          customdesign('engraving_logo', res?.data[0]?.url)
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
    if (file) {
      reader?.readAsDataURL(file);
    }
  }
  const GetcustomCartdetail = async () => {
    try {
      const postdata = {
        "order_id": state?.order_id,
        "order_edit": state?.order_edit,
        "order_type": state?.order_type,
        "editproduct": editproduct
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`);
      if (res.code === 200) {
        const sellInfoCart = res?.data?.sell_info?.cart;
        if (state?.order_id) {
          setUpdatedCart(res.data);
          setsearchproductstatus(true);
          setShowcheckout('sell');
          setCustomcart(sellInfoCart);
          if (!changeproduct) {
            if (sellInfoCart?.[0]?.custom_cart_id) {
              productdetail(sellInfoCart?.[0]?.custom_cart_id);
            }
          }
          sellInfoCart.forEach((result) => {

            setCart(result?.custom_cart_id);
            setProductdetails((state) => ({
              ...state,
              service_labour: {
                ...state?.service_labour,
                product_price: result?.service_labour?.product_price
              }
            }))
          });
        } else {
          if (editproduct) {
            setsearchproductstatus(true);
            setUpdatedCart(res.data);
            setShowcheckout('sell');
            setCustomcart(sellInfoCart);
          } else if ((!cartseq && cartseq !== 0) || deletestatus) {
            const lastIndex = res.data.length - 1;
            setCartseq(lastIndex);
            if (res.data[lastIndex]?.custom_cart_id) {
              productdetail(res.data[lastIndex]?.custom_cart_id);
            }
            setProductdetails((state) => ({
              ...state,
              service_labour: {
                ...state?.service_labour,
                product_price: res.data[lastIndex]?.price
              }
            }))
          } else {
            setProductdetails((state) => ({
              ...state,
              service_labour: {
                ...state?.service_labour,
                product_price: res.data[cartseq]?.price
              }
            }))
            if (res.data[cartseq]?.custom_cart_id) {
              productdetail(res.data[cartseq]?.custom_cart_id);
            }

          }
        }
        setCustomCartdetail(res.data);
        setdeletestatus(false);
      } else {
        error(res.message);
        navigate('/app/pos/custom/createYourOwn');
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const getsearchproductlist = async (value) => {
    try {
      const posdata = {
        "search": value,
        "type": isWarehouse ? 'catalog' : 'inventory',
        "from": 'createYourOwn'
      }
      let res = await _Api(posdata, `api/v1/POS/customOrder/skuNameBySearch`)
      if (res.code === 200) {
        setSearchlist(res?.data);
      } else {
        setSearchlist([]);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const AddtoCart = async (product_id) => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    setCartseq('');
    try {
      const postdata = {
        "product_id": product_id,
        "type": isWarehouse ? 'catalog' : 'inventory',
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "order_type": "custom_order"
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`);
      if (res.code === 200) {
        success(t("Successfully Add to Cart!!"));
        setShowcheckout('sell');
        setsearchproductstatus(true)
        GetcustomCartdetail();

      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  useEffect(() => {
    if (state?.cartid || deletestatus || state?.order_id) {
      GetcustomCartdetail();
      setChangeproduct(false)
    }
    // eslint-disable-next-line
  }, [state?.cartid, deletestatus, state?.order_id]);
  useEffect(() => {
    if (checkeditstatus) {
      getproductdetails();
    }
    // eslint-disable-next-line
  }, [checkeditstatus])
  const uploadcanvas = () => {
    setShowseq(showseq + 1)
    canvas.current
      .exportImage('png')
      .then((data) => {
        selectImage(data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getSalesList = async (props) => {

    try {
      const postdata = `{
            ServiceLabour{
                   name
                   code
                   id
               }
    }`;

      let responce = await graphqlPost(postdata, "App/graphql");
      let ServiceLabour = [];
      if (responce.data?.ServiceLabour.length) {
        responce.data?.ServiceLabour?.forEach((result) => {
          ServiceLabour.push(
            {
              id: result?.id,
              code: result?.code,
              name: result?.name


            }
          );
        })
      }

      setServicelabour(ServiceLabour);
    } catch (err) {
      console.log(err, "err");
    }

  };
  useEffect(() => {
    getSalesList();
  }, []);

  const selectedproduct = (event, id, code, name, index) => {
    if (event.target.checked) {
      let list = []
      if (productdetails?.service_labour?.service_laburelist?.length) {
        let data = [...productdetails?.service_labour?.service_laburelist]
        if (data[index]?.id !== id) {
          data.push({ id: id, name: name, code: code, scrviceamount: 0 })
        }
        setProductdetails((state) => ({
          ...state,
          service_labour: {
            ...state?.service_labour,
            service_laburelist: data
          }
        }))
      } else {
        list.push({ id: id, name: name, code: code, scrviceamount: 0 })
        setProductdetails((state) => ({
          ...state,
          service_labour: {
            ...state?.service_labour,
            service_laburelist: list
          }
        }))
      }

    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.id;
      });
      const resultlist = productdetails?.service_labour?.service_laburelist.filter((element) => {
        return element?.id !== event.target.id;
      });
      setSelecteditem(result);
      setProductdetails((state) => ({
        ...state,
        service_labour: {
          ...state?.service_labour,
          service_laburelist: resultlist
        }
      }))
    }

  };
  const deleteproduct = (id) => {

    setSelecteditem((current) =>
      current.filter((item) => item?.id === id)
    );
    setProductdetails((state) => ({
      ...state,
      service_labour: {
        ...state?.service_labour,
        service_laburelist: state?.service_labour?.service_laburelist.filter((item) => item?.id !== id)
      }
    }))
  }
  const [isSwitchActive, setIsSwitchActive] = useState(false);
  const switchActive = event => {
    setIsSwitchActive(current => !current);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSwitchActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const scrviceamount = (e, key) => {
    let array = [...productdetails?.service_labour?.service_laburelist]
    array[key] = { ...array[key], scrviceamount: parseInt(e.target.value) }
    setProductdetails((state) => ({
      ...state,
      service_labour: {
        ...state?.service_labour,
        service_laburelist: array
      }
    }))
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      total = total + array[i].scrviceamount;
    }

  }
  const scrvicelabure = async () => {
    try {
      const postdata = {
        "cart_id": cartid ? cartid : state ? state?.cartid : '',

        service_labour: {
          "service_remark": productdetails?.service_labour?.service_remark,
          "service_laburelist": productdetails?.service_labour?.service_laburelist,
          "product_price": productdetails?.service_labour?.product_price
        },
        service_saved: productdetails?.service_labour?.service_laburelist?.length ? true : false,
        "order_edit": state?.order_edit,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/saveServiceLabour`)
      if (res.code === 200) {
        if (res?.data === true) {
          setCustomsecrive(true)
        }
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            {
              Loadingstatus ? <Loading /> : ''
            }
            <div className="catalog-area bg-white page-menu-block">
              <div className="common-navigation-header">
                <div className="common-navigation-header-left">
                  <h3 className="common-navigation-header-left-heading">
                    <Link
                      to="/app/pos/custom/createYourOwn"
                      className="gray-mid text-decoration-none"
                      state={{ optiondetailid: isWarehouse }}
                    >
                      {t("CUSTOM ORDER")}
                    </Link>
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'}
                      alt=""
                    />
                    {t("Create your own")}
                  </h3>
                </div>

                <div className="common-navigation-header-right">
                  <div className="com-search-area com-search-area-large">
                    <div
                      className={
                        isWarehouse
                          ? "com-search-area-large-home warehouse"
                          : "com-search-area-large-home"
                      }
                      onClick={() => setIsWarehouse(current => !current)}
                    >
                      <img
                        className="img-1"
                        src={base_assets + "images/pos/icons/warehouse.png"}
                        alt=""
                      />
                      <img
                        className="img-2"
                        src={base_assets + "images/pos/icons/crown-outline.png"}
                        alt=""
                      />
                    </div>
                    <button className="com-search-area-left-img scan-img">
                      <img src={base_assets + "images/pos/icons/scan-icon.png"} alt="" />
                    </button>

                    <input
                      type="text"
                      onChange={(e) => { getsearchproductlist(e.target.value) }}
                      placeholder={t("Scan barcode, Search SKU")}
                      id="dropdownMenuButton1" data-bs-toggle="dropdown" autoComplete="off"
                    />
                    <ul className="dropdown-menu custom-search-dropdown" aria-labelledby="dropdownMenuButton1">
                      {searchlist?.length ? searchlist.map((result, key) => {
                        return (
                          <li
                            key={key}>
                            <div className="dropdown-item"
                              onClick={() => AddtoCart(result?._id)}
                            >
                              {result?.name}<span>{result?.SKU}{result?.stock_id ? '-' + result?.stock_id : ''}
                              </span></div>
                          </li>
                        );
                      }) : ''}
                    </ul>
                    <img
                      className="com-search-area-img" style={{ margin: "0 0.7vw" }}
                      src={base_assets + "images/pos/icons/magnifying-glass.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="selection-process">
                <div className="selection-process-single active-green ">
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s1.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s1-g.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-yellow"
                    src={base_assets + 'images/pos/steps/s1-y.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t("Select Setting")}
                  </span>
                </div>
                <span className="selection-process-line"></span>
                <div
                  className={
                    productdetails?.custom_design_status === 1
                      ? 'selection-process-single active-green'
                      : 'selection-process-single'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s2.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s2-g.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">{t("Design")}</span>
                </div>
              </div>
              <div className="create-own-setting">
                {state?.order_id || editproduct ?
                  <div className="create-own-setting-left">
                    <ul className="create-own-setting-left-ul">
                      {customcart?.length ? customcart.map((result, cartkey) => {
                        return (
                          <li
                            className="create-own-setting-left-ul-li"
                            onClick={() => {
                              productdetail(result?.custom_cart_id, cartkey);
                              setChangeproduct(true);
                              setShowseq(0)
                            }
                            }
                            key={cartkey}
                          >
                            <button className={cartid === result?.custom_cart_id ? "create-own-setting-left-ul-li-btn btn-style active" : "create-own-setting-left-ul-li-btn btn-style"}>
                              <img
                                className="create-own-setting-left-ul-li-btn-img"
                                src={result?.main_image}
                                alt=""
                              />
                            </button>
                          </li>
                        )
                      }) : ""

                      }


                    </ul>
                    <Link to="/app/pos/custom/createYourOwn" state={{ optiondetailid: isWarehouse, changecolor: 'yellow', order_edit: state?.order_edit, order_type: state?.order_type, order_id: state?.order_id }} className='btn create-own-setting-left-ul-add-more'>
                      <img src={base_assets + 'images/icons/adplus.png'} alt="" />
                    </Link>
                  </div> :
                  <div className="create-own-setting-left">
                    <ul className="create-own-setting-left-ul">

                      {customCartdetail?.length
                        ? customCartdetail.map((result, cartkey) => {
                          return (
                            <li
                              className="create-own-setting-left-ul-li"
                              onClick={() => {
                                productdetail(result?.custom_cart_id, cartkey);
                                setShowseq(0)
                              }

                              }
                              key={cartkey}
                            >
                              <button className={cartid === result?.custom_cart_id ? "create-own-setting-left-ul-li-btn btn-style active" : "create-own-setting-left-ul-li-btn btn-style"}>
                                <img
                                  className="create-own-setting-left-ul-li-btn-img"
                                  src={result?.main_image}
                                  alt=""
                                />
                              </button>
                            </li>
                          )
                        }) : ""
                      }
                    </ul>
                    <Link to="/app/pos/custom/createYourOwn" state={{ optiondetailid: isWarehouse, changecolor: 'yellow' }} className='btn create-own-setting-left-ul-add-more'>
                      <img src={base_assets + 'images/icons/adplus.png'} alt="" />
                    </Link>
                  </div>
                }
                <div className="create-own-setting-right">
                  <div className="create-own-setting-right-product-detail">
                    <div className="create-own-setting-right-product-detail-left">
                      <img
                        className="create-own-setting-right-product-detail-left-img"
                        src={productdetails?.product_details?.main_image}
                        alt=""
                      />
                    </div>
                    <div className="create-own-setting-right-product-detail-right">
                      <h3 className="create-own-setting-right-product-detail-right-title">
                        {productdetails?.product_details?.name}
                      </h3>
                      <button
                        className="create-own-setting-right-product-detail-right-sku btn-style text-decoration-none"
                        data-bs-toggle="modal"
                        data-bs-target="#ProductInfoModal"
                      >
                        {productdetails?.product_details?.SKU}
                      </button>
                      <p className="create-own-setting-right-product-detail-right-para">
                        {productdetails?.product_details?.meta_tag}
                      </p>
                      <div className="create-own-setting-right-product-detail-right-detail">
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t("Metal")} :
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.product_details?.metal_name}
                          </span>
                        </div>
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t("Stone")} :
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.product_details?.stone_name}
                          </span>
                        </div>
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t("Size")} :
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.product_details?.size_name}
                          </span>
                        </div>
                      </div>

                      <textarea
                        className="create-own-setting-right-product-detail-right-remark"
                        onChange={(e) =>
                          customdesign('remark', e?.target.value)
                        }
                        placeholder={t("Remark")}
                        value={productdetails?.product_details?.remark ? productdetails?.product_details?.remark : ''}
                      ></textarea>
                    </div>
                  </div>

                  <div className="create-own-setting-right-sketch-area">
                    <div className="selection-process selection-process-second bg-white">
                      <div
                        className={
                          productdetails?.custom_design_status === 1
                            ? showseq >= 1 || showseq >= 0
                              ? 'selection-process-single active-yellow '
                              : 'selection-process-single active-green'
                            : showseq >= 1
                              ? 'selection-process-single active-green '
                              : 'selection-process-single'
                        }
                      >
                        <img
                          className="selection-process-single-img img-gray"
                          src={base_assets + 'images/pos/steps/s6.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img  img-green "
                          src={base_assets + 'images/pos/steps/s6-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img  img-yellow"
                          src={base_assets + 'images/pos/steps/s6-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t("Concept")}
                        </span>
                      </div>
                      <span className="selection-process-line"></span>
                      <div
                        className={
                          productdetails?.custom_design_status === 1
                            ? showseq >= 2
                              ? 'selection-process-single active-yellow '
                              : 'selection-process-single active-green'
                            : showseq >= 2
                              ? 'selection-process-single active-green '
                              : 'selection-process-single'
                        }
                      >
                        <img
                          className="selection-process-single-img  img-gray"
                          src={base_assets + 'images/pos/steps/s7.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img  img-green "
                          src={base_assets + 'images/pos/steps/s7-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-yellow"
                          src={base_assets + 'images/pos/steps/s7-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t("Sketch")}
                        </span>
                      </div>
                      <span className="selection-process-line"></span>
                      <div
                        className={
                          productdetails?.custom_design_status === 1
                            ? showseq >= 3
                              ? 'selection-process-single active-yellow '
                              : 'selection-process-single active-green'
                            : showseq >= 3
                              ? 'selection-process-single active-green '
                              : 'selection-process-single'
                        }
                      >
                        <img
                          className="selection-process-single-img img-gray"
                          src={base_assets + 'images/pos/steps/s8.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-green"
                          src={base_assets + 'images/pos/steps/s8-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-yellow"
                          src={base_assets + 'images/pos/steps/s8-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t("Picture")}
                        </span>
                      </div>
                      <span className="selection-process-line"></span>
                      <div
                        className={
                          productdetails?.custom_design_status === 1
                            ? showseq > 3
                              ? 'selection-process-single active-yellow '
                              : 'selection-process-single active-green'
                            : showseq > 3
                              ? 'selection-process-single active-green '
                              : 'selection-process-single'
                        }
                      >
                        <img
                          className="selection-process-single-img img-gray"
                          src={base_assets + 'images/pos/steps/s4.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-green"
                          src={base_assets + 'images/pos/steps/s4-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-yellow"
                          src={base_assets + 'images/pos/steps/s4-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t("Engraving")}
                        </span>
                      </div>
                      <span className={generalsettings?.data?.generalsetup?.servicelabers === '1' ? "selection-process-line" : "d-none"}></span>
                      <div
                        className={
                          generalsettings?.data?.generalsetup?.servicelabers === '1' ?
                            productdetails?.custom_design_status === 1
                              ? showseq > 4
                                ? 'selection-process-single active-yellow '
                                : 'selection-process-single active-green'
                              : showseq > 4
                                ? 'selection-process-single active-green '
                                : 'selection-process-single'
                            : "d-none"
                        }
                      >
                        <img
                          className="selection-process-single-img img-gray"
                          src={base_assets + 'images/pos/steps/s10.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-green"
                          src={base_assets + 'images/pos/steps/s10-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-yellow"
                          src={base_assets + 'images/pos/steps/s10-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t("Service")}
                        </span>
                      </div>
                    </div>
                    <div className="create-own-setting-right-sketch-area-inner">
                      <div className="create-own-setting-right-sketch-area-inner-border">
                        {showseq === 0 ? (
                          <div className="create-own-setting-right-sketch-area-inner-concept">
                            <label className="create-own-setting-right-sketch-area-inner-concept-label">
                              {t("Description")} :
                            </label>
                            <textarea
                              className="create-own-setting-right-sketch-area-inner-concept-text"
                              placeholder=""
                              onChange={(e) =>
                                customdesign('description', e.target.value)
                              }
                              value={productdetails?.custom_design?.description || ''}
                            ></textarea>
                          </div>
                        ) : showseq === 1 ? (
                          <div className="create-own-setting-right-sketch-area-inner-sketch">
                            <div className="create-own-setting-right-sketch-area-inner-sketch-area">
                              <ReactSketchCanvas
                                className="canvasBoard craft"
                                ref={canvas}
                                strokeWidth={10}
                                strokeColor={colorCode}
                                canvasColor="#fff"
                              />
                              {
                                !canvasedit &&
                                productdetails?.custom_design?.canvas_design &&
                                <div className="create-own-setting-right-sketch-area-inner-sketch-area-getimage">
                                  <img className="india" src={productdetails?.custom_design?.canvas_design} alt="" />
                                </div>

                              }
                              <div className="create-own-setting-right-sketch-area-inner-sketch-area-control">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    canvas.current.eraseMode(false)
                                  }}
                                >
                                  {/* Pen */}
                                  {state?.order_edit ? <img onClick={() => { setCanvasedit(true) }} className="brush-img"
                                    src={
                                      base_assets +
                                      'images/pos/canvas/brush-1.png'
                                    } alt=""
                                  /> :
                                    <img className="brush-img"
                                      onClick={() => { setCanvasedit(true) }}
                                      src={
                                        base_assets +
                                        'images/pos/canvas/brush-1.png'
                                      } alt=""
                                    />}

                                </div>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    canvas.current.eraseMode(true)
                                  }}
                                >
                                  {/* Eraser */}
                                  <img
                                    className="brush-img"
                                    src={
                                      base_assets +
                                      'images/pos/canvas/brush-4.png'
                                    } alt=""
                                  />
                                </div>
                                <input
                                  className="create-own-setting-right-sketch-area-inner-sketch-area-control-colorpick"
                                  type="color"
                                  onChange={colorPicker}
                                  value={colorCode || ''}
                                />

                                <button
                                  className="create-own-setting-right-sketch-area-inner-sketch-area-control-btn"
                                  onClick={() => {
                                    canvas.current.undo()
                                  }}
                                >
                                  <i
                                    className="fa fa-undo"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  className="create-own-setting-right-sketch-area-inner-sketch-area-control-btn"
                                  onClick={() => {
                                    canvas.current.redo()
                                  }}
                                >
                                  <i
                                    className="fa fa-repeat"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  className="create-own-setting-right-sketch-area-inner-sketch-area-control-btn"
                                  onClick={() => {
                                    canvas.current.resetCanvas()
                                  }}
                                >
                                  <i
                                    className="fa fa-dot-circle-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : showseq === 2 ? (
                          <div className="create-own-setting-right-sketch-area-inner-picture">
                            <p className="create-own-setting-right-sketch-area-inner-picture-label">
                              {t("Drag & Drop your pictures here")} <br />
                              {t("or")}
                            </p>
                            <div className="gallery-upload-area create-own-setting-right-sketch-area-inner-picture-upload">
                              <div className="App">
                                <ImageUploading
                                  multiple
                                  value={images || ''}
                                  onChange={(e) => updateimage(e, "update")}
                                  maxNumber={6}
                                  dataURLKey="data_url"
                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    onImageUpdate,
                                    onImageRemove,
                                  }) => (
                                    <div className="gallery-upload-area-wrap">
                                      <div className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row">
                                        <button
                                          type="button"
                                          className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn btn-style"
                                          onClick={onImageUpload}
                                        >
                                          <img

                                            className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn-img"
                                            src={
                                              base_assets +
                                              'images/pos/icons/add-img.png'
                                            }
                                            alt=""
                                          />
                                        </button>
                                        <button className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn   btn-style">
                                          <img
                                            className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn-img"
                                            src={
                                              base_assets +
                                              'images/pos/icons/camera-icon-w.png'
                                            }
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                      <div className="gallery-upload-area-wrap-row">
                                        {productdetails?.custom_design?.image_design?.length ? productdetails?.custom_design?.image_design.map((image, index) => (
                                          <div
                                            key={index}
                                            className="gallery-upload-area-wrap-row-img"
                                          >
                                            <img
                                              className="img"
                                              onClick={() =>
                                                onImageUpdate(index)
                                              }
                                              src={image}
                                              alt=""
                                              width="100"
                                            />
                                            <button
                                              type="button"
                                              className="gallery-upload-area-wrap-row-img-close"
                                              onClick={() => {
                                                onImageRemove(index);
                                              }}
                                            >
                                              <img
                                                src={
                                                  base_assets +
                                                  'images/icons/minus-detail.png'
                                                } alt=""
                                              />
                                            </button>
                                          </div>
                                        )) : ""}
                                      </div>
                                    </div>
                                  )}
                                </ImageUploading>
                              </div>
                            </div>
                          </div>
                        ) : showseq === 3 ? (
                          <div className="create-own-setting-right-sketch-area-inner-engraving">
                            <div className="create-own-setting-right-sketch-area-inner-engraving-form">
                              <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner">
                                <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-col">
                                  <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp">
                                    <label className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label">
                                      {t("Text")}
                                    </label>
                                    <textarea
                                      className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-field com-input-field"
                                      placeholder={t("Write your text here")}
                                      row="3"
                                      onChange={(e) =>
                                        customdesign(
                                          'engraving_text',
                                          e.target.value,
                                        )
                                      }
                                      value={
                                        productdetails?.custom_design
                                          ?.engraving_text
                                        || ''}
                                    ></textarea>
                                  </div>
                                  <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp">
                                    <label className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label">
                                      {t("Position")}
                                    </label>
                                    <input
                                      className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-field com-input-field"
                                      value={
                                        productdetails?.custom_design
                                          ?.engraving_position || ''
                                      }
                                      onChange={(e) =>
                                        customdesign(
                                          'engraving_position',
                                          e.target.value,
                                        )
                                      }
                                      placeholder={t("Which position would you like to engraving ?")}
                                    />
                                  </div>
                                </div>
                                <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-col">
                                  <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp">
                                    <label className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label">
                                      {t("logo")}
                                    </label>
                                    <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop">
                                      <input
                                        className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-input"
                                        type="file"
                                        onChange={(e) => onImageChange(e)}
                                        accept="image/*"
                                      />

                                      {logo ? (
                                        <img
                                          className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-img"
                                          src={logo} alt=""
                                        />
                                      ) : (
                                        <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-inner">
                                          <img
                                            className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-drop-inner-icon"
                                            src={
                                              base_assets +
                                              'images/pos/icons/add-img-g.png'
                                            }
                                            alt=""
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner">
                                <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-col">
                                  <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp">
                                    <label className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label">
                                      {t("Font")}
                                    </label>
                                    <Select
                                      value={font || ''}
                                      onChange={(e) => {
                                        customdesign('font', e?.value)
                                        setFont(e)
                                      }}
                                      options={fontarray}
                                      classNamePrefix="common-select"
                                      placeholder={t("Select Sale Person")}
                                      isSearchable={false}
                                    />
                                    <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-design">
                                      <span
                                        className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-design-text"
                                        style={{
                                          fontFamily:
                                            productdetails?.custom_design?.font,
                                          'textAlign':
                                            productdetails?.custom_design
                                              ?.engraving_position,
                                        }}
                                      >
                                        {
                                          productdetails?.custom_design
                                            ?.engraving_text
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-col">
                                  <div className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp">
                                    <label className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-label">
                                      {t("Position")}
                                    </label>
                                    <input
                                      className="create-own-setting-right-sketch-area-inner-engraving-form-inner-grp-field com-input-field"
                                      placeholder={t("Which position would you like to engraving ?")}
                                      value={
                                        productdetails?.custom_design
                                          ?.logo_position || ''
                                      }
                                      onChange={(e) =>
                                        customdesign(
                                          'logo_position',
                                          e.target.value,
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) :
                          (<span>
                            <div className=''>
                              <div className='d-flex justify-content-between'>
                                <div className='switch-select-area text-start' style={{ width: "23.625vw" }}>
                                  <div className='d-flex'>
                                    <label className="service-modal-label pb-2 switch-select-area-label">
                                      {t('Service Labour')}
                                    </label>

                                    {showmodal || productdetails?.service_labour?.service_laburelist?.length ? (
                                      <span className='ms-auto danger-text cursor-pointer' style={{ fontSize: "0.9vw" }}
                                        onClick={(e) => {
                                          setProductdetails((state) => ({
                                            ...state,
                                            service_labour: {
                                              ...state?.service_labour,
                                              service_laburelist: [],
                                              service_remark: ""
                                            }
                                          }))
                                        }}
                                      >{t("Remove All")}</span>
                                    ) : ""}
                                  </div>

                                  <div
                                    className="dropdown common-dropdown service-modal-content-dropdown switch-select-area-dropdown"
                                    ref={dropdownRef}
                                  >
                                    <button
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownDiamond"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-bs-auto-close="outside"
                                      onClick={switchActive}
                                    >
                                      {t("Service Labour")}
                                    </button>
                                    <ul

                                      className={isSwitchActive ? "dropdown-menu switch-select-area-dropdown-menu active" : "dropdown-menu switch-select-area-dropdown-menu"}
                                      aria-labelledby="dropdownDiamond"
                                    >
                                      {servicelabour?.length ? servicelabour.map((result, key) => {
                                        return (
                                          <li className="dropdown-item"
                                            key={key}>
                                            <div className="com-check">
                                              <input
                                                checked={productdetails.service_labour.service_laburelist.some(item => item.id === result?.id)}
                                                type="checkbox"
                                                className='scrvicecheck'
                                                id={result?.id}
                                                data-code={result?.code}
                                                data-name={result?.name}
                                                onChange={(e) => selectedproduct(e, result?.id, result?.code, result?.name, key)}
                                              />
                                              <label
                                                htmlFor={result?.id}
                                                className="com-check-label"
                                              ></label>
                                            </div>
                                            <label >{result?.code}</label>
                                            <label onClick={() => { setShowmodal(true); switchActive() }} >{result?.name}</label>
                                          </li>
                                        )


                                      }) : ""}

                                    </ul>
                                    {showmodal || productdetails?.service_labour?.service_laburelist?.length ? (
                                      <ul className="switch-select-area-dropdown-menu switch-select-area-dropdown-menu-2">
                                        {productdetails?.service_labour?.service_laburelist?.length ?
                                          productdetails?.service_labour?.service_laburelist.map((result, key) => {
                                            return (
                                              <li className="dropdown-item service-modal-content-dropdown-item"
                                                key={key}>
                                                <div className="">
                                                  <img style={{ width: "1.25vw" }}
                                                    onClick={() => { deleteproduct(result?.id) }}
                                                    src={base_assets + 'images/pos/icons/red-delete.png'} alt=""
                                                  />
                                                </div>
                                                <div>{result?.code}</div>
                                                <div className='switch-select-area-dropdown-item-name'>{result?.name}</div>
                                                <div className="ms-auto">{currency}
                                                  <input type="number"
                                                    value={result?.scrviceamount ? result?.scrviceamount : ""}
                                                    onChange={(e) => scrviceamount(e, key)}
                                                    className="active-input arrow-none text-end" />
                                                </div>
                                              </li>
                                            )
                                          }) : <li>{t("No Data Available")}</li>}
                                      </ul>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <div className='w-50'>
                                  <label className="create-own-setting-right-sketch-area-inner-concept-label mb-2">{t("Remark")}</label>
                                  <textarea className="create-own-setting-right-sketch-area-inner-concept-text"
                                    value={productdetails?.service_labour?.service_remark || ''}
                                    placeholder="Remark"
                                    onChange={(e) =>
                                      setProductdetails((state) => {
                                        if (
                                          state?.service_labour?.service_laburelist?.length &&
                                          e.target.value.trim() !== ""
                                        ) {
                                          return {
                                            ...state,
                                            service_labour: {
                                              ...state.service_labour,
                                              service_remark: e.target.value,
                                            },
                                          };
                                        }
                                        return state;
                                      })
                                    }></textarea>
                                </div>
                              </div>
                            </div>
                          </span>)
                        }
                      </div>
                      <div className="create-own-setting-right-sketch-area-inner-btn">
                        {generalsettings?.data?.generalsetup?.servicelabers === '1' ?
                          showseq > 0 && showseq !== 4 ? (
                            <button
                              onClick={() => {
                                if (showseq > 0) {
                                  setShowseq(showseq - 1)
                                }
                              }}
                              className="hover-effect-inner com-btn com-btn-border"
                            >
                              {t("back")}
                            </button>
                          ) : "" : (
                            showseq > 0 && showseq !== 3 ? (
                              <button
                                onClick={() => {
                                  if (showseq > 0) {
                                    setShowseq(showseq - 1)
                                  }
                                }}
                                className="hover-effect-inner com-btn com-btn-border"
                              >
                                {t("back")}
                              </button>
                            ) : ""
                          )}
                        {generalsettings?.data?.generalsetup?.servicelabers === '1' ?
                          showseq !== 4 ? (
                            <button
                              onClick={() => {
                                if (showseq < 4) {
                                  showseq === 1
                                    ? uploadcanvas()
                                    : setShowseq(showseq + 1)
                                }
                              }}
                              className="hover-effect-inner com-btn"
                            >
                              {t("next")}
                            </button>
                          ) : "" : (
                            showseq !== 3 ? (
                              <button
                                onClick={() => {
                                  if (showseq < 3) {
                                    showseq === 1
                                      ? uploadcanvas()
                                      : setShowseq(showseq + 1)
                                  }
                                }}
                                className="hover-effect-inner com-btn"
                              >
                                {t("next")}
                              </button>
                            ) : ""

                          )}
                        {generalsettings?.data?.generalsetup?.servicelabers === '1' ? showseq === 4 ? (
                          <button
                            onClick={() => {
                              scrvicelabure();
                              submitdetails();
                              setCartseq((customCartdetail?.length - 1) > cartseq ? cartseq + 1 : 0);
                              setCustomsecrive(false)
                            }}
                            className="hover-effect-inner com-btn"
                          >
                            {t("finish")}
                          </button>
                        ) : (
                          ''
                        ) : showseq === 3 ? (
                          <button
                            onClick={() => {
                              submitdetails();
                              setCartseq((customCartdetail?.length - 1) > cartseq ? cartseq + 1 : 0);
                              setCustomsecrive(false)
                            }}
                            className="hover-effect-inner com-btn"
                          >
                            {t("finish")}
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col overflow-hidden">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                searchproductstatus={searchproductstatus}
                setsearchproductstatus={setsearchproductstatus}
                order_id={state?.order_id}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
                customsecrive={customsecrive}
                ordertype={"custom_order"}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} order_edit={state?.order_edit} order_id={state?.order_id} order_type={state?.order_type} />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>

      <ProductModal
        data_product={productfilterdetails}
        setOneproduct={setProductfilterdetails}
        editproduct_submit={editproduct_submit}

      />
    </>
  )
}
export default CreateYourOwnSetting;
