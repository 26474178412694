import React, { useState } from "react";
import { _Api } from "../../api/_call";
import { useNavigate, } from "react-router-dom";
import { success } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
const DataPurgeModal = props => {
  const { dataPurge, } = props;
  let navigate = useNavigate();
  const [Masters, setMasters] = useState(false);
  const [ProductMasters, setProductMasters] = useState(false);
  const [Transaction, setTransaction] = useState(false);
  const { t } = useTranslation();
  const removedata = async () => {
    try {
      const postdata = {
        master: Masters ? 1 : 0,
        productMaster: ProductMasters ? 1 : 0,
        transaction: Transaction ? 1 : 0
      };
      let res = await _Api(postdata, `api/v1/App/rmcollection/data`);
      if (res.code === 200) {
        if (Transaction === 1 && ProductMasters === 1) {
          success(t("Clear Data SuccessFully"));
          navigate("/app/product/main");
        } else if (Transaction === 1) {
          success(t("Clear Data SuccessFully"));
          navigate("/app/inventory");
        } else {
          success(t("Clear Data SuccessFully"));
          navigate("/app/product/main");
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  return (
    <>
      {dataPurge ?
        <div className="modal fade data-purge-modal" id="dataPurgeModal" tabIndex="-1" aria-labelledby="dataPurgeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="dataPurgeModalLabel">{t("Data Purge")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="checkbox-row">
                  <div className="checkbox checkbox-custom checkbox-row-single">
                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="Masters"
                        checked={Masters ? 'checked' : ''}
                        onChange={(e) => setMasters(e.target.checked)}
                      />
                      <label htmlFor="Masters" className="com-check-label"></label>
                    </div>
                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="Masters"
                    >
                      {t("Masters")}
                    </label>
                  </div>
                  <div className="checkbox checkbox-custom checkbox-row-single">
                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="ProductMasters"
                        checked={ProductMasters ? 'checked' : ''}
                        onChange={(e) => setProductMasters(e.target.checked)}
                      />
                      <label htmlFor="ProductMasters" className="com-check-label"></label>
                    </div>
                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="ProductMasters"
                    >
                      {t("Product Masters")}
                    </label>
                  </div>
                  <div className="checkbox checkbox-custom checkbox-row-single">
                    <div className="com-check" id="">
                      <input
                        type="checkbox"
                        id="Transaction"
                        checked={Transaction ? 'checked' : ''}
                        onChange={(e) => setTransaction(e.target.checked)}
                      />
                      <label htmlFor="Transaction" className="com-check-label"></label>
                    </div>
                    <label
                      className="mb10 label-control tooltipfull p-l-10 checkbox_sm v-m"
                      htmlFor="Transaction"
                    >
                      {t("Transaction")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" data-bs-dismiss="modal" onClick={removedata} className="com-btn">{t("Submit")}</button>
              </div>
            </div>
          </div>
        </div>
        : null}
    </>
  );
};

export default DataPurgeModal;