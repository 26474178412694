import axios from 'axios';
export const _Api = function (querystring = "", Url = "") {
    return new Promise(async (resolve, reject) => {

        var data = querystring;
        var config = {
            method: 'post',
            url: process.env.REACT_APP_ECOM_API_URL + Url,
            headers: {
                'authorization': localStorage.getItem("ecomaccesstoken"),
                'Content-Type': 'application/json',
                'authorization-store': localStorage.getItem("storetoken")
            },
            data: data
        };
        await axios(config)
            .then(function (response) {
                if (response?.data?.statusCode === 404) {
                } else if (response?.data?.statusCode === 403) {
                    localStorage.removeItem("ecomaccesstoken");
                } else {
                    resolve(response?.data);
                }
            })
            .catch(function (error) {
                if (error?.response?.data?.statusCode === 401) {
                    window.location.href = "/logout"
                    localStorage.removeItem("ecomaccesstoken");
                }
                reject(error);
            });
    });
};
export const _Apiauth = function (querystring = "", Url = "") {
    return new Promise(async (resolve, reject) => {
        var data = querystring;
        var config = {
            method: 'post',
            url: process.env.REACT_APP_ECOM_API_URL + Url,
            headers: {
                'authorization-store': localStorage.getItem("storetoken"),
                'Content-Type': 'application/json',
            },
            data: data
        };
        await axios(config)
            .then(function (response) {
                if (response?.data?.statusCode === 403) {
                    localStorage.removeItem("ecomaccesstoken");
                } else {
                    resolve(response?.data);
                }
            })
            .catch(function (error) {
                console.log(error, "error");
                reject(error);
            });
    });
};