import './accountpage.scss'
import React, { useEffect, useState } from "react";
import NoImage from "Ecommerce/template_three/assets/images/Noimage.png";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { _Api, _Apiauth } from 'Ecommerce/common/api';
import MinusIcon from 'Ecommerce/template_three/assets/icons/MinusIcon';
import PlusIcon from 'Ecommerce/template_three/assets/icons/PlusIcon';
import EditIcon from 'Ecommerce/template_three/assets/icons/EditIcon';
import Sidebar from './Sidebar';
import billingandShippingCardImage from 'Ecommerce/common/images/billingandshipping.png'
import AppointmentCardImage from 'Ecommerce/common/images/appointmentcardimg.png'
import ChangePasswordCardImage from 'Ecommerce/common/images/changepwdcardimg.png'
import PurchaseHistoryCardImage from 'Ecommerce/common/images/purchasehistorycardimg.png'
import './accountpage.scss'
import LeftIcon from 'admin/common/icons/sidebar/LeftIcon';
import useScreenSize from 'Ecommerce/template_three/include/Common/useScreenSize';
import RightArrow from 'admin/common/icons/right-arrow';

export default function AccountIndexPage() {
    const { t } = useTranslation();
    const screenSize = useScreenSize();
    const [fileName, setFileName] = useState("");
    const [disableFiled, setDisableFiled] = useState(true);
    const [fromvalidaction, setFromvalidaction] = useState();
    const navigate = useNavigate();
    const [accountdata, setAccountdata] = useState({
        contacts: [{ icon: 1, phoneCode: "", number: "" }],
    });
    const [selectdata, setSelectdata] = useState({ country: [], phoneCode: [] });
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    try {
                        const postdata = {
                            image: reader.result,
                        };
                        let res = await _Api(postdata, `redis/uplaod`)
                        if (res.statusCode === 200) {
                            setalldata("profile", res?.url);
                            setFileName(file?.name);
                        }
                    }
                    catch (err) {
                        console.log(err, "err")
                    }
                };
                if (file) {
                    reader?.readAsDataURL(file);
                }
            } else {
                alert("Please select a valid image file.");
            }
        }
    };
    const setalldata = (keyname, value) => {
        setAccountdata((privasestate) => ({
            ...privasestate,
            [keyname]: value,
        }));
    };
    const getaccountdetails = async () => {
        try {
            let postdata = {};
            let res = await _Api(postdata, `account/getDetails`);
            if (res?.statusCode === 200) {
                setAccountdata(res?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    const getcountry = async () => {
        try {
            let postdata = {};
            let res = await _Apiauth(postdata, `redis/countryList`)
            if (res?.statusCode === 200) {
                let countrydata = [];
                let phonecode = [];
                res?.data.forEach((result) => {
                    countrydata.push({
                        label: result?.name,
                        value: result?.id,
                    });
                    phonecode.push({
                        label: result?.phoneCode,
                        value: result?.phoneCode,
                    });
                });
                setSelectdata({ country: countrydata, phoneCode: phonecode });
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        getaccountdetails();
        getcountry();
    }, []);
    const addEventList = () => {
        let newValues_ = { ...accountdata };
        let newValues = [...newValues_?.contacts];
        newValues.push({ icon: 1, phoneCode: "", number: "" });
        newValues_.contacts = newValues;
        setAccountdata(newValues_);
    };
    const handleSelectChange = (index, code, no) => {
        let newValues_ = { ...accountdata };
        let newValues = [...newValues_?.contacts];
        newValues[index] = { icon: 1, phoneCode: code, number: no };
        newValues_.contacts = newValues;
        setAccountdata(newValues_);
    };
    const handleRemoveElement = (indexToRemove) => {
        let newValues_ = { ...accountdata };
        let newValues = [...newValues_?.contacts];
        let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
        newValues_.contacts = updatedArray;
        setAccountdata(newValues_);
    };
    const createaccount = async () => {
        try {
            let objdata = { ...accountdata };
            objdata.country = accountdata?.country?.value;
            if (!objdata.profile) {
                delete objdata.profile;
            }
            const validation = () => {
                let formIsValid = true;
                if (!objdata?.fname) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        namevalid: "First Name is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        namevalid: "",
                    }));
                }

                if (!objdata?.lname) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        lnamevalid: "Last Name is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        lnamevalid: "",
                    }));
                }

                if (!objdata?.address) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        addressvalid: "Address is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        addressvalid: "",
                    }));
                }

                if (!objdata?.country) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        countryvalid: "Country is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        countryvalid: "",
                    }));
                }
                if (!objdata?.gender) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        gendervalid: "Gender is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        gendervalid: "",
                    }));
                }
                if (!objdata?.state) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        statevalid: "State is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        statevalid: "",
                    }));
                }

                if (!objdata?.city) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        cityvalid: "City is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        cityvalid: "",
                    }));
                }

                if (!objdata?.zipcode) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        zipcodevalid: "Invalid Zipcode",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        zipcodevalid: "",
                    }));
                }

                if (!objdata?.email) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        emailvalid: " Email Required",
                    }));
                }
                else {
                    const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
                        ;
                    if (!isEmail(objdata?.email)) {
                        setFromvalidaction((state) => ({
                            ...state,
                            emailvalid: "Invalid Email ",
                        }));
                        formIsValid = false;
                    } else {
                        setFromvalidaction((state) => ({
                            ...state,
                            emailvalid: "",
                        }));
                    }
                }
                if (!objdata?.contacts[0]?.number || !objdata?.contacts[0]?.phoneCode) {
                    formIsValid = false;
                    setFromvalidaction((state) => ({
                        ...state,
                        contactsvalid: "contact is required",
                    }));
                } else {
                    setFromvalidaction((state) => ({
                        ...state,
                        contactsvalid: "",
                    }));
                }
                return formIsValid;
            };
            if (validation()) {
                if (objdata?.govt_ids?.length) {
                    let govt_ids = []
                    accountdata.govt_ids.forEach((result) => {
                        if (result?.idnumber && result?.idtype) {
                            govt_ids.push(result)
                        }
                    })
                    objdata.govt_ids = govt_ids
                }
                let postdata = objdata;
                let res = await _Api(postdata, `account/updateDetails`);
                if (res?.statusCode === 200) {
                    setDisableFiled(true);
                    getaccountdetails();
                }
            }
        } catch (err) {
            console.log(err, "err");
            setFromvalidaction(prevState => ({
                ...prevState,
                error: err.response.data.message,
            }));
        }
    };
    let gender = ["Male", "Female", "Other"];

    const cartData = [
        {title: "Billing & Shipping", img: billingandShippingCardImage, sub: "Manage your billing details ,shipping details", navigateto: "/account/address"},
        {title: "Appointment", img: AppointmentCardImage, sub: "View your appointment to allocate time ", navigateto: "/account/appointmentdata"},
        {title: "Purchase History", img: PurchaseHistoryCardImage, sub: "Manage your billing details ,shipping details", navigateto: "/account/purchasehistory"},
        {title: "Change Password", img: ChangePasswordCardImage, sub: "Manage your billing details ,shipping details", navigateto: "/account/changepassword"},
    ]

  return (
    <div className="bg-white d-flex">
    <Sidebar/>
    <div className='account_landingpage'>
        <div className='account_landingpage-inner'>
            {screenSize.width < 1200 ? <div className='account_landingpage-inner-title' onClick={()=> navigate('/collection')}><span className='cursor-pointer'><LeftIcon/></span><span>{t("Account Details")}</span></div> : "" }
            <div className='account_landingpage-inner-container'>
                <div className='account_landingpage-inner-container-upper'>
                    <div className='account_landingpage-inner-container-upper-imgwrapper'>
                        <img src={accountdata?.profile ? accountdata?.profile : NoImage} alt=""/>
                        { screenSize.width < 525 ? <div className='overlay'></div> : null }
                        {/* { screenSize.width < 525 ? 
                        <>
                        <div className='account_landingpage-inner-container-upper-imgwrapper-top'>
                            <span className=''><LeftIcon/></span>
                        </div>
                        <div className='account_landingpage-inner-container-upper-imgwrapper-user'>
                            <div className='account_landingpage-inner-container-upper-imgwrapper-user-title'>{accountdata?.fname}</div>
                            <div className='account_landingpage-inner-container-upper-imgwrapper-user-subtitle'>{t("Customer")}</div>
                        </div>
                        </>
                        : null} */}
                        {screenSize.width < 1200 && screenSize.width > 525 &&
                         <div className='account_landingpage-inner-container-upper-imgwrapper-editor' onClick={()=>navigate('/account/details')}>
                            <span className='account_landingpage-inner-container-upper-imgwrapper-editor-holder'>
                                <EditIcon/>
                            </span>
                         </div>
                        }
                       
                    </div>
                    <div className='account_landingpage-inner-container-upper-tagwrapper'>
                    <div className='account_landingpage-inner-container-upper-tagwrapper-title'>
                        <span>{accountdata?.fname}</span>
                    </div>
                    {screenSize.width < 1200 ?
                     <div className='account_landingpage-inner-container-upper-tagwrapper-default'>{t("Custom")}</div> : null
                    }
                    <div className='account_landingpage-inner-container-upper-tagwrapper-subtitle'>
                        <span className='text'>{t("Manage your account to get ready to travel our jewelry store! ")}</span>
                        {screenSize.width < 525 ? 
                         <div className='account_landingpage-inner-container-upper-imgwrapper-editor position-static' onClick={()=>navigate('/account/details')}>
                            <span className='account_landingpage-inner-container-upper-imgwrapper-editor-holder'>
                                <EditIcon/>
                            </span>
                        </div>
                        : null}
                    </div>
                    {screenSize.width > 1200 ? 
                    <button className='btnbold roundbtn' onClick={()=>navigate('/account/details')}>
                        <span>{t("Manage")}</span>
                    </button>
                    : null}
                    </div>
                </div>
                <div className='account_landingpage-inner-container-options_card'>
                    <div className='account_landingpage-inner-container-options_card-tag'>{t("My Account")}</div>
                    <div className='account_landingpage-inner-container-options_card-wrapper'>
                    {cartData.map((item, i)=>{
                        return(
                            <div className='account_landingpage-inner-container-options_card-wrapper-holder' key={i}>
                                <div className='account_landingpage-inner-container-options_card-wrapper-holder-title'>{t(`${item?.title}`)}</div>
                                <div className='account_landingpage-inner-container-options_card-wrapper-holder-image'><img src={item?.img} alt='cardimg' onClick={()=>navigate(`${item?.navigateto}`)}/></div>
                                <div className='account_landingpage-inner-container-options_card-wrapper-holder-sub'>{t(`${item?.sub}`)}</div>
                                <button className='btnbold roundbtn' onClick={()=>navigate(`${item?.navigateto}`)}>
                                    <span>{t("Manage")}</span>
                                </button>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
