import React from 'react'

const EyeShow = (props) => {
    const { width, height } = props;
    return (
        <svg  width={width ? width : "24"} height={height ? height : "24"}  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7912 14.1213C15.3539 13.5587 15.6699 12.7956 15.6699 12C15.6699 11.2044 15.3539 10.4413 14.7912 9.87868C14.2286 9.31607 13.4656 9 12.6699 9C11.8743 9 11.1112 9.31607 10.5486 9.87868C9.98599 10.4413 9.66992 11.2044 9.66992 12C9.66992 12.7956 9.98599 13.5587 10.5486 14.1213C11.1112 14.6839 11.8743 15 12.6699 15C13.4656 15 14.2286 14.6839 14.7912 14.1213Z" stroke="#BBBBBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.12793 12C4.40193 7.943 8.19293 5 12.6699 5C17.1479 5 20.9379 7.943 22.2119 12C20.9379 16.057 17.1479 19 12.6699 19C8.19293 19 4.40193 16.057 3.12793 12Z" stroke="#BBBBBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}

export default EyeShow