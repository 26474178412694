import { atom } from "recoil";

export const selectedInventoryItemsState = atom({
    key:"selectedInventoryItemsState",
    default: []
})
export const currentSelectedInventoryItemsState = atom({
    key:"currentSelectedInventoryItemsState",
    default: []
})


export const currentCheckIdState = atom({
    key:"currentCheckIdState",
    default: []
})

export const currentSelectedIdState = atom({
    key:"currentSelectedIdState",
    default: []
})


export const selectedProfileState = atom({
    key:"selectedProfileState",
    default: false
})

export const showImportStockIdModalState  = atom({
    key : "showImportStockIdModalState",
    default:false
})

export const countSelectedInventoryItemState = atom({
    key : "countSelectedInventoryItemState",
    default :0
})

export const selectedInventoryIdState = atom({
    key:"selectedInventoryIdState",
    default: []
})