import React from 'react'
import Earth from '../../../common/icons/Earth'
import AppointmentStandardIcon from '../../../common/icons/AppointmentStandardIcon'
import CustomerSupportIcon from '../../../common/icons/CustomerSupportIcon'
import DiamondIcon from '../../../common/icons/DiamondIcon'
import SearchIcon from '../../../../admin/common/icons/SearchIcon'
import { useTranslation } from 'react-i18next'

export default function BottomBar({withTitle}) {
    const {t} = useTranslation();
    const bottomdata = [
        {title: "Worldwide shipping", subtitle:"Free shippin for above ฿10000", icon:<Earth/>},
        {title: "Book an appointment", subtitle:"schedule an appointment for a personalised", icon:<AppointmentStandardIcon/>},
        {title: "Customer support", subtitle:"24hrs a day, 7 days a wee", icon:<CustomerSupportIcon/>},
        {title: "Hight Quality", subtitle:"Produce top jewellery", icon:<DiamondIcon/>}
    ]
  return (
    <div className="ecommerce-homepage-bottombar">
        {withTitle ? 
        <div className="ecommerce-homepage-bottombar-title">
        {t("Our Services")}
        </div>
        : null }
<div className="ecommerce-homepage-bottombar-inner">
    
    {bottomdata.length ? bottomdata.map((item, i)=>{
        return(
            <div className={`ecommerce-homepage-bottombar-inner-container ${i === 0 ? "" : "hasborder"}`}>
                <span className="ecommerce-homepage-bottombar-inner-container-icon">{ item.icon ? item.icon : <SearchIcon/>}</span>
                <div className="ecommerce-homepage-bottombar-inner-container-content">
                    <span className="ecommerce-homepage-bottombar-inner-container-content-title">{item.title}</span>
                    <span className="ecommerce-homepage-bottombar-inner-container-content-subtitle">{item.subtitle}</span>
                </div>
            </div>
        )
    }) : null}
    
</div>
</div>
  )
}
