import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import './productList.scss';
import { error } from '../../common/helpers/toastify';
import { _Api } from '../api/_call';
import NoOrdersYet from '../../common/module/NoOrdersYet/NoOrdersYet';
import { useSelector } from 'react-redux';
import Loading from '../../common/module/Loading/Loading';
import { useTranslation } from 'react-i18next';


const MixMatchProductList = (props) => {
  const { setShowseqs,
    filter,
    setproduct_id,
    showpage,
    Engraving,
    setprice,
    order_edit,
    editproduct,
    customcart,
    scrvicelist,
    scrviceremark,
    skip,
    setSkip,
    remark,
    showseqs,
    order_type,
    order_id,
  } = props
  const { t } = useTranslation()
  const { state } = useLocation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const customer = useSelector((state) => state.app.customer)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [Salefilter, setSalefilter] = useState('top_sale')
  const [productlist, setProductlist] = useState()
  const [Loadingstatus, setLoadingstatus] = useState(false);
  const [shoremorelodar, setShowmorelodar] = useState(false)
  const [Totalproduct, setTotalproduct] = useState(0);
  const getproducts = async () => {
    if (shoremorelodar) {
      setShowmorelodar(true)
    } else {
      setLoadingstatus(true);
    }
    try {
      const postdata = {
        item: state ? state?.items?.id : '',
        type: filter?.Optiondetails ? 'catalog' : 'inventory',
        limit: 40,
        sale: Salefilter,
        customer_id: customer?.id,
        skip: skip,
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/mixAndMatch/getProductListByItem`,
      )
      if (res.code === 200) {
        if (skip === 0) {
          setProductlist(res?.data)
        } else {
          let finaldata = [...productlist]
          let data = finaldata.concat(res?.data)
          setProductlist(data)
        }
        setLoadingstatus(false);
        setTotalproduct(res?.total)
        setShowmorelodar(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const wishlistset = async (sku, product_id, wishliststatus, i) => {
    if (!customer?.id) {
      error(t('Customer is Requried!!'))
      return
    }
    try {
      const postdata = {
        customer_id: customer?.id,
        isWishlist: wishliststatus === 0 ? 0 : wishliststatus,
        SKU: sku,
        type: filter?.Optiondetails ? 'catalog' : 'inventory',
        product_id: product_id,
      }
      let res = await _Api(postdata, `api/v1/POS/wishlist/updateWishlist`)
      if (res.code === 200) {
        let arry = [...productlist]
        arry[i] = {
          ...arry[i],
          'isWishlist': res?.data?.isWishlist,
        }
        setProductlist(arry)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (filter?.Optiondetails !== null) {
      getproducts()
    }
    // eslint-disable-next-line 
  }, [filter?.Optiondetails, customer?.id, showseqs === 0])
  return (
    <>
      {
        Loadingstatus ? <Loading /> : ''
      }
      <div className="product-filter-bar">
        <div className="product-filter-bar-left common-select">
          <label className="product-filter-bar-left-label">
            {state ? state?.items?.name : ''}
          </label>
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="graph"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className=""
                src={base_assets + 'images/pos/icons/graph-icon.png'}
                alt=""
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="graph">
              <li className="dropdown-item">
                <img
                  className=""
                  onClick={() => setSalefilter('top_sale')}
                  src={base_assets + 'images/pos/icons/top-sale.png'}
                  alt=""
                />{' '}
                Top sales
              </li>
              <li className="dropdown-item">
                <img
                  className=""
                  onClick={() => setSalefilter('lowest_sale')}
                  src={base_assets + 'images/pos/icons/up-sale.png'}
                  alt=""
                />{' '}
                lowest sales
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="products-list">
        <div className="products-list-row">
          {
            productlist?.length ? (
              productlist?.map((result, hkey) => {
                return (
                  <div className="products-list-row-single" key={result?.id}>
                    <Link
                      to="/app/pos/custom/mixdiamondsetting"
                      state={{
                        product_id: result?.product_id,
                        filter: filter,
                        daimond_id: state?.daimond_id,
                        items: state?.items,
                        showpage: showpage,
                        Engraving: Engraving,
                        editproduct: editproduct,
                        customcart: customcart,
                        scrvicelist: scrvicelist,
                        scrviceremark: scrviceremark,
                        remark: remark
                      }}
                      className="link-style"
                    >
                      <div className="products-list-row-single-img">
                        <img
                          className="products-cat-row-single-img"
                          src={result?.main_image ? result?.main_image : ''}
                          alt=""
                        />
                      </div>
                      <div className="products-list-row-single-ct pb-0">
                        <div className='products-list-row-single-ct-name-wrap'>
                          <h2 className="products-list-row-single-ct-name mb-0">
                            {result?.name}
                          </h2>
                          <span className='products-list-row-single-ct-name d-block fw-semibold h-auto spinnercolor'>{result?.SKU}</span>
                        </div>
                      </div>
                    </Link>
                    <div
                      onClick={() => result?.isWishlist ?
                        (parmissiondatashow?.WishList?.Delete && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, hkey)) :
                        (parmissiondatashow?.WishList?.Add && wishlistset(result?.SKU, result?.product_id, result?.isWishlist, hkey))}
                    >
                      <i
                        className={
                          result?.isWishlist === 1
                            ? 'fa fa-heart wishlist-icon'
                            : 'fa fa-heart-o wishlist-icon'
                        }
                      ></i>
                    </div>
                    <div className="products-list-row-single-ct pt-0">
                      <div className="products-list-row-single-ct-bottom">
                        <p className="products-list-row-single-ct-bottom-price">
                          {result?.price ? result?.price : ''}
                        </p>
                        {state?.daimond_id ?
                          order_edit ?
                            (
                              <Link
                                to="/app/pos/custom/mixdiamondsetting"
                                className="products-list-row-single-ct-bottom-add"
                                state={{
                                  product_id: result?.product_id,
                                  filter: filter,
                                  daimond_id: state?.daimond_id,
                                  items: state?.items,
                                  showpage: showpage,
                                  Engraving: Engraving,
                                  editproduct: editproduct,
                                  customcart: customcart,
                                  scrvicelist: scrvicelist ? scrvicelist : [],
                                  scrviceremark: scrviceremark,
                                  remark: remark,
                                  order_edit: order_edit,
                                  order_id: order_id,
                                  order_type: order_type,

                                }}
                              >
                                <img
                                  src={base_assets + 'images/pos/icons/add-pro.png'}
                                  alt=""
                                />

                              </Link>
                            ) :
                            filter?.Optiondetails ?
                              (
                                <Link
                                  to="/app/pos/custom/mixdiamondsetting"
                                  state={{
                                    product_id: result?.product_id,
                                    filter: filter,
                                    daimond_id: state?.daimond_id,
                                    items: state?.items,
                                    showpage: showpage,
                                    Engraving: Engraving,
                                    editproduct: editproduct,
                                    customcart: customcart,
                                    scrvicelist: scrvicelist ? scrvicelist : [],
                                    scrviceremark: scrviceremark,
                                    remark: remark

                                  }}
                                  className="products-list-row-single-ct-bottom-add"
                                >

                                  <img
                                    src={base_assets + 'images/pos/icons/add-pro.png'}
                                    alt=""
                                  />
                                </Link>
                              ) :
                              (
                                <button
                                  className="products-list-row-single-ct-bottom-add"
                                  onClick={() => {
                                    setShowseqs(showpage ? 2 : 1)
                                    setproduct_id(result?.id)
                                    setprice(result?.price)
                                  }}
                                >
                                  <img
                                    src={base_assets + 'images/pos/icons/add-pro.png'}
                                    alt=""
                                  />

                                </button>
                              ) : (
                            <Link
                              to="/app/pos/custom/mixAndMatch"
                              state={{
                                daimond_id: '',
                                items: state?.items,
                                product_id: result?.id,
                                bychangeorder: true,

                              }}
                              className="products-list-row-single-ct-bottom-add"
                            >

                              <img
                                src={base_assets + 'images/pos/icons/add-pro.png'}
                                alt=""
                              />
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <NoOrdersYet />
            )
          }
        </div>
      </div>
      {shoremorelodar ?
        <div className="border-bottom-0 d-block main-green-text mx-auto my-4 spinner-border flex-shrink-0" role="status"></div> : ""}
      {productlist?.length !== 0 && productlist?.length !== Totalproduct ?
        <div className="show-more show-more-fixed">
          <span className='show-more-displaying'>{t("Displaying")} {productlist?.length ? productlist?.length : 0} {t("of")} {Totalproduct} {t("items")}</span>
          <button className={shoremorelodar ? "btn show-more-btn disabled" : "btn show-more-btn"} onClick={() => { setSkip(skip + 10); setShowmorelodar(true); setShowmorelodar(true) }}>
            Show more <img src={base_assets + "images/icons/add-icon.png"} alt="" /></button></div>
        : ""}
    </>
  )
}

export default MixMatchProductList;
