import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Customers from "../admin/customers/Customers"
import HistoryPayment from "../admin/customers/HistoryPayment"
import HistoryCreditNote from "../admin/customers/HistoryCreditNote"
import HistoryPurchase from "../admin/customers/HistoryPurchase"
import HistoryPartialPayment from "../admin/customers/HistoryPartialPayment"
import CustomersReport from "../admin/customers/customerReport/CustomersReport"
import CustomerReportDetail from "../admin/customers/customerReport/CustomerReportDetail"
import AppointmentHistory from '../admin/customers/AppointmentHistory';

export default function CustomerRoutes(toploadingbarStart) {
    return (
        <Routes>
            <Route exact path='/' element={<Customers toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='historypayment' element={<HistoryPayment toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='historycreditnote' element={<HistoryCreditNote toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='historypurchase' element={<HistoryPurchase toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='historypartialpayment' element={<HistoryPartialPayment toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='customersreport' element={<CustomersReport toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='customerreportdetail' element={<CustomerReportDetail toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='appointmenthistory' element={<AppointmentHistory toploadingbarStart={toploadingbarStart} />} > </Route>
        </Routes>
    );
}