// SourceCodeModeToggle.js
let toggleFunction;

export const setToggleFunction = (func) => {
  toggleFunction = func;
};

export const toggleSourceCodeMode1 = () => {
  toggleFunction();
};
