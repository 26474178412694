import React, { useState, useRef, useEffect } from 'react'
import Select from "react-select"
import { _Api, graphqlPost } from './api/_call';
import { error, loading, Updateloading } from "./common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import Lodingspinner from '../components/common/Lodingspinner'
const AdminProfile = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);
  const [phonecodearray, setPhonecodearray] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [countryarray, setCountryarray] = useState([])
  const [completeDataObject, setCompliteDataObject] = useState({ first_name: "", last_name: "", })
  const [passwordchange, setPasswordchange] = useState({ "password": "", "old_password": "", "confirmpassword": "" })
  const [lodingdata, setLodingdata] = useState(false)
  const [changepin, setChangepin] = useState({ "loginPIN": "", "confirmpin": "" })
  const mobileImage = <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />;
  const phoneImage = <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />;
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  const getCountry = async () => {
    try {
      const postdata = `{
                       countries{
                        name
                        id
                        sortname
                        phoneCode  
                        }
                      }`;
      let res = await graphqlPost(postdata, "App/graphql");
      let countryarray = [];
      let phonecodearray_ = [];
      res?.data?.countries.forEach((cnames) => {
        countryarray.push({
          value: cnames?.id,
          label: cnames?.name,
          phonecode: cnames?.phoneCode,
        });
        phonecodearray_.push({
          label: cnames?.phoneCode,
          value: cnames?.phoneCode,
          name: cnames?.name,
        });
      });
      setPhonecodearray(phonecodearray_);
      setCountryarray(countryarray);
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getCountry()
  },
    [])
  const onImageupload = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      let GetloadingID = loading();
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/App/User/uploadimage`);
        if (resfront.code === 200) {
          Updateloading(t, GetloadingID, t("Successfully Upload Image"));
          onChangeValueUpdate("myProfile", resfront?.data?.images?.url);
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  };
  const getprofile = async () => {
    try {
      setLodingdata(true)
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/profile/get`)
      if (res.code === 200) {
        setCompliteDataObject(res?.data)
        setLodingdata(false)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getprofile()
  }, [])
  const updateprofile = async () => {
    let GetloadingID = loading();
    try {
      let postdata = completeDataObject
      let res = await _Api(postdata, `api/v1/App/profile/edit`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        setShowEdit(false)
        getprofile()
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
        setShowEdit(true)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const phoneOneOption = [
    {
      value: 1,
      icon: <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />
    },
    {
      value: 2,
      icon: <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />
    }
  ];

  const addEventList = () => {
    let newValues_ = { ...completeDataObject };
    let newValues = [...newValues_?.contacts];
    newValues.push({ icon: 2, code: "", no: "" });
    newValues_.contacts = newValues;
    setCompliteDataObject(newValues_);
  };

  const handleRemoveElement = (indexToRemove) => {
    let newValues_ = { ...completeDataObject };
    let newValues = [...newValues_?.contacts];
    let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
    newValues_.contacts = updatedArray;
    setCompliteDataObject(newValues_);
  };

  const handleSelectChange = (selectedOption, index, code, no) => {
    let newValues_ = { ...completeDataObject };
    let newValues = [...newValues_?.contacts];
    newValues[index] = { icon: selectedOption, code: code, no: no };
    newValues_.contacts = newValues;
    setCompliteDataObject(newValues_);
  };

  const changepass = async () => {
    if (!passwordchange?.old_password) {
      error(t("Current password field is required"))
      return
    }
    if (!passwordchange?.password) {
      error(t("Password field is required"))
      return
    }
    if (!passwordchange?.confirmpassword) {
      error(t("Confirm password field is required"))
      return
    }
    if (passwordchange?.password === passwordchange?.confirmpassword) {
      let GetloadingID = loading();
      try {
        let postdata = {
          "password": passwordchange?.password,
          "old_password": passwordchange?.old_password
        }
        let res = await _Api(postdata, `api/v1/App/profile/change-password`)
        if (res?.code === 200) {
          Updateloading(t, GetloadingID, (res?.message));
          getprofile()
          if (modalRef.current) {
            modalRef.current.hide();
          }
          setPasswordchange({ "password": "", "old_password": "", "confirmpassword": "" })
        } else {
          Updateloading(t, GetloadingID, res.errors ? (res.errors) : (res.message), 'error');
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    } else {
      error(t("Password is incorrect"))
    }
  }
  const setpincode = async () => {
    if (!changepin?.loginPIN) {
      error(t("PIN field is required"))
      return
    }
    if (!changepin?.confirmpin) {
      error(t("Confirmpin field is required"))
      return
    }
    if (changepin?.loginPIN === changepin?.confirmpin) {
      let GetloadingID = loading();
      try {
        let posstdata = {
          "loginPIN": changepin?.loginPIN
        }
        let res = await _Api(posstdata, `api/v1/App/profile/change-pin`)
        if (res?.code === 200) {
          Updateloading(t, GetloadingID, (res?.message));
          getprofile()
          if (modalRef2.current) {
            modalRef2.current.hide();
          }
          setChangepin({ "loginPIN": "", "confirmpin": "" })
        } else {
          Updateloading(t, GetloadingID, (res.errors), 'error');
        }

      }
      catch (err) {
        console.log(err, 'err')
      }
    } else {
      error(t("PIN is incorrect"))
    }

  }
  useEffect(() => {
    modalRef.current = new window.bootstrap.Modal(document.getElementById('ChangePasswordModal'));
    modalRef2.current = new window.bootstrap.Modal(document.getElementById('ChangePinModal'));
  }, []);
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <span>{t("Profile")}</span>
          </h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
          {showEdit ?
            <>
              <button className="square-btn square-btn-white btn-close focus-none bg-white" onClick={() => setShowEdit(false)}></button>
              <button className="square-btn" onClick={() => { updateprofile() }}>
                <img src={base_assets + 'images/icons/white-save.png'} alt="" />
              </button>
            </> :
            <button className="square-btn" onClick={() => { setShowEdit(true); getprofile() }}>
              <img src={base_assets + 'images/admin/icons/pencil-white.png'} alt="" />
            </button>
          }

        </div>
      </div>
      <div className="admin-main-card">
        <div className={`non-edit fs-14 ${showEdit ? "d-none" : ""}`}>
          {
            lodingdata ?
              <Lodingspinner /> :
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={completeDataObject?.myProfile ? completeDataObject?.myProfile : base_assets + "images/blank-img-2.png"}
                    className="bg- bg-f2 border d-block mx-auto rounded-circle w-75 aspect-ratio-1slash1 object-fit-cover"
                    style={{ maxWidth: '250px' }} alt=""
                  />
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Name")}</label>
                      <span className="non-edit-input">{completeDataObject?.first_name + " " + completeDataObject?.last_name}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Organization")}</label>
                      <span className="non-edit-input">{completeDataObject?.organization}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Address")}</label>
                      <span className="non-edit-input">{completeDataObject?.address}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Country")}</label>
                      <span className="non-edit-input">{completeDataObject?.country_name}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Province / State")}</label>
                      <span className="non-edit-input">{completeDataObject?.state}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("City")}</label>
                      <span className="non-edit-input">{completeDataObject?.city}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Zip")}</label>
                      <span className="non-edit-input">{completeDataObject?.zipcode}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Phone Number")}</label>
                      <span className="non-edit-input">{"+" + completeDataObject?.contacts?.[0]?.code + "-" + completeDataObject?.contacts?.[0]?.no}</span>
                    </div>
                  </div>
                  <div className="row editable">
                    <div className="col-md-12 fw-semibold mb-4 mt-3">
                      {t("Email Information")}
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Cloud account")}</label>
                      <span className="non-edit-input">{completeDataObject?.cloud}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Email")}</label>
                      <span className="non-edit-input">{completeDataObject?.email ? completeDataObject?.email : ''}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Password")}</label>
                      <span className="non-edit-input">**********</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="non-edit-label">{t("Login PIN 6 digit")}</label>
                      <span className="non-edit-input">******</span>
                    </div>
                    <div className="mb-4 me-4 col-md-6">
                      <input
                        className="form-check-input fill-com-check me-3 cursor-pointer"
                        type="checkbox"
                        id="twoway"
                        checked={completeDataObject?.twofactorauth}
                      />
                      <label className="form-check-label cursor-pointer" htmlFor="twoway">
                        {t("Enable two factor authentication")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
          }

        </div>
        <div className={`editable ${showEdit ? '' : 'd-none'}`}>
          <div className="row">
            <div className="col-md-4">
              <div className="edit-item-area-body-circle-img-choose">
                <input id="chooseImg" type="file" className="d-none"
                  onChange={onImageupload}
                  accept="image/*" />
                <label htmlFor="chooseImg" className="rounded-circle overflow-hidden w-75 mx-auto d-block cursor-pointer" style={{ maxWidth: '250px' }}>
                  <img className="w-100 aspect-ratio-1slash1 object-fit-cover" src={completeDataObject?.myProfile ? completeDataObject?.myProfile : base_assets + "images/blank-img-2.png"} alt="" />
                </label  >
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Name")}</label>
                    <input type="text" className="v3-input-group-input w-100"
                      value={completeDataObject?.first_name}
                      onChange={(e) => onChangeValueUpdate("first_name", e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Last Name")}</label>
                    <input type="text" className="v3-input-group-input w-100"
                      value={completeDataObject?.last_name}
                      onChange={(e) => onChangeValueUpdate("last_name", e.target.value)} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Address")}</label>
                    <textarea type="text" rows="3" className="v3-input-group-input w-100 h-auto"
                      value={completeDataObject?.address}
                      onChange={(e) => onChangeValueUpdate("address", e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Country")}</label>
                    <Select
                      className="select-set select w-100 p-0"
                      placeholder=""
                      options={countryarray}
                      value={{
                        value: completeDataObject?.country,
                        label: completeDataObject?.country_name,

                      }}
                      onChange={(e) => {
                        onChangeValueUpdate("country", e.value);
                        onChangeValueUpdate("country_name", e.label);
                      }}
                      classNamePrefix="common-select"
                      isSearchable={true}
                      menuPlacement="top"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Province / State")}</label>
                    <input type="text" className="v3-input-group-input w-100"
                      value={completeDataObject?.state}
                      onChange={(e) => onChangeValueUpdate("state", e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("City")}</label>
                    <input type="text" className="v3-input-group-input w-100"
                      value={completeDataObject?.city}
                      onChange={(e) => onChangeValueUpdate("city", e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Zip")}</label>
                    <input type="text" className="v3-input-group-input w-100"
                      value={completeDataObject?.zipcode}
                      onChange={(e) => onChangeValueUpdate("zipcode", e.target.value)} />
                  </div>
                </div>
                {completeDataObject?.contacts?.length ? completeDataObject?.contacts.map((value, index) => {
                  return (
                    <div className="col-md-12" key={index}>
                      <div className='row w-100'>
                        <div className="col-md-3 d-flex align-items-center">
                          <div className="v3-input-group w-100 d-flex">
                            {index !== 0 ?
                              <button className="border-0 bg-transparent p-0 me-2"
                                onClick={() => handleRemoveElement(index)}><img className="=" src={base_assets + "images/icons/minus-detail.png"} alt="" /></button>
                              : ""}
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={phoneOneOption}
                              value={{ value: value?.icon, icon: value?.icon === 1 ? mobileImage : phoneImage }}
                              onChange={(e) =>
                                handleSelectChange(
                                  e.value ? e.value : value?.icon,
                                  index,
                                  value?.code,
                                  value?.no
                                )}
                              classNamePrefix="common-select"
                              isSearchable={false}
                              getOptionLabel={(e) => (
                                <div className="common-select-icon">{e.icon}</div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="v3-input-group col-md-3">
                          <Select
                            className="select-set select p-0"
                            placeholder=""
                            options={phonecodearray}
                            value={{ value: value?.code, label: value?.code }}
                            onChange={(e) =>
                              handleSelectChange(
                                value?.icon,
                                index,
                                e.value ? e.value : value?.code,
                                value?.no
                              )}
                            classNamePrefix="common-select"
                            isSearchable={true}
                            getOptionLabel={(opt) => opt.label}
                          />
                        </div>
                        <div className="v3-input-group col-md-6">
                          <input value={value?.no}
                            onChange={(e) => handleSelectChange(
                              value?.icon,
                              index,
                              value?.code,
                              e.target.value ? e.target.value : value?.no
                            )}
                            maxLength={17}
                            pattern="[0-9]*"
                            type="text"
                            className="v3-input-group-input w-100" />
                        </div>
                      </div>
                    </div>
                  )
                })
                  : ""}
              </div>
              <div className="col-md-4 mt-3">
                <button className="border-0 bg-transparent p-0 main-green-text"
                  onClick={() => addEventList()}>
                  <img src={base_assets + "images/icons/add-detail.png"} alt="" />
                  <span className="ms-2">{t("Add Phone")}</span>
                </button>
              </div>
              <div className='row'>
                <div className="col-md-12 fw-semibold mb-4 mt-3">{t("Email Information")}</div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Email")}</label>
                    <input type="email" className="v3-input-group-input w-100"
                      value={completeDataObject?.email}
                      onChange={(e) => onChangeValueUpdate("email", e.target.value)} />
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Password")}</label>
                    <input type="password" className="v3-input-group-input w-100" />
                  </div>
                </div>
                <div className="col-md-6 v3-input-group align-items-end  d-flex"
                  onClick={() => setPasswordchange({ "password": "", "old_password": "", "confirmpassword": "" })}>
                  <label className="v3-input-group-label link-color cursor-pointer" data-bs-toggle="modal" data-bs-target="#ChangePasswordModal">{t("Change Password")}</label>
                </div>
                <div className="col-md-6">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Login PIN 6 digit")}</label>
                    <input type="password" className="v3-input-group-input w-100" />
                  </div>
                </div>
                <div className="col-md-6 v3-input-group align-items-end  d-flex">
                  <label className="v3-input-group-label link-color cursor-pointer" data-bs-toggle="modal" data-bs-target="#ChangePinModal" onClick={() => setChangepin({ "loginPIN": "", "confirmpin": "" })}>
                    {t("Change PIN")}</label>
                </div>
              </div>
              <div className="col-md-6 v3-input-group d-flex align-content-center ">
                <input
                  className="form-check-input fill-com-check me-3 cursor-pointer v3-input-group-label"
                  type="checkbox"
                  id="twoway"
                  checked={completeDataObject?.twofactorauth}
                  onChange={(e) => onChangeValueUpdate("twofactorauth", e.target.checked ? true : false)}
                />
                <label className="form-check-label cursor-pointer" htmlFor="twoway">
                  {t("Enable two factor authentication")}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade com-modal" id="ChangePasswordModal" tabIndex="-1" aria-labelledby="ChangePasswordModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title fw-semibold" id="ChangePasswordModalLabel">{t("Change Password")}</h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => setPasswordchange({ "password": "", "old_password": "", "confirmpassword": "" })}></button>
            </div>
            <div className="modal-body fs-14">
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Current Password")}</label>
                <input type="password" className="v3-input-group-input w-100"
                  value={passwordchange?.old_password ? passwordchange?.old_password : ""}
                  onChange={(e) => {
                    setPasswordchange(prevState => ({
                      ...prevState,
                      old_password: e.target.value
                    }))
                  }} />
              </div>
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("New Password")}</label>
                <input type="password" className="v3-input-group-input w-100"
                  value={passwordchange?.password ? passwordchange?.password : ""}
                  onChange={(e) => {
                    setPasswordchange(prevState => ({
                      ...prevState,
                      password: e.target.value
                    }))
                  }} />
              </div>
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Confirm Password")}</label>
                <input type="password" className="v3-input-group-input w-100"
                  value={passwordchange?.confirmpassword ? passwordchange?.confirmpassword : ""}
                  onChange={(e) => {
                    setPasswordchange(prevState => ({
                      ...prevState,
                      confirmpassword: e.target.value
                    }))
                  }} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn text-white main-green-bg borde-0" onClick={() => changepass()}>{t("Save")}</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade com-modal" id="ChangePinModal" tabIndex="-1" aria-labelledby="ChangePinModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title fw-semibold" id="ChangePinModalLabel">{t("Change PIN")}</h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => setChangepin({ "loginPIN": "", "confirmpin": "" })}></button>
            </div>
            <div className="modal-body fs-14">
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("New PIN")}</label>
                <input type="password" className="v3-input-group-input w-100"
                  value={changepin?.loginPIN ? changepin?.loginPIN : ""}
                  onChange={(e) => {
                    setChangepin(prevState => ({
                      ...prevState,
                      loginPIN: e.target.value
                    }))
                  }}
                  maxLength={6} />
              </div>
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Confirm PIN")}</label>
                <input type="password" className="v3-input-group-input w-100"
                  value={changepin?.confirmpin ? changepin?.confirmpin : ""}
                  onChange={(e) => {
                    setChangepin(prevState => ({
                      ...prevState,
                      confirmpin: e.target.value
                    }))
                  }}
                  maxLength={6} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn text-white main-green-bg borde-0" onClick={() => setpincode()}>{t("Save")}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AdminProfile
