import React, { useState, useEffect } from "react";
import "../master.scss"
import { _Api } from "../../api/_call";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const Editsettingtype = (props) => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { setEditItem, dataid, setShowedit, create, setDataid, setCreate } = props
    const { t } = useTranslation();
    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [mixmatch, setMixMatch] = useState(0)
    const [active, setActive] = useState(true)
    const [file, setFile] = useState();
    const createshapestone = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            let checkspace = code.includes(' ');
            if (checkspace) {
                error(t('Please remove space in code'));
                return;
            }
        }
        let GetloadingID = loading();
        const postdata = {
            "type": "SettingType",
            "data": {
                "logo": file,
                "name": name ? name : "",
                "code": code ? code : "",
                "mixMatch": mixmatch ? 1 : 0,
                "Status": active ? "1" : "0"
            }
        }
        let res = await _Api(postdata, `api/v1/App/master/stoneMasterCreate`)
        if (res?.code === 200) {
            setEditItem(false)
            setShowedit(true)
            Updateloading(t, GetloadingID, (res?.message));
            setDataid("")
            setCreate(false)
        } else {
            Updateloading(t, GetloadingID, (res.errors), 'error');
        }
    }
    const editshapestone = async () => {
        if (!name) {
            error(t("Name is required."))
            return
        }
        if (!code) {
            error(t("Code is required."))
            return
        } else {
            let checkspace = code.includes(' ');
            if (checkspace) {
                error(t('Please remove space in code'));
                return;
            }
        }
        let GetloadingID = loading();
        const postdata = {
            "id": dataid,
            "type": "SettingType",
            "data": {
                "logo": file,
                "name": name ? name : "",
                "code": code ? code : "",
                "mixMatch": mixmatch ? 1 : 0,
                "Status": active ? "1" : "0"
            }
        }
        let res = await _Api(postdata, `api/v1/App/master/stoneMasterEdit`)
        if (res?.code === 200) {
            setEditItem(false)
            setShowedit(true)
            Updateloading(t, GetloadingID, (res?.message));
            setDataid("")
            setCreate(false)
        } else {
            Updateloading(t, GetloadingID, (res.errors), 'error');
        }
    }
    const viewmaster = async () => {
        if (create) {
            setFile("")
            setName("")
            setCode("")
            setMixMatch("")
            setActive(true)
        } else if (dataid) {
            try {
                const postdata = {
                    "id": dataid,
                    "type": "SettingType"
                }
                const res = await _Api(postdata, `api/v1/App/master/stoneMasterview`)
                if (res?.code === 200) {
                    setFile(res?.data?.logo)
                    setName(res?.data?.name)
                    setCode(res?.data?.code)
                    setMixMatch(res?.data?.mixMatch)
                    if (res?.data?.Status === "1") {
                        setActive(true)
                    } else {
                        setActive(false)
                    }

                }
            } catch (err) {
                console.log(err, "err")
            }
        }


    }
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setFile(URL.createObjectURL(file));
        const reader = new FileReader(file);
        reader.onloadend = async () => {
            try {
                const postdata = {
                    image: reader.result,
                    name: "profile",
                };
                let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`);
                if (resfront.code === 200) {
                    setFile(resfront?.data?.images?.url);
                }
            } catch (err) {
                console.log(err, "err");
            }
        };
        if (file) {
            reader?.readAsDataURL(file);
        }
    };


    useEffect(() => {
        if (dataid || create) {
            viewmaster();
        }
        // eslint-disable-next-line
    }, [dataid, create]);


    return (
        <>
            <div className="edit-item-area bg-white">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                    <div className="me-auto">
                        <label className="d-block">{t("Name")}: {name ? name : ""}</label>
                        <label className="d-block">{t("Code")}: {code ? code : ""}</label>
                    </div>
                    <button className="square-btn square-btn-white btn-close focus-none" onClick={() => {
                        setEditItem(false);
                        setDataid("");
                        setCreate(false)
                    }}></button>
                    <button className="square-btn " onClick={() => dataid ? editshapestone() : createshapestone()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
                <div className="edit-item-area-body">
                    <div className="row mx-0">
                        <div className="col-md-3 ps-0">
                            <div className="edit-item-area-body-img p-2 d-flex align-items-center justify-content-center">
                                <img className="position-absolute" src={base_assets + 'images/icons/add-img-circle.png'} alt="" />
                                <label className=" d-block h-100 w-100 cursor-pointer" style={{ zIndex: "1" }} htmlFor="itemImgChoose">
                                    <img className={file ? "w-100 h-100 bg-white object-fit-cover" : "d-none"} src={file ? file : base_assets + 'images/icons/add-img-circle.png'} alt="" />
                                </label>
                                <input type="file" id="itemImgChoose" className="d-none"
                                    onChange={onImageChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={name || ''}
                                    onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Code")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={code || ''}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 pe-0 text-end">
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="mixmatch">{t("Mix & Match")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="mixmatch"
                                    checked={mixmatch ? true : ""}
                                    onChange={(e) => setMixMatch(e.target.checked ? 1 : 0)} />
                            </div>
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                    checked={active ? true : false}
                                    onChange={(e) => setActive(e.target.checked)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Editsettingtype;