import React from "react";
import Tabs from "./Tabs";
const TabPos = ({ id, title, content,style }) => {
  return (
    
      <>
      <div className=""><Tabs/></div>
      </>
   
  );
};

export default TabPos;
