import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DateTime from '../common/DateTime'
import { SetdataTable } from '../api/setdatatable'
import CustomerInformation from "../../admin/customers/customerInformation/CustomerInformation";
import { _Api } from '../api/_call'
import { error } from '../common/helpers/toastify'
import { Saleperson, VoucherList } from '../common/graphql/commonlayouts'
import ConfirmationModel from '../common/modules/confirmationmodel'
import CustomerSearchModal from '../common/modules/customerSearch'
import { daimondreservetablehead } from '../common/Tablehead'
import TableColumnModal from '../common/modules/tableColumn'
import { Tablehead } from '../common/modules/Tablehead'
import CustomerSearchDetailModal from '../common/modules/CustomerSearchDetailModal'
import TransAndDeliverDate from '../common/modules/TransAndDeliverDate'
import { calDuedate } from '../common/helpers/formatDate'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const CreateReserve = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation()
  let navigate = useNavigate()
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [commanSelected_sal, setcommanSelected_sall] = useState(null)
  const [deliveryDate, set_deliveryDate] = useState()
  const [transactionDate, set_transactionDate] = useState(new Date())
  const [commanSelected_vouc, setcommanSelected_vouc] = useState()
  const [cusid, setCusid] = useState()
  const [newcustomersid, setnewcustomersid] = useState()
  const [details, setDetails] = useState()
  const [customerid, setCustomerid] = useState()
  const [remark, setRemark] = useState()
  const [newcolumndata, setnewcolumndata] = useState(daimondreservetablehead)
  useEffect(() => {
    callcolumns()
  }, [])
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_daimond_reserve',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getdeliverydate = async () => {
    set_deliveryDate(await calDuedate(new Date()))
  }
  useEffect(() => {
    getdeliverydate()
  }, [])
  const getdetails = async () => {
    try {
      const posdata = { id: state.daimondid }
      let res = await _Api(posdata, `api/v1/Inventory/diamond/getDetails`)
      if (res.code === 200) {
        SetdataTable('daimond_reserve_table', 5, newcolumndata)
        setDetails(res.data)
      } else {
        SetdataTable('daimond_reserve_table', 5, newcolumndata)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const reservedaimond = async () => {
    const handleValidation = () => {
      let formIsValid = true
      if (!customerid) {
        formIsValid = false
        error(t("Customer is Requried!!"))
      }
      if (!commanSelected_sal) {
        formIsValid = false
        error(t("SalePerson is Requried!!"))
      }
      if (!transactionDate) {
        formIsValid = false
        error(t('Transaction Date is Required!!'))
      }
      if (!remark) {
        formIsValid = false
        error(t('Remark is Required!!'))
      }
      if (!commanSelected_vouc) {
        formIsValid = false
        error(t('Voucher is Required!!'))
      }
      return formIsValid
    }
    if (handleValidation()) {
      try {
        const posdata = {
          id: state.daimondid,
          customer_id: customerid,
          salesPerson_id: commanSelected_sal,
          date: transactionDate,
          dueDate: deliveryDate ? deliveryDate : 'mm/dd/yyyy',
          remark: remark,
          voucher_id: commanSelected_vouc,
        }
        let res = await _Api(posdata, `api/v1/Inventory/diamond/createReserve`)
        if (res.code === 200) {
          navigate('/app/inventory/detailDiamond', {
            state: { daimondid: state.daimondid },
          })
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }
  useEffect(() => {
    if (state.daimondid) {
      getdetails()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link
                    to="/app/inventory/detailDiamond"
                    state={{ daimondid: state.daimondid }}
                    className="page-back-btn"
                  >
                    <img
                      src={base_assets + 'images/icons/arrow-left.png'}
                      alt=""
                    />
                  </Link>
                  <img
                    src={base_assets + 'images/icons/home-icon.png'}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={'Diamond_Reserve'}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">
                  {t('Create Reserve')}
                </h1>
              </div>

              <div className="main-body-top-inputs-bar top-bar-input">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">
                    {t('REF-XXX')}
                  </label>
                  <CustomerSearchModal
                    setSearchid={setCustomerid}
                    newcustomer={newcustomersid}
                  ></CustomerSearchModal>
                  {parmissiondatashow?.pos_customer_reports?.Add ?
                    <button
                      className="btn-user"
                      data-bs-toggle="modal"
                      data-bs-target="#customerAddModal"
                    >
                      <img
                        className=""
                        src={base_assets + 'images/pos/icons/add-user.png'}

                        alt=""
                      />
                    </button> :
                    ""}
                  <Saleperson setcommanSelected_sal={setcommanSelected_sall} />
                  <TransAndDeliverDate
                    TransactionDate={transactionDate}
                    DeliveryDate={deliveryDate}
                    setTransactionDate={set_transactionDate}
                    setDeliveryDate={set_deliveryDate}
                  />
                </div>
                <div className="main-body-top-inputs-bar-col-right">
                  <button
                    className="main-body-top-inputs-bar-col-right-submit-btn com-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#createPurchaseModal"
                  >
                    {t('Submit')}
                  </button>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap">
                  <table
                    id="daimond_reserve_table"
                    className="stripe row-border order-column common-table table-striped main-table w-100"
                  >
                    <Tablehead
                      tablehead={daimondreservetablehead}
                      tblName={'tbl_daimond_reserves'}
                    />
                    <tbody>
                      <tr>
                        <td className="sku-col">1</td>
                        <td id="table-create-btn">
                          <div className="table-pro-img">
                            <img
                              className="link-img"
                              src={details?.image[0]}
                              alt=""
                            />
                          </div>
                        </td>
                        <td>{details?.Carat}</td>
                        <td className="po-no">{details?.Colour}</td>
                        <td>{details?.Clarity}</td>
                        <td>{details?.Cut}</td>
                        <td>{details?.Polish}</td>
                        <td>{details?.Symmetry}</td>
                        <td>{details?.Price}</td>
                        <td>{details?.Location}</td>
                        <td>
                          <div className="col-input input-field-bg">
                            <input
                              type="text"
                              onChange={(e) => setRemark(e.target.value)}
                              name="remark"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomerInformation
          cusid={cusid}
          setCusid={setCusid} />
        <CustomerSearchDetailModal
          setnewcustomersid={setnewcustomersid}
          setCusid={setCusid}
        />
      </div>
      <TableColumnModal
        tablehead={daimondreservetablehead}
        tblName={'tbl_daimond_reserves'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={getdetails}
      ></TableColumnModal>
      <ConfirmationModel
        statement="Reserve this item"
        handler_submit={reservedaimond}
      ></ConfirmationModel>
    </>
  )
}

export default CreateReserve;
