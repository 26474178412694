import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { Reportmenupos } from "./Reportmenupos";
import TableModal from "../common/Modals/tableColumnPos";
import SocialModal from "../../components/common/modules/SocialModal/SocialModal";
import { Daterangepos } from "../common/Daterangepos";
import { _Api } from "../common/api/_call"
import { Tablehead } from "../../components/common/modules/Tablehead";
import { posrefundreport_head } from "../../components/common/Tablehead";
import { loading, Updateloading } from "../common/helpers/toastify";
import "./Report.scss"
import { Pagination } from "../common/Pagination";
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import Select from "react-select";
import * as XLSX from 'xlsx'
const Refundreport = () => {
    const { t } = useTranslation();
    const [newcolumndata, setnewcolumndata] = useState(posrefundreport_head);
    const [socialurl, setSocialurl] = useState();
    const [datefilter, setDatefilter] = useState([]);
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [refunddata, setRefunddata] = useState([])
    const [upercount, setUpercount] = useState()
    const [lowercount, setLowercount] = useState()
    const [, setfilterby] = useState('');
    const [filtername, setFiltername] = useState("");
    const [shareloader, setShareloader] = useState(false);
    const [limit, setLimit] = useState(100);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const [printparmission, setPrintparmission] = useState(0)
    const [editparmission, setEditparmission] = useState(0);
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
    useEffect(() => {
        if (parmissiondatashow?.pos_reports?.View)
            setPrintparmission(parmissiondatashow?.pos_reports?.Print)
        setEditparmission(parmissiondatashow?.pos_reports?.Edit);
    }, [parmissiondatashow]);
    const getsharerefundreport = async () => {
        setShareloader(true);
        try {
            const postdata = {
                "search": filtername,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/reports/print/refund`);

            if (res.code === 200) {
                setSocialurl(res?.link);
                setShareloader(false);
            }
        }
        catch (err) {
            console.log(err, 'err');
        }

    }
    const getprintrefundrreport = async () => {
        let GetloadingID = loading();
        try {
            const postdata = {
                "search": filtername,
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : ""
            };
            let res = await _Api(postdata, `api/v1/POS/reports/print/refund`);

            if (res.code === 200) {
                setTimeout(() => {
                    const exportLinkElement = document.createElement('a');
                    exportLinkElement.hidden = true;
                    exportLinkElement.download = res?.name ? res?.name : "report.pdf";
                    exportLinkElement.href = res?.link;
                    exportLinkElement.text = "downloading...";
                    exportLinkElement.setAttribute('target', '_blank');
                    document.body.appendChild(exportLinkElement);
                    exportLinkElement.click();
                    exportLinkElement.remove();
                    Updateloading(t, GetloadingID, t("download successfully"));

                }, 50)
                setSocialurl(res?.link);
            } else {
                Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, 'err');
            Updateloading(t, GetloadingID, err, t('error'));
        }

    }
    const callcolumns = async () => {
        try {
            const postdata = {
                name: "report_refundreport_tbl",
            };
            let columnhead = await _Api(
                postdata,
                `api/v1/Inventory/common/getColumnHeads`
            );
            if (columnhead.code === 200) {
                setnewcolumndata(columnhead?.data?.data);
            }
        } catch (err) {
            console.log(err, "err");
        }
    };
    useEffect(() => {
        callcolumns();
    }, []);
    const filterbyname = (search) => {
        getrefundreport(currentPage, limit, search);
        setstate({ ...state, currentPage: 1 });
    };
    const getlimit = (limit) => {
        setLimit(limit);
        getrefundreport(1, limit);
        setstate({ ...state, currentPage: 1 });

    };
    const [state, setstate] = useState({
        totalPages: 20,
        currentPage: 1,
    });

    const { totalPages, currentPage } = state;
    const handlePaginations = (current) => {
        setstate({ ...state, currentPage: current });
        getrefundreport(current, "");

    };
    const getrefundreport = async (current, firstlimit, search) => {
        UnSetdataTable("refund_report_table");
        try {
            const postdata = {
                "search": search ? search : "",
                "start_date": datefilter ? datefilter[0] : "",
                "end_date": datefilter ? datefilter[1] : "",
                "limit": firstlimit ? firstlimit : limit,
                "skip": current ? (current - 1) * limit : 0,
            };
            let listdata = await _Api(postdata, `api/v1/POS/report/refund`);
            if (listdata.code === 200) {
                setRefunddata(listdata?.data);
                setTimeout(() => {
                    SetdataTable("refund_report_table", 0, newcolumndata, []);
                }, 50)
                setstate({
                    ...state,
                    totalPages: Math.ceil(listdata?.count / postdata.limit),
                    currentPage: current ? current : 1
                });
                setUpercount(listdata?.uppercount);
                setLowercount(listdata?.lowercount);
            } else {
                SetdataTable("refund_report_table", 0, newcolumndata, []);
            }
        }
        catch (err) {
            console.log(err, "err");
        }
    }
    useEffect(() => {
        if (datefilter[0] && datefilter[1]) {
            getrefundreport();
        }
        // eslint-disable-next-line
    }, [newcolumndata, datefilter]);

    const downloadexclsheet = async () => {
        let header_values = [];
        let bodyarray = [];
        posrefundreport_head.map(async (r, k) => {
            header_values.push(r?.lable)
        })
        if (refunddata.length) {
            refunddata.forEach((result, key) => {
                bodyarray.push([key + 1, result?.createdAt, result?.vr_no, result?.order_no, result?.SKU, result?.stock_id, result?.metal_name
                    , result?.stone_name, result?.size_name, result?.Qty, result?.price, result?.formated_amount, result?.location_name, result?.customer_name, result?.user])
            })
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
        const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([fileContent], { type: fileType });
        FileSaver.saveAs(data, "refund_report");
    }
    const selectOptions = [
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 }
    ]
    return (<>
        <div className="main-body">
            <div className="main-body-row">
                <div className="main-body-row-left-col p-0 w-100">
                    <div className="catalog-area bg-white page-menu-block Dailysalereport">
                        <div className="Dailysalereport-header">
                            <div className="Dailysalereport-header-left">
                                <h3 className='common-navigation-header-left-heading Dailysalereport-head'> <Link to="" className='gray-mid text-decoration-none Dailysalereport-head-report'>{t("REPORT")}</Link> <img className="Dailysalereport-head-arrow mx-3" src={base_assets + "images/pos/report/arrow-right.png"} alt="" /><span className="Dailysalereport-head-pagename">{t("Refund Report")}</span> </h3>
                            </div>
                            <div className="Dailysalereport-header-reportdetail">
                                <div className="Dailysalereport-header-reportdetail-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/stock-img.png"} alt=""></img>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Qty")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data lightblue">{upercount?.totalQty}</span>
                                    </div>
                                </div>

                                <div className="Dailysalereport-header-reportdetail-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/value-img.png"} alt=""></img>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Value")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data maingreen">{upercount?.amount}</span>
                                    </div>
                                </div>
                                <div className="Dailysalereport-header-reportdetail-box">
                                    <div className="Dailysalereport-header-reportdetail-box-inner">
                                        <img className="Dailysalereport-header-reportdetail-box-inner-img" src={base_assets + "images/pos/report/customer-img.png"} alt=""></img>
                                    </div>
                                    <div className="Dailysalereport-header-reportdetail-box-text">
                                        <span className="Dailysalereport-header-reportdetail-box-text-inner">{t("Total Customer")}</span>
                                        <span className="Dailysalereport-header-reportdetail-box-text-data lightgray">{upercount?.customer}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Dailysalereport-content-area">
                            <div className="Dailysalereport-content-area-header">
                                <div className="Dailysalereport-content-area-header-inner">
                                    <Reportmenupos
                                        Reportname={"Refund Report"} />
                                    <Select
                                        className="limit-select short mx-3"
                                        options={selectOptions}
                                        value={{ label: limit, value: limit }}
                                        onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                                    />
                                    <div className="date"> <Daterangepos setDatefilter={setDatefilter} /></div>
                                </div>
                                <div className="catalog-area-header-area-control-right">
                                    <div className="com-search-area">
                                        <input type="text" value={filtername} placeholder={t("Search  by Ref number, voucher type, ...")}
                                            onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                                            onChange={(e) => setFiltername(e.target.value)} />
                                        {filtername ? <img className="com-search-area-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                                        <img onClick={() => filterbyname(filtername)} className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />

                                    </div>
                                    <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                                        <button className='btn-style' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={base_assets + 'images/icons/ellipsis-circular.png'} alt="" /></button>
                                        <ul className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li onClick={() => getprintrefundrreport()} className={printparmission ? "" : "d-none"}>
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/print-icon.png"} alt="" />
                                                    </div>
                                                    {t("Print")}</Link>
                                            </li>
                                            <li onClick={() => downloadexclsheet()}>
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/pos/report/export-icon.png"} alt="" />
                                                    </div>
                                                    {t("Export")}</Link>
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target="#socialModal" className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" onClick={() => getsharerefundreport()}>
                                                <div className="dropdown-menu-img">
                                                    <img src={base_assets + "images/pos/report/share-icon.png"} alt="" />
                                                </div>
                                                {t("Share")}
                                            </li>
                                            <li data-bs-toggle="modal" data-bs-target="#columnModal">
                                                <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                                                    <div className="dropdown-menu-img">
                                                        <img src={base_assets + "images/icons/column-icon.png"} alt="" />
                                                    </div>
                                                    {t("Column")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="Dailysalereport-content-area-main">
                                <div className="main-body-main-table-wrap Dailysalereport-table" id="table-scroll">
                                    <table id='refund_report_table' className="common-table first-row-gray w-100 Dailysalereport-table-main">
                                        <Tablehead tablehead={posrefundreport_head} tblname={"report_refundreport_tbl"} setfilterby={setfilterby} />
                                        <tbody>
                                            <>
                                                {refunddata.length ? refunddata.map((result, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{result?.createdAt ? result?.createdAt : ""}</td>
                                                            <td className="link-color">{result?.vr_no ? result?.vr_no : "0"}</td>
                                                            <td className="link-color">
                                                                <Link
                                                                    to={editparmission ? "/app/pos/refund" : ""}
                                                                    className="text-decoration-none"
                                                                    state={{
                                                                        order_id: result?.order_id,
                                                                        order_edit: true,
                                                                        order_type: result?.order_type
                                                                    }}
                                                                >
                                                                    {result.order_no ? result?.order_no : "-"}
                                                                </Link>
                                                            </td>
                                                            <td className="link-color">{result?.SKU ? result?.SKU : ""}</td>
                                                            <td>{result?.stock_id ? result?.stock_id : ""}</td>
                                                            <td>{result?.metal_name ? result?.metal_name : ""}</td>
                                                            <td>{result?.stone_name ? result?.stone_name : ""}</td>
                                                            <td>{result?.size_name ? result?.size_name : ""}</td>
                                                            <td className="Dailysalereport-table-main-innerdata">{result?.Qty ? result?.Qty : ""}</td>
                                                            <td>{result?.price ? result?.price : ""}</td>
                                                            <td className="Dailysalereport-table-main-innerdata">{result?.formated_amount ? result?.formated_amount : ""}</td>
                                                            <td>{result?.location_name ? result?.location_name : ""}</td>
                                                            <td>{result?.customer_name ? result?.customer_name : ""}</td>
                                                            <td>{result?.user ? result?.user : ""}</td>

                                                        </tr>
                                                    )
                                                }) : ""}
                                            </>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="Dailysalereport-table-main-footer-data">{lowercount?.totalQty}</td>
                                                <td></td>
                                                <td className="Dailysalereport-table-main-footer-data">{lowercount?.amount}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            {refunddata.length > 0 ? (
                                <Pagination
                                    total={totalPages}
                                    current={currentPage}
                                    pagination={(crPage) => handlePaginations(crPage)}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <SocialModal shareUrl={socialurl} shareloader={shareloader} />
            <TableModal tablehead={posrefundreport_head} tblName={"report_refundreport_tbl"} setnewcolumndata={setnewcolumndata} />
        </div>

    </>)
}
export default Refundreport;
















