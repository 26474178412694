import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateTime from '../common/DateTime'
import { Tablehead } from '../common/modules/Tablehead'
import { rolesTablethead } from '../common/Tablehead'
import { SetdataTable, UnSetdataTable } from '../api/setdatatable'
import { _Api } from '../api/_call'
import { error, loading, Updateloading } from '../common/helpers/toastify'
import ConfirmationModel from '../common/modules/confirmationmodel'
import { useSelector } from 'react-redux'
import TableLoader from '../../admin/common/TableLoader'
const Roles = (props) => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [newcolumndata, setnewcolumndata] = useState(rolesTablethead)
  const [roleslistdata, setRoleslistdata] = useState([])
  const [rolename, setRolename] = useState('')
  const [showloader, setShowloader] = useState(false)
  const [description, setDescription] = useState('')
  const [filtername, setFiltername] = useState('')
  const [update, setUpdate] = useState(false)
  const [role_id, setRole_id] = useState('')
  const [roleid, setRoleid] = useState()
  const createroleslist = async () => {
    const handleValidation = (e) => {
      let formIsValid = true
      if (rolename === '') {
        error(t('Enter Rolename'))
        formIsValid = false
      }
      if (description === '') {
        error(t('Enter Description'))
        formIsValid = false
      }
      return formIsValid
    }
    if (handleValidation()) {
      let GetloadingID = loading()
      try {
        const postdata = {
          role_name: rolename,
          description: description,
          update: update ? true : false,
          role_id: role_id ? role_id : '',
        }
        let res = await _Api(postdata, `api/v1/App/User/AddRole`)
        if (res.code === 200) {
          Updateloading(t, GetloadingID, t('successfully Create Roles'))
          roleslist()
        }
      } catch (err) {
        console.log(err, 'err')
        Updateloading(t, GetloadingID, err, t('error'))
      }
    }
  }
  const deleteroleslist = async () => {
    let GetloadingID = loading()
    try {
      const postdata = {
        role_id: roleid,
      }
      let res = await _Api(postdata, `api/v1/App/User/DeleteRole`)
      if (res.code === 200) {
        Updateloading(t, GetloadingID, t('successfully Delete Roles'))
        roleslist()
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const roleslist = async (search) => {
    setShowloader(true)
    UnSetdataTable('rolelist')
    try {
      const postdata = {
        search: search ? search : "",
      }
      let res = await _Api(postdata, `api/v1/App/User/ListOfRoles`)
      if (res.code === 200) {
        setRoleslistdata(res?.data)
        setShowloader(false)
        setTimeout(() => {
          SetdataTable('rolelist', 0, newcolumndata, [0])
        }, 50)
      }
    } catch (err) {
      SetdataTable('rolelist', 0, newcolumndata, [0])
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    roleslist()
    // eslint-disable-next-line
  }, [])

  const filterbyname = (search) => {
    roleslist(search)

  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'rolelist_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission)
  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading mb-4">{t('Roles')}</h1>

              <div className="main-body-top-status-bar">
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('Search')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? (
                    <img
                      className="main-body-top-status-bar-filter-clear"
                      src={base_assets + 'images/icons/False.png'}
                      onClick={(e) => { setFiltername(''); filterbyname("") }}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <Link
                  to="/app/users/adduser"
                  className={
                    parmissiondatashow?.user_roles?.Add
                      ? 'main-body-top-inputs-bar-col-right-submit-btn com-btn ms-auto d-flex align-items-center'
                      : 'd-none'
                  }
                  data-bs-toggle="modal"
                  data-bs-target="#createRoleodal"
                  onClick={() => {
                    setDescription('')
                    setRolename('')
                  }}
                >
                  <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>
                  {t('Create')}
                </Link>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  id="rolelist"
                  className="stripe row-border order-column common-table table-striped main-table w-100"

                >
                  <Tablehead
                    tablehead={rolesTablethead}
                    tblName={'rolelist_tbl'}
                  />
                  <tbody>
                    <>
                      {roleslistdata?.length
                        ? roleslistdata.map((result, key) => {
                          return (
                            <tr className="" key={key}>
                              <td className="fixed-side">
                                {parmissiondatashow?.user_roles?.Edit ?
                                  <Link
                                    to={result?.name === 'Administrator' ? "" : "/app/users/rolesUser"}
                                    state={{
                                      role_id: result?.id,
                                      disablecheck:
                                        result?.name === 'Administrator'
                                          ? true
                                          : false,
                                      name: result?.name
                                    }}
                                    className="gray-text text-decoration-none link-color"
                                  >
                                    {result?.name ? result?.name : '-'}
                                  </Link> :
                                  <>   {result?.name ? result?.name : '-'}</>

                                }

                              </td>
                              <td className="fixed-side">
                                {' '}
                                {result?.description
                                  ? result?.description
                                  : '-'}
                              </td>
                              <td className="fixed-side">
                                {' '}
                                {result?.users ? result?.users : '-'}
                              </td>
                              {result?.name === 'Administrator' ? (
                                <td></td>
                              ) : (
                                <td className="fixed-side">
                                  <button
                                    className={
                                      parmissiondatashow?.user_roles?.Edit
                                        ? 'btn-style mx-3'
                                        : 'd-none'
                                    }
                                    onClick={() => {
                                      setUpdate(true)
                                      setRole_id(result?.id)
                                      setDescription(result?.description)
                                      setRolename(result?.name)
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#createRoleodal"
                                  >
                                    <img
                                      style={{ width: '20px' }}
                                      src={
                                        base_assets +
                                        'images/icons/edit-icon.png'
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <button
                                    className={
                                      parmissiondatashow?.user_roles?.Delete
                                        ? 'btn-style'
                                        : 'd-none'
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#createPurchaseModal"
                                    onClick={() => setRoleid(result?.id)}
                                  >
                                    <img
                                      style={{ width: '20px' }}
                                      src={
                                        base_assets +
                                        'images/icons/delete-icon.png'
                                      }
                                      alt=""
                                    />
                                  </button>
                                </td>
                              )}
                            </tr>
                          )
                        })
                        : ''}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {showloader && <TableLoader />}
              </div>

              <div
                className="modal fade create-role-modal common-modal"
                id="createRoleodal"
                tabIndex="-1"
                aria-labelledby="createRoleodalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content pt-0">
                    <div className="modal-header">
                      <h5
                        className="modal-title fw-semibold"
                        id="createRoleodalLabel"
                      >
                        {t('Create Role')}
                      </h5>
                      <button
                        type="button"
                        className="btn-close shadow-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body mt-4">
                      <div className="placeholder-up-input form-floating w-100 mb-3">
                        <input
                          value={rolename}
                          onChange={(e) => setRolename(e.target.value)}
                          type="text"
                          className="placeholder-up-input-field form-control bg-white"
                          placeholder="text"
                        />
                        <label className="placeholder-up-input-label">
                          {t('Role Name')}
                        </label>
                      </div>
                      <div className="textarea placeholder-up-input form-floating w-100">
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          type="text"
                          className="placeholder-up-input-field form-control bg-white"
                          placeholder="text"
                          rows="4"
                        />
                        <label className="placeholder-up-input-label">
                          {t('Description')}
                        </label>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="w-25 com-btn m-0"
                        onClick={() => createroleslist()}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {t('Save')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModel
          statement={'Delete'}
          handler_submit={deleteroleslist}
        ></ConfirmationModel>
      </div>
    </>
  )
}
export default Roles
