import React, { useEffect, useState } from 'react'
// import 'react-awesome-slider/dist/styles.css';
// import "../style.scss"
import Slider from "react-slick";
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { _Api } from '../../../common/api';

export default function CollectionSlider({title}) {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { pathname } = useLocation()
    const { t } = useTranslation();
    const [sliderlist, setSliderlist] = useState()
   
    // let sliderRef = useRef(null);

    // const next = () => {
    //     sliderRef.slickNext();
    // };

    // const previous = () => {
    //     sliderRef.slickPrev();
    // };
    const sliderdata = async () => {
        try {
            let postdata = {
                type: "collection"
            }
            let res = await _Api(postdata, `product/collections`)
            if (res?.statusCode === 200) {
                setSliderlist(res?.data)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div
                className={`sliderarrow top-50 ms-2 translate-middle position-absolute cursor-pointer`}
                style={{ right: '-24px' }}
                onClick={onClick}
            >
                <svg xmlns="http://www.w3.org/2000/svg" color='#fff' width={50} height={50} fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>

            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div
                className={`sliderarrow lg:ms-5 ms-4 top-50 start-0 translate-middle position-absolute cursor-pointer`}
                style={{ zIndex: 10 }}
                onClick={onClick}
            >
                <svg xmlns="http://www.w3.org/2000/svg" color='#fff' width={50} height={50} fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>

            </div>
        );
    }
    var settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        responsive: [
           
            {
                breakpoint: 821,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 431,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                }
            },

        ]
    };
    useEffect(() => {
        sliderdata()
    }, [])

  return (
    <div className='new_collectionslider position-relative'>
        <div>
            {title ?
                <div className='new_collectionslider-title'>
                    {title}
                </div>
            : null }
        </div>
    {/* 
    <div style={{ top: '40%', zIndex: '1', padding: '10px' }} className='new_collectionslider-slidernextIcons d-flex w-100 justify-content-between position-absolute' >
        <button onClick={previous} style={{ background: 'none', border: 'none' }} > <img src={LeftArrow} alt='icon' />  </button>
        <button onClick={next} style={{ background: 'none', border: 'none' }} ><img src={RightArrow} alt='icon' />  </button>
    </div> */}
    <Slider
        {...settings}
    >


        {sliderlist?.length ? sliderlist.map((result, key) => (
            <div className='new_collectionslider-container' key={key}>
                <img
                    src={result?.logo ? result?.logo : ""}
                    className='new_collectionslider-container-image'
                    alt=""
                />
                <div className='new_collectionslider-container-res_content'>
                <div className='new_collectionslider-container-res_content-head'>{result?.name}</div>
                </div>
                <div
                    className='new_collectionslider-container-content'
                >
                    <div className='new_collectionslider-container-content-head'>{result?.name}</div>
                    <p className='new_collectionslider-container-content-subtitle'>{result?.description}<br /></p>
                    <button className='new_collectionslider-container-content-btn bg-transparent border-0 d-flex m-auto align-items-center gap-2'>
                        <Link className='text-decoration-none text-white' to={`/Category/${result?.slug}`}
                            onClick={() => { window.scrollTo(0, 0) }}>{t("Show Now")}</Link>
                        <img className='ms-auto' src={base_assets + "images/admin/icons/right-chevron.png"} alt="" />
                    </button>
                </div>
                <div className='new_collectionslider-container-des'>
                    <span className='new_collectionslider-container-des-title'>{result?.name}</span>
                    <span className='new_collectionslider-container-des-sub'>{result?.description}</span>
                </div>
            </div>
        )) : ""}
    </Slider>
</div >
  )
}






