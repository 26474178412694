import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const CopyToAllchoice = (props) => {
  const { t } = useTranslation()
  const { productChoiceLocation, productChoiceData, productTabIndex, setProductChoiceData } = props;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [productChoiceLocation_, setproductChoiceLocation_] = useState([]);

  useEffect(() => {
    var productChoiceLocation_arr = [...productChoiceLocation];
    setproductChoiceLocation_(productChoiceLocation_arr);
  }, [productChoiceLocation]);

  const handleClick_checkBox = (key, index) => {
    var productChoiceData_ = [...productChoiceData];
    var productChoiceLocation_arr = [...productChoiceLocation_];
    var checked_data = document.getElementById(key + index).checked;
    productChoiceData_[productTabIndex]['location'][index][key] = checked_data;
    setProductChoiceData(productChoiceData_);
    setproductChoiceLocation_(productChoiceLocation_arr);

  };

  const Resetvaluesssss = () => {
    let keysss = "price_check";
    var productChoiceData_ = [...productChoiceData];
    if (productChoiceData_[0]?.location) {
      productChoiceData_[0]?.location?.forEach((element, index) => {
        document.getElementById(keysss + index).checked = false;
        element.price_check = false;
      })
    }
    setProductChoiceData(productChoiceData_);
  };

  return (
    <React.Fragment key="1">
      <div
        className="modal fade copyallmodal"
        id="CopyToAll_choice"
        tabIndex={-1}
        aria-labelledby="CopyAllModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h1 className="copyallmodal-heading">
                {t("Use same values as first variant for all?")}
              </h1>
              <p className="copyallmodal-para">
                {t("This will copy first varient and use it to add or replace")} <br />

                {t("values for all variants.")}
              </p>


              <div className="copyallmodal-country">
                <label className="copyallmodal-country-label">{t("Currency")}</label>
                <div className="copyallmodal-country-select-row">

                  {productChoiceLocation_?.map((element, index) => {
                    if (element.price_check === undefined) {
                      element.price_check = false;
                    }

                    return (
                      <React.Fragment key={index}>
                        <div className="copyallmodal-country-select-row-single">
                          <div className="com-check">
                            <input
                              type="checkbox"
                              id={"price_check" + index}
                              onChange={() =>
                                handleClick_checkBox("price_check", index)
                              }
                              checked={element.price_check}
                            />
                            <label
                              className="com-check-label"
                              htmlFor={"price_check" + index}
                            ></label>
                            <img
                              src={
                                base_assets +
                                "images/flags/" +
                                element?.currency?.toLowerCase() +
                                ".png"
                              } alt=""
                            />
                            <label htmlFor={"price_check" + index}>

                              <span className="copyallmodal-country-name">
                                {element.currency}
                              </span>
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div className="copyallmodal-btn-row">
                <button
                  type="button"
                  className="btn copyallmodal-btn-row-cancel"
                  data-bs-dismiss="modal"
                >

                  {t("cancel")}
                </button>
                <button data-bs-dismiss="modal" type="button" onClick={() => Resetvaluesssss()} className="btn">
                  {t("yes")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CopyToAllchoice;
