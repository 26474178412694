import React, { useEffect, useState } from 'react'
import LeftSideArrowIcon from '../../assets/icons/LeftSideArrowIcon'
import PlusIcon from '../../assets/icons/PlusIcon';
import Select, { components } from "react-select";
import { _Api, _Apiauth } from "../../../common/api";
import AccountSidebar from './AccountSidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MinusIcon from "../../assets/icons/MinusIcon";
import { useTranslation } from 'react-i18next';
import { Nationality } from "../../../../../pos/common/module/nationalityarray";
const AccountShippingAdd = (props) => {
    const { ValueContainer, Placeholder } = components;
    const { state } = useLocation()
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [validation, setValidation] = useState()
    const [dobvalues, setDobvalues] = useState({ date: [], month: [], year: [] })
    const[nationalityoption, setNationalityoption] =useState()
    useEffect(()=>{
        if(Nationality?.length){
            let Nationalitydata=[]
            Nationality.forEach((result)=>{
                Nationalitydata.push({value: result, label:result})
            })
            setNationalityoption(Nationalitydata)
        }
    },[])
    const [alladdressdata, setAlladdressdata] = useState({
        govt_ids: [{ idtype: "", idnumber: "", }],
        contacts: [{ icon: 1, phoneCode: '91', number: '' }],
        emails: [""],
        "billing_address": state?.billing_address ? state?.billing_address : false,
        "is_default": 0,
        "UDID": state?.id ? state?.id : "",
    })
    console.log(alladdressdata, "alladdressdata")
    const [selectdata, setSelectdata] = useState({ country: [], phoneCode: [] })
    const setalldata = (keyname, value) => {
        setAlladdressdata((state) => ({
            ...state,
            [keyname]: value
        }))
    }
    console.log(selectdata, "selectdata")
    let gender = ["Male", "Female", "Other"]

    const idFirstDropdownOption = [
        {
            value: "Passport",
            label: "Passport",
        },
        {
            value: "Driving License",
            label: "Driving License",
        },
        {
            value: "Citizen ID",
            label: "Citizen ID",
        },
    ];
    const CustomValueContainer = ({ children, ...props }) => {
        return (
          <ValueContainer {...props}>
           
            {React.Children.map(children, (child) =>
              child && child.type !== Placeholder ? child : null
            )}
          </ValueContainer>
        );
      };
    const handleSelectChange = (index, lable, value1, value2) => {
        if (lable === "govt_id") {
            let newValues_ = { ...alladdressdata }
            let newValues = [...newValues_?.govt_ids]
            newValues[index] = { idtype: value1, idnumber: value2 };
            newValues_.govt_ids = newValues
            setAlladdressdata(newValues_)
        } else if (lable === "emails") {
            let newValues_ = { ...alladdressdata }
            let newValues = [...newValues_?.emails]
            newValues[index] = value1;
            newValues_.emails = newValues
            setAlladdressdata(newValues_)
        } else if (lable === "contacts") {
            let newValues_ = { ...alladdressdata }
            let newValues = [...newValues_?.contacts]
            newValues[index] = { icon: 1, phoneCode: value1, number: value2 };
            newValues_.contacts = newValues
            setAlladdressdata(newValues_)
        }
    };
    const addEventList = (lable) => {
        if (lable === "ID") {
            let newValues_ = { ...alladdressdata }
            let newValues = [...newValues_?.govt_ids]
            newValues.push({ idtype: "", idnumber: "" });
            newValues_.govt_ids = newValues
            setAlladdressdata((prevState) => ({
                ...prevState,
                govt_ids: newValues,
            }))
        }
        else if (lable === "emails") {
            let newValues_ = { ...alladdressdata }
            let newValues = [...newValues_?.emails]
            newValues_.emails = newValues
            newValues.push("");
            setAlladdressdata((prevState) => ({
                ...prevState,
                emails: newValues,
            }))
        }
        else {
            let newValues_ = { ...alladdressdata }
            let newValues = [...newValues_?.contacts]
            newValues.push({ phoneCode: '', icon: 1, number: "" });
            newValues_.contacts = newValues
            setAlladdressdata((prevState) => ({
                ...prevState,
                contacts: newValues,
            }))
        }
    };
    const handleRemoveElement = (indexToRemove, label) => {
        if (label === "emails") {
            let newValues_ = { ...alladdressdata }
            let updatedArray = newValues_.emails.filter((_, index) => index !== indexToRemove);
            setAlladdressdata((prevState) => ({
                ...prevState,
                emails: updatedArray,
            }))
        } else if (label === "phone") {
            let newValues_ = { ...alladdressdata }
            let updatedArray = newValues_.contacts.filter((_, index) => index !== indexToRemove);
            setAlladdressdata((prevState) => ({
                ...prevState,
                contacts: updatedArray,
            }))
        } else if (label === "govt_id") {
            let newValues_ = { ...alladdressdata }
            let updatedArray = newValues_.govt_ids.filter((_, index) => index !== indexToRemove);
            setAlladdressdata((prevState) => ({
                ...prevState,
                govt_ids: updatedArray,
            }))
        }


    };
    const addaddress = async () => {
        const validfrom = () => {
            let fromisvalid = true
            if (!alladdressdata?.fname) {
                setValidation((state) => ({
                    ...state,
                    fname: "Fname is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    fname: ""
                }))
            }
            if (!alladdressdata?.lname) {
                setValidation((state) => ({
                    ...state,
                    lname: "Lname is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    lname: ""
                }))
            }
            if (!alladdressdata?.nationality) {
                setValidation((state) => ({
                    ...state,
                    nationality: "Nationality is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    nationality: ""
                }))
            }
            if (!alladdressdata?.title) {
                setValidation((state) => ({
                    ...state,
                    title: "Title is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    title: ""
                }))
            }
            if (!alladdressdata?.govt_ids[0]?.idnumber || !alladdressdata?.govt_ids[0]?.idtype) {
                setValidation((state) => ({
                    ...state,
                    govt_ids: "Govt ids is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    govt_ids: ""
                }))
            }
            if (!alladdressdata?.date_of_birth?.date || !alladdressdata?.date_of_birth?.month?.value || !alladdressdata?.date_of_birth?.year) {
                setValidation((state) => ({
                    ...state,
                    formatedate: "DOB is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    formatedate: ""
                }))
            }
            if (!alladdressdata?.age) {
                setValidation((state) => ({
                    ...state,
                    age: "age is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    age: ""
                }))
            }
            if (!alladdressdata?.gender) {
                setValidation((state) => ({
                    ...state,
                    gender: "Gender is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    gender: ""
                }))
            }
            if (!alladdressdata?.address) {
                setValidation((state) => ({
                    ...state,
                    address: "Address is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    address: ""
                }))
            }
            if (!alladdressdata?.country) {
                setValidation((state) => ({
                    ...state,
                    country: "Country is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    country: ""
                }))
            }
            if (!alladdressdata?.city) {
                setValidation((state) => ({
                    ...state,
                    city: "City is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    city: ""
                }))
            }
            if (!alladdressdata?.state) {
                setValidation((state) => ({
                    ...state,
                    state: "State is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    state: ""
                }))
            }
            if (!alladdressdata?.zipcode) {
                setValidation((state) => ({
                    ...state,
                    zipcode: "Zipcode is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    zipcode: ""
                }))
            }
            if (!alladdressdata?.contacts[0]?.number || !alladdressdata?.contacts[0]?.phoneCode) {
                setValidation((state) => ({
                    ...state,
                    contacts: "Contacts is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    contacts: ""
                }))
            }
            if (!alladdressdata?.emails[0]) {
                setValidation((state) => ({
                    ...state,
                    emails: "Email is Require"
                }))
                fromisvalid = false
            } else {
                const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
                    ;
                if (!isEmail(alladdressdata?.emails[0])) {
                    setValidation((state) => ({
                        ...state,
                        emails: "Invalid Email ",
                    }));
                    fromisvalid = false;
                } else {
                    setValidation((state) => ({
                        ...state,
                        emails: ""
                    }))
                }
            }

            return fromisvalid;
        }
        if (validfrom()) {
            let obj = { ...alladdressdata }
            obj.date_of_birth.month = alladdressdata?.date_of_birth?.month.value
            delete obj.UDID
            obj.country = alladdressdata?.country?.value
            if (alladdressdata?.govt_ids?.length) {
                let govt_ids = []
                alladdressdata.govt_ids.forEach((result) => {
                    if (result?.idnumber && result?.idtype) {
                        govt_ids.push(result)
                    }
                })
                obj.govt_ids = govt_ids
            }
            if (alladdressdata?.contacts?.length) {
                let contacts = []
                alladdressdata.contacts.forEach((result) => {
                    if (result?.number && result?.phoneCode) {
                        contacts.push(result)
                    }
                })
                obj.contacts = contacts
            }
            if (alladdressdata?.emails?.length) {
                let emails = []
                alladdressdata.emails.forEach((result) => {
                    if (result) {
                        emails.push(result)
                    }
                })
                obj.emails = emails
            }

            try {
                let postdata = obj
                let res = await _Api(postdata, `account/addAddress`)
                if (res?.statusCode === 200) {
                    navigate('/account/address')
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }

    }

    const callAge = (e) => {
        const current = new Date();
        const year = current.getFullYear();
        const calage = year - e.value;
        setalldata("age", calage)
    };
    const getcountry = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `redis/countryList`)
            if (res?.statusCode === 200) {
                let countrydata = []
                let phonecode = []
                res?.data.forEach((result) => {
                    countrydata.push({
                        label: result?.name,
                        value: result?.id
                    })
                    phonecode.push({
                        label: result?.phoneCode,
                        value: result?.phoneCode
                    })
                })
                setSelectdata({ country: countrydata, phoneCode: phonecode })
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getaddressbyid = async (month) => {
        try {
            let postdata = {
                "id": state?.id ? state?.id : "",
                "billing_address": state?.billing_address ? state?.billing_address : false
            }
            let res = await _Api(postdata, `account/getAddressByID`)
            if (res?.statusCode === 200) {
                let obj = { ...res?.data }
                const monthMatch = month.find(element => element?.value === res?.data?.date_of_birth?.month);
                if (monthMatch) {
                    obj.date_of_birth.month = monthMatch;
                }
                setAlladdressdata(obj)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getcountry()
        const Dateoption = [...new Array(31)].map((each, index) => ({
            label: index + 1,
            value: index + 1,
        }));
        setDobvalues((state) => ({
            ...state,
            date: Dateoption
        }));
        const monthoption = [
            {
                value: 1,
                label: "JAN",
            },
            {
                value: 2,
                label: "FEB",
            },
            {
                value: 3,
                label: "MAR",
            },
            {
                value: 4,
                label: "APR",
            },
            {
                value: 5,
                label: "MAY",
            },
            {
                value: 6,
                label: "JUN",
            },
            {
                value: 7,
                label: "JUL",
            },
            {
                value: 8,
                label: "AUG",
            },
            {
                value: 9,
                label: "SEP",
            },
            {
                value: 10,
                label: "OCT",
            },
            {
                value: 11,
                label: "NOV",
            },
            {
                value: 12,
                label: "DEC",
            },

        ]
        setDobvalues((state) => ({
            ...state,
            month: monthoption
        }));
        const current = new Date();
        const lastyear = current.getFullYear() - 5;
        const firstyear = lastyear - 50;
        const yearoption = [...new Array(lastyear - firstyear + 2)]
            .fill()
            .map((_, idx) => ({ label: firstyear + idx, value: firstyear + idx }));
        setDobvalues((state) => ({
            ...state,
            year: yearoption
        }));
        if (state?.id) {
            getaddressbyid(monthoption)
        }
        // eslint-disable-next-line 
    }, [state?.id])
    const editaddress = async () => {
        const validfrom = () => {
            let fromisvalid = true
            if (!alladdressdata?.fname) {
                setValidation((state) => ({
                    ...state,
                    fname: "Fname is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    fname: ""
                }))
            }
            if (!alladdressdata?.lname) {
                setValidation((state) => ({
                    ...state,
                    lname: "Lname is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    lname: ""
                }))
            }
            if (!alladdressdata?.nationality) {
                setValidation((state) => ({
                    ...state,
                    nationality: "Nationality is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    nationality: ""
                }))
            }
            if (!alladdressdata?.title) {
                setValidation((state) => ({
                    ...state,
                    title: "Title is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    title: ""
                }))
            }
            if (!alladdressdata?.govt_ids[0]?.idnumber || !alladdressdata?.govt_ids[0]?.idtype) {
                setValidation((state) => ({
                    ...state,
                    govt_ids: "Govt ids is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    govt_ids: ""
                }))
            }
            if (!alladdressdata?.date_of_birth?.date || !alladdressdata?.date_of_birth?.month?.value || !alladdressdata?.date_of_birth?.year) {
                setValidation((state) => ({
                    ...state,
                    formatedate: "DOB is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    formatedate: ""
                }))
            }
            if (!alladdressdata?.age) {
                setValidation((state) => ({
                    ...state,
                    age: "age is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    age: ""
                }))
            }
            if (!alladdressdata?.gender) {
                setValidation((state) => ({
                    ...state,
                    gender: "Gender is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    gender: ""
                }))
            }
            if (!alladdressdata?.address) {
                setValidation((state) => ({
                    ...state,
                    address: "Address is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    address: ""
                }))
            }
            if (!alladdressdata?.country) {
                setValidation((state) => ({
                    ...state,
                    country: "Country is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    country: ""
                }))
            }
            if (!alladdressdata?.city) {
                setValidation((state) => ({
                    ...state,
                    city: "City is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    city: ""
                }))
            }
            if (!alladdressdata?.state) {
                setValidation((state) => ({
                    ...state,
                    state: "State is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    state: ""
                }))
            }
            if (!alladdressdata?.zipcode) {
                setValidation((state) => ({
                    ...state,
                    zipcode: "Zipcode is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    zipcode: ""
                }))
            }
            if (!alladdressdata?.contacts[0]?.number || !alladdressdata?.contacts[0]?.phoneCode) {
                setValidation((state) => ({
                    ...state,
                    contacts: "Contacts is Require"
                }))
                fromisvalid = false
            } else {
                setValidation((state) => ({
                    ...state,
                    contacts: ""
                }))
            }
            if (!alladdressdata?.emails[0]) {
                setValidation((state) => ({
                    ...state,
                    emails: "Email is Require"
                }))
                fromisvalid = false
            } else {
                const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
                    ;
                if (!isEmail(alladdressdata?.emails[0])) {
                    setValidation((state) => ({
                        ...state,
                        emails: "Invalid Email ",
                    }));
                    fromisvalid = false;
                } else {
                    setValidation((state) => ({
                        ...state,
                        emails: ""
                    }))
                }
            }
            return fromisvalid;
        }

        if (validfrom()) {
            let obj = { ...alladdressdata }
            obj.date_of_birth.month = alladdressdata?.date_of_birth?.month?.value
            obj.country = alladdressdata?.country?.value
            obj.billing_address = state?.billing_address ? state?.billing_address : false
            if (obj.mname === "") {
                delete obj.mname
            }
            if (alladdressdata?.govt_ids?.length) {
                let govt_ids = []
                alladdressdata.govt_ids.forEach((result) => {
                    if (result?.idnumber && result?.idtype) {
                        govt_ids.push(result)
                    }
                })
                obj.govt_ids = govt_ids
            }
            if (alladdressdata?.contacts?.length) {
                let contacts = []
                alladdressdata.contacts.forEach((result) => {
                    if (result?.number && result?.phoneCode) {
                        contacts.push(result)
                    }
                })
                obj.contacts = contacts
            }
            if (alladdressdata?.emails?.length) {
                let emails = []
                alladdressdata.emails.forEach((result) => {
                    if (result) {
                        emails.push(result)
                    }
                })
                obj.emails = emails
            }
            try {
                let postdata = obj
                let res = await _Api(postdata, `account/updateAddress`)
                if (res?.statusCode === 200) {
                    navigate('/account/address')
                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }
    }
    const titleoption = [
        {
          value: "Mr",
          label: t("Mr."),
        },
        {
          value: "Miss",
          label: t("Miss"),
        },
        {
          value: "Mrs",
          label: t("Mrs"),
        },
        {
          value: "Ms",
          label: t("Ms"),
        },
      ];
    return (
        <div className='accountDetailMainPage d-flex ecommerce-main-layout-bodyContent'>
            <AccountSidebar />
            <div className='account-details'>
                <div className='addAdressBox'>
                    <div className='addAdressBox-headBox'>
                        <p className='addAdressBox-headBox-heading'>{state?.address}</p>
                        <div className='addAdressBox-headBox-backArrowBtnBox'>
                            <Link to="/account/address">
                                <span className='arrowIconbtn'>
                                    <LeftSideArrowIcon width={16.71} height={16.71} />
                                </span >
                            </Link>
                            <span className='backtextShow'>{t("My Addresses")}</span></div>
                    </div>
                    <div className='addAdressBox-formBody'>
                        <div className='addAdressBox-formBody-stepfirst'>
                            <div className='addAdressBox-formBody-nameBox'>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("First Name")} <span className='com-starShow'>*</span></label>
                                    <input className={validation?.fname ? 'form-control border-danger' : "form-control"}
                                        value={alladdressdata?.fname}
                                        onChange={(e) => setalldata("fname", e.target.value)} />
                                    {validation?.fname ? <div className='text-danger'>{validation?.fname}</div> : ""}
                                </div>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Middle Name")}</label>
                                    <input className="form-control"
                                        value={alladdressdata?.mname}
                                        onChange={(e) => setalldata("mname", e.target.value)} />
                                </div>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Last Name")} <span className='com-starShow'>*</span></label>
                                    <input className={validation?.lname ? 'form-control border-danger' : "form-control"}
                                        value={alladdressdata?.lname}
                                        onChange={(e) => setalldata("lname", e.target.value)} />
                                    {validation?.lname ? <div className='text-danger'>{validation?.lname}</div> : ""}
                                </div>
                            </div>
                            <div className='addAdressBox-formBody-nameBox'>
                                    <div className='com-formInputBox'>
                                                                            <label className=''>{t("Nationality")} <span className='com-starShow'>*</span></label>
                                                                            
                                                                                <Select
                                                                                value={ { label: alladdressdata?.nationality, value:alladdressdata?.nationality } }
                                                                                onChange={(e) => setalldata("nationality", e.value)} 
                                                                                options={nationalityoption}
                                                                                className="ecommerce-dropdown"
                                                                                components={{
                                                                                    ValueContainer: CustomValueContainer,
                                                                                }}
                                                                                placeholder="Natioanlity"
                                                                                classNamePrefix="common-select"
                                                                                isSearchable={true}
                                                                                />
                                                                            {validation?.nationality ? <div className='text-danger'>{validation?.nationality}</div> : ""}
                                                                        </div>

                                 <div className='com-formInputBox'>
                                                                            <label className=''>{t("Title")}<span className='com-starShow'>*</span></label>
                                                                           
                                                                                <Select
                                                                                value={ { label: alladdressdata?.title, value: alladdressdata?.title } }
                                                                                onChange={(e) => setalldata("title", e.value)}    
                                                                                className="ecommerce-dropdown"
                                                                                placeholder={t("Title")}
                                                                                options={titleoption}
                                                                                components={{
                                                                                    ValueContainer: CustomValueContainer,
                                                                                }}
                                                                                menuPlacement="auto"
                                                                                classNamePrefix="common-select"
                                                                                getOptionLabel={(e) => (
                                                                                    <div className="">
                                                                                    <span>{e.label}</span>
                                                                                    </div>
                                                                                )}
                                                                                isSearchable={false}
                                                                                />
                                                                           {validation?.title ? <div className='text-danger'>{validation?.title}</div> : ""}
                                                                </div>
                            </div>
                        </div>
                        <div className='addAdressBox-formBody-stepfirst pt-0'>
                            {alladdressdata?.govt_ids?.length ? alladdressdata?.govt_ids?.map((result, key) => {
                                return (
                                    <div className='addAdressBox-formBody-nameBox' key={key}>
                                        <div className="d-flex flex-column w-100">
                                            <div className='com-formInputBox'>
                                                <label className=''>{t("ID")}<span className='com-starShow'>*</span></label>
                                                <Select
                                                    className="ecommerce-dropdown"
                                                    options={idFirstDropdownOption}
                                                    classNamePrefix="common-select"
                                                    value={{ label: result?.idtype, value: result?.idtype }}
                                                    onChange={(e) => handleSelectChange(key, "govt_id", e.value, result?.idnumber)}
                                                />
                                            </div>
                                        </div>
                                        <div className='com-formInputBox'>
                                            <label className=''>{t("Number")}
                                                <span className='com-starShow'>*</span></label>
                                            <div className='formBox-withMinusBtn border-0'>
                                                <input className='form-control'
                                                    value={result?.idnumber}
                                                    onChange={(e) => handleSelectChange(key, "govt_id", result?.idtype, e.target.value)} />
                                                {key > 0 ?
                                                    <button className='formBox-withMinusBtn-removebtn'
                                                        onClick={() => handleRemoveElement(key, "govt_id")}><MinusIcon /></button>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                                : ""}
                            <div className='com-addFormItem-box mt-2'>
                                {validation?.govt_ids ? <div className='text-danger'>{validation?.govt_ids}</div> : ""}
                                <div className='com-addFormItem-box-inner w-25' onClick={() => addEventList("ID")}>
                                    <span className='d-flex'><PlusIcon width={16} height={16} color={'#1CACAC'} /></span>
                                    {t("Add ID")}
                                </div>
                            </div>
                            <div className='addAdressBox-formBody-nameBox'>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Birthdate (Date, Month, Year)")} <span className='com-starShow'>*</span></label>
                                    <div className="birthday-calendarBox ">
                                        <div className="col-md-4 col-4">
                                            <Select
                                                className="ecommerce-dropdown w-100 p-0"
                                                placeholder=""
                                                options={dobvalues?.date}
                                                value={{ value: alladdressdata?.date_of_birth?.date, label: alladdressdata?.date_of_birth?.date }}
                                                onChange={(e) => setAlladdressdata((state) => ({
                                                    ...state,
                                                    date_of_birth: {
                                                        ...state?.date_of_birth,
                                                        date: e.value
                                                    }
                                                }))}
                                                classNamePrefix="common-select"
                                                isSearchable={false}
                                            />
                                        </div>
                                        <div className="col-md-4 col-4">
                                            <Select
                                                className="ecommerce-dropdown w-100 p-0"
                                                placeholder=""
                                                options={dobvalues?.month}
                                                value={alladdressdata?.date_of_birth?.month || null}
                                                onChange={(e) => setAlladdressdata((state) => ({
                                                    ...state,
                                                    date_of_birth: {
                                                        ...state?.date_of_birth,
                                                        month: e
                                                    }
                                                }))}
                                                classNamePrefix="common-select"
                                                isSearchable={false}
                                            />
                                        </div>
                                        <div className="col-md-4 col-4">
                                            <Select
                                                className="ecommerce-dropdown w-100 p-0"
                                                placeholder=""
                                                options={dobvalues?.year}
                                                value={{
                                                    value: alladdressdata?.date_of_birth?.year,
                                                    label: alladdressdata?.date_of_birth?.year
                                                }}
                                                onChange={(e) => {
                                                    setAlladdressdata((state) => ({
                                                        ...state,
                                                        date_of_birth: {
                                                            ...state?.date_of_birth,
                                                            year: e.value
                                                        }
                                                    })); callAge(e)
                                                }}
                                                classNamePrefix="common-select"
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                    {validation?.formatedate ? <div className='text-danger'>{validation?.formatedate}</div> : ""}
                                </div>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Age")} <span className='com-starShow'>*</span></label>
                                    <input className={validation?.age ? 'form-control border-danger' : "form-control"}
                                        disabled
                                        value={alladdressdata?.age}
                                        onChange={(e) => setalldata("age", e.target.value)} />
                                    {validation?.age ? <div className='text-danger'>{validation?.age}</div> : ""}
                                </div>
                            </div>
                            <div className='gender-checkBox'>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Gender")}<span className='com-starShow'>*</span></label>
                                </div>
                                <div className='gender-checkBox-checkContainer'>
                                    {gender?.length ? gender.map((result, kry) => {
                                        return (
                                            <div className='gender-checkBox-checkContainer-singleCheck'>
                                                <input type="radio"
                                                    id={result}
                                                    name="gender"
                                                    value={result}
                                                    checked={alladdressdata?.gender === result}
                                                    onChange={(e) => setalldata("gender", e.target.value)} />
                                                <label htmlFor={result}>{result}</label>

                                            </div>
                                        )
                                    }) : ""}
                                    {validation?.gender ? <div className='text-danger'>{validation?.gender}</div> : ""}
                                </div>
                            </div>
                        </div>
                        <div className='addAdressBox-formBody-stepfirst pt-0 mb-0'>
                            <h3 className='contact-header-box'>{t("Contact")}</h3>
                            <div className='addAdressBox-formBody-nameBox'>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Address")} <span className='com-starShow'>*</span></label>
                                    <input className={validation?.address ? 'form-control border-danger' : "form-control"}
                                        value={alladdressdata?.address}
                                        onChange={(e) => setalldata("address", e.target.value)} />
                                    {validation?.address ? <div className='text-danger'>{validation?.address}</div> : ""}
                                </div>
                            </div>
                            <div className='addAdressBox-formBody-nameBox'>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Country")} <span className='com-starShow'>*</span></label>
                                    <Select
                                        className="ecommerce-dropdown"
                                        options={selectdata?.country}
                                        classNamePrefix="common-select"
                                        value={alladdressdata?.country}
                                        onChange={(e) => setalldata("country", e)}
                                    />
                                    {validation?.country ? <div className='text-danger'>{validation?.country}</div> : ""}
                                </div>
                                <div className='addAdressBox-formBody-nameBox'>
                                    <div className='com-formInputBox'>
                                        <label className=''>{t("Province/State")}
                                            <span className='com-starShow'>*</span></label>
                                        <input className={validation?.state ? 'form-control border-danger' : "form-control"}
                                            value={alladdressdata?.state}
                                            onChange={(e) => setalldata("state", e.target.value)} />
                                        {validation?.state ? <div className='text-danger'>{validation?.state}</div> : ""}
                                    </div>

                                </div>
                            </div>
                            <div className='addAdressBox-formBody-nameBox'>
                                <div className='addAdressBox-formBody-nameBox'>
                                    <div className='com-formInputBox'>
                                        <label className=''>{t("City")} <span className='com-starShow'>*</span></label>
                                        <input className={validation?.city ? 'form-control border-danger' : "form-control"}
                                            value={alladdressdata?.city}
                                            onChange={(e) => setalldata("city", e.target.value)} />
                                        {validation?.city ? <div className='text-danger'>{validation?.city}</div> : ""}
                                    </div>
                                </div>
                                <div className='com-formInputBox'>
                                    <label className=''>{t("Zip Code")} <span className='com-starShow'>*</span></label>
                                    <input className={validation?.zipcode ? 'form-control border-danger' : "form-control"}
                                        value={alladdressdata?.zipcode}
                                        onChange={(e) => setalldata("zipcode", e.target.value)}
                                    />
                                    {validation?.zipcode ? <div className='text-danger'>{validation?.zipcode}</div> : ""}
                                </div>
                            </div>
                            <div className="addAdressBox-formBody-nameBox align-items-start">
                                <div className="com-formPhonefield">
                                    <label className="form-label">{t("Phone")}</label>
                                    {alladdressdata?.contacts?.length ? alladdressdata?.contacts.map((result, key) => {
                                        return (
                                            <div className='phone-filed-container addAdressBox-formBody-nameBox' key={key}>
                                                <div className='d-flex align-items-center phone-filed-container-inner w-100 gap-1 mb-2'>
                                                    <div className='d-flex -flex phone-filed-container-inner-input com-formPhonefield-phoneInputBox w-100 '>
                                                        <Select
                                                            className="ecommerce-dropdown com-formPhonefield-phoneInputBox-selectCodeBox"
                                                            options={selectdata?.phoneCode}
                                                            classNamePrefix="common-select"
                                                            isSearchable={true}
                                                            value={{ value: result?.phoneCode, label: result?.phoneCode }}
                                                            onChange={(e) => handleSelectChange(key, "contacts", e.value, result?.number)}
                                                        />
                                                        <input type='number' className="com-formPhonefield-phoneInputBox-inputBox"
                                                            value={result?.number}
                                                            onChange={(e) => handleSelectChange(key, "contacts", result?.phoneCode, e.target.value)}
                                                        />
                                                    </div>
                                                    {key > 0 ? <button className='d-flex justify-content-center align-items-center removeBtnFormField'
                                                        onClick={() => handleRemoveElement(key, "phone")}>
                                                        <MinusIcon />
                                                    </button>
                                                        : ""}
                                                </div>

                                            </div>
                                        )
                                    }) : ""}
                                    {validation?.contacts ? <div className='text-danger'>{validation?.contacts}</div> : ""}
                                    <div className='com-addFormItem-box ' onClick={() => addEventList()}>
                                        <div className='com-addFormItem-box-inner'>
                                            <span className='d-flex'><PlusIcon width={16} height={16} color={'#1CACAC'} /></span>
                                            {t("Add Phone")}
                                        </div>
                                    </div>
                                </div>
                                <div className='addAdressBox-formBody-nameBox mb-4'>
                                    <div className='com-formInputBox'>
                                        <label className=''>{t("Email")} <span className='com-starShow'>*</span></label>
                                        <div className='form-phoneAddContainer'>
                                            {alladdressdata?.emails?.length ? alladdressdata?.emails.map((result, key) => {
                                                return (
                                                    <div className='formBox-withMinusBtn border-0' key={key}>
                                                        <input className={validation?.emails ? 'form-control border-danger' : "form-control"}
                                                            value={result}
                                                            onChange={(e) => handleSelectChange(key, "emails", e.target.value)} />
                                                        {key > 0 ?
                                                            <button className='formBox-withMinusBtn-removebtn'
                                                                onClick={() => handleRemoveElement(key, "emails")}><MinusIcon /></button>
                                                            : ""}
                                                    </div>
                                                )
                                            }) : ""}
                                            {validation?.emails ? <div className='text-danger'>{validation?.emails}</div> : ""}
                                            <div className='com-addFormItem-box'>
                                                <div className='com-addFormItem-box-inner' onClick={() => addEventList("emails")}>
                                                    <span className='d-flex'><PlusIcon width={16} height={16} color={'#1CACAC'} /></span>
                                                    {t("Add Email")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='com-formInputBox'>
                                <div className="addAdressBox-formBody-radioSwitchBox">
                                    <p className="addAdressBox-formBody-radioSwitchBox-textShow">{t("Default Shipping Address")}</p>
                                    <div className="com-customToggleCheck">
                                        <input type="checkbox" id="togglebtn1" className="com-customToggleCheck-toogleInput"
                                            checked={alladdressdata?.is_default ? true : false}
                                            onChange={(e) => setalldata("is_default", e.target.checked ? 1 : 0)}
                                        />
                                        <label htmlFor="togglebtn1" className="com-customToggleCheck-toggleLabel"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='com-formSaveBtnbox responsiceBtnshow'>
                            <Link to="/account/address"
                                className='text-decoration-none'>
                                <div className='com-formSaveBtnbox-cancelBtn responsiceBtnshow-cancelResp text-center justify-content-center '>{t("Cancel")}</div>
                            </Link>
                            <div className='com-formSaveBtnbox-saveBtn responsiceBtnshow-saveResp text-center' onClick={() => { state?.id ? editaddress() : addaddress() }}>{t("Save")}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccountShippingAdd