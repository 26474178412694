import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MasterItem from "../admin/master/item-master/Item"
import MasterSize from "../admin/master/item-master/Size"
import MasterCollection from "../admin/master/item-master/Collection"
import MasterStyle from "../admin/master/item-master/Style"
import MasterEcommerceCat from "../admin/master/item-master/EcommerceCat"
import MetalMasterMetal from "../admin/master/metal-master/Metal"
import MetalMasterColor from "../admin/master/metal-master/Color"
import StoneMasterStoneGroup from "../admin/master/stone-master/StoneGroup"
import StoneMasterStone from "../admin/master/stone-master/Stone"
import StoneMasterColor from "../admin/master/stone-master/Color"
import StoneMasterSize from "../admin/master/stone-master/Size"
import StoneMasterShape from "../admin/master/stone-master/Shape"
import StoneMasterCut from "../admin/master/stone-master/Cut"
import StoneMasterClarity from "../admin/master/stone-master/Clarity"
import StoneSettingType from "../admin/master/stone-master/SettingType"
import MasterServiceLabour from "../admin/master/ServiceLabour"
import MasterCurrency from "../admin/master/Currency"
import MasterVoucherType from "../admin/master/VoucherType"
import MasterSalesPerson from "../admin/master/SalesPerson"
import MasterVendor from "../admin/master/Vendor"
export default function MasterRoutes(toploadingbarStart) {
    return (
        <Routes>
            <Route exact path='itemmaster/item' element={<MasterItem toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='itemmaster/size' element={<MasterSize toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='itemmaster/collection' element={<MasterCollection toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='itemmaster/style' element={<MasterStyle toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='itemmaster/ecommerce-cat' element={<MasterEcommerceCat toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='metalmaster/metal' element={<MetalMasterMetal toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='metalmaster/color' element={<MetalMasterColor toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/stonegroup' element={<StoneMasterStoneGroup toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/stone' element={<StoneMasterStone toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/color' element={<StoneMasterColor toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/size' element={<StoneMasterSize toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/shape' element={<StoneMasterShape toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/cut' element={<StoneMasterCut toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/clarity' element={<StoneMasterClarity toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='stonemaster/settingtype' element={<StoneSettingType toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='servicelabour' element={<MasterServiceLabour toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='currency' element={<MasterCurrency toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='vouchertype' element={<MasterVoucherType toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='salesperson' element={<MasterSalesPerson toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='vendor' element={<MasterVendor toploadingbarStart={toploadingbarStart} />} > </Route>
        </Routes>
    );
}