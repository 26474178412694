import React, { useState, useEffect } from 'react';
import { _Api } from '../api/_call'
import TableColumnModal from '../common/modules/tableColumn'
import { Tablehead } from '../common/modules/Tablehead'
import DateTime from '../common/DateTime'
import { inventoryskumovementlisthead } from '../common/Tablehead'
import { SetdataTable, UnSetdataTable } from '../api/setdatatable'
import 'datatables.net-fixedcolumns'
import { DateRange } from '../common/helpers/daterange'
import SocialModal from '../common/modules/SocialModal/SocialModal'
import { loading, Updateloading } from '../common/helpers/toastify'
import ProductImageSliderModal from '../common/modules/productImageslider'
import Filtermodal from "../common/modules/Filtermodal";
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Pagination } from '../common/Pagination'
import Select from "react-select";
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { useSelector } from 'react-redux';
import TableLoader from '../../admin/common/TableLoader';
const SkuMovement = (props) => {
  const { t } = useTranslation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [filtername, setFiltername] = useState('')
  const [SelectAllStatus, setSelectAllStatus] = useState()
  const [datefilter, setDatefilter] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(inventoryskumovementlisthead)
  const [skumovementlist, setskumovementlist] = useState([])
  const [skumovementcounts, setskumovementcounts] = useState()
  const [shareloader, setShareloader] = useState(false)
  const [socialurl, setSocialurl] = useState()
  const [imageid, setImageid] = useState()
  const [limit, setLimit] = useState("100")
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const [selecteditem, setSelecteditem] = useState([]);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [datalodaer, setDatsLodaer] = useState(false)
  const [filterdata, setFilterdata] = useState()
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]


  const filterbyname = (search) => {
    getskumovement(currentPage, limit, filterdata, search)
    setstate({ ...state, currentPage: 1 })
  }
  const getlimit = (limit) => {
    setLimit(limit)
    getskumovement(1, limit)
    setstate({ ...state, currentPage: 1 })
  }
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  })
  const { totalPages, currentPage } = state
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current })
    getskumovement(current, '')
  }
  const getskumovement = async (current, firstlimit, filter, search) => {
    setFilterdata(filter)
    setDatsLodaer(true)
    try {
      const postdata = {
        type: 'SKU',
        metal: filter?.metal ? filter?.metal : [],
        location: filter?.location ? filter?.location : [],
        collection: filter?.collection ? filter?.collection : [],
        item: filter?.item ? filter?.item : [],
        search: search ? search : "",
        size: filter?.size ? filter?.size : [],
        stone: filter?.stone ? filter?.stone : [],
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : "",
        limit: firstlimit ? firstlimit : limit,
        skip: current ? (current - 1) * limit : 0,
      }
      UnSetdataTable('sku_movement')
      let resPoinventory = await _Api(
        postdata,
        `api/v1/Inventory/productMovement/SKUMovement`,
      )
      if (resPoinventory?.code === 200) {
        SetdataTable('sku_movement', '4', newcolumndata, [0])
        setskumovementlist(resPoinventory?.data)
        setskumovementcounts(resPoinventory?.count)
        setstate({
          ...state,
          totalPages: Math.ceil(resPoinventory?.total / postdata.limit),
          currentPage: current ? current : 1,
        })
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable('sku_movement', '4', newcolumndata, [0])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_sku_movement',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getskumovement()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    callcolumns()
  }, [])
  const downloadexclsheet = async () => {
    let header_values = []
    let bodyarray = []
    inventoryskumovementlisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (skumovementlist.length) {

      skumovementlist.forEach((result, key) => {
        bodyarray.push([
          key + 1,
          result?.main_image,
          result?.SKU,
          result?.metal_name,
          result?.stone_name,
          result?.size_name,
          result?.item_name,
          result?.collection_name,
          result?.locations,
          result?.aging,
          result?.inward_Qty,
          result?.outward_Qty,
          result?.closing_Qty,
          result?.closing_amount,

        ])
      })
    }
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(bodyarray, {
      origin: 'A2',
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Records')
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([fileContent], { type: fileType })
    FileSaver.saveAs(data, 'SKU_MOVEMENT')
  }
  const getsharerstockid = async () => {
    setShareloader(true)
    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        product_ids: selecteditem ? selecteditem : [],
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/reports/print/productStockCard`,
      )

      if (res.code === 200) {
        setSocialurl(res?.link)
        setShareloader(false)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getprintstockid = async () => {
    let GetloadingID = loading()

    try {
      const postdata = {
        search: filtername,
        start_date: datefilter ? datefilter[0] : '',
        end_date: datefilter ? datefilter[1] : '',
        product_ids: selecteditem ? selecteditem : [],
      }
      let res = await _Api(
        postdata,
        `api/v1/Inventory/reports/print/productStockCard`,
      )

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
        setSocialurl(res?.link)
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const selectedproduct = (event) => {
    if (event.target.checked) {
      const selected = document.querySelectorAll(
        '.inventoryskumovementtablecheck',
      )
      const mainselected = []
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id')
          mainselected.push(product_id)
        }
      }
      setSelecteditem(mainselected)
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value
      })
      setSelecteditem(result)
    }
  }
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(
        '.inventoryskumovementtablecheck',
      )
      let arrya = []
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id')
          arrya.push(product_id)
        }
      }
      setSelecteditem(arrya)
    } else {
      setSelecteditem([])
    }
  }, [SelectAllStatus])
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h2 className="main-body-heading">
                <span>
                  {t('Product Analysis')} {'>'}
                </span>
                {t('Sku Analysis')}
              </h2>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/free-stock.png'}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Stock')}</span>
                      <h2 className="inventory-value stock">
                        {skumovementcounts?.stocks}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/sku.png'}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <p className="inventory-name">{t('SKU')}</p>
                      <h2 className="inventory-value sku">
                        {skumovementcounts?.SKU}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + 'images/icons/location.png'}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Total Value')}</span>
                      <h2 className="inventory-value location">
                        {skumovementcounts?.amount}
                      </h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={
                          base_assets + 'images/icons/loacation_on_green.png'
                        }
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t('Locations')}</span>
                      <h2 className="inventory-value location">
                        {skumovementcounts?.location}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <button
                    className="main-body-top-status-bar-filter-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#filterinventory"
                  >
                    <img
                      className="d-block"
                      src={base_assets + '/images/icons/search-outline.png'}
                      alt=""
                    />
                  </button>
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('PO No./Supplier/Ship to ...')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className={
                          parmissiondatashow?.sku_analysis?.Print
                            ? 'dropdown-item print-dropdown-item'
                            : 'd-none'
                        }
                        onClick={(e) => {
                          getprintstockid()
                        }}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => downloadexclsheet()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#socialModal"
                        onClick={() => getsharerstockid()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/share-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('share')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <table
                  id="sku_movement"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >

                  <Tablehead
                    tablehead={inventoryskumovementlisthead}
                    tblName={'tbl_sku_movement'}
                    setfilterby={""}
                    setShowoption={setSelectAllStatus}
                  />
                  <tbody>
                    {skumovementlist
                      ? skumovementlist.map((result, key) => {
                        return (
                          <tr key={key} className="">
                            <td className="">
                              <div className="com-check">
                                <input
                                  id={result?.id}
                                  data-id={result?.id}
                                  data-locationid={result?.location_id}
                                  className="inventoryskumovementtablecheck skumovementHead"
                                  onChange={selectedproduct}
                                  type="checkbox"
                                />
                                <label
                                  htmlFor={result?.id}
                                  className="com-check-label"
                                />
                              </div>
                            </td>
                            <td className="fixed-side">{key + 1}</td>
                            <td
                              className="table-pro-img fixed-side"
                              onClick={() => setImageid(result?.product_id)}
                              data-bs-toggle="modal"
                              data-bs-target="#ProductViewModal"
                            >
                              <img
                                src={
                                  result?.main_image
                                    ? result?.main_image
                                    : base_assets +
                                    'images/icon/camera_profile.png'
                                }
                                alt=""
                              />
                            </td>
                            <td
                              className="fixed-side link-color cursor-pointer"
                            >
                              <Link
                                to="/app/inventory/skuMovementdetails"
                                className="text-decoration-none"
                                state={{
                                  SKU: result?.SKU,
                                }}
                              >
                                {result?.SKU ? result?.SKU : ''}
                              </Link>
                            </td>
                            <td className="fixed-side">
                              {result?.metal_name ? result?.metal_name : ''}
                            </td>
                            <td className="fixed-side">
                              {result?.stone_name ? result?.stone_name : '-'}
                            </td>
                            <td className="fixed-side">
                              {result?.size_name ? result?.size_name : ''}
                            </td>
                            <td className="fixed-side">
                              {result?.item_name ? result?.item_name : ''}
                            </td>
                            <td className="fixed-side">
                              {result?.collection_name
                                ? result?.collection_name
                                : ''}
                            </td>
                            <td className="location-td fixed-side">
                              <span className="link-color">
                                {result?.locations ? result?.locations : ''}
                              </span>
                            </td>
                            <td className="fixed-side">
                              {result?.aging ? result?.aging : '0'}
                            </td>
                            <td className="fixed-side">
                              {result?.inward_Qty ? result?.inward_Qty : '-'}
                            </td>
                            <td className="fixed-side">
                              {result?.outward_Qty
                                ? result?.outward_Qty
                                : '-'}
                            </td>
                            <td className="fixed-side">
                              {result?.closing_Qty
                                ? result?.closing_Qty
                                : '-'}
                            </td>
                            <td className="fixed-side">
                              {result?.closing_amount
                                ? result?.closing_amount
                                : '-'}
                            </td>
                          </tr>
                        )
                      })
                      : ''}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {datalodaer && <TableLoader />}
              </div>
            </div>
          </div>
        </div>
        {skumovementlist.length > 0 ? (
          <Pagination
            total={totalPages}
            current={currentPage}
            pagination={(crPage) => handlePaginations(crPage)}
          />
        ) : (
          []
        )}
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <ProductImageSliderModal main_id={imageid}></ProductImageSliderModal>
        <Filtermodal submitfiltter={getskumovement} limit={limit} custom_filter_enable={false} />
        <TableColumnModal
          tablehead={inventoryskumovementlisthead}
          tblName={'tbl_sku_movement'}
          setnewcolumndata={setnewcolumndata}
          calldatatable={getskumovement}
        ></TableColumnModal>
      </div>
    </React.Fragment>
  )
}
export default SkuMovement
