import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const NavBar = (props) => {
  const {activeHeader} = props
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const dropdownRef = useRef(null);
  const [activeTab, setActiveTab] = useState(activeHeader)
  const [isActive, setIsActive] = useState(false);
  const { pathname } = useLocation();
  const parts = pathname.split("/");
  let parentPath = parts.length === 3 ? parts[parts.length - 1] : parts[parts.length - 2];
  useEffect(() => {
    if (activeHeader === 'inventory' || activeHeader === 'transactionPurchase' || activeHeader === 'stock_transfer' || activeHeader === 'transactionStockReceive' || activeHeader === 'createTransferInventory') setActiveTab('transaction_tab');
    if (activeHeader === 'summary' || activeHeader === 'myInventory' || activeHeader === 'reserveList' || activeHeader === 'skumovement' || activeHeader === 'stockidmovement' || activeHeader === 'itemsearch' || activeHeader === 'lowStockPlanning' || activeHeader === 'zone') setActiveTab((prev) => 'dropdownMenuinventory')
    if (activeHeader === 'diamondSearch') setActiveTab("diamondSearch_tab")

    if (activeHeader === 'stocktake') {
      if (parentPath === "report") {

        setActiveTab("Report")
      } else {
        setActiveTab("Stocktake_tab")
      }
    }
    if (activeHeader === 'reserve') setActiveTab("reserve_tab")
    if (activeHeader === 'users' || activeHeader === undefined || activeHeader === 'adduser' || (parentPath === 'users'&& activeHeader==='inventory') || activeHeader === 'UserLogs' ) setActiveTab("Users")
    if (activeHeader === 'roles') setActiveTab("Roles")
    if (activeHeader === 'OperationLogReport' || activeHeader === 'UserLogReport' || activeHeader === 'report ') setActiveTab("Report")
    // eslint-disable-next-line
  }, [activeHeader])


  const { t } = useTranslation();
  const { nav } = props;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const handleItemClick = (tab) => {
    setIsActive(false);
    setActiveTab(tab);
  };
  return (
    <nav className="main-header-navs">
      <ul className="main-header-navs-ul">
        {nav === "inventory" ? (
          <>
            <li
                className={parmissiondatashow?.purchase_order?.View ||
                parmissiondatashow?.inventory_transaction_purchase?.View ||
                parmissiondatashow?.inventory_transaction_stock_transfer?.View ||
                parmissiondatashow?.inventory_transaction_stock_Receive?.View
                ? "main-header-navs-ul-li" : "d-none"}
                onClick={() => setActiveTab("transaction_tab")}
            >
              <Link
                id="transaction_tab"
                className={activeTab === 'transaction_tab' || activeTab === 'inventory' || activeTab === 'transactionPurchase' || activeTab === 'stock_transfer' || activeTab === 'transactionStockReceive' ? "dataActive active" : "dataActive"}
                to="/app/inventory"
              >
                <img
                  src={
                    base_assets + "images/header-images/transaction-icon.svg"
                  } alt=""
                />
                {t("Transaction")}
              </Link>
            </li>
            <li className="main-header-navs-ul-li" ref={dropdownRef}>
              <div className="dropdown">
                <Link
                  to="#"
                  className={`dropdown-toggle ${ activeTab === 'dropdownMenuinventory' ? "dataActive active" : "dataActive"}`}
                  onClick={toggleDropdown}
                  type="button"
                  id="dropdownMenuinventory"
                  data-bs-toggle="dropdown"
                  aria-expanded={isActive ? "true" : "false"}
                >
                  <img src={base_assets + "images/header-images/inventory-icon.svg"} alt="" />
                  {t("Inventory")}
                </Link>
                <ul className={`dropdown-menu ${isActive ? "show" : ""}`}>
                  <li onClick={() => handleItemClick("dropdownMenuinventory")} className="dropdown-toggle">
                    <Link className={parmissiondatashow?.Summary?.View ? "dropdown-item" : "d-none"} to="/app/inventory/summary">{t("Summary")}</Link>
                  </li>
                  <li onClick={() => handleItemClick("dropdownMenuinventory")}>
                    <Link className={parmissiondatashow?.my_inventory?.View ? "dropdown-item" : "d-none"} to="/app/inventory/myInventory">{t("My Inventory")}</Link>
                  </li>
                  <li onClick={() => handleItemClick("dropdownMenuinventory")}>
                    <Link className={parmissiondatashow?.reserve_list?.View ? "dropdown-item" : "d-none"} to="/app/inventory/reserveList">{t("Reserve List")}</Link>
                  </li>
                  <li className={parmissiondatashow?.sku_analysis?.View || parmissiondatashow?.stock_analysis?.View ? "hover-sub-dropdown" : "d-none"}
                    onClick={() => setIsActive(!isActive)}>
                    <Link className="dropdown-item dropdown-toggle cursor-pointer" aria-expanded="false">
                      {t("Product Movement")}
                      <img className="menu-arrow" src={base_assets + "images/icons/menu-arrow.png"} alt="" />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className={parmissiondatashow?.sku_analysis?.View ? "dropdown-item" : "d-none"}>
                        <Link className="dropdown-item" to="/app/inventory/skumovement">{t("SKU")}</Link>
                      </li>
                      <li className={parmissiondatashow?.stock_analysis?.View ? "dropdown-item" : "d-none"}>
                        <Link className="dropdown-item" to="/app/inventory/stockidmovement">{t("Stock ID")}</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link onClick={() => handleItemClick("dropdownMenuinventory")} to="/app/inventory/itemsearch" className={parmissiondatashow?.item_search?.View ? "dropdown-item" : "d-none"}>{t("Item Search")}</Link>
                  </li>
                  <li>
                    <Link onClick={() => handleItemClick("dropdownMenuinventory")} to="/app/inventory/lowStockPlanning" className={parmissiondatashow?.Low_Stock_Planning?.View ? "dropdown-item" : "d-none"}>{t("Low Stock Planning")}</Link>
                  </li>
                  <li>
                    <Link onClick={() => handleItemClick("zone")} to="/app/inventory/zone">{t("Zone")}</Link>
                  </li>
                </ul>
              </div>
            </li>

            {
              <li
                data-id="diamondSearch_tab"
                onClick={() => setActiveTab("diamondSearch_tab")}
                className={generalsettings?.data?.generalsetup?.productChoice === '1' ?
                  parmissiondatashow?.inventory_diamond_search?.View ? "main-header-navs-ul-li" : "d-none" : "d-none"}
              >
                <Link
                  id="diamondSearch_tab"
                  className={activeTab === 'diamondSearch_tab' ? "dataActive active" : "dataActive"}
                  to="/app/inventory/diamondSearch"
                >
                  <img
                    src={
                      base_assets + "images/header-images/item-search-icon.svg"
                    } alt=""
                  />
                  {t("Diamond Search")}
                </Link>
              </li>
            }
            <li
              className={parmissiondatashow?.stock_take?.View ? "main-header-navs-ul-li" : "d-none"}
              onClick={() => setActiveTab("Stocktake_tab")}
            >
              <Link className={activeTab === 'Stocktake_tab' ? "dataActive active" : "dataActive"} id="Stocktake_tab" to="/app/inventory/stocktake">
                <img
                  src={base_assets + "images/header-images/stock-icon.svg"} alt=""
                />
                {t("Stock take")}
              </Link>
            </li>
            <li
              className={parmissiondatashow?.inventory_reports?.View ? "main-header-navs-ul-li" : "d-none"}
              onClick={() => setActiveTab("reserve_tab")}
            >
              <Link
                className={activeTab === 'reserve_tab' || activeHeader === 'report' ? "dataActive active" : "dataActive"}
                id="reserve_tab"
                to="/app/inventory/report/reserve"
              >
                <img
                  className="report-icon"
                  src={base_assets + "images/header-images/report-icon.svg"} alt=""
                />
                {t("Report")}
              </Link>
            </li>
          </>
        ) : nav === "product" ? (
          <>

          </>
        ) : nav === "POS" ? (
          ""
        ) : nav === "users" ? (
          <>

            <li
              className={parmissiondatashow?.User?.View ? "main-header-navs-ul-li" : "d-none"}
              onClick={() => setActiveTab("Users")}
            >
              <Link
                className={activeTab === 'Users' ? "dataActive active" : "dataActive"}
                id="Users"
                to="/app/users"
              >
                {t("Users")}
              </Link>
            </li>
            <li
              className={parmissiondatashow?.user_roles?.View ? "main-header-navs-ul-li" : "d-none"}
              onClick={() => setActiveTab("Roles")}
            >
              <Link
                className={activeTab === 'Roles' ? "dataActive active" : "dataActive"}
                id="Roles"
                to="/app/users/roles"
              >
                {t("Roles")}
              </Link>
            </li>
            <li className={parmissiondatashow?.user_reports?.View ? "main-header-navs-ul-li" : "d-none"}
              ref={dropdownRef}
            >
              <div className="dropdown"
                onClick={() => { setActiveTab("Report"); setIsActive(!isActive) }}>
                <Link
                  className={activeTab === 'Report' ? "dropdown-toggle dataActive active" : "dropdown-toggle dataActive"}
                  type="button"
                  id="Report"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("Report")}
                </Link>
                <ul className={`dropdown-menu ${isActive ? 'show' : ''}`} >
                  <li className="dropdown-toggle" onClick={() => setActiveTab('Report')}>
                    <Link
                      id="Report"
                      to="/app/users/UserLogReport"
                    >
                      {t("User Log")}
                    </Link>
                  </li>
                  <li className="dropdown-toggle" onClick={() => setActiveTab('Report')}>
                    <Link
                      id="Report"
                      to="/app/users/OperationLogReport"
                    >
                      {t("Operation Log")}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </>
        ) : ""}
      </ul>


    </nav >
  );
};
export default NavBar;
