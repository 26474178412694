import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import CheckoutBlank from '../checkout/checkoutBlank'
import { error } from '../common/helpers/toastify'
import { useTranslation } from 'react-i18next';
import { Tablehead } from '../../components/common/modules/Tablehead'
import { pospartialpaymenthistary_head } from '../../components/common/Tablehead'
import { _Api } from '../common/api/_call'
import $ from 'jquery';
import { PartialHistorysubtable } from "../Custom/subtable/PartialHistorysubtable";
import CheckoutPayment from '../checkout/checkoutPayment'
import DatePicker from "react-datepicker";
import { toFormatPrice } from '../common/helpers/function';
import { store } from '../../source/index'
const PartialPaymentHistory = () => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { t } = useTranslation();
    let reduxdata = store.getState()
    let OrgSettings = reduxdata?.app?.posorganisation_settings
    const [newcolumndata, setnewcolumndata] = useState(pospartialpaymenthistary_head)
    const [reseivedata, setReseivedata] = useState([])
    const [paytype, setpaytype] = useState('ALL')
    const [showcheckout, setShowcheckout] = useState('blank')
    const [excuteTable, setexcuteTable] = useState(false);
    const [TransactionDate, setTransactionDate] = useState(new Date());
    const itemdaata = localStorage.getItem("customerdetails")
    const parsedObject = JSON.parse(itemdaata);
    const selectTransactionDate = (input) => {
        setTransactionDate(input);

    };
    const callcolumns = async () => {
        try {
            const postdata = {
                name: 'report_receivepayment_tbl',
            }
            let columnhead = await _Api(
                postdata,
                `api/v1/Inventory/common/getColumnHeads`,
            )
            if (columnhead.code === 200) {
                setnewcolumndata(columnhead?.data?.data)
            }
        } catch (err) {
            console.log(err, 'err')
        }
    }
    useEffect(() => {
        callcolumns()
    }, [])
    const getreseivepayment = async () => {
        setexcuteTable(false);
        var expand_col = document.querySelector(".table_btn_expand_col");
        if (expand_col) { expand_col.classList.remove("rotate-90"); }
        var expand_col1 = document.querySelector(".table_btn_expand_col1");
        if (expand_col1) { expand_col1.classList.remove("rotate-90"); }
        var expand_col2 = document.querySelector(".table_btn_expand_col2");
        if (expand_col2) { expand_col2.classList.remove("rotate-90"); }

        if (!parsedObject?.id) {
            error(t("Customer is Requried!!"));
        }

        try {
            setTimeout(async function () {
                UnSetdataTable('receivePaymentTable')
                UnSetdataTable('receivePaymentTable_layby')
                UnSetdataTable('receivePaymentTable_Installment')
                let postdata = {
                    "type": paytype,
                    "customer_id": parsedObject?.id,
                    "date": TransactionDate
                }
                let res = await _Api(postdata, `api/v1/POS/customer/getpartialPaymentHistory`);
                if (res?.code === 200) {
                    setReseivedata(res?.data);
                    setexcuteTable(true);
                    SetdataTable('receivePaymentTable', 4, newcolumndata)
                    SetdataTable('receivePaymentTable_layby', 4, newcolumndata)
                    SetdataTable('receivePaymentTable_Installment', 4, newcolumndata)
                } else {
                    SetdataTable('receivePaymentTable', 4, newcolumndata)
                    SetdataTable('receivePaymentTable_layby', 4, newcolumndata)
                    SetdataTable('receivePaymentTable_Installment', 4, newcolumndata)
                }
            }, 100)
        }
        catch (err) {
            console.log(err, 'err')
        }

    }
    useEffect(() => {
        getreseivepayment()
        // eslint-disable-next-line
    }, [newcolumndata, paytype, parsedObject?.id, TransactionDate])
    const setsubtable = async (id, order_type) => {
        var e = document.querySelector("#table-btn_" + id);
        var row = document.querySelector('#common-main-table-wrap-' + id);
        var alllist_data = [...reseivedata];
        var classList_ = e.classList;
        if (classList_.contains("rotate-90")) {
            classList_.remove("rotate-90");
            alllist_data = [...reseivedata];
            alllist_data.filter(item => item.id === id).forEach((result) => {
                result.subTabledata = null
            })
            setReseivedata(alllist_data);

        } else {
            classList_.add("rotate-90");
            alllist_data = [...reseivedata];
            const postdata = {
                "id": id,
                "type": order_type
            };
            let prioritydata = await _Api(postdata, `api/v1/POS/customer/getpartialPaymentTransaction`);
            var html_ = PartialHistorysubtable(prioritydata, id, t);
            alllist_data.filter(item => item.id === id).forEach((result) => {
                result.subTabledata = html_
            })
            setReseivedata(alllist_data);
            var checkRowSub = setInterval(function () {
                var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
                if (rowSub) {
                    $(row).after(rowSub);
                    clearInterval(checkRowSub);
                }
            }, 100);

        }
    }
    const setsubtable1 = async (id, order_type) => {
        var e = document.querySelector("#table-btn1_" + id);
        var row = document.querySelector(`common-main-table-wrap1-${id}`);
        var classList_ = e.classList;
        if (classList_.contains("rotate-90")) {
            classList_.remove("rotate-90");
            let alllist_data = [...reseivedata];
            alllist_data.filter(item => item.id === id).forEach((result) => {
                result.subTabledata = null
            })
            setReseivedata(alllist_data);

        } else {
            classList_.add("rotate-90");
            let alllist_data = [...reseivedata];
            const postdata = {
                "id": id,
                "type": order_type
            };
            let prioritydata = await _Api(postdata, `api/v1/POS/customer/getpartialPaymentTransaction`);
            var html_ = PartialHistorysubtable(prioritydata, id, t);
            alllist_data.filter(item => item.id === id).forEach((result) => {
                result.subTabledata = html_
            })
            setReseivedata(alllist_data);
            var checkRowSub = setInterval(function () {
                var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
                if (rowSub) {
                    $(row).after(rowSub);
                    clearInterval(checkRowSub);
                }
            }, 100);

        }
    }
    const setsubtable2 = async (id, order_type) => {
        var e = document.querySelector("#table-btn2_" + id);
        var row = document.querySelector(`common-main-table-wrap2-${id}`);
        var classList_ = e.classList;
        if (classList_.contains("rotate-90")) {
            classList_.remove("rotate-90");
            let alllist_data = [...reseivedata];
            alllist_data.filter(item => item.id === id).forEach((result) => {
                result.subTabledata = null
            })
            setReseivedata(alllist_data);

        } else {
            classList_.add("rotate-90");
            let alllist_data = [...reseivedata];
            const postdata = {
                "id": id,
                "type": order_type
            };
            let prioritydata = await _Api(postdata, `api/v1/POS/customer/getpartialPaymentTransaction`);
            var html_ = PartialHistorysubtable(prioritydata, id, t);
            alllist_data.filter(item => item.id === id).forEach((result) => {
                result.subTabledata = html_
            })
            setReseivedata(alllist_data);
            var checkRowSub = setInterval(function () {
                var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
                if (rowSub) {
                    $(row).after(rowSub);
                    clearInterval(checkRowSub);
                }
            }, 100);

        }
    }
    return (
        <>

            <div className="main-body">
                <div className="main-body-row">
                    <div className="main-body-row-left-col p-0 quotation">
                        <div className="com-data-area">
                            <div className="catalog-area-header-area detail-area-header">
                                <div className="catalog-area-header-area-control">
                                    <div className=" transactionhistory-heading d-flex gap-3 align-items-center">
                                        <Link to="/app/pos/customer/customer" >
                                            <img className="transactionhistory-heading-backbtn" src={base_assets + "images/pos/backbtn.png"} alt="" /></Link>
                                        <h1 className="main-heading">{t("Partial Payment History")}</h1>
                                    </div>

                                </div>
                            </div>
                            <div className='customer-inner-data-payment-table-top-bar'>
                                <div className='customer-inner-data-payment-table-top-bar-left'>
                                </div>
                                <div className='customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date'>
                                    <DatePicker
                                        className="placeholder-up-input-field"
                                        selected={TransactionDate}
                                        onChange={selectTransactionDate}
                                        name="TransactionDate"
                                        dateFormat={OrgSettings?.date_picker}
                                        autoComplete='none'
                                    />
                                    <img
                                        className="placeholder-up-input-field-calender"
                                        src={base_assets + "images/icons/calendar-green.svg"}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="com-data-area-inner bg-white customer-inner-data overflow-auto">

                                <div className="Dailysalereport-content-area-main quotation-main mt-0">
                                    <ul
                                        className="Dailysalereport-content-area-navbar nav-tabs quotation-main-navbar mt-4"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li
                                            className="nav-item Dailysalereport-content-area-navbar-item quotation-main-navbar-item"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link active Dailysalereport-content-area-navbar-item-link quotation-main-navbar-item-link"
                                                id="Sale-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#Sale"
                                                type="button"
                                                role="tab"
                                                aria-controls="Sale"
                                                aria-selected="true"
                                                onClick={() => setpaytype('ALL')}
                                            >
                                                {t("ALL")}
                                            </button>
                                        </li>
                                        <li
                                            className="nav-item quotation-main-navbar-item"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link quotation-main-navbar-item-link quotation-main-navbar-item-link-custom Dailysalereport-content-area-navbar-item-link"
                                                id="Layby-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#layby"
                                                type="button"
                                                role="tab"
                                                aria-controls="layby"
                                                aria-selected="false"
                                                onClick={() => setpaytype('LayBy')}
                                            >
                                                {t("Layby")}
                                            </button>
                                        </li>
                                        <li
                                            className="nav-item quotation-main-navbar-item"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link quotation-main-navbar-item-link Dailysalereport-content-area-navbar-item-link"
                                                id="Installment-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#Installment"
                                                type="button"
                                                role="tab"
                                                aria-controls="Installment"
                                                aria-selected="false"
                                                onClick={() => setpaytype('Installment')}
                                            >
                                                {t("Installment")}
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="Sale"
                                            role="tabpanel"
                                            aria-labelledby="Sale-tab"
                                        >
                                            <div
                                                className="main-body-main-table-wrap Dailysalereport-table mt-4"
                                                id="table-scroll"
                                            >
                                                <table
                                                    id="receivePaymentTable"
                                                    className="common-table first-row-gray w-100 quotation-table Dailysalereport-table-main"
                                                >
                                                    <Tablehead
                                                        tablehead={pospartialpaymenthistary_head}
                                                        tblname={'report_receivepayment_tbl'}
                                                        setfilterby={""}
                                                    />
                                                    <tbody>
                                                        <>
                                                            {reseivedata?.length ? reseivedata.map((result, key) => {
                                                                return (
                                                                    <>
                                                                        <tr id={`common-main-table-wrap-${result?.id}`} key={result?.id} >
                                                                            <td>
                                                                                <div className="com-check"
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="poscustomertablehead"
                                                                                        id={result?.id}
                                                                                    />
                                                                                    <label htmlFor={result?.id} className="com-check-label"></label>
                                                                                </div>
                                                                            </td>
                                                                            <td>{key + 1}</td>
                                                                            <td> {result?.date ? result?.date : ""}</td>
                                                                            <td>{result?.vr_type ? result?.vr_type : ""}</td>
                                                                            <td className="link-color">{result?.vr_no ? result?.vr_no : ""}</td>

                                                                            <td>{result?.customer ? result?.customer : ""}</td>
                                                                            <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                                                            <td className="sku-col table_btn_expand_col main-green-text"
                                                                                onClick={() => setsubtable(result?.id, result?.order_type)}
                                                                                id={"table-btn_" + result?.id}>
                                                                                <img
                                                                                    className="sku-col-arrow cursor-pointer"
                                                                                    src={
                                                                                        base_assets +
                                                                                        "/images/icons/right_green_button.png"
                                                                                    }
                                                                                    alt=""
                                                                                />{result?.payment ? result?.payment : ""}</td>

                                                                            <td className='danger-text'>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                                                            <td>{result?.location ? result?.location : ""}</td>
                                                                            <td>{result?.due_date ? result?.due_date : ""}</td>

                                                                        </tr>
                                                                        {
                                                                            excuteTable ? (
                                                                                <>
                                                                                    {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade "
                                            id="layby"
                                            role="tabpanel"
                                            aria-labelledby="layby-tab"
                                        >
                                            <div
                                                className="main-body-main-table-wrap Dailysalereport-table mt-4"
                                                id="table-scroll"
                                            >
                                                <table
                                                    id="receivePaymentTable_layby"
                                                    className="common-table first-row-gray w-100 quotation-table Dailysalereport-table-main"
                                                >
                                                    <Tablehead
                                                        tablehead={pospartialpaymenthistary_head}
                                                        tblname={'report_receivepayment_tbl'}
                                                        setfilterby={""}
                                                    />
                                                    <tbody>
                                                        <>
                                                            {reseivedata?.length ? reseivedata.map((result, key) => {
                                                                return (
                                                                    <>
                                                                        <tr id={`common-main-table-wrap1-${result?.id}`} key={result?.id} >
                                                                            <td>
                                                                                <div className="com-check"
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="poscustomertablehead"
                                                                                        id={result?.id}
                                                                                    />
                                                                                    <label htmlFor={result?.id} className="com-check-label"></label>
                                                                                </div>
                                                                            </td>
                                                                            <td>{key + 1}</td>
                                                                            <td> {result?.date ? result?.date : ""}</td>
                                                                            <td>{result?.vr_type ? result?.vr_type : ""}</td>
                                                                            <td className="link-color">{result?.vr_no ? result?.vr_no : ""}</td>

                                                                            <td>{result?.customer ? result?.customer : ""}</td>
                                                                            <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                                                            <td className="main-green-text sku-col table_btn_expand_col1"
                                                                                onClick={() => setsubtable1(result?.id, result?.order_type)}
                                                                                id={"table-btn1_" + result?.id}>
                                                                                <img
                                                                                    className="sku-col-arrow cursor-pointer"
                                                                                    src={
                                                                                        base_assets +
                                                                                        "/images/icons/right_green_button.png"
                                                                                    }
                                                                                    alt=""
                                                                                />{result?.payment ? result?.payment : ""}</td>
                                                                            <td className='danger-text'>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                                                            <td>{result?.location ? result?.location : ""}</td>
                                                                            <td>{result?.due_date ? result?.due_date : ""}</td>
                                                                        </tr>
                                                                        {
                                                                            excuteTable ? (
                                                                                <>
                                                                                    {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade "
                                            id="Installment"
                                            role="tabpanel"
                                            aria-labelledby="Installment-tab"
                                        >
                                            <div
                                                className="main-body-main-table-wrap Dailysalereport-table mt-4"
                                                id="table-scroll"
                                            >
                                                <table
                                                    id="receivePaymentTable_Installment"
                                                    className="common-table first-row-gray w-100 quotation-table Dailysalereport-table-main"
                                                >
                                                    <Tablehead
                                                        tablehead={pospartialpaymenthistary_head}
                                                        tblname={'report_receivepayment_tbl'}
                                                        setfilterby={""}
                                                    />
                                                    <tbody>
                                                        <>
                                                            {reseivedata?.length ? reseivedata.map((result, key) => {
                                                                return (
                                                                    <>
                                                                        <tr id={`common-main-table-wrap2-${result?.id}`} key={result?.id}>
                                                                            <td>
                                                                                <div className="com-check"
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="poscustomertablehead"
                                                                                        id={result?.id}
                                                                                    />
                                                                                    <label htmlFor={result?.id} className="com-check-label"></label>
                                                                                </div>
                                                                            </td>
                                                                            <td>{key + 1}</td>
                                                                            <td> {result?.date ? result?.date : ""}</td>
                                                                            <td>{result?.vr_type ? result?.vr_type : ""}</td>
                                                                            <td className="link-color">{result?.vr_no ? result?.vr_no : ""}</td>
                                                                            <td>{result?.customer ? result?.customer : ""}</td>
                                                                            <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                                                            <td className="main-green-text sku-col table_btn_expand_col2"
                                                                                onClick={() => setsubtable2(result?.id, result?.order_type)}
                                                                                id={"table-btn2_" + result?.id}>
                                                                                <img
                                                                                    className="sku-col-arrow cursor-pointer"
                                                                                    src={
                                                                                        base_assets +
                                                                                        "/images/icons/right_green_button.png"
                                                                                    }
                                                                                    alt=""
                                                                                />{result?.payment ? result?.payment : ""}</td>
                                                                            <td className='danger-text'>{toFormatPrice(result?.outstanding, { addSymbol: true })}</td>
                                                                            <td>{result?.location ? result?.location : ""}</td>
                                                                            <td>{result?.due_date ? result?.due_date : ""}</td>

                                                                        </tr>
                                                                        {
                                                                            excuteTable ? (
                                                                                <>
                                                                                    {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )
                                                            }) : ""}
                                                        </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body-row-right-col">
                        {showcheckout === 'payment' ? (
                            <CheckoutPayment setShowcheckout={setShowcheckout} />
                        ) : (
                            <CheckoutBlank />
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default PartialPaymentHistory;
