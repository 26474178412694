import React, { useState, useEffect } from "react";
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import { _Api } from "../api/_call";
import { Pagination } from "../common/Pagination"
import { error, loading, Updateloading } from "../common/helpers/toastify";
import Confirmationmodel from "../common/modules/confirmationmodel"
import TableLoader from "../common/TableLoader";
import Select from 'react-select'

const ApplicationKeys = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [editItem, setEditItem] = useState(false);
  const [applicationlist, setApplicationlist] = useState([])
  const [filtername, setFiltername] = useState('');
  const [limit, setLimit] = useState(100);
  const [dataid, setDtataid] = useState("")
  const [showloader, setShowloader] = useState(false);
  const [completeDataObject, setCompliteDataObject] = useState(
    { "name": "", "status": "1", }
  )
  const getlimit = (limit) => {
    setLimit(limit);
    getlist(1, limit);
    setstate({ ...state, currentPage: 1 });
  };
  const filterbyname = () => {
    getlist(currentPage);
    setstate({ ...state, currentPage: 1 });
  };
  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });
  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    getlist(current);

  };
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  const getlist = async (current, firstlimit) => {
    setShowloader(true);
    try {
      var skipNumber = current ? (current - 1) * limit : 0;
      let postdata = {
        "search": filtername,
        "limit": firstlimit ? firstlimit : limit,
        "skip": skipNumber,
      }
      let res = await _Api(postdata, `api/v1/App/applicationkey/get-list`)
      if (res?.code === 200) {
        setApplicationlist(res?.data)
        setShowloader(false)
        setstate({
          ...state,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const Addapplication = async () => {
    if (completeDataObject?.status === "0") {
      completeDataObject.status = "0";
    } else {
      completeDataObject.status = "1";
    }
    if (!completeDataObject?.name) {
      error(t("Name field is required"))
      return
    }
    if (!completeDataObject?.status) {
      error(t("Status field is required"))
      return
    }
    let GetloadingID = loading();
    try {
      let postdata = completeDataObject
      let res;
      if (completeDataObject?._id) {
        res = await _Api(postdata, `api/v1/App/applicationkey/edit`)
      } else {
        res = await _Api(postdata, `api/v1/App/applicationkey/add`)
      }
      if (res.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        getlist()
        setEditItem(false)
        setCompliteDataObject({
          "name": "",
          "status": "",
        })
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getlist()
    // eslint-disable-next-line
  }, [])

  const viewapplication = async (id) => {
    try {
      let postdata = {
        id: id
      }
      let res = await _Api(postdata, `api/v1/App/applicationkey/view`)
      if (res?.code === 200) {
        setCompliteDataObject(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const deleteapplication = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        id: dataid
      }
      let res = await _Api(postdata, `api/v1/App/applicationkey/delete`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        getlist()
      } else {
        Updateloading(t, GetloadingID, res.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const copyButtonValue = (text) => {
    const tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">{t("Setup")} <RightArrow /> <span className="">{t("Application Key's")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <Select
              className="limit-select mx-3"
              options={selectOptions}
              value={{ label: limit, value: limit }}
              onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
            />
            <div className="com-search-bar position-relative">
              <img onClick={filterbyname} className="com-search-bar-search-img" src={base_assets + "images/admin/icons/gray-search.png"} alt="" />
              <input placeholder="Search" className="h-100 border-0 bg-transparent"
                onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                onChange={(e) => setFiltername(e.target.value)} />
            </div>
            <div className="print-export-dropdown dropdown">
              <button
                className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/admin/icons/gray-ellipsis.png"} alt="" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown-item print-dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("print")}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export-x-icon.png"}
                      alt=""
                    />
                  </div>
                  <span>{t("export")}</span>
                </li>
                <li className="dropdown-item">
                  <div className="dropdown-item-img">
                    <img
                      src={base_assets + "images/icons/export.png"}
                      alt=""
                    />
                  </div>
                  <span >{t("Import")}</span>
                </li>
              </ul>
            </div>
            <button className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3" onClick={() => {
              setEditItem(true); setCompliteDataObject({
                name: "",
                status: "1"
              })
            }}>
              <img className="me-2" src={base_assets + "images/admin/icons/add-icon-white.png"} alt="" />{t("Create")}
            </button>
          </div>
        </div>
        <div className="row">
          <div className={editItem ? "col-md-6" : "col-md-12"}>
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th>#</th>
                    <th>{t("Application Name")}	</th>
                    <th>{t("Client ID")}	</th>
                    <th>{t("Client Secret")}	</th>
                    <th>{t("Status")}</th>
                    <th>{t("Last Modified Date")}	</th>
                    <th className="text-center">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {applicationlist.length > 0 ? applicationlist.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td><span>{key + 1}</span></td>
                        <td><span>{result?.name ? result?.name : "-"}</span></td>
                        <td><span>{result?.client_id ? result?.client_id : "-"}</span></td>
                        <td className="link-color cursor-pointer" onClick={() => copyButtonValue(result?.client_secret)}> <span>cuppy</span></td>
                        <td><span>{result?.status ? result?.status : "-"}</span></td>
                        <td><span>{result?.updatedAt ? result?.updatedAt : "-"}</span></td>
                        <td className="text-center">
                          <>
                            <img className="cursor-pointer me-4" onClick={() => { setEditItem(true); viewapplication(result?._id) }} src={base_assets + "images/admin/icons/Edit.png"} alt="" />
                            <img className="cursor-pointer" data-bs-toggle="modal"
                              data-bs-target="#createPurchaseModal"
                              onClick={() => setDtataid(result?._id)} src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                          </>
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center">
                        {' '}
                        {t("No Data Available In Table")}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              {showloader && <TableLoader />}
            </div>
            {applicationlist.length > 0 && (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            )}
          </div>
          <div className={editItem ? "col-md-6 d-block" : "d-none"}>
            <div className="edit-item-area bg-white row">
              <div className="col-md-9 px-0">
                <div className="edit-item-area-body">

                  <div className="row">
                    <div className="col-md-12 ps-0">
                      <div className="row mx-0">
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <label className="v3-input-group-label">{t("Application Name")} <sup>*</sup></label>
                            <input type="text" className="v3-input-group-input w-100"
                              value={completeDataObject?.name}
                              onChange={(e) => onChangeValueUpdate("name", e.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <div>{completeDataObject?.client_id ? completeDataObject?.client_id : ""}</div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="v3-input-group">
                            <div>{completeDataObject?.client_secret ? completeDataObject?.client_secret : ""}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 pe-0">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                  <button className="square-btn square-btn-white btn-close focus-none" onClick={() => { setEditItem(false); }}></button>
                  <button className="square-btn" onClick={() => Addapplication()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
                <div className="form-check form-switch ps-0 ms-2">
                  <label className="form-check-label" htmlFor="mixmatch">{t("Status")}</label>
                  <input className="form-check-input float-none ms-2" type="checkbox" id="mixmatch"
                    onChange={(e) => onChangeValueUpdate("status", e.target.checked ? "1" : "0")}
                    checked={completeDataObject?.status === "1" ? true : false}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        <Confirmationmodel statement={"Delete"} handler_submit={deleteapplication} ></Confirmationmodel>
      </div>
    </>
  )
}

export default ApplicationKeys;