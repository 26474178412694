import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DateTime from '../../common/DateTime';
import { _Api } from '../../api/_call';
import { Tablehead } from '../../common/modules/Tablehead';
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable';
import { posordermaintableHead } from '../../common/Tablehead';
import ProductModal from "../../common/modules/ProductModal";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import { DateRange } from "../../common/helpers/daterange";
import { error, loading, success, Updateloading } from "../../common/helpers/toastify";
import Select from "react-select";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { customOrder_status } from '../../common/helpers/status_class';
const POSOrdermain = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState();
  const [posorderlist, setPosorderlist] = useState();
  const [newcolumndata, setnewcolumndata] = useState(posordermaintableHead);
  const [createlist, setCreatelist] = useState([]);
  const [selectedlocation, setSelectedlocation] = useState();
  const [datefilter, setDatefilter] = useState([]);
  const [getoneproduct, setOneproduct] = useState();
  const [statement, setStatement] = useState("");
  const [changeStatusId, setChangeStatusId] = useState({})
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [totalqty, setTotalqty] = useState()
  const [datalodaer, setDatsLodaer] = useState(false)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const filterbyname = (search) => {
    GetPOsorder(limit, search);
  }

  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color
      };
    }
  };

  const handleChange = async() =>{
    try{
      let postdata = {
       cart_id : changeStatusId.cart_id,
       cart_status : changeStatusId.cart_status === 1 || changeStatusId.cart_status === 0 ? 9 : 1,
       pos_id : changeStatusId.pos_id
      }
      console.log(postdata, "postdata")
      let res = await _Api(postdata, 
        `api/v1/Inventory/po/edit-pos-status`
      )
      if (res.code === 200) {
        success(res.message);
        GetPOsorder();
      }
    }catch(err){
      error(err)
      console.log(err, "error")
    }
  }

  const GetPOsorder = useCallback(async (firstlimit, search) => {
    setDatsLodaer(true)
    UnSetdataTable("pos_order_table_main");
    try {
      const postdata = {
        "search": search ? search : "",
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
      };
      let resPosorder = await _Api(postdata, `api/v1/Inventory/po/getPOSList`);
      if (resPosorder?.code === 200) {
        setDatsLodaer(false)
        SetdataTable('pos_order_table_main', 6, newcolumndata, 0);
        setPosorderlist(resPosorder?.data);
        setTotalqty(resPosorder?.totalQty)
      } else {
        setDatsLodaer(false)
        SetdataTable('pos_order_table_main', 6, newcolumndata, 0);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }, [limit, datefilter, newcolumndata]);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_posorderlist_main',
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const submitlist = (event, e) => {
    const array = [...createlist];
    const mainarray = [...posorderlist];
    if (event.target.checked) {
      const currentid = mainarray.filter(all => {
        return all.cart_id === event.target.value;
      });
      if (!selectedlocation) {
        setSelectedlocation(currentid[0]?.location_id);
      }
      mainarray.forEach((result, key) => {
        if (result?.location_id !== currentid[0]?.location_id) {
          mainarray[key] = {
            ...mainarray[key],
            'rowclass': 'row-inactive'
          }
        } else {
          mainarray[key] = {
            ...mainarray[key],
            'rowclass': ''
          }
        }
      })
      setPosorderlist(mainarray);
      array.push(event.target.value);
      setCreatelist(array);
    } else {
      if (array.length === 1) {
        setCreatelist((current) =>
          current.filter((item) => item !== event.target.value)
        );
        mainarray.forEach((result, key) => {
          mainarray[key] = {
            ...mainarray[key],
            'rowclass': ''
          }
          setPosorderlist(mainarray);
        })
      } else {
        setCreatelist((current) =>
          current.filter((item) => item !== event.target.value)
        );
      }
    }
  }

  useEffect(() => {
    callcolumns();
  }, []);
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      GetPOsorder();
    }
  }, [datefilter, GetPOsorder]);
  const downloadexclsheet = async () => {

    let GetloadingID = loading();
    try {
      let postdata = {
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : "",
      }
      let res = await _Api(postdata, "api/v1/Inventory/po/getPOSOrderListExport")
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }

    }
    catch (err) {
      console.log(err, "err")
    }

  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const changeStatus = (cartStatus, cartId , posId) => {
    console.log(cartStatus, cartId , posId, "change status")
    setStatement("Change Status");
    setChangeStatusId({cart_status: cartStatus, cart_id: cartId, pos_id : posId})
    // setPo_sataus([{ index: index, status: value, po_order_id: po_order_id }]);
    document.querySelector("#status_query_select").click();
  };
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className='page-back-heading'>
                  <div className='page-back-btn'>
                    <span onClick={() => navigate(-1)} className="page-back-btn">
                      <img
                        src={base_assets + "images/icons/arrow-left.png"}
                        alt=""
                      />
                    </span>
                    <div
                      className="main-body-top-tab-bar-left-col nav nav-pills"
                      role="tablist"
                    >
                      <span
                        onClick={() => navigate(-1)}
                        className="main-body-top-tab-bar-left-col-a nav-link active"
                      >
                        <span className="main-body-top-tab-bar-left-col-a-text">
                          {t("POS Order")}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={parmissiondatashow?.purchase_order?.Add ? "main-body-top-tab-bar-right-col" : "d-none"}>
                  <Link
                    to={createlist.length ? "/app/inventory/createpo" : '/app/inventory/posorderlist'}
                    state={{
                      createlist: createlist,
                      hidelocationid: selectedlocation,
                    }}
                    className="main-body-top-tab-bar-right-col-a">
                    <img className="link-img" src={base_assets + "images/icons/plus-circle-white.png"} alt="" />
                    <span className="main-body-top-tab-bar-right-col-a-text">{t("Create PO")}</span>
                  </Link>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); GetPOsorder(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("TF No./StockID/SKU/Metal/Stone")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table id="pos_order_table_main" className="common-table product-base-table table-striped w-100">

                    <Tablehead tablehead={posordermaintableHead} tblName={"tbl_posorderlist_main"} setfilterby={""} />
                    <tbody>
                      <>
                        {posorderlist ? posorderlist.map((result, key) => {
                           var po_status_class_val = customOrder_status(
                            result?.cart_status
                          );
                          var optionData = (result.cart_status === 1 || result.cart_status === 0) ? [
                            {label: "Cancel Order", value: "Cancel Order", color:"#df5454"}
                           ] : [
                            {label: "Open Order", value: "Open Order", color:"#F9BC44"}];
                          var valueofstatus = [];
                          valueofstatus = result?.cart_status === 1 || result?.cart_status === 0? {label: "Open Order", value: "Open Order", color:"#F9BC44"} : {label: "Cancel Order", value: "Cancel Order", color:"#df5454"}                         
                          return (
                            <tr id={`common-main-table-wrap-${result?.cart_id}`} className={result?.rowclass ? result?.rowclass : ''} key={key}>
                              <td>
                                <div className='com-check'>
                                  <input id={"posoder_" + result.cart_id}
                                    data-id={result?.cart_id}
                                    value={result?.cart_id}
                                    onClick={submitlist} className='posordertableHeads' type="checkbox" />
                                  <label htmlFor={"posoder_" + result.cart_id} className="com-check-label" />
                                </div>
                              </td>
                              <td>{key + 1}</td>
                              <td>
                                <div className="table-pro-img">
                                  <img
                                    src={result?.main_image ? result?.main_image : ''}
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td className="col-highlight link-color cursor-pointer" onClick={() => setOneproduct(result)} data-bs-toggle="modal" data-bs-target="#ProductInfoModal">
                                {result?.SKU ? result?.SKU : ""}
                              </td>
                              <td>{result?.date
                                ? result?.date
                                : ''}</td>
                              <td className="po-no sku-col cursor-pointer">
                                {result?.pos_no ? result?.pos_no : ""}
                                {result?.allocatable === true ? <img
                                  className="link-img"
                                  src={base_assets + "images/icons/link-icon.svg"}
                                  alt=""
                                /> : ""}
                              </td>
                              <td className="col-highlight">{result?.stock_id ? result?.stock_id : ""}</td>
                              <td className="col-highlight">{result?.metal_name ? result?.metal_name : ""}</td>
                              <td className="col-highlight">{result?.stone_name ? result?.stone_name : ""}</td>
                              <td className="col-highlight">{result?.size_name ? result?.size_name : ""}</td>
                              <td className="col-highlight">{result?.qty ? result?.qty : ""}</td>
                              <td>
                              <div
                                    className={
                                      "dropdown status-dropdown status-dropdown-select poslist status-tab " +
                                      po_status_class_val
                                    }
                                  >
                                    <Select
                                      options={optionData ? optionData : []}
                                      value={valueofstatus}
                                      // isDisabled={result.status.pu_status === 0 ? true : false}
                                      onChange={(event) =>
                                        changeStatus(result.cart_status, result.cart_id , result.pos_id)
                                      }
                                      className=''
                                      classNamePrefix="status-dropdown-select"
                                      styles={colorStyles}
                                      getOptionLabel={(e) => (
                                        <div className="">
                                          {e.label}
                                        </div>
                                      )}
                                      isSearchable={false}
                                    />
                                  </div>
                                {/* {result?.cart_status === 0 ?
                                  <label className="status-tab gray-tab">
                                    {t("Close Order")}
                                  </label>
                                  : <label className="status-tab gray-tab">
                                    {t("Open Order")}
                                  </label>
                                } */}
                              </td>
                              <td>
                                {result?.priority === 0 ?
                                  <label className="status-tab low-priority">{t("Low")}</label>
                                  : result?.priority === 1 ?
                                    <label className="status-tab medium-priority">{t("Medium")} </label>
                                    : result?.priority === 2 ?
                                      <label className="status-tab high-priority">{t("High")} </label>
                                      : ''
                                }
                              </td>
                              <td className="danger-text" data-location={result?.shipTo ? result?.shipTo : ""}>  {result?.shipTo ? result?.shipTo : ""}</td>
                              <td className="danger-text">{result?.customer ? result?.customer : ""}</td>
                              <td className="danger-text">{result?.delivery_date ? result?.delivery_date : ""}</td>
                              <td className="danger-text">{result?.due_days}</td>
                              <td>{result?.remark ? result?.remark : ""}</td>
                              <td className="text-center">
                                {result?.printURL === "" ? "-"
                                  : <div onClick={() => {
                                    if (result?.printURL) {
                                      window.open(result?.printURL, '_blank', 'noreferrer')
                                    }
                                  }}><img
                                      className="pdf-image"
                                      src={base_assets + "images/icons/pdf-icon.png"}
                                      alt=""
                                    /></div>
                                }
                              </td>
                            </tr>
                          );
                        }) : ""}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{totalqty ? totalqty : 0}</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit={''} />

      </div>
      <TableColumnModal tablehead={posordermaintableHead} tblName={"tbl_posorderlist_main"}
        calldatatable={GetPOsorder}
        setnewcolumndata={setnewcolumndata}></TableColumnModal>
       <ConfirmationModel
          statement={statement}
          handler_submit={statement === 'Change Status' ? handleChange : null}
        ></ConfirmationModel>
        <button
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#createPurchaseModal"
          id="status_query_select"
        ></button>
    </React.Fragment>
  );
}
export default POSOrdermain;

