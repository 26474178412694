import { _Apiauth } from "./api/_call";
export const isCurrentDomain = async (domainToCheck) => {
  const currentDomain = window.location.hostname;
  console.log(currentDomain, "<<<<>>>>>>>>", domainToCheck);
  return currentDomain === domainToCheck;
};
export const setUserToken = (token) => {
  localStorage.setItem("UserToken", token);
  let myDoman = window.location.hostname;
  localStorage.setItem("UserDomain", myDoman);
};

export const getUserToken = async () => {
  if (localStorage.getItem("UserToken")) {
    let res = await _Apiauth(
      { authToken: localStorage.getItem("UserToken") },
      `authentication`
    );
    if (res?.code === 200) {
      return localStorage.getItem("UserToken");
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isAuthenticated = async () => {
  const userToken = await getUserToken();
  return !!userToken;
};

export const logout = () => {
  localStorage.removeItem("UserToken");
  localStorage.removeItem('updateModulePermission');
  localStorage.removeItem('updateUserPermission');
};

export const authMiddleware = () => {
  return (props) => {
    if (!isAuthenticated()) {
      return null;
    }
    return true;
  };
};
