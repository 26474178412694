import React, { useState, useEffect } from "react";
import { _Api } from "../api/_call";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../../source/app/appSlice";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CustomerSearchDetailModal from './CustomerSearchDetailModal';

const CustomerSearchModal = (props) => {
  const { setCusid } = props
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.app.customer);
  const [searchvalue, setSearchvalue] = useState();
  const [searchlist, setSearchlist] = useState("");
  const [show, setShow] = useState(false);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [clearcustomer, setClearcustomer] = useState(false)
  const itemdaata = localStorage.getItem("customerdetails")
  const [newcustomersid, setnewcustomersid] = useState()
  const location = useLocation()
  const parts = location.pathname.split('/');
  const parsedObject = JSON.parse(itemdaata);
  const callsearchlist = async (event) => {
    try {
      const postdata = {
        search: event.target.value,
      };
      let resSearchlist = await _Api(postdata, `api/v1/App/customer/find`);
      if (resSearchlist.code === 200) {
        setSearchlist(resSearchlist.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getsearchvalue = (event, valuedata) => {
    var value = event.target.getAttribute("data-search");
    var valueid = event.target.getAttribute("data-id");
    setSearchvalue(value);
    setShow(false);
    localStorage.removeItem("addcustomerdtaa")
    addresslist(valueid, valuedata, value)
    let customerdata = { id: valueid, name: value, existsInAddress: valuedata }
    let jsondata = JSON.stringify(customerdata);
    localStorage.setItem('customerdetails', jsondata);
    dispatch(updateCustomer({ id: valueid, name: value, existsInAddress: valuedata }));
  };
  const addresslist = async (id, valuedata, name) => {
    try {
      let postdata = {
        "customer_id": id
      }
      let res = await _Api(postdata, `api/v1/Customer/address/list`)
      if (res?.code === 200) {
        if (valuedata) {
          const defaultshipAddress = res?.data?.shipping_address?.length ? res?.data?.shipping_address.find(address => address.is_default === 1) : [];
          const defaultbillAddress = res?.data?.billing_address?.length ? res?.data?.billing_address.find(address => address.is_default === 1) : [];
          let lastobj = { billing_address: defaultbillAddress, shipping_address: defaultshipAddress }
          let data = JSON.stringify(lastobj);
          localStorage.setItem('addcustomerdtaa', data);
        } else {
          if (res?.data?.shipping_address?.length && res?.data?.billing_address?.length) {
            const defaultshipAddress = res?.data?.shipping_address?.length ? res?.data?.shipping_address.find(address => address.is_default === 1) : [];
            const defaultbillAddress = res?.data?.billing_address?.length ? res?.data?.billing_address.find(address => address.is_default === 1) : [];
            let lastobj = { billing_address: defaultbillAddress, shipping_address: defaultshipAddress }
            let data = JSON.stringify(lastobj);
            localStorage.setItem('addcustomerdtaa', data);
            let customerdata = { id: id, name: newcustomersid?.name, existsInAddress: true }
            let jsondata = JSON.stringify(customerdata);
            localStorage.setItem('customerdetails', jsondata);
            dispatch(
              updateCustomer(customerdata)
            );
          } else {
            let customerdata = { id: id, name: name, existsInAddress: false }
            let jsondata = JSON.stringify(customerdata);
            localStorage.setItem('customerdetails', jsondata);
            dispatch(
              updateCustomer(customerdata)
            );
            let data = JSON.stringify(res?.data);
            localStorage.setItem('addcustomerdtaa', data);
          }
        }
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (newcustomersid?.id) {
      setSearchvalue(newcustomersid?.name);
      addresslist(newcustomersid?.id, false, newcustomersid?.name)
    }
    // eslint-disable-next-line
  }, [newcustomersid]);
  useEffect(() => {
    if (clearcustomer) {
      localStorage.removeItem("addcustomerdtaa")
      let customerdata = { id: "", name: "", existsInAddress: "" }
      let jsondata = JSON.stringify(customerdata);
      localStorage.setItem('customerdetails', jsondata);
      dispatch(
        updateCustomer(customerdata)
      );
      setClearcustomer(false)
    } else {
      setSearchvalue(customer?.name);
    }
    // eslint-disable-next-line
  }, [customer?.id, clearcustomer]);
  useEffect(() => {
    if (parsedObject?.id) {
      dispatch(
        updateCustomer(parsedObject)
      );
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className="com-search-area">
        <input
          type="text"
          value={searchvalue ? searchvalue : ""}
          className="main-body-top-status-bar-filter-input placeholder-up-input-field form-control position-relative border-start-0 ps-0 text-truncate bg-white"
          placeholder={t("Search customer name, phone number, Email")}
          autoComplete="off"
          onChange={(e) => {
            setSearchvalue(e.target.value);
            setShow(true);
            callsearchlist(e);
          }}
        />
        {searchvalue ? <img className="com-search-area-clear" src={base_assets + 'images/icons/False.png'}
          onClick={(e) => { setSearchvalue(""); setClearcustomer(true) }} alt="" /> : ""}
        <button
          className="com-search-area-large-filter btn-style"
          data-bs-toggle="modal"
          data-bs-target="#customerSearchDetailModal"
        >
          <svg
            className="com-search-area-img"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.9055 15.8618L19.7925 18.7474C19.9272 18.8868 20.0017 19.0735 20 19.2673C19.9983 19.4611 19.9206 19.6465 19.7835 19.7835C19.6465 19.9206 19.4611 19.9983 19.2673 20C19.0735 20.0017 18.8868 19.9272 18.7474 19.7925L15.8604 16.9055C13.9737 18.5219 11.5344 19.3447 9.05418 19.2013C6.57396 19.058 4.24567 17.9595 2.55788 16.1365C0.870078 14.3135 -0.0460314 11.9076 0.00178169 9.42375C0.0495948 6.93986 1.05761 4.57102 2.81432 2.81432C4.57102 1.05761 6.93986 0.0495948 9.42375 0.00178169C11.9076 -0.0460314 14.3135 0.870078 16.1365 2.55788C17.9595 4.24567 19.058 6.57396 19.2013 9.05418C19.3447 11.5344 18.5219 13.9737 16.9055 15.8604V15.8618ZM9.6088 17.7392C11.7651 17.7392 13.8331 16.8826 15.3579 15.3579C16.8826 13.8331 17.7392 11.7651 17.7392 9.6088C17.7392 7.45248 16.8826 5.38447 15.3579 3.85972C13.8331 2.33497 11.7651 1.47838 9.6088 1.47838C7.45248 1.47838 5.38447 2.33497 3.85972 3.85972C2.33497 5.38447 1.47838 7.45248 1.47838 9.6088C1.47838 11.7651 2.33497 13.8331 3.85972 15.3579C5.38447 16.8826 7.45248 17.7392 9.6088 17.7392Z"
              fill="#5CC7C1"
            />
          </svg>
        </button>

        {show && searchvalue !== "" && searchlist.length > 0 ? (
          <div className="dropdown_search_list">
            <ul className="dropdown_search_list-ul">
              {searchlist
                ? searchlist.map((result, key) => {
                  return (
                    <li
                      data-search={result?.name ? result?.name : ""}
                      data-id={result?._id}
                      onClick={(e) => getsearchvalue(e, result?.existsInAddress)}
                      className="dropdown_search_list-ul-li"
                      key={key}
                    >
                      {result?.name ? result?.name : ""}
                    </li>
                  );
                })
                : ""}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
      {parts[3] === 'customer' ? (
        ''
      ) : (
        <CustomerSearchDetailModal
          setnewcustomersid={setnewcustomersid}
          setCusid={setCusid}
        />
      )}
    </>
  );
};

export default CustomerSearchModal;
