import React from 'react'

export default function CircledTickGray(props) {
  const { width, height } = props;
  return (
    <svg width={width ? width : "20"} height={height ? height : "20"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#A4A4A4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
