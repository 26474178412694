import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { _Api, graphqlPost } from '../api/_call'
import '../styleV3.scss'
import './organization.scss'
import { loading, Updateloading } from '../common/helpers/toastify'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import Lodingspinner from '../../components/common/Lodingspinner'
const Organization = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [logo, setLogo] = useState()
  const [phonecodearray, setPhonecodearray] = useState([])
  const [countryarray, setCountryarray] = useState([])
  const [timeZoneOption, setTimeZoneOption] = useState([])
  const [currencyOption, setcurrencyOption] = useState([])
  const [, setImageselect] = useState()
  const [lodingdata, setLodingdata] = useState(false)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const mobilesplashImageArray = [
    {
      image: base_assets + 'images/wallpapers/wallpaper-image-1.png',
      status: false,
      is_delete: false,
    },
    {
      image: base_assets + 'images/wallpapers/wallpaper-image-2.png',
      status: false,
      is_delete: false,
    },
    {
      image: base_assets + 'images/wallpapers/wallpaper-image-3.png',
      status: false,
      is_delete: false,
    },
    {
      image: base_assets + 'images/wallpapers/wallpaper-image-4.png',
      status: false,
      is_delete: false,
    },
    {
      image: base_assets + 'images/wallpapers/wallpaper-image-5.png',
      status: false,
      is_delete: false,
    },
    {
      image: base_assets + 'images/wallpapers/wallpaper-image-6.png',
      status: false,
      is_delete: false,
    },
  ]
  const [mobilesplashScreen, setMobilesplashScreen] = useState(
    mobilesplashImageArray,
  )
  const [completeDataObject, setCompleteObject] = useState()
  const [editorg, setEditorg] = useState(false)
  const { t } = useTranslation()
  const mobileImageSrc = base_assets + 'images/icons/phone-icon-1.png'
  const phoneImageSrc = base_assets + 'images/icons/phone-icon-2.png'
  const mobileImage = (
    <img src={base_assets + 'images/icons/phone-icon-1.png'} alt="" />
  )
  const phoneImage = (
    <img src={base_assets + 'images/icons/phone-icon-2.png'} alt="" />
  )
  const getCountry = async () => {
    try {
      const postdata = `{
                       countries{
                        name
                        id
                        sortname
                        phoneCode  
                        }
                      }`
      let res = await graphqlPost(postdata, 'App/graphql')
      let countryarray = []
      let phonecodearray_ = []
      res?.data?.countries.forEach((cnames) => {
        countryarray.push({
          value: cnames?.id,
          label: cnames?.name,
          phonecode: cnames?.phoneCode,
        })
        phonecodearray_.push({
          label: cnames?.phoneCode,
          value: cnames?.phoneCode,
          name: cnames?.name,
        })
      })
      setPhonecodearray(phonecodearray_)
      setCountryarray(countryarray)
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getCountry()
  }, [])
  const phoneOneOption = [
    {
      value: 'mobile',
      icon: <img src={base_assets + 'images/icons/phone-icon-1.png'} alt="" />,
    },
    {
      value: 'phone',
      icon: <img src={base_assets + 'images/icons/phone-icon-2.png'} alt="" />,
    },
  ]

  const decimalPointOption = [
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '4',
      label: '4',
    },
    {
      value: '5',
      label: '5',
    },
    {
      value: '6',
      label: '6',
    },
  ]

  const amountDisplayOption = [
    {
      value: 'Million',
      label: 'Million',
    },
    {
      value: 'Lakh',
      label: 'Lakh',
    },
  ]
  const amountSepOption = [
    {
      value: ',',
      label: ',',
    },
    {
      value: '.',
      label: '.',
    },
  ]
  const quantitySepOption = [
    {
      value: 'YES',
      label: 'YES',
    },
    {
      value: 'NO',
      label: 'NO',
    },
  ]
  const dateFormatOption = [
    {
      value: 'd/m/Y',
      label: 'DD/MM/YYYY',
    },
    {
      value: 'Y/m/d',
      label: 'YYYY/MM/DD',
    },
    {
      value: 'm/d/Y',
      label: 'MM/DD/YYYY',
    },
    {
      value: 'm/Y/d',
      label: 'MM/YYYY/DD',
    },
  ]

  const imgFormatOption = [
    {
      value: 'PNG',
      label: 'PNG',
    },
    {
      value: 'JPG',
      label: 'JPG',
    },
    {
      value: 'JPEG',
      label: 'JPEG',
    },
  ]
  const signOutOption = [
    {
      value: '1',
      label: '1 hour',
    },
    {
      value: '2',
      label: '2 hour',
    },
    {
      value: '3',
      label: '3 hour',
    },
    {
      value: '4',
      label: '4 hour',
    },
    {
      value: '5',
      label: '5 hour',
    },
    {
      value: '6',
      label: '6 hour',
    },
  ]

  const getorgview = async () => {
    setLodingdata(true)
    try {
      const postdata = {}
      let res = await _Api(postdata, `api/v1/App/organisation/viewOrg`)
      if (res?.code === 200) {
        setLodingdata(false)
        setCompleteObject(res?.data)
        if (res?.data?.mobilesplashScreen) {
          setMobilesplashScreen(res?.data?.mobilesplashScreen)
        }
        if (res?.data?.brand_logo) {
          setLogo(res?.data?.brand_logo)
        }
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const getTimeZoneApi = async () => {
    try {
      const postdata = {}
      let res = await _Api(postdata, `api/v1/App/location/Timezonelist`)
      if (res?.code === 200) {
        setTimeZoneOption(res?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getStoreCurrency = async () => {
    try {
      const postdata = {}
      let res = await _Api(postdata, `api/v1/App/organisation/getAll_Currency`)
      if (res?.code === 200) {
        let currencyarray = []
        res?.data?.forEach((cnames) => {
          if (cnames) {
            currencyarray.push({
              value: cnames,
              label: cnames,
              icon: (
                <img
                  src={
                    base_assets +
                    'images/flags/' +
                    cnames.toLowerCase() +
                    '.png'
                  }
                  alt=""
                />
              ),
            })
          }
        })
        setcurrencyOption(currencyarray)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getTimeZoneApi()
    getStoreCurrency()
    getorgview()
    // eslint-disable-next-line
  }, [])

  const postorgdata = async () => {
    let GetloadingID = loading()
    try {
      let postdata = completeDataObject
      postdata.mobilesplashScreen = mobilesplashScreen
      let res = await _Api(postdata, `api/v1/App/organisation/editOrgDetail`)
      if (res?.code === 200) {
        if (res?.message) {
          Updateloading(t, GetloadingID, (res?.message))
        } else {
          Updateloading(t, GetloadingID, t("Successfully Save"))
        }
      } else {
        if (res.errors) {
          Updateloading(t, GetloadingID, (res.errors), 'error')
        } else {
          Updateloading(t, GetloadingID, t("Something Went Wrong!!"), 'error')
        }
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  const onImageChange = (e) => {
    let GetloadingID = loading()
    const fileSizeInBytes = e.target.files[0].size
    const fileSizeInKB = fileSizeInBytes / 1024

    if (fileSizeInKB > 150) {
      Updateloading(t,
        GetloadingID,
        t('Please select an image that is less than or equal to 150 KB'),
        'error',
      )
    } else {
      const [file] = e.target.files

      const reader = new FileReader(file)
      reader.onloadend = async () => {
        try {
          const postdata = {
            image: reader.result,
            name: 'profile',
          }
          let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`)
          if (resfront.code === 200) {
            let newValues = [...mobilesplashScreen]
            newValues.push({
              image: resfront?.data?.images?.url,
              status: false,
              is_delete: true,
            })
            setMobilesplashScreen(newValues)
          }
        } catch (err) {
          console.log(err, 'err')
        }
      }
      if (file) {
        reader?.readAsDataURL(file);
      }
      Updateloading(t, GetloadingID, t('upload'))
    }
  }
  const onlogoupdate = (e) => {
    let GetloadingID = loading()
    const fileSizeInBytes = e.target.files[0].size
    const fileSizeInKB = fileSizeInBytes / 1024

    if (fileSizeInKB > 150) {
      Updateloading(t,
        GetloadingID,
        t('Please select an image that is less than or equal to 150 KB'),
        'error',
      )
    } else {
      const [logo] = e.target.files
      setLogo(URL.createObjectURL(logo))
      const reader = new FileReader(logo)
      reader.onloadend = async () => {
        try {
          const postdata = {
            image: reader.result,
            name: 'profile',
          }
          let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`)
          if (resfront.code === 200) {
            let newValues_ = { ...completeDataObject }
            if (resfront?.data?.images?.url) {
              const updatedNewValues = {
                ...newValues_,
                brand_logo: resfront.data.images.url,
              }
              setCompleteObject(updatedNewValues)
              setLogo(resfront?.data?.images?.url)
            }
          }
        } catch (err) {
          console.log(err, 'err')
        }
      }
      reader.readAsDataURL(logo)
      Updateloading(t, GetloadingID, t('upload'))
    }
  }

  const selectMobileSplashScreen = (indexKey) => {
    let newValues = mobilesplashScreen.map((item, index) => {
      if (index === indexKey) {
        return { ...item, status: true }
      } else {
        return { ...item, status: false }
      }
    })

    setMobilesplashScreen(newValues)
  }

  const removeMobileSplashScreen = (indexToRemove) => {
    let newValues = [...mobilesplashScreen]
    let updatedArray = newValues.filter((_, index) => index !== indexToRemove)
    setMobilesplashScreen(updatedArray)
  }
  const imageSrc = (e) => {
    if (e.target.src) {
      setImageselect(e.target.src)
    } else {
      setImageselect('')
    }
  }

  const addEventList = () => {
    let newValues_ = { ...completeDataObject }
    let newValues = [...newValues_?.contacts]
    newValues.push({ icon: 'phone', code: '', no: '' })
    newValues_.contacts = newValues
    setCompleteObject(newValues_)
  }

  const handleRemoveElement = (indexToRemove) => {
    let newValues_ = { ...completeDataObject }
    let newValues = [...newValues_?.contacts]
    let updatedArray = newValues.filter((_, index) => index !== indexToRemove)
    newValues_.contacts = updatedArray
    setCompleteObject(newValues_)
  }

  const handleSelectChange = (selectedOption, index, code, no) => {
    let newValues_ = { ...completeDataObject }
    let newValues = [...newValues_?.contacts]
    newValues[index] = { icon: selectedOption, code: code, no: no }
    newValues_.contacts = newValues
    setCompleteObject(newValues_)
  }

  const onChangeValueUpdate = (keyName, value, type = false) => {
    setCompleteObject((prevState) => ({
      ...prevState,
      [keyName]: value,
    }))
  }

  return (
    <>
      <div className="main-body organization-page pt-0">
        <div className="main-content-wrapper-body">
          <div className="row">

            <div className="col-lg-12">
              <div className="d-flex mb-4 align-items-center mt-0">
                <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                  <span> {t('Organization Detail')}</span>
                </h1>
                {editorg ? (
                  <>
                    <button
                      onClick={() => {
                        setEditorg(false)
                      }}
                      type="button"
                      className="btn-close focus-none square-btn bg-white square-btn-white ms-auto"
                      aria-label="Close"
                    ></button>

                    <button
                      onClick={() => {
                        setEditorg(false)
                        postorgdata()
                      }}
                      type="button"
                      className="square-btn bg-main-green save ms-3"
                    >
                      <img
                        className=""
                        src={base_assets + 'images/icons/white-save.png'}
                        alt=""
                      />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setEditorg(true)}
                    type="button"
                    className={parmissiondatashow?.Organization?.Edit ? "square-btn ms-auto" : "d-none"}
                  >
                    <img
                      className=""
                      src={base_assets + 'images/admin/icons/pencil-white.png'}
                      alt=""
                    />
                  </button>
                )}
              </div>
              {lodingdata ?
                <Lodingspinner /> :
                <div className="organization-page-card admin-main-card bg-white p-5 pb-4 mb-4">
                  <h3 className="organization-page-card-heading mb-4 fw-semibold">
                    {t('Information')}
                  </h3>
                  {editorg ? (
                    <div className="row editable v3-edit-customer-modal">
                      <div className="col-md-3">
                        <div className="organization-page-card-image rounded">
                          <label
                            htmlFor="edit-customer-img-choose"
                            className="v3-edit-customer-modal-left-img cursor-pointer mx-auto d-block rounded-circle"
                          >
                            <img
                              className="v3-edit-customer-modal-left-img-show w-100 h-100"
                              src={logo}
                              alt=""
                            />
                            <input
                              id="edit-customer-img-choose"
                              className="d-none"
                              onChange={onlogoupdate}
                              type="file"
                              accept="image/*"
                            />
                          </label>
                        </div>
                        <label className="v3-edit-customer-modal-left-img-label mt-2 mb-3 text-center d-block">
                          {t('File: PNG, JPG, JPEG')} <br /> t
                          {t('Maximum size 200KB')}
                        </label>
                      </div>
                      <div className="col-md-9 col-lg-7 non-edit ps-5">
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <label className="form-label">
                              {t('Organization Name')}{' '}
                              <sup className="danger-text fs-6 ">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={completeDataObject?.organization}
                              onChange={(e) =>
                                onChangeValueUpdate(
                                  'organization',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label">{t('Website')}</label>
                            <input
                              type="text"
                              className="form-control"
                              value={completeDataObject?.website}
                              onChange={(e) =>
                                onChangeValueUpdate('website', e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-12 mb-4">
                            <label className="form-label">{t('Address')}</label>
                            <textarea
                              className="form-control"
                              style={{ height: '60px' }}
                              rows="3"
                              value={completeDataObject?.address}
                              onChange={(e) =>
                                onChangeValueUpdate('address', e.target.value)
                              }
                            ></textarea>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label">{t('Country')} </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={countryarray}
                              value={{
                                value: completeDataObject?.country,
                                label: completeDataObject?.country_name,
                              }}
                              onChange={(e) => {
                                onChangeValueUpdate('country', e.value)
                                onChangeValueUpdate('country_name', e.label)
                              }}
                              classNamePrefix="common-select"
                              isSearchable={true}
                            />
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label">
                              {t('Province/State')}{' '}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={completeDataObject?.state}
                              onChange={(e) =>
                                onChangeValueUpdate('state', e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label">{t('City')} </label>
                            <input
                              type="text"
                              className="form-control"
                              value={completeDataObject?.city}
                              onChange={(e) =>
                                onChangeValueUpdate('city', e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label">{t('Zip Code')} </label>
                            <input
                              type="text"
                              className="form-control"
                              value={completeDataObject?.zipcode}
                              onChange={(e) =>
                                onChangeValueUpdate('zipcode', e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-12 mb-4">
                            <label className="form-label">{t("Tax ID")}</label>
                            <input
                              type="text"
                              className="form-control"
                              value={completeDataObject?.taxid}
                              onChange={(e) =>
                                onChangeValueUpdate('taxid', e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-12 mb-4">
                            <label className="form-label">{t("Email")} </label>
                            <input
                              type="email"
                              className="form-control"
                              value={completeDataObject?.email}
                              onChange={(e) =>
                                onChangeValueUpdate('email', e.target.value)
                              }
                            />
                          </div>

                          <div className="col-md-12 mb-4">
                            <label className="form-label">{t("Phone")} </label>

                            {completeDataObject?.contacts?.length
                              ? completeDataObject?.contacts.map(
                                (value, index) => {
                                  return (
                                    <div className="row mb-4" key={index}>
                                      <div className="col-md-3 d-flex align-items-center">

                                        <Select
                                          className="select-set select w-100 p-0"
                                          placeholder=""
                                          options={phoneOneOption}
                                          value={{
                                            value: value?.icon,
                                            icon:
                                              value?.icon === "mobile"
                                                ? mobileImage
                                                : phoneImage,
                                          }}
                                          onChange={(e) =>
                                            handleSelectChange(
                                              e.value ? e.value : value?.icon,
                                              index,
                                              value?.code,
                                              value?.no,
                                            )
                                          }
                                          classNamePrefix="common-select"
                                          isSearchable={false}
                                          getOptionLabel={(e) => (
                                            <div className="common-select-icon">
                                              {e.icon}
                                            </div>
                                          )}
                                        />
                                      </div>
                                      <div className="col-md-9 d-flex align-items-center phone-code-mix">
                                        <Select
                                          className="select-set select col-md-3 p-0"
                                          placeholder=""
                                          options={phonecodearray}
                                          value={{
                                            value: value?.code,
                                            label: value?.code,
                                          }}
                                          onChange={(e) =>
                                            handleSelectChange(
                                              value?.icon,
                                              index,
                                              e.value ? e.value : value?.code,
                                              value?.no,
                                            )
                                          }
                                          classNamePrefix="common-select"
                                          isSearchable={true}
                                          getOptionLabel={(opt) => opt.label}
                                        />

                                        <input
                                          value={value?.no}
                                          onChange={(e) =>
                                            handleSelectChange(
                                              value?.icon,
                                              index,
                                              value?.code,
                                              e.target.value
                                                ? e.target.value
                                                : value?.no,
                                            )
                                          }
                                          maxLength={17}
                                          pattern="[0-9]*"
                                          type="text"
                                          className="form-control border-start-0"
                                        />
                                        {index !== 0 ? (
                                          <button
                                            className="border-0 bg-transparent p-0 me-2 col-md-2"
                                            onClick={() =>
                                              handleRemoveElement(index)
                                            }
                                          >
                                            <img
                                              className="="
                                              src={
                                                base_assets +
                                                'images/icons/minus-detail.png'
                                              }
                                              alt=""
                                            />
                                          </button>
                                        ) : (
                                          ''
                                        )}
                                      </div>

                                    </div>
                                  )
                                },
                              )
                              : ''}

                            <div className="col-md-4 mt-3">
                              <button
                                className="border-0 bg-transparent p-0 main-green-text"
                                onClick={() => addEventList()}
                              >
                                <img
                                  src={
                                    base_assets + 'images/icons/add-detail.png'
                                  }
                                  alt=""
                                />
                                <span className="ms-2">{t('Add Phone')}</span>
                              </button>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <label className="form-label">{t("Currency")}</label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={currencyOption}
                              value={{
                                value: completeDataObject?.Default_currency,
                                label: completeDataObject?.Default_currency,
                              }}
                              onChange={(e) => {
                                onChangeValueUpdate('Default_currency', e.value)
                              }}
                              classNamePrefix="common-select"
                              getOptionLabel={(e) => (
                                <div className="common-select-icon">
                                  {e.icon}
                                  <span>{e.label}</span>
                                </div>
                              )}
                              isSearchable={false}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              {t('Decimal Point')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={decimalPointOption}
                              value={{
                                value: completeDataObject?.decimal_point,
                                label: completeDataObject?.decimal_point,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('decimal_point', e.value)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              {t('Amount Display')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={amountDisplayOption}
                              value={{
                                value: completeDataObject?.show_amount,
                                label: completeDataObject?.show_amount,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('show_amount', e.value)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              {t('Amount Separator')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={amountSepOption}
                              value={{
                                value: completeDataObject?.thousand_separator,
                                label: completeDataObject?.thousand_separator,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('thousand_separator', e.value)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              {t('Quantity Separator')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={quantitySepOption}
                              value={{
                                value: completeDataObject?.inventory,
                                label: completeDataObject?.inventory,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('inventory', e.value)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              {t('Date Format')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={dateFormatOption}
                              value={{
                                value: completeDataObject?.date_format,
                                label: completeDataObject?.date_format,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('date_format', e.value)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">{t('Time Zone')}</label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={timeZoneOption}
                              value={{
                                value: completeDataObject?.timezones,
                                label: completeDataObject?.timezones,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('timezones', e.label)
                              }
                              classNamePrefix="common-select"
                              isSearchable={true}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              {t('Image Format')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={imgFormatOption}
                              value={{
                                value: completeDataObject?.image_type,
                                label: completeDataObject?.image_type,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('image_type', e.value)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              {t('Auto Sign Out')}
                            </label>
                            <Select
                              className="select-set select w-100 p-0"
                              placeholder=""
                              options={signOutOption}
                              value={{
                                value: completeDataObject?.inactive_hour,
                                label: completeDataObject?.inactive_hour,
                              }}
                              onChange={(e) =>
                                onChangeValueUpdate('inactive_hour', e.value)
                              }
                              classNamePrefix="common-select"
                              isSearchable={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row non-edit">
                      <div className="col-md-3">
                        <div className="organization-page-card-image rounded">
                          <img
                            className="v3-edit-customer-modal-left-img-show w-100 h-100"
                            src={logo}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-7 ps-5">
                        <h1 className="fs-2 mb-4">
                          {completeDataObject?.organization}
                        </h1>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label className="non-edit-label">
                                {t('Website')}
                              </label>
                              <span className="non-edit-input">
                                {completeDataObject?.website}
                              </span>
                            </div>
                            <div className="mb-4">
                              <label className="non-edit-label">
                                {t('Address')}
                              </label>
                              <span className="non-edit-input">
                                {completeDataObject?.address} <br />
                                {completeDataObject?.city}{' '}
                                {completeDataObject?.state}{' '}
                                {completeDataObject?.country_name}{' '}
                                {completeDataObject?.zipcode}
                              </span>
                            </div>
                            <div className="mb-4">
                              <label className="non-edit-label">
                                {t("Tax ID")}
                              </label>
                              <span className="non-edit-input">
                                {completeDataObject?.taxid}
                              </span>
                            </div>
                            <div className="mb-4">
                              <label className="non-edit-label">
                                {t('Email')}
                              </label>
                              <span className="non-edit-input">
                                {completeDataObject?.email}
                              </span>
                            </div>
                            <div className="mb-4">
                              <label className="non-edit-label">
                                {t('Phone')}
                              </label>
                              {completeDataObject?.contacts?.length
                                ? completeDataObject?.contacts.map(
                                  (value, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className="non-edit-input non-edit-input-with-img d-block mb-3"
                                      >
                                        <img
                                          src={
                                            value?.icon === 'mobile'
                                              ? mobileImageSrc
                                              : phoneImageSrc
                                          }
                                          alt=""
                                        />
                                        +{value?.code} {value?.no}
                                      </span>
                                    )
                                  },
                                )
                                : '-'}
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Currency')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              <img
                                src={
                                  base_assets +
                                  'images/flags/' +
                                  completeDataObject?.Default_currency?.toLowerCase() +
                                  '.png'
                                }
                                alt=""
                              />
                              {completeDataObject?.Default_currency}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Decimal Point')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.decimal_point}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Amount Display')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.show_amount}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Amount Separator')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.thousand_separator}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Quantity Separator')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.inventory}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4"></div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Date Format')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.date_format}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Time Zone')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.timezones}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="non-edit-label">
                              {t('Image Format')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.image_type}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4"></div>
                          <div className="col-md-6">
                            <label className="non-edit-label">
                              {t('Auto Sign Out')}
                            </label>
                            <span className="non-edit-input non-edit-input-with-img">
                              {completeDataObject?.inactive_hour}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              }
              <div className="wall-image organization-page-card admin-main-card bg-white p-5 pb-4">
                <h3 className="organization-page-card-heading mb-4 fw-semibold">
                  {t('Mobile Wallpaper Image')}
                </h3>
                {editorg ? (
                  <div className="editable row mx-0">
                    <div className="wall-image-row d-flex p-4 pb-5 rounded-4 mw-100 col-md-9">
                      {mobilesplashScreen?.length
                        ? mobilesplashScreen.map((value, key) => {
                          return (
                            <div
                              key={key}
                              className={
                                value?.status
                                  ? 'position-relative wall-image-row-col active'
                                  : 'position-relative wall-image-row-col'
                              }
                            >
                              <img
                                className="wall-image-row-col-img"
                                src={value?.image}
                                onClick={(e) => selectMobileSplashScreen(key)}
                                alt=""
                              />
                              {value?.is_delete ? (
                                <button
                                  className="wall-image-row-col-cross border-0 p-0 bg-transparent position-absolute"
                                  onClick={(e) =>
                                    removeMobileSplashScreen(key)
                                  }
                                >
                                  <img
                                    src={
                                      base_assets + 'images/icons/False.png'
                                    }
                                    alt=""
                                  />
                                </button>
                              ) : (
                                ''
                              )}
                              {value?.status ? (
                                <img
                                  className="wall-image-row-col-tick"
                                  src={base_assets + 'images/icons/True.png'}
                                  alt=""
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          )
                        })
                        : ''}
                    </div>
                    <div className="col-md-3 d-flex flex-column align-items-center justify-content-center">
                      <label className="">
                        {t('Upload your own wallpaper')}
                      </label>
                      <div className="wall-image-choose">
                        <input
                          type="file"
                          className="d-none"
                          id="own-wallpaper"
                          onChange={onImageChange}
                          accept="image/*"
                        />
                        <label
                          className="wall-image-choose-btn main-green-text rounded-2 fw-semibold cursor-pointer"
                          htmlFor="own-wallpaper"
                        >
                          {t('Upload')}
                        </label>
                      </div>
                      <label className="v3-edit-customer-modal-left-img-label mt-2 mb-1">
                        {t('File: PNG, JPG, JPEG')}
                      </label>
                      <label className="v3-edit-customer-modal-left-img-label mt-2 mb-3">
                        {t('Image Size 375x812')} <br />{' '}
                        {t('Maximum size 100KB')}
                      </label>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="wall-image-row d-flex p-4 pb-5 rounded-4 mw-100 non-edit"
                      onClick={(e) => imageSrc(e)}
                    >
                      {mobilesplashScreen?.length
                        ? mobilesplashScreen.map((value, key) => {
                          return (
                            <div
                              key={key}
                              className={
                                value?.status
                                  ? 'position-relative wall-image-row-col active'
                                  : 'position-relative wall-image-row-col'
                              }
                            >
                              <img
                                className="wall-image-row-col-img"
                                src={value?.image}
                                alt=""
                              />

                              {value?.status ? (
                                <img
                                  className="wall-image-row-col-tick"
                                  src={base_assets + 'images/icons/True.png'}
                                  alt=""
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          )
                        })
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Organization
