import React from "react";

const Product = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4708 10.9585L12.1923 11.0961V11.4067V21.3473L11.9997 21.4424L3.5 17.2529V6.75748L12 2.5577L20.5 6.75748V13.8998H20.1154V7.9861V7.18136L19.3939 7.53784L12.4708 10.9585ZM12.2212 3.09088L12.0003 2.9819L11.7793 3.09065L9.17228 4.37339L8.2675 4.81858L9.16954 5.26929L15.8871 8.62579L16.1089 8.73661L16.3313 8.62714L18.9816 7.32303L19.8921 6.87496L18.982 6.42599L12.2212 3.09088ZM11.0862 20.5776L11.8077 20.9341V20.1293V11.4067V11.0961L11.5292 10.9585L4.6061 7.53784L3.88462 7.18136V7.9861V16.7087V17.0194L4.16313 17.157L11.0862 20.5776ZM5.01844 6.42577L4.10782 6.87383L5.01798 7.32281L11.7788 10.6579L11.9992 10.7667L12.22 10.6585L14.7945 9.39716L15.7027 8.9522L14.7977 8.50074L8.06935 5.14424L7.84768 5.03366L7.62541 5.14303L5.01844 6.42577Z" stroke="white" />
        <path d="M18.5 15L20.5 18L23 16L22 21H15L14 16L16.5 18L18.5 15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>


    </>
  )
}

export default Product;
