import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import RightArrow from '../../common/icons/right-arrow'
import { useTranslation } from 'react-i18next'
import CloseIcon from '../../common/icons/CloseIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageGallary from '../../../Ecommerce/template_two/default/assets/icons/ImageGallary';
import Apiauth from '../../../API/Apiauth';
import { _Apiauth } from '../../api/_call';
import Select from "react-select";
import { success } from '../../common/helpers/toastify';


export default function AddPage() {
  const {state} = useLocation()
    const {t} = useTranslation();
    const navigate = useNavigate()
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    // const [allValues, setAllValues] = useState(state?.pageDetails ? state?.pageDetails : {status: false});
    const [allValues, setAllValues] = useState({});
    const [value, setValue] = useState("");
    console.log(state, "add page page")
    // Editor ref
    const quill = useRef();
    useEffect(()=>{
      if(state?.pageId){
        getPageDetails();
      }
    },[])
    // Handler to handle button clicked
    function handler() {
      console.log(value);
    }

  const getPageDetails = async() =>{
    try{
      let postdata = { "id": state?.pageId} 
      let result = await Apiauth(postdata, `cms/get-page-info`)
      if(result.code === 200){
        console.log(result, "Check output")
        setAllValues(result?.data)
        setValue(result?.data?.content)
        setAllValues((state)=>({...state, menutype : menuTypeOptions[result?.data?.menutype -1], file : {image : result?.data?.featuredImage}}))
      }
    }
    catch(err){
      console.log(err, "Error")
    }
  } 

    const menuTypeOptions = [
      {label : "Header", value : 1},
      {label : "Footer", value : 2}
    ]

    const imageHandler = useCallback(() => {
      // Create an input element of type 'file'
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
  
      // When a file is selected
      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
  
        // Read the selected file as a data URL
        reader.onload = () => {
          const imageUrl = reader.result;
          const quillEditor = quill.current.getEditor();
  
          // Get the current selection range and insert the image at that index
          const range = quillEditor.getSelection(true);
          quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
        };
  
        reader.readAsDataURL(file);
      };
    }, []);
  

    const modules = useMemo(
      () => ({
        toolbar: {
          container: [
            [{ font: [] }],
            [{ header: [2, 3, 4, false] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image","video"],
            ["clean"],
          ],
          handlers: {
            image: imageHandler,
          },
        },
        clipboard: {
          matchVisual: true,
        },
      }),
      [imageHandler]
    );
    const formats = [
      "font",
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
      "color",
      "clean",
    ];


  const handleSave = async() =>{
    if(allValues?.title && value){
    console.log(allValues, "Text editor" , value, "check values")
    try{
      let postdata = {
        "location_id": state?.location ? state?.location : "",
        "title": allValues?.title ? allValues?.title : "",
        "featuredImage": allValues?.file?.image ? allValues?.file?.image : "",
        "content": value ? value : "",
        "status": allValues?.status && allValues?.status === true ? 1 : 0,
        "slug": allValues?.slug ? allValues.slug : "",
        "metaTitle": allValues?.metaTitle ? allValues?.metaTitle : "",
        "metaKeyword": allValues?.metaKeyword ? allValues?.metaKeyword : "",
        "metaDescription": allValues?.metaDescription ? allValues?.metaDescription : "",
        "menutype": allValues?.menutype ? allValues?.menutype?.value : 1 
    }
    if(state.pageDetails){
      postdata.id = state?.pageDetails?.id
    }
    console.log(postdata, "Add pages page")
    let result = await Apiauth(postdata, `cms/post-page`)
    if(result.code === 200){
      console.log("successfull")
      success(t("Successfully Added!"))
    }
    }
    catch(err){
      console.log(err, "Error")
    }
    }
  }

  const handleFeaturedImage = async(image) =>{
    console.log(image, "featured image");
    try {
      let Output = await getImagetoUrl(image);
      if(Output){
          console.log(Output, "featured output", image?.name)
          setAllValues((state)=>({...state, file : {image : Output, name : image.name} }))
      }
      }catch (error) {
          console.error("Error fetching image URL:", error);
      }
  }

  const getImagetoUrl = async (imagelist) => {
    console.log(imagelist, "check image we imported add apge");
    const file = imagelist;

    if (file) {
        if (file.type.startsWith("image/")) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = async () => {
                    try {
                        const postdata = {
                            image: reader.result,
                            name: imagelist.name,
                        };
                        console.log(postdata, "postdata");
                        let res = await _Apiauth(postdata, `/APP/customer/uploadimage`);
                        
                        if (res.code === 200) {
                            console.log(res?.data?.images?.url, "featured url");
                            resolve(res?.data?.images?.url);
                        } else {
                            reject(new Error("Failed to upload image"));
                        }
                    } catch (err) {
                        reject(err);
                    }
                };

                reader.onerror = (error) => {
                    reject(new Error("File reading error: " + error));
                };

                reader.readAsDataURL(file);
            });
        }
    }

    return null; // Or handle the case where the file is not an image
};

  return (
    <div className='main-content-wrapper-body admincmsAddpage'>
         <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0 cursor-pointer" onClick={()=>  navigate(-1)}>{t("Pages")} <RightArrow /> <span className="cursor-pointer">{t("Add Page")}</span></h1>
           
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
            <button className={"com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"} onClick={handleSave}>
              <img className='w-100' src={base_assets + 'images/icons/white-save.png'} alt="" />
            </button>
          </div>
        </div>
        <div className='row admincmsAddpage-content'>
            <div className='col-md-9 admincmsAddpage-content-section'>
            <div className="col-md-12 bg-white p-2">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Title")}<span className='text-danger'> *</span></label>
                    <input type="text" className="v3-input-group-input w-100"
                       value={allValues?.title}
                       placeholder='Privacy Notice'
                       onChange={(e) => setAllValues((state)=>({...state, title: e.target.value}))} 
                      />
                  </div>
            </div>
            <div className="col-md-12 bg-white p-2">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Description")}<span className='text-danger'> *</span></label>
                    <div>
                    <QuillEditor
                      ref={(el) => (quill.current = el)}
                      theme="snow"
                      value={value}
                      formats={formats}
                      modules={modules}
                      onChange={(value) => setValue(value)}
                    />
                    </div>
                  </div>
            </div>
            <div className="col-md-12 bg-white p-2">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Meta Title")}</label>
                    <input type="text" className="v3-input-group-input w-100"
                    value={allValues?.metaTitle}
                    placeholder='Privacy Notice'
                    onChange={(e) => setAllValues((state)=>({...state, metaTitle: e.target.value}))} 
                      />
                  </div>
            </div>
            <div className="col-md-12 bg-white p-2">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Meta Keyword")}</label>
                    <input type="text" className="v3-input-group-input w-100"
                    value={allValues?.metaKeyword}
                    placeholder='Privacy Notice'
                    onChange={(e) => setAllValues((state)=>({...state, metaKeyword: e.target.value}))} 
                      />
                  </div>
            </div>
            <div className="col-md-12 bg-white p-2">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Meta Description")}</label>
                    <textarea className="v3-input-group-input w-100" value={allValues?.metaDescription} placeholder='Privacy Notice'  onChange={(e) => setAllValues((state)=>({...state, metaDescription: e.target.value}))}/>
                   
                  </div>
            </div>
            <div className="col-md-12 bg-white p-2">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Meta Slug")}</label>
                    <input type='text' className="v3-input-group-input w-100" value={allValues?.slug} placeholder='privacy-notice'  onChange={(e) => setAllValues((state)=>({...state, slug: e.target.value}))}/>
                   
                  </div>
            </div>
            <div className="col-md-12 bg-white p-2">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Menu type")}</label>
                    {/* <input type='text' className="v3-input-group-input w-100" value={allValues?.menutype}  onChange={(e) => setAllValues((state)=>({...state, menutype: e.target.value}))}/> */}
                    <Select
                      className="select-set select w-100 p-0"
                      classNamePrefix="common-select"
                      options={menuTypeOptions}
                      value={allValues?.menutype ? allValues?.menutype : menuTypeOptions[0]}
                      onChange={(e) => setAllValues((state)=>({...state, menutype : e}))}
                    />
                  </div>
            </div>
            </div>
            {/* section 2 */}
            <div className='col-md-3 gap admincmsAddpage-content-section'>
            <div className="col-md-12 bg-white p-2 admincmsAddpage-content-section-inputfield">
                  <div className="v3-input-group">
                    
                    <div className="form-check form-switch ps-0 text-nowrap align-items-center d-flex justify-content-between">
                    <label className="v3-input-group-label">{t("Status")}</label>
                                <input className="form-check-input float-none ms-0 me-2 cursor-pointer mb-0" type="checkbox"
                                  onChange={(e) => setAllValues((state)=>({...state, status : !state.status}))}
                                  checked={allValues?.status} />
                    </div>
                  </div>
            </div>
            <div className="col-md-12 bg-white p-2 admincmsAddpage-content-section-inputfield">
              <label className="v3-input-group-label">{t("Featured Image")}</label>
                  <div className="v3-input-group admincmsAddpage-content-section-inputfield-featured d-flex">
                    
                    <div className='admincmsAddpage-content-section-inputfield-featured-wrapper position-relative'>
                      <input className="v3-input-group-input w-100 cursor-pointer"
                        placeholder='please select image'
                        type="file"
                        accept='.jpg,.png,.jpeg'
                        onChange={(e) =>
                          // setAllValues((state)=>({...state, file : e.target.files[0]}))
                          handleFeaturedImage(e.target.files[0])
                        } 
                      />
                       {/* {allValues?.file ?
                          <div className='admincmsAddpage-content-section-inputfield-featured-wrapper-filename position-absolute top-0 ms-2 mt-2 z-0'>{allValues?.file?.name}</div>
                          : */}
                          <div className='admincmsAddpage-content-section-inputfield-featured-wrapper-placeholder position-absolute top-0 mt-2 z-0'><span><ImageGallary width="24px" height="24px"/></span>{t("Click here to upload an image")}</div>
                        {/* } */}
                    </div>
                       
                  </div>
            </div>
            {allValues?.file &&
            <div className="col-md-12 bg-white p-2 admincmsAddpage-content-section-inputfield">
                  <div className="v3-input-group w-100 position-relative align-end">
                  <span className='d-flex justify-content-end cursor-pointer mb-2' onClick={()=>setAllValues((state)=>({...state, file : null}))}><CloseIcon color={"#000000"}/></span>
                    <img className='w-100' src={allValues?.file?.image}/>
                  </div>
            </div>
            }
            </div>
        </div>
    </div>
  )
}
