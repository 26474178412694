import React, { useEffect, useState } from "react";
import { Routes } from "react-router-dom";
import SessionExpiredModal from "./Route/SessionExpiredModal"
const CustomSwitch = ({ children }) => {
    const [userToken, setUserToken] = useState(localStorage.getItem('UserToken'));
    const [sessionExpiredModalVisible, setSessionExpiredModalVisible] = useState(false);

    useEffect(() => {
        if (!userToken) {
            setSessionExpiredModalVisible(true);
        }
        setUserToken(localStorage.getItem('UserToken'))

    }, [userToken]);
    return (
        <>

            {sessionExpiredModalVisible && <SessionExpiredModal onClose={() => setSessionExpiredModalVisible(false)} />}
            <Routes>{children}</Routes>

        </>
    );
};

export default CustomSwitch;
