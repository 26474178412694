import React, { useEffect, useRef, useState } from "react";
import ImageGallery from 'react-image-gallery';
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../../common/Spinner";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RecentViewProducts from "Ecommerce/template_three/components/RecentViewProducts";
import CollectionSlider from "Ecommerce/template_three/include/Common/CollectionSlider";
import HeartIcon from "admin/common/icons/HeartIcon";
import MinusIcon from "Ecommerce/common/icons/MinusIcon";
import PlusIcon from "Ecommerce/common/icons/PlusIcon";
import { _Api } from "Ecommerce/common/api";
import AddCartModal from "Ecommerce/template_three/components/Cart/AddCartModal";
import CartItemsNotify from "Ecommerce/template_three/components/Cart/CartItemsNotify";
import SizeGuildeModal from "Ecommerce/template_three/components/SizeGuilde/SizeGuildeModal";
import { wishlistobjectheader } from "source/app/appSlice";
import Ring from "admin/common/icons/Ring";
import CircledTick from "admin/common/icons/sidebar/CircledTick";
import Diamond from "admin/common/icons/Diamond";
import RightArrow from "admin/common/icons/right-arrow";
import FilterIconMain from "admin/common/icons/sidebar/FilterIconMain";
// import Ring from "../../../../../admin/common/icons/Ring";
// import CircledTick from "../../../../../admin/common/icons/sidebar/CircledTick";
// import Diamond from "../../../../../admin/common/icons/Diamond";
// import RightArrow from "../../../../../admin/common/icons/right-arrow";
// import FIlterIcon from "../../assets/icons/FIlterIcon";

export default function OrderComplete({productDetail, diamondProductDetail, engravingData, setEngravingData, setShowEngravingPage, setActiveTab, setOpenCart, setIsopennotifycart}) {
  
    const order = "select";
    const orderSelect = [
      {title: 'Choose your setting', order: 1},{title: 'Select your center stone', order: 2},{title: 'Place Complete', order: 3}
    ]
    const orderDiamond = [
      {title: 'Select your center stone', order: 1},{title: 'Choose your setting', order: 2},{title: 'Place Complete', order: 3}
    ]
    const { state } = useLocation();
    const { t } = useTranslation() 
    const dispatch = useDispatch();
    const [diamondProduct, setDiamondProduct] = useState(diamondProductDetail)
    const [productdetails, setProductdetails] = useState(productDetail?.productdetails)
    const [mobileTab, setMobileTab] = useState();
    const [quantity, setQuantity] = useState(1);
    const [showdropDown, setShowDropdown] = useState(1)
    const [tabs, setTabs] = useState(order === 'select' ? orderSelect : orderDiamond );
    const [variantcombo, setVariantcombo] = useState({ metal: '', stone: '', size: '' });
    const [isopencart, setisopencart] = useState(false)
    const [loading, setloading] = useState(true);
    const fullPath = useLocation();
    const parts = fullPath.pathname.split('/');
    const params = new URLSearchParams(fullPath.search);
    const vValue = params.get('v');
    let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
    let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
    let navigate = useNavigate();
    const [variantdata, setVarientdata] = useState([])
    const [varientloading, setVarientloading] = useState(false)
    const [wishlistdata, setWishliatdata] = useState({ id: "", remove: false, edit: false })
    const [engraveActiveTab, setEngraveActiveTab] = useState(0)
    const placeorderpageRef = useRef();
    const shapeArray = [
        {name:'Round', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/round-diamond.png'},
        {name:'Asscher', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/asscher-diamond.png'},
        {name:'Emerald', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/emerald-diamond.png'},
        {name:'Heart', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/heart-diamond.png'},
        {name:'Radiant', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/radiant-diamond.png'},
        {name:'Cushion', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/cushion-diamond.png'},
        {name:'Pear', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/pear-diamond.png'},
        {name:'Oval', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/oval-diamond.png'},
        {name:'Princess', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/princess-diamond.png'},
        {name:'Marquise', img:'https://gis247.s3.us-east-2.amazonaws.com/Diamond/marquise-diamond.png'}
      ]
console.log(productdetails, "product details", diamondProduct, "diamond details" )
      const setwishlist = () => {
        let setobj = { ...productDetail?.productdetails }
        console.log(setobj,"check wishlist")
        if (setobj.isWishlist) {
            setobj.isWishlist = 0
            let removewislistarr = [...localmywishlistremove]
            removewislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(removewislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlistremove', JSON.stringify(unique_ids ? unique_ids : []));
            if (localmywishlist?.length) {
                const updatedData = localmywishlist.filter(id => id !== setobj?._id);
                localStorage.setItem('mywishlist', JSON.stringify(updatedData ? updatedData : []));
                dispatch(wishlistobjectheader(updatedData));
            }
        } else {
            setobj.isWishlist = 1
            let addwislistarr = [...localmywishlist]
            addwislistarr.push(setobj?._id)
            const uniqueIdsSet = new Set(addwislistarr);
            let unique_ids = Array.from(uniqueIdsSet);
            localStorage.setItem('mywishlist', JSON.stringify(unique_ids ? unique_ids : []));
            dispatch(wishlistobjectheader(unique_ids));
            if (localmywishlistremove?.length) {
                const updatedData = localmywishlistremove.filter(removeid => removeid !== setobj?._id);
                localStorage.setItem('mywishlistremove', JSON.stringify(updatedData ? updatedData : []));
            }
        }
        setProductdetails(setobj)
    }


    const combination_variant = (variant, data) => {
        let variant_sku = '';
        if (variant === 'metal') {
            variant_sku = productdetails?.SKU + data?.value + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, metal: data });
        }
        if (variant === 'stone') {
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + data?.value + '-' + (variantcombo?.size ? variantcombo?.size?.value : '');
            setVariantcombo({ ...variantcombo, stone: data });
        }
        if (variant === 'size') {
            let allsize = document.querySelectorAll(".sizeselect");
            allsize.forEach(sizze => {
                sizze.classList.remove('selectedSize');
                if (sizze.textContent === data?.label) {
                    sizze.classList.add('selectedSize');
                }
            });
            variant_sku = productdetails?.SKU + (variantcombo?.metal ? variantcombo?.metal?.value : '') + (variantcombo?.stone ? variantcombo?.stone?.value : '') + '-' + data?.value;
            setVariantcombo({ ...variantcombo, size: data });
        }
        if (variant_sku) {
            // let variant = variantdata?.length ? variantdata.filter(e => e?.SKU === variant_sku)[0] : '0.00';
            // document.getElementById("variantprice").textContent = variant?.price;
            // document.getElementById("variantprice").setAttribute('data-caritemid', variant?._id);
            // if (variant?.main_image) {
            //     let imagearray = [{
            //         "original": variant?.main_image,
            //         "thumbnail": variant?.main_image
            //     }];
            //     if (productdetails?.images?.length) {
            //         const newArr2 = [...productdetails?.images];
            //         newArr2[0] = imagearray[0];
            //         setProductdetails({
            //             ...productdetails,
            //             images: newArr2
            //         });
            //     }

            // }
        }
    }

    const handleChangeEngraving = () =>{
        setShowEngravingPage(true)
    }

    const handleChangeOnClick = (tab) => {
        setActiveTab(tab);
    }

    useEffect(()=>{
        if (placeorderpageRef.current) {
            placeorderpageRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          console.log(productDetail, diamondProduct, "product data")
    },[])


  
    return (
        <div className='mix_matchpage' ref={placeorderpageRef}>
    <div className="ecommerce-main-layout">
                        <div className='mix_matchpage-wrapper'>
                        
                            </div>
                            <div className="mix_match-product-details">
                            <div className="mix_match-product-details-image-section complete">
                                <img src={productdetails?.images?.[0]?.original} alt="producimage"/>
                                {/* <ImageGallery
                                    items={productdetails?.images ? productdetails?.images : []}
                                    showPlayButton={false}
                                    showFullscreenButton={false}
                                    showNav={false}
                                    infinite={true}
                                    disableSwipe={false}
                                    disableThumbnailSwipe={false}
                                    additionalClass="img-view-slider"
                                /> */}
                                <img src={diamondProduct?.image}/>
                            </div>
                                <div className="mix_match-product-details-section">
                                    
                                    <div className="mix_match-product-details-section-product-details complete">
                                        <div className={`mix_match-product-details-section-product-details-name ${productdetails?.isWishlist ? "likeHeartBtn" : ""}`}>
                                            <span>{productdetails?.name}</span>
                                            {/* <div className="d-inline-block cursor-pointer" onClick={() => setwishlist()}>
                                            {productdetails?.isWishlist ?
                                                    <i onClick={() => {
                                                        setWishliatdata((state) => ({
                                                            ...state,
                                                            id: productdetails?._id,
                                                            add: false,
                                                            remove: true
                                                        }))
                                                    }} className="fa fa-sharp fa-solid fa-heart text-danger" style={{ fontSize: "20px" }}></i> :
                                                    <span
                                                        onClick={() => {
                                                            setWishliatdata((state) => ({
                                                                ...state,
                                                                id: productdetails?._id,
                                                                add: true,
                                                                remove: false
                                                            }))
                                                        }
                                                        }>   <HeartIcon color={"red"} width={24} height={24} /></span>}
                                            </div> */}
                                        </div>
                                        
                                        <div className={`mix_match-product-details-section-product-details-name ${productdetails?.isWishlist ? "likeHeartBtn" : ""}`}>
                                            <span>{diamondProduct?.Cut} {diamondProduct?.Carat} {t("Carat")} {diamondProduct?.Culet} {("Diamond")}</span>
                                        </div>
                                        <span className="mix_match-product-details-section-product-details-serialNumber">
                                            {productdetails?.SKU}
                                        </span>
                                    </div>

                                   
                                   
                                   <div className="mix_match-product-details-section-productoverview">
                                      
                                        <div className="mix_match-product-details-section-productoverview-details">
                                            <div className="mix_match-product-details-section-productoverview-details-product">
                                                {/* <div className="mix_match-product-details-section-productoverview-details-product-img"><img src={productdetails?.images?.[0]?.original} alt="product"/></div> */}
                                                <div className="mix_match-product-details-section-productoverview-details-product-items">
                                                    <div className="mix_match-product-details-section-productoverview-details-product-items-credentials">
                                                        <div className="mix_match-product-details-section-productoverview-details-product-items-credentials-setting"><span>{t("Ring Setting")}:</span><span>{productdetails?.price}</span></div>
                                                        
                                                        <div className="mix_match-product-details-section-productoverview-details-product-items-credentials-metal"><span>{t("Metal")} : {productDetail?.productSelection?.metal?.label}</span> <span>{t("Size")} : {productDetail?.productSelection?.size?.name}</span><span>{t("Pointer")} : {productDetail?.productSelection?.pointer?.label}</span></div>
                                                        <div className="mix_match-product-details-section-productoverview-details-product-items-data-change" onClick={()=>handleChangeOnClick('Product')}>{t("Change Ring")}</div>    
                                                    </div>
                                                    {/* <div className="mix_match-product-details-section-productoverview-details-product-items-data">
                                                    <div className="mix_match-product-details-section-productoverview-details-product-items-data-price"> <span>{productdetails?.price}</span></div>    
                                                    <div className="mix_match-product-details-section-productoverview-details-product-items-data-change" onClick={()=>handleChangeOnClick('Product')}>{t("Change")}</div>    
                                                    </div> */}
                                                </div>
                                            </div>

                                            {/* diamond details */}
                                            <div className="mix_match-product-details-section-productoverview-details-product">
                                                {/* <div className="mix_match-product-details-section-productoverview-details-product-img"><img className="mix_match-product-details-section-productoverview-details-product-img-diamond" src={diamondProduct?.image} alt="diamond"/></div> */}
                                                <div className="mix_match-product-details-section-productoverview-details-product-items">
                                                    <div className="mix_match-product-details-section-productoverview-details-product-items-credentials">
                                                        <div className="mix_match-product-details-section-productoverview-details-product-items-credentials-setting">{t("Diamond")} :</div>
                                                        {/* <div className="mix_match-product-details-section-productoverview-details-product-items-credentials-name">{diamondProduct?.Cut} {diamondProduct?.Carat} {t("Carat")} {diamondProduct?.Culet} {("Diamond")}</div> */}
                                                        <div className="mix_match-product-details-section-productoverview-details-product-items-credentials-metal">{diamondProduct?.Carat} {t("Carat")} {diamondProduct?.Cut} - {diamondProduct?.Colour} / {diamondProduct?.Culet}</div>
                                                        <div className="mix_match-product-details-section-productoverview-details-product-items-data-change" onClick={()=>handleChangeOnClick('Diamond')}>{t("Change Diamond")}</div>    
                                                    </div>
                                                    {/* <div className="mix_match-product-details-section-productoverview-details-product-items-data">
                                                    <div className="mix_match-product-details-section-productoverview-details-product-items-data-price"> <span>{diamondProduct?.FormattedPrice}</span></div>    
                                                    <div className="mix_match-product-details-section-productoverview-details-product-items-data-change" onClick={()=>handleChangeOnClick('Diamond')}>{t("Change")}</div>    
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                   </div>

                                   <div className="mix_match-product-details-section-engraving">
                                        <div className="mix_match-product-details-section-engraving-header">
                                            <span className="title">{t("Engraving")} :</span>
                                            {/* <span className="change" onClick={()=>handleChangeEngraving()}>{t("Change Engraving")}</span> */}
                                        </div>
                                        <div className="mix_match-product-details-section-engraving-container">
                                            <div className="mix_match-product-details-section-engraving-container-tabs">
                                                <div className={`mix_match-product-details-section-engraving-container-tabs-button ${engraveActiveTab === 0 ? 'active' : ""}`} onClick={()=> setEngraveActiveTab(0)}>{t("Engraved Message")}</div>
                                                <div className={`mix_match-product-details-section-engraving-container-tabs-button ${engraveActiveTab === 1 ? 'active' : ""}`} onClick={()=> setEngraveActiveTab(1)}>{t("Logo")}</div>
                                            </div>
                                            {engraveActiveTab === 0 ? 
                                            <>
                                            <div className="mix_match-product-details-section-engraving-container-content">
                                                <div className="mix_match-product-details-section-engraving-container-content-titles">
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-tag">{t("Text")}</div>
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-tag">{t("Position")}</div>
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-tag">{t("Font")}</div>
                                                </div>
                                                <div className="mix_match-product-details-section-engraving-container-content-titles">
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-data">{engravingData?.customizeText ? engravingData?.customizeTex : "-"}</div>
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-data">{engravingData?.textposition?.value}</div>
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-data">{engravingData?.fonts?.value}</div>
                                                </div>
                                            </div>
                                            <span className="change" onClick={()=>handleChangeEngraving()}>{t("Change Engraving")}</span>
                                            </>
                                            :
                                            <div className="mix_match-product-details-section-engraving-container-logo">
                                                <div className="mix_match-product-details-section-engraving-container-logo-imagedata">{ engravingData?.imageFile? <> <img src={engravingData?.imageFile?.data_url} alt="logo"/><span>{engravingData?.imageFile?.name}</span> </> : ""}</div>
                                                <div className="mix_match-product-details-section-engraving-container-content">
                                                <div className="mix_match-product-details-section-engraving-container-content-titles">
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-tag">{t("Position")}</div>
                                                </div>
                                                <div className="mix_match-product-details-section-engraving-container-content-titles">
                                                    <div className="mix_match-product-details-section-engraving-container-content-titles-data">{engravingData?.imgPosition?.value}</div>
                                                </div>
                                            </div>
                                            </div>
                                            }
                                        </div>
                                   </div>

                                   <span className="mix_match-product-details-section-product-price complete">
                                        <span>{t("Total")}</span> <span>{diamondProduct?.FormattedPrice?.split(" ")?.[0]} {parseFloat(diamondProduct?.UnformattedPrice) + parseFloat(productDetail?.productSelection?.pointer?.value)}</span> 
                                    </span>

                                    <div className="mix_match-product-details-section-buttons">
                                    <div 
                                    disabled={!productdetails?.size?.length ? (!variantcombo?.size ? false : true) : (!variantcombo?.size ? true : false)}
                                    // setIsopennotifycart(true); to open cartNotification   
                                    onClick={()=> setOpenCart((state)=> state=true)} 
                                        className="mix_match-product-details-section-buttons-addToCartBtn">
                                        {t("Add To Cart")}
                                    </div>
                                    </div>


                                   
                                    
                                </div>
                            </div>
                            <div className="mix_match-product-details-section-product-bottom">
                                <div className="mix_match-product-details-section-product-bottom-top">
                                    <div className={`mix_match-product-details-section-product-bottom-top-tab ${showdropDown === 2 ? "active" : ""}`} onClick={()=>setShowDropdown(2)}>
                                            {t("Product Details")}
                                    </div>
                                    <div className={`mix_match-product-details-section-product-bottom-top-tab ${showdropDown === 1 ? "active" : ""}`} onClick={()=>setShowDropdown(1)}>
                                            {t("Diamond Details")}
                                    </div>
                                </div>
                                    {/* <div className={`mix_match-product-details-section-product-productdetails complete ${showdropDown === 2 ? "border-line" : ""}`}>
                                        <div className="d-flex justify-content-between align-items-center title" onClick={() => {showdropDown === 2 ? setShowDropdown(null) : setShowDropdown(2)}}>
                                            <label>{t("Product Details")}</label>
                                            {showdropDown === 2 ? <MinusIcon width={20} height={20} /> :
                                                <PlusIcon width={20} height={20} />}
                                        </div>
                                        
                                    </div>  */}
                                    <div className={`mix_match-product-details-section-product-productdetailscomplete ${showdropDown === 1 ? "border-line" : ""}`}>
                                        {/* <div className="d-flex justify-content-between align-items-center title" onClick={() => {showdropDown === 1 ? setShowDropdown(null) : setShowDropdown(1)}}>
                                            <label>{t("Diamond Details")}</label>
                                            {showdropDown === 1 ? <MinusIcon width={20} height={20} /> :
                                                <PlusIcon width={20} height={20} />}
                                        </div> */}
                                        {showdropDown === 2 ? 
                                        <div className="mix_match-product-details-section-product-productdetails-inner p-4">
                                            <div className="mix_match-product-details-section-product-productdetails-inner-item-product text-bold text-black">{t("Description")}</div>
                                            <div className="mix_match-product-details-section-product-productdetails-inner-item-data">{productdetails?.Description}</div>
                                            <div className="mix_match-product-details-section-product-productdetails-inner-item-product text-bold text-black m-2 b-2">{t("Product Details")}</div>
                                            <div className="mix_match-product-details-section-product-productdetails-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetails-inner-item-product">{t("Material")}</span>
                                                <span className="mix_match-product-details-section-product-productdetails-inner-item-data">{productdetails?.metal?.[0]?.label}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetails-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetails-inner-item-product">{t("Stone Weight")}</span>
                                                <span className="mix_match-product-details-section-product-productdetails-inner-item-data">{t("0.35 Cr")}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetails-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetails-inner-item-product">{t("Reference Number")}</span>
                                                <span className="mix_match-product-details-section-product-productdetails-inner-item-data">{productdetails?.SKU}</span>
                                            </div>
                                        </div> : null}
                                        {showdropDown === 1 ? 
                                        <div className="wrapper">
                                        <div className="mix_match-product-details-section-product-productdetailscomplete-inner p-4">
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Stock ID")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.StockID}</span>
                                            </div>              
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Shape")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Shape   }</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Carat")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Carat}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Colour")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Colour}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Clarity")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Clarity}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Cut")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Cut}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Polish")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Polish}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Symmetry")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Symmetry}</span>
                                            </div>
                                        </div> 
                                        <div className="mix_match-product-details-section-product-productdetailscomplete-inner p-4">
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Flourescence")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Fluoresence}</span>
                                            </div>              
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Depth")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Depth}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Table")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Table}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Gridle")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Gridle}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Culet")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Culet}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Measurements")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Measurement}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Graded By")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.GradedBy}</span>
                                            </div>
                                            <div className="mix_match-product-details-section-product-productdetailscomplete-inner-item">
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-product">{t("Certification")}</span>
                                                <span className="mix_match-product-details-section-product-productdetailscomplete-inner-item-data">{diamondProduct?.Certification}</span>
                                            </div>

                                        </div> 
                                        </div>
                                        : null}
                                    </div> 
                            </div>             
                            {/* <div className="ecommerce-main-layout-collection">
                                <CollectionSlider />
                            </div>
                            <div className="ecommerce-main-layout-recent_view-product">
                                <RecentViewProducts currentid={state?.id}
                                    setWishliatdata={setWishliatdata} wishlistdata={wishlistdata} />
                            </div>
                            <AddCartModal setisopencart={setisopencart} isopencart={isopencart} />
                            {isopennotifycart ? <CartItemsNotify isopennotifycart={isopennotifycart} setIsopennotifycart={setIsopennotifycart} setisopencart={setisopencart} /> : ''}
                            */}
                             <SizeGuildeModal />
                        </div >
    </div>
  )
}
