import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { _Api } from "../../api/_call";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { LocationList } from "../../common/graphql/commonlayouts";
import DateTime from "../../common/DateTime";
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { Tablehead } from "../../common/modules/Tablehead";
import Advancedfiltermodel from "../../common/modules/advanced-filter-model";
import { VoucherList } from "../../common/graphql/commonlayouts";
import { createtransferlist } from '../../common/Tablehead';
import NotesModalLabel from "../../common/modules/NotesModalLabel";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { toFormatPrice, toUnformatPrice } from "../../common/helpers/function";
import { calDuedate } from "../../common/helpers/formatDate";
import TransAndDeliverDate from "../../common/modules/TransAndDeliverDate";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import Select from "react-select"
const CreateTransfer1 = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState('');
  const [form_note, setForm_note] = useState(null);
  const [commanSelected_vouc, setcommanSelected_vouc] = useState(null);
  const [newcolumndata, setnewcolumndata] = useState(createtransferlist);
  const [listdetail, setListdetail] = useState();
  const [oldlistdetails, setOldlistdetails] = useState();
  const [ShowSubmitbutton, setShowSubmitbutton] = useState(true);
  const [transactionDate, set_transactionDate] = useState(new Date());
  const [deliveredDate, set_deliveredDate] = useState();
  const [commanSelected_loc, setcommanSelected_loc] = useState(null);
  const [selecteditem, setSelecteditem] = useState([]);
  const [repair_connect, setRepair_connect] = useState()
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const getdeliverydate = async () => {
    set_deliveredDate(await calDuedate(new Date()));
  }
  useEffect(() => {
    getdeliverydate();
  }, []);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_create_transfer_list',
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    callcolumns();
  }, []);
  const submitcreatelist = async (firstlimit, search) => {
    setDatsLodaer(true)
    UnSetdataTable("tbl_createTransfer");
    try {
      const postdata = {
        "id": state.createlist,
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
      };
      let res = await _Api(postdata, `api/v1/Inventory/stocktransfer/getCreateList`);
      if (res.code === 200) {
        setListdetail(res.data);
        setOldlistdetails(res.data);
        SetdataTable("tbl_createTransfer", "4", newcolumndata, [1, 9, 10, 11]);
        setDatsLodaer(false)
      } else {
        SetdataTable("tbl_createTransfer", "4", newcolumndata);
        setDatsLodaer(false)
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  useEffect(() => { }, [deliveredDate]);
  const filterbyname = (search) => {
    submitcreatelist(limit, search);
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const deleteProduct = () => {
    const selected = document.querySelectorAll(".createTransfer");
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let product_id = selected[i].getAttribute('data-id');
        setListdetail((current) =>
          current.filter((item) => item?.id !== product_id)
        );
        selected[i].checked = false;
      }
    }
    setSelecteditem([]);
    UnSetdataTable('tbl_createTransfer');
    setTimeout(() => {
      SetdataTable("tbl_createTransfer", "4", newcolumndata);
    }, 100);
  }
  const selectedproduct = (event) => {
    if (event.target.checked) {
      const mainselected = [...selecteditem, event.target.value];
      setSelecteditem(mainselected);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  }
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".createTransfer");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id');
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);

  const changeqty = (event, i) => {
    var main = [...listdetail];
    let oldmain = [...oldlistdetails];
    if (event.target.value <= oldmain[i].po_QTY) {
      const { value, name } = event.target;
      main[i] = {
        ...main[i],
        [name]: value,
      };
      setListdetail(main);
    } else {
      error("Enter Quantity less than" + oldmain[i].po_QTY);
    }
  };
  const submitdata = async () => {
    const main_array = [...listdetail];
    const transfer_data = [];
    let transferfrom = '';
    main_array.forEach((element) => {
      transfer_data.push({ "po_product_id": element.id, "stock_transfer_id": '', "quantity": element.po_QTY, "repair_connect": repair_connect || element?.repair_connect === 1 ? true : false })
      transferfrom = element.location_id;
    })
    const handleValidation = (e) => {
      let formIsValid = true;
      if (!commanSelected_loc) {
        formIsValid = false;
        error(t("Please select location"));
      }
      if (!transactionDate) {
        formIsValid = false;
        error(t("Please select Transaction Date"))
      }
      if (!deliveredDate) {
        formIsValid = false;
        error(t("Please select Delivery Date"))
      }
      if (selecteditem?.length === 0) {
        formIsValid = false;
        error(t("No item Selected!!"))
      }
      var allcheck = document.querySelectorAll(".createTransfer");
      for (var j = 0; j < allcheck.length; j++) {
        let qty = allcheck[j].getAttribute('data-qty');
        if (parseInt(qty) === 0) {
          let qtycheck = document.querySelectorAll(".qtycheck");
          qtycheck[j].style.border = "1px solid red ";
          formIsValid = false;
        } else {
          let qtycheck = document.querySelectorAll(".qtycheck");
          qtycheck[j].style.border = "1px solid #f4f4f4 ";
        }
      }
      return formIsValid;
    }
    if (handleValidation()) {
      setShowSubmitbutton(false);
      let GetloadingID = loading();
      try {
        const postdata = {
          "voucher_id": commanSelected_vouc,
          "transaction_date": transactionDate,
          "delivery_date": deliveredDate,
          "transfer_to_location": commanSelected_loc,
          "transfer_from": transferfrom,
          "remark": form_note,
          "purchase_data": transfer_data,

        };
        let restransferinventory = await _Api(postdata, `api/v1/Inventory/stocktransfer/createStockTransfer`);
        if (restransferinventory.code === 200) {
          setShowSubmitbutton(true);
          Updateloading(t, GetloadingID, t("Created successfully!!"));
          const timer = setTimeout(() => {
            navigate("/app/inventory/stock_transfer");
          }, 100);
          return () => clearTimeout(timer);
        } else {
          setShowSubmitbutton(true);
          Updateloading(t, GetloadingID, restransferinventory?.errors ? restransferinventory?.errors : restransferinventory?.message, 'error');
        }

      }
      catch (err) {
        console.log(err, 'err');
      }
    }
  }

  useEffect(() => {
    submitcreatelist();
    // eslint-disable-next-line
  }, []);

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    createtransferlist.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (listdetail.length) {
      listdetail.forEach((result, key) => {
        bodyarray.push(["", key + 1, result?.main_image, result?.product_name, result?.SKU, result?.stock_id, result?.metal_name, result?.stone_name, result?.size_name, result?.po_QTY, result?.price, result?.amount_total, result?.location_name
        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "create_transfer");

  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link to=
                    {state?.myinventory ? "/app/inventory/myInventory" :
                      state?.ItemSearch ? "/app/inventory/itemsearch"
                        : "/app/inventory/createTransferInventory"
                    } className="page-back-btn">
                    <img
                      src={base_assets + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </Link>
                  <img src={base_assets + "images/icons/home-icon.png"} alt="" />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Stock_Transfer"}
                    voucherid={state.Voucher_id}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">{t("Create transrfer")}</h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills"></div>
              </div>
              <div className="main-body-top-inputs-bar">
                <div className="main-body-top-inputs-bar-col-left">

                  <label className="po-num-label  po-num-red">{t("ST-XXX")}</label>
                  <LocationList addclass={'row-inactive'} setcommanSelected_loc={setcommanSelected_loc} locationid={state ? state?.showwarehouseid : ''} hidelocationid={state ? state?.hidelocationid : ''} />

                  <TransAndDeliverDate TransactionDate={transactionDate} DeliveryDate={deliveredDate} setTransactionDate={set_transactionDate} setDeliveryDate={set_deliveredDate} />
                </div>
                <div className="main-body-top-inputs-bar-col-right">
                  <button onClick={submitdata} className={
                    ShowSubmitbutton
                      ? "main-body-top-inputs-bar-col-right-submit-btn com-btn "
                      : "main-body-top-inputs-bar-col-right-submit-btn com-btn d-block  deactive"
                  }>
                    {t("Create")}

                  </button>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3 z-10"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); submitcreatelist(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("StockID/SKU/Metal/Stone")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <div className="print-export-dropdown-selected-icon">
                    <button className={
                      selecteditem.length > 0
                        ? "deletebtn btn-style d-block"
                        : "d-none"
                    } onClick={deleteProduct}>
                      <img src={base_assets + "images/icons/delete-icon.png"} alt="" />
                    </button>
                  </div>
                  <div
                    className="main-body-top-status-bar-note-button"
                    data-bs-toggle="modal"
                    data-bs-target="#notesModal"
                  >
                    <img
                      src={base_assets + "images/icons/comment-icon.png"}
                      alt=""
                    />
                    {t("note")}
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item print-dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li className="dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/import-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("import")}</span>
                      </li>
                      <li className="dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/share-icon.png"}
                            alt=""
                          />
                        </div>
                        <span data-bs-toggle="modal" data-bs-target="#socialModal">{t("share")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="tbl_createTransfer"
                    className="common-table first-row-gray w-100"
                  >
                    <Tablehead
                      tablehead={createtransferlist}
                      tblName={"tbl_create_transfer_list"}
                      setfilterby={""}
                      setShowoption={setSelectAllStatus}
                    />
                    <tbody>
                      <>
                        {listdetail ? listdetail.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <div className="com-check">
                                  <input id={result?.id}
                                    data-id={result?.id}
                                    data-qty={result?.po_QTY}
                                    className="createTransfer createtransfershead"
                                    onChange={selectedproduct}
                                    onClick={() => setRepair_connect(result?.repair_connect)}
                                    value={result?.id}
                                    type="checkbox" />
                                  <label htmlFor={result?.id} className="com-check-label" />
                                </div>
                              </td>
                              <td>{key + 1}</td>
                              <td className="table-pro-img">
                                <img src={result?.main_image ? result?.main_image : base_assets + "images/icon/camera_profile.png"} alt="" />
                              </td>
                              <td className="po-no">
                                {result?.product_name ? result?.product_name : ''}
                              </td>
                              <td className="po-no">
                                {result?.SKU ? result?.SKU : ''}
                              </td>
                              <td>{result?.stock_id ? result?.stock_id : ''}</td>
                              <td>{result?.metal_name ? result?.metal_name : ''}</td>
                              <td>{result?.stone_name ? result?.stone_name : ''}</td>
                              <td>{result?.size_name ? result?.size_name : ''}</td>
                              <td><div className="col-input input-field-bg">
                                <input
                                  type="text"
                                  className="qtycheck"
                                  name="po_QTY"
                                  value={result?.po_QTY}
                                  onChange={(e) => changeqty(e, key)}
                                />
                              </div>
                              </td>
                              <td>
                                {toFormatPrice(result?.price
                                  , { addSymbol: true })}
                              </td>
                              <td>{toFormatPrice(result?.amount_total, { addSymbol: true })} </td>
                              <td className="text-center link-color">
                                {result?.location_name ? result?.location_name : ''}
                                <span className="td-sub-text text-danger">
                                  {result?.warehouse_location_name ? result?.warehouse_location_name : ''}
                                </span>
                              </td>
                            </tr>
                          );
                        }) : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={2} className="main-green-text left-0">
                          {selecteditem.length > 0 ? (
                            <span> {selecteditem.length} {t("item selected")}</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{listdetail ? listdetail?.reduce(
                          (interation, val) => (interation = interation + parseInt(val.po_QTY)),
                          0
                        ) : ""}</td >
                        <td />
                        <td >
                          {toFormatPrice(
                            listdetail
                              ? listdetail?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation +
                                  parseFloat(toUnformatPrice(val?.amount_total))),
                                0
                              )
                              : ""
                            , { addSymbol: true })}
                        </td>
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
      </div>
      <ConfirmationModel></ConfirmationModel>
      <TableColumnModal tablehead={createtransferlist} tblName={"tbl_create_transfer_list"}
        calldatatable={submitcreatelist}
        setnewcolumndata={setnewcolumndata}></TableColumnModal>
      <NotesModalLabel setForm_note={setForm_note}></NotesModalLabel>
      <Advancedfiltermodel></Advancedfiltermodel>
    </React.Fragment>
  );
};
export default CreateTransfer1;