import { useEffect } from 'react';

const useTabMonitor = (onDuplicateTab) => {
    useEffect(() => {
        const tabId = Math.random().toString();
        let closing = false;

        // const closeSelf = (e) => {
        //     const key = e.key || '';
        //     const id = key.split(':').pop();

        //     if (id === tabId && e.newValue === 'close') {
        //         localStorage.removeItem(key);
        //         closing = true;
        //         onDuplicateTab();
        //         setTimeout(() => window.close(), 2000); // Close after 2 seconds
        //     }
        // };

        const handleStorageChange = (e) => {
            const key = e.key || '';
            const id = key.split(':').pop();

            if (id !== tabId && e.newValue === 'open') {
                onDuplicateTab();
                window.close();
                localStorage.setItem(key, 'close');
            }
        };

        const keepOneTab = () => {
            localStorage.setItem(`@tab:${tabId}`, 'open');

            // window.addEventListener('storage', closeSelf);

            window.addEventListener('beforeunload', () => {
                localStorage.removeItem(`@tab:${tabId}`);
            });

            setTimeout(() => {
                if (closing) {
                    return;
                }

                // window.removeEventListener('storage', closeSelf);
                window.addEventListener('storage', handleStorageChange);
            }, 500);
        };

        keepOneTab();

        return () => {
            localStorage.removeItem(`@tab:${tabId}`);
            // window.removeEventListener('storage', closeSelf);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [onDuplicateTab]);
};

export default useTabMonitor;
