import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DateTime from "../../common/DateTime";
import { _Api } from "../../api/_call";
import {
  LocationList,
  SupplierList,
  VoucherList,
} from "../../common/graphql/commonlayouts";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { Tablehead } from "../../common/modules/Tablehead";
import Advancedfiltermodel from "../../common/modules/advanced-filter-model";
import NotesModalLabel from "../../common/modules/NotesModalLabel";
import { calDuedate } from "../../common/helpers/formatDate";
import Select from "react-select";
import { toFormatPrice, toUnformatPrice } from "../../common/helpers/function";
import { success, error, loading, Updateloading } from "../../common/helpers/toastify";
import { createpotablehead } from "../../common/Tablehead";
import TransAndDeliverDate from "../../common/modules/TransAndDeliverDate";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
const CreatePO = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [form_note, setForm_note] = useState(null);
  const [commanSelected_loc, setcommanSelected_loc] = useState(null);
  const [commanSelected_sup, setcommanSelected_sup] = useState(null);
  const [commanSelected_vouc, setcommanSelected_vouc] = useState(null);
  const [PO_OrdersList, setPO_OrdersList] = useState([]);
  const [TransactionDate, setTransactionDate] = useState(new Date());
  const [DeliveryDate, setDeliveryDate] = useState();
  const [newcolumndata, setnewcolumndata] = useState(createpotablehead);
  const [selecteditem, setSelecteditem] = useState([]);
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState('');
  // const [pos, setPos] = useState()
  const getdeliverydate = async () => {
    setDeliveryDate(await calDuedate(new Date()));
  }
  useEffect(() => {
    getdeliverydate();
  }, []);

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  let mapChange_handle = (i, value, type) => {
    let data = [...PO_OrdersList];
    data[i][type] = value;
    setPO_OrdersList(data);
  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_po_createpo",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const po_create_submit = async () => {
    try {
      if (!commanSelected_vouc) {
        error(t("Please select voucher"));
        return;
      }
      if (!commanSelected_loc) {
        error(t("Please select location"));
        return;
      }
      if (!TransactionDate) {
        error(t("Please select Transaction Date"));
        return;
      }
      if (!DeliveryDate) {
        error(t("Please select Delivery Date"));
        return;
      }
      var checkedValue = document.querySelectorAll(".PO_OrdersList:checked");
      var product_data = [];
      var posdata;
      checkedValue.forEach(function (result) {
        var i = result.getAttribute("data-key");
        var product_details = PO_OrdersList[i]["product_details"];
        product_data.push({
          product_id: product_details["product_id"],
          oldStock_id: "",
          customer_id: PO_OrdersList[i]["Customer_id"],
          so_no: PO_OrdersList[i]["So_No"],
          po_QTY: parseInt(PO_OrdersList[i]["Qty"]),
          price: toFormatPrice(PO_OrdersList[i]["price"]),
          amount_total: toFormatPrice(PO_OrdersList[i]["price"] * parseInt(PO_OrdersList[i]["Qty"])),
          sales_order_id: PO_OrdersList[i]["id"],
          remark: "",
          Style: product_details["Style"],
          Metal: product_details["Metal"],
          Color: product_details["Color"],
          Size: product_details["Size"],
          Unit: product_details["Unit"],
          GrossWt: product_details["GrossWt"],
          NetWt: product_details["NetWt"],
          Stone: product_details["Stone"],
          StoneGroup: product_details["StoneGroup"],
          StoneVariant: product_details["StoneVariant"],
          StoneShape: product_details["StoneShape"],
          Pcs: product_details["Pcs"],
          WeightType: product_details["WeightType"],
          StoneCut: product_details["StoneCut"],
          StoneClarity: product_details["StoneClarity"],
          StoneColor: product_details["StoneColor"],
          stoneSize: product_details["stoneSize"],
          certificate: product_details["certificate"],
          Certificatetype: product_details["Certificatetype"],
          certificateNo: product_details["certificateNo"],
          Weight: product_details["Weight"],
          Stones: product_details["Stones"],
          pos: PO_OrdersList[i]["pos"]
        });
        posdata = PO_OrdersList[i]["pos"]
      });
      if (!product_data.length) {
        error(t("No item Selected!!"));
        return;
      }
      const postdata = {
        voucher_id: commanSelected_vouc,
        location_id: commanSelected_loc,
        supplier_id: commanSelected_sup,
        delivery_date: DeliveryDate,
        transaction_date: TransactionDate,
        note: form_note,
        type: "POS_Order",
        po_data: product_data,
        RefNo: '',
        pos: posdata
      };
      let resPoOrders = await _Api(postdata, `api/v1/Inventory/po/createPO`);
      if (resPoOrders.code === 200) {
        success(t("Added successfully!!"));
        const timer = setTimeout(() => {
          navigate("/app/inventory");
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        error(resPoOrders.errors);
      }
    } catch (err) { }
  };
  const posorders_idshave = useCallback(async () => {
    UnSetdataTable("tbl_pos_order_table");
    try {
      const postdata = {
        "cart_id": state ? state?.createlist : '',
        "ship_to": commanSelected_loc ? commanSelected_loc : state ? state?.hidelocationid : ''
      };
      let resPoOrders = await _Api(postdata, `api/v1/Inventory/po/getPOSDetails`);
      if (resPoOrders.code === 200) {
        SetdataTable("tbl_pos_order_table", "6", newcolumndata);
        setPO_OrdersList(resPoOrders?.data);
        resPoOrders.data.forEach((result, key) => {
          setcommanSelected_loc(result?.location_id);
        })

      } else {
        SetdataTable("tbl_pos_order_table", "6", newcolumndata);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [newcolumndata, state, commanSelected_loc]);
  const filterbyname = (search) => {
    if (filtername) {
      if (!state) {
        posorders_idshave(search);
      } else {
        posorders_idshave(search);
      }
    } else {
      if (!state) {
        posorders_idshave();
      } else {
        posorders_idshave();
      }
    }
  };

  useEffect(() => {
    if (!state) {
      if (commanSelected_loc && commanSelected_sup) {
        posorders_idshave();
      }
    } else {
      posorders_idshave();
    }
  }, [commanSelected_loc, commanSelected_sup, state, posorders_idshave]);
  useEffect(() => {
    callcolumns();
  }, []);
  const deleteProduct = () => {
    const selected = document.querySelectorAll(".PO_OrdersList");
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let product_id = selected[i].getAttribute('data-id');
        setPO_OrdersList((current) =>
          current.filter((item) => item?.id !== product_id)
        );
        setSelecteditem((current) =>
          current.filter((item) => item !== product_id)
        );
        selected[i].checked = false;
      }
    }
    UnSetdataTable('tbl_pos_order_table');
    setTimeout(() => {
      SetdataTable("tbl_pos_order_table", "4", newcolumndata);
    }, 100);
  }
  const selectedproduct = (event) => {
    if (event.target.checked) {
      const mainselected = [...selecteditem, event.target.value];
      setSelecteditem(mainselected);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  }
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".PO_OrdersList");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('value');
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);
  const downloadexclsheet = async () => {
    const handleValidation = (e) => {
      let formIsValid = true
      if (!commanSelected_loc) {
        formIsValid = false
        error(t("Please select location"));
        return;
      }
      return formIsValid
    }
    if (handleValidation()) {
      let GetloadingID = loading();
      try {
        let postdata = {
          "ship_to": commanSelected_loc,
        }
        let res = await _Api(postdata, "api/v1/Inventory/po/getPOSOrderListExportByLocation")
        if (res?.code === 200) {
          Updateloading(t, GetloadingID, (res?.message));
        } else {
          Updateloading(t, GetloadingID, (res.errors), 'error');
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }
  }

  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-body-current-day-time">
                <DateTime></DateTime>
              </div>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link to="/app/inventory" className="page-back-btn">
                    <img
                      src={base_url + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </Link>
                  <img src={base_url + "images/icons/home-icon.png"} alt="" />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Purchase_order"}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center">
                  {t("Create Purchase Order")}
                </h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div
                  className="main-body-top-tab-bar-left-col nav nav-pills"
                  role="tablist"
                >
                  <Link
                    to="/app/inventory/createpo"
                    className="main-body-top-tab-bar-left-col-a nav-link active tab-hover table-refresh"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        className="tab-hover-img-1"
                        src={base_url + "images/icons/price-list-icon.png"}
                        alt=""
                      />
                      <img
                        className="tab-hover-img-2"
                        src={
                          base_url + "images/icons/price-list-icon-green.png"
                        }
                        alt=""
                      />
                      {t("POS Order")}
                    </span>
                  </Link>
                  {state ? !state.createlist ?
                    <Link
                      to="/app/inventory/createpoReplenishOrder"
                      className="main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh"
                    >
                      <span className="main-body-top-tab-bar-left-col-a-text">
                        <img
                          className="tab-hover-img-1"
                          src={base_url + "images/icons/replenish-icon.png"}
                          alt=""
                        />
                        <img
                          className="tab-hover-img-2"
                          src={base_url + "images/icons/replenish-icon-green.png"}
                          alt=""
                        />
                        {t("Replenish Order")}
                      </span>
                    </Link>
                    : '' : <Link
                      to="/app/inventory/createpoReplenishOrder"
                      className="main-body-top-tab-bar-left-col-a nav-link tab-hover table-refresh"
                    >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        className="tab-hover-img-1"
                        src={base_url + "images/icons/replenish-icon.png"}
                        alt=""
                      />
                      <img
                        className="tab-hover-img-2"
                        src={base_url + "images/icons/replenish-icon-green.png"}
                        alt=""
                      />
                      {t("Replenish Order")}
                    </span>
                  </Link>
                  }

                </div>
              </div>
              <div className="tab-content">
                <div id="pos_order" className="tab-pane active">
                  <div className="main-body-top-inputs-bar">
                    <div className="main-body-top-inputs-bar-col-left">
                      <label className="po-num-label  po-num-red">{t("po-xxx")}</label>
                      <LocationList
                        setcommanSelected_loc={setcommanSelected_loc}
                        addclass={state?.hidelocationid ? 'row_inactive' : ""} locationid={state ? state?.hidelocationid : ''}
                      />
                      <SupplierList
                        setcommanSelected_sup={setcommanSelected_sup}
                      />
                      <TransAndDeliverDate TransactionDate={TransactionDate} DeliveryDate={DeliveryDate} setTransactionDate={setTransactionDate} setDeliveryDate={setDeliveryDate} />
                    </div>
                    <div className="main-body-top-inputs-bar-col-right">
                      <button
                        className="main-body-top-inputs-bar-col-right-submit-btn com-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#createPurchaseModal"
                      >
                        {t("Submit")}
                      </button>
                    </div>
                  </div>
                  <div className="main-body-top-status-bar">
                    <Select
                      className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                      options={selectOptions}
                      value={{ label: limit, value: limit }}
                      onChange={(e) => { setLimit(e.value); posorders_idshave(e.value) }}
                      isSearchable={false}
                    />
                    <div className="main-body-top-status-bar-filter">
                      <input
                        type="text"
                        value={filtername}
                        className="main-body-top-status-bar-filter-input"
                        placeholder={t("TF No./StockID/SKU/Metal/Stone")}
                        onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                        onChange={(e) => setFiltername(e.target.value)}
                      />
                      {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                      <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                        <i className="fa fa-search" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="main-body-top-status-bar-multi-date-picker">
                      <button className="print-export-dropdown-selected-icon d-none">
                        <img
                          src={base_url + "images/icons/printer-icon.png"}
                          alt=""
                        />
                      </button>
                      <div className="print-export-dropdown-selected-icon">
                        <button className={
                          selecteditem.length > 0
                            ? "deletebtn btn d-block"
                            : "deletebtn btn d-none"
                        } onClick={deleteProduct}>
                          <img src={base_url + "images/icons/delete-icon.png"} alt="" />
                        </button>
                      </div>
                      <div
                        className="main-body-top-status-bar-note-button"
                        data-bs-toggle="modal"
                        data-bs-target="#notesModal"
                      >
                        <img
                          src={base_url + "images/icons/comment-icon.png"}
                          alt=""
                        />
                        {t("note")}
                      </div>
                      <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              base_url + "images/icons/ellipsis-circular.png"
                            }
                            alt=""
                          />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                            <div className="dropdown-item-img">
                              <img
                                src={
                                  base_url + "images/icons/export-x-icon.png"
                                }
                                alt=""
                              />
                            </div>
                            <span>{t("export")}</span>
                          </li>
                          <li
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#columnModal"
                          >
                            <div className="dropdown-item-img">
                              <img
                                src={base_url + "images/icons/column-icon.png"}
                                alt=""
                              />
                            </div>
                            <span>{t("column")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="common-sub-table-wrap add-row position-relative overflow-auto">
                    <div className="main-body-main-table-wrap">
                      <table
                        id="tbl_pos_order_table"
                        className="common-table product-base-table w-100"
                      >
                        <Tablehead
                          tablehead={createpotablehead}
                          tblName={"tbl_po_createpo"}
                          setfilterby={""}
                          setShowoption={setSelectAllStatus}
                        />
                        <tbody>
                          {PO_OrdersList?.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="com-check ">
                                    <input
                                      id={"po_create_" + index}
                                      type="checkbox"
                                      data-key={index}
                                      onChange={selectedproduct}
                                      data-id={result?.id}
                                      value={result?.pos?.cart_id}
                                      className="PO_OrdersList posordertableHead"
                                    />
                                    <label
                                      htmlFor={"po_create_" + index}
                                      className="com-check-label"
                                    />
                                  </div>
                                </td>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="table-pro-img">
                                    <img
                                      src={result?.product_details?.main_image}
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td className="po-no">
                                  {result?.product_details?.SKU}
                                </td>
                                <td> {result?.createdAt}</td>
                                <td className="po-no danger-text cursor-ponter">
                                  {result.pos.ref}
                                </td>
                                <td className="col-highlight">{result?.stock_id}</td>
                                <td className="col-highlight">
                                  {result?.product_details?.metal_name}
                                </td>
                                <td className="col-highlight">
                                  {result?.product_details?.stone_name}
                                </td>
                                <td className="col-highlight">
                                  {result?.product_details?.size_name}
                                </td>
                                {state?.createlist ?
                                  <td className="col-highlight">
                                    {result?.Qty}
                                  </td> :
                                  <td className="col-highlight">
                                    <div className="col-input">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          mapChange_handle(
                                            index,
                                            e.target.value,
                                            "Qty"
                                          )
                                        }
                                        name="Qty"
                                        defaultValue={result.Qty}
                                      />
                                    </div>
                                  </td>
                                }
                                <td className="col-highlight">
                                  <div className="col-input">
                                    <input
                                      className="col-width"
                                      type="text"
                                      name="price"
                                      onChange={(e) =>
                                        mapChange_handle(
                                          index,
                                          e.target.value,
                                          "price"
                                        )
                                      }
                                      defaultValue={toFormatPrice(result.price)}
                                    />
                                  </div>
                                </td>
                                <td className="col-highlight">
                                  {" "}
                                  {toFormatPrice(
                                    parseInt(result.Qty) *
                                    parseFloat(toUnformatPrice(result.price, { addSymbol: true }))
                                  )}
                                </td>
                                <td className="danger-text col-highlight">
                                  {result.location_name}
                                </td>
                                <td className="danger-text col-highlight">
                                  {result.customer_name}
                                </td>
                                <td className="danger-text col-highlight">
                                  {result.delivery_date}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td className="main-green-text left-0">
                              {selecteditem.length > 0 ? (
                                <span> {selecteditem.length} {t("item selected")}</span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>
                              {PO_OrdersList?.reduce(
                                (a, v) => (a = a + parseInt(v.Qty)),
                                0
                              )}
                            </td>
                            <td />
                            <td>
                              {toFormatPrice(
                                PO_OrdersList?.reduce(
                                  (a, v) =>
                                  (a =
                                    a +
                                    parseInt(v.Qty) *
                                    parseFloat(toUnformatPrice(v.price))),
                                  0
                                )
                                , { addSymbol: true })}
                            </td>
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
      </div>
      <TableColumnModal
        tablehead={createpotablehead}
        tblName={"tbl_po_createpo"}
        setnewcolumndata={setnewcolumndata}
        calldatatable={posorders_idshave}
      ></TableColumnModal>
      <ConfirmationModel statement={t("Create Purchase Order")} handler_submit={po_create_submit}></ConfirmationModel>
      <NotesModalLabel setForm_note={setForm_note}></NotesModalLabel>
      <Advancedfiltermodel></Advancedfiltermodel>
    </React.Fragment>
  );
};
export default CreatePO;
