import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { _Api } from "../../common/api/_call";
import { error } from '../../common/helpers/toastify';
const TableModal = (props) => {
  const { t } = useTranslation();
  const { tablehead, tblName, setnewcolumndata } = props;
  const [newcolumndata_, setnewcolumndata_] = useState(tablehead);

  const handle_apply = async () => {
    try {
      const postdata = {
        name: tblName,
        data: newcolumndata_,
      };
      let resPoOrders = await _Api(
        postdata,
        `api/v1/Inventory/common/saveColumnHead`
      );
      if (resPoOrders.code === 200) {
        setnewcolumndata(newcolumndata_);
      } else {
        error(resPoOrders.errors);
      }
    } catch (err) { }
  };
  useEffect(() => { }, [])
  const userSelectedAnswer = (Qindex) => {
    let newcolumndata_data = [...newcolumndata_];
    var check_checked = document.querySelector("#tableth" + Qindex).checked;
    newcolumndata_data[Qindex].status = check_checked;
    setnewcolumndata_(newcolumndata_data);
  };
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;

  return (
    <div>
      <div
        className="modal fade column-modal"
        id="columnModal"
        tabIndex={-1}
        aria-labelledby="columnModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="columnModalLabel">
                <img
                  className="sku-col-arrow"
                  src={base_assets + "images/icons/green-column.png"}
                  alt=""
                />
                {t("Column")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="column-modal-search-area" data-bs-dismiss="modal"
              >
                <button className="ms-auto column-modal-search-area-apply"
                  onClick={handle_apply}>
                  {t("apply")}
                </button>
              </div>
              <div className="column-modal-checkbox-area">
                {newcolumndata_?.map((result, key) => {
                  return (
                    <div className="column-modal-checkbox-area-check" key={key}>
                      <div className="com-check">
                        <input type="checkbox"
                          onChange={(e) => userSelectedAnswer(key)}
                          id={"tableth" + key}
                          checked={result?.status} />
                        <label
                          htmlFor={"tableth" + key}
                          className="com-check-label"
                        />
                      </div>
                      <label htmlFor={"tableth" + key}>
                        {result?.name ? t(result?.name) : "columnname"}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableModal;
