
// myinventory head
export const inventorytableHead = [
  {
    lable: "",
    name: "check_box",
    class: "inventorytableHead_main",
    status: true,
  },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "fixed-side", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "", name: "", class: "", status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "Aging", name: "Aging", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "text-center", sort: 1, status: true },
  {
    lable: "POS Order",
    name: "POS Order",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "DueDays",
    class: "text-center",
    sort: 1,
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", status: true },
];
//  reserve modal head
export const reservehead = [
  {
    lable: "check_box",
    name: "check_box",
    class: "reservehead_main",
    status: true,
  },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  {
    lable: "Product name",
    name: "product name",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", status: true },
];
// posorder head
export const posordertableHead = [
  {
    lable: "",
    class: "posordertableHead_main",
    sort: 0,
    status: true,
  },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "fixed-side", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "POS No.",
    name: "POS No",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", status: true },
  { lable: "Stone", name: "Stone", class: "", status: true },
  { lable: "Size", name: "Size", class: "", status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true },
  { lable: "Priority", name: "Priority", class: "", sort: 1, status: true },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "DueDays",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", status: true },
  {
    lable: "Design",
    name: "Design",
    class: "",
    status: true,
  },
];
// po pos head
export const posordermaintableHead = [
  {
    status: true,
  },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "fixed-side", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "POS No.",
    name: "POS No",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", status: true },
  { lable: "Stone", name: "Stone", class: "", status: true },
  { lable: "Size", name: "Size", class: "", status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true },
  { lable: "Priority", name: "Priority", class: "", sort: 1, status: true },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "DueDays",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", status: true },
  {
    lable: "Design",
    name: "Design",
    class: "",
    status: true,
  },
];
// reservelist head
export const reservelisthead = [
  {
    lable: "",
    name: "check_box",
    class: "reservelisthead_main ",
    status: true,
  },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Img", name: "Img", class: "fixed-side", sort: 0, status: true },
  {
    lable: "Transaction Date",
    name: "Transaction Date",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "", name: "", class: "", status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  {
    lable: "POS Order",
    name: "POS Order",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "DueDays",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Sales person",
    name: "Sales person",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", sort: 1, status: true },
  { lable: "Design", name: "Design", class: "", sort: 1, status: true },
];

//zone idnex head 
export const zoneIndexhead = [
  {
    lable: "",
    name: "check_box",
    class: "zoneIndex_main",
    status: true,
  },
  { lable: "Ref No", name: "Ref No.", status: true, sort: 0 },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Zone", name: "Zone", class: "", sort: 1, status: true },
  { lable: "Sale Person", name: "Sale Person", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Issue Date", name: "Issue Date", class: "", sort: 1, status: true },
  { lable: "Return Date", name: "Return Date", class: "", sort: 1, status: true },
  { lable: "Issue", name: "Issue", class: "issuehead", sort: 0, status: true },
  { lable: "Sold", name: "Sold", class: "soldhead", status: true },
  { lable: "Return", name: "Return", class: "returnhead", status: true },
  { lable: "Balance", name: "Balance", class: "balancehead", status: true },
  { lable: "Status", name: "Status", class: "", status: true },
  { lable: "User", name: "User", class: "", status: true },

];

//zone add inventory table
export const zoneAddInventory = [
  {
    lable: "",
    name: "check_box",
    class: "zone_addinventory",
    status: true,
  },
  { lable: "#", name: "#", status: true, sort: 0 },
  { lable: "Image", name: "Image", class: "", status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "QTY", name: "QTY", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", status: true },

];

//zone create and edit head 
export const zonEditCreateHeader = [
  {
    lable: "",
    name: "check_box",
    class: "zoneIndex_main",
    status: true,
  },
  { lable: "#", name: "#", status: true, sort: 0 },
  { lable: "Image", name: "Image", class: "", status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Issue", name: "Issue", class: "issuehead", sort: 0, status: true },
  { lable: "Sold", name: "Sold", class: "soldhead", status: true },
  { lable: "Return", name: "Return", class: "returnhead", status: true },
  { lable: "Balance", name: "Balance", class: "balancehead", status: true },
  { lable: "Reason", name: "Reason", class: "Reasons", status: true },

];

export const tbl_allocation_table = [
  {
    lable: "",
    name: "check_box",
    class: "editreplenish_all_main",
    status: true,
  },
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "img.", name: "img.", class: "", status: true },
  {
    lable: "Product Name",
    name: "Product Name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", status: true },
  { lable: "Stone", name: "Stone", class: "", status: true },
  { lable: "Size", name: "Size", class: "", status: true },
  {
    lable: "Stock",
    name: "Stock",
    class: "light-blue-text",
    status: true,
  },
  {
    lable: "Sale Order",
    name: "Sale Order",
    class: "danger-text",
    status: true,
  },
  {
    lable: "Available",
    name: "Available",
    class: "main-green-text",
    status: true,
  },
  {
    lable: "Reorder Point",
    name: "Reorder Point",
    class: "yellow-text",
    status: true,
  },
  {
    lable: "Reorder Qty",
    name: "Reorder Qty",
    class: "status-green-text",
    status: true,
  },
  { lable: "Qty", name: "Qty", class: "gray-text", status: true },
];
// create replenishtable
export const tbl_createreplenishHead = [
  {
    lable: "",
    name: "check_box",
    class: "createreplenish_main",
    status: true,
  },
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "img.", name: "img.", class: "", status: true, sort: 0 },
  {
    lable: "Product Name",
    name: "Product name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", status: true },
  { lable: "Stone", name: "Stone", class: "", status: true },
  { lable: "Size", name: "Size", class: "", status: true },
  {
    lable: "Stock",
    name: "Stock",
    class: "light-blue-text",
    status: true,
  },
  {
    lable: "Sale Order",
    name: "Sale Order",
    class: "danger-text",
    status: true,
  },
  {
    lable: "Available",
    name: "Available",
    class: "main-green-text",
    status: true,
  },
  {
    lable: "Reorder Point",
    name: "Reorder Point",
    class: "yellow-text",
    status: true,
  },
  {
    lable: "Reorder Qty",
    name: "Reorder Qty",
    class: "status-green-text",
    status: true,
  },
  { lable: "Qty", name: "Qty", class: "gray-text", status: true },
];
export const POSOrdertable = [
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "img.", name: "img.", class: "", sort: 0, status: true },
  {
    lable: "Product_name",
    name: "Product name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  {
    lable: "Stock",
    name: "Stock",
    class: "light-blue-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Sale_Order",
    name: "Sale Order",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Available",
    name: "Available",
    class: "main-green-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Reorder_Point",
    name: "Reorder Point",
    class: "yellow-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Reorder_Qty",
    name: "Reorder Qty",
    class: "status-green-text",
    sort: 1,
    status: true,
  },
  { lable: "Qty", name: "Qty", class: "gray-text", sort: 1, status: true },
];

// edit po
export const EditpopostableHead = [
  {
    lable: "",
    name: "check_box",
    class: "EditpopostableHead_main",
    status: true,
  },
  { lable: "#", name: "#", class: "", status: true, sort: 0 },
  { lable: "img.", name: "img.", class: "", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  {
    lable: "POS No",
    name: "POS No",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "Due Days",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", status: true },
  { lable: "Design", name: "Design", class: "", status: true },
];

export const AllocationtableHead = [
  { lable: "", name: "", class: "col-highlight", sort: 0, status: true },
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "img.", name: "img", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "PO No.", name: "PO No.", class: "", sort: 1, status: true },
  { lable: "", name: "", class: "col-highlight", sort: 0, status: true },
  {
    lable: "Stock ID",
    name: "Stock ID",
    class: "",
    sort: 0,
    status: true,
  },
  { lable: "Metal", name: "Metal", class: "", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", sort: 0, status: true },
  { lable: "Qty", name: "Qty", class: "gray-text", sort: 1, status: true },
  {
    lable: "Priority",
    name: "Priority",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Due Days", name: "Due Days", class: "danger-text", sort: 1, status: true },
];

export const TransfertableHead = [
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "TF_No", name: "TF No", class: "", sort: 1, status: true },
  {
    lable: "From",
    name: "From",
    class: "light-blue-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Ship to",
    name: "Ship_to",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "gray-text", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  { lable: "Due Days", name: "Due Days", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  {
    lable: "status",
    name: "status",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Priority",
    name: "Priority",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Remark",
    name: "Remark",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Created",
    name: "Created",
    class: "gray-text",
    sort: 1,
    status: true,
  },
];
// edit purchase 
export const Editpurchasetablehead = [
  { lable: "check_box", name: "check_box", class: "editpurchasedata_main ", status: true },
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "img.", name: "img.", class: "", status: true, sort: 0 },
  {
    lable: "Product_name",
    name: "Product name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock Id", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", status: true },
  { lable: "Stone", name: "Stone", class: "", status: true },
  { lable: "Size", name: "Size", class: "", status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "", name: "", class: "", status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Tax", name: "Tax %", class: "", status: true },
  {
    lable: "Retail Price",
    name: "Retail Price",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  {
    lable: "POS No",
    name: "POS No",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery_Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Due_Days",
    name: "Due Days",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", status: true },
  { lable: "Design", name: "Design", class: "", status: true },
];


export const PurchasetableHead = [
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "PU_No", name: "PU No", class: "", sort: 1, status: true },
  { lable: "PO_No", name: "PO No", class: "", sort: 1, status: true },
  { lable: "Supplier", name: "Supplier", class: "", sort: 1, status: true },
  {
    lable: "Ship_to",
    name: "Ship_to",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "gray-text", sort: 1, status: true },
  {
    lable: "Delivery_Date",
    name: "Delivery Date",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  { lable: "Due_Days", name: "Due Days", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  {
    lable: "status",
    name: "status",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Priority",
    name: "Priority",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Remark",
    name: "Remark",
    class: "gray-text",
    sort: 1,
    status: true,
  },
];
// po list
export const polisttablehead = [
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "PO No.", name: "PO No.", class: "", sort: 1, status: true },
  { lable: "Supplier", name: "Supplier", class: "", sort: 1, status: true },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  // { status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "gray-text",
    sort: 1,
    status: true,
  },
  { lable: "Due Days", name: "Due Days", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Status", name: "status", class: "", sort: 1, status: true },

  { lable: "Priority", name: "Priority", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", sort: 0, status: true },
  { lable: "Created", name: "Created", class: "", sort: 0, status: true },
  { lable: "Modified", name: "Modified", class: "", sort: 0, status: true },
];

// stock recevie
export const stockrecevietablehead = [
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  {
    lable: "Receive Date",
    name: "Receive Date",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "TF No.", name: "TF No.", class: "", sort: 1, status: true },
  { lable: "From", name: "From", class: "danger-text", sort: 1, status: true },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", sort: 0, status: true },
  { lable: "Created", name: "Created", class: "", sort: 0, status: true },
  { lable: "Modified", name: "Modified", class: "", sort: 0, status: true },
];
// allocationlist

export const allocationlisttablehead = [
  { status: true },
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "Img", name: "img", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "PO No.", name: "PO No", class: "", sort: 1, status: true },
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "POS No.", name: "POS No", class: "danger-text", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "danger-text", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Due Days", name: "Due Days", class: "danger-text", sort: 0, status: true },
  { lable: "Ship to", name: "Ship to", class: "danger-text", sort: 0, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 0, status: true },
  { lable: "Priority", name: "Priority", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", sort: 0, status: true },
  { lable: "Design", name: "Design", class: "", sort: 0, status: true },
];
// stocktransfer
export const stocktransfertablehead = [
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "TF No.", name: "TF No.", class: "", sort: 1, status: true },
  { lable: "From", name: "From", class: "", sort: 1, status: true },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Due Days", name: "Due Days", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true },
  { lable: "Priority", name: "Priority", class: "", sort: 1, status: true },
  { lable: "AWB no.", name: "AWB no.", class: "", status: true },
  { lable: "Agent", name: "Agent", class: "", status: true },
  { lable: "Remark", name: "Remark", class: "", status: true },
  { lable: "Created", name: "Created", class: "", status: true },
  { lable: "Modified", name: "Modified", class: "", status: true },
];
// daimond reserve 
export const daimondreservetablehead = [
  { lable: "#", class: "#", status: true, sort: 0 },
  { lable: "Shape", name: "Shape", status: true },
  { lable: "Carate", name: "Carate", status: true },
  { lable: "Color", name: "Color", status: true },
  { lable: "Clarity", name: "Clarity", status: true },
  { lable: "Cut", name: "Cut", status: true },
  { lable: "Polish", name: "Polish", status: true },
  { lable: "Symmetry", name: "Symmetry", status: true },
  { lable: "Price", name: "Price", status: true },
  { lable: "Location", name: "Location", status: true },
  { lable: "Remark", name: "Remark", status: true }
]
// create transfer list
export const createtransferlist = [
  {
    lable: "",
    name: "check_box",
    class: "createtransfershead_main",
    status: true,
  },
  { lable: "#", name: "#", class: "", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "", status: true, sort: 0 },
  {
    lable: "Product Name",
    name: "Product Name",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "text-end", sort: 1, status: true },
  {
    lable: "Stock ID",
    name: "Stock ID",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Metal", name: "Metal", class: "text-end", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "text-end", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "text-end", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "text-end", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "text-end", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "text-end", sort: 1, status: true },
  {
    lable: "Location",
    name: "Location",
    class: "text-end",
    sort: 1,
    status: true,
  },
];
// edit stock transfer 
export const edittransferlisthead = [
  {
    lable: "",
    name: "check_box",
    class: "edittransfershead_main",
    status: true,
  },
  { lable: "#", name: "#", class: "", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "", status: true, sort: 0 },
  {
    lable: "Product Name",
    name: "Product Name",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "text-end", sort: 1, status: true },
  {
    lable: "Stock ID",
    name: "Stock ID",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Metal", name: "Metal", class: "text-end", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "text-end", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "text-end", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "text-end", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "text-end", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "text-end", sort: 1, status: true },
  {
    lable: "Location",
    name: "Location",
    class: "text-end",
    sort: 1,
    status: true,
  },
];

// itemsearch

export const itemsearchtableHead = [
  {
    lable: "",
    name: "",
    class: "",
    status: true,
  },
  { lable: "#", name: "#", class: "pe-0", sort: 0, status: true },
  {
    lable: "Location",
    name: "Location",
    class: "location-width text-start",
    sort: 1,
    status: true,
  },
  {
    lable: "Stock",
    name: "Stock",
    class: "col-highlight text-end",
    sort: 1,
    status: true,
  },
  {
    lable: "Reserved",
    name: "Reserved",
    class: "text-end",
    sort: 1,
    status: true,
  },
  {
    lable: "Available",
    name: "Available",
    class: "text-end",
    sort: 1,
    status: true,
  },
  { lable: "Sold", name: "Sold", class: "text-end", sort: 1, status: true },
];

// transcationhistory
export const transcationhistorytableHead = [
  {
    lable: "",
    name: "",
    class: "pe-0",
    status: true,
  },
  { lable: "#", name: "#", class: "pe-0", sort: 0, status: true },
  {
    lable: "Date",
    name: "Date",
    class: "",
    sort: 1,
    status: true,
  },
  {
    lable: "Stock ID",
    name: "Stock ID",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer Name",
    name: "Customer Name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  {
    lable: "Sale person",
    name: "Sale person",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Price", name: "Price", class: "text-end", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "text-end", sort: 1, status: true },
];
// create transfer inventory
export const createtransferinventoryhead = [
  {
    lable: "",
    name: "",
    class: "",
    status: true,
  },

  {
    lable: "#",
    name: "#",
    class: "",
    status: true,
    sort: 0
  },
  { lable: "Img", name: "Img", class: "", status: true, sort: 0 },
  {
    lable: "SKU",
    name: "SKU",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Item",
    name: "Item",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Collection",
    name: "Collection",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Metal",
    name: "Metal ",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Stone",
    name: "Stone",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Size",
    name: "Size",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Stock ID",
    name: "Stock ID",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "",
    name: "",
    class: "col-highligh",
    sort: 0,
    status: true,
  },
  {
    lable: "Qty",
    name: "Qty",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Price",
    name: "Price",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Aging",
    name: "Aging",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Location",
    name: "Location",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Status",
    name: "Status",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "POS Order",
    name: "POS Order",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "Due Days",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Remark",
    name: "Remark",
    class: "col-highligh",
    status: true,
  },
  {
    lable: "Design",
    name: "Design",
    class: "col-highligh",
    status: true,
  },
];
export const createtransferinventoryheadcheck = [

  {
    lable: "",
    name: "check_box",
    class: "createtransferinventoryhead_main",
    status: true,
    sort: 0
  },

  {
    lable: "#",
    name: "#",
    class: "",
    status: true,
    sort: 0
  },
  { lable: "Img", name: "Img", class: "", status: true, sort: 0 },
  {
    lable: "SKU",
    name: "SKU",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Item",
    name: "Item",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Collection",
    name: "Collection",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Metal",
    name: "Metal ",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Stone",
    name: "Stone",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Size",
    name: "Size",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Stock ID",
    name: "Stock ID",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "",
    name: "",
    class: "col-highligh",
    sort: 0,
    status: true,
  },
  {
    lable: "Qty",
    name: "Qty",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Price",
    name: "Price",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Aging",
    name: "Aging",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Location",
    name: "Location",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Status",
    name: "Status",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "POS Order",
    name: "POS Order",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "Due Days",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Remark",
    name: "Remark",
    class: "col-highligh",
    status: true,
  },
  {
    lable: "Design",
    name: "Design",
    class: "col-highligh",
    status: true,
  },
];
// edit transfer inventory list
export const transferinventorylisthead = [
  {
    lable: "check_box",
    name: "check_box",
    class: "inventorystocklist_main",
    status: true,
  },
  {
    lable: "#",
    name: "#",
    class: "",
    status: true,
    sort: 0
  },
  { lable: "Img", name: "Img", class: "", status: true, sort: 0 },
  {
    lable: "SKU",
    name: "SKU",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Item",
    name: "Item",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Collection",
    name: "Collection",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Metal",
    name: "Metal",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "Stone",
    name: "Stone",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Size",
    name: "Size",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Stock ID",
    name: "Stock ID",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Qty",
    name: "Qty",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Price",
    name: "Price",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Aging",
    name: "Aging",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Location",
    name: "Location",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Status",
    name: "Status",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "POS Order",
    name: "POS Order",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "Due Days",
    class: "col-highligh",
    sort: 1,
    status: true,
  },
  {
    lable: "Remark",
    name: "Remark",
    class: "col-highligh",
    status: true,
  },
  {
    lable: "Design",
    name: "Design",
    class: "col-highligh",
    status: true,
  },
];
// purchaseoutstanding
export const purchaseorderoutstandinghead = [
  {
    lable: "",
    name: "",
    class: "",
    status: true,
  },
  {
    lable: "#",
    name: "#",
    class: "",
    status: true,
    sort: 0
  },
  {
    lable: "Date",
    name: "Date ",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "PO No.", name: "PO no", class: "", sort: 1, status: true },
  {
    lable: "Supplier",
    name: "supplier",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Ship to",
    name: "ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "delivery date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "due days",
    class: "",
    sort: 1,
    status: true,
  },
  {
    lable: "Amount",
    name: "amount",
    class: "",
    sort: 1,
    status: true,
  },
  {
    lable: "Priority",
    name: "priority",
    class: "",
    sort: 1,
    status: true,
  },
];

// edit puchaseoutstanding
export const editpurchaseorderoutstandinghead = [
  {
    lable: "check_box",
    name: "check_box",
    class: "editpo_Outst_checkbox_main",
    status: true,
  },
  {
    lable: "#",
    name: "#",
    class: "",
    status: true,
    sort: 0
  },
  {
    lable: "date",
    name: "Date ",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "po_no", name: "PO no", class: "", sort: 1, status: true },
  {
    lable: "supplier",
    name: "supplier",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "ship_to",
    name: "ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "qty", name: "Qty", class: "", sort: 1, status: true },
  {
    lable: "delivery_date",
    name: "delivery date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "due_days",
    name: "due days",
    class: "",
    sort: 1,
    status: true,
  },
  {
    lable: "amount",
    name: "amount",
    class: "",
    sort: 1,
    status: true,
  },
  {
    lable: "priority",
    name: "priority",
    class: "",
    sort: 1,
    status: true,
  },
];
// create po
export const createpotablehead = [
  {
    lable: "",
    name: "check_box",
    class: "posordertableHead_main",
    status: true,
  },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "fixed-side", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  {
    lable: "POS No.",
    name: "POS No",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", status: true },
  { lable: "Stone", name: "Stone", class: "", status: true },
  { lable: "Size", name: "Size", class: "", status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
];

// create purchase
export const createpurchasetablehead = [
  {
    lable: "",
    name: "",
    class: " ",
    status: true,
  },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  {
    lable: "Date",
    name: "Date",
    class: "col-highlight",
    sort: 1,
    status: true,
  },
  { lable: "PO No", name: "PO No", class: "", sort: 1, status: true },
  { lable: "Supplier", name: "Supplier", class: "", sort: 1, status: true },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Due Days", name: "Due Days", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
];

// transcation purchase

export const transactionPurchasetablehead = [
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "PU No.", name: "PU No", class: "", sort: 1, status: true },
  { lable: "PO No.", name: "PO No", class: "", sort: 1, status: true },
  { lable: "Supplier", name: "Supplier", class: "", sort: 1, status: true },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Due Days", name: "Due Days", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true },
  { lable: "Priority", name: "Priority", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", status: true },
  { lable: "Created", name: "Created", class: "", status: true },
  { lable: "Modified", name: "Modified", class: "", status: true },
];

// outstanting orderpurchase
export const puchaseoutstandingorderstablehead = [
  { lable: "check_box", name: "check_box", class: "purchaseoutstandingorder_main ", status: true },
  { lable: "", name: "", class: "", status: true },
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "img.", name: "img.", class: "", status: true, sort: 0 },
  {
    lable: "Product Name",
    name: "Product name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", status: true },
  { lable: "Metal", name: "Metal", class: "", status: true },
  { lable: "Stone", name: "Stone", class: "", status: true },
  { lable: "Size", name: "Size", class: "", status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "", name: "", class: "", status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Tax", name: "Tax %", class: "", status: true },
  {
    lable: "Retail price",
    name: "Retail Price",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  {
    lable: "POS No.",
    name: "POS No",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Customer",
    name: "Customer",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "Due Days",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "danger-text",
    sort: 1,
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", status: true },
  { lable: "Design", name: "Design", class: "", status: true },
];

// product
export const productmaintablehead = [
  { lable: "", name: "check_box", class: " ", status: true },
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "img.", name: "img.", class: "", status: true, sort: 0 },
  {
    lable: "Product Name",
    name: "Product name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Product ID", name: "Product ID", class: "", sort: 1, status: true },
  { lable: "Type", name: "Type", class: "", status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  {
    lable: "Variant",
    name: "Variant",
    image: "images/icons/variant-sm.png",
    class: "pro-type-img",
    status: true,
  },
  {
    lable: "Reorder Point",
    name: "Reorder Point",
    class: "text-end",
    sort: 1,
    status: true,
  },
  {
    lable: "Reorder Quantity",
    name: "Reorder Quantity",
    class: "text-end",
    sort: 1,
    status: true,
  },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true },
];

// invertory create reserve

export const createreserveinventoryhead = [
  { lable: "check_box", name: "check_box", class: "createreserveinventory_main ", status: true },
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  {
    lable: "Product Name",
    name: "Product name",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", sort: 1, status: true },
];

// report reserve
export const reportreservelisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Price", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  {
    lable: "Customer",
    name: "Customer",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Due Days",
    name: "DueDays",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Sales person",
    name: "Sales person",
    class: "",
    status: true,
  },
  { lable: "Remark", name: "Remark", class: "", status: true },
  { lable: "Design", name: "Design", class: "", status: true },
];

// report po
export const reportpolisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "PO no.", name: "PO no.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Price", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "text-center",
    sort: 1,
    status: true,
  },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  {
    lable: "Customer",
    name: "Customer",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Status",
    name: "Status",
    class: "text-center",
    sort: 1,
    status: true,
  },
  { lable: "Created", name: "Created", class: "", status: true },
  { lable: "Modified", name: "Modified", class: "", status: true },
];

// report purchase
export const reportpurchaselisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "PU No.", name: "PU no.", class: "", sort: 1, status: true },
  { lable: "PO No.", name: "PO no.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Price", class: "", sort: 1, status: true },
  { lable: "Supplier", name: "Supplier", class: "", sort: 1, status: true },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Status",
    name: "Status",
    class: "text-center",
    sort: 1,
    status: true,
  },
  { lable: "Created", name: "Created", class: "", status: true },
  { lable: "Modified", name: "Modified", class: "", status: true },
];

// report stock transfer
export const reportstocktransferlisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "TF No.", name: "TF no.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Price", class: "", sort: 1, status: true },
  { lable: "From", name: "From", class: "", sort: 1, status: true },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Status",
    name: "Status",
    class: "text-center",
    sort: 1,
    status: true,
  },
  { lable: "Created", name: "Created", class: "", status: true, sort: 0 },
  { lable: "Modified", name: "Modified", class: "", status: true, sort: 0 },
];
// report stock receive
export const reportstockreceivelisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  {
    lable: "Receive Date",
    name: " Receive Date",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "TF No.", name: "TF no.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Price", class: "", sort: 1, status: true },
  { lable: "From", name: "From", class: "", sort: 1, status: true },
  { lable: "Ship to", name: "Ship to", class: "", sort: 1, status: true },
  {
    lable: "Delivery Date",
    name: "Delivery Date",
    class: "text-center",
    sort: 1,
    status: true,
  },
  {
    lable: "Status",
    name: "Status",
    class: "text-center",
    sort: 1,
    status: true,
  },
  { lable: "Created", name: "Created", class: "", status: true, sort: 0 },
  { lable: "Modified", name: "Modified", class: "", status: true, sort: 0 },
];

// report stock take
export const reportstocktakelisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref no.", class: "", sort: 1, status: true },
  { lable: "Locations", name: "Locations", class: "", sort: 1, status: true },
  {
    lable: "Total",
    name: "Total",
    class: "yellow-text",
    status: true,
    sort: 0
  },
  {
    lable: "Scanned",
    name: "Scanned",
    class: "jewelry-color",
    status: true,
    sort: 0
  },
  {
    lable: "Unscanned",
    name: "Unscanned",
    class: "stock-tale-green",
    status: true,
    sort: 0
  },
  {
    lable: "Conflict/Unknown",
    name: "Conflict/Unknown",
    class: "blue-navy",
    status: true,
    sort: 0
  },
  { lable: "User", name: "User", class: "", status: true, sort: 0 },
  { lable: "PDF", name: "PDF", class: "", status: true, sort: 0 },
];
// report lowstock
export const reportlowstocklisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref no.", class: "", sort: 1, status: true },
  { lable: "Supplier", name: "Supplier", class: "", sort: 1, status: true },
  { lable: "Store", name: "Store", class: "", sort: 1, status: true },
  { lable: "Type", name: "Type", class: "", sort: 1, status: true },
  { lable: "SKU Count", name: "SKU Count", class: "", sort: 1, status: true },
  { lable: "User", name: "User", class: "", status: true, sort: 0 },
  {
    lable: "Stock",
    name: "Stock",
    class: "light-blue-text",
    status: true,
    sort: 0
  },
  {
    lable: "Sale Order",
    name: "Sale Order",
    class: "danger-text",
    status: true,
    sort: 0
  },
  {
    lable: "Available",
    name: "Available",
    class: "main-green-text",
    status: true,
    sort: 0
  },
  {
    lable: "Reorder Point",
    name: "Reorder Point",
    class: "yellow-text",
    status: true,
    sort: 0
  },
  {
    lable: "Reorder Qty",
    name: "Reorder Qty",
    class: "status-green-text",
    status: true,
    sort: 0
  },
  { lable: "PDF", name: "PDF", class: "", status: true, sort: 0 },
];
// report vendor
export const reportvendorlisthead = [
  { lable: "#", name: "#", class: "col-highlight", status: true, sort: 0 },
  { lable: "Vendor", name: "Vendor", class: "", sort: 1, status: true },
  { lable: "Country", name: "Country", class: "", sort: 1, status: true },
  {
    lable: "Purchase Order",
    name: "Purchase Order",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Purchase", name: "Purchase", class: "", sort: 1, status: true },
];
// vendor purchase 
export const reportvendorpurchaselisthead = [
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Purchase No", name: "Purchase No", sort: 1, class: "", status: true },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true }
]
// vendor purchase order 
export const reportvendorpurchaseorderlisthead = [
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Purchase Order No", name: "Purchase Order No", sort: 1, class: "", status: true },
  {
    lable: "Ship to",
    name: "Ship to",
    class: "",
    sort: 1,
    status: true,
  },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true }
]
// inventory sku movement
export const inventoryskumovementlisthead = [
  { lable: "", name: "", class: "skumovementHead_main", status: true, sort: 0 },
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Aging", name: "Aging", class: "", sort: 1, status: true },
  { lable: "Inward QTY", name: "Inward QTY", class: "text-center", sort: 1, status: true },
  { lable: "Outward QTY", name: "Outward QTY", class: "text-center", sort: 1, status: true },
  { lable: "Closing QTY", name: "Closing QTY", class: "", sort: 1, status: true, },
  { lable: "Closing Amount", name: "Closing Amount", class: "", sort: 1, status: true, },
];

// inventory stockid movement
export const inventorystockidmovementlisthead = [
  { lable: "check_box", name: "check_box", class: "stockidmovementHead_main", status: true, sort: 0 },
  { lable: "#", name: "#", class: "col-highlight", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "", status: true, sort: 0 },
  { lable: "StockID", name: "StockID", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Aging", name: "Aging", class: "", sort: 1, status: true },
  { lable: "Inward QTY", name: "Inward QTY", class: "text-center", sort: 1, status: true, },
  { lable: "Outward QTY", name: "Outward QTY", class: "text-center", sort: 1, status: true, },
  { lable: "Closing QTY", name: "Closing QTY", class: "", sort: 1, status: true, },
  { lable: "Closing Amount", name: "Closing Amount", class: "", sort: 1, status: true, },
];

// inventory summarytbl
export const inventorySummarytableHead = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "fixed-side", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },

  { lable: "Location", name: "Location", class: "", sort: 1, status: true },

  {
    lable: "Stock",
    name: "Stock",
    class: "light-blue-text",
    status: true,
  },
  {
    lable: "Reserved",
    name: "Reserved",
    class: "text-end yellow-text",
    status: true,
  },
  {
    lable: "Available",
    name: "Available",
    class: "main-green-text",
    status: true,
  },

];
// inventory  Lowstock planning
export const inventorylowstockplanlisthead = [
  { lable: "check_box", name: "check_box", class: "lowstockHead_main", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: " ", status: true, sort: 0 },
  { lable: "Ref No", name: "Ref No", class: "", status: true, sort: 0 },
  { lable: "Warehouse", name: "Warehouse", class: "", sort: 1, status: true },
  { lable: "Store", name: "Store", class: "", sort: 1, status: true },
  { lable: "Type", name: "Type", class: "", sort: 1, status: true },
  { lable: "SKU Count", name: "SKU Count", class: "", sort: 1, status: true },
  { lable: "Stock", name: "Stock", class: "light-blue-text", sort: 0, status: true },
  { lable: "Sales Order", name: "Sales Order", class: "danger-text", sort: 0, status: true },
  { lable: "Available", name: "Available", class: "main-green-text", sort: 0, status: true },
  { lable: "Reorder Point", name: "Reorder Point", class: "yellow-text", sort: 0, status: true },
  { lable: "Reorder Qty", name: "Reorder Qty", class: "status-green-text", sort: 0, status: true },
];


// users table 
export const usersTablethead = [
  { lable: "#", name: "#", class: " ", status: true, sort: 0 },
  { lable: "Image", name: "Image", class: "", status: true, sort: 0 },
  { lable: "Name", name: "Name", class: "", sort: 0, status: true },
  { lable: "Email", name: "Email", class: "", sort: 0, status: true },
  { lable: "Phone Number", name: "Phone Number", class: "", sort: 0, status: true },
  { lable: "Position", name: "Position", class: "", sort: 0, status: true },
  { lable: "Country", name: "Country", class: "", sort: 0, status: true },
  { lable: "Role", name: "Role", class: "", sort: 0, status: true },
  { lable: "Created Date", name: "Created Date", class: "", sort: 0, status: true },
  { lable: "Last Login", name: "Last Login", class: "", sort: 0, status: true },
  { lable: "Status", name: "Status", class: "", sort: 0, status: true },
  { lable: "Action", name: "Action", class: "", sort: 0, status: true },
];


export const rolesTablethead = [
  { lable: "Role", name: "Role", class: " ", status: true, sort: 0 },
  { lable: "Description", name: "Description", class: " ", status: true, sort: 0 },
  { lable: "No. Of Users", name: "No. Of Users", class: " ", status: true, sort: 0 },
  { lable: "Action", name: "Action", class: " ", status: true, sort: 0 },
];

export const rolesSubTablethead = [
  { lable: "Role Management", name: "RoleManagement", class: "text-start", status: true, sort: 0 },
  { lable: "View", name: "View", class: " ", status: true, sort: 0 },
  { lable: "Add", name: "Add", class: " ", status: true, sort: 0 },
  { lable: "Edit", name: "Edit", class: " ", status: true, sort: 0 },
  { lable: "Delete", name: "Delete", class: " ", status: true, sort: 0 },
  { lable: "Status", name: "Status", class: " ", status: true, sort: 0 },
  { lable: "Print", name: "Print", class: " ", status: true, sort: 0 },
];

export const userLogTablethead = [
  { lable: "User", name: "User", class: " ", status: true, sort: 0 },
  { lable: "Time", name: "Time", class: "", status: true, sort: 0 },
  { lable: "Type", name: "Type", class: "", status: true, sort: 0 },
  { lable: "IP Address", name: "ipAddress", class: "", status: true, sort: 0 },
  { lable: "Device", name: "Device", class: "", status: true, sort: 0 },
];

export const operationLogTablethead = [
  { lable: "Time", name: "Time", class: "", status: true, sort: 0 },
  { lable: "User", name: "User", class: " ", status: true, sort: 0 },
  { lable: "Category", name: "Category", class: "", status: true, sort: 0 },
  { lable: "Action", name: "Action", class: "", status: true, sort: 0 },
  { lable: "Operation Detail", name: "OperationDetail", class: "", status: true, sort: 0 },
  { lable: "Device", name: "Device", class: "", status: true, sort: 0 },
];






// POS Customer  table head
export const poscustomer_head = [
  { lable: "check_box", name: "check_box", class: "poscustomertablehead_main ", status: true },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Img", name: "Img", class: "fixed-side", status: true, sort: 0 },
  { lable: "Customer ID", name: "Customer Id", class: "", sort: 1, status: true },
  { lable: "Name", name: "Name", class: "", sort: 1, status: true },
  { lable: "Group", name: "Group", class: "", sort: 1, status: true },
  { lable: "Mobile number", name: "Mobile Number", class: "", sort: 1, status: true },
  { lable: "Email", name: "Email", class: "", sort: 1, status: true },
  { lable: "Last Modified Date", name: "Last Modified Date", class: "", sort: 1, status: true },
  { lable: "Action", name: "Action", class: "", status: true }
];

// POS Customer  payment history
export const poscustomerpayhistory_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "VR Type", name: "VR Type", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Oty", name: "Oty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
];
// POS Customer  purchase history
export const poscustomerpurchasehistory_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "VR Type", name: "VR Type", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Oty", name: "Oty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
];
// POS custom Order 
export const poscustomorder_head = [
  { lable: "", name: "", class: " ", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "fixed-side", sort: 1, status: true },
  { lable: "Order no.", name: "Order no.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 0, status: true },
  { lable: "Labour", name: "Labour", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Payment", name: "Payment", class: "", sort: 1, status: true },
  { lable: "Outstanding", name: "Outstanding", class: "", sort: 1, status: true },
  { lable: "Delivery Date", name: "Delivery Date ", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true }
];

//pos custom order report

export const poscustomorderreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "Order no.", name: "Order no.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 1 },
  { lable: "Price", name: "Price ", class: "", status: true, sort: 1 },
  { lable: "Labour", name: "Labour", class: "", status: true, sort: 1 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 },
  { lable: "Payment", name: "Payment", class: "", status: true, sort: 1 },
  { lable: "Outstanding", name: "Outstanding", class: "", status: true, sort: 1 },
  { lable: "Delivery Date", name: "Delivery Date", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },
  { lable: "Sales person", name: "Sales person", class: "", status: true, sort: 1 },
  { lable: "Status", name: "Status", class: "", status: true, sort: 1 },
]

// pos credit note report
export const poscreditnotereport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "VR Type", name: "Vr Type", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 1, status: true },
  { lable: "Currency", name: "Currency	", class: "", sort: 1, status: true },
  { lable: "Opening", name: "Opening", class: "", sort: 1, status: true },
  { lable: "Redeemed", name: "Redeemed ", class: "yellowcolor", sort: 1, status: true },
  { lable: "Balance", name: "Balance", class: "lightgray", status: true, sort: 1 },
  { lable: "Dr/Cr", name: "Dr/Cr ", class: "", status: true, sort: 1 },
  { lable: "Remark", name: "Remark", class: "", status: true, sort: 1 },

]


// pos gift card report
export const posgiftctadreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "VR No.", name: "VR No.", class: "fixed-side", status: true, sort: 1 },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "Gift Card name", name: "Gift Card name", class: "", sort: 1, status: true },
  { lable: "Purchase Date", name: "Purchase Date", class: "", sort: 1, status: true },
  { lable: "Last Used", name: "Last Used", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Current Balance", name: "Current Balance", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },

]

// pos dailysale report
export const posdailysalereport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "VR Type", name: "VR Type", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Gross Sale", name: "Gross Sale", class: "", sort: 1, status: true },
  { lable: "Hidden Dis", name: "Hidden Dis", class: "", status: true, sort: 1 },
  { lable: "Total Sale", name: "Total Sale ", class: "", status: true, sort: 1 },
  { lable: "Dis Amt", name: "Dis Amt", class: "", status: true, sort: 1 },
  { lable: "Labour", name: "Labour", class: "", status: true, sort: 1 },
  { lable: "Ship Charges", name: "Ship Charges", class: "", status: true, sort: 1 },
  { lable: "Sub Total", name: "Sub Total", class: "", status: true, sort: 1 },
  { lable: "Tax Amt", name: "Tax Amt", class: "", status: true, sort: 1 },
  { lable: "Net Sale", name: "Net Sale", class: "", status: true, sort: 1 },
  { lable: "Credit Note", name: "Credit Note", class: "", status: true, sort: 1 },
  { lable: "Cash Amt", name: "Cash Amt", class: "", status: true, sort: 1 },
  { lable: "Credit Amt", name: "Credit Amt", class: "", status: true, sort: 1 },
  { lable: "Bank", name: "Bank", class: "", status: true, sort: 1 },
  { lable: "Balance Due", name: "Balance Due", class: "", status: true, sort: 1 },
  { lable: "Count", name: "Count", class: "", status: true, sort: 1 },
  { lable: "Point", name: "Point", class: "", status: true, sort: 1 },
  { lable: "Sale Person", name: "Sale Person", class: "", status: true, sort: 1 },
  { lable: "User", name: "User", class: "", status: true, sort: 1 },
  { lable: "PDF", name: "PDF", class: "", status: true, sort: 1 },
]

//pos reserve report

export const posreservereport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "Order no.", name: "Order no.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 1 },
  { lable: "Price", name: "Price ", class: "", status: true, sort: 1 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 },
  { lable: "Payment", name: "Payment", class: "main-green-text", status: true, sort: 1 },
  { lable: "Outstanding", name: "Outstanding", class: "danger-text", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },
  { lable: "Sales person", name: "Sales person", class: "", status: true, sort: 1 },

]

// pos cash register report
export const posCashregisterreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Register (Outlet)", name: "Register (Outlet)", class: "fixed-side", status: true, sort: 1 },
  { lable: "Cashier", name: "Cashier", class: "", sort: 1, status: true },
  { lable: "Timed opened", name: "Timed opened", class: "", sort: 0, status: true },
  { lable: "Time closed", name: "Time closed", class: "", sort: 0, status: true },
  { lable: "Expected", name: "Expected", class: "", sort: 0, status: true },
  { lable: "Actual", name: "Actual", class: "", sort: 0, status: true },
  { lable: "Difference", name: "Difference", class: "", sort: 0, status: true },
  { lable: "PDF", name: "PDF", class: "", status: true, sort: 0 },
]

// pos customer report
export const poscustomerreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Customer ID", name: "Customer ID", class: "fixed-side", status: true, sort: 1 },
  { lable: "Customer name", name: "Customer name", class: "", sort: 1, status: true },
  { lable: "Group", name: "Group", class: "", sort: 1, status: true },
  { lable: "Mobile number", name: "Mobile number", class: "", sort: 1, status: true },
  { lable: "Email", name: "Email", class: "", sort: 1, status: true },
  { lable: "Wishlist", name: "Wishlist", class: "yellowcolor", sort: 0, status: true },
  { lable: "Custom Order", name: "Custom Order", class: "danger-text", sort: 0, status: true },
  { lable: "Repair Order", name: "Repair Order", class: "graycolor", status: true, sort: 0 },
  { lable: "Exchange / Refund", name: "Exchange / Refund", class: "", status: true, sort: 0 },
  { lable: "Sales", name: "Sales", class: "main-green-text", status: true, sort: 0 },

]
// pos customer details report
export const poscustomerdetailsall_head = [
  { lable: "Transaction", name: "Transaction", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 0, status: true },
  { lable: "Invoice / Ref no.", name: "Invoice / Ref no.", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 0, status: true },
  { lable: "StockID", name: "StockID", class: "", sort: 0, status: true },
  { lable: "Metal", name: "Metal", class: "yellowcolor", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "danger-text", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "graycolor", status: true, sort: 0 },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 0 },
  { lable: "Amount", name: "Amount", class: "main-green-text", status: true, sort: 0 },

]

export const poscustomerdetailsWishlist_head = [
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "Image", name: "Image", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 0, status: true },
  { lable: "StockID", name: "StockID", class: "", sort: 0, status: true },
  { lable: "Metal", name: "Metal", class: "yellowcolor", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "danger-text", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "graycolor", status: true, sort: 0 },
  { lable: "Price", name: "Price", class: "main-green-text", status: true, sort: 0 },

]

export const poscustomerdetailsCustomOrder_head = [
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "Invoice / Ref no.", name: "Invoice / Ref no.", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 0, status: true },
  { lable: "Metal", name: "Metal", class: "yellowcolor", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "danger-text", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "graycolor", status: true, sort: 0 },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 0 },
  { lable: "Delivery Date", name: "Delivery Date", class: "main-green-text", status: true, sort: 0 },
  { lable: "Amount", name: "Amount", class: "main-green-text", status: true, sort: 0 },
  { lable: "Sketch", name: "Sketch", class: "main-green-text", status: true, sort: 0 },
  { lable: "Picture", name: "Picture", class: "main-green-text", status: true, sort: 0 },
  { lable: "Engraving (Text)", name: "Engraving (Text)", class: "main-green-text", status: true, sort: 0 },
  { lable: "Engraving (Logo)", name: "Engraving (Logo)", class: "main-green-text", status: true, sort: 0 },
  { lable: "Remark", name: "Remark", class: "main-green-text", status: true, sort: 0 },

]
export const poscustomerdetailsrepair_order_head = [
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "Invoice / Ref no.", name: "Invoice / Ref no.", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 0, status: true },
  { lable: "Metal", name: "Metal", class: "yellowcolor", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "danger-text", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "graycolor", status: true, sort: 0 },
  { lable: "Qty", name: "Qty", class: "main-green-text", status: true, sort: 0 },
  { lable: "Amount", name: "Amount", class: "main-green-text", status: true, sort: 0 },
  { lable: "Repair list", name: "Repair list", class: "main-green-text", status: true, sort: 0 },
  { lable: "Remark", name: "Remark", class: "main-green-text", status: true, sort: 0 },

]
export const poscustomerdetailsexchange_refund_head = [
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "Invoice / Ref no.", name: "Invoice / Ref no.", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 0, status: true },
  { lable: "StockID", name: "StockID", class: "main-green-text", status: true, sort: 0 },
  { lable: "Metal", name: "Metal", class: "yellowcolor", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "danger-text", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "graycolor", status: true, sort: 0 },
  { lable: "Qty", name: "Qty", class: "main-green-text", status: true, sort: 0 },
  { lable: "Price", name: "Price", class: "main-green-text", status: true, sort: 0 },
  { lable: "Remark", name: "Remark", class: "main-green-text", status: true, sort: 0 },

]
export const poscustomerdetailssales_head = [
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "Invoice / Ref no.", name: "Invoice / Ref no.", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 0, status: true },
  { lable: "StockID", name: "StockID", class: "main-green-text", status: true, sort: 0 },
  { lable: "Metal", name: "Metal", class: "yellowcolor", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "danger-text", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "graycolor", status: true, sort: 0 },
  { lable: "Qty", name: "Qty", class: "main-green-text", status: true, sort: 0 },
  { lable: "Price", name: "Price", class: "main-green-text", status: true, sort: 0 },
  { lable: "Remark", name: "Remark", class: "main-green-text", status: true, sort: 0 },

]
// pos saleperson report
export const possalepersonreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Sales person", name: "Sales Person", class: "fixed-side", status: true, sort: 1 },
  { lable: "Country", name: "Country", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Invoice", name: "Invoice", class: "", sort: 0, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 0, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 0, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 0, status: true },

]



// pos exchange report
export const posexchangereport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "VR No.", name: "VR No.", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 1 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },
  { lable: "User", name: "User", class: "", status: true, sort: 1 },

]


// pos deposit report
export const posdepositreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "VR No.", name: "VR No.", class: "", sort: 1, status: true },
  { lable: "Transaction", name: "Transaction", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 },
  { lable: "Remark", name: "Remark", class: "", sort: 0, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "User", name: "User", class: "", status: true, sort: 1 },

]
// pos wishlist report
export const poswishlistJewelryreport_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Item", name: "Item", class: "", sort: 1, status: true },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", status: true, sort: 1 },
  { lable: "Size", name: "Size", class: "", status: true, sort: 1 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 }

]
export const poswishlistdiamondreport_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Shape", name: "Shape", class: "", sort: 1, status: true },
  { lable: "Carat", name: "Carat", class: "", sort: 1, status: true },
  { lable: "Colour", name: "Colour", class: "", sort: 1, status: true },
  { lable: "Clarity", name: "Clarity", class: "", sort: 1, status: true },
  { lable: "Cut", name: "Cut", class: "", status: true, sort: 1 },
  { lable: "Polish", name: "Polish", class: "", status: true, sort: 1 },
  { lable: "Symmetry", name: "Symmetry", class: "", status: true, sort: 1 },
  { lable: "Certificate No.", name: "Certificate No.", class: "", status: true, sort: 1 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },

]

// pos refund report
export const posrefundreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "VR No.", name: "VR No.", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", status: true, sort: 1 },
  { lable: "Metal", name: "Metal", class: "", status: true, sort: 1 },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 1 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },
  { lable: "User", name: "User", class: "", status: true, sort: 1 },

]

// pos repair report
export const posrepairreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "Order no.", name: "Order No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", status: true, sort: 1 },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 1 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "Payment", name: "Payment", class: "main-green-text ", status: true, sort: 1 },
  { lable: "Outstanding", name: "Outstanding", class: "danger-text", status: true, sort: 1 },
  { lable: "Delivery Date", name: "Delivery Date", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Customer", name: "Customer", class: "", status: true, sort: 1 },
  { lable: "Sales person", name: "Sales person", class: "", status: true, sort: 1 },
  { lable: "Status", name: "Status", class: "", status: true, sort: 1 },


]

// pos payment report
export const pospaymentreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Name", name: "Name", class: "fixed-side", status: true, sort: 0 },
  { lable: "Type", name: "Type", class: "", sort: 0, status: true },
  { lable: "Location", name: "Location", class: "", sort: 0, status: true },
  { lable: "Total Collected", name: "Total Collected", class: "", sort: 0, status: true },
  { lable: "Refund", name: "Refund", class: "", status: true, sort: 0 },
  { lable: "Net", name: "Net", class: "", status: true, sort: 0 },
]

// pos payment report
export const pospaymentdetails_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 0 },
  { lable: "Time", name: "Time", class: "", sort: 0, status: true },
  { lable: "Ref.", name: "Ref.", class: "", sort: 0, status: true },
  { lable: "Type", name: "Type", class: "", sort: 0, status: true },
  { lable: "Description", name: "Description", class: "", status: true, sort: 0 },
  { lable: "Withdraw", name: "Withdraw", class: "", status: true, sort: 0 },
  { lable: "Deposit", name: "Deposit", class: "", status: true, sort: 0 },
  { lable: "Balance", name: "Balance", class: "", status: true, sort: 0 },
  { lable: "Channel", name: "Channel", class: "", status: true, sort: 0 },
  { lable: "User", name: "User", class: "", status: true, sort: 0 },
]

// pos payment report
export const posdebitreport_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "Vr Type", name: "Vr Type", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 1, status: true },
  { lable: "Currency", name: "Currency", class: "", status: true, sort: 1 },
  { lable: "Type", name: "Type", class: "", status: true, sort: 1 },
  { lable: "Opening", name: "Opening", class: "", status: true, sort: 1 },
  { lable: "Paid", name: "Paid", class: "yellowcolor", status: true, sort: 1 },
  { lable: "Balance", name: "Balance", class: "lightgray", status: true, sort: 1 },
  { lable: "Dr/Cr", name: "Dr/Cr", class: "", status: true, sort: 1 },
]
// pos reserve
export const posreserve_head = [
  { lable: "check_box", name: "check_box", class: "pos_reservetablehead_main", status: true },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Image", name: "Image", class: "fixed-side", status: true, sort: 0 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", sort: 0, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "yellowcolor", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "lightgray", status: true, sort: 0 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Status", name: "Status", class: "", status: true, sort: 1 },

]

// pos Repair order 
export const posrepairorder_head = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref.No", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 1, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 1, status: true },
  { lable: "Size", name: "Size", class: "", sort: 1, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "lightgray", status: true, sort: 1 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 }

]

// pos Exchange  
export const posexchange_head = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref.No", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", sort: 0, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "lightgray", status: true, sort: 0 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 0 }

]
// pos Refund  
export const posrefund_head = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref.No", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", sort: 0, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "lightgray", status: true, sort: 0 },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 0 }

]
// pos Receive payment   
export const posreceivepayment_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "VR Type", name: "Vr Type", class: "", status: true, sort: 1 },
  { lable: "VR No.", name: "Vr.No", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 0 },
  { lable: "Payment", name: "Payment", class: "", sort: 1, status: true },
  { lable: "Outstanding", name: "Outstanding", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Due Date", name: "Due Date", class: "", status: true, sort: 1 },
  { lable: "Action", name: "Action", class: "", status: true, sort: 0 }

]
// pos Repair order delivered   
export const posrepairorderdelivered_head = [
  { lable: "check_box", name: "check_box", class: "pos_repairorderdelivered_main", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Order No.", name: "Order No.", class: "", status: true, sort: 1 },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "lightgray", status: true, sort: 0 },
  { lable: "Labour", name: "Labour", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 0 },
  { lable: "Payment ", name: "Payment", class: "", sort: 1, status: true },
  { lable: "Outstanding", name: "Outstanding", class: "", sort: 1, status: true },
  { lable: "Delivery Date ", name: "Delivery Date ", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Status", name: "Status", class: "", status: true, sort: 0 }

]
// pos catalog producttable  
export const poscatalogproduct_head = [
  { lable: "check_box", name: "check_box", class: "pos_catalogproduct_main", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  // { lable: "Product Name", name: "Product Name", class: "", status: true, sort: 1 },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", sort: 0, status: true },
  // { lable: "Qty", name: "Qty", class: "", status: true, sort: 0 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "", name: "", class: "", status: true, sort: 0 },
  { lable: "", name: "", class: "", status: true, sort: 0 },
  { lable: "", name: "", class: "", status: true, sort: 0 }

]
export const poscatalogproduct_head_inventary = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "", sort: 0, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  // { lable: "Product Name", name: "Product Name", class: "", status: true, sort: 1 },
  { lable: "Collection", name: "Collection", class: "", sort: 1, status: true },
  { lable: "Metal", name: "Metal", class: "", sort: 0, status: true },
  { lable: "Stone", name: "Stone", class: "", sort: 0, status: true },
  { lable: "Size", name: "Size", class: "", sort: 0, status: true },
  { lable: "Stock ID", name: "Stock ID", class: "", sort: 0, status: true },
  { lable: "Qty", name: "Qty", class: "", status: true, sort: 0 },
  { lable: "Price", name: "Price", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Status", name: "Status", class: "", status: true, sort: 1 },
  { lable: "", name: "", class: "", status: true, sort: 0 },
  { lable: "", name: "", class: "", status: true, sort: 0 },
  { lable: "", name: "", class: "", status: true, sort: 0 }

]


// pos reserveorder
export const posreserveorder_head = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "Order no.", name: "Order No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 0, status: true },
  { lable: "Labour", name: "Labour", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Payment", name: "Payment", class: "", sort: 1, status: true },
  { lable: "Outstanding", name: "Outstanding", class: "", status: true, sort: 1 },
  { lable: "Delivery Date", name: "Delivery Date", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Status", name: "status", class: "", status: true, sort: 1 },
]
// pos reserve daimond
export const posreservedaimondorder_head = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "Order no.", name: "Order No.", class: "", sort: 1, status: true },
  { lable: "Shape", name: "Shape", class: "", sort: 1, status: true },
  { lable: "StockId", name: "StockId", class: "", sort: 0, status: true },
  { lable: "Certificate", name: "Certificate", class: "", sort: 1, status: true },
  { lable: "Price", name: "Price", class: "", sort: 1, status: true },
  { lable: "Payment", name: "Payment", class: "", sort: 1, status: true },
  { lable: "Outstanding", name: "Outstanding", class: "", status: true, sort: 1 },
  { lable: "Delivery Date", name: "Delivery Date", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Status", name: "status", class: "", status: true, sort: 1 },
]

// pos reserveorder
export const posrepairorderdeliverde_head = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "Order no.", name: "Order No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 0, status: true },
  { lable: "Labour", name: "Labour", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Payment", name: "Payment", class: "", sort: 1, status: true },
  { lable: "Outstanding", name: "Outstanding", class: "", status: true, sort: 1 },
  { lable: "Delivery Date", name: "Delivery Date", class: "", status: true, sort: 1 },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Status", name: "status", class: "", status: true, sort: 1 },
]

// pos transactionhistary
export const postransactionhistary_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "Transaction", name: "Transaction", class: "", sort: 1, status: true },
  { lable: "Ref.", name: "Ref.", class: "", sort: 1, status: true },
  { lable: "Remark", name: "Remark", class: "", sort: 0, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },

]

// pos quotation   
export const posquotation_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "VR Type", name: "Vr Type", class: "", status: true, sort: 1 },
  { lable: "Quo No.", name: "Quo No.", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Action", name: "Action", class: "", status: true, sort: 0 }

]

// poswishlist customer\
export const poscustomerwishlist_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "", sort: 0, status: true },
  { lable: "Customer ID", name: "Customer ID", class: "fixed-side", status: true, sort: 0 },
  { lable: "Customer name", name: "Customer name", class: "", sort: 0, status: true },
  { lable: "Group", name: "Group", class: "", sort: 0, status: true },
  { lable: "Mobile number", name: "Mobile number", class: "", sort: 0, status: true },
  { lable: "Email", name: "Email", class: "", sort: 0, status: true }

]

// posPark
export const posPark_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "SO No.", name: "SO No.", class: "fixed-side", status: true, sort: 1 },
  { lable: "Customer ", name: "Customer ", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Status", name: "Status", class: "", sort: 1, status: true },
  { lable: "Action", name: "Action", class: "", sort: 0, status: true }
]


// pos credit note
export const poscreditnote_head = [
  { lable: "#", name: "#", class: "fixed-side", status: true, sort: 0 },
  { lable: "Date", name: "Date", class: "fixed-side", status: true, sort: 1 },
  { lable: "VR Type", name: "VR Type", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },

]

// pos quotation list
export const posquotationlist_head = [
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "VR Type", name: "Vr Type", class: "", status: true, sort: 1 },
  { lable: "Quo No.", name: "Quo No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", sort: 1, status: true },
  { lable: "Action", name: "Action", class: "", status: true, sort: 0 }

]


// pos partialpaymenthistary  
export const pospartialpaymenthistary_head = [
  { lable: "", name: "check_box", class: "pos_receivepaymenthead_main", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Date", name: "Date", class: "", sort: 1, status: true },
  { lable: "VR Type", name: "Vr Type", class: "", status: true, sort: 1 },
  { lable: "VR No.", name: "Vr.No", class: "", sort: 1, status: true },
  { lable: "Customer", name: "Customer", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 },
  { lable: "Payment", name: "Payment", class: "", sort: 1, status: true },
  { lable: "Outstanding", name: "Outstanding", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", status: true, sort: 1 },
  { lable: "Due Date", name: "Due Date", class: "", status: true, sort: 1 },

]
// pos appointment  
export const posappointment_head = [
  { lable: "", name: "", class: "", sort: 0, status: true },
  { lable: "#", name: "#", class: "fixed-side", sort: 0, status: true },
  { lable: "Image", name: "Image", class: "fixed-side", status: true, sort: 0 },
  { lable: "Name", name: "Name", class: "", sort: 1, status: true },
  { lable: "Date Time", name: "Date Time", class: "", sort: 1, status: true },
  { lable: "Ref No.", name: "Ref No.", class: "", sort: 1, status: true },
  { lable: "SKU", name: "SKU", class: "", sort: 1, status: true },
  { lable: "Location", name: "Location", class: "", sort: 1, status: true },
  { lable: "Appointment Date", name: "Appointment Date", class: "", sort: 1, status: true },
  { lable: "Qty", name: "Qty", class: "", sort: 1, status: true },
  { lable: "Amount", name: "Amount", class: "", status: true, sort: 1 },
  { lable: "Status", name: "Status", class: "", status: true, sort: 1 }

]