import React, { useState, useEffect, useCallback, useRef } from "react";
import "../master.scss"
import { _Api, graphqlPost } from "../../api/_call";
import Select from "react-select";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';


const Ecomcategory = (props) => {
    const dataFetched = useRef();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { setEditItem, dataid, type, setShowedit, create, setDataid, setCreate } = props
    const { t } = useTranslation();
    const [ecomdata, setEcomdata] = useState({ name: "", description: "", active: true })
    const [category, setCategory] = useState({ id: "", name: "NONE", parent_id: "" })
    const [file, setFile] = useState();
    const [ecomcategoriearrayList, setecomcategoriearrayList] = useState([]);
    const edititemarea = async () => {
        if (!ecomdata?.name) {
            error(t("Name is required."))
            return
        }
        if (!ecomdata?.description) {
            error(t("Description is required."))
            return
        }
        setShowedit(false)
        let GetloadingID = loading();
        const postdata = {
            "id": dataid ? dataid : "",
            "type": type ? type : "",
            "name": ecomdata?.name ? ecomdata?.name : "",
            "Status": ecomdata?.active ? "1" : "0",
            "edit": dataid ? true : false,
            "logo": file ? file : "",
            "group": category,
            "description": ecomdata?.description ? ecomdata?.description : ""
        }
        let res = await _Api(postdata, `api/v1/App/master/itemMasteredit`)
        if (res?.code === 200) {
            setEditItem(false)
            setShowedit(true)
            Updateloading(t, GetloadingID, (res?.message));
            setCreate(false)
        } else {
            Updateloading(t, GetloadingID, (res.errors), 'error');
        }
    }
    const viewmaster = async () => {
        if (create) {
            setEcomdata(existingValues => ({ ...existingValues, description: "" }))
            setEcomdata(existingValues => ({ ...existingValues, name: "" }))
            setEcomdata(existingValues => ({ ...existingValues, active: true }))
            setCategory({ id: "", name: "NONE", parent_id: "" })
            setFile("")
        } else if (dataid && type) {
            try {
                const postdata = {
                    type: type,
                    id: dataid
                }
                const res = await _Api(postdata, `api/v1/App/master/itemMasterViewById`)
                if (res?.code === 200) {
                    setFile(res?.data?.logo)
                    setEcomdata(existingValues => ({ ...existingValues, description: (res?.data?.description) }))
                    setEcomdata(existingValues => ({ ...existingValues, name: (res?.data?.name) }))
                    setEcomdata(existingValues => ({ ...existingValues, active: (res?.data?.Status === "1" ? true : false) }))
                    if (Object.keys(res?.data?.group)?.length === 0) {
                        setCategory({ id: "", name: "NONE", parent_id: "" })
                    } else {
                        setCategory(res?.data?.group)
                    }

                }
            } catch (err) {
                console.log(err, "err")
            }
        }
    }
    const onImageChange = (e) => {
        const [file] = e.target.files;
        const reader = new FileReader(file);
        reader.onloadend = async () => {
            try {
                const postdata = {
                    image: reader.result,
                    name: "profile",
                };
                let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`);
                if (resfront.code === 200) {
                    setFile(resfront?.data?.images?.url);
                }
            } catch (err) {
                console.log(err, "err");
            }
        };
        if (file) {
            reader?.readAsDataURL(file);
        }
    };
    useEffect(() => {
        if (type && (dataid || create)) {
            viewmaster();
        }
        // eslint-disable-next-line
    }, [dataid, create]);

    const getecomcategorie = useCallback(async () => {
        try {
            const postdata = `{
                ecomcategorie {
                    id
                    name 
                    parent_id
                }
            }`;
            let responce = await graphqlPost(postdata, "App/graphql");
            let ecomcategoryarray = [];
            if (responce.data?.ecomcategorie) {
                responce.data.ecomcategorie.forEach((result) => {
                    ecomcategoryarray.push({
                        name: result?.name,
                        id: result?.id,
                        parent_id: result?.parent_id
                    });
                });
            }
            setecomcategoriearrayList(ecomcategoryarray);
            dataFetched.current = false;
        } catch (err) {
            console.log(err, "err");
        }
    }, [setecomcategoriearrayList]);
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true;
        getecomcategorie();
    }, [getecomcategorie, dataid, type, create]);

    return (
        <>
            <div className="edit-item-area bg-white">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                    <div className="me-auto">
                        <label className="d-block">{t("Name")}: {ecomdata?.name ? ecomdata?.name : ""}</label>
                    </div>
                    <button className="square-btn square-btn-white btn-close focus-none" onClick={() => { setEditItem(false); setCreate(false); setDataid("") }}></button>
                    <button className="square-btn " onClick={() => edititemarea()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
                <div className="edit-item-area-body">
                    <div className="row mx-0">
                        <div className="col-md-3 ps-0">
                            <div className="edit-item-area-body-img p-2 d-flex align-items-center justify-content-center">
                                <img className="position-absolute" src={base_assets + 'images/icons/add-img-circle.png'} alt="" />
                                <label className="d-block h-100 w-100 cursor-pointer z-1" style={{ zIndex: "1" }} htmlFor="itemImgChoose">
                                    <img className={file ? "w-100 h-100 bg-white object-fit-cover" : "d-none"} src={file ? file : base_assets + 'images/icons/add-img-circle.png'} alt="" />

                                </label>
                                <input type="file" id="itemImgChoose" className="d-none"
                                    onChange={onImageChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                                <input className="v3-input-group-input w-100"
                                    value={ecomdata?.name || ''}
                                    onChange={(e) => setEcomdata(existingValues => ({ ...existingValues, name: (e.target.value) }))} />
                            </div>
                            <div className="v3-input-group mb-0">
                                <label className="v3-input-group-label">{t("Group Category")}</label>
                                <Select
                                    className="select-set select  p-0"
                                    placeholder=""
                                    options={ecomcategoriearrayList}
                                    value={category}
                                    onChange={(e) => setCategory(e)}
                                    classNamePrefix="common-select"
                                    isSearchable={false}
                                    getOptionLabel={(opt) => opt.name}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 pe-0 text-end">
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="mixmatch">{t("Active")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="mixmatch"
                                    checked={ecomdata?.active ? true : false}
                                    onChange={(e) => setEcomdata(existingValues => ({
                                        ...existingValues,
                                        active: (e.target.checked)
                                    }))} />
                            </div>
                        </div>
                        <div className="v3-input-group mb-0">
                            <label className="v3-input-group-label">{t("Description")}</label>
                            <textarea rows="3" className="v3-input-group-input w-100 h-auto"
                                value={ecomdata?.description || ''}
                                onChange={(e) => setEcomdata(existingValues => ({ ...existingValues, description: (e.target.value) }))} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ecomcategory;