import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { _Api } from '../api/_call';
import { SetdataTable, UnSetdataTable } from '../api/setdatatable';
import TableColumnModal from '../common/modules/tableColumn';
import { Tablehead } from '../common/modules/Tablehead';
import 'datatables.net-dt';
import 'datatables.net-fixedcolumns';
import DateTime from '../common/DateTime';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { itemsearchtableHead } from '../common/Tablehead';
import FileExport from '../common/fileexport';
import { error } from '../common/helpers/toastify';
import SocialModal from '../common/modules/SocialModal/SocialModal';
import { useTranslation } from 'react-i18next';
import TableLoader from '../../admin/common/TableLoader';
const ItemSearch = (props) => {
  const { t } = useTranslation()
  const [settings, setSetting] = useState()
  const [, setfilterby] = useState('')
  const [searchvalue, setSearchvalue] = useState()
  const [searchlist, setSearchlist] = useState('')
  const [transactionsku, setTransactionsku] = useState()
  const [transactionstockid, setTransactionstockid] = useState("")
  const [show, setShow] = useState(false)
  const [getsearchcontent, setGetsearchcontent] = useState()
  const [searchdetail, setSearchdetail] = useState([])
  const [detail, setDetail] = useState()
  const [selectedlocation, setSelectedlocation] = useState()
  const [selecteditems, setSelecteditems] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(itemsearchtableHead)
  const [datalodaer, setDatsLodaer] = useState(false)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const selectlocation = (event) => {
    const mainarray = [...searchdetail]
    if (event.target.checked) {
      const currentid = mainarray.filter((all) => {
        return all.location_id === event.target.id
      })
      setSelectedlocation(currentid[0]?.location_id)
      mainarray.forEach((result, key) => {
        if (result?.location_id !== currentid[0]?.location_id) {
          mainarray[key] = {
            ...mainarray[key],
            'checkboxclass': true,
          }
        } else {
          mainarray[key] = {
            ...mainarray[key],
            'checkboxclass': false,
          }
        }
      })
      setSearchdetail(mainarray)
      setSelecteditems(currentid[0].ids)
    } else {
      mainarray.forEach((result, key) => {
        mainarray[key] = {
          ...mainarray[key],
          'checkboxclass': false,
        }
      })
      setSearchdetail(mainarray)
      setSelecteditems([])
      setSelectedlocation('')
    }
  }
  const getsearchvalue = (event) => {
    var value = event.target.getAttribute('data-search')
    setSearchvalue(value)
    setShow(false)
    callsearchdetails(value)
  }
  const callsearchlist = async (event) => {
    let type = ''
    let typeforsearch = document.querySelectorAll('.typeforsearch')
    for (let i = 0; i < typeforsearch.length; i++) {
      if (typeforsearch[i].checked) {
        type = typeforsearch[i].value
      }
    }
    try {
      const postdata = {
        search: event.target.value,
        type: type,
      }
      let resSearchlist = await _Api(
        postdata,
        `api/v1/inventory/my/itemKeySearch`,
      )
      if (resSearchlist.code === 200) {
        setSearchlist(resSearchlist.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callsearchdetails = async (searchkey) => {
    let type = ''
    let typeforsearch = document.querySelectorAll('.typeforsearch')
    for (let i = 0; i < typeforsearch.length; i++) {
      if (typeforsearch[i].checked) {
        type = typeforsearch[i].value
      }
    }
    UnSetdataTable('item_search_table')
    try {
      setDatsLodaer(true)
      const postdata = {
        search: searchkey,
        type: type,
      }
      let resSearchdata = await _Api(
        postdata,
        `api/v1/inventory/my/itemSearchData`,
      )
      if (resSearchdata.code === 200) {
        setDatsLodaer(false)
        SetdataTable('item_search_table', '0', newcolumndata, [0])
        setSearchdetail(resSearchdata?.data?.item_searchRow)
        setDetail(resSearchdata?.data)
        setTransactionsku(resSearchdata?.data?.SKU)


      } else {
        SetdataTable('item_search_table', '0', newcolumndata, [0])
        error(t('Something Went Wrong!!'))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_inventory_itemsearch',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    let typeforsearch = document.querySelectorAll('.typeforsearch')
    typeforsearch[1].checked = true
    setSetting({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    })
  }, [])

  useEffect(() => {
    SetdataTable('item_search_table', '0', newcolumndata, [0])
  }, [newcolumndata])

  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="item-search-wrap">
                <div className="item-search-wrap-left">
                  <h1 className="main-body-heading">{t('Item Search')}</h1>
                  <div className="main-body-top-status-bar">
                    <div className="d-flex">
                      <div className="item-search-wrap-left-radio">
                        <div className="item-search-wrap-left-radio-single">
                          <div className="com-check radio">
                            <input
                              type="radio"
                              id="f1"
                              value="stock_id"
                              className="typeforsearch"
                              name="itemRadio"
                            />
                            <label
                              className="com-check-label"
                              htmlFor="f1"
                            ></label>
                          </div>
                          <label
                            className="item-search-wrap-left-radio-single-label"
                            htmlFor="f1"
                          >
                            <img
                              className="item-search-wrap-left-radio-single-label-img"
                              src={
                                base_assets + 'images/icons/stock-id-Icon.png'
                              } alt=""
                            />
                            {t('StockID')}
                          </label>
                        </div>
                        <div className="item-search-wrap-left-radio-single">
                          <div className="com-check radio">
                            <input
                              type="radio"
                              value="SKU"
                              id="f2"
                              className="typeforsearch"
                              name="itemRadio"
                            />
                            <label
                              className="com-check-label"
                              htmlFor="f2"
                            ></label>
                          </div>
                          <label
                            className="item-search-wrap-left-radio-single-label"
                            htmlFor="f2"
                          >
                            <img
                              className="item-search-wrap-left-radio-single-label-img"
                              src={base_assets + 'images/icons/sku-icon.png'} alt=""
                            />
                            {t('SKU')}
                          </label>
                        </div>
                      </div>
                      <div className="main-body-top-status-bar-filter">
                        <input
                          type="text"
                          value={searchvalue ? searchvalue : ""}
                          className="main-body-top-status-bar-filter-input position-relative border-start-0"
                          placeholder={t('StockID/SKU')}
                          onChange={(e) => {
                            setSearchvalue(e.target.value)
                            setShow(true)
                            callsearchlist(e)
                          }}
                        />
                        {searchvalue ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => setSearchvalue("")} alt="" /> : ""}
                        <button className="main-body-top-status-bar-filter-search">
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                        {show && searchvalue !== '' && searchlist.length > 0 ? (
                          <div className="item_list_card_all">
                            <div className="item_list_card">
                              <div className="custom_search_title text-green">
                                {t('Custom Search')}
                              </div>
                              <ul
                                id="item_list_cardul_custom"
                                className="item_list_cardul_custom"
                              >
                                <li>{searchvalue}</li>
                              </ul>
                              <div className="custom_search_title text-green">
                                {t('Product')}
                              </div>

                              <ul className="item_list_cardul">
                                {searchlist
                                  ? searchlist.map((result, key) => {
                                    return (
                                      <li
                                        data-search={
                                          result?.search_key
                                            ? result?.search_key
                                            : ''
                                        }
                                        className="grid-li"
                                        onClick={(e) => {
                                          getsearchvalue(e);
                                          setGetsearchcontent(result?.search_key || '');
                                          if (result?.SKU) {
                                            setTransactionstockid(result?.search_key);
                                          } else {
                                            setTransactionstockid("");
                                          }
                                        }}
                                        key={key}
                                      >
                                        {result?.SKU
                                          ? `${result?.SKU} (${result?.search_key})`
                                          : result?.search_key}
                                      </li>
                                    )
                                  })
                                  : ''}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="main-body-top-tab-bar-right-col">
                      <Link
                        to="/app/inventory/transactionHistory"
                        className={
                          getsearchcontent
                            ? 'main-body-top-tab-bar-right-col-a'
                            : 'main-body-top-tab-bar-right-col-a row-inactive'
                        }
                        state={{
                          status: 'All Transcation',
                          location_id: '',
                          SKU: transactionsku,
                          Stock_id: transactionstockid
                        }}
                      >
                        <img
                          src={
                            base_assets + 'images/icons/history-search-icon.png'
                          }
                          alt=""
                        />
                      </Link>
                      <Link
                        to="/app/inventory/quickview"
                        className={
                          getsearchcontent
                            ? 'main-body-top-tab-bar-right-col-a'
                            : 'main-body-top-tab-bar-right-col-a row-inactive'
                        }
                        state={{
                          SKU: transactionsku,
                        }}
                      >
                        <img
                          className="img-360"
                          src={base_assets + 'images/icons/360-view-icon.png'}
                          alt=""
                        />
                      </Link>
                      <Link
                        to="/app/inventory/posorder"
                        state={{ selectedid: selecteditems }}
                        className={
                          selecteditems?.length
                            ? 'main-body-top-tab-bar-right-col-a'
                            : 'main-body-top-tab-bar-right-col-a row-inactive'
                        }
                      >
                        <img
                          src={
                            base_assets +
                            'images/icons/danger-calender-icon.png'
                          }
                          alt=""
                        />
                        <span className="main-body-top-tab-bar-right-col-a-text">
                          {t('order')}
                        </span>
                      </Link>
                      <Link
                        to="/app/inventory/createreserves"
                        state={{ datalist: selecteditems }}
                        className={
                          selecteditems?.length
                            ? 'main-body-top-tab-bar-right-col-a'
                            : 'main-body-top-tab-bar-right-col-a row-inactive'
                        }
                      >
                        <img
                          src={
                            base_assets +
                            'images/icons/yellow-calender-icon.png'
                          }
                          alt=""
                        />
                        <span className="main-body-top-tab-bar-right-col-a-text">
                          {t('reserve')}
                        </span>
                      </Link>
                      <Link
                        to="/app/inventory/createTransfer"
                        state={{
                          createlist: selecteditems,
                          Voucher_id: '',
                          hidelocationid: selectedlocation,
                          showwarehouseid: 'null',
                          ItemSearch: true
                        }}
                        className={
                          selecteditems?.length
                            ? 'main-body-top-tab-bar-right-col-a'
                            : 'main-body-top-tab-bar-right-col-a row-inactive'
                        }
                      >
                        <img
                          src={base_assets + 'images/icons/cube-bar-icon.png'}
                          alt=""
                        />
                        <span className="main-body-top-tab-bar-right-col-a-text">
                          {t('transfer')}
                        </span>
                      </Link>
                      <button className="print-export-dropdown-selected-icon d-none">
                        <img
                          src={base_assets + 'images/icons/printer-icon.png'}
                          alt=""
                        />
                      </button>
                      <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              base_assets + 'images/icons/ellipsis-circular.png'
                            }
                            alt=""
                          />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className="dropdown-item print-dropdown-item">
                            <div className="dropdown-item-img">
                              <img
                                src={
                                  base_assets + 'images/icons/printer-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            <span>{t('print')}</span>
                          </li>
                          <li className="dropdown-item">
                            <div className="dropdown-item-img">
                              <img
                                src={
                                  base_assets + 'images/icons/export-x-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            <FileExport />
                          </li>
                          <li className="dropdown-item">
                            <div className="dropdown-item-img">
                              <img
                                src={
                                  base_assets + 'images/icons/share-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            <span
                              data-bs-toggle="modal"
                              data-bs-target="#socialModal"
                            >
                              {t('share')}
                            </span>
                          </li>
                          <li
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#columnModal"
                          >
                            <div className="dropdown-item-img">
                              <img
                                src={
                                  base_assets + 'images/icons/column-icon.png'
                                }
                                alt=""
                              />
                            </div>
                            <span>{t('column')}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="item-search-wrap-left-box">
                    <div className="item-search-wrap-left-box-head">
                      <div className="item-search-wrap-left-box-head-left">
                        <h5 className="item-search-wrap-left-box-head-left-heading">
                          {t('SKU')}:
                          <span>
                            {getsearchcontent
                              ? detail?.SKU
                                ? detail?.SKU
                                : ''
                              : ''}
                          </span>
                        </h5>
                        <p className="item-search-wrap-left-box-head-left-pro-name">
                          {/* #Cleo Diamond Slim Slip-On Bracelet title */}#
                          {getsearchcontent
                            ? detail?.name
                              ? detail?.name
                              : ''
                            : ''}
                        </p>
                      </div>
                      <div className="item-search-wrap-left-box-head-right">
                        <p className="item-search-wrap-left-box-head-right-available">
                          {t('Available')} :
                          <span>
                            {getsearchcontent
                              ? detail?.finalAvailable
                                ? detail?.finalAvailable
                                : '0'
                              : ''}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="main-body-main-table-wrap">
                      <table
                        id="item_search_table"
                        className="common-table product-base-table w-100"
                      >
                        <Tablehead
                          tablehead={itemsearchtableHead}
                          tblName={'tbl_inventory_itemsearch'}
                          setfilterby={setfilterby}
                        />
                        <tbody>
                          <>
                            {getsearchcontent ? searchdetail?.length ? searchdetail.map((result, key) => {
                              return (
                                <tr key={key}>
                                  <td className="pe-0">
                                    <div
                                      className={
                                        parseInt(result?.available) > 0
                                          ? result?.checkboxclass
                                            ? 'com-check checkbox-disabled'
                                            : 'com-check'
                                          : 'com-check checkbox-disabled'
                                      }
                                    >
                                      <input
                                        id={result?.location_id}
                                        data-ids={result?.ids}
                                        onClick={(e) => selectlocation(e)}
                                        type="checkbox"
                                        className="itemsearchtable"
                                      />
                                      <label
                                        htmlFor={result?.location_id}
                                        className="com-check-label"
                                      />
                                    </div>
                                  </td>
                                  <td className="pe-0">{key + 1}</td>
                                  <td className="link-color text-start">
                                    {result?.location
                                      ? result?.location
                                      : '-'}
                                  </td>
                                  <td className="stock-color text-end">
                                    {result?.total_qty
                                      ? result?.total_qty
                                      : '0'}
                                  </td>
                                  <td className="reserved-color text-end">
                                    {parseInt(result?.reserve) > 0 ? (
                                      <Link
                                        className="reserved-color text-decoration-none"
                                        to="/app/inventory/transactionHistory"
                                        state={{
                                          status: 'reserve',
                                          location_id:
                                            result?.location_id,
                                          SKU: transactionsku,
                                        }}
                                      >
                                        {result?.reserve
                                          ? result?.reserve
                                          : '0'}
                                      </Link>
                                    ) : result?.reserve ? (
                                      result?.reserve
                                    ) : (
                                      '0'
                                    )}
                                  </td>
                                  <td className="available-color text-end">
                                    {result?.available
                                      ? result?.available
                                      : '0'}
                                  </td>
                                  <td className="sold-color text-end">
                                    {parseInt(result?.sold) > 0 ? (
                                      <Link
                                        className="sold-color  text-decoration-none"
                                        to="/app/inventory/transactionHistory"
                                        state={{
                                          status: 'sold',
                                          location_id:
                                            result?.location_id,
                                          SKU: transactionsku,
                                        }}
                                      >
                                        {result?.sold
                                          ? result?.sold
                                          : '0'}
                                      </Link>
                                    ) : result?.sold ? (
                                      result?.sold
                                    ) : (
                                      '0'
                                    )}
                                  </td>
                                </tr>
                              )
                            })
                              :
                              ''
                              : ''}
                          </>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              className="main-green-text left-0 col-highlight text-start"
                              colSpan={3}
                            >
                              {selecteditems.length > 0 ? (
                                <span>{t('1 item selected')}</span>
                              ) : (
                                ''
                              )}
                            </td>
                            <td className="col-highlight text-end">
                              {searchdetail?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val.total_qty)),
                                0,
                              )}
                            </td>
                            <td className="col-highlight text-end">
                              {searchdetail?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val.reserve)),
                                0,
                              )}
                            </td>
                            <td className="col-highlight text-end">
                              {searchdetail?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val.available)),
                                0,
                              )}
                            </td>
                            <td className="col-highlight text-end">
                              {searchdetail?.reduce(
                                (interation, val) =>
                                (interation =
                                  interation + parseInt(val.sold)),
                                0,
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      {datalodaer && <TableLoader />}
                    </div>
                  </div>
                </div>
                <div className="item-search-wrap-right">
                  <Slider className="product-slider" {...settings}>
                    {detail?.images ? (
                      detail?.images.map((result, key) => {
                        return (
                          <div key={key}>
                            <img
                              src={
                                getsearchcontent
                                  ? result
                                  : base_assets +
                                  'images/blank-img.png'
                              } alt=""
                            />
                          </div>
                        )
                      })
                    ) : (
                      <div>
                        <img
                          src={
                            base_assets + 'images/blank-img.png'
                          } alt=""
                        />
                      </div>
                    )}
                  </Slider>
                  {getsearchcontent ? (
                    <div className="item-search-wrap-right-item-specification-box">
                      <h5 className="item-search-wrap-right-item-specification-box-name">
                        {detail?.tag ? detail?.tag : ''}
                      </h5>
                      <div className="item-search-wrap-right-item-specification-box-metal">
                        <div className="item-search-wrap-right-item-specification-box-metal-div">
                          <span className="item-search-wrap-right-item-specification-box-metal-div-span-1">
                            {t('Metal')}:
                          </span>
                          <span className="item-search-wrap-right-item-specification-box-metal-div-span-2">
                            {detail?.metalname ? detail?.metalname : ''}
                          </span>
                        </div>
                        <div className="item-search-wrap-right-item-specification-box-metal-div">
                          <span className="item-search-wrap-right-item-specification-box-metal-div-span-1">
                            {t('Stone')} :
                          </span>
                          <span className="item-search-wrap-right-item-specification-box-metal-div-span-2">
                            {detail?.stone_name ? detail?.stone_name : ''}
                          </span>
                        </div>
                        <div className="item-search-wrap-right-item-specification-box-metal-div">
                          <span className="item-search-wrap-right-item-specification-box-metal-div-span-1">
                            {t('Size')} :
                          </span>
                          <span className="item-search-wrap-right-item-specification-box-metal-div-span-2">
                            {detail?.sizename ? detail?.sizename : ''}
                          </span>
                        </div>
                      </div>
                      <ul className="item-search-wrap-right-item-specification-box-ul">
                        <li className="item-search-wrap-right-item-specification-box-ul-li">
                          {detail?.extra_po_information}
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
        <div
          className="modal fade common-modal"
          id="createPurchaseModal"
          tabIndex={-1}
          aria-labelledby="createPurchaseModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img
                  className="common-modal-exclamation-img"
                  src={base_assets + 'images/icons/exclamation-mark-green.png'}
                  alt=""
                />
                <p className="modal-content-text">
                  {t('Are you sure you want to Reserve this item ?')}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn modal-content-no"
                  data-bs-dismiss="modal"
                >
                  {t('No')}
                </button>
                <button type="button" className="btn modal-content-yes">
                  {t('yes')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableColumnModal
        tablehead={itemsearchtableHead}
        tblName={'tbl_inventory_itemsearch'}
        setnewcolumndata={setnewcolumndata}
        calldatatable={callsearchdetails}
      ></TableColumnModal>
    </React.Fragment>
  )
}

export default ItemSearch;
