import React, { useState, useEffect } from "react";
import "../master.scss"
import Select from "react-select";
import { graphqlPost, _Api } from "../../api/_call";
import { error, loading, Updateloading } from "../../common/helpers/toastify";
import { useTranslation } from 'react-i18next';

const EditNameCode = (props) => {
  const { setEditItem, dataid, type, edit, setUpdatedata, create, setDataid, setCreate } = props;
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [color, setColor] = useState("");
  const { t } = useTranslation();
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [active, setActive] = useState(true)
  const [colorarrayList, setColorarrayList] = useState([])
  const [getCustomErrors, setCustomErrors] = useState({});
  const getColorsList = async () => {
    try {
      const postdata = `{
                colors {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let colorarray = [];
      if (responce?.data?.colors) {
        responce.data?.colors?.forEach((result) => {
          colorarray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      };
      setColorarrayList(colorarray);

    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getColorsList();
  }, []);

  const seteditcode = async () => {
    if (!name) {
      error(t("Name is required."))
      return
    }
    if (!code) {
      error(t("Code is required."))
      return
    } else {
      let checkspace = code.includes(' ');
      if (checkspace) {
        error(t('Please remove space in code'));
        return;
      }
    }
    let GetloadingID = loading();
    try {
      let postdata = {
        "id": dataid ? dataid : "",
        "name": name,
        "code": code,
        "Status": active ? "1" : "0",
        "ColorData": color?.value,
        "edit": edit ? true : false,
        "type": type,
      }
      let res = await _Api(postdata, `api/v1/App/master/metalMasterEdit`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message));
        setUpdatedata(true)
        setEditItem(false)
        setDataid("")
        setCreate(false)
      } else {
        Updateloading(t, GetloadingID, res.errors, 'error');
        if (res.errors?.type) {
          getCustomErrors.type = true;
          setCustomErrors(getCustomErrors);
        }
        if (res.errors?.name) {
          getCustomErrors.name = true;
          setCustomErrors(getCustomErrors);
        }
        if (res.errors?.code) {
          getCustomErrors.code = true;
          setCustomErrors(getCustomErrors);
        }

      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const getfromdata = async () => {
    if (create) {
      setName("")
      setCode("")
      setColor({
        value: "",
        label: ""
      })
      setActive(true)
    } else if (dataid && type) {
      try {
        let postdata = {
          type: type,
          id: dataid
        }
        let res = await _Api(postdata, `api/v1/App/master/metalMasterViewById`)
        if (res?.code) {
          setName(res?.data?.name)
          setCode(res?.data?.code)
          setColor({
            value: res?.data?.ColorData?._id,
            label: res?.data?.ColorData?.name
          })
          if (res?.data?.Status === "1") {
            setActive(true)
          } else {
            setActive(false)
          }

        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }

  }
  useEffect(() => {
    if (dataid && (type || create)) {
      getfromdata();
    }
    // eslint-disable-next-line
  }, [dataid, type, create]);


  return (
    <>
      <div className="edit-item-area bg-white">
        <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
          <div className="me-auto">
            <label className="d-block">{t("Name")}: {name ? name : ""}</label>
            <label className="d-block">{t("Code")}: {code ? code : ""}</label>
          </div>
          <button className="square-btn square-btn-white btn-close focus-none"
            onClick={() => { setEditItem(false); setDataid(""); setCreate(false) }}></button>
          <button className="square-btn"
            onClick={() => seteditcode()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
        </div>
        <div className="edit-item-area-body">
          <div className="row mx-0">
            <div className="col-md-8 ps-0">
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Name")} <sup>*</sup></label>
                <input className="v3-input-group-input w-100"
                  value={name || ''}
                  onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="v3-input-group">
                <label className="v3-input-group-label">{t("Code")} <sup>*</sup></label>
                <input className="v3-input-group-input w-100"
                  value={code || ''}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="v3-input-group mb-0">
                <label className="v3-input-group-label">{t("Color")}</label>
                <Select
                  className="select-set select  p-0"
                  placeholder=""
                  options={colorarrayList}
                  value={color}
                  onChange={(e) => setColor(e)}
                  classNamePrefix="common-select"
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pe-0 text-end">
              <div className="form-check form-switch ps-0">
                <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                <input
                  checked={active ? true : false}
                  onChange={(e) => setActive(e.target.checked)}
                  className="form-check-input float-none ms-2" type="checkbox" id="active" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditNameCode;