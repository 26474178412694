import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DateTime from '../../common/DateTime'
import {
  LocationList,
  SupplierList,
  VoucherList,
} from '../../common/graphql/commonlayouts'
import { SetdataTable, UnSetdataTable } from '../../api/setdatatable'
import { _Api } from '../../api/_call'
import 'react-datepicker/dist/react-datepicker.css'
import TableColumnModal from '../../common/modules/tableColumn'
import ConfirmationModel from '../../common/modules/confirmationmodel'
import { Tablehead } from '../../common/modules/Tablehead'
import Advancedfiltermodel from '../../common/modules/advanced-filter-model'
import NotesModalLabel from '../../common/modules/NotesModalLabel'
import { EditpopostableHead } from '../../common/Tablehead'
import { toFormatPrice, toUnformatPrice } from '../../common/helpers/function'
import Select from 'react-select'
import { po_status_class } from '../../common/helpers/status_class'
import {
  success,
  error,
  loading,
  Updateloading,
} from '../../common/helpers/toastify'
import SocialModal from '../../common/modules/SocialModal/SocialModal'
import ProductModal from '../../common/modules/ProductModal'
import TransAndDeliverDate from '../../common/modules/TransAndDeliverDate'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next';
const EditPurchaseOrder = (props) => {
  const dataFetched = useRef();
  const { t } = useTranslation();
  var colorsArray = []
  var valueofstatus = []
  const { state } = useLocation();
  let navigate = useNavigate()
  const {
    posid,
    posorder,
    po_status,
    deliverydate,
    voucher_id,
    printparmission,
    editparmission,
    statusparmission

  } = state
  const [commanSelected_sup, setcommanSelected_sup] = useState(null)
  const [posorder_ids, setPosorder_ids] = useState([])
  const [EDITpo_OrdersList, setEDITpo_OrdersList] = useState([])
  const [TransactionDate, setTransactionDate] = useState(new Date())
  const [DeliveryDate, setDeliveryDate] = useState(new Date(deliverydate))
  const [deleted_product_id, setDeleted_product_id] = useState([])
  const [limit, setLimit] = useState(100)
  const [filtername, setFiltername] = useState('')
  const [commanSelected_vouc, setcommanSelected_vouc] = useState(null)
  const [selecteditem, setSelecteditem] = useState([])
  const [newcolumndata, setnewcolumndata] = useState(EditpopostableHead)
  const [selectAllStatus, setSelectAllStatus] = useState(false)
  const [type, setType] = useState('')
  const [ponumber, setPonumber] = useState()
  const [editposstatus, setEditposstatus] = useState()
  const [commanSelected_loc, setcommanSelected_loc] = useState([])
  const [note, setNote] = useState()
  const [printurl, setPrinturl] = useState()
  const [postatusdata, setPostatusdata] = useState()
  const [getoneproduct, setOneproduct] = useState()
  const [oneproductindex, setoneproductindex] = useState()
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const [statement, setStatement] = useState('')
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
      }
    },
  }
  useEffect(() => {
    if (posid !== undefined) {
      setPosorder_ids(posorder)
    }
  }, [posid, posorder])
  const filterbyname = () => {
    if (filtername) {
      GetEDITpoPos()
    } else {
      GetEDITpoPos()
    }
  }
  const deleteIcon = (event) => {
    var EDITpo_OrdersList_arr = [...EDITpo_OrdersList]
    if (event.target.checked) {
      const values = [...selecteditem, event.target.value]
      let data_ind = event.target.getAttribute('data-index')
      EDITpo_OrdersList_arr[data_ind].checkBoxSel = true
      setEDITpo_OrdersList(EDITpo_OrdersList_arr)
      setSelecteditem(values)
    } else {
      setSelecteditem((data) =>
        data.filter((ids) => ids !== event.target.value),
      )
      let data_ind = event.target.getAttribute('data-index')
      EDITpo_OrdersList_arr[data_ind].checkBoxSel = false
      setEDITpo_OrdersList(EDITpo_OrdersList_arr)
    }
  }
  const posorderGetById = async () => {
    var posData_array = []
    try {
      const postdata = {
        pos_id: posorder,
      }
      let posData = await _Api(postdata, `api/v1/Inventory/po/getPOSDetails`)
      if (posData.code === 200) {
        posData?.data?.map((result, key) => {
          var product_details_data = result.product_details
          posData_array.push({
            _id: result.id,
            type: 'Sales Order',
            pos_no: result?.So_No,
            customer_name: result?.Customer_name,
            stone_name: product_details_data?.stone_name,
            location_name: result?.location_name,
            size_name: product_details_data?.size_name,
            metal_name: product_details_data?.metal_name,
            createdAt: result?.createdAt,
            price: result?.price,
            total_price: result?.price,
            delivery_date: result?.delivery_date,
            SKU: product_details_data?.SKU,
            main_image: product_details_data?.main_image,
            po_QTY: result?.Qty,
            product_new: true,
          })
          return posData_array
        })

      } else {
        return posData_array
      }
    } catch (err) {
      return posData_array
    }
  }
  const GetEDITpoPos = useCallback(async (firstlimit) => {
    var posData_array = []
    if (posorder !== undefined) {
      posorder.forEach((result, key) => {
        posData_array.push({
          _id: result.product_id,
          is_design: result.is_design,
          is_product: result.is_product,
          is_variant: result.is_variant,
          Unit: result?.Unit,
          type: 'Low Stock',
          pos_no: '',
          location_id: result.location_id,
          name: result.name,
          metal_name: result.metal_name,
          ID: result.ID,
          product_type: result.product_type,
          size_name: result.size_name,
          stone_name: result.stone_name,
          customer_name: '',
          location_name: result.location_name,
          createdAt: '',
          price: result.price,
          total_price: result.total_price,
          delivery_date: '',
          SKU: result.SKU,
          main_image: result.main_image,
          po_QTY: result?.order_qty,
          product_new: true,
          oldStock_id: '',
          customer_id: '',
          amount_total: result.total_price,
          sales_order_id: '',
          remark: '',
          Style: result.Style,
          Metal: result.Metal,
          Color: result.Color,
          Size: result.Size,
          GrossWt: result.GrossWt,
          NetWt: result.NetWt,
          Stones: result.Stones,
        })
      })
    }
    setDatsLodaer(true)
    try {
      const postdata = {
        po_order_id: posid,
        limit: firstlimit ? firstlimit : limit,
        search: filtername,
        status: po_status,
        show_all: true,
      }
      let resPoPOsOrders = await _Api(
        postdata,
        `api/v1/Inventory/po/getPODetails`,
      )
      if (resPoPOsOrders?.code === 200) {
        setDatsLodaer(false)
        let poNewdata;
        if (posorder && resPoPOsOrders?.data?.type === 'Sales Order') {
          poNewdata = await posorderGetById()
        } else {
          poNewdata = await posData_array
        }
        var poOlddata = resPoPOsOrders?.data?.po_product_data
        const storedState = localStorage.getItem('yourAppStateKeyEditPurchaseOrder');
        let mix_data;
        if (storedState) {
          mix_data = poOlddata;
        } else {
          if (poNewdata !== undefined) {
            mix_data = poOlddata.concat(poNewdata);
          } else {
            mix_data = poOlddata
          }
        }
        SetdataTable('editpos_order_table', 6, newcolumndata)
        setEDITpo_OrdersList(mix_data)
        setPrinturl(resPoPOsOrders?.data?.printURL)
        setcommanSelected_loc(resPoPOsOrders?.data?.location_id)
        setcommanSelected_sup(resPoPOsOrders?.data?.supplier_id)
        setPonumber(resPoPOsOrders?.data?.po_no)
        setNote(resPoPOsOrders?.data?.note)
        setType(resPoPOsOrders?.data?.type)
        setPostatusdata(resPoPOsOrders?.data?.status?.po_status)
        setDeleted_product_id([])
      } else {
        setDatsLodaer(false)
        SetdataTable('editpos_order_table', 6, newcolumndata)
      }
    } catch (err) {
      console.log(err, 'err')
    }
    // eslint-disable-next-line 
  }, [filtername, limit, newcolumndata, po_status, posid, posorder]);

  const change_qty_price = (e, i) => {
    const { value, name } = e.target
    const newState = [...EDITpo_OrdersList]
    newState[i] = {
      ...newState[i],
      [name]: value,
    }
    newState[i] = {
      ...newState[i],
      'total_price':
        '$ ' +
        toFormatPrice(
          parseInt(newState[i]['po_QTY']) *
          parseFloat(toUnformatPrice(newState[i]['price'])),
        ),
    }
    setEDITpo_OrdersList(newState)
  }
  const select_po_status = (Value) => {
    setStatement('Change Status')
    setEditposstatus(Value?.value)
    document.querySelector('#status_query_select').click()
  }
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_po_editpopos',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    GetEDITpoPos()
  }, [GetEDITpoPos])
  var po_status_class_val = po_status_class(postatusdata)
  if (postatusdata) {
    if (postatusdata === 3) {
      valueofstatus = [
        { label: t('Approved'), value: postatusdata, color: '#0ECB81' },
      ]
    } else if (postatusdata === 2) {
      valueofstatus = [
        { label: t('Pending'), value: postatusdata, color: '#F9BC44' },
      ]
      colorsArray = [
        { label: t('Approved'), value: 3, color: '#0ECB81' },
        { label: t('Canceled'), value: 0, color: '#FF5757' },
      ]
    } else if (postatusdata === 0) {
      valueofstatus = [
        { label: t('Canceled'), value: postatusdata, color: '#FF5757' },
      ]
    } else {
      valueofstatus = [
        { label: t('Completed'), value: postatusdata, color: '#68B8F2' },
      ]
    }
  }

  const deleteSelectedItems = () => {
    var selecteditem_arr = [...EDITpo_OrdersList]
    var deleted_product_id_ = [...deleted_product_id]
    var selecteditem_arr_ = []
    var posorder_ids_arr_ = []
    selecteditem_arr_ = selecteditem_arr.map((element, index) => {
      var selected_box = document.querySelector('#po_edit_' + element._id);
      if (!selected_box.checked) {
        if (element.product_new === true) {
          posorder_ids_arr_.push(element._id);
        }
        return element;
      } else {
        if (element.product_new !== true) {
          deleted_product_id_.push(element._id);
        }
        return null;
      }
    }).filter(element => element !== null);
    setDeleted_product_id(deleted_product_id_)
    setSelecteditem([])
    setPosorder_ids(posorder_ids_arr_)
    UnSetdataTable('editpos_order_table')
    setTimeout(() => {
      setEDITpo_OrdersList(selecteditem_arr_)
      SetdataTable('editpos_order_table', 6, newcolumndata)
    }, 100)
  }

  const po_create_submit = async () => {
    try {
      if (!commanSelected_vouc) {
        error(t("Please select voucher"))
        return
      }
      if (!commanSelected_loc) {
        error(t("Please select location"))
        return
      }
      var product_data = []
      var new_product = []
      var checkqty = true
      EDITpo_OrdersList?.forEach((result, key) => {
        if (result.po_QTY === 0) {
          checkqty = false

        } else {
          var product_data_obj = {
            product_id: result._id || '',
            oldStock_id: '' || '',
            customer_id: result.Customer_id || '',
            pos_no: result.So_No || '',
            po_QTY: parseInt(result.po_QTY) || 0,
            price: result.price || '',
            amount_total: result.total_price || '',
            sales_order_id: result.id || '',
            remark: '' || '',
            Style: result.Style || '',
            Metal: result.Metal || '',
            Color: result.Color || '',
            Size: result.Size || '',
            Unit: result.Unit || '',
            GrossWt: result.GrossWt || '',
            NetWt: result.NetWt || '',
            Stones: result.Stones || [],
          }
          if (result.product_new === true) {
            new_product.push(product_data_obj)
          } else {
            product_data.push(product_data_obj)
          }
        }

      })
      if (!checkqty) {
        error(t("Quantity should be greater than 0"));
        return;
      }
      let GetloadingID = loading();
      const postdata = {
        voucher_id: commanSelected_vouc,
        location_id: commanSelected_loc,
        supplier_id: commanSelected_sup,
        delivery_date: DeliveryDate,
        transaction_date: TransactionDate,
        note: note,
        po_datas: product_data,
        new_po_datas: new_product,
        deletd_po_datas: deleted_product_id,
        po_order_id: posid,
      }
      let resPoOrders = await _Api(postdata, `api/v1/Inventory/po/editPO`)
      if (resPoOrders.code === 200) {
        Updateloading(t, GetloadingID, "Edited successfully!!");
        const updatedState = {
          ...state,
          posorder: [],
        };
        localStorage.setItem('yourAppStateKeyEditPurchaseOrder', JSON.stringify(updatedState));
        GetEDITpoPos()
      } else {
        Updateloading(t, GetloadingID, resPoOrders.errors, 'error');

      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const handler_status = async () => {
    try {
      const postdata = {
        po_order_id: posid,
        status: editposstatus,
      }
      let statusdata = await _Api(postdata, `api/v1/Inventory/po/editStatusPO`)
      if (statusdata?.code === 200) {
        success(t("Successfully Status Updated!!"))
        GetEDITpoPos()
        setEditposstatus(editposstatus)
      } else {
        error(t("Something Went Wrong!!"))
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  useEffect(() => {
    let selectAllStatus_arr = []
    var allCheckbox = document.querySelectorAll('.EditpopostableHead')
    let array = [...EDITpo_OrdersList]
    if (selectAllStatus === true) {
      array.forEach((result) => {
        result.checkBoxSel = true
      })
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = true
        selectAllStatus_arr.push(checkBox_selectAble.value)
      })
    } else {
      array.forEach((result) => {
        result.checkBoxSel = false
      })
      allCheckbox.forEach(function (checkBox_selectAble) {
        checkBox_selectAble.checked = false
      })
    }
    setSelecteditem(selectAllStatus_arr)
    // eslint-disable-next-line 
  }, [selectAllStatus])
  const editproduct_submit = () => {
    var mainarray = [...EDITpo_OrdersList]
    mainarray[oneproductindex] = getoneproduct
    setEDITpo_OrdersList(mainarray)
  }
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    EditpopostableHead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (EDITpo_OrdersList.length) {
      EDITpo_OrdersList.map((result, key) => {
        return (
          bodyarray.push(["", key + 1, result?.main_image, result?.SKU, result?.createdAt, result?.pos_no, result?.sku, result?.metal_name, result?.stone_name, result?.size_name, result?.po_QTY, result?.price, result?.amount_total, result?.location_name, result?.customer_name, result?.delivery_date, result?.due_days, note, printurl
          ])
        )
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "edit_po_list");

  }
  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <span onClick={() => navigate("/app/inventory")} className="page-back-btn">
                    <img
                      src={base_assets + 'images/icons/arrow-left.png'}
                      alt=""
                    />
                  </span>
                  <img
                    src={base_assets + 'images/icons/home-icon.png'}
                    alt=""
                  />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={'Purchase_order'}
                    voucherid={voucher_id}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center danger-text">
                  {t('edit Purchase Order')}
                </h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills">
                  <span className="main-body-top-tab-bar-left-col-a nav-link active">
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      <img
                        src={
                          base_assets + 'images/icons/price-list-icon-green.png'
                        }
                        alt=""
                      />
                      {type === 'Sales Order'
                        ? 'POS Order'
                        : type === 'Low Stock' || type === 'Master Product'
                          ? t('Replenish Order')
                          : ''}
                    </span>
                  </span>
                </div>
                <div
                  className={
                    'dropdown status-dropdown status-dropdown-select ' +
                    po_status_class_val
                  }
                >
                  <Select
                    options={statusparmission ? colorsArray : []}
                    value={valueofstatus}
                    isDisabled={postatusdata === 2 ? false : true}
                    onChange={(e) => select_po_status(e)}
                    classNamePrefix="status-dropdown-select"
                    styles={colorStyles}
                    getOptionLabel={(e) => <div className="">{e.label}</div>}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="main-body-top-inputs-bar">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">{ponumber}</label>
                  <LocationList
                    setcommanSelected_loc={setcommanSelected_loc}
                    locationid={commanSelected_loc}
                  />
                  <SupplierList
                    setcommanSelected_sup={setcommanSelected_sup}
                    supplierid={commanSelected_sup}
                  />
                  <TransAndDeliverDate
                    TransactionDate={TransactionDate}
                    DeliveryDate={DeliveryDate}
                    setTransactionDate={setTransactionDate}
                    setDeliveryDate={setDeliveryDate}
                  />
                </div>
                <div className="main-body-top-inputs-bar-col-right">
                  {type === 'Sales Order' ? (
                    <Link
                      to="/app/inventory/transaction_posorder"
                      state={{
                        locationid: commanSelected_loc,
                        posid: posid,
                        posorder_ids: posorder_ids,
                        po_status: po_status,
                        editparmission: editparmission,
                        statusparmission: statusparmission
                      }}
                      className="main-body-top-tab-bar-right-col-a"
                    >
                      <img
                        src={base_assets + 'images/icons/price-list-icon.png'}
                        alt=""
                      />
                      <span className="main-body-top-tab-bar-right-col-a-text">
                        {t('POS Order')}
                      </span>
                      <span className="main-body-top-tab-bar-right-col-a-num">
                        (6)
                      </span>
                    </Link>
                  ) : type === 'Low Stock' || type === 'Master Product' ? (
                    <Link
                      state={{
                        locationid: commanSelected_loc,
                        po_order_id: posid,
                        new_low_stock: posorder_ids,
                        supplierid: commanSelected_sup,
                        po_status: po_status,
                        deliverydate: DeliveryDate,
                        transitiondate: TransactionDate,
                        voucher_id: voucher_id,
                        ponumber: ponumber,
                        editparmission: editparmission,
                        statusparmission: statusparmission
                      }}
                      to="/app/inventory/edit-po-Replenish"
                      className="main-body-top-tab-bar-right-col-a"
                    >
                      <img
                        src={
                          base_assets + 'images/icons/replenish-arrow-icon.png'
                        }
                        alt=""
                      />
                      <span className="main-body-top-tab-bar-right-col-a-text">
                        {t('Replenish')}
                      </span>
                    </Link>
                  ) : (
                    ''
                  )}
                  <span></span>
                  {po_status === 2 ? (
                    <>
                      <span className={editparmission ? "divider" : "d-none"} />
                      <button
                        className={editparmission ? "main-body-top-inputs-bar-col-right-submit-btn com-btn" : "d-none"}
                        data-bs-toggle="modal"
                        data-bs-target="#createPurchaseModal"
                        onClick={() => setStatement('Save the Edited Item')}
                      >
                        {t('save')}
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); GetEDITpoPos(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t('TF No./StockID/SKU/Metal/Stone')}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => setFiltername("")} alt="" /> : ""}
                  <button
                    onClick={filterbyname}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + 'images/icons/printer-icon.png'}
                      alt=""
                    />
                  </button>
                  <button className="print-export-dropdown-selected-icon">
                    <img
                      onClick={() => deleteSelectedItems()}
                      className={
                        selecteditem.length > 0
                          ? 'deletebtn btn-style d-block'
                          : ' d-none'
                      }
                      src={base_assets + 'images/icons/delete-icon.png'}
                      alt=""
                    />
                  </button>
                  <div
                    className="main-body-top-status-bar-note-button"
                    data-bs-toggle="modal"
                    data-bs-target="#notesModal"
                  >
                    <img
                      src={base_assets + 'images/icons/comment-icon.png'}
                      alt=""
                    />
                    {t('note')}
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + 'images/icons/ellipsis-circular.png'}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        onClick={() => {
                          if (printurl) {
                            window.open(printurl, '_blank', 'noreferrer')
                          }
                        }}
                        className={printparmission ? "dropdown-item print-dropdown-item" : "d-none"}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => downloadexclsheet()}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/export-x-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('export')}</span>
                      </li>
                      <li className="dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/import-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('import')}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/column-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('column')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="editpos_order_table"
                    className="common-table product-base-table w-100"
                  >
                    <Tablehead
                      tablehead={EditpopostableHead}
                      tblName={'tbl_po_editpopos'}
                      setfilterby={""}
                      setShowoption={setSelectAllStatus}
                    />
                    <tbody>
                      {EDITpo_OrdersList
                        ? EDITpo_OrdersList.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <div className="com-check">
                                  <input
                                    value={result?._id}
                                    id={'po_edit_' + result?._id}
                                    className="EditpopostableHead"
                                    type="checkbox"
                                    data-index={key}
                                    data-product={
                                      result?.product_new
                                        ? result?.product_new
                                        : ''
                                    }
                                    onChange={(e) => deleteIcon(e)}
                                    checked={
                                      result?.checkBoxSel
                                        ? result?.checkBoxSel
                                        : ''
                                    }
                                  />
                                  <label
                                    htmlFor={'po_edit_' + result?._id}
                                    className="com-check-label "
                                  />
                                </div>
                              </td>
                              <td>{key + 1}</td>
                              <td>
                                <div className="table-pro-img">
                                  <img
                                    src={
                                      result?.main_image
                                        ? result?.main_image
                                        : ''
                                    }
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td
                                className="po-no link-color cursor-pointer"
                                onClick={() => {
                                  setOneproduct(result);
                                  setoneproductindex(key)
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#ProductInfoModal"
                              >
                                {result?.SKU ? result?.SKU : ''}
                              </td>
                              <td>
                                {result?.createdAt ? result?.createdAt : ''}
                              </td>
                              <td className="po-no curdor-pointer">
                                {result?.pos_no ? result?.pos_no : ''}
                              </td>
                              <td className="col-highlight">
                                {result?.oldStock_id
                                  ? result?.oldStock_id
                                  : ''}
                              </td>
                              <td className="col-highlight">
                                {result?.metal_name ? result?.metal_name : ''}
                              </td>
                              <td className="col-highlight">
                                {result?.stone_name ? result?.stone_name : ''}
                              </td>
                              <td className="col-highlight">
                                {result?.size_name ? result?.size_name : ''}
                              </td>
                              <td className="col-highlight">
                                <div className="col-input">
                                  <input
                                    type="text"
                                    onChange={(e) => change_qty_price(e, key)}
                                    name="po_QTY"
                                    value={result?.po_QTY ? result?.po_QTY : 0}
                                  />
                                </div>
                              </td>
                              <td className="col-highlight">
                                <div className="col-input">
                                  <input
                                    type="text"
                                    onChange={(e) => change_qty_price(e, key)}
                                    name="price"
                                    value={result?.price ? result?.price : 0}
                                  />
                                </div>
                              </td>
                              <td className="col-highlight">
                                {result?.total_price
                                  ? toFormatPrice(result?.total_price, { addSymbol: true })
                                  : ''}
                              </td>
                              <td className="danger-text col-highlight">
                                {result?.location_name
                                  ? result?.location_name
                                  : ''}
                              </td>
                              <td className="danger-text col-highlight">
                                {result?.customer_name
                                  ? result?.customer_name
                                  : ''}
                              </td>
                              <td className="danger-text col-highlight">
                                {result?.delivery_date
                                  ? result?.delivery_date
                                  : ''}
                              </td>
                              <td className="danger-text col-highlight">
                                {result?.due_days}
                              </td>
                              <td className="text-center col-highlight">
                                {note ? note : '-'}
                              </td>
                              <td className="text-center col-highlight">
                                {result?.pos?.url ?
                                  <div onClick={() => {
                                    if (result?.pos?.url) {
                                      window.open(result?.pos?.url, '_blank', 'noreferrer')
                                    }
                                  }}><img
                                      className="pdf-image"
                                      src={base_assets + "images/icons/pdf-icon.png"}
                                      alt=""
                                    />
                                  </div>
                                  : "-"
                                }
                              </td>

                            </tr>
                          )
                        })
                        : ''}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={4}
                          className="main-green-text left-0 text-start"
                        >
                          {selecteditem.length > 0 ? (
                            <span>
                              {selecteditem.length} {t('item selected')}
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>
                          {EDITpo_OrdersList?.reduce(
                            (interation, val) =>
                            (interation =
                              interation +
                              parseInt(val?.po_QTY ? val?.po_QTY : 0)),
                            0,
                          )}
                        </td>
                        <td />
                        <td>
                          {toFormatPrice(
                            EDITpo_OrdersList?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(
                                  val?.total_price ? val?.total_price : 0,
                                )),
                              0,
                            ),
                            { addSymbol: true })}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
        <ProductModal
          data_product={getoneproduct}
          setOneproduct={setOneproduct}
          editproduct_submit={editproduct_submit}
        />
      </div>
      <TableColumnModal
        tablehead={EditpopostableHead}
        tblName={'tbl_po_editpopos'}
        calldatatable={GetEDITpoPos}
        setnewcolumndata={setnewcolumndata}
      ></TableColumnModal>
      <ConfirmationModel
        statement={statement}
        handler_submit={
          statement === 'Change Status' ? handler_status : po_create_submit
        }
        handler_dismiss={GetEDITpoPos}
      ></ConfirmationModel>
      <NotesModalLabel
        setForm_note={setNote}
        form_note={note}
      ></NotesModalLabel>
      <Advancedfiltermodel></Advancedfiltermodel>
      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#createPurchaseModal"
        id="status_query_select"
      ></button>
    </>
  )
}

export default EditPurchaseOrder
