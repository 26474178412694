import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import '../i18n.js'
import { useTranslation } from 'react-i18next';
import Header from '../components/common/header';
export default function InventoryHeader(props) {
    const { Fulhandle } = props;
    const { i18n } = useTranslation();
    const [openSidebar, setOpenSidebar] = useState(false);
    const openGISSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    localStorage.setItem('middlewareLocations', '');
    return (
        <>
            <div className='main-layout-apps'>
                <Header Fulhandle={Fulhandle} i18n={i18n} openGISSidebar={openGISSidebar} />
                <Outlet />
            </div>
        </>
    );
};



