import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { _Api } from "../api/_call";
import DateTime from "../common/DateTime";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDate } from "../common/helpers/formatDate";
const ProductMenu = (props) => {
  const { t } = useTranslation();
  let { product_value_arr, setected_opt, selectedButton_, setIs_disable, productId_url } = props;
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  let advance_to = "#";
  let varientShow = "row-inactive d-none";
  let allShow = "row-inactive d-none";
  let AdvanceTabOn = "row-inactive ";

  let product_id = "";

  if (product_value_arr[0] === undefined) {
    product_value_arr = [{ product_id: "", name: "", varient_type: "", sku: "" }];
  }
  if (
    product_value_arr[0].product_id !== undefined &&
    product_value_arr[0].product_id
  ) {
    product_id = product_value_arr[0].product_id;
    advance_to = "/app/product/productAdvanced/" + product_id;
    AdvanceTabOn = true;

  }
  if (
    product_value_arr[0].varient_type !== undefined &&
    product_value_arr[0].varient_type === "1" &&
    product_id
  ) {
    varientShow = true;
  }
  if (
    product_value_arr[0].varient_type !== undefined &&
    product_value_arr[0].varient_type === "2" &&
    product_id
  ) {
    varientShow = true;
    allShow = true;
  }

  const [selectedTopNavProduct] = useState(setected_opt);
  const [selectedButton, setButton] = useState(selectedButton_);
  const [logs_data, setLogs_data] = useState([]);
  useEffect(() => {
    if (productId_url) {
      setButton('pe-none ct-disable');
    }
  }, [productId_url]);
  useEffect(() => {
    if (selectedButton_) {
      setButton(selectedButton_)
    }
  }, [selectedButton_]);
  useEffect(() => {
    setIs_disable(selectedButton)
    // eslint-disable-next-line
  }, [selectedButton]);
  useEffect(() => {
    getLogs(product_id);
  }, [product_id]);

  const getLogs = async (product_id) => {
    try {
      const postdata = {
        id: product_id,
      };
      let resproduct = await _Api(
        postdata,
        `api/v1/App/product/getLogs`
      );
      if (resproduct?.code === 200) {
        setLogs_data(resproduct?.data);
      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  return (
    <React.Fragment>
      <div className="fixed-product-header">
        <div className="main-body-current-day-time">
          <DateTime></DateTime>
        </div>
        <h1 className="main-body-heading">
          <Link className="main-body-heading text-decoration-none" to="/app/product/main"> {t("product")}</Link>
        </h1>
        <div className="main-body-top-tab-bar">
          <div
            className="main-body-top-tab-bar-left-col nav nav-pills"
            role="tablist"
            id="topNavProduct"
          >
            <Link
              className={'main-body-top-tab-bar-left-col-a nav-link ' + selectedTopNavProduct.generalInfo}
              state={{ product_value_arr: product_value_arr }}

              to={"/app/product/add-new/" + product_id}
            >
              <span className="main-body-top-tab-bar-left-col-a-text">
                {t("General Info")}
              </span>
            </Link>
            <Link
              className={'main-body-top-tab-bar-left-col-a nav-link ' + selectedTopNavProduct.advanced + " " + AdvanceTabOn}
              state={{ product_value_arr: product_value_arr }}
              to={advance_to}
            >
              <span className="main-body-top-tab-bar-left-col-a-text">
                {t("Advanced")}
              </span>
            </Link>

            <Link
              state={{ product_value_arr: product_value_arr }}
              className={'main-body-top-tab-bar-left-col-a nav-link ' + selectedTopNavProduct.variant + ' ' + varientShow}
              to={"/app/product/productVariant/" + product_id}
            >
              <span className="main-body-top-tab-bar-left-col-a-text">
                {t("Variant")}
              </span>
            </Link>


            <Link
              state={{ product_value_arr: product_value_arr }}
              className={generalsettings?.data?.generalsetup?.productChoice === '1' ? 'main-body-top-tab-bar-left-col-a nav-link ' + selectedTopNavProduct.choice + ' ' + allShow : "d-none"}
              to={"/app/product/productChoice/" + product_id}
            >
              <span className="main-body-top-tab-bar-left-col-a-text">
                {t("Choice")}
              </span>
            </Link>

          </div>
          <div className="main-body-top-tab-bar-right-col tab-bar-save-icon">
            <div className="dropdown product-edit-detail">
              <button
                className="btn btn-secondary dropdown-toggle product-edit-detail-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={base_assets + "images/icons/note-board-icon-dark.png"} alt="" />
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {logs_data?.map((ele, index) => {
                  return (
                    <li key={index}>
                      <div className="product-edit-detail-single">
                        {ele.msg}
                      </div>
                      <div className="product-edit-detail-single">
                        <span className="product-edit-detail-single-date">
                          {formatDate(ele.date)}
                        </span>
                        <span>
                          <i className="fa fa-user-o" aria-hidden="true">
                          </i>  &nbsp;
                          {ele.name}
                        </span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
            {selectedButton === '' || selectedButton === 'ErrorShowBtn' ?
              <button className="main-body-top-tab-bar-right-col-icon">
                <img
                  className="save-icon"
                  src={base_assets + "images/icons/save-icon.png"} alt=""
                />
              </button> : <img
                className="save-icon"
                onClick={() => setButton('')}
                src={base_assets + "images/icons/edit-pencil.png"} alt=""
              />}
            <Link className="product-edit-detail-single-edit-data" to="/app/product/main">
              <button className="main-body-top-tab-bar-right-col-icon">
                <img src={base_assets + "images/icons/cross-bg.png"} alt="" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductMenu;
