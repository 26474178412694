import React, { useState } from 'react'
import Select from 'react-select';
import blockchainImg from "./assets/images/blockchain.png"
import axios from "axios";
import { useTranslation } from "react-i18next";

const BrochureModel = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { countries } = props
  const [brochureErrors, setBrochureErrors] = useState();
  const [modelFromData, setModelFormData] = useState({
    'name': '',
    'email': '',
    'business': [],
    'country': '',
    'phoneCode': '66',
    'phone': '',
  });
  const [brochureStatus, setBrochureStatus] = useState({
    data: "",
    status: false
  });

  const checkboxinput = (e) => {
    let array = { ...modelFromData };
    let checkarray = array?.business;

    if (e.target.checked) {
      setBrochureErrors({});
      if (e.target.value === 'Others') {
        let inputvalue = document.querySelector(".brochureOthercheck");
        checkarray.push({ 'inputvalue': inputvalue.value })
      } else {
        checkarray.push(e.target.value);
      }
    } else {
      if (e.target.value === 'Others') {
        checkarray = checkarray.filter(b => typeof b !== 'object');
      } else {
        checkarray = checkarray.filter(b => b !== e.target.value);
      }
    }
    setModelFormData({ ...modelFromData, 'business': checkarray });
  }

  const changeinput = (e) => {
    setBrochureErrors({});
    let array = { ...modelFromData };
    array?.business.forEach((value) => {
      if (typeof value === 'object') {
        value.inputvalue = e.target.value;
      }
    })
    setModelFormData(array);
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const formsubmiturl = "https://adminapi.gis247.net/";

  const brochureDownload = async (e) => {
    let brochure_other_value = document.querySelector(".brochureOthercheck");
    e.preventDefault();
    setBrochureErrors({});
    if (modelFromData.name === "") {
      setBrochureErrors({ "name": true });
      return;
    }
    if (!modelFromData.business.length) {
      setBrochureErrors({ "bussinesscheck": true });
      return;
    }
    if (modelFromData.business.filter(b => typeof b === 'object')[0] && !brochure_other_value.value) {
      setBrochureErrors({ "business": true });
      return;
    }

    if (modelFromData.email === '' || !emailRegex.test(modelFromData.email)) {
      setBrochureErrors({ "email": true });
      return;
    }
    if (!modelFromData.phone) {
      setBrochureErrors({ "phone": true });
      return;
    }
    if (modelFromData.company === "") {
      setBrochureErrors({ "company": true });
      return;
    }
    if (modelFromData.business.filter(b => typeof b === 'object')[0]) {
      modelFromData?.business.forEach((value) => {
        if (typeof value === 'object') {
          modelFromData?.business.push(value.inputvalue);

          ;
        }
      })
      modelFromData['business'] = modelFromData?.business.filter(b => typeof b !== 'object');
    }




    try {
      const response = await axios.post(formsubmiturl + "api/v1/download/brochure", modelFromData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const { data } = response;

      if (data.code === 200) {
        setModelFormData({
          'name': '',
          'email': '',
          'business': [],
          'country': '',
          'phoneCode': '',
          'phone': '',
        });
        setBrochureStatus({
          data: data.message,
          status: true
        });
        const pdfUrl = base_assets + 'GIS_Brochure.pdf';
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'GIS_Brochure.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        brochure_other_value.value = '';


      } else {
        setBrochureStatus({
          data: data.message,
          status: false
        });
      }
    } catch (error) {
      console.error(error);
    }
  }


  const countryChangeHandler = (selectedOption, selectBox) => {
    if (selectBox === 'country') {
      setModelFormData({
        ...modelFromData,
        country: selectedOption ? selectedOption.value : '',
        phoneCode: selectedOption ? selectedOption.code : '',
      });
    } else if (selectBox === 'phoneCode') {
      setModelFormData({
        ...modelFromData,
        phoneCode: selectedOption ? selectedOption.value : '',
      });
    }
  }

  return (
    <div
      className="modal fade common-modal brochureDownloadModal"
      id="brochureDownloadModal"
      tabIndex={-1}
      aria-labelledby="brochureDownloadModalLabel"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className='brochureModel-main d-flex justify-content-center '>
              <div className='brochureModel-main-first-box'>
                <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                  <img
                    src={blockchainImg}
                    className="block-img"
                    alt="imgframe"
                  ></img>
                </div>

              </div>
              <div className="contact-form brochureModel-main-second-box">
                <h2 className="contact-form-topHeader brochureModel-main-second-box-headerfirst">{t("Download Brochure")}</h2>
                <p className="contact-form-topPara brochureModel-main-second-box-topPara"> {t("Please feel free for download brochure")}</p>

                <form className="enquiry-from-box">
                  <div className="common-row-show">
                    <div className="col-75 common-row-show-innerBox">
                      <input
                        type="text"
                        id="fname"
                        name="name"
                        placeholder="Name"
                        className={brochureErrors?.name ? "border-red com-input-show required-text-show" : "com-input-show required-text-show"}
                        value={modelFromData.name}
                        onChange={(e) => { setModelFormData({ ...modelFromData, name: e.target.value }); setBrochureErrors({}) }}
                      />
                    </div>
                  </div>

                  <div className="row common-row-show">
                    <label htmlFor="buisnesstype" className="brochure-check-box">
                      {t("Business Type")}
                    </label>
                    <div className="contact-check common-row-show-innerBox">
                      <div className="contact-check-singlerow brochure-check-box-singleRow">
                        <label className="contact text-start">
                          {t("Manufacturer")}
                          <input type="checkbox" className="enquiry-com-checkbox" value='Manufacturer' onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="contact text-start">
                        {t("Retailer")}
                          <input type="checkbox" className="enquiry-com-checkbox" value="Retailer" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="contact-check-singlerow">
                        <label className="contact text-start">
                          {t("Gem Stones /Diamonds")}
                          <input type="checkbox" className="enquiry-com-checkbox" value="Gem Stones /Diamonds" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="contact text-start">
                        {t("Wholesaler")}
                          <input type="checkbox" className="enquiry-com-checkbox" value="Wholesaler" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="contact-check-singlerow">
                        <label className="contact text-start">
                        {t("Agent")}
                          <input type="checkbox" className="enquiry-com-checkbox" value="Agent" onChange={(e) => checkboxinput(e)} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="contact other-checkmain text-start">
                        {t("Others")} :
                          <input type="checkbox" className="enquiry-com-checkbox" value="Others" onChange={(e) => checkboxinput(e)} />
                          <input
                            onChange={(e) => changeinput(e)}
                            className={brochureErrors?.business ? "border-red  brochureOthercheck" : " brochureOthercheck"}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className={brochureErrors?.bussinesscheck ? " business-error-show d-block text-start p-0" : " d-none "}>{t("Please Select the Business Role")}</div>
                  </div>

                  <div className="row common-row-show">
                    <div className="col-25 common-row-show-innerBox"></div>
                    <div className="col-75 common-row-show-innerBox">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className={brochureErrors?.email ? "border-red  com-input-show " : "com-input-show"}
                        value={modelFromData.email}
                        onChange={(e) => { setModelFormData({ ...modelFromData, email: e.target.value }); setBrochureErrors({}) }}
                      />
                    </div>
                  </div>

                  <div className="row common-row-show">
                    <div className="col-25 common-row-show-innerBox"></div>
                    <div className="col-75 common-row-show-innerBox">
                      <Select
                        placeholder="Country"
                        className="country-selector text-start"
                        options={countries.map((country) => ({
                          value: country.name,
                          label: country.name,
                          code: country.phoneCode
                        }))}
                        value={modelFromData.country ? { label: modelFromData.country, value: modelFromData.country, code: modelFromData.phoneCode } : null}
                        onChange={(selectedOption) => countryChangeHandler(selectedOption, 'country')}
                      />
                    </div>
                  </div>


                  <div className="row common-row-show">
                    <div className="col-75 common-row-show-innerBox">
                      <div className={brochureErrors?.phone ? "border-red phone-tab" : "phone-tab"}>
                        <Select
                          placeholder="66"
                          className="select-phone"
                          options={countries.map((country) => ({
                            value: country.phoneCode,
                            label: country.phoneCode,
                          }))}
                          value={modelFromData.phoneCode ? { label: modelFromData.phoneCode, value: modelFromData.phoneCode } : null}
                          onChange={(selectedOption) => countryChangeHandler(selectedOption, 'phoneCode')}
                        />
                        <input
                          type="number"
                          className="phone-input com-input-show"
                          id="phone"
                          name="phone"
                          placeholder="Phone"
                          value={modelFromData.phone}
                          onChange={(e) => { setModelFormData({ ...modelFromData, phone: e.target.value.slice(0, 10) }); setBrochureErrors({}) }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    {brochureStatus?.status ?
                      <p className="p-0 mb-0 text-success" >{brochureStatus?.data}</p> :
                      <p className="p-0 mb-0 text-danger" >{brochureStatus?.data}</p>
                    }
                  </div>
                  <button className="contact-common-button mt-4" onClick={brochureDownload} >{t("Download Brochure")}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrochureModel