import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const CopyToAll = (props) => {
  const { t } = useTranslation()
  const { data_currency, setcurrency } = props;

  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;

  useEffect(() => { }, []);
  const handleClick_checkBox = (key, index) => {
    var data_currency_ = [...data_currency];
    data_currency_[index][key] = document.getElementById(key + index).checked;
    setcurrency(data_currency_);
  };


  const Resetvaluesssss = () => {
    let keysss = "stock_check";
    let keysssprice = "check_price";
    var productChoiceData_ = [...data_currency];
    if (productChoiceData_) {
      productChoiceData_?.forEach((element, index) => {
        document.getElementById(keysss + index).checked = false;
        document.getElementById(keysssprice + index).checked = false;
        element.check_price = false;
        element.stock_check = false;
      })
    }
    setcurrency(productChoiceData_);

  };

  return (
    <React.Fragment key="1">
      <div
        className="modal fade copyallmodal"
        id="CopyAllModal"
        tabIndex={-1}
        aria-labelledby="CopyAllModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h1 className="copyallmodal-heading">
                {t("Use same values as first variant for all?")}
              </h1>
              <p className="copyallmodal-para">
                {t("This will copy first varient and use it to add or replace")} <br />{" "}
                {t("values for all variants.")}
              </p>

              <div className="copyallmodal-country">
                <label className="copyallmodal-country-label">{t("Price")}</label>
                <div className="copyallmodal-country-select-row">
                  {data_currency?.map((element, index) => {
                    if (element.check_price === undefined) {
                      element.check_price = false;
                    }

                    return (
                      <React.Fragment key={index}>
                        <div className="copyallmodal-country-select-row-single">
                          <div className="com-check">
                            <input
                              type="checkbox"
                              onChange={() =>
                                handleClick_checkBox("check_price", index)
                              }
                              id={"check_price" + index}
                            />
                            <label
                              className="com-check-label"
                              htmlFor={"check_price" + index}
                            ></label>
                            <img
                              src={
                                base_assets +
                                "images/flags/" +
                                element?.currency?.toLowerCase() +
                                ".png"
                              } alt=""
                            />
                            <label htmlFor={"check_price" + index}>
                              {" "}
                              <span className="copyallmodal-country-name">
                                {element.currency}
                              </span>
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div className="copyallmodal-country">
                <label className="copyallmodal-country-label">{t("Stocks")}</label>
                <div className="copyallmodal-country-select-row">
                  {data_currency?.map((element, index) => {
                    if (element.stock_check === undefined) {
                      element.stock_check = false;
                    }

                    return (
                      <React.Fragment key={index}>
                        <div className="copyallmodal-country-select-row-single">
                          <div className="com-check">
                            <input
                              type="checkbox"
                              id={"stock_check" + index}
                              onChange={() =>
                                handleClick_checkBox("stock_check", index)
                              }
                              checked={element.price_check}
                            />
                            <label
                              className="com-check-label"
                              htmlFor={"stock_check" + index}
                            ></label>
                            <img
                              src={
                                base_assets +
                                "images/flags/" +
                                element?.currency?.toLowerCase() +
                                ".png"
                              } alt=""
                            />
                            <label htmlFor={"stock_check" + index}>
                              {" "}
                              <span className="copyallmodal-country-name">
                                {element.currency}
                              </span>{" "}
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div className="copyallmodal-btn-row">
                <button
                  type="button"
                  className="btn copyallmodal-btn-row-cancel"
                  data-bs-dismiss="modal"
                >
                  {" "}
                  {t("cancel")}
                </button>
                <button data-bs-dismiss="modal" type="button" onClick={() => Resetvaluesssss()} className="btn">

                  {t("yes")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CopyToAll;
