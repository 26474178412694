import React, { useRef, useState, useEffect, useCallback } from 'react';
import { _Api } from "../api/_call";
import { Link, useLocation } from 'react-router-dom';
import DateTime from "../common/DateTime";
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import Select from "react-select";
import * as XLSX from 'xlsx';
import TableLoader from '../../admin/common/TableLoader';
const SkuMovementdetails = (props) => {
    const dataFetched = useRef();
    const { t } = useTranslation();
    const { state } = useLocation();
    const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
    const [filtername, setFiltername] = useState('');
    const [limit, setLimit] = useState(100);
    const [skumovementlist, setskumovementlist] = useState([])
    const [lowercount, setLowercount] = useState()
    const [datalodaer, setDatsLodaer] = useState(false)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const selectOptions = [
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 300, value: 300 }
    ]


    const downloadexclsheet = async () => {
        let header_values = [];
        let bodyarray = [];
        header_values = ["", "Stock id", "Location", "Date", "Type", "Ref no", "Decription", "Inward Qty", "Inward price", "Inward Amount", "Outward Oty", "Outward Price", "Outward Amount", "Outward Currency", "Outward Forex", "Closing Qty", "Closing Price", "Closing Amount", "Remark"]
        if (skumovementlist.length) {
            skumovementlist.forEach((result, key) => {
                bodyarray.push([key + 1, result?.StockID, result?.Location, result?.date, result?.voucher_type, result?.refNo, result?.description, result?.inward?.qty, result?.inward?.price, result?.inward?.amount_total, result?.outward?.qty,
                result?.outward?.price, result?.outward?.amount_total, result?.outward?.currency, result?.outward?.forex,
                result?.closing?.qty, result?.closing?.price, result?.closing?.amount_total, result?.remark
                ])
            })
        }
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
        const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([fileContent], { type: fileType });
        FileSaver.saveAs(data, "SKU MOVEMENT DETAILS");
    }
    const getskumovementdetails = useCallback(async (firstlimit, search) => {
        setDatsLodaer(true)
        try {
            const postdata = {
                SKU: state?.SKU,
                "search": search ? search : "",
                limit: firstlimit ? firstlimit : limit
            }
            let resPoinventory = await _Api(postdata, `api/v1/Inventory/productMovement/StockIDMovement`)
            if (resPoinventory?.code === 200) {
                setDatsLodaer(false)
                setskumovementlist(resPoinventory?.data?.respones)
                setLowercount(resPoinventory?.data?.count)
            }
        } catch (err) {
            console.log(err, 'err')
        }
    }, [limit, state]);
    const filterbyname = (search) => {
        if (search) {
            getskumovementdetails(100, search)
        } else {
            getskumovementdetails(100, search)
        }
    }
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true;
        getskumovementdetails()
    }, [getskumovementdetails])
    return (
        <React.Fragment>
            <div className="main-body report-page ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 skumovementdetails">
                            <span className="main-body-current-day-time">
                                <DateTime></DateTime>
                            </span>
                            <div className="top-heading-area">
                                <div className="top-heading-area-left">
                                    <Link to="/app/inventory/skumovement" className="page-back-btn"><img src={base_url_assets + "images/icons/arrow-left.png"} alt="back" /></Link>
                                    <h2 className="main-body-heading">{t("SKU Movement")}</h2>
                                </div>
                            </div>
                            <div className='report-location-header mb-5'>
                                <span className='report-location-header-location'>{t("SKU")}</span>
                                <span className='report-location-header-divider'>|</span>
                                <span className='report-location-header-location-sub'>{state?.SKU}</span>
                            </div>
                            <div className="">
                                <div id="" className="create-purchase-top-bar">
                                    <div className="main-body-top-status-bar">
                                        <Select

                                            className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                                            options={selectOptions}
                                            value={{ label: limit, value: limit }}
                                            onChange={(e) => { setLimit(e.value); getskumovementdetails(e.value) }}
                                            isSearchable={false}

                                        />
                                        <div className="main-body-top-status-bar-filter">
                                            <input
                                                type="text"
                                                value={filtername}
                                                className="main-body-top-status-bar-filter-input"
                                                placeholder={t("Stock ID")}
                                                onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                                                onChange={(e) => setFiltername(e.target.value)}
                                            />
                                            {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_url_assets + 'images/icons/False.png'} onClick={(e) => {
                                                setFiltername("");
                                                getskumovementdetails(100, "")
                                            }} alt="" /> : ""}
                                            <button className="main-body-top-status-bar-filter-search"
                                                onClick={() => filterbyname(filtername)}>
                                                <i className="fa fa-search" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="main-body-top-status-bar-multi-date-picker">
                                            <button className="print-export-dropdown-selected-icon d-none">
                                                <img
                                                    src={base_url_assets + "images/icons/printer-icon.png"}
                                                    alt=""
                                                />
                                            </button>
                                            <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                                                <button
                                                    className="btn dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    {" "}
                                                    <img
                                                        src={base_url_assets + "images/icons/ellipsis-circular.png"}
                                                        alt=""
                                                    />{" "}
                                                </button>
                                                <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                >
                                                    <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                                                        <div className="dropdown-item-img">
                                                            <img
                                                                src={base_url_assets + "images/icons/export-x-icon.png"}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>{t("export")}</span>
                                                    </li>
                                                    <li
                                                        className="dropdown-item"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#columnModal"
                                                    >
                                                        <div className="dropdown-item-img">
                                                            <img
                                                                src={base_url_assets + "images/icons/column-icon.png"}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>{t("column")}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="common-sub-table-wrap add-row dataTables_scroll dtfc-has-left">
                                        <div className="main-body-main-table-wrap table-responsive">
                                            <table
                                                id="vendor_purchase_table"
                                                className="common-table dataTables_scrollFoot w-100"
                                            >
                                                <thead>
                                                    <tr className="">
                                                        <th rowSpan="2" className="dark-gray-bg border border-light white-color">{t("Stock ID")}</th>
                                                        <th rowSpan="2" className="dark-gray-bg border border-light white-color">{t("Location")}</th>
                                                        <th rowSpan="2" className="dark-gray-bg border border-light white-color">{t("Date")}
                                                        </th>
                                                        <th rowSpan="2" className="dark-gray-bg border border-light white-color" >{t("Type")}
                                                        </th>
                                                        <th rowSpan="2" className="dark-gray-bg border border-light white-color">{t("Ref No")}
                                                        </th>
                                                        <th rowSpan="2" className="dark-gray-bg border border-light white-color">
                                                            {t("Decription")}</th>
                                                        <th colSpan="3" className="status-completed border border-light white-color">
                                                            {t("Inward")}</th>
                                                        <th colSpan="5" className="status-pending border border-light white-color">
                                                            {t("Outward")}</th>
                                                        <th colSpan="3" className="mgf-stock-bg border border-light white-color">{t("Closing")}</th>
                                                        <th rowSpan="2" className="dark-gray-bg border border-light white-color">{t("Remark")}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="skumovementdetails-table-inword white-color">{t("Qty")}</th>
                                                        <th className="skumovementdetails-table-inword white-color">{t("Price")}</th>
                                                        <th className="skumovementdetails-table-inword white-color">{t("Amount")}</th>
                                                        <th className="skumovementdetails-table-outword white-color">{t("Qty")}</th>
                                                        <th className="skumovementdetails-table-outword white-color">{t("Price")}</th>
                                                        <th className="skumovementdetails-table-outword white-color">{t("Amount")}</th>
                                                        <th className="skumovementdetails-table-outword white-color">{t("Currency")}</th>
                                                        <th className="skumovementdetails-table-outword white-color">{t("Forex")}</th>
                                                        <th className="skumovementdetails-table-Closing white-color">{t("Qty")}</th>
                                                        <th className="skumovementdetails-table-Closing white-color"> {t("Price")}</th>
                                                        <th className="skumovementdetails-table-Closing white-color">{t("Amount")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <>
                                                        {
                                                            skumovementlist?.length ? skumovementlist.map((result, key) => {
                                                                return (
                                                                    <tr className="" key={key}>
                                                                        <td className="fixed-side">{result?.StockID ? result?.StockID : 0}</td>
                                                                        <td className="fixed-side link-color">
                                                                            {result?.Location ? result?.Location : "-"}</td>
                                                                        <td className="fixed-side">{result?.date ? result?.date : "-"}</td>
                                                                        <td className="fixed-side">{result?.voucher_type ? result?.voucher_type : "-"}</td>
                                                                        <td className="fixed-side">{result?.refNo ? result?.refNo : "-"}</td>
                                                                        <td className="fixed-side">{result?.description ? result?.description : "-"}</td>
                                                                        <td className="fixed-side">{result?.inward?.qty ? result?.inward?.qty : 0}</td>
                                                                        <td className="fixed-side">
                                                                            {result?.inward?.price ? result?.inward?.price : 0} </td>
                                                                        <td className="fixed-side">{result?.inward?.amount_total ? result?.inward?.amount_total : 0}</td>
                                                                        <td className="fixed-side">{result?.outward?.qty ? result?.outward?.qty : 0}</td>
                                                                        <td className="fixed-side">{result?.outward?.price ? result?.outward?.price : 0}</td>
                                                                        <td className="fixed-side">{result?.outward?.amount_total ? result?.outward?.amount_total : 0}</td>
                                                                        <td className="fixed-side">{result?.outward?.currency ? result?.outward?.currency : "-"}</td>
                                                                        <td className="fixed-side">{result?.outward?.forex ? result?.outward?.forex : 0}</td>
                                                                        <td className="fixed-side">{result?.closing?.qty ? result?.closing?.qty : 0}</td>
                                                                        <td className="fixed-side">{result?.closing?.price ? result?.closing?.price : 0}</td>
                                                                        <td className="fixed-side">{result?.closing?.amount_total ? result?.closing?.amount_total : 0}</td>
                                                                        <td className="fixed-side">{result?.remark ? result?.remark : "-"}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                                : ""}
                                                    </>
                                                </tbody>
                                                <tfoot >
                                                    <tr>
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td>{lowercount?.inward_Qty ? lowercount?.inward_Qty : 0}</td>
                                                        <td />
                                                        <td>{lowercount?.inward_amount ? lowercount?.inward_amount : 0}</td>
                                                        <td>{lowercount?.outward_Qty ? lowercount?.outward_Qty : 0}</td>
                                                        <td />
                                                        <td>{lowercount?.outward_amount ? lowercount?.outward_amount : 0}</td>
                                                        <td />
                                                        <td />
                                                        <td>{lowercount?.closing_Qty ? lowercount?.closing_Qty : 0}</td>
                                                        <td />
                                                        <td >{lowercount?.closing_amount ? lowercount?.closing_amount : 0}</td>
                                                        <td ></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            {datalodaer && <TableLoader />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );


}

export default SkuMovementdetails;