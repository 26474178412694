import React from 'react'

const ShareIcon = (props) => {
    const { width, height } = props;
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12L13.6 5V8.5C10.4 8.5 4 10.6 4 19C4 17.833 5.92 15.5 13.6 15.5V19L20 12Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    )
}

export default ShareIcon