import React, { useRef, useState, useEffect, useCallback } from 'react'
import RightArrow from "../../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker'
import "./email.scss"
import Select from "react-select";
import RichTextEditor from './TextComp'
import { Link, useLocation } from 'react-router-dom'
import { _Api, graphqlPost } from "../../api/_call";
import { store } from '../../../source/index'
import { loading, Updateloading } from '../../common/helpers/toastify'
const EmailTemplate = () => {
  const dataFetched = useRef();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [availableTags, setAvailableTags] = useState([]);
  const [tagsOption, setTagsOption] = useState([])
  const [LocationarrayList, setLocationarrayList] = useState([]);
  const [completeDataObject, setCompliteDataObject] = useState()
  const [editorValue, setEditorValue] = useState();
  const onChangeValueUpdate = (keyName, value) => {
    setCompliteDataObject(prevState => ({
      ...prevState,
      [keyName]: value
    }));
  }
  const getLocationList = async () => {
    try {
      const postdata = `{
          locations {
              id
              name 
          }
      }`;
      let responce = await graphqlPost(postdata);
      let locationarray = [];

      if (responce.data?.locations) {
        responce.data?.locations?.forEach((result) => {
          locationarray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      }

      setLocationarrayList(locationarray);

    } catch (err) {
      console.log(err, "err");

    }
  };
  const gettypes = async (shortcode) => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/App/emailtemplate/get-types`)
      if (res.code === 200) {
        let tages = []
        if (res?.data?.length) {
          res?.data.forEach((result, key) => {
            tages.push({
              label: result?.name,
              value: result?.shortcode,
              _id: result?._id
            })
            if (state?.id) {
              if (shortcode === result?.shortcode) {
                setCompliteDataObject(prevState => ({
                  ...prevState,
                  availabletags: {
                    label: result?.name,
                    value: result?.shortcode,
                    _id: result?._id
                  }
                }));
              }
            }
          })
        }
        setTagsOption(tages)
        dataFetched.current = false;
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const gettypeid = async () => {
    try {
      let postdata = {
        _id: completeDataObject?.availabletags?._id
      }
      let res = await _Api(postdata, `api/v1/App/emailtemplate/get-typesbyid`)
      if (res.code === 200) {
        setCompliteDataObject(prevState => ({
          ...prevState,
          is_recipient: res?.data?.is_recipient ? res?.data?.is_recipient : 0,
          is_start_date: res?.data?.is_start_date ? res?.data?.is_start_date : 0
        }));
        setAvailableTags(res?.data?.avaialable_tags)
        dataFetched.current = false;
      }

    }
    catch (err) {
      console.log(err, "err")
    }
  }

  const viewemail = useCallback(async () => {
    try {
      let postdata = {
        id: state?.id
      }
      let res = await _Api(postdata, `api/v1/App/emailtemplate/view`)
      if (res?.code === 200) {
        setCompliteDataObject(res?.data)
        gettypes(res?.data?.shortcode)
        dataFetched.current = false;
      }
    }
    catch (err) {
      console.log(err, "err")
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (completeDataObject?.availabletags?._id) {
      if (dataFetched.current) return;
      dataFetched.current = true;
      gettypeid()
    }
    if (state?.id) {
      if (dataFetched.current) return;
      dataFetched.current = true;
      viewemail()
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      gettypes()
    }
    getLocationList()
    // eslint-disable-next-line
  }, [state?.id, completeDataObject?.availabletags?._id])

  var date_format = store.getState()
  var OrgSettings = date_format?.app?.organisation_settings
  const date_format_change = (date_format) => {
    var response
    if (date_format === 'DD/MM/YYYY') {
      response = 'dd/MM/yyyy'
    } else if (date_format === 'YYYY/MM/DD') {
      response = 'yyyy/MM/dd'
    } else if (date_format === 'MM/DD/YYYY') {
      response = 'MM/dd/yyyy'
    } else if (date_format === 'MM/YYYY/DD') {
      response = 'MM/yyyy/dd'
    }
    return response
  }
  const addemail = async () => {
    let locationarr = []
    if (completeDataObject?.locations?.length) {
      locationarr = completeDataObject?.locations.map(option => option.value)
    }
    let GetloadingID = loading()
    try {
      let postdata = {
        "subject": completeDataObject?.subject,
        "content": editorValue,
        "locations": locationarr,
        "shortcode": completeDataObject?.availabletags?.value,
        "from_name": completeDataObject?.from_name,
        "from_email": completeDataObject?.from_email,
        "status": completeDataObject?.status,
        "is_recipient": completeDataObject?.is_recipient ? completeDataObject?.is_recipient : 0,
        "is_start_date": completeDataObject?.is_start_date ? completeDataObject?.is_start_date : 0,
        "recipient_emails": "",
        "startAt": completeDataObject?.startAt ? completeDataObject?.startAt : "",
      }
      let res = await _Api(postdata, `api/v1/App/emailtemplate/add`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, res.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const editemail = async () => {
    let locationarr = []
    if (completeDataObject?.locations?.length) {
      locationarr = completeDataObject?.locations.map(option => option.value)
    }
    let GetloadingID = loading()
    try {
      let postdata = {
        "subject": completeDataObject?.subject,
        "content": editorValue,
        "locations": locationarr,
        "shortcode": completeDataObject?.availabletags?.value,
        "from_name": completeDataObject?.from_name,
        "from_email": completeDataObject?.from_email,
        "status": completeDataObject?.status,
        "is_recipient": completeDataObject?.is_recipient ? completeDataObject?.is_recipient : 0,
        "is_start_date": completeDataObject?.is_start_date ? completeDataObject?.is_start_date : 0,
        "recipient_emails": "",
        "startAt": completeDataObject?.startAt ? completeDataObject?.startAt : "",
        "id": state?.id
      }
      let res = await _Api(postdata, `api/v1/App/emailtemplate/edit`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, res.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }

  }
  return (
    <>
      <div className="main-content-wrapper-body">
        <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
          <div className="main-content-wrapper-body-top-bar-left">
            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0"> {t("Setup")} <RightArrow />
              <Link className="text-decoration-none" to="/app/admin/setup/email">{t("Email")}</Link>  <RightArrow /> <span className="">{t("Create Email Template")}</span></h1>
          </div>
          <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">


            <button className="com-plus-text-btn text-white text-center fw-semibold d-flex align-items-center border-0 rounded-1 ms-3"
              onClick={() => { state?.id ? editemail() : addemail() }}>
              <i className="fa fa-upload me-2" aria-hidden="true"></i>{t("Publish To Template")}
            </button>
            <Link to="/app/admin/setup/email" className="square-btn square-btn-white btn-close focus-none text-decoration-none bg-white"></Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="bg-white p-4 rounded-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Available Tags")} </label>
                        <Select
                          className="select-set select w-100 p-0"
                          placeholder=""
                          options={tagsOption}
                          value={completeDataObject?.availabletags}
                          onChange={(e) => { onChangeValueUpdate("availabletags", e) }}
                          classNamePrefix="common-select"
                          isSearchable={true}
                          getOptionLabel={(opt) => opt.label}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 offset-md-2">
                      <div className="v3-input-group">
                        <label className="v3-input-group-label">{t("Status")}</label>
                        <div className="form-check form-switch ps-0 d-flex align-items-center">
                          <label className="form-check-label" htmlFor="mixmatch">{t("Active")}</label>
                          <input className="form-check-input float-none ms-2" type="checkbox" id="mixmatch"
                            checked={completeDataObject?.status ? true : false}
                            onChange={(e) => onChangeValueUpdate("status", e.target.checked ? 1 : 0)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("From Name")} :</label>
                    <input type="text" className="v3-input-group-input w-100"
                      value={completeDataObject?.from_name || ''}
                      onChange={(e) => onChangeValueUpdate("from_name", e.target.value)} />
                  </div>
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("From Email")} :</label>
                    <input type="text" className="v3-input-group-input w-100"
                      value={completeDataObject?.from_email || ''}
                      onChange={(e) => onChangeValueUpdate("from_email", e.target.value)} />
                  </div>
                  {completeDataObject?.is_recipient ?
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Recipient Emails")} :</label>
                      <input type="text" className="v3-input-group-input w-100"
                        value={completeDataObject?.recipient_emails || ''}
                        onChange={(e) => onChangeValueUpdate("recipient_emails", e.target.value)} />
                    </div> : ''
                  }
                  {completeDataObject?.is_start_date ?
                    <div className="v3-input-group">
                      <label className="v3-input-group-label">{t("Date")} :</label>
                      <DatePicker
                        className="placeholder-up-input-field v3-input-group-input w-100"
                        selected={completeDataObject?.startAt}
                        onChange={(e) => onChangeValueUpdate("startAt", e)}
                        name=""
                        dateFormat={date_format_change(
                          OrgSettings?.date_format,
                        )}
                        placeholderText={'DD/MM/YYYY'}
                        autoComplete="off"
                      />
                    </div> : ''
                  }
                  <div className="v3-input-group">
                    <label className="v3-input-group-label">{t("Subject")} :</label>
                    <textarea rows="3" className="v3-input-group-input w-100 h-auto"
                      value={completeDataObject?.subject}
                      onChange={(e) => onChangeValueUpdate("subject", e.target.value)} />
                  </div>
                  <div className="v3-input-group ">
                    <label className="v3-input-group-label">{t("Location")}</label>
                    <Select
                      isMulti
                      options={LocationarrayList}
                      value={completeDataObject?.locations}
                      onChange={(e) => onChangeValueUpdate("locations", e)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Location"
                      isSearchable="false"
                    />
                  </div>
                  <div className="v3-input-group available-tags">
                    <label className="v3-input-group-label">{t("Available Tags")}</label>
                    <RichTextEditor availableTags={availableTags} setEditorValue={setEditorValue} editorValue={editorValue} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default EmailTemplate;