import React from 'react'
import './setting.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import plug from "../../assets/images/icons/plug.png"
const SetupSetting = () => {
  const { t } = useTranslation();
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <span>{t("Settings")}</span>
          </h1>
        </div>
      </div>
      <div className="admin-main-card">
        <div className="admin-setting-grid d-flex align-items-center justify-content-center gap-4 flex-wrap mx-auto">
          {/* comment by deepak sir  */}
          {/* <Link
            to="/app/admin/settings/cloudsetting"
            className="admin-setting-grid-single d-flex align-items-center justify-content-center flex-column border-green main-green-text text-decoration-none"
          >
            <i
              className="fa fa-wrench admin-setting-grid-single-icon"
              aria-hidden="true"
            ></i>
            <label>{t("Cloud setting")}</label>
          </Link> */}
          <Link
            to="/app/admin/settings/smtpemail"
            className="admin-setting-grid-single d-flex align-items-center justify-content-center flex-column border-green main-green-text text-decoration-none"
          >
            <i
              className="fa fa-envelope admin-setting-grid-single-icon"
              aria-hidden="true"
            ></i>
            <label>{t("SMTP Email")}</label>
          </Link>
          <Link
            to="/app/admin/settings/import"
            className="admin-setting-grid-single d-flex align-items-center justify-content-center flex-column border-green main-green-text text-decoration-none"
          >
            <i
              className="fa fa-download admin-setting-grid-single-icon"
              aria-hidden="true"
            ></i>
            <label>{t("Import")}</label>
          </Link>
          <Link
            to="/app/admin/settings/export"
            className="admin-setting-grid-single d-flex align-items-center justify-content-center flex-column border-green main-green-text text-decoration-none"
          >
            <i
              className="fa fa-upload admin-setting-grid-single-icon"
              aria-hidden="true"
            ></i>
            <label>{t("Export")}</label>
          </Link>
          <Link
            to="/app/admin/settings/language"
            className="admin-setting-grid-single d-flex align-items-center justify-content-center flex-column border-green main-green-text text-decoration-none"
          >
            <i
              className="fa fa-language admin-setting-grid-single-icon"
              aria-hidden="true"
            ></i>
            <label>{t("Language")}</label>
          </Link>
          <Link
            to="/app/admin/settings/currency"
            className="admin-setting-grid-single d-flex align-items-center justify-content-center flex-column border-green main-green-text text-decoration-none"
          >
            <i
              className="fa fa-money admin-setting-grid-single-icon"
              aria-hidden="true"
            ></i>
            <label>{t("Currency")}</label>
          </Link>
          <Link
            to="/app/admin/settings/integratedapplications"
            className="admin-setting-grid-single d-flex align-items-center justify-content-center flex-column border-green main-green-text text-decoration-none"
          >
            <img src={plug}
              style={{ width: "30px" }}
              className="admin-setting-grid-single-icon"
              aria-hidden="true"
              alt=''
            />
            <label className='text-center'>{t("Integrated Applications")}</label>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SetupSetting
