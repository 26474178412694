import React, { useState, useEffect } from 'react'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const CustomerSearchModal = (props) => {
  const { t } = useTranslation();
  const { setSearchid, newcustomer, setCreatecustomer } = props
  const [searchvalue, setSearchvalue] = useState()
  const [searchlist, setSearchlist] = useState('')
  const [show, setShow] = useState(false)
  const callsearchlist = async (event) => {
    try {
      const postdata = {
        search: event.target.value,
      }
      let resSearchlist = await _Api(postdata, `api/v1/App/customer/find`)
      if (resSearchlist.code === 200) {
        setSearchlist(resSearchlist.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getsearchvalue = (event) => {
    var value = event.target.getAttribute('data-search')
    var valueid = event.target.getAttribute('data-id')
    setSearchvalue(value)
    setShow(false)
    setSearchid(valueid)
  }
  useEffect(() => {
    if (newcustomer) {
      setSearchid(newcustomer?.id)
      setSearchvalue(newcustomer?.name)
    }
  // eslint-disable-next-line
  }, [newcustomer])
  
  return (
    <div className="main-body-top-status-bar-filter position-relative ps-1">
      <div className="placeholder-up-input form-floating w-100 h-100 ps-2">
        <input
          type="text"
          value={searchvalue ? searchvalue : ""}
          className="main-body-top-status-bar-filter-input placeholder-up-input-field form-control position-relative border-start-0 ps-0 text-truncate bg-white"
          placeholder="Customer"
          onChange={(e) => {
            setSearchvalue(e.target.value)
            setShow(true)
            callsearchlist(e)
          }}
        />
        <label className="placeholder-up-input-label">{t("Customer")}</label>
      </div>
      <button
        className="main-body-top-status-bar-filter-search"
        data-bs-toggle="modal"
        data-bs-target="#customerSearchDetailModal"
      >
        <i onClick={() => setCreatecustomer(false)} className="fa fa-search" aria-hidden="true"></i>
      </button>
      {show && searchvalue !== '' && searchlist.length > 0 ? (
        <div className="dropdown_search_list">
          <ul className="dropdown_search_list-ul">
            {searchlist
              ? searchlist.map((result, key) => {
                return (
                  <li
                    data-search={result?.name ? result?.name : ''}
                    data-id={result?._id}
                    onClick={(e) => { getsearchvalue(e) }}
                    className="dropdown_search_list-ul-li"
                    key={key}
                  >
                    {result?.name ? result?.name : ''}
                  </li>
                )
              })
              : ''}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default CustomerSearchModal
