import React, { useState, useRef, useEffect } from 'react'
import "./common2.scss"
import SideBanner from "../assets/images/LoginSideBanner.png"
import CrossIcon from '../assets/icons/CrossIcon'
import { _Apiauth } from '../../common/api'
import OtpModal from './OtpModal'
import { useTranslation } from "react-i18next";
const EmailVerify = (props) => {
    const { setOpenmodal, emailverifydata } = props
    const { t } = useTranslation();
    const [resandmessage, setResandmessage] = useState()
    const [showreasndpassword, setShowresandpassword] = useState(false)
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
    const [otp, setOtp] = useState()
    const [reesandtocken, setResandtoken] = useState("")
    const [validation, setValidation] = useState()
    const [lodaer, setLodaer] = useState(false)
    const inputRefs = useRef(Array(6).fill(null).map(() => React.createRef()));
    const handleInput = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].current.focus();
        } else if (value.length === 0 && index > 0) {
            inputRefs.current[index - 1].current.focus();
        }
        setOtp(newInputValues.join(''))
    };
    setTimeout(() => {
        setShowresandpassword(true)
    }, 15000);
    // eslint-disable-next-line
    const getemailverify = async () => {
        try {
            setLodaer(true)
            let postdata = {
                "otp": otp,
                "accessToken": reesandtocken ? reesandtocken : emailverifydata?.accessToken
            }
            let res = await _Apiauth(postdata, `auth/verify-user`)
            if (res?.statusCode === 200) {
                setLodaer(false)
                localStorage.setItem('ecomaccesstoken', (res?.accessToken));
                setOpenmodal(prevState => ({
                    ...prevState,
                    showLoginModel: false,
                    showRegisterModel: false,
                    showemailvarifymodal: false,
                    forgetpassword: false,
                    resetpassword: false,
                    newpassword: false
                }));
            } else {
                setLodaer(false)
            }
        }
        catch (err) {
            setLodaer(false)
            console.log(err, "err")
            setValidation(prevState => ({
                ...prevState,
                sussess: {
                    message: err.response.data.message,
                    status: true
                }
            }));
        }
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                getemailverify();
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line
    }, [getemailverify]);

    return (
        <div className='ecommerce-login-overlay'>
            <div className='ecommerce-login'>
                <span className='Cross_icon'
                    onClick={() => {
                        setOpenmodal(prevState => ({
                            ...prevState,
                            showLoginModel: false,
                            showRegisterModel: false,
                            showemailvarifymodal: false,
                            forgetpassword: false,
                            resetpassword: false,
                            newpassword: false
                        }));
                    }} >
                    <CrossIcon width={30} height={30} />
                </span>
                <div className='ecommerce-login-inner'>
                    <div className='formdata'>
                    <img className='ecommerce-login-inner-logo' src={SideBanner} alt='side_banner' />
                    <div className='ecommerce-login-inner-title text-center'>
                        <div className='ecommerce-login-inner-title-heading'>{t("Email Verification")}</div>
                        <div className='ecommerce-login-inner-title-sub-title'>{t("Please enter the 6-digit verification code")}.<br />
                            {t("that was sent to your email")}.</div>
                    </div >
                    <div className='ecommerce-login-inner-input_section  w-75 mx-auto'>
                        <div className="ecommerce-login-inner-input_section-inner">
                            <div className='d-flex gap-2 justify-content-center'>
                                {inputValues.map((value, index) => (
                                    <input
                                        key={index}
                                        className="ecommerce-login-inner-input_section-inner-input w-25 text-center"
                                        maxLength="1"
                                        value={value}
                                        onChange={(e) => handleInput(index, e.target.value)}
                                        ref={inputRefs.current[index]}
                                    />
                                ))}
                            </div>
                        </div>
                        {validation?.sussess?.status ?
                            <div className='text-danger validation-message'>{validation?.sussess?.message}</div> : ""
                        }
                        {lodaer ?
                            <button className='ecommerce-login-inner-input_section-btn'>
                                <div className="border-bottom-0 border-width-2px text-white d-block mx-auto spinner-border flex-shrink-0" role="status"></div></button> :
                            <button className='ecommerce-login-inner-input_section-btn' onClick={() => getemailverify()}>{t("Continue")}</button>
                        }

                        {showreasndpassword ?
                            <div className='ecommerce-login-inner-create-account mt-4'>
                                <div className='ecommerce-login-inner-create-account-register_btn'>
                                    {t("Didn’t receive the email")}?
                                    <span data-bs-toggle="modal" data-bs-target="#emailotpModal" onClick={() => { setResandmessage(""); setValidation(""); setInputValues(['', '', '', '', '', '']) }}>
                                        {t("Click to resend")}
                                    </span>
                                </div>
                            </div> : ""}

                        <div className='text-center mt-2 spinnercolor'>{resandmessage}</div>

                    </div>
                    <div className='ecommerce-login-inner-create-account'>
                        <div className='ecommerce-login-inner-create-account-register_btn'>
                            {t("Already have an account")}?
                            <span onClick={() => {
                                setOpenmodal(prevState => ({
                                    ...prevState,
                                    showLoginModel: true,
                                    showRegisterModel: false,
                                    showemailvarifymodal: false,
                                    forgetpassword: false,
                                    resetpassword: false,
                                    newpassword: false
                                }));
                            }}>
                                {t("SIGN IN")}
                            </span>
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice'>
                            {t("By continuing, I confirm that I have read and agree to the")}
                        </div>
                        <div className='ecommerce-login-inner-create-account-privacy_notice no_padding'>
                            {t("Privacy Notice")}{t("and Term and Conditions")}
                        </div>
                    </div>
                    </div>
                </div >
                <div className='ecommerce-login-sidebanner'>
                    <div className='ecommerce-login-sidebanner-holder'>
                        <img src={SideBanner} alt='side_banner' />
                    </div>
                </div >
            </div >
            <OtpModal emailverifydata={emailverifydata} setResandmessage={setResandmessage} setResandtoken={setResandtoken} />
        </div>

    )
}

export default EmailVerify