import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { _Api } from '../../common/api/_call'
import '../custom.scss'
import CheckoutBlank from '../../checkout/checkoutBlank'
import 'rc-slider/assets/index.css'
import { DaimondSearchModal } from '../../common/module/daimondsearch'
import { useTranslation } from 'react-i18next'

const MixAndMatch = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [items, setItem] = useState()
  const getitem = async () => {
    try {
      const posdata = { type: 'mixAndMatch' }
      let res = await _Api(posdata, `api/v1/POS/home/itemList`)
      if (res.code === 200) {
        setItem(res?.data)
      } else {
        setItem([])
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getitem()
  }, [])
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area bg-white page-menu-block">
              <div className="common-navigation-header">
                <div className="common-navigation-header-left">
                  <h3 className="common-navigation-header-left-heading">
                    {' '}
                    <Link
                      to="/app/pos/custom/custom"
                      className="gray-mid text-decoration-none"
                    >
                      {t('CUSTOM ORDER')}{' '}
                    </Link>
                    <img
                      className="Dailysalereport-head-arrow mx-3"
                      src={base_assets + 'images/pos/report/arrow-right.png'}
                      alt=""
                    />{' '}
                    {t('Mix and Match')}
                  </h3>
                </div>
              </div>
              <div className="selection-process large-selection-process">
                <div className="dropdown common-dropdown diamond-dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownDiamond"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <img
                        src={
                          base_assets +
                          'images/pos/icons/diamond-icon-green.png'
                        }
                        alt=""
                      />
                    </span>{' '}
                    {t('Diamond')}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownDiamond"
                  >
                    {items?.length
                      ? items.map((result, itkey) => {
                        return (
                          <li key={itkey}>
                            <Link
                              className="dropdown-item"
                              to="/app/pos/custom/mixAndMatchProduct"
                              state={{
                                daimond_id: '',
                                items: {
                                  id: result?._id,
                                  name: result?.name,
                                },
                                editproduct: state?.editproduct,
                                customcart: state?.customcart,
                                scrvicelist: state?.scrvicelist,
                                scrviceremark: state?.scrviceremark,
                                remark: state?.remark
                              }}
                            >
                              <span>
                                {' '}
                                <img
                                  src={result?.logo ? result?.logo : ''}
                                  alt=""
                                />
                              </span>
                              {result?.name}
                            </Link>
                          </li>
                        )
                      })
                      : ''}
                  </ul>
                </div>
                {state?.bychangeorder ? (
                  <>
                    <div
                      className={
                        state?.bychangeorder
                          ? 'selection-process-single active-green'
                          : 'selection-process-single'
                      }
                    >
                      <img
                        className="selection-process-single-img img-gray"
                        src={base_assets + 'images/pos/steps/s1.png'}
                        alt=""
                      />
                      <img
                        className="selection-process-single-img img-green"
                        src={base_assets + 'images/pos/steps/s1-g.png'}
                        alt=""
                      />
                      <span className="selection-process-single-name">
                        {t('Select Setting')}
                      </span>
                    </div>
                    <span className="selection-process-line"></span>
                    <div
                      className={
                        state?.daimond_id
                          ? 'selection-process-single active-yellow'
                          : 'selection-process-single'
                      }
                    >
                      <img
                        className="selection-process-single-img img-gray"
                        src={base_assets + 'images/pos/steps/s3.png'}
                        alt=""
                      />
                      <img
                        className="selection-process-single-img img-yellow"
                        src={base_assets + 'images/pos/steps/s3-y.png'}
                        alt=""
                      />
                      <span className="selection-process-single-name">
                        {t('Select Diamond')}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={
                        state?.daimond_id
                          ? 'selection-process-single active-yellow'
                          : 'selection-process-single'
                      }
                    >
                      <img
                        className="selection-process-single-img img-gray"
                        src={base_assets + 'images/pos/steps/s3.png'}
                        alt=""
                      />
                      <img
                        className="selection-process-single-img img-yellow"
                        src={base_assets + 'images/pos/steps/s3-y.png'}
                        alt=""
                      />
                      <span className="selection-process-single-name">
                        {t('Select Diamond')}
                      </span>
                    </div>
                    <span className="selection-process-line"></span>
                    <div
                      className={
                        state?.daimond_id
                          ? 'selection-process-single active-green'
                          : 'selection-process-single'
                      }
                    >
                      <img
                        className="selection-process-single-img img-gray"
                        src={base_assets + 'images/pos/steps/s1.png'}
                        alt=""
                      />
                      <img
                        className="selection-process-single-img img-green"
                        src={base_assets + 'images/pos/steps/s1-g.png'}
                        alt=""
                      />
                      <span className="selection-process-single-name">
                        {t('Select Setting')}
                      </span>
                    </div>
                  </>
                )}
                <span className="selection-process-line"></span>
                <div
                  className={
                    state?.daimond_id
                      ? 'selection-process-single active-green'
                      : 'selection-process-single'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s4.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s4-g.png'}
                    alt=""
                  />

                  <span className="selection-process-single-name">
                    {t('Engraving')}
                  </span>
                </div>
                <span className="selection-process-line"></span>

                <div className="selection-process-single">
                  <img
                    className="selection-process-single-img"
                    src={base_assets + 'images/pos/steps/s5.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Complete')}
                  </span>
                </div>
              </div>
              <DaimondSearchModal />
            </div>
          </div>
          <div className="main-body-row-right-col">
            <CheckoutBlank />
          </div>
        </div>
      </div>
    </>
  )
}

export default MixAndMatch
