import React, { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import RightArrow from "../common/icons/right-arrow";
import { useTranslation } from 'react-i18next';
import { loading, Updateloading, error } from "../common/helpers/toastify";
import { _Api } from '../api/_call';
import Confirmationmodel from "../common/modules/confirmationmodel"
const SettingExport = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [entityType, setEntityType] = useState();
  const [exportdata, setExportdata] = useState([])
  const [dataid, setDelete] = useState("")
  const { t } = useTranslation();
  const entityTypeOption = [
    { label: "Docs & Image Zip", value: "docsimagefile" },
    { label: "Database", value: "database" }
  ]
  const addexport = async () => {
    if (!entityType?.value) {
      error("entityType is Require")
      return;
    }
    let GetloadingID = loading()
    try {
      let postdata = {
        "type": entityType?.value
      }
      let res = await _Api(postdata, `api/v1/settings/export`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, (res?.message))
        exportlist()
      } else {
        Updateloading(t, GetloadingID, res?.errors, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  const exportlist = async () => {

    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/settings/export_list`)
      if (res?.code === 200) {
        setExportdata(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }

  }
  useEffect(() => {
    exportlist()
  }, [])
  const deleteexport = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        id: dataid
      }
      let res = await _Api(postdata, `api/v1/settings/delete_export`)
      if (res?.code === 200) {
        Updateloading(t, GetloadingID, res?.message)
        setDelete("")
        exportlist()
      } else {
        Updateloading(t, GetloadingID, res?.message, 'error');
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <Link className="text-decoration-none" to="/app/admin/settings">{t("Setting")}</Link>  <RightArrow /> <span>{t("Export")}</span>
          </h1>
        </div>
        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center"
          onClick={() => addexport()}>
          <button className="square-btn"><img src={base_assets + "images/icons/white-save.png"} alt="" /></button>
        </div>
      </div>
      <div className="admin-main-card">
        <div className='row'>
          <div className='col-md-6'>
            <div className="v3-input-group">
              <label className="v3-input-group-label">{t("Entity Type")}</label>
              <Select
                className="select-set select w-100 p-0"
                placeholder=""
                options={entityTypeOption}
                value={entityType}
                onChange={(e) => setEntityType(e)}
                classNamePrefix="common-select"
                isSearchable={true}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div
              className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
            >
              <table
                id="my_customer"
                className="com-custom-table-tag table align-middle">
                <thead>
                  <tr className="text-nowrap align-middle">
                    <th>{t("File name")}	</th>
                    <th>{t("Date")}</th>
                    <th className="text-center">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {exportdata?.length ? exportdata.map((result, key) => {
                    return (
                      <tr className="text-nowrap" key={key}>
                        <td>{result?.type}</td>
                        <td>{result?.createdAt}</td>
                        <td className="text-center d-flex justify-content-center gap-3" >
                          {result?.status === 3 ?
                            <i
                              onClick={() => window.open(result?.filekey)}
                              className="fa fa-upload admin-setting-grid-single-icon cursor-pointer"
                              aria-hidden="true"
                            ></i> :
                            ""}
                          <img data-bs-toggle="modal" data-bs-target="#createPurchaseModal" onClick={() => setDelete(result?._id)} className="cursor-pointer" src={base_assets + "images/admin/icons/Delete.png"} alt="" />
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                    </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Confirmationmodel statement={"Delete"} handler_submit={deleteexport} ></Confirmationmodel>
    </div>
  )
}

export default SettingExport
