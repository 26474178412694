import React, { useRef, useState, useEffect, useCallback } from 'react'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const AgeAndGender = (props) => {
  const dataFetched = useRef();
  const { setDashboardhideshow, setDefaultset } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const [ageandgenderdata, setAgeandgenderdata] = useState({})
  const { t } = useTranslation();
  const agegendrechart = useCallback(async () => {
    try {
      let postdata = {}
      let res = await _Api(postdata, `api/v1/dashboard/ageAndGenderStatics`)
      if (res?.code === 200) {
        setAgeandgenderdata(res?.statics)
      } else {
        let obj = {
          statics: res?.statics,
          slideBar: {
            "7_22": {
              "age_group": {
                "start": 7,
                "end": 22
              },
              "Female": 0,
              "Male": 0,
              "Couples": 0,
              "Group": 0
            },
            "23_38": {
              "age_group": {
                "start": 23,
                "end": 38
              },
              "Female": 0,
              "Male": 0,
              "Couples": 0,
              "Group": 0
            },
            "39_54": {
              "age_group": {
                "start": 39,
                "end": 54
              },
              "Female": 0,
              "Male": 0,
              "Couples": 0,
              "Group": 0
            },
            "55_+": {
              "age_group": {
                "start": 55,
                "end": "+"
              },
              "Female": 0,
              "Male": 0,
              "Couples": 0,
              "Group": 0
            }
          }
        }
        setAgeandgenderdata(obj)
      }

    }
    catch (err) {
      console.log(err, "err")
    }
  }, []);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    agegendrechart()
  }, [agegendrechart])

  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Age and Gender")}
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li
                onClick={() => {
                  setDashboardhideshow(prevState => ({
                    ...prevState,
                    AgeandGender: 0
                  }));
                  setDefaultset(true)
                }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area py-3 fs-12">
          <div className="dashboard-area-cards-single-chart-area-age-gender d-flex tet-center justify-content-between">
            <div className="dashboard-area-cards-single-chart-area-age-gender-single dark-sky-color">
              <img src={base_assets + 'images/icons/blue-woman.png'} alt="" />
              <br />
              <span>{parseFloat(ageandgenderdata?.FemaleCustomer).toFixed(2)} %</span>
            </div>
            <div className="dashboard-area-cards-single-chart-area-age-gender-single sky-color">
              <img src={base_assets + 'images/icons/sky-male.png'} alt="" />
              <br />
              <span>{parseFloat(ageandgenderdata?.MaleCustomer).toFixed(2)} %</span>
            </div>
            <div className="dashboard-area-cards-single-chart-area-age-gender-single pink-color">
              <img src={base_assets + 'images/icons/couple.png'} alt="" />
              <br />
              <span>{parseFloat(ageandgenderdata?.CouplesCustomer).toFixed(2)} %</span>
            </div>
            <div className="dashboard-area-cards-single-chart-area-age-gender-single purple-color">
              <img src={base_assets + 'images/icons/other-pink.png'} alt="" />
              <br />
              <span>{parseFloat(ageandgenderdata?.GroupCustomer).toFixed(2)} %</span>
            </div>
          </div>
          <div className="dashboard-area-cards-single-chart-area-progress">
            <div className="dashboard-area-cards-single-chart-area-progress-single mt-4">
              <span className="ms-auto">{ageandgenderdata?.slideBar?.['7_22']?.age_group?.start + "-" + ageandgenderdata?.slideBar?.['7_22']?.age_group?.end}</span>
              <div className="progress w-100">
                <div
                  className="progress-bar dark-sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['7_22']?.Male}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['7_22']?.Female}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['7_22']?.Group}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['7_22']?.Couples}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="dashboard-area-cards-single-chart-area-progress">
            <div className="dashboard-area-cards-single-chart-area-progress-single mt-4">
              <span className="ms-auto">{ageandgenderdata?.slideBar?.['23_38']?.age_group?.start + "-" + ageandgenderdata?.slideBar?.['23_38']?.age_group?.end}</span>
              <div className="progress w-100">
                <div
                  className="progress-bar dark-sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['23_38']?.Male}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['23_38']?.Female}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['23_38']?.Group}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['23_38']?.Couples}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="dashboard-area-cards-single-chart-area-progress">
            <div className="dashboard-area-cards-single-chart-area-progress-single mt-4">
              <span className="ms-auto">{ageandgenderdata?.slideBar?.['39_54']?.age_group?.start + "-" + ageandgenderdata?.slideBar?.['39_54']?.age_group?.end}</span>
              <div className="progress w-100">
                <div
                  className="progress-bar dark-sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['39_54']?.Male}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['39_54']?.Female}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['39_54']?.Group}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['39_54']?.Couples}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="dashboard-area-cards-single-chart-area-progress">
            <div className="dashboard-area-cards-single-chart-area-progress-single mt-4">
              <span className="ms-auto">55 +</span>
              <div className="progress w-100">
                <div
                  className="progress-bar dark-sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['55_+']?.Male}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['55_+']?.Female}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['55_+']?.Group}%` }}
                ></div>
                <div
                  className="progress-bar sky-bg"
                  role="progressbar"
                  style={{ width: `${ageandgenderdata?.slideBar?.['55_+']?.Couples}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AgeAndGender
