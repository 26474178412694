import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
export default function CalendarFilter({ handleShowFilter, applyfilter, setApplyfilter, kanban}) {
    const datePickerRef = useRef(null)
    const customBtnRef = useRef(null)
    const [filters, setFilters] = useState({ OrderType: [], Status: [], start_date: "", end_date: "", activedates: "", location: [], create_at: [], order_type: [] });
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [valueAlreadyExist, setValueAlradyExist] = useState([]);
    const [popupPosition, setPopupPosition] = useState({ top: 0});
    useEffect(() => {
        // Initialize filters based on applyfilter, only on component mount or when applyfilter changes
        if (applyfilter?.OrderType?.length > 0 || applyfilter?.Status?.length > 0 || applyfilter?.activedates !== "" || applyfilter?.create_at?.length > 0 || applyfilter?.location?.length > 0) {
          setFilters({ ...applyfilter }); // Use spread operator to create a copy
          setAlreadyExist(true);
        } else {
          setFilters({ OrderType: [], Status: [], start_date: "", end_date: "", activedates: "", location: [], create_at: [] });
        }
      }, [applyfilter]);

    const { t } = useTranslation();
    let OrderType = [
        "Repair Order",
        "Custom Order",
        "Purchase Order",
        "Reserved Order",
        "Appointment"
    ]

    let Status = [
        "Due",
        "Overdue",
        "Completed"
    ]
    let DataRange = [
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "This Month",
        "Custom Range"
    ]

    let OrderKanban = [
        "POS Order",
        "Replenishment Order"
    ]
    let CreateDate = [
        "Oldest",
        "Newest"
    ]
    let location = [
        "Central World",
        "Central Rama3",
        "Central Pinklao",
        "Central Ladprao",
        "Central Grand Rama9",
        "IconSiam"
    ]

    const onFilterCLick = (label, value) => {
        let updatedArray = filters[label];
        if (updatedArray.includes(value)) {
            updatedArray = updatedArray.filter(item => item !== value);
        } else {
            updatedArray.push(value);
            if(alreadyExist){
                setValueAlradyExist(prev => ([...prev,{key: label, value: value}]))
            }
        }
        setFilters(state => 
            ({...state, [label]: updatedArray })
        )
        
    }

    const changeDates = (label) => {
        const MainDate = new Date();
        let start_date, end_date = new Date();
        switch (label) {
            case 'Yesterday':
                start_date = end_date = new Date(MainDate.setDate(MainDate.getDate() - 1));
                break;
            case 'Last 7 days':
                start_date = new Date(MainDate.setDate(MainDate.getDate() - 7));
                break;
            case 'Last 30 days':
                start_date = new Date(MainDate.setDate(MainDate.getDate() - 30));
                break;
            case 'This Month':
                start_date = new Date(MainDate.getFullYear(), MainDate.getMonth(), 1);
                end_date = new Date(MainDate.getFullYear(), MainDate.getMonth() + 1, 0);
                break;
            default:
                return;
        }
        if (filters?.activedates === label) {
            setFilters((state) => ({
                ...state,
                start_date,
                end_date,
                activedates: ""
            }));
        } else {
            setFilters((state) => ({
                ...state,
                start_date,
                end_date,
                activedates: label
            }));
        }
    };


    const handleApplyFilterButton = () =>{
        handleShowFilter();
        setApplyfilter(prevState => ({
            ...prevState,  // Spread the previous state
            ...filters     // Spread the new filters object
          }));
    }
    const handleFilterCloseButton = () =>{
        
        // setFilters(prevState => ({
        //     ...prevState,
        //     OrderType: [],
        //     Status: [],
        //     start_date: "",
        //     end_date: "",
        //     activedates:"",
        //     location:[],
        //     create_at:[]
        //   }));
        //   if(alreadyExist){
        //     const updatedApplyFilter = { ...applyfilter };

        //     valueAlreadyExist.forEach(obj => {
        //       const { key, value } = obj;
        //       if (updatedApplyFilter[key]) {
        //         updatedApplyFilter[key] = updatedApplyFilter[key].filter(item => item !== value);
        //       }
        //     });
        //     setApplyfilter(updatedApplyFilter)
        //   }
          handleShowFilter(); 
    } 

    const positionDatePicker = () =>{
        const buttonNode = customBtnRef.current;
        const popupNode = datePickerRef.current;
    if (buttonNode && popupNode) {
        const buttonRect = buttonNode.getBoundingClientRect();
        setPopupPosition(prevState => ({
            ...prevState,
            top: buttonRect.top
          }));
    }
    }

    useEffect(() => {
        if (datePickerRef.current) {
          window.addEventListener('resize', positionDatePicker);
          return () => window.removeEventListener('resize', positionDatePicker);
        }
    // eslint-disable-next-line
    }, [datePickerRef.current])

    return (
        <div className='calendarfilter'>
            <div className='calendarfilter-inner'>
                <div className='calendarfilter-inner-title'>
                    <div>{t("Filters")}</div>
                    <div className='cursor-pointer calendarfilter-inner-title-closeicon'
                        onClick={() => handleFilterCloseButton()}
                    > <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#767676" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                   </div>
                </div>
                <div className='calendarfilter-inner-content'>
                {
                        kanban ? 
                        <>
                        <div className='calendarfilter-inner-content-data'>
                        <span>{t("Order Types")}</span>
                        <div className='calendarfilter-inner-content-data-filter'>
                            {OrderKanban?.length ? OrderKanban?.map((result, key) => {
                                return (
                                    <div className=
                                        {`calendarfilter-inner-content-data-filter-item ${filters?.order_type?.includes(result) ? "active" : ""}`}
                                        key={key}
                                        onClick={() => onFilterCLick("order_type", result)}>
                                        {t(result)}
                                    </div>
                                )
                            }) : ""}
                        </div>
                        </div>
                        <div className='calendarfilter-inner-content-data'>
                            <span>{t("Create Date")}</span>
                            <div className='calendarfilter-inner-content-data-filter'>
                            {CreateDate?.length ? CreateDate.map((result, key) => {
                                return (
                                    <div className={`calendarfilter-inner-content-data-filter-item me-2 mb-2 
                                        ${filters?.create_at?.includes(result)  ? "active" : ""}`} key={key}
                                        onClick={() => onFilterCLick("create_at", result)}>
                                        {t(result)}</div>
                                )
                            }) : ""}
                        </div>
                        </div>
                        <div className='calendarfilter-inner-content-data'>
                            <span>{t("Location")}</span>
                            <div className='calendarfilter-inner-content-data-filter'>
                            {location?.length ? location?.map((result, key) => {
                                return (
                                    <div className=
                                        {`calendarfilter-inner-content-data-filter-item ${filters?.location?.includes(result) ? "active" : ""}`}
                                        key={key}
                                        onClick={() => onFilterCLick("location", result)}>
                                        {t(result)}
                                    </div>
                                )
                            }) : ""}
                            </div>
                        </div>
                        </>
                        : 
                        <>
                        <div className='calendarfilter-inner-content-data'>
                        <span>{t("Order Types")}</span>
                        <div className='calendarfilter-inner-content-data-filter'>
                            {OrderType?.length ? OrderType?.map((result, key) => {
                                return (
                                    <div className=
                                        {`calendarfilter-inner-content-data-filter-item ${filters?.OrderType?.includes(result) ? "active" : ""}`}
                                        key={key}
                                        onClick={() => onFilterCLick("OrderType", result)}>
                                        {t(result)}
                                    </div>
                                )
                            }) : ""}
                        </div>
                        </div>
                        <div className='calendarfilter-inner-content-data'>
                            <span>{t("Status")}</span>

                            <div className='calendarfilter-inner-content-data-filter'>
                                {Status?.length ? Status.map((result, key) => {
                                    return (
                                        <div className={`calendarfilter-inner-content-data-filter-item me-2 mb-2 
                                            ${filters?.Status?.includes(result)  ? "active" : ""}`} key={key}
                                            onClick={() => onFilterCLick("Status", result)}>
                                            {t(result)}</div>
                                    )
                                }) : ""}
                            </div>
                        </div>
                        <div className='calendarfilter-inner-content-data'>
                            <span>{t("Due Date")}</span>
                            <div className='calendarfilter-inner-content-data-filter'>
                                {DataRange?.length ? DataRange.map((result, key) => {
                                    return (
                                        result === "Custom Range" ?
                                        <div className='d-grid' key={key}>
                                            <div ref={customBtnRef} onClick={() => {
                                                datePickerRef.current.setOpen(true);
                                                changeDates(result);
                                                positionDatePicker()
                                            }} className={`calendarfilter-inner-content-data-filter-item me-2 mb-2 
                                                ${filters?.activedates === result ? "active" : ""}`} key={key}>
                                                    {t(result)}</div>
                                                {filters?.activedates === result ?  <div className='calendarfilter-inner-content-data-filter-item-customtxt'>{filters?.start_date?.toLocaleDateString()} - {filters?.end_date?.toLocaleDateString()}</div> : "" }
                                        </div>
                                            :
                                            <div className={`calendarfilter-inner-content-data-filter-item me-2 mb-2 
                                                ${filters?.activedates === result? "active" : ""}`} key={key}
                                                onClick={() => changeDates(result)}>{t(result)}</div>
                                    )
                                }) : ""}
                            
                            </div>
                        </div>
                        </>
                    }
                </div>
              
            </div>
            <DatePicker
                                className='calendarfilter-daterangepicker d-none z-40'
                                selectsRange={true}
                                monthsShown={2}
                                startDate={filters?.start_date}
                                endDate={filters?.end_date}
                                style={{ top: `${popupPosition.top}px`}}
                                onChange={(update) => {
                                    setFilters((state) => ({
                                        ...state,
                                        start_date: update[0],
                                        end_date: update[1],
                                        activedates: 'Custom Range'
                                    }))
                                }}
                                dateFormat="yyyy-MM-dd"
                                ref={datePickerRef}
                                autoComplete='none'
                            />
            <div className='calendarfilter-inner-bottom'
                    onClick={() => {handleApplyFilterButton()}}>
                    <button>
                        {t("Apply Filters")}
                    </button>
                </div>
        </div>
    )
}
