import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { _Apiauth } from "./components/api/_call";

const OutControl = (props) => {
  const navigate = useNavigate();
  const dataFetched = useRef();
  const handleLogout = async () => {

    try {
      let postdata = { authToken: localStorage.getItem("UserToken") }
      localStorage.removeItem('UserToken');
      await _Apiauth(postdata, `Auth/Web/logout`)
      localStorage.removeItem('updateModulePermission');
      localStorage.removeItem('updateUserPermission');
      navigate('/login');
    }
    catch (err) {
      console.log(err, "err")
      navigate('/login');
    }


  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true
    handleLogout();
  });

};
export default OutControl;