import React, { useState, useEffect } from "react"
import { _Api } from "../api/_call";
import TableColumnModal from "../common/modules/tableColumn";
import { Tablehead } from "../common/modules/Tablehead";
import DateTime from "../common/DateTime";
import { reportpurchaselisthead } from "../common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../api/setdatatable";
import "datatables.net-fixedcolumns";
import { ReportMenuModal } from "../common/modules/reportMenu";
import { DateRange } from "../common/helpers/daterange";
import SocialModal from "../common/modules/SocialModal/SocialModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { toFormatPrice, toUnformatPrice } from "../common/helpers/function";
import ProductModal from "../common/modules/ProductModal";
import { loading, Updateloading } from "../common/helpers/toastify";
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import Select from "react-select"
const Purchasereport = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [limit, setLimit] = useState(100);
  const [socialurl, setSocialurl] = useState();
  const [filtername, setFiltername] = useState('');
  const [datefilter, setDatefilter] = useState([]);
  const [newcolumndata, setnewcolumndata] = useState(reportpurchaselisthead);
  const [reportpurchaselist, setReportpurchaselist] = useState();
  const [rowlength, setRowlength] = useState(0);
  const [totalresult, setTotalresult] = useState();
  const [reportpurchasecounts, setReportpurchasecounts] = useState([]);
  const [getoneproduct, setOneproduct] = useState();
  const [shareloader, setShareloader] = useState(false);
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const filterbyname = (search) => {
    if (filtername) {
      Getreportpurchase(limit, search);
    } else {
      Getreportpurchase(limit, search);
    }
  }
  useEffect(() => {
    callcolumns();
  }, []);
  const getsharepurchase = async () => {
    setShareloader(true);
    try {
      const postdata = {
        "search": filtername,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/purchase`);

      if (res.code === 200) {
        setSocialurl(res?.link);
        setShareloader(false);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }

  }
  const getprintpurchase = async () => {
    let GetloadingID = loading();
    try {
      const postdata = {
        "search": filtername,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/reports/print/purchase`);
      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a');
          exportLinkElement.hidden = true;
          exportLinkElement.download = res?.name ? res?.name : "report.pdf";
          exportLinkElement.href = res?.link;
          exportLinkElement.text = "downloading...";
          exportLinkElement.setAttribute('target', '_blank');
          document.body.appendChild(exportLinkElement);
          exportLinkElement.click();
          exportLinkElement.remove();
          Updateloading(t, GetloadingID, t("download successfully"));

        }, 50)
        setSocialurl(res?.link);
      } else {
        Updateloading(t, GetloadingID, res?.message ? (res?.message) : (res?.errors), 'error');
      }
    }
    catch (err) {
      console.log(err, 'err');
      Updateloading(t, GetloadingID, err, t('error'));
    }

  }

  const Getreportpurchase = async (firstlimit, search) => {
    UnSetdataTable("report_purchase");
    setDatsLodaer(true)
    try {
      const postdata = {
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : ""
      };
      let res = await _Api(postdata, `api/v1/Inventory/report/purchasereport`);
      if (res.code === 200) {
        setReportpurchaselist(res?.data);
        setReportpurchasecounts(res?.count);
        setRowlength(0);
        setTotalresult(res.total);
        setTimeout(() => {
          SetdataTable("report_purchase", 4, newcolumndata, [0]);
        }, 50)
        setDatsLodaer(false)
      }
    }
    catch (err) {
      SetdataTable("report_purchase", 4, newcolumndata);
      console.log(err, 'err');
    }
  }
  const fetchMoreData = () => {
    if (totalresult !== reportpurchaselist?.length) {
      var len = rowlength + parseInt(limit);
      setRowlength(len);
      UnSetdataTable("report_purchase");
      setTimeout(async () => {
        try {
          const posdata = {
            search: filtername,
            "limit": limit,
            "skip": len,
            "start_date": datefilter ? datefilter[0] : "",
            "end_date": datefilter ? datefilter[1] : "",
          }
          let res = await _Api(posdata, `api/v1/Inventory/report/purchasereport`);
          if (res.code === 200) {
            setTimeout(() => {
              SetdataTable("report_purchase", 4, newcolumndata, [0]);
            }, 50)
            setReportpurchaselist(reportpurchaselist.concat(res.data));
          }
        } catch (err) {
          console.log(err, 'err');
        }
      }, 1000);
    } else {
      UnSetdataTable("report_purchase");
      setTimeout(() => {
        SetdataTable("report_purchase", 4, newcolumndata, [0]);
      }, 50)

    }

  };
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_report_purchase",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Getreportpurchase();
    }
    // eslint-disable-next-line
  }, [datefilter]);
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    reportpurchaselisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (reportpurchaselist.length) {
      reportpurchaselist.forEach((result, key) => {
        bodyarray.push([key + 1, result?.date, result?.pu_no, result?.po_no, result?.SKU, result?.stock_id, result?.metal, result?.stone, result?.size, result?.po_QTY_noChange, result?.amount, result?.supplier, result?.ShipTo, result?.delivery_date, result?.status === 1 ?
          "Approved"
          : result?.status === 2 ?
            "Pending"
            : "Canceled", result?.createdBy, result?.modifiedBy])
      })
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "report_purchase");

  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <React.Fragment>
      <div className="main-body report-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h2 className="main-body-heading"><span>{t("report")} {">"}</span>&nbsp;&nbsp;{t("Purchase")}</h2>
              <div className="main-body-top-inventory">
                <div className="main-body-top-inventory-status">
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/free-stock.png"}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Total Qty")}</span>
                      <h2 className="inventory-value stock">{reportpurchasecounts?.qty ? reportpurchasecounts?.qty : 0}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/sku.png"}
                        className="inventory-img"
                        alt="sku"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Sku")}</span>
                      <h2 className="inventory-value sku">{reportpurchasecounts?.SKU ? reportpurchasecounts?.SKU : 0}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/location.png"}
                        className="inventory-img"
                        alt="value"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Location's")}</span>
                      <h2 className="inventory-value location">{reportpurchasecounts?.location ? reportpurchasecounts?.location : 0}</h2>
                    </div>
                  </div>
                  <div className="main-body-top-inventory-status-card">
                    <div className="left-side">
                      <img
                        src={base_assets + "images/icons/value.png"}
                        className="inventory-img"
                        alt="free-stock"
                      />
                    </div>
                    <div className="right-side">
                      <span className="inventory-name">{t("Total Value")}</span>
                      <h2 className="inventory-value value">{reportpurchasecounts?.amount ? reportpurchasecounts?.amount : 0}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <ReportMenuModal />
                <Select
                  className="limit-select short mx-3 z-10"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); Getreportpurchase(e.value); setRowlength(0) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    value={filtername}
                    type="text"
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("Product name/Stock Id/SKU/Customer/Barcode")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className={parmissiondatashow?.inventory_reports?.Print ? "dropdown-item print-dropdown-item" : "d-none"} onClick={(e) => { getprintpurchase(e.target.value); }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>
                      <li className="dropdown-item" onClick={(e) => { downloadexclsheet(e.target.value); }}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>



                      <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#socialModal" onClick={() => getsharepurchase()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/share-icon.png"}
                            alt=""
                          />
                        </div>
                        <span >{t("share")}</span>
                      </li>

                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
              >
                <InfiniteScroll
                  dataLength={reportpurchaselist ? reportpurchaselist?.length : ''}
                  next={totalresult > rowlength ? fetchMoreData : ''}
                  hasMore={totalresult > rowlength ? true : false}
                  loader={reportpurchaselist?.length !== totalresult ? totalresult > rowlength ? <h6>{t("Loading more items...")}</h6> : '' : ''}
                >
                  <table
                    id="report_purchase"
                    className="stripe row-border order-column common-table table-striped main-table w-100"
                  >

                    <Tablehead
                      tablehead={reportpurchaselisthead}
                      tblName={"tbl_report_purchase"}
                      setfilterby={""}
                    />
                    <tbody>
                      <>
                        {reportpurchaselist ? reportpurchaselist?.map((result, key) => {
                          return (
                            <tr key={key} className="">
                              <td className="fixed-side">{key + 1}</td>
                              <td className="fixed-side">{result?.date ? result?.date : ''}</td>
                              <td className="fixed-side link-color">{result?.pu_no ? result?.pu_no : ''}</td>
                              <td className="fixed-side link-color">{result?.po_no ? result?.po_no : ''}</td>
                              <td className="fixed-side link-color cursor-pointer" onClick={() => setOneproduct(result)} data-bs-toggle="modal" data-bs-target="#ProductInfoModal">{result?.SKU ? result?.SKU : ''}</td>
                              <td className="fixed-side">{result?.stockId ? result?.stockId : ''}</td>
                              <td className="fixed-side">{result?.metal ? result?.metal : ''}</td>
                              <td className="fixed-side">{result?.stone ? result?.stone : ''}</td>
                              <td className="fixed-side">{result?.size ? result?.size : ''}</td>
                              <td className="fixed-side">{result?.QTY ? result?.QTY : ''}</td>
                              <td className="fixed-side">{result?.amount ? result?.amount : ''}</td>
                              <td className="fixed-side">{result?.supplier ? result?.supplier : ''}</td>
                              <td className="fixed-side">{result?.ShipTo ? result?.ShipTo : ''}</td>
                              <td className="fixed-side">{result?.delivery_date ? result?.delivery_date : ''}</td>
                              <td className="fixed-side">
                                {
                                  result?.status === 1 ?
                                    <label className="status-green-text">{t("Approved")}</label>
                                    : result?.status === 2 ?
                                      <label className="yellow-text">{t("Pending")}</label>
                                      : <label className="danger-text">{t("Canceled")}</label>
                                }
                              </td>
                              <td className="fixed-side">{result?.createdBy ? result?.createdBy : ''}</td>
                              <td className="fixed-side">{result?.modifiedBy ? result?.modifiedBy : ''}</td>
                            </tr>
                          );
                        }) : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td >{reportpurchaselist
                          ? reportpurchaselist?.reduce(
                            (interation, val) =>
                            (interation =
                              interation + parseInt(val.QTY)),
                            0
                          )
                          : ""}</td>
                        <td > {
                          toFormatPrice(reportpurchaselist
                            ? reportpurchaselist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.amount)),
                              0
                            )
                            : "", { addSymbol: true })
                        }</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "mt-4" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        <SocialModal shareUrl={socialurl} shareloader={shareloader} />
        <ProductModal data_product={getoneproduct} setOneproduct={setOneproduct} editproduct_submit='' />
      </div>
      <TableColumnModal tablehead={reportpurchaselisthead} tblName={"tbl_report_purchase"} setnewcolumndata={setnewcolumndata}
        calldatatable={Getreportpurchase}></TableColumnModal>
    </React.Fragment>
  );
};
export default Purchasereport;
