import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { DateRange } from "../common/helpers/daterange";
import { useTranslation } from 'react-i18next';
import { _Api } from "../api/_call";
import $ from 'jquery';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Pagination } from "../common/Pagination";
import RightArrow from "../common/icons/right-arrow";
import "./customers.scss";
import Select from "react-select"
import Slider from 'rc-slider';
import { toFormatPrice } from '../common/helpers/function';
import CustomerInformation from "./customerInformation/CustomerInformation";
const HistoryPurchase = () => {
  const { t } = useTranslation();
  const dropdownReftype = useRef(null);
  const dropdownReflocation = useRef(null)
  const dropdownRefprice = useRef(null);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { state } = useLocation();
  const [datefilter, setDatefilter] = useState([]);
  const [purchase, setPurchase] = useState([])
  const [limit, setLimit] = useState(100);
  const [excuteTable, setexcuteTable] = useState(false);
  const [customerdetails, setCustomerdetails] = useState()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const [cusid, setCusid] = useState("")
  const [openSubTable, setOpenSubTable] = useState([]);
  const [typefilter, setTypefilter] = useState([])
  const [typechecked, setTypechecked] = useState([])
  const [searchVal, setSearchVal] = useState("");
  const [shortobj, setShortobj] = useState({})
  const [locationchecked, setLocationchecked] = useState([])
  const [locationfilter, setLocationfilter] = useState([])
  const [price, setPrice] = useState([0, 100])
  const [priceobj, setPriceobj] = useState()
  const getlimit = (limit) => {
    setLimit(limit);
    gethistorypurchase(1, limit);
    setsPage({ ...page, currentPage: 1 });

  };
  const [page, setsPage] = useState({
    totalPages: 20,
    currentPage: 1,
    customer_id: state?.customer_id
  });
  const { totalPages, currentPage } = page;
  const handlePaginations = (current) => {
    setsPage({ ...page, currentPage: current });
    gethistorypurchase(current, "");

  };

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const gethistorypurchase = async (current, firstlimit) => {
    setexcuteTable(false);
    try {
      let postdata = {
        "customer_id": state?.customer_id,
        "type": "PURCHASE",
        "limit": firstlimit ? firstlimit : limit,
        "start_date": datefilter ? datefilter[0] : '',
        "end_date": datefilter ? datefilter[1] : '',
        "filter": {
          "order_type": typechecked ? typechecked : [],
          "location": locationchecked ? locationchecked : [],
          "priceMin": price[0],
          "priceMax": price[1]
        },
        "sort": { [shortobj?.key]: shortobj?.value }
      }
      let res = await _Api(postdata, `api/v1/Customer/customer/purcahseHistory`)
      if (res?.code === 200) {
        setPurchase(res?.data)
        setexcuteTable(true);
        setsPage({
          ...page,
          totalPages: Math.ceil(res?.total / postdata.limit),
          currentPage: current ? current : 1
        });
      }
    }
    catch (err) {
      console.log(err, "err")
    }

  }
  const getcustomerdeteails = async () => {
    try {
      let postdata = {
        "customer_id": state?.customer_id,

      }
      let res = await _Api(postdata, `api/v1/Customer/customer/customerData`)
      if (res?.code === 200) {
        setCustomerdetails(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }

  }
  const historyfilter = async () => {
    try {
      let postdata = {
        "customer_id": state?.customer_id,
      }
      let res = await _Api(postdata, `api/v1/Customer/customer/purcahseHistoryFilter`)
      if (res?.code === 200) {
        setTypefilter(res?.data?.order_type)
        setLocationfilter(res?.data?.location)
        setPrice([res?.data?.priceMin, res?.data?.priceMax]);
        setPriceobj({ 'min': res?.data?.priceMin, 'max': res?.data?.priceMax });
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      gethistorypurchase()
      getcustomerdeteails()
      historyfilter()
    }
    // eslint-disable-next-line 
  }, [datefilter, shortobj, cusid])

  const setSubTablecustom = async (order_id) => {
    var e = document.querySelector("#table-btn_" + order_id);
    var row = document.querySelector(`common-main-table-wrap-${order_id}`);
    var classList_ = e.classList;
    if (classList_.contains("rotate-180")) {
      classList_.remove("rotate-180");
      let alllist_data = [...purchase];
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = null
      })
      setPurchase(alllist_data);
    } else {
      classList_.add("rotate-180");
      let alllist_data = [...purchase];
      const postdata = {
        "Order_id": order_id
      };
      let prioritydata = await _Api(postdata, `api/v1/Customer/customer/getSkuByOrderList`);
      if (prioritydata?.code === 200) {
        setOpenSubTable(prioritydata?.data)
      }


      var html_ = true
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = html_

      })
      setPurchase(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + order_id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);

    }
  }

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    header_values.push("#", "Date Time", "Voucher Type", "Ref No. ", "Location", "SKU", "Qty", "Amount")
    if (purchase.length) {
      purchase.forEach((result, key) => {
        bodyarray.push([key + 1, result?.date, result?.order_type, result?.order_no, result?.location, result?.SKU, result?.total_Qty, result?.total_amount])
      })
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "Customer History Purchase");

  }

  const hendledropdownhide = (lable) => {
    if (lable === "type") {
      dropdownReftype.current.classList.remove("show")
    } else if (lable === "location") {
      dropdownReflocation.current.classList.remove("show")
    } else if (lable === "price") {
      dropdownRefprice.current.classList.remove("show")
    }
  }
  const handleCheckAllChange = (e, lable) => {
    if (lable === "type") {
      setTypechecked(
        e.target.checked ? typefilter.map((c) => c) : []
      );
    } else if (lable === "location") {
      setLocationchecked(
        e.target.checked ? locationfilter.map((c) => c?._id) : []
      );
    }

  };
  const handlefilerChange = (event, data, lable) => {
    if (lable === "type") {
      setTypechecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data]
          : prevChecked.filter((item) => item !== data)

      );
    } else if (lable === "location") {
      setLocationchecked((prevChecked) =>
        event.target.checked
          ? [...prevChecked, data?._id]
          : prevChecked.filter((item) => item !== data?._id)

      );
    }

  };
  const getscarchvalue = async (event, lable) => {
    if (lable === "type") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...typefilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setTypefilter(arr)
        });
      } else {
        historyfilter()
      }
    } else if (lable === "location") {
      const query = searchVal ? searchVal : event.target.value ? event.target.value : "";
      let updatedList = [...locationfilter];
      let arr = []
      if (query) {
        updatedList.forEach((item) => {
          if (item?.name?.toLowerCase() === query.toLowerCase()) {
            arr.push(item)
          }
          setLocationfilter(arr)
        });
      } else {
        historyfilter()
      }
    }
  }
  return (
    <React.Fragment>
      <div className="main-body main-content-wrapper-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
              <div className="main-content-wrapper-body-top-bar-left">
                <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                  {t("Customer")} <RightArrow /><Link className="text-decoration-none" to="/app/admin/customers">{t("Customers")} </Link> <RightArrow /> <span>{t("History")}</span>
                </h1>
              </div>
            </div>
            <div className="customer-history-tabs">
              <Link className="customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none me-2"
                to="/app/admin/customers/historypayment"
                state={{ customer_id: state?.customer_id }}>{t("Payment")}</Link>
              <Link className="active customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none mx-2 " to="/app/admin/customers/historypayment"
                state={{ customer_id: state?.customer_id }}>{t("Purchase")}</Link>
              <Link className="customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none mx-2"
                to="/app/admin/customers/historypartialpayment"
                state={{ customer_id: state?.customer_id }}> {t("Partial Payment")}</Link>
              <Link className="customer-history-tabs-single gray-text d-inline-block py-2 fw-semibold text-decoration-none mx-2"
                to="/app/admin/customers/historycreditnote"
                state={{ customer_id: state?.customer_id }}>{t("Credit Note")}</Link>
            </div>

            <div className="customer-history-inner-ct bg-white pb-4">
              <CustomerInformation
                cusid={cusid}
                setCusid={setCusid} />
              <div className="customer-history-inner-ct-top-bar d-flex align-items-center px-4">
                <img className="me-3 customer-history-inner-ct-top-bar-img" src={customerdetails?.profile} alt="" />
                <span className="customer-history-inner-ct-top-bar-name bg-transparent border-0 p-0 link-color cursor-pointer"
                  data-bs-toggle="modal" data-bs-target="#customerAddModal"
                  onClick={() => { setCusid(state?.customer_id) }}
                >{customerdetails?.name ? customerdetails?.name : "-"}</span>
                {customerdetails?.group === "Platinum" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : customerdetails?.group === "Gold" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/gold-medal.png"} alt="" /> : customerdetails?.group === "Silver" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/silver-medal.png"} alt="" /> : customerdetails?.group === "Diamond" ? <img className="customer-history-inner-ct-top-bar-medal ms-3" src={base_assets + "images/icons/platinum-medal.png"} alt="" /> : ""}
                <i className="fa fa-smile-o mx-3 fs-5 lighgreencolor" aria-hidden="true"></i>
                <span className="d-flex align-items-center"><img src={base_assets + "images/icons/gray-location.png"} alt="" />
                  {" - "}  {customerdetails?.location ? customerdetails?.location : 0}</span>
                <span className="ms-auto gray-mid">{customerdetails?.country ? customerdetails?.country : ""}</span>
              </div>
              <div className="main-body-top-status-bar mb-3 mt-4 px-4 main-content-wrapper-body-top-status-bar main-content-wrapper-body-top-bar-right">

                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />
                <div className="main-body-top-status-bar-multi-date-picker">
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle text-center d-flex align-items-center rounded-1 ms-3 justify-content-center bg-white toggle-btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item print-dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/printer-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("print")}</span>
                      </li>
                      <li className="dropdown-item"
                        onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
              <div className="px-4">
                <div
                  className="main-body-main-table-wrap com-custom-table table-responsive position-relative"
                >
                  <table
                    id="my_customer"
                    className="com-custom-table-tag table align-middle mb-0">
                    <thead>
                      <tr className="text-nowrap align-middle">
                        <th>#</th>
                        <th>{t("Date Time")}</th>
                        <th>{t("Voucher Type")}
                          <div className="dropdown d-inline-block com-custom-table-tag-filter">
                            <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                              <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                            </button>
                            <div className={"dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"}
                              ref={dropdownReftype}>
                              <div className="asc-des-order">
                                <div onClick={() => { setShortobj({ key: "type", value: 1 }); hendledropdownhide("type") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                <div onClick={() => { setShortobj({ key: "type", value: -1 }); hendledropdownhide("type") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-search">
                                <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                  <img src={base_assets + "images/icons/search-icon.png"} alt="" />
                                  <input className="border-0" type="text" placeholder="Search"
                                    onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "type")}
                                    onChange={(e) => setSearchVal(e.target.value)} />
                                </div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-select">
                                <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                  <div>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                      onChange={(e) => handleCheckAllChange(e, "type")}
                                      checked={typechecked.length === typefilter.length} />
                                    <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                                  </div>
                                  <label>{typechecked.length}/{typefilter.length} {t("Selected")}</label>
                                </div>
                                <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                  {typefilter?.length ? typefilter.map((result, key) => {
                                    return (
                                      <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id={key}
                                          checked={
                                            typechecked ? typechecked.includes(result) : ""
                                          }
                                          onChange={(e) => handlefilerChange(e, result, "type")} />
                                        <label className="form-check-label cursor-pointer" htmlFor={key}>{result}</label>
                                      </li>
                                    )
                                  }) : ""}

                                </ul>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2"
                              >
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor">{t("Clear")}</button>
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { gethistorypurchase(); hendledropdownhide("type") }}>{t("Apply")}</button>
                              </div>
                            </div>
                          </div>
                        </th>
                        <th>{t("Ref No.")}</th>
                        <th>{t("Location")}
                          <div className="dropdown d-inline-block com-custom-table-tag-filter">
                            <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                              <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt=""></img>
                            </button>
                            <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                              ref={dropdownReflocation}>
                              <div className="asc-des-order">
                                <div onClick={() => { setShortobj({ key: "location", value: 1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} alt="" /> {t("Sort Ascending")}</div>
                                <div onClick={() => { setShortobj({ key: "location", value: -1 }); hendledropdownhide("location") }} className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} alt="" /> {t("Sort Descending")}</div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-search">
                                <div className="com-custom-table-tag-filter-menu-search-inner d-flex align-items-center">
                                  <img src={base_assets + "images/icons/search-icon.png"} alt=""
                                  />
                                  <input className="border-0" type="text" placeholder="Search"
                                    onKeyPress={(e) => e.key === 'Enter' && getscarchvalue(e, "location")}
                                    onChange={(e) => setSearchVal(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-select">
                                <div className="com-custom-table-tag-filter-menu-select-bar d-flex align-items-center justify-content-between">
                                  <div>
                                    <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox" id="s1"
                                      onChange={(e) => handleCheckAllChange(e, "location")}
                                      checked={locationchecked.length === locationfilter.length} />
                                    <label className="form-check-label cursor-pointer fw-semibold" htmlFor="s1">{t("Select All")}</label>
                                  </div>
                                  <label>{locationchecked?.length}/{locationfilter?.length} {t("Selected")}</label>
                                </div>
                                <ul className="com-custom-table-tag-filter-menu-select-list list-unstyled mt-3 ps-4">
                                  {locationfilter?.length ? locationfilter.map((result, key) => {
                                    return (
                                      <li className="com-custom-table-tag-filter-menu-select-list-single" key={key}>
                                        <input className="fill-com-check form-check-input d-inline-block me-2 cursor-pointer" type="checkbox"
                                          id={result?._id}
                                          onChange={(e) => handlefilerChange(e, result, "location")}
                                          checked={
                                            locationchecked ? locationchecked.includes(result?._id) : ""
                                          } />
                                        <label className="form-check-label cursor-pointer" htmlFor={result?._id}>{result?.name}</label>
                                      </li>
                                    )
                                  }) : ""}
                                </ul>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                  onClick={() => { setLocationchecked([]); setShortobj({}); hendledropdownhide("location") }}>{t("Clear")}</button>
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { gethistorypurchase(); hendledropdownhide("location") }}>{t("Apply")}</button>
                              </div>
                            </div>
                          </div>
                        </th>
                        <th>{t("SKU")} </th>
                        <th>{t("Qty")}</th>
                        <th>{t("Amount")}
                          <div className="dropdown d-inline-block com-custom-table-tag-filter">
                            <button className="after-none bg-transparent border-0 dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                              <img className="ms-2 com-custom-table-tag-filter-img" src={base_assets + "images/icons/table-filter-icon.png"} alt="" />
                            </button>
                            <div className="dropdown-menu com-custom-table-tag-filter-menu p-0 border-0 overflow-hidden"
                              ref={dropdownRefprice}

                            >
                              <div className="asc-des-order">
                                <div className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-up.png"} onClick={() => { setShortobj({ key: "price", value: 1 }); hendledropdownhide("price") }} alt="" /> {t("Sort Ascending")}</div>
                                <div className="asc-des-order-single cursor-pointer"> <img src={base_assets + "images/icons/arrow-sort-down.png"} onClick={() => { setShortobj({ key: "price", value: -1 }); hendledropdownhide("price") }} alt="" /> {t("Sort Descending")}</div>
                              </div>
                              <div className="com-custom-table-tag-filter-menu-select">
                                <div className="range-area mt-0">
                                  <div className="range-area-num d-flex justify-content-between mb-4">
                                    <div className="range-area-num-single">
                                      <span className="range-area-num-unit">
                                        {price[0]}
                                      </span>
                                    </div>
                                    <div className="range-area-num-single justify-content-end">
                                      <span className="range-area-num-unit">
                                        {price[1]}
                                      </span>
                                    </div>
                                  </div>
                                  <Slider
                                    range
                                    min={priceobj?.min}
                                    max={priceobj?.max}
                                    value={price}
                                    onChange={setPrice}
                                  />
                                </div>
                              </div>


                              <div className="com-custom-table-tag-filter-menu-btn-grp text-center d-flex align-items-center gap-2">
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 bg-transparent fw-semibold graythemecolor"
                                  onClick={() => { setPrice([0, 0]); setShortobj({}); hendledropdownhide("price") }}>{t("Clear")}</button>
                                <button className="com-custom-table-tag-filter-menu-btn-grp-single flex-grow-1 main-green-bg text-white fw-semibold" onClick={() => { gethistorypurchase(); hendledropdownhide("price") }}>{t("Apply")}</button>
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {purchase?.length ? purchase.map((result, key) => {
                          return (
                            <React.Fragment key={key}>
                              <tr className="text-nowrap" key={key}>
                                <td>{key + 1}</td>
                                <td>{result?.date ? result?.date : "-"}</td>
                                <td>{result?.order_type ? result?.order_type : "-"}</td>
                                <td>{result?.order_no ? result?.order_no : "-"}</td>
                                <td>{result?.location ? result?.location : "-"}</td>
                                <td
                                  className="sku-col table_btn_expand_col col-highlight"

                                  id={"table-btn_" + result?.order_id}
                                >
                                  <div className="td-icons-wrap">
                                    <div>
                                      <span>
                                        {result?.SKU ? result?.SKU : "0"}</span>
                                      <img
                                        className="sku-col-arrow cursor-pointer"
                                        src={
                                          base_assets +
                                          "images/admin/icons/expand-icon.png"
                                        }
                                        alt="" onClick={() => setSubTablecustom(result?.order_id)}
                                      />
                                    </div>


                                  </div>
                                </td>
                                <td>{result?.total_Qty ? result?.total_Qty : "-"}</td>
                                <td>{toFormatPrice(result?.total_amount, { addSymbol: true })}</td>
                              </tr>
                              <tr className={result.subTabledata ? "com-custom-table-tag-subtable" : "com-custom-table-tag-subtable d-none"}>
                                <td colSpan="100">
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>{t("Product Name")} </th>
                                        <th>{t("SKU")}</th>
                                        <th>{t("Stock ID")}</th>
                                        <th>{t("Metal")}</th>
                                        <th>{t("Size")}</th>
                                        <th>{t("Stone")}</th>
                                        <th>{t("Price")}</th>
                                        <th>{t("Qty")}</th>
                                        <th>{t("Amount")}</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <>
                                        {openSubTable?.length ? openSubTable.map((index, skey) => {
                                          return (
                                            <tr key={skey}>
                                              <td>{skey + 1}</td>
                                              <td> <div className='table-pro-img'><img src={index.main_image ? index.main_image : ""} alt="" /></div></td>
                                              <td>{index?.name ? index?.name : "-"}</td>
                                              <td>{index?.SKU ? index?.SKU : "-"}</td>
                                              <td>{index?.stock_id ? index?.stock_id : "-"}</td>
                                              <td>{index?.metal_name ? index?.metal_name : "-"}</td>
                                              <td>{index?.size_name ? index?.size_name : "-"}</td>
                                              <td>{index?.stone_name ? index?.stone_name : "-"}</td>
                                              <td>{index?.price ? index?.price : "-"}</td>
                                              <td>{index?.Qty ? index?.Qty : "-"}</td>
                                              <td>{index?.amount ? index?.amount : "-"}</td>
                                            </tr>
                                          )
                                        }) : ""}

                                      </>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              {
                                excuteTable ? (
                                  <>
                                    {typeof result.subTable == "undefined" ? (result.subTable = "") : result.subTable}
                                  </>
                                ) : null
                              }
                            </React.Fragment>
                          )

                        }) :
                          <tr>
                            <td colSpan="50" className="text-center"> {t("No Data Available In Table")}</td>
                          </tr>
                        }

                      </>
                    </tbody>
                  </table>


                </div>
              </div>
            </div>
          </div>
        </div>

        {purchase.length > 0 ? (
          <Pagination
            total={totalPages}
            current={currentPage}
            pagination={(crPage) => handlePaginations(crPage)}
          />
        ) : (
          ""
        )}

      </div>
    </React.Fragment>
  );
};

export default HistoryPurchase;