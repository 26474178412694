import React, { useState, useEffect, useCallback } from 'react'
import Chart from 'react-apexcharts'
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';

const SummaryPurchaseOrder = (props) => {
  const { setDashboardhideshow, setDefaultset } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [fixed, setFixed] = useState(false)
  const { t } = useTranslation();
  const [completeDataObject, setCompliteDataObject] = useState({ "timeset": "Monthly" })
  const [Summarydata, setSummarydata] = useState()
  let time_periods = ['Daily', 'Monthly', 'Yearly', 'Weekly']
  const summarygraph = useCallback(async () => {
    try {
      let postdata = {
        duration: completeDataObject?.timeset
      }
      let res = await _Api(postdata, `api/v1/dashboard/inventorySummryStatics`)
      if (res?.code === 200) {
        setSummarydata(res?.data)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [completeDataObject]);
  useEffect(() => {
    summarygraph()
  }, [completeDataObject, summarygraph])
  const SummaryPurchaseChart = {
    series: Summarydata?.data?.length ? Summarydata?.data : [],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: Summarydata?.labels?.length ? Summarydata?.labels : [],
      yaxis: [
        {
          title: {
            text: 'Qty',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Value',
          },
        },
      ],
      colors: Summarydata?.color?.length ? Summarydata?.color : [],
    },
  };

  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Summary Purchase Order")}
          </div>

          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t(`${completeDataObject?.timeset}`)}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              {time_periods?.length ? time_periods.map((result, key) => {
                return (
                  <li key={key}
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        timeset: result
                      }))}>
                    <span className="dropdown-item">{t(`${result}`)}</span>
                  </li>
                )
              }) : ""}
            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
            </span>
            <ul className="dropdown-menu">
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li onClick={() => {
                setDashboardhideshow(prevState => ({
                  ...prevState,
                  SummaryPurchaseOrder: 0
                }));
                setDefaultset(true)
              }}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area py-3">
          <div className='dashboard-area-cards-single-chart-area-inner' id="salesTheMostChart">
            <Chart options={SummaryPurchaseChart.options}
              series={SummaryPurchaseChart.series}
              type="bar"
              height={350}
            />

          </div>
        </div>
      </div>
    </div>
  )
}
export default SummaryPurchaseOrder
