import CloseIcon from 'admin/common/icons/CloseIcon';
import PrintIcon from 'admin/common/icons/PrintIcon';
import RightArrow from 'admin/common/icons/right-arrow';
import SearchIcon from 'admin/common/icons/SearchIcon';
import TableLoader from 'admin/common/TableLoader';
import { DateRange, DateRangeNew } from 'components/common/helpers/daterange';
import Advancedfiltermodel from 'components/common/modules/advanced-filter-model';
import { Tablehead } from 'components/common/modules/Tablehead';
import { Pagination } from 'components/common/Pagination';
import { zoneIndexhead } from 'components/common/Tablehead';
import PlusIcon from 'Ecommerce/common/icons/PlusIcon';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import Select from "react-select";
import ZoneModal from './ZoneModal';

export default function ZoneIndex() {

  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;

  const {t} = useTranslation();

  const [limit, setLimit] = useState();
  const [filtername, setFiltername] = useState("");
  const [datefilter, setDatefilter] = useState([]);
  const [openModal ,setOpenModal] = useState(false);
  const [datalodaer, setDatsLodaer] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [statusChangeDropdown, setStatusChangeDropdown] = useState(null);
  const [pages, setPages] = useState({totalPages: 20,currentPage: 1,});
  const { totalPages, currentPage } = pages;

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  const getlimit = (limit) => {
    setLimit(limit);
  };

  const zoneData = [
    {id: 'sdfsfsdfsf', input: true, refno: '0002', location: 'Central World', zone: 'Self B', Saleperson: 'Rose anne rested', sku: '5', issuedate: '24/09/24', issuetime: '02:56:56 PM', returndate: '26/09/224', returntime: '04:56:56 PM', issue: 2, sold: 1, return: 0, balance: 3, status : 0, user: 'Admin', remark : 'asjfk'  },
    {id: '304950', input: true, refno: '0002', location: 'Central World', zone: 'Self B', Saleperson: 'Rose anne rested', sku: '5', issuedate: '24/09/24', issuetime: '02:56:56 PM', returndate: '26/09/224', returntime: '04:56:56 PM', issue: 0, sold: 2, return: 0, balance: 2, status : 1, user: 'Customer', remark : 'asjfk'  }
  ]
  
  const handlePaginations = (current) => {
    setPages({ ...pages, currentPage: current });
    // getlowstocklist(current, "");

  };

  return (
    <div className="main-body zone_module">
      <div className="container">
        <div className="row">
        <div className="col-lg-12 d-flex justify-content-between">
              <h1 className="main-body-heading"> 
                <span>
                  {t('Inventory')} <span className='ms-2'><RightArrow/></span>
                </span>
                <span>
                  {t('Inventory')} <span className='ms-2'><RightArrow/></span>
                </span>
                {t("Zone")}
              </h1>
              <Link
                    to="createissue"
                    className={"main-body-top-tab-bar-right-col-a"}
                    state={{create: true}}
                  >
                    <PlusIcon color="#fff"/>
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("Create Issue")}
                    </span>
                  </Link>
        </div>
        <div className='zone_module-inner'>
        <div className="main-body-top-status-bar">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />
               
                <div className="main-body-top-status-bar-multi-date-picker">
                  <div className={`main-body-top-status-bar-multi-date-picker-iconholder ${openSearch ? 'active' : ''}`}><span onClick={()=>setOpenSearch(true)}><SearchIcon color="#A4A4A4"/></span><div className={`main-body-top-status-bar-multi-date-picker-iconholder-searchbar ${openSearch ? 'active' : ""}`}><input type="text" className='input'/> <span onClick={()=>setOpenSearch(false)}><CloseIcon/></span> </div></div>
                  <span className='main-body-top-status-bar-multi-date-picker-iconholder'><PrintIcon color="#A4A4A4"/></span>
                  <DateRangeNew setDatefilter={setDatefilter} />
                  
                </div>
        </div>
        <div className='zone_module-inner-table'>
        <div
                className="main-body-main-table-wrap position-relative"
                style={{ maxWidth: '100%', overflowX: 'auto' }}
              >
                <table
                  id="my_inventory"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >
                  <Tablehead tablehead={zoneIndexhead} tblName={'zoneindex_table'}  />
                  <tbody>
                    <>

                      {zoneData?.length ? zoneData.map((result, key) => {
                        return (
                          <tr key={key} className="">
                            <td>
                              <div className={'com-check'}>
                                <input key={key}
                                  className='inventorytablecheck inventorytableHead gisClassTh'
                                  id={result?.id}
                                  type="checkbox"
                                />
                                <label htmlFor={result?.id} className="com-check-label" />
                              </div>
                            </td>
                            <td className="ref-no cursor-pointer" ><Link className='text-decoration-none' to={'edit'} state={{create:false, zoneData : result}}>{result?.refno ? result?.refno : "-"}</Link></td>
                            <td className="table-pro-img" >
                              {result?.location ? result?.location : "-"}
                            </td>
                            <td className="">
                              {result?.zone ? result?.zone : "-"}
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.Saleperson ? result?.Saleperson : "-"}</div>
                            </td>
                            <td>
                              <div className="tooltip-area-up">{result?.sku ? result?.sku : "-"}</div>
                            </td>
                            <td>
                              <div className="tooltip-area-up dateandtime"><span>{result?.issuedate ? result?.issuedate : ""}</span><span>{result?.issuetime ? result?.issuetime : null}</span></div>
                            </td>
                            <td className="">
                              <div className="tooltip-area-up dateandtime"><span>{result?.issuedate ? result?.returndate : ""}</span><span>{result?.returndate ? result?.returndate : null}</span></div>
                            </td>
                            <td className="">{result?.issue ? result?.issue : "-"}</td>
                            <td className="">{result?.sold ? result?.sold : "-"}</td>
                            <td className="">{result?.return ? result?.return : "-"}</td>
                            <td className="">{result?.balance ? result?.balance : "-"}</td>
                           
                            <td className="position-relative">
                              <div className={`successStatusBox ${result?.status === 0 ? 'open' : 'close'}`} onClick={()=>{ statusChangeDropdown === key ? setStatusChangeDropdown(null) : setStatusChangeDropdown(key) }}>
                                {result?.status === 0 ?
                                  <div className='successStatusBox-tag'>{t("Open")}</div>
                                  :
                                  <div className='successStatusBox-tag'>{t("Close")}</div>
                                }
                              </div>
                              {statusChangeDropdown === key && result?.status === 0 ? 
                              <div className={`successStatusBox-dropdown`} onClick={()=>setOpenModal(true)}>
                               <div className={`successStatusBox-dropdown-inner ${result?.status === 0 ? 'close' : 'open'}`}>{result?.status === 0 ? 'Close' : 'Open'}</div>
                              </div>
                              : null }
                            </td>
                            <td className="">{result?.user ? result?.user : "-"}</td>
                          </tr>
                        );
                      }) : ''}
                    </>
                  </tbody>
                </table>
                {datalodaer && <TableLoader />}
              </div>
              {zoneData.length > 0 ? (
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    pagination={(crPage) => handlePaginations(crPage)}
                  />
                ) : (
                  ""
                )}
        </div>
        </div>
        </div>
      </div>
      {openModal && <ZoneModal setOpenModal={setOpenModal} popupContent={{header: 'You Want to change status', subheader: 'You want to change the status from open to close. The closed status can not be changed.', withicon: false}}/>}
    </div>
  )
}
