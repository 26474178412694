import React, { useState, useEffect } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link } from 'react-router-dom';
import logoMainImg from "./assets/images/gis-main-logo.png"
import headerCross from "./assets/images/header-cross.png"
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const { activeDiv, setActiveDiv } = props;
  const [openToggle, setOpenToggle] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        setOpenToggle(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <header className="loading-header">
        <nav className="loading-header-navbar navbar navbar-expand-lg">
          <div className="container-fluid ">
            <a href="#!">
              <span className="main-logo-box">
                <img
                  src={logoMainImg}
                  className="logo"
                  alt="logo img"
                ></img>
              </span>
            </a>
            <button
              className="navbar-toggler "
              type="button"
              onClick={() => setOpenToggle(true)}
            >
              <span className="">
                <HiMenuAlt3 color="#5CC7C0" size={32} />
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarColor04">
              <ul className="loading-header-navbar-nav navbar-nav">
                <li className="loading-header-nav-item nav-item">

                  <Link to="#" className={activeDiv === 'home' ? "loading-header-nav-link nav-link active-nav" : 'loading-header-nav-link nav-link'} data-id="home" onClick={() => setActiveDiv('home')} style={{ color: activeDiv === 'home' && '#5CC7C0'}}>
                    {t("Home")}
                  </Link>

                </li>
                <li className="loading-header-nav-item nav-item">
                  <Link to="#gis247" className={activeDiv === 'gis247' ? "loading-header-nav-link nav-link active-nav" : 'loading-header-nav-link nav-link'} data-id="gis247" style={{ color: activeDiv === 'gis247' && '#5CC7C0'}} onClick={() => setActiveDiv('gis247')}>
                    GIS247{" "}
                  </Link>
                </li>
                <li className="loading-header-nav-item nav-item">
                  <Link to="#features" className={activeDiv === 'features' ? "loading-header-nav-link nav-link active-nav" : 'loading-header-nav-link nav-link'} data-id="features" style={{ color: activeDiv === 'features' && '#5CC7C0'}} onClick={() => setActiveDiv('features')}>
                  {t("Features")}{" "}
                  </Link>
                </li>
                <li className="loading-header-nav-item nav-item">
                  <Link to="#ecommerce" className={activeDiv === 'ecommerces' ? "loading-header-nav-link nav-link active-nav" : 'loading-header-nav-link nav-link'} data-id="ecommerces" style={{ color: activeDiv === 'ecommerces' && '#5CC7C0'}} onClick={() => { setActiveDiv('ecommerces') }}>
                  {t("E-Commerce")}
                  </Link>
                </li>

                <li className="loading-header-nav-item nav-item">
                  <Link to="#contactus" className={activeDiv === 'contactus' ? "loading-header-nav-link nav-link active-nav" : 'loading-header-nav-link nav-link'} data-id="contactus" style={{ color: activeDiv === 'contactus' && '#5CC7C0'}} onClick={() => setActiveDiv('contactus')}>
                  {t("Contact Us")}
                  </Link>

                </li>
              </ul>
              <form className="button-nav">
                <Link to="/login" className="header-login-btn" style={{ margin: "auto", padding: "8px 24px" }}>Login </Link>
                <Link to='/appauth/signup'>
                  <button className="landing-common-button header-register-btn"  >Register</button>
                </Link>
              </form>
            </div>
          </div>
        </nav>
        {openToggle && (
          <div
            className="d-block loading-header-navbar-menu navbar-menu position-fixed top-0 start-0 bottom-0  mw-lg "
            style={{ zIndex: "9999" }}
          >
            <div onClick={() => setOpenToggle(false)}
              className="navbar-close navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark"
              style={{ opacity: "75%" }}
            ></div>
            <nav className="position-relative flex-column  bg-white navbar-menu-show">
              <div className="d-flex justify-content-between align-items-center">
                <a href="#!">
                  <span className="main-logo-box">
                    <img
                      src={logoMainImg}
                      className="logo"
                      alt="logo img"
                    ></img>
                  </span>
                </a>
                <button
                  onClick={() => setOpenToggle(false)}
                  className="navbar-close btn-close "
                  type="button"
                  aria-label="Close"
                >
                  <img src={headerCross} alt="closeicon"/>
                </button>

              </div>
              <div className="loading-header-navbar-mob navbar-mob">
                <ul className="loading-header-navbar-nav navbar-nav">
                  <li className="loading-header-nav-item nav-item ">

                    <a className="loading-header-nav-link nav-link" href="#home" onClick={() => { setActiveDiv('home'); setOpenToggle(false) }}>
                    {t("Home")}
                    </a>

                  </li>
                  <li className="loading-header-nav-item nav-item">

                    <a className="loading-header-nav-link nav-link" href="#gis247" onClick={() => { setActiveDiv('gis247'); setOpenToggle(false) }}>
                      GIS247{" "}
                    </a>

                  </li>
                  <li className="loading-header-nav-item nav-item">

                    <a className="loading-header-nav-link nav-link" href="#features" onClick={() => { setActiveDiv('features'); setOpenToggle(false) }}>
                    {t("Features")}{" "}
                    </a>

                  </li>
                  <li className="loading-header-nav-item nav-item">
                    <a className="loading-header-nav-link nav-link" href="#ecommerce" onClick={() => { setActiveDiv('ecommerces'); setOpenToggle(false) }}>
                    {t("E-Commerce")}
                    </a>
                  </li>

                  <li className="loading-header-nav-item nav-item">
                    <a className="loading-header-nav-link nav-link" href="#contactus" onClick={() => { setActiveDiv('contactus'); setOpenToggle(false) }}>
                    {t("Contact Us")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-auto">
                <form className="button-nav">
                  <Link to="/login" className="header-login-btn" >{t("Login")} </Link>
                  <Link to='/appauth/signup'>
                    <button className="landing-common-button header-register-btn">{t("Register")}</button>
                  </Link>
                </form>
              </div>
            </nav>
          </div>
        )}
      </header>

    </>
  );
};

export default Header;
