import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { _Api } from '../../api/_call';
import { loading, Updateloading } from '../../common/helpers/toastify'
const ChangeDomainModal = (props) => {
  const { dataid, getdomain } = props
  const { t } = useTranslation();
  const [domaintype, setDomaintype] = useState(dataid?.type)
  console.log(domaintype, "")
  const changetyp = async () => {
    let GetloadingID = loading()
    try {
      let postdata = {
        "id": dataid?._id,
        "type": domaintype ? domaintype : dataid?.type
      }
      let res = await _Api(postdata, `api/v1/APP/domain/type-change`)
      if (res?.code === 200) {
        getdomain()
        Updateloading(t, GetloadingID, (res?.message))
      } else {
        Updateloading(t, GetloadingID, (res.message), 'error')
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    if (dataid?.type) {
      setDomaintype(dataid?.type)
    }
  }, [dataid])
  return (
    <div className="modal fade com-modal" id="ChangeDomainModal" tabIndex="-1" aria-labelledby="ChangeDomainModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" style={{ "width": "670px", "maxWidth": "90%" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title fw-semibold" id="ChangeDomainModalLabel">{t("Change Domain For")} {dataid?.name}</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body fs-14">
            <div className="d-flex gap-3 mb-4">
              <input type="radio" name="domainradio" className="input-accent-green"
                onChange={(e) => setDomaintype(1)}
                checked={domaintype === 1 ? true : false} />
              <div>
                <span className="fw-semibold d-block">{t("Primary domain")}</span>
                <span>{t("Displayed in the address bar when visitors are browsing Online Store")}. </span>
              </div>
            </div>
            {/* <div className="d-flex gap-3 mb-4">
              <input type="radio" name="domainradio" className="input-accent-green"
                onChange={(e) => setDomaintype(2)}
                checked={domaintype === 2 ? true : false} />
              <div>
                <span className="fw-semibold d-block">{t("Redirecting domain")} ({t("Current")})</span>
                <span>{t("Directs users to the primary domain for Online Store")}.</span>
              </div>
            </div> */}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn text-white border main-green-text" data-bs-dismiss="modal"
              onClick={() => setDomaintype(dataid?.type)}>{t("Cancel")}</button>
            <button type="button" className="btn text-white main-green-bg borde-0" onClick={() => changetyp()} data-bs-dismiss="modal" >{t("Change Domain Type")}</button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ChangeDomainModal;