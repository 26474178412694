import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableDropdown from "../../assets/icons/TableDropDown";
import AccountSidebar from "./AccountSidebar";
import { _Api } from '../../../common/api';
import $ from 'jquery';
import Spinner from "../../../common/Spinner";
const AppointmentData = () => {
  const { t } = useTranslation();
  const [appoinmentlist, setAppoinmentlist] = useState([])
  const [loadeing, setloading] = useState(true)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const getappoinmentdata = async () => {
    setloading(true)
    try {
      let postdata = {}
      let res = await _Api(postdata, `Appointment/get-Appointment`)
      if (res?.statusCode === 200) {
        setAppoinmentlist(res?.data)
        setloading(false)
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }
  useEffect(() => {
    getappoinmentdata()
  }, [])
  const setSubTablecustom = async (id, product, location_id, currency) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector(`common-main-table-wrap-${id}`);
    var classList_ = e.classList;
    if (classList_.contains("rotate-180")) {
      classList_.remove("rotate-180");
      let alllist_data = [...appoinmentlist];
      alllist_data.filter(item => item._id === id).forEach((result) => {
        result.subTabledata = []
        result.subTable = false
      })
      setAppoinmentlist(alllist_data);
    } else {
      classList_.add("rotate-180");
      let alllist_data = [...appoinmentlist];
      try {
        const postdata = {
          "Product": product,
          "location": location_id,
          "currency": currency ? currency : "USD"
        }
        let prioritydata = await _Api(postdata, `Appointment/get-Appointment-sublist`);
        if (prioritydata?.statusCode === 200) {
          alllist_data.filter(item => item._id === id).forEach((result) => {
            result.subTabledata = prioritydata?.data
            result.subTable = true

          })
          setAppoinmentlist(alllist_data);
        } else {
          alllist_data.filter(item => item._id === id).forEach((result) => {
            result.subTabledata = []
            result.subTable = true
          })
          setAppoinmentlist(alllist_data);
        }
      } catch (err) {
        console.log(err, "err")
        alllist_data.filter(item => item._id === id).forEach((result) => {
          result.subTabledata = []
          result.subTable = true
        })
        setAppoinmentlist(alllist_data);
      }

      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);

    }
  }
  return (
    <div className="accountDetailMainPage d-flex ecommerce-main-layout-bodyContent">
      <AccountSidebar />
      <div className="account-details">
        <div className="appointmentMain-Box">
          <div className="appointmentMain-Box-headBox">
            <h2>{t("Appointment")}</h2>
          </div>
          <div className="com-ecomTableMain">
            <table
              id="appointmentdata"
              className="com-ecomTableMain-table table align-middle"
            >
              <thead className="com-ecomTableMain-table-thead">
                <tr className="text-nowrap align-middle">
                  <th>
                    <div>#</div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Date Time")}
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Ref No.")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("SKU")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>

                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Email")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Location")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="user-name-width ">
                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                      {t("Appointment Date")}
                      <div className="dropdown com-ecomTableMain-table-thead-singleRowHead-dropdownBox">
                        <button
                          className="after-none bg-transparent border-0 dropdown-toggle p-0 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <span className="dropdownToggle_img"><TableDropdown width={15} height={10} /></span>
                        </button>
                        <div
                          className={
                            "dropdown-menu com-ecomTableMain-table-thead-singleRowHead-dropdownBox-dropdownMenu border-0 overflow-hidden"
                          }
                        >
                          <div className="dropdown-content">
                            {t("select")}
                          </div>
                          <div className="dropdown-content">
                            <input type="text" className="dropdown-content-inputBox" placeholder="Search..." />
                          </div>

                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="com-ecomTableMain-table-tbody">
                {loadeing ?
                  <tr className="com-ecomTableMain-table-tbody-trBox">
                    <td colSpan="50" className="text-center">
                      <div className="com-ecomTableMain-table-tbody-trBox-noDataBox">
                        <Spinner />
                      </div>
                    </td>
                  </tr> :
                  appoinmentlist?.length ? appoinmentlist.map((result, key) => {
                    return (
                      <>
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{result?.date ? result?.date : "-"}</td>
                          <td style={{ cursor: "pointer" }}>
                            {result?.ref_no ? <div onClick={() => {
                              if (result?.url) {
                                window.open(result?.url, '_blank', 'noreferrer')
                              }
                            }}>{result?.ref_no ? result?.ref_no : "-"}</div>
                              : "-"
                            }
                          </td>
                          <td className="d-flex gap-2">
                            <div className="mr-2">{result?.SKU ? result?.SKU : "-"}</div>
                            <img
                              id={"table-btn_" + result?._id}
                              onClick={() => setSubTablecustom(result?._id, result?.product, result?.location_id, result?.currency)}
                              className="sku-col-arrow cursor-pointer pl-2"
                              alt="expandicon"
                              src={
                                base_assets +
                                "images/admin/icons/expand-icon.png"
                              }
                            />
                          </td>
                          <td>{result?.email ? result?.email : "-"}</td>
                          <td>{result?.location ? result?.location : "-"}</td>
                          <td>{result?.appointment_date ? result?.appointment_date : "-"}</td>
                        </tr>
                        <tr className={result?.subTable ? "com-custom-table-tag-subtable" : "com-custom-table-tag-subtable d-none"}>
                          <td colSpan="100">
                            <table
                              id="appointmentdata"
                              className="com-ecomTableMain-table table align-middle"
                            >
                              <thead className="com-ecomTableMain-table-thead">
                                <tr className="text-nowrap align-middle">
                                  <th>
                                    <div>#</div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Image")}
                                    </div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("SKU")}

                                    </div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Metal")}

                                    </div>
                                  </th>

                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Stone")}

                                    </div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Size")}
                                    </div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Stock ID")}

                                    </div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Qty")}

                                    </div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Price")}

                                    </div>
                                  </th>
                                  <th className="user-name-width ">
                                    <div className="com-ecomTableMain-table-thead-singleRowHead">
                                      {t("Amount")}

                                    </div>
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <>
                                  {result?.subTabledata?.length ? result.subTabledata.map((element, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <div className="pro-border-img">
                                          <img className="sublistimage object-fit-cover"
                                            src={element?.image} alt="" />
                                        </div>
                                        <td>{element?.SKU ? element?.SKU : "-"}</td>
                                        <td>{element?.metal ? element?.metal : "-"}</td>
                                        <td>{element?.stone ? element?.stone : "-"}</td>
                                        <td>{element?.size ? element?.size : "-"}</td>
                                        <td>{element?.stock_id ? element?.stock_id : "-"}</td>
                                        <td>{element?.qty ? element?.qty : "-"}</td>
                                        <td>{element?.price ? element?.price : "-"}</td>
                                        <td>{element?.amount ? element?.amount : "-"}</td>
                                      </tr>
                                    )
                                  }) : ""}
                                </>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    )
                  }) :
                    <tr className="com-ecomTableMain-table-tbody-trBox">
                      <td colSpan="50" className="text-center">
                        <div className="com-ecomTableMain-table-tbody-trBox-noDataBox">
                          {t("No data available")}
                        </div>
                      </td>
                    </tr>

                }


              </tbody>
              <tfoot className="com-ecomTableMain-table-tfootBox">
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentData;
