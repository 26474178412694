import React from 'react'

const Showloader = () => {

    return (
        <div className="main-body">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div id="table_loader" >
                            <div className="dots">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )

}

export default Showloader