import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const { t } = useTranslation();
  const handleGoBack = () => {
    window.history.back();
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('UserToken')) {
      navigate('/logout');
    }
  }, [navigate]);
  return (
    <div className="main-body">
      <div className="error-page" id="wrapper">
        <img className="error-page-img" src={base_assets + "images/img-404.png"} alt="" />
        <div className="error-page-text" id="info">
          <h1 className="error-page-text-heading">{t("oops!")}</h1>
          <h3 className="error-page-text-para">{t("This page could not be found")}</h3>
        </div>
        <Link className='error-page-btn' role="button" onClick={handleGoBack}>
          {t("Go Back to Home")}
        </Link>
      </div >
    </div >
  )
}

export default PageNotFound