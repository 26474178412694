import React, { useCallback, useEffect, useState } from 'react'
import './setting.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import LocationIcon from "../common/icons/sidebar/Location.js";
import Apiauth from '../../API/Apiauth.js';
import NoLocationData from '../../assets/images/icons/eComLocationNot.svg'
import TableLoader from '../common/TableLoader.js';
import Spinner from '../../Ecommerce/template_one/common/Spinner.js';

const SetupSetting = () => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  
  const [CMSLocations, setCMSLocations] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const getEcomLocations = useCallback(async () => {
    setShowLoader(true);
    try {
      const postdata = {};
      let apiRes = await Apiauth(postdata, `cms/get-cms`);
      if (apiRes.code === 200) {
        setCMSLocations(apiRes.data);
        setShowLoader(false)
      }
    } catch (err) {
      console.error(err, 'err');
    }
  }, []);
  useEffect(() => {
    getEcomLocations();

  }, [getEcomLocations]);

  return (
    <div className="main-content-wrapper-body">
      <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
        <div className="main-content-wrapper-body-top-bar-left">
          <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
            <span>{t("CMS Locations")}</span>
          </h1>
        </div>
      </div>
      <div className="admin-main-card">
        {showLoader ? <div className='w-100 h-100 d-flex align-items-center justify-content-center'><Spinner/></div> : 
        <div className="admin-setting-grid">
          {CMSLocations?.length ? CMSLocations?.map((value, index) => {
            return (
              <Link
                to={`/app/admin/cms/${value?._id}/frontcms`}
                key={index}
                state={{location : value?._id}}
                className="admin-setting-grid-single cmslocation"
              >
                <img className="admin-setting-grid-single-img" src={value?.image} alt='image'/>
                <button className='admin-setting-grid-single-button'>{value?.name}</button>
              </Link>

            )
          })
            : 
            <div className='admin-setting-grid-default'>
              {/* <img src={base_assets + 'images/eComLocationNot.svg'} alt="" /> */}
              <div className='admin-setting-grid-default-tag'>{t("No Location Found")}</div>
              <img src={NoLocationData} alt="" />
            </div>
            }
        </div>
        }
      </div>
    </div>
  )
}

export default SetupSetting
