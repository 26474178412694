import React from "react";

const Accordion = ({ id, title, content, imageSrc, isOpen, handleChange, style }) => {
  const titleStyle = {
    fontSize: "20px",
    width: "100%",
    fontWeight: "500",
    backgroundColor: isOpen === id ? "#F9F9F9" : "transparent",
    ...style,
  };


  return (
    <div className="accordion" id={`accordion${id}`} >
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading${id}`} style={titleStyle}>
          <div className="d-flex align-items-center" style={{margin: "0px  0px 0px 20px" }}
            onClick={(() => {
              handleChange(id)
            })}
          >
            {imageSrc && (
              <img
                src={imageSrc}
                alt= ""
                style={{ width: "30px", height: "30px" }}

              />
            )}
            <button
              style={{ backgroundColor: isOpen === id ? "#F9F9F9" : "white", border: "none",  }}
              className={`accordion-button${isOpen === id ? " active" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${id}`}
              aria-expanded={isOpen ? "true" : "false"}
              aria-controls={`collapse${id} `}
            >
              {title}

            </button>
          </div>
        </h2>
        <div
          id={`collapse${id}`}
          className={`accordion-collapse collapse${isOpen === id ? "show" : ""}`}
          aria-labelledby={`heading${id}`}
          data-bs-parent={`#accordion${id}`}
        >
          <div className="accordion-body" style={{ fontSize: "16px", width: "100%", height: "auto", minHeight: "200px", backgroundColor: "#F9F9F9", color: "#707070", ...style }}>
             {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
