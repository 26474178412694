import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import { Tablehead } from '../../components/common/modules/Tablehead'
import { posrepairorderdeliverde_head } from '../../components/common/Tablehead'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { _Api } from "../common/api/_call";
import CheckoutBlank from "../checkout/checkoutBlank";
import CustomerSearchModal from "../common/module/customerSearch";
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function';
import { Customsubtable } from "../Custom/subtable/Customsubtable";
import CheckoutPayment from '../checkout/checkoutPayment'
import CheckoutSell from '../checkout/checkoutSell'
import $ from 'jquery';
import { store } from '../../source/index'
const RepairOrderDelivered = () => {
  const navigate = useNavigate();
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const customer = useSelector((state) => state.app.customer);
  const [newcolumndata, setnewcolumndata] = useState(posrepairorderdeliverde_head)
  const [repairlist, setRepairlist] = useState([])
  const { t } = useTranslation();
  const [excuteTable, setexcuteTable] = useState(false);
  const [TransactionDate, setTransactionDate] = useState();
  const [, setSelectAllStatus] = useState();
  const [, setfilterby] = useState("");
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank')
  const [, setdeletestatus] = useState(false)
  const [UpdatedCart, setUpdatedCart] = useState();
  const [showpayment, setShowpayment] = useState(false);
  const [searchproductstatus, setsearchproductstatus] = useState(false);
  const [orderid, setOrderid] = useState('');
  const selectTransactionDate = (input) => {
    setTransactionDate(input);
  };

  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'report_repairorderdeliverde_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const getrepairorderdta = async () => {
    setexcuteTable(false);
    var expand_col = document.querySelector(".table_btn_expand_col");
    if (expand_col) { expand_col.classList.remove("rotate-90"); }
    try {
      setTimeout(async function () {
        UnSetdataTable('repair_order_table');
        const postdata = {
          "customer_id": customer?.id,
          "order_type": "repair_order",
          "date": TransactionDate
        }
        let res = await _Api(postdata, `api/v1/POS/customOrder/getOrderList`)
        if (res?.code === 200) {
          setexcuteTable(true);
          setRepairlist(res?.data);
          SetdataTable('repair_order_table', '5', newcolumndata, [1]);

        }
        else {
          SetdataTable('repair_order_table', '5', newcolumndata, [1]);
        }
      }, 100)
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const setSubTablecustom = async (order_id) => {
    var e = document.querySelector("#table-btn_" + order_id);
    var row = document.querySelector('#common-main-table-wrap-' + order_id);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...repairlist];
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = null
      })
      setRepairlist(alllist_data);
    } else {
      classList_.add("rotate-90");
      let alllist_data = [...repairlist];
      const postdata = {
        "Order_id": order_id
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/customOrder/getSkuByOrderList`);
      var html_ = Customsubtable(prioritydata, order_id, t);
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = html_
      })
      setRepairlist(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + order_id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  }
  useEffect(() => {
    getrepairorderdta()
    // eslint-disable-next-line
  }, [customer?.id, TransactionDate, newcolumndata])
  const selectedproduct = (event, id, ststus) => {
    if (event.target.checked) {
      if (ststus === 5) {
        setOrderid(id)
      } else {
        setOrderid("")
      }
    } else {
      setOrderid("")
    }
  }

  const GetCartdetailorder = async () => {
    try {
      const postdata = {
        "order_id": orderid,
        'order_type': 'repair_order',
        'by_repair_order': true
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setShowcheckout('sell');
      } else {
        navigate('/app/pos/repair/repairOrderDelivered')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className='com-data-area'>
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left page-back-btn-inside">
                    <Link to="/app/pos/repair"><img className='page-back-btn-inside-img' src={base_assets + "/images/icons/arrow-left.png"} alt="back" /></Link>
                    <h1 className="heading">{t("Repair Order")}</h1>
                  </div>

                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <button className="com-search-area-left-img scan-img"><img src={base_assets + "/images/pos/icons/scan-icon.png"} alt="" /></button>
                      <input type="text" placeholder={t("Scan barcode, Search SKU")} />
                      <button className="btn-style lh-0">
                        <img className="com-search-area-img" src={base_assets + "/images/pos/icons/magnifying-glass.png"} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='com-data-area-header delivered-header'>
                <div className='delivered-header-left'>
                  <CustomerSearchModal ></CustomerSearchModal>
                  <ul className='graph-indicator ms-4 text-nowrap'>
                    <li className='graph-indicator-single'>
                      <span className='graph-indicator-single-color danger-bg'></span>
                      <label className='graph-indicator-single-label'>{t("Create your own")}</label>
                    </li>
                    <li className='graph-indicator-single'>
                      <span className='graph-indicator-single-color status-pending'></span>
                      <label className='graph-indicator-single-label'>{t("Mix & Match")}</label>
                    </li>
                  </ul>
                </div>
                <div className='customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date'>
                  <DatePicker
                    className="placeholder-up-input-field"
                    selected={TransactionDate}
                    onChange={selectTransactionDate}
                    placeholderText='MM-DD-YYYY'
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    autoComplete='none'
                  />
                  <img
                    className="placeholder-up-input-field-calender"
                    src={base_assets + "images/icons/calendar-green.svg"}
                    alt=""
                  />
                </div>
              </div>
              <div className="com-data-area-inner">
                <div className="main-body-main-table-wrap vertical-scroll-table" id="table-scroll" >
                  <table id='repair_order_table' className="common-table first-row-gray w-100">
                    <Tablehead
                      tablehead={posrepairorderdeliverde_head}
                      tblname={'report_repairorderdeliverde_tbl'}
                      setShowoption={setSelectAllStatus}
                      setfilterby={setfilterby}
                    />
                    <tbody>
                      <>
                        {repairlist.length ? repairlist.map((result, key) => {
                          return (
                            <React.Fragment key={key}>
                              <tr className='tr-left-red-border ' id={`common-main-table-wrap-${result?.order_id}`} key={key}
                              >
                                <td className="row-check">
                                  <div className="com-check radio">
                                    <input
                                      type="radio"
                                      name='customchack'
                                      id={result?.order_id}
                                      onChange={(e) => selectedproduct(e, result?.order_id, result?.status)}
                                      className="repair_item"
                                    />
                                    <label htmlFor={result?.order_id} className="com-check-label" />
                                  </div>
                                </td>
                                <td>{key + 1}</td>
                                <td>{result?.transaction_date ? result?.transaction_date : ""}</td>
                                <td className='link-color text-decoration-none'>{result?.order_no ? result?.order_no : ""}</td>
                                <td className='sku-col table_btn_expand_col'
                                  onClick={() => setSubTablecustom(result?.order_id)}
                                  id={"table-btn_" + result?.order_id}>  <img
                                    className="sku-col-arrow cursor-pointer"
                                    src={
                                      base_assets +
                                      "/images/icons/right_green_button.png"
                                    }
                                    alt=""
                                  />
                                  {result?.SKU}</td>
                                <td>{result?.Qty ? result?.Qty : ""}</td>
                                <td>{toFormatPrice(result?.labour, { addSymbol: true })}</td>
                                <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                <td className='main-green-text'>{toFormatPrice(result?.payment, { addSymbol: true })}</td>
                                <td className='danger-text'>{toFormatPrice(result?.balance_due, { addSymbol: true })}</td>
                                <td className='danger-text'>{result?.delivery_date ? result?.delivery_date : ""}</td>
                                <td>{result?.location_name ? result?.location_name : ""}</td>
                                <td>{result?.status === 1 ? <label className='status-tab open-order-bg'>{t("Open Order")}</label>
                                  :
                                  result?.status === 2 ?
                                    <label className='status-tab production-col'>{t("Production")}</label>
                                    :
                                    result?.status === 3 ?
                                      <label className='status-tab mgf-stock-bg'>{t("MFG Stock")}</label>
                                      :
                                      result?.status === 4 ?
                                        <label className='status-tab transit-bg-col'>{t("transit")} </label>
                                        :
                                        result?.status === 5 ?

                                          <label className='status-tab stoke-bg'>{t("Stock")}</label>
                                          :
                                          result?.status === 6 ?
                                            <label className='status-tab sol-out-col'>{t("Sold Out")} </label>
                                            :
                                            result?.status === 9 ?
                                              <label className='status-tab gray-tab'>{t("Close Order")}</label>
                                              : ''
                                }
                                </td>
                              </tr>
                              {
                                excuteTable ? (
                                  <>
                                    {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                  </>
                                ) : null
                              }
                            </React.Fragment>

                          )

                        }) : ""}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{
                          repairlist
                            ? repairlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                val?.Qty),
                              0
                            )
                            : ""
                        }</td>
                        <td>{
                          toFormatPrice(repairlist
                            ? repairlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.labour)),
                              0
                            )
                            : "", { addSymbol: true })

                        }</td>
                        <td>{
                          toFormatPrice(repairlist
                            ? repairlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.amount)),
                              0
                            )
                            : "", { addSymbol: true })
                        }</td>
                        <td className='main-green-text'>{
                          toFormatPrice(repairlist
                            ? repairlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.payment)),
                              0
                            )
                            : "", { addSymbol: true })
                        }</td>
                        <td className='danger-text'>{
                          toFormatPrice(repairlist
                            ? repairlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.balance_due)),
                              0
                            )
                            : "", { addSymbol: true })
                        }</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className='com-data-area-footer-btn'>
                  <button className='com-btn disabled-hover-btn'>

                  </button>
                  <button onClick={() => { GetCartdetailorder(); }}
                    className={orderid !== '' && showcheckout === 'blank' ? 'com-btn ms-auto' : 'com-btn ms-auto btn-disabled'}>
                    {t("Submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                ordertype={"pos_order"}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
                by_repair_order={true}
                order_id={orderid}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                by_repair_order={true}
                order_id={orderid} />
            ) : (
              <CheckoutBlank />
            )}
          </div>

        </div>
      </div>
    </>
  )
}

export default RepairOrderDelivered;