import React, { useEffect, useState, useCallback, useRef } from "react";
import { graphqlPost, _Api } from "../api/_call";
import Select, { components } from "react-select";
import SelectBox from "../common/selectbox/selectBox";
import { useTranslation } from 'react-i18next';
export const ItemList = (props) => {
  const [ItemList, setItemList] = useState(null);
  const [ItemList_, setItemList_] = useState(null);
  const dataFetched = useRef();
  const { SetItem_product, selected_id } = props;
  const getItem = async () => {
    try {
      const postdata = `{
                        items {
                            id
                            name 
                          }
                        }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setItemList(responce.data);
      dataFetched.current = false
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getItem();
  }, []);
  useEffect(() => {
    if (ItemList_) {
      SetItem_product(ItemList_);
    } else {
      SetItem_product(selected_id);
    }
  }, [ItemList_, SetItem_product, selected_id]);
  let status = [];

  let data_ = [];
  ItemList?.items?.forEach((result, index) => {
    if (selected_id !== "value" && selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    status.push({
      label: result.name,
      value: result.id,
    });
  });
  const { t } = useTranslation()
  return (
    <React.Fragment>

      <SelectBox
        option={status}
        placeholder_val={t("Item")}
        setstatusSelectedOptions={setItemList_}
        selected_val={data_}
      ></SelectBox>

    </React.Fragment>
  );
};

export const GetLangList = (props) => {
  const [LangList, setLangList] = useState(null);
  const dataFetched = useRef();
  const { SetLangList_map, index_list, selected_id, setDescription_language } = props;
  const [statusSelectedOptions_LangList, setstatusSelectedOptions_LangList] =
    useState(null);
  const getLang = async () => {
    try {
      const postdata = [];
      let lang_data = await _Api(postdata, `api/v1/APP/product/getLang`);
      if (lang_data.code === 200) {
        setLangList(lang_data.data);
        dataFetched.current = false;
      } else {
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  var data_ = [];
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getLang();
  }, []);
  var status = [];
  LangList?.forEach((result, index) => {
    if (selected_id === result) {
      data_ = { label: result, value: result };
    }
    status.push({
      value: result,
      label: result,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_LangList) {
      let locations_arr = statusSelectedOptions_LangList;
      let SetLangList_map_ = [...SetLangList_map];
      SetLangList_map_[index_list]["language"] = locations_arr;
      setDescription_language(SetLangList_map_);
    }
  }, [SetLangList_map, index_list, setDescription_language, statusSelectedOptions_LangList]);
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Language")}
        setstatusSelectedOptions={setstatusSelectedOptions_LangList}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const CollectionsList = (props) => {
  const [CollectionsList, setCollectionsList] = useState(null);
  const [CollectionsList_, setCollectionsList_] = useState(null);
  const dataFetched = useRef();
  const { SetCollections_product, selected_id } = props;
  const getCollection = async () => {
    try {
      const postdata = `{
                        collections {
                            id
                            name 
                        }
                    }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setCollectionsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getCollection();
  }, []);

  var status = [];
  var data_ = "";
  CollectionsList?.collections?.forEach((result, index) => {
    if (selected_id !== "value" && selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    var id = result.id;
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (CollectionsList_) {
      SetCollections_product(CollectionsList_);
    } else {
      SetCollections_product(selected_id);
    }
  }, [CollectionsList_, SetCollections_product, selected_id]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Collection")}
        setstatusSelectedOptions={setCollectionsList_}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const StylesList = (props) => {
  const dataFetched = useRef();
  const [StylesList, setStylesList] = useState(null);
  const [StylesList_, setStylesList_] = useState(null);

  const { SetStype_product, selected_id } = props;
  const getStylesList = async () => {
    try {
      const postdata = `{
                        styles {
                            id
                            name 
                        }
                    }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setStylesList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getStylesList();
  }, []);

  var status = [];
  var data_ = [];
  StylesList?.styles?.forEach((result, index) => {
    if (selected_id !== "value" && selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    var id = result.id;
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (StylesList_) {
      SetStype_product(StylesList_);
    }
  }, [SetStype_product, StylesList_]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Style")}
        setstatusSelectedOptions={setStylesList_}
        selected_val={data_}
        menuIsOpen={true}

      ></SelectBox>
    </React.Fragment>
  );
};

export const MetalsList = (props) => {
  const [metalsList, setmetalsList] = useState(null);
  const [metalsList_selected, setmetalsList_selected] = useState(null);
  const dataFetched = useRef();
  const { t } = useTranslation();
  const { setstatusSelectedOptions, selected_id, setFormValues_color } = props;
  const getmetals = async () => {
    try {
      const postdata = `{
                metals {
                    id
                    name 
                    color
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setmetalsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getmetals();
  }, []);

  let status = [];
  let data_ = "";
  let data_color = [];
  metalsList?.metals?.forEach((result) => {
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    data_color[result.id] = result.color;
    var id = result.id;
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (metalsList_selected) {
      setstatusSelectedOptions(metalsList_selected);
      if (data_color[metalsList_selected] !== undefined) {
        setFormValues_color(data_color[metalsList_selected]);
      }
    }
    // eslint-disable-next-line
  }, [metalsList_selected]);
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Metal")}
        setstatusSelectedOptions={setmetalsList_selected}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const ColorsList = (props) => {
  const dataFetched = useRef();
  const [colorsList, setcolorsList] = useState(null);
  const { setstatusSelectedOptions, selected_id } = props;
  const getcolors = async () => {
    try {
      const postdata = `{
                colors {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setcolorsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getcolors();
  }, []);

  var status = [];
  var data_ = "";

  colorsList?.colors?.forEach((result, index) => {
    var id = result.id;
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }

    status.push({
      value: id,
      label: result.name,
    });
  });
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Color")}
        setstatusSelectedOptions={setstatusSelectedOptions}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const SizesList = (props) => {
  const dataFetched = useRef();
  const [sizesList, setsizesList] = useState(null);
  const { setstatusSelectedOptions, selected_id, prodoct_id } = props;
  const getsizes = useCallback(async () => {
    try {
      const postdata = {
        id: prodoct_id,
      };
      let responce = await _Api(postdata, `api/v1/APP/product/getsizes`);
      setsizesList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  }, [prodoct_id]);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getsizes();
  }, [getsizes]);

  var status = [];
  var data_ = "";
  sizesList?.forEach((result, index) => {
    var id = result._id;
    if (selected_id !== "value" && selected_id === result._id) {
      data_ = { label: result.name, value: result._id };
    } else if (selected_id === "value" || (selected_id && !data_)) {
      data_ = "value";
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Size")}
        setstatusSelectedOptions={setstatusSelectedOptions}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const EcomcategorieList = (props) => {
  const dataFetched = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [ecomcategorieList, setecomcategorieList] = useState();
  const [ecomcategoriearrayList, setecomcategoriearrayList] = useState([]);
  const { setEcomcategorie, ecomcategorie_, setEcomcategorie_ } = props;

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const delete_categorie = (index) => {
    setEcomcategorie_([
      ...ecomcategorie_.slice(0, index),
      ...ecomcategorie_.slice(index + 1),
    ]);
    setEcomcategorie([
      ...ecomcategorie_.slice(0, index),
      ...ecomcategorie_.slice(index + 1),
    ]);
  };
  const getecomcategorie = async () => {
    try {
      const postdata = `{
                ecomcategorie {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      let ecomcategoryarray = [];
      if (responce.data?.ecomcategorie) {
        responce.data?.ecomcategorie?.forEach((result) => {
          ecomcategoryarray.push(
            {
              label: result?.name,
              value: result?.id
            }
          );
        })
      }
      setecomcategoriearrayList(ecomcategoryarray);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getecomcategorie();

  }, []);
  const setEcomcategorie_val = (value, text, type = "") => {
    var Ecomcategorie_text_arr = [...ecomcategorie_];
    Ecomcategorie_text_arr.push({ id: value, name: text });

    const removeDuplicates = (originalArray, prop) => {
      var newArray = [];
      var lookupObject = {};

      for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }

      for (i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
      return newArray;
    };
    var uniqueArray = removeDuplicates(Ecomcategorie_text_arr, "id");
    setEcomcategorie_(uniqueArray);
    setEcomcategorie(uniqueArray);
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="product-ct-wrap-col-left-ecommerce-cat-box product-ct-wrap-col-left-additional-info-box">
        <h4 className="product-ct-wrap-col-left-additional-info-box-heading">
          {t("eCommerce Category")} <span className="question-mark">?</span>
        </h4>
        <div className="row">
          <div className="col-md-7">
            <div className="placeholder-up-input w-100">
              <Select
                className=" select-set select  p-0"
                placeholder={t("eCom-Categories")}
                options={ecomcategoriearrayList}
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                value={ecomcategorieList}
                onChange={(e) => { setecomcategorieList(e); setEcomcategorie_val(e?.value, e?.label) }}
                classNamePrefix="common-select"
                getOptionLabel={(opt) => opt.label}
                isSearchable={true}
              />
            </div>
          </div>
        </div>

        {ecomcategorie_ ? ecomcategorie_?.map((result_, index) => {
          return (
            <div
              key={index}
              className="product-ct-wrap-col-left-ecommerce-cat-box-selected-value"
            >
              <div
                deepak={index}
                className="product-ct-wrap-col-left-ecommerce-cat-box-selected-value-single"
              >
                <button
                  type="button"
                  onClick={(e) => delete_categorie(index)}
                  className="product-ct-wrap-col-left-ecommerce-cat-box-selected-value-single-close"
                >
                  <img src={base_assets + "images/icons/minus-detail.png"} alt="" />
                </button>
                <label className="product-ct-wrap-col-left-ecommerce-cat-box-selected-value-single-text">
                  {result_.name}
                </label>
              </div>
            </div>
          );
        }) : ''}
      </div>
    </React.Fragment>
  );
};

export const StonegroupsList = (props) => {
  const dataFetched = useRef();
  const [stonegroupsList, setstonegroupsList] = useState(null);
  const { setstatusSelectedOptions, index_stone, selected_id, formstoneGroup, set_stoneGroup, setFormValues_stone } =
    props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const getstonegroups = async () => {
    try {
      const postdata = `{
                stonegroups {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setstonegroupsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getstonegroups();
  }, []);
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["StoneGroup"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
      var formstoneGroup_arr = [...formstoneGroup];
      formstoneGroup_arr[index_stone] = statusSelectedOptions_stone;

      set_stoneGroup(formstoneGroup_arr);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  var status = [];
  var data_ = "";
  stonegroupsList?.stonegroups?.forEach((result, index) => {
    var id = result.id;
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Stone Group")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const StonesList = (props) => {
  const dataFetched = useRef();
  const [stonesList, setstonesList] = useState([]);
  const {
    setstatusSelectedOptions,
    index_stone,
    selected_id,
    formstoneGroup,
    setFormValues_stone
  } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  var id = formstoneGroup[index_stone] ? formstoneGroup[index_stone] : '';
  const getstones = useCallback(async () => {
    try {
      const postdata = `{
                stones(group_id:"`+ id + `") {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setstonesList(responce.data);
      // dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  }, [id]);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getstones();
  }, [formstoneGroup, getstones]);
  var status = [];
  var data_ = [];
  stonesList?.stones?.forEach((result, index) => {
    var id = result.id;
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  if (!stonesList?.stones?.[0]) {
    data_ = "value";
  }
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["Stone"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Stone")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const ShapesList = (props) => {
  const dataFetched = useRef();
  const [shapesList, setshapesList] = useState(null);
  const { setstatusSelectedOptions, index_stone, selected_id, formShape_ids, setShape_ids } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const getshapes = async () => {
    try {
      const postdata = `{
                shapes {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setshapesList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getshapes();
  }, []);
  var data_ = "";
  var status = [];
  shapesList?.shapes?.forEach((result, index) => {
    var id = result.id;
    if (selected_id !== "value" && selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    } else if (selected_id === "value" || (selected_id && !data_)) {
      data_ = "value";
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      setstatusSelectedOptions[index_stone]["StoneShape"] =
        statusSelectedOptions_stone;
      var formShape_ids_arr = [...formShape_ids];
      formShape_ids_arr[index_stone] = statusSelectedOptions_stone;
      setShape_ids(formShape_ids_arr);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Shape")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const CutsList = (props) => {
  const dataFetched = useRef();
  const [cutsList, setcutsList] = useState(null);
  const { setstatusSelectedOptions, index_stone, selected_id, setFormValues_stone } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const getcuts = async () => {
    try {
      const postdata = `{
                cuts {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setcutsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getcuts();
  }, []);
  var status = [];
  var data_ = "";
  cutsList?.cuts?.forEach((result, index) => {
    var id = result.id;
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["StoneCut"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Cut")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const ClaritysList = (props) => {
  const dataFetched = useRef();
  const [claritysList, setclaritysList] = useState(null);
  const { setstatusSelectedOptions, index_stone, selected_id, setFormValues_stone } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const getclaritys = async () => {
    try {
      const postdata = `{
                claritys {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setclaritysList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getclaritys();
  }, []);
  var status = [];
  var data_ = "";
  claritysList?.claritys?.forEach((result, index) => {
    var id = result.id;
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  const { t } = useTranslation()
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["StoneClarity"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line 
  }, [statusSelectedOptions_stone]);
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Clarity")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const StonecolorsList = (props) => {
  const dataFetched = useRef();
  const [stonecolorsList, setstonecolorsList] = useState(null);
  const { setstatusSelectedOptions, index_stone, selected_id, setFormValues_stone } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const getstonecolors = async () => {
    try {
      const postdata = `{
                stonecolors {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setstonecolorsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getstonecolors();
  }, []);
  var status = [];
  var data_ = [];
  stonecolorsList?.stonecolors?.forEach((result, index) => {
    var id = result.id;
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["StoneColor"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line 
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Color")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const StonesettingType = (props) => {
  const dataFetched = useRef();
  const [stonesettingType, setstonesettingType] = useState(null);
  const { setstatusSelectedOptions, index_stone, selected_id, setFormValues_stone } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const getStonesettingType = async () => {
    try {
      const postdata = `{
        settingType {
            id
            name 
        }
    }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setstonesettingType(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getStonesettingType();
  }, []);
  var status = [];
  var data_ = [];
  stonesettingType?.settingType?.forEach((result, index) => {
    var id = result.id;
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["setting_type"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Setting")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const StoneSizeList = (props) => {
  const dataFetched = useRef();
  const [val_stoneSize, setstoneSize] = useState(null);
  const { setstatusSelectedOptions, index_stone, selected_id, setFormValues_stone } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const getstoneSize = async () => {
    try {
      const postdata = `{
        stoneSize {
          id
          name 
          Pointer
          Weight
        }
    }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setstoneSize(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getstoneSize();
  }, []);
  var status = [];
  var data_ = [];
  val_stoneSize?.stoneSize?.forEach((result, index) => {
    if (selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    }
    var id = result.id;
    status.push({
      value: id,
      label: result.name,
      Pointer: result?.Pointer,
      Weight: result.Weight,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["stoneSize"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Size")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const LocationsList = (props) => {
  const dataFetched = useRef();
  const [locationsList, setlocationsList] = useState(null);
  const { setcommanSelected_sup } = props;
  const getlocations = async () => {
    try {
      const postdata = `{
                locations {
                    id
                    name 
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setlocationsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getlocations();
  }, []);

  return (
    <React.Fragment>
      <div className="placeholder-up-input w-100">
        <select
          className="select"
          onChange={(e) => setcommanSelected_sup(e.target.value)}
          defaultValue=""
        >
          <option value="" />
          {locationsList?.locations?.map((result, index) => {
            return (
              <option key={index} value={result.id}>
                {result.name}
              </option>
            );
          })}
        </select>
        <label className="placeholder-up-input-label">Locations</label>
      </div>
    </React.Fragment>
  );
};

export const TaxsList = (props) => {
  const dataFetched = useRef();
  const [taxsList, settaxsList] = useState(null);
  const { setstatusSelectedOptions, index_stone, setPricetax, selected_id } =
    props;
  const [statusSelectedOptions_tax, setstatusSelectedOptions_tax] =
    useState(null);
  const gettaxs = async () => {
    try {
      const postdata = `{
                taxs {
                    id
                    name 
                    rate
                    type
                }
            }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      settaxsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    gettaxs();
  }, []);
  var status = [];
  var data_ = "";
  taxsList?.taxs?.forEach((result, index) => {
    var id = result.id + "_" + result.type + "_" + result.rate;
    if (selected_id !== "value" && selected_id === result.id) {
      data_ = { label: result.name, value: id };
      setstatusSelectedOptions[index_stone]["taxType"] = result.type;
      setstatusSelectedOptions[index_stone]["tax_rate_val"] = result.rate;
      setstatusSelectedOptions[index_stone]["taxrate"] = result.id;
    } else if (selected_id === "value" || (selected_id && !data_)) {
      data_ = "value";
    }
    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_tax) {
      var tax_arr = statusSelectedOptions_tax.split("_");
      setstatusSelectedOptions[index_stone]["taxrate"] = tax_arr[0];
      var taxType = tax_arr[1];
      var tax_rate = tax_arr[2];
      setstatusSelectedOptions[index_stone]["taxType"] = taxType;
      setstatusSelectedOptions[index_stone]["tax_rate_val"] = tax_rate;
      setstatusSelectedOptions[index_stone]["taxrate"] = tax_arr[0];
      setPricetax(index_stone + 1);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_tax]);
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Tax")}
        setstatusSelectedOptions={setstatusSelectedOptions_tax}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};
export const LocationsListOtherDetails = (props) => {
  const dataFetched = useRef();
  const [locationsList, setlocationsList] = useState(null);
  const {
    setstatusSelectedOptions,
    index_stone,
    setOtherDetailsLocation,
    selected_id,
  } = props;
  const [statusSelectedOptions_Locations, setstatusSelectedOptions_Locations] =
    useState(null);
  const getlocations = async () => {
    try {
      const postdata = `{
                  locations {
                      id
                      name 
                      currency
                  }
              }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setlocationsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getlocations();
  }, []);
  var status = [];
  var data_ = "";
  locationsList?.locations?.forEach((result, index) => {
    var id = result.id;
    if (selected_id !== "value" && selected_id === result.id) {
      data_ = { label: result.name, value: id };
    } else if (selected_id === "value" || (selected_id && !data_)) {
      data_ = "value";
    }

    status.push({
      value: id,
      label: result.name,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_Locations) {
      setstatusSelectedOptions[index_stone]["location"] =
        statusSelectedOptions_Locations;
      setOtherDetailsLocation(statusSelectedOptions_Locations);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_Locations]);
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Location")}
        setstatusSelectedOptions={setstatusSelectedOptions_Locations}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};
export const LocationsListPricing = (props) => {
  const dataFetched = useRef();
  const [locationsList, setlocationsList] = useState(null);
  const {
    setstatusSelectedOptions,
    index_stone,
    setPriceLocation,
    selected_id,
    setPricetax
  } = props;
  const [statusSelectedOptions_locations, setstatusSelectedOptions_locations] =
    useState(null);
  const getlocations = async () => {
    try {
      const postdata = `{
                  locations {
                      id
                      name 
                      currency
                      tax
                  }
              }`;
      let responce = await graphqlPost(postdata, "App/graphql");
      setlocationsList(responce.data);
      dataFetched.current = false;
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getlocations();
  }, []);
  var status = [];
  var data_ = "";
  locationsList?.locations?.forEach((result, index) => {
    if (selected_id !== "value" && selected_id === result.id) {
      data_ = { label: result.name, value: result.id };
    } else if (selected_id === "value" || (selected_id && !data_)) {
      data_ = "value";
    }
    status.push({
      value: result.id + "_" + result.currency + "_" + result.tax,
      data_value: result.currency,
      label: result.name,
    });
  });
  useEffect(() => {
    if (statusSelectedOptions_locations) {
      var locations_arr = statusSelectedOptions_locations.split("_");
      if (locations_arr[0] !== undefined && locations_arr[1] !== undefined) {
        setstatusSelectedOptions[index_stone]["location"] = locations_arr[0];
        setstatusSelectedOptions[index_stone]["currency"] = locations_arr[1];
        setstatusSelectedOptions[index_stone]["taxrate"] = locations_arr[2] ? locations_arr[2] : '';
        setPricetax(index_stone + 1);
      }
      setPriceLocation(locations_arr[0]);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_locations]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Location")}
        setstatusSelectedOptions={setstatusSelectedOptions_locations}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const CertificateTypelist = (props) => {
  const { setstatusSelectedOptions, index_stone, selected_id, setFormValues_stone } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const certificate_opt = [
    { value: "AGS", label: "AGS" },
    { value: "EFCO", label: "EFCO" },
    { value: "EGL", label: "EGL" },
    { value: "GEMSCAN", label: "GEMSCAN" },
    { value: "GIA", label: "GIA" },
    { value: "GRS", label: "GRS" },
    { value: "HRD", label: "HRD" },
    { value: "IGI", label: "IGI" },
    { value: "UGL", label: "UGL" },
  ];
  var data_ = "";
  certificate_opt?.forEach((result, index) => {
    if (result.value === selected_id) {
      data_ = result;
    }
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["Certificatetype"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={certificate_opt}
        placeholder_val={t("Certificate Type")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const StoneVarientType = (props) => {
  const { t } = useTranslation()
  const {
    setstatusSelectedOptions,
    index_stone,
    selected_id,
    setDesignValue,
    formDesignValue,
  } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(0);
  const Varient_opt = [
    { value: "1", label: t("Varient") },
    { value: "0", label: t("NoVarient") },
    { value: "2", label: t("Design") },
  ];
  var data_ = { value: "0", label: t("NoVarient") };
  Varient_opt?.forEach((result, index) => {
    if (result.value === selected_id) {
      data_ = result;
    }
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      setstatusSelectedOptions[index_stone]["StoneVariant"] = statusSelectedOptions_stone;
      var formDesignValue_arr = [...formDesignValue];
      formDesignValue_arr[index_stone] = statusSelectedOptions_stone;
      setDesignValue(formDesignValue_arr);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);

  return (
    <React.Fragment>
      <SelectBox
        option={Varient_opt}
        placeholder_val={t("Variant")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const UnitSelect = (props) => {
  const { setUnit, selected_id } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const Unit_obj = [
    { value: "Pcs", label: "Pcs" },
    { value: "Pr", label: "Pair" },
  ];
  var data_ = "";
  Unit_obj?.forEach((result, index) => {
    if (result.value === selected_id) {
      data_ = result;
    }
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      setUnit(statusSelectedOptions_stone);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={Unit_obj}
        placeholder_val={t("Unit")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};


export const StoneUnitselect = (props) => {
  const { setstatusSelectedOptions, index_stone, selected_id, setFormValues_stone } = props;
  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  const StoneUnit_obj = [
    { value: "Cts", label: "CT" },
    { value: "G", label: "G" },
  ];
  var data_ = "";
  StoneUnit_obj?.forEach((result, index) => {
    if (selected_id === result.value) {
      data_ = { label: result.label, value: result.value };
    }
  });
  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["WeightType"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={StoneUnit_obj}
        placeholder_val={t("Unit")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};

export const GetPointer = (props) => {
  const [stonesPointer, setStonesPointer] = useState(null);
  const {
    setstatusSelectedOptions,
    index_stone,
    selected_id,
    formShape_ids,
    setFormValues_stone
  } = props;

  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] =
    useState(null);
  if (setstatusSelectedOptions[index_stone] === undefined) {
    setstatusSelectedOptions[index_stone] = '';
  }
  const getPointers = async () => {
    try {
      const postdata = { id: formShape_ids[index_stone] !== undefined && formShape_ids[index_stone] ? formShape_ids[index_stone] : setstatusSelectedOptions[index_stone]?.StoneShape };
      let responce = await _Api(postdata, `api/v1/APP/product/getPointerByShapeID`);
      setStonesPointer(responce.data);
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getPointers();
    // eslint-disable-next-line
  }, [formShape_ids]);
  var status = [];
  var data_ = "";
  stonesPointer?.forEach((result, index) => {

    if (selected_id !== "value" && selected_id === result.value) {

      data_ = result;
    }
    status.push(result);
  });

  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["stonePointer"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Pointer")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};


export const GetShapeToStoneSize = (props) => {
  const [stonesPointer, setStonesPointer] = useState(null);
  const {
    setstatusSelectedOptions,
    index_stone,
    selected_id,
    formShape_ids,
    setFormValues_stone,
    send_StoneVarint,
    StoneShape_id
  } = props;

  const [statusSelectedOptions_stone, setstatusSelectedOptions_stone] = useState(null);
  if (setstatusSelectedOptions[index_stone] === undefined) {
    setstatusSelectedOptions[index_stone] = '';
  }
  const getStoneSize = useCallback(async () => {
    try {

      if (StoneShape_id) {
        const postdata = { id: StoneShape_id, mixMatch: send_StoneVarint === "2" ? 1 : false };
        let responce = await _Api(postdata, `api/v1/APP/product/getShapeToStoneSize`);
        setStonesPointer(responce.data);
      }
    } catch (err) {
      console.log(err, "err");
    }

  }, [StoneShape_id, send_StoneVarint]);
  useEffect(() => {

    getStoneSize();
  }, [formShape_ids, getStoneSize]);
  var status = [];
  var data_ = "";
  stonesPointer?.forEach((result, index) => {
    if (selected_id !== "value" && selected_id === result.value) {
      data_ = result;
    }
    status.push(result);
  });

  useEffect(() => {
    if (statusSelectedOptions_stone) {
      let setstatusSelectedOptions_ = [...setstatusSelectedOptions];
      setstatusSelectedOptions_[index_stone]["stoneSize"] =
        statusSelectedOptions_stone;
      setFormValues_stone(setstatusSelectedOptions_);
    }
    // eslint-disable-next-line
  }, [statusSelectedOptions_stone]);

  const { t } = useTranslation()
  return (
    <React.Fragment>
      <SelectBox
        option={status}
        placeholder_val={t("Size")}
        setstatusSelectedOptions={setstatusSelectedOptions_stone}
        selected_val={data_}
      ></SelectBox>
    </React.Fragment>
  );
};